import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import styles from './index.module.css';

const Collapse = ({ title, css='', children }) => {

    const [show, setShow] = useState(false);
    const onHide = () => setShow(state => !state);

    return (
        <div className={styles.wrapper}>
            <div className={styles.titleFlex} onClick={onHide}>
                <FontAwesomeIcon icon={!show ? faAngleDown : faAngleUp}/>
                <p className={css}>{title}</p>                
            </div>
            {
                show && children
            }
        </div>
    )
}

export default Collapse