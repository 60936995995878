import { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { SectionTitle } from "../../../../../components";
import styles from './index.module.css';
import { isNumber } from "../../../../../utils";

const CrossSection = ({ 
    dimension, 
    width, 
    pileShape, 
    memberType,
    coverUp, 
    coverBottom, 
    rebarNumber = {},
    rebarNumberAs1 = {}, 
    rebarNumberAs2 = {}, 
    rebarDiameter = {} ,
    rebarDiameterAs1 = {},
    rebarDiameterAs2 = {},
    rebarSpiral = {},
    negativeMoment,
    x,
    d
}) => {
    const ratio = width > 3*dimension ? 300 : 200;
    const maxDim = Math.max(dimension, width);
    const heightPx = (dimension/maxDim)*ratio;
    const compressionZone = isNumber(x) && isNumber(dimension) ? (x/dimension)*100 : 0;
    const mmPerPx = ratio/maxDim;

    return (
        <Fragment>
            <SectionTitle 
                classNameWrapper={styles.centerTitle}
                title="Przekrój poprzeczny"
            />
            <Row>
                <Col xs={12}>
                    <div className={styles.sectionContainer}>
                        <div className={styles.wrapper}>
                            {
                                pileShape === 2 &&
                                <div>
                                    {rebarNumberAs2 && rebarNumberAs2.value > 0 && <p className={styles.rebarDescription}>{rebarNumberAs2.value}&#981;{rebarDiameterAs2.value}mm (A<sub>s2</sub>)</p>}                                    
                                </div>                                
                            }
                            <div className={styles.flexRow}>
                                {
                                    dimension && coverUp && coverBottom &&
                                    <div className={styles.verticalDimension}>
                                        <div style={{ height: `${(coverUp/dimension)*heightPx}px` }} className={styles.lineWrapper}>
                                            <div className={styles.line}></div>
                                            <p>{`${coverUp}`}</p>
                                            <div className={styles.line}></div>
                                        </div>
                                        <div style={{ height:`${heightPx - ((coverUp + coverBottom)/dimension)*heightPx}px` }} className={styles.lineWrapper}>
                                            <p>{`${dimension - (coverUp + coverBottom)}`}</p>
                                        </div>
                                        <div style={{ height: `${(coverBottom/dimension)*heightPx}px` }} className={styles.lineWrapper}>
                                            <div className={styles.line}></div>
                                            <p>{`${coverBottom}`}</p>
                                            <div className={styles.line}></div>
                                        </div>
                                    </div>
                                }
                                <div className={styles.block}>
                                    {
                                        pileShape === 1
                                        ?
                                        <div 
                                            className={styles.crossSectionCircular}
                                            style={{
                                                padding: `${(coverUp/dimension)*ratio}px`,
                                                background: `${(compressionZone > 100 || !isNumber(compressionZone))
                                                    ? '#ccc'
                                                    : `linear-gradient(${negativeMoment ? '0deg' : '180deg'}, #b3b3b3 ${compressionZone}%, #ccc ${compressionZone}% 100%)`}`
                                            }}
                                        >
                                            <div 
                                                className={styles.spiralCircular}
                                                style={{
                                                    border: `${(rebarSpiral.value/dimension)*ratio}px solid #000`,
                                                }}
                                            >
                                                {
                                                    rebarNumber && rebarNumber.value > 0 &&
                                                    new Array(rebarNumber.value).fill('').map((_, idx) => {
                                                        return (
                                                            <div 
                                                                key={idx} 
                                                                className={styles.rebarCircle}
                                                                style={{
                                                                    transform: `
                                                                        rotate(${rebarNumber.value%2 === 0 ? (360/rebarNumber.value)*idx : 90 + (360/rebarNumber.value)*idx}deg) 
                                                                        translateY(${(((coverUp + rebarSpiral.value  + 0.5*rebarDiameter.value)/dimension)*ratio) - 0.5*ratio}px)`,
                                                                    width: `${(rebarDiameter.value/dimension)*ratio}px`,
                                                                    height: `${(rebarDiameter.value/dimension)*ratio}px`,
                                                                    top: `calc(50% - ${0.5 * (rebarDiameter.value/dimension)*ratio}px)`,
                                                                    left: `calc(50% - ${0.5 * (rebarDiameter.value/dimension)*ratio}px)`
                                                                }}
                                                            >
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {rebarNumber.value > 0 && <p className={styles.rebarDescription}>{rebarNumber.value}&#981;{rebarDiameter.value}mm (A<sub>s</sub>)</p>}
                                            </div>
                                        </div>
                                        : 
                                        <Fragment>
                                            <div 
                                                className={memberType === 'Płyta' ? styles.crossSection1 : styles.crossSection}
                                                style={{
                                                    width: `${width*mmPerPx}px`,
                                                    height: `${dimension*mmPerPx}px`,
                                                    paddingTop: `${coverUp*mmPerPx}px`,
                                                    paddingBottom: `${coverBottom*mmPerPx}px`,
                                                    paddingLeft: `${coverBottom*mmPerPx}px`,
                                                    paddingRight: `${coverBottom*mmPerPx}px`,
                                                    background: `${(compressionZone > 100  || !isNumber(compressionZone))
                                                        ? '#ccc'
                                                        : `linear-gradient(${negativeMoment ? '0deg' : '180deg'}, #b3b3b3 ${compressionZone}%, #ccc ${compressionZone}% 100%)`}`
                                                }}
                                            >
                                                {
                                                    memberType === 'Płyta' &&
                                                    <Fragment>
                                                        <div className={styles.lineDashed}></div>
                                                        <div className={styles.lineDashed1}></div>
                                                    </Fragment>
                                                }
                                                <div 
                                                    className={styles.spiral}
                                                    style={{
                                                        border: `${memberType !== 'Płyta' ? `${rebarSpiral.value*mmPerPx}px solid #333` : 'none'} `,
                                                        borderRadius: `${(rebarSpiral.value <= 16 ? 2 : 3.5) * (rebarSpiral.value*mmPerPx)}px`,
                                                    }}
                                                >
                                                    <div 
                                                        className={styles.rebarRow}
                                                        style={{
                                                            width: `${memberType !== 'Płyta' ? `calc(100% - ${(rebarSpiral.value <= 16 ? 2 : 3.5) * (rebarSpiral.value*mmPerPx)}px)` : '100%'}`,
                                                            position: 'relative',
                                                            left: `${memberType !== 'Płyta' ? `${(rebarSpiral.value <= 16 ? 2 : 3.5) * (rebarSpiral.value*mmPerPx)*0.5}px` : '0'}`
                                                        }}    
                                                    >
                                                        <div className={styles.column}>
                                                            <div className={styles.flex}>
                                                                {
                                                                    rebarNumberAs2 && rebarNumberAs2.value > 0 &&
                                                                    new Array(rebarNumberAs2.value).fill('').map((_, idx) => {
                                                                        return (
                                                                            <div 
                                                                                key={idx} 
                                                                                className={styles.rebar}
                                                                                style={{
                                                                                    width: `${rebarDiameterAs2.value*mmPerPx}px`,
                                                                                    height: `${rebarDiameterAs2.value*mmPerPx}px`
                                                                                }}
                                                                            >
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div 
                                                        className={styles.rebarRow}
                                                        style={{
                                                            width: `${memberType !== 'Płyta' ? `calc(100% - ${(rebarSpiral.value <= 16 ? 2 : 3.5) * (rebarSpiral.value*mmPerPx)}px)` : '100%'}`,
                                                            position: 'relative',
                                                            left: `${memberType !== 'Płyta' ? `${(rebarSpiral.value <= 16 ? 2 : 3.5) * (rebarSpiral.value*mmPerPx)*0.5}px` : '0'}`
                                                        }}
                                                    >
                                                        <div className={styles.column}>
                                                            <div className={styles.flex}>
                                                            {
                                                                    rebarNumberAs1.value > 0 &&
                                                                    new Array(rebarNumberAs1.value).fill('').map((_, idx) => {
                                                                        return (
                                                                            <div 
                                                                                key={idx} 
                                                                                className={styles.rebar}
                                                                                style={{
                                                                                    width: `${rebarDiameterAs1.value*mmPerPx}px`,
                                                                                    height: `${rebarDiameterAs1.value*mmPerPx}px`
                                                                                }}
                                                                            >
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {rebarNumberAs1.value > 0 && <p className={styles.rebarDescription}>{rebarNumberAs1.value}&#981;{rebarDiameterAs1.value}mm (A<sub>s1</sub>)</p>}
                                        </Fragment>
                                    }

                                    {
                                        pileShape === 1
                                        ?
                                            dimension &&
                                            <div className={styles.dimension}>
                                                <p>{`${dimension}`}</p>
                                                <div className={styles.lineWrapper}>
                                                    <div className={styles.line}></div>
                                                    <div className={styles.line}></div>
                                                </div>
                                            </div>
                                        :
                                        width &&
                                        <Fragment>
                                            <div 
                                                className={styles.dimension}
                                                style={{
                                                    width: `${(width/maxDim)*ratio}px`
                                                }}
                                            >
                                                <p>{`${width}`}</p>
                                                <div className={styles.lineWrapper}>
                                                    <div className={styles.line}></div>
                                                    <div className={styles.line}></div>
                                                </div>
                                                
                                            </div>
                                        </Fragment>
                                    }
                                </div>
                                <Fragment>
                                    {
                                        dimension && coverUp && coverBottom && x > 0 &&
                                        <div className={styles.verticalDimensionRight}>
                                            <div style={{ 
                                                height:`${x > dimension ? heightPx : (x/dimension)*heightPx}px`, 
                                                top: `${negativeMoment ? x > dimension ? 0 : heightPx - (x/dimension)*heightPx : 0}px`}} 
                                                className={styles.lineWrapper}
                                            >
                                                <div className={styles.line}></div>
                                                <p>x={x}</p>
                                                <div className={styles.line}></div>
                                            </div>
                                        </div>
                                    }
                                </Fragment>
                                <Fragment>
                                    {
                                        dimension && coverUp && coverBottom &&
                                        <div className={styles.verticalDimensionRight}>
                                            <div style={{ 
                                                height: pileShape === 1 
                                                    ? 
                                                    `${((dimension - coverBottom - rebarSpiral.value - 0.5*rebarDiameter.value)/dimension)*heightPx}px`
                                                    :
                                                    `${(d/dimension)*heightPx}px`,
                                                top: pileShape === 1 
                                                    ?
                                                    `${negativeMoment ? heightPx - ((dimension - coverUp - rebarSpiral.value - 0.5*rebarDiameter.value)/dimension)*heightPx : 0}px`
                                                    :
                                                    `${negativeMoment ? heightPx - (d/dimension)*heightPx : 0}px`
                                                }} 
                                                
                                                className={styles.lineWrapper}
                                            >
                                                <div className={styles.line}></div>
                                                <p>{pileShape === 1 && rebarSpiral.value > 0 && rebarDiameter.value > 0
                                                    ? 
                                                    dimension - coverBottom - rebarSpiral.value - 0.5*rebarDiameter.value
                                                    :
                                                    d
                                                }</p>
                                                <div className={styles.line}></div>
                                            </div>
                                        </div>
                                    }
                                </Fragment>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}

export default CrossSection