import React, { useContext } from 'react';
import { ConfirmModal } from '../../components';
import { authContext } from '../../context/Authentication';

const Logout = ({ open, onChange }) => {

    const { logOut } = useContext(authContext);

    const logOutFromDashboard = () => {
        onChange(false);
        logOut();
    }
    const onHide = () => onChange(false);

    return (
        <ConfirmModal open={open} onHide={onHide} onConfirm={logOutFromDashboard} title="Na pewno chcesz się wylogować?">Niezapisane zmiany w projektach zostaną utracone</ConfirmModal> 
    )
}

export default Logout;