import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import { isNumber, roundNumber, setSoilColor } from '../../../../../utils';
import styles from './index.module.css';
import CorrectDepth from '../../Results/CorrectDepth';
import SoilLayer from '../../Results/VerticalCapacityChart/SoilProfile/SoilLayer';
import useDeepCompareEffect from 'use-deep-compare-effect';

const PileCapacityChart = ({
    results = [],
    soilProfile = [],
    pileLength = '',
    pileHeadSpot = '',
    pileShape = '',
    diameterPile = '',
    singlePile = false,
    title = '',
    activeChart,
    groundLevel,
    type = 'nt',
}) => {
    const soilProfileRef = useRef(null);
    const resultsRef = useRef(null);
 
    const [isPileVisible, setIsPileVisible] = useState(false);
    const [soilLayer, setSoilLayer] = useState([]);
    const [profilePileLength, setProfilePileLength] = useState('');
    const [profilePileHeadSpot, setProfilePileHeadSpot] = useState('');
    const [pileBaseSpot, setPileBaseSpot] = useState('');
    const [resultsWidth, setResultsWidth] = useState(0);
    const [resultsHeight, setResultsHeight] = useState(0);
    const [maxHeight, setMaxHeight] = useState(0);
    const [maxNt, setMaxNt] = useState('');
    const [axisNtScale, setAxisNtScale] = useState([]);
    const [currentNt, setCurrentNt] = useState('');
    const [currentPileLength, setCurrentPileLength] = useState('');
    const [currentCapacity, setCurrentCapacity] = useState({});
    const [correctDepth, setCorrectDepth] = useState(false);
    const [warningDepth, setWarningDepth] = useState(false);

    const soilProfileHeights = soilProfile.map((ele) => ele.height);

    useEffect(() => {
        if (resultsRef.current) {
            setResultsWidth(resultsRef.current.offsetWidth * 0.8);
        }
    }, [isPileVisible, activeChart]);

    useDeepCompareEffect(() => {
        setMaxHeight(Math.max(...soilProfileHeights) + 1);
    }, [soilProfileHeights]);

    useDeepCompareEffect(() => {
        if (soilProfileRef.current) {
            const offsetHeight = soilProfileRef.current.offsetHeight;
            const layers = soilProfile.map((ele, i, arr) => {
                return (
                    <SoilLayer
                        key={i}
                        backgroundColor={setSoilColor({
                            id: ele.density,
                            il: ele.plasticity,
                        })}
                        name={ele.name}
                        height={
                            maxHeight === 0
                                ? 0
                                : (offsetHeight *
                                      (i === arr.length - 1
                                          ? ele.absHeight + 1
                                          : ele.absHeight)) /
                                  maxHeight
                        }
                        layerHeight={ele.height}
                        groundLevel={groundLevel}
                    />
                );
            });
            setSoilLayer(layers);
            setResultsHeight(offsetHeight);
        }
    }, [maxHeight, soilProfile]);

    useDeepCompareEffect(() => {
        const nt = Math.max(...results.map((ele) => ele.nt));

        const maxAxisNt = roundNumber(nt - (nt % 5), 0);
        const initPileLength =
            isNumber(pileLength) &&
            roundNumber(pileLength + pileHeadSpot,2) <= maxHeight &&
            pileLength > 0
                ? pileLength
                : maxHeight;
        const initNt =
            results.find(
                (ele) => roundNumber(ele.z,2) === roundNumber(initPileLength + pileHeadSpot,2),
            ) || {};

        setIsPileVisible(
            isNumber(pileHeadSpot) && maxHeight - pileHeadSpot > 0,
        );
        setPileBaseSpot((state) =>
            isNumber(initPileLength) ? roundNumber(initPileLength + pileHeadSpot,2) : state,
        );

        setCurrentPileLength(isNumber(initPileLength) ? initPileLength : '');

        setCurrentNt(initNt.nt);
        setCurrentCapacity(initNt);
        setAxisNtScale(
            Array(5)
                .fill(maxAxisNt / 5)
                .reduce((acc, ele, idx) => [...acc, ele * (idx + 1)], []),
        );

        setMaxNt(nt);

        if (soilProfileRef.current) {
            setProfilePileHeadSpot(
                maxHeight === 0
                    ? 0
                    : (resultsHeight * pileHeadSpot) / maxHeight,
            );
            setProfilePileLength(
                maxHeight === 0
                    ? 0
                    : (resultsHeight * initPileLength) / maxHeight,
            );
        }
    }, [maxHeight, resultsHeight, pileLength, results, pileHeadSpot]);

    useDeepCompareEffect(() => {
        const {
            correctBaseDepth,
            correctMultiLayerDepth,
            isCohesive,
            isSoftLayer,
        } = currentCapacity.correctDepth || {};
        const correctLength =
            correctBaseDepth >= 1 &&
            correctMultiLayerDepth &&
            !isCohesive &&
            !isSoftLayer;
        const warningLength =
            correctBaseDepth > 0 &&
            correctBaseDepth < 1 &&
            correctMultiLayerDepth &&
            !isCohesive &&
            !isSoftLayer;

        setCorrectDepth(correctLength);
        setWarningDepth(warningLength);
    }, [currentCapacity, pileLength]);

    return (
        <Fragment>
            <div className={styles.chartWrapper}>
                <div className={styles.title}>
                    <h2>{title}</h2>
                </div>
                <div className={styles.wrapper} ref={soilProfileRef}>
                    {isPileVisible && ( 
                        <Fragment>
                            <div
                                className={styles.pileWrapper}
                                style={{
                                    height: `${profilePileLength}px`,
                                    top: `${profilePileHeadSpot}px`,
                                }}>
                                <div className={styles.pile}>
                                    <span className={styles.pileHeadSpot}>
                                        {isNumber(pileHeadSpot)
                                            ? pileHeadSpot.toFixed(2)
                                            : ''}
                                    </span>
                                    <div
                                        className={`${styles.pileShape} ${
                                            pileShape === 'Kołowy'
                                                ? styles.circle
                                                : ''
                                        }`}></div>
                                    <div className={styles.pileLength}>
                                        <p>
                                            L ={' '}
                                            {roundNumber(currentPileLength, 2)}{' '}
                                            m
                                        </p>
                                    </div>
                                    <span className={styles.pileBaseSpot}>
                                        {isNumber(pileBaseSpot)
                                            ? pileBaseSpot.toFixed(2)
                                            : ''}
                                    </span>
                                </div>

                                <div
                                    className={styles.results}
                                    ref={resultsRef}>
                                    <div
                                        className={styles.horizontalAxis}
                                        >
                                        {axisNtScale.map((ele, idx) => {
                                            return (
                                                <span
                                                    key={idx}
                                                    className={styles.axisCoord}
                                                    style={{
                                                        left: `${
                                                            (resultsWidth /
                                                                maxNt) *
                                                            ele
                                                        }px`,
                                                    }}>
                                                    <p className={styles.value}>
                                                        {ele}
                                                    </p>
                                                </span>
                                            );
                                        })}

                                        <span className={styles.caret}>
                                            <p
                                                className={
                                                    styles.axisDescription
                                                }>
                                                N<sub>t</sub>[kN]
                                            </p>
                                        </span>
                                    </div>
                                    <div className={styles.verticalAxis}>
                                        <span className={styles.verticalCaret}>
                                            <p
                                                className={
                                                    styles.axisVerticalDescription
                                                }>
                                                Z[m]
                                            </p>
                                        </span>
                                    </div>
                                    <div
                                        className={
                                            styles.currentHorizontalValue
                                        }
                                        style={{
                                            top: `${profilePileLength}px`,
                                            width: `${
                                                (resultsWidth * currentNt) /
                                                maxNt
                                            }px`,
                                        }}></div>
                                    <div
                                        className={styles.currentVerticalValue}
                                        style={{
                                            height: `${profilePileLength}px`,
                                            left: `${
                                                (resultsWidth * currentNt) /
                                                maxNt
                                            }px`,
                                        }}></div>

                                    {type === 'nt' && (
                                        <div
                                            className={`${
                                                styles.capacityPoint
                                            } ${
                                                correctDepth
                                                    ? styles.validDepth
                                                    : warningDepth
                                                    ? styles.warningDepth
                                                    : styles.wrongDepth
                                            }`}
                                            style={{
                                                top: `${
                                                    (resultsHeight *
                                                        (currentCapacity.z -
                                                            pileHeadSpot)) /
                                                    maxHeight
                                                }px`,
                                                left: `${
                                                    (resultsWidth *
                                                        currentCapacity.nt) /
                                                    maxNt
                                                }px`,
                                            }}>
                                            <FontAwesomeIcon
                                                icon={
                                                    correctDepth
                                                        ? faCheckCircle
                                                        : warningDepth
                                                        ? faCheckCircle
                                                        : faTimesCircle
                                                }
                                            />
                                        </div>
                                    )}
                                    {type === 'nw' && (
                                        <div
                                            className={`${styles.capacityPoint} ${styles.validDepth}`}
                                            style={{
                                                top: `${
                                                    (resultsHeight *
                                                        (currentCapacity.z -
                                                            pileHeadSpot)) /
                                                    maxHeight
                                                }px`,
                                                left: `${
                                                    (resultsWidth *
                                                        currentCapacity.nt) /
                                                    maxNt
                                                }px`,
                                            }}>
                                            <FontAwesomeIcon
                                                icon={faCheckCircle}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Fragment>
                    )}
                    <div className={styles.layersWrapper}>
                        {[...soilLayer]}
                    </div>
                </div>
            </div>
            <div className={styles.capacityDescription}>
                <p>
                    Średnica pala [D] -{' '}
                    <span className={styles.spanValue}>
                        <b>
                            {isNumber(diameterPile)
                                ? roundNumber(diameterPile, 2)
                                : ''}{' '}
                            m
                        </b>
                    </span>
                </p>
                <p>
                    Długość pala [L] -{' '}
                    <span className={styles.spanValue}>
                        <b>
                            {isNumber(currentPileLength)
                                ? roundNumber(currentPileLength, 2)
                                : ''}{' '}
                            m
                        </b>
                    </span>
                </p>
                {type === 'nt' && (
                    <Fragment>
                        <p>
                            Nośność podstawy [N<sub>p</sub>] -{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(currentCapacity.np)
                                        ? roundNumber(currentCapacity.np, 0)
                                        : ''}{' '}
                                    kN
                                </b>
                            </span>
                        </p>
                        <p>
                            Nośność pobocznicy [N<sub>s</sub>] -{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(currentCapacity.ns)
                                        ? roundNumber(currentCapacity.ns, 0)
                                        : ''}{' '}
                                    kN
                                </b>
                            </span>
                        </p>
                        <p>
                            Nośność na wciskanie wg PN-83/B-2482 [N<sub>t</sub>] -{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(currentCapacity.nt)
                                        ? roundNumber(currentCapacity.nt, 0)
                                        : ''}{' '}
                                    kN
                                </b>
                            </span>
                        </p>
                        <p>
                            Nośność na wciskanie wg Eurokodu 7 [N<sub>t,ec7</sub>] -{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(currentCapacity.ntec7d)
                                        ? roundNumber(currentCapacity.ntec7d, 0)
                                        : ''}{' '}
                                    kN
                                </b>
                            </span>
                        </p>
                        <p>
                            Nośność graniczna wg Eurokodu 7 [N<sub>gr,ec7</sub>] -{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(currentCapacity.ntec7)
                                        ? roundNumber(currentCapacity.ntec7, 0)
                                        : ''}{' '}
                                    kN
                                </b>
                            </span>
                        </p>
                        <p>
                            Siła do badania próbnego pala [N<sub>lt</sub>] -{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(currentCapacity.ntec7lt)
                                        ? roundNumber(currentCapacity.ntec7lt, 0)
                                        : ''}{' '}
                                    kN
                                </b>
                            </span>
                        </p>

                        {!singlePile && (
                            <p>
                                Nośność pala w grupie wg PN-83/B-2482 [N
                                <sub>t,gr</sub>] -{' '}
                                <span className={styles.spanValue}>
                                    <b>
                                        {isNumber(currentCapacity.ntgr)
                                            ? roundNumber(currentCapacity.ntgr, 0)
                                            : ''}{' '}
                                        kN
                                    </b>
                                </span>
                            </p>
                        )}
                        <div className={styles.avoidBreak}>
                            <p className={styles.underline}>UWAGI:</p>
                            <CorrectDepth
                                className={styles.correctDepthClass}
                                correctDepth={currentCapacity.correctDepth}
                            />                            
                        </div>
                    </Fragment>
                )}
                {type === 'nw' && (
                    <Fragment>
                        <p>
                            Nośność na wyciąganie wg PN-83/B-2482 [N<sub>tw</sub>] -{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(currentCapacity.nt)
                                        ? roundNumber(currentCapacity.nt, 0)
                                        : ''}{' '}
                                    kN
                                </b>
                            </span>
                        </p>
                        {!singlePile && (
                            <p>
                                Nośność na wyciąganie pala w grupie [N
                                <sub>tw,gr</sub>] -{' '}
                                <span className={styles.spanValue}>
                                    <b>
                                        {isNumber(currentCapacity.nwgr)
                                            ? roundNumber(currentCapacity.nwgr, 0)
                                            : ''}{' '}
                                        kN
                                    </b>
                                </span>
                            </p>
                        )}
                    </Fragment>
                )}                
            </div>

        </Fragment>
    );
};

export default PileCapacityChart;