import { useCallback, useEffect, useState, useContext } from 'react';
import { authContext } from '../../context/Authentication';
import { useLocation } from 'react-router-dom';
import { isObjectId } from '../../config/ProjectConfig';
import { AuthFetch } from '../../routes';
import { useIsMountedState } from '../../hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';
import stringify from 'fast-json-stable-stringify';

const SaveProjectHelpers = ({ 
    fileNameToSave = {},
    onLoadProjectName,
    projectTitle,
    currentProjectId,
    currentProfileId,
    currentUserEmail,
    loadProjectState,
    model,
    results,
    fetch,
    onChangeState,
    onProjectStateChange,
    onProfileStateChange,
    profileStateChange,
    removeProfileStateChange,
    setAlert,
    setLoading,

    saveProjectToLocalStorage
}) => {
    const { email: userEmail } = useContext(authContext);

    const [projectName, setProjectName] = useState({
        value: '',
        isValid: null,
    });
    const [mainProjectName, setMainProjectName] = useState({
        value: '',
        isValid: null,
    });
    const [newMainProjectName, setNewMainProjectName] = useState({
        value: '',
        isValid: null,
    });
    const [projectExists, setProjectExists] = useState({
        value: false,
    });
    const [projects, setProjects] = useState([]);
    const [currentProfile, setCurrentProfile] = useState('');
    const [subUsers, setSubUsers] = useState([]);
    const [userAccessToProject, setUserAccessToProject] = useState([]);
    const [currentUser, setCurrentUser] = useState('');
    const [currentUserProjects, setCurrentUserProjects] = useState([]);
    const [newProject, setNewProject] = useState(false);
    const location = useLocation();

    const isMountedState = useIsMountedState();

    useDeepCompareEffect(() => {
        const userEmailMatch = currentUser.match(/\(([^)]+)\)/);
        const currentUserEmailToProject = userEmailMatch
            ? userEmailMatch[1]
            : userEmail;
        const currentUserObj =
            projects.length > 0
                ? projects.find( 
                      (ele) => ele.email === currentUserEmailToProject,
                  )
                : undefined;
        const currentUserObjProjects = currentUserObj
            ? currentUserObj.projects
            : undefined;
        const currentProfilesArr = currentUserObjProjects
            ? currentUserObjProjects[0].soilProfiles[0]
            : undefined;
        if (currentUserObjProjects) {
            setCurrentUserProjects(currentUserObjProjects);
            setCurrentProfile(
                currentProfilesArr ? currentProfilesArr.profileName : '',
            );
            setMainProjectName(() => ({
                value: currentUserObjProjects[0].name,
                isValid: true,
            }));
        } else {
            setCurrentUserProjects([]);
            setCurrentProfile('');

            setMainProjectName(() => ({
                value: '',
                isValid: null,
            }));
        }
    }, [currentUser, userEmail, projects]);

    useDeepCompareEffect(() => {
        if (isObjectId(currentProjectId)) {
            const emailPattern = /\(([^)]+)\)/;
            const regexEmail = currentUserEmail.match(emailPattern);
            const userEmailMatch = regexEmail ? regexEmail[1] : userEmail;
            const userProjects =
                Array.isArray(projects) &&
                projects.find(
                    (ele) =>
                        ele.email === userEmailMatch &&
                        ele.projects.find(
                            (element) => element.id === currentProjectId,
                        ),
                );

            if (userProjects && Array.isArray(userProjects.projects)) {
                const currentProject =
                    userProjects.projects.find(
                        (ele) => ele.id === currentProjectId,
                    ) || {};
                const profile = Array.isArray(currentProject.soilProfiles)
                    ? currentProject.soilProfiles.find(
                          (ele) => ele.id === currentProfileId,
                      )
                    : '';
                setCurrentUser(currentUserEmail);
                setCurrentUserProjects(userProjects.projects);
                setMainProjectName((state) => ({
                    ...state,
                    value: currentProject.name,
                }));
                setCurrentProfile(profile ? profile.profileName : '');
            }
        }
    }, [
        userEmail,
        currentUserEmail,
        loadProjectState,
        currentProjectId,
        currentProfileId,
        projects,
    ]);

    useEffect(() => {
        setProjectName({
            value: fileNameToSave.state === 'init' ? '' : fileNameToSave.value,
            isValid: fileNameToSave.state === 'init' ? null : true,
        });
    }, [fileNameToSave.state, fileNameToSave.value]);

    useDeepCompareEffect(() => {
        AuthFetch({
            url: fetch.projectList.url,
            method: fetch.projectList.method
        })
        .then((response) => {
            if(isMountedState.current){
                if (
                    Array.isArray(response.responseData.projects) &&
                    response.responseData.projects.length > 0
                ) {
                    if(onProfileStateChange){
                        onProfileStateChange(false);
                    }
                    setNewProject(false);
                    setSubUsers(response.responseData.subUsers);
                    setProjects(() => {
                        return response.responseData.projects.reduce(
                            (acc, ele) => {
                                const { userId, ...rest } = ele;
                                const userExistsIndex =
                                    acc.length > 0
                                        ? acc.findIndex(
                                            (ele) => ele.id === userId.id,
                                        )
                                        : -1;

                                if (userExistsIndex > -1) {
                                    acc[userExistsIndex] = {
                                        ...acc[userExistsIndex],
                                        projects: [
                                            ...acc[userExistsIndex].projects,
                                            rest,
                                        ],
                                    };
                                    return [...acc];
                                }
                                return [
                                    ...acc,
                                    {
                                        ...userId,
                                        projects: [rest],
                                    },
                                ];
                            },
                            [],
                        );
                    });
                } else {
                    setProjects([]);
                }                    
            }

        })
        .catch((e) => {
            if(isMountedState.current){
                setAlert({
                    error: true,
                    message: 'Błąd podczas wczytywania projektów',
                });
            }
        });
    }, [
        newProject,
        profileStateChange,
        removeProfileStateChange,
        location.pathname,
        setAlert,
        onProfileStateChange,

        isMountedState
    ]);

    const onChange = (e) => {
        const target = e.currentTarget;
        if (target.name === 'projectName') {
            setProjectName((state) => ({
                ...state,
                value: target.value,
            }));
        } else if (target.name === 'currentUser') {
            setCurrentUser(target.value);
        } else if (target.name === 'mainProjectName') {
            const currentProject =
                currentUserProjects.length > 0
                    ? currentUserProjects.find(
                          (ele) => ele.name === target.value,
                      )
                    : undefined;
            const profiles = currentProject
                ? currentProject.soilProfiles[0]
                : undefined;
            setMainProjectName((state) => ({
                ...state,
                value: target.value,
            }));
            setCurrentProfile(profiles ? profiles.profileName : '');
        } else if (target.name === 'newMainProjectName') {
            setNewMainProjectName((state) => ({
                ...state,
                value: target.value,
            }));
        } else if (target.name === 'profileName') {
            setCurrentProfile(target.value);
        }
    };
    const onBlur = useCallback((e) => {
        const target = e.currentTarget;

        if (target.name === 'projectName') {
            setProjectName((state) => ({
                ...state,
                isValid: target ? target.checkValidity() : false,
            }));
        } else if (target.name === 'mainProjectName') {
            setMainProjectName((state) => ({
                ...state,
                isValid: target ? target.checkValidity() : false,
            }));
        } else if (target.name === 'newMainProjectName') {
            setNewMainProjectName((state) => ({
                ...state,
                isValid: target ? target.checkValidity() : false,
            }));
        }
    }, []);
    const onAddNewProject = () => {
        if (newMainProjectName.isValid) {
            setLoading({ name: 'creatingProject', loading: true });
            AuthFetch({
                url: fetch.create.url,
                method: fetch.create.method,
                body: {
                    name: newMainProjectName.value,
                    userAccessToProject: userAccessToProject,
                },
            }).then((response) => {
                setLoading({ name: 'creatingProject', loading: false });
                if (response.success) {
                    if (response.responseData.exists) {
                        setAlert({
                            error: true,
                            message: 'Projekt o podanej nazwie już istnieje',
                        });
                    } else {
                        if(onProjectStateChange){
                            onProjectStateChange(true);
                        }
                        onChangeState(true);
                        setNewProject(true);
                        setAlert({
                            error: false,
                            message: 'Projekt został utworzony',
                            hideTime: 2000,
                        });
                    }
                } else {
                    setAlert({
                        error: true,
                        message: 'Błąd podczas tworzenia projektu',
                    });
                }
            });
        } else {
            setNewMainProjectName((state) => ({
                ...state,
                isValid: false,
            }));
            setAlert({ error: true, message: 'Nazwa projektu jest wymagana' });
        }
    };
    const onSubmit = () => {
        const currentProject = currentUserProjects.find((ele) => ele.name === mainProjectName.value) || {};
        const profiles = currentProject.soilProfiles;
        const profile = Array.isArray(profiles) && profiles.length > 0
            ? profiles.find((ele) => ele.profileName === currentProfile) ||
                {}
            : {};

        const isValidSave = currentProject.id && projectName.isValid && mainProjectName.isValid;

        if(isValidSave) {
            setLoading({ name: 'savingProject', loading: true });
            AuthFetch({
                url: fetch.save.url,
                method: fetch.save.method,
                body: {
                    projectName: projectName.value,
                    projectId: currentProject.id,
                    profileId: profile.id,

                    model: {
                        ...projectTitle,
                        ...model,
                    },
                    results: results,
                },
            })
            .then((response) => {
                setLoading({ name: 'savingProject', loading: false });
                if (response.success) {
                    if (response.responseData.exists) {
                        setProjectExists((state) => ({
                            ...state,
                            value: true,
                        }));
                    } else {
                        saveProjectToLocalStorage(stringify({ id: response.responseData.details.id, projectId: response.responseData.details.projectId }));
                        onChangeState(true);
                        onLoadProjectName({
                            state: 'saved',
                            value: projectName.value,
                        });
                        setAlert({
                            error: false,
                            message: 'Obliczenia zostały zapisane',
                            hideTime: 2000,
                        });
                    }
                } else {
                    setAlert({
                        error: true,
                        message: 'Błąd podczas zapisu obliczeń',
                    });
                }
            });
        } else {
            setAlert({
                error: true,
                message: 'Nazwa projektu oraz nazwa obliczeń są wymagane',
            });
            setProjectName((state) => ({ ...state, isValid: !!state.isValid }));
            setMainProjectName((state) => ({
                ...state,
                isValid: !!state.isValid,
            }));
        }
    };
    const onOverwriteProject = () => {
        const currentProject =
            currentUserProjects.find(
                (ele) => ele.name === mainProjectName.value,
            ) || {};
        const profiles = currentProject.soilProfiles;
        const profile =
            Array.isArray(profiles) && profiles.length > 0
                ? profiles.find((ele) => ele.profileName === currentProfile) ||
                  {}
                : {};

        const isValidSave =
            currentProject.id && projectName.isValid && mainProjectName.isValid;

        if (isValidSave) {
            setLoading({ name: 'overwritingProject', loading: true });
            AuthFetch({
                url: fetch.overwrite.url,
                method: fetch.overwrite.method,
                body: {
                    projectName: projectName.value,
                    projectId: currentProject.id,
                    profileId: profile.id,
                    model: {
                        ...projectTitle,
                        ...model,
                    },
                    results: results,
                },
            }).then((response) => {
                setLoading({ name: 'overwritingProject', loading: false });
                if (response.success) {
                    saveProjectToLocalStorage(stringify({ id: response.responseData.details.id, projectId: response.responseData.details.projectId }));
                    setTimeout(() => {
                        onLoadProjectName({
                            state: 'saved',
                            value: projectName.value,
                        });
                        setProjectExists((state) => ({
                            ...state,
                            value: false,
                        }));
                    }, 2000);
                    onChangeState(true);
                    setAlert({
                        error: false,
                        message: 'Obliczenia zostały zapisane',
                        hideTime: 2000,
                    });
                } else {
                    setAlert({
                        error: true,
                        message: 'Błąd podczas zapisu projektu',
                    });
                }
            });
        } else {
            setAlert({
                error: true,
                message: 'Nazwa projektu oraz nazwa obliczeń są wymagane',
            });
        }
    };
    const onChangeAccessUser = (id) => {
        if (userAccessToProject.includes(id)) {
            setUserAccessToProject((state) =>
                state.filter((ele) => ele !== id),
            );
        } else {
            setUserAccessToProject((state) => [...state, id]);
        }
    };
    const onAddAllUsers = (ids = []) => {
        if (Array.isArray(ids)) {
            if (userAccessToProject.length > 0) {
                setUserAccessToProject([]);
            } else {
                ids.forEach((ele) => {
                    setUserAccessToProject((state) => [...state, ele]);
                });
            }
        }
    };
    const saveProject = {
        state: {
            projectExists,
            projectName,
            mainProjectName,
            newMainProjectName,
            projects,
            userAccessToProject,
            subUsers,
            userEmail,
            currentUser,
            currentUserProjects,
            currentProfile,
        },
        onChangeCheckbox: onChangeAccessUser,
        onAddAllUsers: onAddAllUsers,
        onChange: onChange,
        onBlur: onBlur,
        onSubmit: onSubmit,
        onAddNewProject: onAddNewProject,
        onOverwrite: onOverwriteProject,
        onHideExistProjectModal: setProjectExists,
    };
    return {
        saveProject,
    };
};

export default SaveProjectHelpers;