import { useState, Fragment } from 'react';
import { EditProject, SaveProject } from '../../ProjectActions';
import { faEdit, faSave, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.css';
import CustomSelectInput from '../../../components/CustomSelectInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfirmModal } from '../../../components';

const EditAndSaveActions = ({
    geoProfiles = true,
    actions: { saveProject = {}, newProject = {}, editProject = {}, globalLoading = {} },
}) => {
    const [open, setOpen] = useState({}); 

    const onOpen = (e) => {
        const target = e.currentTarget;
        const targetName = target.getAttribute('name');

        if (target && targetName) {
            if (targetName === 'editProject') {
                editProject.onLoadProjects();
            }
            setOpen((state) => ({ ...state, [targetName]: true }));
        }
    };
    const onHide = () => setOpen({});

    const onConfirm = () => {
        if (typeof newProject === 'function') {
            newProject();
            onHide();
        }
    };

    return (
        <Fragment>
            <div className={styles.wrapper}>
                <CustomSelectInput
                    title={'Edycja obliczeń'}
                    altTitle="Edycja obliczeń"
                    name="editProject"
                >
                    <ul>
                        <li name="newProject" onClick={onOpen} className={styles.item}>
                            <span><FontAwesomeIcon icon={faEdit}/></span>Nowy projekt (wyczyść arkusz)
                        </li>
                        <li name="editProject" onClick={onOpen} className={styles.item}>
                            <span><FontAwesomeIcon icon={faFolderOpen}/></span>Wczytaj obliczenia
                        </li>
                        <li name="saveProject" onClick={onOpen} className={styles.item}>
                            <span><FontAwesomeIcon icon={faSave}/></span>Zapisz obliczenia
                        </li>
                    </ul>
                </CustomSelectInput>
            </div>
            <EditProject
                open={open.editProject}
                onHide={onHide}
                onRemoveProject={editProject.onRemoveProject}
                onLoadProject={editProject.onLoadProject}
                onChange={editProject.onChange}
                onBlur={editProject.onBlur}
                state={editProject.state}
                isUserRole={editProject.isUserRole}
                id={editProject.id}
                loading={globalLoading.state}
            />
            <SaveProject
                geoProfiles={geoProfiles}
                open={open.saveProject}
                openOverwriteProject={saveProject.state.projectExists}
                onHideExistProjectModal={saveProject.onHideExistProjectModal}
                onChange={saveProject.onChange}
                onChangeCheckbox={saveProject.onChangeCheckbox}
                onBlur={saveProject.onBlur}
                onSubmit={saveProject.onSubmit}
                onAddNewProject={saveProject.onAddNewProject}
                onAddAllUsers={saveProject.onAddAllUsers}
                onOverwrite={saveProject.onOverwrite}
                state={saveProject.state}
                saveLoading={globalLoading.state.savingProject}
                overwriteLoading={globalLoading.state.overwritingProject} 
                newProjectLoading={globalLoading.state.creatingProject}
                onHide={onHide}
            />
            <ConfirmModal
                title="Uwaga!"
                onHide={onHide}
                onConfirm={onConfirm}
                open={open.newProject}>
                <h4 className={styles.h4}>
                    Niezapisane dane zostaną utracone, otworzyć nowe obliczenia?
                </h4>

            </ConfirmModal>
        </Fragment>
    );
};

export default EditAndSaveActions;
