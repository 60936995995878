import { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { SectionTitle } from "../../../../components";
import styles from './index.module.css';

const CrossSectionModel = ({ dimension, width, pileShape, memberType, cover, coverUp, coverBottom }) => {
    return (
        <Fragment>
            <SectionTitle 
                classNameWrapper={styles.centerTitle}
                title="Widok przekroju"
            >
                Widok przekroju jest jedynie grafiką poglądową, nie jest rysunkiem w skali.
            </SectionTitle>
            <Row>
                <Col xs={12}>
                    <div className={styles.sectionContainer}>
                        <div className={styles.wrapper}>
                            {
                                pileShape !=='Kołowy'
                                ?
                                <Fragment>
                                    {
                                        dimension && coverUp && coverBottom &&
                                        <div style={{ height: `${memberType === 'Płyta' ? '150px' : '200px'}`}} className={styles.verticalDimension}>
                                            <div style={{ height: '18px'}} className={styles.lineWrapper}>
                                                <div className={styles.line}></div>
                                                <p>{`${coverUp}`}</p>
                                                <div className={styles.line}></div>
                                            </div>
                                            <div style={{ height: `${memberType === 'Płyta' ? '114px' : '164px'}`}} className={styles.lineWrapper}>
                                                <p>{`${dimension - coverUp - coverBottom}`}</p>
                                            </div>
                                            <div style={{ height: `18px`}} className={styles.lineWrapper}>
                                                <div className={styles.line}></div>
                                                <p>{`${coverBottom}`}</p>
                                                <div className={styles.line}></div>
                                            </div>
                                        </div>                                          
                                    }
                                </Fragment>
                                :
                                <Fragment>
                                    {
                                        dimension && cover &&
                                        <div className={styles.verticalDimension}>
                                            
                                            <div style={{ height: '18px'}} className={styles.lineWrapper}>
                                                <div className={styles.line}></div>
                                                <p>{`${cover}`}</p>
                                                <div className={styles.line}></div>
                                            </div>
                                            <div style={{ height: `164px`}} className={styles.lineWrapper}>
                                                <p>{`${dimension - 2*cover}`}</p>
                                            </div>
                                            <div style={{ height: `18px`}} className={styles.lineWrapper}>
                                                <div className={styles.line}></div>
                                                <p>{`${cover}`}</p>
                                                <div className={styles.line}></div>
                                            </div>
                                        </div>                                          
                                    }
                                </Fragment>
                            }
                            <div className={styles.block}>
                                {
                                    pileShape === 'Kołowy'
                                    ?
                                    <div className={styles.crossSectionCircular}>
                                        <div className={styles.spiralCircular}>
                                            <div className={styles.rebarCircle}></div>
                                            <div className={styles.rebarCircle}></div>
                                            <div className={styles.rebarCircle}></div>
                                            <div className={styles.rebarCircle}></div>
                                            <div className={styles.rebarCircle}></div>
                                            <div className={styles.rebarCircle}></div>
                                            <div className={styles.rebarCircle}></div>
                                            <div className={styles.rebarCircle}></div>
                                            <p className={styles.rebarDescription}>A<sub>s</sub></p>
                                        </div>
                                        <div className={styles.xAxis}>
                                            <p>x</p>
                                        </div>
                                        <div className={styles.yAxis}>
                                            <p>y</p>
                                        </div>
                                    </div>
                                    :
                                    <Fragment>
                                    {
                                        memberType === 'Płyta'
                                        ?
                                        <div className={styles.crossSection1}>
                                            <div className={styles.lineDashed}></div>
                                            <div className={styles.lineDashed1}></div>
                                            <div className={styles.spiralNone}>
                                                <div className={styles.rebarRow}>
                                                    <div className={styles.column}>
                                                        <div className={styles.flex}>
                                                            <div className={styles.rebar2}></div>
                                                            <div className={styles.rebar2}></div>
                                                            <div className={styles.rebar2}></div>
                                                        </div>
                                                        <p className={styles.rebarDescription}>A<sub>s2</sub></p>
                                                    </div>
                                                </div>
                                                <div className={styles.rebarRow}>
                                                    <div className={styles.column}>
                                                        <p className={styles.rebarDescription}>A<sub>s1</sub></p>
                                                        <div className={styles.flex}>
                                                            <div className={styles.rebar1}></div>
                                                            <div className={styles.rebar1}></div>
                                                            <div className={styles.rebar1}></div>
                                                            <div className={styles.rebar1}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={styles.xAxis}>
                                                <p>x</p>
                                            </div>
                                            <div className={styles.yAxis}>
                                                <p>y</p>
                                            </div>

                                        </div>
                                        :
                                        <div className={styles.crossSection}>
                                            <div className={styles.spiral}>
                                                <div className={styles.rebarRow}>
                                                    <div className={styles.column}>
                                                        <div className={styles.flex}>
                                                            <div className={styles.rebar}></div>
                                                            <div className={styles.rebar}></div>
                                                            <div className={styles.rebar}></div>
                                                        </div>
                                                        <p className={styles.rebarDescription}>A<sub>s2</sub></p>
                                                    </div>
                                                </div>
                                                <div className={styles.rebarRow}>
                                                    <div className={styles.column}>
                                                        <p className={styles.rebarDescription}>A<sub>s1</sub></p>
                                                        <div className={styles.flex}>
                                                            <div className={styles.rebar}></div>
                                                            <div className={styles.rebar}></div>
                                                            <div className={styles.rebar}></div>
                                                            <div className={styles.rebar}></div>
                                                            <div className={styles.rebar}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={styles.xAxis}>
                                                <p>x</p>
                                            </div>
                                            <div className={styles.yAxis}>
                                                <p>y</p>
                                            </div>
                                        </div>
                                    }
                                    </Fragment>

                                }

                                {
                                    pileShape === 'Kołowy'
                                    ?
                                        dimension &&
                                        <div className={styles.dimension}>
                                            <p>{`${dimension}`}</p>
                                            <div className={styles.lineWrapper}>
                                                <div className={styles.line}></div>
                                                <div className={styles.line}></div>
                                            </div>
                                        </div>
                                    :
                                    width &&
                                    <div className={styles.dimension}>
                                        <p>{`${width}`}</p>
                                        <div className={styles.lineWrapper}>
                                            <div className={styles.line}></div>
                                            <div className={styles.line}></div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}

export default CrossSectionModel