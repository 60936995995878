import { Col, Row } from 'react-bootstrap';
import {
    Modal,
    Table,
    Button,
    Checkbox1,
    SectionTitle,
} from '../../../../../components';
import styles from './index.module.css';

const BlockAccount = ({
    users = [],
    open,
    onHide,
    onChange,
    onSubmit,
    type,
}) => {
    return (
        <Modal
            open={open}
            css={styles.modalOverflow}
            cssDialog={styles.modal}
            onHide={onHide}
            hideButton={false}
            title="Zablokuj/Odblokuj konto użytkownika">
            <div className={styles.spaceBetween}>
                <SectionTitle title="Zablokuj/Odblokuj konto użytkownika" />
            </div>
            {users.length > 0 ? (
                <Table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Nr</th>
                            <th>Imię</th>
                            <th>Nazwisko</th>
                            <th>Email</th>
                            <th>Konto</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((element, index) => {
                            return (
                                <tr key={index}>
                                    <td width={30}>{index + 1}</td>
                                    <td>{element.firstName}</td>
                                    <td>{element.lastName}</td>
                                    <td>{element.email}</td>
                                    <td>
                                        <div
                                            name="blockAccountCheckbox"
                                            className={styles.flexCheckbox}>
                                            <Checkbox1
                                                value={element.blacklisted}
                                                name="blockAccountCheckbox"
                                                label="Zablokuj konto"
                                                css={styles.checkboxWidth}
                                                onChange={() =>
                                                    onChange(element.id)
                                                }
                                            />
                                            <p
                                                name="blockAccountCheckbox"
                                                onClick={onChange}>
                                                Zablokuj konto
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            ) : (
                <p className={styles.noUsersCenter}>
                    Nie wybrano żadnych użytkowników
                </p>
            )}
            {users.length > 0 && (
                <Row className={styles.row}>
                    <Col xs={12}>
                        <div className={styles.rightButton}>
                            <Button
                                variant={'danger'}
                                onClick={() => onSubmit(type)}
                                title={'Zapisz zmiany'}
                            />
                        </div>
                    </Col>
                </Row>
            )}
        </Modal>
    );
};

export default BlockAccount;
