import { useCallback, useState } from 'react';

const FileNameToSave = () => {
    const [fileName, setFileName] = useState({
        state: 'init',
        value: 'Nowy projekt',
    });

    const onLoad = useCallback((fileName) => setFileName(fileName),[]);

    const fileNameToSave = {
        fileName,
        onLoad
    };

    return {
        fileNameToSave,
    };
};

export default FileNameToSave;