import React, { createContext, useState, useCallback } from 'react';
import { withForm } from '../../hoc';
import { roundNumber } from '../../utils';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import config from '../../config/config';
import { roles } from '../../config/ProjectConfig';
import AuthFetch from '../../routes/AuthFetch';
import { useIsMountedState } from '../../hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';

const authContext = createContext({});
const parseJwt = (token) => {
    if (token) {
        const splitToken = token.split('.')[1];

        if (typeof splitToken === 'string') {
            return JSON.parse(
                window.atob(splitToken.replace('-', '+').replace('_', '/')),
            );
        }
    }

    return false;
}

const checkSessionTime = ({
    parseObj,
    isSubscriptionActive = true,
    timeStamp,
    logOut,
    setRenewToken,
}) => {
    if (parseObj.hasOwnProperty('exp') && isSubscriptionActive) {
        if (parseObj.exp - timeStamp < 0) {
            logOut();
        }
        if (parseObj.exp - timeStamp < 120) {
            setRenewToken(true);
        }
    } else {
        logOut();
    }
};
const checkSessionInterval = ({
    role,
    endDate = '',
    setMinutes,
    logOut,
    setToken,
    setUpdateReport,
    setRenewToken,
}) => {
    return setInterval(() => {
        const parseObj = parseJwt(localStorage.getItem('token')) || {};
        const timeStamp = Math.ceil(Date.now() / 1000);
        setToken(localStorage.getItem('token'));
        setUpdateReport(localStorage.getItem('updateReport'));

        if (role === roles[2]) {
            checkSessionTime({ parseObj, logOut, timeStamp, setRenewToken });
        } else {
            const minutes = roundNumber(
                moment(endDate).diff(moment(), 'seconds', true),
                0,
            );
            setMinutes(minutes);

            checkSessionTime({
                isSubscriptionActive: minutes > 0,
                parseObj,
                timeStamp,
                logOut,
                setRenewToken,
            });
        }
    }, 5000);
};

const AuthProvider = ({ actions = {}, children }) => {
    const navigate = useNavigate();
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [updateReport, setUpdateReport] = useState(
        localStorage.getItem('updateReport'),
    );
    const [reportDetails, setReportDetails] = useState({});
    const [minutes, setMinutes] = useState(0);
    const [renewToken, setRenewToken] = useState(false);
    const [id, setId] = useState('');
    const [subscription, setSubscription] = useState({});
    const [userObj, setUserObj] = useState({});
    const [stateChanged, setStateChanged] = useState(false);
    const { current } = useIsMountedState();

    const [logoutState, setLogoutState] = useState(false);

    const [isLoggedOut, setIsLoggedOut] = useState(true);
    const [isLoading, setIsLoading] = useState(true);


    const logIn = (token, user) => {
        localStorage.setItem('token', token.access.token);
        localStorage.setItem('isLoggedIn', true);

        if (user.role !== roles[2]) {
            const days = roundNumber(
                moment(user.subscription.endDate).diff(moment(), 'days', true),
                0,
            );
            const minutes = roundNumber(
                moment(user.subscription.endDate).diff(
                    moment(),
                    'seconds',
                    true,
                ),
                0,
            );

            setSubscription({
                days: days < 0 ? 0 : days,
                type: user.subscription.plan,
                endDate: user.subscription.endDate,
            });
            setMinutes(minutes);
        }

        setUserObj({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            permission: user.role,
            invoice: user.invoice,
        });
        setIsLoggedOut(false);
        setIsLoading(false);
        setLogoutState(false);
        setReportDetails({ ...user.report, logo: user.logo.base64 });
        setToken(token.access.token);
        setId(user._id);
    };

    const logOutAction = useCallback(() => {
        setIsLoggedOut(true);
        setIsLoading(true)
        setLogoutState(true);
        setRenewToken(false);
        setToken('');
        setSubscription({});
        setUserObj({});
        localStorage.removeItem('token');
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('updateReport');
        localStorage.removeItem('kx');
        localStorage.removeItem('vertical');
        localStorage.removeItem('micropiles');
        localStorage.removeItem('compression');
        localStorage.removeItem('cptPile');
        localStorage.removeItem('reinforcement');
        localStorage.removeItem('sheetPileCpt');
        localStorage.removeItem('waling');
    },[]);

    const logOut = useCallback(() => {
        const sessionToken = localStorage.getItem('token');
        if(current){
            if (sessionToken) {
                AuthFetch({
                    method: 'POST',
                    url: '/api/auth/logout',
                    body: {
                        accessToken: sessionToken,
                    }
                })
                .finally(() => {
                    logOutAction();
                    navigate(config.routes.dashboard.logIn);                         
                })
            } else {
                logOutAction();
                navigate(config.routes.dashboard.logIn);  
            }
        }
    },[current, navigate, logOutAction]);

    const nextSession = () => {
        const sessionToken = localStorage.getItem('token');

        actions
            .renewSession({
                body: {
                    accessToken: sessionToken,
                },
                headers: {
                    Authorization: `Bearer ${sessionToken}`,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem('token', response.data.access.token);
                    localStorage.setItem('isLoggedIn', true);
                    setRenewToken(false);
                } else {
                    logOut();
                }
            })
            .catch(() => {
                logOut();
            });
    };

    useDeepCompareEffect(() => {
        let interval;
        if(current){
            const isLoggedIn = localStorage.getItem('isLoggedIn');

            if (token && userObj.permission && isLoggedIn) {
                interval = checkSessionInterval({
                    role: userObj.permission,
                    endDate: subscription.endDate,
                    setMinutes,
                    logOut,
                    setToken,
                    setUpdateReport,
                    setRenewToken,
                });
            }
        }

        return () => clearInterval(interval);
    }, [token, userObj.permission, subscription.endDate, logOut, current]);

    useDeepCompareEffect(() => {
        const sessionToken = localStorage.getItem('token');

        if(current){
            if (sessionToken && !logoutState) {
                AuthFetch({
                    method: 'POST',
                    url: '/api/auth/me',
                    body: {
                        accessToken: sessionToken,
                    }
                })
                .then(response => {
                    if(current){
                        if (response.status === 200) {
                            if (
                                [roles[0], roles[1]].includes(
                                    response.responseData.user.role,
                                )
                            ) {
                                const days = roundNumber(
                                    moment(
                                        response.responseData.user.subscription.endDate,
                                    ).diff(moment(), 'days', true),
                                    0,
                                );
                                setSubscription({
                                    days: days < 0 ? 0 : days,
                                    type: response.responseData.user.subscription.plan,
                                    endDate:
                                        response.responseData.user.subscription.endDate,
                                });
                            }
                            setIsLoggedOut(false);
                            setIsLoading(false);
                            setUserObj({
                                firstName: response.responseData.user.firstName,
                                lastName: response.responseData.user.lastName,
                                email: response.responseData.user.email,
                                permission: response.responseData.user.role,
                                invoice: response.responseData.user.invoice,
                            });
                            setId(response.responseData.user._id);
                            setReportDetails({
                                ...response.responseData.user.report,
                                logo: response.responseData.user.logo.base64,
                            });
                        } else {
                            logOut();
                        }
                    }
                })
                .catch((e) => {
                    logOut();
                });
            } 
        }
    }, [
        current,
        token,
        minutes,
        logoutState,
        reportDetails,
        updateReport,
        logOut
    ]);

    const authObj = {
        logIn,
        nextSession,
        logOut,
    };
    const state = {
        id,
        ...userObj,
        isLoggedOut,
        subscription,
        stateChanged,
        isLoading,
        report: {
            ...reportDetails,
        },
    };
    return (
        <authContext.Provider
            value={{
                ...state,
                ...authObj,
                renewToken,
                setReportDetails,
                setStateChanged: useCallback((bool) => setStateChanged(bool),[]),
            }}>
            {children}
        </authContext.Provider>
    );
};

export { authContext };
export default withForm({
    component: AuthProvider,
    onlyActions: true,
    actions: [
        {
            name: 'renewSession',
            url: '/api/auth/refresh-tokens',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            returnData: true,
        },
    ],
});
