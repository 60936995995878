import React, { Fragment, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { withDefaultApexChart } from '../../../../../hoc';
import { roundNumber, stringToNumber } from '../../../../../utils';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';

const defaultSeries = [
    {
        name: '',
        data: [],
    },
];

const CrossSectionCapacity = ({
    result={},
    circular,
    shape,
    x,
    a2,
    calcResult={},
    chartOptions = {},
}) => {
    const [options, setOptions] = useState(chartOptions);
    const [series, setSeries] = useState(defaultSeries);
    const [isLoaded, setIsLoaded] = useState(false);

    const as1 = circular ? calcResult.as : calcResult.as1; 
    const as2 = circular ? calcResult.as : calcResult.as2;

    useEffect(() => {
        setSeries(() => {
            return [
                {
                    name: 'Mrd',
                    data: result.collectionPositive,
                },
                {
                    name: 'Mrd1',
                    data: result.collectionNegative,
                },
            ];
        });
    },[calcResult.x, as1, as2, shape, result.collectionPositive, result.collectionNegative]);

    useEffect(() => {
        setTimeout(() => 
            setIsLoaded(true)
        )
    },[calcResult.x, as1, as2, shape]);
    
    useDeepCompareEffect(() => {
        setOptions(() => {
            return {
                ...chartOptions,
                legend: {
                    show: false
                },
                subtitle: {
                    ...chartOptions['subtitle'],
                    margin: 30,
                    offsetY: 20,
                    text: result.pileShape === 1 
                        ? 
                        [`As - ${roundNumber(result.as,2)} cm2 `]
                        : 
                        [`As1 - ${roundNumber(result.as1,2)} cm2 (${x > a2 
                            ?
                            result.negativeMoment ? 'ściskane' : 'rozciągane'
                            :
                            'rozciągane'
                        })`, `As2 - ${roundNumber(result.as2,2)} cm2 (${x > a2 
                            ?
                            result.negativeMoment ? 'rozciągane' : 'ściskane'
                            :
                            'rozciągane'
                        })`],
                },
                yaxis: {
                    ...chartOptions['yaxis'],
                    reversed: false,
                },
                xaxis: {
                    ...chartOptions['xaxis'],
                    max: 1.15*Math.ceil(result.maxMrd.x/100)*100,
                    min: 1.15*Math.floor(result.minMrd.x/100)*100,
                    type: 'numeric',
                    title: {
                        ...chartOptions['xaxis']['title'],
                        offsetY: -3,
                    },
                    labels: {
                        style: {
                            fontSize: '14px',
                        },
                        offsetY: 0,
                        formatter: (val) => {
                            return Math.abs(stringToNumber(val.toFixed(0))) === 0 ? '' : val.toFixed(0)
                        }
                    }
                },
                grid: {
                    show: true,
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },   
                    yaxis: {
                        lines: {
                            show: true
                        }
                    }, 
                },
                fill: {
                    ...chartOptions['fill'],
                    colors: ['#ff7f7f', '#318CE7'],
                    opacity: 0.1,
                },
                stroke: {
                    ...chartOptions['stroke'],
                    colors: ['#b20000', '#318CE7'],
                    width: 1,
                },
                dataLabels: {
                    ...chartOptions['dataLabels'],
                    offsetY: 0,
                    offsetX: 0,
                    formatter: function (x, opt) {
                        const series = opt.w.globals.initialSeries[opt.seriesIndex].data;
                        return roundNumber(series[opt.dataPointIndex][0], 2);
                    },
                },
                markers: {
                    size: 0,
                },
                tooltip: {
                    enabled: false
                },
                annotations: {
                    ...chartOptions['annotations'],
                    xaxis: [
                        {
                            x: 0,
                            opacity: 1,
                            strokeDashArray: 0,
                            borderColor: '#ccc',
                            label: {
                                text: 0,
                                position: 'bottom',
                                orientation: 'horizontal',
                                offsetY: 25,
                                style: {
                                    fontSize: '14px',
                                }
                            }
                        },
                    ],
                    points: [
                        ...[
                          ...result.points
                        ] 
                    ]
                },
            };
        });
    },[result.points]);

    return (
        <Fragment>
            {
                isLoaded &&
                <Chart
                    options={options}
                    series={series}
                    type="area"
                    height={'480px'}
                    width={'100%'}
                />
            }            
        </Fragment>
    );
};

export default React.memo(
    withDefaultApexChart(CrossSectionCapacity),
    (prev, next) => stringify(prev) === stringify(next),
);
