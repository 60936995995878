import { defaultNoncohesiveSoilsWeight, noncohesiveMoistures } from "../../config/ProjectConfig";
import greaterThanZero from "../greaterThanZero";
import minMaxArgument from "../minMaxArgument";
import roundNumber from "../roundNumber";

const precision = 1;

const frictionAngleZPo = (id) => roundNumber(7.1429*id + 35.043, precision);
const frictionAnglePrPs = (id) => roundNumber(6.6667*id + 29.6667, precision);
const frictionAnglePdPpi = (id) => roundNumber(5*id + 28, precision);


const frictionAngleGenesisA = (il) => roundNumber(-17.475*il + 25.095, precision);
const frictionAngleGenesisB = (il) => roundNumber(-18.627*il + 22.027, precision);
const frictionAngleGenesisC = (il) => roundNumber(-16.05*il + 18.043, precision);
const frictionAngleGenesisD = (il) => roundNumber(-13.333*il + 13, precision);

const cohesionGenesisA = (il) => roundNumber(21.203*Math.pow(il,2) - 54.251*il + 48.695, precision);
const cohesionGenesisB = (il) => roundNumber(17.774*Math.pow(il,2) - 45.623*il + 39.279, precision);
const cohesionGenesisC = (il) => roundNumber(212.88*Math.pow(il,4) - 421.3*Math.pow(il,3) + 309.06*Math.pow(il,2) - 117.41*il + 30.09, precision);
const cohesionGenesisD = (il) => roundNumber(19.698*Math.pow(il,2) - 60.25*il + 59.333, precision);

const edometricModuleZPo = (id) => roundNumber(46.599*Math.pow(id,2) + 133.37*id + 60.694, precision);
const edometricModulePrPs = (id) => roundNumber(91.497*Math.pow(id,2) + 41.395*id + 36.52, precision);
const edometricModulePdPpi = (id) => roundNumber(47.959*Math.pow(id,2) + 36.259*id + 16.531, precision);

const edometricModuleGenesisA = (il) => roundNumber(-134.38*Math.pow(il,3) + 257.5*Math.pow(il,2) - 191.46*il + 67.2, precision);
const edometricModuleGenesisB = (il) => roundNumber(-130.96*Math.pow(il,3) + 229.44*Math.pow(il,2) - 151.65*il + 49.573, precision);
const edometricModuleGenesisC = (il) => roundNumber(33.161*Math.exp(-2.205*il), precision);
const edometricModuleGenesisD = (il) => roundNumber(29.365*Math.pow(il,2) - 45.803*il + 22.44, precision);

const noncohesiveSoilWeight = (signature, moisture, id, underWater = false) => {
    let densityIndex = 0;
    const moistureIndex = noncohesiveMoistures.findIndex(ele => ele === moisture);

    if(moistureIndex > -1){
        if((id >= 0.68 && id <= 1)) {
            densityIndex = 0;
        } else if((id >= 0.34 && id <= 0.67)) {
            densityIndex = 1;
        } else if((id >= 0 && id <= 0.33)) {
            densityIndex = 2;
        } else {
            return ''
        }

        if(underWater){
            return defaultNoncohesiveSoilsWeight[signature][moistureIndex]['underWater'][densityIndex]
        }

        return defaultNoncohesiveSoilsWeight[signature][moistureIndex]['aboveWater'][densityIndex]
    } else {
        return ''
    }
}
const cohesiveSoilWeight = (weights, il) => {

    if(Array.isArray(weights)){
        if(il < 0){
            return weights[0]
        } else if((il >=0 && il <= 0.25)){
            return weights[1]
        } else if((il >=0.26 && il <= 0.5)){
            return weights[2]
        } else if((il >=0.51 && il <= 1)){
            return weights[3]
        } else {
            return ''
        }
    } else {
        return ''
    }
}
const frictionAngleNoncohesiveSoils = {
    0: (id, arr) => greaterThanZero(minMaxArgument(frictionAngleZPo, Number(id), arr)),
    1: (id, arr) => greaterThanZero(minMaxArgument(frictionAnglePrPs, Number(id), arr)),
    2: (id, arr) => greaterThanZero(minMaxArgument(frictionAnglePdPpi, Number(id), arr)),
};
const edometricModulusNoncohesiveSoils = {
    0: (id, arr) => greaterThanZero(minMaxArgument(edometricModuleZPo, Number(id), arr)),
    1: (id, arr) => greaterThanZero(minMaxArgument(edometricModulePrPs, Number(id), arr)),
    2: (id, arr) => greaterThanZero(minMaxArgument(edometricModulePdPpi, Number(id), arr)),
};

const cohesionByGenesis = {
    A: (il, arr) => greaterThanZero(minMaxArgument(cohesionGenesisA, Number(il), arr)),
    B: (il, arr) => greaterThanZero(minMaxArgument(cohesionGenesisB, Number(il), arr)),
    C: (il, arr) => greaterThanZero(minMaxArgument(cohesionGenesisC, Number(il), arr)), 
    D: (il, arr) => greaterThanZero(minMaxArgument(cohesionGenesisD, Number(il), arr)),
}
const frictionAngleByGenesis = {
    A: (il, arr) => greaterThanZero(minMaxArgument(frictionAngleGenesisA, Number(il), arr)),
    B: (il, arr) => greaterThanZero(minMaxArgument(frictionAngleGenesisB, Number(il), arr)),
    C: (il, arr) => greaterThanZero(minMaxArgument(frictionAngleGenesisC, Number(il), arr)),
    D: (il, arr) => greaterThanZero(minMaxArgument(frictionAngleGenesisD, Number(il), arr)),
}
const edometricModulesByGenesis = {
    A: (il, arr) => greaterThanZero(minMaxArgument(edometricModuleGenesisA, Number(il), arr)),
    B: (il, arr) => greaterThanZero(minMaxArgument(edometricModuleGenesisB, Number(il), arr)),
    C: (il, arr) => greaterThanZero(minMaxArgument(edometricModuleGenesisC, Number(il), arr)),
    D: (il, arr) => greaterThanZero(minMaxArgument(edometricModuleGenesisD, Number(il), arr)),
}
export { frictionAngleNoncohesiveSoils, edometricModulusNoncohesiveSoils, noncohesiveSoilWeight, cohesiveSoilWeight, cohesionByGenesis, frictionAngleByGenesis, edometricModulesByGenesis }