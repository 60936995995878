import {
    faDollarSign,
    faFolderOpen,
    faWallet,
    faUsers,
    faCalculator,
    faKey,
} from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';

import { Col, Row } from 'react-bootstrap';
import { SectionTitle } from '../../../../components';
import SingleElement from './SingleElement';
import styles from './index.module.css';

const Statistics = ({ stats }) => {
    return (
        <Fragment>
            <Row className={styles.row}>
                <Col xs={12}>
                    <SectionTitle title="Statystyki aplikacji" />
                </Col>
                <Col sm={12} md={4}>
                    <SingleElement
                        wrapperTitle={
                            'Liczba wszystkich kont bez kont administratorów'
                        }
                        title={'Liczba użytkowników'}
                        options={[
                            { option: 'Łącznie', name: 'all' },
                            { option: 'Miesiąc', name: 'lastMonth' },
                            { option: 'Tydzień', name: 'lastWeek' },
                        ]}
                        css={'stat1'}
                        values={stats.users}
                        icon={faUsers}
                    />
                </Col>
                <Col sm={12} md={4}>
                    <SingleElement
                        wrapperTitle={'Suma płatności'}
                        title={'Suma płatności'}
                        options={[
                            { option: 'Łącznie', name: 'all', currency: 'pl' },
                            {
                                option: 'Miesiąc',
                                name: 'lastMonth',
                                currency: 'pl',
                            },
                            {
                                option: 'Tydzień',
                                name: 'lastWeek',
                                currency: 'pl',
                            },
                        ]}
                        css={'stat2'}
                        values={stats.payments}
                        icon={faWallet}
                    />
                </Col>
                <Col sm={12} md={4}>
                    <SingleElement
                        wrapperTitle={'Ostatnia płatność'}
                        title={'Ostatnia płatność'}
                        options={[
                            { option: 'Kwota', name: 'amount', currency: 'pl' },
                            { option: 'Data', name: 'date' },
                        ]}
                        css={'stat3'}
                        values={stats.lastPayment}
                        icon={faDollarSign}
                    />
                </Col>
            </Row>
            <Row className={styles.row}>
                <Col sm={12} md={4}>
                    <SingleElement
                        wrapperTitle={'Aktywne subskrypcje'}
                        title={'Aktywne subskrypcje'}
                        css={'stat1'}
                        values={stats.activeSubscriptions}
                        icon={faKey}
                    />
                </Col>
                <Col sm={12} md={4}>
                    <SingleElement
                        wrapperTitle={'Liczna projektów'}
                        title={'Liczba projektów'}
                        css={'stat2'}
                        values={stats.projects}
                        icon={faFolderOpen}
                    />
                </Col>
                <Col sm={12} md={4}>
                    <SingleElement
                        wrapperTitle={'Liczba obliczeń'}
                        title={'Liczba obliczeń'}
                        css={'stat3'}
                        values={stats.calculations}
                        icon={faCalculator}
                    />
                </Col>
            </Row>
        </Fragment>
    );
};

export default Statistics;
