import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './index.module.css';
import useDeepCompareEffect from 'use-deep-compare-effect';

const SingleElement = ({
    title,
    wrapperTitle,
    icon,
    options=[],
    values,
    css = '',
}) => {
    const [value, setValue] = useState(0);
    const [name, setName] = useState('');
    const [currency, setCurrency] = useState('');

    useDeepCompareEffect(() => {
        if (values) {
            if (Array.isArray(options) && options.length > 0) {
                setValue(values[options[0].name]);
                setName(options[0].name);

                setCurrency(options[0].currency);
            } else {
                setValue(values);
            }
        }
    }, [values, options]);

    const onChange = (name, currency) => {
        setValue(values[name]);
        setCurrency(currency);
        setName(name);
    };

    return (
        <div
            className={`${styles.wrapper} backgroundAround statsWrapper`}
            title={wrapperTitle}>
            <Row className={styles.row}>
                <Col className={styles.col} xs={8}>
                    <div>
                        <h4 className={styles.h4}>{title}</h4>
                        <div className={styles.optionsWrapper}>
                            {Array.isArray(options) &&
                                options.map((ele, idx) => {
                                    return (
                                        <p
                                            onClick={() =>
                                                onChange(ele.name, ele.currency)
                                            }
                                            name={ele.name}
                                            key={idx}
                                            style={{
                                                textDecoration: `${
                                                    name === ele.name
                                                        ? 'underline'
                                                        : 'none'
                                                }`,
                                            }}>
                                            {ele.option}
                                        </p>
                                    );
                                })}
                        </div>
                        <h2 className={styles.h2}>
                            {value}
                            {currency === 'pl' ? (
                                <span className={styles.currency}>zł</span>
                            ) : (
                                ''
                            )}
                        </h2>
                    </div>
                </Col>
                {icon && (
                    <Col className={`${styles.col} ${styles.flexRight}`} xs={4}>
                        <div className={`${styles.icon} stat ${css}`}>
                            <FontAwesomeIcon icon={icon} />
                        </div>
                    </Col>
                )}
            </Row>
        </div>
    );
};

export default SingleElement;
