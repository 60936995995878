import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment  } from 'react'; 
import styles from './index.module.css';


const CheckboxInput = ({ onChange, altLabel, label, title, name, value, children, css='', ...rest }) => {
    return ( 
        <div className={styles.wrapper}>
            {   title &&
                <div className={styles.titleWrapper}>
                    <label className={styles.title}>{title}</label>

                    {
                        children &&
                        <Fragment>
                            <FontAwesomeIcon className={styles.infoIcon} icon={faQuestionCircle}/>
                            <div className={`${styles.info} defaultBox`}>
                                {children}
                            </div> 
                        </Fragment>
                    }
                </div>
            }
            
            <div title={label} className={`${styles.checkboxWrapper} ${css} custom-checkbox`}>
                <input className={styles.checkbox} type='checkbox' name={name} checked={value} onChange={onChange} {...rest}/>
                {
                    value ?
                    <label className={styles.afterLabel}>{altLabel}</label>
                    :
                    <label className={styles.label}>{altLabel}</label>
                }
            </div>
        </div>
    )
}

export default CheckboxInput