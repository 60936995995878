import React, { Fragment, useEffect, useRef, useState } from 'react';
import { isNumber, roundNumber, setSoilColor } from '../../../../../utils';
import SoilLayer from './SoilLayer';
import styles from './index.module.css';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';

const MicropileChart = ({
    results = [],
    soilTable = [],
    calculationParameters,
    slideLineArr,
    isDeepPlate,
    title, 
    intersectionPoints,
}) => {
    const soilProfileRef = useRef(null);
    const micropileRef = useRef(null);
    const [soilLayer, setSoilLayer] = useState([]);
    const [rightWaterHeight, setRightWaterHeight] = useState('');
    const [leftWaterHeight, setLeftWaterHeight] = useState('');
    const [profileWallLength, setProfileWallLength] = useState('');
    const [profileMicroPileLength, setProfileMicroPileLength] = useState({
        total: '',
        bond: '',
    });
    const [profilePileHeadSpot, setProfilePileHeadSpot] = useState('');
    const [micropileAngle, setMicropileAngle] = useState(0);
    const [slideLinePx, setSlideLinePx] = useState({});
    const [deepSlideLinePx, setDeepSlideLinePx] = useState([]);
    const [maxHeight, setMaxHeight] = useState('');
    const [maxWidth, setMaxWidth] = useState('');
    const [offsetHeight, setOffsetHeight] = useState('');
    const [offsetWidth, setOffsetWidth] = useState('');
    const [excavationBase, setExcavationBase] = useState('');
    const [axisModelScale, setAxisModelScale] = useState([]);
    const [excavationWidthPx, setExcavationWidthPx] = useState('');
    const [micropileOffset, setMicropileOffset] = useState({
        width: 0,
        height: 0,
    });
    const [currentMicropileLength, setCurrentMicropileLength] = useState({
        total: 0,
        bond: 0,
        free: 0,
        head: 0,
    });

    const slideLineArrSlipPlate = slideLineArr.slipPlate;
    const slideLineArrDeepPlate = slideLineArr.deepPlate;
    const intersectionPointsDeepPlate = intersectionPoints.deepPlate;
    const intersectionPointsSlipPlate = intersectionPoints.slipPlate;

    useDeepCompareEffect(() => {
        setCurrentMicropileLength(() => {
            if (results[1]) {
                return {
                    total: roundNumber(
                        results[1].freeLength + results[1].totalBondLength,
                        2,
                    ),
                    bond: roundNumber(results[1].totalBondLength, 2),
                    free: roundNumber(results[1].freeLength, 2),
                    head: roundNumber(results[1].headLength, 2),
                };
            } else {
                return {
                    total: 0,
                    bond: 0,
                    free: 0,
                    head: 0,
                };
            }
        });
    }, [results]);

    useDeepCompareEffect(() => {
        const slipAxisHorizontal = Math.max(
            ...slideLineArrSlipPlate.map((ele) => ele.topLeft),
        );
        const deepAxisHorizontal = isDeepPlate
            ? Math.max(...slideLineArrDeepPlate.map((ele) => ele.topLeft))
            : 0;

        const modalWidth = Math.max(
            currentMicropileLength.total,
            slipAxisHorizontal,
            deepAxisHorizontal,
        );
        const layersHeight = soilTable.map((ele) => ele.height);
        const maxValueHeight = Math.max(...layersHeight);

        setAxisModelScale(
            Array(10)
                .fill(modalWidth / 10)
                .reduce((acc, ele, idx) => [...acc, ele * (idx + 1)], []),
        );
        setMaxWidth(modalWidth);
        setMaxHeight(maxValueHeight);

        if (soilProfileRef.current) {
            setOffsetHeight(soilProfileRef.current.offsetHeight);
            setOffsetWidth(soilProfileRef.current.offsetWidth);
        }
    }, [
        slideLineArrSlipPlate,
        slideLineArrDeepPlate,
        currentMicropileLength.total,
        isDeepPlate,
        soilTable,
    ]);

    useEffect(() => {
        setExcavationWidthPx(offsetWidth * 0.4);
    }, [offsetWidth]);

    useDeepCompareEffect(() => {
        if (soilProfileRef.current) {
            const isMaxHeightCorrect = !isNumber(maxHeight);
            const leftZwg = isNumber(calculationParameters.leftZwg) ? calculationParameters.leftZwg : 0;

            setExcavationBase(
                (offsetHeight * calculationParameters.excavationBase) /
                    maxHeight,
            );
            setRightWaterHeight(
                isMaxHeightCorrect
                    ? ''
                    : (offsetHeight * calculationParameters.rightZwg) /
                          maxHeight,
            );
            setLeftWaterHeight(
                isMaxHeightCorrect
                    ? ''
                    : (offsetHeight * leftZwg) /
                          maxHeight,
            );

            setSoilLayer(
                soilTable.map(
                    ({ name, height, density, plasticity }, i, arr) => {
                        return (
                            <SoilLayer
                                key={i}
                                backgroundColor={setSoilColor({
                                    id: density,
                                    il: plasticity,
                                })}
                                name={name}
                                groundLevel={calculationParameters.groundLevel}
                                height={
                                    isNumber(maxHeight) &&
                                    maxHeight !== 0 &&
                                    isNumber(offsetHeight)
                                        ? offsetHeight *
                                          ((i === 0
                                              ? height
                                              : height - arr[i - 1].height) /
                                              maxHeight)
                                        : 0
                                }
                                layerHeight={height}
                            />
                        );
                    },
                ),
            );
        }
    }, [
        offsetHeight,
        maxHeight,
        calculationParameters.excavationBase,
        calculationParameters.rightZwg,
        calculationParameters.leftZwg,
        calculationParameters.groundLevel,
        soilTable,
    ]);

    useDeepCompareEffect(() => {
        const wallBaseSpot =
            calculationParameters.sheetPileSpot > maxHeight
                ? maxHeight
                : calculationParameters.sheetPileSpot;

        const convertMetersToPx = (ele) => {
            return {
                ...ele,
                topLeft:
                    ((offsetWidth - excavationWidthPx) * ele.topLeft) /
                        maxWidth +
                    excavationWidthPx,
                downLeft:
                    ((offsetWidth - excavationWidthPx) * ele.downLeft) /
                        maxWidth +
                    excavationWidthPx,
                bottom: (offsetHeight * ele.bottom) / maxHeight,
                top: (offsetHeight * ele.top) / maxHeight,
            };
        };
        setSlideLinePx(slideLineArrSlipPlate.map(convertMetersToPx));
        setDeepSlideLinePx(slideLineArrDeepPlate.map(convertMetersToPx));

        setProfilePileHeadSpot(
            (offsetHeight * calculationParameters.micropileHeadSpot) /
                maxHeight,
        );
        setProfileWallLength(
            (offsetHeight *
                (wallBaseSpot > maxHeight ? maxHeight : wallBaseSpot)) /
                maxHeight,
        );
        setMicropileAngle(() => {
            const defaultXLength = (offsetWidth - excavationWidthPx) / maxWidth;
            const defaultYLength =
                (Math.tan(
                    (calculationParameters.micropileAngle * Math.PI) / 180,
                ) *
                    offsetHeight) /
                maxHeight;
            const defaultResults =
                (Math.atan(defaultYLength / defaultXLength) * 180) / Math.PI;

            if (calculationParameters.isDeepPlate) {
                const xLength =
                    (intersectionPointsDeepPlate[0] *
                        (offsetWidth - excavationWidthPx)) /
                    maxWidth;
                const yLength =
                    ((intersectionPointsDeepPlate[1] -
                        calculationParameters.micropileHeadSpot) *
                        offsetHeight) /
                    maxHeight;

                const results = (Math.atan(yLength / xLength) * 180) / Math.PI;

                if (yLength <= 0 || !isNumber(results)) {
                    return defaultResults;
                } else {
                    return results;
                }
            } else {
                const xLength =
                    (intersectionPointsSlipPlate[0] *
                        (offsetWidth - excavationWidthPx)) /
                    maxWidth;
                const yLength =
                    ((intersectionPointsSlipPlate[1] -
                        calculationParameters.micropileHeadSpot) *
                        offsetHeight) /
                    maxHeight;

                const results = (Math.atan(yLength / xLength) * 180) / Math.PI;

                if (yLength <= 0 || !isNumber(results)) {
                    return defaultResults;
                } else {
                    return results;
                }
            }
        });
    }, [
        calculationParameters.micropileHeadSpot,
        calculationParameters.micropileAngle,
        calculationParameters.isDeepPlate,
        calculationParameters.sheetPileSpot,
        offsetHeight,
        offsetWidth,
        maxHeight,
        maxWidth,
        excavationWidthPx,
        slideLineArrDeepPlate,
        slideLineArrSlipPlate,
        intersectionPointsDeepPlate,
        intersectionPointsSlipPlate,
    ]);

    useEffect(() => {
        const micropileLength = currentMicropileLength.total;
        const bondLength = currentMicropileLength.bond;

        const x =
            (micropileLength *
                Math.cos(
                    (calculationParameters.micropileAngle * Math.PI) / 180,
                ) *
                (offsetWidth - excavationWidthPx)) /
            maxWidth;
        const h =
            (micropileLength *
                Math.sin(
                    (calculationParameters.micropileAngle * Math.PI) / 180,
                ) *
                offsetHeight) /
            maxHeight;

        const xBond =
            (bondLength *
                Math.cos(
                    (calculationParameters.micropileAngle * Math.PI) / 180,
                ) *
                (offsetWidth - excavationWidthPx)) /
            maxWidth;
        const hBond =
            (bondLength *
                Math.sin(
                    (calculationParameters.micropileAngle * Math.PI) / 180,
                ) *
                offsetHeight) /
            maxHeight;

        setProfileMicroPileLength({
            total: Math.sqrt(Math.pow(x, 2) + Math.pow(h, 2)),
            bond: Math.sqrt(Math.pow(xBond, 2) + Math.pow(hBond, 2)),
        });
    }, [
        micropileAngle,
        currentMicropileLength.total,
        currentMicropileLength.bond,
        calculationParameters.micropileAngle,
        excavationWidthPx,
        maxWidth,
        offsetWidth,
        offsetHeight,
        maxHeight,
    ]);

    useEffect(() => {
        if (micropileRef.current) {
            setMicropileOffset({
                width: micropileRef.current.offsetWidth,
                height: micropileRef.current.offsetHeight / 2,
            });
        }
    }, [micropileAngle]);

    return (
        <Fragment>
            <div className={styles.chartWrapper}>
                <div className={styles.title}>
                    <h2>{title}</h2>
                </div>
                <div className={styles.groundLevel}>
                    <span className={styles.spot}>
                        0.00{' '}
                        {`${
                            isNumber(calculationParameters.groundLevel)
                                ? `(${calculationParameters.groundLevel.toFixed(
                                      2,
                                  )} m.n.p.m.)`
                                : ''
                        }`}
                    </span>
                </div>

                <div className={styles.wrapper} ref={soilProfileRef}>
                    <div
                        className={styles.horizontalAxis}
                        style={{
                            left: `${excavationWidthPx}px`,
                            width: `${offsetWidth - excavationWidthPx}px`,
                        }}>
                        {axisModelScale.slice(0, -1).map((ele, idx) => {
                            return (
                                <span
                                    key={idx}
                                    className={styles.axisCoord}
                                    style={{
                                        left: `${
                                            ((offsetWidth - excavationWidthPx) /
                                                maxWidth) *
                                            ele
                                        }px`,
                                    }}>
                                    <p className={styles.chartValue}>
                                        {roundNumber(ele, 1)}
                                    </p>
                                </span>
                            );
                        })}

                        <span className={styles.caret}>
                            {<p className={styles.axisDescription}>[m]</p>}
                        </span>
                    </div>
                    {Array.isArray(slideLinePx) &&
                        slideLinePx.every((ele) =>
                            Object.values(ele).every((ele) => isNumber(ele)),
                        ) && (
                            <svg className={styles.slideSvg}>
                                {slideLinePx.map((ele, idx) => {
                                    return (
                                        <polyline
                                            key={idx}
                                            className={styles.slidePolyline}
                                            points={`${ele.downLeft},${ele.bottom} ${ele.topLeft}, ${ele.top}`}></polyline>
                                    );
                                })}
                            </svg>
                        )}
                    {isDeepPlate &&
                        deepSlideLinePx.every((ele) =>
                            Object.values(ele).every((ele) => isNumber(ele)),
                        ) && (
                            <svg className={styles.slideSvg}>
                                {deepSlideLinePx.map((ele, idx) => {
                                    return (
                                        <polyline
                                            key={idx}
                                            strokeDasharray="10,10"
                                            className={styles.slidePolyline}
                                            points={`${ele.downLeft},${ele.bottom} ${ele.topLeft}, ${ele.top}`}></polyline>
                                    );
                                })}
                            </svg>
                        )}
                    <div
                        className={styles.excavationWrapper}
                        style={{ 
                            width: `${excavationWidthPx}px`,
                            height: `${excavationBase}px`
                        }}>
                        {isNumber(calculationParameters.excavationBase) &&
                            isNumber(profileWallLength) &&
                            profileWallLength > excavationBase && (
                                <div
                                    className={styles.excavation}
                                    style={{ 
                                        top: `${leftWaterHeight}px`,
                                        height: `${excavationBase - leftWaterHeight}px`
                                    }}>
                                    <span className={styles.spotProfile}>
                                        -
                                        {calculationParameters.excavationBase.toFixed(
                                            2,
                                        )}{' '}
                                        {`${
                                            isNumber(
                                                calculationParameters.groundLevel,
                                            )
                                                ? `(${roundNumber(
                                                      calculationParameters.groundLevel -
                                                          calculationParameters.excavationBase,
                                                      2,
                                                  ).toFixed(2)})`
                                                : ''
                                        }`}
                                    </span>
                                </div>
                            )}
                        {isNumber(calculationParameters.leftZwg) &&
                            isNumber(offsetHeight) &&
                            offsetHeight > leftWaterHeight && (
                                <div
                                    className={styles.zwg}
                                    style={{ top: `${leftWaterHeight}px` }}>
                                    <div className={styles.zwgSpot}></div>
                                    <span className={styles.zwgLevel}>
                                        -
                                        {calculationParameters.leftZwg.toFixed(
                                            2,
                                        )}{' '}
                                        {`${
                                            isNumber(
                                                calculationParameters.groundLevel,
                                            )
                                                ? `(${roundNumber(
                                                      calculationParameters.groundLevel -
                                                          calculationParameters.leftZwg,
                                                      2,
                                                  ).toFixed(2)})`
                                                : ''
                                        }`}
                                    </span>
                                </div>
                            )}
                        {isNumber(calculationParameters.sheetPileSpot) && (
                            <div
                                className={styles.sheetPile}
                                style={{
                                    left: `${excavationWidthPx}px`,
                                    height: `${profileWallLength}px`,
                                }}>
                                <span className={styles.wallBaseSpot}>
                                    -
                                    {calculationParameters.sheetPileSpot.toFixed(
                                        2,
                                    )}{' '}
                                    {`${
                                        isNumber(
                                            calculationParameters.groundLevel,
                                        )
                                            ? `(${roundNumber(
                                                  calculationParameters.groundLevel -
                                                      calculationParameters.sheetPileSpot,
                                                  2,
                                              ).toFixed(2)})`
                                            : ''
                                    }`}
                                </span>
                            </div>
                        )}
                    </div>
                    {
                        <div
                            ref={micropileRef}
                            className={styles.pileWrapper}
                            style={{
                                top: `${
                                    profilePileHeadSpot - micropileOffset.height
                                }px`,
                                left: `${
                                    excavationWidthPx - micropileOffset.width
                                }px`,
                                transform: `rotate(${micropileAngle || 0}deg)`,
                            }}>
                            <div className={styles.microHead}>
                                <span
                                    className={styles.pileHeadSpot}
                                    style={{
                                        transform: `rotate(-${
                                            micropileAngle || 0
                                        }deg)`,
                                    }}>
                                    -
                                    {isNumber(
                                        calculationParameters.micropileHeadSpot,
                                    )
                                        ? `${calculationParameters.micropileHeadSpot.toFixed(
                                              2,
                                          )} ${
                                              isNumber(
                                                  calculationParameters.groundLevel,
                                              )
                                                  ? `(${roundNumber(
                                                        calculationParameters.groundLevel -
                                                            calculationParameters.micropileHeadSpot,
                                                        2,
                                                    ).toFixed(2)})`
                                                  : ''
                                          }`
                                        : ''}
                                </span>
                                <div className={styles.topHead}></div>
                                <div className={styles.bottomHead}></div>
                            </div>
                            <div
                                className={styles.pile}
                                style={{
                                    width: `${profileMicroPileLength.total}px`,
                                }}>
                                <div className={styles.steelRod}>
                                    <div className={styles.rod}></div>
                                </div>
                                <div
                                    className={styles.freeLength}
                                    style={{
                                        width: `${
                                            profileMicroPileLength.total -
                                            profileMicroPileLength.bond
                                        }px`,
                                    }}></div>
                                <div
                                    className={styles.bond}
                                    style={{
                                        width: `${profileMicroPileLength.bond}px`,
                                    }}></div>
                                <div
                                    className={styles.angleDescription}
                                    style={{
                                        transform: `rotate(-${
                                            micropileAngle || 0
                                        }deg)`,
                                    }}>
                                    <span>
                                        {calculationParameters.micropileAngle}
                                        &#176;
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                    {isNumber(calculationParameters.rightZwg) &&
                        isNumber(offsetHeight) &&
                        offsetHeight > rightWaterHeight && (
                            <div
                                className={styles.zwgRight}
                                style={{
                                    left: `${excavationWidthPx}px`,
                                    width: `calc(100% - ${excavationWidthPx}px)`,
                                    top: `${rightWaterHeight}px`,
                                }}>
                                {
                                    calculationParameters.rightZwg !== calculationParameters.leftZwg &&
                                    <Fragment>
                                        <div className={styles.zwgRightSpot}></div>
                                        <span className={styles.zwgRightLevel}>
                                            -{calculationParameters.rightZwg.toFixed(2)}{' '}
                                            {`${
                                                isNumber(
                                                    calculationParameters.groundLevel,
                                                )
                                                    ? `(${roundNumber(
                                                        calculationParameters.groundLevel -
                                                            calculationParameters.rightZwg,
                                                        2,
                                                    ).toFixed(2)})`
                                                    : ''
                                            }`}
                                        </span>                                        
                                    </Fragment>
                                }
                            </div>
                        )}
                    <div>
                        {[...soilLayer]}
                    </div>
                </div>
            </div>
            <div className={styles.capacityDescription}>
                <p>
                    Kąt nachylenia mikropala:{' '}
                    <span className={styles.value}>
                        {calculationParameters.micropileAngle}&#176;
                    </span>
                </p>
                <p>
                    Projektowana siła obliczeniowa:{' '}
                    <span className={styles.value}>{results[1].axisLoad}kN</span>
                </p>
                <p>
                    Osiągnięta nośność obliczeniowa:{' '}
                    <span className={styles.value}>
                        {results[1].totalCapacity}kN
                    </span>
                </p>
                <p>
                    Długość odcinka nośnego/buławy:{' '}
                    <span className={styles.value}>
                        {results[1].totalBondLength}m
                    </span>
                </p>

                {!calculationParameters.isSteelRod && (
                    <Fragment>
                        <p>
                            Długość całkowita mikropala:{' '}
                            <span className={styles.value}>{roundNumber(results[1].freeLength + results[1].totalBondLength,2)}m</span>
                        </p>
                    </Fragment>
                )}

                {calculationParameters.isSteelRod && (
                    <Fragment>
                        <p>
                            Długość zakotwienia głowicy:{' '}
                            <span className={styles.value}>
                                {results[1].headLength}m
                            </span>
                        </p>
                        <p>
                            Długość całkowita mikropala:{' '}
                            <span className={styles.value}>
                                {roundNumber(
                                    results[1].totalBondLength +
                                        results[1].freeLength +
                                        results[1].headLength,
                                    2,
                                )}
                                m
                            </span>
                        </p>
                    </Fragment>
                )}


                {results[1].totalElongation && (
                    <Fragment>
                        {isNumber(results[1].totalElongation[0].concreteVolume) && (
                            <p>
                                Objętość zaczynu cementowego:{' '}
                                <span className={styles.value}>
                                    {roundNumber(
                                        results[1].totalElongation[0].concreteVolume,
                                        2,
                                    )}
                                    m<sup>3</sup>
                                </span>
                            </p>
                        )}
                    </Fragment>
                )}
                {!results[1].veryfied && (
                    <Fragment>
                        <p className={styles.underline}>UWAGA: </p>
                        <p>
                            Nie osiągnięto wymaganej nośności mikropala, zbyt krótki
                            profil gruntowy
                        </p>
                    </Fragment>
                )}                
            </div>

        </Fragment>
    );
};

export default React.memo(
    MicropileChart,
    (prev, next) => stringify(prev) === stringify(next),
);
