import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { Fragment, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    Button,
    ConfirmModal,
    InputFile,
    SectionTitle,
} from '../../../components';
import { isNumber, isString, roundNumber } from '../../../utils';
import styles from './index.module.css';

const ReportLogo = ({ state, onChange, onSubmit, onDelete }) => {
    const [open, setOpen] = useState({});

    const onOpen = (e) =>
        setOpen((state) => ({
            ...state,
            [e.currentTarget.name]: true,
        }));
    const onHide = () => setOpen({});

    return (
        <Fragment>
            <div className={styles.wrapper}>
                <SectionTitle title="Logo drukowane w raportach pdf" />
                <div className={`backgroundAround`}>
                    <Row>
                        <Col
                            xs={12}
                            sm={{ span: 10, offset: 1 }}
                            md={{ span: 8, offset: 2 }}
                            className={styles.col}>
                            <div className={styles.imageWrapper}>
                                {isString(state.logoImg.name) &&
                                state.logoImg.name.length > 0 ? (
                                    <p>
                                        Nazwa pliku: {state.logoImg.name}{' '}
                                        <span className={styles.italic}>
                                            {isNumber(state.logoImg.size) && (
                                                <Fragment>
                                                    (
                                                    {roundNumber(
                                                        state.logoImg.size /
                                                            1000,
                                                        0,
                                                    )}
                                                    kB)
                                                </Fragment>
                                            )}
                                        </span>
                                    </p>
                                ) : (
                                    <p className={styles.italic}>
                                        Nie wybrano żadnego pliku
                                    </p>
                                )}
                                {
                                    state.logoImg.base64
                                    ?
                                    <img
                                        className={styles.avatar}
                                        alt="Logo raportów pdf"
                                        src={state.logoImg.base64}
                                    />
                                    :
                                    <p>Nie dodano loga do raportów pdf</p>
                                }

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className={styles.col}>
                            <div className={styles.flex}>
                                <div className={styles.inputSpace}>
                                    <InputFile
                                        onChange={onChange}
                                        name="logoImage"
                                        type="file"
                                        required
                                    />
                                    {state.isLogo && (
                                        <div>
                                            <Button
                                                icon={faTrashAlt}
                                                onClick={onOpen}
                                                css={'default-button-style'}
                                                name="deleteLogo"
                                                altTitle="Usuń logo"
                                                title="Usuń logo"
                                            />
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <Button
                                        onClick={onSubmit}
                                        css={'default-button-style'}
                                        name="saveChanges"
                                        altTitle="Zapisz zmiany"
                                        title="Zapisz zmiany"
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <ConfirmModal
                open={open.deleteLogo}
                onHide={onHide}
                onConfirm={onDelete}
                title="Usuń logo">
                <p>
                    Jesteś pewny, że chcesz usunąć logo drukowane w raportach
                    pdf?
                </p>
            </ConfirmModal>
        </Fragment>
    );
};

export default ReportLogo;
