import { Table } from "react-bootstrap"
import round from "../../../../../utils/roundNumber";
import styles from './index.module.css';

const GroupPileDetails = ({ currentLength, pileHeadSpot, parameters=[] }) => {

    return (
        <div className={styles.wrapper}>
            <div className={styles.margins}>
                <h2>Nośność pala na wciskanie zgodnie z PN-83/B-2482 - pal w grupie</h2>                
            </div>
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th>Nr<br></br>[-]</th>
                        <th>Z<br></br>[m.p.p.t.]</th>
                        <th>m<sub>1</sub><br></br>[-]</th>
                        <th>m<sub>1w</sub><br></br>[-]</th>
                        <th>m<sub>2</sub><br></br>[-]</th>
                        <th>m<sub>n</sub><br></br>[-]</th>
                        <th>N<sub>tfz</sub><br></br>[kN/pal]</th>
                        <th>N<sub>tgr</sub><br></br>[kN]</th>
                        <th>N<sub>tfz</sub> &gt; N<sub>tgr</sub><br></br>[-]</th>
                        <th>N<sub>twfz</sub><br></br>[kN/pal]</th>
                        <th>N<sub>twgr</sub><br></br>[kN]</th>
                        <th>N<sub>twfz</sub> &gt; N<sub>twgr</sub><br></br>[-]</th>
                    </tr>
                </thead>
                <tbody>
                    {parameters.map((element, index) => {
                        return (
                            <tr key={index} style={{ backgroundColor: round(pileHeadSpot + currentLength,2) === round(element.z,2) ? '#ddd' : 'transparent' }}>
                                <td>{index+1}</td>
                                <td>{round(element.z,2)}</td>
                                <td>{round(element.m1,2)}</td>
                                <td>{round(element.m1w,2)}</td>
                                <td>{round(element.m2,2)}</td> 
                                <td>{round(element.mn,2)}</td>
                                <td>{round(element.ntfz,2)}</td>
                                <td>{round(element.ntgr,2)}</td>
                                <td>{element.ntfz > element.ntgr ? 'ok' : 'niespełniony'}</td>
                                <td>{round(element.ntwfz,2)}</td>
                                <td>{round(element.nwgr,2)}</td>
                                <td>{element.ntwfz > element.nwgr ? 'ok' : 'niespełniony'}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <p className={styles.underline}>Oznaczenia:</p>
            <p>Z - <span className={styles.value}>rzędna zagłębienia pala poniżej poziomu terenu</span></p>
            <p>m<sub>1</sub> - <span className={styles.value}>współczynnik zależny od rozstawu pali (nachodzenia stref naprężeń)</span></p>
            <p>m<sub>1w</sub> - <span className={styles.value}>współczynnik zależny od rozstawu pali wycąganych (nachodzenia stref naprężeń)</span></p>
            <p>m<sub>2</sub> - <span className={styles.value}>współczynnik częściowy dla pali wbijanych w piaski luźne</span></p>
            <p>m<sub>n</sub> - <span className={styles.value}>współczynnik redukcyjny tarcie negatywne gruntu</span></p>
            <p>N<sub>tfz</sub> - <span className={styles.value}>nośność na wciskanie fundamentu zastępczego</span></p>    
            <p>N<sub>tgr</sub> - <span className={styles.value}>nośność na wciskanie pala w grupie</span></p>
            <p>N<sub>twfz</sub> - <span className={styles.value}>nośność na wyciąganie fundamentu zastępczego</span></p>
            <p>N<sub>twgr</sub> - <span className={styles.value}>nośność na wyciąganie pala w grupie</span></p>       
        </div>
    )
}

export default GroupPileDetails