import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { withDefaultApexChart } from '../../../../../hoc';
import { chartsMaxYTick, roundNumber } from '../../../../../utils';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';

const defaultSeries = [
    {
        name: '',
        data: [],
    },
];

const MomentChart = ({
    dataToChart: { bendingMoments = [] },
    chartOptions = {},
}) => {
    const [options, setOptions] = useState(chartOptions);
    const [series, setSeries] = useState(defaultSeries);

    useDeepCompareEffect(() => {
        const maxMoment = Math.max(...bendingMoments);
        const minMoment = Math.min(...bendingMoments);

        setSeries(() => {
            return [
                {
                    name: 'Z',
                    data: [
                        ...bendingMoments.reduce((acc, ele, index) => {
                            return [...acc, [ele, index * 0.1]];
                        }, []),
                    ],
                },
            ];
        });
        setOptions(() => {
            const maxZ = Math.ceil(
                Math.max(...series[0].data.map((ele) => ele[1])),
            );
            const maxVal = Math.max(Math.abs(maxMoment), Math.abs(minMoment));

            const { maxNum, maxTick } = chartsMaxYTick(maxZ);

            return {
                ...chartOptions,
                subtitle: {
                    ...chartOptions['subtitle'],
                    text: `Mmax - ${roundNumber(
                        maxMoment,
                        2,
                    )} kNm, Mmin - ${roundNumber(minMoment, 2)}kNm`,
                },
                yaxis: {
                    ...chartOptions['yaxis'],
                    tickAmount: maxTick,
                    max: maxNum,
                },
                xaxis: {
                    ...chartOptions['xaxis'],
                    max: 1.2 * maxVal,
                    min: -1.2 * maxVal,
                    tickAmount: 10,
                },
                fill: {
                    ...chartOptions['fill'],
                    colors: ['#ff7f7f'],
                    opacity: 0.65,
                },
                stroke: {
                    ...chartOptions['stroke'],
                    colors: ['#b20000'],
                    width: 1,
                },
                dataLabels: {
                    ...chartOptions['dataLabels'],
                    offsetY: 0,
                    offsetX: 0,
                    formatter: function (x, opt) {
                        const series = opt.w.globals.initialSeries[0].data;
                        return roundNumber(series[opt.dataPointIndex][0], 2);
                    },
                },
                markers: {
                    size: 0,
                },
                tooltip: {
                    enabled: false,
                },
                annotations: {
                    ...chartOptions['annotations'],
                    xaxis: [
                        {
                            x: 0,
                            opacity: 1,
                            strokeDashArray: 0,
                            borderColor: '#000',
                        },
                    ],
                },
            };
        });
    }, [chartOptions, series, bendingMoments]);

    return (
        <Chart
            options={options}
            series={series}
            type="area"
            height={'350px'}
            width={'100%'}
        />
    );
};

export default React.memo(
    withDefaultApexChart(MomentChart),
    (prev, next) => stringify(prev) === stringify(next),
);
