import React from 'react';
import styled from 'styled-components';
import { Form, Modal, Button } from 'react-bootstrap';
import { AlertMessage2, Email } from '../../../components';
import { withForm } from '../../../hoc';

const ModalBootstrap = styled(Modal)`
    & .modal-dialog {
        padding-top: 200px;
        max-width: 450px;
        margin-top: 0;
    }
`;
const ModalWrapper = styled.div`
    & .modal-title {
        font-size: 15px;
    }
    & .modal-footer {
        border-top: none;
        display: block;
    }
    & button {
        float: right;
        box-shadow: none !important;
    }
    & .modal-body {
        padding: 15px 15px 0 15px;
    }
    & .modal-header {
        padding: 10px 15px;
        border-bottom: 1px solid #999;
        align-content: center;
        align-items: center;

        & div {
            font-size: 15px !important;
            font-weight: 500;
        }
        & .btn-close {
            outline: none !important;
            color: #333;
            font-size: 15px;
            padding: 0 !important;
            margin: 0 !important;
        }
    }
`;
const FloatWrapper = styled.div`
    overflow: hidden;
    margin-right: 0;

    & button {
        background-color: #1b3e6e;
        border-color: #1b3e6e;
        display: block;
        width: 100%;
        border-radius: 20px;
        transition: all 0.5s ease;

        &:active {
            background-color: #1b3e6e !important;
            border-color: #1b3e6e !important;
        }
        &:hover {
            background-color: #1b3e90;
            border-color: #1b3e90;
        }
        &:focus {
            background-color: #1b3e6e;
            border-color: #1b3e6e;
        }
    }
`;
const SPAN = styled.span`
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 14px;
    color: #999;
    display: block;
`;

const ForgotPasswordModal = ({
    formState,
    messageState = {},
    onChange,
    actions = {},
    onSubmit,
    open,
    onHide,
}) => {
    const onSubmitForm = (e) => {
        e.preventDefault();

        onSubmit((formData) => {
            actions.forgotPassword({
                body: formData,
            });
        });
    };

    return (
        <ModalBootstrap show={open} onHide={onHide}>
            <ModalWrapper>
                <Form onSubmit={onSubmitForm} noValidate>
                    <Modal.Header closeLabel='' closeButton>
                        <Modal.Title>Zmień aktualne hasło</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Email
                            email={formState.email}
                            onChange={onChange}
                            label="Email"
                            placeholder="Email"
                            required>
                            <SPAN>
                                *Link do zmiany hasła zostanie wysłany na adres
                                e-mail, postępuj zgodnie z podanymi w e-mailu
                                instrukcjami
                            </SPAN>
                        </Email>
                    </Modal.Body>
                    <Modal.Footer>
                        <FloatWrapper>
                            <Button variant="success" type="submit">
                                Wyślij
                            </Button>
                        </FloatWrapper>
                        <AlertMessage2
                            {...messageState.forgotPassword}
                            isLoaded={formState.isLoaded}
                            loadingName={formState.loadingName}
                        />
                    </Modal.Footer>
                </Form>
            </ModalWrapper>
        </ModalBootstrap>
    );
};

export default withForm({
    component: ForgotPasswordModal,
    clearFormToDefaultState: true,
    actions: [
        {
            name: 'forgotPassword',
            url: '/api/auth/forgot-password',
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            errorMessage: 'Wystąpił błąd podczas resetowania hasła',
            successMessage:
                'Sprawdź skrzynkę pocztową w celu zresetowania hasła',
            showErrorMessage: true,
            showSuccessMessage: true,
        },
    ],
    localState: {
        loadingName: '',
        email: {
            value: '',
            isValid: null,
        },
    },
});
