import { isNumber, roundNumber } from "../../../../../utils";
import styles from './index.module.css';


const CalculationSection = ({                                                             
    groundLevel,
    zwg,
}) => {
    return ( 
        <div className={styles.wrapper}>
            <h2>Parametry obliczeń</h2>
            <p>Rzędna terenu: <span className={styles.value}>{roundNumber(groundLevel,2).toFixed(2)} m.n.p.m.</span></p>
            <p>Rzędna zwierciadła wody: <span className={styles.value}>{isNumber(zwg) ? `${roundNumber(zwg,2).toFixed(2)} m.p.p.t.` : 'Brak'}</span></p>
        </div>
    )
}

export default CalculationSection