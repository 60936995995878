import { Fragment } from 'react';
import { Table } from 'react-bootstrap';
import round from '../../../../../utils/roundNumber';
import styles from './index.module.css';
import { isNumber } from '../../../../../utils';


const ReinforcementSummary = ({ data=[] }) => {
    return (
        
        <Fragment>
            {
                data.length > 0
                ?
                <Fragment>
                    {data.map((element, index) => {
                        const diameter = element.results.initModelValues.h;
                        const b = element.results.initModelValues.b;
                        const memberType = element.results.initModelValues.memberType;
                        return (
                            <div className={styles.tableWrapper} key={index}>
                                {
                                    element.results.initModelValues.circular 
                                    ?
                                    <Table className={styles.table}>
                                        <thead>
                                            <tr>
                                                <th>
                                                    Nr<br></br>[-]
                                                </th>
                                                <th>
                                                    M<sub>x</sub><br></br>[kNm]
                                                </th>
                                                <th>
                                                    N<sub>z</sub><br></br>[kN]
                                                </th>
                                                <th>
                                                    V<sub>y</sub><br></br>[kN]
                                                </th>
                                                <th>
                                                    Typ<br></br>[-]
                                                </th>
                                                <th>
                                                    Beton<br></br>[-]
                                                </th>
                                                <th>
                                                    Stal<br></br>[-]
                                                </th>
                                                <th>
                                                    A<sub>s</sub><br></br>[cm<sup>2</sup>]
                                                </th>
                                                <th>
                                                    n<br></br>[-]
                                                </th>
                                                <th>
                                                    d<br></br>[mm]
                                                </th>
                                                <th>
                                                    A<sub>s,pov</sub><br></br>[cm<sup>2</sup>]
                                                </th>
                                                <th>
                                                    &#961;<br></br>[%]
                                                </th>
                                                <th>
                                                    s<br></br>[mm]
                                                </th>
                                                <th>
                                                    l<sub>bd+</sub><br></br>[mm]
                                                </th>
                                                <th>
                                                    l<sub>bd-</sub><br></br>[mm]
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <Fragment>
                                                {
                                                    Array.isArray(element.results.calculation) && element.results.calculation.length > 0 &&
                                                    element.results.calculation.map((ele, idx) => {
                                                        const sectionCapacity = element.model.checkCapacity.find(element => element.index === ele.defaultResults.index);
                                                        const a2 = ele.defaultResults.a2;
                                                        return (
                                                            <tr key={idx}>
                                                                <td>{`${index + 1}.${idx+1}`}</td>
                                                                <td>{ele.defaultResults.med}</td>
                                                                <td>{ele.defaultResults.ned}</td>
                                                                <td>{ele.defaultResults.shearForce}</td>

                                                                <td>{element.results.initModelValues.memberType}</td>
                                                                <td>{element.results.initModelValues.concreteClass}</td>
                                                                <td>{element.results.initModelValues.steelClass}</td>
                                                                <td>{ele.result.as}</td>
                                                                <td>{sectionCapacity.rebarNumber.value}</td>
                                                                <td>{sectionCapacity.rebarDiameter.value}</td>
                                                                <td>{sectionCapacity.rebarArea.value}</td>
                                                                <td>{round(100*sectionCapacity.rebarArea.value/(diameter*diameter*0.25*0.01*Math.PI),2)}</td>
                                                                <td>{round(2*Math.PI*(diameter*0.5 - a2)/sectionCapacity.rebarNumber.value,2)}</td>
                                                                {ele.lbd && isNumber(ele.lbd.tension) ? <td>{round(ele.lbd.tension,0)}</td> : <td>-</td>}
                                                                {ele.lbd && isNumber(ele.lbd.compression) ? <td>{round(ele.lbd.compression,0)}</td> : <td>-</td>}
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </Fragment>
                                        </tbody>
                                    </Table>
                                    :
                                    <Table className={styles.table}>
                                        <thead>
                                            <tr>
                                                <th>
                                                    Nr<br></br>[-]
                                                </th>
                                                <th>
                                                    M<sub>x</sub><br></br>[kNm]
                                                </th>
                                                {
                                                    element.results.initModelValues.memberType === 'Słup' &&
                                                    <th>
                                                        N<sub>z</sub><br></br>[kN]
                                                    </th>
                                                }
                                                <th>
                                                    V<sub>y</sub><br></br>[kN]
                                                </th>
                                                <th>
                                                    Typ<br></br>[-]
                                                </th>
                                                <th>
                                                    Beton<br></br>[-]
                                                </th>
                                                <th>
                                                    Stal<br></br>[-]
                                                </th>
                                                <th>
                                                    A<sub>s1</sub><br></br>[cm<sup>2</sup>]
                                                </th>
                                                <th>
                                                    n<sub>1</sub><br></br>[-]
                                                </th>
                                                <th>
                                                    d<sub>1</sub><br></br>[mm]
                                                </th>
                                                <th>
                                                    A<sub>1,pov</sub><br></br>[cm<sup>2</sup>]
                                                </th>
                                                <th>
                                                    &#961;<sub>1</sub><br></br>[%]
                                                </th>
                                                <th>
                                                    s<sub>1</sub><br></br>[mm]
                                                </th>
                                                <th>
                                                    l<sub>bd,1</sub><br></br>[mm]
                                                </th>
                                                <th>
                                                    A<sub>s2</sub><br></br>[cm<sup>2</sup>]
                                                </th>
                                                <th>
                                                    n<sub>2</sub><br></br>[-]
                                                </th>
                                                <th>
                                                    d<sub>2</sub><br></br>[mm]
                                                </th>
                                                <th>
                                                    A<sub>2,pov</sub><br></br>[cm<sup>2</sup>]
                                                </th>
                                                <th>
                                                    &#961;<sub>2</sub><br></br>[%]
                                                </th>
                                                <th>
                                                    s<sub>2</sub><br></br>[mm]
                                                </th>
                                                <th>
                                                    l<sub>bd,2</sub><br></br>[mm]
                                                </th>
                                                {
                                                    memberType !== 'Słup' &&
                                                    <Fragment>
                                                        <th>
                                                            w<sub>k</sub><br></br>[mm]
                                                        </th>
                                                    </Fragment>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <Fragment>
                                                {
                                                    Array.isArray(element.results.calculation) && element.results.calculation.length > 0 &&
                                                    element.results.calculation.map((ele, idx, arr) => {
                                                        const sectionCapacity = element.model.checkCapacity.find(element => element.index === ele.defaultResults.index);
                                                        const a2 = ele.defaultResults.a2;
                                                        const a1 = ele.defaultResults.a1;
                                                        const d = ele.defaultResults.d;
                                                        const x = ele.result.x;
                                                        const negativeMoment = ele.defaultResults.negativeMoment;

                                                        return (
                                                            <tr key={idx}>
                                                                <td>{`${index +1}.${idx+1}`}</td>
                                                                <td>{ele.defaultResults.med}</td>
                                                                {
                                                                    element.results.initModelValues.memberType === 'Słup' && 
                                                                    <td>{ele.defaultResults.ned}</td>
                                                                }
                                                                <td>{ele.defaultResults.shearForce}</td>
                                                                <td>{element.results.initModelValues.memberType}</td>
                                                                <td>{element.results.initModelValues.concreteClass}</td>
                                                                <td>{element.results.initModelValues.steelClass}</td>
                                                                <td>{ele.result.as1}</td>
                                                                <td>{sectionCapacity.rebarNumberAs1.value}</td>
                                                                <td>{sectionCapacity.rebarDiameterAs1.value}</td>
                                                                <td>{sectionCapacity.rebarAreaAs1.value}</td>
                                                                <td>{round(100*sectionCapacity.rebarAreaAs1.value/(diameter*b*0.01),2)}</td>
                                                                <td>{round((b - 2*a1)/(sectionCapacity.rebarNumberAs1.value - 1),2)}</td>

                                                                {ele.lbd
                                                                    ? 
                                                                    <td>{x > a2 ? negativeMoment ? round(ele.lbd.compression,0) || '-' : x > d ? round(ele.lbd.compression,0) || '-' : round(ele.lbd.tension,0) || '-' : round(ele.lbd.tension,0) || '-' }</td>
                                                                    : 
                                                                    <td>-</td>
                                                                }
                                                                <td>{ele.result.as2}</td>
                                                                <td>{sectionCapacity.rebarNumberAs2.value}</td>
                                                                <td>{sectionCapacity.rebarDiameterAs2.value}</td>
                                                                <td>{sectionCapacity.rebarAreaAs2.value}</td>
                                                                <td>{round(100*sectionCapacity.rebarAreaAs2.value/(diameter*b*0.01),2)}</td>
                                                                <td>{round((b - 2*a1)/(sectionCapacity.rebarNumberAs2.value - 1),2)}</td>

                                                                {ele.lbd
                                                                    ? 
                                                                    <td>{
                                                                        x > a2 
                                                                            ? 
                                                                            negativeMoment 
                                                                                ? 
                                                                                    x > d 
                                                                                        ? round(ele.lbd.compression,0) || '-' 
                                                                                        : round(ele.lbd.tension,0) || '-' 
                                                                                : round(ele.lbd.compression,0) || '-' 
                                                                            : round(ele.lbd.tension,0) || '-' 
                                                                    }</td>
                                                                    : 
                                                                    <td>-</td>
                                                                }
                                                                {
                                                                    memberType !== 'Słup' &&
                                                                    <Fragment>
                                                                        <td>
                                                                            {
                                                                                ele.crack && isNumber(ele.crack.wk)
                                                                                ?
                                                                                round(ele.crack.wk,2)
                                                                                :
                                                                                '-'
                                                                            }
                                                                        </td>
                                                                    </Fragment>
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </Fragment>
                                        </tbody>
                                    </Table>                                      
                                }
                            </div>
                        )
                    })}

                    <p className={styles.underline}>Oznaczenia:</p>
                    <p>
                        M<sub>x</sub> -{' '}
                        <span className={styles.value}>moment zginający</span>
                    </p>
                    <p>
                        N<sub>z</sub> -{' '}
                        <span className={styles.value}>siła osiowa</span>
                    </p>
                    <p>
                        V<sub>y</sub> -{' '}
                        <span className={styles.value}>siła tnąca</span>
                    </p>
                    <p>
                        A<sub>s</sub> -{' '}
                        <span className={styles.value}>obliczeniowa powierzchnia zbrojenia przekroju kołowego</span>
                    </p>
                    <p>
                        A<sub>s,pov</sub> -{' '}
                        <span className={styles.value}>przyjęta powierzchnia zbrojenia</span>
                    </p>
                    <p>
                        n -{' '}
                        <span className={styles.value}>liczba prętów</span>
                    </p>
                    <p>
                        d -{' '}
                        <span className={styles.value}>średnica pręta</span>
                    </p>
                    <p>
                        &#961; -{' '}
                        <span className={styles.value}>stopień zbrojenia</span>
                    </p>
                    <p>
                        s -{' '}
                        <span className={styles.value}>osiowy rozstaw prętów</span>
                    </p>
                    <p>
                        l<sub>bd</sub> -{' '}
                        <span className={styles.value}>minimalna długość zakotwienia prętów</span>
                    </p>
                    <p>
                        w<sub>k</sub> -{' '}
                        <span className={styles.value}>szerokość rysy</span>
                    </p>
                    <p>*cyfry 1,2 odnoszą się odpowiednio do zbrojenia dolnego i górnego.</p>
                    <p>**znaki +,- odnoszą się odpowiednio do długości zakotwienia prętów rozciąganych i ściskanych.</p>
                </Fragment>
                :
                <p>Brak wyników obliczeń</p>
            }

        </Fragment>
    )
};

export default ReinforcementSummary;