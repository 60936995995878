import { useCallback, useState } from "react";
import { isNumber, stringToNumber } from "../../../utils";
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';

const micropileResultsProps = [
    'capacity',
    'steelRod', 
    'soilTable',
    'calculationParameters',
    'initMicropileLength',
    'initCapacity'
];
const defaultRodModule = {
    value: 3
};

const ResultsHelpers = () => {
 
    const [resultsState, setResultsState] = useState({});
    const [resultsActive, setResultsActive] = useState(false);
    const [rodModule, setRodModule] = useState(defaultRodModule);
    const [prodLengthValues, setProdLengthValues] = useState({
        currentCapacityNt: {},
        currentCapacityNtw: {},
    });
    const [currentMicropileLength, setCurrentMicropileLength] = useState({});
    const [currentCapacity, setCurrentCapacity] = useState({});

    useDeepCompareEffect(() => {
        const isResultsArr = Object.keys(resultsState).filter(ele => ele !== 'isResultsActual');
        const isResultsActive = isResultsArr.length > 0 && isResultsArr.every(ele => micropileResultsProps.includes(ele));

        setResultsActive(isResultsActive);

        const initNt = Array.isArray(resultsState.capacity) ? resultsState.capacity.find(ele => ele.z >= (currentMicropileLength.micropileCompressionLength + resultsState.calculationParameters.calcMicropileHeadElevation)) || {} : {};
        const initNtw = Array.isArray(resultsState.capacity) ? resultsState.capacity.find(ele => ele.z >= (currentMicropileLength.micropilePullLength + resultsState.calculationParameters.calcMicropileHeadElevation)) || {} : {};

        const angleRad = resultsState.calculationParameters ? Math.abs(Math.cos(Math.abs(resultsState.calculationParameters.micropileAngle)*Math.PI/180)) : 0;
        const micropileAngleRad = (isNumber(angleRad) && angleRad) > 0 ? angleRad : 1;

        const capacityObject = resultsState.calculationParameters ? {
            rodCapacityValue: resultsState.steelRod ? resultsState.steelRod.rodCapacityValue : '',
            diameter: initNt.diameter,
            rodProd: resultsState.steelRod ? resultsState.steelRod.prod : '',
            rodName: resultsState.steelRod ? resultsState.steelRod.name : '',
            axisLoad: resultsState.calculationParameters.desAxisLoadValue ,
            micropileAngle: resultsState.calculationParameters.micropileAngle,
            bondLength: currentMicropileLength.micropileCompressionLength/micropileAngleRad,
            bondLengthPull: currentMicropileLength.micropilePullLength/micropileAngleRad,
            totalRodLength: ((initNt.z - resultsState.calculationParameters.micropileHeadSpot)/micropileAngleRad) + resultsState.calculationParameters.headLength,
            totalRodLengthPull: ((initNtw.z - resultsState.calculationParameters.micropileHeadSpot)/micropileAngleRad) + resultsState.calculationParameters.headLength,
            np: initNt.np,
            ns: initNt.ns,
            nt: initNt.nt,
            ntlt: 1.5*initNt.nt,
            ntw: initNtw.ntw,
            ntwlt: 1.5*initNtw.ntw,
        } : {}

        setCurrentCapacity(state => {
            const emptyState = (Object.keys(state).length === 0 && state.constructor === Object) || (stringify(capacityObject) !== stringify(state));

            const validObj = emptyState ? capacityObject : state;

            return validObj;
        });
        
    },[
        resultsState,
        currentMicropileLength.micropileCompressionLength,
        currentMicropileLength.micropilePullLength
    ]);

    const { isResultsActual, ...restState } = resultsState;

    const loadProject = ({ isResultsActive, currentMicropileLength, currentCapacity, results={} }) => {
        const { rodModule={}, ...restResults } = results;

        setResultsActive(isResultsActive);
        setResultsState(restResults);
        setRodModule(rodModule);
        setCurrentCapacity(currentCapacity || {});
        setCurrentMicropileLength(currentMicropileLength || {});
        setProdLengthValues({
            currentCapacityNt: results.initCapacity ? results.initCapacity.nt : {},
            currentCapacityNtw: results.initCapacity ? results.initCapacity.ntw : {},
        })
    }
    const onChangeRodModule = (e) => setRodModule({ value: stringToNumber(e.currentTarget.value) });

    const results = {
        state: {
            ...resultsState,
            rodModule,
            currentMicropileValues: prodLengthValues
        },
        stateToSave: { 
            ...restState,
            rodModule
        },
        isResultsActive: resultsActive,
        currentMicropileLength: currentMicropileLength,
        currentCapacity: currentCapacity,
        updateState: setResultsState,
        loadProject: loadProject,
        onChangeRodModule: useCallback((obj) => onChangeRodModule(obj),[]),
        onChangeProdMicropileLength: useCallback((obj) => setProdLengthValues(state => ({
            ...state,
            ...obj
        })),[]),
        onChangeCurrentMicropileLength: useCallback((obj) => setCurrentMicropileLength(obj),[]) ,
        onChangeCurrentCapacity: setCurrentCapacity,
        
        initState: () => {
            setResultsState({});
            setResultsActive(false);
        }
    }

    return { 
        results
    }
}

export default ResultsHelpers