const defaultSaftyFactors = {
    frictionAngleSafe: 1,
    cohesionSafe: 0.4,
    weightSafe: 0.9,
    kxSafe: 1,
    rgrSafe: 1,
    kpSafe: 0.85,
    nonCohesiveShaftFriction: -0.5,
    cohesiveShaftFriction: -1,

    steelReductionFactor: 1.15,
    concreteReductionFactor: 1.4,
    constructionReductionFactor: 1,

    deadLoadReduction: 1.35,
    liveLoadReduction: 1.5,
    globalLoadsReduction: 1.42,
    liveDeadLoadRation: 0.50
};

export default defaultSaftyFactors;