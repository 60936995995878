import React, { Fragment } from 'react';
import styles from './index.module.css';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SelectField = ({ 
    name, 
    value, 
    title='', 
    info=false,
    infoCss='',
    onChange, 
    defaultOption='', 
    defaultValue='', 
    disabledDefault=true,
    isValid, 
    options=[], 
    required, 
    dataRow, 
    children,
    css,
    ...rest 
}) => {
    return ( 
        <Fragment>
            {
                title &&
                <div className={styles.titleWrapper}>
                    {title && <label className={styles.title}>{title}</label>}
                    {
                        info &&
                        <Fragment>
                            <FontAwesomeIcon className={styles.infoIcon} icon={faQuestionCircle}/>
                            <div className={`${styles.info} ${infoCss} defaultBox`}>
                                {info}
                            </div> 
                        </Fragment>
                    }
                </div>                
            }

            <div className={`${styles.wrapper} ${isValid === false ? styles.wrapperInvalid : ''} ${css}`}>   
                <select className={styles.select} name={name} value={value}  onChange={onChange} data-row={dataRow} required={required} {...rest}>
                    {
                        children 
                        ?
                        children
                        :
                        <Fragment>
                            {defaultOption && <option value={defaultValue} title={defaultValue} disabled={disabledDefault}>{defaultOption}</option>}
                            
                            {options.length > 0 && options.map((ele, index) => {
                                return (
                                    <option key={index} value={ele} title={ele} index={index}>{ele}</option>
                                )
                            })}
                        </Fragment>
                    }
                </select>
            </div>
        </Fragment>
    )
}

export default React.memo(SelectField)