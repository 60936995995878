import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { micropilesCompressionContext } from '../../context/MicropilesCompression';
import styles from './index.module.css';
import ProjectTitle from '../Micropiles/model/ProjectTitle';
import SoilTable from './model/SoilTable';
import SoilProfile from './model/SoilProfile';
import MicropileProperties from './model/MicropileParameters';
import SaftyFactors from './model/SaftyFactors';

import { MainAlertAndLoading } from '../../components';
import ZwgLevel from './model/ZwgLevel';
import SubmitCalculations from '../SubmitCalculations';
import { useWindowSize } from '../../hooks';

const MicropilesCompression = () => {
    const {
        projectTitle = {},
        soilTable = {},
        soilProfile = {},
        saveSoilProfile = {},
        editSoilProfile = {},
        globalAlerts = {},
        globalLoading = {},
        micropileProperties = {},
        zwgLevel = {},
        saftyFactors = {},
        submitCalculation = {},
    } = useContext(micropilesCompressionContext);

    const modelRef = useRef(null);
    const [modelWidthPx, setModelWidthPx] = useState(0);
    const windowSize = useWindowSize();

    useEffect(() => {
        if (modelRef.current) {
            setModelWidthPx(modelRef.current.offsetWidth);
        }
    }, [windowSize.width]);

    return (
        <Fragment>
            <section className={styles.section}>
                <Row>
                    <ProjectTitle {...projectTitle} />
                </Row>
            </section>
            <section className={styles.section}>
                <Row>
                    <SoilTable
                        {...soilTable}
                        saveSoilProfile={saveSoilProfile}
                        editSoilProfile={editSoilProfile}
                        globalLoading={globalLoading.state}
                    />
                </Row>
            </section>
            <section className={styles.section}>
                <Row>
                    <Col xs={12} sm={6} ref={modelRef}>
                        <SoilProfile
                            {...soilProfile}
                            soilTable={soilTable.state}
                            micropileHeadSpot={
                                micropileProperties.state.micropileHeadSpot
                                    .value
                            }
                            micropileAngle={
                                micropileProperties.state.micropileAngle.value
                            }
                            profileParameters={{
                                groundLevel: zwgLevel.state.groundLevel,
                                zwg: zwgLevel.state.zwg,
                            }}
                            modelWidthPx={modelWidthPx}
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <ZwgLevel {...zwgLevel} />
                        <MicropileProperties
                            maxHeight={Math.max(
                                ...soilTable.state.map(
                                    (ele) => ele.height.value,
                                ),
                            )}
                            {...micropileProperties}
                        />
                        <SaftyFactors
                            isSteelRod={
                                micropileProperties.state.isSteelRod.value &&
                                (micropileProperties.state.rodType.value ||
                                    micropileProperties.state.rodThreadbarType
                                        .value)
                            }
                            isBuckling={
                                micropileProperties.state.buckling.value
                            }
                            {...saftyFactors}
                        />
                    </Col>
                </Row>
            </section>
            <section>
                <Row>
                    <Col xs={12}>
                        <SubmitCalculations {...submitCalculation} />
                    </Col>
                </Row>
            </section>
            <MainAlertAndLoading
                alerts={globalAlerts.state.alerts}
                updateAlerts={globalAlerts.updateState}
                loadings={globalLoading.state}
            />
        </Fragment>
    );
};

export default MicropilesCompression;
