const noncohesive = [
    {
        id: 0,
        pl: 'Żwir',
    },
    {
        id: 1,
        pl: 'Pospółka',
    }, 
    {
        id: 2,
        pl: 'Piasek gruby'
    },
    {
        id: 3,
        pl: 'Piasek średni'
    },
    { 
        id: 4,
        pl: 'Piasek drobny'
    },   
    {
        id: 5, 
        pl: 'Piasek pylasty'
    },      
].map(ele => ({ ...ele, state: 'noncohesive' }));

const cohesiveSoils = [
    {
        id: 6,
        pl: 'Żwir gliniasty'
    },
    {
        id: 7,
        pl: 'Pospółka gliniasta'
    },
    {
        id: 8,
        pl: 'Piasek gliniasty'
    },
    {
        id: 9,
        pl: 'Pył piaszczysty'
    },
    {
        id: 10,
        pl: 'Pył'
    },
    {
        id: 11,
        pl: 'Glina piaszczysta'
    },
    {
        id: 12,
        pl: 'Glina'
    },
    {
        id: 13,
        pl: 'Glina pylasta'
    },
    {
        id: 14,
        pl: 'Glina zwięzła'
    },
    {
        id: 15,
        pl: 'Ił piaszczysty'
    },
    {
        id: 16,
        pl: 'Ił'
    },
    {
        id: 17,
        pl: 'Ił pylasty'
    },  

].map(ele => ({ ...ele, state: 'cohesive' }));

const organic = [
    {
        id: 18,
        pl: 'Grunt organiczny',
        state: 'organic'
    },    
]
const rocks = [
    {
        id: 19,
        pl: 'Łupki',
    },
    {
        id: 20,
        pl: 'Zwietrzlina',
    },
    {
        id: 21,
        pl: 'Skała słabo zwietrzała',
    },
    {
        id: 22,
        pl: 'Skała miękka',
    },
    {
        id: 23,
        pl: 'Piaskowiec',
    },
    {
        id: 24,
        pl: 'Wapień',
    },
    {
        id: 25,
        pl: 'Skała twarda',
    },
    {
        id: 26,
        pl: 'Granit', 
    },
    {
        id: 27,
        pl: 'Gnejs',
    }    
].map(ele => ({ ...ele, state: 'rock' }));

const micropileSoils = [
    ...noncohesive,
    ...cohesiveSoils,
    ...organic,
    ...rocks
].map(ele => ({ ...ele, name: ele.pl }))

export default micropileSoils