import { useEffect } from 'react';
import { MainTitle } from '../../../components';

const CapacityChartsNavigation = () => {
    useEffect(() => {
        document.title = `Wykresy nośności`;

        return () => {
            document.title = '';
        };
    }, []);

    return (
        <MainTitle
            title={'Wykresy nośności'}
            subtitle={
                'Graficzne zestawienie wyników obliczeń'
            }
        />
    );
};

export default CapacityChartsNavigation;
