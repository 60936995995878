import { isNumber } from '../../../../../utils';
import styles from './index.module.css';

const CalculationSection = ({ parameters = {}, analysisType = '' }) => {
    return (
        <div className={styles.wrapper}>
            <h2>Parametry obliczeń</h2>
            <p>
                Rzędna terenu:{' '}
                <span className={styles.value}>
                    {parameters.groundLevel.value.toFixed(2)} m.n.p.m.
                </span>
            </p>
            <p>
                Rzędna zwierciadła wody:{' '}
                <span className={styles.value}>
                    {isNumber(parameters.waterLevel.value)
                        ? `${parameters.waterLevel.value.toFixed(2)} m.p.p.t.`
                        : 'Brak'}
                </span>
            </p>
            {(analysisType === 1 || analysisType === 2) && (
                <p>
                    Typ analizy:{' '}
                    <span className={styles.value}>{`${
                        analysisType === 1
                            ? 'sprężysto-plastyczna'
                            : 'sprężysta'
                    }`}</span>
                </p>
            )}
        </div>
    );
};

export default CalculationSection;
