import { useState } from 'react';
import { sortObjectKeys, stringToNumber } from '../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const micropileResultsProps = [
    'slipPlate',
    'deepPlate',
    'steelRod',
    'soilTable',
    'intersectionPoints',
    'calculationParameters',
    'slideLineArr',
    'currentCapacity',
];
const defaultRodModule = {
    value: 3,
};

const ResultsHelpers = () => {
    const [resultsState, setResultsState] = useState({});
    const [resultsActive, setResultsActive] = useState(false);
    const [rodModule, setRodModule] = useState(defaultRodModule);

    useDeepCompareEffect(() => {
        const isResultsArr = Object.keys(resultsState).filter(
            (ele) => ele !== 'isResultsActual',
        );
        const isResultsActive =
            isResultsArr.length > 0 &&
            isResultsArr.every((ele) => micropileResultsProps.includes(ele));

        setResultsActive(isResultsActive);
    }, [resultsState]);

    const { isResultsActual, ...restState } = resultsState;

    const loadProject = ({ isResultsActive, results = {} }) => {
        const { rodModule = {}, ...restResults } = results;
        setResultsActive(isResultsActive);
        setResultsState(restResults);
        setRodModule(rodModule);
    };
    const onChangeRodModule = (e) => setRodModule({ value: stringToNumber(e.currentTarget.value) });
    
    const results = {
        state: {
            ...resultsState,
            rodModule,
        },
        stateToSave: {
            ...restState,
            rodModule,
        },
        isResultsActive: resultsActive,
        updateState: setResultsState,
        loadProject: loadProject,
        onChangeRodModule: onChangeRodModule,
        initState: () => {
            setResultsState({});
            setResultsActive(false);
        },
    };

    return {
        results,
    };
};

export default ResultsHelpers;
