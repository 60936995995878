import React, { useState, useEffect, useContext, useRef, Fragment } from "react";
import { Link } from "react-router-dom";
import config from "../../config/config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { useWindowSize } from '../../hooks';

import styles from './index.module.css';
import { faClock, faEnvelopeOpen } from "@fortawesome/free-regular-svg-icons";
import ContactForm from "../ContactForm";
import { contactFormContext } from "../../context/ContactForm";
import Loading from "../Loading";
import MainAlertAndLoading from "../MainAlertAndLoading";
import { isAdminRole } from "../../utils";
import logo from '../../assets/Logo.png';
import logo_light from '../../assets/Logo_light.png';

const Navbar = ({ onLogOut, role, subsDays=0, mode }) => {
    const navRef                            = useRef({});
    const menuRef                           = useRef(null);
    const hamburgerMenuRef                  = useRef(null);
    const [toggleOpen, setToggleOpen]       = useState(false);
    const [open, setOpen]                   = useState(false);
    const size                              = useWindowSize()
    const { routes } = config;

    const {
        globalAlerts={},
        globalLoading={},
        contactForm={}
    } = useContext(contactFormContext);

    useEffect(() => {
        if(menuRef.current && size.width > 992){
            setToggleOpen(false);
        }
    },[size.width]);

    const toggleMenu = () =>{
        if(!toggleOpen){   
            setToggleOpen(true);
        } else {
            setToggleOpen(false);
        }
    }

    const onOpen = (e) => setOpen(true);
    const onHide = () => setOpen(false);

    return (
        <Fragment>
            <div id={"navbar-wrapper"} className={styles.wrapper}>
                <div ref={navRef} className={`${styles.mainMenu} container`}>
                    <nav id="site-navigation" className={`${styles.siteNavigation} navbar`}>
                        <div className={`${styles.navContainer}`}>
                            <div style={{ padding: 0 }} className="navbar-brand">
                                <div className={styles.siteBrandingText}>
                                    <Link to={routes.dashboard.home} rel="home">
                                        <img src={mode === 'dark' ? logo_light : logo} alt='Company logo'/>
                                    </Link> 
                                </div>
                            </div>
                            <div ref={hamburgerMenuRef} id="main-menu-panel" className={`${styles.openPanel} ${toggleOpen ? styles.clicked : ''}`} data-panel="main-menu-panel" onClick={toggleMenu}>
                                <span></span>
                                <span></span> 
                                <span></span>
                            </div>
                            <div ref={menuRef} className={`${styles.menuContainer} ${toggleOpen ? styles.openNav : ''} menu-container`}>
                                <ul className={`${styles.navbarNav} nav`}>
                                    {
                                        !isAdminRole(role) &&
                                        <li className={styles.noPadding}>
                                            <div className={`${styles.licence} backgroundAround`} title="Dni do końca licencji">
                                                <FontAwesomeIcon icon={faClock} />
                                                {subsDays}
                                                <span className={styles.subsLeft}> - {subsDays === 1 ? 'dzień' : 'dni' } do końca subskrypcji</span>
                                            </div>
                                        </li>
                                    }
                                    <li name="contact" title="Formularz kontaktowy" onClick={onOpen}>
                                        <button className={styles.contact} >
                                            <FontAwesomeIcon icon={faEnvelopeOpen}/>
                                            Kontakt
                                        </button>            
                                    </li>
                                    <li>
                                        <Link title="Moje konto" className={styles.account} to={routes.dashboard.home}>
                                            <FontAwesomeIcon icon={faCogs}/> Moje konto
                                        </Link>
                                    </li>
                                    <li title="Wyloguj" onClick={() => onLogOut(true)}>
                                        <div className={styles.logOut}>
                                            <FontAwesomeIcon icon={faPowerOff} />
                                            {toggleOpen ? 'Wyloguj' : '' }
                                        </div>
                                    </li>  
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>            
            </div>       
            <ContactForm
                open={open}
                onHide={onHide}
                { ...contactForm }
            />
            <MainAlertAndLoading
                alerts={globalAlerts.state.alerts}
                updateAlerts={globalAlerts.updateState}
            />
            {
                globalLoading.state.sendingMessage &&
                <div className={styles.loadingWrapper}>
                    <Loading
                        text="Wysyłanie wiadomości..."
                    />                
                </div>   
            }
        </Fragment>

    )
}
export default Navbar