import { useState } from 'react';
import { stringToNumber } from '../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const initState = {
    allItems: false,
    soilsTable: false,
    projectInfo: false,
    micropileProperties: false,
    saftyFactors: false,
    micropileCompressionDetailsTable: false,
    micropilePullDetailsTable: false,
    steelRodTable: false,
    micropileCompressionChart: false,
    micropilePullChart: false,
    summary: false,

    firstPage: false,
    
    micropileCompressionLength: '',
    micropilePullLength: '',
};

const PrintResultsHelpers = (data) => {
    const [reportItem, setRaportItem] = useState(initState);

    useDeepCompareEffect(() => {
        if (data.currentMicropileLength) {
            setRaportItem((state) => {
                return {
                    ...state,
                    micropileCompressionLength:
                        data.currentMicropileLength
                            .micropileCompressionLength || '',
                    micropilePullLength:
                        data.currentMicropileLength.micropilePullLength || '',
                };
            });
        }
    }, [data.currentMicropileLength]);

    const onChangeRaportItems = (e) => {
        const input = e.currentTarget.closest('input');

        if (input) {
            const { name, checked, value } = input;

            if (name === 'allItems') {
                setRaportItem((state) => ({
                    ...state,
                    ...Object.fromEntries(
                        Object.entries(state).map(([key, val]) => {
                            if (
                                key === 'micropileCompressionLength' ||
                                key === 'micropilePullLength'
                            ) {
                                return [key, val];
                            } else {
                                return [key, !!checked ? true : false];
                            }
                        }),
                    ),
                }));
            } else if (
                name === 'micropileCompressionLength' ||
                name === 'micropilePullLength'
            ) {
                setRaportItem((state) => {
                    return {
                        ...state,
                        [name]: stringToNumber(value),
                    };
                });
            } else {
                setRaportItem((state) => ({
                    ...state,
                    [name]: !!checked,
                }));
            }
        }
    };

    const printResults = {
        onChange: onChangeRaportItems,
        initState: () => setRaportItem(initState),
        state: {
            reportItem,
            ...data,
        },
    };

    return {
        printResults,
    };
};

export default PrintResultsHelpers;
