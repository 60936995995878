import React, { createContext, useContext } from 'react';
import { 
    GlobalAlertService,
    GlobalLoadingService,
    UsersListService,
    RenewSubscriptionService,
    RemoveUsersService,
    AddNewUsersService,
    InvoiceFormService
} from '../../services/Admin'; 
import { adminAuthContext } from '../Admin';
  
const usersContext = createContext({});

const AdminUsersListProvider = ({ children }) => {
    const { permission } = useContext(adminAuthContext)
    const { globalAlerts } = GlobalAlertService();
    const { globalLoading } = GlobalLoadingService();
    const { usersList } = UsersListService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        
        fetch: {
            getUsersList: {
                url: '/api/admin/geoincloud/user/list',
                method: 'GET'
            },
            blockUserAccount: {
                url: '/api/admin/geoincloud/user/blockuser',
                method: 'PUT'
            }
        },
        role: permission
    });
    const { invoiceForm } = InvoiceFormService();
    const { renewSubscription } = RenewSubscriptionService({
        users: usersList.state.selectedUsers,
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        setUserListStateChanged: usersList.setStateChanged,

        updateInvoiceState: invoiceForm.updateInvoiceState,
        invoiceState: invoiceForm.state.invoiceState,
        invoiceType: invoiceForm.state.invoiceType,

        fetch: {
            renewSubscription: {
                url: '/api/admin/geoincloud/user/renewsubscription',
                method: 'PUT'
            }
        }
    });

    const { removeUsers } = RemoveUsersService({
        users: usersList.state.selectedUsers,
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        setUserListStateChanged: usersList.setStateChanged,

        fetch: {
            removeUsers: {
                url: '/api/admin/geoincloud/user/deleteusers',
                method: 'DELETE'
            }
        } 
    });

    const { addNewUsers } = AddNewUsersService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        setUserListStateChanged: usersList.setStateChanged,
        selectedUsers: usersList.state.selectedUsers,

        updateInvoiceState: invoiceForm.updateInvoiceState,
        invoiceState: invoiceForm.state.invoiceState,
        invoiceType: invoiceForm.state.invoiceType,

        fetch: {
            addNewUsers: {
                url: '/api/admin/geoincloud/user/addnewusers',
                method: 'POST'
            }
        }
    });
    
    return ( 
        <usersContext.Provider   
            value={{ 
                globalAlerts,
                globalLoading,
                usersList,
                renewSubscription,
                removeUsers,
                invoiceForm,
                addNewUsers
            }}
        >
            {children}
        </usersContext.Provider>
    )
}

export { usersContext }
export default AdminUsersListProvider