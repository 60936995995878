import { Outlet, useOutletContext } from "react-router-dom";
import WalingProvider from "../../../context/Waling";
import { WalingNavigation } from "../../../pages";

const WalingLayout = () => {
    const context = useOutletContext();

    return (
        <WalingProvider>
            <WalingNavigation report={context.report}/>
            <Outlet context={context}/>
        </WalingProvider>
    )
};

export default WalingLayout;