import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    Checkbox,
    InputField,
    SectionTitle,
    SelectField,
} from '../../../../components';
import baseAreaPng from '../../../../assets/baseArea.png';
import profilesJpg from '../../../../assets/profiles.JPG';
import skinFrictionPng from '../../../../assets/skinFriction.png';
import styles from './index.module.css';

const Calculations = ({ isChartData, state, sheetPileTypes, technologyTypes, updateSoilTable, onChange, onBlur, onKeyDown }) => {

    return (
        <Fragment>
            <div className={styles.wrapper}>
                <SectionTitle title="Parametry modelu" />
                {
                    isChartData &&
                    <Row>
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                value={state.groundLevel.value}
                                isValid={state.groundLevel.isValid}
                                type="number"
                                title="Rzędna terenu [m.n.p.m]"
                                placeholder={'Wpisz rzędną terenu'}
                                name="groundLevel"
                                required
                            />
                        </Col>                          
                    </Row>
                }
                <Row>
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        {
                            isChartData 
                            ?
                            <InputField
                                onChange={(e) => {
                                    onChange(e, updateSoilTable)
                                }}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                value={state.cptLevel.value}
                                isValid={state.cptLevel.isValid}
                                type="number"
                                title="Rzędna istniejącego terenu [m.n.p.t]"
                                placeholder={'Wpisz rzędną terenu'}
                                name="cptLevel"
                            >
                                Rzędna istniejącego terenu, na którym wykonano sondowanie CPT, jeżeli jest równa rzędnej projektowanej należy wpisać "0" lub pozostawić pole puste. Jeżeli rzędna projektowana jest większa od rzędnej istniejącego terenu, należy wpisać wartość dodatnią okręślającą odległość od rzędnej projektowanej do istniejącej.<br></br><b>UWAGA</b><br></br>Opcja ta dotyczy jedynie danych odczytanych z wykresu sondowania CPT (nie dotyczy danych wpisywanych ręcznie do tabeli).
                            </InputField>
                            :
                            <InputField
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                value={state.groundLevel.value}
                                isValid={state.groundLevel.isValid}
                                type="number"
                                title="Rzędna projektowanego terenu [m.n.p.m]"
                                placeholder={'Wpisz rzędną terenu'}
                                name="groundLevel"
                                required
                            />
                        }
                    </Col>
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <InputField
                            onChange={onChange}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            value={state.zwg.value}
                            isValid={state.zwg.isValid}
                            type="number"
                            title="Rzędna ZWG [m.p.p.t.]"
                            placeholder={'Rzędna zwierciadła wody gruntowej'}
                            name="zwg"
                        />
                    </Col>
                </Row>
                <SectionTitle title="Parametry ścianki szczelnej" />
                <Row>
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <Checkbox
                            onChange={onChange}
                            title="Wybierz typ ścianki z listy"
                            name="sheetPileList"
                            value={state.sheetPileList.value}
                        >
                        </Checkbox>
                    </Col>
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <InputField
                            onChange={onChange}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            value={state.sheetPileNumbers.value}
                            isValid={state.sheetPileNumbers.isValid}
                            type="number"
                            title={'Liczba zestawów do analizy'}
                            
                            placeholder={'Liczba zestawów'}
                            name="sheetPileNumbers"
                            disable={true}
                            required
                        >
                            Jeden zestaw składa się z dwóch grodzic AZ lub jednej grodzicy PU/GU.
                            <img alt={'Widok jednego zestawu ścianki szczelnej'} src={profilesJpg} />
                        </InputField>
                    </Col>
                </Row>
                {
                    !state.sheetPileList.value &&
                    <Row>
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                value={state.baseArea.value}
                                isValid={state.baseArea.isValid}
                                type="number"
                                title={<Fragment>Powierzchnia podstawy [cm<sup>2</sup>]</Fragment>}
                                placeholder={'Powierzchnia podstawy'}
                                name="baseArea"
                                required
                            >
                                <img alt={''} src={baseAreaPng} />
                            </InputField>
                        </Col>  
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                value={state.perimeter.value}
                                isValid={state.perimeter.isValid}
                                type="number"
                                title="Długość pobocznicy [cm]"
                                placeholder={'Długość pobocznicy'}
                                name="perimeter"
                            >
                                <img alt={''} src={skinFrictionPng} />
                            </InputField>
                        </Col>
                    </Row>
                }
                <Row>
                {
                    state.sheetPileList.value &&
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <SelectField
                            title="Rodzaj ścianki szczelnej"
                            onBlur={onBlur}
                            onChange={onChange}
                            value={state.sheetPileType.value}
                            defaultOption="Wybierz rodzaj ścianki"
                            isValid={state.sheetPileType.isValid}
                            options={Object.keys(sheetPileTypes)}
                            name="sheetPileType"
                            infoCss={styles.infoCss}

                            required
                        />
                    </Col>
                }
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <SelectField
                            title="Technologia pogrążania grodzic"
                            onBlur={onBlur}
                            onChange={onChange}
                            value={state.technology.value}
                            defaultOption="Wybierz technologię pogrążania ścianki"
                            isValid={state.technology.isValid}
                            options={technologyTypes}
                            name="technology"
                            infoCss={styles.infoCss}
                            info={
                                'Dla grodzic wwibrowywanych nośność podstawy jest obniżana o 50% a nośność pobocznicy o 30%.'
                            }

                            required
                        />
                    </Col>  
                    {
                        !state.sheetPileList.value &&
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                value={state.sheetPileHeadSpot.value}
                                isValid={state.sheetPileHeadSpot.isValid}
                                type="number"
                                title={'Rzędna głowicy ścianki [m.p.p.t]'}
                                
                                placeholder={'Rzędna głowicy ścianki'}
                                name="sheetPileHeadSpot"
                                required
                            />
                        </Col>
                    }
                </Row>
                <Row>
                {
                    state.sheetPileList.value &&
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <InputField
                            onChange={onChange}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            value={state.sheetPileHeadSpot.value}
                            isValid={state.sheetPileHeadSpot.isValid}
                            type="number"
                            title={'Rzędna głowicy ścianki [m.p.p.t]'}
                            
                            placeholder={'Rzędna głowicy ścianki'}
                            name="sheetPileHeadSpot"
                            required
                        />
                    </Col>
                }
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <InputField
                            onChange={onChange}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            value={state.sheetPileLength.value}
                            isValid={state.sheetPileLength.isValid}
                            type="number"
                            title={'Długość grodzicy ścianki [m]'}
                            
                            placeholder={'Długość grodzicy'}
                            name="sheetPileLength"
                            required
                        />
                    </Col>
                    {
                        !state.sheetPileList.value &&
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                value={state.yt.value}
                                isValid={state.yt.isValid}
                                type="number"
                                title={'Współczynnik bezpieczeństwa'}
                                
                                placeholder={'Wsp. bezpieczeństwa'}
                                name="yt"
                                required
                                disable={true}
                            />
                        </Col>
                    }
                </Row>
                <Row>
                    {
                        state.sheetPileList.value &&
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                value={state.yt.value}
                                isValid={state.yt.isValid}
                                type="number"
                                title={'Współczynnik bezpieczeństwa'}
                                
                                placeholder={'Wsp. bezpieczeństwa'}
                                name="yt"
                                required
                                disable={true}
                            />
                        </Col>
                    }
                </Row>
            </div>
        </Fragment>
    );
};

export default Calculations;
