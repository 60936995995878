import { useEffect, useState } from 'react';
import { isNumber, stringToNumber } from '../../../utils';

const initState = {
    allItems: false,
    projectInfo: false,
    soilsTable: false,
    pileParameters: false,

    pnCapacityDetails: false,
    enCapacityDetails: false,
    pilesInGroupDetails: false,
    helperFactorsDetails: false,

    pileChart: false,
    pullPileChart: false,
    qcdChart: false,
    tcdChart: false,

    firstPage: false,

    pileLength: '',
    pullPileLength: '',
};
const PrintResultsHelpers = (data) => { 
    const [reportItem, setRaportItem] = useState(initState);

    useEffect(() => {
        if (isNumber(data.currentPileLength)) {
            setRaportItem((state) => {
                return {
                    ...state,
                    pileLength: data.currentPileLength || '',
                    pullPileLength: data.currentPileLength || '',
                };
            });
        }
    }, [data.currentPileLength]);

    const onChangeRaportItems = (e) => {
        const input = e.currentTarget.closest('input');

        if (input) {
            const { name, checked, value } = input;

            if (name === 'allItems') {
                setRaportItem((state) => ({
                    ...state,
                    ...Object.fromEntries(
                        Object.entries(state).map(([key, val]) => {
                            if (
                                key === 'pilesInGroupDetails' &&
                                data.results.singlePile
                            ) {
                                return [key, false];
                            } else if (
                                key === 'pileLength' ||
                                key === 'pullPileLength'
                            ) {
                                return [key, val];
                            } else {
                                return [key, !!checked ? true : false];
                            }
                        }),
                    ),
                }));
            } else if (name === 'pileLength' || name === 'pullPileLength') {
                setRaportItem((state) => {
                    return {
                        ...state,
                        [name]: stringToNumber(value),
                    };
                });
            } else {
                setRaportItem((state) => ({
                    ...state,
                    [name]: !!checked,
                }));
            }
        }
    };

    const printResults = {
        onChange: onChangeRaportItems,
        initState: () => setRaportItem(initState),
        state: {
            reportItem,
            ...data,
        },
    };

    return {
        printResults,
    };
};

export default PrintResultsHelpers;
