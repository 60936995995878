import { useState, Fragment } from 'react';
import { Button } from '../../../../components';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.css';
import PrintResults from '../../../HorizontalCapacity/results/PrintResults';

const PrintHorizontalCapacityResults = ({
    report,
    printResults = {},
    isResultsActual,
}) => {
    const [open, setOpen] = useState(false);

    const onOpen = () => setOpen(true);
    const onHide = () => setOpen(false);

    return (
        <Fragment>
            {isResultsActual && (
                <Fragment>
                    <div className={styles.wrapper}>
                        <Button
                            icon={faPrint}
                            css={styles.printButton}
                            variant="danger"
                            name="report"
                            onClick={onOpen}
                            title="Raport z obliczeń"
                            altTitle="Raport"
                        />
                    </div>

                    <PrintResults
                        open={open}
                        onChange={printResults.onChange}
                        onLoad={printResults.onLoad}
                        state={{
                            report,
                            ...printResults.state,
                        }}
                        onHide={onHide}
                    />
                </Fragment>
            )}
        </Fragment>
    );
};

export default PrintHorizontalCapacityResults;
