import React, { Fragment, useCallback, useState } from 'react';
import {
    ReportFirstPage,
    ResultsFooter,
    ResultsHeader,
} from '../../../../../components';
import MicropileSlipPlaneSummary from '../MicropileSlipPlaneSummary';
import MicropileDeepPlaneSummary from '../MicropileDeepPlaneSummary';
import MicropileCompressionSummary from '../MicropileCompressionSummary';
import VerticalSummary from '../VerticalSummary';
import KoseckiSummary from '../KoseckiSummary';
import styles from './index.module.css';
import ReinforcementSummary from '../ReinforcementSummary';
import PileCptSummary from '../PileCptSummary';
import SheetPileCptSummary from '../SheetPileCptSummary';

const PdfReport = React.forwardRef(({ pdfState }, ref) => {
    const [footerHeight, setFooterHeight] = useState(0);

    return (
        <div className={styles.wrapper} ref={ref}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <td>
                            <div className={styles.headerSpace}></div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <main className={styles.content}>
                                <ol>
                                    <div className={styles.page}>
                                        {pdfState.reportItem.firstPage &&
                                            <Fragment>
                                                <ReportFirstPage
                                                    title="Zestawienie wyników obliczeń"
                                                    author={pdfState.report.author}
                                                    contact={pdfState.report.contact}
                                                    logo={pdfState.report.logo}
                                                />
                                                {
                                                    Object.values(pdfState.reportItem).some(ele => !!ele) &&
                                                    <div className={styles.break}></div>
                                                }
                                            </Fragment>
                                        }
                                        {pdfState.reportItem.micropilePullSlipPlaneSummary && (
                                            <Fragment>
                                                <li className={styles.h1}>
                                                    Mikropal kotwiący z uwzględnieniem klina odłamu
                                                </li>
                                                <MicropileSlipPlaneSummary
                                                    data={pdfState.calculations.filter(
                                                        (ele) =>
                                                            ele.type === 2 &&
                                                            ele.results.results
                                                                .currentCapacity,
                                                    )}
                                                />
                                                {
                                                    (
                                                        pdfState.reportItem.micropilePullDeepPlaneSummary ||
                                                        pdfState.reportItem.micropileCompressionSummary ||
                                                        pdfState.reportItem.verticalSummary ||
                                                        pdfState.reportItem.koseckiSummary ||
                                                        pdfState.reportItem.reinforcementSummary ||
                                                        pdfState.reportItem.pileCptSummary
                                                    ) &&
                                                    <div className={styles.break}></div>
                                                }
                                            </Fragment>
                                        )}
                                        {pdfState.reportItem.micropilePullDeepPlaneSummary && (
                                            <Fragment>
                                                <li className={styles.h1}>
                                                    Mikropal kotwiący z uwzględnieniem głębokiej powierzchni poślizgu
                                                </li>
                                                <MicropileDeepPlaneSummary
                                                    data={pdfState.calculations.filter(
                                                        (ele) =>
                                                            ele.type === 2 &&
                                                            ele.results.results
                                                                .currentCapacity &&
                                                            ele.results.results
                                                                .currentCapacity
                                                                .deepPlate,
                                                    )}
                                                />
                                                {
                                                    (
                                                        pdfState.reportItem.micropileCompressionSummary ||
                                                        pdfState.reportItem.verticalSummary ||
                                                        pdfState.reportItem.koseckiSummary ||
                                                        pdfState.reportItem.reinforcementSummary ||
                                                        pdfState.reportItem.pileCptSummary
                                                    ) &&
                                                    <div className={styles.break}></div>
                                                }
                                            </Fragment>
                                        )}
                                        {pdfState.reportItem.micropileCompressionSummary && (
                                            <Fragment>
                                                <li className={styles.h1}>
                                                    Mikropal wciskany/wyciągany
                                                </li>
                                                <MicropileCompressionSummary
                                                    data={pdfState.calculations.filter(
                                                        (ele) =>
                                                            ele.type === 3 &&
                                                            ele.results
                                                                .currentCapacity,
                                                    )}
                                                />
                                                {
                                                    (
                                                        pdfState.reportItem.verticalSummary ||
                                                        pdfState.reportItem.koseckiSummary ||
                                                        pdfState.reportItem.reinforcementSummary ||
                                                        pdfState.reportItem.pileCptSummary
                                                    ) &&
                                                    <div className={styles.break}></div>
                                                }
                                            </Fragment>
                                        )}
                                        {pdfState.reportItem.verticalSummary && (
                                            <Fragment>
                                                <li className={styles.h1}>
                                                    Nośnośś pionowa pali wg PN-83/B-2482 oraz Eurokodu 7
                                                </li>
                                                <VerticalSummary
                                                    data={pdfState.calculations.filter(
                                                        (ele) =>
                                                            ele.type === 1 &&
                                                            ele.results.currentCapacity,
                                                    )}
                                                />
                                                {
                                                    (
                                                        pdfState.reportItem.koseckiSummary ||
                                                        pdfState.reportItem.reinforcementSummary ||
                                                        pdfState.reportItem.pileCptSummary
                                                    ) &&
                                                    <div className={styles.break}></div>
                                                }
                                            </Fragment>
                                        )}
                                        {pdfState.reportItem.koseckiSummary && (
                                            <Fragment>
                                                <li className={styles.h1}>
                                                    Siły wewnętrzne w palu wg metody uogólnionej
                                                </li>
                                                <KoseckiSummary
                                                    data={pdfState.calculations.filter(
                                                        (ele) =>
                                                            ele.type === 0 &&
                                                            ele.results.currentCapacity,
                                                    )}
                                                />
                                                {
                                                    (
                                                        pdfState.reportItem.reinforcementSummary ||
                                                        pdfState.reportItem.pileCptSummary
                                                    ) &&
                                                    <div className={styles.break}></div>
                                                }
                                            </Fragment>
                                        )}
                                        {pdfState.reportItem.reinforcementSummary && (
                                            <Fragment>
                                                <li className={styles.h1}>
                                                    Zbrojenie przekroju zginanego z siłą podłużną wg Eurokodu 2
                                                </li>
                                                <ReinforcementSummary
                                                    data={pdfState.calculations.filter(
                                                        (ele) =>
                                                            ele.type === 4 && ele.results.isResultsActive && ele.results.isResultsActual,
                                                    )}
                                                />
                                                {
                                                    (
                                                        pdfState.reportItem.pileCptSummary
                                                    ) &&
                                                    <div className={styles.break}></div>
                                                }
                                            </Fragment>
                                        )}
                                        {pdfState.reportItem.pileCptSummary && (
                                            <Fragment>
                                                <li className={styles.h1}>
                                                    Nośność pionowa pala na podstawie sndowania CPT
                                                </li>
                                                <PileCptSummary
                                                    data={pdfState.calculations.filter(
                                                        (ele) =>
                                                            ele.type === 5 && ele.results.isResultsActive && ele.results.isResultsActual,
                                                    )}
                                                />
                                                {
                                                    (
                                                        pdfState.reportItem.sheetpileCptSummary
                                                    ) &&
                                                    <div className={styles.break}></div>
                                                }
                                            </Fragment>
                                        )}
                                        {
                                            pdfState.reportItem.sheetpileCptSummary && (
                                                <Fragment>
                                                <li className={styles.h1}>
                                                    Nośność pionowa grodzic na podstawie metody francuskiego Centralnego Laboratorium Dróg i Mostów w oparciu o wyniki sondowań CPT
                                                </li>
                                                <SheetPileCptSummary
                                                    data={pdfState.calculations.filter(
                                                        (ele) =>
                                                            ele.type === 6 && ele.results.isResultsActive && ele.results.isResultsActual,
                                                    )}
                                                />
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                </ol>
                            </main>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <div
                                style={{ height: footerHeight }}
                                className={styles.footerSpace}></div>
                        </td>
                    </tr>
                </tfoot>
            </table>
            <ResultsHeader
                authorTitle="Autor zestawienia"
                author={pdfState.report.author}
                contact={pdfState.report.contact}
            />
            <ResultsFooter
                title={'Zestawienie wyników'}
                setFooterHeight={useCallback((val) => setFooterHeight(val),[])}
                globalTitle=""
            />
        </div>
    );
});

export default PdfReport;
