import { Fragment } from 'react';
import styles from './index.module.css'

const CorrectDepth = ({ className='', correctDepth = {} }) => {

    const { correctBaseDepth, correctMultiLayerDepth, isCohesive, isSoftLayer } = correctDepth;
    const correctLength = correctBaseDepth >= 1 && correctMultiLayerDepth && !isCohesive && !isSoftLayer;
    const warningDepth = (correctBaseDepth > 0 && correctBaseDepth < 1) && correctMultiLayerDepth && !isCohesive && !isSoftLayer;

    return (
        <Fragment>
            { 
                correctLength 
                ?
                    <p>Pal prawidłowo zagłębiony w gruncie</p> 
                :
                    warningDepth
                    ?
                        <p className={`${styles.acceptableDepth} ${className}`}>Dopuszczalne zagłębienie pala w gruncie uwarstwionym, jednak brak minimalnego zagłębienia podstawy pala w bieżącej warstwie.</p>    
                    :   
                    <Fragment>
                        <ol className={`${styles.wrongDepth} ${className}`}>
                            {!correctBaseDepth && <li><p>Nieprawidłowe zagłębienie pala w gruncie uwarstwionym</p></li>}
                            {correctMultiLayerDepth <= 0 && <li><p>Nieprawidłowe zagłębienie podstawy pala</p></li>}
                            {isCohesive && <li><p>W odległości mniejszej niż 2,5D pod podstawą pala znajduje się warstwa gruntu spoistego</p></li>}
                            {isSoftLayer && <li><p>W odległości mniejszej niż 5D pod podstawą pala znajduje się warstwa gruntu organicznego lub spoistego miękkoplastycznego</p></li>}                                                        
                        </ol>

                    </Fragment>
            }
        </Fragment>
    )
}

export default CorrectDepth