import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { InputField, SectionTitle } from '../../../../components';
import styles from './index.module.css';

const ZwgLevel = ({ state, onChange, onBlur, onKeyDown, maxHeight }) => {
    return (
        <Fragment>
            <SectionTitle title="Parametry profilu" />
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        title={`Rzędna terenu [m.n.p.m.]`}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        onBlur={onBlur}
                        name="groundLevel"
                        type="number"
                        value={state.groundLevel.value}
                        isValid={state.groundLevel.isValid}
                        placeholder="Rzędna terenu">
                        Na profilu podano rzędne w odniesieniu do poziomu
                        terenu, natomiast w nawiasie podano rzędne w odniesieniu
                        do poziomu morza.
                    </InputField>
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        title="Rzędna ZWG [m.p.p.t.]"
                        onChange={onChange}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        value={state.zwg.value}
                        isValid={state.zwg.isValid}
                        type="number"
                        placeholder={'Rzędna zwierciadła wody'}
                        name="zwg">
                        Ujemna wartość oznacza rzędną ZWG powyżej poziomu
                        terenu.
                    </InputField>
                </Col>
            </Row>
        </Fragment>
    );
};

export default ZwgLevel;
