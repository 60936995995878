import { useState } from 'react';

const capacityChartTypes = [
    {
        type: 0,
        name: 'Powierzchnia zbrojenia',
    },
    {
        type: 1,
        name: 'Rozstaw zbrojenia',
    },
    {
        type: 2,
        name: 'Liczba prętów',
    },
    {
        type: 3,
        name: 'Długość zakotwienia',
    },
];
const ReinforcementService = () => {
    const [settings, setSettings] = useState({
        showVertical: true,
        showHorizontal: true,
        showLegend: true,
        showValues: false,
        showDescription: true,
        showValue: true,
        showValue1: true,
        rotateDescriptionX: false,
    });
    const [capacityType, setCapacityType] = useState(0);
    const [capacityName, setCapacityName] = useState('Powierzchnia zbrojenia');
    const [chartNames, setChartNames] = useState({
        0: {
            title: 'Powierzchnia zbrojenia przekroju żelbetowego',
            axisY: 'Powierzchnia zbrojenia [cm2]',
            axisX: 'Profile geologiczne',
            legend: '- powierzchnia zbrojenia As/As1',
            legend1: '- powierzchnia zbrojenia As2',
        },
        1: {
            title: 'Rozstaw zbrojenia w osiach prętów',
            axisY: 'Rozstaw zbrojenia [cm]',
            axisX: 'Profile geologiczne',
            legend: '- rozstaw prętów As/As1',
            legend1: '- rozstaw prętów As2'
        },
        2: {
            title: 'Liczba prętów w przekroju',
            axisY: 'Liczba prętów [-]',
            axisX: 'Profile geologiczne',
            legend: '- liczba prętów As/As1',
            legend1: '- liczba prętów As2',
        },
        3: {
            title: 'Długość zakotwienia prętów zbrojeniowych',
            axisY: 'Długość zakotwienia [mm]',
            axisX: 'Profile geologiczne',
            legend: '- długość zakotwienia zbrojenia rozciąganego',
            legend1: '- długość zakotwienia zbrojenia ściskanego',
        },
    });

    const onChangeChartTitles = (e = {}, type) => {
        if (e.target) {
            const { name, value } = e.target;

            setChartNames((state) => ({
                ...state,
                [type]: {
                    ...state[type],
                    [name]: value,
                },
            }));
        }
    };

    const onToggleChartType = (e) => {
        const currentSelectProject = capacityChartTypes.find(
            (ele) => ele.name === e.target.value,
        );

        if (currentSelectProject) {
            setCapacityType(currentSelectProject.type);
            setCapacityName(currentSelectProject.name);
        }
    };

    const onChangeSettings = (e) => {
        e.preventDefault();
        e.persist();

        const name = e.currentTarget.getAttribute('name');

        setSettings((state) => ({ ...state, [name]: !state[name] }));
    };

    const reinforcement = {
        state: {
            chartNames,
            capacityType,
            capacityName,
            capacityChartTypes,

            settings,
        },
        onChangeChartTitles,
        onToggleChartType,
        onChangeSettings,
    };

    return {
        reinforcement
    };
};

export default ReinforcementService;
