import { Outlet } from "react-router-dom";
import AdminAuthProvider from '../../../context/Admin';

const AdminAuthLayout = () => {
    return (
        <AdminAuthProvider>
            <Outlet />
        </AdminAuthProvider>
    )
}

export default AdminAuthLayout;