import { Fragment } from "react";
import styles from './index.module.css';

const ModelFactors = ({ parameters={} }) => {

    return ( 
        <div className={styles.wrapper}>
            <h2>Parametry obliczeniowe modelu</h2>
            <p>Wsp. uwzględniający efekty długotrwałe [&alpha;<sub>cc</sub>]: <span className={styles.value}>{parameters.alfa.value}</span></p>
            <p>Model granicznej wysokości strefy ściskanej: <span className={styles.value}>{parameters.compressionZone.value === 1
                ?
                    <Fragment>Z graniczną redystrubucją (&#958;<sub>u</sub> = (&#948; - 0.44)/1.25)</Fragment>
                :
                    <Fragment>Z uplastycznieniem stali (&#958;<sub>u</sub> = E<sub>s</sub>&#x3B5;<sub>cu2</sub>/(E<sub>s</sub>&#x3B5;<sub>cu2</sub> + f<sub>yd</sub>))</Fragment>
            }</span></p>
            <p>Model ze wzmocnieniem liniowym: <span className={styles.value}>{parameters.hardeningModel.value ? 'Tak' : 'Nie'}</span></p>            
            <p>Graniczna wysokość strefy ściskanej [&#958;<sub>u</sub>]: <span className={styles.value}>{parameters.compressionZoneValue.value}</span></p>
            {
                parameters.compressionZone.value === 1 &&
                <p>Współczynnik redystrybucji [&#948;]: <span className={styles.value}>{parameters.defaultDelta.value}</span></p>
            }
            <p>Współczynnik materiałowy betonu [y<sub>c</sub>]: <span className={styles.value}>{parameters.yc.value}</span></p>
            <p>Współczynnik materiałowy stali [y<sub>t</sub>]: <span className={styles.value}>{parameters.yt.value}</span></p>

            <p>Uwzględnij zbrojenie minimalne [A<sub>s,min</sub>]: <span className={styles.value}>{parameters.minimumReinforcement.value === 1 ? 'Tak' : 'Nie'}</span></p>

            {
                parameters.minimumReinforcement.value === 1 &&
                <Fragment>
                    <p>Zbrojenie minimalne na rysy [A<sub>s,min,crack</sub>]: <span className={styles.value}>{parameters.minimumReinforcementCrack.value ? 'Tak' : 'Nie'}</span></p>

                    {
                        parameters.minimumReinforcementCrack.value &&
                        <p>Maksymalna szerokość rysy: <span className={styles.value}>{parameters.maxCrack.value} mm</span></p>
                    }
                </Fragment>

            }
        </div>
    )
}

export default ModelFactors