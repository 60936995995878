
const NewProjectHelpers = ({
    initProject=[],
    onLoadProjectName,
    onLoadProfileName
}) => {

    const newProject = () => {

        onLoadProjectName({
            state: 'init',
            value: 'Nowy projekt'
        });
        onLoadProfileName({
            state: 'init',
            value: ''
        });
        initProject.forEach(ele => {
            if(typeof ele === 'function'){
                ele()
            }
        })
    }

    return {
        newProject: newProject
    }
}

export default NewProjectHelpers 