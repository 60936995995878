import { useState } from "react"
import { isNumber } from "../../../utils"
import useDeepCompareEffect from 'use-deep-compare-effect';

const SoilProfileHelpers = ({ 
    soilTableState = [],
}) => {
    const [profile, setProfile] = useState([]);

    useDeepCompareEffect(() => {
        setProfile(() => [...soilTableState.filter(ele => (isNumber(ele.height.value) && ele.height.isValid))])
    },[soilTableState]);

    return {
        soilProfile: {
            height: profile.map(ele => ele.height.value),
            density: profile.map(ele => ele.density.value),
            plasticity: profile.map(ele => ele.plasticity.value), 
            friction: profile.map(ele => ele.friction ? ele.friction.value : ''),
            name: profile.map(ele => ele.name.value),
            qccpt: profile.map(ele => ele.qccpt.value),
            cucpt: profile.map(ele => ele.cucpt.value),
        }
    }

}

export default SoilProfileHelpers