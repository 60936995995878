import React from 'react';
import { useLocation } from 'react-router-dom';
import NavItem from './NavigationItem';
import styles from './index.module.css';

const Navigation = ({
    modelPath = '',
    resultsPath = '',
    actions: { results = {} },
}) => {
    const location = useLocation();
    const { pathname } = location;

    return (
        <div className={styles.navWrapper}>
            <NavItem
                active={pathname}
                css={'project-navigation'}
                to={modelPath}
                title="Model">
                Model
            </NavItem>
            {results.isResultsActive && (
                <NavItem
                    active={pathname}
                    css={'project-navigation'}
                    to={resultsPath}
                    title="Wyniki">
                    Wyniki
                    <span
                        className={`${styles.actualResults} ${
                            results.state.isResultsActual
                                ? ''
                                : styles.wrongResults
                        }`}
                        title={
                            results.state.isResultsActual
                                ? 'Obliczenia są aktualne'
                                : 'Obliczenia są nieaktualne'
                        }></span>
                </NavItem>
            )}
        </div>
    );
};

export default Navigation;
