import { isNumber } from '../../../../utils';

const alfaDegree = ({ id, il }) => {
    if(isNumber(id) && !isNumber(il)){
        if(id <= 0.33){
            return 5;
        } else if(id > 0.33 && id <= 0.67){
            return 6;
        } else if(id > 0.67){
            return 7;
        } else {
            return '';
        }
    } else if(isNumber(il) && !isNumber(id)){
        if(il > 0.5){
            return 1;
        } else if(il > 0 && il <= 0.5) {
            return 4;
        } else if(il < 0) {
            return 6;
        } else {
            return '';
        }
    } else {
        return '';
    }
}

export default alfaDegree