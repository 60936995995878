import stringify from 'fast-json-stable-stringify';
import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    Checkbox,
    InputField,
    SelectField,
    SectionTitle,
} from '../../../../components';
import {
    concretePiles,
    allPileTypesHorizontal,
} from '../../../../config/ProjectConfig';
import styles from './index.module.css';

const PileParameters = ({
    onChange,
    onBlur,
    concreteClasses,
    pileTypes,
    layersHeight,
    pileTechnologies,
    pileShape,
    state,
}) => {
    return (
        <Fragment>
            <SectionTitle title="Parametry pala" />
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <SelectField
                        title="Rodzaj pala"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={state.pileType.value}
                        defaultOption="Wybierz rodzaj pala"
                        isValid={state.pileType.isValid}
                        options={pileTypes}
                        name="pileType"
                        required
                    />
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <SelectField
                        name="pileTechnology"
                        title="Technologia wykonania"
                        value={state.pileTechnology.value}
                        defaultOption="Wybierz technologię pala"
                        isValid={state.pileTechnology.isValid}
                        options={pileTechnologies}
                        onBlur={onBlur}
                        onChange={onChange}
                        required
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        name="diameterPile"
                        type="number"
                        value={state.diameterPile.value}
                        isValid={state.diameterPile.isValid}
                        title="Średnica/szerokość pala [m]"
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholder="Wpisz średnicę pala"
                        required
                    />
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        name="pileLength"
                        type="number"
                        data-height={stringify(layersHeight)}
                        value={state.pileLength.value}
                        isValid={state.pileLength.isValid}
                        title="Długość pala [m]"
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholder="Wpisz długość pala"
                        required
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        name="pileHeadSpot"
                        type="number"
                        data-height={stringify(layersHeight)}
                        value={state.pileHeadSpot.value}
                        isValid={state.pileHeadSpot.isValid}
                        title="Rzędna głowicy pala [m.p.p.t]"
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholder="Wpisz rzędną gł. pala"
                        required
                    />
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <SelectField
                        name="pileShape"
                        title="Kształt przekroju pala"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={state.pileShape.value}
                        isValid={state.pileShape.isValid}
                        defaultValue={state.pileShape.value}
                        defaultOption={state.pileShape.value}
                        options={pileShape}
                        required
                    />
                </Col>
            </Row>
            {(state.pileType.value === allPileTypesHorizontal[1] ||
                state.pileType.value === allPileTypesHorizontal[2]) && (
                <Row>
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <InputField
                            name="thickness"
                            type="number"
                            value={state.thickness.value}
                            isValid={state.thickness.isValid}
                            title="Grubość ścianki pala [mm]"
                            onChange={onChange}
                            onBlur={onBlur}
                            placeholder="Wpisz grubość ścianki"
                            required
                        />
                    </Col>
                </Row>
            )}
            {Object.values(concretePiles).includes(state.pileType.value) && (
                <Row>
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <SelectField
                            name="concreteClass"
                            title="Klasa betonu"
                            onChange={onChange}
                            onBlur={onBlur}
                            isValid={state.concreteClass.isValid}
                            value={state.concreteClass.value}
                            defaultValue={state.concreteClass.value}
                            defaultOption={state.concreteClass.value}
                            options={concreteClasses}
                            required
                        />
                    </Col>
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <InputField
                            name="concreteFck"
                            type="number"
                            value={state.concreteFck.value}
                            isValid={state.concreteFck.isValid}
                            onBlur={onBlur}
                            onChange={onChange}
                            title={
                                <Fragment>
                                    f<sub>ck</sub> [MPa]
                                </Fragment>
                            }
                            disable={true}
                            required
                        />
                    </Col>
                </Row>
            )}
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <Checkbox
                        onChange={onChange}
                        title="Rodzaj konstrukcji"
                        name="temporaryContruction"
                        label="Konstrukcja tymczasowa"
                        value={state.temporaryContruction.value}
                    />
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <Checkbox
                        onChange={onChange}
                        title="Rodzaj palowania"
                        name="trestles"
                        label="Pale kozłowe"
                        value={state.trestles.value}
                    />
                </Col>
            </Row>
        </Fragment>
    );
};

export default React.memo(
    PileParameters,
    (prev, next) => stringify(prev) === stringify(next),
);
