import React, { Fragment, useContext } from 'react';
import { MainAlertAndLoading } from '../../../../components';
import { sheetPileCptContext } from '../../../../context/SheetPileCpt';
import ResultsWrapper from './ResultsWrapper';

const Results = ({ openSidebar }) => {
    const {
        results: {
            state: {
                calculations,
                results=[],
                soilTable=[],
                currentCapacity={},
                currentPileLength
            },
            isResultsActive,

            onChangeCurrentSheetPileLength,
            onChangeCurrentCapacity,
        }, 
        globalAlerts={},
        globalLoading={},
    } = useContext(sheetPileCptContext);

    return (
        <Fragment>
            <ResultsWrapper
                isResultsActive={isResultsActive}
                data={{
                    ...calculations,
                    results,
                    soilTable,
                    currentCapacity,
                    currentPileLengthByUser: currentPileLength,
                    openSidebar,
                    onChangeCurrentSheetPileLength,
                    onChangeCurrentCapacity,
                }}
            />
            <MainAlertAndLoading
                alerts={globalAlerts.state.alerts}
                updateAlerts={globalAlerts.updateState}
                loadings={globalLoading.state}
            />
        </Fragment>
    );
};

export default Results;
