import { isNumber } from "../../../utils";

const isDensityCorrect = (id) => isNumber(id) ? id >= 0 && id <= 1 : false;
const isPlasticityCorrect = (il) => isNumber(il) ? il <= 1 : false;

const isMicropileAngleCorrect = (angle) => isNumber(angle) ? angle >=0 && angle <= 90 : false;
const isMicropileCompressionAngleCorrect = (angle) => isNumber(angle) ? angle >=-90 && angle <= 90 : false;
const isSheetPileBaseSpotCorrect = (spot, maxVal) => isNumber(spot) && isNumber(maxVal) && spot > 0 && maxVal >= spot;
const isExcavationBaseCorrect = (spot, maxVal) => isNumber(spot) && isNumber(maxVal) && spot >= 0 && maxVal >= spot;

const isSlipPlateCorrect = (spot, maxVal) => isNumber(spot) && isNumber(maxVal) && spot >= 0 && maxVal >= spot;

const isEmail = /^(?=.{4,500}$)[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,10}$/; 
const isPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{10,500}$/;

const isObjectId = (value) => /^[0-9a-fA-F]{24}$/.test(value) ;

export { isEmail, isPassword, isObjectId, isDensityCorrect, isPlasticityCorrect, isMicropileAngleCorrect, isMicropileCompressionAngleCorrect, isSheetPileBaseSpotCorrect, isExcavationBaseCorrect, isSlipPlateCorrect }
