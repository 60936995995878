
import { useState } from "react";
import { AuthFetch } from "../../../routes";
import { isNumber, roundNumber } from "../../../utils";
import useDeepCompareEffect from "use-deep-compare-effect";

const SubmitCalculationHelpers = ({ 
    calculationInput,
    results, 
    setAlert,
    setLoading,
    calculationInputIsLoaded
}) => {
    const [isValidCalculationInputToSend, setIsValidCalculationInputToSend] = useState(false);
    const [calculationInputToSend, setCalculationToSend] = useState({});

    const onCalculationInputToSend = (obj) => {
        return Object.fromEntries(Object.entries(obj)
                .filter((ele,_,arr) => {
                    const pileShape = arr.find(ele => ele[0] === 'pileShape');

                    if(pileShape[1]){
                        if(pileShape[1].value === 'Kołowy'){
                            return !['coverUp', 'coverBottom', 'width', 'rebarAs1', 'rebarAs2', 'nCut'].includes(ele[0])
                        } else {
                            return !['cover', 'rebar', 'stirrupType'].includes(ele[0])
                        }                    
                    } else {
                        return false;
                    }
                }) 
                .filter((ele,_,arr) => {
                    const imperfection = arr.find(ele => ele[0] === 'ea');
                    const secondOrder = arr.find(ele => ele[0] === 'secondOrder');
                    
                    if(secondOrder[1]){
                        if(!secondOrder[1].value){
                            if(!imperfection[1].value){
                                return !['l','leff', 'bucklingRatio', 'm01', 'm02', 'nominalStiffness', 'nominalCurvature', 'c0', 'c','finalPhi'].includes(ele[0])
                            } else {
                                return !['m01', 'm02', 'nominalStiffness', 'nominalCurvature', 'c0', 'c', 'finalPhi'].includes(ele[0])
                            }
                        } else {
                            const nominalStiffness = arr.find(ele => ele[0] === 'nominalStiffness');

                            if(nominalStiffness[1].value){
                                return !['c'].includes(ele[0])
                            } else {
                                return !['c0'].includes(ele[0])
                            }
                        }                    
                    } else {
                        return false
                    }
                }) 
                .filter((ele,_,arr) => {
                    const compressionZone = arr.find(ele => ele[0] === 'compressionZone');

                    if(compressionZone[1]){
                        if(compressionZone[1].value === 2){
                            return !['defaultDelta'].includes(ele[0])
                        } else {
                            return ele
                        }                    
                    } else {
                        return false
                    }
                }) 
                .filter((ele,_,arr) => {
                    const crackReinforement = arr.find(ele => ele[0] === 'minimumReinforcementCrack');

                    if(crackReinforement[1]){
                        if(!crackReinforement[1].value){
                            return !['maxCrack'].includes(ele[0])
                        } else {
                            return ele
                        }                    
                    } else {
                        return false
                    }
                }) 
                .filter((ele,_,arr) => {
                    const memberType = arr.find(ele => ele[0] === 'memberType');
                    const pileShape = arr.find(ele => ele[0] === 'pileShape');
                    
                    
                    if(memberType[1].value === 'Słup' || pileShape[1].value !== 'Prostokątny'){
                        return !['isCrackCalc','maxCrackCalc', 'finalPhiCrack'].includes(ele[0])
                    } else {
                        const isCrackCalc = arr.find(ele => ele[0] === 'isCrackCalc');
                        if(!isCrackCalc[1].value){
                            return !['maxCrackCalc', 'finalPhiCrack'].includes(ele[0])
                        }
                        return ele
                    }
                })
            )   
    }
    const onIsValidState = (obj) => {
        return Object.entries(obj).every(ele => {
                if(ele[0] === 'internalForces'){
                    return Object.values(ele[1]).every(ele => Object.values(ele).some(ele => ele.isValid))
                } else {
                    return ele[1].isValid
                }
            })
    }

    useDeepCompareEffect(() => {
        setCalculationToSend(onCalculationInputToSend(calculationInput.state));
    },[calculationInput.state]);

    useDeepCompareEffect(() => {
        setIsValidCalculationInputToSend(onIsValidState(calculationInputToSend));
    },[calculationInputToSend]);

    const onSubmitCalculation = (loadingCalculations = true) => {
        try {
            if(isValidCalculationInputToSend){
                const { internalForces, ...rest } = calculationInputToSend;

                if(loadingCalculations){
                    setLoading({ name: 'calculations', loading: true });
                } else {
                    setLoading({ name: 'fetchProject', loading: true });
                }

                AuthFetch({ 
                    url: '/api/reinforcement/submit', 
                    method: 'POST',
                    body: {
                        calculationInput: {
                            internalForces: internalForces.map(ele => Object.entries(ele).reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }),{})),
                            ...Object.entries(rest).reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }),{})
                        }
                    }
                }).then(response => {
                    setLoading({ name: 'calculations', loading: false });
                    setLoading({ name: 'fetchProject', loading: false });

                    if(response.success){
                        if(response.responseData.incorrectDimensions){
                            setAlert({ error: true, message: 'Błędne wymiary przekroju' });
                        } else {
                            const { coverBottom, coverUp, h, b, spiral, circular } = response.responseData.initModelValues;
                            
                            results.updateState({
                                isResultsActual: true,
                                ...response.responseData
                            });
                            results.updateCheckCapacity((state) => {
                                return response.responseData.calculation.map((ele, idx) => {

                                    if(ele.incorrectDimensions){
                                        return {
                                            ...state[idx]
                                        }
                                    } 
                                    if(circular){
                                        const rebar = response.responseData.rebar;
                                        const rebarNumber = Math.ceil(ele.result.as/(Math.PI*0.25*rebar**2*0.01));
                                        const innerD = h - coverBottom - coverUp - 2*spiral - rebar;
                            
                                        const circleAround = innerD * Math.PI;
                                        const angle = Math.asin(rebar/innerD);
                                        const angleDegree = angle*360/Math.PI;

                                        const space = rebarNumber < 2 ? 0 : roundNumber((circleAround - rebarNumber*(Math.PI*innerD*angleDegree/360))/rebarNumber,2);

                                        return {
                                            
                                            index: ele.defaultResults.index,
                                            rebarArea: {
                                                value: '',
                                                isValid: true
                                            },
                                            condition: {
                                                value: '',
                                                isValid: true
                                            },
                                            rebarNumber: {
                                                value: rebarNumber,
                                                isValid:isNumber(rebarNumber) ? true : false
                                            },
                                            rebarDiameter: {
                                                value: rebar,
                                                isValid: isNumber(rebar) ? true : false
                                            },
                                            rebarSpiral: {
                                                value: spiral,
                                                isValid: isNumber(spiral) ? true : false
                                            },
                                            space: {
                                                value: space < 0 ? 0 : space,
                                            },
                                            calcAs: {
                                                value: ele.result.as
                                            },
                                            ned: {
                                                value: ele.defaultResults.ned
                                            },
                                            conditionShear: {
                                                value: results.onCheckShearAs({
                                                    b: ele.shearResult.b, 
                                                    space: ele.shearResult.s, 
                                                    d: ele.shearResult.d,
                                                    memberType: response.responseData.initModelValues.memberType,
                                                    rebar,
                                                    h
                                                })
                                            }
                                        }
                                    } else {
                                        const rebarAs1 = response.responseData.rebarAs1;
                                        const rebarAs2 = response.responseData.rebarAs2;
                                        const rebarNumberAs1 = Math.ceil(ele.result.as1/(Math.PI*0.25*rebarAs1**2*0.01));
                                        const rebarNumberAs2 = Math.ceil(ele.result.as2/(Math.PI*0.25*rebarAs2**2*0.01));
                                        const spaceAs1 = rebarNumberAs1 < 2 
                                            ? 
                                            0 
                                            : 
                                            roundNumber(((b - 2*coverBottom - 2*spiral - rebarNumberAs1*rebarAs1) / (rebarNumberAs1 - 1)),2);
                                        const spaceAs2 = rebarNumberAs2 < 2 
                                            ? 
                                            0 
                                            : 
                                            roundNumber(((b - 2*coverUp - 2*spiral - rebarNumberAs2*rebarAs2) / (rebarNumberAs2 - 1)),2);
                                        
                                        return {
                                            index: ele.defaultResults.index,
                                            rebarAreaAs1: { 
                                                value: '',
                                                isValid: true
                                            },
                                            rebarAreaAs2: {
                                                value: '',
                                                isValid: true
                                            },
                                            rebarNumberAs1: {
                                                value: rebarNumberAs1,
                                                isValid:isNumber(rebarNumberAs1) ? true : false
                                            },
                                            rebarNumberAs2: {
                                                value: rebarNumberAs2,
                                                isValid:isNumber(rebarNumberAs2) ? true : false
                                            },
                                            rebarDiameterAs1: {
                                                value: rebarAs1,
                                                isValid: isNumber(rebarAs1) ? true : false
                                            },
                                            rebarDiameterAs2: {
                                                value: rebarAs2,
                                                isValid: isNumber(rebarAs2) ? true : false
                                            },
                                            rebarSpiral: {
                                                value: spiral,
                                                isValid: isNumber(spiral) ? true : false
                                            },
                                            spaceAs2: {
                                                value: spaceAs2 < 0 ? 0 : spaceAs2,
                                            },
                                            spaceAs1: {
                                                value: spaceAs1 < 0 ? 0 : spaceAs1,
                                            },
                                            calcAs1: {
                                                value: ele.result.as1
                                            },
                                            calcAs2: {
                                                value: ele.result.as2
                                            },
                                            ned: {
                                                value: ele.defaultResults.ned
                                            },
                                            conditionShear: {
                                                value: results.onCheckShearAs({
                                                    b: ele.shearResult.b, 
                                                    space: ele.shearResult.s, 
                                                    d: ele.shearResult.d,
                                                    memberType: response.responseData.initModelValues.memberType,
                                                    rebar: ele.defaultResults.negativeMoment ? rebarAs2 : rebarAs1,
                                                    h
                                                })
                                            },
                                            conditionAs1: {
                                                value: ele.defaultResults.negativeMoment
                                                    ?
                                                        ele.result.x > ele.defaultResults.a1
                                                        ?
                                                        results.onCheckSpaceAs2({
                                                            rebarNumber: rebarNumberAs1,
                                                            space: spaceAs1,
                                                            rebarDiameter: rebarAs1,
                                                            as1: rebarAs2*rebarAs2*0.25*Math.PI*rebarNumberAs2,
                                                            h,
                                                            b,
                                                            
                                                        })
                                                        :
                                                        results.onCheckSpaceAs1({
                                                            rebarNumber: rebarNumberAs1,
                                                            space: spaceAs1,
                                                            rebarDiameter: rebarAs1,
                                                            as2: rebarAs2*rebarAs2*0.25*Math.PI*rebarNumberAs2,
                                                            h,
                                                            b
                                                        })
                                                    :
                                                        ele.result.x > ele.defaultResults.d
                                                        ?
                                                        results.onCheckSpaceAs2({
                                                            rebarNumber: rebarNumberAs1,
                                                            space: spaceAs1,
                                                            rebarDiameter: rebarAs1,
                                                            as1: rebarAs2*rebarAs2*0.25*Math.PI*rebarNumberAs2,
                                                            h,
                                                            b,
                                                            
                                                        })
                                                        :
                                                        results.onCheckSpaceAs1({
                                                            rebarNumber: rebarNumberAs1,
                                                            space: spaceAs1,
                                                            rebarDiameter: rebarAs1,
                                                            as2: rebarAs2*rebarAs2*0.25*Math.PI*rebarNumberAs2,
                                                            h,
                                                            b
                                                        })
                                            },
                                            conditionAs2: {
                                                value: ele.defaultResults.negativeMoment
                                                ?
                                                    ele.result.x > ele.defaultResults.d
                                                    ?
                                                    results.onCheckSpaceAs2({
                                                        rebarNumber: rebarNumberAs2,
                                                        space: spaceAs2,
                                                        rebarDiameter: rebarAs2,
                                                        as1: rebarAs1*rebarAs1*0.25*Math.PI*rebarNumberAs1,
                                                        h,
                                                        b,
                                                        
                                                    })
                                                    :
                                                    results.onCheckSpaceAs1({
                                                        rebarNumber: rebarNumberAs2,
                                                        space: spaceAs2,
                                                        rebarDiameter: rebarAs2,
                                                        as2: rebarAs1*rebarAs1*0.25*Math.PI*rebarNumberAs1,
                                                        h,
                                                        b,
                                                    })
                                                :
                                                    ele.result.x > ele.defaultResults.a2
                                                    ?
                                                    results.onCheckSpaceAs2({
                                                        rebarNumber: rebarNumberAs2,
                                                        space: spaceAs2,
                                                        rebarDiameter: rebarAs2,
                                                        as1: rebarAs1*rebarAs1*0.25*Math.PI*rebarNumberAs1,
                                                        h,
                                                        b
                                                    })                                                
                                                    :
                                                    results.onCheckSpaceAs1({
                                                        rebarNumber: rebarNumberAs2,
                                                        space: spaceAs2,
                                                        rebarDiameter: rebarAs2,
                                                        as2: rebarAs1*rebarAs1*0.25*Math.PI*rebarNumberAs1,
                                                        h,
                                                        b
                                                    })
                                            }
                                        }
                                    }
                                });
                            });                            
                        }
                    } else {
                        setAlert({ error: true, message: 'Błąd podczas wykonywania obliczeń' });
                    }
                })
                .catch(() => {
                    setAlert({ error: true, message: 'Błąd podczas wykonywania obliczeń' });
                    setLoading({ name: 'calculations', loading: false });
                })
            } else {
                setAlert({ error: true, message: 'Nieprawidłowe parametry obliczeń zbrojenia przekroju' });

                calculationInput.updateState(state => {
                    return {
                        ...state,
                        ...Object.entries(calculationInputToSend).reduce((acc, [key, value]) => ({ ...acc, [key]: { value: value.value, isValid: !!value.isValid } }) ,{})
                    }
                });
            }
        } catch(e) {
            setAlert({ error: true, message: 'Błąd podczas wykonywania obliczeń' });
            setLoading({ name: 'calculations', loading: false });
        }
    }

    const submitCalculation = {
        onSubmit: onSubmitCalculation,
        onCalculationInputToSend,
        onIsValidState,
        state: {
            isValidCalculationInputToSend,
            calculationInputIsLoaded
        }
    }
    return {
        submitCalculation
    }
}

export default SubmitCalculationHelpers