import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    Button,
    InputField,
    SectionTitle,
    Table,
    Modal,
    Checkbox,
    RadioInput,
    Subtitle,
} from '../../../../components';
import styles from './index.module.css';

const SaftyFactors = ({
    state,
    isPileGroup,
    isOpenSteelPile,
    isSinglePile,
    onChange,
    onBlur,
    onKeyDown,
}) => {
    const [open, setOpen] = useState({});

    const onOpen = (e) => {
        const target = e.currentTarget;
        if (target) {
            setOpen((state) => ({ ...state, [target.name]: true }));
        }
    };
    const onHide = () => setOpen({});

    return (
        <Fragment>
            <div className={styles.wrapper}>
                <SectionTitle title="Współczynniki technologiczne i bezpieczeństwa" />
                <Row className={styles.row}>
                    <Col xs={12} md={6}>
                        <Button
                            onClick={onOpen}
                            icon={faFolderOpen}
                            css={`
                                ${styles.button} default-button-style
                            `}
                            name="technologyFactorsPN"
                            altTitle="Współczynniki wg PN-83/B-2482"
                            title="PN-83/B-2482"
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <Button
                            onClick={onOpen}
                            icon={faFolderOpen}
                            css={`
                                ${styles.button} default-button-style
                            `}
                            name="technologyFactorsEN"
                            altTitle="Współczynniki wg PN-EN 1997 (EC7)"
                            title="PN-EN 1997 (EC7)"
                        />
                    </Col>
                </Row>
            </div>
            <Modal
                open={open.technologyFactorsPN}
                css={styles.modalOverflow}
                cssDialog={styles.modal}
                onHide={onHide}
                title="Współczynniki wg PN-83/B-2482">
                <Row className={styles.row}>
                    <Col xs={12} sm={6}>
                        <InputField
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.m.value}
                            isValid={state.m.isValid}
                            type="number"
                            title="Współczynnik korekcyjny - m"
                            placeholder="Wsp. od oparcia fundamentu"
                            name="m"
                            disable={true}
                            step={0.001}
                            min={0}
                            required
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <InputField
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.tn.value}
                            isValid={state.tn.isValid}
                            type="number"
                            title="Tarcie negatywne w warstwach nienośnych [kPa]"
                            placeholder="Tarcie negatywne"
                            name="tn"
                            disable={true}
                            step={0.001}
                            min={0}
                            required
                        />
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <Subtitle title="Poziom interpolacji oporów q i t" />
                    </Col>

                    <Col
                        xs={12}
                        sm={6}
                        md={3}
                        className={`${styles.flexEnd} ${styles.flexColumn}`}>
                        <RadioInput
                            onChange={onChange}
                            name="qtInterpolationMethod"
                            label={'Dla spągu każdej nienośnej warstwy gruntu'}
                            checked={state.qtInterpolationMethod.value === 1}
                            value={1}
                        />
                    </Col>
                    <Col
                        xs={12}
                        sm={6}
                        md={3}
                        className={`${styles.flexEnd} ${styles.flexColumn}`}>
                        <RadioInput
                            onChange={onChange}
                            name="qtInterpolationMethod"
                            label={
                                'Dla warstw nienośnych oraz tarcia negatywnego od poziomu terenu'
                            }
                            checked={state.qtInterpolationMethod.value === 2}
                            value={2}
                        />
                    </Col>
                    <Col
                        xs={12}
                        sm={6}
                        md={3}
                        className={`${styles.flexEnd} ${styles.flexColumn}`}>
                        <RadioInput
                            onChange={onChange}
                            name="qtInterpolationMethod"
                            label={'Dowolna rzędna interpolacji oporów q i t'}
                            checked={state.qtInterpolationMethod.value === 3}
                            value={3}
                        />
                    </Col>
                    {state.qtInterpolationMethod.value === 3 && (
                        <Col xs={12} sm={6} md={3}>
                            <InputField
                                title={'Rzędna terenu interpolacji [m.p.p.t.]'}
                                placeholder={'Rzędna terenu'}
                                onChange={onChange}
                                onKeyDown={onKeyDown}
                                onBlur={onBlur}
                                value={state.customInterpolationLevel.value}
                                isValid={state.customInterpolationLevel.isValid}
                                type="number"
                                name="customInterpolationLevel"
                                required
                            />
                        </Col>
                    )}
                </Row>
                {isOpenSteelPile && (
                    <Row className={styles.row}>
                        <Col xs={12} className={styles.columnSpace}>
                            <Checkbox
                                onChange={onChange}
                                title={
                                    <Fragment>
                                        Uwzględnij współczynnik redukcyjny a
                                        <sub>2</sub>
                                    </Fragment>
                                }
                                name="a2reduction"
                                value={state.a2reduction.value}>
                                Współczynnik redukujący nośność na pobocznicy
                                pali stalowych z dnem otwartym. Wyłączenie tego
                                współczynnika spowoduje obliczenie nośności na
                                pobocznicy jak dla pali stalowych z dnem
                                zamkniętym.
                            </Checkbox>
                        </Col>
                    </Row>
                )}
                <Row className={styles.row}>
                    <Col xs={12}>
                        <label className={styles.label}>
                            Parametry pala i gruntu
                        </label>
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        Nr<br></br>[-]
                                    </th>
                                    <th width={140}>
                                        Nazwa gruntu<br></br>[-]
                                    </th>
                                    <th title="Zagłębienie warstwy">
                                        H<br></br>[m.p.p.t.]
                                    </th>
                                    <th title='Wysokość zastępcza'>
                                        H<sub>z</sub>
                                        <br></br>[m.p.p.t.]
                                    </th>
                                    <th title="Głębokość krytyczna dla podstawy pala">
                                        h<sub>ci</sub>
                                        <br></br>[m]
                                    </th>
                                    <th title="Głębokość krytyczna dla pobocznicy pala">
                                        t<sub>ci</sub>
                                        <br></br>[m]
                                    </th>
                                    <th title="Charakterystyczna wytrzymałość gruntu pod podstawą pala" width={75}>
                                        q<sup>(n)</sup>
                                        <br></br>[kPa]
                                    </th>
                                    <th title="Charakterystyczna wytrzymałość gruntu wzdłuż pobocznicy pala" width={75}>
                                        t<sup>(n)</sup>
                                        <br></br>[kPa]
                                    </th>
                                    <th>
                                        &#945;<br></br>[&#176;]
                                    </th>
                                    <th title="Współczynnik bezpieczeństwa dla wytrzymałości gruntu">
                                        &#947;<sub>m</sub>
                                        <br></br>[-]
                                    </th>
                                    <th title="Współczynnik technologiczny podstawy pala">
                                        S<sub>p</sub>
                                        <br></br>[-]
                                    </th>
                                    <th title="Współczynnik technologiczny pobocznicy pala">
                                        S<sub>s</sub>
                                        <br></br>[-]
                                    </th>
                                    <th title="Współczynnik technologiczny do wyciągania pala">
                                        S<sup>w</sup>
                                        <br></br>[-]
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.technologyFactors.map(
                                    (element, index) => {
                                        return (
                                            <tr
                                                className={styles.tr}
                                                key={index}>
                                                <td>{index + 1}</td>
                                                <td className={styles.tdLeft}>
                                                    {element.name}
                                                </td>
                                                <td>{element.height}</td>
                                                <td>{element.hz}</td>
                                                <td>{element.hci}</td>
                                                <td>{element.tci}</td>
                                                <td>{element.qc}</td>
                                                <td>{element.t}</td>
                                                <td>{element.alfa}</td>
                                                <td>
                                                    <InputField
                                                        onChange={onChange}
                                                        onKeyDown={onKeyDown}
                                                        onBlur={onBlur}
                                                        data-row={index}
                                                        value={element.ym.value}
                                                        isValid={
                                                            element.ym.isValid
                                                        }
                                                        type="number"
                                                        name="ym"
                                                        step={0.001}
                                                        min={0}
                                                        disable={true}
                                                        required
                                                    />
                                                </td>
                                                <td>
                                                    <InputField
                                                        onChange={onChange}
                                                        onKeyDown={onKeyDown}
                                                        onBlur={onBlur}
                                                        data-row={index}
                                                        value={element.sp.value}
                                                        isValid={
                                                            element.sp.isValid
                                                        }
                                                        type="number"
                                                        name="sp"
                                                        step={0.001}
                                                        min={0}
                                                        disable={true}
                                                        required
                                                    />
                                                </td>
                                                <td>
                                                    <InputField
                                                        onChange={onChange}
                                                        onKeyDown={onKeyDown}
                                                        onBlur={onBlur}
                                                        data-row={index}
                                                        value={element.ss.value}
                                                        isValid={
                                                            element.ss.isValid
                                                        }
                                                        type="number"
                                                        name="ss"
                                                        step={0.001}
                                                        min={0}
                                                        disable={true}
                                                        required
                                                    />
                                                </td>
                                                <td>
                                                    <InputField
                                                        onChange={onChange}
                                                        onKeyDown={onKeyDown}
                                                        onBlur={onBlur}
                                                        data-row={index}
                                                        value={element.sw.value}
                                                        isValid={
                                                            element.sw.isValid
                                                        }
                                                        type="number"
                                                        name="sw"
                                                        disable={true}
                                                        step={0.001}
                                                        min={0}
                                                        required
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                {isPileGroup.value && !isSinglePile.value && (
                    <Row className={styles.row}>
                        <Col xs={12}>
                            <label className={styles.label}>
                                Parametry fundamentu zastępczego
                            </label>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>
                                            Nr<br></br>[-]
                                        </th>
                                        <th width={140}>
                                            Nazwa gruntu<br></br>[-]
                                        </th>
                                        <th>
                                            H<br></br>[m.p.p.t.]
                                        </th>
                                        <th>
                                            H<sub>z</sub>
                                            <br></br>[m.p.p.t.]
                                        </th>
                                        <th>
                                            h<sub>ci</sub>
                                            <br></br>[m]
                                        </th>
                                        <th>
                                            t<sub>ci</sub>
                                            <br></br>[m]
                                        </th>
                                        <th width={75}>
                                            q<sub>c</sub>
                                            <sup>(n)</sup>
                                            <br></br>[kPa]
                                        </th>
                                        <th width={75}>
                                            t<sup>(n)</sup>
                                            <br></br>[kPa]
                                        </th>
                                        <th>
                                            &#947;<sub>m</sub>
                                            <br></br>[-]
                                        </th>
                                        <th>
                                            S<sub>p</sub>
                                            <br></br>[-]
                                        </th>
                                        <th>
                                            S<sub>s</sub>
                                            <br></br>[-]
                                        </th>
                                        <th>
                                            S<sup>w</sup>
                                            <br></br>[-]
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.technologyFactors.map(
                                        (element, index) => {
                                            return (
                                                <tr
                                                    className={styles.tr}
                                                    key={index}>
                                                    <td>{index + 1}</td>
                                                    <td
                                                        className={
                                                            styles.tdLeft
                                                        }>
                                                        {element.name}
                                                    </td>
                                                    <td>{element.height}</td>
                                                    <td>{element.hz}</td>
                                                    <td>{element.hcifz}</td>
                                                    <td>{element.tcifz}</td>
                                                    <td>{element.qc}</td>
                                                    <td>{element.t}</td>
                                                    <td>
                                                        <InputField
                                                            onChange={onChange}
                                                            onKeyDown={
                                                                onKeyDown
                                                            }
                                                            onBlur={onBlur}
                                                            data-row={index}
                                                            value={
                                                                element.ymfz
                                                                    .value
                                                            }
                                                            isValid={
                                                                element.ymfz
                                                                    .isValid
                                                            }
                                                            type="number"
                                                            name="ymfz"
                                                            disable={true}
                                                            step={0.001}
                                                            min={0}
                                                            required
                                                        />
                                                    </td>
                                                    <td>
                                                        <InputField
                                                            onChange={onChange}
                                                            onKeyDown={
                                                                onKeyDown
                                                            }
                                                            onBlur={onBlur}
                                                            data-row={index}
                                                            value={
                                                                element.spfz
                                                                    .value
                                                            }
                                                            isValid={
                                                                element.spfz
                                                                    .isValid
                                                            }
                                                            type="number"
                                                            name="spfz"
                                                            disable={true}
                                                            step={0.001}
                                                            min={0}
                                                            required
                                                        />
                                                    </td>
                                                    <td>
                                                        <InputField
                                                            onChange={onChange}
                                                            onKeyDown={
                                                                onKeyDown
                                                            }
                                                            onBlur={onBlur}
                                                            data-row={index}
                                                            value={
                                                                element.ssfz
                                                                    .value
                                                            }
                                                            isValid={
                                                                element.ssfz
                                                                    .isValid
                                                            }
                                                            type="number"
                                                            name="ssfz"
                                                            disable={true}
                                                            step={0.001}
                                                            min={0}
                                                            required
                                                        />
                                                    </td>
                                                    <td>
                                                        <InputField
                                                            onChange={onChange}
                                                            onKeyDown={
                                                                onKeyDown
                                                            }
                                                            onBlur={onBlur}
                                                            data-row={index}
                                                            value={
                                                                element.sw.value
                                                            }
                                                            isValid={
                                                                element.swfz
                                                                    .isValid
                                                            }
                                                            type="number"
                                                            name="swfz"
                                                            disable={true}
                                                            step={0.001}
                                                            min={0}
                                                            required
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        },
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                )}
            </Modal>

            <Modal
                open={open.technologyFactorsEN}
                css={styles.modalOverflow}
                cssDialog={styles.modal}
                onHide={onHide}
                title="Współczynniki wg PN-EN 1997">
                <Row className={styles.row}>
                    <Col xs={12} sm={6}>
                        <InputField
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.pnenFactor.value}
                            isValid={state.pnenFactor.isValid}
                            type="number"
                            title="Współczynnik przejściowy między PN a EC7"
                            placeholder="Wsp. przejściowy"
                            name="pnenFactor"
                            disable={true}
                            step={0.001}
                            min={1}
                            required
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <InputField
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.yrd.value}
                            isValid={state.yrd.isValid}
                            type="number"
                            title={useMemo(
                                () => (
                                    <Fragment>
                                        Współczynnik fundamentu - &#947;
                                        <sub>Rd1,2 lub 3</sub>
                                    </Fragment>
                                ),
                                [],
                            )}
                            placeholder="Współczynnik modelu"
                            name="yrd"
                            disable={true}
                            step={0.001}
                            min={1}
                            required
                        />
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12} sm={6}>
                        <InputField
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.profilesNumber.value}
                            isValid={state.profilesNumber.isValid}
                            type="number"
                            title={'Liczba zbadanych profili gruntowych'}
                            placeholder="Liczba profilów"
                            name="profilesNumber"
                            disable={true}
                            step={0.001}
                            min={1}
                            required
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <InputField
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.ksi.value}
                            isValid={state.ksi.isValid}
                            type="number"
                            title={useMemo(
                                () => (
                                    <Fragment>
                                        Współczynnik zależny od liczby badań
                                        podłoża - &#11420;
                                        <sub>
                                            {state.capacityType.value === 1
                                                ? 3
                                                : 4}
                                        </sub>
                                    </Fragment>
                                ),
                                [state.capacityType.value],
                            )}
                            placeholder="Wsp. korelacyjny"
                            name="ksi"
                            disable={true}
                            step={0.001}
                            min={1}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6}>
                        <InputField
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.yt.value}
                            isValid={state.yt.isValid}
                            type="number"
                            title={useMemo(
                                () => (
                                    <Fragment>
                                        Częściowy współczynnik bezpieczeństwa -
                                        &#947;<sub>t</sub> (zestaw R2)
                                    </Fragment>
                                ),
                                [],
                            )}
                            placeholder="Wsp. redukcyjny"
                            name="yt"
                            disable={true}
                            step={0.001}
                            min={1}
                            required
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Row>
                            <Col xs={12} sm={6} className={`${styles.flexEnd}`}>
                                <RadioInput
                                    onChange={onChange}
                                    title="Rodzaj nośności zgodnie z Eurokodem 7"
                                    radioTitle="(Rc,calc)mean"
                                    name="capacityType"
                                    label={useMemo(
                                        () => (
                                            <Fragment>
                                                (R<sub>c;calc</sub>)
                                                <sub>mean</sub>
                                            </Fragment>
                                        ),
                                        [],
                                    )}
                                    checked={state.capacityType.value === 1}
                                    value={1}
                                />
                            </Col>
                            <Col xs={12} sm={6} className={`${styles.flexEnd}`}>
                                <RadioInput
                                    onChange={onChange}
                                    name="capacityType"
                                    radioTitle="(Rc,calc)min"
                                    label={useMemo(
                                        () => (
                                            <Fragment>
                                                (R<sub>c;calc</sub>)
                                                <sub>min</sub>
                                            </Fragment>
                                        ),
                                        [],
                                    )}
                                    checked={state.capacityType.value === 2}
                                    value={2}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <Checkbox
                            onChange={onChange}
                            title="Sztywne zwieńczenie pali"
                            name="rigidPileHead"
                            label={`${
                                state.rigidPileHead.value ? 'Tak' : 'Nie'
                            }`}
                            value={state.rigidPileHead.value}
                        />
                    </Col>
                </Row>
            </Modal>
        </Fragment>
    );
};

export default SaftyFactors;