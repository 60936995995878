import { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Table, Pagination, SectionTitle } from '../../../components';
import { withPagination } from '../../../hoc';
import { isNumber } from '../../../utils';
import isString from '../../../utils/isString';
import round from '../../../utils/roundNumber';
import styles from './index.module.css';

const VerticalSummary = ({
    dataToPaginate = [],
    pagination,
    paginationHelpers = {},
}) => {
    return (
        <Fragment>
            {dataToPaginate.length > 0 && (
                <Row className={styles.row}>
                    <Col xs={12}>
                        <SectionTitle
                            title={`Wyniki nośności pionowej pali wg PN-83/B-02482 oraz Eurokodu 7`}
                        />
                        <Table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>
                                        Nr<br></br>[-]
                                    </th>
                                    <th className={styles.min120} title="Nazwa pliku">
                                        Nazwa pliku<br></br>
                                    </th>
                                    <th className={styles.min120} title="Profil gruntowy">
                                        Nazwa profilu<br></br>
                                    </th>
                                    <th title="Rodzaj pala">
                                        Rodzaj pala<br></br>
                                    </th>
                                    <th title="Technologia wykonania">
                                        Technologia<br></br>
                                    </th>
                                    <th title="Długość pala">
                                        L<br></br>[m]
                                    </th>
                                    <th title="Średnica pala">
                                        D<br></br>[m]
                                    </th>
                                    <th title="Nośność podstawy">
                                        N<sub>p</sub>
                                        <br></br>[kN]
                                    </th>
                                    <th title="Nośność pobocznicy">
                                        N<sub>s</sub>
                                        <br></br>[kN]
                                    </th>
                                    <th
                                        className={styles.bold}
                                        title="Nośność obliczeniowa na wciskanie">
                                        N<sub>t</sub>
                                        <br></br>[kN]
                                    </th>
                                    <th title="Nośność obliczeniowa na wciskanie pala w grupie">
                                        N<sub>tgr</sub>
                                        <br></br>[kN]
                                    </th>
                                    <th title="Nośność obliczeniowa na wyciąganie">
                                        N<sub>tw</sub>
                                        <br></br>[kN]
                                    </th>
                                    <th title="Nośność obliczeniowa na wyciąganie pala w grupie">
                                        N<sub>twgr</sub>
                                        <br></br>[kN]
                                    </th>
                                    <th
                                        className={styles.bold}
                                        title="Nośność obliczeniowa na wciskanie wg EC7">
                                        N<sub>t,ec7</sub>
                                        <br></br>[kN]
                                    </th>

                                    <th title="Nośność graniczna wg EC7">
                                        N<sub>gr,ec7</sub>
                                        <br></br>[kN]
                                    </th>
                                    <th title="Siła do badania próbnego">
                                        N<sub>lt</sub>
                                        <br></br>[kN]
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataToPaginate.map((element, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {paginationHelpers.countingItemsPerPage +
                                                    index +
                                                    1}
                                            </td> 
                                            <td>{element.projectName}</td>
                                            <td>
                                                {isString(element.profileName)
                                                    ? element.profileName
                                                    : '-'}
                                            </td>
                                            <td>
                                                {
                                                    element.results
                                                        .currentCapacity
                                                        .pileType
                                                }
                                            </td>
                                            <td>
                                                {
                                                    element.results
                                                        .currentCapacity
                                                        .pileTechnology
                                                }
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity
                                                        .currentPileLength,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity
                                                        .diameterPile,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity.np,
                                                    1,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity.ns,
                                                    1,
                                                )}
                                            </td>
                                            <td className={styles.bold}>
                                                {round(
                                                    element.results
                                                        .currentCapacity.nt,
                                                    1,
                                                )}
                                            </td>

                                            <td>
                                                {isNumber(
                                                    element.results
                                                        .currentCapacity.ntgr,
                                                )
                                                    ? round(
                                                          element.results
                                                              .currentCapacity
                                                              .ntgr,
                                                          1,
                                                      )
                                                    : '-'}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity.ntw,
                                                    1,
                                                )}
                                            </td>
                                            <td>
                                                {isNumber(
                                                    element.results
                                                        .currentCapacity.nwgr,
                                                )
                                                    ? round(
                                                          element.results
                                                              .currentCapacity
                                                              .nwgr,
                                                          1,
                                                      )
                                                    : '-'}
                                            </td>
                                            <td className={styles.bold}>
                                                {round(
                                                    element.results
                                                        .currentCapacity.ntec7d,
                                                    1,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity.ntec7,
                                                    1,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity
                                                        .ntec7lt,
                                                    1,
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        {paginationHelpers.totalPages > 1 && (
                            <Pagination
                                currentPage={pagination.currentPage}
                                itemsPerPage={pagination.itemsPerPage}
                                totalItems={paginationHelpers.allItemsLength}
                                nextPage={paginationHelpers.nextPage}
                            />
                        )}
                    </Col>
                </Row>
            )}
        </Fragment>
    );
};

export default withPagination({
    component: VerticalSummary,
    returnAllCollection: false,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 15,
    },
});
