import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Checkbox, InputField, SelectField, SectionTitle, RadioInput } from '../../../../components';
import styles from './index.module.css'
import stringify from 'fast-json-stable-stringify';

const MicropileParameters = ({onChange, onBlur, onKeyDown, maxHeight, state={} }) => {    
    return (
        <Fragment>  
            <SectionTitle title="Parametry mikropala" />
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField 
                        title="Rzędna głowicy mikropala [m.p.p.t]"
                        name="micropileHeadSpot"
                        type="number"
                        data-maxheight={stringify(maxHeight)}
                        value={state.micropileHeadSpot.value}
                        isValid={state.micropileHeadSpot.isValid}
                        
                        onChange={onChange} 
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        placeholder="Rzędna głowicy mikropala"
                        required
                    >
                        Ujemna wartość oznacza rzędną głowicy mikropala powyżej poziomu terenu.
                    </InputField>
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField 
                        title="Kąt nachylenia do pionu [&#176;]"
                        name="micropileAngle"
                        type="number"
                        value={state.micropileAngle.value}
                        isValid={state.micropileAngle.isValid}
                        
                        onChange={onChange}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        placeholder="Kąt nachylenia"
                        min={-89}
                        max={89}

                        step={0.001}
                    />
                </Col>
            </Row>
            <Row className={styles.row}>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <RadioInput
                        onChange={onChange} 
                        title="Rodzaj mikropala" 
                        radioTitle="Samowiercący/iniekcja ciśnieniowa"
                        name="micropileType"
                        label="System samowiercący"
                        checked={state.micropileType.value === 1}
                        value={1}
                    />
                </Col>
                <Col xs={12} sm={6} className={`${styles.columnSpace} ${styles.flexEnd}`}>
                    <RadioInput
                        onChange={onChange} 
                        name="micropileType"
                        radioTitle="Iniekcja tradycyjna"
                        label="Iniekcja zwykła"
                        checked={state.micropileType.value === 2}
                        value={2}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <Checkbox
                        onChange={onChange} 
                        title="Wymiaruj żerdź mikropala"
                        name="isSteelRod"
                        label={''}
                        value={state.isSteelRod.value}
                    />
                </Col>  
                {
                    state.isSteelRod.value &&
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <Checkbox
                            onChange={onChange} 
                            title="Uwzględnij wyboczenie żerdzi"
                            name="buckling"
                            label={''}
                            value={state.buckling.value}
                        >
                            Wyboczenie mikropali w gruncie obliczane jest na podstawie opracowania "Knicknachweis von Mikropfählen in geschichteten Böden", którego autorami są dr inż. Robert Ofner oraz prof. dr hab. inż. Heinz Wimmer.
                        </Checkbox>
                    </Col>
                }
            </Row>
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        title="Obliczeniowa siła osiowa [kN]"
                        name="axisLoad"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={state.axisLoad.value}
                        isValid={state.axisLoad.isValid}
                        placeholder="Siła osiowa"
                        type="number"

                        required
                    />
                </Col>   
                {
                    !state.isSteelRod.value ?
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <InputField 
                            title="Średnica koronki [mm]"
                            name="micropileScrewDiameter"
                            type="number"
                            value={state.micropileScrewDiameter.value}
                            isValid={state.micropileScrewDiameter.isValid}
                            
                            onChange={onChange}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            placeholder="Średnica koronki" 
                            min={0}
                            step={0.001}

                            required
                        />
                    </Col>
                    :
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <SelectField 
                            title="Typ żerdzi"
                            onBlur={onBlur}
                            onChange={onChange} 
                            value={state.micropileType.value === 1 ? state.rodType.value : state.rodThreadbarType.value}
                            defaultOption="Wybierz typ żerdzi"
                            isValid={state.micropileType.value === 1 ? state.rodType.isValid : state.rodThreadbarType.isValid}
                            options={state.allRods} 
                            name={state.micropileType.value === 1 ? "rodType" : "rodThreadbarType"}
                        /> 
                    </Col>
                }    
                </Row>
                {
                    state.isSteelRod.value && 
                    <Row>
                        {
                            state.micropileType.value === 1 &&
                            <Col xs={12} sm={6} className={styles.columnSpace}>
                                <SelectField 
                                    title="Typ koronki"
                                    onBlur={onBlur}
                                    onChange={onChange} 
                                    value={state.screwType.value}
                                    defaultOption="Wybierz typ koronki"
                                    isValid={state.screwType.isValid}
                                    options={state.allScrews} 
                                    name="screwType"
                                /> 
                            </Col>                             
                        }
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField 
                                title={state.micropileType.value === 1 ? "Średnica koronki [mm]" : "Średnica otworu [mm]"}
                                name="screwTypeDiameter"
                                type="number"
                                value={state.screwTypeDiameter.value}
                                isValid={state.screwTypeDiameter.isValid}
                                
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                placeholder="Średnica koronki" 
                                min={0}
                                step={0.001}
                                disable={state.micropileType.value === 1 ? true : false}

                                required
                            />
                        </Col>
                    </Row>
                }
                <Row>
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <Checkbox
                            onChange={onChange} 
                            title="Długość wolna mikropala"
                            name="isFreeLength"
                            label={''}
                            value={state.isFreeLength.value}
                        >  
                            Długość wolna mikropala, liczona od rzędnej głowicy (nie uwzględniona do nośności), po której obliczony zostanie odcinek nośny mikropala.
                        </Checkbox>                  
                    </Col>
                    { 
                        state.isFreeLength.value &&
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField
                                title="Długość wolna [m]"
                                name="freeLength"
                                onChange={onChange}
                                onBlur={onBlur}
                                value={state.freeLength.value}
                                isValid={state.freeLength.isValid}
                                placeholder="Długość wolna"
                                type="number"
                                min={0}
                                step={0.001}
                                
                                required
                            />
                        </Col>
                    }
                </Row>
                <Row>
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <Checkbox
                            onChange={onChange} 
                            title="Uwzględnij nośność podstawy"
                            name="baseCapacity"
                            label={''}
                            value={state.baseCapacity.value}
                        >
                            Nośność podstawy mikropali jest zwykle pomijana, ze względu na jej małą wartość w stosunku do nośności pobocznicy, lecz może zostać uwzględniona gdy wykonano dokładne badania podłoża gruntowego lub na podstawie próbnych obciążeń mikropali.
                        </Checkbox>
                    </Col>
                    {
                        state.baseCapacity.value &&
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField 
                                title={"Nośność podstawy [% x Ns]"}
                                name="baseCapacityValue"
                                type="number"
                                value={state.baseCapacityValue.value}
                                isValid={state.baseCapacityValue.isValid}
                                
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                placeholder="Nośność podstawy mikropala" 
                                min={0}
                                step={0.001}
                                disable={true}

                                required
                            >
                                Nośność podstawy przyjęto jako procent nośności pobocznicy. Domyślnie przyjęto wartość nośności podstawy jako 10% nośności pobocznicy.
                            </InputField>
                        </Col>                        
                    }
                </Row>                
        </Fragment>
    )
}

export default MicropileParameters