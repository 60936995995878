import React from 'react';
import styles from './index.module.css';

const SoilLayer = ({
    height, 
    backgroundColor,
    ...rest
}) => {

    return (
        <div 
            {...rest}
            className={`${styles.layer} ${height === 0 ? styles.hiddenLayer : ''}`} 
            style={{ height: `${height}px`, backgroundColor }}
        >
        </div>
    )
}

export default SoilLayer