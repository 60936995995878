import { roles } from "../../config/ProjectConfig";

const planCost = ({ plan, role, plans, freeAccount }) => {
    if(freeAccount){
        return 0;
    } else {
        if(plan === "Subskrypcja roczna" || plan === 2){
            if(role === roles[0]){
                return plans.year.second
            } else if(role === roles[1]){
                return plans.year.first
            } else {
                return ''
            }
        } else if(plan === "Subskrypcja miesięczna" || plan === 1) {
            if(role === roles[0]){
                return plans.month.second
            } else if(role === roles[1]){
                return plans.month.first
            } else {
                return ''
            }
        } else {
            return ''
        }   
    }
                
};


export default planCost