import React, { useState } from 'react';
import { roundNumber, chartsMaxYTick } from '../../../../../utils';
import { withDefaultPrintChart } from '../../../../../hoc';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';
import ApexCharts from "apexcharts";

const DisplacementChart = ({
    series: { displacements = [] },
    chartTitle = '',
    yLabel = '',
    xLabel = '',
    chartOptions = [],
    onLoad,
}) => {
    const [options, setOptions] = useState(chartOptions);
    const [seriesState, setSeriesState] = useState([
        {
            name: '',
            data: [],
        },
    ]);

    useDeepCompareEffect(() => {
        setSeriesState(() => {
            return [
                {
                    name: 'Z',
                    data: [
                        ...displacements.reduce(
                            (acc, ele, index, arr) => {
                                if (index === arr.length - 1) {
                                    return [
                                        ...acc,
                                        [ele, index * 0.1],
                                        [0, index * 0.1],
                                    ];
                                } else {
                                    return [...acc, [ele, index * 0.1]];
                                }
                            },
                            [],
                        ),
                    ],
                },
            ];
        });
    }, [displacements]);

    useDeepCompareEffect(() => {
        const maxDisplacement = Math.max(...displacements);
        const minDisplacement = Math.min(...displacements);

        const maxZ = Math.ceil(
            Math.max(
                ...seriesState[0].data.map((ele) =>
                    roundNumber(ele[1], 0),
                ),
            ),
        );
        const maxVal = roundNumber(
            Math.max(Math.abs(maxDisplacement), Math.abs(minDisplacement)),
            1,
        );

        const { maxNum, maxTick } = chartsMaxYTick(maxZ);

        setOptions((state) => {
            return {
                ...state,
                title: {
                    ...state['title'],
                    text: chartTitle,
                    margin: 20,
                    offsetY: 10,
                    floating: true
                },
                grid: {
                    ...state['grid'],
                    show: true,
                    padding: {
                        top: 50,
                        bottom: 20,
                    }  
                },
                subtitle: {
                    ...state['subtitle'],
                    align: 'center',
                    margin: 0,
                    offsetY: 90,
                    text: `Dmax - ${roundNumber(
                        Math.max(
                            Math.abs(maxDisplacement),
                            Math.abs(minDisplacement),
                        ),
                        2,
                    )} mm`,
                },
                fill: {
                    ...state['fill'],
                    colors: ['#00CED1'],
                },
                stroke: {
                    ...state['stroke'],
                    colors: ['#008B8B'],
                },
                yaxis: {
                    ...state['yaxis'],
                    tickAmount: maxTick,
                    max: maxNum,
                },
                xaxis: {
                    ...state['xaxis'],
                    max: 1.2 * maxVal,
                    min: -1.2 * maxVal,
                    tickAmount: 10,
                    labels: {
                        ...state['xaxis']['labels'],
                        showDuplicates: false,
                        offsetY: 10,
                        formatter: function (value) {
                            return roundNumber(value, 2);
                        },

                    },
                    title: {
                        ...state['xaxis']['title'],
                        offsetY: 30,
                        text: xLabel,
                    },
                },

                dataLabels: {
                    ...chartOptions['dataLabels'],
                    offsetY: 0,
                    offsetX: 0,

                    formatter: function (x, opt) {
                        const currentVal = roundNumber(opt.w.globals.initialSeries[0].data[opt.dataPointIndex][0],0);
    
                        return currentVal === 0
                            ? 
                            ''
                            :
                            opt.dataPointIndex % 7 === 0
                            ?
                                currentVal
                            :
                            ''
                    },
                },
            };
        });
    }, [seriesState, displacements, chartTitle, xLabel, yLabel]);

    useDeepCompareEffect(() => {
        let chart = new ApexCharts(document.querySelector("#displacementChart"), {
            series: seriesState,
            ...options,
            chart: {
                ...options['chart'],
                type: 'area'
            }
        });
        chart.render();

        chart.dataURI().then(({ imgURI }) => {
            onLoad({ displacementChart: imgURI })
        });

        return () => chart.destroy();
    },[seriesState, options]);

    return (
        <div id="displacementChart"></div>
    );
};

export default React.memo(
    withDefaultPrintChart(DisplacementChart),
    (prev, next) => stringify(prev) === stringify(next),
);
