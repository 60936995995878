import { Fragment } from 'react';
import { Table } from 'react-bootstrap';
import { isNumber } from '../../../../../utils';
import round from '../../../../../utils/roundNumber';
import styles from './index.module.css';

const SteelRodTable = ({ screwType, steelRod = {} }) => {
    return (
        <div className={styles.wrapper}>
            {steelRod && (
                <Fragment>
                    <div className={styles.margins}>
                        <h2>Parametry techniczne żerdzi</h2>
                    </div>
                    <Table className={styles.table}>
                        <thead>
                            <tr>
                                <th>
                                    Nazwa<br></br>[-]
                                </th>
                                <th>
                                    Rodzaj<br></br>[-]
                                </th>
                                {screwType && (
                                    <th>
                                        Koronka<br></br>[-]
                                    </th>
                                )}
                                {steelRod.subname && (
                                    <th>
                                        Typ<br></br>[-]
                                    </th>
                                )}
                                <th>
                                    d<sub>zew</sub>
                                    <br></br>[mm]
                                </th>
                                {isNumber(steelRod.din) && (
                                    <th>
                                        d<sub>wew</sub>
                                        <br></br>[mm]
                                    </th>
                                )}
                                <th>
                                    a<br></br>[mm<sup>2</sup>]
                                </th>
                                <th>
                                    f<sub>u</sub>
                                    <br></br>[kN]
                                </th>
                                <th>
                                    f<sub>upl</sub>
                                    <br></br>[kN]
                                </th>
                                {isNumber(steelRod.rmk) && (
                                    <th>
                                        R<sub>m,k</sub>
                                        <br></br>[kN]
                                    </th>
                                )}
                                {steelRod.g && (
                                    <th>
                                        Gwint<br></br>[-]
                                    </th>
                                )}
                                <th>
                                    m<br></br>[kg/m]
                                </th>
                                {isNumber(steelRod.wx) && (
                                    <th>
                                        W<sub>x</sub>
                                        <br></br>[cm<sup>3</sup>]
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{steelRod.prod}</td>
                                <td>{steelRod.name}</td>
                                {screwType && (
                                    <td>{screwType}</td>
                                )}
                                {steelRod.subname && (
                                    <td>{steelRod.subname}</td>
                                )}
                                <td>{round(steelRod.dout, 2)}</td>
                                {isNumber(steelRod.din) && (
                                    <td>{round(steelRod.din, 2)}</td>
                                )}
                                <td>{round(steelRod.a, 2)}</td>
                                <td>{round(steelRod.fu, 2)}</td>
                                <td>{round(steelRod.fupl, 2)}</td>
                                {isNumber(steelRod.rmk) && (
                                    <td>{round(steelRod.rmk, 2)}</td>
                                )}
                                {steelRod.g && <td>{steelRod.g}</td>}
                                <td>{round(steelRod.m, 2)}</td>
                                {isNumber(steelRod.wx) && (
                                    <td>{round(steelRod.wx, 2)}</td>
                                )}
                            </tr>
                        </tbody>
                    </Table>

                    <p className={styles.underline}>Oznaczenia:</p>
                    <p>
                        d<sub>zew</sub> -{' '}
                        <span className={styles.value}>
                            średnica zewnętrzna żerdzi
                        </span>
                    </p>
                    {steelRod.din && (
                        <p>
                            d<sub>wew</sub> -{' '}
                            <span className={styles.value}>
                                średnica wewnętrzna żerdzi
                            </span>
                        </p>
                    )}
                    <p>
                        a -{' '}
                        <span className={styles.value}>
                            powierzchnia przekroju żerdzi
                        </span>
                    </p>
                    <p>
                        f<sub>u</sub> -{' '}
                        <span className={styles.value}>
                            charakterystyczna nośność na zerwanie żerdzi
                        </span>
                    </p>
                    <p>
                        f<sub>upl</sub> -{' '}
                        <span className={styles.value}>
                            charakterystyczna nośność plastyczna żerdzi
                        </span>
                    </p>
                    {steelRod.rmk && (
                        <p>
                            R<sub>m,k</sub> -{' '}
                            <span className={styles.value}>
                                charakterystyczna nośność żerdzi
                            </span> 
                        </p>
                    )}
                    <p>
                        m - <span className={styles.value}>masa żerdzi</span>
                    </p>
                    {steelRod.wx && (
                        <p>
                            W<sub>x</sub> -{' '}
                            <span className={styles.value}>
                                wskaźnik wytrzymałości żerdzi
                            </span>
                        </p>
                    )}
                </Fragment>
            )}
        </div>
    );
};

export default SteelRodTable;
