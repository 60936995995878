import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { withResults } from '../../../../../hoc';
import { useWindowSize } from '../../../../../hooks';
import MicropileCapacityChart from '../MicropileCapacityChart';
import MicropileCapacityPullTable from '../MicropileCapacityPullTable';
import MicropileCapacityTable from '../MicropileCapacityTable';
import SteelRodTable from '../SteelRodTable';

import styles from './index.module.css';

const ResultsWrapper = ({ data = {}, openSidebar }) => {
    const resultsRef = useRef(null);
    const [resultsModelWidth, setResultsModelWidth] = useState(0);
    const windowSize = useWindowSize();

    useEffect(() => {
        if (resultsRef.current) {
            setResultsModelWidth(resultsRef.current.offsetWidth);
        }
    }, [windowSize.width]);

    return (
        <div className={'micropile-results'} ref={resultsRef}>
            <Row className={styles.row}>
                <Col xs={12}>
                    <MicropileCapacityChart
                        openSidebar={openSidebar}
                        dataToChart={{
                            soilTable: data.results.soilTable,
                            calculationParameters:
                                data.results.calculationParameters,
                            results: data.results.capacity,
                            steelRod: data.results.steelRod,
                            currentMicropileLength: data.currentMicropileLength,
                            resultsModelWidth: resultsModelWidth,
                            onChangeRodModule: data.onChangeRodModule,
                            onChangeCurrentMicropileLength:
                                data.onChangeCurrentMicropileLength,
                            onChangeProdMicropileLength:
                                data.onChangeProdMicropileLength,
                            onChangeCurrentCapacity:
                                data.onChangeCurrentCapacity,
                            rodModule: data.results.rodModule,
                        }}
                    />
                </Col>
            </Row>
            <Row className={styles.row}>
                <Col xs={12}>
                    <MicropileCapacityTable
                        dataToPaginate={data.results.capacity}
                    />
                </Col>
            </Row>
            <Row className={styles.row}>
                <Col xs={12}>
                    <MicropileCapacityPullTable
                        dataToPaginate={data.results.capacity}
                    />
                </Col>
            </Row>
            {data.results.steelRod && (
                <Row className={styles.row}>
                    <Col xs={12}>
                        <SteelRodTable data={data.results.steelRod} />
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default withResults(ResultsWrapper);
