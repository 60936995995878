import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { withResults } from '../../../../../hoc';
import { useWindowSize } from '../../../../../hooks';
import MicropileCapacityChart from '../MicropileCapacityChart';
import SteelRodTable from '../SteelRodTable';

import styles from './index.module.css';

const ResultsWrapper = ({ data = {}, openSidebar }) => {
    const resultsRef = useRef(null);
    const [resultsModelWidth, setResultsModelWidth] = useState(0);

    const windowSize = useWindowSize();

    useEffect(() => {
        if (resultsRef.current) {
            setResultsModelWidth(resultsRef.current.offsetWidth);
        }
    }, [windowSize.width]);

    return (
        <div className={'micropile-results'} ref={resultsRef}>
            <Row className={styles.row}>
                <Col xs={12}>
                    <MicropileCapacityChart
                        openSidebar={openSidebar}
                        soilTable={data.results.soilTable}
                        calculationParameters={
                            data.results.calculationParameters
                        }
                        slideLineArr={data.results.slideLineArr}
                        intersectionPoints={data.results.intersectionPoints}
                        slipPlate={data.results.slipPlate}
                        deepPlate={data.results.deepPlate}
                        steelRod={data.results.steelRod}
                        resultsModelWidth={resultsModelWidth}
                        onChangeRodModule={data.onChangeRodModule}
                        rodModule={data.results.rodModule}
                    />
                </Col>
            </Row>
            {data.results.steelRod && (
                <Row className={styles.row}>
                    <Col xs={12}>
                        <SteelRodTable data={data.results.steelRod} screwType={data.results.calculationParameters.screwType}/>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default withResults(ResultsWrapper);
