import { useCallback, useState } from 'react';
import {
    cohesiveSoilType,
    nonCohesiveSoilType,
    isDensityCorrect,
    isPlasticityCorrect,
} from '../../../config/ProjectConfig';
import { isNumber, makeNumberPositive, stringToNumber } from '../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const defaultRow = {
    name: {
        value: '',
        isValid: null,
    },
    height: {
        value: '',
        isValid: null,
    },
    absHeight: {
        value: '',
    },
    weight: {
        value: '',
        isValid: null,
    },
    underWaterWeight: {
        value: '',
        isValid: null,
    },
    cohesion: {
        value: '',
        isValid: null,
    },
    friction: {
        value: '',
        isValid: null,
    },
    density: {
        value: '',
        isValid: null,
    },
    plasticity: {
        value: '',
        isValid: null,
    },
    edometricModule: {
        value: '',
        isValid: null,
    },
    genesis: {
        value: '',
        isValid: null,
    },
    soilName: {
        value: '',
        isValid: null,
    },
    soilType: {
        value: '',
        isValid: null,
    },
};

const SoilTableHelpers = ({ updatePileProperties, updateResultsState }) => {
    const [soilTableState, setSoilTableState] = useState([{ ...defaultRow }]);
    const soilTableHeights = soilTableState.map(({ height }) => ({ height }));

    useDeepCompareEffect(() => {
        setSoilTableState((state) =>
            state.map((element, index, arr) => {
                if (index === 0) {
                    return {
                        ...element,
                        absHeight: {
                            value: isNumber(element.height.value)
                                ? Number(element.height.value)
                                : '',
                        },
                    };
                } else {
                    return {
                        ...element,
                        absHeight: {
                            value:
                                isNumber(element.height.value) &&
                                isNumber(arr[index - 1].height.value)
                                    ? element.height.value -
                                          arr[index - 1].height.value >
                                      0
                                        ? Number(
                                              (
                                                  element.height.value -
                                                  arr[index - 1].height.value
                                              ).toFixed(2),
                                          )
                                        : ''
                                    : '',
                        },
                    };
                }
            }),
        );
    }, [soilTableHeights]);

    const onBlurSoilTableState = (e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if (input) {
            const { name, value } = input;
            const rowNumber = Number(input.getAttribute('data-row'));
            const valid = input.checkValidity();

            if (name === 'height') {
                updatePileProperties((state) => {
                    const maxLayerHeight = Math.max(
                        ...soilTableState.map((ele) => ele.height.value),
                    );
                    return {
                        ...state,
                        pileLength: {
                            ...state['pileLength'],
                            ...(isNumber(state.pileLength.value) &&
                                isNumber(state.pileHeadSpot.value) && {
                                    isValid:
                                        state.pileLength.value +
                                            state.pileHeadSpot.value <=
                                        maxLayerHeight,
                                }),
                        },
                        pileHeadSpot: {
                            ...state['pileHeadSpot'],
                            ...(isNumber(state.pileHeadSpot.value) && {
                                isValid:
                                    state.pileHeadSpot.value <= maxLayerHeight,
                            }),
                        },
                    };
                });
                setSoilTableState((state) =>
                    state.map((element, index, arr) => {
                        return {
                            ...element,
                            height: {
                                ...element['height'],
                                isValid:
                                    valid &&
                                    arr
                                        .slice(0, index)
                                        .every(
                                            (ele) =>
                                                ele.height.value <
                                                element.height.value,
                                        ),
                            },
                        };
                    }),
                );
            } else {
                setSoilTableState((state) =>
                    state.map((element, index) => {
                        const nonCohesiveCollection = Object.values(
                            nonCohesiveSoilType,
                        ).map((ele) => ele.pl);
                        const cohesiveCollection = Object.values(
                            cohesiveSoilType,
                        ).map((ele) => ele.pl);

                        const isNonCohesiveSoilType =
                            nonCohesiveCollection.includes(
                                element.soilType.value,
                            );
                        const isCohesiveSoilType = cohesiveCollection.includes(
                            element.soilType.value,
                        );

                        const isCurrentNonCohesiveSoilType =
                            nonCohesiveCollection.includes(value);
                        const isCurrentCohesiveSoilType =
                            cohesiveCollection.includes(value);

                        if (rowNumber !== index) {
                            return element;
                        } else {
                            return {
                                ...element,
                                [name]: {
                                    ...element[name],
                                    isValid: valid,
                                },
                                ...(name === 'cohesion' && {
                                    cohesion: {
                                        ...element['cohesion'],
                                        isValid: element.density.isValid
                                            ? null
                                            : valid,
                                    },
                                    soilType: {
                                        value: isPlasticityCorrect(
                                            stringToNumber(
                                                element.plasticity.value,
                                            ),
                                        )
                                            ? isNonCohesiveSoilType
                                                ? ''
                                                : element.soilType.value
                                            : '',
                                        isValid: isPlasticityCorrect(
                                            stringToNumber(
                                                element.plasticity.value,
                                            ),
                                        )
                                            ? isNonCohesiveSoilType
                                                ? null
                                                : element.soilType.isValid
                                            : null,
                                    },
                                }),
                                ...(name === 'genesis' && {
                                    soilType: {
                                        value: isPlasticityCorrect(
                                            stringToNumber(
                                                element.plasticity.value,
                                            ),
                                        )
                                            ? isNonCohesiveSoilType
                                                ? ''
                                                : element.soilType.value
                                            : '',
                                        isValid: isPlasticityCorrect(
                                            stringToNumber(
                                                element.plasticity.value,
                                            ),
                                        )
                                            ? isNonCohesiveSoilType
                                                ? null
                                                : element.soilType.isValid
                                            : null,
                                    },
                                    genesis: {
                                        ...element['genesis'],
                                        isValid: !!element.density.isValid
                                            ? null
                                            : valid,
                                    },
                                }),
                                ...(name === 'density' && {
                                    density: {
                                        ...element['density'],
                                        isValid: element.plasticity.isValid
                                            ? null
                                            : valid,
                                    },
                                    soilType: {
                                        value: isDensityCorrect(
                                            stringToNumber(value),
                                        )
                                            ? isCohesiveSoilType
                                                ? ''
                                                : element.soilType.value
                                            : element.soilType.value,
                                        isValid: isDensityCorrect(
                                            stringToNumber(value),
                                        )
                                            ? isCohesiveSoilType
                                                ? null
                                                : element.soilType.isValid
                                            : element.soilType.isValid,
                                    },
                                }),
                                ...(name === 'plasticity' && {
                                    plasticity: {
                                        ...element['plasticity'],
                                        isValid: element.density.isValid
                                            ? null
                                            : valid,
                                    },
                                    soilType: {
                                        value: isPlasticityCorrect(
                                            stringToNumber(value),
                                        )
                                            ? isNonCohesiveSoilType
                                                ? ''
                                                : element.soilType.value
                                            : element.soilType.value,
                                        isValid: isPlasticityCorrect(
                                            stringToNumber(value),
                                        )
                                            ? isNonCohesiveSoilType
                                                ? null
                                                : element.soilType.isValid
                                            : element.soilType.isValid,
                                    },
                                }),
                                ...(name === 'soilType' &&
                                    isCurrentCohesiveSoilType && {
                                        density: {
                                            value: '',
                                            isValid: null,
                                        },
                                        plasticity: {
                                            value: isNumber(
                                                element.density.value,
                                            )
                                                ? element.density.value
                                                : element.plasticity.value,
                                            isValid: isNumber(
                                                element.density.value,
                                            )
                                                ? isPlasticityCorrect(
                                                      element.density.value,
                                                  )
                                                : element.plasticity.isValid,
                                        },
                                    }),
                                ...(name === 'soilType' &&
                                    isCurrentNonCohesiveSoilType && {
                                        genesis: {
                                            value: '',
                                            isValid: null,
                                        },
                                        plasticity: {
                                            value: '',
                                            isValid: null,
                                        },
                                        density: {
                                            value: isNumber(
                                                element.plasticity.value,
                                            )
                                                ? element.plasticity.value
                                                : element.density.value,
                                            isValid: isNumber(
                                                element.plasticity.value,
                                            )
                                                ? isDensityCorrect(
                                                      element.plasticity.value,
                                                  )
                                                : element.density.isValid,
                                        },
                                    }),
                            };
                        }
                    }),
                );
            }
        }
    };

    const onChangeSoilState = useCallback((e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if (input) {
            const rowNumber = stringToNumber(input.getAttribute('data-row'));
            const { name, value, type } = input;

            updateResultsState((state) => {
                return {
                    ...state,
                    isResultsActual: false,
                };
            });

            setSoilTableState((state) =>
                state.map((element, index, arr) => {
                    if (rowNumber !== index) {
                        return element;
                    } else {
                        return {
                            ...element,
                            ...(name === 'cohesion' && {
                                density: {
                                    value: '',
                                    isValid: null,
                                },
                            }),
                            ...(name === 'density' && {
                                plasticity: {
                                    value: '',
                                    isValid: null,
                                },
                                genesis: {
                                    value: '',
                                    isValid: null,
                                },
                                cohesion: {
                                    value: '',
                                    isValid: null,
                                },
                            }),
                            ...(name === 'plasticity' && {
                                density: {
                                    value: '',
                                    isValid: null,
                                },
                            }),
                            ...(name === 'genesis' && {
                                density: {
                                    value: '',
                                    isValid: null,
                                },
                                plasticity: {
                                    value: isNumber(element.density.value)
                                        ? element.density.value
                                        : element.plasticity.value,
                                    isValid: isNumber(element.density.value)
                                        ? isPlasticityCorrect(
                                              element.density.value,
                                          )
                                        : element.plasticity.isValid,
                                },
                            }),
                            [name]: {
                                value:
                                    type === 'number' && value.length > 0
                                        ? name === 'plasticity'
                                            ? Number(value)
                                            : makeNumberPositive(Number(value))
                                        : value,
                                isValid: true,
                            },
                        };
                    }
                }),
            );
        }
    }, []);

    const addRowToSoilTable = () => {
        updateResultsState((state) => {
            return { 
                ...state,
                isResultsActual: false,
            };
        });
        setSoilTableState((state) => [...state, defaultRow]);
    }
        
    const removeRowFromSoilTable = (i) => {
        updateResultsState((state) => {
            return { 
                ...state,
                isResultsActual: false,
            };
        });
        setSoilTableState((state) => [
            ...state.filter((element, index) => index !== i),
        ]);        
    }

    const onLoadSoilProfile = (soilProfile = []) => {
        const loadedProfile = soilProfile.reduce((acc, ele, idx) => {
            const entries = Object.fromEntries(
                Object.entries(ele).filter((ele) =>
                    Object.keys(defaultRow).includes(ele[0]),
                ),
            );

            return [
                ...acc,
                {
                    ...defaultRow,
                    ...entries,
                },
            ];
        }, []);
        updateResultsState((state) => {
            return { 
                ...state,
                isResultsActual: false,
            };
        });
        setSoilTableState(loadedProfile);
    };

    const soilTable = {
        onChange: onChangeSoilState,
        onBlur: onBlurSoilTableState,
        addRow: addRowToSoilTable,
        removeRow: removeRowFromSoilTable,
        state: soilTableState,
        updateState: setSoilTableState,
        initState: () => setSoilTableState([defaultRow]),
        onLoad: onLoadSoilProfile,
    };

    return {
        soilTable,
    };
};

export default SoilTableHelpers;