import { Fragment } from 'react';
import { Table } from 'react-bootstrap';
import round from '../../../../../utils/roundNumber';
import styles from './index.module.css';
import { isString } from '../../../../../utils';

const MicropileSlipPlaneSummary = ({ data = [] }) => {
    return (
        <Fragment>
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th>
                            Tytuł obliczeń<br></br>[-]
                        </th>
                        <th>
                            Nazwa profilu<br></br>[-]
                        </th>
                        <th>
                            Producent<br></br>[-]
                        </th>
                        <th>
                            Typ żerdzi<br></br>[-]
                        </th>
                        <th>
                            d<br></br>[mm]
                        </th>
                        <th>
                            &#945;<br></br>[&#176;]
                        </th>
                        <th>
                            F<br></br>[kN]
                        </th>
                        <th>
                            L<sub>b</sub>
                            <br></br>[m]
                        </th>
                        <th>
                            L<sub>tot</sub>
                            <br></br>[m]
                        </th>

                        <th>
                            F<sub>rod</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            L<sub>b,rod</sub>
                            <br></br>[m]
                        </th>
                        <th>
                            L<sub>tot,rod</sub>
                            <br></br>[m]
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((element, index) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    {isString(element.model.projectTitle) &&
                                    element.model.projectTitle.length > 0
                                        ? element.model.projectTitle
                                        : '-'}
                                </td>
                                <td>
                                    {isString(element.profileName)
                                        ? element.profileName
                                        : '-'}
                                </td>
                                <td>
                                    {isString(
                                        element.results.results.currentCapacity
                                            .slipPlate.prod,
                                    )
                                        ? element.results.results
                                              .currentCapacity.slipPlate.prod
                                        : '-'}
                                </td>
                                <td>
                                    {isString(
                                        element.results.results.currentCapacity
                                            .slipPlate.name,
                                    )
                                        ? element.results.results
                                              .currentCapacity.slipPlate.name
                                        : '-'}
                                </td>
                                <td>
                                    {round(
                                        element.results.results.currentCapacity
                                            .slipPlate.diameter,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.results.currentCapacity
                                            .slipPlate.micropileAngle,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.results.currentCapacity
                                            .slipPlate.axisLoad,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.results.currentCapacity
                                            .slipPlate.bondLength,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.results.currentCapacity
                                            .slipPlate.totalLength,
                                        2,
                                    )}
                                </td>

                                <td>
                                    {element.results.results.currentCapacity
                                        .slipPlate.steelRod
                                        ? round(
                                              element.results.results
                                                  .currentCapacity.slipPlate
                                                  .steelRod.axisLoad,
                                              2,
                                          )
                                        : '-'}
                                </td>
                                <td>
                                    {element.results.results.currentCapacity
                                        .slipPlate.steelRod
                                        ? round(
                                              element.results.results
                                                  .currentCapacity.slipPlate
                                                  .steelRod.bondLength,
                                              2,
                                          )
                                        : '-'}
                                </td>
                                <td>
                                    {element.results.results.currentCapacity
                                        .slipPlate.steelRod
                                        ? round(
                                              element.results.results
                                                  .currentCapacity.slipPlate
                                                  .steelRod.totalLength,
                                              2,
                                          )
                                        : '-'}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <p className={styles.underline}>Oznaczenia:</p>
            <p>
                d -{' '}
                <span className={styles.value}>
                    średnica koronki wiertniczej mikropala
                </span>
            </p>
            <p>
                &#945; -{' '}
                <span className={styles.value}>
                    kąt nachylenia mikropala do poziomu
                </span>
            </p>
            <p>
                F -{' '}
                <span className={styles.value}>
                    projektowana siła osiowa w mikropalu
                </span>
            </p>
            <p>
                L<sub>b</sub> -{' '}
                <span className={styles.value}>
                    długość buławy kotwiącej (części nośnej) mikropala
                    potrzebnej do przeniesienia siły osiowej F
                </span>
            </p>
            <p>
                L<sub>tot</sub> -{' '}
                <span className={styles.value}>
                    całkowita długość mikropala potrzebna do przeniesienia siły
                    osiowej F
                </span>
            </p>
            <p>
                F<sub>rod</sub> -{' '}
                <span className={styles.value}>
                    siła osiowa równa nośności stalowej żerdzi mikropala
                </span>
            </p>
            <p>
                L<sub>b,rod</sub> -{' '}
                <span className={styles.value}>
                    długość buławy kotwiącej (części nośnej) mikropala
                    potrzebnej do przeniesienia siły osiowej F<sub>rod</sub>
                </span>
            </p>
            <p>
                L<sub>tot,rod</sub> -{' '}
                <span className={styles.value}>
                    całkowita długość mikropala potrzebna do przeniesienia siły
                    osiowej F<sub>rod</sub>
                </span>
            </p>
        </Fragment>
    );
};

export default MicropileSlipPlaneSummary;
