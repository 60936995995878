import { Fragment } from "react";
import { Col } from "react-bootstrap";
import { SectionTitle } from "../../../../../components";
import { roundNumber } from "../../../../../utils";
import styles from './index.module.css';

const SecondOrderEffect = ({ 
    imperfection,
    ned,
    secondOrder={},
    result={}
 }) => {
    const initMed = result.negativeMoment ? -Math.abs(result.initMed) : Math.abs(result.initMed);
    const imperfectionMed = result.negativeMoment ? -Math.abs(result.imperfectionMed) : Math.abs(result.imperfectionMed);
    const secondMed = result.negativeMoment ? -Math.abs(secondOrder.med) : Math.abs(secondOrder.med);
    const m2 = result.negativeMoment ? -Math.abs(secondOrder.m2) : Math.abs(secondOrder.m2)
    return (
        <Fragment>
            {
                imperfection &&
                <Col xs={12} md={6} className={styles.col}>
                    <SectionTitle title="Imperfekcje geometryczne" />
                    <p>Zastępczy mimośród [e<sub>i</sub>] - {roundNumber((imperfectionMed/ned)*1000,2)} mm</p>
                    <p>Moment zginający wywołany imperfekcją geometryczną [M<sub>i</sub>] - {roundNumber(imperfectionMed,2)} kNm</p>
                    <p>Całkowity moment zginający z uwzględnieniem imperfekcji [M<sub>iEd</sub>] - {roundNumber(imperfectionMed + initMed,2)} kNm</p>
                </Col>
            }
            {
                secondOrder.secondOrder &&
                <Col xs={12} md={6} className={styles.col}>
                    <SectionTitle title="Efekty drugiego rzędu" />
                    <p><u>Smukłość słupa</u></p>
                    <p>Smukłość [&lambda;] - {roundNumber(secondOrder.slenderness,2)}</p>
                    <p>Smukłość graniczna [&lambda;<sub>lim</sub>] - {roundNumber(secondOrder.slendernessLim,2)} 
                        <b>
                            {secondOrder.slenderness > secondOrder.slendernessLim ? ' - Należy uwzględnić efekty drugiego rzędu' : ' - Efekty drugiego rzędu można pominąć'}
                        </b>
                    </p>

                    {
                        secondOrder.slenderness > secondOrder.slendernessLim &&
                        <Fragment>
                            <p><u>{
                                secondOrder.nominalCurvature ? 
                                'Metoda nominalnej krzywizny' : 
                                secondOrder.nominalStiffness ? 
                                'Metoda nominalnej sztywności' : 
                                ''}</u>
                            </p>
                            {
                                secondOrder.nominalStiffness &&
                                <Fragment>
                                    <p>Krytyczna siła wyboczeniowa [N<sub>b</sub>] - {roundNumber(secondOrder.nb,2)} kN</p>
                                    <p>Sztywność nominalna [EI] - {roundNumber(secondOrder.EI*0.0001,2)} kNm<sup>2</sup></p>
                                    <p>Obliczeniowy moment pierwszego rzędu [M<sub>0Ed</sub>] - {roundNumber(imperfectionMed + initMed,2)} kNm</p>
                                    <p>Obliczeniowy moment drugiego rzędu [M<sub>Ed</sub>] - {roundNumber(secondMed,2)} kNm</p>
                                </Fragment>
                            }
                            {
                                secondOrder.nominalCurvature &&
                                <Fragment>
                                    <p>Ugięcie [e<sub>2</sub>] - {roundNumber(secondOrder.e2*100,2)} cm</p>
                                    <p>Obliczeniowy moment pierwszego rzędu [M<sub>0Ed</sub>] - {roundNumber(imperfectionMed + initMed,2)} kNm</p>
                                    <p>Nominalny moment drugiego rzędu [M<sub>2</sub>] - {roundNumber(m2,2)} kNm</p>
                                    <p>Obliczeniowy moment drugiego rzędu [M<sub>Ed</sub>] - {roundNumber(secondMed,2)} kNm</p>
                                </Fragment>
                            }
                        </Fragment>
                    }
                </Col>
            }
        </Fragment>
    )
}

export default SecondOrderEffect;