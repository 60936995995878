import React, { Fragment, useState } from 'react';
import { Table as BootstrapTable } from 'react-bootstrap';
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.css';

const Table = ({ children, csvData, csvDataTitle='Eksportuj tabelę do CSV', csvHeaders, csvFilename, className='', filterByDate=false }) => {

    const [showList, setShowList] = useState(false);
    const handleShowList = () => setShowList(state => !state);
    const random6Numbers = () => Math.random().toString().substr(2, 6)

    return (
        <div className={styles.tableResponsive}>
            <div className={styles.headerWrapper}>
                <Fragment>
                    {(csvData && csvFilename) && 
                        <div>
                            <div className={styles.exportData}>
                                <FontAwesomeIcon icon={faBars} onClick={handleShowList}/>
                            </div>    
                            <div className={`${styles.list} ${showList ? '' : styles.hideList} export-table-csv`}>
                                <CSVLink title={csvDataTitle} data={csvData} filename={`${csvFilename}-${random6Numbers()}.csv`} headers={csvHeaders} separator={";"}>{csvDataTitle}</CSVLink>                          
                            </div>
                        </div>
                    }
                    {
                        filterByDate && 
                        <div>
                            {filterByDate}
                        </div>
                    }
                </Fragment>
            </div>

            <div className={`${styles.tableResponsiveWrapper} table-wrapper`}>
                <BootstrapTable className={className} responsive>
                    {children}
                </BootstrapTable>
            </div>
        </div>
    )
}

export default Table