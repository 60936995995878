import { Fragment, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { SelectField } from '../../components';
import { capacityChartsContext } from '../../context/CapacityCharts';
import ProjectsList from './ProjectsList';
import VerticalCapacityChart from './VerticalCapacityChart';
import styles from './index.module.css';
import MicropilePullChart from './MicropilePullChart';
import MicropileCompressionChart from './MicropileCompressionChart';
import KxChart from './KxChart';
import { useOutletContext } from 'react-router-dom';
import ReinforcementChart from './ReinforcementChart';
import PileCptChart from './PileCptChart';
import SheetpileCptChart from './SheetpileCptChart';

const CapacityCharts = () => {  
    const { report } = useOutletContext();
    const {
        projects,
        verticalCapacity,
        micropilePullCapacity,
        micropileCompressionCapacity,
        kxCapacity,
        pileCptCapacity,
        sheetpileCptCapacity,
        reinforcement
    } = useContext(capacityChartsContext);

    return (
        <Fragment>
            <Row>
                <Col xs={12} sm={6}>
                    <ProjectsList
                        onChangeCurrentProject={projects.onChangeCurrentProject}
                        onChangeUser={projects.onChangeUser}
                        currentProject={projects.state.currentProject}
                        currentUserProjects={projects.state.currentUserProjects}
                        state={{
                            allProjects: projects.state.allProjects,
                            userEmail: projects.state.userEmail,
                            currentUser: projects.state.currentUser,
                        }}
                    />
                </Col>
                <Col xs={12} sm={6}>
                    <SelectField
                        title="Rodzaj obliczeń"
                        onChange={projects.onChangeCalculationType}
                        value={projects.state.currentCalcType.name}
                        defaultOption="Wybierz rodzaj obliczeń"
                        options={projects.state.calculationType.map(
                            (ele) => ele.name, 
                        )}
                        name="calculationType"
                    />
                </Col>
            </Row>
            <Row className={styles.row}>
                {projects.state.currentCalcType.type === 0 && (
                    <KxChart
                        collection={projects.state.kxCollection}
                        onChangeChartTitles={kxCapacity.onChangeChartTitles}
                        onToggleChartType={kxCapacity.onToggleChartType}
                        onChangeSettings={kxCapacity.onChangeSettings}
                        state={{
                            report: report,
                            capacityChartTypes:
                                kxCapacity.state.capacityChartTypes,
                            capacityType: kxCapacity.state.capacityType,
                            capacityName: kxCapacity.state.capacityName,
                            chartNames: kxCapacity.state.chartNames,
                            settings: kxCapacity.state.settings,
                        }}
                    />
                )}
                {projects.state.currentCalcType.type === 1 && (
                    <VerticalCapacityChart
                        collection={projects.state.verticalCapactyCollection}
                        onChangeChartTitles={
                            verticalCapacity.onChangeChartTitles
                        }
                        onToggleChartType={verticalCapacity.onToggleChartType}
                        onChangeSettings={verticalCapacity.onChangeSettings}
                        state={{
                            report: report,
                            capacityChartTypes:
                                verticalCapacity.state.capacityChartTypes,
                            capacityType: verticalCapacity.state.capacityType,
                            capacityName: verticalCapacity.state.capacityName,
                            chartNames: verticalCapacity.state.chartNames,
                            settings: verticalCapacity.state.settings,
                        }}
                    />
                )}
                {projects.state.currentCalcType.type === 2 && (
                    <MicropilePullChart
                        collection={projects.state.micropilePullCollection}
                        onChangeChartTitles={
                            micropilePullCapacity.onChangeChartTitles 
                        }
                        onToggleChartType={
                            micropilePullCapacity.onToggleChartType
                        }
                        onChangeSettings={
                            micropilePullCapacity.onChangeSettings
                        }
                        state={{
                            report: report,
                            capacityChartTypes:
                                micropilePullCapacity.state.capacityChartTypes,
                            capacityType:
                                micropilePullCapacity.state.capacityType,
                            capacityName:
                                micropilePullCapacity.state.capacityName,
                            chartNames: micropilePullCapacity.state.chartNames,
                            settings: micropilePullCapacity.state.settings,
                        }}
                    />
                )}
                {projects.state.currentCalcType.type === 3 && (
                    <MicropileCompressionChart
                        collection={
                            projects.state.micropileCompressionCollection
                        }
                        onChangeChartTitles={
                            micropileCompressionCapacity.onChangeChartTitles
                        }
                        onToggleChartType={
                            micropileCompressionCapacity.onToggleChartType
                        }
                        onChangeSettings={
                            micropileCompressionCapacity.onChangeSettings
                        }
                        state={{
                            report: report,
                            capacityChartTypes:
                                micropileCompressionCapacity.state
                                    .capacityChartTypes,
                            capacityType:
                                micropileCompressionCapacity.state.capacityType,
                            capacityName:
                                micropileCompressionCapacity.state.capacityName,
                            chartNames:
                                micropileCompressionCapacity.state.chartNames,
                            settings:
                                micropileCompressionCapacity.state.settings,
                        }}
                    />
                )}
                {projects.state.currentCalcType.type === 4 && (
                    <ReinforcementChart
                        collection={projects.state.reinforcementCollection}
                        onChangeChartTitles={reinforcement.onChangeChartTitles}
                        onToggleChartType={reinforcement.onToggleChartType}
                        onChangeSettings={reinforcement.onChangeSettings}
                        state={{
                            report: report,
                            capacityChartTypes: reinforcement.state.capacityChartTypes,
                            capacityType: reinforcement.state.capacityType,
                            capacityName: reinforcement.state.capacityName,
                            chartNames: reinforcement.state.chartNames,
                            settings: reinforcement.state.settings,
                        }}
                    />
                )}
                {projects.state.currentCalcType.type === 5 && (
                    <PileCptChart
                        collection={projects.state.pileCptCollection}
                        onChangeChartTitles={pileCptCapacity.onChangeChartTitles}
                        onToggleChartType={pileCptCapacity.onToggleChartType}
                        onChangeSettings={pileCptCapacity.onChangeSettings}
                        state={{
                            report: report,
                            capacityChartTypes: pileCptCapacity.state.capacityChartTypes,
                            capacityType: pileCptCapacity.state.capacityType,
                            capacityName: pileCptCapacity.state.capacityName,
                            chartNames: pileCptCapacity.state.chartNames,
                            settings: pileCptCapacity.state.settings,
                        }}
                    />
                )}
                {projects.state.currentCalcType.type === 6 && (
                    <SheetpileCptChart
                        collection={projects.state.sheetpileCptCollection}
                        onChangeChartTitles={sheetpileCptCapacity.onChangeChartTitles}
                        onToggleChartType={sheetpileCptCapacity.onToggleChartType}
                        onChangeSettings={sheetpileCptCapacity.onChangeSettings}
                        state={{
                            report: report,
                            capacityChartTypes: sheetpileCptCapacity.state.capacityChartTypes,
                            capacityType: sheetpileCptCapacity.state.capacityType,
                            capacityName: sheetpileCptCapacity.state.capacityName,
                            chartNames: sheetpileCptCapacity.state.chartNames,
                            settings: sheetpileCptCapacity.state.settings,
                        }}
                    />
                )}
            </Row>
        </Fragment>
    );
};

export default CapacityCharts;
