import { Outlet, useOutletContext } from "react-router-dom";
import ProjectsProvider from "../../../context/Projects";
import { ProjectsNavigation } from "../../../pages";

const ProjectsLayout = () => {
    const context = useOutletContext();

    return (
        <ProjectsProvider>
            <ProjectsNavigation />
            <Outlet context={context}/>
        </ProjectsProvider>
    )
}

export default ProjectsLayout;