import { Table } from 'react-bootstrap';
import styles from './index.module.css';

const RgrKxSection = ({ parameters = [] }) => {
    return (
        <div className={styles.wrapper}>
            <h2>
                Wartości R<sub>gr</sub> i K<sub>x</sub> w węzłach
            </h2>
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th>
                            Nazwa gruntu<br></br>[-]
                        </th>
                        <th>
                            Z<br></br>[m.p.p.t]
                        </th>
                        <th>
                            H<br></br>[m]
                        </th>
                        <th>
                            k<sub>x</sub>
                            <br></br>[kN/m]
                        </th>
                        <th>
                            R<sub>gr</sub>
                            <br></br>[kN]
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {parameters.map((element, index) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{element.name}</td>
                                <td>{element.z}</td>
                                <td>{element.h}</td>
                                <td>{element.kx}</td>
                                <td>{element.rgr}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <p className={styles.underline}>Oznaczenia:</p>
            <p>
                Z -{' '}
                <span className={styles.value}>
                    rzędna zagłębienia pala poniżej poziomu terenu
                </span>
            </p>
            <p>
                H - <span className={styles.value}>grubość warstwy</span>
            </p>
            <p>
                k<sub>x</sub> -{' '}
                <span className={styles.value}>sztywność pozioma gruntu</span>
            </p>
            <p>
                R<sub>gr</sub> -{' '}
                <span className={styles.value}>reakcja graniczna gruntu</span>
            </p>
        </div>
    );
};

export default RgrKxSection;
