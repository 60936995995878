import React, { Fragment, useCallback, useRef, useState } from 'react';
import {
    ResultsFooter, 
    ResultsHeader,
    ReportFirstPage,
} from '../../../../../components';
import CalculationSection from '../CalculationSection';
import styles from './index.module.css';
import ResultsSection from '../Results';

const PdfReport = React.forwardRef(({ pdfState }, ref) => {
    const [footerHeight, setFooterHeight] = useState(0);
    const contentRef = useRef(null);

    return (
        <Fragment>
            <div className={styles.wrapper} ref={ref}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <td>
                                <div className={styles.headerSpace}></div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <main className={styles.content} ref={contentRef}>
                                    <ol>
                                        <div>
                                            {
                                                pdfState.reportItem.firstPage &&
                                                <Fragment>
                                                    <ReportFirstPage
                                                        title="Nośność kleszcza stalowego oraz płyty oporowej na podstawie Eurokodu 3 i danych do projektowania firmy Arcelor Mittal"
                                                        subtitle={pdfState.projectTitle.projectTitle
                                                        }
                                                        author={pdfState.report.author}
                                                        contact={pdfState.report.contact}
                                                        logo={pdfState.report.logo}
                                                    />                                          
                                                </Fragment>
                                            }
                                            {(pdfState.reportItem.projectInfo) && (
                                                <Fragment>
                                                    <div className={styles.break}></div>
                                                    <li className={styles.h1}>
                                                        Dane do obliczeń
                                                    </li> 
                                                    {pdfState.reportItem.projectInfo && (
                                                        <CalculationSection
                                                            calculations={pdfState.calculations}
                                                            c={pdfState.results.plate.c}
                                                            e={pdfState.results.plate.e}
                                                        />
                                                    )}
                                                </Fragment>
                                            )}
                                            {(pdfState.reportItem.capacityDetails) && (
                                                <Fragment>
                                                    <li className={styles.h1}>
                                                        Wyniki obliczeń
                                                    </li>
                                                    {pdfState.reportItem
                                                        .capacityDetails && (
                                                        <ResultsSection
                                                            results={pdfState.results}
                                                        />
                                                    )}
                                                </Fragment>
                                            )}
                                        </div>
                                    </ol>
                                </main>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <div
                                    style={{ height: footerHeight }}
                                    className={styles.footerSpace}></div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <ResultsHeader
                    author={pdfState.report.author}
                    contact={pdfState.report.contact}
                />
                <ResultsFooter
                    setFooterHeight={useCallback((val) => setFooterHeight(val),[])}
                    pdfState={pdfState.reportItem}
                    globalTitle="Nośność kleszcza stalowego oraz płyty oporowej/kotwiącej"
                    title={pdfState.projectTitle.projectTitle}
                />
            </div>
        </Fragment>
    );
});

export default PdfReport;