/*ANP samowiercacy*/

const drillType = {
    0: 'EC',
    1: 'EY-DC',
    2: 'EYY-DC',
    3: 'ES',
    4: 'ESS',
    5: 'EX',
    6: 'EXX',
    7: 'ES-DC',
    8: 'ESS-DC',
    9: 'EW',
}
const drill_R108 = [
    [drillType[9],200],
]
const drill_R64_76 = [
    [drillType[3],130],
    [drillType[3],170],
    [drillType[4],130],
    [drillType[4],180],
    [drillType[5],130],
    [drillType[6],130],
    [drillType[9],130],
    [drillType[9],150],
    [drillType[9],175],
    [drillType[9],200],
]

const drill_R51 = [
    [drillType[0],115],
    [drillType[1],90],
    [drillType[3],90],
    [drillType[3],100],
    [drillType[3],115],
    [drillType[4],76],
    [drillType[4],90],
    [drillType[4],100],
    [drillType[4],115],
    [drillType[4],150],
    [drillType[5],76],
    [drillType[5],90],
    [drillType[6],76],
    [drillType[6],90],
    [drillType[6],115],
    [drillType[7],115],
    [drillType[8],115],
    [drillType[9],130],
    [drillType[9],150],
    [drillType[9],175],
]

const drill_R38 = [
    [drillType[1],76],
    [drillType[1],90],
    [drillType[2],76],
    [drillType[2],90],
    [drillType[3],76],
    [drillType[3],90],
    [drillType[4],76],
    [drillType[4],90],
    [drillType[5],76],
    [drillType[5],90],
    [drillType[6],76],
    [drillType[6],90],
    [drillType[9],100],
    [drillType[9],115],
]

const drill_R32 = [
    [drillType[0],51],
    [drillType[1],76],
    [drillType[2],76],
    [drillType[3],51],
    [drillType[4],51],
    [drillType[5],51],
    [drillType[6],51],
    [drillType[9],76],
]

const anp = [
    {
        prod: 'ANP',
        name: 'R32',
        subname: 'H0210-32',
        fu: 210,
        rmk: 170,
        drill: drill_R32
    },
    {
        prod: 'ANP',
        name: 'R32',
        subname: 'H0250-32',
        fu: 250,
        rmk: 190,
        drill: drill_R32
    },
    {
        prod: 'ANP',
        name: 'R32',
        subname: 'H0280-32',
        fu: 280,
        rmk: 230,
        drill: drill_R32
    },
    {
        prod: 'ANP',
        name: 'R32',
        subname: 'H0360-32',
        fu: 360,
        rmk: 280,
        drill: drill_R32
    },
    {
        prod: 'ANP',
        name: 'R32',
        subname: 'H0400-32',
        fu: 400,
        rmk: 330,
        drill: drill_R32
    },
    {
        prod: 'ANP',
        name: 'R38',
        subname: 'H0420-38',
        fu: 420,
        rmk: 350,
        drill: drill_R38
    },
    {
        prod: 'ANP',
        name: 'R38',
        subname: 'H0500-38',
        fu: 500,
        rmk: 400,
        drill: drill_R38
    },
    {
        prod: 'ANP',
        name: 'R51',
        subname: 'H0630-51',
        fu: 630,
        rmk: 530,
        drill: drill_R51
    },
    {
        prod: 'ANP',
        name: 'R51',
        subname: 'H0800-51',
        fu: 800,
        rmk: 630,
        drill: drill_R51
    },
    //Gwint trapezowy
    {
        prod: 'ANP',
        name: 'RR64',
        subname: 'H1000-64',
        fu: 1000,
        rmk: 800,
        drill: drill_R64_76
    },
    {
        prod: 'ANP',
        name: 'RR64',
        subname: 'H1200-64',
        fu: 1200,
        rmk: 950,
        drill: drill_R64_76
    },
    {
        prod: 'ANP',
        name: 'RR76',
        subname: 'H1400-76',
        fu: 1400,
        rmk: 1075,
        drill: drill_R64_76
    },
    {
        prod: 'ANP',
        name: 'RR76',
        subname: 'H1600-76',
        fu: 1600,
        rmk: 1200,
        drill: drill_R64_76
    },
    {
        prod: 'ANP',
        name: 'RR76',
        subname: 'H1800-76',
        fu: 1800,
        rmk: 1400,
        drill: drill_R64_76
    },
    {
        prod: 'ANP',
        name: 'RR108',
        subname: 'H2500-108',
        fu: 2500,
        rmk: 1850,
        drill: drill_R108
    },
];

export default anp;