import React, { Fragment, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Checkbox, SectionTitle, Modal } from '../../../../components';
import ReportWrapper from './ReportWrapper';
import ReactToPrint from 'react-to-print';
import styles from './index.module.css';

const PrintResults = ({ state, onChange, open, onHide }) => {
    const pdfComponentRef = useRef();

    return (
        <Fragment>
            <Modal
                title="Drukuj zestawienie wyników"
                open={open}
                cssDialog={styles.modal}
                onHide={onHide}
                hideButton={false}>
                <Row>
                    <Col xs={6}>
                        <Checkbox
                            name="allItems"
                            label="Zaznacz wszystko"
                            value={state.reportItem.allItems} 
                            onChange={onChange}
                        />
                    </Col>
                    <Col xs={6}>
                        <Checkbox
                            name="firstPage"
                            label="Strona tytułowa"
                            value={state.reportItem.firstPage} 
                            onChange={onChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <SectionTitle title="Zestawienie wyników obliczeń" />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox
                            name="verticalSummary"
                            label="Nośność pionowa wg PN-83/B-2482 + EC7"
                            value={state.reportItem.verticalSummary}
                            onChange={onChange}
                        />
                        <Checkbox
                            name="koseckiSummary"
                            label="Metoda uogólniona M. Koseckiego"
                            value={state.reportItem.koseckiSummary}
                            onChange={onChange}
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox
                            name="micropilePullSlipPlaneSummary"
                            label="Mikropal kotwiący - klin odłamu"
                            value={
                                state.reportItem.micropilePullSlipPlaneSummary
                            }
                            onChange={onChange}
                        />
                        <Checkbox
                            name="micropilePullDeepPlaneSummary"
                            label="Mikropal kotwiący - głęboka powierzchnia poślizgu"
                            value={
                                state.reportItem.micropilePullDeepPlaneSummary
                            }
                            onChange={onChange}
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox
                            name="micropileCompressionSummary"
                            label="Mikropal wciskany/wyciągany"
                            value={state.reportItem.micropileCompressionSummary}
                            onChange={onChange}
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox
                            name="reinforcementSummary"
                            label="Zbrojenie przekroju"
                            value={state.reportItem.reinforcementSummary}
                            onChange={onChange}
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox
                            name="pileCptSummary"
                            label="Nośność pala na podstawie wyniów CPT"
                            value={state.reportItem.pileCptSummary}
                            onChange={onChange}
                        />                        
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox
                            name="sheetpileCptSummary"
                            label="Nośność ścianki szczelnej na podstawie wyniów CPT"
                            value={state.reportItem.sheetpileCptSummary}
                            onChange={onChange}
                        />                        
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <ReactToPrint
                            pageStyle={`{ size: 210mm 297mm }`}
                            trigger={() => (
                                <Button
                                    css={`
                                        ${styles.button} default-button-style
                                    `}
                                    altTitle="Drukuj"
                                    title="Drukuj zestawienie"
                                    type="button"
                                />
                            )}
                            content={() => pdfComponentRef.current}
                        />
                    </Col>
                </Row>

                <ReportWrapper pdfState={state} ref={pdfComponentRef} />
            </Modal>
        </Fragment>
    );
};

export default PrintResults;
