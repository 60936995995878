import { Fragment } from 'react';
import ProjectNavigation from '../ProjectNavigation';
import { MainTitle, SectionTitle } from '../../../components';
import EditAndSaveActions from '../EditAndSaveActions';
import styles from './index.module.css';
import { useOutletContext } from 'react-router-dom';

const DefaultNavigation = ({
    title = '',
    subtitle = '',
    modelRoute = '',
    resultsRoute = '',
    editProject=true,
    navigation=true,
    geoProfiles=true,
    context = {},
    children,
}) => { 
    const { id, permission } = useOutletContext();

    return (
        <Fragment>
            <MainTitle title={title} subtitle={subtitle} />
            <div className={styles.fileNameWrapper}>
                <SectionTitle title={context.fileNameToSave.fileName.value} />
            </div>
            <div className={navigation ? styles.wrapper : styles.rightWrapper}>
                {
                    navigation &&
                    <div className={styles.flex}>
                        <ProjectNavigation
                            modelPath={modelRoute}
                            resultsPath={resultsRoute}
                            actions={{
                                results: context.results,
                            }}
                        />
                    </div>                    
                }
                <div className={styles.flex}>
                    {children}
                    { 
                        editProject &&
                        <EditAndSaveActions
                            geoProfiles={geoProfiles}
                            actions={{    
                                newProject: context.newProject,
                                saveProject: context.saveProject,
                                editProject: {
                                    isUserRole: permission,
                                    id: id,
                                    ...context.editProject,
                                    ...context.loadAndRemoveProject,
                                },
                                globalLoading: context.globalLoading,
                            }}
                        />
                    }
                </div>
            </div>
        </Fragment>
    );
};

export default DefaultNavigation;
