import React, { createContext, useContext } from 'react';
import { 
    GlobalAlertService,
    GlobalLoadingService,
    StatisticsService,
    ChangePasswordService
} from '../../services/Admin'; 
import { adminAuthContext } from '../Admin';
  
const homeContext = createContext({});

const AdminHomeProvider = ({ children }) => {
    const { permission } = useContext(adminAuthContext);
    const { globalAlerts } = GlobalAlertService();
    const { globalLoading } = GlobalLoadingService();

    const { statistics } = StatisticsService({
        setAlert: globalAlerts.addAlert, 
        
        fetch: {
            statistics: {
                url: '/api/admin/geoincloud/user/application/statistics',
                method: 'GET'
            },
        },
        role: permission
    });
    const { changePassword }            = ChangePasswordService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        fetch: {
            changePassword: {
                url: '/api/admin/geoincloud/auth/change-password',
                method: 'PUT'
            },
        }
    });
  
    
    return ( 
        <homeContext.Provider   
            value={{ 
                globalAlerts,
                globalLoading,
                statistics,
                changePassword
            }}
        >
            {children}
        </homeContext.Provider>
    )
}

export { homeContext }
export default AdminHomeProvider