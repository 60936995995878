import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

const initStateObj = {
    projectInfo: false,
    soilsTable: false,
    micropileProperties: false,
    saftyFactors: false,
    steelRodTable: false,
    micropileDetailsTable: false,
    micropileChart: false,
    results: false,

    firstPage: false,
}; 

const PrintResultsHelpers = (data) => {
    const [reportItem, setRaportItem] = useState({
        ...initStateObj,
        allItems: false,
    });
    const results = data.results;

    useDeepCompareEffect(() => {
        if (results.calculationParameters) {
            setRaportItem((state) => ({
                ...state,
                ...Object.fromEntries(
                    Object.entries(state).map(([key, val]) => {
                        if (key === 'steelRodTable') {
                            return [
                                key,
                                results.calculationParameters
                                    .isSteelRod === true
                                    ? val
                                    : false,
                            ];
                        }
                        return [key, val];
                    }),
                ),
            }));
        }
    }, [results]);

    const onChangeRaportItems = (e) => {
        const input = e.currentTarget.closest('input');

        if (input && data.results.calculationParameters) {
            const { name, checked } = input;

            if (name === 'allItems') {
                setRaportItem((state) => ({
                    ...state,
                    ...Object.fromEntries(
                        Object.entries(state).map(([key, val]) => {
                            if (key === 'steelRodTable') {
                                return [
                                    key,
                                    data.results.calculationParameters
                                        .isSteelRod === true
                                        ? !!checked
                                        : false,
                                ];
                            }
                            return [key, !!checked ? true : false];
                        }),
                    ),
                }));
            } else {
                setRaportItem((state) => ({
                    ...state,
                    [name]: !!checked,
                }));
            }
        }
    };

    const printResults = {
        onChange: onChangeRaportItems,
        initState: () => setRaportItem(initStateObj),
        state: {
            reportItem,
            ...data,
        },
    };

    return {
        printResults,
    };
};

export default PrintResultsHelpers;
