import findDivisors from "../findDivisors";
import roundNumber from "../roundNumber";

const chartsMaxYTick = (maxZ) => {
    const roundedMax = roundNumber(maxZ,0);
    const mod = roundedMax % 2;
    const maxNum = roundNumber(roundedMax + mod,0);
    const divs = findDivisors(maxNum);
    const maxTick = Array.isArray(divs) ? divs.reverse().find(ele => ele <= 15) : roundedMax;

    return {
         maxNum,
         maxTick
    }

}

export default chartsMaxYTick;