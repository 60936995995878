import React, { createContext } from 'react';
import { 
    SoilTableService,
    DefaultSoilService,
    LoadingService, 
    GlobalAlertService,
    ProjectTitleService,
    CalculationSettingsService,
    PilePropertiesService,
    ResultsService,
    SoilProfileService,
    NewProjectService,
    SaftyFactorsService,
    SubmitCalculationsService, 
    SaveSoilService,
    EditSoilService, 
    SaveProjectService,
    EditProjectService,
    PrintResultsService,
    FileNameToSave,
    LoadAndRemoveProjectService
} from '../../services/VerticalCapacity'; 
 
const verticalCapacityContext = createContext({});

const VerticalCapacityProvider = ({ children }) => {
    const { globalAlerts }          = GlobalAlertService();
    const { globalLoading }         = LoadingService();

    const { results } = ResultsService(); 

    const { pileProperties }        = PilePropertiesService({
        updateResultsState: results.updateState
    });
    const { calculationSettings }   = CalculationSettingsService({
        updateResultsState: results.updateState
    }); 
    const { fileNameToSave }        = FileNameToSave();
    const soilProfileFileNameToSave  = FileNameToSave();

    const { soilTable }             = SoilTableService({
        updateCalculationSettings: calculationSettings.updateState,
        updatePileProperties: pileProperties.updateState,
        updateResultsState: results.updateState,
        newEmbankment: calculationSettings.state.newEmbankment,
        embankmentsNumber: calculationSettings.state.embankmentsNumber,
    });

    const { editSoilProfile } = EditSoilService({
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad, 
        updateSoilTable: soilTable.onLoad, 
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,

        onChangeState: soilTable.onChangeState,

        fetch: {
            getProfiles: {
                url: '/api/soils/parameters/profiles',
                method: 'GET'
            },
            getProjectsName: {
                url: '/api/projects/list/names',
                method: 'GET'
            },
            removeProfile: {
                url: '/api/soils/parameters/edit',
                method: 'DELETE'
            },
            loadProfile: {
                url: '/api/soils/parameters/edit',
                method: 'GET'
            }
        }
    });
    const { soilProfile } = SoilProfileService({
        soilTableState: soilTable.state,
        newEmbankment: calculationSettings.state.newEmbankment,
        embankmentsNumber: calculationSettings.state.embankmentsNumber,
        negativeFriction: calculationSettings.state.negativeFriction,
        negativeFrictionLayers: calculationSettings.state.layersName,
    });
    const { saftyFactors } = SaftyFactorsService({
        soilsCollection: soilTable.state, 
        waterLevel: calculationSettings.state.waterLevel,
        pileParameters: pileProperties.state,

        negativeFriction: calculationSettings.state.negativeFriction,
        negativeFrictionLayers: calculationSettings.state.layersName,

        soilStateChange: editSoilProfile.state.stateChanged,

        updateResultsState: results.updateState
    });
    const { defaultSoilLayer } = DefaultSoilService(soilTable.updateState);    
    const { projectTitle } = ProjectTitleService(); 

    const { submitCalculation } = SubmitCalculationsService({
        soilTable: { state: soilTable.state, updateState: soilTable.updateState },
        pileProperties: { state: pileProperties.state, updateState: pileProperties.updateState },
        calculationSettings: { state: calculationSettings.state, updateState: calculationSettings.updateState },
        saftyFactors: { state: saftyFactors.state, updateState: saftyFactors.updateState },
        results: { 
            updateState: results.updateState,
            updateCurrentPileLength: results.onChangeCurrentPileLength,
        },
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading
    });
    const { loadAndRemoveProject } = LoadAndRemoveProjectService({
        profileStateChanged: editSoilProfile.state.stateChanged,
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        urlOnLoad: '/api/projects/list/names',
        urlOnRemove: '/api/vertical-capacity/pn-en/project/',
        urlOnLoadCalculations: '/api/vertical-capacity/pn-en/projects'
    }); 
    const { printResults } = PrintResultsService({
        projectTitle: projectTitle.state, 
        soilsCollection: soilTable.state, 
        calculationsSettings: calculationSettings.state,
        currentPileLength: results.currentPileLength,
        results: {
            ...results.state,
        }
    });
    const { newProject } = NewProjectService({
        onLoadProjectName: fileNameToSave.onLoad, 
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad,
        initProject: [ 
            () => localStorage.removeItem('vertical'),
            pileProperties.initState, 
            calculationSettings.initState,
            globalAlerts.initState,
            globalLoading.initState,
            projectTitle.initState,
            defaultSoilLayer.initState,
            saftyFactors.initState,
            soilTable.initState,
            results.initState,
            printResults.initState
        ]
    });
    const { editProject } = EditProjectService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,

        loadPileProperties: pileProperties.loadProject, 
        loadSoilTable: soilTable.loadProject,
        loadCalculationSettings: calculationSettings.loadProject,
        loadSaftyFactors: saftyFactors.loadProject,
        loadTitle: projectTitle.loadProject,
        loadResults: results.loadProject,

        onLoadFileName: fileNameToSave.onLoad,
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad, 

        onSetMainProjectName: loadAndRemoveProject.onSetMainProjectName,
        onSetSoilProfileMainProjectName: editSoilProfile.onSetMainProjectName,

        initProject: () => {
            defaultSoilLayer.initState();
            saftyFactors.initState();
            soilTable.initState();
        },

        projectId: loadAndRemoveProject.state.currentProjectId
    });
    const { saveSoilProfile } = SaveSoilService({
        fileNameToSave: soilProfileFileNameToSave.fileNameToSave.fileName,
        initMainProjectName: loadAndRemoveProject.state.mainProjectName,
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad, 
        onChangeState: editSoilProfile.onStateChange,
        model: {
            soilsCollection: soilTable.state
        },
        fetch: {
            projectList: {
                url: '/api/projects/list/names',
                method: 'GET'
            },
            saveSoil: {
                url: '/api/soils/parameters/edit',
                method: 'POST'
            },
            editSoil: {
                url: '/api/soils/parameters/edit',
                method: 'PUT'
            },
        },
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading
    }); 
    const { saveProject } = SaveProjectService({
        fileNameToSave: fileNameToSave.fileName,
        onLoadProjectName: fileNameToSave.onLoad, 
        projectTitle: projectTitle.state,

        currentProjectId: editProject.currentProjectId,
        currentProfileId: editProject.currentProfileId,
        currentUserEmail: loadAndRemoveProject.state.currentUser,
        loadProjectState: editProject.loadProjectState,
         
        model: { 
            soilsCollection: soilTable.state, 
            pileParameters: pileProperties.state, 
            calculationsSettings: calculationSettings.state,
            saftyFactors: saftyFactors.state,
        },
        results: { 
            singlePile: results.state.singlePile,
            isResultsActive: results.isResultsActive,
            isResultsActual: results.state.isResultsActual,
            results: results.state.results,
            currentPileLength: results.state.currentPileLength,
            currentCapacity: results.state.currentCapacity,
            soilProfile: results.state.soilProfile,
            pileParameters: results.state.pileParameters,
            groundLevel: results.state.groundLevel
        },
        fetch: {
            save: {
                url: '/api/vertical-capacity/pn-en/saveProject',
                method: 'POST'
            }, 
            overwrite: {
                url: '/api/vertical-capacity/pn-en/saveProject',
                method: 'PUT'
            },
            create: {
                url: '/api/projects/create',
                method: 'POST'
            },
            projectList: {
                url: '/api/projects/list',
                method: 'GET'
            }
        },
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        onChangeState: loadAndRemoveProject.onStateChange,
        onProjectStateChange: saveSoilProfile.onProjectStateChange,
        onProfileStateChange: saveSoilProfile.onProfileStateChange,
        profileStateChange: saveSoilProfile.profileStateChange,
        removeProfileStateChange: editSoilProfile.state.stateChanged,

        saveProjectToLocalStorage: (str) => localStorage.setItem('vertical', str)
    });

    return (
        <verticalCapacityContext.Provider 
            value={{ 
                soilTable, 
                defaultSoilLayer,
                calculationSettings,
                soilProfile,
                projectTitle,
                pileProperties,
                globalAlerts,
                globalLoading,
                saftyFactors,
                submitCalculation,
                newProject,
                results,
                saveSoilProfile,
                editSoilProfile,
                saveProject,
                editProject,
                printResults,
                loadAndRemoveProject,

                fileNameToSave,
            }}
        >
            {children}
        </verticalCapacityContext.Provider>
    )
}

export { verticalCapacityContext }
export default VerticalCapacityProvider