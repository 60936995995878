import { useState } from "react";
import { plans, roles } from '../../../config/ProjectConfig';
import { AuthFetch } from "../../../routes";
import { planCost } from "../../../utils";
import { loadStripe } from '@stripe/stripe-js';
import useDeepCompareEffect from 'use-deep-compare-effect';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_ID);
const subscription = {
    "Subskrypcja miesięczna": 1,
    "Subskrypcja roczna": 2 
}

const RenewSubscription = ({ 
    fetch,
    setAlert,
    updateInvoiceState, 
    setLoading,
    usersToRenewSubscription,

    invoiceState,
    isSaveFormData,
    invoiceType,
}) => {
    const [subusersState, setSubusersState] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);

    useDeepCompareEffect(() => setSubusersState(usersToRenewSubscription),[usersToRenewSubscription]);
    useDeepCompareEffect(() => setTotalPrice(subusersState.reduce((acc,ele) => acc+ele.subscription.price,0)), [subusersState])

    const onChange = (e, id) => {
        const input = e.target.closest('select');

        if(input){
            const { value } = input;
            
            setSubusersState(state => state.map((element) => {
                if(element.id !== id){
                    return element
                } else {                    
                    return {
                        ...element,
                        subscription: {
                            ...element.subscription,
                            plan: value,
                            price: planCost({ plan: value, role: roles[0], plans: plans })
                        }
                    }
                }
            }))
        } 
    };

    const onSubmit = () => {
        const { companyName, nip, firstName, lastName, ...rest } = invoiceState;

        const validFormObject = invoiceType === 1
            ? { companyName, nip, ...rest }
            : invoiceType === 2
            ? { firstName, lastName, ...rest }
            : {};

        const isFormValidInvoice = Object.values(validFormObject).every(
            (ele) => ele.isValid,
        );
    
        if(subusersState.length > 0 && isFormValidInvoice){
            setLoading({ name: 'renewUsersSubscriptions', loading: true });
            AuthFetch({
                url: fetch.renewSubscription.url, 
                method: fetch.renewSubscription.method,
                body: {
                    subusers: subusersState.map(ele => ({
                        id: ele.id,
                        email: ele.email,
                        firstName: ele.firstName,
                        lastName: ele.lastName,
                        price: ele.subscription.price,
                        endDate: ele.subscription.endDate,
                        plan: subscription[ele.subscription.plan]
                    })),
                    totalPrice: totalPrice,
                    invoiceType,
                    saveFormData: isSaveFormData,
                    ...Object.entries(validFormObject).reduce(
                        (acc, [key, value]) => ({ ...acc, [key]: value.value }),
                        {},
                    ),
                }
            })
            .then(async (response) => {
                if(response.success){
                    const { id: sessionId } = response.responseData;

                    const stripe = await stripePromise;

                    const { error } = await stripe.redirectToCheckout({ sessionId });

                    if(error){
                        setLoading({ name: 'renewUsersSubscriptions', loading: false });
                        setAlert({ error: true, message: 'Błąd podczas wykonywania płatności' });
                    } else {
                        setLoading({ name: 'renewUsersSubscriptions', loading: false });
                    }
                } else {
                    setLoading({ name: 'renewUsersSubscriptions', loading: false });
                    setAlert({ error: true, message: 'Błąd podczas wykonywania płatności' });
                }
            })
            .catch(() => {
                setLoading({ name: 'renewUsersSubscriptions', loading: false });
                setAlert({ error: true, message: 'Błąd podczas wykonywania płatności' });
            })
        } else {
            setAlert({ error: true, message: 'Niepoprawne dane do zakupu licencji' });
            updateInvoiceState((state) => { 
                return {
                    ...state,
                    ...Object.entries(validFormObject).reduce(
                        (acc, [key, value]) => ({
                            ...acc,
                            [key]: {
                                value: value.value,
                                isValid: !!value.isValid,
                            },
                        }),
                        {},
                    ),
                };
            });
        }
    }

    return {
        renewSubscription: { 
            onChange: onChange,
            onSubmit: onSubmit,
            state: {
                subusersState,
                totalPrice,
                plans
            }
        }
    }
}

export default RenewSubscription