import { Outlet, useOutletContext } from "react-router-dom"
import HomeProvider from "../../../context/Home"

const HomeLayout = () => {
    return (
        <HomeProvider>
            <Outlet context={useOutletContext()}/>
        </HomeProvider>
    )
}

export default HomeLayout;