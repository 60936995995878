import { Fragment } from "react";
import { isNumber, roundNumber } from "../../../../../utils";
import styles from './index.module.css';

const CapacityResults = ({ 
    currentCapacity,
    yt,
    sheetPileNumbers
 }) => { 
    return (
        <div className={styles.wrapper}>
            <h2>Wyniki</h2>
            <p>Współczynnik bezpieczeństa [y<sub>t</sub>]: <span className={styles.value}>{yt}</span></p>
            <p>Liczba zestawów do analizy [-]: <span className={styles.value}>{sheetPileNumbers}</span></p>
            {
                isNumber(sheetPileNumbers) && sheetPileNumbers > 1 &&
                <Fragment>
                    <p>Nośność podstawy jednego zestawu [R<sub>b,single,d</sub>]: <span className={styles.value}>{roundNumber(currentCapacity.Rbk_single/yt,2).toFixed(2)}kN</span></p>
                    <p>Nośność pobocznicy jednego zestawu [R<sub>s,single,d</sub>]: <span className={styles.value}>{roundNumber(currentCapacity.Rsk_single/yt,2).toFixed(2)}kN</span></p>
                    <p>Nośność ścianki szczelnej na wciskanie jednego zestawu [R<sub>c,single, d</sub>]: <span className={styles.value}>{roundNumber(currentCapacity.Rck_single/yt,2).toFixed(2)}kN</span></p>
                </Fragment>
            }
            <p>Nośność podstawy [R<sub>b,d</sub>]: <span className={styles.value}>{roundNumber(currentCapacity.Rbk/yt,2).toFixed(2)}kN</span></p>
            <p>Nośność pobocznicy [R<sub>s,d</sub>]: <span className={styles.value}>{roundNumber(currentCapacity.Rsk/yt,2).toFixed(2)}kN</span></p>
            {
                isNumber(currentCapacity.Rcd_meter) &&
                <p>Nośność ścianki szczelnej na wciskanie jako ciągłej ściany [R<sub>c,d</sub>]: <span className={styles.value}>{roundNumber(currentCapacity.Rcd_meter,2).toFixed(2)}kN/mb</span></p>
            }
            <p>Nośność ścianki szczelnej na wciskanie [R<sub>c,d</sub>]: <span className={styles.value}>{roundNumber(currentCapacity.Rcd,2).toFixed(2)}kN</span></p>
        </div>
    )
}

export default CapacityResults;