import React, { Fragment, useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Modal, Loading, ConfirmModal, SelectField } from '../../../components';
import styles from './index.module.css';
import ProfilesList from './ProfilesList';
import stringify from 'fast-json-stable-stringify';

const EditSoils = ({
    open,
    loading = true,
    onHide,
    onRemoveProfile,
    onLoadProfile,

    state,
    onChange,
}) => {
    const [openConfirmModal, setOpenConfirmModal] = useState({});
    const [profileName, setProfileName] = useState('');
    const [profileId, setProfileId] = useState('');

    const currentUserProjectsStringify = stringify(state.currentUserProjects);
    const projectsStringify = stringify(state.projects);
    const profilesStringify = stringify(state.profiles);

    const currentUserProjects = useMemo(() => {
        const currentUserProjectsParsed = JSON.parse(
            currentUserProjectsStringify,
        );

        return currentUserProjectsParsed;
    }, [currentUserProjectsStringify]);

    const userProjects = useMemo(() => {
        const projectsParsed = JSON.parse(projectsStringify);

        return projectsParsed.filter((ele) => ele.email !== state.userEmail);
    }, [projectsStringify, state.userEmail]);

    const profiles = useMemo(() => {
        const profilesParsed = JSON.parse(profilesStringify);

        return profilesParsed;
    }, [profilesStringify]);

    const loadingProfiles = useMemo(() => loading, [loading]);

    const onOpenProfileModal = (e) => {
        const target = e.currentTarget;
        const dataProfile = target.getAttribute('data-profile');
        const dataId = target.getAttribute('data-id');
        if (target) {
            setProfileName(dataProfile);
            setProfileId(dataId);
            setOpenConfirmModal((state) => ({ ...state, [target.name]: true }));
        }
    };
    const onDeleteProfile = () => {
        setOpenConfirmModal({});
        onRemoveProfile(profileId);
    };

    const onFetchProfile = () => {
        setOpenConfirmModal({});
        onLoadProfile(profileId);
    };

    const onHideConfirmModal = () => setOpenConfirmModal({});

    return (
        <Fragment>
            <Modal
                css={styles.modal}
                cssDialog={styles.modalDialog}
                open={open}
                onHide={onHide}
                title="Edytuj profil geologiczny">
                {loadingProfiles ? (
                    <Loading oppositeColors={true} />
                ) : (
                    <Fragment>
                        <Row className={styles.row}>
                            {Array.isArray(userProjects) &&
                                userProjects.length > 0 && (
                                    <Col xs={6}>
                                        <SelectField
                                            title="Wybierz użytkownika"
                                            onChange={onChange}
                                            value={state.currentUser}
                                            defaultOption="Moje profile"
                                            disabledDefault={false}
                                            options={userProjects.map(
                                                (ele) =>
                                                    `${ele.firstName} ${ele.lastName} (${ele.email})`,
                                            )}
                                            name="currentUser"
                                        />
                                    </Col>
                                )}
                            <Col xs={6}>
                                <SelectField
                                    title="Wybierz projekt"
                                    onChange={onChange}
                                    value={state.mainProjectName.value}
                                    defaultOption="Wybierz projekt"
                                    options={currentUserProjects.map(
                                        (ele) => ele.name,
                                    )}
                                    name="mainProjectName"
                                />
                            </Col>
                        </Row>

                        <ProfilesList
                            dataToPaginate={profiles.sort(
                                (a, b) =>
                                    new Date(b.updatedAt) -
                                    new Date(a.updatedAt),
                            )}
                            onOpenProfileModal={onOpenProfileModal}
                        />

                        <ConfirmModal
                            open={openConfirmModal.openProfile}
                            onHide={onHideConfirmModal}
                            onConfirm={onFetchProfile}
                            title="Otwórz profil geologiczny">
                            <p className={styles.p}>
                                Na pewno chcesz otworzyć profil -{' '}
                                <b>{profileName}</b>? <br></br>Niezapisany
                                profil geologiczny zostanie utracony.
                            </p>
                        </ConfirmModal>
                        <ConfirmModal
                            open={openConfirmModal.deleteProfile}
                            onHide={onHideConfirmModal}
                            onConfirm={onDeleteProfile}
                            title="Usuń profil geologiczny">
                            <p className={styles.p}>
                                Jesteś pewny, że chcesz usunąć profil
                                geologiczny - <b>{profileName}</b>?
                            </p>
                        </ConfirmModal>
                    </Fragment>
                )}
            </Modal>
        </Fragment>
    );
};

export default EditSoils;
