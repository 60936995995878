import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import styles from './index.module.css';


const RadioInput = ({ onChange, checked, label, radioTitle='', title, name, value, css='', children }) => {

    return ( 
        <div className={styles.wrapper}>
            {title &&
            <div className={styles.titleWrapper}>
                 <label className={styles.title}>{title}</label>
                {
                    children &&
                    <Fragment>
                        <FontAwesomeIcon className={styles.infoIcon} icon={faQuestionCircle}/>
                        <div className={`${styles.info} defaultBox`}>
                            {children}
                        </div> 
                    </Fragment>
                }
            </div>
            }
            <div className={`${styles.switch} ${css} checkbox-switch`}>
                <input className={styles.checkbox} type='radio' name={name} checked={checked} value={value} title={radioTitle} onChange={onChange} />
                <label className={styles.label}><div className={styles.ball} checked={value && 'checked'}></div></label>

                {
                    label &&
                    <Fragment>
                        <span>
                            {label}
                            {
                                children &&
                                <Fragment>
                                    <FontAwesomeIcon className={styles.infoIcon} icon={faQuestionCircle}/>
                                    <div className={`${styles.info} defaultBox`}>
                                        {children}
                                    </div> 
                                </Fragment>
                            }
                        </span>
                    </Fragment>
                }
            </div>            
        </div>
    )
}

export default RadioInput