import React from 'react';
import { Fragment, useCallback, useState } from 'react';
import { ResultsFooter, ResultsHeader } from '../../../components';
import { isNumber, roundNumber } from '../../../utils';
import styles from './index.module.css';

const ChartToPrintReinforcement = React.forwardRef(({
    chartNames,
    capacityType,
    capacityCollection,
    maxValue,
    settings,
    profileAndValue,
    barWidth,
    chartColor,
    chartColor1,
    currentChartHeight, 

    report={},
    globalTitle,
}, ref) => {
    const [footerHeight, setFooterHeight] = useState(0);

    return (
        <div className={styles.wrapper} ref={ref}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <td>
                            <div className={styles.headerSpace}></div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <main className={styles.content}>
                                <ol>
                                    <div>
                                        <div className={styles.chart}>
                                            <div className={styles.title}>
                                                <h1>{chartNames[capacityType].title}</h1>
                                            </div>
                                            <div className={styles.flex}>
                                                <div className={styles.verLabelName}>
                                                    <p>{chartNames[capacityType].axisY}</p>
                                                </div>                    
                                                <div className={styles.chartWrapper}>
                                                    <div className={styles.verticalAxis}>
                                                        {
                                                            capacityCollection.map((ele,idx) => {
                                                                return (
                                                                    <div key={idx} className={styles.verticalItem} style={{ top: `${currentChartHeight - ((Math.abs(ele)/Math.abs(maxValue))*currentChartHeight)}px` }}>
                                                                        <div className={styles.line}></div>
                                                                        {
                                                                            settings.showVertical &&
                                                                            <div className={styles.verticalAxisValue}>
                                                                                <p>{ele}</p>
                                                                            </div>                                                            
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <div className={styles.horizontalAxis}>
                                                        {profileAndValue.map((ele, idx) => {
                                                            return (
                                                                <div key={idx} className={styles.horizontalAxisValue}>
                                                                    <div className={styles.flexBars}>
                                                                        {
                                                                            <div>
                                                                                {settings.showValue && Array.isArray(ele.value) &&
                                                                                ele.value.map((element,index) => {
                                                                                    return (
                                                                                        <Fragment key={index}>
                                                                                            {
                                                                                            isNumber(element) ? (
                                                                                                <Fragment>
                                                                                                    <div className={styles.flexEnd} style={{
                                                                                                            height: `${currentChartHeight - (Math.abs(element)/Math.abs(maxValue))*currentChartHeight}px`,
                                                                                                        }}>
                                                                                                        {settings.showValues && (
                                                                                                            <div className={styles.chartValue}>
                                                                                                                <p style={{lineHeight: `${barWidth > 25 ? 25 : barWidth}px`}}>
                                                                                                                    {roundNumber(element,1)}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className={styles.value} style={{
                                                                                                            height: `${(Math.abs(element) / Math.abs(maxValue))*currentChartHeight}px`,
                                                                                                            width: `${barWidth > 25 ? 25 : barWidth}px`,
                                                                                                            background: `rgba(${chartColor.r},${chartColor.g},${chartColor.b},${chartColor.a})`,
                                                                                                        }}>
                                                                                                        {settings.showDescription && (
                                                                                                            <p>
                                                                                                                {
                                                                                                                    ele.circular
                                                                                                                    ?
                                                                                                                    <Fragment>Śr. zbrojenia, &phi;={ele.rebar}mm</Fragment>
                                                                                                                    :
                                                                                                                    <Fragment>Śr. zbrojenia, &phi;={ele.rebarAs1}mm</Fragment>
                                                                                                                }
                                                                                                            </p>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </Fragment>
                                                                                            ) : (
                                                                                                <div className={styles.flexEnd} style={{
                                                                                                    height: `${currentChartHeight}px`,
                                                                                                }}>
                                                                                                </div>
                                                                                            )
                                                                                            }

                                                                                        </Fragment>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        }
                                                                        {
                                                                            <div>
                                                                                {settings.showValue1 && Array.isArray(ele.value1) &&

                                                                                ele.value1.map((element, index) => {
                                                                                    return (
                                                                                        <Fragment key={index}>
                                                                                            {
                                                                                            isNumber(element) ? (
                                                                                                <Fragment>
                                                                                                    <div className={styles.flexEnd} style={{
                                                                                                            height: `${currentChartHeight - (element/maxValue) *currentChartHeight}px`,
                                                                                                        }}>
                                                                                                        {settings.showValues && (
                                                                                                            <div className={styles.chartValue}>
                                                                                                                <p style={{
                                                                                                                        lineHeight: `${barWidth > 25 ? 25 : barWidth}px`,
                                                                                                                    }}>
                                                                                                                    {roundNumber(element,1)}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className={styles.value} style={{
                                                                                                            height: `${(element/maxValue) *currentChartHeight}px`,
                                                                                                            width: `${barWidth > 25 ? 25 : barWidth}px`,
                                                                                                            background: `rgba(${chartColor1.r},${chartColor1.g},${chartColor1.b},${chartColor1.a})`,
                                                                                                        }}>
                                                                                                        {settings.showDescription && (
                                                                                                            <p>
                                                                                                                {
                                                                                                                    ele.circular
                                                                                                                    ?
                                                                                                                    <Fragment>Śr. zbrojenia, &phi;={ele.rebar}mm</Fragment>
                                                                                                                    :
                                                                                                                    <Fragment>Śr. zbrojenia, &phi;={ele.rebarAs2}mm</Fragment>
                                                                                                                }
                                                                                                            </p>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </Fragment>
                                                                                            ) : (
                                                                                                <div className={styles.flexEnd} style={{
                                                                                                    height: `${currentChartHeight}px`,
                                                                                                }}></div>
                                                                                            )                                            
                                                                                            }
                                                                                        </Fragment>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    {settings.showHorizontal && (
                                                                        <div
                                                                            style={{
                                                                                writingMode: `${settings.rotateDescriptionX ? 'vertical-rl' : ''}`,
                                                                                transform: `${settings.rotateDescriptionX ? 'rotate(180deg)': ''}`,
                                                                                maxHeight: `${settings.rotateDescriptionX ? '150px' : ''}`,
                                                                                maxWidth: `${settings.rotateDescriptionX ? '' : '100px'}`,
                                                                            }}
                                                                            className={styles.horizontalItem}>
                                                                                <p>{ele.profile}</p>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>                        
                                            </div>
                                            <div className={styles.horLabelName}>
                                                <p>{chartNames[capacityType].axisX}</p>
                                            </div>              
                                            {
                                                settings.showLegend &&
                                                <div className={styles.legend}>
                                                    {
                                                        settings.showValue && chartNames[capacityType].legend && Array.isArray(profileAndValue) && profileAndValue.some(ele => isNumber(ele.value[0])) &&
                                                        <div className={styles.legendItem}>
                                                            <div style={{ background: `rgba(${chartColor.r},${chartColor.g},${chartColor.b},${chartColor.a})`}} className={styles.pileLength}></div>
                                                            <p>{chartNames[capacityType].legend}</p>
                                                        </div>                        
                                                    }
                                                    {
                                                        settings.showValue1 && chartNames[capacityType].legend1 && Array.isArray(profileAndValue) && profileAndValue.some(ele => isNumber(ele.value1[0])) &&
                                                        <div className={styles.legendItem}>
                                                            <div style={{ background: `rgba(${chartColor1.r},${chartColor1.g},${chartColor1.b},${chartColor1.a})`}} className={styles.pileLength}></div>
                                                            <p>{chartNames[capacityType].legend1}</p>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </ol>
                            </main>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <div
                                style={{ height: footerHeight }}
                                className={styles.footerSpace}></div>
                        </td>
                    </tr>
                </tfoot>
            </table>
            <ResultsHeader
                author={report.author}
                contact={report.contact}
            />
            <ResultsFooter
                setFooterHeight={useCallback((val) => setFooterHeight(val),[])}
                globalTitle={globalTitle}
                title={chartNames[capacityType].title}
            />
        </div>
    )
})

export default ChartToPrintReinforcement;