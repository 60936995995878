import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { roundNumber } from '../../utils';

const factor = 3;
const Wrapper = styled.div`
    margin: 0;
    width: ${x => `${factor*x.width}px`};
    height: 50mm;

    border: 1px solid #bbb;
    z-index: 100;
`;

const withPrintChartOptions = (Component) => {
    return (props) => {
        const [loaded, setLoaded] = useState(false);
        const [loadedPage, setLoadedPage] = useState(false);

        const chartOptions = {
            chart: {
                background: '#fff',
                fontFamily: 'Calibri, sans-serif',
                offsetY: 10,
                animations: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
                events: {
                    mounted: () => {
                        setLoaded(true);
                    },
                },
            },
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: `${factor*3}mm`,
                    colors: ['#000'],
                },
                background: {
                    enabled: false,
                },
                formatter: function (x, opt) {
                    const label =
                        opt.w.globals.initialSeries[0].data[
                            opt.dataPointIndex
                        ][0];

                    return label === 0
                        ? 
                        ''
                        :
                        opt.dataPointIndex % 2 === 0
                        ?
                        roundNumber(opt.w.globals.initialSeries[0].data[opt.dataPointIndex][0],0)
                        :
                        ''
                },
            },
            stroke: {
                width: 1,
                curve: 'straight',
                colors: ['#7BD39A'],
            },
            fill: {
                opacity: 0.65,
                type: 'solid',
                colors: ['#7BD39A'],
            },
            title: {
                margin: 0,
                offsetY: 40,
                align: 'center',
                style: {
                    fontSize: `${factor*4.5}mm`,
                    color: '#000',
                },
            },
            subtitle: {
                style: {
                    fontSize: `${factor*4}mm`,
                    color: '#000',
                },
            },
            grid: {
                show: true,
                borderColor: '#ddd',
                position:'back'
            },
            tooltip: {
                enabled: false,
            },
            yaxis: {
                reversed: true,
                type: 'numeric',
                decimalsInFloat: 1,
                labels: {
                    style: {
                        fontSize: `${factor*3}mm`,
                        colors: '#000',
                    },
                },
            },
            xaxis: {
                type: 'numeric',
          
                title: {
                    offsetY: 50,
                    style: {
                        fontSize: `${factor*4}mm`,
                        color: '#000',
                    },
                },
                labels: {
                    offsetY: 25,
                    style: {
                        fontSize: `${factor*3}mm`,
                        colors: '#000',
                    },
                },
            },
        };
        useEffect(() => {
            setLoaded(loaded);
        }, [loaded]);

        useEffect(() => {
            setLoadedPage(false);

            const timeout = setTimeout(() => {
                setLoadedPage(true);
            }, 0);

            return () => clearTimeout(timeout);
        }, []);

        return (
            loadedPage && (
                <Wrapper width={props.maxWidth}>
                    <Component
                        loaded={loaded}
                        chartOptions={chartOptions}
                        {...props}
                    />
                </Wrapper>
            )
        );
    };
};

export default withPrintChartOptions;
