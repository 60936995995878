import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Loading, Modal } from '../../../../components';
import styles from './index.module.css';

const OverwriteProject = ({
    open,
    projectName,
    onSubmit,
    overwriteLoading,
    onHideExistProjectModal,
}) => {
    const [openOverwriteProjectState, setOverwriteProjectState] = useState(
        open.value,
    );

    useEffect(() => {
        setOverwriteProjectState(open.value);
    }, [open.value]);

    const onHideOverwriteProject = () => {
        onHideExistProjectModal({
            value: false,
        });
        setOverwriteProjectState(false);
    };
    return (
        <Modal
            open={openOverwriteProjectState}
            css={styles.modal}
            cssDialog={styles.modalDialog}
            onHide={onHideOverwriteProject}
            title="Nadpisz projekt"
            hideButton={false}>
            <Row>
                <Col xs={12}>
                    <h4 className={styles.h4}>
                        Plik o nazwie <span>{projectName}</span> już istnieje,
                        chcesz nadpisać istniejący projekt?
                    </h4>
                </Col>
                <Col xs={12}>
                    <Button
                        css={`
                            ${styles.button} default-button-style
                        `}
                        altTitle="Zapisz"
                        title="Tak, zapisz"
                        name="overwriteProject"
                        onClick={onSubmit}
                        type="submit"
                        disabled={overwriteLoading}
                    />
                </Col>
            </Row>

            {overwriteLoading && (
                <Loading oppositeColors={true} text="Zapisuję projekt..." />
            )}
        </Modal>
    );
};

export default OverwriteProject;
