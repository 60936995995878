import React from  'react';
import Loading2 from '../../components/Loading2';

const loadingHOC = (Component) => {

    return (props) => {

        const { isLoaded, showLoadingIcon = true } = props;
 
        return (
            showLoadingIcon ? 
                isLoaded ? <Component {...props}/> : <Loading2 {...props}/>
            :
                isLoaded && <Component {...props}/>
        )
    }
    
}
export default loadingHOC;