import { useContext, useEffect } from 'react';
import config from '../../../config/config';
import { reinforcementContext } from '../../../context/Reinforcement';
import PrintMicropileResults from './PrintResults';
import DefaultNavigation from '../DefaultNavigation';

const ReinforcementNavigation = ({ report }) => {
    const ReinforcementContext = useContext(reinforcementContext);
    const { routes } = config;

    useEffect(() => {
        document.title = `Zbrojenie przekroju - ${ReinforcementContext.fileNameToSave.fileName.value}`;

        return () => {
            document.title = '';
        };
    }, [ReinforcementContext.fileNameToSave.fileName.value]); 

    return (
        <DefaultNavigation
            title="Zbrojenie przekroju"
            subtitle="Zbrojenie przekroju metodą nieliniową zgodnie z Eurokodem 2"
            modelRoute={routes.dashboard.reinforcementModel}
            resultsRoute={routes.dashboard.reinforcementResults}
            context={ReinforcementContext}>
            {ReinforcementContext.results.state.isResultsActual && ReinforcementContext.results.isResultsActive && (
                <PrintMicropileResults
                    report={report}
                    isResultsActual={
                        ReinforcementContext.results.state.isResultsActual
                    }
                    printResults={ReinforcementContext.printResults}
                />
                )}
        </DefaultNavigation>
    );
};

export default ReinforcementNavigation;
