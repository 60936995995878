import { Fragment } from 'react';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './index.module.css';

const Subtitle = ({ title, children }) => {

    return (
        <div className={styles.titleWrapper}>
            {title && <label className={styles.title}>{title}</label>}

            {
                children &&
                <Fragment>
                    <FontAwesomeIcon className={styles.infoIcon} icon={faQuestionCircle}/>
                    <div className={`${styles.info} defaultBox`}>
                        {children}
                    </div> 
                </Fragment>
            }
        </div>
    )
}

export default Subtitle