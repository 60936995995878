import { Fragment } from "react"
import { isNumber, roundNumber } from "../../../../../utils";
import styles from './index.module.css';


const CalculationSection = ({                                                             
    groundLevel,
    negativeFriction,
    newEmbankment,
    zwg,
    to,
    from,
    layersName 
}) => {
    return ( 
        <div className={styles.wrapper}>
            <h2>Parametry obliczeń</h2>
            <p>Rzędna terenu: <span className={styles.value}>{roundNumber(groundLevel,2).toFixed(2)} m.n.p.m.</span></p>
            <p>Rzędna zwierciadła wody: <span className={styles.value}>{isNumber(zwg) ? `${roundNumber(zwg,2).toFixed(2)} m.p.p.t.` : 'Brak'}</span></p>
            <p>Świeży nasyp gruntowy: <span className={styles.value}>{newEmbankment ? 'Tak' : 'Brak' }</span></p>
            {
                newEmbankment &&
                <Fragment>
                    <p>Od: <span className={styles.value}>{roundNumber(from,2).toFixed(2)} m.p.p.t.</span></p>
                    <p>Do: <span className={styles.value}>{roundNumber(to,2).toFixed(2)} m.p.p.t.</span></p>
                </Fragment>
                
            }
            <p>Warstwy z tarciem negatywnym: <span className={styles.value}>{!negativeFriction && 'Brak' }</span></p>
            {
                negativeFriction &&
                <ul>
                    {
                        Object.values(layersName).map((ele, idx) => {
                            return (
                                ele.value 
                                ?
                                    <li key={idx}><p>{ele.name}</p></li>
                                :
                                ''
                            )
                        })                        
                    }
                </ul>                
            }
        </div>
    )
}

export default CalculationSection