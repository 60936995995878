import React, { Fragment, useCallback, useState } from 'react';
import {
    ResultsFooter, 
    ResultsHeader,
    ReportFirstPage,
} from '../../../../../components';
import SoilParametersSection from '../SoilParametersSection';
import ZwgLevel from '../ZwgLevel';
import MicropileParametersSection from '../MicropileParametersSection';
import MicropileCapacityCompressionDetails from '../MicropileCapacityCompressionDetails';
import MicropileCapacityPullDetails from '../MicropileCapacityPullDetails';
import MicropileCapacityChart from '../MicropileCapacityCompressionChart';
import SteelRodTable from '../SteelRodTable';
import SaftyFactorsSection from '../SaftyFactorsSection';
import styles from './index.module.css';
import Summary from '../Summary';

const PdfReport = React.forwardRef(({ pdfState }, ref) => {
    const [footerHeight, setFooterHeight] = useState(0);

    return (
        <div className={styles.wrapper} ref={ref}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <td>
                            <div className={styles.headerSpace}></div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <main className={styles.content}>
                                <ol>
                                    <div>
                                        {
                                            pdfState.reportItem.firstPage &&
                                            <Fragment>
                                                <ReportFirstPage
                                                    title="Nośność na wciskanie i wyciąganie mikropali iniekcyjnych"
                                                    subtitle={
                                                        pdfState.projectTitle
                                                            .projectTitle
                                                    }
                                                    author={pdfState.report.author}
                                                    contact={pdfState.report.contact}
                                                    logo={pdfState.report.logo}
                                                />
                                                                                             
                                            </Fragment>
                                        }

                                        {(pdfState.reportItem.soilsTable ||
                                            pdfState.reportItem.projectInfo ||
                                            pdfState.reportItem
                                                .micropileProperties ||
                                            pdfState.reportItem
                                                .saftyFactors) && (
                                            <Fragment>
                                                <div className={styles.break}></div>   
                                                <li className={styles.h1}>
                                                    Dane do projektu
                                                </li>
                                                {pdfState.reportItem
                                                    .soilsTable && (
                                                    <SoilParametersSection
                                                        parameters={
                                                            pdfState.results
                                                                .soilTable
                                                        }
                                                    />
                                                )}
                                                {pdfState.reportItem
                                                    .projectInfo && (
                                                    <ZwgLevel
                                                        parameters={{
                                                            groundLevel:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .groundLevel,
                                                            zwg: pdfState
                                                                .results
                                                                .calculationParameters
                                                                .zwg,
                                                        }}
                                                    /> 
                                                )}
                                                {pdfState.reportItem
                                                    .micropileProperties && (
                                                    <MicropileParametersSection
                                                        parameters={{
                                                            groundLevel:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .groundLevel,
                                                            freeLength:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .freeLength,
                                                            micropileHeadSpot:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .micropileHeadSpot,
                                                            micropileAngle:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .micropileAngle,
                                                            desAxisLoadValue:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .desAxisLoadValue,
                                                            diameter:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .diameter,
                                                            steelRodProd:
                                                                pdfState.results
                                                                    .steelRod
                                                                    ? pdfState
                                                                          .results
                                                                          .steelRod
                                                                          .prod
                                                                    : '',
                                                            steelRodName:
                                                                pdfState.results
                                                                    .steelRod
                                                                    ? pdfState
                                                                          .results
                                                                          .steelRod
                                                                          .name
                                                                    : '',
                                                            steelRodSubname:
                                                                pdfState.results
                                                                    .steelRod
                                                                    ? pdfState
                                                                          .results
                                                                          .steelRod
                                                                          .subname
                                                                    : '',
                                                            screwType:
                                                                pdfState.results
                                                                    .steelRod
                                                                    ? pdfState
                                                                        .results
                                                                        .steelRod
                                                                        .screwType
                                                                    : '',
                                                            micropileType:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .micropileType,
                                                            steelYoungModulus:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .steelYoungModulus,
                                                            fc: pdfState.results
                                                                .calculationParameters
                                                                .fc,
                                                            fy: pdfState.results
                                                                .calculationParameters
                                                                .fy,
                                                        }}
                                                    />
                                                )}
                                                {pdfState.reportItem
                                                    .saftyFactors && (
                                                    <SaftyFactorsSection
                                                        parameters={{
                                                            softSoilCount:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .softSoilCount,
                                                            cohesiveSoils:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .cohesiveSoils,
                                                            cohesiveSoilsCpt:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .cohesiveSoilsCpt,
                                                            gravel: pdfState
                                                                .results
                                                                .calculationParameters
                                                                .gravel,
                                                            gravelCpt:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .gravelCpt,
                                                            rocks: pdfState
                                                                .results
                                                                .calculationParameters
                                                                .rocks,
                                                            sands: pdfState
                                                                .results
                                                                .calculationParameters
                                                                .sands,
                                                            rocksCpt:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .rocksCpt,
                                                            sandsCpt:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .sandsCpt,

                                                            isSteelRod:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .isSteelRod,
                                                            ksi: pdfState
                                                                .results
                                                                .calculationParameters
                                                                .ksi,
                                                            profilesNumber:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .profilesNumber,
                                                            qccuInterpolation:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .qccuInterpolation,
                                                            steelRodCapacity:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .steelRodCapacity,
                                                            steelRodYt:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .steelRodYt,
                                                            yt: pdfState.results
                                                                .calculationParameters
                                                                .yt,
                                                            yst: pdfState
                                                                .results
                                                                .calculationParameters
                                                                .yst,
                                                            yc: pdfState.results
                                                                .calculationParameters
                                                                .yc,
                                                            ys: pdfState.results
                                                                .calculationParameters
                                                                .ys,
                                                            ycu: pdfState
                                                                .results
                                                                .calculationParameters
                                                                .ycu,
                                                            modelFactor:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .modelFactor,
                                                            tci: pdfState
                                                                .results
                                                                .calculationParameters
                                                                .tci,
                                                        }}
                                                    />
                                                )}
                                                
                                            </Fragment>
                                        )}
                                        {(pdfState.reportItem
                                            .micropileCompressionDetailsTable ||
                                            pdfState.reportItem
                                                .micropilePullDetailsTable ||
                                            (pdfState.reportItem
                                                .steelRodTable &&
                                                pdfState.results
                                                    .calculationParameters
                                                    .isSteelRod) ||
                                            pdfState.reportItem.summary) && (
                                            <Fragment>
                                                <div className={styles.break}></div>
                                                <li className={styles.h1}>
                                                    Wyniki obliczeń
                                                </li>
                                                {pdfState.reportItem
                                                    .micropileCompressionDetailsTable && (
                                                    <MicropileCapacityCompressionDetails
                                                        parameters={
                                                            pdfState.results
                                                                .capacity
                                                        }
                                                        steelRod={
                                                            pdfState.results
                                                                .steelRod
                                                        }
                                                    />
                                                )}
                                                {pdfState.reportItem
                                                    .micropilePullDetailsTable && (
                                                    <MicropileCapacityPullDetails
                                                        parameters={
                                                            pdfState.results
                                                                .capacity
                                                        }
                                                        steelRod={
                                                            pdfState.results
                                                                .steelRod
                                                        }
                                                    />
                                                )}
                                                {pdfState.reportItem
                                                    .steelRodTable &&
                                                    pdfState.results
                                                        .calculationParameters
                                                        .isSteelRod && (
                                                        <SteelRodTable
                                                            steelRod={
                                                                pdfState.results
                                                                    .steelRod
                                                            }
                                                        />
                                                    )}
                                                
                                            </Fragment>
                                        )}
                                        {pdfState.reportItem.summary && (
                                            <Fragment>
                                                <div className={styles.break}></div>
                                                <li className={styles.h1}>
                                                    Podsumowanie wyników
                                                </li>
                                                <Summary
                                                    title={
                                                        'Teoretyczne wyniki mikropala wciskanego'
                                                    }
                                                    moduleRodTitle={`Rzeczywista długość mikropala wciskanego dla modułu żerdzi - ${pdfState.results.rodModule.value.toFixed(
                                                        2,
                                                    )} m`}
                                                    currentCapacity={
                                                        pdfState.results
                                                            .currentMicropileValues
                                                            .currentCapacityNt
                                                    }
                                                    steelRod={
                                                        pdfState.results
                                                            .steelRod
                                                    }
                                                    currentMicropileLength={
                                                        pdfState.reportItem
                                                            .micropileCompressionLength
                                                    }
                                                    rodModule={
                                                        pdfState.results
                                                            .rodModule.value
                                                    }
                                                    calculationParameters={{
                                                        axisLoad:
                                                            pdfState.results
                                                                .calculationParameters
                                                                .desAxisLoadValue,
                                                        diameter:
                                                            pdfState.results
                                                                .calculationParameters
                                                                .diameter,
                                                        headLength:
                                                            pdfState.results
                                                                .calculationParameters
                                                                .headLength,
                                                        micropileAngle:
                                                            pdfState.results
                                                                .calculationParameters
                                                                .micropileAngle,
                                                        isSteelRod:
                                                            pdfState.results
                                                                .calculationParameters
                                                                .isSteelRod,
                                                        micropileHeadSpot:
                                                            pdfState.results
                                                                .calculationParameters
                                                                .micropileHeadSpot,
                                                    }}
                                                />
                                                <Summary
                                                    title={
                                                        'Teoretyczne wyniki mikropala wyciąganego'
                                                    }
                                                    moduleRodTitle={`Rzeczywista długość mikropala wyciąganego dla modułu żerdzi - ${pdfState.results.rodModule.value.toFixed(
                                                        2,
                                                    )} m`}
                                                    currentCapacity={
                                                        pdfState.results
                                                            .currentMicropileValues
                                                            .currentCapacityNtw
                                                    }
                                                    steelRod={
                                                        pdfState.results
                                                            .steelRod
                                                    }
                                                    currentMicropileLength={
                                                        pdfState.reportItem
                                                            .micropilePullLength
                                                    }
                                                    rodModule={
                                                        pdfState.results
                                                            .rodModule.value
                                                    }
                                                    calculationParameters={{
                                                        axisLoad:
                                                            pdfState.results
                                                                .calculationParameters
                                                                .desAxisLoadValue,
                                                        diameter:
                                                            pdfState.results
                                                                .calculationParameters
                                                                .diameter,
                                                        headLength:
                                                            pdfState.results
                                                                .calculationParameters
                                                                .headLength,
                                                        micropileAngle:
                                                            pdfState.results
                                                                .calculationParameters
                                                                .micropileAngle,
                                                        isSteelRod:
                                                            pdfState.results
                                                                .calculationParameters
                                                                .isSteelRod,
                                                        micropileHeadSpot:
                                                            pdfState.results
                                                                .calculationParameters
                                                                .micropileHeadSpot,
                                                    }}
                                                />
                                                <div className={styles.break}></div>
                                            </Fragment>
                                        )}
                                        {(pdfState.reportItem
                                            .micropileCompressionChart ||
                                            pdfState.reportItem
                                                .micropilePullChart) && (
                                            <Fragment>
                                                <li className={styles.h1}>
                                                    Załączniki
                                                </li>
                                                {pdfState.reportItem
                                                    .micropileCompressionChart && (
                                                    <div
                                                        className={
                                                            styles.avoidBreak
                                                        }>
                                                        <MicropileCapacityChart
                                                            results={
                                                                pdfState.results
                                                                    .capacity
                                                            }
                                                            title="Nośność mikropala na wciskanie"
                                                            soilProfile={
                                                                pdfState.results
                                                                    .soilTable
                                                            }
                                                            pullCapacity={false}
                                                            micropileLength={
                                                                pdfState
                                                                    .reportItem
                                                                    .micropileCompressionLength
                                                            }
                                                            micropileHeadElevation={
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .micropileHeadSpot
                                                            }
                                                            micropileAngle={
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .micropileAngle
                                                            }
                                                            groundLevel={
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .groundLevel
                                                            }
                                                        />
                                                    </div>
                                                )}
                                                {pdfState.reportItem
                                                    .micropilePullChart && (
                                                    <div
                                                        className={
                                                            styles.avoidBreak
                                                        }>
                                                        <MicropileCapacityChart
                                                            results={
                                                                pdfState.results
                                                                    .capacity
                                                            }
                                                            title="Nośność mikropala na wyciąganie"
                                                            soilProfile={
                                                                pdfState.results
                                                                    .soilTable
                                                            }
                                                            pullCapacity={true}
                                                            micropileLength={
                                                                pdfState
                                                                    .reportItem
                                                                    .micropilePullLength
                                                            }
                                                            micropileHeadElevation={
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .micropileHeadSpot
                                                            }
                                                            micropileAngle={
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .micropileAngle
                                                            }
                                                            groundLevel={
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .groundLevel
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </Fragment>
                                        )}
                                    </div>
                                </ol>
                            </main>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <div
                                style={{ height: footerHeight }}
                                className={styles.footerSpace}></div>
                        </td>
                    </tr>
                </tfoot>
            </table>
            <ResultsHeader
                author={pdfState.report.author}
                contact={pdfState.report.contact}
            />
            <ResultsFooter
                setFooterHeight={useCallback((val) => setFooterHeight(val),[])}
                pdfState={pdfState.reportItem}
                globalTitle="Nośność na wciskanie i wyciąganie mikropali iniekcyjnych"
                title={pdfState.projectTitle.projectTitle}
            />
        </div>
    );
});

export default PdfReport;
