import {
    faChartLine,
    faFolderOpen,
    faLayerGroup,
} from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';

import { Col } from 'react-bootstrap';
import { SectionTitle } from '../../../components';
import SingleElement from './SingleElement';
import styles from './index.module.css';

const Statistics = ({ stats = {} }) => {
    return (
        <Fragment>
            <Col xs={12} className={styles.col}>
                <SectionTitle title="Statystyki konta" />
            </Col>
            <Col sm={12} md={4} className={styles.colLeft}>
                <SingleElement
                    wrapperTitle={'Liczba projektów, których jesteś autorem'}
                    title={'Projekty'}
                    css={'stat1'}
                    description={stats.projects}
                    icon={faFolderOpen}
                />
            </Col>
            <Col sm={12} md={4} className={styles.colXs}>
                <SingleElement
                    wrapperTitle={
                        'Liczba profili gruntowych zapisana w projektach'
                    }
                    title={'Profile gruntowe'}
                    css={'stat2'}
                    description={stats.profiles}
                    icon={faLayerGroup}
                />
            </Col>
            <Col sm={12} md={4} className={styles.colRight}>
                <SingleElement
                    wrapperTitle={'Liczba wykonanych obliczeń w projektach'}
                    title={'Obliczenia'}
                    css={'stat3'}
                    description={stats.calculations}
                    icon={faChartLine}
                />
            </Col>
        </Fragment>
    );
};

export default Statistics;
