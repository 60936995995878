import { Table } from 'react-bootstrap';
import { isNumber } from '../../../../../utils';
import styles from './index.module.css';

const SoilParametersSection = ({ parameters = [] }) => {
    return (
        <div className={styles.wrapper}>
            <h2>Parametry gruntu</h2>
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-] 
                        </th>
                        <th>
                            Nazwa warstwy<br></br>[-]
                        </th>
                        <th>
                            Rodzaj gruntu<br></br>[-]
                        </th>
                        <th>
                            Z<br></br>[m.p.p.t]
                        </th>
                        <th>
                            q<sub>c</sub>
                            <br></br>[MPa]
                        </th>
                        <th>
                            c<sub>u</sub>
                            <br></br>[kPa]
                        </th>
                        <th>
                            &#966; <br></br>[&#176;]
                        </th>
                        <th>
                            I<sub>d</sub> <br></br>[-]
                        </th>
                        <th>
                            I<sub>L</sub> <br></br>[-]
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {parameters.map((element, index) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{element.name}</td>
                                <td>{element.soilName}</td>
                                <td>{element.height}</td>
                                <td>
                                    {isNumber(element.qccpt)
                                        ? element.qccpt
                                        : '-'}
                                </td>
                                <td>
                                    {isNumber(element.cucpt)
                                        ? element.cucpt
                                        : '-'}
                                </td>
                                <td>{element.friction}</td>
                                <td>
                                    {isNumber(element.density)
                                        ? element.density
                                        : '-'}
                                </td>
                                <td>
                                    {isNumber(element.plasticity)
                                        ? element.plasticity
                                        : '-'}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default SoilParametersSection;