import { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { Form, Row, Col } from 'react-bootstrap';
import { Button, Modal, RadioInput1, SectionTitle, InputField, Checkbox1 } from '../../../components';
import styles from './index.module.css';
import { isNumber } from '../../../utils';
import { faShoppingCart, faDollar } from '@fortawesome/free-solid-svg-icons';

const dateOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
};

const licenceDays = (days = 0) => {
    if (days === 1) return '(pozostał 1 dzień)';
    else return `(pozostało ${days} dni)`;
};
const Subscription = ({ 
    onChange, 
    onOrderSubscription, 
    state, 
    subsObj,
    invoiceType,
    invoiceState,
    saveFormData,
    onChangeInvoiceType,
    onChangeInvoice,
    onBlurInvoice,
    onChangeDataCheckbox,
}) => { 
    const [open, setOpen] = useState(false);
    const [openInvoice, setOpenInvoice] = useState(false);

    const onOpenInvoice = () => setOpenInvoice(true);
    const onHideInvoice = () => setOpenInvoice(false)
    const onOpen = () => setOpen(true);
    const onHide = () => setOpen(false);

    useEffect(() => {
        return () => setOpen(false);
    }, []);

    return (
        <Fragment>
            <div className={styles.wrapper}>
                <SectionTitle title="Subskrypcja" />
                <div className={`backgroundAround`}>
                    <h4 className={styles.h4}>
                        Data wygaśnięcia licencji: <br></br>
                        <span className={`${styles.date} titleColorClass`}>
                            {new Date(subsObj.endDate).toLocaleDateString(
                                'pl-PL',
                                dateOptions,
                            )}
                        </span>{' '}
                        <span className={`${styles.days} titleColorClass`}>
                            {licenceDays(subsObj.days)}
                        </span>
                    </h4>

                    <h4 className={styles.h4}>
                        {' '}
                        Twój aktualny plan to:<br></br>
                        <span className={`${styles.plan} titleColorClass`}>
                            {subsObj.type === 1
                                ? 'Subskrypcja miesięczna'
                                : subsObj.type === 2
                                ? 'Subskrypcja roczna'
                                : subsObj.type === 3
                                ? 'Konto testowe'
                                : ''}
                        </span>
                    </h4>

                    <div className={styles.buttonWrapper}>
                        <Button
                            css={styles.center}
                            variant="info"
                            name="subscribe"
                            onClick={onOpen}
                            title="Przedłuż subskrypcję"
                            altTitle="Przedłuż subskrypcję"
                        />
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                css={styles.modalOverflow}
                cssDialog={styles.modal}
                onHide={onHide}
                title="Odnowienie subskrypcji">
                <Row className={styles.row}>
                    <Col xs={12} sm={{ span: 10, offset: 1 }}>
                        <Form className={styles.radioForm}>
                            <RadioInput1
                                name="yearSubscription"
                                label={'Roczna subskrypcja'}
                                onChange={onChange}
                                value={state.subscriptionType.yearSubscription}
                            />
                            <RadioInput1
                                name="monthSubscription"
                                label={'Miesięczna subskrypcja'}
                                onChange={onChange}
                                value={state.subscriptionType.monthSubscription}
                            />
                        </Form>
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12}>
                        {isNumber(state.summaryCost) ? (
                            <Fragment>
                                <h2 className={styles.summary}>
                                    {`${state.summaryCost}`}
                                    <span>{` zł / ${
                                        state.subscriptionType.yearSubscription
                                            ? 'rok'
                                            : 'miesiąc'
                                    }`}</span>
                                    {state.subscriptionType
                                        .yearSubscription && (
                                        <p
                                            className={
                                                styles.p
                                            }>{`(1 miesiąc gratis)`}</p>
                                    )}
                                </h2>
                                <p className={styles.newLicence}>
                                    Licencja zostanie przedłużona do:{' '}
                                    {moment(subsObj.endDate).diff(
                                        moment(),
                                        'seconds',
                                    ) > 0
                                        ? moment(subsObj.endDate)
                                              .add(
                                                  1,
                                                  state.subscriptionType
                                                      .yearSubscription
                                                      ? 'years'
                                                      : 'months',
                                              )
                                              .local('pl')
                                              .format('DD.MM.YYYY, HH:mm')
                                        : moment()
                                              .add(
                                                  1,
                                                  state.subscriptionType
                                                      .yearSubscription
                                                      ? 'years'
                                                      : 'months',
                                              )
                                              .local('pl')
                                              .format('DD.MM.YYYY, HH:mm')}
                                </p>
                            </Fragment>
                        ) : (
                            <h2>-</h2>
                        )}
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12} className={styles.center}>
                        <Button
                            icon={faShoppingCart}
                            css={styles.buyNowButton}
                            variant="info"
                            name="subscribe"
                            onClick={onOpenInvoice}
                            title="Złóż zamówienie"
                            altTitle="Złóż zamówienie"
                        />
                    </Col>
                </Row>
                <Row className={styles.row1}>
                    <Col xs={12}>
                        <SectionTitle
                            className={styles.sectionTitle}
                            title="*W razie dodania nowego użytkownika przypisanego do konta, koszt jego subskrycji wynosi:"
                        />
                    </Col>
                    <Col xs={12}>
                        <div className={styles.singlePlan}>
                            <h4 className={styles.h4Licences}>
                                {state.subscriptionType.yearSubscription
                                    ? state.plans.year.second
                                    : state.plans.month.second}
                                <span className={styles.spanLicences}>
                                    zł/szt.
                                </span>
                            </h4>
                        </div>
                    </Col>
                </Row>
            </Modal>

            <Modal
                css={styles.modalOverflow}
                cssDialog={styles.modal1}
                onHide={onHideInvoice}
                title="Dane do faktury"
                open={openInvoice}
                hideButton={false}>
                <Row className={styles.row2}>
                    <Col xs={12} sm={{ span: 10, offset: 1 }}>
                        <Form className={styles.radioForm}>
                            <RadioInput1
                                name="company"
                                label={'Faktura na firmę'}
                                onChange={() => onChangeInvoiceType(1)}
                                value={invoiceType === 1}
                            />
                            <RadioInput1
                                name="person"
                                label={'Faktura na osobę prywatną'}
                                onChange={() => onChangeInvoiceType(2)}
                                value={invoiceType === 2}
                            />
                        </Form>
                    </Col>
                </Row>
                <Row className={styles.row2}>
                    {invoiceType === 2 && (
                        <Fragment>
                            <Col xs={12} sm={6}>
                                <InputField
                                    title="Imię"
                                    placeholder={'Imię'}
                                    name="firstName"
                                    onChange={onChangeInvoice}
                                    onBlur={onBlurInvoice}
                                    value={invoiceState.firstName.value}
                                    isValid={invoiceState.firstName.isValid}
                                    required
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <InputField
                                    title="Nazwisko"
                                    placeholder={'Nazwisko'}
                                    name="lastName"
                                    onChange={onChangeInvoice}
                                    onBlur={onBlurInvoice}
                                    value={invoiceState.lastName.value}
                                    isValid={invoiceState.lastName.isValid}
                                    required
                                />
                            </Col>
                        </Fragment>
                    )}
                    {invoiceType === 1 && (
                        <Fragment>
                            <Col xs={12}>
                                <InputField
                                    title="Nazwa firmy"
                                    placeholder={'Nazwa firmy'}
                                    name="companyName"
                                    onChange={onChangeInvoice}
                                    onBlur={onBlurInvoice}
                                    value={invoiceState.companyName.value}
                                    isValid={invoiceState.companyName.isValid}
                                    required
                                />
                            </Col>
                        </Fragment>
                    )}
                </Row>
                {invoiceType === 1 && (
                    <Row className={styles.row2}>
                        <Col xs={12}>
                            <InputField
                                title="NIP"
                                placeholder={'NIP'}
                                name="nip"
                                onChange={onChangeInvoice}
                                onBlur={onBlurInvoice}
                                value={invoiceState.nip.value}
                                isValid={invoiceState.nip.isValid}
                                required
                            />
                        </Col>
                    </Row>
                )}

                <Row className={styles.row2}>
                    <Col xs={12} sm={8}>
                        <InputField
                            title="Nazwa ulicy"
                            placeholder={'Nazwa ulicy'}
                            name={'street'}
                            onChange={onChangeInvoice}
                            onBlur={onBlurInvoice}
                            value={invoiceState.street.value}
                            isValid={invoiceState.street.isValid}
                            required
                        />
                    </Col>
                    <Col xs={12} sm={4}>
                        <InputField
                            title="Nr domu/lokalu"
                            placeholder={'Nr domu/lokalu'}
                            name="placeNumber"
                            onChange={onChangeInvoice}
                            onBlur={onBlurInvoice}
                            value={invoiceState.placeNumber.value}
                            isValid={invoiceState.placeNumber.isValid}
                            required
                        />
                    </Col>
                </Row>
                <Row className={styles.row2}>
                    <Col xs={12} sm={8}>
                        <InputField
                            title="Miejscowość"
                            placeholder={'Miejscowość'}
                            name="city"
                            onChange={onChangeInvoice}
                            onBlur={onBlurInvoice}
                            value={invoiceState.city.value}
                            isValid={invoiceState.city.isValid}
                            required
                        />
                    </Col>
                    <Col xs={12} sm={4}>
                        <InputField
                            title="Kod pocztowy"
                            placeholder={'Kod pocztowy'}
                            name="postcode"
                            onChange={onChangeInvoice}
                            onBlur={onBlurInvoice}
                            value={invoiceState.postcode.value}
                            isValid={invoiceState.postcode.isValid}
                            required
                        />
                    </Col>
                </Row>
                <Row className={styles.row2}>
                    <Col xs={12}>
                        <InputField
                            title="Email"
                            placeholder={'Email'}
                            name="email"
                            onChange={onChangeInvoice}
                            onBlur={onBlurInvoice}
                            value={invoiceState.email.value}
                            isValid={invoiceState.email.isValid}
                            required
                        />
                    </Col>
                </Row>
                <Row className={styles.row2}>
                    <Col xs={12}>
                        <div
                            name="saveFormData"
                            className={styles.flexCheckbox}>
                            <Checkbox1
                                value={saveFormData}
                                name="saveFormData"
                                label="Zapamiętaj dane do faktury"
                                css={styles.checkboxWidth}
                                onChange={onChangeDataCheckbox}
                            />
                            <p
                                name="saveFormData"
                                onClick={onChangeDataCheckbox}>
                                Zapamiętaj dane do faktury
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className={styles.row2}>
                    <Col xs={12} className={styles.toRight}>
                        <Button
                            icon={faDollar}
                            variant="info"
                            name="sendInvoice"
                            onClick={onOrderSubscription}
                            title="Zapłać"
                            altTitle="Zapłać"
                        />
                    </Col>
                </Row>
            </Modal>
        </Fragment>
    );
}; 

export default Subscription;
