import { useCallback, useState } from "react";

const initStateObj = {
    allItems: false,
    projectInfo: false,
    pileLoads: false,
    soilsTable: false,        
    foundationParameters: false,
    pileParameters: false,
    designSoilsParams: false,
    kxRgr: false,
    internalForces: false,
    bendingChart: false,
    shearChart: false,
    displacementChart: false,
    plasticDisplacementChart: false,
    kxChart: false,
    rgrChart: false,

    firstPage: false
}
const initLoad = {
    bendingChart: '',
    shearChart: '',
    displacementChart: '',
    kxChart: '',
    rgrChart: ''
}

const PrintResultsHelpers = (data) => {
    const [reportItem, setRaportItem] = useState(initStateObj);
    const [loaded, setLoaded] = useState(initLoad)

    const onChangeRaportItems = (e) => {
        const input = e.currentTarget.closest('input');


        if(input){
            const { name, checked } = input;
            
            if(name === 'allItems'){
                if(data.results.internalForces && Array.isArray(data.results.internalForces.plasticDisplacements) && data.results.internalForces.plasticDisplacements.length > 0){
                    setRaportItem(state => ({
                        ...state,
                        ...Object.fromEntries(Object.entries(state).map(([key,val]) => [key, !!checked ? true : false]))
                    }))   
                } else {
                    setRaportItem(state => ({
                        ...state,
                        ...Object.fromEntries(Object.entries(state).filter(ele => ele[0] !== 'plasticDisplacementChart').map(([key,val]) => [key, !!checked ? true : false]))
                    }))
                }
            } else {
                setRaportItem(state => ({
                    ...state,
                    [name]: !!checked
                }))                
            }
        }
    };

    const printResults = {
        onChange: onChangeRaportItems,
        initState: () => setRaportItem(initStateObj),
        onLoad: useCallback((x) => setLoaded(state => ({ ...state, ...x})) ,[]),
        state: {
            reportItem,
            allLoaded: loaded,
            ...data
        }
    }

    return {
        printResults
    }

}

export default PrintResultsHelpers