import React, { Fragment, useCallback, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Checkbox, Modal } from '../../../../components';
import ReportWrapper from './ReportWrapper';
import ReactToPrint from 'react-to-print';
import styles from './index.module.css'; 

const PrintResults = ({ state, onChange, open, onHide }) => { 
    const [loading, setLoading] = useState(false);
    const pdfComponentRef = useRef();
 
    return ( 
        <Fragment>            
            <Modal title="Raport z obliczeń" cssDialog={styles.dialog} open={open} onHide={onHide} hideButton={false}>
                <Row className={styles.row}>
                    <Col xs={12} sm={6}>
                        <Checkbox name="allItems" label="Zaznacz wszystko" value={state.reportItem.allItems} onChange={onChange}/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="firstPage" label="Strona tytułowa" value={state.reportItem.firstPage} onChange={onChange}/>
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12} sm={6}>
                        <Checkbox name="projectInfo" label="Dane wejściowe do obliczeń" value={state.reportItem.projectInfo} onChange={onChange}/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="capacityDetails" label="Wyniki obliczeń" value={state.reportItem.capacityDetails} onChange={onChange}/>
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <ReactToPrint 
                            trigger = {() => <Button css={`${styles.button} default-button-style`} altTitle="Drukuj" title="Drukuj raport" type="button" loading={loading}/>}
                            content={() => pdfComponentRef.current}
                        /> 
                    </Col>
                </Row> 
                
                <ReportWrapper
                    setLoading={useCallback((e) => setLoading(e),[])}
                    pdfState={state}
                    ref={pdfComponentRef} 
                />
            </Modal>
        </Fragment>
    )
}

export default PrintResults