import React, { Fragment, useCallback, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Checkbox, SectionTitle, Modal, InputField } from '../../../../components';
import ReportWrapper from './ReportWrapper';
import ReactToPrint from 'react-to-print';
import styles from './index.module.css'; 

const PrintResults = ({ state, onChange, open, onHide }) => {
    const [loading, setLoading] = useState(false);
    const pdfComponentRef = useRef();
 
    return ( 
        <Fragment>            
            <Modal title="Raport z obliczeń" open={open} onHide={onHide} hideButton={false}>
                <Row className={styles.row}>
                    <Col xs={12} sm={6}>
                        <Checkbox name="allItems" label="Zaznacz wszystko" value={state.reportItem.allItems} onChange={onChange}/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="firstPage" label="Strona tytułowa" value={state.reportItem.firstPage} onChange={onChange}/>
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <SectionTitle title="Dane wejściowe"/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="soilsTable" label="Tabela parametrów gruntu" value={state.reportItem.soilsTable} onChange={onChange}/>
                        <Checkbox name="projectInfo" label="Parametry ogólne obliczeń" value={state.reportItem.projectInfo} onChange={onChange}/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="pileParameters" label="Parametry pala" value={state.reportItem.pileParameters} onChange={onChange}/>
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <SectionTitle title="Wyniki obliczeń"/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="pnCapacityDetails" label="Wyniki nośności wg PN-83/B-2482" value={state.reportItem.pnCapacityDetails} onChange={onChange}/>
                        <Checkbox name="enCapacityDetails" label="Wyniki nośności wg EC7" value={state.reportItem.enCapacityDetails} onChange={onChange}/>
                    </Col>
                    <Col xs={12} sm={6}>
                        {
                            !state.results.singlePile &&
                            <Checkbox name="pilesInGroupDetails" label="Wyniki nośności pala w grupie" value={state.reportItem.pilesInGroupDetails} onChange={onChange}/>
                        }
                        <Checkbox name="helperFactorsDetails" label="Tabela współczynników technologicznych" value={state.reportItem.helperFactorsDetails} onChange={onChange}/>
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <SectionTitle title="Załączniki graficzne"/>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Checkbox name="pileChart" label="Wykres nośności pala wciskanego" value={state.reportItem.pileChart} onChange={onChange}/>
                    </Col>
                    {
                        state.reportItem.pileChart &&
                        <Col xs={12} sm={6}>
                            <InputField
                                title={'Długość pala wciskanego'}
                                onChange={onChange}              
                                name="pileLength"
                                type="number"
                                value={state.reportItem.pileLength}
                                
                                placeholder="Dł. pala wciskanego"
                            />
                        </Col>
                    }
                </Row>   
                <Row className={styles.row}>
                    <Col xs={12} sm={6}>
                        <Checkbox name="pullPileChart" label="Wykres nośności pala wyciąganego" value={state.reportItem.pullPileChart} onChange={onChange}/>
                    </Col>
                    {
                        state.reportItem.pullPileChart &&
                        <Col xs={12} sm={6}>
                            <InputField
                                title={'Długość pala wyciąganego'}
                                onChange={onChange}              
                                name="pullPileLength"
                                type="number"
                                value={state.reportItem.pullPileLength}
                                
                                placeholder="Dł. pala wyciąganego"
                            />
                        </Col>
                    }
                </Row>    
                <Row className={styles.row}>
                    <Col xs={12} sm={6}>
                        <Checkbox name="qcdChart" label="Rozkład oporu gruntu pod podstawą" value={state.reportItem.qcdChart} onChange={(e) => {
                            if(!state.reportItem.qcdChart){
                                setLoading(true)
                            }
                            onChange(e);
                        }}/>               
                    </Col> 
                    <Col xs={12} sm={6}>
                        <Checkbox name="tcdChart" label="Rozkład oporu gruntu na pobocznicy" value={state.reportItem.tcdChart} onChange={(e) => {
                            if(!state.reportItem.tcdChart){
                                setLoading(true)
                            }
                            onChange(e);
                        }}/>  
                    </Col>                  
                </Row>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <ReactToPrint 
                            trigger = {() => <Button css={`${styles.button} default-button-style`} altTitle="Drukuj" title="Drukuj raport" type="button" loading={loading}/>}
                            content={() => pdfComponentRef.current}
                        /> 
                    </Col>
                </Row> 
                
                <ReportWrapper
                    setLoading={useCallback((e) => setLoading(e),[])}
                    pdfState={state}
                    ref={pdfComponentRef} 
                />
            </Modal>
        </Fragment>
    )
}

export default PrintResults