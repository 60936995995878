import { Fragment } from "react";
import AlertMessage from "../Alert";
import Loading from "../Loading";
import styles from './index.module.css';

const MainAlertAndLoading = ({ alerts=[], updateAlerts, loadings={} }) => {

    return (
        <Fragment>
            <div className={styles.alerts}>
                {
                    Array.isArray(alerts) && alerts.length > 0 && 

                    alerts.map((ele, index) => {
                        return (
                            <AlertMessage
                                index={index}
                                updateState={updateAlerts}
                                key={index}
                                message={ele.message}
                                error={ele.error}
                            />
                        )
                    })
                }
            </div>
            {
                loadings.calculations && 
                <div className={styles.loadingWrapper}>
                    <Loading text={loadings.text ? loadings.text : "Wykonuję obliczenia..."}/>
                </div>
            }
            {
                loadings.fetchProject && 
                <div className={styles.loadingWrapper}>
                    <Loading text="Wczytuję projekt..."/>
                </div>  
            }
            {
                loadings.removeCalculation && 
                <div className={styles.loadingWrapper}>
                    <Loading text="Usuwam obliczenia..."/>
                </div>  
            }
            {
                loadings.fetchProfile && 
                <div className={styles.loadingWrapper}>
                    <Loading text="Wczytuję profil geologiczny..."/>
                </div>
            }
        </Fragment>
    )
}

export default MainAlertAndLoading