import { isNumber } from '../../../../../utils';
import styles from './index.module.css';

const FoundationParametersSection = ({ parameters = {} }) => {
    return (
        <div className={styles.wrapper}>
            <h2>Parametry fundamentu</h2>
            <p>
                Liczba kolumn pali:{' '}
                <span className={styles.value}>
                    {parameters.columns.value} [-]
                </span>
            </p>
            <p>
                Liczba rzędów pali:{' '}
                <span className={styles.value}>
                    {parameters.rows.value} [-]
                </span>
            </p>
            {isNumber(parameters.perpendicularSpace.value) && (
                <p>
                    Rozstaw kolumn:{' '}
                    <span className={styles.value}>
                        {parameters.perpendicularSpace.value.toFixed(2)} m
                    </span>
                </p>
            )}
            {isNumber(parameters.parallelSpace.value) && (
                <p>
                    Rozstaw rzędów:{' '}
                    <span className={styles.value}>
                        {parameters.parallelSpace.value.toFixed(2)} m
                    </span>
                </p>
            )}
        </div>
    );
};

export default FoundationParametersSection;
