const pilesShape = {
    'CFA': 'Kołowy', 
    'Z rury stalowej z dnem otwartym': 'Kołowy', 
    'Z rury stalowej z dnem zamkniętym': 'Kołowy',
    'Żelbetowe prefabrykowane': 'Kwadratowy', 
    'Z profili stalowych': ['Kwadratowy', 'Kołowy'],
    'Wiercone bez iniekcji pod postawą': 'Kołowy', 
    'Omega': 'Kołowy', 
    'Atlas': 'Kołowy',
    'Vibro': 'Kołowy',
    'Vibro-Fundex': 'Kołowy',
    'FDP': 'Kołowy',
    'SDP': 'Kołowy',
    'PCS Lambda': 'Kołowy',
    'Starsol': 'Kołowy',
    'Franki': 'Kołowy'
};

export default pilesShape