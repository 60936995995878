import React from 'react';
import { Form } from 'react-bootstrap';
import { isEmail } from '../../config/ProjectConfig';
import styles from './index.module.css';

const Email = ({ onChange, onBlur, email: { value, isValid, occupied = false }, children, label='', placeholder, autofocus, required }) => {

    return ( 
        <Form.Group controlId="email">
            {label && <Form.Label>{label}</Form.Label>}
            <input
                className={`${styles.inputEmail} ${isValid === false ? styles.inputEmailWrong : ''}`}
                onChange={(e) => onChange(e, e.currentTarget.closest('input').checkValidity() && isEmail.test(e.currentTarget.closest('input').value))}
                onBlur={onBlur}
                type="text" 
                name="email" 
                value={value} 
                placeholder={placeholder} 
                autoFocus={autofocus}
                required={required}
            />
            
            {(isValid === false && occupied === false) && <div>
                <ul className={`${styles.ulList} ${isValid === false ? styles.ulListWrong : ''}`}>
                    <li>Niepoprawny adres email</li>
                    {required && <li>Adres email jest obowiązkowy</li>}
                </ul>                            
            </div>}
            {children}
        </Form.Group>
    )
} 

export default Email