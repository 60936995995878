import React, { Fragment, useCallback, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Checkbox, SectionTitle, Modal } from '../../../../components';
import ReportWrapper from './ReportWrapper';
import ReactToPrint from 'react-to-print';
import styles from './index.module.css'; 

const PrintResults = ({ state, onChange, open, onHide }) => {
    const [loading, setLoading] = useState(false);
    const pdfComponentRef = useRef();
 
    return ( 
        <Fragment>            
            <Modal title="Raport z obliczeń" open={open} onHide={onHide} hideButton={false}>
                <Row className={styles.row}>
                    <Col xs={12} sm={6}>
                        <Checkbox name="allItems" label="Zaznacz wszystko" value={state.reportItem.allItems} onChange={onChange}/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="firstPage" label="Strona tytułowa" value={state.reportItem.firstPage} onChange={onChange}/>
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <SectionTitle title="Dane wejściowe"/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="projectInfo" label="Parametry ogólne obliczeń" value={state.reportItem.projectInfo} onChange={onChange}/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="pileParameters" label="Parametry ścianki szczelnej" value={state.reportItem.pileParameters} onChange={onChange}/>
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <SectionTitle title="Wyniki obliczeń"/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="capacityDetails" label="Wyniki nośności pionowej grodzicy" value={state.reportItem.capacityDetails} onChange={onChange}/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="capacityTable" label="Tabela wyników nośności grodzicy" value={state.reportItem.capacityTable} onChange={onChange}/>
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <SectionTitle title="Załączniki graficzne"/>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Checkbox name="pileChart" label="Wykres nośności pionowej grodzicy" value={state.reportItem.pileChart} onChange={onChange}/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="cptChart" label="Wykres sondowania CPT" value={state.reportItem.cptChart} onChange={(e) => {
                            if(!state.reportItem.cptChart){
                                setLoading(true)
                            }
                            onChange(e);
                        }}/>  
                    </Col>   
                </Row>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <ReactToPrint 
                            trigger = {() => <Button css={`${styles.button} default-button-style`} altTitle="Drukuj" title="Drukuj raport" type="button" loading={loading}/>}
                            content={() => pdfComponentRef.current}
                        /> 
                    </Col>
                </Row>
                <ReportWrapper
                    setLoading={useCallback((e) => setLoading(e),[])}
                    pdfState={state}
                    ref={pdfComponentRef} 
                />
            </Modal>
        </Fragment>
    )
}

export default PrintResults