const defaultDiameter = {
    d: 12,
    w: 0.888
};
const defaultDiameterSpiral = {
    d: 10,
    w: 0.617
};
const rebarDiameters = [
    {
        d: 6,
        w: 0.222       
    },
    {
        d: 8,
        w: 0.395   
    },
    {
        d: 10,
        w: 0.617   
    },
    {
        d: 12,
        w: 0.888   
    },
    {
        d: 14,
        w: 1.21   
    },
    {
        d: 16,
        w: 1.58    
    },
    {
        d: 18,
        w: 2    
    },
    {
        d: 20,
        w: 2.47       
    },
    {
        d: 22,
        w: 2.98    
    },
    {
        d: 25,
        w: 3.85    
    },
    {
        d: 28,
        w: 4.83
    },
    {
        d: 32,
        w: 6.31
    },
    {
        d: 34,
        w: 7.09
    },
    {
        d: 36,
        w: 7.99
    },
    {
        d: 40,
        w: 9.87
    },
    {
        d: 45,
        w: 12.5
    },
]

export {
    defaultDiameter,
    defaultDiameterSpiral,
    rebarDiameters
}