const sheetPiles = {
    //mm

    'AZ 18-800' : {
        type: 'z',
        b: 800,
        h: 449,
        tf: 8.5,
        tw: 8.5,
        a: 129,
        gsp: 80.7,
        gw: 101,
        iy: 41320,
        wel: 1840,
        sy: 1065,
        wpl: 2135
    },
    'AZ 20-800' : {
        type: 'z',
        b: 800,
        h: 450,
        tf: 9.5,
        tw: 9.5,
        a: 141,
        gsp: 88.6,
        gw: 111,
        iy: 45050,
        wel: 2000,
        sy: 1165,
        wpl: 2330
    },
    'AZ 22-800' : {
        type: 'z',
        b: 800,
        h: 451,
        tf: 10.5,
        tw: 10.5,
        a: 153,
        gsp: 96.4,
        gw: 120,
        iy: 48790,
        wel: 2165,
        sy: 1260,
        wpl: 2525
    },
    'AZ 23-800' : {
        type: 'z',
        b: 800,
        h: 474,
        tf: 11.5,
        tw: 9,
        a: 151,
        gsp: 94.6,
        gw: 118,
        iy: 55260,
        wel: 2330,
        sy: 1340,
        wpl: 2680
    },
    'AZ 25-800' : {
        type: 'z',
        b: 800,
        h: 475,
        tf: 12.5,
        tw: 10,
        a: 163,
        gsp: 102.6,
        gw: 128,
        iy: 59410,
        wel: 2500,
        sy: 1445,
        wpl: 2890
    },
    'AZ 27-800' : {
        type: 'z',
        b: 800,
        h: 476,
        tf: 13.5,
        tw: 11,
        a: 176,
        gsp: 110.5,
        gw: 138,
        iy: 63570,
        wel: 2670,
        sy: 1550,
        wpl: 3100
    },
    'AZ 28-750' : {
        type: 'z',
        b: 750,
        h: 509,
        tf: 12,
        tw: 10,
        a: 171,
        gsp: 100.8,
        gw: 134,
        iy: 71540,
        wel: 2810,
        sy: 1620,
        wpl: 3245
    },
    'AZ 30-750' : {
        type: 'z',
        b: 750,
        h: 510,
        tf: 13,
        tw: 11,
        a: 185,
        gsp: 108.8,
        gw: 145,
        iy: 76670,
        wel: 3005,
        sy: 1740,
        wpl: 3485
    },
    'AZ 32-750' : {
        type: 'z',
        b: 750,
        h: 511,
        tf: 14,
        tw: 12,
        a: 198,
        gsp: 116.7,
        gw: 156,
        iy: 81800,
        wel: 3200,
        sy: 1860,
        wpl: 3720
    },
    'AZ 12-770' : {
        type: 'z',
        b: 770,
        h: 344,
        tf: 8.5,
        tw: 8.5,
        a: 120,
        gsp: 72.6,
        gw: 94,
        iy: 21430,
        wel: 1245,
        sy: 740,
        wpl: 1480
    },
    'AZ 13-770' : {
        type: 'z',
        b: 770,
        h: 344,
        tf: 9,
        tw: 9,
        a: 126,
        gsp: 76.1,
        gw: 99,
        iy: 22360,
        wel: 1300,
        sy: 775,
        wpl: 1546
    },
    'AZ 14-770' : {
        type: 'z',
        b: 770,
        h: 345,
        tf: 9.5,
        tw: 9.5,
        a: 132,
        gsp: 79.5,
        gw: 103,
        iy: 23300,
        wel: 1355,
        sy: 805,
        wpl: 1611
    },
    'AZ 12-700' : {
        type: 'z',
        b: 700,
        h: 314,
        tf: 8.5,
        tw: 8.5,
        a: 123,
        gsp: 67.7,
        gw: 97,
        iy: 18880,
        wel: 1205,
        sy: 710,
        wpl: 1415
    },
    'AZ 13-700' : {
        type: 'z',
        b: 700,
        h: 315,
        tf: 9.5,
        tw: 9.5,
        a: 135,
        gsp: 74,
        gw: 106,
        iy: 20540,
        wel: 1305,
        sy: 770,
        wpl: 1540
    },
    'AZ 13-700-10/10' : {
        type: 'z',
        b: 700,
        h: 316,
        tf: 10,
        tw: 10,
        a: 140,
        gsp: 77.2,
        gw: 110,
        iy: 21370,
        wel: 1355,
        sy: 800,
        wpl: 1600
    },
    'AZ 14-700' : {
        type: 'z',
        b: 700,
        h: 316,
        tf: 10.5,
        tw: 10.5,
        a: 146,
        gsp: 80.3,
        gw: 115,
        iy: 22190,
        wel: 1405,
        sy: 835,
        wpl: 1665
    },
    'AZ 17-700' : {
        type: 'z',
        b: 700,
        h: 420,
        tf: 8.5,
        tw: 8.5,
        a: 133,
        gsp: 73.1,
        gw: 104,
        iy: 36230,
        wel: 1730,
        sy: 1015,
        wpl: 2027 
    },
    'AZ 18-700' : {
        type: 'z',
        b: 700,
        h: 420,
        tf: 9,
        tw: 9,
        a: 139,
        gsp: 76.5,
        gw: 109,
        iy: 37800,
        wel: 1800,
        sy: 1060,
        wpl: 2116
    },
    'AZ 19-700' : {
        type: 'z',
        b: 700,
        h: 421,
        tf: 9.5,
        tw: 9.5,
        a: 146,
        gsp: 80,
        gw: 114,
        iy: 39380,
        wel: 1870,
        sy: 1105,
        wpl: 2206
    },
    'AZ 20-700' : {
        type: 'z',
        b: 700,
        h: 421,
        tf: 10,
        tw: 10,
        a: 152,
        gsp: 83.5,
        gw: 119,
        iy: 40960,
        wel: 1945,
        sy: 1150,
        wpl: 2296 
    },
    'AZ 24-700' : {
        type: 'z',
        b: 700,
        h: 459,
        tf: 11.2,
        tw: 11.2,
        a: 174,
        gsp: 95.7,
        gw: 137,
        iy: 55820,
        wel: 2430,
        sy: 1435,
        wpl: 2867
    },
    'AZ 26-700' : {
        type: 'z',
        b: 700,
        h: 460,
        tf: 12.2,
        tw: 12.2,
        a: 187,
        gsp: 102.9,
        gw: 147,
        iy: 59720,
        wel: 2600,
        sy: 1535,
        wpl: 3070
    },
    'AZ 28-700' : {
        type: 'z',
        b: 700,
        h: 461,
        tf: 13.2,
        tw: 13.2,
        a: 200,
        gsp: 110,
        gw: 157,
        iy: 63620,
        wel: 2760,
        sy: 1635,
        wpl: 3273
    },
    'AZ 36-700N' : {
        type: 'z',
        b: 700,
        h: 499,
        tf: 15,
        tw: 11.2,
        a: 216,
        gsp: 118.6,
        gw: 169,
        iy: 89610,
        wel: 3590,
        sy: 2055,
        wpl: 4110
    },
    'AZ 38-700N' : {
        type: 'z',
        b: 700,
        h: 500,
        tf: 16,
        tw: 12.2,
        a: 230,
        gsp: 126.4,
        gw: 181,
        iy: 94840,
        wel: 3795,
        sy: 2180,
        wpl: 4360
    },
    'AZ 40-700N' : {
        type: 'z',
        b: 700,
        h: 501,
        tf: 17,
        tw: 13.2,
        a: 244,
        gsp: 134.2,
        gw: 192,
        iy: 100080,
        wel: 3995,
        sy: 2305,
        wpl: 4605
    },
    'AZ 42-700N' : {
        type: 'z',
        b: 700,
        h: 499,
        tf: 18,
        tw: 14,
        a: 259,
        gsp: 142.1,
        gw: 203,
        iy: 104930,
        wel: 4205,
        sy: 2425,
        wpl: 4855
    },
    'AZ 44-700N' : {
        type: 'z',
        b: 700,
        h: 500,
        tf: 19,
        tw: 15,
        a: 273,
        gsp: 149.9,
        gw: 214,
        iy: 110150,
        wel: 4405,
        sy: 2550,
        wpl: 5105
    },
    'AZ 46-700N' : {
        type: 'z',
        b: 700,
        h: 501,
        tf: 20,
        tw: 16,
        a: 287,
        gsp: 157.7,
        gw: 225,
        iy: 115370,
        wel: 4605,
        sy: 2675,
        wpl: 5350
    },
    'AZ 48-700' : {
        type: 'z',
        b: 700,
        h: 503,
        tf: 22,
        tw: 15,
        a: 288,
        gsp: 158.5,
        gw: 226,
        iy: 119650,
        wel: 4755,
        sy: 2745,
        wpl: 5490
    },
    'AZ 50-700' : {
        type: 'z',
        b: 700,
        h: 504,
        tf: 23,
        tw: 16,
        a: 303,
        gsp: 166.3,
        gw: 238,
        iy: 124890,
        wel: 4955,
        sy: 2870,
        wpl: 5735
    },
    'AZ 52-700' : {
        type: 'z',
        b: 700,
        h: 505,
        tf: 24,
        tw: 17,
        a: 317,
        gsp: 174.1,
        gw: 249,
        iy: 130140,
        wel: 5155,
        sy: 2990,
        wpl: 5985
    },
    'AZ 18' : {
        type: 'z',
        b: 630,
        h: 380,
        tf: 9.5,
        tw: 9.5,
        a: 150,
        gsp: 74.4,
        gw: 118,
        iy: 34200,
        wel: 1800,
        sy: 1050,
        wpl: 2104
    },
    'AZ 18-10/10' : {
        type: 'z',
        b: 630,
        h: 381,
        tf: 10,
        tw: 10,
        a: 157,
        gsp: 77.8,
        gw: 123,
        iy: 35540,
        wel: 1870,
        sy: 1095,
        wpl: 2189
    },
    'AZ 26' : {
        type: 'z',
        b: 630,
        h: 427,
        tf: 13,
        tw: 12.2,
        a: 198,
        gsp: 97.8,
        gw: 155,
        iy: 55510,
        wel: 2600,
        sy: 1530,
        wpl: 3059
    },
    'GU 6N' : {
        type: 'u',
        b: 600,
        h: 309,
        tf: 6,
        tw: 6,
        a: 89,
        gsp: 41.9,
        gw: 70,
        iy: 9670,
        wel: 625,
        sy: 375,
        wpl: 765
    },
    'GU 7N' : {
        type: 'u',
        b: 600,
        h: 310,
        tf: 6.5,
        tw: 6.4,
        a: 94,
        gsp: 44.1,
        gw: 74,
        iy: 10450,
        wel: 675,
        sy: 400,
        wpl: 825
    },
    'GU 7S' : {
        type: 'u',
        b: 600,
        h: 311,
        tf: 7.2,
        tw: 6.9,
        a: 98,
        gsp: 46.3,
        gw: 77,
        iy: 11540,
        wel: 740,
        sy: 440,
        wpl: 900
    },
    'GU 7HWS' : {
        type: 'u',
        b: 600,
        h: 312,
        tf: 7.3,
        tw: 6.9,
        a: 101,
        gsp: 47.4,
        gw: 79,
        iy: 11620,
        wel: 745,
        sy: 445,
        wpl: 910
    },
    'GU 8N' : {
        type: 'u',
        b: 600,
        h: 312,
        tf: 7.5,
        tw: 7.1,
        a: 103,
        gsp: 48.5,
        gw: 81,
        iy: 12010,
        wel: 770,
        sy: 460,
        wpl: 935
    },
    'GU 8S' : {
        type: 'u',
        b: 600,
        h: 313,
        tf: 8,
        tw: 7.5,
        a: 108,
        gsp: 50.8,
        gw: 85,
        iy: 12800,
        wel: 820,
        sy: 490,
        wpl: 995
    },
    'GU 10N' : {
        type: 'u',
        b: 600,
        h: 316,
        tf: 9,
        tw: 6.8,
        a: 118,
        gsp: 55.8,
        gw: 93,
        iy: 15700,
        wel: 990,
        sy: 565,
        wpl: 1160
    },
    'GU 11N' : {
        type: 'u',
        b: 600,
        h: 318,
        tf: 10,
        tw: 7.4,
        a: 128,
        gsp: 60.2,
        gw: 100,
        iy: 17450,
        wel: 1095,
        sy: 630,
        wpl: 1280
    },
    'GU 12N' : {
        type: 'u',
        b: 600,
        h: 320,
        tf: 11,
        tw: 8,
        a: 137,
        gsp: 64.6,
        gw: 108,
        iy: 19220,
        wel: 1200,
        sy: 690,
        wpl: 1400
    },
    'GU 13N' : {
        type: 'u',
        b: 600,
        h: 418,
        tf: 9,
        tw: 7.4,
        a: 127,
        gsp: 59.9,
        gw: 100,
        iy: 26590,
        wel: 1270,
        sy: 755,
        wpl: 1535
    },
    'GU 14N' : {
        type: 'u',
        b: 600,
        h: 420,
        tf: 10,
        tw: 8,
        a: 136,
        gsp: 64.3,
        gw: 107,
        iy: 29410,
        wel: 1400,
        sy: 830,
        wpl: 1685
    },
    'GU 15N' : {
        type: 'u',
        b: 600,
        h: 422,
        tf: 11,
        tw: 8.6,
        a: 146,
        gsp: 68.7,
        gw: 115,
        iy: 32260,
        wel: 1530,
        sy: 910,
        wpl: 1840
    },
    'GU 16N' : {
        type: 'u',
        b: 600,
        h: 430,
        tf: 10.2,
        tw: 8.4,
        a: 154,
        gsp: 72.6,
        gw: 121,
        iy: 35950,
        wel: 1670,
        sy: 980,
        wpl: 1988
    },
    'GU 18N' : {
        type: 'u',
        b: 600,
        h: 430,
        tf: 11.2,
        tw: 9,
        a: 163,
        gsp: 76.9,
        gw: 128,
        iy: 38650,
        wel: 1800,
        sy: 1055,
        wpl: 2134
    },
    'GU 20N' : {
        type: 'u',
        b: 600,
        h: 430,
        tf: 12.2,
        tw: 9.5,
        a: 172,
        gsp: 81.1,
        gw: 135,
        iy: 41320,
        wel: 1920,
        sy: 1125,
        wpl: 2280 
    },
    'GU 21N' : {
        type: 'u',
        b: 600,
        h: 450,
        tf: 11.1,
        tw: 9,
        a: 174,
        gsp: 81.9,
        gw: 137,
        iy: 46380,
        wel: 2060,
        sy: 1195,
        wpl: 2422
    },
    'GU 22N' : {
        type: 'u',
        b: 600,
        h: 450,
        tf: 12.1,
        tw: 9.5,
        a: 183,
        gsp: 86.1,
        gw: 144,
        iy: 49460,
        wel: 2200,
        sy: 1275,
        wpl: 2580
    },
    'GU 23N' : {
        type: 'u',
        b: 600,
        h: 450,
        tf: 13.1,
        tw: 10,
        a: 192,
        gsp: 90.4,
        gw: 151,
        iy: 52510,
        wel: 2335,
        sy: 1355,
        wpl: 2735
    },
    'GU 27N' : {
        type: 'u',
        b: 600,
        h: 452,
        tf: 14.2,
        tw: 9.7,
        a: 207,
        gsp: 97.4,
        gw: 162,
        iy: 60580,
        wel: 2680,
        sy: 1525,
        wpl: 3087
    },
    'GU 28N' : {
        type: 'u',
        b: 600,
        h: 454,
        tf: 15.2,
        tw: 10.1,
        a: 216,
        gsp: 101.8,
        gw: 170,
        iy: 64460,
        wel: 2840,
        sy: 1620,
        wpl: 3269
    },
    'GU 30N' : {
        type: 'u',
        b: 600,
        h: 456,
        tf: 16.2,
        tw: 10.5,
        a: 226,
        gsp: 106.2,
        gw: 177,
        iy: 68380,
        wel: 3000,
        sy: 1710,
        wpl: 3450
    },
    'GU 31N' : {
        type: 'u',
        b: 600,
        h: 452,
        tf: 18.5,
        tw: 10.6,
        a: 233,
        gsp: 109.9,
        gw: 183,
        iy: 69210,
        wel: 3065,
        sy: 1745,
        wpl: 3525
    },
    'GU 32N' : {
        type: 'u',
        b: 600,
        h: 452,
        tf: 19.5,
        tw: 11,
        a: 242,
        gsp: 114.1,
        gw: 190,
        iy: 72320,
        wel: 3200,
        sy: 1825,
        wpl: 3687
    },
    'GU 33N' : {
        type: 'u',
        b: 600,
        h: 452,
        tf: 20.5,
        tw: 11.4,
        a: 251,
        gsp: 118.4,
        gw: 197,
        iy: 75410,
        wel: 3340,
        sy: 1905,
        wpl: 3845
    },
    'GU 16-400' : {
        type: 'u',
        b: 400,
        h: 290,
        tf: 12.7,
        tw: 9.4,
        a: 197,
        gsp: 62,
        gw: 155,
        iy: 22580,
        wel: 1560,
        sy: 885,
        wpl: 1815
    },
    'GU 18-400' : {
        type: 'u',
        b: 400,
        h: 292,
        tf: 15,
        tw: 9.7,
        a: 221,
        gsp: 69.3,
        gw: 173,
        iy: 26090,
        wel: 1785,
        sy: 1015,
        wpl: 2080
    },


    'PU 12' : {
        type: 'u',
        b: 600,
        h: 360,
        tf: 9.8,
        tw: 9,
        a: 140,
        gsp: 66.1,
        gw: 110,
        iy: 21600,
        wel: 1200,
        sy: 715,
        wpl: 1457
    },
    'PU 12S' : {
        type: 'u',
        b: 600,
        h: 360,
        tf: 10,
        tw: 10,
        a: 151,
        gsp: 71,
        gw: 118,
        iy: 22660,
        wel: 1260,
        sy: 755,
        wpl: 1543
    },
    'PU 18-1' : {
        type: 'u',
        b: 600,
        h: 430,
        tf: 10.2,
        tw: 8.4,
        a: 154,
        gsp: 72.6,
        gw: 121,
        iy: 35950,
        wel: 1670,
        sy: 980,
        wpl: 1988
    },
    'PU 18' : {
        type: 'u',
        b: 600,
        h: 430,
        tf: 11.2,
        tw: 9,
        a: 163,
        gsp: 76.9,
        gw: 128,
        iy: 38650,
        wel: 1800,
        sy: 1055,
        wpl: 2134
    },
    'PU 18+1' : {
        type: 'u',
        b: 600,
        h: 430,
        tf: 12.2,
        tw: 9.5,
        a: 172,
        gsp: 81.1,
        gw: 135,
        iy: 41320,
        wel: 1920,
        sy: 1125,
        wpl: 2280
    },
    'PU 22-1' : {
        type: 'u',
        b: 600,
        h: 450,
        tf: 11.1,
        tw: 9,
        a: 174,
        gsp: 81.9,
        gw: 137,
        iy: 46380,
        wel: 2060,
        sy: 1195,
        wpl: 2422
    },
    'PU 22' : {
        type: 'u',
        b: 600,
        h: 450,
        tf: 12.1,
        tw: 9.5,
        a: 183,
        gsp: 86.1,
        gw: 144,
        iy: 49460,
        wel: 2200,
        sy: 1275,
        wpl: 2580
    },
    'PU 22+1' : {
        type: 'u',
        b: 600,
        h: 450,
        tf: 13.1,
        tw: 10,
        a: 192,
        gsp: 90.4,
        gw: 151,
        iy: 52510,
        wel: 2335,
        sy: 1355,
        wpl: 2735
    },
    'PU 28-1' : {
        type: 'u',
        b: 600,
        h: 452,
        tf: 14.2,
        tw: 9.7,
        a: 207,
        gsp: 97.4,
        gw: 162,
        iy: 60580,
        wel: 2680,
        sy: 1525,
        wpl: 3087
    },
    'PU 28' : {
        type: 'u',
        b: 600,
        h: 454,
        tf: 15.2,
        tw: 10.1,
        a: 216,
        gsp: 101.8,
        gw: 170,
        iy: 64460,
        wel: 2840,
        sy: 1620,
        wpl: 3269
    },
    'PU 28+1' : {
        type: 'u',
        b: 600,
        h: 456,
        tf: 16.2,
        tw: 10.5,
        a: 226,
        gsp: 106.2,
        gw: 177,
        iy: 68380,
        wel: 3000,
        sy: 1710,
        wpl: 3450
    },
    'PU 32-1' : {
        type: 'u',
        b: 600,
        h: 452,
        tf: 18.5,
        tw: 10.6,
        a: 233,
        gsp: 109.9,
        gw: 183,
        iy: 69210,
        wel: 3065,
        sy: 1745,
        wpl: 3525
    },
    'PU 32' : {
        type: 'u',
        b: 600,
        h: 452,
        tf: 19.5,
        tw: 11,
        a: 242,
        gsp: 114.1,
        gw: 190,
        iy: 72320,
        wel: 3200,
        sy: 1825,
        wpl: 3687
    },
    'PU 32+1' : {
        type: 'u',
        b: 600,
        h: 452,
        tf: 20.5,
        tw: 11.4,
        a: 251,
        gsp: 118.4,
        gw: 197,
        iy: 75410,
        wel: 3340,
        sy: 1905,
        wpl: 3845
    },

    'AU 14' : {
        type: 'u',
        b: 750,
        h: 408,
        tf: 10,
        tw: 8.3,
        a: 132,
        gsp: 77.9,
        gw: 104,
        iy: 28680,
        wel: 1405,
        sy: 820,
        wpl: 1663
    },
    'AU 16' : {
        type: 'u',
        b: 750,
        h: 411,
        tf: 11.5,
        tw: 9.3,
        a: 147,
        gsp: 86.3,
        gw: 115,
        iy: 32850,
        wel: 1600,
        sy: 935,
        wpl: 1891
    },
    'AU 18' : {
        type: 'u',
        b: 750,
        h: 441,
        tf: 10.5,
        tw: 9.1,
        a: 150,
        gsp: 88.5,
        gw: 118,
        iy: 39300,
        wel: 1780,
        sy: 1030,
        wpl: 2082
    },
    'AU 20' : {
        type: 'u',
        b: 750,
        h: 444,
        tf: 12,
        tw: 10,
        a: 165,
        gsp: 96.9,
        gw: 129,
        iy: 44440,
        wel: 2000,
        sy: 1155,
        wpl: 2339
    },
    'AU 23' : {
        type: 'u',
        b: 750,
        h: 447,
        tf: 13,
        tw: 9.5,
        a: 173,
        gsp: 102.1,
        gw: 136,
        iy: 50700,
        wel: 2270,
        sy: 1285,
        wpl: 2600
    },
    'AU 25' : {
        type: 'u',
        b: 750,
        h: 450,
        tf: 14.5,
        tw: 10.2,
        a: 188,
        gsp: 110.4,
        gw: 147,
        iy: 56240,
        wel: 2500,
        sy: 1420,
        wpl: 2866 
    },
}

export default sheetPiles