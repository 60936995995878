import React, { useState } from 'react';
import { withDefaultPrintChart } from '../../../../../hoc';
import { roundNumber, stringToNumber } from '../../../../../utils';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';
import ApexCharts from "apexcharts";

const CapacityChart = ({
    results={},
    circular,
    calcResult={},
    chartOptions = {},
    setDataURI,

    chartTitle,
    xLabel,
    yLabel
}) => {
    const [options, setOptions] = useState(chartOptions);
    const [series, setSeries] = useState([
        {
            name: '',
            data: [],
        },
    ]);

    useDeepCompareEffect(() => {
        setSeries(() => {
            return [
                {
                    name: 'Mrd',
                    data: results.collectionPositive,
                },
                {
                    name: 'Mrd1',
                    data: results.collectionNegative,
                },
            ];
        });

        setOptions(() => {
            return {
                ...chartOptions,
                legend: {
                    show: false
                },
                title: {
                    ...chartOptions['title'],
                    text: chartTitle,
                    align: 'center',
                    floating: true,
                    offsetY: 3,
                    margin: 25,
                    style: {
                        fontSize: `12mm`,
                        color: '#000',
                    },
                },
                subtitle: {
                    ...chartOptions['subtitle'],
                    align: 'center',
                    offsetY: 60,
                    margin: 5,
                    style: {
                        fontSize: `9mm`,
                        color: '#000',
                    },

                    text: circular
                        ? 
                        [`As - ${roundNumber(results.as,2)} cm2 `]
                        : 
                        [`As1 - ${roundNumber(results.as1,2)} cm2 (${calcResult.result.x > calcResult.defaultResults.a2 
                            ?
                            calcResult.defaultResults.negativeMoment ? 'ściskane' : 'rozciągane'
                            :
                            'rozciągane'
                        }), As2 - ${roundNumber(results.as2,2)} cm2 (${calcResult.result.x > calcResult.defaultResults.a2 
                            ?
                            calcResult.defaultResults.negativeMoment ? 'rozciągane' : 'ściskane'
                            :
                            'rozciągane'
                        })`],
                },
                yaxis: {
                    ...chartOptions['yaxis'],
                    reversed: false,
                    tickAmount: 9,
                    title: {
                        ...chartOptions['yaxis']['title'],
                        text: yLabel,
                        offsetX: -15,
                        style: {
                            fontSize: '12mm'
                        }
                    },
                    axisBorder: {
                        show: false
                    }
                },
                xaxis: {
                    ...chartOptions['xaxis'],
                    max: 1.15*Math.ceil(results.maxMrd.x/100)*100,
                    min: 1.15*Math.floor(results.minMrd.x/100)*100,
                    type: 'numeric',
                    title: {
                        ...chartOptions['xaxis']['title'],
                        offsetY: 25,
                        text: xLabel,

                        style: {
                            fontSize: '12mm'
                        }
                    },
                    labels: {
                        ...chartOptions['xaxis']['labels'],
                        offsetY: 15,
                        formatter: (val) => {
                            return Math.abs(stringToNumber(val.toFixed(0))) === 0 ? '' : val.toFixed(0)
                        },
                        style: {
                            ...chartOptions['xaxis']['labels']['style'],
                            fontSize: '9mm'
                        }
                    },
                    axisBorder: {
                        show: false
                    }
                },
                grid: {
                    show: true,
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },   
                    yaxis: {
                        lines: {
                            show: true
                        }
                    }, 
                },
                fill: {
                    ...chartOptions['fill'],
                    colors: ['#ff7f7f', '#318CE7'],
                    opacity: 0.1,
                },
                stroke: {
                    ...chartOptions['stroke'],
                    colors: ['#b20000', '#318CE7'],
                    width: 1,
                },
                dataLabels: {
                    ...chartOptions['dataLabels'],
                    offsetY: 0,
                    offsetX: 0,
                    formatter: function (x, opt) {
                        const series = opt.w.globals.initialSeries[opt.seriesIndex].data;
                        return roundNumber(series[opt.dataPointIndex][0], 2);
                    },
                },
                markers: {
                    size: 0,
                },
                tooltip: {
                    enabled: false
                },
                annotations: {
                    ...chartOptions['annotations'],
                    xaxis: [
                        {
                            x: 0,
                            opacity: 1,
                            strokeDashArray: 0,
                            borderColor: '#ccc',
                            label: {
                                text: 0,
                                position: 'bottom',
                                orientation: 'horizontal',
                                offsetY: 25,
                            }
                        },
                    ],
                    points: results.points.filter(ele => ele.label.borderColor === 'red').map(ele => {
                        if(ele.label){
                            if(ele.label.style){
                                return {
                                    ...ele,
                                    label : {
                                        ...ele['label'],
                                        offsetY: 50,
                                        style: {
                                            ...ele['label']['style'],
                                            fontSize: '7mm'
                                        }
                                    }
                                }
                            } else {    
                                return ele
                            }
                        } else {    
                            return ele
                        }
                    })
                },
            };
        });
    }, [chartOptions, results, calcResult]);

    useDeepCompareEffect(() => {
        let chart = new ApexCharts(document.querySelector("#capacityChart"), {
            series: series,
            ...options,
            chart: {
                ...options['chart'],
                type: 'area'
            }
        });

        chart.render();

        chart.dataURI().then(({imgURI}) => {
            setDataURI(imgURI)
        });

        return () => chart.destroy();
    },[series, options]);

    return (
        <div id="capacityChart"></div>
    );
};

export default React.memo(
    withDefaultPrintChart(CapacityChart),
    (prev, next) => stringify(prev) === stringify(next),
);