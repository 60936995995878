import React, { Fragment, useContext } from 'react';
import { MainAlertAndLoading } from '../../../../components';
import { cptpileContext } from '../../../../context/CptPile';
import ResultsWrapper from './ResultsWrapper';

const Results = ({ openSidebar }) => {
    const {
        results: {
            state: {
                results = [],
                currentPileLength,
                ...restState
            },
            isResultsActive,

            onChangeCurrentPileLength,
            onChangeCurrentCapacity,
            onCalculateSettlement,
            onSetCharLoad
        },
        globalAlerts={},
        globalLoading={},
    } = useContext(cptpileContext);

    return (
        <Fragment>
            <ResultsWrapper
                isResultsActive={isResultsActive}
                data={{
                    results,
                    openSidebar,
                    currentPileLengthByUser: currentPileLength,
                    onChangeCurrentPileLength,
                    onChangeCurrentCapacity,
                    onCalculateSettlement,
                    onSetCharLoad,
                    ...restState
                }}
            />
            <MainAlertAndLoading
                alerts={globalAlerts.state.alerts}
                updateAlerts={globalAlerts.updateState}
                loadings={globalLoading.state}
            />
        </Fragment>
    );
};

export default Results;
