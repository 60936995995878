import React, { Fragment, useContext, useState } from 'react';
import { dataContext } from '../../../../context/HorizontalCapacity';
import { useIsMountedState } from '../../../../hooks';
import ResultsWrapper from './ResultsWrapper';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { MainAlertAndLoading } from '../../../../components';

const Results = () => {
    const {
        results: {
            state: {
                parametersDetails = [],
                designSoilParameters = [],
                internalForces = {},
                internalForcesDetails = {},
                restParameters = {},
            },
            isResultsActive,
        },
        globalAlerts={},
        globalLoading={},
    } = useContext(dataContext);

    const [loaded, setLoaded] = useState(false);
    const mountedState = useIsMountedState();

    useDeepCompareEffect(() => {
        if (mountedState.current) {
            setLoaded(false);
            setTimeout(() => {
                setLoaded(true);
            }, 0);
        }
    }, [mountedState]);

    return (
        loaded && (
            <Fragment>
                <ResultsWrapper
                    isResultsActive={isResultsActive}
                    data={{
                        parametersDetails,
                        designSoilParameters,
                        internalForces,
                        internalForcesDetails,
                        restParameters,
                    }}
                />            
                <MainAlertAndLoading
                    alerts={globalAlerts.state.alerts}
                    updateAlerts={globalAlerts.updateState}
                    loadings={globalLoading.state}
                />
            </Fragment>

        )
    );
};

export default Results;
