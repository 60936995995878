import { useCallback, useEffect, useState } from 'react';
import {
    anp,
    atm_sas,
    dywidag,
    dywidag_threadbar,
    ec7ksi,
    titan,
    minova,
} from '../../../config/ProjectConfig';
import { isNumber, makeNumberPositive, roundNumber } from '../../../utils';

const defaultState = {
    gravel: {
        value: '',
        isValid: null,
    },
    sands: {
        value: '',
        isValid: null,
    },
    cohesiveSoils: {
        value: '',
        isValid: null,
    },
    rocks: {
        value: '',
        isValid: null,
    },

    gravelCpt: {
        value: '',
        isValid: null,
    },
    sandsCpt: {
        value: '',
        isValid: null,
    },
    cohesiveSoilsCpt: {
        value: '',
        isValid: null,
    },
    rocksCpt: {
        value: '',
        isValid: null,
    },

    profilesNumber: {
        value: 1,
        isValid: true,
    },
    capacityType: {
        value: 1,
        isValid: true,
    },
    ksi: {
        value: 1.4,
        isValid: true,
    },
    yt: {
        value: 1.1,
        isValid: true,
    },
    yst: {
        value: 1.15,
        isValid: true,
    },
    qccuInterpolation: {
        value: 0.5,
        isValid: true,
    },
    steelRodYt: {
        value: 1.15,
        isValid: true,
    },
    tci: {
        value: 5,
        isValid: true,
    },
    modelFactor: {
        value: 1.25,
        isValid: true,
    },
    fy: {
        value: 460,
        isValid: true,
    },
    fc: {
        value: 35,
        isValid: true,
    },
    yc: {
        value: 1.5,
        isValid: true,
    },
    ycu: {
        value: 1.4,
        isValid: true,
    },
    steelYoungModulus: {
        value: 200,
        isValid: true,
    },
    softSoilCount: {
        value: true,
        isValid: true,
    },
    soilsInBuckling: {
        value: true,
        isValid: true,
    },
    alphaParam: {
        value: 0.49,
        isValid: true,
    },
};

const SaftyFactorsHelpers = ({ micropileType, axisLoad, updateRods, updateResultsState }) => {
    const [saftyFactorsState, setSaftyFactorsState] = useState({
        ...defaultState,
    });

    useEffect(() => {
        const yt = saftyFactorsState.steelRodYt.value;
        const rodsCollection =
            micropileType.value === 1
                ? [...titan, ...anp, ...dywidag, ...minova]
                : [...atm_sas, ...dywidag_threadbar];

        updateRods(
            rodsCollection
                .filter((ele) => roundNumber(ele.rmk / yt, 2) >= axisLoad.value)
                .map((ele) => ({
                    ...ele,
                    currentCapacityValue: roundNumber(ele.rmk / yt, 2),
                })),
        );
    }, [
        axisLoad.value,
        saftyFactorsState.steelRodYt.value,
        micropileType.value,
        updateRods
    ]);

    useEffect(() => {
        const ksiValue = ec7ksi(
            saftyFactorsState.capacityType.value,
            saftyFactorsState.profilesNumber.value,
        );
        setSaftyFactorsState((state) => ({
            ...state,
            ksi: {
                value: ksiValue,
                isValid: isNumber(ksiValue) ? true : false,
            },
        }));
    }, [
        saftyFactorsState.capacityType.value,
        saftyFactorsState.profilesNumber.value,
    ]);

    useEffect(() => {
        const setIsValid = (val) => (val === 1 || val === 2 ? true : false);
        setSaftyFactorsState((state) => ({
            ...state,
            gravel: {
                value:
                    micropileType.value === 1
                        ? 2
                        : micropileType.value === 2
                        ? 1.3
                        : '',
                isValid: setIsValid(micropileType.value),
            },
            sands: {
                value:
                    micropileType.value === 1
                        ? 1.5
                        : micropileType.value === 2
                        ? 1.2
                        : '',
                isValid: setIsValid(micropileType.value),
            },
            cohesiveSoils: {
                value:
                    micropileType.value === 1
                        ? 1.3
                        : micropileType.value === 2
                        ? 1.15
                        : '',
                isValid: setIsValid(micropileType.value),
            },
            rocks: {
                value:
                    micropileType.value === 1 || micropileType.value === 2
                        ? 1
                        : '',
                isValid: setIsValid(micropileType.value),
            },
            gravelCpt: {
                value:
                    micropileType.value === 1
                        ? 75
                        : micropileType.value === 2
                        ? 20
                        : '',
                isValid: setIsValid(micropileType.value),
            },
            sandsCpt: {
                value:
                    micropileType.value === 1
                        ? 50
                        : micropileType.value === 2
                        ? 20
                        : '',
                isValid: setIsValid(micropileType.value),
            },
            cohesiveSoilsCpt: {
                value:
                    micropileType.value === 1
                        ? 25
                        : micropileType.value === 2
                        ? 10
                        : '',
                isValid: setIsValid(micropileType.value),
            },
            rocksCpt: {
                value:
                    micropileType.value === 1
                        ? 10
                        : micropileType.value === 2
                        ? 0
                        : '',
                isValid: setIsValid(micropileType.value),
            },
        }));
    }, [
        micropileType.value,
        saftyFactorsState.gravel.value,
        saftyFactorsState.gravelCpt.value,
        saftyFactorsState.sands.value,
        saftyFactorsState.sandsCpt.value,
        saftyFactorsState.cohesiveSoils.value,
        saftyFactorsState.cohesiveSoilsCpt.value,
        saftyFactorsState.rocks.value,
        saftyFactorsState.rocksCpt.value,
    ]);

    const onBlurSaftyFactorsState = useCallback((e) => {
        const { name } = e.currentTarget;
        const valid = e.currentTarget.checkValidity();

        setSaftyFactorsState((state) => ({
            ...state,
            [name]: {
                ...state[name],
                isValid: valid,
            },
        }));
    }, []);

    const onChangeSaftyFactorsState = useCallback((e) => {
        const input = e.currentTarget.closest('input') || e.currentTarget.closest('select');
        const { value, name, type, checked } = input;

        updateResultsState(state => {
            return {
                ...state,
                isResultsActual: false, 
            }
        });

        if (type === 'radio') {
            setSaftyFactorsState((state) => {
                return {
                    ...state,
                    [name]: {
                        value: Number(value),
                        isValid: true,
                    },
                };
            });
        } else if (type === 'checkbox') {
            setSaftyFactorsState((state) => ({
                ...state,
                [name]: {
                    isValid: true,
                    value: !!checked,
                },
            }));
        } else {
            setSaftyFactorsState((state) => ({
                ...state,
                [name]: {
                    value:
                        type === 'number' && value.length > 0
                            ? makeNumberPositive(Number(value))
                            : value,
                    isValid: true,
                },
            }));
        }
    }, []);

    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    }, []);

    const loadProject = (saftyFactorsObj = {}) => {
        setSaftyFactorsState((state) => {
            return {
                ...defaultState,
                ...saftyFactorsObj,
            };
        });
    };

    const saftyFactors = {
        onChange: onChangeSaftyFactorsState,
        onBlur: onBlurSaftyFactorsState,
        onKeyDown: onKeyDown,
        updateState: setSaftyFactorsState,
        loadProject: loadProject,
        initState: () =>
            setSaftyFactorsState({
                ...defaultState,
            }),
        state: saftyFactorsState,
    };

    return {
        saftyFactors,
    };
};

export default SaftyFactorsHelpers;