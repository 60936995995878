import { Fragment } from "react";
import styles from './index.module.css';

const CalculationInput= ({ parameters={}, memberType }) => {
    return (  
        <div className={styles.wrapper}>
            <h2>Parametry geometryczne przekroju i zbrojenia</h2>
            <p>Typ elementu: <span className={styles.value}>{memberType}</span></p>
            <p>Kształt przekroju: <span className={styles.value}>{parameters.pileShape.value}</span></p>
            <p>Klasa ekspozycji: <span className={styles.value}>{parameters.exposureClass.value ? parameters.exposureClass.value : 'Brak'}</span></p>
            {
                parameters.circular 
                ?
                <Fragment>
                    <p>Średnica przekroju: <span className={styles.value}>{parameters.diameterPile.value} mm</span></p>
                    <p>Otulina zbrojenia: <span className={styles.value}>{parameters.cover.value} mm</span></p>
                    <p>Średnica zbrojenia: <span className={styles.value}>{parameters.rebar.value} mm</span></p>
                </Fragment>
                :
                <Fragment>
                    <p>Wysokość przekroju: <span className={styles.value}>{parameters.diameterPile.value} mm</span></p>
                    <p>Szerokość przekroju: <span className={styles.value}>{parameters.width.value} mm</span></p>
                    <p>Otulina zbrojenia górnego: <span className={styles.value}>{parameters.coverUp.value} mm</span></p>
                    <p>Otulina zbrojenia dolnego: <span className={styles.value}>{parameters.coverBottom.value} mm</span></p>
                    <p>Średnica zbrojenia górnego: <span className={styles.value}>{parameters.rebarAs1.value} mm</span></p>
                    <p>Średnica zbrojenia dolnego: <span className={styles.value}>{parameters.rebarAs2.value} mm</span></p>
                </Fragment>
            }
            {
                memberType !== 'Płyta' &&
                <Fragment>
                    <p>Rodzaj zbrojenia na ścinanie: <span className={styles.value}>{`${parameters.circular ? parameters.stirrupType.value : 'Strzemiona'}`}</span></p>
                    <p>Średnica zbrojenia na ścinanie: <span className={styles.value}>{parameters.spiral.value} mm</span></p>
                    <p>n-cięte zbrojenie na ścinanie: <span className={styles.value}>{parameters.nCut.value}</span></p>                    
                </Fragment>
            }
        </div>
    )
}

export default CalculationInput