import { useContext, useEffect } from 'react';
import config from '../../../config/config';
import { micropilesContext } from '../../../context/Micropiles';
import PrintMicropileResults from './PrintResults';
import DefaultNavigation from '../DefaultNavigation';

const MicropilesNavigation = ({ report }) => {
    const MicropileContext = useContext(micropilesContext);
    const { routes } = config;

    useEffect(() => {
        document.title = `Mikropal - ${MicropileContext.fileNameToSave.fileName.value}`;

        return () => {
            document.title = '';
        };
    }, [MicropileContext.fileNameToSave.fileName.value]); 

    return (
        <DefaultNavigation
            title="Mikropal kotwiący"
            subtitle="Nośność na wyciąganie mikropali iniekcyjnych"
            modelRoute={routes.dashboard.micropilesModel}
            resultsRoute={routes.dashboard.micropilesResults}
            context={MicropileContext}>
            {MicropileContext.results.state.isResultsActual && MicropileContext.results.isResultsActive && (
                <PrintMicropileResults
                    report={report}
                    isResultsActual={
                        MicropileContext.results.state.isResultsActual
                    }
                    printResults={MicropileContext.printResults}
                />
            )}
        </DefaultNavigation>
    );
};

export default MicropilesNavigation;
