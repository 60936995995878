import { useState } from 'react';
import { AuthFetch } from '../../../routes';
import { roundNumber, isString, isNumber } from '../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useIsMountedState } from '../../../hooks';
import stringify from 'fast-json-stable-stringify';

const mimeTypes = [
    'image/apng',
    'image/avif',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/webp',
];
let initFileInfo = {
    name: '',
    type: '',
    size: '',
    base64: '',
};
const ReportLogoService = ({
    fetch,
    setAlert,
    updateReportDetails,
    setLoading,
}) => {
    const [logoImg, setLogoImg] = useState(initFileInfo);
    const [isLogo, setIsLogo] = useState(false);
    const isMountedState = useIsMountedState();

    useDeepCompareEffect(() => {
        if(isMountedState.current){
            AuthFetch({
                url: fetch.getLogo.url,
                method: fetch.getLogo.method,
            })
                .then((response) => {
                    if(isMountedState.current){
                        if (response.success && response.status === 200) {
                            const { size, ...rest } = response.responseData;
                            const isValidForm =
                                Object.values(rest).every(
                                    (ele) => isString(ele) && ele.length > 0,
                                ) && isNumber(size);

                            setLogoImg({
                                ...response.responseData,
                            });
                            setIsLogo(isValidForm ? true : false);
                        } else {
                            setAlert({
                                error: true,
                                message:
                                    'Błąd podczas wczytywania loga do raportów pdf',
                            });
                        }
                    }
                })
                .catch((e) => {
                    if(isMountedState.current){
                        setAlert({
                            error: true,
                            message: 'Błąd podczas wczytywania loga do raportów pdf',
                        });
                    }

                });            
        }
    }, [fetch.getLogo.url, fetch.getLogo.method, setAlert, isMountedState]);

    const onChange = (e) => { 
        const file = e.target.files ? e.target.files[0] : null;

        if (file) {
            if (file.size > 5000000) {
                setAlert({
                    error: true,
                    message: `Dopuszczalny rozmiar pliku to 5.0MB, aktualny plik zajmuje ${roundNumber(
                        file.size / 1000000,
                        1,
                    )}MB`,
                });
            } else if (!mimeTypes.includes(file.type)) {
                setAlert({
                    error: true,
                    message: `Nieprawidłowy format pliku. Wybierz plik o formacie graficznym`,
                });
            } else {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    let fileInfo = {
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        base64: reader.result,
                    };
                    setLogoImg(fileInfo);
                };
            }
        } else {
            setLogoImg(initFileInfo);
            setAlert({ error: true, message: `Błąd podczas dodawania pliku` });
        }
    };
    const onDelete = () => {
        setLoading({ name: 'deleteLogo', loading: true });
        AuthFetch({
            url: fetch.deleteLogo.url,
            method: fetch.deleteLogo.method,
        })
            .then((response) => {
                setLoading({ name: 'deleteLogo', loading: false });
                if (response.success && response.status === 200) {
                    updateReportDetails((state) => ({
                        ...state,
                        logo: null,
                    }));
                    localStorage.setItem(
                        'updateReport',
                        stringify(new Date()),
                    );
                    setIsLogo(false);

                    setLogoImg({
                        ...response.responseData.logo,
                    });
                    setAlert({
                        error: false,
                        message: 'Logo zostało usunięte',
                        hideTime: 2000,
                    });
                } else {
                    if (response.status === 401) {
                        setAlert({
                            error: true,
                            message: 'Nie znaleziono użytkownika',
                        });
                    } else {
                        setAlert({
                            error: true,
                            message: 'Błąd podczas usuwania loga',
                        });
                    }
                }
            })
            .catch((error) => {
                setAlert({
                    error: true,
                    message: 'Błąd podczas zapisywania loga',
                });
                setLoading({ name: 'deleteLogo', loading: false });
            });
    };
    const onSubmit = () => {
        const { size, ...rest } = logoImg;
        const isValidForm =
            Object.values(rest).every(
                (ele) => isString(ele) && ele.length > 0,
            ) && isNumber(size);

        if (isValidForm) {
            setLoading({ name: 'updateLogo', loading: true });
            AuthFetch({
                url: fetch.updateLogo.url,
                method: fetch.updateLogo.method,
                body: {
                    ...logoImg,
                },
            })
                .then((response) => {
                    setLoading({ name: 'updateLogo', loading: false });
                    if (response.success && response.status === 200) {
                        updateReportDetails((state) => ({
                            ...state,
                            logo: response.responseData.base64,
                        }));
                        localStorage.setItem(
                            'updateReport',
                            stringify(new Date()),
                        );
                        setAlert({
                            error: false,
                            message: 'Logo zostało zaktualizowane',
                            hideTime: 2000,
                        });
                        setIsLogo(true);
                    } else {
                        if (response.status === 401) {
                            setAlert({
                                error: true,
                                message: 'Nie znaleziono użytkownika',
                            });
                        } else if (response.status === 400) {
                            setAlert({
                                error: true,
                                message: 'Niepoprawny rozmiar lub format pliku',
                            });
                        } else {
                            setAlert({
                                error: true,
                                message: 'Błąd podczas zapisywania loga',
                            });
                        }
                    }
                })
                .catch((error) => {
                    setAlert({
                        error: true,
                        message: 'Błąd podczas zapisywania loga',
                    });
                    setLoading({ name: 'updateLogo', loading: false });
                });
        } else {
            setAlert({ error: true, message: 'Nie wybrano poprawnego pliku' });
        }
    };

    return {
        reportLogo: {
            onChange,
            onSubmit,
            onDelete,
            state: {
                logoImg,
                isLogo,
            },
        },
    };
};

export default ReportLogoService;
