import { Fragment } from "react"
import { isNumber, roundNumber } from "../../../../../utils";
import styles from './index.module.css';


const ResultsSection = ({ results={} }) => {

    return ( 
        <div className={styles.wrapper}>
            <Fragment>
                
                {
                    (results.type === 1 || results.type === 4) &&
                    <Fragment>
                        <h2>Wyniki nośności kleszcza</h2>
                        <p>Kleszcz stalowy: <span className={styles.value}>2x{results.waling.designCeo[0]}</span></p>
                        <p>Wskaźnik wytrzymalości [W<sub>x</sub>]: <span className={styles.value}>{results.waling.designCeo[1].wx} cm<sup>3</sup></span></p>
                        <p>Wymagany wskaźnik wytrzymałości [W<sub>x,req</sub>]: <span className={styles.value}>{results.waling.ceoWx} cm<sup>3</sup></span></p>
                        <p>Rozstaw ceowników: <span className={styles.value}>{10*results.waling.s.toFixed(1)} mm</span></p>
                        <p>Rozstaw kotew: <span className={styles.value}>{results.waling.space.toFixed(2)} m</span></p>
                        <p>Granica plastyczności stali: <span className={styles.value}>{results.waling.walingFy} MPa</span></p>
                    </Fragment>
                }

                <h2>Wyniki nośności płyty oporowej</h2>
                {
                    (results.type !== 1 && results.type !== 4) &&
                    <p>Rozstaw kotew: <span className={styles.value}>{results.waling.space.toFixed(2)} m</span></p>
                }
                <p>Szerokość płyty [b<sub>w</sub>]: <span className={styles.value}>{10*results.plate.ba.toFixed(1)} mm</span></p>
                <p>Wysokość płyty [h<sub>p</sub>]: <span className={styles.value}>{10*results.plate.ha.toFixed(1)} mm</span></p>
                <p>Grubość płyty [t<sub>p</sub>]: <span className={styles.value}>{10*results.plate.ta.toFixed(1)} mm</span></p>
                {
                    isNumber(results.plate.c) && isNumber(results.plate.e) && 
                    <Fragment>
                        <p>Wysokość podkładki [c]: <span className={styles.value}>{10*results.plate.c.toFixed(1)} mm</span></p>
                        <p>Szerokość podkładki [e]: <span className={styles.value}>{10*results.plate.e.toFixed(1)} mm</span></p>
                    </Fragment>
                }
                <p>Klasa stali płyty: <span className={styles.value}>{results.plate.steelGrade}</span></p>
                <p>Granica plastyczności stali płyty [f<sub>y</sub>]: <span className={styles.value}>{10*results.plate.fy} MPa</span></p>
                <p>Masa płyty oporowej: <span className={styles.value}>{roundNumber(0.001*results.plate.m,2).toFixed(2)} kg</span></p>

                <h2>Warunki równowagi sił</h2>
                {
                    results.type === 1
                    ?
                    <Fragment>
                        <p>Kleszcz stalowy</p>
                        <p>M<sub>Ed,waling</sub>: <span className={styles.value}>{results.forces.Med} kNm</span></p>
                        <p>W<sub>x,req</sub>: <span className={styles.value}>{results.waling.ceoWx} cm<sup>3</sup></span></p>
                        <p>W<sub>x,req</sub> &le; W<sub>x,ceo</sub>
                            <span className={styles.value}>
                                {results.waling.ceoWx} cm<sup>3</sup> &le; {results.waling.designCeo[1].wx} cm<sup>3</sup> (
                                    {roundNumber(100*results.waling.ceoWx/results.waling.designCeo[1].wx,2)}%
                                )
                            </span>
                        </p>
                        <p>
                            <b>
                                <span>
                                    {
                                        results.waling.ceoWx/results.waling.designCeo[1].wx <=  1 ? 'Kleszcz zaprojektowany poprawnie' : 'Kleszcz zaprojektowany błędnie'
                                    }
                                </span>                            
                            </b>
                        </p>

                        <p>Nośność płyty oporowej</p>
                        <p>Masa płyty: <span className={styles.value1}>{roundNumber(results.plate.m*0.001,2).toFixed(2)} kg</span></p>
                        <p>F<sub>Ed</sub> &le; F<sub>M,V,Rd</sub> = <sup>2</sup>&frasl;<sub>3</sub>&middot;
                            {'['}
                                -(b<sub>a</sub> - d') + 
                                <span style={{ whiteSpace: "nowrap" }}>
                                    <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;(b<sub>a</sub> - d')<sup>2</sup> + 3t<sub>a</sub><sup>2</sup>&nbsp;</span>
                                </span>
                            {']'} (h<sub>a</sub> - d)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                            <span className={styles.value1}>{results.forces.Fed} kN &le; {results.forces.Fmvrd} kN ({roundNumber(100*results.forces.Fed/results.forces.Fmvrd,2)}%)</span>
                        </p>
                        <p>
                            F<sub>Ed</sub> &le; F<sub>Rd,l,1</sub> = <sup>&pi;</sup>&frasl;<sub>2
                            <span style={{ whiteSpace: "nowrap" }}>
                                <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;2&nbsp;</span>
                            </span>
                            </sub>(d<sub>SG</sub><sup>2</sup> - d<sup>2</sup>)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                            
                            <span className={styles.value1}>{results.forces.Fed} kN &le; {results.forces.FrdL1} kN ({roundNumber(100*results.forces.Fed/results.forces.FrdL1,2)}%)</span>
                        </p>

                        <p>
                            F<sub>Rd,l,1</sub> &le; F<sub>Rd,l,2</sub> = <sup>1</sup>&frasl;<sub>
                            <span style={{ whiteSpace: "nowrap" }}>
                                <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;3&nbsp;</span>
                            </span>
                            </sub>&pi;d<sub>SG</sub>t<sub>a</sub><sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                            
                            <span className={styles.value1}>
                                {results.forces.FrdL1} kN &le; {results.forces.FrdL2} kN ({roundNumber(100*results.forces.FrdL1/results.forces.FrdL2,2)}%)
                            </span>
                            
                        </p>

                        <p>
                            <b>
                                <span>
                                    {
                                        results.forces.FrdL1/results.forces.FrdL2 <= 1 && results.forces.Fed/results.forces.FrdL1 <= 1 && results.forces.Fed/results.forces.Fmvrd <= 1 
                                        ? 'Płyta oporowa zaprojektowana poprawnie' 
                                        : 'Płyta oporowa zaprojektowana błędnie'
                                    }
                                </span>
                            </b>
                        </p>

                        <p>Nośność ścianki szczelnej na podstawie PN-EN 1993-5 pkt 7.4.3</p>
                        <p>F<sub>Ed,web</sub> &le; 0.5R<sub>c,Rd</sub> 
                            <span className={styles.value2}>
                                {results.forces.Fed*0.5} kN &le; {0.5*results.forces.Rcrd} kN {0.5*results.forces.Fed > 0.5*results.forces.Rcrd ? 'Potrzebna dalsza weryfikacja' : 'Dalsza weryfikacja nie jest konieczna'}
                            </span>
                        </p>
                        {
                            0.5*results.forces.Fed <= 0.5*results.forces.Rcrd &&
                            <p>
                                <b>
                                    <span>Ścianka szczelna zaprojektowana poprawnie</span>
                                </b>
                            </p>
                        }
                    
                        {
                            0.5*results.forces.Fed > 0.5*results.forces.Rcrd &&
                            <Fragment>
                                <p>
                                    <sup>F<sub>Ed</sub></sup>&frasl;<sub>R<sub>c,Rd</sub></sub> + 0.5<sup>M<sub>Ed</sub></sup>&frasl;<sub>M<sub>c,Rd</sub></sub> &le; 1 
                                    <span className={styles.value2}>
                                        {results.forces.conditionVal} &le; 1 (<span>{roundNumber(100*results.forces.conditionVal,2)}%</span>)
                                    </span>
                                </p>
                                <p>
                                    <b>
                                        <span>
                                            {
                                                results.forces.conditionVal <= 1
                                                ? 'Ścianka szczelna zaprojektowana poprawnie' 
                                                : 'Ścianka szczelna zaprojektowana błędnie'
                                            }
                                        </span>
                                    </b>
                                </p>
                            </Fragment>
                        }
                    </Fragment>
                    :
                    results.type === 2
                    ?
                    <Fragment>
                        <p>Nośność płyty oporowej</p>
                        <p>Masa płyty: <span className={styles.value1}>{roundNumber(results.plate.m*0.001,2).toFixed(2)} kg</span></p>
                        <p>
                            F<sub>Ed</sub> &le; F<sub>M,V,Rd</sub> = <sup>2</sup>&frasl;<sub>3</sub>&middot;
                            {'['}
                                -(b<sub>a</sub> - d') + 
                                <span style={{ whiteSpace: "nowrap" }}>
                                <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;(b<sub>a</sub> - d')<sup>2</sup> + 3t<sub>a</sub><sup>2</sup>&nbsp;</span>
                                </span>
                            {']'} (h<sub>a</sub> - d)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                            <span className={styles.value1}>{results.forces.Fed} kN &le; {results.forces.Fmvrd} kN (
                                <span>{roundNumber(100*results.forces.Fed/results.forces.Fmvrd,2)}%</span>
                            )</span>
                        </p>
                        <p>
                            F<sub>Ed</sub> &le; F<sub>Rd,l,1</sub> = <sup>&pi;</sup>&frasl;<sub>2
                            <span style={{ whiteSpace: "nowrap" }}>
                                <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;2&nbsp;</span>
                            </span>
                            </sub>(d<sub>SG</sub><sup>2</sup> - d<sup>2</sup>)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                            
                            <span className={styles.value1}>{results.forces.Fed} kN &le; {results.forces.FrdL1} kN (<span>{roundNumber(100*results.forces.Fed/results.forces.FrdL1,2)}%</span>)
                            </span>
                        </p>
                        <p>
                            F<sub>Rd,l,1</sub> &le; F<sub>Rd,l,2</sub> = <sup>1</sup>&frasl;<sub>
                            <span style={{ whiteSpace: "nowrap" }}>
                                <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;3&nbsp;</span>
                            </span>
                            </sub>&pi;d<sub>SG</sub>t<sub>a</sub><sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                            
                            <span className={styles.value1}>{results.forces.FrdL1} kN &le; {results.forces.FrdL2} kN (<span>{roundNumber(100*results.forces.FrdL1/results.forces.FrdL2,2)}%</span>)
                            </span>
                        </p>
                        <p>
                            <b>
                                <span>
                                    {
                                        results.forces.FrdL1/results.forces.FrdL2 <= 1 && results.forces.Fed/results.forces.FrdL1 <= 1 && results.forces.Fed/results.forces.Fmvrd <= 1 
                                        ? 'Płyta oporowa zaprojektowana poprawnie' 
                                        : 'Płyta oporowa zaprojektowana błędnie'
                                    }
                                </span>
                            </b>
                        </p>
                        <p>Nośność ścianki szczelnej na podstawie PN-EN 1993-5 pkt 7.4.3</p>
                        <p>
                            F<sub>Ed,web</sub> &le; 0.5R<sub>c,Rd</sub> <span className={styles.value2}>{results.forces.Fed*0.5} kN &le; {0.5*results.forces.Rcrd} kN {0.5*results.forces.Fed > 0.5*results.forces.Rcrd ? 'Potrzebna dalsza weryfikacja' : 'Dalsza weryfikacja nie jest konieczna'}</span>
                        </p>
                        {
                            0.5*results.forces.Fed <=  0.5*results.forces.Rcrd &&
                            <p>
                                <b>
                                    <span>Ścianka szczelna zaprojektowana poprawnie</span>
                                </b>
                            </p>
                        }
                    
                        {
                            0.5*results.forces.Fed > 0.5*results.forces.Rcrd &&
                            <Fragment>
                                <p>
                                    <sup>F<sub>Ed</sub></sup>&frasl;<sub>R<sub>c,Rd</sub></sub> + 0.5<sup>M<sub>Ed</sub></sup>&frasl;<sub>M<sub>c,Rd</sub></sub> &le; 1 <span className={styles.value2}>{results.forces.conditionVal} &le; 1 (<span>{roundNumber(100*results.forces.conditionVal,2)}%</span>)</span>
                                </p>
                                <p>
                                    <b>
                                        <span>
                                            {
                                                results.forces.conditionVal <= 1
                                                ? 'Ścianka szczelna zaprojektowana poprawnie' 
                                                : 'Ścianka szczelna zaprojektowana błędnie'
                                            }
                                        </span>
                                    </b>
                                </p>
                            </Fragment>
                        }
                    </Fragment>
                    :
                    results.type === 3
                    ?
                    <Fragment>
                        {
                            results.sheetPileType === 'u'
                            ?
                            <Fragment>
                                <p>Nośność płyty oporowej</p>
                                <p>Masa płyty: <span className={styles.value1}>{roundNumber(results.plate.m*0.001,2).toFixed(2)} kg</span></p>
                                <p>
                                    F<sub>Ed</sub> &le; F<sub>M,V,Rd</sub> = <sup>2</sup>&frasl;<sub>3</sub>&middot;
                                    {'['}
                                        -(b<sub>a</sub> - d') + 
                                        <span style={{ whiteSpace: "nowrap" }}>
                                            <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;(b<sub>a</sub> - d')<sup>2</sup> + 3t<sub>a</sub><sup>2</sup>&nbsp;</span>
                                        </span>
                                    {']'} (h<sub>a</sub> - d)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                    <span className={styles.value1}>{results.forces.Fed} kN &le; {results.forces.Fmvrd} kN (
                                        <span>{roundNumber(100*results.forces.Fed/results.forces.Fmvrd,2)}%</span>
                                    )
                                    </span>
                                </p>
                                <p>
                                    F<sub>Ed</sub> &le; F<sub>Rd,l,1</sub> = <sup>&pi;</sup>&frasl;<sub>2
                                    <span style={{ whiteSpace: "nowrap" }}>
                                        <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;2&nbsp;</span>
                                    </span></sub>(d<sub>SG</sub><sup>2</sup> - d<sup>2</sup>)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                    
                                    <span className={styles.value1}>{results.forces.Fed} kN &le; {results.forces.FrdL1} kN (
                                        <span>{roundNumber(100*results.forces.Fed/results.forces.FrdL1,2)}%</span>
                                    )
                                    </span>
                                </p>
                                <p>
                                    F<sub>Rd,l,1</sub> &le; F<sub>Rd,l,2</sub> = <sup>1</sup>&frasl;<sub>
                                    <span style={{ whiteSpace: "nowrap" }}>
                                        <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;3&nbsp;</span>
                                    </span></sub>&pi;d<sub>SG</sub>t<sub>a</sub><sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                
                                    <span className={styles.value1}>{results.forces.FrdL1} kN &le; {results.forces.FrdL2} kN (
                                        <span>{roundNumber(100*results.forces.FrdL1/results.forces.FrdL2,2)}%</span>
                                    )
                                    </span>
                                </p>
                                <p>
                                    <b>
                                        <span>
                                            {
                                                results.forces.FrdL1/results.forces.FrdL2 <= 1 && results.forces.Fed/results.forces.FrdL1 <= 1 && results.forces.Fed/results.forces.Fmvrd <= 1 
                                                ? 'Płyta oporowa zaprojektowana poprawnie' 
                                                : 'Płyta oporowa zaprojektowana błędnie'
                                            }
                                        </span>
                                    </b>
                                </p>

                                <p>Nośność ścianki szczelnej na podstawie PN-EN 1993-5 pkt 7.4.3</p>

                                <p>
                                    F<sub>Ed</sub> &le; R<sub>vf,Rd</sub> = 2(b<sub>b</sub> + h<sub>a</sub>)t<sub>f</sub> <sup>f<sub>y</sub></sup>&frasl;<sub><span style={{ whiteSpace: "nowrap" }}>
                                        <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;3&nbsp;</span>
                                    </span>y<sub>M0</sub></sub>
                                
                                    <span className={styles.value1}>
                                        {results.forces.Fed} kN &le; {results.forces.Rvfrd} kN (
                                        <span>{roundNumber(100*results.forces.Fed/results.forces.Rvfrd,2)}%</span>
                                    )
                                    </span>
                                </p>

                                <p>
                                    F<sub>Ed</sub> &le; R<sub>tw,Rd</sub> = 2h<sub>a</sub>t<sub>w</sub> <sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                
                                    <span className={styles.value1}>
                                        {results.forces.Fed} kN &le; {results.forces.Rtwrd} kN (
                                        <span>{roundNumber(100*results.forces.Fed/results.forces.Rtwrd,2)}%</span>
                                    )
                                    </span>
                                </p>
                                <p>
                                    <b>
                                        <span>
                                            {
                                                results.forces.Fed/results.forces.Rtwrd <= 1 && results.forces.Fed/results.forces.Rvfrd <= 1
                                                ? 'Ścianka szczelna zaprojektowana poprawnie' 
                                                : 'Ścianka szczelna zaprojektowana błędnie'
                                            }
                                        </span>
                                    </b>
                                </p>
                            </Fragment>
                            :
                            <Fragment>
                                <p>Nośność płyty oporowej</p>
                                <p>Masa płyty: <span className={styles.value1}>{roundNumber(results.plate.m*0.001,2).toFixed(2)} kg</span></p>
                                <p>
                                    F<sub>Ed</sub> &le; F<sub>M,V,Rd</sub> = <sup>2</sup>&frasl;<sub>3</sub>&middot;
                                        {'['}
                                            -(g - d') + 
                                            <span style={{ whiteSpace: "nowrap" }}>
                                                <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;(g - d')<sup>2</sup> + 3t<sub>a</sub><sup>2</sup>&nbsp;</span>
                                            </span>
                                        {']'} (h<sub>a</sub> - d)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                        <span className={styles.value1}>
                                            {results.forces.Fed} kN &le; {results.forces.Fmvrd} kN (<span>{roundNumber(100*results.forces.Fed/results.forces.Fmvrd,2)}%</span>)
                                        </span>
                                </p>
                                <p>
                                    F<sub>Ed</sub> &le; F<sub>Rd,l,1</sub> = <sup>&pi;</sup>&frasl;<sub>2
                                    <span style={{ whiteSpace: "nowrap" }}>
                                        <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;2&nbsp;</span>
                                    </span></sub>(d<sub>SG</sub><sup>2</sup> - d<sup>2</sup>)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                
                                    <span className={styles.value1}>
                                        {results.forces.Fed} kN &le; {results.forces.FrdL1} kN (<span>{roundNumber(100*results.forces.Fed/results.forces.FrdL1,2)}%</span>)
                                    </span>
                                </p>
                                <p>
                                    F<sub>Rd,l,1</sub> &le; F<sub>Rd,l,2</sub> = <sup>1</sup>&frasl;<sub>
                                    <span style={{ whiteSpace: "nowrap" }}>
                                        <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;3&nbsp;</span>
                                    </span></sub>&pi;d<sub>SG</sub>t<sub>a</sub><sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                
                                    <span className={styles.value1}>
                                        {results.forces.FrdL1} kN &le; {results.forces.FrdL2} kN (<span>{roundNumber(100*results.forces.FrdL1/results.forces.FrdL2,2)}%</span>)
                                    </span>
                                </p>
                                <p>
                                    <b>
                                        <span>
                                            {
                                                results.forces.FrdL1/results.forces.FrdL2 <= 1 && results.forces.Fed/results.forces.FrdL1 <= 1 && results.forces.Fed/results.forces.Fmvrd <= 1 
                                                ? 'Płyta oporowa zaprojektowana poprawnie' 
                                                : 'Płyta oporowa zaprojektowana błędnie'
                                            }
                                        </span>
                                    </b>
                                </p>
                                <p>Nośność ścianki szczelnej na podstawie PN-EN 1993-5 pkt 7.4.3</p>
                                <p>
                                    F<sub>Ed</sub> &le; R<sub>vf,Rd</sub> = 2(b<sub>b</sub> + h<sub>a</sub>)t<sub>f</sub> <sup>f<sub>y</sub></sup>&frasl;<sub><span style={{ whiteSpace: "nowrap" }}>
                                        <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;3&nbsp;</span>
                                    </span>y<sub>M0</sub></sub>
                                
                                    <span className={styles.value1}>
                                        {results.forces.Fed} kN &le; {results.forces.Rvfrd} kN (<span>{roundNumber(100*results.forces.Fed/results.forces.Rvfrd,2)}%</span>)
                                    </span>
                                </p>
                                <p>
                                    F<sub>Ed</sub> &le; R<sub>tw,Rd</sub> = 2h<sub>a</sub>t<sub>w</sub> <sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                
                                    <span className={styles.value1}>
                                        {results.forces.Fed} kN &le; {results.forces.Rtwrd} kN (<span>{roundNumber(100*results.forces.Fed/results.forces.Rtwrd,2)}%</span>)
                                    </span>
                                </p>
                                <p>
                                    <b>
                                        <span >
                                            {
                                                results.forces.Fed/results.forces.Rtwrd <= 1 && results.forces.Fed/results.forces.Rvfrd <= 1
                                                ? 'Ścianka szczelna zaprojektowana poprawnie' 
                                                : 'Ścianka szczelna zaprojektowana błędnie'
                                            }
                                        </span>
                                    </b>
                                </p>
                            </Fragment>
                        }
                    </Fragment>
                    :
                    results.type === 4
                    ?
                    <Fragment>
                        {
                            results.sheetPileType === 'u'
                            ?
                            <Fragment>
                                <p>Kleszcz stalowy</p>
                                <p>M<sub>Ed,waling</sub>: <span className={styles.value1}>{roundNumber(results.forces.Med,2)} kN</span></p>
                                <p>W<sub>x,req</sub>: <span className={styles.value1}>{roundNumber(results.waling.ceoWx,2)} cm<sup>3</sup></span></p>
                                <p>
                                    W<sub>x,req</sub> &le; W<sub>x,ceo</sub>

                                    <span className={styles.value1}>
                                        {results.waling.ceoWx} cm<sup>3</sup> &le; {results.waling.designCeo[1].wx} cm<sup>3</sup> (
                                        <span>{roundNumber(100*results.waling.ceoWx/results.waling.designCeo[1].wx,2)}%</span>
                                        )
                                    </span>
                                </p>
                                <p>
                                    <b>
                                        <span>
                                            {
                                                results.waling.ceoWx/results.waling.designCeo[1].wx <= 1 
                                                ? 
                                                'Kleszcz zaprojektowany poprawnie' 
                                                : 
                                                'Kleszcz zaprojektowany błędnie'
                                            }
                                        </span>
                                    </b>
                                </p>
                                <p>Nośność płyty oporowej</p>
                                <p>Masa płyty: <span className={styles.value1}>{roundNumber(results.plate.m*0.001,2).toFixed(2)} kg</span></p>
                                <p>
                                    F<sub>P,Ed</sub> &le; F<sub>P,M,V,Rd</sub> = <sup>2</sup>&frasl;<sub>3</sub>&middot;
                                    {'['}
                                        -(b<sub>a</sub> - d') + 
                                        <span style={{ whiteSpace: "nowrap" }}>
                                            <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;(b<sub>a</sub> - d')<sup>2</sup> + 3t<sub>a</sub><sup>2</sup>&nbsp;</span>
                                        </span>
                                    {']'} (h<sub>a</sub> - d)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                    <span className={styles.value1}>
                                        {results.forces.Fp} kN &le; {results.forces.Fpmvrd} kN (
                                            <span>{roundNumber(100*results.forces.Fp/results.forces.Fpmvrd,2)}%</span>
                                        )
                                    </span>
                                </p>
                                <p>
                                    F<sub>W,Ed</sub> &le; F<sub>W,M,V,Rd</sub> = <sup>2</sup>&frasl;<sub>3</sub>&middot;
                                    {'['}
                                        -(s - d') + 
                                        <span style={{ whiteSpace: "nowrap" }}>
                                            <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;(s - d')<sup>2</sup> + 3t<sub>a</sub><sup>2</sup>&nbsp;</span>
                                        </span>
                                    {']'} (b<sub>a</sub> - d)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                    <span className={styles.value1}>
                                        {results.forces.Fw} kN &le; {results.forces.Fwmvrd} kN (
                                            <span>{roundNumber(100*results.forces.Fw/results.forces.Fwmvrd,2)}%</span>
                                        )
                                    </span>
                                </p>
                                <p>
                                    F<sub>Ed</sub> &le; F<sub>Rd,l,1</sub> = <sup>&pi;</sup>&frasl;<sub>2
                                    <span style={{ whiteSpace: "nowrap" }}>
                                        <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;2&nbsp;</span>
                                    </span></sub>(d<sub>SG</sub><sup>2</sup> - d<sup>2</sup>)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                
                                    <span className={styles.value1}>
                                        {results.forces.Fed} kN &le; {results.forces.FrdL1} kN (
                                            <span>{roundNumber(100*results.forces.Fed/results.forces.FrdL1,2)}%</span>
                                        )
                                    </span>
                                </p>
                                <p>
                                    F<sub>Rd,l,1</sub> &le; F<sub>Rd,l,2</sub> = <sup>1</sup>&frasl;<sub>
                                    <span style={{ whiteSpace: "nowrap" }}>
                                        <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;3&nbsp;</span>
                                    </span></sub>&pi;d<sub>SG</sub>t<sub>a</sub><sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                
                                    <span className={styles.value1}>
                                        {results.forces.FrdL1} kN &le; {results.forces.FrdL2} kN (
                                            <span>{roundNumber(100*results.forces.FrdL1/results.forces.FrdL2,2)}%</span>
                                        )                                            
                                    </span>
                                </p>
                                <p>
                                    <b>
                                        <span>
                                            {
                                                results.forces.Fp/results.forces.Fpmvrd <= 1 && results.forces.Fw/results.forces.Fwmvrd <= 1 && results.forces.Fed/results.forces.FrdL1 <= 1 && results.forces.FrdL1/results.forces.FrdL2
                                                ? 'Płyta oporowa zaprojektowana poprawnie' 
                                                : 'Płyta oporowa zaprojektowana błędnie'
                                            }
                                        </span>
                                    </b>
                                </p>
                                <p>Nośność ścianki szczelnej na podstawie PN-EN 1993-5 pkt 7.4.3</p>
                                <p>
                                    F<sub>Ed</sub> &le; R<sub>vf,Rd</sub> = 2(b<sub>b</sub> + h<sub>a</sub>)t<sub>f</sub> <sup>f<sub>y</sub></sup>&frasl;<sub><span style={{ whiteSpace: "nowrap" }}>
                                        <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;3&nbsp;</span>
                                    </span>y<sub>M0</sub></sub>
                                
                                    <span className={styles.value1}>
                                        {results.forces.Fed} kN &le; {results.forces.Rvfrd} kN (
                                            <span>{roundNumber(100*results.forces.Fed/results.forces.Rvfrd,2)}%</span>
                                        )                                            
                                    </span>
                                </p>
                                <p>
                                    F<sub>Ed</sub> &le; R<sub>tw,Rd</sub> = 2h<sub>a</sub>t<sub>w</sub> <sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                
                                    <span className={styles.value1}>
                                        {results.forces.Fed} kN &le; {results.forces.Rtwrd} kN (
                                            <span>{roundNumber(100*results.forces.Fed/results.forces.Rtwrd,2)}%</span>
                                        )
                                    </span>
                                </p>
                                <p>
                                    <b>
                                        <span>
                                            {
                                                results.forces.Fed/results.forces.Rtwrd <= 1 && results.forces.Fed/results.forces.Rvfrd <= 1
                                                ? 'Ścianka szczelna zaprojektowana poprawnie' 
                                                : 'Ścianka szczelna zaprojektowana błędnie'
                                            }
                                        </span>
                                    </b>
                                </p>
                            </Fragment>
                            :
                            <Fragment>
                                <p>Kleszcz stalowy</p>
                                <p>M<sub>Ed,waling</sub>: <span className={styles.value1}>{roundNumber(results.forces.Med,2)} kN</span></p>
                                <p>W<sub>x,req</sub>: <span className={styles.value1}>{roundNumber(results.waling.ceoWx,2)} cm<sup>3</sup></span></p>
                                <p>
                                    W<sub>x,req</sub> &le; W<sub>x,ceo</sub>

                                    <span className={styles.value1}>
                                        {results.waling.ceoWx} cm<sup>3</sup> &le; {results.waling.designCeo[1].wx} cm<sup>3</sup> (
                                        <span>{roundNumber(100*results.waling.ceoWx/results.waling.designCeo[1].wx,2)}%</span>
                                        )
                                    </span>
                                </p>
                                <p>
                                    <b>
                                        <span>
                                            {
                                                results.waling.ceoWx/results.waling.designCeo[1].wx <= 1 
                                                ? 
                                                'Kleszcz zaprojektowany poprawnie' 
                                                : 
                                                'Kleszcz zaprojektowany błędnie'
                                            }
                                        </span>
                                    </b>
                                </p>
                                <p>Nośność płyty oporowej</p>
                                <p>Masa płyty: <span className={styles.value1}>{roundNumber(results.plate.m*0.001,2).toFixed(2)} kg</span></p>
                                <p>
                                    F<sub>Ed</sub> &le; F<sub>M,V,Rd</sub> = <sup>2</sup>&frasl;<sub>3</sub>&middot;
                                    {'['}
                                        -(g - d') + 
                                        <span style={{ whiteSpace: "nowrap" }}>
                                            <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;(g - d')<sup>2</sup> + 3t<sub>a</sub><sup>2</sup>&nbsp;</span>
                                        </span>
                                    {']'} (h<sub>a</sub> - d)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                    <span className={styles.value1}>
                                        {results.forces.Fed} kN &le; {results.forces.Fmvrd} kN (
                                            <span>{roundNumber(100*results.forces.Fed/results.forces.Fmvrd,2)}%</span>
                                        )
                                    </span>
                                </p>
                                <p>
                                    F<sub>Ed</sub> &le; F<sub>Rd,l,1</sub> = <sup>&pi;</sup>&frasl;<sub>2
                                    <span style={{ whiteSpace: "nowrap" }}>
                                        <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;2&nbsp;</span>
                                    </span></sub>(d<sub>SG</sub><sup>2</sup> - d<sup>2</sup>)<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                
                                    <span className={styles.value1}>
                                        {results.forces.Fed} kN &le; {results.forces.FrdL1} kN (
                                            <span>{roundNumber(100*results.forces.Fed/results.forces.FrdL1,2)}%</span>
                                        )                                            
                                    </span>
                                </p>
                                <p>
                                    F<sub>Rd,l,1</sub> &le; F<sub>Rd,l,2</sub> = <sup>1</sup>&frasl;<sub>
                                    <span style={{ whiteSpace: "nowrap" }}>
                                        <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;3&nbsp;</span>
                                    </span></sub>&pi;d<sub>SG</sub>t<sub>a</sub><sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                
                                    <span className={styles.value1}>
                                        {results.forces.FrdL1} kN &le; {results.forces.FrdL2} kN (
                                            <span>{roundNumber(100*results.forces.FrdL1/results.forces.FrdL2,2)}%</span>
                                        )
                                    </span>
                                </p>
                                <p>
                                    M<sub>Ed</sub> = 0.25&middot;F<sub>W,Ed</sub>(s-d'): <span className={styles.value1}>{roundNumber(results.forces.Medplate*0.01,2)} kNm</span> 
                                </p>
                                <p>
                                    M<sub>Ed</sub> &le; M<sub>pl,Rd</sub> =
                                    {'['}
                                    <sup>1</sup>&frasl;<sub>4</sub>(b<sub>a</sub> - d)t<sub>a</sub><sup>2</sup> +
                                    
                                    c&middot;e(t<sub>a</sub> - <sup>c&middot;e</sup>&frasl;<sub>(b<sub>a</sub> - d)</sub> + c){']'}<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                    <span className={styles.value1}>
                                        {roundNumber(results.forces.Medplate*0.01,2)} kNm &le; {roundNumber(results.forces.MplRd*0.01,2)} kNm (
                                            <span>{roundNumber(100*results.forces.Medplate/results.forces.MplRd,2)}%</span>
                                        )                                            
                                    </span>
                                </p>
                                <p>
                                    V<sub>Ed</sub> = 0.5&middot;F<sub>W,Ed</sub> <span className={styles.value1}>{roundNumber(results.forces.Vedplate,2)} kN</span> 
                                </p>
                                <p>
                                    V<sub>Ed</sub> &le; 0.5&middot;V<sub>pl,Rd</sub> =
                                    {' ['}
                                    (b<sub>a</sub> - d)t<sub>a</sub> +
                                    
                                    2c&middot;e{']'}<sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>

                                    <span className={styles.value1}>
                                        {results.forces.Vedplate} kN &le; {results.forces.VplRd} kN (
                                            <span>{roundNumber(100*results.forces.Vedplate/results.forces.VplRd,2)}%</span>
                                        )                                            
                                    </span>
                                </p>
                                <p>
                                    <b>
                                        <span>
                                            {
                                                results.forces.Fed/results.forces.Fmvrd <= 1 && results.forces.Fed/results.forces.FrdL1 <= 1 && results.forces.FrdL1/results.forces.FrdL2 && results.forces.Medplate/results.forces.MplRd <= 1 && results.forces.Vedplate/results.forces.VplRd <= 1
                                                ? 'Płyta oporowa zaprojektowana poprawnie' 
                                                : 'Płyta oporowa zaprojektowana błędnie'
                                            }
                                        </span>
                                    </b>
                                </p>
                                <p>Nośność ścianki szczelnej na podstawie PN-EN 1993-5 pkt 7.4.3</p>
                                <p>
                                    F<sub>Ed</sub> &le; R<sub>vf,Rd</sub> = 2(b<sub>b</sub> + h<sub>a</sub>)t<sub>f</sub> <sup>f<sub>y</sub></sup>&frasl;<sub><span style={{ whiteSpace: "nowrap" }}>
                                        <span className={styles.radic}>&radic;</span><span className={styles.rootLine}>&nbsp;3&nbsp;</span>
                                    </span>y<sub>M0</sub></sub>
                                
                                    <span className={styles.value1}>
                                        {results.forces.Fed} kN &le; {results.forces.Rvfrd} kN (
                                            <span>{roundNumber(100*results.forces.Fed/results.forces.Rvfrd,2)}%</span>
                                        )                                            
                                    </span>
                                </p>
                                <p>
                                    F<sub>Ed</sub> &le; R<sub>tw,Rd</sub> = 2h<sub>a</sub>t<sub>w</sub> <sup>f<sub>y</sub></sup>&frasl;<sub>y<sub>M0</sub></sub>
                                
                                    <span className={styles.value1}>
                                        {results.forces.Fed} kN &le; {results.forces.Rtwrd} kN (
                                            <span>{roundNumber(100*results.forces.Fed/results.forces.Rtwrd,2)}%</span>
                                        )                                            
                                    </span>
                                </p>
                                <p>
                                    <b>
                                        <span>
                                            {
                                                results.forces.Fed/results.forces.Rtwrd <= 1 && results.forces.Fed/results.forces.Rvfrd <= 1
                                                ? 'Ścianka szczelna zaprojektowana poprawnie' 
                                                : 'Ścianka szczelna zaprojektowana błędnie'
                                            }
                                        </span>
                                    </b>
                                </p>
                            </Fragment>
                        }
                    </Fragment>
                    :
                    <Fragment></Fragment>                
                }
            </Fragment>
        </div>
    )
}

export default ResultsSection