/**
 * Noncohesive soil signature according to PN-B-81-03020
 * 0 - Żwiry i Pospółki
 * 1 - Piaski grube i średnie
 * 2 - Piaski drobne i pylaste
 */
/**
 * Noncohesive soil weight depending on moistures
 * 0 - mało wilgotne
 * 1 - wilgotne 
 * 2 - mokre
 */
/**
 * signature -> moistures
 */
 const defaultNoncohesiveSoilsWeight = {
    0 : {
        0 : {
            aboveWater: [18.5, 17.5, 17],
            underWater: [11.2, 10.5, 10.1]
        },
        1 : {
            aboveWater: [20, 19, 18.5],
            underWater: [11.3, 10.6, 10]
        },
        2 : {
            aboveWater: [21, 20.5, 20],
            underWater: [11.5, 10.8, 10.1]
        },        
    }, 
    1 : {
        0 : {
            aboveWater: [18,17,16.5],
            underWater: [10.8,10.1,9.7]
        },
        1 : {
            aboveWater: [19,18.5,18],
            underWater: [10.6,10.1,9.7]
        },
        2 : {
            aboveWater: [20.5,20,19.5],
            underWater: [10.8,10.2,9.7]
        },  
    },
    2 : {
        0 : {
            aboveWater: [17,16.5,16],
            underWater: [10.1,9.7,9.3]
        },
        1 : {
            aboveWater: [18.5,17.5,17],
            underWater: [10.1,9.4,8.9]
        },
        2 : {
            aboveWater: [20,19,18.5],
            underWater: [10.2,9.6,9]
        },  
    }
};

export default defaultNoncohesiveSoilsWeight