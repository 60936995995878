import { Fragment } from "react"
import { InputField } from ".."
import styles from './index.module.css';

const ConfirmPassword = ({ onChange, value, isValid, name='confirmPassword', title='Potwierdź hasło', ...rest }) => {
  
    return (
        <Fragment>
            <InputField
                title={title}
                isValid={isValid}
                type="password"
                name={name}
                value={value} 
                placeholder={title}
                onChange={onChange} 

                {...rest}
            />
            {isValid === false && <div>
                <ul className={styles.ul}>
                    <li>Podane hasła nie są takie same</li>
                </ul>
            </div>}
        </Fragment>
    )
}

export default ConfirmPassword