import { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { SectionTitle } from "../../../../../components";
import { roundNumber } from "../../../../../utils";
import styles from './index.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";

const ShearSummary = ({ results, stirrupType='Strzemiona', pileShape, shearForce }) => {
    return (
        <Fragment>
            <SectionTitle
                title="Zbrojenie na ścinanie (SGN)"
            />
            <Row className={styles.row}>
                {
                    results.incorrectDiameter
                    ?
                    <Col xs={12} className={styles.col}>
                        <div className={styles.flex}>
                            <FontAwesomeIcon
                                icon={faBan}
                            />
                            <p>Niepoprawne wymiary przekroju ze względu na ścinanie.</p>                            
                        </div>

                    </Col>
                    :
                    results.reinfNotRequired
                    ?
                    <Col xs={12} className={styles.col}>
                        <p>Zbrojenie na ścinanie nie jest wymagane</p>
                    </Col>
                    :
                    <Fragment>
                        <Col xs={12} className={styles.col}>
                            {
                                pileShape === 1 && stirrupType === 'Spirala'
                                ?
                                    <b><p>Obliczeniowy skok spirali [p] - {roundNumber(results.s,2)} mm</p></b>
                                :
                                    <b><p>Obliczeniowy rozstaw strzemion [s] - {roundNumber(results.s,2)} mm</p></b>
                            }
                            {
                                pileShape === 1 && stirrupType === 'Spirala'
                                ?
                                    <p>Maksymalny skok spirali [p<sub>min</sub>] - {roundNumber(results.smin,2)} mm</p>
                                :
                                    <p>Maksymalny rozstaw strzemion [s<sub>min</sub>] - {roundNumber(results.smin,2)} mm</p>
                            }   

                            <p>Obliczeniowa siła poprzeczna [V<sub>Ed</sub>] - {roundNumber(shearForce,2)} kN</p>
                            {
                                results.vrdc && <p>Nośność na ścinanie bez zbrojenia [V<sub>Rd,c</sub>] - {roundNumber(results.vrdc,2)} kN</p>
                            }
                            {
                                results.vrdMax && <p>Nośność krzyżulca ściskanego [V<sub>Rd,max</sub>] - {roundNumber(results.vrdMax,2)} kN</p>
                            }
                            {
                                results.cotTeta && <p>Kąt nachylenia krzyżulców ściskanych [&#920;] - {roundNumber(
                                    Math.atan(1/results.cotTeta)*180/Math.PI
                                ,2)} &deg;</p>
                            }
                        </Col>
                    </Fragment>
                }
            </Row>
        </Fragment>
    )
}

export default ShearSummary;