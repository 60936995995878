import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
} from '@fortawesome/free-regular-svg-icons';
import { isNumber, roundNumber, setSoilColorQc } from '../../../../../utils';
import SoilLayer from  '../../../results/Results/CptCapacityChart/SoilProfile/SoilLayer';
import styles from './index.module.css';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useWindowSize } from '../../../../../hooks';


const CapacityChart = ({
    openSidebar,
    currentPileLength,
    groundLevel,
    sheetPileHeadSpot,
    sheetPileLength,
    sheetPileList,
    sheetPileNumbers,
    sheetPileType,
    baseArea,
    perimeter,
    yt,
    results=[],
    soilTable=[],
    currentCapacity,
    title=''
}) => {
    const soilProfileRef = useRef(null);
    const resultsRef = useRef(null);
    const [soilLayer, setSoilLayer] = useState([]);
    const [resultsWidth, setResultsWidth] = useState(0);
    const [soilProfileHeight, setSoilProfileHeight] = useState(0);
    const [profilePileLength, setProfilePileLength] = useState('');
    const [profilePileHeadSpot, setProfilePileHeadSpot] = useState('');
    const [axisNtScale, setAxisNtScale] = useState([]);
    const [maxNt, setMaxNt] = useState('');
    const [maxHeight, setMaxHeight] = useState(0);

    const [currentNt, setCurrentNt] = useState('');
    const [pileBaseSpot, setPileBaseSpot] = useState('');
    const windowSize = useWindowSize();
    const soilProfileHeights = results.map((ele) => ele.z);

    useEffect(() => {
        if (resultsRef.current) {
            setResultsWidth(resultsRef.current.offsetWidth*0.9);
        }
    }, [
        openSidebar,
        windowSize.width,
    ]);

    useDeepCompareEffect(() => {
        setMaxHeight(Math.max(...soilTable.map(ele => ele.z)));
    }, [soilTable]);

    useDeepCompareEffect(() => {
        if (soilProfileRef.current) {
            const offsetHeight = soilProfileRef.current.offsetHeight;
            const maxHeight = Math.max(...soilTable.map(ele => ele.z));
            const layers = soilTable.map((ele, i, arr) => {
                return (
                    <SoilLayer
                        key={i}
                        backgroundColor={setSoilColorQc({
                            type: ele.soilType,
                            qc: ele.qc,
                        })}
                        height={
                            maxHeight === 0
                                ? 0
                                : (offsetHeight *
                                      (i === arr.length - 1
                                          ? ele.absHeight
                                          : ele.absHeight)) /
                                  maxHeight
                        }
                    />
                );
            });
            setSoilLayer(layers);
            setSoilProfileHeight(offsetHeight);
        }
    }, [soilTable, soilProfileHeights, pileBaseSpot]);

    useDeepCompareEffect(() => {
        const nt = Math.max(...results.map((ele) =>  ele.Rcd ));        
        const maxAxisNt = roundNumber(nt - (nt % 5), 0);

        const initPileLength =
            isNumber(currentPileLength) && currentPileLength > 0
                ? currentPileLength
                : isNumber(sheetPileLength) && sheetPileLength > 0
                ? sheetPileLength
                : roundNumber(maxHeight - sheetPileHeadSpot - 1,2);


        setAxisNtScale(
            Array(5)
            .fill(maxAxisNt / 5)
            .reduce((acc, ele, idx) => [...acc, ele * (idx + 1)], []),
        );
        
        setMaxNt(nt);

        setPileBaseSpot((state) =>
            isNumber(state) && state > sheetPileHeadSpot
                ? state
                : roundNumber(initPileLength + sheetPileHeadSpot,2),
        );

        setCurrentNt(() => currentCapacity.Rcd);

        if (soilProfileRef.current) {
            setProfilePileHeadSpot(
                maxHeight === 0
                    ? 0
                    : (soilProfileHeight * sheetPileHeadSpot) / maxHeight,
            );
            setProfilePileLength(maxHeight === 0 ? 0 : (soilProfileHeight * initPileLength) / maxHeight);
        }
    }, [
        maxHeight,
        soilProfileHeight,
        currentPileLength,
        currentCapacity,
        sheetPileHeadSpot,
        sheetPileLength,
        results,
    ]);

    return (
        <Fragment>
            <div className={styles.chartWrapper}>
                <div className={styles.title}>
                    <h2>{title}</h2>
                </div>
                <div className={styles.wrapper} ref={soilProfileRef}>
                    <div className={styles.pileWrapperCapacityView}
                        style={{
                            height: `${profilePileLength}px`,
                            top: `${profilePileHeadSpot}px`,
                        }}
                    >
                        <div className={styles.pileCapacityView}>
                           <div className={styles.sheetPileLength}>
                                <p>
                                    {sheetPileList && `${sheetPileType}`} L ={' '}
                                    {roundNumber(currentPileLength, 2)} m
                                </p>
                            </div>
                        </div>
                        <div className={styles.spotWrapper}>
                            <span className={styles.pileSpot}>
                                {isNumber(sheetPileHeadSpot) ? sheetPileHeadSpot.toFixed(2) : ''}
                                <span className={styles.spanBlock}>
                                    {(isNumber(sheetPileHeadSpot) && isNumber(groundLevel)) ? `(${(groundLevel - sheetPileHeadSpot).toFixed(2)})`: ''}
                                </span>
                            </span>
                            <span className={styles.pileSpot}> 
                                {isNumber(pileBaseSpot) ? pileBaseSpot.toFixed(2) : ''}
                                <span className={styles.spanBlock}>
                                    {(isNumber(pileBaseSpot) && isNumber(groundLevel)) ? `(${(groundLevel - pileBaseSpot).toFixed(2)})`: ''}
                                </span>
                            </span>
                        </div>
                        <div className={styles.results} ref={resultsRef}>
                            <div className={styles.horizontalAxis}>
                                {axisNtScale.map((ele, idx) => {
                                    return (
                                        <span key={idx} className={styles.axisCoord}
                                            style={{left: `${(resultsWidth / maxNt)*ele}px`}}>
                                            <p className={ styles.value }>
                                                {ele}
                                            </p>
                                        </span>
                                    );
                                })}

                                <span className={styles.caret}>
                                    <p className={styles.axisDescription}>
                                        <Fragment>R<sub>c,d</sub>[kN]</Fragment>
                                    </p>
                                </span>
                            </div>
                            <div className={styles.verticalAxis}>
                                <span className={styles.verticalCaret}>
                                    <p className={styles.axisVerticalDescription}>Z[m]</p>
                                </span>
                            </div>
                            <div className={styles.currentHorizontalValue} 
                                style={{ 
                                    top: `${profilePileLength}px`,
                                    width: `${(resultsWidth * currentNt) / maxNt}px`,
                                }}
                            >
                                <div className={styles.line}></div>
                            </div>
                            <div className={styles.currentVerticalValue}
                                style={{
                                    height: `${profilePileLength}px`,
                                    left: `${
                                        (resultsWidth * currentNt) /
                                        maxNt
                                    }px`,
                                }}>
                                    <div className={styles.lineVertical}></div>
                            </div>
                            <svg className={styles.svgWrapper}>
                                {results.map((ele, i, a) => {
                                    return (
                                        <Fragment key={i}>
                                        {
                                            i < (a.length - 1)
                                            &&
                                            <Fragment key={i}>
                                                <polyline 
                                                    className={styles.svgLine} 
                                                    points={`
                                                        ${(resultsWidth*ele.Rcd) / maxNt}, ${(soilProfileHeight *(ele.z - sheetPileHeadSpot)) /maxHeight} 
                                                        ${(resultsWidth*a[i+1].Rcd) / maxNt}, ${(soilProfileHeight *(a[i+1].z - sheetPileHeadSpot)) /maxHeight}`}
                                                ></polyline>
                                            </Fragment>
                                            }
                                        </Fragment>
                                    )
                                })}
                            </svg>
                            {results.map((ele, i) => {
                                return (
                                    <Fragment key={i}>
                                        <div className={`${styles.capacityPoint} ${styles.validDepth}`}
                                            style={{
                                                top: `${(soilProfileHeight *(ele.z - sheetPileHeadSpot)) /maxHeight}px`,
                                                left: `${(ele.Rcd > 0) ? (resultsWidth*ele.Rcd) / maxNt : 0}px`
                                            }}>
                                                <FontAwesomeIcon
                                                    icon={faCheckCircle}
                                                />
                                        </div>
                                    </Fragment>
                                );
                            })}
                        </div>                        
                    </div>
                    <div>
                        {[...soilLayer]}
                    </div>
                </div>
            </div>
            <div className={styles.capacityDescription}>
                {
                    sheetPileList &&
                    <p>Rodzaj ścianki szczelnej [-] -{' '}
                        <span className={styles.spanValue}><b>{sheetPileType}</b></span>
                    </p>
                }
                {
                    !sheetPileList && 
                    <Fragment>
                        <p>Powierzchnia podstawy [A] -{' '}
                            <span className={styles.spanValue}>
                                <b>{baseArea} cm<sup>2</sup></b>
                            </span>
                        </p>
                        <p>Pobocznica zestawu grodzic [U] -{' '}
                            <span className={styles.spanValue}>
                                <b>{perimeter} cm</b>
                            </span> 
                        </p>
                    </Fragment>
                }
                <p>
                    Długość ścianki szczelnej w gruncie [L] -{' '}
                    <span className={styles.spanValue}>
                        <b>
                            {isNumber(currentPileLength)
                                ? roundNumber(currentPileLength, 2).toFixed(2)
                                : ''}{' '}
                            m
                        </b>                        
                    </span>
                </p>
                {
                    sheetPileNumbers > 1 &&
                    <Fragment>
                        <p>
                            <Fragment>
                                Nośność podstawy jednego zestawu [R<sub>b,single,d</sub>] -{' '}
                                <span className={styles.spanValue}>
                                    <b>
                                        {isNumber(currentCapacity.Rbk_single/yt)
                                            ? roundNumber(currentCapacity.Rbk_single/yt, 2)
                                            : ' '} kN
                                    </b>                                    
                                </span>
                            </Fragment>
                        </p>
                        <p>
                            Nośność pobocznicy jednego zestawu [R<sub>s,single,d</sub>] -{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    <Fragment>
                                        {isNumber(currentCapacity.Rsk_single/yt)
                                            ? roundNumber(currentCapacity.Rsk_single/yt, 2)
                                            : ''}
                                        kN
                                    </Fragment>
                                </b>
                            </span>
                        </p>
                        <p>
                            Nośność ścianki szczelnej na wciskanie jednego zestawu [R<sub>c,single, d</sub>] -{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(currentCapacity.Rcd_single)
                                        ? roundNumber(currentCapacity.Rcd_single, 2)
                                        : ''}{' '}
                                    kN
                                </b>                                
                            </span>
                        </p>
                    </Fragment>
                }
                <p>
                    <Fragment>
                        Nośność podstawy [R<sub>b,d</sub>] -{' '}
                        <span className={styles.spanValue}>
                            <b>
                                {isNumber(currentCapacity.Rbk)
                                    ? roundNumber(currentCapacity.Rbk/yt, 2)
                                    : ' '} kN
                            </b>                            
                        </span>
                    </Fragment>
                </p>
                <p>
                    Nośność pobocznicy [R<sub>s,d</sub>] -{' '}
                    <span className={styles.spanValue}>
                        <b>
                            <Fragment>
                                {isNumber(currentCapacity.Rsk)
                                    ? roundNumber(currentCapacity.Rsk/yt, 2)
                                    : ''}
                                kN
                            </Fragment>
                        </b>
                    </span>
                </p>

                {
                sheetPileList &&
                <p>
                    Nośność ścianki szczelnej na wciskanie jako ciągłej ściany*[R<sub>c,d</sub>] -{' '}
                    <span className={styles.spanValue}>
                        <b>
                            {isNumber(currentCapacity.Rcd_meter)
                                ? roundNumber(currentCapacity.Rcd_meter, 2)
                                : ''}{' '}
                            kN/mb 
                        </b>
                    </span>
                </p>
                }
                <p>
                    <u>
                        Nośność ścianki szczelnej na wciskanie [R<sub>c,d</sub>] -{' '}
                        <span className={styles.spanValue}>
                            <b>
                                {isNumber(currentCapacity.Rcd)
                                    ? roundNumber(currentCapacity.Rcd, 2)
                                    : ''}{' '}
                                kN
                            </b>                            
                        </span>
                    </u>
                </p>
                {
                    sheetPileList &&
                    <p>* Nośność ściany na metr bieżący wyznaczono z założeniem, że obliczenia dotyczą konstrukcji ciągłej. W przypadku innych typów konstrukcji np. pale skrzynkowe, ścianka Jagged-wall itp. wartość tę należy zignorować.</p>
                }    
            </div>
        </Fragment>
    );
};

export default React.memo(
    CapacityChart,
    (prev, next) => stringify(prev) === stringify(next),
);
