import React, { useEffect, useState } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import styles from './index.module.css';

const DisplayPerPage = ({onDisplayItemsNumber, itemsPerPage='', totalItems, display=[], align='right' }) => {

    const [itemsPerPageState, setItemsPerPageState] = useState(itemsPerPage > totalItems ? totalItems : itemsPerPage);

    useEffect(() => {
        setItemsPerPageState(itemsPerPage > totalItems ? totalItems : itemsPerPage);
    },[itemsPerPage, totalItems]);

    return ( 
        <div className={align === 'right' ? styles.wrapper : styles.wrapperLeft}>
            <div className={align === 'right' ? styles.floatWrapper : styles.floatWrapperLeft}>
                <span className={styles.span}>Liczba wierszy: </span>
                <DropdownButton className={styles.dropDown} title={itemsPerPageState}>
                    <div className={styles.dropdownMenu} onClick={onDisplayItemsNumber}>
                        {[...new Set(display.filter(ele => ele <= totalItems))].sort((a,b)=>(a-b)).map((element,index) => {
                            return (
                                <Dropdown.Item className={styles.dropdownItem} key={index} active={itemsPerPageState === element} value={element}>{element}</Dropdown.Item>
                            )
                        })}                        
                    </div>
                </DropdownButton>
            </div>
        </div>
    )
}

export default DisplayPerPage