import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useIsMountedState } from '../../hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useParams } from 'react-router-dom';

const defaultState = {
    isLoaded: true,
    clearForm: false,
    actionLoaded: [],
};

const urlReplacer = (url, paramsObject) =>
    Object.keys(paramsObject).length > 0
        ? url
              .replace(/(?<=(:))\w+/g, ($1) => paramsObject[$1])
              .replace(/:/g, '')
        : url;

const withForm = ({ 
    component,
    localState = {},
    actions = [],
    onlyActions = false,
    clearFormToDefaultState = false,
}) => {
    const Component = component;

    return (props) => {
        const urlParams = useParams()
        const isMountedState = useIsMountedState();
        const errorMessageTimeoutRef = useRef(null);
        const successMessageTimeoutRef = useRef(null);
        const stateTimeoutRef = useRef(null);

        const [formState, setFormState] = useState({
            ...defaultState,
            ...localState,
            ...actions.reduce((acc, ele) => {
                acc[ele.name] = {
                    responseData: {},
                    loadingName: ele.loadingName || '',
                    isLoaded: true,
                };
                return acc;
            }, {}),
        });

        const [messageState, setMessageState] = useState(
            actions.reduce((acc, ele) => {
                acc[ele.name] = {
                    error: false,
                    open: false,
                    message: false,
                    success: false,
                };
                return acc;
            }, {}),
        );

        useDeepCompareEffect(() => {
            const allActionsLoaded = formState.actionLoaded.every(
                (ele) => ele === true,
            );

            if (
                allActionsLoaded &&
                formState.actionLoaded.length === actions.length
            ) {
                setFormState((state) => ({
                    ...state,
                    isLoaded: true,
                    actionLoaded: [],
                }));
            }
        }, [formState]);

        useEffect(() => {
            setFormState((state) => ({ ...state, clearForm: false }));
        }, [formState.clearForm]);

        useEffect(() => {
            return () => {
                clearTimeout(successMessageTimeoutRef);
                clearTimeout(errorMessageTimeoutRef);
                clearTimeout(stateTimeoutRef);
            };
        }, []);

        const onToggleCheckbox = () =>
            setFormState((state) => ({ ...state, checked: !state.checked }));
        const onChange = (event, isValid) => {
            event.persist();
            const { name, value } = event.target;
            const validInput = event.target.checkValidity();

            setFormState((state) => ({
                ...state,
                ...(name === 'email' && {
                    email: {
                        ...state['email'],
                        occupied: false,
                    },
                }),
                [name]: {
                    ...state[name],
                    value: value,
                    isValid: isValid !== undefined ? isValid : validInput,
                },
            }));
        };

        const onSubmit = (callback, localFormState = {}) => {
            const dataToSend = Object.entries({
                ...formState,
                ...localFormState,
            }).filter(
                ([key, value]) =>
                    value.hasOwnProperty('value') &&
                    value.hasOwnProperty('isValid'),
            );
            const valid = dataToSend.every(([key, value]) => value.isValid);

            if (valid) {
                setFormState((state) => ({ ...state, isLoaded: false }));
                callback(
                    dataToSend.reduce(
                        (acc, [key, { value }]) => ({ ...acc, [key]: value }),
                        {},
                    ),
                );
            } else {
                setFormState((state) => ({
                    ...state,
                    ...dataToSend.reduce(
                        (acc, [key, { isValid, ...rest }]) => ({
                            ...acc,
                            [key]: { isValid: !!isValid, ...rest },
                        }),
                        {},
                    ),
                }));
            }
        };

        const actionFunction = (element, { params, body, headers }) => {
            return axios({
                url: urlReplacer(
                    element.url,
                    (!!params && Object.keys(params).length) > 0
                        ? params
                        : urlParams,
                ),
                method: element.method,
                ...(!!element.headers && {
                    headers: { ...element.headers, ...headers },
                }),
                ...(!!body && { data: body }),
            })
                .then((response) => {
                    if (isMountedState.current) {
                        if (element.returnData) {
                            return response;
                        } else {
                            setFormState((state) => ({
                                ...state,
                                actionLoaded: [...state.actionLoaded, true],
                                [element.name]: {
                                    ...state[element.name],
                                    responseData: response.data,
                                    status: response.status,
                                    statusText: response.statusText,
                                    isLoaded: true,
                                },
                            }));

                            stateTimeoutRef.current = setTimeout(() => {
                                if (isMountedState.current) {
                                    if (clearFormToDefaultState) {
                                        setFormState((state) => ({
                                            ...state,
                                            ...localState,
                                            clearForm: true,
                                        }));
                                    }
                                }
                            }, 1000);

                            setMessageState((state) => ({
                                ...state,
                                [element.name]: {
                                    success: true,
                                    open: element.showSuccessMessage,
                                    message:
                                        element.successMessage ||
                                        response.data.message,
                                },
                            }));

                            successMessageTimeoutRef.current = setTimeout(
                                () => {
                                    if (isMountedState.current) {
                                        if (element.closeSuccessMessage) {
                                            setMessageState((state) => ({
                                                ...state,
                                                [element.name]: {
                                                    success: true,
                                                    open: false,
                                                    message: false,
                                                },
                                            }));
                                        }
                                    }
                                },
                                8000,
                            );

                            return response;
                        }
                    }
                })
                .catch((error) => {
                    if (isMountedState.current) {
                        if (element.returnData) {
                            return {
                                success: false,
                                message:
                                    error.response.data.message ||
                                    element.errorMessage,
                                data: error.response.data || {},
                            };
                        } else {
                            setFormState((state) => ({
                                ...state,
                                actionLoaded: [...state.actionLoaded, true],
                                [element.name]: {
                                    ...state[element.name],
                                    responseData: error.response.data,
                                    status: error.response.status,
                                    statusText: error.response.statusText,
                                    isLoaded: true,
                                },
                            }));

                            setMessageState((state) => ({
                                ...state,
                                [element.name]: {
                                    success: false,
                                    open: element.showErrorMessage,
                                    message:
                                        element.errorMessage ||
                                        error.response.data.message,
                                },
                            }));

                            errorMessageTimeoutRef.current = setTimeout(() => {
                                if (isMountedState.current) {
                                    if (element.closeErrorMessage) {
                                        setMessageState((state) => ({
                                            ...state,
                                            [element.name]: {
                                                success: false,
                                                open: false,
                                                message: false,
                                            },
                                        }));
                                    }
                                }
                            }, 8000);

                            return {
                                success: false,
                                message:
                                    error.response.data.message ||
                                    element.errorMessage,
                                data: error.response.data || {},
                            };
                        }
                    }
                });
        };
        const actionsObj = () => {
            let obj = {};
            actions.forEach((element) => {
                obj[element.name] = (params = {}) => {
                    setFormState((state) => ({
                        ...state,
                        [element.name]: {
                            ...state[element.name],
                            isLoaded: false,
                        },
                    }));

                    return actionFunction(element, params);
                };
            });
            return obj;
        };

        const actionsObjResolved = actionsObj();

        const formObject = {
            ...(!onlyActions && {
                onSubmit,
                onChange,
                onToggleCheckbox,
            }), 

            formState,
            messageState,
            actions: actionsObjResolved
        };

        return <Component {...props} {...formObject} />;
    };
};

export default withForm;
