import { useCallback, useState } from "react";
import { isNumber, roundNumber, stringToNumber } from '../../../utils';
import { nkInterpolation } from "../../../config/ProjectConfig";


const ashFiTypes = {
    1: 'fi > 30%',
    2: 'fi od 10% do 30%',
    3: 'fi < 10%'
};
const defaultPNBSoilObj = { 
    type: {
        value: 0,
        isValid: true
    },
    cohesiveType: {
        value: 1,
        isValid: true
    },
    densityOrPlasticity: {
        value: '',
        isValid: null 
    },
    ashFi: {
        value: ashFiTypes[3],
        isValid: true
    }
}
const soilTypes = {
    0: 'Niespoisty',
    1: 'Spoisty'
};
const cohesiveSoilType = {
    1: 'Gliny pokrywowe i zwałowe zlodowacenia Wisły nieskonsolidowane lodowcem',
    2: 'Gliny zwałowe starsze skonsolidowane',
    3: 'Utwory zastoiskowe czwartorzędowe iły pylaste, gliny pylaste',
    4: 'Iły plioceńskie i mioceńskie',
    5: 'Gytie'
}


const DefaultPNBSoilHelpers = (setSoilTableState) => {
 
    const [defaultSoilParametersState, setDefaultSoilParametersState] = useState(defaultPNBSoilObj);

    const onBlurDefaultSoilState = useCallback((e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if(input){
            const { name } = input;
            const valid = input.checkValidity(); 
                  
            setDefaultSoilParametersState(state => ({ ...state, [name]: { ...state[name], isValid: valid }}))
        }
    },[]);

    const onChangeDefaultSoilState = useCallback((e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if(input){
            const { name, value, type } = input;
            
            setDefaultSoilParametersState(state => {
                if(name === 'type'){
                    const val = value === 'Niespoisty' ? 0 : value === 'Spoisty' ? 1 : '';

                    return {
                        ...state,
    
                        [name]: {
                            value: val,
                            isValid: isNumber(val) ? true : false,
                        }
                    }
                } else if(name === 'cohesiveType'){
                    const val = stringToNumber(Object.keys(cohesiveSoilType).find(key => cohesiveSoilType[key] === value));

                    return {
                        ...state,
    
                        [name]: {
                            value: val,
                            isValid: isNumber(val) ? true : false,
                        }
                    }
                } else {
                    return {
                        ...state,

                        [name]: {
                            value: (type === 'number' && value.length > 0) ? stringToNumber(value) : value,
                            isValid: true
                        }
                    }                    
                }
            }) 
        }
    },[]);

    const onSubmitDefaultSoil = (obj, i) => {

        const qc = obj.type.value === 0 
            ?
            10**((obj.densityOrPlasticity.value + 0.165)/0.709)
            :
                obj.type.value === 1
                ?
                    obj.ashFi.value === ashFiTypes[1]
                    ?
                        10**((0.242 - obj.densityOrPlasticity.value)/0.427)
                        :
                        obj.ashFi.value === ashFiTypes[2]
                            ?
                            10**((0.518 - obj.densityOrPlasticity.value)/0.653)
                            :
                                obj.ashFi.value === ashFiTypes[3]
                                ?
                                10**((0.729 - obj.densityOrPlasticity.value)/0.736)
                                :
                                ''
                :
                '';
        
        const soilWeight = obj.type.value === 0 
            ?
                19
            :
                obj.type.value === 1
                ?
                    obj.cohesiveType.value === 1
                    ?
                    21
                    :
                    obj.cohesiveType.value === 2
                    ?
                    20.5
                    :
                    obj.cohesiveType.value === 3
                    ?
                    20
                    :
                    obj.cohesiveType.value === 4
                    ?
                    19
                    :
                    obj.cohesiveType.value === 5
                    ?
                    17
                    :
                    20
                :
                '';

        setSoilTableState(state => state.map((element,index) => {
            if(i !== index){
                return element
            } else {
                return {
                    ...element,
                    cohesiveType: {
                        value: obj.type.value === 0 ? '' : obj.cohesiveType.value
                    },
                    soilType: {
                        value: obj.type.value,
                        isValid: true
                    },
                    nk: {
                        value: nkInterpolation({ qc, type: obj.cohesiveType.value })
                    },
                    y: {
                        value: soilWeight,
                        isValid: isNumber(soilWeight) ? true : false
                    },
                    qc: {
                        value: roundNumber(qc,2),
                        isValid: true
                    }
                }
            }
        }))
    };

    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    },[]);

    const defaultSoilLayer = {
        state: defaultSoilParametersState,
        soilTypes: soilTypes,
        cohesiveSoilType: cohesiveSoilType,
        ashFiTypes: ashFiTypes,
        initState: () => setDefaultSoilParametersState(defaultPNBSoilObj),
        onChange: onChangeDefaultSoilState,
        onBlur: onBlurDefaultSoilState,
        onKeyDown: onKeyDown,
        onSubmit: onSubmitDefaultSoil
    };

    return {
        defaultSoilLayer
    }
}

export default DefaultPNBSoilHelpers