import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
    Fragment,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { isNumber, roundNumber, setSoilColor } from '../../../../utils';
import { InputField, SectionTitle } from '../../../../components';
import SoilLayer from './SoilLayer';
import styles from './index.module.css';
import { intersect } from 'mathjs';
import round from '../../../../utils/roundNumber';
import { Col, Row } from 'react-bootstrap';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';

const SoilProfile = ({
    pileHeadSpot = '',
    slipPlate = '',
    isSlipPlate,
    isDeepPlate,
    pileAngle = 0,

    height = [],
    density = [],
    plasticity = [],
    profileParameters = {},
    name = [],
    qccpt = [],
    cucpt = [],
    friction = [],

    soilTable = [],

    modelWidthPx,
}) => {
    const soilProfileRef = useRef(null);
    const micropileRef = useRef(null);

    const [soilLayer, setSoilLayer] = useState([]);

    const [rightWaterHeight, setRightWaterHeight] = useState('');
    const [leftWaterHeight, setLeftWaterHeight] = useState('');

    const [profileWallLength, setProfileWallLength] = useState('');
    const [profileMicroPileLength, setProfileMicroPileLength] = useState('');
    const [profilePileHeadSpot, setProfilePileHeadSpot] = useState('');
    const [micropileAngle, setMicropileAngle] = useState(0);

    const [isPileVisible, setIsPileVisible] = useState(false);

    const [slideLine, setSlideLine] = useState({});
    const [slideLinePx, setSlideLinePx] = useState({});

    const [deepSlideLine, setDeepSlideLine] = useState([]);
    const [deepSlideLinePx, setDeepSlideLinePx] = useState([]);

    const [maxHeight, setMaxHeight] = useState('');
    const [maxWidth, setMaxWidth] = useState('');
    const [offsetHeight, setOffsetHeight] = useState('');
    const [offsetWidth, setOffsetWidth] = useState('');

    const [intersectionPoints, setIntersectionPoints] = useState({});

    const [excavationBase, setExcavationBase] = useState('');
    const [axisModelScale, setAxisModelScale] = useState([]);
    const [modelWidth, setModelWidth] = useState({
        value: 20,
        isValid: true,
    });
    const [excavationWidth, setExcavationWidth] = useState({
        value: 5,
        isValid: true,
    });

    const [excavationWidthPx, setExcavationWidthPx] = useState('');

    const [micropileOffset, setMicropileOffset] = useState({
        width: 0,
        height: 0,
    });

    useEffect(() => {
        setAxisModelScale(
            Array(10)
                .fill(modelWidth.value / 10)
                .reduce((acc, ele, idx) => [...acc, ele * (idx + 1)], []),
        );
        setMaxWidth(modelWidth.value);
        setExcavationWidth((state) => {
            return {
                ...state,
                value: roundNumber(0.4 * modelWidth.value, 1),
            };
        });
    }, [modelWidth.value]);

    useEffect(() => {
        setExcavationWidthPx((offsetWidth * excavationWidth.value) / maxWidth);
    }, [excavationWidth.value, offsetWidth, maxWidth]);

    useEffect(() => {
        if (
            isNumber(profileParameters.excavationBase.value) &&
            isNumber(maxHeight) &&
            maxHeight > 0
        ) {
            setExcavationBase(
                (offsetHeight * profileParameters.excavationBase.value) /
                    maxHeight,
            );
        } else {
            setExcavationBase('');
        }
    }, [profileParameters.excavationBase.value, maxHeight, offsetHeight]);

    useDeepCompareEffect(() => {
        const maxValueHeight = Math.max(...height);
        setMaxHeight(isNumber(maxValueHeight) ? maxValueHeight : '');

        if (soilProfileRef.current) {
            setOffsetHeight(soilProfileRef.current.offsetHeight);
            setOffsetWidth(soilProfileRef.current.offsetWidth);
        }
    }, [height, modelWidth.value, modelWidthPx]);

    useDeepCompareEffect(() => {
        const microPileP1 = [0, pileHeadSpot];
        const microPileP2 = [
            Math.cos(pileAngle * (Math.PI / 180)),
            pileHeadSpot + Math.sin(pileAngle * (Math.PI / 180)),
        ];

        setIntersectionPoints(
            Object.fromEntries(
                Object.entries(slideLine).map((ele) => [
                    ele[0],
                    ele[1]
                        .map((ele) => {
                            const findIntersection = [
                                microPileP1,
                                microPileP2,
                                [ele.downLeft, ele.bottom],
                                [ele.topLeft, ele.top],
                            ];
                            const isCorrectPoint = findIntersection
                                .map((ele) => ele.every((ele) => isNumber(ele)))
                                .every((ele) => ele === true);

                            if (isCorrectPoint) {
                                const interPoint = intersect(
                                    ...findIntersection,
                                );
                                if (Array.isArray(interPoint)) {
                                    if (
                                        interPoint[1] >= ele.top &&
                                        interPoint[1] < ele.bottom
                                    ) {
                                        return interPoint;
                                    } else {
                                        return null;
                                    }
                                } else {
                                    return null;
                                }
                            } else {
                                return [];
                            }
                        })
                        .find((ele) => Array.isArray(ele)),
                ]),
            ),
        );
    }, [slideLine, pileHeadSpot, pileAngle]);

    useDeepCompareEffect(() => {

        const reduceSoilTableToSoilAngles = () => {
            return soilTable
                .slice()
                .reverse()
                .reduce(
                    (acc, ele) => {
                        const angle = 45 + ele.friction.value / 2;
                        const deepAngle = (ele.friction.value * 2) / 3;

                        const bottomSlipPoint = isSlipPlate
                            ? isNumber(slipPlate)
                                ? slipPlate > maxHeight
                                    ? maxHeight
                                    : slipPlate
                                : profileParameters.sheetPileSpot.value >
                                  maxHeight
                                ? maxHeight
                                : profileParameters.sheetPileSpot.value
                            : profileParameters.sheetPileSpot.value > maxHeight
                            ? maxHeight
                            : profileParameters.sheetPileSpot.value;

                        if (
                            isNumber(ele.height.value) &&
                            isNumber(ele.absHeight.value) &&
                            isNumber(ele.friction.value) &&
                            isNumber(profileParameters.sheetPileSpot.value)
                        ) {
                            if (
                                ele.height.value >= bottomSlipPoint &&
                                ele.height.value - ele.absHeight.value <
                                    bottomSlipPoint
                            ) {
                                return {
                                    ...acc,
                                    slipPlate: [
                                        ...acc['slipPlate'],
                                        {
                                            downLeft: 0,
                                            topLeft:
                                                (bottomSlipPoint -
                                                    (ele.height.value -
                                                        ele.absHeight.value)) /
                                                Math.tan(
                                                    angle * (Math.PI / 180),
                                                ),
                                            bottom: bottomSlipPoint,
                                            top:
                                                ele.height.value -
                                                ele.absHeight.value,
                                        },
                                    ],
                                    deepPlate: [
                                        ...acc['deepPlate'],
                                        {
                                            downLeft: 0,
                                            topLeft:
                                                (bottomSlipPoint -
                                                    (ele.height.value -
                                                        ele.absHeight.value)) /
                                                Math.tan(
                                                    deepAngle * (Math.PI / 180),
                                                ),
                                            bottom: bottomSlipPoint,
                                            top:
                                                ele.height.value -
                                                ele.absHeight.value,
                                        },
                                    ],
                                };
                            } else if (ele.height.value < bottomSlipPoint) {
                                const isLastSlipPlate =
                                    Array.isArray(acc['slipPlate']) &&
                                    acc['slipPlate'].length > 0;
                                const isLastDeepPlate =
                                    Array.isArray(acc['deepPlate']) &&
                                    acc['deepPlate'].length > 0;
                                return {
                                    ...acc,
                                    slipPlate: [
                                        ...acc['slipPlate'],
                                        {
                                            downLeft: isLastSlipPlate
                                                ? acc['slipPlate'][
                                                      acc['slipPlate'].length -
                                                          1
                                                  ].topLeft
                                                : 0,
                                            topLeft: isLastSlipPlate
                                                ? acc['slipPlate'][
                                                      acc['slipPlate'].length -
                                                          1
                                                  ].topLeft +
                                                  ele.absHeight.value /
                                                      Math.tan(
                                                          angle *
                                                              (Math.PI / 180),
                                                      )
                                                : 0,
                                            bottom: ele.height.value,
                                            top:
                                                ele.height.value -
                                                ele.absHeight.value,
                                        },
                                    ],
                                    deepPlate: [
                                        ...acc['deepPlate'],
                                        {
                                            downLeft: isLastDeepPlate
                                                ? acc['deepPlate'][
                                                      acc['deepPlate'].length -
                                                          1
                                                  ].topLeft
                                                : 0,
                                            topLeft: isLastDeepPlate
                                                ? acc['deepPlate'][
                                                      acc['deepPlate'].length -
                                                          1
                                                  ].topLeft +
                                                  ele.absHeight.value /
                                                      Math.tan(
                                                          deepAngle *
                                                              (Math.PI / 180),
                                                      )
                                                : 0,
                                            bottom: ele.height.value,
                                            top:
                                                ele.height.value -
                                                ele.absHeight.value,
                                        },
                                    ],
                                };
                            } else {
                                return {
                                    ...acc,
                                };
                            }
                        } else {
                            return {
                                ...acc,
                            };
                        }
                    },
                    {
                        slipPlate: [],
                        deepPlate: [],
                    },
                );
        };

        setSlideLine(reduceSoilTableToSoilAngles());
    }, [
        soilTable,
        height,
        slipPlate,
        isSlipPlate,
        profileParameters.sheetPileSpot.value,
        maxHeight,
    ]);

    useDeepCompareEffect(() => {

        setDeepSlideLine(() => {
            if (
                Array.isArray(slideLine.deepPlate) &&
                Array.isArray(intersectionPoints.deepPlate)
            ) {
                return slideLine.deepPlate.reduce((acc, ele) => {
                    if (ele.top > intersectionPoints.deepPlate[1]) {
                        return [...acc, ele];
                    } else if (
                        intersectionPoints.deepPlate[1] >= ele.top &&
                        intersectionPoints.deepPlate[1] < ele.bottom
                    ) {
                        return [
                            ...acc,
                            {
                                downLeft: ele.downLeft || 0,
                                topLeft: intersectionPoints.deepPlate[0],
                                bottom: ele.bottom || 0,
                                top: intersectionPoints.deepPlate[1],
                            },
                            {
                                downLeft: intersectionPoints.deepPlate[0],
                                topLeft: intersectionPoints.deepPlate[0],
                                bottom: intersectionPoints.deepPlate[1],
                                top: 0,
                            },
                        ];
                    } else {
                        return acc;
                    }
                }, []);
            } else {
                return [];
            }
        });
    }, [intersectionPoints, slideLine]);

    useDeepCompareEffect(() => {
        if (soilProfileRef.current) {

            const isMaxHeightCorrect = maxHeight === 0 || !isNumber(maxHeight);

            setRightWaterHeight(
                isMaxHeightCorrect
                    ? ''
                    : (offsetHeight * profileParameters.rightZwg.value) /
                          maxHeight,
            );
            setLeftWaterHeight(
                isMaxHeightCorrect
                    ? ''
                    : (offsetHeight * profileParameters.leftZwg.value) /
                          maxHeight,
            );

            setSoilLayer(
                height.map((ele, i, arr) => {
                    return (
                        <SoilLayer
                            key={i}
                            backgroundColor={setSoilColor({
                                id: density[i],
                                il: plasticity[i],
                            })}
                            name={name[i]}
                            height={
                                isNumber(maxHeight) &&
                                maxHeight !== 0 &&
                                isNumber(offsetHeight)
                                    ? offsetHeight *
                                      ((i === 0 ? ele : ele - arr[i - 1]) /
                                          maxHeight)
                                    : 0
                            }
                            groundLevel={profileParameters.groundLevel.value}
                            layerHeight={ele}
                            density={density[i]}
                            friction={friction[i]}
                            plasticity={plasticity[i]}
                            qccpt={roundNumber(qccpt[i], 2)}
                            cucpt={roundNumber(cucpt[i], 2)}
                        />
                    );
                }),
            );
        }
    }, [
        height,
        density,
        plasticity,
        name,
        qccpt,
        cucpt,
        friction,
        offsetHeight,
        maxHeight,

        profileParameters.groundLevel.value,
        profileParameters.rightZwg.value,
        profileParameters.leftZwg.value,
    ]);

    useDeepCompareEffect(() => {

        const convertMetersToPx = (ele) => {
            return {
                ...ele,
                topLeft:
                    ((offsetWidth - excavationWidthPx) * ele.topLeft) /
                        maxWidth +
                    excavationWidthPx,
                downLeft:
                    ((offsetWidth - excavationWidthPx) * ele.downLeft) /
                        maxWidth +
                    excavationWidthPx,
                bottom: (offsetHeight * ele.bottom) / maxHeight,
                top: (offsetHeight * ele.top) / maxHeight,
            };
        };
        setSlideLinePx(
            Object.fromEntries(
                Object.entries(slideLine).map((ele) => [
                    ele[0],
                    ele[1].map(convertMetersToPx),
                ]),
            ),
        );
        setDeepSlideLinePx(deepSlideLine.map(convertMetersToPx));
    }, [
        slideLine,
        deepSlideLine,
        offsetWidth,
        offsetHeight,
        maxWidth,
        maxHeight,
        modelWidth.value,
        excavationWidthPx,
    ]);

    useDeepCompareEffect(() => {
        const wallBaseSpot =
            profileParameters.sheetPileSpot.value > maxHeight
                ? maxHeight
                : profileParameters.sheetPileSpot.value;

        setIsPileVisible(
            isNumber(pileHeadSpot) &&
                isNumber(wallBaseSpot) &&
                wallBaseSpot > 0 &&
                wallBaseSpot >= pileHeadSpot,
        );

        setProfilePileHeadSpot(
            maxHeight === 0 ? 0 : (offsetHeight * pileHeadSpot) / maxHeight,
        );
        setProfileWallLength(
            maxHeight === 0
                ? 0
                : (offsetHeight *
                      (wallBaseSpot > maxHeight ? maxHeight : wallBaseSpot)) /
                      maxHeight,
        );

        setMicropileAngle(() => {
            if (isDeepPlate) {
                if (
                    Array.isArray(intersectionPoints.deepPlate) &&
                    isNumber(intersectionPoints.deepPlate[0]) &&
                    isNumber(intersectionPoints.deepPlate[1])
                ) {
                    const xLength =
                        (intersectionPoints.deepPlate[0] *
                            (offsetWidth - excavationWidthPx)) /
                        maxWidth;
                    const yLength =
                        ((intersectionPoints.deepPlate[1] - pileHeadSpot) *
                            offsetHeight) /
                        maxHeight;

                    return (Math.atan(yLength / xLength) * 180) / Math.PI;
                } else {
                    return pileAngle;
                }
            } else {
                if (
                    Array.isArray(intersectionPoints.slipPlate) &&
                    isNumber(intersectionPoints.slipPlate[0]) &&
                    isNumber(intersectionPoints.slipPlate[1])
                ) {
                    const xLength =
                        (intersectionPoints.slipPlate[0] *
                            (offsetWidth - excavationWidthPx)) /
                        maxWidth;
                    const yLength =
                        ((intersectionPoints.slipPlate[1] - pileHeadSpot) *
                            offsetHeight) /
                        maxHeight;

                    return (Math.atan(yLength / xLength) * 180) / Math.PI;
                } else {
                    return pileAngle;
                }
            }
        });
    }, [
        profileParameters.sheetPileSpot.value,
        pileHeadSpot,
        offsetHeight,
        offsetWidth,
        maxHeight,
        maxWidth,
        pileAngle,
        excavationWidth.value,
        excavationWidthPx,
        intersectionPoints,
        isDeepPlate,
    ]);

    useEffect(() => {
        const maxProfileHeight =
            ((maxHeight - pileHeadSpot) * offsetHeight) / maxHeight;
        const maxProfileWidth = offsetWidth - excavationWidthPx;
        const maxAngle =
            (Math.atan(maxProfileHeight / maxProfileWidth) * 180) / Math.PI;

        const radianAngle = (micropileAngle * Math.PI) / 180;

        setProfileMicroPileLength(
            micropileAngle >= maxAngle
                ? maxProfileHeight / Math.sin(radianAngle)
                : maxProfileWidth / Math.cos(radianAngle),
        );
    }, [
        micropileAngle,
        offsetHeight,
        maxHeight,
        offsetWidth,
        excavationWidthPx,
        maxWidth,
        pileHeadSpot,
    ]);

    useEffect(() => {
        if (micropileRef.current) {
            setMicropileOffset({
                width: micropileRef.current.offsetWidth,
                height: micropileRef.current.offsetHeight / 2,
            });
        }
    }, [micropileAngle, isPileVisible]);

    const onChangeModelWidth = useCallback(
        (e) =>
            setModelWidth({
                value: e.currentTarget.value,
                isValid: e.currentTarget.checkValidity(),
            }),
        [],
    );
    const onChangeExcavationWidth = useCallback(
        (e) =>
            setExcavationWidth({
                value: e.currentTarget.value,
                isValid: e.currentTarget.checkValidity(),
            }),
        [],
    );
    return (
        <Fragment>
            <SectionTitle title="Profil gruntowy" />
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        title="Zakres modelu [m]"
                        onChange={onChangeModelWidth}
                        value={modelWidth.value}
                        isValid={modelWidth.isValid}
                        type="number"
                        placeholder={'Skala modelu'}
                        name="modelWidth"
                        min={0}
                        disable={true}
                    />
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        title="Szerokość wykopu [m]"
                        onChange={onChangeExcavationWidth}
                        value={excavationWidth.value}
                        isValid={excavationWidth.isValid}
                        type="number"
                        placeholder={'Szerokość wykopu'}
                        name="excavationWidth"
                        min={0}
                        disable={true}
                    />
                </Col>
            </Row>
            {height.length > 0 && modelWidth.value > 0 ? (
                <Fragment>
                    <div className={styles.groundLevel}>
                        <span className={styles.spot}>
                            0.00{' '}
                            {`${
                                isNumber(profileParameters.groundLevel.value)
                                    ? `(${profileParameters.groundLevel.value.toFixed(
                                          2,
                                      )} m.n.p.m)`
                                    : ''
                            }`}
                        </span>
                    </div>

                    <div className={styles.wrapper} ref={soilProfileRef}>
                        <div className={styles.horizontalAxis} style={{
                                left: `${excavationWidthPx}px`,
                                width: `${offsetWidth - excavationWidthPx}px`,
                        }}>
                            {axisModelScale.slice(0, -1).map((ele, idx) => {
                                return (
                                    <span
                                        key={idx}
                                        className={styles.axisCoord}
                                        style={{
                                            left: `${
                                                ((offsetWidth -
                                                    excavationWidthPx) /
                                                    modelWidth.value) *
                                                ele
                                            }px`,
                                        }}>
                                        <p className={styles.value}>
                                            {round(ele, 1)}
                                        </p>
                                    </span>
                                );
                            })}

                            <span className={styles.caret}>
                                {<p className={styles.axisDescription}>[m]</p>}
                            </span>
                        </div>
                        {Array.isArray(slideLinePx.slipPlate) &&
                            slideLinePx.slipPlate.every((ele) =>
                                Object.values(ele).every((ele) =>
                                    isNumber(ele),
                                ),
                            ) && (
                                <svg className={styles.slideSvg}>
                                    {slideLinePx.slipPlate.map((ele, idx) => {
                                        return (
                                            <polyline
                                                key={idx}
                                                className={styles.slidePolyline}
                                                points={`${ele.downLeft},${ele.bottom} ${ele.topLeft}, ${ele.top}`}></polyline>
                                        );
                                    })}
                                </svg>
                            )}
                        {isDeepPlate &&
                            deepSlideLinePx.every((ele) =>
                                Object.values(ele).every((ele) =>
                                    isNumber(ele),
                                ),
                            ) && (
                                <svg className={styles.slideSvg}>
                                    {deepSlideLinePx.map((ele, idx) => {
                                        return (
                                            <polyline
                                                key={idx}
                                                strokeDasharray="10,10"
                                                className={styles.slidePolyline}
                                                points={`${ele.downLeft},${ele.bottom} ${ele.topLeft}, ${ele.top}`}></polyline>
                                        );
                                    })}
                                </svg>
                            )}
                        <div className={styles.excavationWrapper}
                            style={{ 
                                width: `${excavationWidthPx}px`,
                                height: `${excavationBase}px`
                            }}> 
                            {isNumber(profileParameters.excavationBase.value) &&
                                excavationWidth.value > 0 &&
                                isNumber(profileWallLength) &&
                                profileWallLength > excavationBase && (
                                    <div
                                        className={styles.excavation}
                                        style={{
                                            top: `${leftWaterHeight}px`,
                                            height: `${excavationBase - leftWaterHeight}px`,
                                        }}>
                                        <span className={styles.spotProfile}>
                                            -
                                            {profileParameters.excavationBase.value.toFixed(
                                                2,
                                            )}{' '}
                                            {`${
                                                isNumber(
                                                    profileParameters
                                                        .groundLevel.value,
                                                )
                                                    ? `(${roundNumber(
                                                          profileParameters
                                                              .groundLevel
                                                              .value -
                                                              profileParameters
                                                                  .excavationBase
                                                                  .value,
                                                          2,
                                                      ).toFixed(2)})`
                                                    : ''
                                            }`}
                                        </span>
                                    </div>
                                )}
                            {isNumber(profileParameters.leftZwg.value) &&
                                isNumber(offsetHeight) &&
                                offsetHeight > leftWaterHeight &&
                                excavationWidth.value > 0 && (
                                    <div
                                        className={styles.zwg}
                                        style={{ top: `${leftWaterHeight}px` }}>
                                        <div className={styles.zwgSpot}></div>
                                        <span className={styles.zwgLevel}>
                                            -
                                            {profileParameters.leftZwg.value.toFixed(
                                                2,
                                            )}{' '}
                                            {`${
                                                isNumber(
                                                    profileParameters
                                                        .groundLevel.value,
                                                )
                                                    ? `(${roundNumber(
                                                          profileParameters
                                                              .groundLevel
                                                              .value -
                                                              profileParameters
                                                                  .leftZwg
                                                                  .value,
                                                          2,
                                                      ).toFixed(2)})`
                                                    : ''
                                            }`}
                                        </span>
                                    </div>
                                )}
                            {isNumber(
                                profileParameters.sheetPileSpot.value,
                            ) && (
                                <div
                                    className={styles.sheetPile}
                                    style={{
                                        left: `${excavationWidthPx}px`,
                                        height: `${profileWallLength}px`,
                                    }}>
                                    <span className={styles.wallBaseSpot}>
                                        -
                                        {profileParameters.sheetPileSpot.value.toFixed(
                                            2,
                                        )}{' '}
                                        {`${
                                            isNumber(
                                                profileParameters.groundLevel
                                                    .value,
                                            )
                                                ? `(${roundNumber(
                                                      profileParameters
                                                          .groundLevel.value -
                                                          profileParameters
                                                              .sheetPileSpot
                                                              .value,
                                                      2,
                                                  ).toFixed(2)})`
                                                : ''
                                        }`}
                                    </span>
                                </div>
                            )}
                        </div>
                        {isPileVisible && (
                            <div
                                ref={micropileRef}
                                className={styles.pileWrapper}
                                style={{
                                    top: `${
                                        profilePileHeadSpot -
                                        micropileOffset.height
                                    }px`,
                                    left: `${
                                        excavationWidthPx -
                                        micropileOffset.width
                                    }px`,
                                    transform: `rotate(${
                                        micropileAngle || 0
                                    }deg)`,
                                }}>
                                <div className={styles.microHead}>
                                    <span
                                        className={styles.pileHeadSpot}
                                        style={{
                                            transform: `rotate(-${
                                                micropileAngle || 0
                                            }deg)`,
                                        }}>
                                        -
                                        {isNumber(pileHeadSpot)
                                            ? `${pileHeadSpot.toFixed(2)} ${
                                                  isNumber(
                                                      profileParameters
                                                          .groundLevel.value,
                                                  )
                                                      ? `(${roundNumber(
                                                            profileParameters
                                                                .groundLevel
                                                                .value -
                                                                pileHeadSpot,
                                                            2,
                                                        ).toFixed(2)})`
                                                      : ''
                                              }`
                                            : ''}
                                    </span>
                                    <div className={styles.topHead}></div>
                                    <div className={styles.bottomHead}></div>
                                </div>
                                <div
                                    className={styles.pile}
                                    style={{
                                        width: `${
                                            0.9 * profileMicroPileLength
                                        }px`,
                                    }}>
                                    <div className={styles.steelRod}>
                                        <div className={styles.rod}></div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isNumber(profileParameters.rightZwg.value) &&
                            isNumber(offsetHeight) &&
                            offsetHeight > rightWaterHeight && (
                                <div
                                    className={styles.zwgRight}
                                    style={{
                                        left: `${excavationWidthPx}px`,
                                        width: `calc(100% - ${excavationWidthPx}px)`,
                                        top: `${rightWaterHeight}px`,
                                    }}>
                                    {
                                        profileParameters.rightZwg.value !== profileParameters.leftZwg.value &&
                                        <Fragment>
                                            <div className={styles.zwgRightSpot}></div>
                                            <span className={styles.zwgRightLevel}>
                                                -
                                                {profileParameters.rightZwg.value.toFixed(
                                                    2,
                                                )}{' '}
                                                {`${
                                                    isNumber(
                                                        profileParameters.groundLevel
                                                            .value,
                                                    )
                                                        ? `(${roundNumber(
                                                            profileParameters
                                                                .groundLevel.value -
                                                                profileParameters
                                                                    .rightZwg.value,
                                                            2,
                                                        ).toFixed(2)})`
                                                        : ''
                                                }`}
                                            </span>
                                        </Fragment>
                                    }

                                </div>
                            )}
                        <div>
                            {[...soilLayer]}
                        </div>
                    </div>
                </Fragment>
            ) : (
                <div className={styles.soilsProps}>
                    <FontAwesomeIcon icon={faBan} />
                    <p className={styles.p}>Uzupełnij parametry gruntu</p>
                </div>
            )}
        </Fragment>
    );
};

export default React.memo(
    SoilProfile,
    (prev, next) => stringify(prev) === stringify(next),
);