import React from 'react';
import Button from '../Button';
import { Modal } from 'react-bootstrap';
import styles from './index.module.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompress, faExpand } from '@fortawesome/free-solid-svg-icons';

const DefaultModal = ({ open, isFull, onHide, fullScreenFunc, title, children, css, cssDialog, hideButton=true, fullScreen=false ,...rest }) => {
    
    const onFullScreen = () => {
        if(fullScreenFunc){
            fullScreenFunc(state => !state)
        } 
    };

    return (
        <Modal 
            className={`${styles.modal} ${css ? css : ''}`} 
            dialogClassName={`${styles.modalDialog} ${cssDialog ? cssDialog : ''} ${isFull ? styles.fullScreenDialog : ''}`} 
            contentClassName={styles.modalContent} 
            show={open} 
            onHide={onHide} 
            {...rest}
        >
            <Modal.Header className={styles.modalHeader}>
                <Modal.Title className={styles.modalTitle}>{title}</Modal.Title>

                <div className={styles.actions}>
                    {
                        fullScreen &&
                        <div className={styles.fullScreen} title={isFull ? 'Zamknij pełny ekran' : "Pełny ekran"} onClick={onFullScreen}>
                            <FontAwesomeIcon icon={isFull ? faCompress : faExpand} />
                        </div>                        
                    }
                    {
                        
                        <button type="button" title="Zamknij" className="close" onClick={onHide}>
                            <span aria-hidden="true">×</span>
                        </button>
                    }
                </div>
                
            </Modal.Header> 
            <Modal.Body className={styles.modalBody}> 
                {children}
            </Modal.Body> 
            {
                hideButton &&
                <Modal.Footer className={styles.modalFooter}>
                    <div className={styles.buttonsWrapper}>
                        <Button altTitle="Zamknij" css={`${styles.defaultButton} default-button-style`} onClick={onHide}>Zamknij</Button>
                    </div>
                </Modal.Footer>
            }
        </Modal>  
    )
}
 
export default DefaultModal