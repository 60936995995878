import React from 'react'; 
import styles from './index.module.css';


const CheckboxInput = ({ onChange, label, title, name, value, children, css='', cssLabel='', cssText='', cssWrapper='',...rest }) => {

    return ( 
        <div className={`${styles.wrapper} ${cssWrapper}`}>
            <div className={`${styles.checkboxWrapper} ${css} custom-radio`}>
                <p>
                    <input className={styles.checkbox} type='radio' name={name} id={name} checked={value} onChange={onChange} {...rest}/>
                    <label className={`${styles.label} ${cssLabel}`}></label>
                    <label className={`${styles.label1} ${cssText}`} title={label} htmlFor={name}>{label}</label>
                </p>
            </div>
        </div>
    )
}

export default CheckboxInput