const concreteClasses = [
    {
        name: 'C12/15',
        fck: 12,
        fctm: 1.6,
        fctk: 1.1,
        ecm: 27085 //MPA
    },
    {
        name: 'C16/20',
        fck: 16,
        fctm: 1.9,
        fctk: 1.3,
        ecm: 28608
    },
    {
        name: 'C20/25',
        fck: 20,
        fctm: 2.2,
        fctk: 1.5,
        ecm: 29962
    },
    {
        name: 'C25/30',
        fck: 25,
        fctm: 2.6,
        fctk: 1.8,
        ecm: 31476
    },
    {
        name: 'C30/37',
        fck: 30,
        fctm: 2.9,
        fctk: 2,
        ecm: 32837
    },
    {
        name: 'C35/45',
        fck: 35,
        fctm: 3.2,
        fctk: 2.2,
        ecm: 34077
    },
    {
        name: 'C40/50',
        fck: 40,
        fctm: 3.5,
        fctk: 2.5,
        ecm: 35220
    },
    {
        name: 'C45/55',
        fck: 45,
        fctm: 3.8,
        fctk: 2.7,
        ecm: 36283
    },
    {
        name: 'C50/60',
        fck: 50,
        fctm: 4.1,
        fctk: 2.9,
        ecm: 37278
    },
    {
        name: 'C55/67',
        fck: 55,
        fctm: 4.2,
        fctk: 3,
        ecm: 38214
    },
    {
        name: 'C60/75',
        fck: 60,
        fctm: 4.4,
        fctk: 3.1,
        ecm: 39100
    },
    {
        name: 'C70/85',
        fck: 70,
        fctm: 4.6,
        fctk: 3.2,
        ecm: 40743
    },
    {
        name: 'C80/95',
        fck: 80,
        fctm: 4.8,
        fctk: 3.4,
        ecm: 42244
    },
    {
        name: 'C90/105',
        fck: 90,
        fctm: 5.0,
        fctk: 3.5,
        ecm: 43631
    },
    {
        name: 'C100/115',
        fck: 100,
        fctm: 5.2,
        fctk: 3.6,
        ecm: 44921
    },
];

export default concreteClasses;