import { Table } from "react-bootstrap"
import round from '../../../../../utils/roundNumber';
import styles from './index.module.css';

const PnEnCapacityDetails = ({ currentLength, pileHeadSpot, parameters=[] }) => {

    return (
        <div className={styles.wrapper}>
            <div className={styles.margins}>
                <h2>Nośność pala na wciskanie z uwzględnieniem Eurokodu 7 - pal pojedynczy</h2>                
            </div>
            <Table className={styles.table}>
                <thead>
                    <tr>
                    <th>Nr<br></br>[-]</th>
                        <th>Z<br></br>[m.p.p.t.]</th>
                        <th>&#947;<sub>Rd</sub><br></br>[m]</th>
                        <th>&#947;<sub>tr</sub><br></br>[m]</th>
                        <th>&#947;<sub>t</sub><br></br>[m]</th>
                        <th>N<sub>t,ec7</sub><br></br>[kN]</th>
                        <th>N<sub>gr,ec7</sub><br></br>[kN]</th>
                        <th>N<sub>lt</sub><br></br>[kN]</th>
                    </tr>
                </thead>
                <tbody>
                    {parameters.map((element, index) => {
                        return (
                            <tr key={index} style={{ backgroundColor: round(pileHeadSpot + currentLength,2) === round(element.z,2) ? '#ddd' : 'transparent' }}>
                                <td>{index+1}</td>
                                <td>{round(element.z,2)}</td>
                                <td>{round(element.capacityParameters.yrd,3)}</td>
                                <td>{round(element.capacityParameters.ytr,3)}</td>
                                <td>{round(element.capacityParameters.yt,2)}</td>
                                <td>{round(element.ntec7d,2)}</td>
                                <td>{round(element.ntec7,2)}</td>
                                <td>{round(element.ntec7lt,2)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>  
            <p className={styles.underline}>Oznaczenia:</p>
            <p>Z - <span className={styles.value}>rzędna zagłębienia pala poniżej poziomu terenu</span></p>
            <p>&#947;<sub>Rd</sub> - <span className={styles.value}>współczynnik modelu zależny od liczby pali podpierających fundament</span></p>
            <p>&#947;<sub>tr</sub> - <span className={styles.value}>współczynnik zależny od sztywności zwieńczenia pali</span></p>
            <p>&#947;<sub>t</sub> - <span className={styles.value}>częściowy współczynnik bezpieczeństwa</span></p>
            <p>N<sub>t,ec7</sub> - <span className={styles.value}>nośność obliczeniowa z uwzględnieniem Eurokodu 7</span></p>
            <p>N<sub>gr,ec7</sub> - <span className={styles.value}>nośność graniczna z uwzględnieniem Eurokodu 7</span></p>
            <p>N<sub>lt</sub> - <span className={styles.value}>siła do przeprowadzenia próbnego obciążenia</span></p>        
        </div>
    )
}

export default PnEnCapacityDetails