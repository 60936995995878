import React from 'react';
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import * as legoData from "./410-lego-loader.json";
import * as doneData from "./433-checked-done.json";
import styles from './index.module.css';

const loadingOptions = {
  loop: true,
  autoplay: true,
  animationData: legoData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
const doneOptions = { 
  loop: false,
  autoplay: true,
  animationData: doneData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const Loading2 = ({ loadingName, isLoaded }) => {

  return (
    <div className={styles.wrapper}> 
      <FadeIn>
          <div className={styles.flexAlign}>
              <h3 className={styles.h3}>{loadingName}</h3>
              {!isLoaded ? <Lottie options={loadingOptions} height={90} width={90}/> : <Lottie options={doneOptions} height={90} width={90}/>}
          </div>
      </FadeIn>              
    </div>   
  )
}

export default Loading2