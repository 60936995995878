
const defaultRebarClass = {
    name: 'B 500 SP',
    minD: 8,
    maxD: 40,
    fyk: 500,
    ft: 575,
    class: 'C',
    euk: 7.5
}

export default defaultRebarClass