import { useEffect, useState, useCallback } from 'react';
import { AuthFetch } from '../../../routes';
import { isAdminRole, isUserRole } from '../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

import moment from 'moment';
import { useIsMountedState } from '../../../hooks';

const PaymentsHistoryService = ({
    fetch,
    setAlert,
    role,
    initInvoice={},
}) => {
    const [paymentsList, setPaymentsList] = useState([]); 

    const [startDate, setStartDate] = useState(
        moment().subtract(6, 'months').toDate(),
    );
    const [endDate, setEndDate] = useState(moment().toDate());
    const [saveFormData, setSaveFormData] = useState(false);

    const [invoice, setInvoice] = useState({
        firstName: {
            value: '',
            isValid: null,
        },
        lastName: {
            value: '',
            isValid: null,
        },
        companyName: {
            value: '',
            isValid: null,
        },
        nip: {
            value: '',
            isValid: null,
        },
        street: {
            value: '',
            isValid: null,
        },
        placeNumber: {
            value: '',
            isValid: null,
        },
        postcode: {
            value: '',
            isValid: null,
        },
        city: {
            value: '',
            isValid: null,
        },

        email: {
            value: '',
            isValid: null,
        },
    });
    const [invoiceType, setInvoiceType] = useState(1);
    const isMountedState = useIsMountedState();
    const [invoiceData, setInvoiceData] = useState(false)

    useEffect(() => {
        setSaveFormData(initInvoice.rememberData);
        setInvoice({
            firstName: {
                value: initInvoice.firstName || '',
                isValid: initInvoice.firstName ? true : null,
            },
            lastName: {
                value: initInvoice.lastName || '',
                isValid: initInvoice.lastName ? true : null,
            },
            companyName: {
                value: initInvoice.companyName || '',
                isValid: initInvoice.companyName ? true : null,
            },
            nip: {
                value: initInvoice.nip || '',
                isValid: initInvoice.nip ? true : null,
            },
            street: {
                value: initInvoice.street || '',
                isValid: initInvoice.street ? true : null,
            },
            placeNumber: {
                value: initInvoice.placeNumber || '',
                isValid: initInvoice.placeNumber ? true : null,
            },
            postcode: {
                value: initInvoice.postcode || '',
                isValid: initInvoice.postcode ? true : null,
            },
            city: {
                value: initInvoice.city || '',
                isValid: initInvoice.city ? true : null,
            },

            email: {
                value: initInvoice.email || '',
                isValid: initInvoice.email ? true : null,
            },
        });
    }, [
        initInvoice.firstName,
        initInvoice.lastName,
        initInvoice.companyName,
        initInvoice.nip,
        initInvoice.street,
        initInvoice.placeNumber,
        initInvoice.postcode,
        initInvoice.city,
        initInvoice.email,
        initInvoice.rememberData,
    ]);

    useDeepCompareEffect(() => {
        if(isMountedState.current){
            if (((isUserRole(role)) || isAdminRole(role)) && startDate && endDate) {
                AuthFetch({
                    url: `${fetch.getPaymentsHistory.url}/${startDate}/${endDate}`,
                    method: fetch.getPaymentsHistory.method,
                })
                .then((response) => {
                    if(isMountedState.current){
                        setInvoiceData(false)  ;
                        if (
                            Array.isArray(response.responseData) &&
                            response.responseData.length > 0
                        ) {
                            setPaymentsList(response.responseData);
                        } else {
                            setPaymentsList([]);
                        }                            
                    }

                })
                .catch(() => {
                    if(isMountedState.current){
                        setPaymentsList([]);
                        setAlert({
                            error: true,
                            message: 'Błąd podczas wczytywania listy płatności',
                        });                            
                    }

                });
            }            
        }
    }, [
        fetch.getPaymentsHistory.url,
        fetch.getPaymentsHistory.method,
        role,
        startDate,
        endDate,
        setAlert,
        isMountedState,
        invoiceData
    ]);

    const onChangeDateRange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const onClearDateInput = () => {
        setStartDate(moment().subtract(6, 'months').toDate());
        setEndDate(moment().toDate());
    };

    const onChangeInvoiceType = (val) => setInvoiceType(val);

    const onBlurInvoiceState = useCallback((e) => {
        const { name } = e.currentTarget;
        const valid = e.currentTarget.checkValidity();

        setInvoice((state) => {
            return {
                ...state,
                [name]: {
                    ...state[name],
                    isValid: valid,
                },
            };
        });
    }, []);

    const onChangeInvoiceState = useCallback((e) => {
        const input = e.currentTarget.closest('input');

        if (input) {
            const { name, value } = input;

            setInvoice((state) => {
                return {
                    ...state,

                    [name]: {
                        value: value,
                        isValid: true,
                    },
                };
            });
        }
    }, []);

    const onChangeDataCheckbox = (e) => {
        const target = e.currentTarget;
        if (target) {
            setSaveFormData((state) => !state);
        }
    };

    const payments = {
        onChangeInvoiceType,
        onChangeDateRange,
        onClearDateInput,
        onChangeInvoiceState,
        onBlurInvoiceState,
        onChangeDataCheckbox,

        updateInvoiceState: setInvoice,

        state: {
            endDate,
            startDate,
            paymentsList,
            invoiceType,
            saveFormData,
            invoiceState: invoice,
        },
    };

    return {
        payments,
    };
};

export default PaymentsHistoryService;
