import React, { Fragment, useMemo, useState } from 'react';
import { withPagination } from '../../../../../hoc';
import {
    ItemsPerPage,
    Pagination,
    SectionTitle,
    Table,
} from '../../../../../components';
import styles from './index.module.css';
import useDeepCompareEffect from 'use-deep-compare-effect';

const KxRgrDetails = ({
    dataToPaginate,
    allCollection = [],
    pagination,
    paginationHelpers,
}) => {
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [csvData, setCsvData] = useState([]);

    useDeepCompareEffect(() => {
        setCsvData(
            allCollection.map((ele) =>
                Object.fromEntries(
                    Object.entries(ele).map(([key, value]) => [
                        [key],
                        value.toString().replace('.', ','),
                    ]),
                ),
            ),
        );

        setCsvHeaders([
            {
                label: 'Nazwa',
                key: 'name',
            },
            {
                label: 'Z [m.p.p.t.]',
                key: 'z',
            },
            {
                label: 'H [m]',
                key: 'h',
            },
            {
                label: 'kx [kN/m]',
                key: 'kx',
            },
            {
                label: 'Rgr [kN]',
                key: 'rgr',
            },
        ]);
    }, [allCollection]);

    return (
        <Fragment>
            <SectionTitle
                title={useMemo(
                    () => (
                        <Fragment>
                            Wartości K<sub>x</sub> oraz R<sub>gr</sub> w węzłach
                            na długości pala - wartości obliczeniowe
                        </Fragment>
                    ),
                    [],
                )}
            />
            <ItemsPerPage
                onDisplayItemsNumber={paginationHelpers.displayItemsNumber}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                display={[15, 30, paginationHelpers.allItemsLength]}
            />
            <Table
                csvData={csvData}
                csvFilename={'KxRgrDetails'}
                csvHeaders={csvHeaders}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th>
                            Nazwa gruntu<br></br>[-]
                        </th>
                        <th title="Rzędna">
                            Z<br></br>[m.p.p.t]
                        </th>
                        <th title="Grubość warstwy">
                            H<br></br>[m]
                        </th>
                        <th title="Sztywność pozioma gruntu">
                            k<sub>x</sub>
                            <br></br>[kN/m]
                        </th>
                        <th title="Reakcja graniczna gruntu">
                            R<sub>gr</sub>
                            <br></br>[kN]
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {dataToPaginate.map((element, index) => {
                        return (
                            <tr className={styles.tr} key={index}>
                                <td>
                                    {paginationHelpers.countingItemsPerPage +
                                        index +
                                        1}
                                </td>
                                <td>{element.name}</td>
                                <td>{element.z}</td>
                                <td>{element.h}</td>
                                <td>{element.kx}</td>
                                <td>{element.rgr}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <Pagination
                currentPage={pagination.currentPage}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                nextPage={paginationHelpers.nextPage}
            />
        </Fragment>
    );
};

export default withPagination({
    component: KxRgrDetails,
    returnAllCollection: true,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 25,
    },
});
