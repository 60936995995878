import React, { createContext } from 'react';
import { 
    LoadingService, 
    GlobalAlertService,
    ProjectTitleService,
    FileNameToSave,
    SaveProjectService,
    EditProjectService,
    LoadAndRemoveProjectService, 
    CalculationInputService,
    SubmitCalculationsService,
    ResultsService,
    CheckCapacityService,
    CrossSectionCapacity,
    PrintResultsService,
    NewProjectService
} from '../../services/Reinforcement'; 
 
const reinforcementContext = createContext({});

const ReinforcementProvider = ({ children }) => {
    const { globalAlerts }          = GlobalAlertService();
    const { globalLoading }         = LoadingService();
    const { fileNameToSave }        = FileNameToSave(); 
    const soilProfileFileNameToSave = FileNameToSave();
    const { projectTitle }          = ProjectTitleService(); 
    
    
    const { checkCapacity } = CheckCapacityService();

    const { results } = ResultsService({
        updateResultsState: checkCapacity.updateResultsState,
    }); 
    const { calculationInput } = CalculationInputService({
        updateResultsState: results.updateState,
    });
    const { sectionCapacity } = CrossSectionCapacity({
        calculation: results.state.calculation,
        initValues: results.state.initModelValues
    });
    const { submitCalculation } = SubmitCalculationsService({
        calculationInput: { state: calculationInput.state, updateState: calculationInput.updateState },
        calculationInputIsLoaded: calculationInput.isLoaded,
        results: {
            onCheckShearAs: checkCapacity.onCheckShearAs,
            onCheckSpaceAs1: checkCapacity.onCheckSpaceAs1,
            onCheckSpaceAs2: checkCapacity.onCheckSpaceAs2,
            updateCheckCapacity: checkCapacity.updateState,
            updateState: results.updateState,
        },
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading 
    });
    const { loadAndRemoveProject } = LoadAndRemoveProjectService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        urlOnLoad: '/api/projects/list/names',
        urlOnRemove: '/api/reinforcement/project/',
        urlOnLoadCalculations: '/api/reinforcement/projects'
    });
    const { printResults } = PrintResultsService({
        projectTitle: projectTitle.state, 
        calculationInput: calculationInput.state,
        checkCapacity: checkCapacity.state, 
        sectionCapacity: sectionCapacity.state,
        results: {
            ...results.state,
        }
    });
    const { editProject } = EditProjectService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,

        loadTitle: projectTitle.loadProject,
        loadCalculationInput: calculationInput.loadProject,
        loadCheckCapacity: checkCapacity.loadProject,

        onLoadFileName: fileNameToSave.onLoad,
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad, 

        onSetMainProjectName: loadAndRemoveProject.onSetMainProjectName,

        onSubmitCalc: submitCalculation.onSubmit,
        onCalculationInputToSend: submitCalculation.onCalculationInputToSend,
        onIsValidState: submitCalculation.onIsValidState,
        initResults: results.initState,
        onSubmitState: submitCalculation.state,

        projectId: loadAndRemoveProject.state.currentProjectId,
    });
    const { saveProject } = SaveProjectService({
        fileNameToSave: fileNameToSave.fileName,
        onLoadProjectName: fileNameToSave.onLoad, 
        projectTitle: projectTitle.state,

        currentProjectId: editProject.currentProjectId,
        currentUserEmail: loadAndRemoveProject.state.currentUser,
        loadProjectState: editProject.loadProjectState,
         
        model: { 
            calculationInput: calculationInput.state, 
            checkCapacity: checkCapacity.state, 
        },
        
        results: { 
            calculation: Array.isArray( results.state.calculation) ? results.state.calculation.map(ele => {
                return {
                    ...(ele.defaultResults.isCrackCalc && results.state.initModelValues.memberType !== 'Słup') && {
                        crack: ele.crack,
                        sgnLbd: ele.sgnLbd,
                        sgnResult: ele.sgnResult,
                    },
                    defaultResults: ele.defaultResults,
                    lbd: ele.lbd,
                    result: ele.result, 
                    secondOrderEffect: ele.secondOrderEffect,
                    shearResult: ele.shearForce
                }
            }) : [],
            shape: results.state.shape,
            rebar: results.state.rebar,
            rebarAs1: results.state.rebarAs1,
            rebarAs2: results.state.rebarAs2,
            initModelValues: results.state.initModelValues,

            isResultsActive: results.isResultsActive,
            isResultsActual: results.state.isResultsActual,
        },
        
        fetch: {
            save: {
                url: '/api/reinforcement/saveProject',
                method: 'POST'
            }, 
            overwrite: {
                url: '/api/reinforcement/saveProject',
                method: 'PUT'
            },
            create: {
                url: '/api/projects/create',
                method: 'POST'
            },
            projectList: {
                url: '/api/projects/list',
                method: 'GET'
            }
        },
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        onChangeState: loadAndRemoveProject.onStateChange,

        saveProjectToLocalStorage: (str) => localStorage.setItem('reinforcement', str)
    });
    const { newProject } = NewProjectService({
        onLoadProjectName: fileNameToSave.onLoad, 
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad,
        initProject: [ 
            () => localStorage.removeItem('reinforcement'),
            calculationInput.initState, 
            globalAlerts.initState,
            globalLoading.initState,
            projectTitle.initState,
            checkCapacity.initState,
            sectionCapacity.initState,
            results.initState,
            printResults.initState
        ]
    });
    
    return (
        <reinforcementContext.Provider 
            value={{ 
                results,
                projectTitle,
                globalAlerts,
                globalLoading,
                fileNameToSave,
                saveProject,
                editProject,
                loadAndRemoveProject,
                calculationInput,
                submitCalculation,
                checkCapacity,
                printResults,
                sectionCapacity,
                newProject
            }}
        >
            {children}
        </reinforcementContext.Provider>
    )
}

export { reinforcementContext }
export default ReinforcementProvider