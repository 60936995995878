import React from 'react';
import { Alert } from 'react-bootstrap';
import styles from './index.module.css';

const AlertMessage = ({ error, message, updateState, index }) => {

    const onClose = () => updateState(state => ({ ...state, alerts: state.alerts.filter((e,i) => i !== index) }));
    
    return (
        <div className={styles.container}>
            {
                <Alert className={styles.alert} variant={error ? 'danger' : 'success'}>
                    {message}
                    <div className={styles.close} onClick={onClose}>&#x2715;</div>
                </Alert>                
            }
        </div>
    )
}

export default AlertMessage; 