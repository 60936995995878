import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import { findClosest, isNumber, setSoilColor } from '../../../../../../utils';
import { Button, SectionTitle, InputField } from '../../../../../../components';
import SoilLayer from './SoilLayer';
import styles from './index.module.css';
import round from '../../../../../../utils/roundNumber';
import { Col, Row } from 'react-bootstrap';
import CorrectDepth from '../../CorrectDepth';
import stringify from 'fast-json-stable-stringify';
import { useWindowSize } from '../../../../../../hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';

const SoilProfile = ({
    soilProfile,
    results = [],
    steelRod = {},
    calculationParameters = {},
    rodModule,
    onChangeCurrentMicropileLength,
    onChangeRodModule,
    onChangeProdMicropileLength,

    initCurrentMicropileLength = {},

    openSidebar,
}) => {
    const soilProfileRef = useRef(null);
    const resultsRef = useRef(null);
    const pileRef = useRef(null);
    const [isMicropileVisible, setIsMicropileVisible] = useState(false);
    const [soilLayer, setSoilLayer] = useState([]);
    const [profileMicropileLength, setProfileMicropileLength] = useState('');
    const [profileMicropileHeadSpot, setProfileMicropileHeadSpot] =
        useState('');
    const [micropileBaseSpot, setMicropileBaseSpot] = useState('');
    const [resultsWidth, setResultsWidth] = useState(0);
    const [resultsHeight, setResultsHeight] = useState(0);
    const [maxHeight, setMaxHeight] = useState(0);
    const [maxNt, setMaxNt] = useState('');
    const [axisNtScale, setAxisNtScale] = useState([]);
    const [currentNt, setCurrentNt] = useState('');
    const [currentMicropileLength, setCurrentMicropileLength] = useState('');
    const [currentCapacity, setCurrentCapacity] = useState({});

    const [activeCapacity, setActiveCapacity] = useState({
        pileCapacity: true,
        pullCapacity: false,
    });
    const [micropileAngle, setMicropileAngle] = useState(0);
    const [pileRefWidth, setPileRefWidth] = useState(0);
    const [micropileHeadSpot, setMicropileHeadSpot] = useState(0);
    const [micropileLengthObject, setMicropileLengthObject] = useState({
        micropileCompressionLength:
            initCurrentMicropileLength.micropileCompressionLength || '',
        micropilePullLength:
            initCurrentMicropileLength.micropilePullLength || '',
    });
    const [prodLengthValues, setProdLengthValues] = useState({
        bondLength: '',
        totalLength: '',
    });
    const windowSize = useWindowSize();
    const [totalMicropileRodLength, setTotalMicropileRodLength] = useState(0);

    useDeepCompareEffect(() => {
        setMicropileLengthObject({
            micropileCompressionLength:
                initCurrentMicropileLength.micropileCompressionLength || '',
            micropilePullLength:
                initCurrentMicropileLength.micropilePullLength || '',
        });
    }, [initCurrentMicropileLength]);
    
    useEffect(() => {
        if (
            isNumber(currentMicropileLength) &&
            isNumber(rodModule.value) &&
            isNumber(calculationParameters.headLength)
        ) {
            const absMicropileLength =
                (currentCapacity.z - calculationParameters.micropileHeadSpot) /
                Math.cos(
                    (Math.abs(calculationParameters.micropileAngle) * Math.PI) /
                        180,
                );
            const freeLength =
                (calculationParameters.micropileHeadSpot < 0
                    ? Math.abs(calculationParameters.micropileHeadSpot)
                    : 0) /
                Math.cos(
                    (Math.abs(calculationParameters.micropileAngle) * Math.PI) /
                        180,
                );

            const tot =
                Math.ceil(
                    (absMicropileLength + calculationParameters.headLength) /
                        rodModule.value,
                ) * rodModule.value;
            const bond = tot - (freeLength + calculationParameters.headLength);

            setProdLengthValues((state) => ({
                ...state,
                bondLength: round(bond, 2),
                totalLength: round(tot, 2),
            }));
            setTotalMicropileRodLength(
                absMicropileLength + calculationParameters.headLength,
            );
        }
    }, [
        rodModule.value,
        currentMicropileLength,
        micropileHeadSpot,
        currentCapacity.z,
        calculationParameters.micropileHeadSpot,
        calculationParameters.headLength,
        calculationParameters.micropileAngle,
    ]);

    useEffect(() => {
        if (pileRef.current) {
            setPileRefWidth(pileRef.current.offsetWidth - 20);
        }
        if (resultsRef.current) {
            setResultsWidth(resultsRef.current.offsetWidth);
        }
    }, [windowSize.width, currentNt, openSidebar, activeCapacity.pullCapacity, activeCapacity.pileCapacity, isMicropileVisible]);

    useEffect(() => {
        setMicropileHeadSpot(
            calculationParameters.micropileHeadSpot < 0
                ? 0
                : calculationParameters.micropileHeadSpot,
        );
    }, [calculationParameters.micropileHeadSpot]);

    useDeepCompareEffect(() => {
        setMaxHeight(Math.max(...soilProfile.map((ele) => ele.height)) + 1);
    }, [soilProfile]);

    useDeepCompareEffect(() => {
        if (soilProfileRef.current) {
            const offsetHeight = soilProfileRef.current.offsetHeight;

            const layers = soilProfile.map((ele, i, arr) => {
                const absHeight = i === 0 ? ele.height : ele.height - arr[i - 1].height;
                return (
                    <SoilLayer
                        key={i}
                        backgroundColor={setSoilColor({
                            id: ele.density,
                            il: ele.plasticity,
                        })}
                        groundLevel={calculationParameters.groundLevel}
                        name={ele.name}
                        height={
                            maxHeight === 0
                                ? 0
                                : (offsetHeight *
                                      (i === arr.length - 1
                                          ? absHeight + 1
                                          : absHeight)) /
                                  maxHeight
                        }
                        layerHeight={ele.height}
                    />
                );
            });
            setSoilLayer(layers);
            setResultsHeight(offsetHeight);
        }
    }, [
        maxHeight,
        calculationParameters.groundLevel,
        soilProfile,
        soilProfileRef.current,
    ]);

    useDeepCompareEffect(() => {
        onChangeCurrentMicropileLength(micropileLengthObject);
    }, [micropileLengthObject, onChangeCurrentMicropileLength]);

    useDeepCompareEffect(() => {
        const nt = activeCapacity.pullCapacity
            ? Math.max(...results.map((ele) => ele.ntw))
            : Math.max(...results.map((ele) => ele.nt));
        const maxAxisNt = round(nt - (nt % 5), 0);

        const initPileLength = activeCapacity.pullCapacity
            ? micropileLengthObject.micropilePullLength
            : micropileLengthObject.micropileCompressionLength;

        const initNt =
            results.find(
                (ele) => ele.z === initPileLength + micropileHeadSpot,
            ) || results[0];

        setIsMicropileVisible(
            isNumber(micropileHeadSpot) && maxHeight - micropileHeadSpot > 0,
        );
        setCurrentNt(activeCapacity.pullCapacity ? initNt.ntw : initNt.nt);
        setCurrentCapacity(initNt);

        setAxisNtScale(
            Array(5)
                .fill(maxAxisNt / 5)
                .reduce((acc, ele, idx) => [...acc, ele * (idx + 1)], []),
        );
        setMaxNt(nt);

        setMicropileBaseSpot(initPileLength + micropileHeadSpot);

        if (soilProfileRef.current) {
            const maxProfileWidth = pileRefWidth / 2;
            const angleSign =
                calculationParameters.micropileAngle /
                Math.abs(calculationParameters.micropileAngle);
            const maxAngle =
                (Math.atan(
                    maxProfileWidth /
                        ((resultsHeight * (maxHeight - micropileHeadSpot)) /
                            maxHeight),
                ) *
                    180) /
                Math.PI;
            const radianAngle =
                Math.abs(calculationParameters.micropileAngle) > maxAngle
                    ? (maxAngle * Math.PI) / 180
                    : (calculationParameters.micropileAngle * Math.PI) / 180;
            const maxMicropileLength = initPileLength / Math.cos(radianAngle);

            setProfileMicropileHeadSpot(
                maxHeight === 0
                    ? 0
                    : (resultsHeight * micropileHeadSpot) / maxHeight,
            );
            setMicropileAngle((angleSign * radianAngle * 180) / Math.PI);
            setProfileMicropileLength(
                maxHeight === 0
                    ? 0
                    : (resultsHeight * maxMicropileLength) / maxHeight,
            );

            setCurrentMicropileLength(
                initPileLength /
                    Math.cos(
                        (Math.abs(calculationParameters.micropileAngle) *
                            Math.PI) /
                            180,
                    ),
            );
        }
    }, [
        maxHeight,
        resultsHeight,
        pileRefWidth,
        micropileHeadSpot,
        calculationParameters.micropileAngle,
        activeCapacity.pullCapacity,
        activeCapacity.pileCapacity,
        micropileLengthObject.micropilePullLength,
        micropileLengthObject.micropileCompressionLength,
        results,
    ]);

    const onChangePileLength = (e) => {
        e.stopPropagation();

        const profileSpots = results.map((ele) => ele.z);
        const clientRect = e.currentTarget.getClientRects()[0];
        const baseSpot =
            ((clientRect.height - clientRect.bottom + e.clientY) /
                clientRect.height) *
            maxHeight;
        const closestSpot = findClosest(profileSpots, baseSpot);
        const closestResult = results.find((ele) => ele.z === closestSpot);
        const existMicropileAngleRadian =
            (calculationParameters.micropileAngle * Math.PI) / 180;
        const micropileLengthPx =
            (clientRect.height * (closestSpot - micropileHeadSpot)) / maxHeight;

        setCurrentNt((state) =>
            closestResult
                ? activeCapacity.pullCapacity
                    ? closestResult.ntw
                    : closestResult.nt
                : state,
        );
        setCurrentCapacity(closestResult);

        setMicropileBaseSpot((state) =>
            closestSpot > micropileHeadSpot ? closestSpot : state,
        );
        setCurrentMicropileLength((state) =>
            closestSpot > micropileHeadSpot
                ? (closestSpot - micropileHeadSpot) /
                  Math.cos(existMicropileAngleRadian)
                : state,
        );
        setProfileMicropileLength((state) =>
            closestSpot > micropileHeadSpot ? micropileLengthPx : state,
        );

        setMicropileLengthObject((state) => ({
            micropileCompressionLength: !activeCapacity.pullCapacity
                ? closestSpot > micropileHeadSpot
                    ? closestSpot - micropileHeadSpot
                    : state.micropileCompressionLength
                : state.micropileCompressionLength,

            micropilePullLength: activeCapacity.pullCapacity
                ? closestSpot > micropileHeadSpot
                    ? closestSpot - micropileHeadSpot
                    : state.micropilePullLength
                : state.micropilePullLength,
        }));
        onChangeProdMicropileLength({
            ...(!activeCapacity.pullCapacity && {
                currentCapacityNt: closestResult,
            }),
            ...(activeCapacity.pullCapacity && {
                currentCapacityNtw: closestResult,
            }),
        });
    };

    const onChangeCapacity = (e) => {
        const { name } = e.currentTarget;

        setActiveCapacity((state) => {
            return {
                ...state,
                pileCapacity: false,
                pullCapacity: false,
                [name]: true,
            };
        });
    };

    return (
        <Fragment>
            <Row>
                <Col xs={12} sm={7}>
                    <SectionTitle
                        className={styles.title}
                        title="Rozkład obliczeniowej nośności pala"
                    />
                    <Row>
                        <Col xs={12}>
                            <Button
                                onClick={onChangeCapacity}
                                css={`project-navigation ${styles.buttonMarginLeft}`}
                                name="pileCapacity"
                                altTitle="Nośność na wciskanie"
                                title="Wciskanie"
                                active={activeCapacity.pileCapacity}
                            />
                            <Button
                                onClick={onChangeCapacity}
                                css={`project-navigation ${styles.buttonMarginRight}`}
                                name="pullCapacity"
                                altTitle="Nośność na wyciąganie"
                                title="Wyciąganie"
                                active={activeCapacity.pullCapacity}
                            />
                        </Col>
                    </Row>
                    <div
                        className={styles.wrapper}
                        ref={soilProfileRef}
                        onClick={onChangePileLength}>
                        {isMicropileVisible && (
                            <Fragment>
                                <div
                                    className={styles.pileWrapper}
                                    style={{
                                        height: `${profileMicropileLength}px`,
                                        top: `${profileMicropileHeadSpot}px`,
                                    }}>
                                    <div className={styles.resultsWrapper}>
                                        <div
                                            className={styles.pileSchema}
                                            ref={pileRef}>
                                            <div
                                                className={styles.pile}
                                                style={{
                                                    transform: `rotate(${
                                                        -micropileAngle || 0
                                                    }deg)`,
                                                }}>
                                                {calculationParameters.isSteelRod && (
                                                    <div
                                                        className={
                                                            styles.steelRod
                                                        }>
                                                        <div
                                                            className={
                                                                styles.rod
                                                            }></div>
                                                    </div>
                                                )}
                                                <div
                                                    className={
                                                        styles.pileLength
                                                    }>
                                                    <p>
                                                        L ={' '}
                                                        {round(
                                                            currentMicropileLength,
                                                            2,
                                                        )}{' '}
                                                        m
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={styles.spotWrapper}>
                                                <span
                                                    className={styles.pileSpot}>
                                                    {isNumber(micropileHeadSpot)
                                                        ? (-micropileHeadSpot).toFixed(
                                                              2,
                                                          )
                                                        : ''}
                                                </span>
                                                <span
                                                    className={styles.pileSpot}>
                                                    {isNumber(micropileBaseSpot)
                                                        ? (-micropileBaseSpot).toFixed(
                                                              2,
                                                          )
                                                        : ''}
                                                </span>
                                            </div>
                                        </div>

                                        <div
                                            className={styles.results}
                                            ref={resultsRef}>
                                            <div
                                                className={
                                                    styles.horizontalAxis
                                                }>
                                                {axisNtScale.map((ele, idx) => {
                                                    return (
                                                        <span
                                                            key={idx}
                                                            className={
                                                                styles.axisCoord
                                                            }
                                                            style={{
                                                                left: `${
                                                                    (resultsWidth /
                                                                        maxNt) *
                                                                    ele
                                                                }px`,
                                                            }}>
                                                            <p
                                                                className={
                                                                    styles.value
                                                                }>
                                                                {ele}
                                                            </p>
                                                        </span>
                                                    );
                                                })}

                                                <span className={styles.caret}>
                                                    {activeCapacity.pullCapacity ? (
                                                        <p
                                                            className={
                                                                styles.axisDescription
                                                            }>
                                                            R<sub>t,d</sub>[kN]
                                                        </p>
                                                    ) : (
                                                        <p
                                                            className={
                                                                styles.axisDescription
                                                            }>
                                                            R<sub>c,d</sub>[kN]
                                                        </p>
                                                    )}
                                                </span>
                                            </div>
                                            <div
                                                className={styles.verticalAxis}>
                                                <span
                                                    className={
                                                        styles.verticalCaret
                                                    }>
                                                    <p
                                                        className={
                                                            styles.axisVerticalDescription
                                                        }>
                                                        Z[m]
                                                    </p>
                                                </span>
                                            </div>
                                            <div
                                                className={
                                                    styles.currentHorizontalValue
                                                }
                                                style={{
                                                    top: `${profileMicropileLength}px`,
                                                    width: `${
                                                        (resultsWidth *
                                                            currentNt) /
                                                        maxNt
                                                    }px`,
                                                }}></div>
                                            <div
                                                className={
                                                    styles.currentVerticalValue
                                                }
                                                style={{
                                                    height: `${profileMicropileLength}px`,
                                                    left: `${
                                                        (resultsWidth *
                                                            currentNt) /
                                                        maxNt
                                                    }px`,
                                                }}></div>
                                            {results.map((ele, i) => {
                                                const buckling = Array.isArray(
                                                    ele.buckling,
                                                );
                                                const veryfiedNt = ele.veryfied;
                                                const veryfiedNtw =
                                                    ele.veryfiedNtw;
                                                const axisLoadCorrect =
                                                    isNumber(
                                                        veryfiedNt.axisLoad,
                                                    )
                                                        ? veryfiedNt.axisLoad >=
                                                          1
                                                            ? true
                                                            : false
                                                        : false;
                                                const steelRodCorrect =
                                                    isNumber(
                                                        veryfiedNt.steelRodLoad,
                                                    )
                                                        ? veryfiedNt.steelRodLoad >
                                                          1
                                                            ? false
                                                            : true
                                                        : true;

                                                const axisLoadCorrectNtw =
                                                    isNumber(
                                                        veryfiedNtw.axisLoad,
                                                    )
                                                        ? veryfiedNtw.axisLoad >=
                                                          1
                                                            ? true
                                                            : false
                                                        : false;
                                                const steelRodCorrectNtw =
                                                    isNumber(
                                                        veryfiedNtw.steelRodLoad,
                                                    )
                                                        ? veryfiedNtw.steelRodLoad >
                                                          1
                                                            ? false
                                                            : true
                                                        : true;

                                                const bucklingLayers = buckling
                                                    ? ele.buckling.filter(
                                                          (prop) =>
                                                              prop.buckling,
                                                      )
                                                    : [];

                                                const isDefRatio =
                                                    bucklingLayers.length > 0
                                                        ? bucklingLayers.every(
                                                              (ele) => {
                                                                  if (
                                                                      ele
                                                                          .details
                                                                          .freeSpaceRod
                                                                  ) {
                                                                      return true;
                                                                  } else {
                                                                      return ele
                                                                          .details
                                                                          .isDefRatioCorrect;
                                                                  }
                                                              },
                                                          )
                                                        : true;

                                                const bucklingCorrect =
                                                    bucklingLayers.length > 0
                                                        ? bucklingLayers.every(
                                                              (ele) => {
                                                                  return (
                                                                      ele
                                                                          .details
                                                                          .isAxialForceCorrect &&
                                                                      ele
                                                                          .details
                                                                          .isBuckCorrect &&
                                                                      isDefRatio
                                                                  );
                                                              },
                                                          )
                                                        : true;

                                                const correctLength =
                                                    axisLoadCorrect &&
                                                    bucklingCorrect;
                                                const correctLengthNtw =
                                                    axisLoadCorrectNtw;

                                                return activeCapacity.pullCapacity ? (
                                                    <div
                                                        key={i}
                                                        className={`${
                                                            styles.capacityPoint
                                                        } ${
                                                            correctLengthNtw
                                                                ? steelRodCorrectNtw
                                                                    ? styles.validDepth
                                                                    : styles.warningDepth
                                                                : styles.wrongDepth
                                                        }`}
                                                        style={{
                                                            top: `${
                                                                (resultsHeight *
                                                                    (ele.z -
                                                                        micropileHeadSpot)) /
                                                                maxHeight
                                                            }px`,
                                                            left: `${
                                                                (resultsWidth *
                                                                    ele.ntw) /
                                                                maxNt
                                                            }px`,
                                                        }}>
                                                        <FontAwesomeIcon
                                                            icon={
                                                                correctLengthNtw
                                                                    ? faCheckCircle
                                                                    : faTimesCircle
                                                            }
                                                        />
                                                    </div>
                                                ) : (
                                                    <div
                                                        key={i}
                                                        className={`${
                                                            styles.capacityPoint
                                                        } ${
                                                            correctLength
                                                                ? steelRodCorrect
                                                                    ? styles.validDepth
                                                                    : styles.warningDepth
                                                                : styles.wrongDepth
                                                        }`}
                                                        style={{
                                                            top: `${
                                                                (resultsHeight *
                                                                    (ele.z -
                                                                        micropileHeadSpot)) /
                                                                maxHeight
                                                            }px`,
                                                            left: `${
                                                                (resultsWidth *
                                                                    ele.nt) /
                                                                maxNt
                                                            }px`,
                                                        }}>
                                                        <FontAwesomeIcon
                                                            icon={
                                                                correctLength
                                                                    ? faCheckCircle
                                                                    : faTimesCircle
                                                            }
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                        <div>
                            {[...soilLayer]}
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={5}>
                    <div className={styles.resultsOverview}>
                        <SectionTitle
                            className={styles.title}
                            title="Obliczeniowa nośność mikropala"
                        />
                        <div className={styles.sticky}>
                            <p>
                                Średnica wiercenia [d]-{' '}
                                <b>
                                    {isNumber(calculationParameters.diameter)
                                        ? round(
                                              calculationParameters.diameter,
                                              2,
                                          )
                                        : ''}{' '}
                                    mm
                                </b>
                            </p>
                            <p className={styles.underline}>
                                Długość mikropala w gruncie [L]-{' '}
                                <b>
                                    {isNumber(currentMicropileLength)
                                        ? round(currentMicropileLength, 2)
                                        : ''}{' '}
                                    m
                                </b>
                            </p>
                            <p>
                                Objętość zaczynu cementowego [V]-{' '}
                                <b>
                                    {isNumber(currentCapacity.concreteVolume)
                                        ? round(
                                              currentCapacity.concreteVolume,
                                              2,
                                          ).toFixed(2)
                                        : ''}{' '}
                                    m<sup>3</sup>
                                </b>
                            </p>
                            {calculationParameters.isSteelRod && (
                                <Fragment>
                                    <p>
                                        Długość zakotwienia głowicy:{' '}
                                        <b>
                                            {calculationParameters.headLength.toFixed(
                                                2,
                                            )}{' '}
                                            m
                                        </b>
                                    </p>
                                    <p>
                                        Długość całkowita żerdzi:{' '}
                                        <b>
                                            {round(
                                                totalMicropileRodLength,
                                                2,
                                            ).toFixed(2)}{' '}
                                            m
                                        </b>
                                    </p>
                                    <p>
                                        Ciężar żerdzi mikropala:{' '}
                                        <b>
                                            {round(
                                                totalMicropileRodLength *
                                                    steelRod.m,
                                                2,
                                            ).toFixed(2)}{' '}
                                            kg
                                        </b>
                                    </p>
                                    <p>
                                        Obliczeniowa nośność żerdzi [R
                                        <sub>m,d</sub>] -{' '}
                                        <b>
                                            {isNumber(
                                                calculationParameters.rodCapacityValue,
                                            )
                                                ? round(
                                                      calculationParameters.rodCapacityValue,
                                                      2,
                                                  )
                                                : ''}{' '}
                                            kN
                                        </b>
                                    </p>
                                </Fragment>
                            )}
                            <p>
                                Projektowana siła obliczeniowa [P] -{' '}
                                <b>
                                    {isNumber(
                                        calculationParameters.desAxisLoadValue,
                                    )
                                        ? round(
                                              calculationParameters.desAxisLoadValue,
                                              2,
                                          )
                                        : ''}{' '}
                                    kN
                                </b>
                            </p>
                            {activeCapacity.pullCapacity ? (
                                <Fragment>
                                    <p>
                                        Nośność na wyciąganie mikropala [R
                                        <sub>t,d</sub>] -{' '}
                                        <b>
                                            {isNumber(currentCapacity.ntw)
                                                ? round(currentCapacity.ntw, 2)
                                                : ''}{' '}
                                            kN
                                        </b>
                                    </p>
                                    <p>
                                        Siła do badania próbnego mikropala [R
                                        <sub>t,lt</sub>] -{' '}
                                        <b>
                                            {isNumber(currentCapacity.ntw)
                                                ? round(
                                                      currentCapacity.ntw * 1.5,
                                                      2,
                                                  )
                                                : ''}{' '}
                                            kN
                                        </b>
                                    </p>

                                    <div className={styles.summary}>
                                        <Row className={styles.row}>
                                            <Col xs={12} className={styles.col}>
                                                <h3>PODSUMOWANIE WYNIKÓW:</h3>
                                            </Col>
                                        </Row>
                                        <Row className={styles.row}>
                                            <Col xs={4} className={styles.col}>
                                                <p>
                                                    R<sub>t,d</sub> -{' '}
                                                    {isNumber(
                                                        currentCapacity.nt,
                                                    )
                                                        ? round(
                                                              currentCapacity.ntw,
                                                              2,
                                                          )
                                                        : ''}{' '}
                                                    kN
                                                </p>
                                            </Col>
                                            <Col xs={4} className={styles.col}>
                                                <p>
                                                    L -{' '}
                                                    {isNumber(
                                                        currentMicropileLength,
                                                    )
                                                        ? round(
                                                              currentMicropileLength,
                                                              2,
                                                          )
                                                        : ''}{' '}
                                                    m
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className={styles.warnings}>
                                        <h3>UWAGI:</h3>
                                        <CorrectDepth
                                            correctDepth={{
                                                micropileCapacity:
                                                    currentCapacity.veryfiedNtw,
                                            }}
                                        />
                                    </div>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <p>
                                        Nośność podstawy [R<sub>b</sub>] -{' '}
                                        <b>
                                            {isNumber(currentCapacity.np)
                                                ? round(currentCapacity.np, 2)
                                                : ''}{' '}
                                            kN
                                        </b>
                                    </p>
                                    <p>
                                        Nośność pobocznicy [R<sub>s</sub>] -{' '}
                                        <b>
                                            {isNumber(currentCapacity.ns)
                                                ? round(currentCapacity.ns, 2)
                                                : ''}{' '}
                                            kN
                                        </b>
                                    </p>
                                    <p>
                                        Nośność na wciskanie mikropala [R
                                        <sub>c,d</sub>] -{' '}
                                        <b>
                                            {isNumber(currentCapacity.nt)
                                                ? round(currentCapacity.nt, 2)
                                                : ''}{' '}
                                            kN
                                        </b>
                                    </p>
                                    <p>
                                        Siła do badania próbnego mikropala [R
                                        <sub>c,lt</sub>] -{' '}
                                        <b>
                                            {isNumber(currentCapacity.nt)
                                                ? round(
                                                      currentCapacity.nt * 1.5,
                                                      2,
                                                  )
                                                : ''}{' '}
                                            kN
                                        </b>
                                    </p>

                                    <div className={styles.summary}>
                                        <Row className={styles.row}>
                                            <Col xs={12} className={styles.col}>
                                                <h3>PODSUMOWANIE WYNIKÓW:</h3>
                                            </Col>
                                        </Row>
                                        <Row className={styles.row}>
                                            <Col xs={4} className={styles.col}>
                                                <p>
                                                    R<sub>b</sub> -{' '}
                                                    {isNumber(
                                                        currentCapacity.np,
                                                    )
                                                        ? round(
                                                              currentCapacity.np,
                                                              2,
                                                          )
                                                        : ''}{' '}
                                                    kN
                                                </p>
                                            </Col>
                                            <Col xs={4} className={styles.col}>
                                                <p>
                                                    R<sub>s</sub> -{' '}
                                                    {isNumber(
                                                        currentCapacity.ns,
                                                    )
                                                        ? round(
                                                              currentCapacity.ns,
                                                              2,
                                                          )
                                                        : ''}{' '}
                                                    kN
                                                </p>
                                            </Col>
                                            <Col xs={4} className={styles.col}>
                                                <p>
                                                    R<sub>c,d</sub> -{' '}
                                                    {isNumber(
                                                        currentCapacity.nt,
                                                    )
                                                        ? round(
                                                              currentCapacity.nt,
                                                              2,
                                                          )
                                                        : ''}{' '}
                                                    kN
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className={styles.row}>
                                            <Col xs={4} className={styles.col}>
                                                <p>
                                                    L -{' '}
                                                    {isNumber(
                                                        currentMicropileLength,
                                                    )
                                                        ? round(
                                                              currentMicropileLength,
                                                              2,
                                                          )
                                                        : ''}{' '}
                                                    m
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className={styles.warnings}>
                                        <h3>UWAGI:</h3>
                                        <CorrectDepth
                                            correctDepth={{
                                                micropileCapacity:
                                                    currentCapacity.veryfied,
                                                buckling:
                                                    currentCapacity.buckling,
                                            }}
                                        />
                                    </div>
                                </Fragment>
                            )}
                            {calculationParameters.isSteelRod && (
                                <div className={styles.typicalLength}>
                                    <SectionTitle title="Rzeczywista długość mikropala" />
                                    <Row>
                                        <Col xs={12} sm={8}>
                                            <InputField
                                                title="Moduł długości żerdzi"
                                                name="rodModule"
                                                type="number"
                                                value={rodModule.value}
                                                onChange={onChangeRodModule}
                                                placeholder="Moduł żerdzi"
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <div
                                                className={
                                                    styles.typicalDescription
                                                }>
                                                {rodModule.value > 0 ? (
                                                    <Fragment>
                                                        {isNumber(
                                                            prodLengthValues.bondLength,
                                                        ) && (
                                                            <p
                                                                className={
                                                                    styles.p
                                                                }>
                                                                Długość
                                                                mikropala w
                                                                gruncie:{' '}
                                                                <b>
                                                                    {prodLengthValues.bondLength.toFixed(
                                                                        2,
                                                                    )}{' '}
                                                                    m
                                                                </b>
                                                            </p>
                                                        )}
                                                        {isNumber(
                                                            prodLengthValues.totalLength,
                                                        ) && (
                                                            <p
                                                                className={
                                                                    styles.p
                                                                }>
                                                                Długość
                                                                całkowita
                                                                żerdzi:{' '}
                                                                <b>
                                                                    {
                                                                        rodModule.value
                                                                    }
                                                                    m x{' '}
                                                                    {round(
                                                                        prodLengthValues.totalLength /
                                                                            rodModule.value,
                                                                        0,
                                                                    )}{' '}
                                                                    ={' '}
                                                                    {prodLengthValues.totalLength.toFixed(
                                                                        2,
                                                                    )}{' '}
                                                                    m
                                                                </b>
                                                            </p>
                                                        )}
                                                        {isNumber(
                                                            steelRod.m,
                                                        ) && (
                                                            <p
                                                                className={
                                                                    styles.p
                                                                }>
                                                                Masa całkowita
                                                                żerdzi:{' '}
                                                                <span
                                                                    className={
                                                                        styles.result
                                                                    }>
                                                                    <b>
                                                                        {round(
                                                                            prodLengthValues.totalLength *
                                                                                steelRod.m,
                                                                            2,
                                                                        )}{' '}
                                                                        kg
                                                                    </b>
                                                                </span>
                                                            </p>
                                                        )}
                                                    </Fragment>
                                                ) : (
                                                    <Fragment></Fragment>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
};

export default React.memo(SoilProfile, (prev, next) => {
    return stringify(prev) === stringify(next);
});
