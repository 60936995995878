import { Fragment, useState } from 'react';
import styles from './index.module.css';
import { roundNumber, isNumber } from '../../../../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const DescriptionDetails = ({
    results = [],
    steelRodWeight='',
    rodModule='',
    title='', 
    isSteelRod
}) => {

    const [prodLengthValues, setProdLengthValues] = useState({
        bondLength: '',
        totalLength: ''
    });

    useDeepCompareEffect(() => {
        if(results[1] && isNumber(rodModule)){
            const tot = Math.ceil(roundNumber(results[1].totalBondLength + results[1].freeLength + results[1].headLength,2)/rodModule)*rodModule;
            setProdLengthValues({
                bondLength: roundNumber(tot - (results[1].freeLength + results[1].headLength),2),
                totalLength: tot,
            });   
        }
    },[rodModule, results]);

    return (
        <div className={styles.wrapper}>
            {
                results[1] &&
                <Fragment>
                    <div className={styles.margins}>
                        <h2>{title}</h2>
                    </div>
                    <p>Projektowana siła obliczeniowa: <span className={styles.value}>{results[1].axisLoad}kN</span></p>
                    <p>Osiągnięta nośność obliczeniowa: <span className={styles.value}>{results[1].totalCapacity}kN</span></p>
                    <p>Długość odcinka nośnego/buławy: <span className={styles.value}>{results[1].totalBondLength}m</span></p>
                    <p>Długość mikropala w gruncie: <span className={styles.value}>{roundNumber(results[1].totalBondLength + results[1].freeLength,2)}m</span></p>                     
                    {
                        isSteelRod &&
                        <Fragment>
                            <p>Długość zakotwienia głowicy: <span className={styles.value}>{results[1].headLength}m</span></p>
                            <p>Długość całkowita żerdzi: <span className={styles.value}>{roundNumber(results[1].totalBondLength + results[1].freeLength + results[1].headLength,2)}m</span></p>
                        </Fragment>
                    }
                    {
                        results[1].totalElongation &&
                        <Fragment>
                            {
                                isSteelRod &&
                                <Fragment>
                                { 
                                    isNumber(results[1].totalElongation[0].ea) &&
                                    <p>Sztywność osiowa mikropala EA: <span className={styles.value}>{roundNumber(results[1].totalElongation[0].ea/1000000,3)} x 10<sup>6</sup>kN</span></p>
                                }
                                {
                                    isNumber(results[1].totalElongation[0].elonSum) &&
                                    <p>Wydłużenie sprężyste mikropala: <span className={styles.value}>{roundNumber(results[1].totalElongation[0].elonSum*1000,1)}mm</span></p>
                                }
                                </Fragment>
                            }
                            {
                                isNumber(results[1].totalElongation[0].concreteVolume) &&
                                <p>Objętość zaczynu cementowego: <span className={styles.value}>{roundNumber(results[1].totalElongation[0].concreteVolume,2)}m<sup>3</sup></span></p>
                            }
                        </Fragment>
                    }   
                    {
                        isNumber(steelRodWeight) && <p>Masa całkowita żerdzi: <span className={styles.value}>{roundNumber((results[1].totalBondLength + results[1].freeLength + results[1].headLength)*steelRodWeight,2)}kg</span></p>
                    }

                    {
                        !results[1].veryfied && 
                        <Fragment>
                            <div className={styles.margins}>
                                <h2>UWAGA: </h2>
                            </div>
                            <p>Nie osiągnięto wymaganej nośności mikropala, zmień/wydłuż profil gruntowy</p>
                        </Fragment> 
                    }

                    {
                        isSteelRod && isNumber(rodModule) && rodModule > 0
                        ?
                            <Fragment>
                                <div className={styles.margins}>
                                    <h2>Rzeczywista długość mikropala z uwzględnieniem klina odłamu</h2>                                    
                                </div>
                                {
                                    isNumber(prodLengthValues.bondLength) && 
                                    <p>Długość odcinka nośnego/buławy: <span className={styles.value}>{prodLengthValues.bondLength}m</span></p>
                                }
                                {
                                    isNumber(prodLengthValues.totalLength) && 
                                    <p>Długość całkowita mikropala: <span className={styles.value}>{rodModule}m x {roundNumber(prodLengthValues.totalLength/rodModule,0)} = {prodLengthValues.totalLength}m</span></p>
                                }
                                {
                                    isNumber(steelRodWeight) && isNumber(prodLengthValues.totalLength) &&
                                    <p>Masa całkowita żerdzi: <span className={styles.value}>{roundNumber(prodLengthValues.totalLength*steelRodWeight,2)}kg</span></p>
                                }
                            </Fragment>
                        :
                            <Fragment></Fragment>
                    }
                </Fragment>
            }
        </div>
    )
}

export default DescriptionDetails