import React from 'react';
import Pagination from "react-js-pagination";
import styles from './index.module.css';

const PaginationHandler = ({ nextPage, currentPage, itemsPerPage, totalItems, css='' }) => {

    return(
        <div className={`${styles.wrapper} ${css} pagination-wrapper`}>
            <Pagination
                hideDisabled
                activePage={currentPage}
                itemsCountPerPage={ Number.parseInt(itemsPerPage)}
                totalItemsCount={totalItems}
                pageRangeDisplayed={5}
                onChange={nextPage}
                innerClass={styles.pagination}
                activeLinkClass={styles.active}
                linkClass={styles.defaultLink}
            />            
        </div>
    )
}

export default React.memo(PaginationHandler)