import { Fragment, useState } from 'react';
import {
    Modal,
    SectionTitle,
    ConfirmModal,
    Checkbox1,
} from '../../../components';
import styles from './index.module.css';
import {
    faQuestionCircle,
    faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

const Calculations = ({
    data = {},
    profilesArray = [],
    calculationsInProfile = [],
    currentProject = {},
    allCalculationsWitoutProfile = [],
    calculationIds = [],
    onChangeCalculationIds,

    isUserRole,
    id,
}) => {
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [calcName, setCalcName] = useState('');
    const [calcId, setCalcId] = useState('');
    const [calcType, setCalcType] = useState('');
    const [infoObj, setInfoObj] = useState({});

    const onOpenCalculationModal = (id, name, type) => {
        setCalcName(name);
        setCalcId(id);
        setCalcType(type);
        setOpenConfirmModal(true);
    };
    const onOpenInfoModal = (obj) => {
        setInfoObj(obj);
        setOpenInfoModal(true);
    };
    const onHideInfoModal = () => {
        setInfoObj({});
        setOpenInfoModal(false);
    };
    const onDeleteCalculation = () => {
        setOpenConfirmModal(false);
        data.onRemoveCalculation(calcId, calcType);
    };

    const onHideConfirmModal = () => setOpenConfirmModal(false);

    return (
        <Fragment>
            <Col xs={12} md={6}>
                {profilesArray.length > 0 ? (
                    calculationsInProfile.length > 0 ? (
                        <Fragment>
                            {calculationsInProfile.map((ele) => {
                                return (
                                    <div
                                        key={ele.profileId}
                                        className={styles.row}>
                                        <SectionTitle
                                            title={`Obliczenia dla profilu - ${ele.profileName}`}
                                        />
                                        <div className={`backgroundAround`}>
                                            <ul className={styles.ul}>
                                                {ele.calculations.length > 0 ? (
                                                    <Fragment>
                                                        {ele.calculations.map(
                                                            (ele, idx) => {
                                                                return (
                                                                    <li key={idx}>
                                                                        <div className={styles.projectWrapper}>
                                                                            <p>{`${ idx + 1}. ${ele.projectName} (${
                                                                                ele.type === 0
                                                                                    ? 'Kx'
                                                                                    : ele.type === 1
                                                                                    ? 'PN-83/B-02482'
                                                                                    : ele.type === 2
                                                                                    ? 'Mikropal kotwiący'
                                                                                    : ele.type === 3
                                                                                    ? 'Mikropal'
                                                                                    : ele.type === 4
                                                                                    ? 'Zbrojenie przekroju'
                                                                                    : ele.type === 5
                                                                                    ? 'Pal CPT'
                                                                                    : ele.type === 6
                                                                                    ? 'Ścianka szczelna CPT'
                                                                                    : ''
                                                                            })`}</p>

                                                                            <div
                                                                                className={
                                                                                    styles.flex
                                                                                }>
                                                                                    {
                                                                                        ele.results.isResultsActual &&
                                                                                        <Checkbox1
                                                                                            css={
                                                                                                styles.calculationCheckbox
                                                                                            }
                                                                                            value={calculationIds.includes(
                                                                                                ele.id,
                                                                                            )}
                                                                                            onChange={() =>
                                                                                                onChangeCalculationIds(
                                                                                                    ele.id,
                                                                                                )
                                                                                            }
                                                                                            label={
                                                                                                'Wybierz obliczenia do zestawienia'
                                                                                            }
                                                                                            name={
                                                                                                'singleCalculation'
                                                                                            }
                                                                                        />                                                                                        
                                                                                    }
 
                                                                                <span
                                                                                    className={`${
                                                                                        styles.actualResults
                                                                                    } ${
                                                                                        ele
                                                                                            .results.isResultsActual
                                                                                            ? ''
                                                                                            : styles.wrongResults
                                                                                    }`}
                                                                                    title={
                                                                                        ele
                                                                                            .results
                                                                                            .isResultsActual
                                                                                            ? 'Obliczenia są aktualne'
                                                                                            : 'Obliczenia są nieaktualne'
                                                                                    }></span>
                                                                                {(isUserRole ||
                                                                                    id ===
                                                                                        currentProject.userId) && (
                                                                                    <div
                                                                                        className={
                                                                                            styles.actionCalc
                                                                                        }
                                                                                        title="Usuń obliczenia"
                                                                                        onClick={() =>
                                                                                            onOpenCalculationModal(
                                                                                                ele.id,
                                                                                                ele.projectName,
                                                                                                ele.type,
                                                                                            )
                                                                                        }>
                                                                                        <FontAwesomeIcon
                                                                                            icon={
                                                                                                faTrashAlt
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                                <div
                                                                                    className={
                                                                                        styles.actionCalc
                                                                                    }
                                                                                    title="Informacje o obliczeniach"
                                                                                    onClick={() =>
                                                                                        onOpenInfoModal(
                                                                                            {
                                                                                                calcName:
                                                                                                    ele.projectName,
                                                                                                profileName:
                                                                                                    ele.profileName,
                                                                                                projectName:
                                                                                                    ele
                                                                                                        .projectId
                                                                                                        .name,
                                                                                                user: ele.author,
                                                                                                type: ele.type,
                                                                                                created:
                                                                                                    ele.createdAt,
                                                                                                updated:
                                                                                                    ele.updatedAt,
                                                                                            },
                                                                                        )
                                                                                    }>
                                                                                    <FontAwesomeIcon
                                                                                        icon={
                                                                                            faQuestionCircle
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                );
                                                            },
                                                        )}
                                                    </Fragment>
                                                ) : (
                                                    <p>
                                                        Brak zapisanych obliczeń
                                                    </p>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                );
                            })}
                        </Fragment>
                    ) : (
                        <p className={styles.center}>
                            Brak zapisanych obliczeń
                        </p>
                    )
                ) : (
                    <Fragment>
                        {currentProject.name &&
                        currentProject.profiles.length > 0 ? (
                            <p className={styles.center}>
                                Wybierz profil gruntowy
                            </p>
                        ) : (
                            <p></p>
                        )}
                    </Fragment>
                )}
            </Col>
            <Col xs={12} md={6}>
                {allCalculationsWitoutProfile.length > 0 && (
                    <Fragment>
                        <SectionTitle
                            title={`Obliczenia nieprzypisane do profilu`}
                        />
                        <div className={`${styles.wrapper} backgroundAround`}>
                            <ul className={styles.ul}>
                                {allCalculationsWitoutProfile.map(
                                    (ele, idx) => {
                                        return (
                                            <li key={idx}>
                                                <div
                                                    className={
                                                        styles.projectWrapper
                                                    }>
                                                    <p>{`${idx + 1}. ${
                                                        ele.projectName
                                                    } (${
                                                        ele.type === 0
                                                            ? 'Kx'
                                                            : ele.type === 1
                                                            ? 'PN-83/B-02482'
                                                            : ele.type === 2
                                                            ? 'Mikropal kotwiący'
                                                            : ele.type === 3
                                                            ? 'Mikropal'
                                                            : ele.type === 4
                                                            ? 'Zbrojenie przekroju'
                                                            : ele.type === 5
                                                            ? 'Pal CPT'
                                                            : ele.type === 6
                                                            ? 'Ścianka szczelna CPT'
                                                            : ''
                                                    })`}</p>

                                                    <div
                                                        className={styles.flex}>
                                                        <Checkbox1
                                                            css={
                                                                styles.calculationCheckbox
                                                            }
                                                            value={calculationIds.includes(
                                                                ele.id,
                                                            )}
                                                            onChange={() =>
                                                                onChangeCalculationIds(
                                                                    ele.id,
                                                                )
                                                            }
                                                            label={
                                                                'Wybierz obliczenia do zestawienia'
                                                            }
                                                            name={
                                                                'singleCalculation'
                                                            }
                                                        />
                                                        <span
                                                            className={`${
                                                                styles.actualResults
                                                            } ${
                                                                ele.results
                                                                    .isResultsActual
                                                                    ? ''
                                                                    : styles.wrongResults
                                                            }`}
                                                            title={
                                                                ele.results
                                                                    .isResultsActual
                                                                    ? 'Obliczenia są aktualne'
                                                                    : 'Obliczenia są nieaktualne'
                                                            }></span>
                                                        {(isUserRole ||
                                                            id ===
                                                                currentProject.userId) && (
                                                            <div
                                                                className={
                                                                    styles.actionCalc
                                                                }
                                                                title="Usuń obliczenia"
                                                                onClick={() =>
                                                                    onOpenCalculationModal(
                                                                        ele.id,
                                                                        ele.projectName,
                                                                        ele.type,
                                                                    )
                                                                }>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faTrashAlt
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                        <div
                                                            className={
                                                                styles.actionCalc
                                                            }
                                                            title="Informacje o obliczeniach"
                                                            onClick={() =>
                                                                onOpenInfoModal(
                                                                    {
                                                                        calcName:
                                                                            ele.projectName,
                                                                        profileName:
                                                                            ele.profileName,
                                                                        projectName:
                                                                            ele
                                                                                .projectId
                                                                                .name,
                                                                        user: ele.author,
                                                                        type: ele.type,
                                                                        created:
                                                                            ele.createdAt,
                                                                        updated:
                                                                            ele.updatedAt,
                                                                    },
                                                                )
                                                            }>
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faQuestionCircle
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    },
                                )}
                            </ul>
                        </div>
                    </Fragment>
                )}
            </Col>

            <Modal
                open={openInfoModal}
                cssDialog={styles.modalDialogInfo}
                onHide={onHideInfoModal}
                hideButton={false}
                title={`Informacje o obliczeniach - ${infoObj.calcName}`}>
                <div className={styles.infoWrapper}>
                    <Row>
                        <Col xs={12}>
                            <SectionTitle
                                title="Nazwa obliczeń"
                                className={styles.h2Title}
                            />
                        </Col>
                        <Col xs={12}>
                            <p>{infoObj.calcName}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <SectionTitle
                                title="Rodzaj obliczeń"
                                className={styles.h2Title}
                            />
                        </Col>
                        <Col xs={12}>
                            <p>
                                {infoObj.type === 0
                                    ? 'Nośność pozioma wg metody uogólnionej M. Koseckiego'
                                    : infoObj.type === 1
                                    ? 'Nośność pionowa pali wg PN-83/B-02482 oraz Eurokodu 7'
                                    : infoObj.type === 2
                                    ? 'Nośność na wyciąganie mikropali kotwiących'
                                    : infoObj.type === 3
                                    ? 'Nośność pionowa na wciskanie/wyciąganie mikropali'
                                    : infoObj.type === 4
                                    ? 'Zbrojenie przekroju wg Eurokodu 2'
                                    : infoObj.type === 5
                                    ? 'Nośność pionowa pali na podstawie sondowania CPT'
                                    : infoObj.type === 6
                                    ? 'Nośność pionowa ścianki szczelnej na podstawie sondowania CPT'
                                    : ''
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <SectionTitle
                                title="Nazwa projektu"
                                className={styles.h2Title}
                            />
                        </Col>
                        <Col xs={12}>
                            <p>{infoObj.projectName}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <SectionTitle
                                title="Nazwa profilu gruntowego"
                                className={styles.h2Title}
                            />
                        </Col>
                        <Col xs={12}>
                            <p>{infoObj.profileName || 'Brak'}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <SectionTitle
                                title="Autor obliczeń"
                                className={styles.h2Title}
                            />
                        </Col>
                        <Col xs={12}>
                            <p>
                                {infoObj.user
                                    ? `${infoObj.user.firstName} ${infoObj.user.lastName}, ${infoObj.user.email}`
                                    : ''}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <SectionTitle
                                title="Data utworzenia obliczeń"
                                className={styles.h2Title}
                            />
                        </Col>
                        <Col xs={12}>
                            <p>
                                {moment(infoObj.created)
                                    .local('pl')
                                    .format('DD.MM.YYYY, HH:mm')}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <SectionTitle
                                title="Data ostatniej aktualizacji obliczeń"
                                className={styles.h2Title}
                            />
                        </Col>
                        <Col xs={12}>
                            <p>
                                {moment(infoObj.updated)
                                    .local('pl')
                                    .format('DD.MM.YYYY, HH:mm')}
                            </p>
                        </Col>
                    </Row>
                </div>
            </Modal>
            <ConfirmModal
                open={openConfirmModal}
                onHide={onHideConfirmModal}
                onConfirm={onDeleteCalculation}
                title="Usuń obliczenia">
                <p>
                    Jesteś pewny, że chcesz usunąć obliczenia -{' '}
                    <b>{calcName}</b>?
                </p>
            </ConfirmModal>
        </Fragment>
    );
};

export default Calculations;