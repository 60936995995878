import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import { findClosest, isNumber, roundNumber, setSoilColor } from '../../../../../../utils';
import { Button, SectionTitle } from '../../../../../../components';
import SoilLayer from './SoilLayer';
import styles from './index.module.css';
import round from '../../../../../../utils/roundNumber';
import { Col, Row } from 'react-bootstrap';
import CorrectDepth from '../../CorrectDepth';
import { useWindowSize } from '../../../../../../hooks';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';

const SoilProfile = ({
    soilProfile,
    results = [],
    pileHeadSpot = 0,
    groundLevel=0,
    pileLength = 0,
    pileShape = '',
    pileType = '',
    pileTechnology = '',
    diameterPile = '',
    singlePile,
    currentPileLengthByUser,

    onChangeCurrentPileLength,
    onChangeCurrentCapacity,

    openSidebar,
}) => {
    const soilProfileRef = useRef(null);
    const resultsRef = useRef(null);
    const [isPileVisible, setIsPileVisible] = useState(false);
    const [soilLayer, setSoilLayer] = useState([]);
    const [profilePileLength, setProfilePileLength] = useState('');
    const [profilePileHeadSpot, setProfilePileHeadSpot] = useState('');
    const [pileBaseSpot, setPileBaseSpot] = useState('');
    const [resultsWidth, setResultsWidth] = useState(0);
    const [resultsHeight, setResultsHeight] = useState(0);
    const [maxHeight, setMaxHeight] = useState(0);
    const [maxNt, setMaxNt] = useState('');
    const [axisNtScale, setAxisNtScale] = useState([]);
    const [currentNt, setCurrentNt] = useState('');
    const [currentPileLength, setCurrentPileLength] = useState(
        currentPileLengthByUser,
    );
    const [currentCapacity, setCurrentCapacity] = useState({});
    const [activeCapacity, setActiveCapacity] = useState({
        pileCapacity: true,
        pullCapacity: false,
    });

    const windowSize = useWindowSize();

    const soilProfileHeights = soilProfile.map((ele) => ele.height);

    useEffect(() => {
        if (resultsRef.current) {
            setResultsWidth(resultsRef.current.offsetWidth*0.95);
        }
    }, [
        isPileVisible,
        activeCapacity.pileCapacity,
        activeCapacity.pullCapacity,
        openSidebar,
        windowSize.width,
    ]);

    useDeepCompareEffect(() => {
        setMaxHeight(Math.max(...soilProfileHeights) + 1);
    }, [soilProfileHeights]);

    useDeepCompareEffect(() => {
        if (soilProfileRef.current) {
            const offsetHeight = soilProfileRef.current.offsetHeight;

            const layers = soilProfile.map((ele, i, arr) => {
                return (
                    <SoilLayer
                        key={i}
                        groundLevel={groundLevel}
                        backgroundColor={setSoilColor({
                            id: ele.density,
                            il: ele.plasticity,
                        })}
                        name={ele.name}
                        height={
                            maxHeight === 0
                                ? 0
                                : (offsetHeight *
                                      (i === arr.length - 1
                                          ? ele.absHeight + 1
                                          : ele.absHeight)) /
                                  maxHeight
                        }
                        layerHeight={ele.height}
                    />
                );
            });
            setSoilLayer(layers);
            setResultsHeight(offsetHeight);
        }
    }, [maxHeight, soilProfile]);

    useEffect(() => {
        onChangeCurrentPileLength(currentPileLength);
        onChangeCurrentCapacity({
            currentPileLength,
            diameterPile,
            pileShape,
            pileType,
            pileTechnology,
            singlePile,

            np: currentCapacity.np,
            ns: currentCapacity.ns,
            nt: currentCapacity.nt,
            ntec7: currentCapacity.ntec7,
            ntec7d: currentCapacity.ntec7d,
            ntec7lt: currentCapacity.ntec7lt,
            ntw: currentCapacity.ntw,
            ntgr: currentCapacity.ntgr,
            nwgr: currentCapacity.nwgr,
        });
    }, [
        onChangeCurrentPileLength,
        onChangeCurrentCapacity,
        pileType,
        pileTechnology,
        diameterPile,
        pileShape,
        singlePile,
        currentPileLength,

        currentCapacity.np,
        currentCapacity.ns,
        currentCapacity.nt,
        currentCapacity.ntec7,
        currentCapacity.ntec7d,
        currentCapacity.ntec7lt,
        currentCapacity.ntw,
        currentCapacity.ntgr,
        currentCapacity.nwgr,
    ]);

    useDeepCompareEffect(() => {
        const nt = activeCapacity.pullCapacity
            ? Math.max(...results.map((ele) => ele.ntw))
            : Math.max(...results.map((ele) => ele.nt));

        const maxAxisNt = round(nt - (nt % 5), 0);
        const initPileLength =
            isNumber(currentPileLength) && currentPileLength > 0
                ? currentPileLength
                : isNumber(pileLength) && pileLength > 0
                ? pileLength
                : roundNumber(maxHeight - pileHeadSpot - 1,2);


        const initNt =
            results.find(
                (ele) => roundNumber(ele.z,2) === roundNumber(initPileLength + pileHeadSpot,2),
            ) || {};

        setIsPileVisible(
            isNumber(pileHeadSpot) && maxHeight - pileHeadSpot > 0,
        );
        setPileBaseSpot((state) =>
            isNumber(state) && state > pileHeadSpot
                ? state
                : roundNumber(initPileLength + pileHeadSpot,2),
        );

        setCurrentPileLength((state) =>
            isNumber(state) && state > 0
                ? state
                : isNumber(initPileLength)
                ? initPileLength
                : '',
        );

        setCurrentNt((state) =>
            activeCapacity.pullCapacity ? initNt.ntw : initNt.nt,
        );
        setCurrentCapacity(initNt);
        setAxisNtScale(
            Array(5)
            .fill(maxAxisNt / 5)
            .reduce((acc, ele, idx) => [...acc, ele * (idx + 1)], []),
        );

        setMaxNt(nt);

        if (soilProfileRef.current) {
            setProfilePileHeadSpot(
                maxHeight === 0
                    ? 0
                    : (resultsHeight * pileHeadSpot) / maxHeight,
            );
            setProfilePileLength(
                maxHeight === 0
                    ? 0
                    : (resultsHeight * initPileLength) / maxHeight,
            );
        }
    }, [
        maxHeight,
        resultsHeight,
        activeCapacity.pileCapacity,
        activeCapacity.pullCapacity,
        currentPileLength,
        pileHeadSpot,
        pileLength,
        results,
    ]);

    const onChangePileLength = (e) => {
        e.stopPropagation();

        const profileSpots = results.map((ele) => ele.z);
        const clientRect = e.currentTarget.getClientRects()[0];
        const baseSpot =
            ((clientRect.height - clientRect.bottom + e.clientY) /
                clientRect.height) *
            maxHeight;
        const closestSpot = findClosest(profileSpots, baseSpot);
        const closestResult = results.find((ele) => ele.z === roundNumber(closestSpot,2));

        setCurrentNt((state) =>
            closestResult
                ? activeCapacity.pullCapacity
                    ? closestResult.ntw
                    : closestResult.nt
                : state,
        );
        setCurrentCapacity(closestResult);
        setPileBaseSpot((state) =>
            closestSpot > pileHeadSpot ? closestSpot : state,
        );
        setCurrentPileLength((state) =>
            closestSpot > pileHeadSpot ? closestSpot - pileHeadSpot : state,
        );
        setProfilePileLength((state) =>
            closestSpot > pileHeadSpot
                ? (clientRect.height * (closestSpot - pileHeadSpot)) / maxHeight
                : state,
        );
    };

    const onChangeCapacity = (e) => {
        const { name } = e.currentTarget;

        setActiveCapacity((state) => {
            return {
                ...state,
                pileCapacity: false,
                pullCapacity: false,
                [name]: true,
            };
        });
    };

    return (
        <Fragment>
            <Row>
                <Col xs={12} sm={7}>
                    <SectionTitle
                        className={styles.title}
                        title="Rozkład obliczeniowej nośności pala wg PN-83/B-2482"
                    />
                    <Row>
                        <Col xs={12}>
                            <Button
                                onClick={onChangeCapacity}
                                css={`project-navigation ${styles.buttonMarginLeft}`}
                                name="pileCapacity"
                                altTitle="Nośność na wciskanie"
                                title="Wciskanie"
                                active={activeCapacity.pileCapacity}
                            />
                            <Button
                                onClick={onChangeCapacity}
                                css={`project-navigation ${styles.buttonMarginRight}`}
                                name="pullCapacity"
                                altTitle="Nośność na wyciąganie"
                                title="Wyciąganie"
                                active={activeCapacity.pullCapacity}
                            />
                        </Col>
                    </Row>
                    <div
                        className={styles.wrapper}
                        ref={soilProfileRef}
                        onClick={onChangePileLength}>
                        {isPileVisible && (
                            <Fragment>
                                <div
                                    className={styles.pileWrapper}
                                    style={{
                                        height: `${profilePileLength}px`,
                                        top: `${profilePileHeadSpot}px`,
                                    }}>
                                    <div className={styles.pile}>
                                        <div
                                            className={`${styles.pileShape} ${
                                                pileShape === 'Kołowy'
                                                    ? styles.circle
                                                    : ''
                                            }`}></div>
                                        <div className={styles.pileLength}>
                                            <p>
                                                L ={' '}
                                                {round(currentPileLength, 2)} m
                                            </p>
                                        </div>
                                    </div>
                                    <div className={styles.spotWrapper}>
                                        <span className={styles.pileSpot}>
                                            {isNumber(pileHeadSpot) && isNumber(groundLevel)
                                                ? <Fragment>{(pileHeadSpot).toFixed(2)} <br></br> ({(groundLevel - pileHeadSpot).toFixed(2)})</Fragment>
                                                : ''}
                                        </span>
                                        <span className={styles.pileSpot}>
                                            {isNumber(pileBaseSpot)
                                                ? <Fragment>{pileBaseSpot.toFixed(2)} <br></br>({(groundLevel - pileBaseSpot).toFixed(2)})</Fragment>
                                                : ''}
                                        </span>
                                    </div>
                                    <div 
                                        className={styles.results}
                                        ref={resultsRef}>
                                        <div
                                            className={styles.horizontalAxis}
                                            >
                                            {axisNtScale.map((ele, idx) => {
                                                return (
                                                    <span key={idx} className={styles.axisCoord} style={{left: `${(resultsWidth / maxNt) *ele}px`}}>
                                                        <p className={styles.value}>
                                                            {ele}
                                                        </p>
                                                    </span>
                                                );
                                            })}

                                            <span className={styles.caret}>
                                                {activeCapacity.pullCapacity ? (
                                                    <p
                                                        className={
                                                            styles.axisDescription
                                                        }>
                                                        N<sub>tw</sub>[kN]
                                                    </p>
                                                ) : (
                                                    <p
                                                        className={
                                                            styles.axisDescription
                                                        }>
                                                        N<sub>t</sub>[kN]
                                                    </p>
                                                )}
                                            </span>
                                        </div>
                                        <div className={styles.verticalAxis}>
                                            <span
                                                className={
                                                    styles.verticalCaret
                                                }>
                                                <p
                                                    className={
                                                        styles.axisVerticalDescription
                                                    }>
                                                    Z[m]
                                                </p>
                                            </span>
                                        </div>
                                        <div className={
                                                styles.currentHorizontalValue
                                            }
                                            style={{
                                                top: `${profilePileLength}px`,
                                                width: `${
                                                    (resultsWidth * currentNt) /
                                                    maxNt
                                                }px`,
                                            }}></div>
                                        <div
                                            className={
                                                styles.currentVerticalValue
                                            }
                                            style={{
                                                height: `${profilePileLength}px`,
                                                left: `${
                                                    (resultsWidth * currentNt) /
                                                    maxNt
                                                }px`,
                                            }}></div>
                                        {results.map((ele, i) => {
                                            const {
                                                correctBaseDepth,
                                                correctMultiLayerDepth,
                                                isCohesive,
                                                isSoftLayer,
                                            } = ele.correctDepth;
                                            const correctLength =
                                                correctBaseDepth >= 1 &&
                                                correctMultiLayerDepth &&
                                                !isCohesive &&
                                                !isSoftLayer;
                                            const warningDepth =
                                                correctBaseDepth > 0 &&
                                                correctBaseDepth < 1 &&
                                                correctMultiLayerDepth &&
                                                !isCohesive &&
                                                !isSoftLayer;

                                            return activeCapacity.pullCapacity ? (
                                                <div
                                                    key={i}
                                                    className={`${styles.capacityPoint} ${styles.validDepth}`}
                                                    style={{
                                                        top: `${
                                                            (resultsHeight *
                                                                (ele.z -
                                                                    pileHeadSpot)) /
                                                            maxHeight
                                                        }px`,
                                                        left: `${
                                                            (resultsWidth *
                                                                (activeCapacity.pullCapacity
                                                                    ? ele.ntw
                                                                    : ele.nt)) /
                                                            maxNt
                                                        }px`,
                                                    }}>
                                                    <FontAwesomeIcon
                                                        icon={faCheckCircle}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    key={i}
                                                    className={`${
                                                        styles.capacityPoint
                                                    } ${
                                                        correctLength
                                                            ? styles.validDepth
                                                            : warningDepth
                                                            ? styles.warningDepth
                                                            : styles.wrongDepth
                                                    }`}
                                                    style={{
                                                        top: `${
                                                            (resultsHeight *
                                                                (ele.z -
                                                                    pileHeadSpot)) /
                                                            maxHeight
                                                        }px`,
                                                        left: `${
                                                            (resultsWidth *
                                                                (activeCapacity.pullCapacity
                                                                    ? ele.ntw
                                                                    : ele.nt)) /
                                                            maxNt
                                                        }px`,
                                                    }}>
                                                    <FontAwesomeIcon
                                                        icon={
                                                            correctLength
                                                                ? faCheckCircle
                                                                : warningDepth
                                                                ? faCheckCircle
                                                                : faTimesCircle
                                                        }
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </Fragment>
                        )}
                        <div>
                            {[...soilLayer]}
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={5}>
                    <div className={styles.resultsOverview}>
                        <SectionTitle
                            className={styles.title}
                            title="Obliczeniowa nośność pala"
                        />
                        <div className={styles.sticky}>
                            <p>
                                Średnica pala [D]-{' '}
                                <b>
                                    {isNumber(diameterPile)
                                        ? round(diameterPile, 2)
                                        : ''}{' '}
                                    m
                                </b>
                            </p>
                            <p>
                                Długość pala w gruncie [L]-{' '}
                                <b>
                                    {isNumber(currentPileLength)
                                        ? round(currentPileLength, 2)
                                        : ''}{' '}
                                    m
                                </b>
                            </p>
                            {activeCapacity.pullCapacity ? (
                                <Fragment>
                                    <p>
                                        Nośność na wyciąganie wg PN-83/B-2482 [N
                                        <sub>tw</sub>] -{' '}
                                        <b>
                                            {isNumber(currentCapacity.ntw)
                                                ? round(currentCapacity.ntw, 0)
                                                : ''}{' '}
                                            kN
                                        </b>
                                    </p>
                                    {!singlePile && (
                                        <p>
                                            Nośność na wyciąganie pala w grupie
                                            [N<sub>tw,gr</sub>] -{' '}
                                            <b>
                                                {isNumber(currentCapacity.nwgr)
                                                    ? round(
                                                          currentCapacity.nwgr,
                                                          0,
                                                      )
                                                    : ''}{' '}
                                                kN
                                            </b>
                                        </p>
                                    )}
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <p>
                                        Nośność podstawy [N<sub>p</sub>] -{' '}
                                        <b>
                                            {isNumber(currentCapacity.np)
                                                ? round(currentCapacity.np, 0)
                                                : ''}{' '}
                                            kN
                                        </b>
                                    </p>
                                    <p>
                                        Nośność pobocznicy [N<sub>s</sub>] -{' '}
                                        <b>
                                            {isNumber(currentCapacity.ns)
                                                ? round(currentCapacity.ns, 0)
                                                : ''}{' '}
                                            kN
                                        </b>
                                    </p>
                                    <p>
                                        Nośność na wciskanie wg PN-83/B-2482 [N
                                        <sub>t</sub>] -{' '}
                                        <b>
                                            {isNumber(currentCapacity.nt)
                                                ? round(currentCapacity.nt, 0)
                                                : ''}{' '}
                                            kN
                                        </b>
                                    </p>
                                    <p>
                                        Nośność na wciskanie wg Eurokodu 7 [N
                                        <sub>t,ec7</sub>] -{' '}
                                        <b>
                                            {isNumber(currentCapacity.ntec7d)
                                                ? round(
                                                      currentCapacity.ntec7d,
                                                      0,
                                                  )
                                                : ''}{' '}
                                            kN
                                        </b>
                                    </p>
                                    <p>
                                        Nośność graniczna wg Eurokodu 7 [N
                                        <sub>gr,ec7</sub>] -{' '}
                                        <b>
                                            {isNumber(currentCapacity.ntec7)
                                                ? round(
                                                      currentCapacity.ntec7,
                                                      0,
                                                  )
                                                : ''}{' '}
                                            kN
                                        </b>
                                    </p>
                                    <p>
                                        Siła do badania próbnego pala [N
                                        <sub>lt</sub>] -{' '}
                                        <b>
                                            {isNumber(currentCapacity.ntec7lt)
                                                ? round(
                                                      currentCapacity.ntec7lt,
                                                      0,
                                                  )
                                                : ''}{' '}
                                            kN
                                        </b>
                                    </p>

                                    {!singlePile && (
                                        <p>
                                            Nośność pala w grupie wg
                                            PN-83/B-2482 [N<sub>t,gr</sub>] -{' '}
                                            <b>
                                                {isNumber(currentCapacity.ntgr)
                                                    ? round(
                                                          currentCapacity.ntgr,
                                                          0,
                                                      )
                                                    : ''}{' '}
                                                kN
                                            </b>
                                        </p>
                                    )}
                                    <div className={styles.summary}>
                                        <Row className={styles.row}>
                                            <Col xs={12} className={styles.col}>
                                                <h3>PODSUMOWANIE WYNIKÓW:</h3>
                                            </Col>
                                        </Row>
                                        <Row className={styles.row}>
                                            <Col xs={4} className={styles.col}>
                                                <p>
                                                    N<sub>p</sub> -{' '}
                                                    {isNumber(
                                                        currentCapacity.np,
                                                    )
                                                        ? round(
                                                              currentCapacity.np,
                                                              0,
                                                          )
                                                        : ''}{' '}
                                                    kN
                                                </p>
                                            </Col>
                                            <Col xs={4} className={styles.col}>
                                                <p>
                                                    N<sub>s</sub> -{' '}
                                                    {isNumber(
                                                        currentCapacity.ns,
                                                    )
                                                        ? round(
                                                              currentCapacity.ns,
                                                              0,
                                                          )
                                                        : ''}{' '}
                                                    kN
                                                </p>
                                            </Col>
                                            <Col xs={4} className={styles.col}>
                                                <p>
                                                    N<sub>t</sub> -{' '}
                                                    {isNumber(
                                                        currentCapacity.nt,
                                                    )
                                                        ? round(
                                                              currentCapacity.nt,
                                                              0,
                                                          )
                                                        : ''}{' '}
                                                    kN
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className={styles.row}>
                                            <Col xs={4} className={styles.col}>
                                                <p>
                                                    N<sub>t,ec7</sub> -{' '}
                                                    {isNumber(
                                                        currentCapacity.ntec7d,
                                                    )
                                                        ? round(
                                                              currentCapacity.ntec7d,
                                                              0,
                                                          )
                                                        : ''}{' '}
                                                    kN
                                                </p>
                                            </Col>
                                            {!singlePile && (
                                                <Col
                                                    xs={4}
                                                    className={styles.col}>
                                                    <p>
                                                        N<sub>t,gr</sub> -{' '}
                                                        {isNumber(
                                                            currentCapacity.ntgr,
                                                        )
                                                            ? round(
                                                                  currentCapacity.ntgr,
                                                                  0,
                                                              )
                                                            : ''}{' '}
                                                        kN
                                                    </p>
                                                </Col>
                                            )}
                                            <Col xs={4} className={styles.col}>
                                                <p>
                                                    L<sub>pala</sub> -{' '}
                                                    {isNumber(currentPileLength)
                                                        ? round(
                                                              currentPileLength,
                                                              2,
                                                          )
                                                        : ''}{' '}
                                                    m
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className={styles.warnings}>
                                        <h3>UWAGI:</h3>
                                        <CorrectDepth
                                            correctDepth={
                                                currentCapacity.correctDepth
                                            }
                                        />
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
};

export default React.memo(
    SoilProfile,
    (prev, next) => stringify(prev) === stringify(next),
);
