import { useEffect, useState } from 'react';
import { plans } from '../../../config/ProjectConfig';
import { AuthFetch } from '../../../routes';
import { loadStripe } from '@stripe/stripe-js';
import { isUserRole } from '../../../utils';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_ID);

const initSubscriptionType = {
    yearSubscription: false,
    monthSubscription: false,
};

const Subscription = ({ 
    setAlert,
    setLoading,
    updateInvoiceState,
    invoiceState,
    isSaveFormData,
    invoiceType,
    role
}) => {
    const [subscriptionType, setSubscriptionType] = useState({
        yearSubscription: true,
        monthSubscription: false,
    });
    const [summaryCost, setSummaryCost] = useState();

    useEffect(() => {
        setLoading({ name: 'startPayment', loading: false });
    },[]);

    const orderSubscription = () => {
        const { companyName, nip, firstName, lastName, ...rest } = invoiceState;

        const validFormObject =
            invoiceType === 1
                ? { companyName, nip, ...rest }
                : invoiceType === 2
                ? { firstName, lastName, ...rest }
                : {};

        const isFormValid = Object.values(validFormObject).every(
            (ele) => ele.isValid,
        );

        if(isUserRole(role) && isFormValid){
            setLoading({ name: 'startPayment', loading: true });
            AuthFetch({
                url: '/api/subscription/order',
                method: 'POST',
                body: {
                    plan: subscriptionType.yearSubscription
                        ? 2
                        : subscriptionType.monthSubscription
                        ? 1
                        : '',
                    amount: summaryCost,
                    invoiceType,
                    saveFormData: isSaveFormData,
                    ...Object.entries(validFormObject).reduce(
                        (acc, [key, value]) => ({ ...acc, [key]: value.value }),
                        {},
                    ),
                },
            })
            .then(async (response) => {
                setLoading({ name: 'startPayment', loading: false });
                if (response.success) {
                    const { id: sessionId } = response.responseData;
                    const stripe = await stripePromise;
                    const { error } = await stripe.redirectToCheckout({
                        sessionId,
                    });
                    if (error) {
                        setAlert({
                            error: true,
                            message: 'Błąd podczas wykonywania płatności',
                        });
                    }
                } else {
                    setAlert({
                        error: true,
                        message: 'Błąd podczas wykonywania płatności',
                    });
                }
            })
            .catch(() => {
                setLoading({ name: 'startPayment', loading: false });
                setAlert({
                    error: true,
                    message: 'Błąd podczas wykonywania płatności',
                });
            });
        } else {
            setAlert({
                error: true,
                message: 'Nieprawidłowo wypełnione dane do faktury',
            });
            updateInvoiceState((state) => { 
                return {
                    ...state,
                    ...Object.entries(validFormObject).reduce(
                        (acc, [key, value]) => ({
                            ...acc,
                            [key]: {
                                value: value.value,
                                isValid: !!value.isValid,
                            },
                        }),
                        {},
                    ),
                };
            })
        }
    };

    useEffect(() => {
        setSummaryCost(() => { 
            if (subscriptionType.yearSubscription) {
                return plans.year.first;
            } else {
                return plans.month.first;
            }
        });
    }, [subscriptionType.yearSubscription, subscriptionType.monthSubscription]);

    const onChange = (e) => {
        const { name } = e.currentTarget;

        setSubscriptionType((state) => {
            if (Object.keys(state).includes(name)) {
                return {
                    ...initSubscriptionType,
                    [name]: true,
                };
            } else {
                return state;
            }
        });
    };

    return {
        subscription: {
            onChange: onChange,
            onOrderSubscription: orderSubscription,
            state: {
                subscriptionType,
                plans,
                summaryCost,
            },
        },
    };
};

export default Subscription;