import React, { Fragment } from 'react';
import { withDefaultApexChart } from '../../../../../hoc';
import { roundNumber } from '../../../../../utils';
import stringify from 'fast-json-stable-stringify';
import { Button, Table, TableInput } from '../../../../../components';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.css';


const CrossSectionPoints = ({
    result={},
    newPoint={},
    index,
    onChange,
    onRemovePoint,
    onAddPoint
}) => {

    return (     
        <Table>
            <thead className={styles.thead}> 
                <tr>
                    <th>
                        Nr<br></br>[-]
                    </th>
                    <th>
                        M<br></br>[kNm]
                    </th>
                    <th>
                        N<br></br>[kN]
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody className={styles.tbody}>
                <Fragment>
                    {
                        result.points.length > 0 &&
                        result.points.map((ele,idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{idx + 1}</td>
                                    <td>{roundNumber(ele.x,2)}</td>
                                    <td>{roundNumber(ele.y,2)}</td>
                                    <td>
                                        <Button
                                            variant="danger"
                                            icon={faTrashAlt}
                                            altTitle={'Usuń'}
                                            onClick={() => onRemovePoint(idx, index)}
                                        />
                                    </td>
                                </tr>                                    
                            )
                        })
                    }
                    <tr>
                        <td>{result.points.length + 1}</td>
                        <td>
                            <TableInput
                                name="x"
                                type="number"
                                placeholder='Mrd'
                                onChange={(e) => onChange(e, index)}
                                value={newPoint.x}
                            />
                        </td>
                        <td>
                            <TableInput
                                name="y"
                                type="number"
                                placeholder='Nrd'
                                onChange={(e) => onChange(e, index)}
                                value={newPoint.y}
                            />
                        </td>
                        <td>
                            <Button
                                variant="info"
                                icon={faPlus}
                                altTitle={'Dodaj'}
                                onClick={() => onAddPoint(index)}
                            />
                        </td>
                    </tr>
                </Fragment>
            </tbody>
        </Table>
    );
};

export default React.memo(
    withDefaultApexChart(CrossSectionPoints),
    (prev, next) => stringify(prev) === stringify(next),
);
