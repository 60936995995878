import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Fragment } from 'react';
import styles from './index.module.css';

const SectionTitle = ({ title, subtitle, className='', infoCss='', classNameWrapper='', children }) => {
    return (
        <Fragment>
            <div className={classNameWrapper}>
                <div className={styles.wrapper}>
                    <h2 className={`${styles.h2} ${className}`}>{title}</h2>
                    {
                        children &&
                        <Fragment>
                            <FontAwesomeIcon className={styles.infoIcon} icon={faQuestionCircle}/>
                            <div className={`${styles.info} ${infoCss} defaultBox`}>
                                {children}
                            </div> 
                        </Fragment>
                    }                    
                </div>
                { subtitle && <h4 className={styles.h4}>{subtitle}</h4>}   
            </div>
        </Fragment>
    )
}

export default SectionTitle