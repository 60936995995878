import React, { Fragment, useCallback, useRef, useState, useEffect } from 'react';
import {
    ResultsFooter, 
    ResultsHeader,
    ReportFirstPage,
} from '../../../../../components';
import CalculationSection from '../CalculationSection';
import PileParametersSection from '../PileParametersSection';
import styles from './index.module.css';
import TechnologyFactors from '../TechnologyFactors';
import CapacityResults from '../CapacityResults';
import CapacityTable from '../CapacityTable';
import CapacityChart from '../CapacityChart';
import SettlementChart from '../SettlementChart';
import QcChart from '../QcChart';
import CuChart from '../CuChart';

const PdfReport = React.forwardRef(({ pdfState, setLoading }, ref) => {
    const [footerHeight, setFooterHeight] = useState(0);
    const contentRef = useRef(null);
    const [maxWidth, setMaxWidth] = useState(0);
    const [dataURI, setDataURI] = useState('');
    const [dataURICu, setDataURICu] = useState('');

    useEffect(() =>{
        if(contentRef.current){
            setMaxWidth(contentRef.current.offsetWidth)
        }
    },[]);

    useEffect(() => {
        if(pdfState.reportItem.cptChart){
            setLoading(!dataURI);
        } else {
            setLoading(false);
        }
        
    },[dataURI, setLoading, pdfState.reportItem.cptChart]);

    return (
        <Fragment>
            <div className={styles.wrapper} ref={ref}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <td>
                                <div className={styles.headerSpace}></div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <main className={styles.content} ref={contentRef}>
                                    <ol>
                                        <div>
                                            {
                                                pdfState.reportItem.firstPage &&
                                                <Fragment>
                                                    <ReportFirstPage
                                                        title="Nośność pali wg niemieckich zaleceń do projektowania EA-Pf&auml;hle"
                                                        subtitle={
                                                            pdfState.projectTitle
                                                                .projectTitle
                                                        }
                                                        author={pdfState.report.author}
                                                        contact={pdfState.report.contact}
                                                        logo={pdfState.report.logo}
                                                    />                                          
                                                </Fragment>
                                            }
                                            {(
                                                pdfState.reportItem.projectInfo ||
                                                pdfState.reportItem.pileParameters || 
                                                pdfState.reportItem.helperFactorsDetails
                                            ) && (
                                                <Fragment>
                                                    <div className={styles.break}></div>
                                                    <li className={styles.h1}>
                                                        Dane do projektu
                                                    </li> 
                                                    {pdfState.reportItem.projectInfo && (
                                                        <CalculationSection
                                                            groundLevel={pdfState.results.groundLevel}
                                                            negativeFriction={pdfState.results.negativeFriction}
                                                            newEmbankment={pdfState.results.newEmbankment}
                                                            zwg={pdfState.results.zwg}
                                                            to={pdfState.calculations.to.value}
                                                            from={pdfState.calculations.from.value}
                                                            layersName={pdfState.calculations.layersName}
                                                        />
                                                    )}
                                                    {pdfState.reportItem.pileParameters && (
                                                        <PileParametersSection
                                                            currentPileLength={pdfState.results.currentPileLength}
                                                            diameterPile={pdfState.results.diameterPile}
                                                            baseWidth={pdfState.results.baseWidth}
                                                            pileHeadSpot={pdfState.results.pileHeadSpot}
                                                            pileType={pdfState.results.pileType}
                                                            pileTypeNumber={pdfState.results.pileTypeNumber}
                                                            hPile={pdfState.results.hPile}
                                                            hType={pdfState.results.hType}
                                                        />
                                                    )}
                                                    {
                                                        pdfState.reportItem.helperFactorsDetails && (
                                                            <TechnologyFactors
                                                                ygq={pdfState.results.ygq}
                                                                yst={pdfState.results.yst}
                                                                yt={pdfState.results.yt}
                                                                softSoilCount={pdfState.results.softSoilCount}
                                                                hardSoilCount={pdfState.results.hardSoilCount}
                                                                qccuInterpolation={pdfState.results.qccuInterpolation}
                                                            />
                                                        )
                                                    }
                                                    
                                                </Fragment>
                                            )}
                                            {(
                                                pdfState.reportItem.capacityDetails || pdfState.reportItem.capacityTable
                                            ) && (
                                                <Fragment>
                                                    <div className={styles.break}></div>
                                                    <li className={styles.h1}>
                                                        Wyniki obliczeń
                                                    </li> 
                                                    {pdfState.reportItem.capacityDetails && (
                                                        <CapacityResults
                                                            currentCapacity={pdfState.results.currentCapacity}
                                                            pileTypeNumber={pdfState.results.pileTypeNumber}
                                                            yt={pdfState.results.yt}
                                                        />
                                                    )}
                                                    {
                                                        pdfState.reportItem.capacityTable && (
                                                            <CapacityTable
                                                                currentLength={pdfState.results.currentPileLength}
                                                                pileHeadSpot={pdfState.results.pileHeadSpot}
                                                                pileTypeNumber={pdfState.results.pileTypeNumber}
                                                                results={pdfState.results.results}
                                                                yt={pdfState.results.yt}
                                                                yst={pdfState.results.yst}
                                                            />
                                                        )
                                                    }
                                                    <div className={styles.break}></div>
                                                </Fragment>
                                            )} 
                                            {(pdfState.reportItem.pileChart || pdfState.reportItem.resistanceSettlement || pdfState.reportItem.cptChart) && (
                                                <Fragment>
                                                    <li className={styles.h1}>
                                                        Załączniki
                                                    </li> 
                                                    <Fragment>
                                                        {
                                                            (pdfState.reportItem.pileChart && 
                                                                <Fragment>
                                                                    <CapacityChart
                                                                        soilProfile={pdfState.results.soilProfile}
                                                                        results={pdfState.results.results}
                                                                        pileTypeNumber={pdfState.results.pileTypeNumber}
                                                                        pileHeadSpot={pdfState.results.pileHeadSpot}
                                                                        groundLevel={pdfState.results.groundLevel}
                                                                        diameterPile={pdfState.results.diameterPile}
                                                                        title={"Wykres nośności pala wciskanego wg zaleceń EAP"}
                                                                        type="nt"
                                                                        currentPileLength={pdfState.results.currentPileLength}
                                                                        currentCapacity={pdfState.results.currentCapacity}
                                                                        yt={pdfState.results.yt}
                                                                        yst={pdfState.results.yst}
                                                                        charLoad={pdfState.results.charLoad}
                                                                        hPile={pdfState.results.hPile}
                                                                    />
                                                                    <div className={styles.break}></div>
                                                                    <CapacityChart
                                                                        soilProfile={pdfState.results.soilProfile}
                                                                        results={pdfState.results.results}
                                                                        pileTypeNumber={pdfState.results.pileTypeNumber}
                                                                        pileHeadSpot={pdfState.results.pileHeadSpot}
                                                                        groundLevel={pdfState.results.groundLevel}
                                                                        diameterPile={pdfState.results.diameterPile}
                                                                        title={"Wykres nośności pala wyciąganego wg zaleceń EAP"}
                                                                        type="nw"
                                                                        currentPileLength={pdfState.results.currentPileLength}
                                                                        currentCapacity={pdfState.results.currentCapacity}
                                                                        yt={pdfState.results.yt}
                                                                        yst={pdfState.results.yst}
                                                                        charLoad={pdfState.results.charLoad}
                                                                        hPile={pdfState.results.hPile}
                                                                    />
                                                                    <div className={styles.break}></div>
                                                                </Fragment>
                                                            )
                                                        }
                                                        {
                                                            (
                                                                pdfState.reportItem.resistanceSettlement &&
                                                                <Fragment>
                                                                    <SettlementChart
                                                                        soilProfile={pdfState.results.soilProfile}
                                                                        results={pdfState.results.results}
                                                                        pileTypeNumber={pdfState.results.pileTypeNumber}
                                                                        pileHeadSpot={pdfState.results.pileHeadSpot}
                                                                        diameterPile={pdfState.results.diameterPile}
                                                                        title={"Wykres osiadania głowicy pala w zależności od obciążenia"}
                                                                        currentPileLength={pdfState.results.currentPileLength}
                                                                        currentCapacity={pdfState.results.currentCapacity}
                                                                        hType={pdfState.results.hType}
                                                                    />
                                                                    <div className={styles.break}></div>
                                                                </Fragment>

                                                            )
                                                        }
                                                        <div className={styles.avoidBreak}>
                                                            {
                                                                pdfState.reportItem.cptChart &&
                                                                <Fragment>
                                                                    <h2>Wykresy sondowń CPT (q<sub>c</sub> ora c<sub>u</sub>)</h2>
                                                                    <div className={styles.imgBorder} style={{ width: `${maxWidth}px` }}>
                                                                        <img alt='' src={dataURI} />
                                                                    </div>
                                                                    <div className={styles.imgBorder} style={{ width: `${maxWidth}px` }}>
                                                                        <img alt='' src={dataURICu} />
                                                                    </div>
                                                                </Fragment>
                                                            }
                                                        </div>
                                                    </Fragment>
                                                </Fragment>
                                            )}
                                        </div>
                                    </ol>
                                </main>
                            </td> 
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <div
                                    style={{ height: footerHeight }}
                                    className={styles.footerSpace}></div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <ResultsHeader
                    author={pdfState.report.author}
                    contact={pdfState.report.contact}
                />
                <ResultsFooter
                    setFooterHeight={useCallback((val) => setFooterHeight(val),[])}
                    pdfState={pdfState.reportItem}
                    globalTitle="Nośność pionowa pali wg niemieckich zaleceń do projektowania EA-Pf&auml;hle"
                    title={pdfState.projectTitle.projectTitle}
                />
            </div>
            {
                pdfState.reportItem.cptChart &&
                <Fragment>
                    <div className={styles.chartsWrapper}>
                        <QcChart
                            maxWidth={maxWidth}
                            setDataURI={setDataURI}
                            chartTitle="Opór stożka sondy CPT - qc [MPa]"
                            xLabel="qc [MPa]"
                            yLabel="Głębokość Z [m.p.p.t]"
                            results={pdfState.soilsCollection.map(state => {
                                return {
                                    qc: state.qc.value,
                                    z: state.z.value,
                                    soil: state.soilType.value,
                                    zSoil: state.zSoil.value
                                }
                            })}
                        />
                        <CuChart
                            maxWidth={maxWidth}
                            setDataURI={setDataURICu}
                            chartTitle="Wytrzymałość gruntu na ścinanie - cu [kPa]"
                            yLabel="Głębokość Z [m.p.p.t]"
                            xLabel="cu [kPa]"
                            results={pdfState.soilsCollection.map(state => {
                                return {
                                    qc: state.qc.value,
                                    cu: state.cu.value,
                                    z: state.z.value,
                                    soil: state.soilType.value,
                                    zSoil: state.zSoil.value
                                }
                            })}
                        />
                    </div>
                </Fragment>

            }
        </Fragment>
    );
});

export default PdfReport;