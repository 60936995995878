import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import error404 from '../../assets/404.svg';

const Image = styled.img`
    max-width: 350px;
    text-align: center;
    display: block;
    padding-top: 150px;
    margin: 0px auto 10px auto;
`;
const H1 = styled.h1`
    text-align: center;
    font-size: 36px;
    margin-top: 30px;
`;
const Error = () => {
    const [isloaded, setIsLoaded] = React.useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsLoaded(true);
        }, 100);
        document.title = 'Strona nie istnieje!'

        return () => document.title = ''
    },[]);

    return (
        <Fragment>
            {
                isloaded && (
                    <div id="not-found-404">
                        <Image alt="Page not found" src={error404}></Image>
                        <H1>Niestety, ta strona nie istnieje!</H1>
                    </div>
                )
            }
        </Fragment>
    );
};

export default Error;
