import { isNumber } from "../../../utils"

const nkInterpolation = ({ qc, type }) => {
    if(isNumber(type)){
        if(type === 1) {
            if(qc < 0.5) {
                return 12
            } else if(qc > 2.5){
                return 25
            } else {
                return 12 + (qc - 0.5)*13/2
            }
        } else if(type === 2) {
            if(qc < 1.5) {
                return 12
            } else if(qc > 7){
                return 20
            } else {
                return 12 + (qc - 1.5)*8/5.5
            }
        } else if(type === 3) {
            if(qc < 1.2) {
                return 6
            } else if(qc > 3.5){
                return 15
            } else {
                return 6 + (qc - 1.2)*9/2.3
            }
        } else if(type === 4) {
            if(qc < 1.3) {
                return 8
            } else if(qc > 4.5){
                return 14
            } else {
                return 8 + (qc - 1.3)*6/3.2
            }
        } else if(type === 5) {
            if(qc < 0.2) {
                return 1
            } else if(qc > 4){
                return 6
            } else {
                return 1 + (qc - 0.2)*5/3.8
            }
        } else {
            return '';
        }        
    } else {
        return '';
    }

}

export default nkInterpolation