import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import { InputField } from "..";
import { isEmail } from "../../config/ProjectConfig";

import Button from "../Button";
import Modal  from "../Modal";
import styles from './index.module.css';

const ContactForm = ({
    open, 
    onHide,
    state,
    onChange,
    onSubmit
}) => {

    return (
        <Modal open={open} css={styles.modalOverflow} cssDialog={styles.modal} onHide={onHide} hideButton={false} title="Formularz kontaktowy">
            <Row className={styles.row}>
                <Col xs={12} sm={6}>
                    <InputField
                        onChange={onChange}
                        value={state.contactFormDetails.firstName.value}
                        isValid={state.contactFormDetails.firstName.isValid}
                        title="Imię"
                        name="firstName"
                        placeholder="Imię"
                        disable={true}

                        required
                    />
                </Col>
                <Col xs={12} sm={6}>
                    <InputField
                        onChange={onChange}
                        value={state.contactFormDetails.lastName.value}
                        isValid={state.contactFormDetails.lastName.isValid}
                        title="Nazwisko"
                        name="lastName"
                        placeholder="Nazwisko"
                        disable={true}

                        required
                    />
                </Col>
            </Row>
            <Row className={styles.row}>
                <Col xs={12}>
                    <InputField
                        onChange={(e) => onChange(e, e.currentTarget.closest('input').checkValidity() && isEmail.test(e.currentTarget.closest('input').value))}
                        value={state.contactFormDetails.email.value}
                        isValid={state.contactFormDetails.email.isValid}
                        title="Adres email do kontaktu"
                        name="email"
                        placeholder="Adres email"
                        type="text" 
                        disable={true}

                        required
                    />
                </Col>
            </Row>
            <Row className={styles.row}>
                <Col xs={12}>
                    <InputField
                        onChange={onChange}
                        value={state.contactFormDetails.content.value}
                        isValid={state.contactFormDetails.content.isValid}
                        css={styles.textarea}
                        title="Treść wiadomości"
                        name="content"
                        placeholder="Treść wiadomości"
                        textarea={true}

                        required
                    />
                </Col>
            </Row>
            <Row className={styles.row}>
                <Col xs={12}>
                    <Button
                        onClick={onSubmit}
                        icon={faPaperPlane}
                        title="Wyślij wiadomość"
                        css={"default-button-style"}
                        name="sendMessage"
                        altTitle="Wyślij wiadomość"
                    />
                </Col>    
            </Row>
        </Modal>
    )
}

export default ContactForm