import React, { Fragment, useState } from 'react';
import { withPagination } from '../../../../../hoc';
import {
    ItemsPerPage,
    Pagination,
    SectionTitle,
    Table,
} from '../../../../../components';
import styles from './index.module.css';
import round from '../../../../../utils/roundNumber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import CorrectDepth from '../CorrectDepth';
import { isNumber } from '../../../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const TechnologyFactorsDesign = ({
    dataToPaginate,
    allCollection = [],
    pagination,
    paginationHelpers,
}) => {
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [a1, setA1] = useState(false);
    const [a2, setA2] = useState(false);

    useDeepCompareEffect(() => {
        setCsvData(
            allCollection
                .map(
                    ({
                        z,
                        qcd,
                        tcd,
                        a1,
                        a2,
                        capacityParameters: {
                            hci,
                            hz,
                            name,
                            sp,
                            ss,
                            sw,
                            tci,
                            ym,
                        } = {},
                    }) => ({
                        z,
                        qcd,
                        tcd,
                        ...(isNumber(a1) &&
                            isNumber(a2) && {
                                a1,
                                a2,
                            }),
                        hci,
                        hz,
                        name,
                        sp,
                        ss,
                        sw,
                        tci,
                        ym,
                    }),
                )
                .map((ele) =>
                    Object.fromEntries(
                        Object.entries(ele).map(([key, value = '']) => [
                            [key],
                            value.toString().replace('.', ','),
                        ]),
                    ),
                ),
        );

        setCsvHeaders([
            {
                label: 'Z [m.p.p.t.]',
                key: 'z',
            },
            {
                label: 'H [m.p.p.t.]',
                key: 'hz',
            },
            {
                label: 'qcd [kPa]',
                key: 'qcd',
            },
            {
                label: 'tcd [kPa]',
                key: 'tcd',
            },
            {
                label: 'hci [m]',
                key: 'hci',
            },
            {
                label: 'tci [m]',
                key: 'tci',
            },
            {
                label: 'ym [-]',
                key: 'ym',
            },
            {
                label: 'Sp [-]',
                key: 'sp',
            },
            {
                label: 'Ss [-]',
                key: 'ss',
            },
            {
                label: 'Sw [-]',
                key: 'sw',
            },
        ]);
    }, [allCollection]);

    useDeepCompareEffect(() => {
        const isA1 = csvData.some((ele) =>
            Object.keys(ele).includes('a1'),
        );
        const isA2 = csvData.some((ele) =>
            Object.keys(ele).includes('a2'),
        );

        setA1(isA1);
        setA2(isA2);

        if (isA1 && isA2) {
            setCsvHeaders((state) => {
                return [
                    ...state,
                    {
                        label: 'a1 [-]',
                        key: 'a1',
                    },
                    {
                        label: 'a2 [-]',
                        key: 'a2',
                    },
                ];
            });
        }
    }, [csvData]);

    return (
        <Fragment>
            <SectionTitle
                title={'Współczynniki technologiczne i bezpieczeństwa'}
            />
            <ItemsPerPage
                onDisplayItemsNumber={paginationHelpers.displayItemsNumber}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                display={[15, 30, paginationHelpers.allItemsLength]}
            />
            <Table
                csvData={csvData}
                csvFilename={'technologyFactors'}
                csvHeaders={csvHeaders}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th title="Rzędna">
                            z<br></br>[m.p.p.t.]
                        </th>
                        <th title="Nazwa warstwy">
                            Nazwa<br></br>[-]
                        </th>
                        <th title="Poziom interpolacji">
                            H<sub>z</sub>
                            <br></br>[m.p.p.t.]
                        </th>
                        <th title="Obliczeniowy opór pod podstawą">
                            q<sup>(r)</sup>
                            <br></br>[kPa]
                        </th>
                        <th title="Obliczeniowy opór na pobocznicy">
                            t<sup>(r)</sup>
                            <br></br>[kPa]
                        </th>
                        <th title="Głębokość interpolacji oporu pod podstawą">
                            h<sub>ci</sub>
                            <br></br>[m]
                        </th>
                        <th title="Głębokość interpolacji oporu na pobocznicy">
                            t<sub>ci</sub>
                            <br></br>[m]
                        </th>
                        <th title="Współczynnik materiałowy dla oporów q i t">
                            &#947;<sub>m</sub>
                            <br></br>[-]
                        </th>
                        {a1 && a2 && (
                            <Fragment>
                                <th title="Współczynnik redukcyjny dla pali rurowych otwartych">
                                    a<sub>1</sub>
                                    <br></br>[-]
                                </th>
                                <th title="Współczynnik redukcyjny dla pali rurowych otwartych">
                                    a<sub>2</sub>
                                    <br></br>[-]
                                </th>
                            </Fragment>
                        )}
                        <th title="Współczynnik technologiczny dla podstawy">
                            s<sub>p</sub>
                            <br></br>[-]
                        </th>
                        <th title="Współczynnik technologiczny dla pobocznicy">
                            s<sub>s</sub>
                            <br></br>[-]
                        </th>
                        <th title="Współczynnik technologiczny dla wyciągania pala">
                            s<sub>w</sub>
                            <br></br>[-]
                        </th>
                        <th title="Poprawność zagłębienia pala w gruncie"></th>
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    {dataToPaginate.map(
                        (
                            {
                                correctDepth = {},
                                capacityParameters = {},
                                ...element
                            },
                            index,
                        ) => {
                            const {
                                correctBaseDepth,
                                correctMultiLayerDepth,
                                isCohesive,
                                isSoftLayer,
                            } = correctDepth;
                            const correctLength =
                                correctBaseDepth >= 1 &&
                                correctMultiLayerDepth &&
                                !isCohesive &&
                                !isSoftLayer;
                            const warningDepth =
                                correctBaseDepth > 0 &&
                                correctBaseDepth < 1 &&
                                correctMultiLayerDepth &&
                                !isCohesive &&
                                !isSoftLayer;
                            return (
                                <tr
                                    className={`${styles.tr} ${
                                        correctLength
                                            ? styles.validDepth
                                            : warningDepth
                                            ? styles.warningDepth
                                            : styles.wrongDepth
                                    }`}
                                    key={index}>
                                    <td>
                                        {paginationHelpers.countingItemsPerPage +
                                            index +
                                            1}
                                    </td>
                                    <td>{round(element.z, 2)}</td>
                                    <td>{capacityParameters.name}</td>
                                    <td>{round(capacityParameters.hz, 2)}</td>
                                    <td>{round(element.qcd, 2)}</td>
                                    <td>{round(element.tcd, 2)}</td>
                                    <td>{round(capacityParameters.hci, 2)}</td>
                                    <td>{round(capacityParameters.tci, 2)}</td>
                                    <td>{round(capacityParameters.ym, 2)}</td>
                                    {isNumber(element.a1) &&
                                        isNumber(element.a2) && (
                                            <Fragment>
                                                <td>{round(element.a1, 2)}</td>
                                                <td>{round(element.a2, 2)}</td>
                                            </Fragment>
                                        )}
                                    <td>{round(capacityParameters.sp, 2)}</td>
                                    <td>{round(capacityParameters.ss, 2)}</td>
                                    <td>{round(capacityParameters.sw, 2)}</td>
                                    <td>
                                        <FontAwesomeIcon
                                            icon={
                                                correctLength
                                                    ? faCheckCircle
                                                    : warningDepth
                                                    ? faCheckCircle
                                                    : faTimesCircle
                                            }
                                        />
                                        <div className={styles.info}>
                                            <CorrectDepth
                                                className={styles.depthColor}
                                                correctDepth={correctDepth}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            );
                        },
                    )}
                </tbody>
            </Table>

            <Pagination
                currentPage={pagination.currentPage}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                nextPage={paginationHelpers.nextPage}
            />
        </Fragment>
    );
};

export default withPagination({
    component: TechnologyFactorsDesign,
    returnAllCollection: true,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 25,
    },
});
