import isNumber from "../isNumber";
import splitSoilsByWaterLevel from "../splitSoilsByWaterLevel";
import stringToNumber from "../stringToNumber";

const hz = (arrToLast, twoLastElements, waterLevel) => {
    const sumAboveLayers = splitSoilsByWaterLevel(arrToLast, waterLevel).map(ele => {
        if(isNumber(waterLevel.value) && waterLevel.value >=0){
            if(ele.height.value <= waterLevel.value){
                return ele.absHeight.value*ele.weight.value
            } else {
                return ele.absHeight.value*ele.underWaterWeight.value
            }
        } else {
            return ele.absHeight.value*ele.weight.value
        }                
    }).reduce((acc, val) => acc+val, 0);

    if(isNumber(waterLevel.value) && (twoLastElements[1].height.value > waterLevel.value)){
        const calcHz = stringToNumber((twoLastElements[0].height.value - Number((0.65*sumAboveLayers/(twoLastElements[1].underWaterWeight.value)))).toFixed(2));
        return calcHz >= 0 ? calcHz : 0;
    } else {
        const calcHz = stringToNumber((twoLastElements[0].height.value - Number((0.65*sumAboveLayers/(twoLastElements[1].weight.value)))).toFixed(2));
        return calcHz >= 0 ? calcHz : 0;
    }
}

const pileInterpolationLevel = ({ arr, waterLevel={}, negativeFrictionLayers, isNegativeFriction=false, type=1 }) => {
    const arrLength = arr.length;
    const twoLastElements = arr.slice(-2);
    const arrToLastElement = arr.slice(0, arrLength-1);
    const isValid = arr
                    .map(({ height, absHeight, weight, underWaterWeight, layerNo, qc, t }) => ({ height, absHeight, weight, underWaterWeight, layerNo, qc, t }))
                    .every(ele => Object.values(ele).every(ele => ele.hasOwnProperty('isValid') ? ele.isValid : isNumber(ele.value)));
                    
    if(isValid && Array.isArray(twoLastElements)){
        const isSecondToLastSoftSoil = twoLastElements[0].t.value === 0 && twoLastElements[0].qc.value === 0;
        const isSecondToLastSoftSoilFromGround = (twoLastElements[0].t.value === 0 && twoLastElements[0].qc.value === 0) || (isNegativeFriction && negativeFrictionLayers[twoLastElements[0].layerNo.value].value)

        if(twoLastElements.length === 1){ 
            if(type === 1){
                if(!isSecondToLastSoftSoil) {
                    return 0;
                } else {
                    return ''
                }
            } else {
                if(!isSecondToLastSoftSoilFromGround){
                    return 0;
                } else {
                    return ''
                }
            }
        } else if(twoLastElements.length === 2){
            const isSoilsSoft = arrToLastElement.every(ele => (ele.t.value === 0 && ele.qc.value === 0 ) || (isNegativeFriction && negativeFrictionLayers[ele.layerNo.value].value));
            const isLastLayerNegative = isNegativeFriction && negativeFrictionLayers[twoLastElements[1].layerNo.value] ? negativeFrictionLayers[twoLastElements[1].layerNo.value].value : false;

            const isLastSoil = twoLastElements[1].t.value > 0 || twoLastElements[1].qc.value > 0;
            const isLastSoilNegative = isLastSoil && !isLastLayerNegative;

            if(type === 1) {
                if(isSecondToLastSoftSoil && isLastSoil){
                    return hz(arrToLastElement, twoLastElements, waterLevel);
                } else {
                    return ''
                }                
            } else {
                if(isSoilsSoft && isLastSoilNegative){
                    return hz(arrToLastElement, twoLastElements, waterLevel);
                } else {
                    return ''
                }
            }
        } else {
            return ''
        }
    } else {
        return ''
    }
}

export {
    pileInterpolationLevel
}