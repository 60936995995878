import React from 'react';
import styled from 'styled-components';
import { Form, Button } from 'react-bootstrap';
import Email from '../Email';
import Password from '../Password';
import ConfirmPassword from '../ConfirmPassword';
import { AlertMessage2 } from '../../../components';
import { withLoading, withAlert, withForm } from '../../../hoc';
import { isPassword } from '../../../config/ProjectConfig';

const PasswordContent = styled.div`
    margin-bottom: 100px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 100px;
    & form {
        background-color: #fff;
        padding: 20px;
        margin-top: 15px;
        box-shadow: 0 0.4rem 0.8rem -0.1rem rgba(0, 32, 128, 0.1),
            0 0 0 1px #f0f2f7;
    }
`;
const H1 = styled.h1`
    font-size: 28px;
    color: #333;
    text-align: center;
`;

const ResetPasswordForm = ({
    responseData = {},
    formState,
    messageState = {}, 
    onSubmit,
    onChange,
    actions = {},
}) => {
    const onConfirmPassword = (e) => {
        let isPasswordCorrect = !!(
            e.target.checkValidity() &&
            isPassword.test(e.target.value) &&
            e.target.value === formState.password.value
        );
        onChange(e, isPasswordCorrect);
    };
    const onSubmitForm = (e) => {
        e.preventDefault();

        onSubmit(
            (formData) => {
                actions.saveNewPassword({
                    body: formData,
                });
            },
            {
                confirmPassword: {
                    ...formState['confirmPassword'],
                    isValid:
                        formState.confirmPassword.value.length > 0 &&
                        formState.password.value ===
                            formState.confirmPassword.value,
                },
            },
        );
    };

    return (
        <PasswordContent>
            <H1>Zmień aktualne hasło</H1>
            <Form onSubmit={onSubmitForm} noValidate>
                <Email userEmail={responseData.email} />
                <Password password={formState.password} onChange={onChange} />
                <ConfirmPassword
                    confirmPassword={formState.confirmPassword}
                    onChange={onConfirmPassword}
                />

                <Button variant="danger" type="submit">
                    Zmień hasło
                </Button>
                <AlertMessage2
                    {...messageState.saveNewPassword} 
                    isLoaded={formState.isLoaded}
                    loadingName={formState.loadingName}
                />
            </Form>
        </PasswordContent>
    );
};

export default withForm({
    component: withLoading(withAlert(ResetPasswordForm)),
    clearFormToDefaultState: true,
    actions: [
        {
            name: 'saveNewPassword',
            url: '/api/auth/reset-password/:id',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            successMessage: 'Hasło zostało zmienione',
            errorMessage: 'Zmiana hasła się nie powiodła',
            showErrorMessage: true,
            showSuccessMessage: true,
        },
    ],
    localState: {
        loadingName: '',
        password: {
            value: '',
            isValid: null,
        },
        confirmPassword: {
            value: '',
            isValid: null,
        },
    },
});
