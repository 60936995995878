import { useCallback, useEffect, useState } from 'react';
import {
    isMicropileAngleCorrect,
    isExcavationBaseCorrect,
    isSlipPlateCorrect,
} from '../../../config/ProjectConfig';
import { isNumber, makeNumberPositive, stringToNumber } from '../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const defaultState = {
    micropileHeadSpot: {
        value: '',
        isValid: null,
    },
    micropileAngle: {
        value: '',
        isValid: null,
    },
    micropileScrewDiameter: {
        value: '',
        isValid: null,
    },
    axisLoad: {
        value: '',
        isValid: null,
    },
    isSlipPlate: {
        value: false,
        isValid: true,
    },
    slipPlate: {
        value: '',
        isValid: null,
    },
    micropileType: {
        value: 1,
        isValid: true,
    },
    isSteelRod: {
        value: false,
        isValid: true,
    },
    isDeepPlate: {
        value: false,
        isValid: true,
    },
    rodType: {
        value: '',
        isValid: null,
    },
    screwType: {
        value: '',
        isValid: true,
    },
    screwTypeDiameter: {
        value: '',
        isValid: null,
    },
    rodThreadbarType: {
        value: '',
        isValid: null,
    },
    isFreeLength: {
        value: false,
        isValid: true,
    },
    freeLength: {
        value: '',
        isValid: null,
    },
};

const mapRodTypes = (ele) =>
    `${ele.prod} - ${ele.name} ${ele.subname} | (${ele.currentCapacityValue}kN)`;
const mapScrewTypes = (ele) => `${ele[0]} - ${ele[1]}mm`;

const mapThreadBarTypes = (ele) =>
    `${ele.prod} - ${ele.name} - ${ele.dout}mm | (${ele.currentCapacityValue}kN)`;

const MicropilePropertiesHelpers = ({ profileParameters = {}, maxHeight, updateResultsState }) => {
    const [pilePropertiesState, setPilePropertiesState] = useState(defaultState);
    const [allRods, setAllRods] = useState([]);
    const [allScrews, setAllScrews] = useState([]);

    useDeepCompareEffect(() => {
        const singleRod = allRods.find(
            (ele) => mapRodTypes(ele) === pilePropertiesState.rodType.value,
        );

        setAllScrews(singleRod ? singleRod.drill : []);
    }, [pilePropertiesState.rodType.value, allRods]);

    useDeepCompareEffect(() => {
        const singleScrew = allScrews.find(
            (ele) => mapScrewTypes(ele) === pilePropertiesState.screwType.value,
        );

        setPilePropertiesState((state) => ({
            ...state,
            ...(!!state.screwType.value && {
                screwTypeDiameter: {
                    value: Array.isArray(singleScrew) ? singleScrew[1] : '',
                    isValid:
                        Array.isArray(singleScrew) && isNumber(singleScrew[1])
                            ? true
                            : null,
                },
            }),
        }));
    }, [pilePropertiesState.screwType.value, allScrews]);

    useEffect(() => {
        if (
            isNumber(profileParameters.sheetPileSpot.value) ||
            isNumber(maxHeight)
        ) {
            setPilePropertiesState((state) => ({
                ...state,
                ...(isNumber(state.slipPlate.value) &&
                    state.isSlipPlate.value && {
                        slipPlate: {
                            ...state['slipPlate'],
                            isValid: isSlipPlateCorrect(
                                state.slipPlate.value,
                                maxHeight,
                            ),
                        },
                    }),
                ...(isNumber(state.micropileHeadSpot.value) && {
                    micropileHeadSpot: {
                        ...state['micropileHeadSpot'],
                        isValid: isExcavationBaseCorrect(
                            state.micropileHeadSpot.value,
                            profileParameters.sheetPileSpot.value,
                        ),
                    },
                }),
            }));
        }
    }, [profileParameters.sheetPileSpot.value, maxHeight]);

    const onBlurPilePropertiesState = useCallback(
        (e) => {
            const { name, value } = e.currentTarget;
            const valid = e.currentTarget.checkValidity();
            const greaterThanZeroProps = [
                'micropileScrewDiameter',
                'micropileAngle',
                'micropileHeadSpot'
            ];

            setPilePropertiesState((state) => {
                return {
                    ...state,
                    [name]: {
                        ...state[name],
                        isValid: greaterThanZeroProps.includes(name)
                            ? value > 0 && valid
                            : valid,
                    },
                    ...(name === 'micropileAngle' && {
                        [name]: {
                            ...state[name],
                            isValid: isMicropileAngleCorrect(
                                stringToNumber(value),
                            ),
                        },
                    }),
                    ...(name === 'micropileHeadSpot' && {
                        [name]: {
                            ...state[name],
                            isValid:
                                stringToNumber(value) >= 0 &&
                                profileParameters.sheetPileSpot.value >=
                                    value &&
                                valid,
                        },
                    }),
                    ...(name === 'slipPlate' && {
                        [name]: {
                            ...state[name],
                            isValid: maxHeight >= stringToNumber(value) && valid && value > 0,
                        },
                    }),
                };
            });
        },
        [profileParameters.sheetPileSpot.value, maxHeight],
    );

    const onChangePilePropertiesState = useCallback((e) => {
        const input =
            e.currentTarget.closest('input') ||
            e.currentTarget.closest('select');

        if (input) {
            const { name, value, type, checked } = input;

            updateResultsState((state) => {
                return {
                    ...state,
                    isResultsActual: false,
                };
            });

            if (type === 'radio') {
                setPilePropertiesState((state) => {
                    return {
                        ...state,
                        [name]: {
                            value: Number(value),
                            isValid: true,
                        },
                    };
                });
            } else if (type === 'checkbox') {
                setPilePropertiesState((state) => ({
                    ...state,
                    [name]: {
                        ...state[name],
                        value: !!checked,
                    },
                }));
            } else {
                setPilePropertiesState((state) => {
                    return {
                        ...state,
                        ...(name === 'axisLoad' && {
                            rodType: {
                                value: '',
                                isValid: true,
                            },
                            rodThreadbarType: {
                                value: '',
                                isValid: true,
                            },
                            screwType: {
                                value: '',
                                isValid: true,
                            },
                            screwTypeDiameter: {
                                value: '',
                                isValid: null,
                            },
                        }),
                        ...(name === 'screwTypeDiameter' && {
                            screwType: {
                                value: '',
                                isValid: true,
                            },
                        }),
                        ...(name === 'rodType' &&
                            state.rodType.value.length > 0 && {
                                screwType: {
                                    value: '',
                                    isValid: true,
                                },
                                screwTypeDiameter: {
                                    value: '',
                                    isValid: null,
                                },
                            }),
                        [name]: {
                            value:
                                type === 'number' && value.length > 0
                                    ? makeNumberPositive(stringToNumber(value))
                                    : value,
                            isValid: true,
                        },
                    };
                });
            }
        }
    }, []);

    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    }, []);

    const loadProject = (micropilePropertiesObj = {}) => {
        setPilePropertiesState(() => {
            return {
                ...defaultState,
                ...micropilePropertiesObj,
            };
        });
    };

    const micropileProperties = {
        onChange: onChangePilePropertiesState,
        onBlur: onBlurPilePropertiesState,
        onKeyDown: onKeyDown,
        state: {
            ...pilePropertiesState,
            allRods:
                pilePropertiesState.micropileType.value === 1
                    ? allRods.map(mapRodTypes)
                    : allRods.map(mapThreadBarTypes),
            allScrews: allScrews.map(mapScrewTypes),
        },
        stateToSave: {
            ...pilePropertiesState,
        },
        updateState: setPilePropertiesState,
        updateRods: useCallback((obj) => setAllRods(obj),[]),
        loadProject: loadProject,
        initState: () => {
            setPilePropertiesState(defaultState);
            setAllRods([]);
        },
    };

    return {
        micropileProperties,
    };
};

export default MicropilePropertiesHelpers;
