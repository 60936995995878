import { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    Button,
    InputField,
    PasswordInput,
    SectionTitle,
} from '../../../../components';
import ConfirmPassword from '../../../../components/ConfirmPassword';
import styles from './index.module.css';

const ChangePassword = ({ onSubmit, onChange, onConfirmPassword, state }) => {
    return (
        <Fragment>
            <Row className={styles.row}>
                <Col xs={12} sm={7}>
                    <div className={styles.wrapper}>
                        <SectionTitle title="Zmiana hasła" />
                        <div className={`backgroundAround`}>
                            <div className={styles.inputSpace}>
                                <InputField
                                    onChange={onChange}
                                    value={
                                        state.changePassword.currentPassword
                                            .value
                                    }
                                    isValid={
                                        state.changePassword.currentPassword
                                            .isValid
                                    }
                                    name="currentPassword"
                                    type="password"
                                    title="Bieżące hasło"
                                    placeholder="Bieżące hasło"
                                    required
                                />
                            </div>
                            <div className={styles.inputSpace}>
                                <PasswordInput
                                    onChange={onChange}
                                    value={
                                        state.changePassword.newPassword.value
                                    }
                                    isValid={
                                        state.changePassword.newPassword.isValid
                                    }
                                    name="newPassword"
                                    type="password"
                                    title="Nowe hasło"
                                    placeholder="Nowe hasło"
                                    required
                                />
                            </div>
                            <div className={styles.inputSpace}>
                                <ConfirmPassword
                                    onChange={onConfirmPassword}
                                    value={
                                        state.changePassword.confirmPassword
                                            .value
                                    }
                                    isValid={
                                        state.changePassword.confirmPassword
                                            .isValid
                                    }
                                    name="confirmPassword"
                                    type="password"
                                    title="Powtórz nowe hasło"
                                    placeholder="Powtórz nowe hasło"
                                    required
                                />
                            </div>
                            <div className={styles.buttonToRight}>
                                <Button
                                    onClick={onSubmit}
                                    css={'default-button-style'}
                                    name="saveChanges"
                                    altTitle="Zapisz zmiany"
                                    title="Zapisz zmiany"
                                />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
};

export default ChangePassword;
