import { faCheckCircle } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import config from "../../../config/config";
import styles from './index.module.css';

const CheckoutSuccess = ({ link = true}) => {

    useEffect(() => {
        document.title = `Płatność zaakceptowana`;

        return () => {
            document.title = ''; 
        } 
    },[]);

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <div className={styles.wrapper}>
                        <div className={styles.icon}>
                            <h1>
                                <FontAwesomeIcon icon={faCheckCircle}/>
                            </h1>
                        </div>
                        <h2>Płatność zaakceptowana!</h2>
                        <p>Twoja transakcja została poprawnie rozliczona</p>

                        {
                            link &&
                            <button className={styles.button}>
                                <Link to={config.routes.dashboard.logIn} title="Zaloguj się">Powrót do logowania</Link>
                            </button>                            
                        }
                    </div>
                </Col>
            </Row>            
        </Container>

    )
}

export default CheckoutSuccess