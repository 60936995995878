import { Fragment, useEffect, useState } from 'react';
import { isNumber, roundNumber } from '../../../../../utils';
import styles from './index.module.css';

const Summary = ({
    title = '',
    moduleRodTitle = '',
    rodModule = '',
    steelRod = {},
    calculationParameters = {},
    currentMicropileLength = 0,
    currentCapacity = {},
    pull = false,
}) => {
    const [totalRodLength, setTotalRodLength] = useState({
        bondNt: 0,
        totalNt: 0,
        currentTotalLengthNt: 0,
    });

    useEffect(() => {
        const absMicropileLengthNt =
            (currentCapacity.z - calculationParameters.micropileHeadSpot) /
            Math.cos(
                (Math.abs(calculationParameters.micropileAngle) * Math.PI) /
                    180,
            );

        const freeLength =
            (calculationParameters.micropileHeadSpot < 0
                ? Math.abs(calculationParameters.micropileHeadSpot)
                : 0) /
            Math.cos(
                (Math.abs(calculationParameters.micropileAngle) * Math.PI) /
                    180,
            );

        const totNt =
            Math.ceil(
                (absMicropileLengthNt + calculationParameters.headLength) /
                    rodModule,
            ) * rodModule;
        const bondNt = totNt - (freeLength + calculationParameters.headLength);

        setTotalRodLength({
            bondNt: roundNumber(bondNt, 2),
            totalNt: roundNumber(totNt, 2),

            currentTotalLengthNt: roundNumber(
                absMicropileLengthNt + calculationParameters.headLength,
                2,
            ),
        });
    }, [
        currentCapacity.z,
        currentMicropileLength,
        rodModule,
        calculationParameters.micropileHeadSpot,
        calculationParameters.micropileAngle,
        calculationParameters.headLength,
    ]);

    return (
        <div className={styles.wrapper}>
            {
                <Fragment>
                    <h2>{title}</h2>

                    <p>
                        Projektowana siła obliczeniowa:{' '}
                        <span className={styles.value}>
                            {roundNumber(calculationParameters.axisLoad, 2)} kN
                        </span>
                    </p>
                    <p>
                        Obliczeniowa nośność mikropala:{' '}
                        <span className={styles.value}>
                            {roundNumber(
                                pull ? currentCapacity.ntw : currentCapacity.nt,
                                2,
                            )}{' '}
                            kN
                        </span>
                    </p>
                    {isNumber(currentMicropileLength) &&
                        isNumber(calculationParameters.micropileAngle) && (
                            <p>
                                Długość mikropala w gruncie:{' '}
                                <span className={styles.value}>
                                    {roundNumber(
                                        currentMicropileLength /
                                            Math.cos(
                                                (Math.abs(
                                                    calculationParameters.micropileAngle,
                                                ) *
                                                    Math.PI) /
                                                    180,
                                            ),
                                        2,
                                    ).toFixed(2)}{' '}
                                    m
                                </span>
                            </p>
                        )}
                    <p>
                        Średnica wiercenia:{' '}
                        <span className={styles.value}>
                            {roundNumber(calculationParameters.diameter, 2)} mm
                        </span>
                    </p>
                    {isNumber(currentCapacity.concreteVolume) && (
                        <p>
                            Objętość zaczynu cementowego:{' '}
                            <span className={styles.value}>
                                {roundNumber(
                                    currentCapacity.concreteVolume,
                                    2,
                                ).toFixed(2)}{' '}
                                m<sup>3</sup>
                            </span>
                        </p>
                    )}
                    {calculationParameters.isSteelRod && (
                        <Fragment>
                            <p>
                                Długość zakotwienia głowicy:{' '}
                                <span className={styles.value}>
                                    {calculationParameters.headLength} m
                                </span>
                            </p>
                            <p>
                                Długość całkowita żerdzi:{' '}
                                <span className={styles.value}>
                                    {totalRodLength.currentTotalLengthNt.toFixed(
                                        2,
                                    )}{' '}
                                    m
                                </span>
                            </p>
                            <p>
                                Ciężar żerdzi:{' '}
                                <span className={styles.value}>
                                    {roundNumber(
                                        totalRodLength.currentTotalLengthNt *
                                            steelRod.m,
                                        2,
                                    ).toFixed(2)}{' '}
                                    kg
                                </span>
                            </p>
                        </Fragment>
                    )}
                    {calculationParameters.isSteelRod &&
                    isNumber(rodModule) &&
                    rodModule > 0 ? (
                        <Fragment>
                            <h2>{moduleRodTitle}</h2>
                            {isNumber(totalRodLength.bondNt) && (
                                <p>
                                    Długość odcinka nośnego/buławy:{' '}
                                    <span className={styles.value}>
                                        {roundNumber(
                                            totalRodLength.bondNt,
                                            2,
                                        ).toFixed(2)}{' '}
                                        m
                                    </span>
                                </p>
                            )}
                            {isNumber(totalRodLength.totalNt) && (
                                <p>
                                    Długość całkowita mikropala:{' '}
                                    <span className={styles.value}>
                                        {totalRodLength.totalNt.toFixed(2)} m
                                    </span>
                                </p>
                            )}
                            {isNumber(steelRod.m) &&
                                isNumber(totalRodLength.totalNt) && (
                                    <p>
                                        Masa całkowita żerdzi:{' '}
                                        <span className={styles.value}>
                                            {roundNumber(
                                                totalRodLength.totalNt *
                                                    steelRod.m,
                                                2,
                                            ).toFixed(2)}{' '}
                                            kg
                                        </span>
                                    </p>
                                )}
                        </Fragment>
                    ) : (
                        <Fragment></Fragment>
                    )}
                </Fragment>
            }
        </div>
    );
};

export default Summary;
