/*Titan*/

const drillType = {
    0: 'Carbide CrC',
    1: 'Button',
    2: 'Carbide B',
    3: 'Clay Bit',
    4: 'Carbide CrC 2',
    5: 'Cross Cut',
    6: 'Carbide Y/CrC 3',
    7: 'Carbide Y CrC',
    8: 'Carbide TW',
    9: 'Carbide CrC/2',
}

const drill_127 = [
    [drillType[5], 200],
    [drillType[2], 200],
    [drillType[3], 220],
]

const drill_103 = [
    [drillType[5], 175],
    [drillType[2], 175],
    [drillType[8], 175],
    [drillType[3], 220],
    [drillType[5], 220],
    [drillType[3], 280],
]

const drill_73 = [
    [drillType[5], 130],
    [drillType[8], 130],
    [drillType[9], 130],
    [drillType[2], 130],
    [drillType[5], 175],
    [drillType[3], 200],
]

const drill_52 = [
    [drillType[5], 115],
    [drillType[0], 115],
    [drillType[2], 115],
    [drillType[3], 130],
    [drillType[5], 130],
    [drillType[3], 175],
]

const drill_40 = [
    [drillType[0], 70],
    [drillType[1], 70],
    [drillType[5], 90],
    [drillType[6], 90],
    [drillType[2], 90],
    [drillType[3], 110],
    [drillType[7], 115],
    [drillType[5], 115],
    [drillType[3], 150],
]

const drill_30 = [
    [drillType[0], 46],
    [drillType[1], 51],
    [drillType[2], 51],
    [drillType[1], 55],
    [drillType[1], 70],
    [drillType[3], 75],
    [drillType[4], 75],
    [drillType[5], 76],
    [drillType[5], 90],
    [drillType[3], 95],
]

const titan = [
{
    prod: 'TITAN',
    name: '30/16',
    subname: '',
    fu: 240,
    rmk: 190, //kN, nośność obliczeniowa
    drill: drill_30 //koronki wiertnicze
},
{
    prod: 'TITAN',
    name: '30/11',
    subname: '',
    fu: 305,
    rmk: 260,
    drill: drill_30
},
{
    prod: 'TITAN',
    name: '40/20',
    subname: '',
    fu: 530,
    rmk: 425,
    drill: drill_40
},
{
    prod: 'TITAN',
    name: '40/16',
    subname: '',
    fu: 650,
    rmk: 525,
    drill: drill_40
},
{
    prod: 'TITAN',
    name: '52/29',
    subname: '',
    fu: 813,
    rmk: 635,
    drill: drill_52
},
{
    prod: 'TITAN',
    name: '52/26',
    subname: '',
    fu: 880,
    rmk: 730,
    drill: drill_52
},
{
    prod: 'TITAN',
    name: '73/56',
    subname: '',
    fu: 1150,
    rmk: 830,
    drill: drill_73
},
{
    prod: 'TITAN',
    name: '73/53',
    subname: '',
    fu: 1180,
    rmk: 970,
    drill: drill_73
},
{
    prod: 'TITAN',
    name: '73/45',
    subname: '',
    fu: 1460,
    rmk: 1270,
    drill: drill_73
},
{
    prod: 'TITAN',
    name: '73/35',
    subname: '',
    fu: 1720,
    rmk: 1430,
    drill: drill_73
},
{
    prod: 'TITAN',
    name: '103/78',
    subname: '',
    fu: 2120,
    rmk: 1800,
    drill: drill_103
},
{
    prod: 'TITAN',
    name: '103/51',
    subname: '',
    fu: 3590,
    rmk: 2670,
    drill: drill_103
},
{
    prod: 'TITAN',
    name: '103/43',
    subname: '',
    fu: 4050,
    rmk: 3390,
    drill: drill_103
},
{
    prod: 'TITAN',
    name: '127/103',
    subname: '',
    fu: 2320,
    rmk: 2015,
    drill: drill_127
},
/*{
    name: '196/130',
    dout: 196,
    din: 130,
    a: 16077,
    fu: 9601,
    fupl: '',
    rmk: 6465,
    rmd: 6465/1.15,
    wx: 548,
    m: 127.3,
    g: 'prawy',
    drill: []
}*/
];

export default titan