import {
    faPlus,
    faShoppingCart,
    faTrashAlt,
    faDollar,
} from '@fortawesome/free-solid-svg-icons';
import { Form, Col, Row } from 'react-bootstrap';
import {
    Modal,
    Table,
    TableInput,
    Button,
    SelectField,
    SectionTitle,
    InputField,
    RadioInput1,
    Checkbox1
} from '../../../../components';
import styles from './index.module.css';
import { Fragment, useState } from 'react';

const NewUserModal = ({
    open,
    onOpen,
    onHide,
    state = [],
    currentUsers = 0,
    summaryCost,
    onChange,
    onSubmit,
    onBlur,
    onKeyDown,
    addRow,
    removeRow,

    invoiceType,
    invoiceState,
    saveFormData,
    onChangeInvoiceType,
    onChangeInvoice,
    onBlurInvoice,
    onChangeDataCheckbox,
}) => {
    const [openInvoice, setOpenInvoice] = useState(false);

    const onOpenInvoice = () => setOpenInvoice(true);
    const onHideInvoice = () => setOpenInvoice(false)
    return (
        <Fragment>
            <Modal
                open={open}
                css={styles.modalOverflow}
                cssDialog={styles.modal}
                onHide={onHide}
                title="Dodaj nowego użytkownika">
                <SectionTitle title="Podstawowe dane nowych użytkowników" />
                <Table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Nr</th>
                            <th>Imię</th>
                            <th>Nazwisko</th>
                            <th>Email</th>
                            <th>Typ subskrypcji</th>
                            <th>Cena</th>
                            <th></th>
                        </tr>
                    </thead>

                    {state.length > 0 && (
                        <tbody>
                            {state.map((element, index) => {
                                return (
                                    <tr key={index}>
                                        <td width={30}>{index + 1}</td>
                                        <td width={200}>
                                            <TableInput
                                                name="firstName"
                                                placeholder="Imię"
                                                data-row={index}
                                                onBlur={onBlur}
                                                onChange={onChange}
                                                onKeyDown={onKeyDown}
                                                value={element.firstName.value}
                                                isValid={element.firstName.isValid}
                                                required
                                            />
                                        </td>
                                        <td width={200}>
                                            <TableInput
                                                name="lastName"
                                                placeholder="Nazwisko"
                                                data-row={index}
                                                onBlur={onBlur}
                                                onChange={onChange}
                                                onKeyDown={onKeyDown}
                                                value={element.lastName.value}
                                                isValid={element.lastName.isValid}
                                                required
                                            />
                                        </td>
                                        <td width={250}>
                                            <TableInput
                                                name="email"
                                                placeholder="Adres email"
                                                data-row={index}
                                                onBlur={onBlur}
                                                onChange={onChange}
                                                onKeyDown={onKeyDown}
                                                value={element.email.value}
                                                isValid={element.email.isValid}
                                                required
                                            />
                                        </td>
                                        <td width={200}>
                                            <SelectField
                                                name="type"
                                                value={element.type.value}
                                                isValid={element.type.isValid}
                                                defaultOption="[-]"
                                                options={[
                                                    'Subskrypcja miesięczna',
                                                    'Subskrypcja roczna',
                                                ]}
                                                data-row={index}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                required
                                            />
                                        </td>
                                        <td width={150}>{element.price.value}</td>
                                        <td width={100}>
                                            <Button
                                                css={styles.deleteButton}
                                                variant="danger"
                                                title="Usuń"
                                                icon={faTrashAlt}
                                                altTitle={'Usuń użytkownika'}
                                                onClick={() => removeRow(index)}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    )}
                </Table>
                <div className={styles.actionsWrapper}>
                    <div className={styles.actions}>
                        <Button
                            css="default-button-style"
                            onClick={addRow}
                            title={'Dodaj użytkownika'}
                            icon={faPlus}
                        />
                    </div>
                </div>
                <Row>
                    <Col xs={12}>
                        <h4 className={styles.account}>
                            Liczba aktywnych subskrypcji przypisanych do konta:{' '}
                            {currentUsers}
                        </h4>
                        <h2 className={styles.cost}>
                            Łączny koszt: {summaryCost} zł
                        </h2>

                        <div className={styles.centerButton}>
                            <Button
                                icon={faShoppingCart}
                                variant={'info'}
                                onClick={() => {
                                    onHide();
                                    onOpenInvoice();
                                }} 
                                title={
                                    state.length === 1
                                        ? 'Zakup licencję'
                                        : 'Zakup licencje'
                                }
                            />
                        </div> 
                    </Col>
                </Row>
            </Modal>
            <Modal
                css={styles.modalOverflow}
                cssDialog={styles.modal1}
                onHide={() => {
                    onOpen(state => {
                        return {
                            ...state,
                            addNewUser: true
                        }
                    });
                    onHideInvoice();
                }}
                title="Dane do faktury"
                open={openInvoice}
                hideButton={false}>
                <Row className={styles.row2}>
                    <Col xs={12} sm={{ span: 10, offset: 1 }}>
                        <Form className={styles.radioForm}>
                            <RadioInput1
                                name="company"
                                label={'Faktura na firmę'}
                                onChange={() => onChangeInvoiceType(1)}
                                value={invoiceType === 1}
                            />
                            <RadioInput1
                                name="person"
                                label={'Faktura na osobę prywatną'}
                                onChange={() => onChangeInvoiceType(2)}
                                value={invoiceType === 2}
                            />
                        </Form>
                    </Col>
                </Row>
                <Row className={styles.row2}>
                    {invoiceType === 2 && (
                        <Fragment>
                            <Col xs={12} sm={6}>
                                <InputField
                                    title="Imię"
                                    placeholder={'Imię'}
                                    name="firstName"
                                    onChange={onChangeInvoice}
                                    onBlur={onBlurInvoice}
                                    value={invoiceState.firstName.value}
                                    isValid={invoiceState.firstName.isValid}
                                    required
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <InputField
                                    title="Nazwisko"
                                    placeholder={'Nazwisko'}
                                    name="lastName"
                                    onChange={onChangeInvoice}
                                    onBlur={onBlurInvoice}
                                    value={invoiceState.lastName.value}
                                    isValid={invoiceState.lastName.isValid}
                                    required
                                />
                            </Col>
                        </Fragment>
                    )}
                    {invoiceType === 1 && (
                        <Fragment>
                            <Col xs={12}>
                                <InputField
                                    title="Nazwa firmy"
                                    placeholder={'Nazwa firmy'}
                                    name="companyName"
                                    onChange={onChangeInvoice}
                                    onBlur={onBlurInvoice}
                                    value={invoiceState.companyName.value}
                                    isValid={invoiceState.companyName.isValid}
                                    required
                                />
                            </Col>
                        </Fragment>
                    )}
                </Row>
                {invoiceType === 1 && (
                    <Row className={styles.row2}>
                        <Col xs={12}>
                            <InputField
                                title="NIP"
                                placeholder={'NIP'}
                                name="nip"
                                onChange={onChangeInvoice}
                                onBlur={onBlurInvoice}
                                value={invoiceState.nip.value}
                                isValid={invoiceState.nip.isValid}
                                required
                            />
                        </Col>
                    </Row>
                )}

                <Row className={styles.row2}>
                    <Col xs={12} sm={8}>
                        <InputField
                            title="Nazwa ulicy"
                            placeholder={'Nazwa ulicy'}
                            name={'street'}
                            onChange={onChangeInvoice}
                            onBlur={onBlurInvoice}
                            value={invoiceState.street.value}
                            isValid={invoiceState.street.isValid}
                            required
                        />
                    </Col>
                    <Col xs={12} sm={4}>
                        <InputField
                            title="Nr domu/lokalu"
                            placeholder={'Nr domu/lokalu'}
                            name="placeNumber"
                            onChange={onChangeInvoice}
                            onBlur={onBlurInvoice}
                            value={invoiceState.placeNumber.value}
                            isValid={invoiceState.placeNumber.isValid}
                            required
                        />
                    </Col>
                </Row>
                <Row className={styles.row2}>
                    <Col xs={12} sm={8}>
                        <InputField
                            title="Miejscowość"
                            placeholder={'Miejscowość'}
                            name="city"
                            onChange={onChangeInvoice}
                            onBlur={onBlurInvoice}
                            value={invoiceState.city.value}
                            isValid={invoiceState.city.isValid}
                            required
                        />
                    </Col>
                    <Col xs={12} sm={4}>
                        <InputField
                            title="Kod pocztowy"
                            placeholder={'Kod pocztowy'}
                            name="postcode"
                            onChange={onChangeInvoice}
                            onBlur={onBlurInvoice}
                            value={invoiceState.postcode.value}
                            isValid={invoiceState.postcode.isValid}
                            required
                        />
                    </Col>
                </Row>
                <Row className={styles.row2}>
                    <Col xs={12}>
                        <InputField
                            title="Email"
                            placeholder={'Email'}
                            name="email"
                            onChange={onChangeInvoice}
                            onBlur={onBlurInvoice}
                            value={invoiceState.email.value}
                            isValid={invoiceState.email.isValid}
                            required
                        />
                    </Col>
                </Row>
                <Row className={styles.row2}>
                    <Col xs={12}>
                        <div
                            name="saveFormData"
                            className={styles.flexCheckbox}>
                            <Checkbox1
                                value={saveFormData}
                                name="saveFormData"
                                label="Zapamiętaj dane do faktury"
                                css={styles.checkboxWidth}
                                onChange={onChangeDataCheckbox}
                            />
                            <p
                                name="saveFormData"
                                onClick={onChangeDataCheckbox}>
                                Zapamiętaj dane do faktury
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className={styles.row2}>
                    <Col xs={12} className={styles.toRight}>
                        <Button
                            icon={faDollar}
                            variant="info"
                            name="sendInvoice"
                            onClick={onSubmit}
                            title="Zapłać"
                            altTitle="Zapłać"
                        />
                    </Col>
                </Row>
            </Modal>
        </Fragment>

    );
};

export default NewUserModal;
