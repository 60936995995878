import { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Table, Pagination, SectionTitle } from '../../../components';
import { withPagination } from '../../../hoc';
import { isNumber } from '../../../utils';
import isString from '../../../utils/isString';
import round from '../../../utils/roundNumber';
import styles from './index.module.css';

const MicropilesCompressionSummary = ({
    dataToPaginate = [],
    pagination,
    paginationHelpers = {},
}) => {
    return (
        <Fragment>
            {dataToPaginate.length > 0 && (
                <Row className={styles.row}>
                    <Col xs={12}>
                        <SectionTitle
                            title={`Wyniki mikropali wciskanych/wyciąganych`}
                        />
                        <Table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>
                                        Nr<br></br>[-]
                                    </th>
                                    <th className={styles.min120} title="Nazwa pliku">
                                        Nazwa pliku<br></br>
                                    </th>
                                    <th className={styles.min120} title="Profil gruntowy">
                                        Nazwa profilu<br></br>
                                    </th>
                                    <th title="Producent żerdzi">
                                        Producent<br></br>
                                    </th>
                                    <th title="Typ żerdzi">
                                        Typ<br></br>
                                    </th>
                                    <th title="Średnica wiercenia">
                                        d<br></br>[mm]
                                    </th>
                                    <th title="Kąt nachylenia do pionu">
                                        &#945;<br></br>[&#176;]
                                    </th>
                                    <th title="Siła osiowa">
                                        F<br></br>[kN]
                                    </th>
                                    <th title="Nośność podstawy mikropala wciskanego">
                                        R<sub>b</sub>
                                        <br></br>[kN]
                                    </th>
                                    <th title="Nośność pobocznicy mikropala wciskanego">
                                        R<sub>s</sub>
                                        <br></br>[kN]
                                    </th>
                                    <th title="Nośność obliczeniowa na wciskanie">
                                        R<sub>c,d</sub>
                                        <br></br>[kN]
                                    </th>
                                    <th title="Długość wciskanej buławy w gruncie">
                                        L<sub>b,c</sub>
                                        <br></br>[m]
                                    </th>
                                    <th title="Całkowita długość wciskanego mikropala">
                                        L<sub>tot,c</sub>
                                        <br></br>[m]
                                    </th>
                                    <th title="Nośność obliczeniowa na wyciąganie">
                                        R<sub>t,d</sub>
                                        <br></br>[kN]
                                    </th>
                                    <th title="Długość wyciąganej buławy w gruncie">
                                        L<sub>b,t</sub>
                                        <br></br>[m]
                                    </th>
                                    <th title="Całkowita długość wyciąganego mikropala">
                                        L<sub>tot,t</sub>
                                        <br></br>[m]
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataToPaginate.map((element, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {paginationHelpers.countingItemsPerPage +
                                                    index +
                                                    1}
                                            </td>
                                            <td>{element.projectName}</td>
                                            <td>
                                                {isString(element.profileName)
                                                    ? element.profileName
                                                    : '-'}
                                            </td>
                                            <td>
                                                {isString(
                                                    element.results
                                                        .currentCapacity
                                                        .rodProd,
                                                )
                                                    ? element.results
                                                          .currentCapacity
                                                          .rodProd
                                                    : '-'}
                                            </td>
                                            <td>
                                                {isString(
                                                    element.results
                                                        .currentCapacity
                                                        .rodName,
                                                )
                                                    ? element.results
                                                          .currentCapacity
                                                          .rodName
                                                    : '-'}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity
                                                        .diameter,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity
                                                        .micropileAngle,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity
                                                        .axisLoad,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {isNumber(
                                                    element.results
                                                        .currentCapacity.np,
                                                )
                                                    ? round(
                                                          element.results
                                                              .currentCapacity
                                                              .np,
                                                          2,
                                                      )
                                                    : '-'}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity.ns,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity.nt,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity
                                                        .bondLength,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity
                                                        .totalRodLength,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity.ntw,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity
                                                        .bondLengthPull,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity
                                                        .totalRodLengthPull,
                                                    2,
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        {paginationHelpers.totalPages > 1 && (
                            <Pagination
                                currentPage={pagination.currentPage}
                                itemsPerPage={pagination.itemsPerPage}
                                totalItems={paginationHelpers.allItemsLength}
                                nextPage={paginationHelpers.nextPage}
                            />
                        )}
                    </Col>
                </Row>
            )}
        </Fragment>
    );
};

export default withPagination({
    component: MicropilesCompressionSummary,
    returnAllCollection: false,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 15,
    },
});
