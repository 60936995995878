import { Fragment, useState } from "react";
import { ItemsPerPage, Pagination, Table } from "../../../../../components"
import { roundNumber } from "../../../../../utils";
import styles from './index.module.css';
import { withPagination } from "../../../../../hoc";
import useDeepCompareEffect from "use-deep-compare-effect";

const CapacityTable = ({
    dataToPaginate,
    allCollection = [],
    pagination,
    paginationHelpers

}) => {
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [csvData, setCsvData] = useState([]);

    useDeepCompareEffect(() => {
        setCsvData(
            allCollection
                .map((ele) =>
                    Object.fromEntries(
                        Object.entries(ele).map(([key, value = '']) => [
                            [key],
                            value.toString().replace('.', ','),
                        ]),
                    ),
                ),
        );

        setCsvHeaders([
            {
                label: 'Mrd [kNm]',
                key: 'mrd',
            },
            {
                label: 'Mrd,Negative [kNm]',
                key: 'mrdNeg',
            },
            {
                label: 'Nrd [kN]',
                key: 'nrd',
            },
        ]);
    }, [allCollection]);

    return (
        <Fragment>
            <ItemsPerPage
                onDisplayItemsNumber={paginationHelpers.displayItemsNumber}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                display={[15, 30, paginationHelpers.allItemsLength]}
            />
            <Table
                csvData={csvData}
                csvFilename={'CrossSectionCapacity'}
                csvHeaders={csvHeaders}
            >
                <thead className={styles.thead}>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th title="Wysokość strefy ściskanej">
                            x<br></br>[mm]
                        </th>
                        <th title="Dodatni moment zginający">
                            M<sup>+</sup><br></br>[kNm]
                        </th>
                        <th title="Ujemny moment ginający">
                            M<sup>-</sup><br></br>[kNm]
                        </th>
                        <th title="Siła osiowa">
                            N<br></br>[kN]
                        </th>
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    {
                        dataToPaginate
                        .map((ele, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{paginationHelpers.countingItemsPerPage + idx + 1}.</td>
                                    <td>{roundNumber(ele.x,2)}</td>
                                    <td>{roundNumber(ele.mrd,2)}</td>
                                    <td>{roundNumber(ele.mrdNeg,2)}</td>
                                    <td>{roundNumber(ele.nrd,2)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>

            <Pagination
                currentPage={pagination.currentPage}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                nextPage={paginationHelpers.nextPage}
            />
        </Fragment>
    )
}

export default withPagination({
    component: CapacityTable,
    returnAllCollection: true,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 25,
    },
}) 