import { Fragment, useEffect, useState } from 'react';
import {
    Button,
    ConfirmModal,
    Checkbox1,
    SectionTitle,
    Table,
    Pagination,
    ItemsPerPage,
} from '../../../../components';
import styles from './index.module.css';
import {
    faPlus,
    faBell,
    faBan,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { withPagination } from '../../../../hoc';
import BlockAccount from './BlockAccount';
import RenewSubscription from './RenewSubscription';
import { isNumber, roundNumber } from '../../../../utils';
import NewUserModal from './NewUserModal';

const UsersTable = ({
    dataToPaginate,
    pagination,
    paginationHelpers,
    state = {},
    renewSubscriptionState = {},
    removeUsers = {},
    addNewUsers = {},
    onChangeIdsCheckbox,
    onChangeBlockAccount,
    onChangeRenewSubscriptionType,
    onSubmitBlockUserAccount,
    onSubmitRenewSubscription,

    invoiceType,
    invoiceState,
    saveFormData,
    onChangeInvoiceType,
    onChangeInvoice,
    onBlurInvoice,
    onChangeDataCheckbox
}) => {
    const [open, setOpen] = useState({});
    const onOpen = (e) => {
        const target = e.currentTarget;
        setOpen({
            [target.name]: true,
        });
    };
    const onHide = () => setOpen({});

    useEffect(() => {
        return () => setOpen({});
    }, []);

    return (
        <Fragment>
            <div className={styles.flexSpace}>
                <SectionTitle title="Lista użytkowników" />
                <ItemsPerPage
                    onDisplayItemsNumber={paginationHelpers.displayItemsNumber}
                    itemsPerPage={pagination.itemsPerPage}
                    totalItems={paginationHelpers.allItemsLength}
                    display={[10, 50, 100, paginationHelpers.allItemsLength]}
                />
            </div>

            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th></th>
                        <th title="Numer wiersza">Nr</th>
                        <th title="Imię">Imię</th>
                        <th title="Nazwisko">Nazwisko</th>
                        <th title="Adres email">Email</th>
                        <th title="Status konta">Konto</th>
                        <th title="Typ konta">Typ konta</th>
                        <th title="Liczba subkont">Subkonta</th>
                        <th title="Rodzaj subskrypcji">Licencja</th>
                        <th className={styles.min110} title="Data wygaśnięcia subskrypcji">
                            Data wygaśnięcia
                        </th>
                        <th title="Liczba dnia do końca subskrypcji">
                            Pozostało
                        </th>
                        <th title="Status subskrypcji">Status</th>
                    </tr>
                </thead>

                {dataToPaginate.length > 0 ? (
                    <tbody>
                        {dataToPaginate
                            .sort((a, b) => a.lp - b.lp)
                            .map((element, index) => {
                                return (
                                    <Fragment key={index}>
                                        <tr
                                            style={{
                                                color: `${
                                                    element.blacklisted
                                                        ? 'red'
                                                        : 'inherit'
                                                }`,
                                            }}
                                            className={`${
                                                Math.trunc(Number(element.lp)) %
                                                    2 ===
                                                0
                                                    ? 'shadow-rows'
                                                    : ''
                                            }`}
                                            key={element.id}>
                                            <td>
                                                <Checkbox1
                                                    css={styles.checkbox}
                                                    onChange={() =>
                                                        onChangeIdsCheckbox(
                                                            element.id,
                                                        )
                                                    }
                                                    value={state.arrayOfIds.includes(
                                                        element.id,
                                                    )}
                                                    name="selectRow"
                                                />
                                            </td>
                                            <td width={30}>{element.lp}</td>
                                            <td>{element.firstName}</td>
                                            <td>{element.lastName}</td>
                                            <td>{element.email}</td>
                                            <td>{`${
                                                element.active
                                                    ? 'Aktywne'
                                                    : 'Nieaktywne'
                                            }${
                                                element.blacklisted
                                                    ? '/Zawieszone'
                                                    : ''
                                            }`}</td>
                                            <td>{element.role}</td>
                                            <td>
                                                {isNumber(
                                                    element.subUsersNumber,
                                                )
                                                    ? element.subUsersNumber
                                                    : '-'}
                                            </td>
                                            <td>
                                                {element.subscription
                                                    ? element.subscription
                                                          .plan === 1
                                                        ? 'Miesięczna'
                                                        : element.subscription
                                                              .plan === 2
                                                        ? 'Roczna'
                                                        : element.subscription
                                                              .plan === 3
                                                        ? 'Testowa'
                                                        : ''
                                                    : '-'}
                                            </td>
                                            <td>
                                                {element.subscription
                                                    ? element.subscription.endDate
                                                        ? moment(
                                                              element
                                                                  .subscription
                                                                  .endDate,
                                                          )
                                                              .local('pl') 
                                                              .format(
                                                                  'DD.MM.YYYY, HH:mm',
                                                              )
                                                        : '-'
                                                    : '-'}
                                            </td>
                                            <td>
                                                {element.subscription
                                                    ? moment(element.subscription.endDate,).diff(moment(), 'days') > 0
                                                        ?   roundNumber(
                                                                moment(element.subscription.endDate).diff(moment(),'days', true),
                                                                0
                                                            )
                                                        : 0
                                                    : '-'}
                                            </td>
                                            <td>
                                                {element.subscription
                                                    ? moment(element.subscription.endDate).diff(moment(), 'seconds') > 0
                                                        ? element.subscription.freeAccount ? 'Darmowe konto' : 'Opłacona/Aktywna'
                                                        : 'Nieaktywna'
                                                    : '-'}
                                            </td>
                                        </tr>
                                    </Fragment>
                                );
                            })}
                    </tbody>
                ) : (
                    <tbody>
                        <tr>
                            <td className={styles.tdNoBorder}>
                                <div className={styles.absoluteTr}>
                                    <p className={styles.noUsers}>
                                        Brak użytkowników przypisanych do konta
                                    </p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                )}
            </Table>
            <div className={styles.actionsWrapper}>
                <div className={`${styles.actions}`}>
                    {Array.isArray(state.arrayOfIds) &&
                        state.arrayOfIds.length > 0 && (
                            <div>
                                <Button
                                    css={styles.button}
                                    onClick={onOpen}
                                    name="blockAccount"
                                    variant="danger"
                                    icon={faBan}
                                    altTitle="Blokuj konto"
                                    title="Blokuj konto"
                                />
                                <Button
                                    css={styles.button}
                                    onClick={onOpen}
                                    name="deleteUsers"
                                    variant="danger"
                                    icon={faTrashAlt}
                                    altTitle="Usuń konto"
                                    title="Usuń konto"
                                />
                                <Button
                                    css={styles.button}
                                    onClick={onOpen}
                                    name="renewSubscription"
                                    variant="info"
                                    icon={faBell}
                                    altTitle="Przedłuż subskrypcję"
                                    title="Przedłuż subskrypcję"
                                />
                            </div>
                        )}

                    <Button
                        css="default-button-style"
                        name="addNewUser"
                        onClick={onOpen}
                        title={'Dodaj nowe konta'}
                        icon={faPlus}
                    />
                </div>
            </div>
            {paginationHelpers.totalPages > 1 && (
                <Pagination
                    css={styles.pagination}
                    currentPage={pagination.currentPage}
                    itemsPerPage={pagination.itemsPerPage}
                    totalItems={paginationHelpers.allItemsLength}
                    nextPage={paginationHelpers.nextPage}
                />
            )}
            <BlockAccount
                open={open.blockAccount}
                users={state.selectedUsers}
                onHide={onHide}
                onChange={onChangeBlockAccount}
                onSubmit={onSubmitBlockUserAccount}
            />
            <RenewSubscription
                open={open.renewSubscription}
                onSubmit={onSubmitRenewSubscription}
                users={renewSubscriptionState.usersState}
                onChange={onChangeRenewSubscriptionType}
                onHide={onHide}
                onOpen={setOpen}

                usersCost={renewSubscriptionState.usersCost}
                invoiceType={invoiceType}
                invoiceState={invoiceState}
                saveFormData={saveFormData}
                onChangeInvoiceType={onChangeInvoiceType}
                onChangeInvoice={onChangeInvoice}
                onBlurInvoice={onBlurInvoice}
            />
            <NewUserModal
                open={open.addNewUser}
                onOpen={setOpen}
                onHide={onHide}
                state={addNewUsers.state.newUsersState}
                usersCost={addNewUsers.state.usersCost}
                summaryCost={addNewUsers.state.summaryCost}
                onChange={addNewUsers.onChangeNewUsersState}
                onChangeSubuser={addNewUsers.onChangSubusersState}
                onSubmit={addNewUsers.onSubmit}
                onBlur={addNewUsers.onBlurNewUsersState}
                onBlurSubuser={addNewUsers.onBlurSubusersState}
                onKeyDown={addNewUsers.onKeyDown}
                addRow={addNewUsers.addRowToNewUsersTable}
                addSubuserRow={addNewUsers.addSubuserToUser}
                removeRow={addNewUsers.removeRowFromNewUsersTable}
                removeSubuserRow={addNewUsers.removeSubuserRow}

                invoiceType={invoiceType}
                invoiceState={invoiceState}
                saveFormData={saveFormData}
                onChangeInvoiceType={onChangeInvoiceType}
                onChangeInvoice={onChangeInvoice}
                onBlurInvoice={onBlurInvoice}
            />
            <ConfirmModal
                open={open.deleteUsers}
                onHide={onHide}
                onConfirm={removeUsers.onSubmit}
                title="Usuń konto użytkownika">
                <Fragment>
                    <p className={styles.p16}>
                        Jesteś pewien, że chcesz usunąć{' '}
                        {`${
                            state.arrayOfIds.length > 1
                                ? 'konta zaznaczonych użytkowników'
                                : 'konto zaznaczonego użytkownika'
                        }`}
                        ?
                    </p>
                    <div name="deleteCheckbox" className={styles.flexCheckbox}>
                        <Checkbox1
                            value={removeUsers.state.deleteCheckbox}
                            name="deleteUsersCalculations"
                            label="Usuń obliczenia"
                            css={styles.checkboxWidth}
                            onChange={removeUsers.onChange}
                        />
                        <p name="deleteCheckbox" onClick={removeUsers.onChange}>
                            Usuń projekty oraz przypisane do nich obliczenia i
                            profile gruntowe, których{' '}
                            {state.arrayOfIds.length > 1
                                ? 'autorami są wybrani użytkownicy'
                                : 'autorem jest wybrany użytkownik'}{' '}
                            (dotyczy użytkowników przypisanych do
                            "kont-rodziców").
                        </p>
                    </div>
                    <p className={styles.p16}>
                        *Jeżeli nie zaznaczysz powyższego okienka to projekty{' '}
                        {state.arrayOfIds.length > 1
                            ? 'usuwanych użytkowników'
                            : 'usuwanego użytkowinika'}{' '}
                        zostaną przypisane do konta "rodzica" (jeżeli istnieje).
                    </p>
                    <p className={styles.p16}>
                        **Jeżeli{' '}
                        {state.arrayOfIds.length > 1
                            ? 'usuwani użytkownicy są autorami'
                            : 'usuwany użytkownik jest autorem'}{' '}
                        obliczeń lub profili gruntowych w projektach, których
                        nie jest on autorem to nie zostaną one usunięte.
                    </p>
                    <p className={styles.p16}>
                        ***Usunięcie konta rodzica spowoduje usunięcie
                        wszystkich zależnych kont oraz obliczeń z nimi
                        związanymi.
                    </p>
                </Fragment>
            </ConfirmModal>
        </Fragment>
    );
};

export default withPagination({
    component: UsersTable,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 20,
    },
});
