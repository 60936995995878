import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import config from "../../config/config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { useWindowSize } from '../../hooks';

import styles from './index.module.css';
import logo from '../../assets/Logo.png';
import logo_light from '../../assets/Logo_light.png';

const AdminNavbar = ({ onLogOut, mode }) => {
    const navRef                            = useRef({});
    const menuRef                           = useRef(null);
    const hamburgerMenuRef                  = useRef(null);
    const [toggleOpen, setToggleOpen]       = useState(false);
    const size                              = useWindowSize()
    const { routes } = config;

    useEffect(() => {
        if(menuRef.current && size.width > 992){
            setToggleOpen(false);
        }
    },[size.width]);

    const toggleMenu = () =>{
        if(!toggleOpen){   
            setToggleOpen(true); 
        } else {
            setToggleOpen(false);
        } 
    }

    return (
        <div id={"navbar-wrapper"} className={styles.wrapper}>
            <div ref={navRef} className={`${styles.mainMenu} container`}>
                <nav id="site-navigation" className={`${styles.siteNavigation} navbar`}>
                    <div className={`${styles.navContainer}`}>
                        <div style={{ padding: 0 }} className="navbar-brand">
                            <div className={styles.siteBrandingText}>
                                <Link to={routes.admin.home} rel="home">
                                    <img src={mode === 'dark' ? logo_light : logo} alt='Company logo'/>
                                </Link> 
                            </div>
                        </div>
                        <div ref={hamburgerMenuRef} id="main-menu-panel" className={`${styles.openPanel} ${toggleOpen ? styles.clicked : ''}`} data-panel="main-menu-panel" onClick={toggleMenu}>
                            <span></span>
                            <span></span> 
                            <span></span>
                        </div>
                        <div ref={menuRef} className={`${styles.menuContainer} ${toggleOpen ? styles.openNav : ''} menu-container`}>
                            <ul className={`${styles.navbarNav} nav`}>
                                <li title="Wyloguj" onClick={() => onLogOut(true)}>
                                    <div className={styles.logOut}>
                                        <FontAwesomeIcon icon={faPowerOff} />
                                        {toggleOpen ? 'Wyloguj' : '' }
                                    </div>
                                </li>  
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>            
        </div>
    )
}
export default AdminNavbar