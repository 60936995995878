import { useCallback, useEffect, useState } from 'react';
import {
    defaultSaftyFactors,
    kxInterpolationLevel,
    m1Factor,
    n3Factor,
    pileShape,
    pilesType,
} from '../../../config/ProjectConfig';
import {
    isNumber,
    kphFactor,
    longTermFactor,
    makeNumberPositive,
    pileDimensionFactor,
    roundNumber,
    stringToNumber,
    subHeight,
} from '../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const defaultTechnologyFactors = {
    name: '',
    hz: '',
    zc: '',
    height: '',
    absHeight: '',
    kph: '',
    friction: '',
    pileFriction: '',
    edometricModule: '',
    soilWeight: '',
    density: '',
    plasticity: '',
    cohesion: '',

    m1: {
        value: '',
        isValid: false,
    },
    sn: {
        value: '',
        isValid: false,
    },
    longTermFactor: {
        value: '',
        isValid: false,
    },
};
const initStateObj = {
    pileShapeFactor: {
        value: '',
        isValid: true,
    },
    frictionAngleSafe: {
        value: defaultSaftyFactors.frictionAngleSafe,
        isValid: true,
    },
    cohesionSafe: {
        value: defaultSaftyFactors.cohesionSafe,
        isValid: true,
    },
    weightSafe: {
        value: defaultSaftyFactors.weightSafe,
        isValid: true,
    },
    kxSafe: {
        value: defaultSaftyFactors.kxSafe,
        isValid: true,
    },
    rgrSafe: {
        value: defaultSaftyFactors.rgrSafe,
        isValid: true,
    },
    kpSafe: {
        value: defaultSaftyFactors.kpSafe,
        isValid: true,
    },
    nonCohesiveShaftFriction: {
        value: defaultSaftyFactors.nonCohesiveShaftFriction,
        isValid: true,
    },
    cohesiveShaftFriction: {
        value: defaultSaftyFactors.cohesiveShaftFriction,
        isValid: true,
    },
    steelReductionFactor: {
        value: defaultSaftyFactors.steelReductionFactor,
        isValid: true,
    },
    concreteReductionFactor: {
        value: defaultSaftyFactors.concreteReductionFactor,
        isValid: true,
    },
    constructionReductionFactor: {
        value: defaultSaftyFactors.constructionReductionFactor,
        isValid: true,
    },
    n0: {
        value: '',
        isValid: false,
    },
    n1: {
        value: '',
        isValid: false,
    },
    n2: {
        value: '',
        isValid: false,
    },
    n3: {
        value: '',
        isValid: true,
    },
    deadLoadReduction: {
        value: defaultSaftyFactors.deadLoadReduction,
        isValid: true,
    },
    liveLoadReduction: {
        value: defaultSaftyFactors.liveLoadReduction,
        isValid: true,
    },
    globalLoadsReduction: {
        value: defaultSaftyFactors.globalLoadsReduction,
        isValid: true,
    },
    liveDeadLoadRatio: {
        value: defaultSaftyFactors.liveDeadLoadRation,
        isValid: true,
    },
    analysisType: {
        value: 1,
        isValid: true,
    },
};

const SaftyFactorsHelpers = ({
    soilsCollection,
    pileParameters,
    foundationParameters,
    calculationsSettings,

    updateResultsState,
}) => {
    const [saftyFactorsState, setSaftyFactorsState] = useState({
        ...initStateObj,
        technologyFactors: soilsCollection.map((ele) => {
            return {
                ...defaultTechnologyFactors,
            };
        }),
    });
    const [soilsParameters, setSoilsParameters] = useState(soilsCollection);

    useEffect(() => {
        setSaftyFactorsState((state) => {
            const globalRatio = roundNumber(
                state.liveDeadLoadRatio.value * state.liveLoadReduction.value +
                    (1 - state.liveDeadLoadRatio.value) *
                        state.deadLoadReduction.value,
                2,
            );
            return {
                ...state,
                globalLoadsReduction: {
                    ...state['globalLoadsReduction'],
                    value: globalRatio,
                    isValid: globalRatio > 0,
                },
            };
        });
    }, [
        saftyFactorsState.deadLoadReduction.value,
        saftyFactorsState.liveLoadReduction.value,
        saftyFactorsState.liveDeadLoadRatio.value,
    ]);

    useEffect(() => {
        const pileShapeType = pileShape[pileParameters.pileShape.value];
        const n3 = n3Factor[pileParameters.pileShape.value];

        setSaftyFactorsState((state) => ({
            ...state,
            n3: {
                value: n3,
                isValid: isNumber(n3) ? true : false,
            },
            pileShapeFactor: {
                value: pileShapeType ? pileShapeType : '',
                isValid: pileShapeType ? true : false,
            },
        }));
    }, [pileParameters.pileShape.value]);

    useEffect(() => {
        const n0 = pileDimensionFactor('n0', {
            d: pileParameters.diameterPile.value,
        });

        setSaftyFactorsState((state) => ({
            ...state,
            n0: {
                value: n0,
                isValid: isNumber(n0) ? true : false,
            },
        }));
    }, [pileParameters.diameterPile.value]);

    useEffect(() => {
        const r1 =
            foundationParameters.columns.value === 1
                ? 0
                : foundationParameters.perpendicularSpace.value;
        const n1 = pileDimensionFactor('n1', {
            d: pileParameters.diameterPile.value,
            r1: r1,
            trestles: pileParameters.trestles.value,
        });

        setSaftyFactorsState((state) => ({
            ...state,
            n1: {
                value: n1,
                isValid: isNumber(n1) ? true : false,
            },
        }));
    }, [
        foundationParameters.columns.value,
        pileParameters.diameterPile.value,
        foundationParameters.perpendicularSpace.value,
        pileParameters.trestles.value,
    ]);

    useEffect(() => {
        const r2 =
            foundationParameters.rows.value === 1
                ? 0
                : foundationParameters.parallelSpace.value;
        const n2 = pileDimensionFactor('n2', {
            d: pileParameters.diameterPile.value,
            r2: r2,
            rows: foundationParameters.rows.value,
            trestles: pileParameters.trestles.value,
        });

        setSaftyFactorsState((state) => ({
            ...state,
            n2: {
                value: n2,
                isValid: isNumber(n2) ? true : false,
            },
        }));
    }, [
        pileParameters.diameterPile.value,
        foundationParameters.parallelSpace.value,
        foundationParameters.rows.value,
        pileParameters.trestles.value,
    ]);

    useDeepCompareEffect(() => {
        setSoilsParameters((state) =>
            soilsCollection.reduce((acc, ele) => {
                if (isNumber(calculationsSettings.waterLevel.value)) {
                    if (
                        ele.height.value -
                            calculationsSettings.waterLevel.value >
                            0 &&
                        ele.height.value -
                            calculationsSettings.waterLevel.value <
                            ele.absHeight.value
                    ) {
                        return [
                            ...acc,
                            {
                                ...ele,
                                height: {
                                    value: calculationsSettings.waterLevel
                                        .value,
                                    isValid: true,
                                },
                                absHeight: {
                                    value: Number(
                                        (
                                            ele.absHeight.value -
                                            (ele.height.value -
                                                calculationsSettings.waterLevel
                                                    .value)
                                        ).toFixed(2),
                                    ),
                                },
                            },
                            {
                                ...ele,
                                absHeight: {
                                    value: Number(
                                        (
                                            ele.height.value -
                                            calculationsSettings.waterLevel
                                                .value
                                        ).toFixed(2),
                                    ),
                                },
                            },
                        ];
                    } else {
                        return [...acc, ele];
                    }
                } else {
                    return [...acc, ele];
                }
            }, []),
        );
    }, [soilsCollection, calculationsSettings.waterLevel.value]);

    useDeepCompareEffect(() => {
        setSaftyFactorsState((state) => ({
            ...state,
            technologyFactors: soilsParameters.map(
                (element, index, arr) => {
                    const pileFrictionAngle =
                        isNumber(element.friction.value) &&
                        (isNumber(element.plasticity.value) ||
                            isNumber(element.density.value))
                            ? isNumber(element.plasticity.value)
                                ? state.cohesiveShaftFriction.value *
                                  element.friction.value
                                : state.nonCohesiveShaftFriction.value *
                                  element.friction.value
                            : '';
                    const kph = kphFactor(
                        element.friction.value,
                        pileFrictionAngle,
                    );

                    return {
                        ...defaultTechnologyFactors,
                        ...state['technologyFactors'][index],
                        name: element.name.value,
                        height: element.height.value,
                        absHeight: element.absHeight.value,
                        soilWeight:
                            isNumber(calculationsSettings.waterLevel.value) &&
                            calculationsSettings.waterLevel.value <
                                element.height.value
                                ? element.underWaterWeight.value
                                : element.weight.value,
                        hz: subHeight(
                            arr
                                .map(
                                    ({
                                        height,
                                        absHeight,
                                        weight,
                                        underWaterWeight,
                                    }) => ({
                                        height,
                                        absHeight,
                                        weight,
                                        underWaterWeight,
                                    }),
                                )
                                .slice(0, index + 1),
                            calculationsSettings.waterLevel.value,
                        ),

                        friction: element.friction.value,
                        pileFriction: isNumber(pileFrictionAngle)
                            ? Number(pileFrictionAngle.toFixed(2))
                            : '',
                        kph: isNumber(kph) ? kph : '',

                        edometricModule: element.edometricModule.value,
                        zc: kxInterpolationLevel(element.soilType.value),

                        density: element.density.value,
                        plasticity: element.plasticity.value,

                        cohesion: element.cohesion.value,

                        m1: {
                            ...(isNumber(element.density.value) ||
                            isNumber(element.plasticity.value)
                                ? {
                                      value: isNumber(element.density.value)
                                          ? m1Factor['noncohesive']
                                          : m1Factor['cohesive'],
                                      isValid: true,
                                  }
                                : {
                                      value: '',
                                      isValid: false,
                                  }),
                        },
                        sn: {
                            ...(pilesType[pileParameters.pileType.value] &&
                            pilesType[pileParameters.pileType.value][
                                pileParameters.pileTechnology.value
                            ]
                                ? {
                                      value: pilesType[
                                          pileParameters.pileType.value
                                      ][pileParameters.pileTechnology.value].sn(
                                          isNumber(element.plasticity.value)
                                              ? true
                                              : false,
                                          element.density.value,
                                      ),
                                      isValid: true,
                                  }
                                : {
                                      value: '',
                                      isValid: false,
                                  }),
                        },
                        longTermFactor: {
                            ...(isNumber(element.density.value) ||
                            isNumber(element.plasticity.value)
                                ? {
                                      value: longTermFactor(
                                          {
                                              id: element.density.value,
                                              il: element.plasticity.value,
                                          },
                                          pileParameters.temporaryContruction
                                              .value,
                                      ),
                                      isValid: true,
                                  }
                                : {
                                      value: '',
                                      isValid: false,
                                  }),
                        },
                    };
                },
            ),
        }));
    }, [
        soilsParameters,

        calculationsSettings.waterLevel.value,
        saftyFactorsState.cohesiveShaftFriction.value,
        saftyFactorsState.nonCohesiveShaftFriction.value,

        pileParameters.pileType.value,
        pileParameters.pileTechnology.value,
        pileParameters.temporaryContruction.value,
    ]);

    const onBlurSaftyFactorsState = useCallback((e) => {
        const { name } = e.currentTarget;
        const valid = e.currentTarget.checkValidity();
        const rowNumber = stringToNumber(
            e.currentTarget.getAttribute('data-row'),
        );

        if (name === 'sn' || name === 'longTermFactor') {
            setSaftyFactorsState((state) => ({
                ...state,
                technologyFactors: state.technologyFactors.map(
                    (element, index) => {
                        if (rowNumber !== index) {
                            return element;
                        } else {
                            return {
                                ...element,
                                [name]: {
                                    ...element[name],
                                    isValid: valid,
                                },
                            };
                        }
                    },
                ),
            }));
        } else {
            setSaftyFactorsState((state) => ({
                ...state,
                [name]: {
                    ...state[name],
                    isValid: valid,
                },
            }));
        }
    }, []);

    const onChangeSaftyFactorsState = useCallback((e) => {
        const { value, name, type } = e.currentTarget;
        const rowNumber = stringToNumber(
            e.currentTarget.getAttribute('data-row'),
        );

        updateResultsState((state) => {
            return {
                ...state,
                isResultsActual: false,
            };
        });
        
        if (type === 'radio') {
            setSaftyFactorsState((state) => {
                return {
                    ...state,
                    [name]: {
                        value: Number(value),
                        isValid: true,
                    },
                };
            });
        } else {
            if (name === 'sn' || name === 'longTermFactor' || name === 'm1') {
                setSaftyFactorsState((state) => ({
                    ...state,
                    technologyFactors: state.technologyFactors.map(
                        (element, index) => {
                            if (rowNumber !== index) {
                                return element;
                            } else {
                                return {
                                    ...element,
                                    [name]: {
                                        value:
                                            value.length > 0
                                                ? makeNumberPositive(
                                                      Number(value),
                                                  )
                                                : value,
                                        isValid: true,
                                    },
                                };
                            }
                        },
                    ),
                }));
            } else {
                setSaftyFactorsState((state) => ({
                    ...state,
                    [name]: {
                        value:
                            name === 'nonCohesiveShaftFriction' ||
                            name === 'cohesiveShaftFriction'
                                ? value.length > 0
                                    ? Number(value)
                                    : value
                                : value.length > 0
                                ? makeNumberPositive(Number(value))
                                : value,
                        isValid: true,
                    },
                }));
            }
        }
    }, []);

    const saftyFactors = {
        onChange: onChangeSaftyFactorsState,
        onBlur: onBlurSaftyFactorsState,
        updateState: setSaftyFactorsState,
        initState: () =>
            setSaftyFactorsState({
                ...initStateObj,
                technologyFactors: [defaultTechnologyFactors],
            }),
        state: saftyFactorsState,
    };

    return {
        saftyFactors,
    };
};

export default SaftyFactorsHelpers;