import isNumber from "../../isNumber"
import roundNumber from '../../roundNumber';

const B = (rows) => {
    const isRowsNumberGreaterThanZero = isNumber(rows) && rows > 0;

    if(isRowsNumberGreaterThanZero){
        if(rows === 1){
            return 1
        } else if(rows === 2){
            return 0.6
        } else if(rows === 3){
            return 0.5
        } else if(rows >= 4) {
            return 0.45
        } else {
            return ''
        }        
    } else {
        return ''
    }
}

const pileDimensionFactor = (type, { d, r1, r2, rows, trestles }) => {
    const r1IsNumber = isNumber(r1);
    const rowsAreNumber = isNumber(rows);
    const dIsNumberGreaterThanZero = isNumber(d) && d > 0;    
    const r2IsNumber = isNumber(r2);
    const isTrestles = !!trestles;

    if(type === 'n0'){
        if(dIsNumberGreaterThanZero){
            return ((d > 1.5) && (d <= 3)) ? roundNumber(d/1.5,2) : 1
        } else {
            return ''
        }

    } else if(type === 'n1'){
        if(isTrestles){
            return 1
        } else {
            if(r1IsNumber && dIsNumberGreaterThanZero){
                const n1 = 0.2*(r1/d) + 0.4;

                return n1 > 1 ? 1 : roundNumber(n1,2) 
            } else {
                return ''
            }            
        }

    } else if(type === 'n2'){
        if(isTrestles){
            return 1
        } else {
            if(r2IsNumber && dIsNumberGreaterThanZero && rowsAreNumber){
                const d0 = d >= 1 ? d+1 : 1.5*d + 0.5;
                const n2 = B(rows) + (((1-B(rows))*(r2-d)))/(1.8*d0);

                return n2 > 1 ? 1 : roundNumber(n2, 2);
            } else {
                return ''
            }            
        }
    } else {
        return ''
    }
}

export default pileDimensionFactor