import React, { Fragment, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    Checkbox,
    InputField,
    LabelName,
    SectionTitle,
    SelectField,
} from '../../../../components';
import styles from './index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import h_single from '../../../../config/ProjectConfig/CptPile/H_piles';
import useDeepCompareEffect from 'use-deep-compare-effect';


const Calculations = ({ isChartData, state, pileTypes, updateSoilTable, onChange, onBlur, onKeyDown }) => {
    const [isOpenTube, setIsOpenTube] = useState(false);

    useDeepCompareEffect(() => {
        setIsOpenTube(pileTypes[3] === state.pileType.value);
    },[state.pileType.value, pileTypes]);

    return (
        <Fragment>
            <SectionTitle title="Parametry modelu" />
            {
                isChartData &&
                <Row>
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <InputField
                            onChange={onChange}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            value={state.groundLevel.value}
                            isValid={state.groundLevel.isValid}
                            type="number"
                            title="Rzędna projektowanego terenu [m.n.p.m]"
                            placeholder={'Wpisz rzędną terenu'}
                            name="groundLevel"
                            required
                        />
                    </Col>
                </Row>                
            }
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    {
                        isChartData 
                        ?
                        <InputField
                            onChange={(e, idx) => {
                                onChange(e, idx, updateSoilTable)
                            }}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            value={state.cptLevel.value}
                            isValid={state.cptLevel.isValid}
                            type="number"
                            title="Rzędna istniejącego terenu [m.n.p.t]"
                            placeholder={'Wpisz rzędną terenu'}
                            name="cptLevel"
                        >
                            Rzędna istniejącego terenu, na którym wykonano sondowanie CPT, jeżeli jest równa rzędnej projektowanej należy wpisać "0" lub pozostawić pole puste. Jeżeli rzędna projektowana jest większa od rzędnej istniejącego terenu, należy wpisać wartość dodatnią okręślającą odległość od rzędnej projektowanej do istniejącej.<br></br><b>UWAGA</b><br></br>Opcja ta dotyczy jedynie danych odczytanych z wykresu sondowania CPT (nie dotyczy danych wpisywanych ręcznie do tabeli).
                        </InputField>
                        :
                        <InputField
                            onChange={onChange}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            value={state.groundLevel.value}
                            isValid={state.groundLevel.isValid}
                            type="number"
                            title="Rzędna projektowanego terenu [m.n.p.m]"
                            placeholder={'Wpisz rzędną terenu'}
                            name="groundLevel"
                            required
                        />
                    }
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        onChange={onChange}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        value={state.zwg.value}
                        isValid={state.zwg.isValid}
                        type="number"
                        title="Rzędna ZWG [m.p.p.t.]"
                        placeholder={'Rzędna zwierciadła wody gruntowej'}
                        name="zwg"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <Checkbox
                        onChange={onChange}
                        title="Świeży nasyp gruntowy"
                        name="newEmbankment"
                        label={`${state.newEmbankment.value ? '' : 'Brak'}`}
                        value={state.newEmbankment.value}
                    >
                        W warstwach oznaczonych jako świeży nasyp gruntowy wartość oporów pod podstawą oraz na pobocznicy pala wynoszą 0 (q<sub>s,k</sub>=0, q<sub>b,k</sub>=0).
                    </Checkbox>
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <Checkbox
                        onChange={onChange}
                        title="Tarcie negatywne"
                        name="negativeFriction"
                        label={`${state.negativeFriction.value ? '' : 'Brak'}`}
                        value={state.negativeFriction.value}
                    >
                        <p>Charakterystyczna wartość tarcia negatywnego:</p>
                        <p>T<sub>c,k</sub>=	&#931;&#951;<sub>s</sub>&#8901;q<sub>s,i</sub>&#8901;A<sub>s,i</sub></p>
                    </Checkbox>
                </Col>
            </Row>
            <Row>
                {state.newEmbankment.value && (
                    <Fragment>
                        <Col xs={12} sm={3} className={styles.columnSpace}>
                            <InputField
                                name="from"
                                type="number"
                                value={state.from.value}
                                isValid={state.from.isValid}
                                title="Od [m.p.p.t.]"
                                onChange={onChange}
                                onBlur={onBlur}
                                placeholder="od"
                                required
                            />
                        </Col>
                        <Col xs={12} sm={3} className={styles.columnSpace}>
                            <InputField
                                name="to"
                                type="number"
                                value={state.to.value}
                                isValid={state.to.isValid}
                                title="Do [m.p.p.t.]"
                                onChange={onChange}
                                onBlur={onBlur}
                                placeholder="do"
                                required
                            />
                        </Col>
                    </Fragment>

                )}
                {state.negativeFriction.value ? (
                    state.layersName.length > 0 ? (
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <Fragment>
                                <LabelName title="Numery warstw z tarciem negatywnym" />
                                {state.layersName.map((ele,idx) => {
                                    return (
                                        <div className={styles.fullWidth} key={idx}>
                                            <Checkbox
                                                onChange={(e) => onChange(e, idx)}
                                                name="layersName"
                                                label={ele.name}
                                                value={ele.value}
                                            />
                                        </div>
                                    );
                                })}
                            </Fragment>
                        </Col>
                    ) : (
                        <Col xs={12} sm={6} className={`${styles.wrongSoilLayers} ${styles.columnSpace}`}>
                            <FontAwesomeIcon icon={faBan} />
                            <p className={styles.p}>
                                Uzupełnij parametry gruntu
                            </p>
                        </Col>
                    )
                ) : (
                    <Fragment></Fragment>
                )}
            </Row>
            <SectionTitle title="Parametry pala" />
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <SelectField
                        title="Rodzaj pala"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={state.pileType.value}
                        defaultOption="Wybierz rodzaj pala"
                        isValid={state.pileType.isValid}
                        options={Object.values(pileTypes).sort((a, b) => a.toLowerCase() < b.toLowerCase() ? 1 : -1)}
                        name="pileType"
                        infoCss={styles.infoCss}
                        info={'Nośność pali rurowych z dnem otwartym oraz z elementów typu H w gruntach niespoistych obliczana jest na podstawie aktualizacji zaleceń EAP. Artykuł pt. "Bearing capacity of open-ended piles based on empirical values – recommendations according to the German approach" - J. Lüking, P. Becker. Natomiast w gruntach spoistych z procedury opisanej w zaleceniach EAP.'}

                        required
                    />
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        onChange={onChange}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        value={state.pileHeadSpot.value}
                        isValid={state.pileHeadSpot.isValid}
                        type="number"
                        title={'Rzędna głowicy pala [m.p.p.t]'}
                        
                        placeholder={'Rzędna głowicy pala'}
                        name="pileHeadSpot"
                        required
                    >
                        Rzędna głowicy pala poniżej projektowanej rzędnej terenu
                    </InputField>
                </Col>  
            </Row>
            <Row>
                <Col xs={12} sm={isOpenTube ? 4 : 6} className={styles.columnSpace}>
                    <InputField
                        onChange={onChange}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        value={state.pileLength.value}
                        isValid={state.pileLength.isValid}
                        type="number"
                        title={'Długość pala [m]'}
                        
                        placeholder={'Długość pala'}
                        name="pileLength"
                        required
                    />
                </Col>
                <Col xs={12} sm={isOpenTube ? 4 : 6} className={styles.columnSpace}>
                    {
                        ![pileTypes[6], pileTypes[7]].includes(state.pileType.value)
                        ?
                        <InputField
                            onChange={onChange}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            value={state.diameterPile.value}
                            isValid={state.diameterPile.isValid}
                            type="number"
                            title={
                                state.pileType.value === '' ? 'Szerokość boku/Średnica pala [m]':
                                [pileTypes[1]].includes(state.pileType.value) ? 'Szerokość boku pala [m]' : 'Średnica pala [m]'
                            }
                            placeholder={'Wpisz wymiar przekroju pala'}
                            name="diameterPile"
                            required
                        />
                        :
                        <SelectField
                            title="Przekrój pala"
                            onBlur={onBlur}
                            onChange={onChange}
                            value={state.hType.value}
                            defaultOption="Wybierz przekrój pala"
                            isValid={state.hType.isValid}
                            options={Object.keys(h_single)}
                            name="hType"
                            infoCss={styles.infoCss}

                            required
                        />
                    }

                </Col>
                {
                    isOpenTube &&
                    <Col xs={12} sm={4} className={styles.columnSpace}>
                        <InputField
                            onChange={onChange}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            value={state.thickness.value}
                            isValid={state.thickness.isValid}
                            type="number"
                            title={'Grubość ścianki [mm]'}
                            
                            placeholder={'Grubość ścianki'}
                            name="thickness"
                            required
                        />
                    </Col>
                }
            </Row>
            {
                ['Wiercony', 'Wkręcany'].includes(state.pileType.value) &&
                <Row>
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <Checkbox
                            onChange={onChange}
                            title="Poszerzona podstawa"
                            name="isBaseWidth"
                            label={`${state.isBaseWidth.value ? '' : 'Nie'}`}
                            value={state.isBaseWidth.value}
                        />
                    </Col>
                    {state.isBaseWidth.value && (
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField
                                name="baseWidth"
                                type="number"
                                value={state.baseWidth.value}
                                isValid={state.baseWidth.isValid}
                                title={
                                    state.pileType.value === '' ? 'Szerokość/Średnica podstawy [m]':
                                    [pileTypes[1]].includes(state.pileType.value) ? 'Szerokość podstawy pala [m]' : 'Średnica podstawy pala [m]'
                                }
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                placeholder="Wpisz wymiar podstawy"

                                required
                            />
                        </Col>
                    )}
                </Row>                
            }
        </Fragment>
    );
};

export default Calculations;
