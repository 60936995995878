import { Fragment, useContext } from 'react';
import { walingContext } from '../../context/Waling';
import styles from './index.module.css';
import { MainAlertAndLoading } from '../../components';
import { Col, Row } from 'react-bootstrap';

import ProjectTitle from '../CptPile/model/ProjectTitle';

import Calculations from './model/Calculations';
import SubmitCalculations from '../SubmitCalculations';
import Results from './results/Results';

const Waling = (props) => {
    const {
        globalAlerts = {},
        globalLoading = {},
        projectTitle={},
        calculationState={},
        submitCalculation={},
        results={}
    } = useContext(walingContext);

    const { isResultsActual, ...rest } = results.state;
    return (
        <Fragment>
            <section className={styles.section}>
                <Row>
                    <ProjectTitle {...projectTitle} />
                </Row>
            </section>
            <section>
                
                <Calculations
                    { ...calculationState }
                    results={results}
                >
                    {
                        Object.values(rest).length > 0 &&
                        <Results
                            results={results.state}
                        />
                    }                    
                </Calculations>


            </section>
            <section>
                <Row>
                    <Col xs={12}>
                        <SubmitCalculations {...submitCalculation} />
                    </Col>
                </Row>
            </section>
            <MainAlertAndLoading
                alerts={globalAlerts.state.alerts}
                updateAlerts={globalAlerts.updateState}
                loadings={globalLoading.state}
            />
        </Fragment>
    );
};

export default Waling;
