import stringify from 'fast-json-stable-stringify';
import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { InputField, SectionTitle } from '../../../../components';
import styles from './index.module.css';

const CalculationSettings = ({ profileProperties = {} }) => {
    return (
        <Fragment>
            <SectionTitle title="Parametry profilu gruntowego" />
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        onChange={profileProperties.onChange}
                        onBlur={profileProperties.onBlur}
                        value={profileProperties.state.groundLevel.value}
                        isValid={profileProperties.state.groundLevel.isValid}
                        type="number"
                        title="Rzędna terenu [m.n.p.m]"
                        placeholder={'Wpisz rzędną terenu'}
                        name="groundLevel"
                        required
                    />
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        onChange={profileProperties.onChange}
                        onBlur={profileProperties.onBlur}
                        value={profileProperties.state.waterLevel.value}
                        isValid={profileProperties.state.waterLevel.isValid}
                        type="number"
                        title={'Poziom zwierciadła wody [m.p.p.t]'}
                        placeholder={'Wpisz poziom ZWG'}
                        name="waterLevel"
                    />
                </Col>
            </Row>
        </Fragment>
    );
};

export default React.memo(
    CalculationSettings,
    (prev, next) => stringify(prev) === stringify(next),
);
