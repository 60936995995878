import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { genesisType } from '../../../../../config/ProjectConfig';
import { isNumber, roundNumber } from '../../../../../utils';
import styles from './index.module.css';

const SoilLayer = ({
    height,
    backgroundColor,
    layerHeight,
    name,
    weight,
    underWaterWeight,
    cohesion,
    friction,
    genesis,
    edometricModule,
    density,
    plasticity,
    groundLevel
}) => {
    const [descriptionHeight, setDescrptionHeight] = useState(0);

    const descriptionRef = (e) => {
        if (e) {
            setDescrptionHeight(e.offsetHeight);
        }
    };

    return (
        <div
            className={`${styles.layer} ${
                height === 0 ? styles.hiddenLayer : ''
            }`}
            style={{ height: `${height}px`, backgroundColor }}>
            <span className={styles.spot}>
                -{layerHeight.toFixed(2)}{' '}
                {`${
                    isNumber(groundLevel)
                        ? `(${roundNumber(groundLevel - layerHeight, 2).toFixed(
                              2,
                          )})`
                        : ''
                }`}
            </span>
            <div className={styles.wrapper}>
                <div className={styles.description}> 
                    <div
                        className={
                            descriptionHeight > height
                                ? styles.listWrapperHidden
                                : styles.listWrapperVisible
                        }
                        ref={descriptionRef}>
                        {name && <span className={styles.item}>{name}</span>}
                        {typeof weight === 'number' && (
                            <span className={styles.item}>
                                &#947; = {weight}kN/m<sup>3</sup>
                            </span>
                        )}
                        {typeof underWaterWeight === 'number' && (
                            <span className={styles.item}>
                                &#947;' = {underWaterWeight}kN/m<sup>3</sup>
                            </span>
                        )}
                        {typeof cohesion === 'number' && (
                            <span className={styles.item}>
                                c = {cohesion}kPa
                            </span>
                        )}
                        {typeof friction === 'number' && (
                            <span className={styles.item}>
                                &#966; = {friction}&#176;
                            </span>
                        )}
                        {typeof density === 'number' && (
                            <span className={styles.item}>Id = {density}</span>
                        )}
                        {typeof plasticity === 'number' && (
                            <span className={styles.item}>
                                Il = {plasticity}
                            </span>
                        )}
                        {typeof edometricModule === 'number' && (
                            <span className={styles.item}>
                                E<sub>0</sub> = {edometricModule}MPa
                            </span>
                        )}
                        {genesisType.includes(genesis) && (
                            <span className={styles.item}>
                                Geneza = {genesis}
                            </span>
                        )}
                    </div>
                    <div
                        className={`${styles.icon} ${
                            descriptionHeight <= height ? styles.iconHidden : ''
                        }`}>
                        <FontAwesomeIcon icon={faInfo} />
                        <div className={styles.details}>
                            {name && (
                                <span className={styles.item}>{name}</span>
                            )}
                            {typeof weight === 'number' && (
                                <span className={styles.item}>
                                    &#947; = {weight}kN/m<sup>3</sup>
                                </span>
                            )}
                            {typeof underWaterWeight === 'number' && (
                                <span className={styles.item}>
                                    &#947;' = {underWaterWeight}kN/m<sup>3</sup>
                                </span>
                            )}
                            {typeof cohesion === 'number' && (
                                <span className={styles.item}>
                                    c = {cohesion}kPa
                                </span>
                            )}
                            {typeof friction === 'number' && (
                                <span className={styles.item}>
                                    &#966; = {friction}&#176;
                                </span>
                            )}
                            {typeof density === 'number' && (
                                <span className={styles.item}>
                                    Id = {density}
                                </span>
                            )}
                            {typeof plasticity === 'number' && (
                                <span className={styles.item}>
                                    Il = {plasticity}
                                </span>
                            )}
                            {typeof edometricModule === 'number' && (
                                <span className={styles.item}>
                                    E<sub>0</sub> = {edometricModule}MPa
                                </span>
                            )}
                            {genesisType.includes(genesis) && (
                                <span className={styles.item}>
                                    Geneza = {genesis}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SoilLayer;
