const dywidag_threadbar = [
	{
		prod: 'DYWIDAG',
		name: 'Y1050H',
		dout: 26.5,  
		fu: 580, 
		rmk: 525,
	},
	{
		prod: 'DYWIDAG',
		name: 'Y1050H',
		dout: 32, 
		fu: 845, 
		rmk: 760,
	},
	{
		prod: 'DYWIDAG',
		name: 'Y1050H',
		dout: 36, 
		fu: 1070, 
		rmk: 960,
	},
	{
		prod: 'DYWIDAG',
		name: 'Y1050H',
		dout: 40, 
		fu: 1320, 
		rmk: 1190,
	},
	{
		prod: 'DYWIDAG',
		name: 'Y1050H',
		dout: 47,
		fu: 1820, 
		rmk: 1650,
	},
	//GEWI
	{
		prod: 'DYWIDAG',
		name: 'GEWI B500B',
		dout: 16, 
		fu: 111, 
		rmk: 101,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI B500B',
		dout: 20, 
		fu: 173, 
		rmk: 157,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI B500B',
		dout: 25, 
		fu: 270, 
		rmk: 245,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI B500B',
		dout: 28, 
		fu: 339, 
		rmk: 308,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI B500B',
		dout: 32, 
		fu: 442, 
		rmk: 402,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI B500B',
		dout: 36, 
		fu: 560, 
		rmk: 510,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI B500B',
		dout: 40, 
		fu: 691, 
		rmk: 628,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI B500B',
		dout: 43, 
		fu: 799, 
		rmk: 726,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI B500B',
		dout: 50, 
		fu: 1080, 
		rmk: 982,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI B500B',
		dout: 57.5, 
		fu: 1818, 
		rmk: 1441,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI B500B',
		dout: 63.5, 
		fu: 2217, 
		rmk: 1758,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI B500B',
		dout: 75, 
		fu: 2430, 
		rmk: 2209,
	},
	//GEWI Plus
	{
		prod: 'DYWIDAG',
		name: 'GEWI Plus S670/800',
		dout: 18, 
		fu: 204, 
		rmk: 170,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI Plus S670/800',
		dout: 22, 
		fu: 304, 
		rmk: 255,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI Plus S670/800',
		dout: 25, 
		fu: 393, 
		rmk: 329,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI Plus S670/800',
		dout: 28, 
		fu: 493, 
		rmk: 413,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI Plus S670/800',
		dout: 30, 
		fu: 565, 
		rmk: 474,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI Plus S670/800',
		dout: 35, 
		fu: 770, 
		rmk: 645,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI Plus S670/800',
		dout: 43, 
		fu: 1162, 
		rmk: 973,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI Plus S670/800',
		dout: 50, 
		fu: 1570, 
		rmk: 1315,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI Plus S670/800',
		dout: 57.5, 
		fu: 2077, 
		rmk: 1740,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI Plus S670/800',
		dout: 63.5, 
		fu: 2534, 
		rmk: 2122,
	},
	{
		prod: 'DYWIDAG',
		name: 'GEWI Plus S670/800',
		dout: 75, 
		fu: 3534, 
		rmk: 2960,
	},
].map(ele =>({ ...ele, din: 0 }))

export default dywidag_threadbar