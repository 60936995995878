import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { isNumber, roundNumber, setSoilColor } from '../../../../utils';
import { SectionTitle } from '../../../../components';
import SoilLayer from './SoilLayer';
import styles from './index.module.css';
import useDeepCompareEffect from 'use-deep-compare-effect';

const SoilProfile = ({
    pileLength = '',
    pileHeadSpot = '',
    pileShape,
    height = [],
    density = [],
    plasticity = [],
    underWaterWeight = [],
    groundLevel = 0,
    waterLevel,
    name = [],
    weight = [],
    qc = [],
    t = [],
    newEmbankment = false,
    embankmentsNumber = '',
    negativeFriction = false,
    negativeFrictionLayers = {},
}) => {
    const soilProfileRef = useRef(null);
    const [soilLayer, setSoilLayer] = useState([]);
    const [profilePileLength, setProfilePileLength] = useState('');
    const [isPileVisible, setIsPileVisible] = useState(false);

    const [zwgHeight, setZwgHeight] = useState('');
    const [freeSpace, setFreeSpace] = useState(0);
    const [pileHeadSpotPx, setPileHeadSpotPx] = useState('');
    const [maxHeight, setMaxHeight] = useState('');
    const [minSpotVal, setMinSpotVal] = useState(0);
    const [offsetHeight, setOffsetHeight] = useState('');

    useEffect(() => {
        const val = Math.min(
            ...[waterLevel, pileHeadSpot].filter((ele) => isNumber(ele)),
        );
        setMinSpotVal(isNumber(val) && val < 0 ? Math.abs(val) : 0);
    }, [waterLevel, pileHeadSpot]);

    useDeepCompareEffect(() => {
        const maxValueHeight = Math.max(...height) + minSpotVal;

        setMaxHeight(isNumber(maxValueHeight) ? maxValueHeight : '');

        if (soilProfileRef.current) {
            setOffsetHeight(soilProfileRef.current.offsetHeight);
        }
    }, [height, minSpotVal]); 

    useDeepCompareEffect(() => {
        if (soilProfileRef.current) {

            const isMaxHeightCorrect = maxHeight === 0 || !isNumber(maxHeight);
            const zwg = waterLevel;
            const freeSpaceHeight = roundNumber(
                (offsetHeight * minSpotVal) / maxHeight,
                1,
            );

            setFreeSpace(freeSpaceHeight);

            const currentZwg =
                zwg >= 0
                    ? (offsetHeight * (zwg + minSpotVal)) / maxHeight
                    : zwg > pileHeadSpot
                    ? (offsetHeight * (minSpotVal - Math.abs(zwg))) / maxHeight
                    : 0;

            setZwgHeight(isMaxHeightCorrect ? '' : currentZwg);

            const layers = height.map((ele, i, arr) => {
                return (
                    <SoilLayer
                        key={i}
                        groundLevel={groundLevel}
                        backgroundColor={setSoilColor({
                            id: density[i],
                            il: plasticity[i],
                        })}
                        newEmbankment={
                            newEmbankment
                                ? embankmentsNumber >= i + 1 &&
                                  i <= arr.length - 2
                                    ? true
                                    : false
                                : false
                        }
                        negativeFriction={
                            negativeFriction
                                ? negativeFrictionLayers[i + 1] &&
                                  negativeFrictionLayers[i + 1].value &&
                                  negativeFrictionLayers[i + 1].isValid
                                : false
                        }
                        name={name[i]}
                        height={
                            maxHeight === 0
                                ? 0
                                : soilProfileRef.current.offsetHeight *
                                  ((i === 0 ? ele : ele - arr[i - 1]) /
                                      maxHeight)
                        }
                        layerHeight={ele}
                        density={density[i]}
                        plasticity={plasticity[i]}
                        weight={weight[i]}
                        underWaterWeight={underWaterWeight[i]}
                        qc={roundNumber(qc[i], 2)}
                        t={roundNumber(t[i], 2)}
                    />
                );
            });
            setSoilLayer(layers);
        }
    }, [
        height,
        density,
        plasticity,
        weight,
        underWaterWeight,
        name,
        qc,
        t,
        negativeFrictionLayers,
        maxHeight,
        waterLevel,
        pileHeadSpot,
        embankmentsNumber,
        minSpotVal,
        offsetHeight,
        groundLevel,
        newEmbankment,
        negativeFriction,
    ]);

    useEffect(() => {
        const currentMicropileSpot =
            pileHeadSpot >= 0
                ? (offsetHeight * (pileHeadSpot + minSpotVal)) / maxHeight
                : pileHeadSpot > waterLevel
                ? (offsetHeight * (minSpotVal - Math.abs(pileHeadSpot))) /
                  maxHeight
                : 0;

        setPileHeadSpotPx(maxHeight === 0 ? 0 : currentMicropileSpot);
    }, [
        offsetHeight,
        waterLevel,
        pileHeadSpot,
        maxHeight,
        freeSpace,
        minSpotVal,
    ]);

    useDeepCompareEffect(() => {
        const profileMaxHeight = Math.max(...height);

        setIsPileVisible(
            isNumber(pileHeadSpot) &&
                isNumber(pileLength) &&
                pileLength > 0 &&
                profileMaxHeight >= pileHeadSpot + pileLength,
        );

        if (soilProfileRef.current) {
            setProfilePileLength(
                maxHeight === 0 ? 0 : (offsetHeight * pileLength) / maxHeight,
            );
        }
    }, [pileLength, pileHeadSpot, height, maxHeight, offsetHeight]);

    return (
        <Fragment>
            <SectionTitle title="Profil gruntowy" />
            {height.length > 0 ? (
                <Fragment>
                    <div
                        className={styles.groundLevel}
                        style={{ top: `${freeSpace}px` }}>
                        <span className={styles.spot}>
                            0.00{' '}
                            {`${
                                isNumber(groundLevel)
                                    ? `(${roundNumber(groundLevel, 2).toFixed(
                                          2,
                                      )} m.n.p.m.)`
                                    : '' 
                            }`}
                        </span>
                    </div>
                    <div className={styles.wrapper} ref={soilProfileRef}>
                        {isPileVisible && (
                            <div
                                className={styles.pileWrapper}
                                style={{
                                    height: `${profilePileLength}px`,
                                    top: `${pileHeadSpotPx}px`,
                                }}>
                                <div className={styles.pile}>
                                    <div
                                        className={`${styles.pileShape} ${
                                            pileShape === 'Kołowy'
                                                ? styles.circle
                                                : ''
                                        }`}></div>
                                </div>
                                <div className={styles.spotWrapper}>
                                    <span className={styles.pileSpot}>
                                        {isNumber(pileHeadSpot)
                                            ? (-pileHeadSpot).toFixed(2)
                                            : ''}{' '}
                                        {`${
                                            isNumber(groundLevel)
                                                ? `(${roundNumber(
                                                      groundLevel -
                                                          pileHeadSpot,
                                                      2,
                                                  ).toFixed(2)})`
                                                : ''
                                        }`}
                                    </span>
                                    <span className={styles.pileSpot}>
                                        {isNumber(pileHeadSpot + pileLength)
                                            ? (-(
                                                  pileHeadSpot + pileLength
                                              )).toFixed(2)
                                            : ''}{' '}
                                        {`${
                                            isNumber(groundLevel)
                                                ? `(${roundNumber(
                                                      groundLevel -
                                                          (pileHeadSpot +
                                                              pileLength),
                                                      2,
                                                  ).toFixed(2)})`
                                                : ''
                                        }`}
                                    </span>
                                </div>
                            </div>
                        )}
                        {isNumber(waterLevel) &&
                            soilProfileRef.current &&
                            offsetHeight > zwgHeight && (
                                <div
                                    className={`${styles.zwg} ${
                                        typeof waterLevel === 'number'
                                            ? ''
                                            : styles.hiddenZwg
                                    }`}
                                    style={{ top: zwgHeight || 0 }}>
                                    <span className={styles.zwgLevel}>
                                        {(-waterLevel).toFixed(2)}{' '}
                                        {`${
                                            isNumber(groundLevel)
                                                ? `(${roundNumber(
                                                      groundLevel - waterLevel,
                                                      2,
                                                  ).toFixed(2)})`
                                                : ''
                                        }`}
                                    </span>
                                    <div className={styles.zwgSpot}></div>
                                </div>
                            )}

                        <div className={styles.layersWrapper}>
                            {
                                freeSpace > 0 &&
                                <div className={styles.freeSpace} style={{ height: `${freeSpace}px` }}></div>
                            }                            
                            {[...soilLayer]}
                        </div>
                    </div>
                </Fragment>
            ) : (
                <div className={styles.soilsProps}>
                    <FontAwesomeIcon icon={faBan} />
                    <p className={styles.p}>Uzupełnij parametry gruntu</p>
                </div>
            )}
        </Fragment>
    );
};

export default SoilProfile;