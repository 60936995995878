import React, { Fragment, useCallback, useRef, useState, useEffect } from 'react';
import {
    ResultsFooter, 
    ResultsHeader,
    ReportFirstPage,
} from '../../../../../components';
import CalculationSection from '../CalculationSection';
import PileParametersSection from '../PileParametersSection';
import styles from './index.module.css';
import CapacityResults from '../CapacityResults';
import CapacityTable from '../CapacityTable';
import CapacityChart from '../CapacityChart';
import QcChart from '../QcChart';

const PdfReport = React.forwardRef(({ pdfState, setLoading }, ref) => {
    const [footerHeight, setFooterHeight] = useState(0);
    const contentRef = useRef(null);
    const [maxWidth, setMaxWidth] = useState(0);
    const [dataURI, setDataURI] = useState('');

    useEffect(() =>{
        if(contentRef.current){
            setMaxWidth(contentRef.current.offsetWidth)
        }
    },[]);

    useEffect(() => {
        if(pdfState.reportItem.cptChart){
            setLoading(!dataURI);
        } else {
            setLoading(false);
        }
        
    },[dataURI, setLoading, pdfState.reportItem.cptChart]);

    return (
        <Fragment>
            <div className={styles.wrapper} ref={ref}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <td>
                                <div className={styles.headerSpace}></div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <main className={styles.content} ref={contentRef}>
                                    <ol>
                                        <div>
                                            {
                                                pdfState.reportItem.firstPage &&
                                                <Fragment>
                                                    <ReportFirstPage
                                                        title="Nośność pionowa grodzic na podstawie metody francuskiego Centralnego Laboratorium Dróg i Mostów w oparciu o wyniki sondowań CPT"
                                                        subtitle={
                                                            pdfState.projectTitle
                                                                .projectTitle
                                                        }
                                                        author={pdfState.report.author}
                                                        contact={pdfState.report.contact}
                                                        logo={pdfState.report.logo}
                                                    />                                          
                                                </Fragment>
                                            }
                                            {(
                                                pdfState.reportItem.projectInfo ||
                                                pdfState.reportItem.pileParameters
                                            ) && (
                                                <Fragment>
                                                    <div className={styles.break}></div>
                                                    <li className={styles.h1}>
                                                        Dane do projektu
                                                    </li> 
                                                    {pdfState.reportItem.projectInfo && (
                                                        <CalculationSection
                                                            groundLevel={pdfState.results.calculations.groundLevel}
                                                            zwg={pdfState.results.calculations.zwg}
                                                        />
                                                    )}
                                                    {pdfState.reportItem.pileParameters && (
                                                        <PileParametersSection
                                                            currentSheetPileLength={pdfState.results.currentPileLength}
                                                            baseArea={pdfState.results.calculations.baseArea}
                                                            perimeter={pdfState.results.calculations.perimeter}
                                                            sheetPileHeadSpot={pdfState.results.calculations.sheetPileHeadSpot}
                                                            sheetPileList={pdfState.results.calculations.sheetPileList}
                                                            sheetPileType={pdfState.results.calculations.sheetPileType}
                                                            technology={pdfState.results.calculations.technology}
                                                            
                                                        />
                                                    )}                                                   
                                                </Fragment>
                                            )}
                                            {(
                                                pdfState.reportItem.capacityDetails || pdfState.reportItem.capacityTable
                                            ) && (
                                                <Fragment>
                                                    <li className={styles.h1}>
                                                        Wyniki obliczeń
                                                    </li> 
                                                    {pdfState.reportItem.capacityDetails && (
                                                        <CapacityResults
                                                            currentCapacity={pdfState.results.currentCapacity}
                                                            yt={pdfState.results.calculations.yt}
                                                            sheetPileNumbers={pdfState.results.calculations.sheetPileNumbers}
                                                        />
                                                    )}
                                                    {
                                                        pdfState.reportItem.capacityTable && (
                                                            <CapacityTable
                                                                currentSheetPileLength={pdfState.results.currentPileLength}
                                                                sheetPileHeadSpot={pdfState.results.calculations.sheetPileHeadSpot}
                                                                sheetPileType={pdfState.results.calculations.sheetPileType}
                                                                results={pdfState.results.results}
                                                                yt={pdfState.results.calculations.yt}
                                                            />
                                                        )
                                                    }
                                                    <div className={styles.break}></div>
                                                </Fragment>
                                            )} 
                                            {(pdfState.reportItem.pileChart || pdfState.reportItem.cptChart) && (
                                                <Fragment>
                                                    <li className={styles.h1}>
                                                        Załączniki 
                                                    </li> 
                                                    <Fragment>
                                                        {
                                                            (pdfState.reportItem.pileChart && 
                                                                <Fragment>
                                                                    <CapacityChart
                                                                        {...pdfState.results.calculations}
                                                                        results={pdfState.results.results}
                                                                        soilTable={pdfState.results.soilTable}
                                                                        currentCapacity={pdfState.results.currentCapacity}
                                                                        currentPileLength={pdfState.results.currentPileLength}
                                                                        title={"Nośność pionowa grodzic na wciskanie na podstawie wyników CPT"}
                                                                    />
                                                                    <div className={styles.break}></div>
                                                                </Fragment>
                                                            )
                                                        }
                                                        <div className={styles.avoidBreak}>
                                                            {
                                                                pdfState.reportItem.cptChart &&
                                                                <Fragment>
                                                                    <h2>Wykres sondownia CPT (q<sub>c</sub>)</h2>
                                                                    <div className={styles.imgBorder} style={{ width: `${maxWidth}px` }}>
                                                                        <img alt='' src={dataURI} />
                                                                    </div>
                                                                </Fragment>
                                                            }
                                                        </div>
                                                    </Fragment>
                                                </Fragment>
                                            )}
                                        </div>
                                    </ol>
                                </main>
                            </td> 
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <div
                                    style={{ height: footerHeight }}
                                    className={styles.footerSpace}></div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <ResultsHeader
                    author={pdfState.report.author}
                    contact={pdfState.report.contact}
                />
                <ResultsFooter
                    setFooterHeight={useCallback((val) => setFooterHeight(val),[])}
                    pdfState={pdfState.reportItem}
                    globalTitle="Nośność pionowa pali wg niemieckich zaleceń do projektowania EA-Pf&auml;hle"
                    title={pdfState.projectTitle.projectTitle}
                />
            </div>
            {
                pdfState.reportItem.cptChart &&
                <Fragment>
                    <div className={styles.chartsWrapper}>
                        <QcChart
                            maxWidth={maxWidth}
                            setDataURI={setDataURI}
                            chartTitle="Opór stożka sondy CPT - qc [MPa]"
                            xLabel="qc [MPa]"
                            yLabel="Głębokość Z [m.p.p.t]"
                            results={pdfState.soilsCollection.map(state => {
                                return {
                                    qc: state.qc.value,
                                    z: state.z.value,
                                    soil: state.soilType.value,
                                }
                            })}
                        />
                    </div>
                </Fragment>
            }
        </Fragment>
    );
});

export default PdfReport;