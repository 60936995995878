import React, { Fragment, useState } from 'react';
import { withPagination } from '../../../../../hoc';
import {
    ItemsPerPage,
    Pagination,
    SectionTitle,
    Table,
} from '../../../../../components';
import styles from './index.module.css';
import round from '../../../../../utils/roundNumber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import useDeepCompareEffect from 'use-deep-compare-effect';

const CapacityTable = ({ 
    dataToPaginate,
    allCollection = [],
    pagination,
    paginationHelpers,
    pileTypeNumber,

    yt,
    yst,
    currentZ
}) => {
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [csvData, setCsvData] = useState([]);

    useDeepCompareEffect(() => {
        const bored_screwPile = [4,5].includes(pileTypeNumber);
        if(bored_screwPile){
            setCsvData(
                allCollection
                    .map(
                        ({
                            z,
                            Rbk_current_01,
                            Rsk_current_ssg,
                            Tsk_current_ssg,
                            Rcd_current,
                            Rcd_lower,
                            Rcd_upper,
                            Rtd_current,
                            Rtd_lower,
                            Rtd_upper,
                        }) => ({ z, Rbk_current_01, Rsk_current_ssg, Tsk_current_ssg, yt, Rcd_current, Rcd_lower, Rcd_upper, yst, Rtd_current, Rtd_lower, Rtd_upper }),
                    )
                    .map((ele) =>
                        Object.fromEntries(
                            Object.entries(ele).map(([key, value = '']) => [
                                [key],
                                value.toString().replace('.', ','),
                            ]),
                        ),
                    ),
            );
            setCsvHeaders([
                {
                    label: 'Z [m.p.p.t.]',
                    key: 'z',
                },
                {
                    label: 'Rbk [kN]',
                    key: 'Rbk_current_01',
                },
                {
                    label: 'Rsk [kN]',
                    key: 'Rsk_current_ssg',
                },
                {
                    label: 'Tsk [kN]',
                    key: 'Tsk_current_ssg',
                },
                {
                    label: 'yt [-]',
                    key: 'yt',
                },
                {
                    label: 'Rcd [kN]',
                    key: 'Rcd_current',
                },
                {
                    label: 'Rcd_min [kN]',
                    key: 'Rcd_lower',
                },
                {
                    label: 'Rcd_max [kN]',
                    key: 'Rcd_upper',
                },
                {
                    label: 'yst [-]',
                    key: 'yst',
                },
                {
                    label: 'Rtd [kN]',
                    key: 'Rtd_current',
                },
                {
                    label: 'Rtd_min [kN]',
                    key: 'Rtd_lower',
                },
                {
                    label: 'Rtd_max [kN]',
                    key: 'Rtd_upper',
                },
            ]);
        } else {
            setCsvData(
                allCollection
                    .map(
                        ({
                            z,
                            Rbk_current_01D,
                            Rsk_current_01D,
                            Tsk_current_01D,
                            Rcd_current,
                            Rcd_lower,
                            Rcd_upper,
                            Rtd_current,
                            Rtd_lower,
                            Rtd_upper,
                        }) => ({ z, Rbk_current_01D, Rsk_current_01D, Tsk_current_01D, yt, Rcd_current, Rcd_lower, Rcd_upper, yst, Rtd_current, Rtd_lower, Rtd_upper }),
                    )
                    .map((ele) =>
                        Object.fromEntries(
                            Object.entries(ele).map(([key, value = '']) => [
                                [key],
                                value.toString().replace('.', ','),
                            ]),
                        ),
                    ),
            );
            setCsvHeaders([
                {
                    label: 'Z [m.p.p.t.]',
                    key: 'z',
                },
                {
                    label: 'Rbk [kN]',
                    key: 'Rbk_current_01D',
                },
                {
                    label: 'Rsk [kN]',
                    key: 'Rsk_current_01D',
                },
                {
                    label: 'Tsk [kN]',
                    key: 'Tsk_current_01D',
                },
                {
                    label: 'yt [-]',
                    key: 'yt',
                },
                {
                    label: 'Rcd [kN]',
                    key: 'Rcd_current',
                },
                {
                    label: 'Rcd_min [kN]',
                    key: 'Rcd_lower',
                },
                {
                    label: 'Rcd_max [kN]',
                    key: 'Rcd_upper',
                },
                {
                    label: 'yst [-]',
                    key: 'yst',
                },
                {
                    label: 'Rtd [kN]',
                    key: 'Rtd_current',
                },
                {
                    label: 'Rtd_min [kN]',
                    key: 'Rtd_lower',
                },
                {
                    label: 'Rtd_max [kN]',
                    key: 'Rtd_upper',
                },
            ]);
        }
    }, [allCollection]);
    
    return (
        <Fragment>
            <SectionTitle
                title={
                    'Nośność pionowa pala pojedynczego wg zaleceń EAP'
                }
            />
            <ItemsPerPage
                onDisplayItemsNumber={paginationHelpers.displayItemsNumber}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                display={[15, 30, paginationHelpers.allItemsLength]}
            />
            <Table
                csvData={csvData}
                csvFilename={'SinglePileCapacityEAP'}
                csvHeaders={csvHeaders}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th title="Rzędna">
                            z<br></br>[m.p.p.t.]
                        </th>
                        <th title="Charakterystyczna nośność podstawy">
                            R<sub>b,k</sub>
                            <br></br>[kN]
                        </th>
                        <th title="Charakterystyczna nośność pobocznicy">
                            R<sub>s,k</sub>=&Sigma;R<sub>s,k,i</sub>
                            <br></br>[kN]
                        </th>
                        <th title="Charakterystyczne tarcie negatywne">
                            T<sub>n,k</sub>=&Sigma;T<sub>n,i</sub>
                            <br></br>[kN]
                        </th>
                        <th title="Współczynnik bezp. przy ściskaniu">
                            &gamma;<sub>b,s,t</sub>
                            <br></br>[-]
                        </th> 
                        <th title="Nośność obliczeniowa na wciskanie">
                            R<sub>c,d</sub>
                            <br></br>[kN]
                        </th> 
                        <th title="Minimalna nośność obliczeniowa na wciskanie">
                            R<sub>c,d,min</sub>
                            <br></br>[kN]
                        </th> 
                        <th title="Maksymalna nośność obliczeniowa na wciskanie">
                            R<sub>c,d,max</sub>
                            <br></br>[kN]
                        </th> 
                        <th title="Współczynnik bezp. przy wyciąganiu">
                            &gamma;<sub>s,t</sub>
                            <br></br>[-]
                        </th> 
                        <th title="Nośność obliczeniowa na wyciąganie">
                            R<sub>t,d</sub>
                            <br></br>[kN]
                        </th>
                        <th title="Minimalna nośność obliczeniowa na wyciąganie">
                            R<sub>t,d,min</sub>
                            <br></br>[kN]
                        </th>
                        <th title="Maksymalna nośność obliczeniowa na wyciąganie">
                            R<sub>t,d,max</sub>
                            <br></br>[kN]
                        </th>
                        <th title="Poprawność zagłębienia pala w gruncie"></th>
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    {dataToPaginate.map((element, index) => {
                        const correctDepth = !element.correctConditions.depth || !element.correctConditions.depthInLayer
                        const bored_screwPile = [4,5].includes(pileTypeNumber);
                        return (
                            <tr
                                className={`${styles.tr} ${correctDepth ? styles.wrongDepth : styles.validDepth} ${element.z === currentZ ? 'shadowTr' : ''}`}
                                key={index}
                            >
                                <td>{paginationHelpers.countingItemsPerPage + index + 1}</td>
                                <td>{round(element.z, 2)}</td>
                                <td>{round(bored_screwPile ? element.Rbk_current_01 : element.Rbk_current_01D, 2)}</td>
                                <td>{round(bored_screwPile ? element.Rsk_current_ssg : element.Rsk_current_01D, 2)}</td>
                                <td>{round(bored_screwPile ? element.Tsk_current_ssg : element.Tsk_current_01D, 2)}</td>
                                <td>{round(yt, 2)}</td>
                                <td>{round(element.Rcd_current, 2)}</td>
                                <td>{round(element.Rcd_lower, 2)}</td>
                                <td>{round(element.Rcd_upper, 2)}</td>
                                <td>{round(yst, 2)}</td>
                                <td>{round(element.Rtd_current, 2)}</td>
                                <td>{round(element.Rtd_lower, 2)}</td>
                                <td>{round(element.Rtd_upper, 2)}</td>
                                <td>
                                    <FontAwesomeIcon icon={
                                        correctDepth
                                            ? faTimesCircle
                                            : faCheckCircle
                                        } 
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <Pagination
                currentPage={pagination.currentPage}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                nextPage={paginationHelpers.nextPage}
            />
        </Fragment>
    );
};

export default withPagination({
    component: CapacityTable,
    returnAllCollection: true,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 25,
    },
});