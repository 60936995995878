import { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Table, Pagination, SectionTitle } from '../../../components';
import { withPagination } from '../../../hoc';
import round from '../../../utils/roundNumber';
import styles from './index.module.css';

const PileCptSummary = ({
    dataToPaginate = [],
    pagination,
    paginationHelpers = {},
}) => {
    return (
        <Fragment>
            {dataToPaginate.length > 0 && (
                <Row className={styles.row}>
                    <Col xs={12}>
                        <SectionTitle
                            title={`Nośność pionowa pali na podstawie sondowania CPT`}
                        />
                        <Table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>
                                        Nr<br></br>[-]
                                    </th>
                                    <th title="Nazwa pliku">
                                        Nazwa pliku<br></br>[-]
                                    </th>
                                    <th title="Profil gruntowy">
                                        Profil gruntowy<br></br>[-]
                                    </th>
                                    <th title="Rodzaj pala">
                                        Typ pala<br></br>[-]
                                    </th>
                                    <th title="Rzędna">
                                        z<br></br>[m.p.p.t.]
                                    </th>
                                    <th title="Charakterystyczna nośność podstawy">
                                        R<sub>b,k</sub>
                                        <br></br>[kN]
                                    </th>
                                    <th title="Charakterystyczna nośność pobocznicy">
                                        R<sub>s,k</sub>=&Sigma;R<sub>s,k,i</sub>
                                        <br></br>[kN]
                                    </th>
                                    <th title="Charakterystyczne tarcie negatywne">
                                        T<sub>n,k</sub>=&Sigma;T<sub>n,i</sub>
                                        <br></br>[kN]
                                    </th>
                                    <th title="Współczynnik bezp. przy ściskaniu">
                                        &gamma;<sub>b,s,t</sub>
                                        <br></br>[-]
                                    </th> 
                                    <th title="Nośność obliczeniowa na wciskanie">
                                        R<sub>c,d</sub>
                                        <br></br>[kN]
                                    </th> 
                                    <th title="Minimalna nośność obliczeniowa na wciskanie">
                                        R<sub>c,d,min</sub>
                                        <br></br>[kN]
                                    </th> 
                                    <th title="Maksymalna nośność obliczeniowa na wciskanie">
                                        R<sub>c,d,max</sub>
                                        <br></br>[kN]
                                    </th> 
                                    <th title="Współczynnik bezp. przy wyciąganiu">
                                        &gamma;<sub>s,t</sub>
                                        <br></br>[-]
                                    </th> 
                                    <th title="Nośność obliczeniowa na wyciąganie">
                                        R<sub>t,d</sub>
                                        <br></br>[kN]
                                    </th>
                                    <th title="Minimalna nośność obliczeniowa na wyciąganie">
                                        R<sub>t,d,min</sub>
                                        <br></br>[kN]
                                    </th>
                                    <th title="Maksymalna nośność obliczeniowa na wyciąganie">
                                        R<sub>t,d,max</sub>
                                        <br></br>[kN]
                                    </th>
                                </tr>
                            </thead>
                            <tbody className={styles.tbody}>
                                {dataToPaginate.map((element, index) => {
                                    const bored_screwPile = [4,5].includes(element.results.results.pileTypeNumber);
                                    return (
                                        <tr className={styles.tr} key={index}>
                                            <td>{paginationHelpers.countingItemsPerPage + index + 1}</td>
                                            <td>{element.projectName}</td>
                                            <td>{element.profileName}</td>
                                            <td>{element.results.results.pileType}</td>
                                            <td>{round(element.results.results.currentCapacity.z, 2)}</td>
                                            <td>{round(bored_screwPile ? element.results.results.currentCapacity.Rbk_current_01 : element.results.results.currentCapacity.Rbk_current_01D, 2)}</td>
                                            <td>{round(bored_screwPile ? element.results.results.currentCapacity.Rsk_current_ssg : element.results.results.currentCapacity.Rsk_current_01D, 2)}</td>
                                            <td>{round(bored_screwPile ? element.results.results.currentCapacity.Tsk_current_ssg : element.results.results.currentCapacity.Tsk_current_01D, 2)}</td>
                                            <td>{round(element.results.results.yt, 2)}</td>
                                            <td>{round(element.results.results.currentCapacity.Rcd_current, 2)}</td>
                                            <td>{round(element.results.results.currentCapacity.Rcd_lower, 2)}</td>
                                            <td>{round(element.results.results.currentCapacity.Rcd_upper, 2)}</td>
                                            <td>{round(element.results.results.yst, 2)}</td>
                                            <td>{round(element.results.results.currentCapacity.Rtd_current, 2)}</td>
                                            <td>{round(element.results.results.currentCapacity.Rtd_lower, 2)}</td>
                                            <td>{round(element.results.results.currentCapacity.Rtd_upper, 2)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        {paginationHelpers.totalPages > 1 && (
                            <Pagination
                                currentPage={pagination.currentPage}
                                itemsPerPage={pagination.itemsPerPage}
                                totalItems={paginationHelpers.allItemsLength}
                                nextPage={paginationHelpers.nextPage}
                            />
                        )}
                    </Col>
                </Row>
            )}
        </Fragment>
    );
};

export default withPagination({
    component: PileCptSummary,
    returnAllCollection: false,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 15,
    },
});
