import React, { Fragment, useCallback, useRef, useState, useEffect } from 'react';
import {
    ResultsFooter, 
    ResultsHeader,
    ReportFirstPage,
} from '../../../../../components';
import SoilParametersSection from '../SoilParametersSection';
import CalculationSection from '../CalculationSection';
import PileParametersSection from '../PileParametersSection';
import styles from './index.module.css';
import PnCapacityDetails from '../PnCapacityDetails';
import PnEnCapacityDetails from '../PnEnCapacityDetails';
import TechnologyFactorsDetails from '../TechnologyFactorsDetails';
import GroupPileDetails from '../GroupPileDetails';
import QcdChart from '../QcdChart';
import TcdChart from '../TcdChart';
import PileCapacityChart from '../PileCapacityChart';
import useDeepCompareEffect from 'use-deep-compare-effect';

const PdfReport = React.forwardRef(({ pdfState, setLoading }, ref) => {
    const [pileCapacityChartProfile, setPileCapacityChartProfile] = useState(
        [],
    );
    const [footerHeight, setFooterHeight] = useState(0);

    const pdfSoilsCollection = pdfState.soilsCollection;

    const contentRef = useRef(null);

    const [maxWidth, setMaxWidth] = useState(0);

    const [dataURI, setDataURI] = useState('');
    const [dataURITcd, setDataURITcd] = useState('');

    useDeepCompareEffect(() => {
        if (pdfSoilsCollection) {
            setPileCapacityChartProfile(
                pdfSoilsCollection.map((ele) => {
                    return {
                        name: ele.name.value,
                        density: ele.density.value,
                        plasticity: ele.plasticity.value,
                        height: ele.height.value,
                        absHeight: ele.absHeight.value,
                    };
                }),
            );
        }
    }, [pdfSoilsCollection]);

    useEffect(() =>{
        if(contentRef.current){
            setMaxWidth(contentRef.current.offsetWidth)
        }
    },[]);

    useEffect(() => {
        if(pdfState.reportItem.qcdChart && !pdfState.reportItem.tcdChart){
            setLoading(!dataURI);
        } else if(pdfState.reportItem.tcdChart && !pdfState.reportItem.qcdChart){
            setLoading(!dataURITcd);
        } else if(pdfState.reportItem.qcdChart && pdfState.reportItem.tcdChart){
            setLoading(!(dataURI && dataURITcd));
        } else {
            setLoading(false);
        }
        
    },[dataURI, dataURITcd, setLoading, pdfState.reportItem.qcdChart, pdfState.reportItem.tcdChart]);

    return (
        <Fragment>
            <div className={styles.wrapper} ref={ref}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <td>
                                <div className={styles.headerSpace}></div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <main className={styles.content} ref={contentRef}>
                                    <ol>
                                        <div>
                                            {
                                                pdfState.reportItem.firstPage &&
                                                <Fragment>
                                                    <ReportFirstPage
                                                        title="Nośność pionowa pali wg normy PN-83/B-2482 z uwzględnieniem Eurokodu 7"
                                                        subtitle={
                                                            pdfState.projectTitle
                                                                .projectTitle
                                                        }
                                                        author={pdfState.report.author}
                                                        contact={pdfState.report.contact}
                                                        logo={pdfState.report.logo}
                                                    />                                          
                                                </Fragment>
                                            }
                                            {(pdfState.reportItem.soilsTable ||
                                                pdfState.reportItem.projectInfo ||
                                                pdfState.reportItem
                                                    .pileParameters) && (
                                                <Fragment>
                                                    <div className={styles.break}></div>
                                                    <li className={styles.h1}>
                                                        Dane do projektu
                                                    </li> 
                                                    {pdfState.reportItem
                                                        .soilsTable && (
                                                        <SoilParametersSection
                                                            parameters={
                                                                pdfState.soilsCollection
                                                            }
                                                        />
                                                    )}
                                                    {pdfState.reportItem
                                                        .projectInfo && (
                                                        <CalculationSection
                                                            parameters={
                                                                pdfState.calculationsSettings
                                                            }
                                                        />
                                                    )}
                                                    {pdfState.reportItem
                                                        .pileParameters && (
                                                        <PileParametersSection
                                                            parameters={
                                                                pdfState.results
                                                                    .pileParameters
                                                            }
                                                            pileLength={
                                                                pdfState.reportItem
                                                                    .pileLength
                                                            }
                                                        />
                                                    )}
                                                    
                                                </Fragment>
                                            )}
                                            {(pdfState.reportItem
                                                .pnCapacityDetails ||
                                                pdfState.reportItem
                                                    .enCapacityDetails ||
                                                pdfState.reportItem
                                                    .pilesInGroupDetails ||
                                                pdfState.reportItem
                                                    .helperFactorsDetails) && (
                                                <Fragment>
                                                    <div className={styles.break}></div>
                                                    <li className={styles.h1}>
                                                        Wyniki obliczeń
                                                    </li>
                                                    {pdfState.reportItem
                                                        .pnCapacityDetails && (
                                                        <PnCapacityDetails
                                                            pileHeadSpot={pdfState.results.pileParameters.pileHeadSpot}
                                                            currentLength={pdfState.currentPileLength}
                                                            parameters={
                                                                pdfState.results
                                                                    .results
                                                            }
                                                        />
                                                    )}
                                                    {pdfState.reportItem
                                                        .enCapacityDetails && (
                                                        <PnEnCapacityDetails
                                                            pileHeadSpot={pdfState.results.pileParameters.pileHeadSpot}
                                                            currentLength={pdfState.currentPileLength}
                                                            parameters={
                                                                pdfState.results
                                                                    .results
                                                            }
                                                        />
                                                    )}
                                                    {pdfState.reportItem
                                                        .pilesInGroupDetails && (
                                                        <GroupPileDetails
                                                            pileHeadSpot={pdfState.results.pileParameters.pileHeadSpot}
                                                            currentLength={pdfState.currentPileLength}
                                                            parameters={
                                                                pdfState.results
                                                                    .results
                                                            }
                                                        />
                                                    )}
                                                    {pdfState.reportItem
                                                        .helperFactorsDetails && (
                                                        <TechnologyFactorsDetails                                        
                                                            pileHeadSpot={pdfState.results.pileParameters.pileHeadSpot}
                                                            currentLength={pdfState.currentPileLength}
                                                            parameters={
                                                                pdfState.results
                                                                    .results
                                                            }
                                                        />
                                                    )}
                                                    <div className={styles.break}></div>
                                                </Fragment>
                                            )}
                                            {(pdfState.reportItem.pileChart ||
                                                pdfState.reportItem.pullPileChart ||
                                                pdfState.reportItem.qcdChart ||
                                                pdfState.reportItem.tcdChart) && (
                                                <Fragment>
                                                    <li className={styles.h1}>
                                                        Załączniki
                                                    </li>
                                                    {pdfState.reportItem
                                                        .pileChart && (
                                                        <Fragment>
                                                            <PileCapacityChart
                                                                title="Nośność pala na wciskanie wg PN-83/B-2482 z uwzględnieniem Eurokodu 7"
                                                                results={
                                                                    pdfState.results
                                                                        .results
                                                                }
                                                                pileLength={
                                                                    pdfState
                                                                        .reportItem
                                                                        .pileLength
                                                                }
                                                                pileHeadSpot={
                                                                    pdfState.results
                                                                        .pileParameters
                                                                        .pileHeadSpot
                                                                }
                                                                pileShape={
                                                                    pdfState.results
                                                                        .pileParameters
                                                                        .pileShape
                                                                }
                                                                diameterPile={
                                                                    pdfState.results
                                                                        .pileParameters
                                                                        .diameterPile
                                                                }
                                                                singlePile={
                                                                    pdfState.results
                                                                        .singlePile
                                                                }
                                                                activeChart={
                                                                    pdfState.reportItem
                                                                        .pileChart
                                                                }
                                                                soilProfile={
                                                                    pileCapacityChartProfile
                                                                }
                                                                groundLevel={pdfState.results.groundLevel}
                                                            />
                                                            <div className={styles.break}></div>
                                                        </Fragment>
                                                    )}
                                                    {pdfState.reportItem
                                                        .pullPileChart && (
                                                            <PileCapacityChart
                                                                title="Nośność pala na wyciąganie wg PN-83/B-2482"
                                                                results={pdfState.results.results.map(
                                                                    (ele) => ({
                                                                        ...ele,
                                                                        nt: ele.ntw,
                                                                    }),
                                                                )}
                                                                pileLength={
                                                                    pdfState
                                                                        .reportItem
                                                                        .pullPileLength
                                                                }
                                                                pileHeadSpot={
                                                                    pdfState.results
                                                                        .pileParameters
                                                                        .pileHeadSpot
                                                                }
                                                                pileShape={
                                                                    pdfState.results
                                                                        .pileParameters
                                                                        .pileShape
                                                                }
                                                                diameterPile={
                                                                    pdfState.results
                                                                        .pileParameters
                                                                        .diameterPile
                                                                }
                                                                singlePile={
                                                                    pdfState.results
                                                                        .singlePile
                                                                }
                                                                soilProfile={
                                                                    pileCapacityChartProfile
                                                                }
                                                                groundLevel={pdfState.results.groundLevel}
                                                                type={'nw'}
                                                            />
                                                    )}
                                                    <div className={styles.avoidBreak}>
                                                        {
                                                            pdfState.reportItem.qcdChart &&
                                                            <Fragment>
                                                                <h2>Rozkład oporu gruntu pod podstawą pala</h2>
                                                                <div className={styles.imgBorder} style={{ width: `${maxWidth}px` }}>
                                                                    <img alt='' src={dataURI} />
                                                                </div>                                                                
                                                            </Fragment>
                                                        }
                                                        {
                                                            pdfState.reportItem.tcdChart &&
                                                            <Fragment>
                                                                <h2>Rozkład oporu gruntu wzdłuż pobocznicy pala</h2>
                                                                <div className={styles.imgBorder} style={{ width: `${maxWidth}px` }}>
                                                                    <img alt='' src={dataURITcd} />
                                                                </div>                                                                    
                                                            </Fragment>
                                                        }
                                                    </div>
                                                </Fragment>
                                            )}
                                        </div>
                                    </ol>
                                </main>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <div
                                    style={{ height: footerHeight }}
                                    className={styles.footerSpace}></div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <ResultsHeader
                    author={pdfState.report.author}
                    contact={pdfState.report.contact}
                />
                <ResultsFooter
                    setFooterHeight={useCallback((val) => setFooterHeight(val),[])}
                    pdfState={pdfState.reportItem}
                    globalTitle="Nośność pionowa pali wg normy PN-83/B-2482 z uwzględnieniem Eurokodu 7"
                    title={pdfState.projectTitle.projectTitle}
                />
            </div>
            {
                pdfState.reportItem
                .tcdChart &&
                <div className={styles.chartsWrapper}>
                    <TcdChart
                        maxWidth={maxWidth}
                        setDataURI={setDataURITcd}
                        chartTitle="Obliczeniowy opór na pobocznicy pala t,r"
                        xLabel="t,r [kPa]"
                        results={
                            pdfState.results
                                .results
                        }
                    />                
                </div>                
            }
            {
                pdfState.reportItem.qcdChart &&
                <div className={styles.chartsWrapper}>
                    <QcdChart
                        maxWidth={maxWidth}
                        setDataURI={setDataURI}
                        chartTitle="Obliczeniowy opór pod podstawą pala q,r"
                        xLabel="q,r [kPa]"
                        results={pdfState.results.results}
                    />                
                </div>                
            }
        </Fragment>
    );
});

export default PdfReport;