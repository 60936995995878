import { useCallback, useState } from "react"
import { isNumber, makeNumberPositive, roundNumber, stringToNumber ,isMinPileSpace, isMinPerpendicularPileSpace } from "../../../utils";

const initStateObj = {
    parallelSpace: {
        value: '',
        isValid: null,
    },
    perpendicularSpace: {
        value: '',
        isValid: null,
        isWarning: false
    },
    columns: {
        value: '',
        isValid: null
    },
    rows: {
        value: '',
        isValid: null
    },
}

const FoundationParametersHelpers = ({
    updateResultsState
}) => {

    const [foundationParametersState, setFoundationParametersState] = useState(initStateObj);

    const onBlurFoundationParametersState = useCallback((e) => {
        const { name, value } = e.currentTarget;
        const diameterPile = stringToNumber(e.currentTarget.getAttribute('data-diameter'));
        const valid = isNumber(diameterPile) && !isMinPileSpace(value, diameterPile) ? false : e.currentTarget.checkValidity();

        setFoundationParametersState(state => ({
            ...state, 
            [name]: {
                ...state[name],
                isValid: valid,
                ...(name === 'perpendicularSpace') && {
                    isWarning: valid ? !isMinPerpendicularPileSpace(value, diameterPile) : false
                }
            }
        }))
    },[])
    const onChangeFoundationParametersState = useCallback((e) => {
        const input = e.currentTarget.closest('input');
        if(input){
            const { name, value, type } = input;

            updateResultsState((state) => {
                return {
                    ...state,
                    isResultsActual: false,
                };
            });

            setFoundationParametersState(state => {
                return {
                    ...state,
                    [name]: {
                        value: (type === 'number' && value.length > 0) ? (name === 'columns' || name === 'rows') ? roundNumber(makeNumberPositive(Number(value))) : makeNumberPositive(Number(value)) : value,
                        isValid: true
                    }
                }
            })
        }
    },[])

    const foundationParameters = {
        onChange: onChangeFoundationParametersState,
        onBlur: onBlurFoundationParametersState,
        state: foundationParametersState,
        initState: () => setFoundationParametersState(initStateObj),
        updateState: setFoundationParametersState
    }

    return {
        foundationParameters
    }
}

export default FoundationParametersHelpers