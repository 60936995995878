import { Fragment } from "react";
import { isNumber } from "../../../../../utils";
import styles from './index.module.css';


const CalculationSection = ({ calculations={}, c, e }) => {

    return ( 
        <div className={styles.wrapper}>
            <h2>Parametry obliczeń</h2>
            <p>Typ analizy: <span className={styles.value}>{calculations.calcElements.value ? 'Dobór elementu' : 'Sprawdzenie nośności'}</span></p>
            <p>Siła od kotwienia: <span className={styles.value}>{calculations.anchorForce.value} kN/m</span></p>
            <p>Moment w poziomie kotwienia: <span className={styles.value}>{calculations.med_sheet.value} kNm/m</span></p>
            <p>Rozstaw kotew: <span className={styles.value}>{calculations.space.value.toFixed(2)} m</span></p>
            <p>Rodzaj ścianki szczelnej: <span className={styles.value}>{calculations.sheetPileType.value}</span></p>
            {
                (calculations.walingType_1.value || calculations.walingType_4.value) &&
                <Fragment>
                    <h2>Parametry kleszcza</h2>
                    {
                        calculations.checkElements.value && calculations.ceo.value &&
                        <p>Rodzaj kleszcza: <span className={styles.value}>2x{calculations.ceo.value}</span></p>
                    }
                    {
                        isNumber(calculations.s.value) && calculations.checkElements.value &&
                        <p>Rozstaw ceowników: <span className={styles.value}>{calculations.s.value.toFixed(2)} mm</span></p>
                    }
                    <p>Klasa stali kleszcza: <span className={styles.value}>{calculations.steelClass.value}</span></p>
                    <p>Granica plastyczności kleszcza: <span className={styles.value}>{calculations.ceoFy.value} MPa</span></p>                    
                </Fragment>
            }
            {
                calculations.checkElements.value &&
                <Fragment>
                    <h2>Parametry płyty oporowej</h2>
                    <p>Szerokość płyty [b<sub>w</sub>]: <span className={styles.value}>{calculations.width.value} mm</span></p>
                    <p>Wysokość płyty [h<sub>p</sub>]: <span className={styles.value}>{calculations.height.value} mm</span></p>
                    <p>Grubość płyty [t<sub>p</sub>]: <span className={styles.value}>{calculations.thickness.value} mm</span></p>
                    {
                        isNumber(c) && isNumber(e) &&
                        <Fragment>
                            <p>Wysokość podkładki [c]: <span className={styles.value}>{10*c.toFixed(1)} mm</span></p>
                            <p>Szerokość podkładki [e]: <span className={styles.value}>{10*e.toFixed(1)} mm</span></p>
                        </Fragment>
                    }                    
                </Fragment>
            }

            <p>Średnica otworu płyty [d]: <span className={styles.value}>{calculations.d.value} mm</span></p>
            <p>Średnica nakrętki kotwy [d<sub>SG</sub>]: <span className={styles.value}>{calculations.dsg.value} mm</span></p>
        </div>
    )
}

export default CalculationSection