import { faFolderOpen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import { Pagination } from '../../../../components';
import { withPagination } from '../../../../hoc';
import styles from './index.module.css';
import { Row, Col } from 'react-bootstrap';

const ProfilesList = ({
    onOpenProfileModal,
    dataToPaginate,
    pagination,
    paginationHelpers,
}) => {
    return (
        <Fragment>
            <Row className={styles.row}>
                <Col xs={12}>
                    {dataToPaginate.length > 0 ? (
                        <Fragment>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th>Nr</th>
                                        <th title="Nazwa profilu">
                                            Nazwa profilu
                                        </th>
                                        <th title="Data zapisu">Data zapisu</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className={styles.tbody}>
                                    {dataToPaginate.map((ele, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {paginationHelpers.countingItemsPerPage +
                                                        index +
                                                        1}
                                                    .
                                                </td>
                                                <td>{ele.profileName}</td>
                                                <td>
                                                    {new Date(
                                                        ele.updatedAt,
                                                    ).toLocaleString('pl-PL', {
                                                        dateStyle: 'medium',
                                                        timeStyle: 'short',
                                                    })}
                                                </td>
                                                <td>
                                                    <button
                                                        className={styles.load}
                                                        title="Otwórz profil geologiczny"
                                                        name="openProfile"
                                                        data-id={ele.id}
                                                        data-profile={
                                                            ele.profileName
                                                        }
                                                        onClick={
                                                            onOpenProfileModal
                                                        }>
                                                        <FontAwesomeIcon
                                                            icon={faFolderOpen}
                                                        />
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        className={
                                                            styles.delete
                                                        }
                                                        title="Usuń profil geologiczny"
                                                        name="deleteProfile"
                                                        data-id={ele.id}
                                                        data-profile={
                                                            ele.profileName
                                                        }
                                                        onClick={
                                                            onOpenProfileModal
                                                        }>
                                                        <FontAwesomeIcon
                                                            icon={faTrashAlt}
                                                        />
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            {paginationHelpers.totalPages > 1 && (
                                <Pagination
                                    currentPage={pagination.currentPage}
                                    itemsPerPage={pagination.itemsPerPage}
                                    totalItems={
                                        paginationHelpers.allItemsLength
                                    }
                                    nextPage={paginationHelpers.nextPage}
                                    css={styles.paginationWrapper}
                                />
                            )}
                        </Fragment>
                    ) : (
                        <p className={styles.center}>
                            Brak zapisanych profili gruntowych
                        </p>
                    )}
                </Col>
            </Row>
        </Fragment>
    );
};

export default withPagination({
    component: ProfilesList,
    returnAllCollection: false,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 15,
    },
});
