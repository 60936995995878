import React from 'react';
import styled from 'styled-components';
import { Fade, Container } from 'react-bootstrap'; 
import logo from '../../../assets/Logo.svg'

import ResendActivationLink from '../ResendLink';
import { AlertMessage2 }  from '../../../components';
import config from '../../../config/config';


const Image = styled.img`
    max-width: 350px;
    text-align: center; 
    display: block;
    padding-top: 130px;
    margin: 0px auto 10px auto;
`
const CenterDiv = styled.div`
    text-align: center;

    & h1 {
        margin: 50px 0;
    }

    & a {
        color: #000;
        display: block;
    }
` 
const ActivationContent = ({ expired, ...rest }) => {
    const { routes } = config;

    return (
        <Container>
            <Image src={logo} alt="Activation preview"/>
            <CenterDiv>
                <h1>Aktywacja konta</h1>
            </CenterDiv>
            
            <Fade>
                <AlertMessage2 {...rest}></AlertMessage2>
            </Fade>

            <CenterDiv>
                <a href={routes.dashboard.logIn}>Powrót do logowania</a>
            </CenterDiv>

            {expired && <ResendActivationLink />}
        </Container>
    )
}

export default ActivationContent;