import React, { createContext, useContext } from 'react';
import {
    GlobalAlert,
    Loading,
    ContactFormService
} from '../../services';
import { authContext } from '../Authentication';
  
const contactFormContext = createContext({});

const ContactFormProvider = ({ children }) => {
    const { firstName, lastName, email } = useContext(authContext);
    const { globalAlerts }              = GlobalAlert();
    const { globalLoading }             = Loading(); 
    const { contactForm } = ContactFormService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        fetch: {
            sendMessage: {
                url: '/api/users/message/send',
                method: 'POST',
            }
        },
        user: {
            firstName,
            lastName,
            email
        }
    });
    return ( 
        <contactFormContext.Provider   
            value={{ 
                globalAlerts,
                globalLoading,
                contactForm
            }}
        >
            {children}
        </contactFormContext.Provider>
    )
}

export { contactFormContext }
export default ContactFormProvider