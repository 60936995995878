const minMaxArgument = (callback, val, arr) => {
    if(Array.isArray(arr)){
        const min = Math.min(...arr);
        const max = Math.max(...arr);

        if(val < min){
            return callback(min)
        } else if(val > max){
            return callback(max)
        } else {
            return callback(val)
        }
    } else {
        return callback(val)
    }
}

export default minMaxArgument