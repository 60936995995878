const sheetPiles = {
    //cm. cm2
    //b - szerokość JEDNEGO brusa cm
    'AZ 18-800' : {
        b: 160, 
        perimeter: 460.32,
        area: 205.72,
        plug: 3484.46
    },
    'AZ 20-800' : {
        b: 160, 
        perimeter: 460.56,
        area: 226.6,
        plug: 3482.6
    },
    'AZ 22-800' : {
        b: 160, 
        perimeter: 460.4,
        area: 245.56,
        plug: 3480.46
    },
    'AZ 23-800' : {
        b: 160, 
        perimeter: 467.2,
        area: 240.98,
        plug: 3666.82
    },
    'AZ 25-800' : {
        b: 160, 
        perimeter: 467.44,
        area: 261.28,
        plug: 3664.82
    },
    'AZ 27-800' : {
        b: 160, 
        perimeter: 467.7,
        area: 281.58,
        plug: 3662.67
    },
    'AZ 28-750' : {
        b: 150, 
        perimeter: 466.92,
        area: 256.8,
        plug: 3684.62
    },
    'AZ 30-750' : {
        b: 150, 
        perimeter: 467.16,
        area: 277.1,
        plug: 3681.91
    },
    'AZ 32-750' : {
        b: 150, 
        perimeter: 467.4,
        area: 297.4,
        plug: 3679.25
    },
    'AZ 12-770' : {
        b: 154, 
        perimeter: 411.9,
        area: 185.06,
        plug: 2547.96
    },
    'AZ 13-770' : {
        b: 154, 
        perimeter: 412.02,
        area: 193.8,
        plug: 2547.43
    },
    'AZ 14-770' : {
        b: 154, 
        perimeter: 412.14,
        area: 202.54,
        plug: 2546.91
    },
    'AZ 12-700' : {
        b: 140, 
        perimeter: 383.3,
        area: 172.46,
        plug: 2107.35
    },
    'AZ 13-700' : {
        b: 140, 
        perimeter: 383.54,
        area: 188.54,
        plug: 2106.31
    },
    'AZ 13-700-10/10' : {
        b: 140, 
        perimeter: 383.66,
        area: 196.6,
        plug: 2105.78
    },
    'AZ 14-700' : {
        b: 140, 
        perimeter: 383.78,
        area: 204.6,
        plug: 2105.26
    },
    'AZ 17-700' : {
        b: 140, 
        perimeter: 414.04,
        area: 186.32,
        plug: 2838.88
    },
    'AZ 18-700' : {
        b: 140, 
        perimeter: 414.16,
        area: 195.12,
        plug: 2837.98
    },
    'AZ 19-700' : {
        b: 140, 
        perimeter: 413.58,
        area: 203.92,
        plug: 2837.07
    },
    'AZ 20-700' : {
        b: 140, 
        perimeter: 414.42,
        area: 212.74,
        plug: 2836.16
    },
    'AZ 24-700' : {
        b: 140, 
        perimeter: 428.68,
        area: 243.8,
        plug: 3086.58
    },
    'AZ 26-700' : {
        b: 140, 
        perimeter: 428.92,
        area: 262.06,
        plug: 3084.43
    },
    'AZ 28-700' : {
        b: 140, 
        perimeter: 429.16,
        area: 280.32,
        plug: 3082.27
    },
    'AZ 36-700N' : {
        b: 140, 
        perimeter: 454.72,
        area: 302.24,
        plug: 3337.23
    },
    'AZ 38-700N' : {
        b: 140, 
        perimeter: 454.98,
        area: 322.04,
        plug: 3334.3
    },
    'AZ 40-700N' : {
        b: 140, 
        perimeter: 455.24,
        area: 341.86,
        plug: 3331.37
    },
    'AZ 42-700N' : {
        b: 140, 
        perimeter: 454.06,
        area: 362.14,
        plug: 3307.28
    },
    'AZ 44-700N' : {
        b: 140, 
        perimeter: 454.34,
        area: 381.96,
        plug: 3304.34
    },
    'AZ 46-700N' : {
        b: 140, 
        perimeter: 454.6,
        area: 401.8,
        plug: 3301.4
    },
    'AZ 48-700' : {
        b: 140, 
        perimeter: 452.78,
        area: 403.76,
        plug: 3314.51
    },
    'AZ 50-700' : {
        b: 140, 
        perimeter: 453.06,
        area: 423.62,
        plug: 3311.58
    },
    'AZ 52-700' : {
        b: 140, 
        perimeter: 453.34,
        area: 443.48,
        plug: 3308.64
    },
    'AZ 18' : {
        b: 126, 
        perimeter: 384.82,
        area: 189.6,
        plug: 2294.74
    },
    'AZ 18-10/10' : {
        b: 126, 
        perimeter: 384.94,
        area: 197.84,
        plug: 2293.76
    },
    'AZ 26' : {
        b: 126, 
        perimeter: 399.52,
        area: 249.14,
        plug: 2560.99
    },
    
    'GU 6N' : {
        b: 60, 
        perimeter: 171.18,
        area: 53.39,
        plug: 741.65
    },
    'GU 7N' : {
        b: 60, 
        perimeter: 171.27,
        area: 56.21,
        plug: 741.65
    },
    'GU 7S' : {
        b: 60, 
        perimeter: 171.4,
        area: 60.15,
        plug: 741.64
    },
    'GU 7HWS' : {
        b: 60, 
        perimeter: 171.44,
        area: 60.44,
        plug: 741.52
    },
    'GU 8N' : {
        b: 60, 
        perimeter: 171.45,
        area: 61.85,
        plug: 741.65
    },
    'GU 8S' : {
        b: 60, 
        perimeter: 171.58,
        area: 64.67,
        plug: 741.52
    },
    'GU 10N' : {
        b: 60, 
        perimeter: 175.29,
        area: 71.09,
        plug: 776.95
    },
    'GU 11N' : {
        b: 60, 
        perimeter: 175.47,
        area: 76.71,
        plug: 776.95
    },
    'GU 12N' : {
        b: 60, 
        perimeter: 175.66,
        area: 82.33,
        plug: 776.95
    },
    'GU 13N' : {
        b: 60, 
        perimeter: 188,
        area: 76.29,
        plug: 957.52
    },
    'GU 14N' : {
        b: 60, 
        perimeter: 188.22,
        area: 81.88,
        plug: 957.52
    },
    'GU 15N' : {
        b: 60, 
        perimeter: 188.32,
        area: 87.54,
        plug: 957.52
    },
    'GU 16N' : {
        b: 60, 
        perimeter: 193.78,
        area: 92.52,
        plug: 999.25
    },
    'GU 18N' : {
        b: 60, 
        perimeter: 193.60,
        area: 97.97,
        plug: 993.76
    },
    'GU 20N' : {
        b: 60, 
        perimeter: 193.42,
        area: 103.41,
        plug: 988.36
    },
    'GU 21N' : {
        b: 60, 
        perimeter: 193.42,
        area: 103.41,
        plug: 988.36
    },
    'GU 22N' : {
        b: 60, 
        perimeter: 200.48,
        area: 109.77,
        plug: 1063.86
    },
    'GU 23N' : {
        b: 60, 
        perimeter: 200.29,
        area: 115.2,
        plug: 1058.42
    },
    'GU 27N' : {
        b: 60, 
        perimeter: 205.93,
        area: 124.12,
        plug: 1104.38
    },
    'GU 28N' : {
        b: 60, 
        perimeter: 206.11,
        area: 129.72,
        plug: 1104.38
    },
    'GU 30N' : {
        b: 60, 
        perimeter: 206.29,
        area: 135.33,
        plug: 1104.38
    },
    'GU 31N' : {
        b: 60, 
        perimeter: 204.27,
        area: 140.00,
        plug: 1090.50
    },
    'GU 32N' : {
        b: 60, 
        perimeter: 204.08,
        area: 145.4,
        plug: 1085.09
    },
    'GU 33N' : {
        b: 60, 
        perimeter: 203.9,
        area: 150.8,
        plug: 1079.69
    },
    'GU 16-400' : {
        b: 40, 
        perimeter: 151.81,
        area: 78.93,
        plug: 5054.55
    },
    'GU 18-400' : {
        b: 40, 
        perimeter: 151.87,
        area: 88.33,
        plug: 504.61
    },


    'PU 12' : {
        b: 60, 
        perimeter: 180.43,
        area: 84.19,
        plug: 840.95
    },
    'PU 12S' : {
        b: 60, 
        perimeter: 180.90,
        area: 90.49,
        plug: 843.05
    },
    'PU 18-1' : {
        b: 60, 
        perimeter: 193.78,
        area: 92.52,
        plug: 999.25
    },
    'PU 18' : {
        b: 60, 
        perimeter: 193.60,
        area: 97.98,
        plug: 993.76
    },
    'PU 18+1' : {
        b: 60, 
        perimeter: 193.42,
        area: 103.41,
        plug: 988.36
    },
    'PU 22-1' : {
        b: 60, 
        perimeter: 200.66,
        area: 104.33,
        plug: 1069.29
    },
    'PU 22' : {
        b: 60, 
        perimeter: 200.47,
        area: 109.77,
        plug: 1063.86
    },
    'PU 22+1' : {
        b: 60, 
        perimeter: 200.29,
        area: 115.2,
        plug: 1058.42
    },
    'PU 28-1' : {
        b: 60, 
        perimeter: 205.93,
        area: 124.12,
        plug: 1104.38
    },
    'PU 28' : {
        b: 60, 
        perimeter: 206.11,
        area: 129.72,
        plug: 1104.38
    },
    'PU 28+1' : {
        b: 60, 
        perimeter: 206.29,
        area: 135.33,
        plug: 1104.38
    },
    'PU 32-1' : {
        b: 60, 
        perimeter: 204.27,
        area: 140.0,
        plug: 1090.50
    },
    'PU 32' : {
        b: 60, 
        perimeter: 204.08,
        area: 145.4,
        plug: 1085.09
    },
    'PU 32+1' : {
        b: 60, 
        perimeter: 203.9,
        area: 150.8,
        plug: 1079.69
    },

    'AU 14' : {
        b: 75, 
        perimeter: 212.40,
        area: 99.23,
        plug: 1199.84
    },
    'AU 16' : {
        b: 75, 
        perimeter: 212.7,
        area: 109.87,
        plug: 1199.92
    },
    'AU 18' : {
        b: 75, 
        perimeter: 221.04,
        area: 112.81,
        plug: 1331.27
    },
    'AU 20' : {
        b: 75, 
        perimeter: 221.35,
        area: 123.43,
        plug: 1331.54
    },
    'AU 23' : {
        b: 75, 
        perimeter: 225.34,
        area: 130.07,
        plug: 1376.5
    },
    'AU 25' : {
        b: 75, 
        perimeter: 225.64,
        area: 140.63,
        plug: 1376.51
    },
}

export default sheetPiles