import { Table } from "react-bootstrap"
import styles from './index.module.css';

const SoilParametersSection = ({ parameters=[] }) => {

    return (
        <div className={styles.wrapper}>
            <h2>Parametry gruntu</h2>
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th>Nr<br></br>[-]</th>
                        <th>Nazwa gruntu<br></br>[-]</th>
                        <th>Z<br></br>[m.p.p.t]</th>
                        <th>&#947; <br></br>[kN/m<sup>3</sup>]</th>
                        <th>&#947;' <br></br>[kN/m<sup>3</sup>]</th>
                        <th>I<sub>d</sub> <br></br>[-]</th>
                        <th>I<sub>L</sub> <br></br>[-]</th> 
                    </tr>
                </thead>
                <tbody>
                    {parameters.map((element, index) => { 
                        return (
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{element.name.value}</td>
                                <td>{element.height.value}</td>
                                <td>{element.weight.value}</td>
                                <td>{element.underWaterWeight.value}</td>
                                <td>{element.density.value || '-'}</td>
                                <td>{element.plasticity.value || '-'}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>            
        </div>
    )
}

export default SoilParametersSection