import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import {
    Modal,
    Table,
    Button,
    SelectField,
    ItemsPerPage,
    Pagination,
    SectionTitle,
} from '../../../../components'; 
import { withPagination } from '../../../../hoc';
import styles from './index.module.css';
import { Fragment, useState } from 'react';
import InvoiceForm from '../../../../components/InvoiceForm';

const RenewSubscription = ({
    dataToPaginate,
    pagination,
    paginationHelpers,
    open,
    onHide,
    onOpen,
    state = {},
    onChange,
    onSubmit,

    invoiceType,
    invoiceState,
    saveFormData,
    onChangeInvoiceType,
    onChangeInvoice,
    onBlurInvoice,
    onChangeDataCheckbox,
}) => {
    const [openInvoice, setOpenInvoice] = useState(false);

    const onOpenInvoice = () => setOpenInvoice(true);
    const onHideInvoice = () => setOpenInvoice(false)

    return (
        <Fragment>
            <Modal
                open={open}
                css={styles.modalOverflow}
                cssDialog={styles.modal}
                onHide={onHide}
                title="Przedłużenie subskrypcji">
                <div className={styles.spaceBetween}>
                    <SectionTitle title="Wybierz typ subskrypcji dla użytkowników" />
                    <ItemsPerPage
                        onDisplayItemsNumber={paginationHelpers.displayItemsNumber}
                        itemsPerPage={pagination.itemsPerPage}
                        totalItems={paginationHelpers.allItemsLength}
                        display={[15, 30, paginationHelpers.allItemsLength]}
                    />
                </div>
                <Table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Nr</th>
                            <th>Imię</th>
                            <th>Nazwisko</th>
                            <th>Email</th>
                            <th>Typ subskrypcji</th>
                            <th>Data wygaśnięcia</th>
                            <th width={100}>Cena</th>
                        </tr>
                    </thead>

                    {dataToPaginate.length > 0 && (
                        <tbody>
                            {dataToPaginate.map((element, index) => {
                                return (
                                    <tr key={index}>
                                        <td width={30}>
                                            {paginationHelpers.countingItemsPerPage +
                                                index +
                                                1}
                                        </td>
                                        <td>{element.firstName}</td>
                                        <td>{element.lastName}</td>
                                        <td>{element.email}</td>
                                        <td width={220}>
                                            <SelectField
                                                name="type"
                                                value={element.subscription.plan}
                                                defaultOption="Wybierz plan subskrypcyjny"
                                                options={[
                                                    'Subskrypcja miesięczna',
                                                    'Subskrypcja roczna',
                                                ]}
                                                data-row={index}
                                                onChange={(e) =>
                                                    onChange(e, element.id)
                                                }
                                                required
                                            />
                                        </td>
                                        <td>
                                            {moment(
                                                moment(
                                                    element.subscription.endDate,
                                                ).diff(moment(), 'seconds') > 0
                                                    ? element.subscription.endDate
                                                    : {},
                                            )
                                                .add(
                                                    1,
                                                    element.subscription.plan ===
                                                        'Subskrypcja roczna'
                                                        ? 'years'
                                                        : 'months',
                                                )
                                                .local('pl')
                                                .format('DD.MM.YYYY, HH:mm')}
                                        </td>
                                        <td width={150}>
                                            {element.subscription.price}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    )}
                </Table>
                {paginationHelpers.totalPages > 1 && (
                    <Pagination
                        currentPage={pagination.currentPage}
                        itemsPerPage={pagination.itemsPerPage}
                        totalItems={paginationHelpers.allItemsLength}
                        nextPage={paginationHelpers.nextPage}
                    />
                )}
                <Row>
                    <Col xs={12}>
                        <h2 className={styles.cost}>
                            Łączny koszt: {state.totalPrice} zł
                        </h2>

                        <div className={styles.centerButton}>
                            <Button
                                icon={faShoppingCart}
                                variant={'info'}
                                onClick={() => {
                                    onOpenInvoice();
                                    onHide();
                                
                                }}
                                title={
                                    dataToPaginate.length === 1
                                        ? 'Przedłuż subskrypcję'
                                        : 'Przedłuż subskrypcje'
                                }
                            />
                        </div>
                    </Col>
                </Row>
            </Modal>        
            <InvoiceForm
                openInvoice={openInvoice}
                invoiceType={invoiceType}
                invoiceState={invoiceState}
                saveFormData={saveFormData}
                onChangeInvoiceType={onChangeInvoiceType}
                onChangeInvoice={onChangeInvoice}
                onBlurInvoice={onBlurInvoice}
                onChangeDataCheckbox={onChangeDataCheckbox}
                onHideInvoice={() => {
                    onOpen(state => {
                        return {
                            ...state,
                            renewSubscription: true                            
                        }
                    });
                    onHideInvoice();
                }}
                onSubmit={onSubmit}
            />
        </Fragment>

    );
};

export default withPagination({
    component: RenewSubscription,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 15,
    },
});
