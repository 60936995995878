import React, { Fragment } from 'react';
import styles from './index.module.css';

const NavCategory = ({ label, children }) => {
    return (
        <Fragment>
            <label className={styles.label}>{label}</label>
            <ul className={styles.ul}>{children}</ul>
        </Fragment>
    );
};

export default NavCategory;
