import React from 'react';
import { Fade, Form } from 'react-bootstrap';
import styled from 'styled-components';
import { AlertMessage2, Email } from '../../../components';
import { withForm } from '../../../hoc';

const ResendForm = styled.div`
    max-width: 600px;
    margin: 50px auto;
`
const Button = styled.button`
    color: #fff;
    margin-top: 15px;
    background-color: rgb(34, 123, 175);
    font-size: 15px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid #007bff;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:hover{
        background-color: rgb(34, 123, 175);
    }
`
const ResendActivationLink = ({ formState, messageState={}, onChange, actions={}, onSubmit }) => {

    const onSubmitForm = (e) => {
        e.preventDefault();

        onSubmit((formData) => {
            actions.resendLink({
                body: formData
            })            
        })
    } 
    return (
        <ResendForm>
            <Form onSubmit={onSubmitForm} noValidate>
                <Form.Group controlId="username">
                    <Email onChange={onChange} email={formState.email} label="Email" placeholder="Email" isCorrectLabel required/>
                </Form.Group>
                <Button className="resend-link" type="submit" variant="primary">Wyślij link aktywacyjny</Button>
            </Form>
            <Fade>
                <AlertMessage2 isLoaded={formState.isLoaded} loadingName={formState.loadingName} { ...messageState.resendLink }></AlertMessage2>
            </Fade>
        </ResendForm>
    )
}

export default withForm({
    component: ResendActivationLink,
    clearFormToDefaultState: true,
    actions: [
        {
            name: 'resendLink',
            url: '/api/auth/account/activate/resend',
            method: 'POST',
            headers: {
                'Accept': 'application/json', 
                'Content-Type': 'application/json',
            },
            errorMessage: 'Wystąpił błąd podczas aktywacji konta',
            showErrorMessage: true,
            showSuccessMessage: true,
        },
    ],
    localState: {
        loadingName: "",
        email: {
            value: '',
            isValid: null,
        },
    }    
}) 