import { Fragment } from "react";
import styles from './index.module.css';

const ProjectInfo= ({ parameters={}, memberType }) => {
    return ( 
        <div className={styles.wrapper}>
            <h2>Parametry materiałowe i siły wewnętrzne</h2>
            <p>Klasa betonu: <span className={styles.value}>{parameters.concreteClass.value}</span></p>
            <p>Charakterystyczna wytrzymałość walcowa na ściskanie [f<sub>ck</sub>]: <span className={styles.value}>{parameters.fck.value} MPa</span></p>
            <p>Średnia wytrzymałość na rozciąganie [f<sub>ctm</sub>]: <span className={styles.value}>{parameters.fctm.value} MPa</span></p>
            <p>Moduł sprężystości betonu [E<sub>cm</sub>]: <span className={styles.value}>{parameters.ecm.value} MPa</span></p>

            <p>Klasa zbrojenia głównego: <span className={styles.value}>{parameters.steelClass.value || '[-]'}</span></p>
            <p>Charakterystyczna granica plastyczności zbrojenia głównego [f<sub>yk</sub>]: <span className={styles.value}>{parameters.fyk.value} MPa</span></p>
            <p>Charakterystyczna wytrzymałość na rozciąganie zbrojenia głównego [f<sub>t</sub>]: <span className={styles.value}>{parameters.ft.value} MPa</span></p>
            {
                memberType !== 'Płyta' &&
                <Fragment>
                    <p>Klasa zbrojenia na ścinanie: <span className={styles.value}>{parameters.steelClassSpiral.value || '[-]'}</span></p>
                    <p>Charakterystyczna granica plastyczności zbrojenia na ścinanie [f<sub>ywk</sub>]: <span className={styles.value}>{parameters.fywk.value} MPa</span></p>                    
                </Fragment>
            }
            <p>Moduł sprężystości stali [E]: <span className={styles.value}>{parameters.youngModulus.value} GPa</span></p>
            <p>Graniczne odkształcenie stali [&epsilon;<sub>uk</sub>]: <span className={styles.value}>{parameters.euk.value} %</span></p>

            <p>Siły wewnętrzne:</p>
            {
                parameters.internalForces.map((ele,idx, arr) => {
                    return (
                        <Fragment key={idx}>
                            {arr.length > 1 && <p><u>Przypadek nr {idx +1}</u></p>}
                            <p>Moment zginający [M<sub>x</sub>]: <span className={styles.value1}>{ele.bendingMoment.value || '0'} kNm</span></p>
                            {memberType === 'Słup' && <p>Siła osiowa [N<sub>z</sub>]: <span className={styles.value}>{ele.axialForce.value || '0'} kN</span></p>}
                            {
                                memberType !== 'Płyta' &&
                                <p>Siła ścinająca [V<sub>y</sub>]: <span className={styles.value1}>{ele.shearForce.value || '0'} kN</span></p>
                            }
                        </Fragment>
                    )
                })
            }
        </div>
    )
}

export default ProjectInfo