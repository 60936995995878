import { Navigate, Outlet } from "react-router-dom"
import config from '../../../config/config';

const AdminLoginLayout = () => {
    const { routes } = config;

    const token = localStorage.getItem('token1');
    const isLoggedIn = localStorage.getItem('isLoggedIn1')
    const isUserLoggedIn = token && isLoggedIn

    return (
        isUserLoggedIn
        ?
        <Navigate to={routes.admin.home} />
        :
        <Outlet />
    )
}

export default AdminLoginLayout