const pilesType = {
    'CFA': { 
        'Wiercony': {
            sn: () => 0.9,
        }
    }, 
    'Z rury stalowej z dnem otwartym': { 
        'Wbijany': {
            sn: (cohesive) => cohesive ? 1 : 1.1,
        },
        'Wciskany': {
            sn: (cohesive) => cohesive ? 1 : 1.1,
        },
        'Wwibrowywany': {
            sn: (cohesive, id) => cohesive ? 0.7 : id > 0.33 ? 1.1 : 1.2
        },
        'Wpłukiwany - ostatni 1m wbijany': {
            sn: (cohesive) => cohesive ? 0.5 : 0.8
        }
    }, 
    'Z rury stalowej z dnem zamkniętym': {
        'Wbijany': {
            sn: (cohesive) => cohesive ? 1 : 1.1
        },
        'Wciskany': {
            sn: (cohesive) => cohesive ? 1 : 1.1
        },
        'Wwibrowywany': {
            sn: (cohesive, id) => cohesive ? 0.5 : id > 0.33 ? 1.1 : 1.2
        },
        'Wpłukiwany - ostatni 1m wbijany': {
            sn: (cohesive) => cohesive ? 0.3 : 0.8
        }
    },
    'Żelbetowe prefabrykowane': {
        'Wbijany': {
            sn: (cohesive) => cohesive ? 1 : 1.1
        },
        'Wciskany': {
            sn: (cohesive) => cohesive ? 1 : 1.1
        },
        'Wwibrowywany': {
            sn: (cohesive, id) => cohesive ? 0.5 : id > 0.33 ? 1.1 : 1.2
        },
        'Wpłukiwany - ostatni 1m wbijany': {
            sn: (cohesive) => cohesive ? 0.3 : 0.8
        }
    }, 
    'Wiercone z iniekcją pod podstawą':{
        'Wiercony bez rury obsadowej': {
            sn: () => 0.9
        },
        'Wiercony w rurze obsadowej wyciąganej': {
            sn: () => 0.9
        },
        'Wiercony w rurze obsadowej pozostawianej': {
            sn: () => 0.9
        },
    }, 
    'Wiercone bez iniekcji pod postawą': {
        'Wiercony bez rury obsadowej': {
            sn: () => 0.9
        },
        'Wiercony w rurze obsadowej wyciąganej': {
            sn: () => 0.9
        },
        'Wiercony w rurze obsadowej pozostawianej': {
            sn: () => 0.9
        },
    }, 
    'Omega': {
        'Wkręcany': {
            sn: () => 1.1,
        }
    }, 
    'Atlas': {
        'Wkręcany': {
            sn: () => 1.1,
        }
    },
    'Vibrex': {
        'Wbijany': {
            sn: () => 1.15,
        }
    },
    'Vibro-Fundex': {
        'Wbijany': {
            sn: () => 1.15
        }
    },
    'Fundex': {
        'Wkręcano-wciskany': {
            sn: () => 1.15
        }
    },
    'Tubex': {
        'Wkręcany': {
           sn: () => 1.1            
        }
    },
    'FDP': {
        'Wkręcany': {
           sn: () => 1.1            
        }
    },
    'SDP': {
        'Wkręcany': {
           sn: () => 1.1            
        }
    },
    'PCS Lambda': {
        'Wiercony': {
            sn: () => 0.9,
        }   
    },
    'Starsol': {
        'Wiercony': {
            sn: () => 0.9,
        }
    },
    'Franki': {
        'Wbijany':{
            sn: () => 1.15
        }
    }
};

export default pilesType