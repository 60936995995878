import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { isNumber, roundNumber, setSoilColor } from '../../../../utils';
import { SectionTitle } from '../../../../components';
import SoilLayer from './SoilLayer';
import { isMicropileCompressionAngleCorrect } from '../../../../config/ProjectConfig/projectPatterns';
import styles from './index.module.css';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';

const SoilProfile = ({
    micropileHeadSpot = '',
    micropileAngle = '',
    height = [],
    density = [],
    plasticity = [],
    profileParameters = {},
    name = [],
    qccpt = [],
    cucpt = [],
    friction = [],
}) => {
    const soilProfileRef = useRef(null);
    const micropileRef = useRef(null);
    const [soilLayer, setSoilLayer] = useState([]);

    const [zwgHeight, setZwgHeight] = useState('');
    const [freeSpace, setFreeSpace] = useState(0);
    const [micropileFreeSpace, setMicropileFreeSpace] = useState(0);
    const [minSpotVal, setMinSpotVal] = useState(0);

    const [micropileLength, setMicropileLength] = useState('');
    const [micropileHeadSpotPx, setMicropileHeadSpotPx] = useState('');
    const [isPileVisible, setIsPileVisible] = useState(false);
    const [maxHeight, setMaxHeight] = useState('');
    const [offsetHeight, setOffsetHeight] = useState('');
    const [offsetWidth, setOffsetWidth] = useState('');
    const [micropileOffset, setMicropileOffset] = useState({
        width: 0,
        height: 0,
    });

    useDeepCompareEffect(() => {
        const maxValueHeight = Math.max(...height) + minSpotVal;

        setMaxHeight(isNumber(maxValueHeight) ? maxValueHeight : '');

        if (soilProfileRef.current) {
            setOffsetHeight(soilProfileRef.current.offsetHeight);
            setOffsetWidth(soilProfileRef.current.offsetWidth);
        }
    }, [
        height,
        micropileHeadSpot,
        profileParameters.zwg.value,
        minSpotVal,
        micropileAngle,
    ]);

    useDeepCompareEffect(() => {
        setIsPileVisible(
            isNumber(micropileHeadSpot) &&
                isMicropileCompressionAngleCorrect(micropileAngle) &&
                Math.max(...height) > micropileHeadSpot,
        );
    }, [micropileAngle, micropileHeadSpot, height]);

    useEffect(() => {
        const val = Math.min(
            ...[profileParameters.zwg.value, micropileHeadSpot].filter((ele) =>
                isNumber(ele),
            ),
        );
        setMinSpotVal(isNumber(val) && val < 0 ? Math.abs(val) : 0);
    }, [profileParameters.zwg.value, micropileHeadSpot]);

    useEffect(() => {
        const currentMicropileSpot =
            micropileHeadSpot >= 0
                ? (offsetHeight * (micropileHeadSpot + minSpotVal)) / maxHeight
                : micropileHeadSpot > profileParameters.zwg.value
                ? (offsetHeight * (minSpotVal - Math.abs(micropileHeadSpot))) /
                  maxHeight
                : 0;

        setMicropileHeadSpotPx(maxHeight === 0 ? 0 : currentMicropileSpot);
        setMicropileFreeSpace(
            maxHeight === 0
                ? 0
                : micropileHeadSpot < 0
                ? Math.abs((micropileHeadSpot * offsetHeight) / maxHeight)
                : 0,
        );
    }, [
        profileParameters.zwg.value,
        micropileHeadSpot,
        offsetHeight,
        maxHeight,
        freeSpace,
        minSpotVal,
    ]);

    useDeepCompareEffect(() => {
        if (soilProfileRef.current) {
            const isMaxHeightCorrect = maxHeight === 0 || !isNumber(maxHeight);
            const zwg = profileParameters.zwg.value;
            const freeSpaceHeight = roundNumber(
                (offsetHeight * minSpotVal) / maxHeight,
                1,
            );

            setFreeSpace(freeSpaceHeight);

            const currentZwg =
                zwg >= 0
                    ? (offsetHeight * (zwg + minSpotVal)) / maxHeight
                    : zwg > micropileHeadSpot
                    ? (offsetHeight * (minSpotVal - Math.abs(zwg))) / maxHeight
                    : 0;

            setZwgHeight(isMaxHeightCorrect ? '' : currentZwg);

            setSoilLayer(
                height.map((ele, i, arr) => {
                    const soilLayerHeight =
                        isNumber(maxHeight) &&
                        maxHeight !== 0 &&
                        isNumber(offsetHeight)
                            ? offsetHeight *
                              ((i === 0 ? ele : ele - arr[i - 1]) / maxHeight)
                            : 0;

                    return (
                        <SoilLayer
                            key={i}
                            backgroundColor={setSoilColor({
                                id: density[i],
                                il: plasticity[i],
                            })}
                            name={name[i]}
                            height={roundNumber(soilLayerHeight, 2)}
                            groundLevel={profileParameters.groundLevel.value}
                            layerHeight={ele}
                            density={density[i]}
                            friction={friction[i]}
                            plasticity={plasticity[i]}
                            qccpt={roundNumber(qccpt[i], 2)}
                            cucpt={roundNumber(cucpt[i], 2)}
                        />
                    );
                }),
            );
        }
    }, [
        profileParameters.groundLevel.value,
        profileParameters.zwg.value,
        micropileHeadSpot,
        plasticity,
        friction,
        height,
        density,
        qccpt,
        cucpt,
        name,
        offsetHeight,
        maxHeight,
        minSpotVal,
    ]);

    useEffect(() => {
        const maxProfileHeight =
            micropileHeadSpot >= 0
                ? ((maxHeight - (micropileHeadSpot + minSpotVal)) *
                      offsetHeight) /
                  maxHeight
                : ((maxHeight - (minSpotVal - Math.abs(micropileHeadSpot))) *
                      offsetHeight) /
                  maxHeight;
        const maxProfileWidth = offsetWidth / 2;
        const maxAngle =
            (Math.atan(maxProfileWidth / maxProfileHeight) * 180) / Math.PI;

        const radianAngle = (Math.abs(micropileAngle) * Math.PI) / 180;

        setMicropileLength(
            Math.abs(micropileAngle) >= maxAngle
                ? maxProfileWidth / Math.sin(radianAngle)
                : maxProfileHeight,
        );
    }, [
        micropileAngle,
        offsetHeight,
        maxHeight,
        offsetWidth,
        micropileHeadSpot,
        freeSpace,
        minSpotVal,
    ]);

    useEffect(() => {
        if (micropileRef.current) {
            setMicropileOffset({
                width: micropileRef.current.offsetWidth,
                height: micropileRef.current.offsetHeight / 2,
            });
        }
    }, [micropileAngle, maxHeight, isPileVisible]);

    return (
        <Fragment>
            <SectionTitle title="Profil gruntowy" />

            {height.length > 0 ? (
                <Fragment>
                    <div className={styles.groundLevel}>
                        <span
                            className={styles.spot}
                            style={{ top: `${freeSpace}px` }}>
                            0.00{' '}
                            {`${
                                isNumber(profileParameters.groundLevel.value)
                                    ? `(${profileParameters.groundLevel.value.toFixed(
                                          2,
                                      )} m.n.p.m)`
                                    : ''
                            }`}
                        </span>
                    </div>

                    <div className={styles.wrapper} ref={soilProfileRef}>
                        {isPileVisible && (
                            <div
                                ref={micropileRef}
                                className={styles.pileWrapper}
                                style={{
                                    top: `${
                                        micropileHeadSpotPx -
                                        micropileOffset.height
                                    }px`,
                                    transform: `rotate(${
                                        -micropileAngle || 0
                                    }deg)`,
                                    left: `${offsetWidth / 2}px`,
                                }}>
                                <div
                                    className={styles.microHead}
                                    style={{
                                        left: `${-micropileOffset.width / 2}px`,
                                        top: `-${micropileOffset.height}px`,
                                    }}>
                                    <span
                                        className={styles.pileHeadSpot}
                                        style={{
                                            transform: `rotate(${
                                                micropileAngle || 0
                                            }deg)`,
                                        }}>
                                        {isNumber(micropileHeadSpot)
                                            ? `${roundNumber(
                                                  -micropileHeadSpot,
                                                  2,
                                              ).toFixed(2)} ${
                                                  isNumber(
                                                      profileParameters
                                                          .groundLevel.value,
                                                  )
                                                      ? `(${roundNumber(
                                                            profileParameters
                                                                .groundLevel
                                                                .value -
                                                                micropileHeadSpot,
                                                            2,
                                                        ).toFixed(2)})`
                                                      : ''
                                              }`
                                            : ''}
                                    </span>
                                    <div className={styles.topHead}></div>
                                    <div className={styles.bottomHead}></div>
                                </div>
                                <div
                                    className={styles.pile}
                                    style={{
                                        height: `${
                                            0.9 *
                                            (micropileLength -
                                                micropileFreeSpace)
                                        }px`,
                                        top: `${micropileFreeSpace + 13}px`,
                                    }}>
                                    <div
                                        className={styles.steelRod}
                                        style={{
                                            top: `${
                                                -40 - micropileFreeSpace
                                            }px`,
                                            height: `calc(100% + ${
                                                40 + micropileFreeSpace
                                            }px)`,
                                        }}>
                                        <div className={styles.rod}></div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isNumber(profileParameters.zwg.value) &&
                            isNumber(offsetHeight) &&
                            offsetHeight > zwgHeight && (
                                <div
                                    className={styles.zwg}
                                    style={{ top: `${zwgHeight}px` }}>
                                    <div className={styles.zwgSpot}></div>
                                    <span className={styles.zwgLevel}>
                                        {roundNumber(
                                            -profileParameters.zwg.value,
                                            2,
                                        ).toFixed(2)}{' '}
                                        {`${
                                            isNumber(
                                                profileParameters.groundLevel
                                                    .value,
                                            )
                                                ? `(${roundNumber(
                                                      profileParameters
                                                          .groundLevel.value -
                                                          profileParameters.zwg
                                                              .value,
                                                      2,
                                                  ).toFixed(2)})`
                                                : ''
                                        }`}
                                    </span>
                                </div>
                            )}

                        <div className={styles.layersWrapper}>
                            {
                                freeSpace > 0 &&
                                <div className={styles.freeSpace} style={{ height: `${freeSpace}px` }}></div>
                            }                            
                            {[...soilLayer]}
                        </div>
                    </div>
                </Fragment>
            ) : (
                <div className={styles.soilsProps}>
                    <FontAwesomeIcon icon={faBan} />
                    <p className={styles.p}>Uzupełnij parametry gruntu</p>
                </div>
            )}
        </Fragment>
    );
};

export default React.memo(
    SoilProfile,
    (prev, next) => stringify(prev) === stringify(next),
);
