import { Fragment, useState } from 'react';
import { Table } from 'react-bootstrap';
import { isNumber } from '../../../../../utils';
import round from '../../../../../utils/roundNumber';
import styles from './index.module.css';
import useDeepCompareEffect from 'use-deep-compare-effect';

const TechnologyFactorsDetails = ({ currentLength, pileHeadSpot, parameters = [] }) => {
    const [a1, setA1] = useState(false);
    const [a2, setA2] = useState(false);

    useDeepCompareEffect(() => {
        setA1(parameters.some((ele) => ele.a1));
        setA2(parameters.some((ele) => ele.a2));
    }, [parameters]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.margins}>
                <h2>Współczynniki technologiczne i bezpieczeństwa</h2>
            </div>
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th>
                            Z<br></br>[m.p.p.t.]
                        </th>
                        <th>
                            Nazwa<br></br>[-]
                        </th>
                        <th>
                            H<sub>z</sub>
                            <br></br>[m.p.p.t.]
                        </th>
                        <th>
                            q<sup>(r)</sup>
                            <br></br>[kPa]
                        </th>
                        <th>
                            t<sup>(r)</sup>
                            <br></br>[kPa]
                        </th>
                        <th>
                            h<sub>ci</sub>
                            <br></br>[m]
                        </th>
                        <th>
                            t<sub>ci</sub>
                            <br></br>[m]
                        </th>
                        <th>
                            &#947;<sub>m</sub>
                            <br></br>[-]
                        </th>
                        {a1 && a2 && (
                            <Fragment>
                                <th>
                                    a<sub>1</sub>
                                    <br></br>[-]
                                </th>
                                <th>
                                    a<sub>2</sub>
                                    <br></br>[-]
                                </th>
                            </Fragment>
                        )}
                        <th>
                            s<sub>p</sub>
                            <br></br>[-]
                        </th>
                        <th>
                            s<sub>s</sub>
                            <br></br>[-]
                        </th>
                        <th>
                            s<sub>w</sub>
                            <br></br>[-]
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {parameters.map((element, index) => {
                        return (
                            <tr key={index} style={{ backgroundColor: round(pileHeadSpot + currentLength,2) === round(element.z,2) ? '#ddd' : 'transparent' }}>
                                <td>{index + 1}</td>
                                <td>{round(element.z, 2)}</td>
                                <td>{element.capacityParameters.name}</td>
                                <td>
                                    {round(element.capacityParameters.hz, 2)}
                                </td>
                                <td>{round(element.qcd, 2)}</td>
                                <td>{round(element.tcd, 2)}</td>
                                <td>
                                    {round(element.capacityParameters.hci, 2)}
                                </td>
                                <td>
                                    {round(element.capacityParameters.tci, 2)}
                                </td>
                                <td>
                                    {round(element.capacityParameters.ym, 2)}
                                </td>

                                {isNumber(element.a1) && isNumber(element.a2) && (
                                    <Fragment>
                                        <td>{round(element.a1, 2)}</td>
                                        <td>{round(element.a2, 2)}</td>
                                    </Fragment>
                                )}
                                <td>
                                    {round(element.capacityParameters.sp, 2)}
                                </td>
                                <td>
                                    {round(element.capacityParameters.ss, 2)}
                                </td>
                                <td>
                                    {round(element.capacityParameters.sw, 2)}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <div className={styles.symbols}>
                <p className={styles.underline}>Oznaczenia:</p>
                <p>
                    Z -{' '}
                    <span className={styles.value}>
                        rzędna zagłębienia pala poniżej poziomu terenu
                    </span>
                </p>
                <p>
                    H<sub>z</sub> -{' '}
                    <span className={styles.value}>
                        rzędna poziomu interpolacji oporów gruntu
                    </span>
                </p>
                <p>
                    q<sub>cd</sub> -{' '}
                    <span className={styles.value}>
                        obliczeniowy opór gruntu pod podstawą z uwzględnieniem poziomu interpolacji
                    </span>
                </p>
                <p>
                    t<sub>cd</sub> - <span className={styles.value}>obliczeniowy opór gruntu na pobocznicy z uwzględnieniem poziomu interpolacji</span>
                </p>
                <p>
                    h<sub>ci</sub> -{' '}
                    <span className={styles.value}>
                        wysokość interpolacji oporu gruntu pod podstawą
                    </span>
                </p>
                <p>
                    t<sub>ci</sub> -{' '}
                    <span className={styles.value}>
                        wysokość interpolacji oporu gruntu na pobocznicy
                    </span>
                </p>
                <p>
                    &#947;<sub>m</sub> -{' '}
                    <span className={styles.value}>
                        częściowy współczynnik bezpieczeństwa dla oporów gruntu
                    </span>
                </p>
                {a1 && a2 && (
                    <Fragment>
                        <p>
                            a<sub>1</sub> -{' '}
                            <span className={styles.value}>
                                współczynnik redukujący nośność pod podstawą dla pali rurowych otwartych
                            </span>
                        </p>
                        <p>
                            a<sub>2</sub> -{' '}
                            <span className={styles.value}>
                                współczynnik redukujący nośność na pobocznicy dla pali rurowych otwartych
                            </span>
                        </p>
                    </Fragment>
                )}
                <p>
                    s<sub>p</sub> -{' '}
                    <span className={styles.value}>
                        współczynnik technologiczny dla podstawy pala
                    </span>
                </p>
                <p>
                    s<sub>s</sub> -{' '}
                    <span className={styles.value}>
                        współczynnik technologiczny dla pobocznicy pala
                    </span>
                </p>
                <p>
                    s<sub>w</sub> -{' '}
                    <span className={styles.value}>
                        współczynnik technologiczny dla pali wyciąganych
                    </span>
                </p>
            </div>
        </div>
    );
};

export default TechnologyFactorsDetails;