import React, { useState, useMemo } from 'react';
import { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    AddNewProject,
    Button,
    InputField,
    Loading,
    Modal,
    SelectField,
} from '../../../components';
import OverwriteProject from './OverwriteProject';
import styles from './index.module.css';
import stringify from 'fast-json-stable-stringify'; 

const SaveProject = ({
    geoProfiles,
    open,
    openOverwriteProject,
    onHide,
    onChange, 
    onChangeCheckbox,
    onBlur,
    onSubmit,
    onOverwrite,
    state,
    saveLoading,
    overwriteLoading,
    onHideExistProjectModal,
    onAddNewProject,
    onAddAllUsers,
    newProjectLoading,
}) => {
    const [addProjectOpen, setAddProjectOpen] = useState({});
    const currentUserProjectsStringify = stringify(state.currentUserProjects);
    const projectsStringify = stringify(state.projects);
    const currentUserProjects = useMemo(() => {
        const currentUserProjectsParsed = JSON.parse(
            currentUserProjectsStringify,
        );

        return currentUserProjectsParsed;
    }, [currentUserProjectsStringify]);

    const soilProfiles = useMemo(() => {
        if (
            Array.isArray(currentUserProjects) &&
            currentUserProjects.length > 0
        ) {
            const currentProject =
                currentUserProjects.find(
                    (ele) => ele.name === state.mainProjectName.value,
                ) || {};
            return Array.isArray(currentProject.soilProfiles) &&
                currentProject.soilProfiles.length > 0
                ? currentProject.soilProfiles.map((ele) => ele.profileName)
                : [];
        } else {
            return [];
        }
    }, [currentUserProjects, state.mainProjectName.value]);

    const userProjects = useMemo(
        () => {
            const projectsParsed = JSON.parse(projectsStringify);
            
            return projectsParsed.filter((ele) => ele.email !== state.userEmail)
        },
        [projectsStringify, state.userEmail],
    );
    const onOpenAddProjectModal = (e) =>
        setAddProjectOpen((state) => ({
            ...state,
            [e.currentTarget.name]: true,
        }));
    const onHideAddProjectModal = () => setAddProjectOpen({});

    return (
        <Fragment>
            <Modal
                open={open}
                onHide={onHide}
                css={styles.modal}
                cssDialog={styles.modalDialog}
                title="Zapisz obliczenia"
                hideButton={false}>
                {Array.isArray(userProjects) && userProjects.length > 0 && (
                    <Row className={styles.row}>
                        <Col xs={6}>
                            <SelectField
                                title="Autor projektu"
                                onChange={onChange}
                                value={state.currentUser}
                                defaultOption="Moje projekty"
                                disabledDefault={false}
                                options={userProjects.map(
                                    (ele) =>
                                        `${ele.firstName} ${ele.lastName} (${ele.email})`,
                                )}
                                name="currentUser"
                            />
                        </Col>
                    </Row>
                )}
                <Row className={styles.row}>
                    <Col xs={geoProfiles ? 4 : 6}>
                        <SelectField
                            title="Projekt"
                            onBlur={onBlur}
                            onChange={onChange}
                            value={state.mainProjectName.value}
                            defaultOption="Wybierz projekt"
                            isValid={state.mainProjectName.isValid}
                            options={currentUserProjects.map((ele) => ele.name)}
                            name="mainProjectName"
                            required
                        />
                    </Col>
                    {
                        geoProfiles &&
                        <Col xs={4}>
                            <SelectField
                                title="Profil gruntowy"
                                onBlur={onBlur}
                                onChange={onChange}
                                value={state.currentProfile}
                                defaultOption="Brak profilu"
                                disabledDefault={false}
                                options={soilProfiles}
                                name="profileName"
                            />
                        </Col>                        
                    }

                    <Col xs={geoProfiles ? 4 : 6} className={styles.flex}>
                        <Button
                            css={`
                                ${styles.addProjectButton} default-button-style
                            `}
                            title={'Nowy projekt'}
                            altTitle="Nowy projekt"
                            name="addProjectOpen"
                            onClick={onOpenAddProjectModal}
                            type="submit"
                        />
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <InputField
                            onChange={onChange}
                            onBlur={onBlur}
                            value={state.projectName.value}
                            isValid={state.projectName.isValid}
                            type="text"
                            title="Nazwa obliczeń"
                            placeholder="Nazwa obliczeń"
                            name="projectName"
                            required
                        />
                    </Col>
                    <Col xs={12}>
                        <Button
                            css={`
                                ${styles.button} default-button-style
                            `}
                            title={'Zapisz obliczenia'}
                            altTitle="Zapisz obliczenia"
                            name="saveProject"
                            onClick={onSubmit}
                            type="submit"

                            disabled={saveLoading}
                        />
                    </Col>
                </Row>
                {saveLoading && (
                    <Loading
                        oppositeColors={true}
                        text="Zapisuję obliczenia..."
                    />
                )}
            </Modal>
            <AddNewProject
                open={addProjectOpen.addProjectOpen}
                onHide={onHideAddProjectModal}
                onAddAllUsers={onAddAllUsers}
                onChangeCheckbox={onChangeCheckbox}
                onChange={onChange}
                onBlur={onBlur}
                onSubmit={onAddNewProject}
                state={{
                    newMainProjectName: state.newMainProjectName,
                    subUsers: state.subUsers,
                    userAccessToProject: state.userAccessToProject,
                }}
                loading={newProjectLoading}
                inputName="newMainProjectName"
            />
            <OverwriteProject
                onHideExistProjectModal={onHideExistProjectModal}
                overwriteLoading={overwriteLoading}
                open={openOverwriteProject}
                projectName={state.value}
                onSubmit={onOverwrite}
            />
        </Fragment>
    );
};

export default SaveProject;
