import { Fragment } from 'react';
import { Table } from 'react-bootstrap';
import round from '../../../../../utils/roundNumber';
import styles from './index.module.css';
import { isNumber, isString } from '../../../../../utils';

const VerticalSummary = ({ data = [] }) => {
    return (
        <Fragment>
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th>
                            Tytuł obliczeń<br></br>[-]
                        </th>
                        <th>
                            Nazwa profilu<br></br>[-]
                        </th>
                        <th>
                            Rodzaj pala<br></br>[-]
                        </th>
                        <th>
                            Technologia<br></br>[-]
                        </th>
                        <th>
                            L<br></br>[m]
                        </th>
                        <th>
                            D<br></br>[m]
                        </th>
                        <th>
                            N<sub>p</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            N<sub>s</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            N<sub>t</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            N<sub>tgr</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            N<sub>tw</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            N<sub>twgr</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            N<sub>t,ec7</sub>
                            <br></br>[kN]
                        </th>

                        <th>
                            N<sub>gr,ec7</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            N<sub>lt</sub>
                            <br></br>[kN]
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((element, index) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    {isString(element.model.projectTitle) &&
                                    element.model.projectTitle.length > 0
                                        ? element.model.projectTitle
                                        : '-'}
                                </td>
                                <td>
                                    {isString(element.profileName)
                                        ? element.profileName
                                        : '-'}
                                </td>
                                <td>
                                    {element.results.currentCapacity.pileType}
                                </td>
                                <td>
                                    {
                                        element.results.currentCapacity
                                            .pileTechnology
                                    }
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity
                                            .currentPileLength,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity
                                            .diameterPile,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity.np,
                                        1,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity.ns,
                                        1,
                                    )}
                                </td>
                                <td className={styles.bold}>
                                    {round(
                                        element.results.currentCapacity.nt,
                                        1,
                                    )}
                                </td>

                                <td>
                                    {isNumber(
                                        element.results.currentCapacity.ntgr,
                                    )
                                        ? round(
                                              element.results.currentCapacity
                                                  .ntgr,
                                              1,
                                          )
                                        : '-'}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity.ntw,
                                        1,
                                    )}
                                </td>
                                <td>
                                    {isNumber(
                                        element.results.currentCapacity.nwgr,
                                    )
                                        ? round(
                                              element.results.currentCapacity
                                                  .nwgr,
                                              1,
                                          )
                                        : '-'}
                                </td>
                                <td className={styles.bold}>
                                    {round(
                                        element.results.currentCapacity.ntec7d,
                                        1,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity.ntec7,
                                        1,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity.ntec7lt,
                                        1,
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <p className={styles.underline}>Oznaczenia:</p>
            <p>
                L -{' '}
                <span className={styles.value}>projektowana długość pala</span>
            </p>
            <p>
                D -{' '}
                <span className={styles.value}>średnica/długość boku pala</span>
            </p>
            <p>
                N<sub>p</sub> -{' '}
                <span className={styles.value}>nośność podstawy pala</span>
            </p>
            <p>
                N<sub>s</sub> -{' '}
                <span className={styles.value}>nośność pobocznicy pala</span>
            </p>
            <p>
                N<sub>t</sub> -{' '}
                <span className={styles.value}>
                    obliczeniowa nośność pala pojedynczego na wciskanie
                </span>
            </p>
            <p>
                N<sub>tgr</sub> -{' '}
                <span className={styles.value}>
                    obliczeniowa nośność pala w grupie na wciskanie
                </span>
            </p>
            <p>
                N<sub>tw</sub> -{' '}
                <span className={styles.value}>
                    obliczeniowa nośność pala pojedynczego na wyciąganie
                </span>
            </p>
            <p>
                N<sub>tgr</sub> -{' '}
                <span className={styles.value}>
                    obliczeniowa nośność pala w grupie na wyciąganie
                </span>
            </p>
            <p>
                N<sub>t,ec7</sub> -{' '}
                <span className={styles.value}>
                    obliczeniowa nośność pala pojedynczego na wciskanie wg EC7
                </span>
            </p>
            <p>
                N<sub>gr,ec7</sub> -{' '}
                <span className={styles.value}>
                    nośność graniczna pala pojedynczego na wciskanie wg EC7
                </span>
            </p>
            <p>
                N<sub>lt</sub> -{' '}
                <span className={styles.value}>
                    siła do wykonania próbnego obciążenia na podstawie wyników
                    wg EC7
                </span>
            </p>
        </Fragment>
    );
};

export default VerticalSummary;
