import React from 'react';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';

const UL = styled.ul`
    padding-left: 0;
    padding-top: 5px;
    margin-bottom: 0;
    color: red;
`;
const LI = styled.li`
    list-style: none;
`;
const InputPassword = styled.input`
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    border-color: ${({ valid }) => valid === false && 'red'};

    &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
`;
const Wrapper = styled.div`
    margin: 15px 0
`
const Password = ({ onChange, password: { value, isValid }, label = '' }) => {
    return (
        <Wrapper>
            <Form.Group controlId="password">
                {label && <Form.Label>{label}</Form.Label>}
                <InputPassword
                    valid={isValid}
                    name="password"
                    type="password"
                    value={value}
                    placeholder="Hasło"
                    onChange={onChange}
                    required></InputPassword>
                {isValid === false && (
                    <UL>
                        <LI>Hasło jest wymagane!</LI>
                    </UL>
                )}
            </Form.Group>
        </Wrapper>
    );
};

export default Password;
