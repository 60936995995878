import { useState } from 'react';
import { plans, roles, subscriptionTypes } from '../../../config/ProjectConfig';
import { AdminFetch } from '../../../routes';
import { groupByProperty, isNumber, planCost } from '../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const subscription = {
    'Subskrypcja miesięczna': 1, 
    'Subskrypcja roczna': 2,
};

const RenewSubscription = ({
    fetch,
    setAlert,
    setLoading, 
    users,
    setUserListStateChanged,

    updateInvoiceState,
    invoiceState,
    invoiceType,
}) => {
    const [usersState, setUsersState] = useState([]);
    const [renderUsersState, setRenderUsersState] = useState([]);

    const [usersCost, setUsersCost] = useState(0);

    useDeepCompareEffect(() => {
        setUsersState(
            users
                .filter((ele) => ele.role !== roles[2])
                .map((ele) => {
                    const {
                        firstName,
                        lastName,
                        parentId,
                        email,
                        role,
                        id,
                        subscription,
                    } = ele;

                    return {
                        firstName,
                        lastName,
                        id,
                        role,
                        email,
                        parentId,
                        subscription: {
                            freeAccount: subscription.freeAccount,
                            endDate: subscription.endDate,
                            plan: subscription.plan,
                            price: planCost({
                                plan: subscriptionTypes[subscription.plan],
                                role: ele.role,
                                plans: plans,
                                freeAccount: subscription.freeAccount,
                            }),
                        },
                    };
                })
                .reduce((acc, ele, _, arr) => {
                    if (ele.role === roles[1]) {
                        const subusers = arr.filter(
                            (element) => element.parentId === ele.id,
                        );

                        return [
                            ...acc,
                            {
                                ...ele,
                                subusers,
                            },
                        ];
                    } else {
                        const parentUser = arr.find(
                            (element) => element.id === ele.parentId,
                        );

                        if (parentUser) {
                            return acc;
                        } else {
                            return [
                                ...acc,
                                {
                                    ...ele,
                                },
                            ];
                        }
                    }
                }, []),
        );
    }, [users]);

    useDeepCompareEffect(() => {
        setUsersCost( renderUsersState.reduce((acc, ele) => acc + ele[0].totalPrice, 0))
    }, [renderUsersState]);

    useDeepCompareEffect(() => {
        if (usersState.length > 0) {
            setRenderUsersState(
                groupByProperty(
                    usersState.reduce((acc, ele) => {
                        let subUsersCost = 0;
                        if (
                            Array.isArray(ele.subusers) &&
                            ele.subusers.length > 0
                        ) {
                            subUsersCost = ele.subusers.reduce(
                                (acc, element) => {
                                    if (isNumber(element.subscription.price)) {
                                        return acc + element.subscription.price;
                                    } else {
                                        return acc + 0;
                                    }
                                },
                                0,
                            );
                        }
                        if (isNumber(ele.subscription.price)) {
                            return [
                                ...acc,
                                {
                                    ...ele,
                                    totalPrice:
                                        subUsersCost + ele.subscription.price,
                                },
                            ];
                        } else {
                            return [
                                ...acc,
                                {
                                    ...ele,
                                    totalPrice: subUsersCost,
                                },
                            ];
                        }
                    }, []),
                    'parentId',
                    'string',
                ),
            );
        } else {
            setRenderUsersState([]);
        }
    }, [usersState]);

    const onChange = (e, id) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if (input) {
            const { value, name, checked } = input;
            setUsersState((state) =>
                state.map((ele) => {
                    if (ele.id !== id) {
                        if (ele.subusers && Array.isArray(ele.subusers)) {
                            const subuserId = ele.subusers.find(
                                (ele) => ele.id === id,
                            );

                            if (subuserId) {
                                if(name === 'type'){
                                    return {
                                        ...ele,
                                        subusers: ele.subusers.map((element) => {
                                            if (element.id !== id) {
                                                return element;
                                            } else {
                                                return {
                                                    ...element,
                                                    subscription: {
                                                        ...element.subscription,
                                                        plan: subscription[value],
                                                        price: planCost({
                                                            plan: value,
                                                            role: element.role,
                                                            plans: plans,
                                                            freeAccount: element.subscription.freeAccount,
                                                        }),
                                                    },
                                                };
                                            }
                                        }),
                                    };
                                } else if(name === 'freeAccount'){
                                    return {
                                        ...ele,
                                        subusers: ele.subusers.map((element) => {
                                            if (element.id !== id) {
                                                return element;
                                            } else {
                                                return {
                                                    ...element,
                                                    subscription: {
                                                        ...element.subscription,
                                                        freeAccount: checked,
                                                        price: planCost({
                                                            plan: element.subscription.plan,
                                                            role: element.role,
                                                            plans: plans,
                                                            freeAccount: checked,
                                                        }),
                                                    },
                                                };
                                            }
                                        }),
                                    };
                                } else {
                                    return {
                                        ...ele,
                                        subusers: ele.subusers.map((element) => {
                                            return element;
                                        }),
                                    };
                                }
                            } else {
                                return ele;
                            }
                        } else {
                            return ele;
                        }
                    } else {
                        if(name === 'type'){
                            return {
                                ...ele,
                                subscription: {
                                    ...ele.subscription,
                                    plan: subscription[value],
                                    price: planCost({
                                        plan: value,
                                        role: ele.role,
                                        plans: plans,
                                        freeAccount: ele.subscription.freeAccount,
                                    }),
                                },
                            };
                        } else if(name === 'freeAccount'){
                            return {
                                ...ele,
                                subscription: {
                                    ...ele.subscription,
                                    freeAccount: checked,
                                    price: planCost({
                                        plan: ele.subscription.plan,
                                        role: ele.role,
                                        plans: plans,
                                        freeAccount: checked,
                                    }),
                                },
                            };
                        } else {
                            return {
                                ...ele,
                            };
                        }
                    }
                }),
            );
        }
    };

    const onSubmit = () => {
        const { companyName, nip, firstName, lastName, ...rest } = invoiceState;
        const validFormObject = invoiceType === 1
            ? { companyName, nip, ...rest }
            : invoiceType === 2
            ? { firstName, lastName, ...rest }
            : {};

        const isFormValidInvoice = usersCost > 0 ? Object.values(validFormObject).every((ele) => ele.isValid) : true;

        if (usersState.length > 0 && isFormValidInvoice) {
            setLoading({ name: 'renewSubscription', loading: true });
            AdminFetch({
                url: fetch.renewSubscription.url,
                method: fetch.renewSubscription.method,
                body: usersCost > 0
                    ? 
                    {
                        users: renderUsersState,
                        usersCost,
                        invoiceType,
                        ...Object.entries(validFormObject).reduce(
                            (acc, [key, value]) => ({ ...acc, [key]: value.value }),
                            {},
                        ),
                    }
                    :
                    {
                        users: renderUsersState,
                        usersCost,
                    }
                    
            })
            .then((response) => {
                setLoading({ name: 'renewSubscription', loading: false });

                if (response.success) {
                    setAlert({
                        error: false,
                        message: 'Subskrypcje zostały zaktualizowane',
                        hideTime: 2000,
                    });
                    setUserListStateChanged(true);
                } else {
                    setAlert({
                        error: true,
                        message: 'Błąd podczas wykonywania płatności',
                    });
                }
            })
            .catch(() => {
                setLoading({ name: 'renewSubscription', loading: false });
                setAlert({
                    error: true,
                    message: 'Błąd podczas wykonywania płatności',
                });
            });
        } else {
            setAlert({
                error: true,
                message: 'Niepoprawne dane w formularzu',
            });
            if(usersCost > 0){
                updateInvoiceState((state) => { 
                    return {
                        ...state,
                        ...Object.entries(validFormObject).reduce(
                            (acc, [key, value]) => ({
                                ...acc,
                                [key]: {
                                    value: value.value,
                                    isValid: !!value.isValid,
                                },
                            }),
                            {},
                        ),
                    };
                });
            }
        }
    };

    return {
        renewSubscription: {
            onChange: onChange,
            onSubmit: onSubmit,
            state: {
                usersState: renderUsersState,
                usersCost,
                plans,
            },
        },
    };
};

export default RenewSubscription;
