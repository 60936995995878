import { Fragment, useContext, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { Loading, MainAlertAndLoading } from '../../../components';
import { usersContext } from '../../../context/AdminUsersList';
import UsersTable from './UsersTable';
import styles from './index.module.css';

const UsersList = () => {
    const {
        globalAlerts,
        globalLoading,
        usersList,
        renewSubscription,
        removeUsers,
        addNewUsers,
        invoiceForm
    } = useContext(usersContext);

    useEffect(() => {
        document.title = `GeoInCloud | Lista użytkowników`;

        return () => {
            document.title = '';
        };
    }, []);

    return (
        <Fragment>
            <Row className={styles.row}>
                <UsersTable
                    invoiceType={invoiceForm.state.invoiceType}
                    invoiceState={invoiceForm.state.invoiceState}
                    onChangeInvoiceType={invoiceForm.onChangeInvoiceType}
                    onChangeInvoice={invoiceForm.onChangeInvoiceState}
                    onBlurInvoice={invoiceForm.onBlurInvoiceState}


                    onChangeIdsCheckbox={usersList.onChangeIdsCheckbox}
                    onChangeBlockAccount={usersList.onChangeBlockAccount}
                    onChangeDeleteCheckbox={usersList.onChangeDeleteCheckbox}
                    onChangeRenewSubscriptionType={renewSubscription.onChange}
                    onSubmitRenewSubscription={renewSubscription.onSubmit}
                    onSubmitBlockUserAccount={
                        usersList.onSubmitBlockUserAccount
                    }
                    dataToPaginate={usersList.state.usersListState}
                    state={{
                        deleteCheckbox: usersList.state.deleteCheckbox,
                        selectedUsers: usersList.state.selectedUsers,
                        arrayOfIds: usersList.state.arrayOfIds,
                    }}
                    renewSubscriptionState={{
                        usersState: renewSubscription.state.usersState,
                        totalPrice: renewSubscription.state.totalPrice,
                        usersCost: renewSubscription.state.usersCost,
                    }}
                    removeUsers={removeUsers}
                    addNewUsers={addNewUsers}
                />
            </Row>
            <MainAlertAndLoading
                alerts={globalAlerts.state.alerts}
                updateAlerts={globalAlerts.updateState}
            />

            {globalLoading.state.addNewUsers && (
                <div className={styles.loadingWrapper}>
                    <Loading text="Dodawanie nowych użytkowników..." />
                </div>
            )}
            {globalLoading.state.renewSubscription && (
                <div className={styles.loadingWrapper}>
                    <Loading text="Aktualizacja subskrypcji..." />
                </div>
            )}
            {globalLoading.state.blockAccount && (
                <div className={styles.loadingWrapper}>
                    <Loading text="Aktualizacja użytkowników..." />
                </div>
            )}
            {globalLoading.state.removeUsers && (
                <div className={styles.loadingWrapper}>
                    <Loading text="Usuwanie użytkowników..." />
                </div>
            )}
        </Fragment>
    );
};

export default UsersList;
