import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'react-bootstrap';
import { authContext } from '../../context/Authentication';

const ModalBootstrap = styled(Modal)`
    & .modal-dialog {
        padding-top: 200px;
        max-width: 450px;
        margin-top: 0;
        color: #333;

        & .modal-content {
            background-color: #f9f9f9;

            & .modal-header {
                border-bottom: 1px solid #666;

                & .close {
                    color: #333;
                    font-size: 30px;
                    padding: 5px 15px;
                }
            }
        }
    }
`;
const ModalWrapper = styled.div`
    & .modal-title {
        color: #333;
        font-weight: 700;
        font-size: 20px;
    }
    & .modal-footer {
        border-top: none;
        display: block;
    }
    & button {
        float: right;
        box-shadow: none !important;
    }
    & .modal-body {
        padding: 20px 15px 20px 15px;
    }
`;
const FloatWrapper = styled.div`
    overflow: hidden;
    margin-right: 0;

    & button {
        color: #000;
        border-radius: 5px;
        transition: all 0.5s ease;
        background-color: #f9f9f9;
        border-color: #666;
        margin: 3px;

        &.login {
            background-color: #1b2431;
            color: #ddd;
            padding-left: 25px;
            padding-right: 25px;

            &:hover,
            &:active,
            &:focus {
                background-color: #1b2431 !important;
                color: #fff !important;
            }
        }

        &:hover,
        &:active,
        &:focus {
            background-color: #f0f0f0 !important;
            border-color: #666 !important;
            color: #000 !important;
        }
    }
`;
const SPAN = styled.span`
    font-size: 15px;
    text-align: center;
    color: #333;
    display: block;
`;

const RenewSession = () => {
    const { renewToken, nextSession, logOut } = useContext(authContext);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        setOpenModal(renewToken);
    }, [renewToken]);

    const newSession = () => {
        nextSession();
        setOpenModal(false);
    };
    const logOutFromCurrentSession = () => {
        setOpenModal(false);
        logOut();
    };
    const onHide = () => setOpenModal(false);

    return (
        <ModalBootstrap show={openModal} onHide={onHide}>
            <ModalWrapper>
                <Modal.Header closeButton>
                    <Modal.Title>Twoja sesja wygasa!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SPAN>
                        Twoja sesja dobiega końca, zaraz zostaniesz wylogowany.
                        Potrzebujesz więcej czasu?
                    </SPAN>
                </Modal.Body>
                <Modal.Footer>
                    <FloatWrapper>
                        <Button className={'login'} onClick={newSession}>
                            Pozostań zalogowany
                        </Button>
                        <Button onClick={logOutFromCurrentSession}>
                            Wyloguj
                        </Button>
                    </FloatWrapper>
                </Modal.Footer>
            </ModalWrapper>
        </ModalBootstrap>
    );
};

export default RenewSession;
