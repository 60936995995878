import React, { Fragment, useState } from 'react';
import { withPagination } from '../../../../../hoc';
import {
    ItemsPerPage,
    Pagination,
    SectionTitle,
    Table,
} from '../../../../../components';
import styles from './index.module.css';
import round from '../../../../../utils/roundNumber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import CorrectDepth from '../CorrectDepth';
import useDeepCompareEffect from 'use-deep-compare-effect';

const Ec7SingleCapacity = ({
    dataToPaginate,
    allCollection = [],
    pagination,
    paginationHelpers,
}) => {
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [csvData, setCsvData] = useState([]);

    useDeepCompareEffect(() => {
        setCsvData(
            allCollection
                .map(
                    ({
                        z,
                        d,
                        ntec7,
                        ntec7d,
                        ntec7lt,
                        capacityParameters: { yrd, ytr, yt },
                    }) => ({ z, d, ntec7, ntec7d, ntec7lt, yrd, ytr, yt }),
                )
                .map((ele) =>
                    Object.fromEntries(
                        Object.entries(ele).map(([key, value = '']) => [
                            [key],
                            value.toString().replace('.', ','),
                        ]),
                    ),
                ),
        );

        setCsvHeaders([
            {
                label: 'Z [m.p.p.t.]',
                key: 'z',
            },
            {
                label: 'd [m]',
                key: 'd',
            },
            {
                label: 'yRd [-]',
                key: 'yrd',
            },
            {
                label: 'ytr [-]',
                key: 'ytr',
            },
            {
                label: 'yt [-]',
                key: 'yt',
            },
            {
                label: 'Nt,ec7 [kN]',
                key: 'ntec7d',
            },
            {
                label: 'Ngr,ec7 [kN]',
                key: 'ntec7',
            },
            {
                label: 'Nlt [kN]',
                key: 'ntec7lt',
            },
        ]);
    }, [allCollection]);

    return (
        <Fragment>
            <SectionTitle 
                title={
                    'Nośność pionowa pala pojedynczego z uwzględnieniem Eurokodu 7'
                }
            />
            <ItemsPerPage
                onDisplayItemsNumber={paginationHelpers.displayItemsNumber}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                display={[15, 30, paginationHelpers.allItemsLength]}
            />
            <Table
                csvData={csvData}
                csvFilename={'EC7PileCapacity'}
                csvHeaders={csvHeaders}
                >
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th title="Rzędna">
                            z<br></br>[m.p.p.t.]
                        </th>
                        <th title="Współczynnik modelu">
                            &#947;<sub>Rd</sub>
                            <br></br>[m]
                        </th>
                        <th title="Współczynnik sztywności oczepu">
                            &#947;<sub>tr</sub>
                            <br></br>[m]
                        </th>
                        <th title="Współczynnik bezpieczeństwa">
                            &#947;<sub>t</sub>
                            <br></br>[m]
                        </th>
                        <th title="Nośność obliczeniowa wg EC7">
                            N<sub>t,ec7</sub>
                            <br></br>[kN]
                        </th>
                        <th title="Nośność graniczna wg EC7">
                            N<sub>gr,ec7</sub>
                            <br></br>[kN]
                        </th>
                        <th title="Siła do próbnego obciążenia pala">
                            N<sub>lt</sub>
                            <br></br>[kN]
                        </th>
                        <th title="Poprawność zagłębienia pala w gruncie"></th>
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    {dataToPaginate.map((element, index) => {
                        const {
                            correctBaseDepth,
                            correctMultiLayerDepth,
                            isCohesive,
                            isSoftLayer,
                        } = element.correctDepth;
                        const correctLength =
                            correctBaseDepth >= 1 &&
                            correctMultiLayerDepth &&
                            !isCohesive &&
                            !isSoftLayer;
                        const warningDepth =
                            correctBaseDepth > 0 &&
                            correctBaseDepth < 1 &&
                            correctMultiLayerDepth &&
                            !isCohesive &&
                            !isSoftLayer;
                        return (
                            <tr
                                className={`${styles.tr} ${
                                    correctLength
                                        ? styles.validDepth
                                        : warningDepth
                                        ? styles.warningDepth
                                        : styles.wrongDepth
                                }`}
                                key={index}>
                                <td>
                                    {paginationHelpers.countingItemsPerPage +
                                        index +
                                        1}
                                </td>
                                <td>{round(element.z, 2)}</td>
                                <td>
                                    {round(element.capacityParameters.yrd, 3)}
                                </td>
                                <td>
                                    {round(element.capacityParameters.ytr, 3)}
                                </td>
                                <td>
                                    {round(element.capacityParameters.yt, 2)}
                                </td>
                                <td>{round(element.ntec7d, 2)}</td>
                                <td>{round(element.ntec7, 2)}</td>
                                <td>{round(element.ntec7lt, 2)}</td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={
                                            correctLength
                                                ? faCheckCircle
                                                : warningDepth
                                                ? faCheckCircle
                                                : faTimesCircle
                                        }
                                    />
                                    <div className={styles.info}>
                                        <CorrectDepth
                                            className={styles.depthColor}
                                            correctDepth={element.correctDepth}
                                        />
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <Pagination
                currentPage={pagination.currentPage}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                nextPage={paginationHelpers.nextPage}
            />
        </Fragment>
    );
};

export default withPagination({
    component: Ec7SingleCapacity,
    returnAllCollection: true,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 25,
    },
});
