import { Fragment, useContext, useState } from 'react';
import { cptpileContext } from '../../context/CptPile';
import styles from './index.module.css';
import ProjectTitle from './model/ProjectTitle';
import { Button, MainAlertAndLoading } from '../../components';

import { Col, Row } from 'react-bootstrap';
import SoilTable from './model/SoilTable';
import CptChart from './model/CptChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { isNumber } from '../../utils';
import Calculations from './model/Calculations';
import CuChart from './model/CuChart';
import SaftyFactors from './model/SaftyFactors';
import SubmitCalculations from '../SubmitCalculations';

const CptPile = () => {
    const {
        globalAlerts = {},
        globalLoading = {},
        projectTitle={},
        soilTable={},
        calculationState={},
        saveSoilProfile={},
        editSoilProfile={},
        defaultSoilLayer={},
        saftyFactors={},
        submitCalculation={}
    } = useContext(cptpileContext);

    const [chartType, setChartType] = useState(0);

    const onChangeChartType = (type) => setChartType(type);
    return (
        <Fragment>
            <section className={styles.section}>
                <Row>
                    <ProjectTitle {...projectTitle} />
                </Row>
            </section>
            <section className={styles.section}>
                <Row>
                    <SoilTable 
                        {...soilTable} 
                        dataToPaginate={soilTable.state}
                        defaultSoilLayer={defaultSoilLayer}
                        saveSoilProfile={saveSoilProfile}
                        editSoilProfile={editSoilProfile}
                        globalLoading={globalLoading.state}
                    />
                </Row>
            </section>
            <section>
                <Row>
                    <Col xs={12} sm={6}>
                        <Button
                            css={`project-navigation ${chartType === 0 ? 'active' : ''}`}
                            onClick={() => onChangeChartType(0)}
                            title={'Opór stożka qc'}
                        />
                        <Button
                            css={`project-navigation ${chartType === 1 ? 'active' : ''}`}
                            onClick={() => onChangeChartType(1)}
                            title={'Wytrzymałość na ściananie cu'}
                        />
                        {
                            chartType === 0 &&
                            <Fragment>
                                {
                                    (Array.isArray(soilTable.state) && soilTable.state.length > 0 && soilTable.state.some(ele => {
                                        return isNumber(ele.qc.value) && isNumber(ele.z.value)
                                    }))
                                    ?
                                        <CptChart
                                            chartTitle="Wykres sondowania statycznego CPT"
                                            yLabel="Głębokość Z [m.p.p.t]"
                                            xLabel="Opór stożka sondy CPT - qc [MPa]"
                                            dataToChart={soilTable.state.map(state => {
                                                return {
                                                    qc: state.qc.value,
                                                    z: state.z.value,
                                                    soil: state.soilType.value,
                                                    zSoil: state.zSoil.value
                                                }
                                            })}
                                        />
                                    :
                                    <div className={styles.banWrapper}>
                                        <FontAwesomeIcon icon={faBan} />
                                        <p>Uzupełnij parametry gruntu</p>
                                    </div>                                    
                                }
                            </Fragment>
                        }
                        {
                            chartType === 1 &&
                            <Fragment>
                                {
                                    (Array.isArray(soilTable.state) && soilTable.state.length > 0 && soilTable.state.some(ele => {
                                        return isNumber(ele.cu.value) && isNumber(ele.z.value)
                                    }))
                                    ?
                                        <CuChart
                                            chartTitle="Wykres sondowania statycznego CPT"
                                            yLabel="Głębokość Z [m.p.p.t]"
                                            xLabel="Wytrzymałość gruntu na ścinanie - cu [kPa]"
                                            dataToChart={soilTable.state.map(state => {
                                                return {
                                                    qc: state.qc.value,
                                                    cu: state.cu.value,
                                                    z: state.z.value,
                                                    soil: state.soilType.value,
                                                    zSoil: state.zSoil.value
                                                }
                                            })}
                                        />
                                    :
                                    <div className={styles.banWrapper}>
                                        <FontAwesomeIcon icon={faBan} />
                                        <p>Uzupełnij parametry gruntu</p>
                                    </div>
                                }
                            </Fragment>
                        }
                    </Col>
                    <Col xs={12} sm={6}>
                        <Calculations
                            layers={soilTable.state.map(ele => ele.name)}
                            isChartData={soilTable.state.some(ele => ((ele.cd && ele.cd.value === false) || !ele.cd))}
                            updateSoilTable={soilTable.updateState}
                            { ...calculationState }
                        />
                        <SaftyFactors
                            {...saftyFactors}
                        />                        
                    </Col>                
                </Row>
            </section>
            <section>
                <Row>
                    <Col xs={12}>
                        <SubmitCalculations {...submitCalculation} />
                    </Col>
                </Row>
            </section>
            <MainAlertAndLoading
                alerts={globalAlerts.state.alerts}
                updateAlerts={globalAlerts.updateState}
                loadings={globalLoading.state}
            />
        </Fragment>
    );
};

export default CptPile;
