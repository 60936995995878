import { AuthFetch } from "../../../routes";

const SubmitCalculationHelpers = ({ 
    calculations,
    results,
    setAlert,  
    setLoading 
}) => {

    const onSubmitCalculation = () => {

        const calculationToSend = Object.fromEntries(Object.entries(calculations.state)
            .filter((ele,_,arr) => {
                const walingType_1 = arr.find(ele => ele[0] === 'walingType_1')[1].value;
                const walingType_2 = arr.find(ele => ele[0] === 'walingType_2')[1].value;
                const walingType_3 = arr.find(ele => ele[0] === 'walingType_3')[1].value;
                const checkElements = arr.find(ele => ele[0] === 'checkElements')[1].value;

                if(checkElements){
                    if(walingType_1){
                        return !['walingType_1', 'walingType_2', 'walingType_3', 'walingType_4', 'calcElements'].includes(ele[0])
                    } else if(walingType_2){
                        return !['walingType_1', 'walingType_2', 'walingType_3', 'walingType_4', 'calcElements', 'ceo', 's', 'steelClass', 'ceoFy'].includes(ele[0])
                    } else if(walingType_3){
                        return !['walingType_1', 'walingType_2', 'walingType_3', 'walingType_4', 'calcElements', 'ceo', 's', 'steelClass', 'ceoFy', 'bb', 'med_sheet'].includes(ele[0])
                    } else {
                        return !['walingType_1', 'walingType_2', 'walingType_3', 'walingType_4', 'calcElements', 'bb', 'med_sheet'].includes(ele[0])
                    }
                } else {
                    if(walingType_1){
                        return !['walingType_1', 'walingType_2', 'walingType_3', 'walingType_4', 'checkElements', 'ceo', 'width', 'height', 'thickness', 's'].includes(ele[0])
                    } else if(walingType_2) {
                        return !['walingType_1', 'walingType_2', 'walingType_3', 'walingType_4', 'checkElements', 'ceo', 'width', 'height', 'thickness', 's', 'steelClass', 'ceoFy'].includes(ele[0])
                    } else if(walingType_3) {
                        return !['walingType_1', 'walingType_2', 'walingType_3', 'walingType_4', 'checkElements', 'ceo', 'width', 'height', 'thickness', 's', 'steelClass', 'ceoFy', 'bb', 'med_sheet'].includes(ele[0])
                    } else {
                        return !['walingType_1', 'walingType_2', 'walingType_3', 'walingType_4', 'checkElements', 'ceo', 'width', 'height', 'thickness', 's', 'bb', 'med_sheet'].includes(ele[0])
                    }
                }
            })
        );

        const isValidCalculations = Object.entries(calculationToSend).every(([key, value]) => value.isValid);

        if(isValidCalculations){
            setLoading({ name: 'calculations', loading: true });
            AuthFetch({
                url: '/api/waling/calculations/submit', 
                method: 'POST',
                body: {
                    types: {
                        walingType_1: calculations.state.walingType_1.value,
                        walingType_2: calculations.state.walingType_2.value,
                        walingType_3: calculations.state.walingType_3.value,
                        walingType_4: calculations.state.walingType_4.value
                    },
                    calculations: Object.entries(calculationToSend).reduce((acc, [key, value]) => {
                        return {
                            ...acc, 
                            [key]: value.value
                        }
                    },{}),
                }
            }).then(response => {
                setLoading({ name: 'calculations', loading: false });
                if(response.success){
                    results.updateState({
                        isResultsActual: true,
                        ...response.responseData
                    });
                } else {
                    setAlert({ error: true, message: 'Błąd podczas wykonywania obliczeń' });
                }
            })
        } else { 
            !isValidCalculations && setAlert({ error: true, message: 'Nieprawidłowe parametry modelu' });

            calculations.updateState(state => {
                return {
                    ...state,
                    ...Object.entries(calculationToSend).reduce((acc, [key, value]) => ({ ...acc, [key]: { value: value.value, isValid: !!value.isValid } }) ,{})
                }
            });
        }
    }

    const submitCalculation = {
        onSubmit: onSubmitCalculation
    }
    return {
        submitCalculation
    }
}

export default SubmitCalculationHelpers