import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Form, Button } from 'react-bootstrap';
import Email from '../../ResetPassword/Email';
import Password from '../../ResetPassword/Password';
import ConfirmPassword from '../../ResetPassword/ConfirmPassword';
import { AlertMessage2 } from '../../../components';
import { withLoading, withAlert, withForm } from '../../../hoc';
import { isPassword } from '../../../config/ProjectConfig';
import { Link } from 'react-router-dom';
import config from '../../../config/config';

const PasswordContent = styled.div`

    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 100px;
    & form {
        background-color: #fff;
        padding: 20px;
        margin-top: 15px;
        box-shadow: 0 0.4rem 0.8rem -0.1rem rgba(0, 32, 128, 0.1),
            0 0 0 1px #f0f2f7;
    }
`;
const H1 = styled.h1`
    font-size: 28px;
    color: #333;
    text-align: center;
`;
const Margin = styled.div`
    margin: 15px 0;
`
const WrapperA = styled.div`
    text-align: center;

    & a {
        display: block;
        color: #000;
        margin: 15px 0;
    }
`
const SetPasswordForm = ({
    responseData = {},
    formState,
    messageState = {},
    onSubmit,
    onChange,
    actions = {},
}) => {
    const { routes } = config;
    const onConfirmPassword = (e) => {
        let isPasswordCorrect = !!(
            e.target.checkValidity() &&
            isPassword.test(e.target.value) &&
            e.target.value === formState.password.value
        );
        onChange(e, isPasswordCorrect);
    };
    const onSubmitForm = (e) => {
        e.preventDefault();

        onSubmit(
            (formData) => {
                actions.saveNewPassword({
                    body: formData,
                });
            },
            {
                confirmPassword: {
                    ...formState['confirmPassword'],
                    isValid:
                        formState.confirmPassword.value.length > 0 &&
                        formState.password.value ===
                            formState.confirmPassword.value,
                },
            },
        );
    };
    return (
        <Fragment>
            <PasswordContent>
                <H1>Ustaw hasło do konta</H1>
                <Form onSubmit={onSubmitForm} noValidate>
                    <Margin>
                        <Email userEmail={responseData.email} />
                    </Margin>
                    <Margin>
                        <Password password={formState.password} onChange={onChange} />
                    </Margin>
                    <Margin>
                        <ConfirmPassword 
                            confirmPassword={formState.confirmPassword}
                            onChange={onConfirmPassword}
                        />                    
                    </Margin>

                    <Button variant="danger" type="submit">
                        Ustaw hasło
                    </Button>
                    <AlertMessage2
                        {...messageState.saveNewPassword}
                        isLoaded={formState.isLoaded}
                        loadingName={formState.loadingName}
                    />
                </Form>
            </PasswordContent>
            <WrapperA>
                <Link to={routes.dashboard.logIn}>Powrót do logowania</Link>
            </WrapperA>
        </Fragment>

    );
};

export default withForm({
    component: withLoading(withAlert(SetPasswordForm)),
    clearFormToDefaultState: true,
    actions: [
        {
            name: 'saveNewPassword',
            url: '/api/auth/set-password/:id',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            successMessage: 'Hasło zostało ustawione poprawnie',
            errorMessage: 'Ustawienie hasła się nie powiodło',
            showErrorMessage: true,
            showSuccessMessage: true,
        },
    ],
    localState: {
        loadingName: '',
        password: {
            value: '',
            isValid: null,
        },
        confirmPassword: {
            value: '',
            isValid: null,
        },
    },
});
