import { faArrowDown, faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { isNumber } from '../../../../utils';
import { SectionTitle } from '../../../../components';
import styles from './index.module.css';
import stringify from 'fast-json-stable-stringify';

const FoundationSchema = ({ foundationParameters, pileShape }) => {
    return (
        <Fragment>
            <SectionTitle title="Schemat fundamentu" />
            {foundationParameters.columns.isValid &&
            foundationParameters.rows.isValid ? (
                <Fragment>
                    <div className={styles.loadDirection}>
                        <p className={styles.p}>
                            Kierunek działania siły poziomej
                        </p>
                        <FontAwesomeIcon icon={faArrowDown} />
                    </div>
                    {foundationParameters.columns.value > 1 &&
                        isNumber(
                            foundationParameters.perpendicularSpace.value,
                        ) && (
                            <div className={styles.horizontalDimension}>
                                <p className={styles.p}>
                                    {`${
                                        foundationParameters.columns.value
                                    } x ${foundationParameters.perpendicularSpace.value.toFixed(
                                        2,
                                    )}m`}{' '}
                                    (R<sub>1</sub>)
                                </p>
                            </div>
                        )}

                    <div className={styles.schemaWrapper}>
                        {foundationParameters.rows.value > 1 &&
                            isNumber(
                                foundationParameters.parallelSpace.value,
                            ) && (
                                <div className={styles.verticalDimension}>
                                    <p className={styles.p}>
                                        {`${
                                            foundationParameters.rows.value
                                        } x ${foundationParameters.parallelSpace.value.toFixed(
                                            2,
                                        )}m`}{' '}
                                        (R<sub>2</sub>)
                                    </p>
                                </div>
                            )}
                        <div className={styles.foundationWrapper}>
                            <div className={styles.foundation}>
                                {Array(foundationParameters.rows.value)
                                    .fill((ele, ind) => ind)
                                    .map((row, indexRow) => {
                                        return (
                                            <div
                                                className={styles.foundationRow}
                                                key={indexRow}>
                                                {Array(
                                                    foundationParameters.columns
                                                        .value,
                                                )
                                                    .fill((ele, ind) => ind)
                                                    .map(
                                                        (
                                                            column,
                                                            indexColumn,
                                                            arr,
                                                        ) => {
                                                            return (
                                                                <div
                                                                    className={`${
                                                                        styles.pile
                                                                    } ${
                                                                        pileShape.value ===
                                                                        'Kwadratowy'
                                                                            ? styles.rectangularPile
                                                                            : ''
                                                                    }`}
                                                                    key={
                                                                        indexColumn
                                                                    }>
                                                                    <span
                                                                        className={
                                                                            styles.pileNumber
                                                                        }>
                                                                        {indexColumn +
                                                                            indexRow *
                                                                                arr.length +
                                                                            1}
                                                                    </span>
                                                                    <div
                                                                        className={
                                                                            styles.horizontalAxis
                                                                        }></div>
                                                                    <div
                                                                        className={
                                                                            styles.verticalAxis
                                                                        }></div>
                                                                </div>
                                                            );
                                                        },
                                                    )}
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </Fragment>
            ) : (
                <div className={styles.foundationAlert}>
                    <FontAwesomeIcon icon={faBan} />
                    <p className={styles.p}>Zdefiniuj wymiary fundamentu</p>
                </div>
            )}
        </Fragment>
    );
};

export default React.memo(
    FoundationSchema,
    (prev, next) => stringify(prev) === stringify(next),
);
