import { AuthFetch } from "../../../routes";
import { isObjectId } from "../../../config/ProjectConfig";
import { useState, useEffect } from "react";
import stringify from 'fast-json-stable-stringify';

const EditProjectHelpers = ({ 
    setAlert, 
    setLoading, 
    loadMicropileProperties,
    loadSoilTable,  
    loadZwgLevel,
    loadSaftyFactors, 
    loadTitle,
    loadResults,
    onLoadFileName,
    onLoadProfileName,

    onSetMainProjectName,
    onSetSoilProfileMainProjectName,

    projectId
}) => {

    const [currentProjectId, setCurrentProjectId] = useState('');
    const [currentProfileId, setCurrentProfileId] = useState('');
    const [loadProjectState, setLoadProjectState] = useState(false);
    const [currentProjectLoaded, setCurrentProjectLoaded] = useState(false);
    const compressionCurrentProject = JSON.parse(localStorage.getItem('compression')) || {};

    const updateStateOnLoad = (response) => {
        const { model={}, results={}, projectName='' } = response.responseData;     

        setLoadProjectState(true);
        onSetMainProjectName(() => ({ 
            isValid: true,
            value: response.responseData.projectId.name
        }));
        onSetSoilProfileMainProjectName(() => ({
            value: response.responseData.projectId.name
        }));

        setCurrentProjectId(response.responseData.projectId.id);
        setCurrentProfileId(response.responseData.profileId);

        onLoadFileName({
            state: projectName ? 'loaded' : 'init',
            value: projectName
        });
        onLoadProfileName({
            state: 'init',
            value: ''
        });

        loadMicropileProperties(model.micropileParameters);
        loadSoilTable(model.soilsCollection);
        loadZwgLevel(model.zwgLevel);
        loadSaftyFactors(model.saftyFactors); 
        loadTitle({
            projectTitle: model.projectTitle
        });                    
        
       setTimeout(() => {
            loadResults({
                isResultsActive: results.isResultsActive,
                currentMicropileLength: results.currentMicropileLength, 
                currentCapacity: results.currentCapacity,
                results: {
                    isResultsActual: results.isResultsActual,
                    ...results.results
                }
            })
       },0)
    }

    useEffect(() => {
        if(!currentProjectLoaded && compressionCurrentProject.id && compressionCurrentProject.projectId){
            setLoadProjectState(false);
            AuthFetch({
                url: `/api/micropileCompression/calculations/${compressionCurrentProject.id}/${compressionCurrentProject.projectId}`,
                method: 'GET',
            }).then(response => {
                if(response.success){
                    setCurrentProjectLoaded(true);
                    updateStateOnLoad(response);                     
                }
            })
        }
    },[currentProjectLoaded, compressionCurrentProject.id, compressionCurrentProject.projectId]);

    const onLoadProject = (id)=> {
        if(isObjectId(id) && isObjectId(projectId)){
            setLoading({ name: 'fetchProject', loading: true });
            setLoadProjectState(false);
            AuthFetch({
                url: `/api/micropileCompression/calculations/${id}/${projectId}`,
                method: 'GET',
            }).then(response => {
                setLoading({ name: 'fetchProject', loading: false });
                if(response.success) {
                    localStorage.setItem('compression', stringify({ id: response.responseData.id, projectId: response.responseData.projectId.id }));
                    setCurrentProjectLoaded(true);
                    updateStateOnLoad(response);
                } else {
                    setAlert({ error: true, message: 'Błąd podczas wczytywania projektu' });
                } 
            })
            .catch(() => {
                setAlert({ error: true, message: 'Błąd podczas wczytywania projektu' });
            })            
        }

    }
 
    const editProject = {
        onLoadProject: onLoadProject,
        currentProjectId: currentProjectId,
        currentProfileId: currentProfileId,
        loadProjectState: loadProjectState
    }

    return {
        editProject
    }
}

export default EditProjectHelpers