import { Table } from 'react-bootstrap';
import round from '../../../../../utils/roundNumber';
import styles from './index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import { isNumber } from '../../../../../utils';

const MicropileCapacityCompressionDetails = ({ parameters = [] }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.margins}>
                <h2>Nośność mikropala na wciskanie</h2>                
            </div>
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th>
                            z<br></br>[m.p.p.t.]
                        </th>
                        <th>
                            q<sub>s,k</sub>
                            <br></br>[kPa]
                        </th>
                        <th>
                            d<br></br>[mm]
                        </th>
                        <th>
                            a<br></br>[-]
                        </th>
                        <th>
                            a<sub>cpt</sub>
                            <br></br>[mm]
                        </th>
                        <th>
                            &#947;<sub>t</sub>
                            <br></br>[-]
                        </th>
                        <th>
                            &#958;<br></br>[-]
                        </th>
                        <th>
                            R<sub>b</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            R<sub>s,i</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            R<sub>s</sub>=&Sigma;R<sub>s,i</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            R<sub>c,d</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            R<sub>c,lt</sub>
                            <br></br>[kN]
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {parameters.map((element, index) => {
                        const buckling = element.buckling || {};
                        const veryfiedNt = element.veryfied;
                        const axisLoadCorrect = isNumber(veryfiedNt.axisLoad)
                            ? veryfiedNt.axisLoad >= 1
                                ? true
                                : false
                            : false;
                        const isDefRatio = buckling.details
                            ? buckling.details.freeSpaceRod
                                ? true
                                : buckling.details.isDefRatioCorrect
                            : true;
                        const bucklingCorrect = buckling.details
                            ? buckling.details.isAxialForceCorrect &&
                              buckling.details.isBuckCorrect &&
                              isDefRatio
                            : true;
                        const correctLength =
                            axisLoadCorrect && bucklingCorrect;

                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{round(element.z, 2)}</td>
                                <td>{round(element.qsk, 2)}</td>
                                <td>{round(element.diameter, 2)}</td>
                                <td>
                                    {element.isCpt
                                        ? '-'
                                        : round(element.enlargeDiameter, 2)}
                                </td>
                                <td>
                                    {element.isCpt
                                        ? round(element.enlargeDiameter, 2)
                                        : '-'}
                                </td>
                                <td>{round(element.yt, 2)}</td>
                                <td>{round(element.ksi, 2)}</td>
                                <td>{round(element.np, 2)}</td>
                                <td>{round(element.nsi, 2)}</td>
                                <td>{round(element.ns, 2)}</td>
                                <td>{round(element.nt, 2)}</td>
                                <td>{round(element.nt * 1.5, 2)}</td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={
                                            correctLength
                                                ? faCheckCircle
                                                : faTimesCircle
                                        }
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <p className={styles.underline}>Oznaczenia:</p>
            <p>
                Z -{' '}
                <span className={styles.value}>
                    rzędna zagłębienia pala poniżej poziomu terenu
                </span>
            </p>
            <p>
                q<sub>s,k</sub> -{' '}
                <span className={styles.value}>
                    graniczny opór gruntu na pobocznicy mikropala
                </span>
            </p>
            <p>
                d -{' '}
                <span className={styles.value}>
                    średnica koronki wiertniczej mikropala
                </span>
            </p>
            <p>
                a -{' '}
                <span className={styles.value}>
                    współczynnik poszerzenia średnicy mikropala
                </span>
            </p>
            <p>
                a<sub>cpt</sub> -{' '}
                <span className={styles.value}>
                    poszerzenie średnicy mikropala na podstawie badań sondą CPT
                </span>
            </p>
            <p>
                &#947;<sub>t</sub> -{' '}
                <span className={styles.value}>
                    częściowy współczynnik bezpieczeństwa dla mikropali
                    wciskanych
                </span>
            </p>
            <p>
                &#958; -{' '}
                <span className={styles.value}>
                    współczynnik modelu wg. Eurokodu 7 (załącznik krajowy)
                </span>
            </p>
            <p>
                R<sub>b</sub> -{' '}
                <span className={styles.value}>nośność podstawy mikropala</span>
            </p>
            <p>
                R<sub>s,i</sub> -{' '}
                <span className={styles.value}>
                    przyrost nośność na pobocznicy mikropala
                </span>
            </p>
            <p>
                R<sub>s</sub> -{' '}
                <span className={styles.value}>
                    nośność pobocznicy mikropala
                </span>
            </p>
            <p>
                R<sub>c,d</sub> -{' '}
                <span className={styles.value}>
                    obliczeniowa nośność mikropala wciskanego
                </span>
            </p>
            <p>
                R<sub>c,lt</sub> -{' '}
                <span className={styles.value}>
                    siła do wykonania próbnego obciążenia mikropala wciskanego
                </span>
            </p>
        </div>
    );
};

export default MicropileCapacityCompressionDetails;
