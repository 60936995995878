import { useMemo } from 'react';
import { SelectField } from '../../../components';
import styles from './index.module.css';
import stringify from 'fast-json-stable-stringify';

const ProjectsList = ({
    currentUserProjects = [],
    onChangeCurrentProject,
    onChangeUser,
    currentProject = {},
    state = {},
}) => {
    const allProjectsStringify = stringify(state.allProjects);

    const userProjects = useMemo(() => {
        const allProjectsParsed = JSON.parse(allProjectsStringify);

        return allProjectsParsed.filter((ele) => ele.email !== state.userEmail);
    }, [allProjectsStringify, state.userEmail]);

    return (
        <div className={styles.wrapper}>
            {Array.isArray(userProjects) && userProjects.length > 0 && (
                <div className={`${styles.fullWidth} ${styles.space}`}>
                    <SelectField
                        title="Wybierz użytkownika"
                        onChange={onChangeUser}
                        value={state.currentUser}
                        defaultOption="Moje projekty"
                        disabledDefault={false}
                        options={userProjects.map(
                            (ele) =>
                                `${ele.firstName} ${ele.lastName} (${ele.email})`,
                        )}
                        name="currentUser"
                    />
                </div>
            )}
            <div className={styles.fullWidth}>
                <SelectField
                    title="Wybierz projekt"
                    onChange={onChangeCurrentProject}
                    value={currentProject.name}
                    defaultOption="Wybierz projekt"
                    options={currentUserProjects.map((ele) => ele.name)}
                    name="mainProjectName"
                />
            </div>
        </div>
    );
};

export default ProjectsList;
