import { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { SectionTitle } from "../../../../../components";
import { isNumber, roundNumber } from "../../../../../utils";
import styles from './index.module.css';

const SummarySGU = ({ results, a2, d }) => {
    return (
        <Fragment>
            <SectionTitle
                title="Zbrojenie główne (SGU)"
            />
            <Row className={styles.row}>
                <Col xs={12} className={styles.col}>
                    {
                            <Fragment>
                                {results.result.reinfAsNotRequired && <b><u>Zbrojenie obliczeniowe nie jest wymagane</u></b>}
                                <b><p>Zbrojenie górne ({ results.result.x > a2 
                                    ? 
                                    results.defaultResults.negativeMoment 
                                        ? results.result.x > d ? 'ściskane' : 'rozciągane' 
                                        : 'ściskane' 
                                    : 
                                    'rozciągane'}) [A<sub>s2</sub>] - {roundNumber(results.result.as2,2)} cm<sup>2</sup></p></b>
                                <b><p>Zbrojenie dolne ({ results.result.x > a2 
                                ? 
                                results.defaultResults.negativeMoment 
                                    ? 'ściskane' 
                                    : results.result.x > d ? 'ściskane' : 'rozciągane' 
                                : 
                                'rozciągane'}) [A<sub>s1</sub>] - {roundNumber(results.result.as1,2)} cm<sup>2</sup></p></b>
                            </Fragment>       
                    }
                </Col>
                <Col xs={12} className={styles.col}>
                    <p>Wysokość strefy ściskanej [x] - {roundNumber(results.result.x,2)} mm</p>
                </Col>
                <Col xs={12} className={styles.col}>
                    <p>Szerokość rysy [w<sub>k</sub>] - {roundNumber(results.crack.wk,2)} mm</p>
                </Col>
                <Col xs={12} className={styles.col}>
                    <p>Dopuszczalna szerokość rysy [w<sub>max</sub>] - {roundNumber(results.crack.maxCrackCalc,2)} mm</p>
                </Col>
                {
                    results.defaultResults.lbdCalculation &&
                    <Fragment>
                        {
                            results.lbd && isNumber(results.lbd.tension) &&
                            <Col xs={12} className={styles.col}>
                                <p>Długość zakotwienia zbrojenia rozciąganego [l<sub>bd+</sub>] - {roundNumber(results.lbd.tension,0)} mm</p>
                            </Col>
                        }
                        {
                            results.lbd && isNumber(results.lbd.compression) &&
                            <Col xs={12} className={styles.col}>
                                <p>Długość zakotwienia zbrojenia ściskanego [l<sub>bd-</sub>] - {roundNumber(results.lbd.compression,0)} mm</p>
                            </Col>
                        }
                    </Fragment>
                }
            </Row>
        </Fragment>
    )
}

export default SummarySGU;