import React from 'react'
import { View, StyleSheet, Text, Font } from '@react-pdf/renderer'

Font.register({
    family: "Roboto",
    fonts: [
        {
            src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"
        },
        {
            src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
            fontWeight: 400
        },
        {
            src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
            fontWeight: 500
        },
        {
            src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
            fontWeight: 700
        }
    ]
    
  });

const styles = StyleSheet.create({
  table: {
    width: '100%',
    marginTop: 50
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    backgroundColor: '#ddd',
    color: '#000',
    borderTop: '1px solid #000',
    borderBottom: '1px solid #000',
    borderLeft: '1px solid #000',
    textAlign: 'center'
  },
  body: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    color: '#000',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    borderBottom: '1px solid #000',
    borderLeft: '1px solid #000',
    textAlign: 'center'
  }
})

const TablePDF = ({ children }) => <View style={styles.table}>{children}</View>

const TableHeader = ({ children }) => (
  <View style={styles.header}>{children}</View>
)

const TableHeaderCell = ({ children, width = 20 }) => (
  <Text style={{
    fontSize: 10,
    width: `${width}%`,
    height: 25,
    padding: 5,
    borderRight: '1px solid #000',
    color: '#000',
    textAlign: 'center'
  }}>{children}</Text>
)

const TableBody = ({ children }) => <View style={styles.body}>{children}</View>

const TableRow = ({ children }) => <View style={styles.row}>{children}</View>

const TableCell = ({ children, width = 20 }) => (
    <Text style={{
        width: `${width}%`,
        borderRight: '1px solid #000',
        color: '#000',
        textAlign: 'center',
        height: 25,
        padding: 5,
    }}>{children}</Text>
)

TablePDF.Header = TableHeader
TablePDF.Body = TableBody
TablePDF.Row = TableRow
TablePDF.HeaderCell = TableHeaderCell
TablePDF.Cell = TableCell

export default TablePDF