import { isNumber } from "../../../utils";
import { AuthFetch } from "../../../routes";

const SubmitCalculationHelpers = ({ 
    soilTable, 
    pileProperties, 
    calculationSettings,
    saftyFactors,
    results, 
    setAlert,  
    setLoading 
}) => {
    const onSubmitCalculation = () => {
        const { technologyFactors, ...restFactors } = saftyFactors.state;

        const pileParametersToSend = Object.fromEntries(Object.entries(pileProperties.state)
            .filter((ele,_,arr) => !arr.find(ele => ele[0] === 'isBaseWidth')[1].value ? ele[0] !== 'baseWidth' : ele)
            .filter((ele,_,arr) => !arr.find(ele => ele[0] === 'singlePile')[1].value ? ele[0] !== 'pilesInFoundation' : ele)
            .filter((ele,_,arr) => !arr.find(ele => ele[0] === 'pileGroup')[1].value ? !['groupLength','groupPilesNumber','groupWidth','pileSpace'].includes(ele[0]) : ele));

        const soilTableToSend = soilTable.state.map(element => {
            return Object.fromEntries(Object.entries(element).filter(ele => !['absHeight','qc','t','layerNo'].includes(ele[0])));
        })
        .map(element => {
            return Object.fromEntries(Object.entries(element).filter((ele,_,arr) => {
                const plasticity = arr.find(ele => ele[0] === 'plasticity');
                const density = arr.find(ele => ele[0] === 'density');

                if(plasticity[1] && density[1]){
                    return isNumber(plasticity[1].value) && !isNumber(density[1].value) ? ele[0] !== 'density'
                        : !isNumber(plasticity[1].value) && isNumber(density[1].value) ? ele[0] !== 'plasticity'
                        : ele
                } else {
                    return ele
                }
            }))
        });
        const saftyFactorsToSend = Object.fromEntries(
            Object.entries(restFactors)
            .filter((ele,_,arr) => !(arr.find(ele => ele[0] === 'qtInterpolationMethod')[1].value === 3) ? ele[0] !=='customInterpolationLevel' : ele)
            .filter((ele,_,arr) => pileProperties.state.pileType.value !== 'Z rury stalowej z dnem otwartym' ? ele[0] !== 'a2reduction' : ele) 
        );

        const technologyFactorsToSend = technologyFactors.map(ele => {
            const { plasticity, density, hcifz, tcifz, spfz, ssfz, swfz, ymfz, ...rest } = ele;

            return {
                ...rest,
                ...(!pileProperties.state.singlePile.value && pileProperties.state.pileGroup.value) && {
                    hcifz, 
                    tcifz, 
                    spfz, 
                    ssfz, 
                    swfz, 
                    ymfz,
                },
                ...isNumber(plasticity) && {
                    plasticity
                },
                ...isNumber(density) && { 
                    density
                },
            }
        });

        const calculationSetingsToSend = Object.fromEntries(Object.entries(calculationSettings.state)
            .filter((ele,_,arr) => !arr.find(ele => ele[0] === 'newEmbankment')[1].value ? ele[0] !== 'embankmentsNumber' : ele)
            .filter((ele,_,arr) => !arr.find(ele => ele[0] === 'negativeFriction')[1].value ? ele[0] !== 'layersName' : ele)
        );
        const isValidCalculationSettingsState = Object.entries(calculationSetingsToSend).every(ele => ele[0] === 'layersName' ? Object.values(ele[1]).every(ele => ele.isValid) : ele[1].isValid);
        const isValidPileProperties = Object.values(pileParametersToSend).every(ele => ele.isValid);
        const isValidSoilState = soilTableToSend.every(ele => Object.values(ele).every(ele => ele.isValid));
        
        const isValidRestFactorsState = Object.values(saftyFactorsToSend).every(ele => ele.isValid);
        const isValidTechnologyFactorsState = technologyFactorsToSend.every(element => Object.values(element).every(ele => ele.hasOwnProperty('isValid') ? ele.isValid : (isNumber(ele) || ele.length > 0)));

        if(isValidSoilState && isValidPileProperties && isValidCalculationSettingsState && isValidRestFactorsState && isValidTechnologyFactorsState){
            setLoading({ name: 'calculations', loading: true });
            AuthFetch({
                url: '/api/vertical-capacity/pn-en/submit', 
                method: 'POST',
                body: {
                    calculationSettings: Object
                                        .entries(calculationSetingsToSend) 
                                        .reduce((acc, [key, value]) => ({ ...acc, [key]: key === 'layersName' ? Object.entries(value).reduce((acc, [key,value]) => ({ ...acc, [key]: value.value }) ,{}) : value.value }),{}),
                    pileParameters:     Object
                                        .entries(pileParametersToSend)
                                        .reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }),{}),
                    saftyFactors:       Object
                                        .entries(saftyFactorsToSend)
                                        .reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }),{}),
                    technologyFactors: technologyFactorsToSend.map(ele => Object.entries(ele).reduce((acc, [key, value]) => {
                        return {
                            ...acc,
                            [key]: value.hasOwnProperty('value') ? value.value : value
                        }
                    },{}))
                }
            }).then(response => {
                setLoading({ name: 'calculations', loading: false });
                if(response.success){
                    results.updateCurrentPileLength(response.responseData.pileParameters.pileLength);
                    results.updateState({
                        isResultsActual: true,
                        ...response.responseData
                    })
                } else {
                    setAlert({ error: true, message: 'Błąd podczas wykonywania obliczeń' });
                }
            });
        } else {
            const invalidSoilState = soilTableToSend.map(ele => Object.entries(ele).reduce( (acc, [key, value]) => ({ ...acc, [key]: { value: value.value, isValid: !!value.isValid } }),{}));

            !isValidSoilState && setAlert({ error: true, message: 'Nieprawidłowe parametry podłoża gruntowego' });
            !isValidPileProperties && setAlert({ error: true, message: 'Nieprawidłowe parametry pala' });
            !isValidCalculationSettingsState && setAlert({ error: true, message: 'Nieprawidłowe parametry profilu gruntowego' });
            (!isValidRestFactorsState || !isValidTechnologyFactorsState) && setAlert({ error: true, message: 'Nieprawidłowe współczynniki bezpieczeństwa' });

            soilTable.updateState(state => state.map((element, index) => {
                return {
                    ...element,
                    ...invalidSoilState[index]
                }
            }));
            pileProperties.updateState(state => {
                return {
                    ...state,
                    ...Object.entries(pileParametersToSend).reduce((acc, [key, value]) => ({ ...acc, [key]: { value: value.value, isValid: !!value.isValid } }) ,{})
                }
            });
            calculationSettings.updateState(state => {
                return {
                    ...state,
                    ...Object.entries(calculationSetingsToSend)
                            .reduce((acc, [key, value]) => (
                                { ...acc, 
                                    [key]: key === 'layersName' ? Object.entries(value).reduce((acc, [key,value]) => ({ ...acc, [key]: { ...value, isValid: value.isValid } }),{}) : { value: value.value, isValid: !!value.isValid } 
                                })
                            ,{})
                }
            });
            saftyFactors.updateState(state => {
                return {
                    ...state,
                    ...Object.entries(saftyFactorsToSend).reduce( (acc, [key, value]) => ({ ...acc, [key]: { value: value.value, isValid: !!value.isValid } }) ,{}),
                    technologyFactors: state.technologyFactors.map((element, index) => {
                        return Object.entries(element).reduce((acc, [key, value]) => ({ ...acc, [key]: value.hasOwnProperty('isValid') ? { value: value.value, isValid: !!value.isValid } : value }),{})
                    }) 
                }
            })
        }
    }

    const submitCalculation = {
        onSubmit: onSubmitCalculation
    }
    return {
        submitCalculation
    }
}

export default SubmitCalculationHelpers