import { useState } from 'react';
import { roles } from '../../../config/ProjectConfig';
import { AdminFetch } from '../../../routes';
import { groupByProperty } from '../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const RemoveUsers = ({
    users,
    fetch,
    setAlert,
    setLoading,
    setUserListStateChanged,
}) => {
    const [deleteCheckbox, setDeleteCheckbox] = useState(false);
    const [usersToRemove, setUsersToRemove] = useState([]);

    useDeepCompareEffect(() => {
        setUsersToRemove(
            groupByProperty(
                users.map((ele) => {
                    const { parentId, email, role, id } = ele;
                    return {
                        id,
                        role,
                        email,
                        parentId,
                    };
                }),
                'parentId',
            ).map((ele) => ({ users: ele })),
        );
    }, [users]);

    const onChange = () => setDeleteCheckbox((state) => !state);

    const onSubmit = () => {
        if (usersToRemove.length > 0) {
            setLoading({ name: 'removeUsers', loading: true });
            AdminFetch({
                url: fetch.removeUsers.url,
                method: fetch.removeUsers.method,
                body: {
                    usersToRemove: usersToRemove.map((ele) => ({
                        ...ele,
                        users: ele.users.filter(
                            (element) => element.role !== roles[2],
                        ),
                    })),
                    deleteCheckbox,
                },
            })
                .then((response) => {
                    setLoading({ name: 'removeUsers', loading: false });
                    if (response.success) {
                        setAlert({
                            error: false,
                            message: 'Użytkownicy zostali usunięci',
                            hideTime: 2000,
                        });
                        setUserListStateChanged(true);
                    } else {
                        setAlert({
                            error: true,
                            message: 'Błąd podczas usuwania użytkowników',
                        });
                    }
                })
                .catch(() => {
                    setLoading({ name: 'removeUsers', loading: false });
                    setAlert({
                        error: true,
                        message: 'Błąd podczas usuwania użytkowników',
                    });
                });
        } else {
            setAlert({
                error: true,
                message: 'Nie wybrano żadnego użytkownika',
            });
        }
    };

    const removeUsers = {
        onSubmit,
        onChange,

        state: {
            deleteCheckbox,
        },
    };

    return {
        removeUsers,
    };
};

export default RemoveUsers;
