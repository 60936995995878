import { useState } from "react"
import { isNumber } from "../../../utils"
import useDeepCompareEffect from 'use-deep-compare-effect';

const SoilProfileHelpers = ({ 
    soilTableState = [],
    newEmbankment, 
    embankmentsNumber,
    negativeFriction,
    negativeFrictionLayers, 
}) => {
    const [profile, setProfile] = useState([]);

    useDeepCompareEffect(() => {
        setProfile(() => [...soilTableState.filter(ele => (isNumber(ele.height.value) && ele.height.isValid))])
    },[soilTableState]);

    return {
        soilProfile: {
            height: profile.map(ele => ele.height.value),
            density: profile.map(ele => ele.density.value),
            plasticity: profile.map(ele => ele.plasticity.value),
            qc: profile.map(ele => ele.qc.value), 
            t: profile.map(ele => ele.t.value),
            name: profile.map(ele => ele.name.value),
            weight: profile.map(ele => ele.weight.value),
            underWaterWeight: profile.map(ele => ele.underWaterWeight.value),
            newEmbankment: newEmbankment.value,
            embankmentsNumber: embankmentsNumber.value,
            negativeFriction: negativeFriction.value,
            negativeFrictionLayers: negativeFrictionLayers
        }
    }

}

export default SoilProfileHelpers