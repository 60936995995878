const nonCohesiveSoilType = {
    0: {
        pl: 'Niespoisty'
    }, 
}
const cohesiveSoilType = {
    1: {
        pl: 'Mało spoisty'
    },
    2: {
        pl: 'Średnio spoisty'
    },
    3: {
        pl: 'Zwięzło spoisty'
    },
    4: {
        pl: 'Bardzo spoisty'
    }, 
    5: {
        pl: 'Organiczny'
    }
}
const soilType = {
    ...nonCohesiveSoilType,
    ...cohesiveSoilType
};

export { nonCohesiveSoilType, cohesiveSoilType, soilType }