import stringify from 'fast-json-stable-stringify';
import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { InputField, SectionTitle } from '../../../../components';
import styles from './index.module.css';

const ZwgLevels = ({ state, onChange, onBlur, onKeyDown, maxHeight }) => {
    return (
        <Fragment>
            <SectionTitle title="Parametry profilu" />
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        title={`Rzędna terenu [m.n.p.m.]`}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        onBlur={onBlur}
                        name="groundLevel"
                        type="number"
                        value={state.groundLevel.value}
                        isValid={state.groundLevel.isValid}
                        placeholder="Rzędna terenu"
                        min={-1000}
                        step={0.001}>
                        Na profilu podano rzędne w odniesieniu do poziomu
                        terenu, natomiast w nawiasie podano rzędne w odniesieniu
                        do poziomu morza.
                    </InputField>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        title={`Rzędna spodu ścianki [m.p.p.t.]`}
                        data-height={stringify(maxHeight)}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        onBlur={onBlur}
                        name="sheetPileSpot"
                        type="number"
                        value={state.sheetPileSpot.value}
                        isValid={state.sheetPileSpot.isValid}
                        placeholder="Rzędna spodu ścianki"
                        min={0}
                        step={0.001}
                        required
                    />
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        title={`Rzędna dna wykopu [m.p.p.t.]`}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        onBlur={onBlur}
                        name="excavationBase"
                        type="number"
                        value={state.excavationBase.value}
                        isValid={state.excavationBase.isValid}
                        placeholder="Rzędna spodu ścianki"
                        min={0}
                        step={0.001}
                        required
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        title="Rzędna ZWG w wykopie [m.p.p.t.]"
                        onChange={onChange}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        value={state.leftZwg.value}
                        isValid={state.leftZwg.isValid}
                        type="number"
                        placeholder={'Lewa strona'}
                        name="leftZwg"
                        min={0}
                        step={0.001}
                    />
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        title={'Rzędna ZWG naziomu [m.p.p.t]'}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        onBlur={onBlur}
                        value={state.rightZwg.value}
                        isValid={state.rightZwg.isValid}
                        type="number"
                        placeholder={'Prawa strona'}
                        name="rightZwg"
                        min={0}
                        step={0.001}
                    />
                </Col>
            </Row>
        </Fragment>
    );
};

export default ZwgLevels;
