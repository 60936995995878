import { useCallback, useState } from "react";
import { stringToNumber } from "../../../utils";

const defaultState = {
    zwg: {
        value: '',
        isValid: null
    },
    groundLevel: {
        value: '',
        isValid: null 
    }
}

const ZwgLevelHelpers = ({ updateResultsState }) => {
    const [zwgLevelsState, setZwgLevelsState] = useState(defaultState);

    const onBlurZwgState = useCallback((e) => {
        const { name } = e.currentTarget;
        const valid = e.currentTarget.checkValidity(); 

        setZwgLevelsState(state => ({
            ...state, 
            [name]: { 
                ...state[name],
                isValid: valid
            },
        })) 
    },[]);

    const onChangeZwgState = useCallback((e) => {
        const input = e.currentTarget.closest('input');
        
        if(input){
            const { value, name } = input;

            updateResultsState(state => {
                return {
                    ...state,
                    isResultsActual: false, 
                }
            });
            setZwgLevelsState(state => ({
                ...state, 
                [name]: { 
                    value: stringToNumber(value),
                    isValid: true
                }
            }))
        }
    },[]);

    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    },[]);

    const loadProject = (calculationSettingsObj={}) => {
        setZwgLevelsState(() => {
            return {
                ...defaultState,
                ...calculationSettingsObj
            }
        })
    }

    const zwgLevel = {
        onChange: onChangeZwgState,
        onBlur: onBlurZwgState,
        onKeyDown: onKeyDown,
        initState: () => setZwgLevelsState(defaultState),
        updateState: setZwgLevelsState,
        loadProject: loadProject,
        state: zwgLevelsState
    }

    return {
        zwgLevel
    }

}

export default ZwgLevelHelpers