import { useState, useContext } from 'react';
import { authContext } from '../../context/Authentication';
import { AuthFetch } from '../../routes';

import { useIsMountedState } from '../../hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';

const EditSoilsHelpers = ({
    setAlert,
    setLoading,
    updateSoilTable,
    onLoadProfileName,
    onChangeState,
    fetch
}) => {
    const { email: userEmail } = useContext(authContext);
    const [profiles, setProfiles] = useState([]);
    const [projects, setProjects] = useState([]);
    const [mainProjectName, setMainProjectName] = useState({
        value: '',
    });
    const [stateChanged, setStateChanged] = useState(true);

    const [noProjects, setNoProjects] = useState(false);
    const [currentUser, setCurrentUser] = useState('');
    const [currentUserProjects, setCurrentUserProjects] = useState([]);

    const isMountedState = useIsMountedState();

    useDeepCompareEffect(() => {
        const userEmailMatch = currentUser.match(/\(([^)]+)\)/);
        const currentUserObj =
            projects.length > 0
                ? projects.find((ele) => ele.email === userEmail)
                : undefined;

        const currentUserObjProjects = currentUserObj
            ? currentUserObj.projects
            : undefined;

        if (userEmailMatch) {
            const subUserProjects = projects.find(
                (ele) => ele.email === userEmailMatch[1],
            );

            if (subUserProjects) {
                setCurrentUserProjects(subUserProjects.projects);
                setMainProjectName((state) => ({
                    value: state.value || subUserProjects.projects[0].name,
                }));
            } else {
                setCurrentUserProjects(
                    currentUserObj ? currentUserObj.projects : [],
                );
                setCurrentUser('');
                setMainProjectName((state) => ({
                    value: state.value ? state.value : currentUserObjProjects ? currentUserObjProjects[0].name : '',
                }));
            }
        } else {
            setCurrentUserProjects(
                currentUserObj ? currentUserObj.projects : [],
            );
            setCurrentUser('');
            setMainProjectName((state) => ({
                value: state.value ? state.value : currentUserObjProjects ? currentUserObjProjects[0].name : '',
            }));
        }
    }, [currentUser, userEmail, projects]);

    useDeepCompareEffect(() => {
        const projectByName = currentUserProjects.find(
            (ele) => ele.name === mainProjectName.value,
        );

        if (projectByName) {
            AuthFetch({
                url: `${fetch.getProfiles.url}/${projectByName.id}`,
                method: fetch.getProfiles.method,
            })
            .then((response) => {
                if(isMountedState.current){
                    if (response.success) {
                        setStateChanged(false);
                        setProfiles(() => [...response.responseData]);
                    } else {
                        setAlert({
                            error: true,
                            message:
                                'Błąd podczas wczytywania profili gruntowych',
                        });
                    }                        
                }

            })
            .catch((e) => {
                if(isMountedState.current){
                    setAlert({
                        error: true,
                        message: 'Błąd podczas wczytywania profili gruntowych',
                    });
                }

            });
        }
    }, [
        currentUserProjects,
        projects,
        mainProjectName,
        stateChanged,
        setAlert,
        fetch.getProfiles
    ]);

    const onLoadProjects = () => {
        setLoading({
            name: 'fetchAllProfiles',
            loading: !stateChanged ? false : true,
        });
        AuthFetch({
            url: fetch.getProjectsName.url,
            method: fetch.getProjectsName.method,
        })
        .then((response) => {
            setLoading({ name: 'fetchAllProfiles', loading: false });

            if (response.success) {
                setStateChanged(false);
                if (response.responseData.projects.length === 0) {
                    setNoProjects(true);
                } else {
                    setProjects(() => {
                        return response.responseData.projects.reduce(
                            (acc, ele) => {
                                const { userId, ...rest } = ele;
                                const userExistsIndex =
                                    acc.length > 0
                                        ? acc.findIndex(
                                                (ele) => ele.id === userId.id,
                                            )
                                        : -1;

                                if (userExistsIndex > -1) {
                                    acc[userExistsIndex] = {
                                        ...acc[userExistsIndex],
                                        projects: [
                                            ...acc[userExistsIndex]
                                                .projects,
                                            rest,
                                        ],
                                    };
                                    return [...acc];
                                }
                                return [
                                    ...acc,
                                    {
                                        ...userId,
                                        projects: [rest],
                                    },
                                ];
                            },
                            [],
                        );
                    });
                }
            } else {
                setAlert({
                    error: true,
                    message: 'Błąd podczas wczytywania projektów',
                });
            }
        })
        .catch((e) => {
            setAlert({
                error: true,
                message: 'Błąd podczas wczytywania projektów',
            });
        });
    };

    const onRemoveProfile = (id) => {
        AuthFetch({
            url: `${fetch.removeProfile.url}/'${id}`,
            method: fetch.removeProfile.method,
        }).then((response) => {
            if (response.success) {
                setStateChanged(true);
                setAlert({
                    error: false,
                    message: 'Profil geologiczny został usunięty',
                    hideTime: 2000,
                });
                setProfiles((state) => [
                    ...state.filter(
                        (ele) => ele.id !== response.responseData.id,
                    ),
                ]);
            } else {
                setAlert({
                    error: true,
                    message: 'Błąd podczas usuwania profilu geologicznego',
                });
            }
        });
    };

    const onLoadProfile = (id) => {
        setLoading({ name: 'fetchProfile', loading: true });
        AuthFetch({
            url: `${fetch.loadProfile.url}/${id}`,
            method: fetch.loadProfile.method,
        }).then((response) => {
            setLoading({ name: 'fetchProfile', loading: false });
            if (response.success) {
                if (typeof onChangeState === 'function') {
                    onChangeState(true);
                }
                onLoadProfileName({
                    state: response.responseData.profileName
                        ? 'loaded'
                        : 'init',
                    value: response.responseData.profileName,
                });
                updateSoilTable(response.responseData.soilsCollection);
            } else {
                setAlert({
                    error: true,
                    message: 'Błąd podczas wczytywania profilu geologicznego',
                });
            }
        });
    };

    const onChange = (e) => {
        const target = e.currentTarget;
        if (target.name === 'currentUser') {
            setCurrentUser(target.value);
        } else {
            setMainProjectName((state) => ({
                ...state,
                value: target.value,
            }));
        }
    };

    const editSoilProfile = {
        onLoadProfile: onLoadProfile,
        onRemoveProfile: onRemoveProfile,
        onLoadProjects: onLoadProjects,
        onStateChange: setStateChanged,
        onChange: onChange,
        onSetMainProjectName: setMainProjectName,
        state: {
            stateChanged,
            profiles,
            projects,
            mainProjectName,

            userEmail,
            noProjects,
            currentUser,
            currentUserProjects,
        },
    };

    return {
        editSoilProfile,
    };
};

export default EditSoilsHelpers;
