import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

const initState = {
    allItems: false,
    projectInfo: false,
    calculationInput: false,
    summary: false,
    shearSummary: false,
    secondOrder: false,
    secondOrderModel: false,
    capacityChart: false,
    capacityDetails: false,
    firstPage: false,
    sectionChart: false,
    modelFactors: false
};
const PrintResultsHelpers = (data) => { 
    const [reportItem, setRaportItem] = useState(initState);

    useDeepCompareEffect(() => {
        if(data.results.initModelValues){
            if(!data.results.initModelValues.secondOrder){
                setRaportItem(state => {
                    return {
                        ...state,
                        secondOrder: false
                    }
                })
            }            
        }

    },[data.results]);

    const onChangeRaportItems = (e) => {
        const input = e.currentTarget.closest('input');

        if (input) {
            const { name, checked } = input;

            if (name === 'allItems') {
                setRaportItem((state) => ({
                    ...state,
                    ...Object.fromEntries(
                        Object.entries(state).map(([key]) => {
                            if(!data.results.initModelValues.secondOrder){
                                if(key === 'secondOrder' && !data.results.initModelValues.circular){
                                    return [key, false];
                                } else if(key === 'secondOrderModel' && !data.results.initModelValues.circular){
                                    return [key, false];
                                } else {
                                    return [key, !!checked ? true : false];
                                }
                                
                            } else {
                                return [key, !!checked ? true : false];
                            }
                        }),
                    ),
                }));
            } else {
                setRaportItem((state) => ({
                    ...state,
                    [name]: !!checked,
                }));
            }
        }
    };

    const printResults = {
        onChange: onChangeRaportItems,
        initState: () => setRaportItem(initState),
        state: {
            reportItem,
            ...data,
        },
    };

    return {
        printResults,
    };
};

export default PrintResultsHelpers;
