import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    Button,
    InputField,
    SelectField,
    RadioInput,
    SectionTitle,
    Subtitle,
    Modal,
    Checkbox,
} from '../../../../components';
import styles from './index.module.css';

const SaftyFactors = ({
    state,
    onChange,
    onBlur,
    onKeyDown,
}) => {
    const [open, setOpen] = useState({});

    const onOpen = (e) => {
        const target = e.currentTarget;
        if (target) {
            setOpen((state) => ({ ...state, [target.name]: true }));
        }
    };
    const onHide = () => setOpen({});

    return (
        <Fragment>
            <div className={styles.wrapper}>
                <SectionTitle title="Parametry obliczeń" />

                <Row className={styles.row}>
                    <Col xs={12} md={6}>
                        <Button
                            onClick={onOpen}
                            icon={faFolderOpen}
                            css={`
                                ${styles.button} default-button-style
                            `}
                            name="saftyFactors"
                            altTitle="Parametry obliczeń"
                            title="Parametry obliczeń"
                        />
                    </Col>
                </Row>
            </div>
            <Modal
                open={open.saftyFactors}
                css={styles.modalOverflow}
                cssDialog={styles.modal}
                onHide={onHide}
                title="Współczynniki materiałowe"
            >
                <Row className={styles.row}>
                    <Col xs={6}>
                        <InputField
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.yt.value}
                            isValid={state.yt.isValid}
                            type="number"
                            title={<Fragment>Wsp. materiałowy dla stali - y<sub>t</sub></Fragment>}
                            placeholder="Wsp. materiałowy dla stali"
                            name="yt"
                            disable={true}
                            required
                        />
                    </Col>
                    <Col xs={6}>
                        <InputField
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.yc.value}
                            isValid={state.yc.isValid}
                            type="number"
                            title={<Fragment>Wsp. materiałowy dla betonu - y<sub>c</sub></Fragment>}
                            placeholder="Wsp. materiałowy dla betonu"
                            name="yc"
                            disable={true}
                            required
                        >
                            Wg polskiej wersji Eurokodu 2 (PN-EN-1992) wartość współczynnika materiałowego dla betonu wynosi 1.4, natomiast wg wersji europejskiej 1.5.
                        </InputField>
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={6}>
                        <InputField
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.youngModulus.value}
                            isValid={state.youngModulus.isValid}
                            type="number"
                            title={<Fragment>Moduł Younga stali [GPa]</Fragment>}
                            placeholder="Moduł Younga stali"
                            name="youngModulus"
                            disable={true}
                            required
                        />
                    </Col>
                    <Col xs={6}>
                        <InputField
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.alfa.value}
                            isValid={state.alfa.isValid}
                            type="number"
                            title={<Fragment>&#945;<sub>cc</sub></Fragment>}
                            name="alfa"
                            disable={true}
                            required
                        />
                    </Col>
                </Row>
                <Row className={styles.row1}>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Subtitle
                                    title={'Model granicznej wysokości strefy ściskanej'}
                                />
                            </Col>
                            <Col xs={6} md={3} className={`${styles.flexEnd} ${styles.flexColumn} ${styles.marginBottom}`}>
                                <RadioInput
                                    onChange={onChange}
                                    name="compressionZone"
                                    label={'Z graniczną redystrybucją'}
                                    checked={state.compressionZone.value === 1}
                                    value={1}
                                >
                                    <Fragment>
                                        Metoda wyznaczania granicznej wysokości strefy ściskanej oparta na uwzględnieniu dopuszczalnej redystrybucji naprężeń w przekroju zgodnie z zasadami dotyczącymi sprężystej analizy betonu z ograniczoną redystrybucją.<br></br>Dla betonów zwykłych:<br></br> &#958;<sub>u</sub> = (&#948; - 0.44)/1.25
                                    </Fragment>
                                </RadioInput>
                            </Col>
                            <Col xs={6} md={3} className={`${styles.flexEnd} ${styles.flexColumn} ${styles.marginBottom}`}>
                                <RadioInput
                                    onChange={onChange}
                                    name="compressionZone"
                                    label={'Z uplastycznieniem stali'}
                                    checked={state.compressionZone.value === 2}
                                    value={2}
                                >
                                    <Fragment>
                                        Metoda wyznaczania granicznej wysokości strefy ściskanej przedstawiana głównie w polskich podręcznikach do projektowania oparta na założeniu, że w zbrojeniu rozciąganym osiągana jest granica plastyczności stali. 
                                        <br></br>
                                        &#958;<sub>u</sub> = E<sub>s</sub>&#x3B5;<sub>cu2</sub>/(E<sub>s</sub>&#x3B5;<sub>cu2</sub> + f<sub>yd</sub>)
                                        <br></br>
                                        Taki sposób wyznaczania &#958;<sub>u</sub> może prowadzić do zawyżenia nośności przekroju poprzez dopuszczenie przypadków w których beton ulegnie kruchemu zniszczeniu.
                                    </Fragment>
                                </RadioInput>
                            </Col>
                            {
                                state.compressionZone.value === 1 &&
                                <Col xs={6} md={3} className={styles.marginBottom}>
                                    <SelectField
                                        title={<Fragment>Wsp. redystrybucji - &#948;</Fragment>}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={state.defaultDelta.value}
                                        isValid={state.defaultDelta.isValid}
                                        options={[1,0.9,0.8,0.7]}
                                        name="defaultDelta"
                                        required
                                    />
                                </Col>
                            }
                            <Col xs={6} md={state.compressionZone.value === 1 ? 3 : 6} className={styles.marginBottom}>
                                <InputField
                                    onChange={onChange}
                                    onKeyDown={onKeyDown}
                                    onBlur={onBlur}
                                    value={state.compressionZoneValue.value}
                                    isValid={state.compressionZoneValue.isValid}
                                    type="number"
                                    name={"compressionZoneValue"}
                                    placeholder={"Graniczna wys. strefy ściskanej"}
                                    title={<Fragment>Graniczna wys. strefy ściskanej - &#958;<sub>u</sub></Fragment>}
                                    disable={true}
                                    required
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={styles.row1}>
                    <Col xs={12} sm={6} className={styles.margin}>
                        <Row>
                            <Col xs={12}>
                                <Subtitle title="Zbrojenie minimalne">
                                    Powierzchnia zbrojenia minimalnego zależy od typu elementu: <br></br>
                                    - Elementy zginane (belka) - kruche zniszczenie betonu<br></br>
                                    <b>A<sub>s,min</sub> = 0.26 (f<sub>ctm</sub>/f<sub>yk</sub>)b<sub>t</sub>d</b> lecz nie mniej niż 0.0013b<sub>t</sub>d<br></br>
                                    - Elementy ściskane (słup)<br></br>
                                    <b>A<sub>s,min</sub> = 0.10 N<sub>Ed</sub>/f<sub>yd</sub></b> lecz nie mniej niż 0.002 A<sub>c</sub><br></br>
                                    - Elementy rozciągane<br></br>
                                    <b>A<sub>s,min</sub> = 0.002 A<sub>c</sub></b><br></br>
                                    - Zbrojenie na ścinanie<br></br>
                                    <b>A<sub>sw,min</sub> = 0.08 s<sub>l</sub>b<sub>w</sub>sin&#945;(f<sub>ck</sub><sup>0.5</sup>/f<sub>yk</sub>)</b><br></br>
                                    Powierzchnia zbrojenia maksymalnego wynosi: <br></br>
                                    <b>A<sub>s,max</sub> = 0.04 A<sub>c</sub></b><br></br>
                                    <br></br>
                                    Opcja "Brak" oznacza pominięcie warunku minimalnego zbrojenia elementu, co może skutkować koniecznością analizy przekroju jako elementu niezbrojonego.<br></br>
                                    UWAGA:<br></br>
                                    W stopach oraz ławach fundamentowych nie ma konieczności uwzględniania A<sub>s,min</sub> ze względu na kruche zniszczenie betonu, lecz należy uwzględniać zbrojenie ze względu na SGN (ULS).
                                </Subtitle>                                
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} className={`${styles.flexEnd} ${styles.flexColumn}`}>
                                <RadioInput
                                    onChange={onChange}
                                    name="minimumReinforcement"
                                    label={'Uwzględnij'}
                                    checked={state.minimumReinforcement.value === 1}
                                    value={1}
                                />
                            </Col>
                            <Col xs={6} className={`${styles.flexEnd} ${styles.flexColumn}`}>
                                <RadioInput
                                    onChange={onChange}
                                    name="minimumReinforcement"
                                    label={'Pomiń'}
                                    checked={state.minimumReinforcement.value === 2}
                                    value={2}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={6} className={styles.margin}>
                        <Row>
                            {
                                state.minimumReinforcement.value === 1 &&
                                <Col xs={6} className={`${styles.flexEnd} ${styles.flexColumn}`}>
                                    <Subtitle title="Zbrojenie minimalne na rysy">
                                        <Fragment>
                                            Zbrojenie uwzględniane jest jedynie w strefie rozciąganej przekroju: <br></br>
                                            <p className={styles.p}>    
                                                A<sub>s,min</sub>=k<sub>c</sub><span>&#183;</span>k<span>&#183;</span>f<sub>ct,eff</sub>
                                                <span>&#183;</span>A<sub>ct</sub>/&#963;<sub>s</sub>                                                
                                            </p>
                                            <div>
                                                <p>k<sub>c</sub> - współczynnik zależny od rozkładu napreżeń w przekroju przed zarysowaniem oraz od ramienia sił wewnętrznych</p><br></br>
                                                <p>k - współczynnik zależny od wpływu samorównoważących się naprężeń w przekroju</p><br></br>
                                                <p>f<sub>ct,eff</sub> - efektywna wytrzymałość betonu na rozciąganie</p><br></br>
                                                <p>A<sub>ct</sub> - pole przekroju strefy rozciąganej betonu przed zarysowaniem</p><br></br>
                                                <p>&#963;<sub>s</sub> - maksymalne naprężenie rozciągające w zbrojeniu</p>
                                            </div>
                                        </Fragment>
                                    </Subtitle>
                                    <Checkbox
                                        onChange={onChange}
                                        name="minimumReinforcementCrack"
                                        label={'Uwzględnij'}
                                        value={state.minimumReinforcementCrack.value}
                                    />
                                </Col>
                            }
                            {
                                state.minimumReinforcement.value === 1 && state.minimumReinforcementCrack.value &&
                                <Col xs={6} className={`${styles.flexEnd} ${styles.flexColumn}`}>
                                    <Subtitle title="Maksymalna szerokość rysy [mm]"/>
                                    <InputField
                                        onChange={onChange}
                                        onKeyDown={onKeyDown}
                                        onBlur={onBlur}
                                        value={state.maxCrack.value}
                                        isValid={state.maxCrack.isValid}
                                        type="number"
                                        title=""
                                        name="maxCrack"
                                        disable={true}
                                        required
                                    />
                                </Col>
                            }
                        </Row>
                    </Col>
                </Row>                
                <Row className={styles.row1}>
                    <Col xs={12} sm={6}>
                        <Checkbox
                            onChange={onChange}
                            title="Uwzględnij model ze wzmocnieniem liniowym"
                            name="hardeningModel"
                            label={`${state.hardeningModel.value ? 'Tak' : 'Nie'}`}
                            value={state.hardeningModel.value}
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <InputField
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.euk.value}
                            isValid={state.euk.isValid}
                            type="number"
                            infoCss={styles.info}
                            name={"euk"}
                            placeholder={"Odkształcenie stali zbrojenowej"}
                            title={"Graniczne odkształcenie stali zbrojeniowej [%]"}
                            disable={true}
                            required
                        >
                            <Fragment>
                                Charakterystyczne graniczne odkształcenie stali zbrojeniowej &#949;<sub>uk</sub> zależy od klasy ciągliwości stali i wynosi odpowiednio:<br></br>
                                <p>Dla klasy A - 2,5%</p><br></br>
                                <p>Dla klasy B - 5,0%</p><br></br>
                                <p>Dla klasy C - 7,5%</p><br></br>

                                <p>Wartość obliczeniowa wynosi: &#949;<sub>ud</sub> = 0.9*&#949;<sub>uk</sub></p><br></br>
                                <p>*Jeżeli nie wybrano żadnej nazwy gatunku stali zbrojeniowej do obliczeń przyjęta zostanie wartość &#949;<sub>ud</sub>=1,0%</p>
                            </Fragment>
                        </InputField>
                    </Col>

                </Row>
                <Row className={styles.row}>
                    <Row>
                        <Col xs={6}>
                            <Subtitle title="Długość zakotwienia pręta">
                                <Fragment>
                                    Wyznaczana jest długość zakotwienia pręta wg punktu 8.4 normy PN-EN 1992-1-1 (Eurokod 2). W przypadku gdy cały przekrój jest ściskany lub rozciągany długość zakotwienia podawana jest jako większa ze zbrojenia A<sub>s1</sub> i A<sub>s2</sub>.
                                </Fragment>
                            </Subtitle>
                        </Col>                        
                    </Row>
                    <Col xs={6} sm={3} className={`${styles.flexEnd} ${styles.flexColumn}`}>
                        <Subtitle title="Oblicz długość zakotwienia pręta"/>
                        <Checkbox
                            onChange={onChange}
                            name="lbdCalculation"
                            label={state.lbdCalculation.value ? 'Tak' : 'Nie'}
                            value={state.lbdCalculation.value}
                        />
                    </Col>
                    {
                        state.lbdCalculation.value &&
                        <Col xs={6} sm={3} className={`${styles.flexEnd} ${styles.flexColumn}`}>
                            <Subtitle title="Przyczepność do betonu"/>
                            <Checkbox
                                onChange={onChange}
                                name="concreteCondition"
                                label={state.concreteCondition.value ? 'Dobra': 'Zła'}
                                value={state.concreteCondition.value}
                            />
                        </Col>
                    }
                    {
                        state.lbdCalculation.value &&
                        <Col xs={6} sm={3} className={`${styles.flexEnd} ${styles.flexColumn}`}>
                            <Subtitle title="Rodzaj zakotwienia"/>
                            <Checkbox
                                onChange={onChange}
                                name="rebarShape"
                                label={state.rebarShape.value ? 'Proste': 'Inne niż proste'}
                                value={state.rebarShape.value}
                            />
                        </Col>
                    }
                </Row>
            </Modal>
        </Fragment>
    );
};

export default SaftyFactors;