import { roundNumber } from "../../../../../utils";
import styles from './index.module.css';

const CapacityResults = ({ 
    currentCapacity,
    yt,
    pileTypeNumber
 }) => { 

    return (
        <div className={styles.wrapper}>
            <h2>Wyniki</h2>
            <p>Nośność obliczeniowa podstawy [R<sub>b,d</sub>]: <span className={styles.value}>{
                pileTypeNumber === 4 || pileTypeNumber === 5
                    ?
                    roundNumber(currentCapacity.Rbk_current_01/yt,2).toFixed(2)
                    :
                    roundNumber(currentCapacity.Rbk_current_01D/yt,2).toFixed(2)
            } kN</span></p>
            <p>Nośność obliczeniowa pobocznicy [R<sub>s,d</sub>]: <span className={styles.value}>{
                pileTypeNumber === 4 || pileTypeNumber === 5
                ?
                roundNumber(currentCapacity.Rsk_current_ssg/yt,2).toFixed(2)
                :
                roundNumber(currentCapacity.Rsk_current_01D/yt,2).toFixed(2)
            }kN</span></p>
            <p><b>Nośność obliczeniowa pala na wciskanie [R<sub>c,d</sub>]: <span className={styles.value}>{roundNumber(currentCapacity.Rcd_current,2).toFixed(2)}kN</span></b></p>
            <p>Minimalna nośność obliczeniowa pala na wciskanie [R<sub>c,d,min</sub>]: <span className={styles.value}>{roundNumber(currentCapacity.Rcd_lower,2).toFixed(2)}kN</span></p>
            <p>Maksymalna nośność obliczeniowa pala na wciskanie [R<sub>c,d,max</sub>]: <span className={styles.value}>{roundNumber(currentCapacity.Rcd_upper,2).toFixed(2)}kN</span></p>
            <p><b>Nośność obliczeniowa pala na wyciąganie [R<sub>t,d</sub>]: <span className={styles.value}>{roundNumber(currentCapacity.Rtd_current,2).toFixed(2)}kN</span></b></p>
            <p>Minimalna nośność obliczeniowa pala na wyciąganie [R<sub>t,d,min</sub>]: <span className={styles.value}>{roundNumber(currentCapacity.Rtd_lower,2).toFixed(2)}kN</span></p>
            <p>Maksymalna nośność obliczeniowa pala na wyciąganie [R<sub>t,d,max</sub>]: <span className={styles.value}>{roundNumber(currentCapacity.Rtd_upper,2).toFixed(2)}kN</span></p>
            <p>Siła do badania próbnego pala [R<sub>c,lt</sub>]: <span className={styles.value}>{roundNumber(currentCapacity.Rcd_current*1.5,2).toFixed(2)}kN</span></p>
            <p>Charakterystyczne obciążenie głowicy pala [P]: <span className={styles.value}>{roundNumber(currentCapacity.f,2).toFixed(2)}kN</span></p>
            <p><b>Osiadanie głowicy pala [s]: <span className={styles.value}>{roundNumber(currentCapacity.s*10,2).toFixed(2)}mm</span></b></p>
        </div>
    )
}

export default CapacityResults