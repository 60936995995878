import { Table } from "react-bootstrap"
import round from '../../../../../utils/roundNumber';
import styles from './index.module.css';

const PnCapacityDetails = ({ currentLength, pileHeadSpot, parameters=[] }) => {

    return (
        <div className={styles.wrapper}>
            <div className={styles.margins}>
                <h2>Nośność pala na wciskanie zgodnie z PN-83/B-2482 - pal pojedynczy</h2>                 
            </div>
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th>Nr<br></br>[-]</th>
                        <th>Z<br></br>[m.p.p.t.]</th>
                        <th>N<sub>p</sub><br></br>[kN]</th>
                        <th>N<sub>s1</sub><br></br>[kN]</th>
                        <th>N<sub>s</sub>=&Sigma;N<sub>s1</sub><br></br>[kN]</th>
                        <th>N<sub>w1</sub><br></br>[kN]</th>
                        <th>N<sub>w</sub>=&Sigma;N<sub>w1</sub><br></br>[kN]</th>
                        <th>T<sub>n1</sub><br></br>[kN]</th>
                        <th>T<sub>n</sub>=&Sigma;T<sub>n1</sub><br></br>[kN]</th>
                        <th>m<br></br>[-]</th>
                        <th>m &#215; N<sub>t</sub><br></br>[kN]</th>
                        <th>m &#215; N<sub>tw</sub><br></br>[kN]</th>
                    </tr>
                </thead>
                <tbody>
                    {parameters.map((element, index) => {
                        return (
                            <tr key={index} style={{ backgroundColor: round(pileHeadSpot + currentLength,2) === round(element.z,2) ? '#ddd' : 'transparent' }}>
                                <td>{index+1}</td>
                                <td>{round(element.z,2)}</td>
                                <td>{round(element.np,2)}</td>
                                <td>{round(element.ns1,2)}</td>
                                <td>{round(element.ns,2)}</td>
                                <td>{round(element.nw1,2)}</td>
                                <td>{round(element.nw,2)}</td>
                                <td>{round(element.tn1,2)}</td>
                                <td>{round(element.tn,2)}</td>
                                <td>{round(element.capacityParameters.m,2)}</td>
                                <td>{round(element.nt,2)}</td>
                                <td>{round(element.ntw,2)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <p className={styles.underline}>Oznaczenia:</p>
            <p>Z - <span className={styles.value}>rzędna zagłębienia pala poniżej poziomu terenu</span></p>
            <p>N<sub>p</sub> - <span className={styles.value}>nośność podstawy na rzędnej Z</span></p>
            <p>N<sub>s1</sub> - <span className={styles.value}>przyrost nośności pobocznicy w danej warstwie</span></p>
            <p>N<sub>s</sub> - <span className={styles.value}>nośność pobocznicy na rzędnej Z</span></p>
            <p>N<sub>w1</sub> - <span className={styles.value}>przyrost nośności na wyciąganie w danej warstwie</span></p>
            <p>N<sub>w</sub> - <span className={styles.value}>nośność na wyciąganie na rzędnej Z</span></p>
            <p>T<sub>n1</sub> - <span className={styles.value}>przyrost tarcia negatywnego w danej warstwie</span></p>
            <p>T<sub>n</sub> - <span className={styles.value}>tarcie negatywne na rzędnej Z</span></p>
            <p>m - <span className={styles.value}>współczynnik modelu zależny od liczby pali podpierających fundament</span></p>
        </div>
    )
}

export default PnCapacityDetails