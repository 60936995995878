import React, { Fragment, useContext } from 'react';
import { MainAlertAndLoading } from '../../../../components';
import { verticalCapacityContext } from '../../../../context/VerticalCapacity';
import ResultsWrapper from './ResultsWrapper';

const Results = ({ openSidebar }) => {
    const {
        results: {
            state: {
                currentPileLength,
                results = [],
                soilProfile = [],
                singlePile,
                pileParameters: {
                    pileType,
                    pileTechnology,
                    pileLength,
                    pileHeadSpot, 
                    pileShape,
                    diameterPile,
                } = {},
                groundLevel
            },
            isResultsActive,
            onChangeCurrentPileLength,
            onChangeCurrentCapacity,
        },
        globalAlerts={},
        globalLoading={},
    } = useContext(verticalCapacityContext);

    return (
        <Fragment>
            <ResultsWrapper
                isResultsActive={isResultsActive}
                data={{
                    groundLevel,
                    singlePile,
                    soilProfile,
                    results: results.map((ele) =>
                        ele.nt > 0 ? ele : { ...ele, nt: 0 },
                    ).map(ele => ele.ntec7d > 0 ? ele : { ...ele, ntec7d: 0, ntec7: 0, ntec7lt: 0 })
                    ,
                    pileLength,
                    pileHeadSpot,
                    pileShape,
                    diameterPile,
                    currentPileLength,
                    pileType,
                    pileTechnology,
                    onChangeCurrentPileLength,
                    onChangeCurrentCapacity,

                    openSidebar,
                }}
            />
            <MainAlertAndLoading
                alerts={globalAlerts.state.alerts}
                updateAlerts={globalAlerts.updateState}
                loadings={globalLoading.state}
            />
        </Fragment>
    );
};

export default Results;
