import React, { createContext, useState, useEffect } from 'react';

const themeContext = createContext({});

const ThemeProvider = ({ children }) => {

    const [mode, setMode] = useState(localStorage.getItem('mode'));

    useEffect(() => {
        if(!mode){
            localStorage.setItem('mode', 'light')
        } 
    },[mode]);

    const lightTheme = { 
        background: '#f1f1f1',
        shadowRowBackground: '#f5f5f5',
        color: '#343434',
        titleColor: '#333',
        elementsBackground: '#fff',
        border: '1px solid #ddd',
        placeholder: '#d3d3d3',
        
        activeInputBorder: '#c3c3c3',
        disabledInputColor: '#9d9d9d',
        disabledInputBackground: '#dfdfdf',

        chart: {
            color: '#000',
            background: '#fff',
        },  

        table: {
            exportButton:{
                background: '#282e38',
                hoverBackground: '#3b4452',
                border: '1px solid #3b4452',
                borderRadius: '5px',
                color: '#eee',
            },
            borderColor: '#ddd',
            elementsBackground: '#f9f9f9',
        },
        defaultButton: {
            border: 'none',
            color: '#fff',
            hoverBackground: '#172b4d',
            background: '#172b4d',

            info: {
                border: '1px solid #01b169',
                color: '#01b169',
                hoverBackground: '#f1f1f1',
                background: '#fff',
            },
            danger: {
                border: '1px solid #ff0000;',
                color: '#ff0000;',
                hoverBackground: '#f1f1f1',
                background: '#fff',
            }
        },
        checkbox: {
            openBackground: '#fff',
            closeBackground: '#bbb',
            openIcon: '#bbb',
            closeIcon: '#fff',
            switchBorder: '1px solid #ddd',

            borderColor: '#bbb',
        },
        sidebar: {
            background: '#282e38',
            color: '#f3f3f3',
            iconColor: '#eee',
            activeColor: '#71b6f9',   
            labelColor: '#ddd',

            avatarBorder: '#ddd',

            toggleButtonColorOpen: '#f3f3f3',
            toggleButtonColorHide: '#343a40'
        },
        stats: {
            background: '#fff',
            iconBackround1: 'linear-gradient(310deg, rgb(17, 113, 239), rgb(17, 205, 239))',
            iconBackround2: 'linear-gradient(310deg, rgb(245, 54, 92), rgb(245, 96, 54))',
            iconBackround3: 'linear-gradient(310deg, rgb(45, 206, 137), rgb(45, 206, 204))',
            iconBackround4: 'linear-gradient(310deg, rgb(251, 99, 64), rgb(251, 177, 64))',
            color: '#fff',
        },
        loadingColor: '#fff',
        oppositeLoadingColor: '#333'
    }

    const darkTheme = {
        background: '#282e38',
        shadowRowBackground: '#282e38',
        color: '#bbb',
        titleColor: '#f3f3f3',
        elementsBackground: '#323a46',
        border: '1px solid #3b4452',
        placeholder: '#999',

        activeInputBorder: '#7d7d7d',
        disabledInputColor: '#333',
        disabledInputBackground: '#999',

        chart: {
            color: '#000',
            background: '#323a46'
        }, 
        
        table: {
            exportButton:{
                background: '#eee',
                hoverBackground: '#ddd',
                border: '1px solid #3b4452',
                borderRadius: '5px',
                color: '#282e38',
            },
            borderColor: '#3b4452',
            elementsBackground: '#282e38',
        },
        defaultButton: {
            border: '1px solid #3b4452',
            color: '#bbb',
            hoverBackground: '#282e38',
            background: '#323a46',

            info: {
                border: '1px solid #01b169',
                color: '#01b169',
                hoverBackground: '#282e38',
                background: '#323a46',
            },
            danger: {
                border: '1px solid #ff0000;',
                color: '#ff0000;',
                hoverBackground: '#282e38',
                background: '#323a46',
            }
        },
        checkbox: {
            openBackground: '#3b4452',
            closeBackground: '#fff',
            openIcon: '#999',
            closeIcon: '#3b4452',
            switchBorder: '1px solid #282e38',

            borderColor: '#282e38',
        },
        sidebar: {
            background: '#fdfdfd',
            color: '#000',
            iconColor: '#333',
            activeColor: '#71b6f9',   
            labelColor: '#444',

            toggleButtonColorOpen: '#000',
            toggleButtonColorHide: '#fff',

            avatarBorder: '#555'
        },
        stats: {
            background: '#323a46',
            iconBackround1: '#282e38',
            iconBackround2: '#282e38',
            iconBackround3: '#282e38',
            iconBackround4: '#282e38',
            color: '#bbb',
        },
        loadingColor: '#fff',
        oppositeLoadingColor: '#ddd'
    }
    const toggleMode = () => {
        const currentMode = localStorage.getItem('mode');
        if(currentMode === 'light' || !currentMode){
            setMode('dark');
            localStorage.setItem('mode', 'dark');
        } else {
            setMode('light');
            localStorage.setItem('mode', 'light')
        }
    };
    const modeTheme = (mode === 'light' || !mode) ? lightTheme : darkTheme;

    return (
        <themeContext.Provider value={{ modeTheme, toggleMode, mode }}>
            {children}
        </themeContext.Provider>
    )
}

export { themeContext }

export default ThemeProvider