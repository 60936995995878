import React, { Fragment, useContext } from 'react';
import { MainAlertAndLoading } from '../../../../components';
import { micropilesContext } from '../../../../context/Micropiles';

import ResultsWrapper from './ResultsWrapper';

const Results = ({ openSidebar }) => {
    const { 
        results,
        globalAlerts,
        globalLoading
    } = useContext(micropilesContext);

    return (
        <Fragment>
            <ResultsWrapper
                isResultsActive={results.isResultsActive}
                isResultsActual={results.state.isResultsActual}
                openSidebar={openSidebar}
                data={{
                    results: results.state,
                    onChangeRodModule: results.onChangeRodModule,
                }}
            />
            <MainAlertAndLoading
                alerts={globalAlerts.state.alerts}
                updateAlerts={globalAlerts.updateState}
                loadings={globalLoading.state}
            />
        </Fragment>
    )
}

export default Results