import { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Table, Pagination, SectionTitle } from '../../../components';
import { withPagination } from '../../../hoc';
import isString from '../../../utils/isString';
import round from '../../../utils/roundNumber';
import styles from './index.module.css';

const MicropilesDeepPlateSummary = ({
    dataToPaginate = [],
    pagination,
    paginationHelpers = {},
}) => {
    return (
        <Fragment>
            {dataToPaginate.length > 0 && (
                <Row className={styles.row}>
                    <Col xs={12}>
                        <SectionTitle
                            title={`Wyniki mikropali kotwiących z uwzględnieniem głębokiej powierzchni poślizgu`}
                        />
                        <Table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>
                                        Nr<br></br>[-]
                                    </th>
                                    <th className={styles.min120} title="Nazwa pliku">
                                        Nazwa pliku<br></br>
                                    </th>
                                    <th className={styles.min120} title="Profil gruntowy">
                                        Nazwa profilu<br></br>
                                    </th>
                                    <th title="Producent żerdzi">
                                        Producent<br></br>
                                    </th>
                                    <th title="Typ żerdzi">
                                        Typ<br></br>
                                    </th>
                                    <th title="Średnica wiercenia">
                                        d<br></br>[mm]
                                    </th>
                                    <th title="Kąt nachylenia">
                                        &#945;<br></br>[&#176;]
                                    </th>
                                    <th title="Siła osiowa">
                                        F<br></br>[kN]
                                    </th>
                                    <th title="Długość buławy">
                                        L<sub>b</sub>
                                        <br></br>[m]
                                    </th>
                                    <th title="Długość całkowita">
                                        L<sub>tot</sub>
                                        <br></br>[m]
                                    </th>

                                    <th title="Nośność żerdzi">
                                        F<sub>rod</sub>
                                        <br></br>[kN]
                                    </th>
                                    <th title="Długość buławy dla nośności żerdzi">
                                        L<sub>b,rod</sub>
                                        <br></br>[m]
                                    </th>
                                    <th title="Długość całkowita dla nośności żerdzi">
                                        L<sub>tot,rod</sub>
                                        <br></br>[m]
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataToPaginate.map((element, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {paginationHelpers.countingItemsPerPage +
                                                    index +
                                                    1}
                                            </td>
                                            <td>{element.projectName}</td>
                                            <td>
                                                {isString(element.profileName)
                                                    ? element.profileName
                                                    : '-'}
                                            </td>
                                            <td>
                                                {isString(
                                                    element.results.results
                                                        .currentCapacity
                                                        .deepPlate.prod,
                                                )
                                                    ? element.results.results
                                                          .currentCapacity
                                                          .deepPlate.prod
                                                    : '-'}
                                            </td>
                                            <td>
                                                {isString(
                                                    element.results.results
                                                        .currentCapacity
                                                        .deepPlate.name,
                                                )
                                                    ? element.results.results
                                                          .currentCapacity
                                                          .deepPlate.name
                                                    : '-'}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results.results
                                                        .currentCapacity
                                                        .deepPlate.diameter,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results.results
                                                        .currentCapacity
                                                        .deepPlate
                                                        .micropileAngle,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results.results
                                                        .currentCapacity
                                                        .deepPlate.axisLoad,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results.results
                                                        .currentCapacity
                                                        .deepPlate.bondLength,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results.results
                                                        .currentCapacity
                                                        .deepPlate.totalLength,
                                                    2,
                                                )}
                                            </td>

                                            <td>
                                                {element.results.results
                                                    .currentCapacity.deepPlate
                                                    .steelRod
                                                    ? round(
                                                          element.results
                                                              .results
                                                              .currentCapacity
                                                              .deepPlate
                                                              .steelRod
                                                              .axisLoad,
                                                          2,
                                                      )
                                                    : '-'}
                                            </td>
                                            <td>
                                                {element.results.results
                                                    .currentCapacity.deepPlate
                                                    .steelRod
                                                    ? round(
                                                          element.results
                                                              .results
                                                              .currentCapacity
                                                              .deepPlate
                                                              .steelRod
                                                              .bondLength,
                                                          2,
                                                      )
                                                    : '-'}
                                            </td>
                                            <td>
                                                {element.results.results
                                                    .currentCapacity.deepPlate
                                                    .steelRod
                                                    ? round(
                                                          element.results
                                                              .results
                                                              .currentCapacity
                                                              .deepPlate
                                                              .steelRod
                                                              .totalLength,
                                                          2,
                                                      )
                                                    : '-'}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        {paginationHelpers.totalPages > 1 && (
                            <Pagination
                                currentPage={pagination.currentPage}
                                itemsPerPage={pagination.itemsPerPage}
                                totalItems={paginationHelpers.allItemsLength}
                                nextPage={paginationHelpers.nextPage}
                            />
                        )}
                    </Col>
                </Row>
            )}
        </Fragment>
    );
};

export default withPagination({
    component: MicropilesDeepPlateSummary,
    returnAllCollection: false,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 15,
    },
});
