import React, { useMemo, useState } from 'react';
import { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Loading, Modal, ConfirmModal, SelectField } from '../../../components';
import styles from './index.module.css';
import ProjectsList from './ProjectsList';
import stringify from 'fast-json-stable-stringify';

const EditProject = ({
    open,
    loading,
    onHide,
    onRemoveProject,
    onLoadProject,
    state = {},
    isUserRole,
    id,
    onChange,
}) => {
    const [openConfirmModal, setOpenConfirmModal] = useState({});
    const [projectName, setProjectName] = useState('');
    const [projectId, setProjectId] = useState('');

    const currentUserProjectsStringify = stringify(state.currentUserProjects);
    const projectsStringify = stringify(state.projects);
    const calculationsStringify = stringify(state.calculations);

    const currentUserProjects = useMemo(() => {
        const currentUserProjectsParsed = JSON.parse(currentUserProjectsStringify);

        return currentUserProjectsParsed;
    }, [currentUserProjectsStringify]);

    const userProjects = useMemo(() => {
        const projectsParsed = JSON.parse(projectsStringify);

        return projectsParsed.filter((ele) => ele.email !== state.userEmail);
    }, [projectsStringify, state.userEmail]);

    const calculations = useMemo(() => {
        const calculationsParsed = JSON.parse(calculationsStringify);

        return calculationsParsed;
    }, [calculationsStringify]);

    const onOpenProjectModal = (e) => {
        const target = e.currentTarget;
        const dataProject = target.getAttribute('data-project');
        const dataId = target.getAttribute('data-id');
        if (target) {
            setProjectName(dataProject);
            setProjectId(dataId);
            setOpenConfirmModal((state) => ({ ...state, [target.name]: true }));
        }
    };
    const onDeleteProject = () => {
        setOpenConfirmModal({});
        onRemoveProject(projectId);
    };

    const onFetchProject = () => {
        setOpenConfirmModal({});
        onLoadProject(projectId);
    };

    const onHideConfirmModal = () => setOpenConfirmModal({});

    return (
        <Fragment>
            <Modal
                open={open}
                onHide={onHide}
                css={styles.modal}
                cssDialog={styles.modalDialog}
                title="Wczytaj obliczenia">
                {loading.fetchAllProjects ? (
                    <Loading oppositeColors={true} />
                ) : (
                    <Fragment>
                        <Row className={styles.row}>
                            {Array.isArray(userProjects) &&
                                userProjects.length > 0 && (
                                    <Col xs={6}>
                                        <SelectField
                                            title="Wybierz użytkownika"
                                            onChange={onChange}
                                            value={state.currentUser}
                                            defaultOption="Moje projekty"
                                            disabledDefault={false}
                                            options={userProjects.map(
                                                (ele) =>
                                                    `${ele.firstName} ${ele.lastName} (${ele.email})`,
                                            )}
                                            name="currentUser"
                                        />
                                    </Col>
                                )}

                            <Col xs={6}>
                                <SelectField
                                    title="Wybierz projekt"
                                    onChange={onChange}
                                    value={state.mainProjectName.value}
                                    defaultOption="Wybierz projekt"
                                    options={currentUserProjects.map(
                                        (ele) => ele.name,
                                    )}
                                    name="mainProjectName"
                                />
                            </Col>
                        </Row>
                        {state.currentUserProjects.length === 0 ? (
                            <div className={styles.center}>
                                <p className={styles.p}>
                                    Brak zapisanych projektów
                                </p>
                            </div>
                        ) : (
                            <ProjectsList
                                isUserRole={isUserRole}
                                id={id}
                                currentProjectId={state.currentProjectId}
                                dataToPaginate={calculations.sort(
                                    (a, b) =>
                                        new Date(b.updatedAt) -
                                        new Date(a.updatedAt),
                                )}
                                onOpenProjectModal={onOpenProjectModal}
                            />
                        )}

                        <ConfirmModal
                            open={openConfirmModal.openProject}
                            onHide={onHideConfirmModal}
                            onConfirm={onFetchProject}
                            title="Otwórz projekt">
                            <p className={styles.p}>
                                Na pewno chcesz otworzyć projekt -{' '}
                                <b>{projectName}</b>? <br></br>Niezapisane dane
                                istniejącego projektu zostaną utracone.
                            </p>
                        </ConfirmModal>
                        <ConfirmModal
                            open={openConfirmModal.deleteProject}
                            onHide={onHideConfirmModal}
                            onConfirm={onDeleteProject}
                            title="Usuń projekt">
                            <p className={styles.p}>
                                Jesteś pewny, że chcesz usunąć projekt -{' '}
                                <b>{projectName}</b>?
                            </p>
                        </ConfirmModal>
                    </Fragment>
                )}
            </Modal>
        </Fragment>
    );
};

export default EditProject;
