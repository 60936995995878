import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, InputField, SectionTitle, Modal, Checkbox } from '../../../../components';
import styles from './index.module.css';
import stringify from 'fast-json-stable-stringify';

const SaftyFactors = ({ state, onChange, onBlur, onKeyDown }) => {

    const [open, setOpen] = useState({}); 

    const onOpen = (e) => {
        const target = e.currentTarget;  
        if(target){
            setOpen(state => ({ ...state, [target.name]: true }))
        } 
    }; 
    const onHide = () => setOpen({});

    return(
        <Fragment>    
            <div className={styles.wrapper}>
                <SectionTitle title="Ustawienia i współczynniki technologiczne" />
                <Row className={styles.row}>
                    <Col xs={12}>
                        <Button 
                            onClick={onOpen}
                            icon={faFolderOpen}
                            css={`${styles.button} default-button-style`}
                            name="technologyFactors"
                            altTitle="Parametry obliczeń"
                            title="Parametry obliczeń"
                        />
                    </Col>
                </Row>
            </div>
            <Modal open={open.technologyFactors} css={styles.modalOverflow} cssDialog={styles.modal} onHide={onHide} title="Parametry obliczeń">
                <Row className={styles.row}>
                    <Col xs={12} sm={6} className={`${styles.flexEnd} ${styles.flexColumn}`}>
                        <Checkbox
                            onChange={onChange} 
                            title="Uwzględnij grunty słabe" 
                            name="softSoilCount"
                            label={state.softSoilCount.value ? 'Tak' : 'Nie'}
                            value={state.softSoilCount.value}
                        >
                            <Fragment>
                                Przy obliczaniu nośności pali uwzględnione zostają grunty niespoiste o wartości q<sub>c</sub> &#60; 7,5MPa oraz grunty spoiste o wartości c<sub>u</sub> &#60; 60kPa (100kPa dla pali wierconych i wkręcanych).
                            </Fragment>
                        </Checkbox>
                    </Col>
                    <Col xs={12} sm={6} className={`${styles.flexEnd} ${styles.flexColumn}`}>
                        <Checkbox
                            onChange={onChange} 
                            title="Uwzględnij grunty mocne" 
                            name="hardSoilCount"
                            label={state.hardSoilCount.value ? 'Tak' : 'Nie'}
                            value={state.hardSoilCount.value}
                        >
                            <Fragment>
                                Ta opcja nie jest zgodna z zaleceniami EAP, gdzie opory pod podstawą i na pobocznicy pala wyznaczane są do wartości q<sub>c</sub>=25MPa oraz c<sub>u</sub>=250kPa. Włączenie tej opcji umożliwia inetrpolację liniową wartości oporów pala dla oporów na stożku q<sub>c</sub> {'>'} 25MPa oraz c<sub>u</sub> {'>'} 250kPa, przy czym nadwyżka powyżej 25MPa dzielona jest przez współczynnik bezpieczeństwa 1,5.
                            </Fragment>
                        </Checkbox>
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12} sm={6} className={`${styles.flexEnd} ${styles.flexColumn}`}>
                        <InputField 
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.qccuInterpolation.value}
                            isValid={state.qccuInterpolation.isValid}
                            type="number"
                            title={useMemo(() => <Fragment>Zakres interpolacji granicznego oporu gruntu - q<sub>s,k</sub></Fragment>,[])}
                            placeholder="Zakres interpolacji"
                            name="qccuInterpolation"
                            disable={true}

                            required
                        >
                            Wartość 0 oznacza, że 10% wyników może być poniżej zakładanych wartości oporów na pobocznicy i pod podstawą (10% pali może mieć nośność niższą od obliczonej). Natomiast wartość 1 oznacza, że 50% wyników może być poniżej zakładanych wartości.
                        </InputField>
                    </Col>                    
                    <Col xs={12} sm={6}>
                        <InputField 
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.ygq.value}
                            isValid={state.ygq.isValid}
                            type="number"
                            title={useMemo(() => <Fragment>Współczynnik obciążeń - &#947;<sub>G,Q</sub></Fragment>,[])}
                            placeholder="Wsp. obliczniowej siły"
                            name="ygq"
                            disable={true}

                            required
                        >
                            Współczynnik przejściowy z nośności obliczeniowej na obciążenie charakterystyczne pala, dla którego wyznaczane jest osiadanie na podstawie wykresu obciążenie - osiadanie głowicy pala.
                        </InputField>
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12} sm={6}>
                        <InputField 
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.yt.value}
                            isValid={state.yt.isValid}
                            type="number"
                            title={useMemo(() => <Fragment>Częściowy współczynnik bezp. przy ściskaniu - &#947;<sub>b,s,t</sub></Fragment>,[])}
                            placeholder="Wsp. redukcyjny"
                            name="yt"
                            disable={true}

                            required
                        >
                            Częściowy współczynnik bezpieczeństwa nośności podstawy i pobocznicy lub całkowity na ściskanie pali.
                        </InputField>
                    </Col>
                    <Col xs={12} sm={6}>
                        <InputField 
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.yst.value}
                            isValid={state.yst.isValid}
                            type="number"
                            title={useMemo(() => <Fragment>Częściowy współczynnik bezp. przy wyciąganiu - &#947;<sub>s,t</sub></Fragment>,[])}
                            placeholder="Wsp. redukcyjny"
                            name="yst"
                            disable={true}

                            required
                        >
                            Częściowy współczynnik bezpieczeństwa nośności pobocznicy lub całkowity na wyciąganie pali. 
                        </InputField>
                    </Col>
                </Row>
            </Modal>
        </Fragment>
    )
}

export default React.memo(SaftyFactors, (prev, next) => stringify(prev) === stringify(next))