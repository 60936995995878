import React, { Fragment } from 'react';
import { Alert } from 'react-bootstrap';
import { withLoading } from '../../hoc';
import styles from './index.module.css';

const AlertMessage2 = ({
    success,
    open,
    message,
    children,
    variant = {},
    alerts = [],
}) => {
    return Array.isArray(alerts) && alerts.length > 0 ? (
        <Fragment>
            {alerts.map((element, index) => {
                if (element.open) {
                    return (
                        <Alert
                            key={index}
                            variant={
                                element.success
                                    ? `${variant.success || 'success'}`
                                    : `${variant.error || 'danger'}`
                            }
                            show={element.open}>
                            {element.message}
                            {children}
                        </Alert>
                    );
                } else {
                    return (
                        <Fragment></Fragment>
                    )
                }
            })}
        </Fragment>
    ) : (
        <Fragment>
            {open && (
                <Alert
                    className={styles.defaultAlertBox}
                    variant={
                        success
                            ? `${variant.success || 'success'}`
                            : `${variant.error || 'danger'}`
                    }
                    show={open}>
                    {message}
                    {children}
                </Alert>
            )}
        </Fragment>
    );
};

export default withLoading(AlertMessage2);
