import { Fragment, useEffect, useState } from 'react';
import {
    Button,
    Checkbox1,
    ConfirmModal,
    SectionTitle,
    Table,
    Pagination,
    ItemsPerPage,
} from '../../../components';
import styles from './index.module.css';
import {
    faPlus,
    faEdit,
    faKey,
    faBell,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import NewUserModal from './NewUserModal';
import EditUserModal from './EditUserModal';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import RenewSubscription from './RenewSubscription';
import { withPagination } from '../../../hoc';

const NewUsers = ({
    dataToPaginate,
    pagination,
    paginationHelpers,

    renewSubscription = {},
    state = {},
    addRow,
    removeRow,
    onBlur,
    onChange,
    onChangeEditUser,
    onFindEditUser,
    onChangeIdsCheckbox,
    onSaveEditUser,
    onResetUserPassword,
    onDeleteUser,
    onSubmit,
    onKeyDown,

    onDeleteCalculations,


    invoiceType,
    invoiceState,
    saveFormData,
    onChangeInvoiceType,
    onChangeInvoice,
    onBlurInvoice,
    onChangeDataCheckbox
}) => {
    const [open, setOpen] = useState({});
    const onOpen = (e) => {
        const target = e.currentTarget;
        setOpen({
            [target.name]: true,
        });
    };
    const onHide = () => setOpen({});

    useEffect(() => {
        return () => setOpen({});
    }, []);

    return (
        <Fragment>
            <div className={styles.flexSpace}>
                <SectionTitle title="Użytkownicy przypisani do konta" />
                <ItemsPerPage
                    onDisplayItemsNumber={paginationHelpers.displayItemsNumber}
                    itemsPerPage={pagination.itemsPerPage}
                    totalItems={paginationHelpers.allItemsLength}
                    display={[10, 25, 50, paginationHelpers.allItemsLength]}
                />
            </div>

            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th></th>
                        <th>Nr</th>
                        <th title="Imię">Imię</th>
                        <th title="Nazwisko">Nazwisko</th>
                        <th title="Adres email">Email</th>
                        <th title="Rodzaj subskrypcji">Licencja</th>
                        <th title="Data wygaśnięcia aktywnej subskrypcji">
                            Data wygaśnięcia
                        </th>
                        <th title="Status konta">Status konta</th>
                        <th title="Status subskrypcji">Status subskrypcji</th>
                        <th></th>
                    </tr>
                </thead>

                {dataToPaginate.length > 0 ? (
                    <tbody>
                        {dataToPaginate.map((element, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <Checkbox1
                                            css={styles.checkbox}
                                            onChange={() =>
                                                onChangeIdsCheckbox(element.id)
                                            }
                                            value={state.arrayOfIds.includes(
                                                element.id,
                                            )}
                                            name="selectRow"
                                        />
                                    </td>
                                    <td width={30}>{index + 1}</td>
                                    <td>{element.firstName}</td>
                                    <td>{element.lastName}</td>
                                    <td>{element.email}</td>
                                    <td>
                                        {element.subscription.plan === 1
                                            ? 'Miesięczna'
                                            : element.subscription.plan === 2
                                            ? 'Roczna'
                                            : element.subscription.plan === 3
                                            ? 'Testowa'
                                            : ''}
                                    </td>
                                    <td>
                                        {element.subscription.endDate
                                            ? moment(
                                                  element.subscription.endDate,
                                              )
                                                  .local('pl')
                                                  .format('DD.MM.YYYY, HH:mm')
                                            : '-'}
                                    </td>
                                    <td>
                                        {element.active
                                            ? 'Aktywne'
                                            : 'Nieaktywne'}
                                    </td>
                                    <td>
                                        {moment(
                                            element.subscription.endDate,
                                        ).diff(moment(), 'seconds') > 0
                                            ? 'Opłacona/Aktywna'
                                            : 'Nieaktywna'}
                                    </td>
                                    <td width={100}>
                                        <Button
                                            name="editUser"
                                            css={`
                                                ${styles.editButton} default-button-info-style
                                            `}
                                            icon={faEdit}
                                            title="Edytuj"
                                            altTitle={'Edytuj konto'}
                                            onClick={(e) => {
                                                onOpen(e);
                                                onFindEditUser(element.id);
                                            }}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                ) : (
                    <tbody>
                        <tr>
                            <td className={styles.tdNoBorder}>
                                <div className={styles.absoluteTr}>
                                    <p className={styles.noUsers}>
                                        Brak użytkowników przypisanych do konta
                                    </p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                )}
            </Table>
            <div className={styles.actionsWrapper}>
                <div
                    className={`${styles.actions} ${
                        state.arrayOfIds.length > 0 ? styles.spaceBetween : ''
                    }`}>
                    {state.arrayOfIds.length > 0 && (
                        <div>
                            <Button
                                css={styles.button}
                                onClick={onOpen}
                                name="resetUsersPassword"
                                variant="danger"
                                icon={faKey}
                                altTitle="Resetuj hasło"
                                title="Resetuj hasło"
                            />
                            <Button
                                css={styles.button}
                                onClick={onOpen}
                                name="deleteUsers"
                                variant="danger"
                                icon={faTrashAlt}
                                altTitle="Usuń konto"
                                title="Usuń konto"
                            />
                            <Button
                                css={styles.button}
                                onClick={onOpen}
                                name="renewSubscription"
                                variant="info"
                                icon={faBell}
                                altTitle="Przedłuż subskrypcję"
                                title="Przedłuż subskrypcję"
                            />
                        </div>
                    )}

                    <Button
                        css="default-button-style"
                        name="addNewUser"
                        onClick={onOpen}
                        title={'Dodaj nowe konta'}
                        icon={faPlus}
                    />
                </div>
            </div>
            {paginationHelpers.totalPages > 1 && (
                <Pagination
                    currentPage={pagination.currentPage}
                    itemsPerPage={pagination.itemsPerPage}
                    totalItems={paginationHelpers.allItemsLength}
                    nextPage={paginationHelpers.nextPage}
                />
            )}

            <RenewSubscription
                open={open.renewSubscription}
                onOpen={setOpen}
                onHide={onHide}
                onChange={renewSubscription.onChange}
                onSubmit={renewSubscription.onSubmit}
                state={renewSubscription.state}
                dataToPaginate={renewSubscription.state.subusersState}

                invoiceType={invoiceType}
                invoiceState={invoiceState}
                saveFormData={saveFormData}
                onChangeInvoiceType={onChangeInvoiceType}
                onChangeInvoice={onChangeInvoice}
                onBlurInvoice={onBlurInvoice}
                onChangeDataCheckbox={onChangeDataCheckbox}
            />

            <NewUserModal
                open={open.addNewUser}
                onHide={onHide}
                onOpen={setOpen}
                state={state.newUsers}
                currentUsers={state.currentUsersNumber}
                summaryCost={state.summaryCost}
                onChange={onChange}
                onSubmit={onSubmit}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                addRow={addRow}
                removeRow={removeRow}

                invoiceType={invoiceType}
                invoiceState={invoiceState}
                saveFormData={saveFormData}
                onChangeInvoiceType={onChangeInvoiceType}
                onChangeInvoice={onChangeInvoice}
                onBlurInvoice={onBlurInvoice}
                onChangeDataCheckbox={onChangeDataCheckbox}
            />
            <EditUserModal
                state={state.editUser}
                onChange={onChangeEditUser}
                open={open.editUser}
                onHide={onHide}
                onSubmit={onSaveEditUser}
            />
            <ConfirmModal
                open={open.resetUsersPassword}
                onHide={onHide}
                onConfirm={onResetUserPassword}
                cssDialog={styles.confirmModal}
                title="Zresetuj hasło użytkownika">
                Jesteś pewien, że chcesz zresetować{' '}
                {`${
                    state.arrayOfIds.length > 1
                        ? 'hasła zaznaczonych użytkowników'
                        : 'hasło zaznaczonego użytkownika'
                }`}
                ?
            </ConfirmModal>
            <ConfirmModal
                open={open.deleteUsers}
                onHide={onHide}
                onConfirm={onDeleteUser}
                title="Usuń konto użytkownika">
                <Fragment>
                    <p className={styles.p16}>
                        Jesteś pewien, że chcesz usunąć{' '}
                        {`${
                            state.arrayOfIds.length > 1
                                ? 'konta zaznaczonych użytkowników'
                                : 'konto zaznaczonego użytkownika'
                        }`}
                        ?
                    </p>
                    <div name="deleteCheckbox" className={styles.flexCheckbox}>
                        <Checkbox1
                            value={state.deleteCheckbox}
                            name="deleteUsersCalculations"
                            label="Usuń obliczenia"
                            css={styles.checkboxWidth}
                            onChange={onDeleteCalculations}
                        />
                        <p name="deleteCheckbox" onClick={onDeleteCalculations}>
                            Usuń projekty oraz przypisane do nich obliczenia i
                            profile gruntowe, których{' '}
                            {state.arrayOfIds.length > 1
                                ? 'autorami są wybrani użytkownicy'
                                : 'autorem jest wybrany użytkownik'}
                            .
                        </p>
                    </div>
                    <p className={styles.p16}>
                        *Jeżeli nie zaznaczysz powyższego okienka to projekty{' '}
                        {state.arrayOfIds.length > 1
                            ? 'usuwanych użytkowników'
                            : 'usuwanego użytkowinika'}{' '}
                        zostaną przypisane do Twojego konta.
                    </p>
                    <p className={styles.p16}>
                        **Jeżeli{' '}
                        {state.arrayOfIds.length > 1
                            ? 'usuwani użytkownicy są autorami'
                            : 'usuwany użytkownik jest autorem'}{' '}
                        obliczeń lub profili gruntowych w projektach, których
                        nie jest on autorem to nie zostaną one usunięte.
                    </p>
                </Fragment>
            </ConfirmModal>
        </Fragment>
    );
};

export default withPagination({
    component: NewUsers,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 10,
    },
});
