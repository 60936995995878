import { useRef } from 'react';
import styles from './index.module.css';
import useDeepCompareEffect from 'use-deep-compare-effect';

const PdfFooter = ({
    title = '',
    pdfState={},
    setFooterHeight,
    globalTitle = 'Tytuł projektu',
}) => {
    const ref = useRef();

    useDeepCompareEffect(() => {
        if (ref.current) {
            setFooterHeight(ref.current.offsetHeight);
        }
    }, [pdfState, ref, setFooterHeight]);

    return (
        <div ref={ref} className={`${styles.footer} pageFooter`}>
            <div className={styles.wrapper}>
                {globalTitle && <p>{globalTitle}</p>}
                <p>
                    <b>{title}</b>
                </p>
            </div>
        </div>
    );
};

export default PdfFooter;
