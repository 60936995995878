import { Outlet, useOutletContext } from "react-router-dom";
import AdminUsersListProvider from "../../../context/AdminUsersList";

const AdminUserListLayout = () => {
    return (
        <AdminUsersListProvider>
            <Outlet context={useOutletContext()}/>
        </AdminUsersListProvider>
    )
}

export default AdminUserListLayout;