import { useContext, useEffect } from 'react';
import { walingContext } from '../../../context/Waling';
import PrintWalingResults from './PrintResults';
import DefaultNavigation from '../DefaultNavigation';

const WalingNavigation = ({ report }) => {
    const WalingContext = useContext(walingContext);

    useEffect(() => {
        document.title = `Kleszcz stalowy - ${WalingContext.fileNameToSave.fileName.value}`;

        return () => {
            document.title = '';
        };
    }, [WalingContext.fileNameToSave.fileName.value]); 

    return (
        <DefaultNavigation 
            title="Nośność kleszcza stalowego oraz płyty oporowej"
            subtitle="Nośność kleszcza stalowego oraz płyty oporowej na podstawie Eurokodu 3 i danych do projektowania firmy Arcelor Mittal (Durability - technical manual)"
            context={WalingContext}
            navigation={false}
            geoProfiles={false}
        >
            {WalingContext.results.state.isResultsActual && (
                <PrintWalingResults
                    report={report}
                    isResultsActual={
                        WalingContext.results.state.isResultsActual
                    }
                    printResults={WalingContext.printResults}
                />
                )
            } 
        </DefaultNavigation>
    );
};

export default WalingNavigation;
