import React, { Fragment, useContext, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './index.module.css';
import Projects from './ProjectsList';
import Profiles from './ProfilesList';
import { projectsContext } from '../../context/Projects';
import { MainAlertAndLoading, SectionTitle } from '../../components';
import Calculations from './CalculationList';
import VerticalSummary from './VerticalSummary';
import KoseckiSummary from './KoseckiSummary';
import MicropilesSummary from './MicropilesSummary';
import MicropilesCompressionSummary from './MicropilesCompressionSummary';
import MicropilesDeepPlateSummary from './MicropilesDeepPlaneSummary';
import PrintSummaryResults from './PrintResults';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useOutletContext } from 'react-router-dom';
import { isUserRole } from '../../utils';
import ReinforcementSummary from './ReinforcementSummary';
import PileCptSummary from './PileCptSummary';
import SheetPileCptSummary from './SheetPileCptSummary';

const GeotechnicalProfiles = () => {
    const {
        createProject,
        createProfile,
        defaultSoilLayer,
        globalLoading,
        globalAlerts,
        calculations,
        printResults,
    } = useContext(projectsContext);

    const { report, id, permission } = useOutletContext();

    const [calculationsCollection, setCalculationsCollection] = useState([]);
    const [calculationIds, setCalculationIds] = useState([]);

    const [calculationsToReport, setCalculationsToReport] = useState([]);

    const allCalculationsWitoutProfile = createProject.state.allCalculationsWitoutProfile;
    const calculationsInProfile = createProfile.state.calculationsInProfile;

    useDeepCompareEffect(() => {
        setCalculationsCollection(() =>
            [
                ...allCalculationsWitoutProfile,
                ...calculationsInProfile
                    .map((ele) => ele.calculations)
                    .flat(1),
            ].filter(
                (ele) =>
                    ele.results.isResultsActive && ele.results.isResultsActual,
            ),
        );
    }, [allCalculationsWitoutProfile, calculationsInProfile]);

    useDeepCompareEffect(() => {
        setCalculationIds(() =>
            calculationsCollection.map((ele) => ele.id),
        );
    }, [calculationsCollection]);

    useDeepCompareEffect(() => {
        setCalculationsToReport(() =>
            calculationsCollection.filter((ele) =>
                calculationIds.includes(ele.id),
            ),
        );
    }, [calculationsCollection, calculationIds]);

    const onChangeCalculationIds = (id) => {
        setCalculationIds((state) => {
            if (state.includes(id)) {
                return state.filter((ele) => ele !== id);
            } else {
                return [...state, id];
            }
        });
    };

    return (
        <Fragment>
            <section className={styles.section}>
                <Row className={styles.row}>
                    <Col xs={12} md={6}>
                        <Projects
                            data={createProject}
                            dataToPaginate={
                                createProject.state.currentUserProjects
                            }
                            currentProject={createProject.state.currentProject}
                            loading={globalLoading}
                            isUserRole={isUserRole(permission)}
                            id={id}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <Profiles
                            data={createProfile}
                            profiles={
                                createProject.state.currentProject.profiles
                            }
                            userId={createProject.state.currentProject.userId}
                            loading={globalLoading}
                            defaultSoilLayer={defaultSoilLayer}
                            currentProject={createProject.state.currentProject}
                            currentProfileName={
                                createProfile.state.currentProfileName
                            }
                            isUserRole={isUserRole(permission)}
                            id={id}
                        />
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Calculations
                        data={calculations}
                        calculationIds={calculationIds}
                        profilesArray={createProfile.state.calculationsIdArray}
                        currentProject={createProject.state.currentProject}
                        calculationsInProfile={
                            createProfile.state.calculationsInProfile
                        }
                        allCalculationsWitoutProfile={
                            createProject.state.allCalculationsWitoutProfile
                        }
                        onChangeCalculationIds={onChangeCalculationIds}
                        isUserRole={isUserRole(permission)}
                        id={id}
                    />
                </Row>
            </section>
            <section className={styles.section}>
                {calculationsCollection.flat(1).length > 0 && (
                    <Row className={styles.row}>
                        <Col xs={12}>
                            <div className={styles.flexPrint}>
                                <SectionTitle
                                    title="Podsumowanie wyników obliczeń"
                                    classNameWrapper={
                                        styles.sectionTitleWrapper
                                    }
                                >
                                    <p>Do podsumowania wyników brane są jedynie obliczenia z aktualnymi wynikami</p>
                                </SectionTitle>
                                <PrintSummaryResults
                                    printResults={{
                                        ...printResults,
                                        state: {
                                            reportItem:
                                                printResults.state.reportItem,
                                            calculations: calculationsToReport,
                                        },
                                    }}
                                    report={report}
                                />
                            </div>
                        </Col>
                    </Row>
                )}

                <VerticalSummary
                    dataToPaginate={calculationsToReport.filter(
                        (ele) => ele.type === 1 && ele.results.currentCapacity,
                    )}
                />
                <MicropilesSummary
                    dataToPaginate={calculationsToReport.filter(
                        (ele) =>
                            ele.type === 2 &&
                            ele.results.results.currentCapacity,
                    )}
                />
                <MicropilesDeepPlateSummary
                    dataToPaginate={calculationsToReport.filter(
                        (ele) =>
                            ele.type === 2 &&
                            ele.results.results.currentCapacity &&
                            ele.results.results.currentCapacity.deepPlate,
                    )}
                />
                <MicropilesCompressionSummary
                    dataToPaginate={calculationsToReport.filter(
                        (ele) => ele.type === 3 && ele.results.currentCapacity,
                    )}
                />
                <KoseckiSummary
                    dataToPaginate={calculationsToReport.filter(
                        (ele) => ele.type === 0 && ele.results.currentCapacity,
                    )}
                />
                <ReinforcementSummary
                    dataToPaginate={calculationsToReport.filter(
                        (ele) => ele.type === 4 && Array.isArray(ele.results.calculation) && ele.results.calculation.length > 0
                    )}
                />
                <PileCptSummary
                    dataToPaginate={calculationsToReport.filter(
                        (ele) => ele.type === 5 && ele.results.results
                    )}
                />
                <SheetPileCptSummary
                    dataToPaginate={calculationsToReport.filter(
                        (ele) => ele.type === 6 && ele.results
                    )}

                />
            </section>
            <MainAlertAndLoading
                alerts={globalAlerts.state.alerts}
                updateAlerts={globalAlerts.updateState}
                loadings={globalLoading.state}
            />
        </Fragment>
    );
};

export default GeotechnicalProfiles;
