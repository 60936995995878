import React, { createContext, useContext } from 'react';
import { 
    GlobalAlertService,
    GlobalLoadingService,
    PaymentsListService
} from '../../services/Admin'; 
import { adminAuthContext } from '../Admin';
  
const paymentsContext = createContext({});

const AdminPaymentsProvider = ({ children }) => {
    const { permission } = useContext(adminAuthContext);
    const { globalAlerts } = GlobalAlertService();
    const { globalLoading } = GlobalLoadingService();
    const { payments } = PaymentsListService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,

        fetch: {
            getPayments: {
                url: '/api/admin/geoincloud/user/paymentslist',
                method: 'GET'
            },
            sendInvoiceRequest: {
                url: '/api/admin/geoincloud/user/invoice/send',
                method: 'POST'
            },
        },

        role: permission 
    });

    return ( 
        <paymentsContext.Provider   
            value={{ 
                globalAlerts,
                globalLoading,
                payments
            }}
        >
            {children}
        </paymentsContext.Provider>
    )
}

export { paymentsContext }
export default AdminPaymentsProvider