const h_single = {
    'HZ880M-A': {
        h: 831.3, //mm
        h1: 803.4, //mm
        b: 458,
        tmax: 29,
        s: 13,
        r: 30,
        ab: 296.6, //cm2        
    },
    'HZ880M-B': {
        h: 831.3, //mm
        h1: 807.4, //mm
        b: 460,
        tmax: 29,
        s: 15,
        r: 30,
        ab: 328.9, //cm2
    },
    'HZ880M-C': {
        h: 831.3, //mm
        h1: 811.4, //mm
        b: 460,
        tmax: 29,
        s: 15,
        r: 30,
        ab: 343.4, //cm2        
    },
    'HZ1080M-A': {
        h: 1075.3, //mm
        h1: 1047.4, //mm
        b: 454,
        tmax: 29,
        s: 16,
        r: 30,
        ab: 368.7, //cm2
    },
    'HZ1080M-B': {
        h: 1075.3, //mm
        h1: 1053.4, //mm
        b: 454,
        tmax: 29,
        s: 16,
        r: 30,
        ab: 391.7, //cm2
    },
    'HZ1080M-C': {
        h: 1075.3, //mm
        h1: 1059.4, //mm
        b: 456,
        tmax: 29,
        s: 18,
        r: 30,
        ab: 433.7, //cm2
    },
    'HZ1080M-D': {
        h: 1075.3, //mm
        h1: 1067.4, //mm
        b: 457,
        tmax: 30.7,
        s: 19,
        r: 30,
        ab: 467.7, //cm2
    },
    'HZ1180M-A': {
        h: 1075.4, //mm
        h1: 1075.4, //mm
        b: 458,
        tmax: 31,
        s: 20,
        r: 30,
        ab: 494.9, //cm2
    },
    'HZ1180M-B': {
        h: 1079.4, //mm
        h1: 1079.4, //mm
        b: 458,
        tmax: 33,
        s: 20,
        r: 30,
        ab: 512.1, //cm2
    },
    'HZ1180M-C': {
        h: 1083.4, //mm
        h1: 1083.4, //mm
        b: 459,
        tmax: 35,
        s: 21,
        r: 30,
        ab: 541.2, //cm2
    },
    'HZ1180M-D': {
        h: 1087.4, //mm
        h1: 1087.4, //mm
        b: 460,
        tmax: 37,
        s: 22,
        r: 30,
        ab: 568.1, //cm2
    },

    'HP 200': {
        h: 200, //mm
        h1: 200, //mm
        b: 205,
        tmax: 9,
        s: 9,
        r: 0,
        ab: 54.1, //cm2,
        m: 42.5 //kg/m
    },
    'HP 220': {
        h: 210, //mm
        h1: 210, //mm
        b: 225,
        tmax: 11,
        s: 11,
        r: 0,
        ab: 72.9, //cm2,
        m: 57.2 //kg/m
    },
    'HP 260': {
        h: 249, //mm
        h1: 249, //mm
        b: 265,
        tmax: 12,
        s: 12,
        r: 0,
        ab: 95.5, //cm2,
        m: 75 //kg/m
    },
    'HP 305': {
        h: 308, //mm
        h1: 308, //mm
        b: 311,
        tmax: 15.4,
        s: 15.3,
        r: 0,
        ab: 140, //cm2,
        m: 110 //kg/m
    },
    'HP 320': {
        h: 311, //mm
        h1: 311, //mm
        b: 308,
        tmax: 16,
        s: 16,
        r: 0,
        ab: 150, //cm2,
        m: 117 //kg/m
    },
    'HP 360': {
        h: 356, //mm
        h1: 356, //mm
        b: 376,
        tmax: 17.9,
        s: 17.8,
        r: 0,
        ab: 194, //cm2,
        m: 152 //kg/m
    },
    'HP 400': {
        h: 368, //mm
        h1: 368, //mm
        b: 400,
        tmax: 24,
        s: 24,
        r: 0,
        ab: 271, //cm2,
        m: 213 //kg/m
    },
};

export default h_single;