import React, { useEffect } from 'react';
import styled from 'styled-components';
import { withLoader } from '../../hoc';
import SetPasswordForm from './SetPasswordForm';
import config from '../../config/config';

const Container = styled.div`
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 576px) {
        max-width: 540px;
    }
    @media (min-width: 768px) {
        max-width: 720px;
    }
    @media (min-width: 992px) {
        max-width: 960px;
    }
`;

const SetPassword = ({
    fetchData: { setPasswordForm, ...rest },
    messageState = {},
    match,
}) => {

    useEffect(() => {
        document.title = `Ustaw hasło do konta`;

        return () => {
            document.title = '';
        };
    }, []);
 
    return (
        <Container>
            <SetPasswordForm
                logo={true}
                fallbackRoute={config.routes.dashboard.logIn}
                responseData={setPasswordForm.responseData}
                match={match}
                {...messageState.setPasswordForm}
                {...rest}
                renderErrorMessage
            />
        </Container>
    );
};

export default withLoader({
    component: SetPassword,
    fetch: [
        {
            name: 'setPasswordForm',
            url: '/api/auth/set-password/:id',
            errorMessage: 'Wystąpił błąd podczas ustawiania hasła',
            showErrorMessage: true,
        },
    ],
    localState: {
        loadingName: '',
    },
});

