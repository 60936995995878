import React, { Fragment, useEffect, useRef, useState } from 'react';
import { isNumber, roundNumber, setSoilColor } from '../../../../../utils';
import { SectionTitle, Button } from '../../../../../components';
import SoilLayer from './SoilLayer';
import styles from './index.module.css';
import { Col, Row } from 'react-bootstrap';
import DescriptionResults from './DescriptionResults';
import MicropileCapacityTable from '../MicropileCapacityTable';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';

const MicropileCapacityChart = ({
    soilTable = [],
    calculationParameters,
    slideLineArr,
    slipPlate = {},
    deepPlate = {},
    steelRod = {},
    intersectionPoints,
    resultsModelWidth,
    onChangeRodModule,
    rodModule,

    openSidebar
}) => {

    const soilProfileRef = useRef(null);
    const micropileRef = useRef(null);
    const [soilLayer, setSoilLayer] = useState([]);
    const [rightWaterHeight, setRightWaterHeight] = useState('');
    const [leftWaterHeight, setLeftWaterHeight] = useState('');
    const [profileWallLength, setProfileWallLength] = useState('');
    const [profileMicroPileLength, setProfileMicroPileLength] = useState({
        total: '',
        bond: '',
    });
    const [profilePileHeadSpot, setProfilePileHeadSpot] = useState('');
    const [micropileAngle, setMicropileAngle] = useState(0);
    const [slideLinePx, setSlideLinePx] = useState({});
    const [deepSlideLinePx, setDeepSlideLinePx] = useState([]);
    const [maxHeight, setMaxHeight] = useState('');
    const [maxWidth, setMaxWidth] = useState('');
    const [offsetHeight, setOffsetHeight] = useState('');
    const [offsetWidth, setOffsetWidth] = useState('');
    const [excavationBase, setExcavationBase] = useState('');
    const [axisModelScale, setAxisModelScale] = useState([]);
    const [excavationWidthPx, setExcavationWidthPx] = useState('');
    const [micropileOffset, setMicropileOffset] = useState({
        width: 0,
        height: 0,
    });
    const [currentMicropileLength, setCurrentMicropileLength] = useState({
        total: 0,
        bond: 0,
        free: 0,
        head: 0,
    });
    const [currentCapacityType, setCurrentCapacityType] = useState({
        slipPlateCapacity: false,
        deepPlateCapacity: false, 
    });
    const [currentBondLengthType, setCurrentBondLengthType] = useState({
        axisLoadCapacity: false,
        steelRodCapacity: false,
    });
    const [currentCapacity, setCurrentCapacity] = useState([]);

    const [prodLengthValues, setProdLengthValues] = useState({
        bondLength: '',
        totalLength: '',
    });
    const slideLineArrSlipPlate = slideLineArr.slipPlate;
    const slideLineArrDeepPlate = slideLineArr.deepPlate;
    const intersectionPointsDeepPlate = intersectionPoints.deepPlate;
    const intersectionPointsSlipPlate = intersectionPoints.slipPlate;

    const onChangeCapacity = (e) => {
        const { name } = e.currentTarget;
        setCurrentCapacityType(() => {
            return {
                slipPlateCapacity: false,
                deepPlateCapacity: false,
                [name]: true,
            };
        });
    };

    const onChangeBondCapacity = (e) => {
        const { name } = e.currentTarget;
        setCurrentBondLengthType(() => {
            return {
                axisLoadCapacity: false,
                steelRodCapacity: false,
                [name]: true,
            };
        });
    };

    useEffect(() => {
        if (
            isNumber(currentMicropileLength.total) &&
            isNumber(currentMicropileLength.free) &&
            isNumber(currentMicropileLength.bond) &&
            isNumber(rodModule.value)
        ) {
            const tot =
                Math.ceil(
                    (currentMicropileLength.total +
                        currentMicropileLength.head) /
                        rodModule.value,
                ) * rodModule.value;
            setProdLengthValues({
                bondLength: roundNumber(
                    tot -
                        (currentMicropileLength.free +
                            currentMicropileLength.head),
                    2,
                ),
                totalLength: tot,
            });
        }
    }, [
        rodModule.value,
        currentMicropileLength.total,
        currentMicropileLength.bond,
        currentMicropileLength.free,
        currentMicropileLength.head,
    ]);

    useEffect(() => {
        setCurrentCapacityType((state) => {
            return {
                ...state,
                slipPlateCapacity: !calculationParameters.isDeepPlate,
                deepPlateCapacity: calculationParameters.isDeepPlate,
            };
        });
        setCurrentBondLengthType((state) => {
            return {
                ...state,
                axisLoadCapacity:
                    calculationParameters.bondCapacityType === 1 ||
                    !calculationParameters.isSteelRod,
                steelRodCapacity:
                    calculationParameters.isSteelRod &&
                    calculationParameters.bondCapacityType === 2,
            };
        });
    }, [
        calculationParameters.bondCapacityType,
        calculationParameters.isSteelRod,
        calculationParameters.isDeepPlate,
    ]);

    useDeepCompareEffect(() => {
        if (currentCapacityType.deepPlateCapacity) {
            if (currentBondLengthType.steelRodCapacity) {
                setCurrentCapacity(deepPlate.steelRodCapacity);
                setCurrentMicropileLength({
                    total: roundNumber(
                        deepPlate.steelRodCapacity[1].freeLength +
                            deepPlate.steelRodCapacity[1].totalBondLength,
                        2,
                    ),
                    bond: roundNumber(
                        deepPlate.steelRodCapacity[1].totalBondLength,
                        2,
                    ),
                    free: roundNumber(
                        deepPlate.steelRodCapacity[1].freeLength,
                        2,
                    ),
                    head: roundNumber(
                        deepPlate.steelRodCapacity[1].headLength,
                        2,
                    ),
                });
            } else if (currentBondLengthType.axisLoadCapacity) {
                setCurrentCapacity(deepPlate.axisLoadCapacity);
                setCurrentMicropileLength({
                    total: roundNumber(
                        deepPlate.axisLoadCapacity[1].freeLength +
                            deepPlate.axisLoadCapacity[1].totalBondLength,
                        2,
                    ),
                    bond: roundNumber(
                        deepPlate.axisLoadCapacity[1].totalBondLength,
                        2,
                    ),
                    free: roundNumber(
                        deepPlate.axisLoadCapacity[1].freeLength,
                        2,
                    ),
                    head: roundNumber(
                        deepPlate.axisLoadCapacity[1].headLength,
                        2,
                    ),
                });
            } else {
                setCurrentCapacity([]);
                setCurrentMicropileLength({
                    total: 0,
                    bond: 0,
                    free: 0,
                    head: 0,
                });
            }
        } else if (currentCapacityType.slipPlateCapacity) {
            if (currentBondLengthType.steelRodCapacity) {
                setCurrentCapacity(slipPlate.steelRodCapacity);
                setCurrentMicropileLength({
                    total: roundNumber(
                        slipPlate.steelRodCapacity[1].freeLength +
                            slipPlate.steelRodCapacity[1].totalBondLength,
                        2,
                    ),
                    bond: roundNumber(
                        slipPlate.steelRodCapacity[1].totalBondLength,
                        2,
                    ),
                    free: roundNumber(
                        slipPlate.steelRodCapacity[1].freeLength,
                        2,
                    ),
                    head: roundNumber(
                        slipPlate.steelRodCapacity[1].headLength,
                        2,
                    ),
                });
            } else if (currentBondLengthType.axisLoadCapacity) {
                setCurrentCapacity(slipPlate.axisLoadCapacity);
                setCurrentMicropileLength({
                    total: roundNumber(
                        slipPlate.axisLoadCapacity[1].freeLength +
                            slipPlate.axisLoadCapacity[1].totalBondLength,
                        2,
                    ),
                    bond: roundNumber(
                        slipPlate.axisLoadCapacity[1].totalBondLength,
                        2,
                    ),
                    free: roundNumber(
                        slipPlate.axisLoadCapacity[1].freeLength,
                        2,
                    ),
                    head: roundNumber(
                        slipPlate.axisLoadCapacity[1].headLength,
                        2,
                    ),
                });
            } else {
                setCurrentMicropileLength({
                    total: 0,
                    bond: 0,
                    free: 0,
                    head: 0,
                });
                setCurrentCapacity([]);
            }
        } else {
            setCurrentCapacity([]);
            setCurrentMicropileLength({
                total: 0,
                bond: 0,
                free: 0,
                head: 0,
            });
        }
    }, [
        currentBondLengthType.axisLoadCapacity,
        currentBondLengthType.steelRodCapacity,
        currentCapacityType.deepPlateCapacity,
        currentCapacityType.slipPlateCapacity,
        slipPlate,
        deepPlate,
    ]);

    useDeepCompareEffect(() => {
        const isDeepPlate = calculationParameters.isDeepPlate;
        const isSteelRod =
            calculationParameters.isSteelRod &&
            calculationParameters.bondCapacityType === 2;

        const deepLength = isDeepPlate
            ? isSteelRod
                ? roundNumber(
                      deepPlate.steelRodCapacity[1].freeLength +
                          deepPlate.steelRodCapacity[1].totalBondLength,
                      2,
                  )
                : roundNumber(
                      deepPlate.axisLoadCapacity[1].freeLength +
                          deepPlate.axisLoadCapacity[1].totalBondLength,
                      2,
                  )
            : 0;

        const slipLength = isSteelRod
            ? roundNumber(
                  slipPlate.steelRodCapacity[1].freeLength +
                      slipPlate.steelRodCapacity[1].totalBondLength,
                  2,
              )
            : roundNumber(
                  slipPlate.axisLoadCapacity[1].freeLength +
                      slipPlate.axisLoadCapacity[1].totalBondLength,
                  2,
              );

        const slipAxisHorizontal = Math.max(
            ...slideLineArrSlipPlate.map((ele) => ele.topLeft),
        );
        const deepAxisHorizontal = isDeepPlate
            ? Math.max(...slideLineArrDeepPlate.map((ele) => ele.topLeft))
            : 0;

        const modalWidth = Math.max(
            deepLength,
            slipLength,
            slipAxisHorizontal,
            deepAxisHorizontal,
        );
        const layersHeight = soilTable.map((ele) => ele.height);
        const maxValueHeight = Math.max(...layersHeight);

        setAxisModelScale(
            Array(10)
                .fill(modalWidth / 10)
                .reduce((acc, ele, idx) => [...acc, ele * (idx + 1)], []),
        );
        setMaxWidth(modalWidth);
        setMaxHeight(maxValueHeight);

        if (soilProfileRef.current) {
            setOffsetHeight(soilProfileRef.current.offsetHeight);
            setOffsetWidth(soilProfileRef.current.offsetWidth);
        }
    }, [
        calculationParameters.isDeepPlate,
        calculationParameters.isSteelRod,
        calculationParameters.bondCapacityType,
        resultsModelWidth,
        currentMicropileLength.total,

        deepPlate,
        slipPlate,
        slideLineArrSlipPlate,
        slideLineArrDeepPlate,
        soilTable,

        openSidebar
    ]);

    useEffect(() => {
        setExcavationWidthPx(offsetWidth * 0.4);
    }, [offsetWidth]);

    useDeepCompareEffect(() => {
        if (soilProfileRef.current) {
            const isMaxHeightCorrect = !isNumber(maxHeight);
            const leftZwg = isNumber(calculationParameters.leftZwg) ? calculationParameters.leftZwg : 0;
            setExcavationBase(
                (offsetHeight * calculationParameters.excavationBase) /
                    maxHeight,
            );
            setRightWaterHeight(
                isMaxHeightCorrect
                    ? ''
                    : (offsetHeight * calculationParameters.rightZwg) /
                          maxHeight,
            );
            setLeftWaterHeight(
                isMaxHeightCorrect
                    ? ''
                    : (offsetHeight * leftZwg) /
                          maxHeight,
            );

            setSoilLayer(
                soilTable.map(
                    ({ name, height, density, plasticity }, i, arr) => {
                        return (
                            <SoilLayer
                                key={i}
                                backgroundColor={setSoilColor({
                                    id: density,
                                    il: plasticity,
                                })}
                                name={name}
                                groundLevel={calculationParameters.groundLevel}
                                height={
                                    isNumber(maxHeight) &&
                                    maxHeight !== 0 &&
                                    isNumber(offsetHeight)
                                        ? offsetHeight *
                                          ((i === 0
                                              ? height
                                              : height - arr[i - 1].height) /
                                              maxHeight)
                                        : 0
                                }
                                layerHeight={height}
                            />
                        );
                    },
                ),
            );
        }
    }, [
        calculationParameters.groundLevel,
        calculationParameters.excavationBase,
        calculationParameters.rightZwg,
        calculationParameters.leftZwg,
        offsetHeight,
        maxHeight,

        soilTable,
    ]);

    useDeepCompareEffect(() => {
        const wallBaseSpot =
            calculationParameters.sheetPileSpot > maxHeight
                ? maxHeight
                : calculationParameters.sheetPileSpot;

        const convertMetersToPx = (ele) => {
            return {
                ...ele,
                topLeft:
                    ((offsetWidth - excavationWidthPx) * ele.topLeft) /
                        maxWidth +
                    excavationWidthPx,
                downLeft:
                    ((offsetWidth - excavationWidthPx) * ele.downLeft) /
                        maxWidth +
                    excavationWidthPx,
                bottom: (offsetHeight * ele.bottom) / maxHeight,
                top: (offsetHeight * ele.top) / maxHeight,
            };
        };
        setSlideLinePx(slideLineArrSlipPlate.map(convertMetersToPx));
        setDeepSlideLinePx(slideLineArrDeepPlate.map(convertMetersToPx));

        setProfilePileHeadSpot(
            (offsetHeight * calculationParameters.micropileHeadSpot) /
                maxHeight,
        );
        setProfileWallLength(
            (offsetHeight *
                (wallBaseSpot > maxHeight ? maxHeight : wallBaseSpot)) /
                maxHeight,
        );
        setMicropileAngle(() => {
            const defaultXLength = (offsetWidth - excavationWidthPx) / maxWidth;
            const defaultYLength =
                (Math.tan(
                    (calculationParameters.micropileAngle * Math.PI) / 180,
                ) *
                    offsetHeight) /
                maxHeight;
            const defaultResults =
                (Math.atan(defaultYLength / defaultXLength) * 180) / Math.PI;

            if (calculationParameters.isDeepPlate) {
                const xLength =
                    (intersectionPointsDeepPlate[0] *
                        (offsetWidth - excavationWidthPx)) /
                    maxWidth;
                const yLength =
                    ((intersectionPointsDeepPlate[1] -
                        calculationParameters.micropileHeadSpot) *
                        offsetHeight) /
                    maxHeight;

                const results = (Math.atan(yLength / xLength) * 180) / Math.PI;

                if (yLength <= 0 || !isNumber(results)) {
                    return defaultResults;
                } else {
                    return results;
                }
            } else {
                const xLength =
                    (intersectionPointsSlipPlate[0] *
                        (offsetWidth - excavationWidthPx)) /
                    maxWidth;
                const yLength =
                    ((intersectionPointsSlipPlate[1] -
                        calculationParameters.micropileHeadSpot) *
                        offsetHeight) /
                    maxHeight;

                const results = (Math.atan(yLength / xLength) * 180) / Math.PI;

                if (yLength <= 0 || !isNumber(results)) {
                    return defaultResults;
                } else {
                    return results;
                }
            }
        });
    }, [
        calculationParameters.isDeepPlate,
        calculationParameters.micropileHeadSpot,
        calculationParameters.sheetPileSpot,
        calculationParameters.micropileAngle,

        slideLineArrDeepPlate,
        slideLineArrSlipPlate,

        intersectionPointsDeepPlate,
        intersectionPointsSlipPlate,

        offsetHeight,
        offsetWidth,
        maxHeight,
        maxWidth,
        excavationWidthPx,
    ]);

    useEffect(() => {
        const micropileLength = currentMicropileLength.total;
        const bondLength = currentMicropileLength.bond;

        const x =
            (micropileLength *
                Math.cos(
                    (calculationParameters.micropileAngle * Math.PI) / 180,
                ) *
                (offsetWidth - excavationWidthPx)) /
            maxWidth;
        const h =
            (micropileLength *
                Math.sin(
                    (calculationParameters.micropileAngle * Math.PI) / 180,
                ) *
                offsetHeight) /
            maxHeight;

        const xBond =
            (bondLength *
                Math.cos(
                    (calculationParameters.micropileAngle * Math.PI) / 180,
                ) *
                (offsetWidth - excavationWidthPx)) /
            maxWidth;
        const hBond =
            (bondLength *
                Math.sin(
                    (calculationParameters.micropileAngle * Math.PI) / 180,
                ) *
                offsetHeight) /
            maxHeight;

        setProfileMicroPileLength({
            total: Math.sqrt(Math.pow(x, 2) + Math.pow(h, 2)),
            bond: Math.sqrt(Math.pow(xBond, 2) + Math.pow(hBond, 2)),
        });
    }, [
        micropileAngle,
        currentMicropileLength.total,
        currentMicropileLength.bond,
        calculationParameters.micropileAngle,
        excavationWidthPx,
        maxWidth,
        offsetWidth,
        offsetHeight,
        maxHeight,
    ]);

    useEffect(() => {
        if (micropileRef.current) {
            setMicropileOffset({
                width: micropileRef.current.offsetWidth,
                height: micropileRef.current.offsetHeight / 2,
            });
        }
    }, [micropileAngle]);

    return (
        <Fragment>
            <SectionTitle title="Projektowana długość mikropala" />
            <Row>
                <Col xs={12}>
                    <Row>
                        {calculationParameters.isDeepPlate && (
                            <Col xs={6}>
                                <Row>
                                    <Col xs={12}>
                                        <p className={styles.subtitle}>
                                            Typ analizy
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <div className={styles.flex}>
                                            <Button
                                                onClick={onChangeCapacity}
                                                css={`project-navigation ${styles.buttonMarginLeft}`}
                                                name="slipPlateCapacity"
                                                altTitle="Klin odłamu"
                                                title="Klin odłamu"
                                                active={
                                                    currentCapacityType.slipPlateCapacity
                                                }
                                            />
                                            <Button
                                                onClick={onChangeCapacity}
                                                css={`project-navigation ${styles.buttonMarginRight}`}
                                                name="deepPlateCapacity"
                                                altTitle="Głęboka powierzchnia poślizgu"
                                                title="Gł. powierzchnia pośizgu"
                                                active={
                                                    currentCapacityType.deepPlateCapacity
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        )}
                        {calculationParameters.bondCapacityType === 2 &&
                            calculationParameters.isSteelRod && (
                                <Col xs={6}>
                                    <Fragment>
                                        <Row>
                                            <Col xs={12}>
                                                <p className={styles.subtitle}>
                                                    Rodzaj wymiarowania długości
                                                    mikropala
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <div className={styles.flex}>
                                                    <Button
                                                        onClick={
                                                            onChangeBondCapacity
                                                        }
                                                        css={`project-navigation ${styles.buttonMarginLeft}`}
                                                        name="axisLoadCapacity"
                                                        altTitle="Siła osiowa"
                                                        title="Podana siła osiowa"
                                                        active={
                                                            currentBondLengthType.axisLoadCapacity
                                                        }
                                                    />
                                                    <Button
                                                        onClick={
                                                            onChangeBondCapacity
                                                        }
                                                        css={`project-navigation ${styles.buttonMarginRight}`}
                                                        name="steelRodCapacity"
                                                        altTitle="Nośność żerdzi"
                                                        title="Nośność żerdzi"
                                                        active={
                                                            currentBondLengthType.steelRodCapacity
                                                        }
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                </Col>
                            )}
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={8} md={7}>
                    <div className={styles.groundLevel}>
                        <span className={styles.spot}>
                            {`${
                                isNumber(calculationParameters.groundLevel)
                                    ? `0.00 (${calculationParameters.groundLevel.toFixed(2)} m.n.p.m.)`
                                    : '0.00'
                            }`}
                        </span>
                    </div>

                    <div className={styles.wrapper} ref={soilProfileRef}>
                        <div
                            className={styles.horizontalAxis}
                            style={{
                                left: `${excavationWidthPx}px`,
                                width: `${offsetWidth - excavationWidthPx}px`,
                            }}>
                            {axisModelScale.slice(0, -1).map((ele, idx) => {
                                return (
                                    <span
                                        key={idx}
                                        className={styles.axisCoord}
                                        style={{
                                            left: `${
                                                ((offsetWidth -
                                                    excavationWidthPx) /
                                                    maxWidth) *
                                                ele
                                            }px`,
                                        }}>
                                        <p className={styles.value}>
                                            {roundNumber(ele, 1)}
                                        </p>
                                    </span>
                                );
                            })}

                            <span className={styles.caret}>
                                {<p className={styles.axisDescription}>[m]</p>}
                            </span>
                        </div>
                        {Array.isArray(slideLinePx) &&
                            slideLinePx.every((ele) =>
                                Object.values(ele).every((ele) =>
                                    isNumber(ele),
                                ),
                            ) && (
                                <svg className={styles.slideSvg}>
                                    {slideLinePx.map((ele, idx) => {
                                        return (
                                            <polyline
                                                key={idx}
                                                className={styles.slidePolyline}
                                                points={`${ele.downLeft},${ele.bottom} ${ele.topLeft}, ${ele.top}`}></polyline>
                                        );
                                    })}
                                </svg>
                            )}
                        {calculationParameters.isDeepPlate &&
                            currentCapacityType.deepPlateCapacity &&
                            deepSlideLinePx.every((ele) =>
                                Object.values(ele).every((ele) =>
                                    isNumber(ele),
                                ),
                            ) && (
                                <svg className={styles.slideSvg}>
                                    {deepSlideLinePx.map((ele, idx) => {
                                        return (
                                            <polyline
                                                key={idx}
                                                strokeDasharray="10,10"
                                                className={styles.slidePolyline}
                                                points={`${ele.downLeft},${ele.bottom} ${ele.topLeft}, ${ele.top}`}></polyline>
                                        );
                                    })}
                                </svg>
                            )}
                        <div
                            className={styles.excavationWrapper}
                            style={{ width: `${excavationWidthPx}px` }}>
                            {isNumber(calculationParameters.excavationBase) &&
                                isNumber(profileWallLength) &&
                                profileWallLength > excavationBase && (
                                    <div
                                        className={styles.excavation}
                                        style={{
                                            top: `${leftWaterHeight}px`,
                                            height: `${excavationBase - leftWaterHeight}px`,
                                        }}>
                                        <span className={styles.spotProfile}>
                                            -
                                            {calculationParameters.excavationBase.toFixed(
                                                2,
                                            )}{' '}
                                            {`${
                                                isNumber(
                                                    calculationParameters.groundLevel,
                                                )
                                                    ? `(${roundNumber(
                                                          calculationParameters.groundLevel -
                                                              calculationParameters.excavationBase,
                                                          2,
                                                      ).toFixed(2)})`
                                                    : ''
                                            }`}
                                        </span>
                                    </div>
                                )}
                            {isNumber(calculationParameters.leftZwg) &&
                                isNumber(offsetHeight) &&
                                offsetHeight > leftWaterHeight && (
                                    <div
                                        className={styles.zwg}
                                        style={{ top: `${leftWaterHeight}px` }}>
                                        <div className={styles.zwgSpot}></div>
                                        <span className={styles.zwgLevel}>
                                            -
                                            {calculationParameters.leftZwg.toFixed(
                                                2,
                                            )}{' '}
                                            {`${
                                                isNumber(
                                                    calculationParameters.groundLevel,
                                                )
                                                    ? `(${roundNumber(
                                                          calculationParameters.groundLevel -
                                                              calculationParameters.leftZwg,
                                                          2,
                                                      ).toFixed(2)})`
                                                    : ''
                                            }`}
                                        </span>
                                    </div>
                                )}
                            {isNumber(calculationParameters.sheetPileSpot) && (
                                <div
                                    className={styles.sheetPile}
                                    style={{
                                        left: `${excavationWidthPx}px`,
                                        height: `${profileWallLength}px`,
                                    }}>
                                    <span className={styles.wallBaseSpot}>
                                        -
                                        {calculationParameters.sheetPileSpot.toFixed(
                                            2,
                                        )}{' '}
                                        {`${
                                            isNumber(
                                                calculationParameters.groundLevel,
                                            )
                                                ? `(${roundNumber(
                                                      calculationParameters.groundLevel -
                                                          calculationParameters.sheetPileSpot,
                                                      2,
                                                  ).toFixed(2)})`
                                                : ''
                                        }`}
                                    </span>
                                </div>
                            )}
                        </div>
                        {
                            <div
                                ref={micropileRef}
                                className={styles.pileWrapper}
                                style={{
                                    top: `${
                                        profilePileHeadSpot -
                                        micropileOffset.height
                                    }px`,
                                    left: `${
                                        excavationWidthPx -
                                        micropileOffset.width
                                    }px`,
                                    transform: `rotate(${
                                        micropileAngle || 0
                                    }deg)`,
                                }}>
                                <div className={styles.microHead}>
                                    <span
                                        className={styles.pileHeadSpot}
                                        style={{
                                            transform: `rotate(-${
                                                micropileAngle || 0
                                            }deg)`,
                                        }}>
                                        -
                                        {isNumber(
                                            calculationParameters.micropileHeadSpot,
                                        )
                                            ? `${calculationParameters.micropileHeadSpot.toFixed(
                                                  2,
                                              )} ${
                                                  isNumber(
                                                      calculationParameters.groundLevel,
                                                  )
                                                      ? `(${roundNumber(
                                                            calculationParameters.groundLevel -
                                                                calculationParameters.micropileHeadSpot,
                                                            2,
                                                        ).toFixed(2)})`
                                                      : ''
                                              }`
                                            : ''}
                                    </span>
                                    <div className={styles.topHead}></div>
                                    <div className={styles.bottomHead}></div>
                                </div>
                                <div
                                    className={styles.pile}
                                    style={{
                                        width: `${profileMicroPileLength.total}px`,
                                    }}>
                                    <div className={styles.steelRod}>
                                        <div className={styles.rod}></div>
                                    </div>
                                    <div
                                        className={styles.freeLength}
                                        style={{
                                            width: `${
                                                profileMicroPileLength.total -
                                                profileMicroPileLength.bond
                                            }px`,
                                        }}></div>
                                    <div
                                        className={styles.bond}
                                        style={{
                                            width: `${profileMicroPileLength.bond}px`,
                                        }}></div>
                                    <div
                                        className={styles.angleDescription}
                                        style={{
                                            transform: `rotate(-${
                                                micropileAngle || 0
                                            }deg)`,
                                        }}>
                                        <span>
                                            {
                                                calculationParameters.micropileAngle
                                            }
                                            &#176;
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                        {isNumber(calculationParameters.rightZwg) &&
                            isNumber(offsetHeight) &&
                            offsetHeight > rightWaterHeight && (
                                <div
                                    className={styles.zwgRight}
                                    style={{
                                        left: `${excavationWidthPx}px`,
                                        width: `calc(100% - ${excavationWidthPx}px)`,
                                        top: `${rightWaterHeight}px`,
                                    }}>
                                    {calculationParameters.rightZwg !== calculationParameters.leftZwg && 
                                    <Fragment>
                                        <div className={styles.zwgRightSpot}></div>
                                        <span className={styles.zwgRightLevel}>
                                            -
                                            {calculationParameters.rightZwg.toFixed(
                                                2,
                                            )}{' '}
                                            {`${
                                                isNumber(
                                                    calculationParameters.groundLevel,
                                                )
                                                    ? `(${roundNumber(
                                                        calculationParameters.groundLevel -
                                                            calculationParameters.rightZwg,
                                                        2,
                                                    ).toFixed(2)})`
                                                    : ''
                                            }`}
                                        </span>                                        
                                    </Fragment>
                                    }
                                </div>
                            )}
                        <div>
                            {[...soilLayer]}
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={4} md={5}>
                    <DescriptionResults
                        result={currentCapacity}
                        steelRodWeight={steelRod.m}
                        rodName={Object.keys(steelRod).length > 0 ? `${steelRod.prod} - ${steelRod.name} ${steelRod.subname}` : ''}
                        isSteelRod={calculationParameters.isSteelRod}
                        diameter={calculationParameters.diameter}
                        rodModule={rodModule} 
                        prodLengthValues={prodLengthValues}
                        onChangeRodModule={onChangeRodModule}
                    />
                </Col>
            </Row>
            <Row className={styles.row}>
                <Col xs={12}>
                    <MicropileCapacityTable
                        dataToPaginate={currentCapacity[0] || []}
                    />
                </Col>
            </Row>
        </Fragment>
    );
};

export default React.memo(
    MicropileCapacityChart,
    (prev, next) => stringify(prev) === stringify(next),
);