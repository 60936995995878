import React, { createContext, useState, useCallback } from 'react';
import { withForm } from '../../hoc';
import { useNavigate } from 'react-router-dom';
import config from '../../config/config';
import { roles } from '../../config/ProjectConfig';
import AdminFetch from '../../routes/AdminFetch';
import { useIsMountedState } from '../../hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';

const adminAuthContext = createContext({}); 
const parseJwt = (token) => {
    if (token) {
        const splitToken = token.split('.')[1];

        if (typeof splitToken === 'string') {
            return JSON.parse(
                window.atob(splitToken.replace('-', '+').replace('_', '/')),
            );
        }
    }

    return false;
};
const checkSessionTime = ({ parseObj, timeStamp, logOut, setRenewToken }) => {
    if (parseObj.hasOwnProperty('exp')) {
        if (parseObj.exp - timeStamp < 0) {
            logOut();
        }
        if (parseObj.exp - timeStamp < 120) {
            setRenewToken(true);
        }
    } else {
        logOut();
    }
};
const checkSessionInterval = ({ role, logOut, setToken, setRenewToken }) => {
    return setInterval(() => {
        const parseObj = parseJwt(localStorage.getItem('token1')) || {};
        const timeStamp = Math.ceil(Date.now() / 1000);
        setToken(localStorage.getItem('token1'));

        if (role === roles[2]) {
            checkSessionTime({ parseObj, logOut, timeStamp, setRenewToken });
        } else {
            logOut();
        }
    }, 3000);
};

const AdminAuthProvider = ({ actions = {}, children }) => {
    const navigate = useNavigate();
    const [token, setToken] = useState(localStorage.getItem('token1'));
    const [renewToken, setRenewToken] = useState(false);
    const [id, setId] = useState('');
    const [userObj, setUserObj] = useState({});
    const { current } = useIsMountedState();
    const [stateChanged, setStateChanged] = useState(false);
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const logIn = (token, user) => {
        localStorage.setItem('token1', token.access.token);
        localStorage.setItem('isLoggedIn1', true);

        setUserObj({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            permission: user.role,
        });
        setIsLoggedOut(false);
        setIsLoading(false);
        setToken(token.access.token);
        setId(user._id);
    };
    const logOutAction = useCallback(() => {
        setIsLoggedOut(true);
        setIsLoading(true)
        setRenewToken(false);
        setToken('');
        setUserObj({});
        localStorage.removeItem('token1');
        localStorage.removeItem('isLoggedIn1');
    },[]);

    const logOut = useCallback(() => {
        const sessionToken = localStorage.getItem('token1');
        if (current) {
            if (sessionToken) {
                AdminFetch({
                    method: 'POST',
                    url: '/api/admin/geoincloud/auth/logout',
                    body: {
                        accessToken: sessionToken,
                    },
                }).finally(() => {
                    logOutAction();
                    navigate(config.routes.admin.logIn);
                });
            } else {
                navigate(config.routes.admin.logIn);
                logOutAction();
            }
        }
    }, [current, navigate, logOutAction]);

    const nextSession = () => {
        const sessionToken = localStorage.getItem('token1');

        actions
            .renewSession({
                body: {
                    accessToken: sessionToken,
                },
                headers: {
                    Authorization: `Bearer ${sessionToken}`,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem('token1', response.data.access.token);
                    localStorage.setItem('isLoggedIn1', true);
                    setRenewToken(false);
                } else {
                    logOut();
                }
            })
            .catch(() => {
                logOut();
            });
    };

    useDeepCompareEffect(() => {
        let interval;
        if (current) {
            const isLoggedIn = localStorage.getItem('isLoggedIn1');

            if (token && userObj.permission && isLoggedIn) {
                interval = checkSessionInterval({
                    role: userObj.permission,
                    logOut,
                    setToken,
                    setRenewToken,
                });
            }
        }

        return () => clearInterval(interval);
    }, [token, userObj.permission, logOut, current]);

    useDeepCompareEffect(() => {
        const sessionToken = localStorage.getItem('token1');

        if (current) {
            if (sessionToken && !isLoggedOut) {
                AdminFetch({
                    method: 'POST',
                    url: '/api/admin/geoincloud/auth/me',
                    body: {
                        accessToken: sessionToken,
                    },
                })
                .then((response) => {
                    if (current) {
                        if (response.status === 200) {
                            if (
                                response.responseData.user.role === roles[2]
                            ) {
                                setIsLoggedOut(false);
                                setIsLoading(false);
                                setUserObj({
                                    firstName:
                                        response.responseData.user
                                            .firstName,
                                    lastName:
                                        response.responseData.user.lastName,
                                    email: response.responseData.user.email,
                                    permission:
                                        response.responseData.user.role,
                                });
                                setId(response.responseData.user._id);
                            } else {
                                logOut();
                            }
                        } else {
                            logOut();
                        }
                    }
                })
                .catch((e) => {
                    logOut();
                });
            }
        }
    }, [
        current, 
        token, 
        isLoggedOut, 
        logOut
    ]);

    const authObj = {
        logIn,
        nextSession,
        logOut,
    };
    const state = {
        id,
        ...userObj,
        isLoggedOut,
        stateChanged,
        isLoading,
    };
    return (
        <adminAuthContext.Provider 
            value={{ 
                ...state, 
                ...authObj, 
                renewToken,
                isUserLoggedIn: localStorage.getItem('token1') && localStorage.getItem('isLoggedIn1'),
                setStateChanged: useCallback((bool) => setStateChanged(bool),[]),
            }}>
            {children}
        </adminAuthContext.Provider>
    );
};

export { adminAuthContext };
export default withForm({
    component: AdminAuthProvider,
    onlyActions: true,
    actions: [
        {
            name: 'renewSession',
            url: '/api/admin/geoincloud/auth/refresh-tokens',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            returnData: true,
        },
    ],
});
