import { useCallback, useEffect, useState } from 'react';
import { AdminFetch } from '../../../routes';
import { isAdminRole } from '../../../utils';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

const PaymentsListService = ({ fetch, setAlert, setLoading, role }) => {
    const location = useLocation();
    const [paymentsList, setPaymentsList] = useState([]);
    const [startDate, setStartDate] = useState(
        moment().subtract(6, 'months').toDate(),
    );
    const [endDate, setEndDate] = useState(moment().toDate());
    const [invoiceId, setInvoiceId] = useState('');

    const [invoice, setInvoice] = useState({
        firstName: {
            value: '',
            isValid: null,
        },
        lastName: {
            value: '',
            isValid: null,
        },
        companyName: {
            value: '',
            isValid: null,
        },
        nip: {
            value: '',
            isValid: null,
        },
        street: {
            value: '',
            isValid: null,
        },
        placeNumber: {
            value: '',
            isValid: null,
        },
        postcode: {
            value: '',
            isValid: null,
        },
        city: {
            value: '',
            isValid: null,
        },

        email: {
            value: '',
            isValid: null,
        },
    });
    const [invoiceType, setInvoiceType] = useState(1);
    const [invoiceStatusState, setInvoiceStatusState] = useState(false);
    const [invoiceData, setInvoiceData] = useState(false);
    const [buyer, setBuyer] = useState({});

    useEffect(() => {
        if (isAdminRole(role) && startDate && endDate) {
            AdminFetch({
                url: `${fetch.getPayments.url}/${startDate}/${endDate}`,
                method: fetch.getPayments.method,
            })
            .then((response) => {
                if (
                    Array.isArray(response.responseData) &&
                    response.responseData.length > 0
                ) {
                    setInvoiceStatusState(false);
                    setPaymentsList(response.responseData);
                } else {
                    setPaymentsList([]);
                }
            })
            .catch((e) => {
                setPaymentsList([]);
                setAlert({
                    error: true,
                    message: 'Błąd podczas wczytywania listy płatności',
                });
            });
        }
    }, [
        invoiceStatusState,
        invoiceData,
        startDate,
        endDate,
        fetch.getPayments.url,
        fetch.getPayments.method,
        role,
        setAlert,
    ]);

    useEffect(() => {
        setStartDate(moment().subtract(6, 'months').toDate());
        setEndDate(moment().toDate());
    }, [location.pathname]);

    const onChangeDateRange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const onClearDateInput = () => {
        setStartDate(moment().subtract(6, 'months').toDate());
        setEndDate(moment().toDate());
    };

    const onChangeInvoiceType = (val) => setInvoiceType(val);

    const onBlurInvoiceState = useCallback((e) => {
        const { name } = e.currentTarget;
        const valid = e.currentTarget.checkValidity();

        setInvoice((state) => {
            return {
                ...state,
                [name]: {
                    ...state[name],
                    isValid: valid,
                },
            };
        });
    }, []);

    const onChangeInvoiceState = useCallback((e) => {
        const input = e.currentTarget.closest('input');

        if (input) {
            const { name, value } = input;

            setInvoice((state) => {
                return {
                    ...state,

                    [name]: {
                        value: value,
                        isValid: true,
                    },
                };
            });
        }
    }, []);

    const payments = {
        onChangeDateRange,
        onClearDateInput,
        onChangeInvoiceType,
        onChangeInvoiceState,
        onBlurInvoiceState,
        setInvoiceId,
        setBuyer,
        
        state: {
            endDate,
            startDate,
            paymentsList,
            invoice,
            invoiceType,
            invoiceId,
        },
    };

    return {
        payments,
    };
};

export default PaymentsListService;
