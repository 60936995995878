import { useState, Fragment } from 'react';
import { Button } from '../../../components';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.css';
import PrintResults from './PrintProjectResults';

const PrintSummaryResults = ({ report, printResults = {} }) => {
    const [open, setOpen] = useState(false);

    const onOpen = () => setOpen(true);
    const onHide = () => setOpen(false);

    return (
        <Fragment>
            {
                <Fragment>
                    <div className={styles.wrapper}>
                        <Button
                            icon={faPrint}
                            css={styles.printButton}
                            variant="danger"
                            name="report"
                            onClick={onOpen}
                            title="Drukuj zestawienie"
                            altTitle="Zestawienie wyników"
                        />
                    </div>

                    <PrintResults
                        open={open}
                        onChange={printResults.onChange}
                        state={{
                            report,
                            ...printResults.state,
                        }}
                        onHide={onHide}
                    />
                </Fragment>
            }
        </Fragment>
    );
};

export default PrintSummaryResults;
