import styles from './index.module.css';
import { SectionTitle, Table } from '../../../../../components';
import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';
import round from '../../../../../utils/roundNumber';
import { isNumber } from '../../../../../utils';

const DesignTable = ({ 
    data: {
        rebarArea = {},
        rebarAreaAs1 = {},
        rebarAreaAs2 = {},
        rebarDiameter = {},
        rebarDiameterAs1 = {},
        rebarDiameterAs2 = {},
        rebarNumber = {},
        rebarNumberAs1 = {},
        rebarNumberAs2 = {},
        space = {},
        spaceAs1 = {},
        spaceAs2 = {},
        condition = {},
        conditionAs1 = {},
        conditionAs2 = {}
    }, 
    isLbdCalc,
    negativeMoment,
    diameter,
    pileShape,
    a2,
    x,
    b,
    lbd,
    d
}) => {
    return (
        <Fragment>
            {
                pileShape === 1
                ?
                <Fragment>
                    <SectionTitle
                        title={<Fragment>Zbrojenie przekroju A<sub>s</sub></Fragment>}
                    />
                    <Table>
                        <thead className={styles.thead}>
                            <tr>
                                <th>
                                    Nr<br></br>[-]
                                </th>
                                <th>
                                    Liczba prętów<br></br>[-]
                                </th>
                                <th>
                                    Średnica<br></br>[mm]
                                </th>
                                <th>
                                    Powierzchnia<br></br>[cm<sup>2</sup>]
                                </th>
                                <th>Stopień zbrojenia<br></br>[%]</th>
                                <th title='Wymiar w osiach prętów'>
                                    Rozstaw<br></br>[mm]
                                </th>
                                <th title='Wymiar w świetle prętów'>
                                    Odstęp<br></br>[mm]
                                </th>
                                {
                                    isLbdCalc &&
                                    <Fragment>
                                        {
                                            lbd && isNumber(lbd.tension) &&
                                            <th title='Długość zakotwienia pręta rozciąganego'>
                                                l<sub>bd+</sub><br></br>[mm]
                                            </th>
                                        }
                                        {
                                            lbd && isNumber(lbd.compression) &&
                                            <th title='Długość zakotwienia pręta ściskanego'>
                                                l<sub>bd-</sub><br></br>[mm]
                                            </th>
                                        }                                        
                                    </Fragment>
                                }
                                <th>
                                    Warunek<br></br>[-]
                                </th>
                            </tr>
                        </thead>
                        <tbody className={styles.tbody}>
                            <tr>
                                <td>1</td>
                                <td>{rebarNumber.value || ''}</td>
                                <td>{rebarDiameter.value || ''}</td>
                                <td>{rebarArea.value || ''}</td>
                                <td>{round(100*rebarArea.value/(diameter*diameter*0.25*0.01*Math.PI),2) || ''}</td>
                                <td>{round(2*Math.PI*(diameter*0.5 - a2)/rebarNumber.value,2) || ''}</td>
                                <td>{space.value || 0}</td>
                                {isLbdCalc && lbd && isNumber(lbd.tension) && <td>{round(lbd.tension,0)}</td>}
                                {isLbdCalc && lbd && isNumber(lbd.compression) && <td>{round(lbd.compression,0)}</td>}
                                <td className={condition.value ? styles.valid : styles.inValid}>
                                    <FontAwesomeIcon
                                        icon={condition.value ? faCheck : faBan}
                                    />
                                    <div className={styles.info}>
                                        Minimalne odstępy między prętami:
                                        <ul>
                                            <li>max(<span>&phi;</span>, 20mm, d<sub>g</sub>+5mm)</li>
                                            <li>w przekroju kołowym co najmniej 4 prętów po obwodzie.</li>
                                            <li>Maksymalny stopień zbrojenia przekroju 	&Sigma;A<sub>s</sub> &le; 4% A<sub>c</sub></li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>                            
                </Fragment>
                :
                <Fragment>
                    {
                        rebarNumberAs2.value > 0 &&
                        <Fragment>
                            <SectionTitle
                                title={<Fragment>Zbrojenie górne ({ x > a2 
                                    ?
                                    negativeMoment ? x > d ? 'ściskane' : 'rozciągane' : 'ściskane' 
                                    :
                                    'rozciągane'
                                }) A<sub>s2</sub></Fragment>}
                            />
                            <Table>
                                <thead className={styles.thead}>
                                    <tr>
                                        <th>
                                            Nr<br></br>[-]
                                        </th>
                                        <th>
                                            Liczba prętów<br></br>[-]
                                        </th>
                                        <th>
                                            Średnica<br></br>[mm]
                                        </th>
                                        <th>
                                            Powierzchnia<br></br>[cm<sup>2</sup>]
                                        </th>
                                        <th>
                                            Stopień zbrojenia<br></br>[%]
                                        </th>
                                        <th title='Wymiar w osiach prętów'>
                                            Rozstaw<br></br>[mm]
                                        </th>
                                        <th title='Wymiar w świetle prętów'>
                                            Odstęp<br></br>[mm]
                                        </th>
                                        {
                                            isLbdCalc &&
                                            <Fragment>
                                                {
                                                    x > a2 
                                                    ?
                                                    negativeMoment 
                                                        ? x > d 
                                                            ? 
                                                            <Fragment>
                                                                {
                                                                lbd && isNumber(lbd.compression) &&
                                                                <th title='Długość zakotwienia pręta ściskanego'>
                                                                    l<sub>bd-</sub><br></br>[mm]
                                                                </th>  
                                                                }
                                                            </Fragment>
                                                            :
                                                            <Fragment>
                                                                {
                                                                lbd && isNumber(lbd.tension) &&
                                                                <th title='Długość zakotwienia pręta rozciąganego'>
                                                                    l<sub>bd+</sub><br></br>[mm]
                                                                </th>
                                                                }
                                                            </Fragment> 
                                                        : 
                                                        <Fragment>
                                                            {
                                                            lbd && isNumber(lbd.compression) &&
                                                            <th title='Długość zakotwienia pręta ściskanego'>
                                                                l<sub>bd-</sub><br></br>[mm]
                                                            </th>  
                                                            }
                                                        </Fragment>
                                                    :
                                                    <Fragment>
                                                        {
                                                        lbd && isNumber(lbd.tension) &&
                                                        <th title='Długość zakotwienia pręta rozciąganego'>
                                                            l<sub>bd+</sub><br></br>[mm]
                                                        </th>
                                                        }
                                                    </Fragment> 
                                                }                                                
                                            </Fragment>
                                        }

                                        <th>
                                            Warunek<br></br>[-]
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className={styles.tbody}>
                                    <tr>
                                        <td>1</td>
                                        <td>{rebarNumberAs2.value || ''}</td>
                                        <td>{rebarDiameterAs2.value || ''}</td>
                                        <td>{rebarAreaAs2.value || ''}</td>
                                        <td>{round(100*rebarAreaAs2.value/(diameter*b*0.01),2) || ''}</td>
                                        <td>{round(spaceAs2.value + rebarDiameterAs2.value,2) || ''}</td>
                                        <td>{round(spaceAs2.value,2) || 0}</td>
                                        {
                                            isLbdCalc &&
                                            <Fragment>
                                                {
                                                    x > a2 
                                                    ?
                                                    negativeMoment 
                                                        ? x > d 
                                                            ? 
                                                            <Fragment>
                                                                {lbd && isNumber(lbd.compression) && <td>{round(lbd.compression,0)}</td>}
                                                            </Fragment>
                                                            :                                                     
                                                            <Fragment>
                                                                {lbd && isNumber(lbd.tension) && <td>{round(lbd.tension,0)}</td>}
                                                            </Fragment> 
                                                        : 
                                                        <Fragment>
                                                            {lbd && isNumber(lbd.compression) && <td>{round(lbd.compression,0)}</td>}
                                                        </Fragment>
                                                    :
                                                    <Fragment>
                                                        {lbd && isNumber(lbd.tension) && <td>{round(lbd.tension,0)}</td>}
                                                    </Fragment> 
                                                }                                                
                                            </Fragment>
                                        }

                                        <td className={conditionAs2.value ? styles.valid : styles.inValid}>
                                            <FontAwesomeIcon
                                                icon={conditionAs2.value ? faCheck : faBan}
                                            />
                                            <div className={styles.info}>
                                                Minimalne odstępy między prętami:
                                                <ul>
                                                    <li>max(<span>&phi;</span>, 20mm, d<sub>g</sub>+5mm)</li>
                                                    <li>w przekroju prostokątnym co najmniej po jednym pręcie w każdym narożniku.</li>
                                                    <li>Maksymalny stopień zbrojenia przekroju 	&Sigma;A<sub>s</sub> &le; 4% A<sub>c</sub> (suma zbrojenia górnego i dolnego)</li>
                                                </ul>
                                                Maksymalne odstępy między prętami:
                                                <ul>
                                                    <li>w przekroju ściskanym 400mm.</li>
                                                    <li>w przekroju zginanym 250mm lub 2h.</li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Fragment>
                    }
                    {
                        rebarNumberAs1.value > 0 &&
                        <Fragment>
                            <SectionTitle
                                title={<Fragment>Zbrojenie dolne ({ x > a2 
                                ?    
                                negativeMoment ? 'ściskane' : x > d ? 'ściskane' : 'rozciągane'
                                :
                                'rozciągane'
                            }) A<sub>s1</sub></Fragment>}
                            />
                            <Table>
                                <thead className={styles.thead}>
                                    <tr>
                                        <th>
                                            Nr<br></br>[-]
                                        </th>
                                        <th>
                                            Liczba prętów<br></br>[-]
                                        </th>
                                        <th>
                                            Średnica<br></br>[mm]
                                        </th>
                                        <th>
                                            Powierzchnia<br></br>[cm<sup>2</sup>]
                                        </th>
                                        <th>
                                            Stopień zbrojenia<br></br>[%]
                                        </th>
                                        <th title='Wymiar w osiach prętów'>
                                            Rozstaw<br></br>[mm]
                                        </th>
                                        <th title='Wymiar w świetle prętów'>
                                            Odstęp<br></br>[mm]
                                        </th>
                                        {
                                            isLbdCalc && 
                                            <Fragment>
                                                { x > a2 
                                                    ? negativeMoment 
                                                        ? 
                                                        <Fragment>
                                                            {
                                                            lbd && isNumber(lbd.compression) &&
                                                            <th title='Długość zakotwienia pręta ściskanego'>
                                                                l<sub>bd-</sub><br></br>[mm]
                                                            </th>  
                                                            }
                                                        </Fragment>
                                                        : 
                                                            x > d 
                                                            ? 
                                                            <Fragment>
                                                                {
                                                                lbd && isNumber(lbd.compression) &&
                                                                <th title='Długość zakotwienia pręta ściskanego'>
                                                                    l<sub>bd-</sub><br></br>[mm]
                                                                </th>  
                                                                }
                                                            </Fragment>
                                                            : 
                                                            <Fragment>
                                                            {
                                                                lbd && isNumber(lbd.tension) &&
                                                                <th title='Długość zakotwienia pręta rozciąganego'>
                                                                    l<sub>bd+</sub><br></br>[mm]
                                                                </th>                                               
                                                            }
                                                            </Fragment>
                                                    : 
                                                    <Fragment>
                                                        {
                                                            lbd && isNumber(lbd.tension) &&
                                                            <th title='Długość zakotwienia pręta rozciąganego'>
                                                                l<sub>bd+</sub><br></br>[mm]
                                                            </th>
                                                        }
                                                    </Fragment>
                                                }                                                
                                            </Fragment>
                                        }
                                        <th>
                                            Warunek<br></br>[-]
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className={styles.tbody}>
                                    <tr>
                                        <td>1</td>
                                        <td>{rebarNumberAs1.value || ''}</td>
                                        <td>{rebarDiameterAs1.value || ''} </td>
                                        <td>{rebarAreaAs1.value || ''}</td>
                                        <td>{round(100*rebarAreaAs1.value/(diameter*b*0.01),2) || ''}</td>
                                        <td>{round(spaceAs1.value + rebarDiameterAs1.value,2) || ''}</td>
                                        <td>{round(spaceAs1.value,2) || 0}</td>
                                        {
                                            isLbdCalc &&
                                            <Fragment>
                                                { 
                                                    x > a2 
                                                    ? negativeMoment 
                                                        ? 
                                                        <Fragment>
                                                            {lbd && isNumber(lbd.compression) && <td>{round(lbd.compression,0)}</td>}
                                                        </Fragment>
                                                        : 
                                                            x > d 
                                                            ? 
                                                            <Fragment>
                                                                {lbd && isNumber(lbd.compression) && <td>{round(lbd.compression,0)}</td>}
                                                            </Fragment>
                                                            : 
                                                            <Fragment>
                                                            {lbd && isNumber(lbd.tension) && <td>{round(lbd.tension,0)}</td>}
                                                            </Fragment>
                                                    : 
                                                    <Fragment>
                                                        {lbd && isNumber(lbd.tension) && <td>{round(lbd.tension,0)}</td>}
                                                    </Fragment>
                                                }                                                
                                            </Fragment>
                                        }
 
                                        <td className={conditionAs1.value ? styles.valid : styles.inValid}>
                                            <FontAwesomeIcon
                                                icon={conditionAs1.value ? faCheck : faBan}
                                            />
                                            <div className={styles.info}>
                                                Minimalne odstępy między prętami:
                                                <ul>
                                                    <li>max(<span>&phi;</span>, 20mm, d<sub>g</sub>+5mm)</li>
                                                    <li>w przekroju prostokątnym co najmniej po jednym pręcie w każdym narożniku.</li>
                                                    <li>Maksymalny stopień zbrojenia przekroju 	&Sigma;A<sub>s</sub> &le; 4% A<sub>c</sub> (suma zbrojenia górnego i dolnego)</li>
                                                </ul>
                                                Maksymalne odstępy między prętami:
                                                <ul>
                                                    <li>w przekroju ściskanym 400mm.</li>
                                                    <li>w przekroju zginanym 250mm lub 2h.</li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Fragment>
                    }
                </Fragment>
            }   
        </Fragment>
    )
}

export default DesignTable;