import React, { createContext, useContext } from 'react';
import { 
    GlobalAlertService,
    GlobalLoadingService,
    SubscriptionService, 
    StatisticsService,
    ChangePasswordService,
    CalculationAuthorService,
    ReportLogoService,
    RenewSubscription,
    NewUsers
} from '../../services/Home'; 
import PaymentsHistoryService from '../../services/Home/PaymentsHistory';
import { authContext } from '../Authentication';
  
const homeContext = createContext({});

const HomeProvider = ({ children }) => {
    const { setReportDetails, stateChanged, permission, invoice }    = useContext(authContext);

    const { globalAlerts }                      = GlobalAlertService();
    const { globalLoading }                     = GlobalLoadingService(); 
    const { stats }                             = StatisticsService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        fetch: {
            stats: {
                url: '/api/projects/statistics',
                method: 'GET'
            },
        }
    });
    const { changePassword }            = ChangePasswordService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        fetch: {
            changePassword: {
                url: '/api/auth/account/change-password',
                method: 'PUT'
            },
        }
    });
    const { calculationAuthor } = CalculationAuthorService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        updateReportDetails: setReportDetails,
        fetch: {
            updateReportData: {
                url: '/api/users/report',
                method: 'PUT'
            },
            getReportData: {
                url: '/api/users/report',
                method: 'GET'
            },
        }
    });
    const { reportLogo } = ReportLogoService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        updateReportDetails: setReportDetails,
        fetch:{
            updateLogo: {
                url: '/api/users/report/logo',
                method: 'PUT'
            },
            deleteLogo: {
                url: '/api/users/report/logo',
                method: 'DELETE'
            },
            getLogo: {
                url: '/api/users/report/logo',
                method: 'GET'
            }
        }
    }); 


    const { payments } = PaymentsHistoryService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        role: permission, 
        initInvoice: invoice,

        fetch: {
            getPaymentsHistory: {
                url: '/api/users/payments/history',
                method: 'GET'
            },
        }
    });
    const { newUsers } = NewUsers({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        updateInvoiceState: payments.updateInvoiceState,
        stateChanged: stateChanged,
        fetch: {
            deleteUser: {
                url: '/api/users/subscribe/user/delete',
                method: 'DELETE'
            },
            resetPassword: {
                url: '/api/users/subscribe/user/resetpassword',
                method: 'PUT'
            },
            subscribeUsers: {
                url: '/api/users/subscribe/new/users',
                method: 'POST'
            },
            getSubscribers: {
                url: '/api/users/subscribe/new/users',
                method: 'GET'
            },
            editUser: {
                url: '/api/users/subscribe/user/edit',
                method: 'PUT'
            }
        },
        role: permission,
        invoiceState: payments.state.invoiceState,
        isSaveFormData: payments.state.saveFormData,
        invoiceType: payments.state.invoiceType,
    });    
    const { renewSubscription } = RenewSubscription({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        updateInvoiceState: payments.updateInvoiceState,
        usersToRenewSubscription: newUsers.state.usersToRenewSubscription,
        invoiceState: payments.state.invoiceState,
        isSaveFormData: payments.state.saveFormData,
        invoiceType: payments.state.invoiceType,

        fetch: {
            renewSubscription: {
                url: '/api/subscription/renew/subusers',
                method: 'POST'
            }
        }
    });
    const { subscription }                      = SubscriptionService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        updateInvoiceState: payments.updateInvoiceState,
        invoiceState: payments.state.invoiceState,
        isSaveFormData: payments.state.saveFormData,
        invoiceType: payments.state.invoiceType,
        role: permission,
    });
    return ( 
        <homeContext.Provider   
            value={{ 
                newUsers,
                subscription,
                renewSubscription,
                stats,
                changePassword,
                calculationAuthor,
                globalAlerts,
                globalLoading,
                reportLogo,

                payments
            }}
        >
            {children}
        </homeContext.Provider>
    )
}

export { homeContext }
export default HomeProvider