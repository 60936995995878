import { useState } from "react"
import { isNumber } from "../../../utils"
import useDeepCompareEffect from 'use-deep-compare-effect';

const SoilProfileHelpers = (soilTableState = []) => {

    const [profile, setProfile] = useState([]);

    useDeepCompareEffect(() => {
        setProfile(() => [...soilTableState.filter(ele => (isNumber(ele.height.value) && ele.height.isValid))])
    },[soilTableState])

    return {
        soilProfile: {
            height: profile.map(ele => ele.height.value),
            density: profile.map(ele => ele.density.value),
            plasticity: profile.map(ele => ele.plasticity.value),
            name: profile.map(ele => ele.name.value),
            weight: profile.map(ele => ele.weight.value),
            underWaterWeight: profile.map(ele => ele.underWaterWeight.value),
            cohesion: profile.map(ele => ele.cohesion.value),
            friction: profile.map(ele => ele.friction.value),
            edometricModule: profile.map(ele => ele.edometricModule.value),
            genesis: profile.map(ele => ele.genesis.value)             
        }
    }

}

export default SoilProfileHelpers