import React, { createContext } from 'react';
import { GlobalAlertService, GlobalLoadingService } from '../../services/Home';

import {
    KxCapacityService,
    MicropileCompressionCapacityService,
    MicropilePullCapacityService,
    ProjectsListService,
    VerticalCapacityService,
    ReinforcementService,
    PileCptCapacityService,
    SheetpileCptCapacityService
} from '../../services/CapacityCharts';

const capacityChartsContext = createContext({});

const CapacityChartsProvider = ({ children }) => {
    const { globalAlerts } = GlobalAlertService();
    const { globalLoading } = GlobalLoadingService();

    const { projects } = ProjectsListService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
    });

    const { verticalCapacity } = VerticalCapacityService();
    const { micropilePullCapacity } = MicropilePullCapacityService();
    const { micropileCompressionCapacity } = MicropileCompressionCapacityService();
    const { kxCapacity } = KxCapacityService();
    const { reinforcement } = ReinforcementService();
    const { pileCptCapacity } = PileCptCapacityService();
    const { sheetpileCptCapacity} = SheetpileCptCapacityService()

    return (
        <capacityChartsContext.Provider
            value={{
                globalAlerts,
                globalLoading,
                projects,
                verticalCapacity,
                micropilePullCapacity,
                micropileCompressionCapacity,
                kxCapacity,
                reinforcement,
                pileCptCapacity,
                sheetpileCptCapacity
            }}>
            {children}
        </capacityChartsContext.Provider>
    );
};

export { capacityChartsContext };
export default CapacityChartsProvider;
