import styles from './index.module.css';
import { isNumber, roundNumber } from "../../../../../utils";

const InitForces = ({ 
    initMed, 
    med, 
    medp,
    ned, 
    shearForce, 
    secondOreder, 
    ea,
    memberType
}) => {
    return ( 
        <div className={styles.wrapper}>
            <p>Siły wewnętrzne przyjęte do obliczeń</p>
            {memberType === 'Słup' && <p>Siła osiowa [N<sub>z,SGN</sub>]*: <span className={styles.value}>{roundNumber(ned,2)} kN</span></p>}
            {
                memberType !== 'Płyta' &&
                <p>Siła ścinająca [V<sub>y,SGN</sub>]: <span className={styles.value}>{roundNumber(shearForce,2)} kN</span></p>
            }
            <p>Moment zginający [M<sub>x,SGN</sub>]: <span className={styles.value}>{roundNumber(initMed,2)} kNm</span></p>
            {isNumber(medp) && <p> Moment zginający [M<sub>x,SGU</sub>]: <span className={styles.value}>{roundNumber(medp,2)} kNm</span></p>}
            {
                (secondOreder || ea) &&
                <p>Moment zginający II-go rzędu [M<sub>x,II,SGN</sub>]: <span className={styles.value}>{roundNumber(med,2)} kNm</span></p>
            }
            {memberType === 'Słup' && <p>*dodatnia siła osiowa jest siłą ściskającą</p>}
        </div>
    )
}

export default InitForces