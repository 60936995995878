const steelClasses = [
    {
        name: 'S235',
        fyk: 235
    },
    {
        name: 'S275',
        fyk: 275
    },
    {
        name: 'S355',
        fyk: 355
    },
    {
        name: 'S450',
        fyk: 440
    }
]

export default steelClasses;