const plateSteelGrades = {
    'S235JR/J0/J2': {
        40: {
            fy: 235,
        },
        80: {
            fy: 215,
        },
        100: {
            fy: 215,
        },
        150: {
            fy: 195,
        },
        200: {
            fy: 185,
        },
        250: {
            fy: 175,
        },
        400: {
            fy: 165,
        }
    },
    'S275JR/J0/J2': {
        16: {
            fy: 275,
        },
        40: {
            fy: 265,
        },
        63: {
            fy: 255,
        },
        80: {
            fy: 245,
        },
        100: {
            fy: 235,
        },
        150: {
            fy: 225,
        },
        200: {
            fy: 215,
        },
        250: {
            fy: 205,
        },
        400: {
            fy: 195,
        }
    },
    'S355JR/J0/J2/K2': {
        16: {
            fy: 355,
        },
        40: {
            fy: 345,
        },
        63: {
            fy: 335,
        },
        80: {
            fy: 325,
        },
        100: {
            fy: 315,
        },
        150: {
            fy: 295,
        },
        200: {
            fy: 285,
        },
        250: {
            fy: 275,
        },
        400: {
            fy: 265,
        }
    },
    'S460JR/J0/J2/K2': {
        16: {
            fy: 460,
        },
        40: {
            fy: 440,
        },
        63: {
            fy: 420,
        },
        80: {
            fy: 400,
        },
        150: {
            fy: 390,
        },
    },

    'S275N/NL': {
        16: {
            fy: 275,
        },
        40: {
            fy: 265,
        },
        63: {
            fy: 255,
        },
        80: {
            fy: 245,
        },
        100: {
            fy: 235,
        },
        150: {
            fy: 225,
        },
        200: {
            fy: 215,
        },
        250: {
            fy: 205,
        },
        400: {
            fy: 195,
        }
    },
    'S355N/NL': {
        16: {
            fy: 355,
        },
        40: {
            fy: 345,
        },
        63: {
            fy: 335,
        },
        80: {
            fy: 325,
        },
        100: {
            fy: 315,
        },
        150: {
            fy: 295,
        },
        200: {
            fy: 285,
        },
        250: {
            fy: 275,
        },
        400: {
            fy: 265,
        }
    },
    'S420N/NL': {
        16: {
            fy: 420,
        },
        40: {
            fy: 400,
        },
        63: {
            fy: 390,
        },
        80: {
            fy: 370,
        },
        100: {
            fy: 360,
        },
        150: {
            fy: 340,
        },
        200: {
            fy: 330,
        },
        250: {
            fy: 320,
        }
    },
    'S460N/NL': {
        16: {
            fy: 460,
        },
        40: {
            fy: 440,
        },
        63: {
            fy: 430,
        },
        80: {
            fy: 410,
        },
        100: {
            fy: 400,
        },
        150: {
            fy: 380,
        },
        200: {
            fy: 370,
        },
        250: {
            fy: 275,
        }
    },

    'S500J0': {
        16: {
            fy: 500,
        },
        40: {
            fy: 480,
        },
        63: {
            fy: 460,
        },
        150: {
            fy: 450,
        },
    },

    'S275M/ML': {
        16: {
            fy: 275,
        },
        40: {
            fy: 265,
        },
        63: {
            fy: 255,
        },
        80: {
            fy: 245,
        },
        100: {
            fy: 245,
        },
        150: {
            fy: 240,
        },
    },
    'S355M/ML': {
        16: {
            fy: 355,
        },
        40: {
            fy: 345,
        },
        63: {
            fy: 335,
        },
        80: {
            fy: 325,
        },
        100: {
            fy: 325,
        },
        150: {
            fy: 320,
        },
    },
    'S420M/ML': {
        16: {
            fy: 420,
        },
        40: {
            fy: 400,
        },
        63: {
            fy: 390,
        },
        80: {
            fy: 380,
        },
        100: {
            fy: 370,
        },
        150: {
            fy: 365,
        },
    },
    'S460M/ML': {
        16: {
            fy: 460,
        },
        40: {
            fy: 440,
        },
        63: {
            fy: 430,
        },
        80: {
            fy: 410,
        },
        100: {
            fy: 400,
        },
        150: {
            fy: 385,
        },
    },

    'S460Q/QL/QL1': {
        50: {
            fy: 460,
        },
        100: {
            fy: 440,
        },
        150: {
            fy: 400,
        },
    },
    'S500Q/QL/QL1': {
        50: {
            fy: 500,
        },
        100: {
            fy: 480,
        },
        150: {
            fy: 440,
        },
    },
    'S550Q/QL/QL1': {
        50: {
            fy: 550,
        },
        100: {
            fy: 530,
        },
        150: {
            fy: 490,
        },
    },
    'S620Q/QL/QL1': {
        50: {
            fy: 620,
        },
        100: {
            fy: 580,
        },
        150: {
            fy: 560,
        },
    },
    'S690Q/QL/QL1': {
        50: {
            fy: 690,
        },
        100: {
            fy: 650,
        },
        150: {
            fy: 630,
        },
    },
    'S890Q/QL/QL1': {
        50: {
            fy: 890,
        },
        100: {
            fy: 830,
        },
    },
    'S960Q/QL/QL1': {
        50: {
            fy: 960,
        },
    },
};

export default plateSteelGrades;