import { useCallback, useState } from 'react';
import { isEmail, plans, roles } from '../../../config/ProjectConfig';
import { AdminFetch } from '../../../routes';
import { isNumber, planCost, stringToNumber } from '../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const defaultUserRow = {
    rowNumber: {
        value: 1,
    },
    firstName: {
        value: '',
        isValid: null,
    },
    lastName: {
        value: '',
        isValid: null,
    },
    email: {
        value: '',
        isValid: null,
    },
    price: {
        value: '',
        isValid: null,
    },
    type: {
        value: '',
        isValid: null,
    },
    plan: {
        value: '',
        isValid: null,
    },
    freeAccount: {
        value: false,
        isValid: true,
    },
    totalPrice: {
        value: 0,
        isValid: null,
    },
    isActiveSubs: false,
    subUsers: [],
};
const subscription = {
    1: 'Subskrypcja miesięczna',
    2: 'Subskrypcja roczna',
};

const getKeyByValue = (object, value) =>
    Object.keys(object).find((key) => object[key] === value) || null;

const AddNewUsersService = ({
    selectedUsers,
    setLoading,
    setAlert,
    setUserListStateChanged,
    fetch,

    updateInvoiceState,
    invoiceState,
    invoiceType,
}) => {
    const [newUsersState, setNewUsersState] = useState([{ ...defaultUserRow }]);
    const [usersCost, setUsersCost] = useState(0);

    useDeepCompareEffect(() => {
        const currentUsers = selectedUsers.filter((ele) =>
            [roles[1], roles[2]].includes(ele.role),
        );
        if (currentUsers.length > 0) {
            setNewUsersState(
                currentUsers.map((ele, idx) => {
                    return {
                        rowNumber: {
                            value: idx + 1,
                        },
                        firstName: {
                            value: ele.firstName,
                            isValid: true,
                        },
                        lastName: {
                            value: ele.lastName,
                            isValid: true,
                        },
                        email: {
                            value: ele.email,
                            isValid: true,
                        },
                        freeAccount: {  
                            value: ele.subscription ? ele.subscription.freeAccount : false,
                            isValid: true,
                        },
                        price: {
                            value: '',
                            isValid: true,
                        },
                        type: {
                            value: ele.subscription
                                ? subscription[ele.subscription.plan]
                                : '',
                            isValid: true,
                        },
                        plan: {
                            value: ele.subscription
                                ? ele.subscription.plan
                                : '',
                            isValid: true,
                        },
                        totalPrice: {
                            value: 0,
                            isValid: true,
                        },
                        isActiveSubs: true,

                        subUsers: ele.subUsers.map((element) => {
                            return {
                                firstName: {
                                    value: element.firstName,
                                    isValid: true,
                                },
                                lastName: {
                                    value: element.lastName,
                                    isValid: true,
                                },
                                email: {
                                    value: element.email,
                                    isValid: true,
                                },
                                freeAccount: {
                                    value: element.subscription ? element.subscription.freeAccount : false,
                                    isValid: true,
                                },
                                price: {
                                    value: '',
                                    isValid: true,
                                },
                                isActiveSubs: true,
                                type: {
                                    value: subscription[
                                        element.subscription.plan
                                    ],
                                    isValid: true,
                                },
                                plan: {
                                    value: element.subscription.plan,
                                    isValid: true,
                                },
                            };
                        }),
                    };
                }),
            );
        } else {
            setNewUsersState([{ ...defaultUserRow }]);
        }
    }, [selectedUsers]);

    useDeepCompareEffect(() => {
        if (newUsersState.length > 0) {
            setNewUsersState((state) =>
                state.reduce((acc, ele) => {
                    let subUsersCost = 0;
                    if (ele.subUsers.length > 0) {
                        subUsersCost = ele.subUsers.reduce((acc, element) => {
                            if (isNumber(element.price.value)) {
                                return acc + element.price.value;
                            } else {
                                return acc + 0;
                            }
                        }, 0);
                    }
                    if (isNumber(ele.price.value)) {
                        return [
                            ...acc,
                            {
                                ...ele,
                                totalPrice: {
                                    value: subUsersCost + ele.price.value,
                                    isValid: true,
                                },
                            },
                        ];
                    } else {
                        return [
                            ...acc,
                            {
                                ...ele,
                                totalPrice: {
                                    value: subUsersCost,
                                    isValid: true,
                                },
                            },
                        ];
                    }
                }, []),
            );

            setUsersCost(
                newUsersState.reduce((acc, ele) => {
                    if (isNumber(ele.totalPrice.value)) {
                        return acc + ele.totalPrice.value;
                    } else if(ele.subUsers.length > 0) {
                        return acc + ele.subUsers.reduce((acc, ele) => {
                            if (isNumber(ele.price.value)) {
                                return acc + ele.price.value;
                            } else {
                                return acc + 0;
                            }
                        });
                    } else {
                        return acc + 0;
                    }
                },0)
            )
        }
    }, [newUsersState, newUsersState.length]);

    const onBlurNewUsersState = useCallback((e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if (input) {
            const { name, value } = input;
            const dataRow = Number(input.getAttribute('data-row'));
            const valid = input.checkValidity();

            setNewUsersState((state) =>
                state.map((element, index) => {
                    if (dataRow !== index) {
                        return element;
                    } else {
                        return {
                            ...element,
                            [name]: {
                                ...element[name],
                                isValid: valid,
                            },
                            ...(name === 'email' && {
                                email: {
                                    ...element[name],
                                    isValid: isEmail.test(value) && valid,
                                },
                            }),
                        };
                    }
                }),
            );
        }
    }, []);

    const onBlurSubusersState = useCallback((e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if (input) {
            const dataParent = Number(input.getAttribute('data-parent'));
            const dataRow = Number(input.getAttribute('data-row'));
            const valid = input.checkValidity();
            const { name, value } = input;

            setNewUsersState((state) =>
                state.map((element, index) => {
                    if (dataParent !== index) {
                        return element;
                    } else {
                        return {
                            ...element,
                            subUsers: element.subUsers.map((ele, idx) => {
                                if (dataRow !== idx) {
                                    return ele;
                                } else {
                                    return {
                                        ...ele,
                                        [name]: {
                                            ...ele[name],
                                            isValid: valid,
                                        },
                                        ...(name === 'email' && {
                                            email: {
                                                ...ele[name],
                                                isValid:
                                                    isEmail.test(value) &&
                                                    valid,
                                            },
                                        }),
                                    };
                                }
                            }),
                        };
                    }
                }),
            );
        }
    }, []);

    const onChangeNewUsersState = useCallback((e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if (input) {
            const dataRow = stringToNumber(input.getAttribute('data-row'));
            const { name, value, checked } = input;

            setNewUsersState((state) =>
                state.map((element, index) => {
                    if (dataRow !== index) {
                        return element;
                    } else {
                        if (name === 'type') {
                            const price = planCost({
                                plan: value,
                                role: roles[1],
                                plans: plans,
                                freeAccount: element.freeAccount.value,
                            });
                            return {
                                ...element,
                                [name]: {
                                    value: value,
                                    isValid: true,
                                },
                                plan: {
                                    value: Number(
                                        getKeyByValue(subscription, value),
                                    ),
                                    isValid: true,
                                },
                                price: {
                                    value: price,
                                    isValid: isNumber(price) ? true : false,
                                },
                            };
                        } else if (name === 'freeAccount') {
                            const price = planCost({
                                plan: element.type.value,
                                role: roles[1],
                                plans: plans,
                                freeAccount: checked,
                            });

                            return {
                                ...element,
                                [name]: {
                                    value: checked,
                                    isValid: true,
                                },
                                price: {
                                    value: price,
                                    isValid: isNumber(price) ? true : false,
                                },
                            };
                        } else {
                            return {
                                ...element,
                                [name]: {
                                    value: value,
                                    isValid: true,
                                },
                            };
                        }
                    }
                }),
            );
        }
    }, []);

    const onChangSubusersState = useCallback((e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if (input) {
            const dataParent = stringToNumber(
                input.getAttribute('data-parent'),
            );
            const dataRow = stringToNumber(input.getAttribute('data-row'));
            const { name, value, checked } = input;

            setNewUsersState((state) =>
                state.map((element, index) => {
                    if (dataParent !== index) {
                        return element;
                    } else {
                        return {
                            ...element,
                            subUsers: element.subUsers.map((ele, idx) => {
                                if (dataRow !== idx) {
                                    return ele;
                                } else {
                                    if (name === 'type') {
                                        const price = planCost({
                                            plan: value,
                                            role: roles[0],
                                            plans: plans,
                                            freeAccount: ele.freeAccount.value,
                                        });

                                        return {
                                            ...ele,
                                            [name]: {
                                                value: value,
                                                isValid: true,
                                            },
                                            plan: {
                                                value: Number(getKeyByValue(subscription,value)),
                                                isValid: true,
                                            },
                                            price: {
                                                value: price,
                                                isValid: isNumber(price)
                                                    ? true
                                                    : false,
                                            },
                                        };
                                    } else if (name === 'freeAccount') {
                                        const price = planCost({
                                            plan: ele.type.value,
                                            role: roles[0],
                                            plans: plans,
                                            freeAccount: checked,
                                        });
            
                                        return {
                                            ...ele,
                                            [name]: {
                                                value: checked,
                                                isValid: true,
                                            },
                                            price: {
                                                value: price,
                                                isValid: isNumber(price) ? true : false,
                                            },
                                        };
                                    } else {
                                        return {
                                            ...ele,
                                            [name]: {
                                                value: value,
                                                isValid: true,
                                            },
                                        };
                                    }
                                }
                            }),
                        };
                    }
                }),
            );
        }
    }, []);

    const addRowToNewUsersTable = () =>
        setNewUsersState((state) => {
            return [
                ...state,
                {
                    ...defaultUserRow,
                    rowNumber: {
                        value: state.length + 1,
                    },
                },
            ];
        });
    const removeRowFromNewUsersTable = (i) =>
        setNewUsersState((state) => {
            return [
                ...state
                    .filter((_, index) => index !== i)
                    .map((ele, idx) => ({
                        ...ele,
                        rowNumber: {
                            value: idx + 1,
                        },
                    })),
            ];
        });

    const removeSubuserRow = (pi, i) =>
        setNewUsersState((state) =>
            state.map((element, index) => {
                if (pi !== index) {
                    return element;
                } else {
                    return {
                        ...element,
                        subUsers: element.subUsers.filter(
                            (_, index) => index !== i,
                        ),
                    };
                }
            }),
        );

    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    }, []);

    const addSubuserToUser = (dataRow) => {
        setNewUsersState((state) =>
            state.map((element, index) => {
                const { subUsers, rowNumber, totalPrice, ...rest } =
                    defaultUserRow;
                if (dataRow !== index) {
                    return element;
                } else {
                    return {
                        ...element,
                        subUsers: [
                            ...element.subUsers,
                            {
                                ...rest,
                            },
                        ],
                    };
                }
            }),
        );
    };

    const onSubmit = () => {
        const { companyName, nip, firstName, lastName, ...rest } = invoiceState;
        const stateToValidation = newUsersState.map(
            ({ rowNumber, isActiveSubs, ...rest }) => ({ ...rest }),
        );
        const isValidForm = stateToValidation.every((element) => {
            return Object.entries(element).every(([key, value]) => {
                if (key === 'subUsers' && value.length > 0) {
                    return value.every((ele) =>
                        Object.values(ele).every((element) =>
                            element.hasOwnProperty('isValid')
                                ? element.isValid
                                : true,
                        ),
                    );
                } else {
                    return value.hasOwnProperty('isValid')
                        ? value.isValid
                        : true;
                }
            });
        });
        const activeSubsNumber = newUsersState.reduce((acc,ele) => {
            const activeSubsSubUsers = ele.subUsers.reduce((acc1,ele) => {
                if (!ele.isActiveSubs) {
                    return acc1 + 1;
                } else {
                    return acc1;
                }
            },0);
            if (!ele.isActiveSubs) {
                return acc + 1 + activeSubsSubUsers;
            } else {
                return acc + activeSubsSubUsers;
            }
        },0);

        const validFormObject = invoiceType === 1
            ? { companyName, nip, ...rest }
            : invoiceType === 2
            ? { firstName, lastName, ...rest }
            : {};

        const isFormValidInvoice = usersCost > 0 ? Object.values(validFormObject).every((ele) => ele.isValid) : true;

        if(activeSubsNumber > 0) {
            if (isValidForm && isFormValidInvoice) {
                setLoading({ name: 'addNewUsers', loading: true });

                const body = newUsersState.map((ele) =>
                    Object.entries(ele).reduce((acc, ele) => {
                        if (ele[0] === 'subUsers') {
                            return {
                                ...acc,
                                [ele[0]]: ele[1].map((ele) =>
                                    Object.entries(ele).reduce(
                                        (acc, ele) => ({
                                            ...acc,
                                            [ele[0]]: ele[1].value,
                                        }),
                                        {},
                                    ),
                                ),
                            };
                        } else {
                            return {
                                ...acc,
                                [ele[0]]: ele[1].value,
                            };
                        }
                    }, {}),
                );

                AdminFetch({
                    url: fetch.addNewUsers.url,
                    method: fetch.addNewUsers.method,
                    body: usersCost > 0 ? 
                        {
                            users: body,
                            usersCost,
                            invoiceType,
                            ...Object.entries(validFormObject).reduce(
                                (acc, [key, value]) => ({ ...acc, [key]: value.value }),
                                {},
                            ),
                        }
                        :
                        {
                            users: body,
                            usersCost,

                        }
                })
                .then(async (response) => {
                    setLoading({ name: 'addNewUsers', loading: false });
                    if (response.success) {
                        setUserListStateChanged(true);
                        setAlert({
                            error: false,
                            message: 'Użytkownicy zostali dodani!',
                            hideTime: 2000,
                        });
                        setNewUsersState([{ ...defaultUserRow }]);
                    } else {
                        setAlert({
                            error: true,
                            message: 'Błąd podczas dodawania użytkowników',
                        });
                    }
                })
                .catch(() => {
                    setLoading({ name: 'userSubscription', loading: false });
                    setAlert({
                        error: true,
                        message: 'Błąd podczas dodawania użytkowników',
                    });
                });
            } else {
                setAlert({
                    error: true,
                    message: 'Niepoprawne dane do dodania nowych użytkowników',
                });
                setNewUsersState((state) => {
                    return [
                        ...state.map((state) =>
                            Object.entries(state).reduce((acc, [key, value]) => {
                                if (key === 'subUsers') {
                                    return {
                                        ...acc,
                                        [key]: value.map((state) =>
                                            Object.entries(state).reduce(
                                                (acc, [key, value]) => {
                                                    if (
                                                        value.hasOwnProperty(
                                                            'isValid',
                                                        ) &&
                                                        value.hasOwnProperty(
                                                            'value',
                                                        )
                                                    ) {
                                                        return {
                                                            ...acc,
                                                            [key]: {
                                                                value: value.value,
                                                                isValid:
                                                                    !!value.isValid,
                                                            },
                                                        };
                                                    } else {
                                                        return {
                                                            ...acc,
                                                            [key]: value,
                                                        };
                                                    }
                                                },
                                                {},
                                            ),
                                        ),
                                    };
                                } else {
                                    if (
                                        value.hasOwnProperty('isValid') &&
                                        value.hasOwnProperty('value')
                                    ) {
                                        return {
                                            ...acc,
                                            [key]: {
                                                value: value.value,
                                                isValid: !!value.isValid,
                                            },
                                        };
                                    } else {
                                        return {
                                            ...acc,
                                            [key]: value,
                                        };
                                    }
                                }
                            }, {}),
                        ),
                    ];
                });

                if(usersCost > 0){
                    updateInvoiceState((state) => { 
                        return {
                            ...state,
                            ...Object.entries(validFormObject).reduce(
                                (acc, [key, value]) => ({
                                    ...acc,
                                    [key]: {
                                        value: value.value,
                                        isValid: !!value.isValid,
                                    },
                                }),
                                {},
                            ),
                        };
                    });
                }
            }
        } else {
            setAlert({
                error: true,
                message: 'Nie wybrano żadnego użytkownika do aktywacji subskrypcji',
            });
        }
    };

    const addNewUsers = {
        onBlurNewUsersState,
        onBlurSubusersState,
        onChangeNewUsersState,
        onChangSubusersState,
        addRowToNewUsersTable,

        removeRowFromNewUsersTable,
        removeSubuserRow,

        addSubuserToUser,
        onKeyDown,

        onSubmit,

        state: {
            newUsersState,
            usersCost
        },
    };

    return {
        addNewUsers,
    };
};

export default AddNewUsersService;
