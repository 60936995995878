import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    Checkbox,
    InputField,
    SelectField,
    SectionTitle,
    RadioInput,
} from '../../../../components';
import styles from './index.module.css';
import stringify from 'fast-json-stable-stringify';

const PileParameters = ({ 
    onChange,
    onBlur,
    onKeyDown,
    pileTypes,
    layersHeight,
    pileTechnologies,
    pileShape,
    pileTypeShape,
    state = {},
}) => { 
    return (
        <Fragment>
            <SectionTitle title="Parametry pala" />
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <SelectField
                        title="Rodzaj pala"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={state.pileType.value}
                        defaultOption="Wybierz rodzaj pala"
                        isValid={state.pileType.isValid}
                        options={pileTypes}
                        name="pileType"
                        infoCss={styles.infoCss}
                        info={
                            <Fragment>
                                Zgodnie z normą PN-83/B-2482 zastosowano współczynniki technologiczne dla pali:
                                <ul>
                                    <li>Żelbetowych prefabrykowanych</li>
                                    <li>Franki</li>
                                    <li>Vibro</li>
                                    <li>Wierconych bez iniekcji pod podstawą</li>
                                    <li>Stalowych z dnem zamkniętym</li>
                                    <li>Stalowych z profili</li>
                                    <li>Wierconych bez iniekcji pod podstawą</li>
                                </ul>
                                Dla pali CFA oraz Strasol zastosowano współczynniki jak dla pali wierconych głowicą pokrętną.<br></br>
                                Dla pali przemieszczeniowych wkręcanych (Omega, Atlas, FDP, SDP) oraz wierconych PCS Lambda zastosowano współczynniki pod podstawą jak dla pali wierconych głowicą pokrętną oraz na pobocznicy jak dla pali Vibro.<br></br><br></br>
                                *W oknie "Współczynniki technologiczne i bezpieczeństwa" można modyfikować powyższe współczynniki technologiczne.
                            </Fragment>
                        }
                        required
                    />
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <SelectField
                        name="pileTechnology"
                        title="Technologia wykonania"
                        value={state.pileTechnology.value}
                        defaultOption="Wybierz technologię pala"
                        isValid={state.pileTechnology.isValid}
                        options={pileTechnologies}
                        onBlur={onBlur}
                        onChange={onChange}
                        required
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        onBlur={onBlur}
                        name="diameterPile"
                        type="number"
                        value={state.diameterPile.value}
                        isValid={state.diameterPile.isValid}
                        title={`${
                            state.pileShape.value === 'Kołowy'
                                ? 'Średnica'
                                : 'Szerokość boku'
                        } pala [m]`}
                        placeholder="Wpisz średnicę pala"
                        min={0}
                        step={0.001}
                        required
                    />
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <SelectField
                        name="pileShape"
                        title="Kształt przekroju pala"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={state.pileShape.value}
                        isValid={state.pileShape.isValid}
                        defaultValue={state.pileShape.value}
                        defaultOption={state.pileShape.value}
                        options={pileTypeShape.filter(ele => ele !== state.pileShape.value)}
                        required
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        name="pileHeadSpot"
                        type="number"
                        data-height={stringify(layersHeight)}
                        value={state.pileHeadSpot.value}
                        isValid={state.pileHeadSpot.isValid}
                        title="Rzędna głowicy pala [m.p.p.t]"
                        onChange={onChange}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        placeholder="Rzędna głowicy pala"
                        required
                    />
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        name="pileLength"
                        type="number"
                        data-height={stringify(layersHeight)}
                        value={state.pileLength.value}
                        isValid={state.pileLength.isValid}
                        title="Długość pala [m]"
                        onChange={onChange}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        placeholder="Wpisz długość pala"
                        min={0}
                        step={0.001}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <Checkbox
                        onChange={onChange}
                        title="Poszerzona podstawa"
                        name="isBaseWidth"
                        label={`${state.isBaseWidth.value ? '' : 'Nie'}`}
                        value={state.isBaseWidth.value}
                    />
                </Col>
                {state.isBaseWidth.value && (
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <InputField
                            name="baseWidth"
                            type="number"
                            value={state.baseWidth.value}
                            isValid={state.baseWidth.isValid}
                            title="Średnica/szerokość podstawy [m]"
                            onChange={onChange}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            placeholder="Wpisz wymiar podstawy"
                            min={0}
                            step={0.001}
                            required
                        />
                    </Col>
                )}
            </Row>
            <Row>
                <Col
                    xs={12}
                    sm={6}
                    className={`${styles.columnSpace} ${styles.flexEnd} ${styles.flexColumn}`}>
                    <Checkbox
                        onChange={onChange}
                        title="Parametry fundamentu palowego"
                        name="singlePile"
                        label={'Pal pojedynczy'}
                        value={state.singlePile.value}
                    />
                </Col>
                <Col
                    xs={12}
                    sm={6}
                    className={`${styles.columnSpace} ${styles.flexEnd}`}>
                    <Checkbox
                        onChange={onChange}
                        name="pileGroup"
                        label={'Pal w grupie'}
                        value={state.pileGroup.value}
                    />
                </Col>
            </Row>
            {state.singlePile.value && (
                <Fragment>
                    <Row>
                        <Col
                            xs={12}
                            sm={4}
                            className={`${styles.columnSpace} ${styles.flexEnd}`}>
                            <RadioInput
                                onChange={onChange}
                                title="Oparcie fundamentu na"
                                name="pilesInFoundation"
                                label={'1 palu'}
                                checked={state.pilesInFoundation.value === 1}
                                value={1}
                            />
                        </Col>
                        <Col
                            xs={12}
                            sm={4}
                            className={`${styles.columnSpace} ${styles.flexEnd}`}>
                            <RadioInput
                                onChange={onChange}
                                name="pilesInFoundation"
                                label={'2 palach'}
                                checked={state.pilesInFoundation.value === 2}
                                value={2}
                            />
                        </Col>
                        <Col
                            xs={12}
                            sm={4}
                            className={`${styles.columnSpace} ${styles.flexEnd}`}>
                            <RadioInput
                                onChange={onChange}
                                name="pilesInFoundation"
                                label={'> 2 palach'}
                                checked={state.pilesInFoundation.value === 3}
                                value={3}
                            />
                        </Col>
                    </Row>
                </Fragment>
            )}
            {state.pileGroup.value && (
                <Fragment>
                    <Row>
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField
                                name="pileSpace"
                                type="number"
                                value={state.pileSpace.value}
                                isValid={state.pileSpace.isValid}
                                title="Rozstaw pali [m]"
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                placeholder="Rozstaw pali"
                                min={0}
                                step={0.001}
                                required
                            />
                        </Col>
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField
                                name="groupPilesNumber"
                                type="number"
                                value={state.groupPilesNumber.value}
                                isValid={state.groupPilesNumber.isValid}
                                title="Liczba pali w grupie [m]"
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                placeholder="Liczba pali"
                                min={0}
                                required
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField
                                name="groupLength"
                                type="number"
                                value={state.groupLength.value}
                                isValid={state.groupLength.isValid}
                                title="Długość grupy pali [m]"
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                placeholder="Długość grupy pali"
                                min={0}
                                step={0.001}
                                required
                            />
                        </Col>
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField
                                name="groupWidth"
                                type="number"
                                value={state.groupWidth.value}
                                isValid={state.groupWidth.isValid}
                                title="Szerokość grupy pali [m]"
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                placeholder="Szerokość grupy pali"
                                min={0}
                                step={0.001}
                                required
                            />
                        </Col>
                    </Row>
                </Fragment>
            )}
        </Fragment>
    );
};

export default PileParameters;
