import React, { useState } from 'react';
import { withDefaultPrintChart } from '../../../../../hoc';
import { isNumber, roundNumber } from '../../../../../utils';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';
import ApexCharts from "apexcharts";

const TcdChart = ({
    results = [],
    chartTitle = '',
    yLabel,
    xLabel,
    chartOptions,
    setDataURI
}) => {
    const [options, setOptions] = useState(chartOptions);
    const [series, setSeries] = useState([
        {
            name: '',
            data: [],
        },
    ]);

    useDeepCompareEffect(() => {
        setSeries(() => {
            return [
                {
                    name: 'Z',
                    data: [
                        ...results.reduce((acc, ele, index, arr) => {
                            if (index === arr.length - 1) {
                                return [...acc, [ele.tcd, ele.z], [0, ele.z]];
                            } else {
                                if (isNumber(ele.tcd1)) {
                                    return [
                                        ...acc,
                                        [ele.tcd, ele.z],
                                        [ele.tcd1, ele.z],
                                    ];
                                } else {
                                    return [...acc, [ele.tcd, ele.z]];
                                }
                            }
                        }, []),
                    ],
                },
            ];
        });

        setOptions((state) => {
            const maxZ = Math.max(...results.map((ele) => ele.z));

            return {
                ...chartOptions,
                title: {
                    ...chartOptions['title'],
                    text: chartTitle,
                },
                yaxis: {
                    ...chartOptions['yaxis'],
                    type: 'numeric',
                    tickAmount: Math.ceil(maxZ / 2),
                    max: maxZ,

                    title: {
                        ...state['yaxis']['title'],
                        text: yLabel,
                    },
                },
                xaxis: {
                    ...chartOptions['xaxis'],
                    max:
                        Math.max(
                            ...results.reduce(
                                (acc, ele) => [...acc, ele.tcd],
                                [],
                            ),
                        ),

                    title: {
                        ...state['xaxis']['title'],
                        offsetY: 30,
                        text: xLabel,

                    },
                    labels: {
                        ...state['xaxis']['labels'],
                        offsetY: 10
                    },
                },
                dataLabels: {
                    ...chartOptions['dataLabels'],
                    offsetY: 0,
                    offsetX: 0,
                    formatter: function (x, opt) {
                        const currentVal = roundNumber(opt.w.globals.initialSeries[0].data[opt.dataPointIndex][0],0);
                        const prevVal = opt.dataPointIndex > 1 ? roundNumber(opt.w.globals.initialSeries[0].data[opt.dataPointIndex - 2][0],0) : 0;
    
                        return currentVal === 0
                            ? 
                            ''
                            :
                            opt.dataPointIndex % 2 === 0
                            ?
                                currentVal === prevVal
                                ?
                                ''
                                :
                                currentVal
                            :
                            ''
                    },
                },
                fill: {
                    ...chartOptions['fill'],
                    opacity: 0.9,
                    colors: ['#ddd'],
                },
                stroke: {
                    ...chartOptions['stroke'],
                    colors: ['#bbb'],
                },
            };
        });
    }, [chartOptions, results, chartTitle, xLabel, yLabel]);

    useDeepCompareEffect(() => {
        let chart = new ApexCharts(document.querySelector("#tcdChart"), {
            series: series,
            ...options,
            chart: {
                ...options['chart'],
                type: 'area'
            }
        });
        chart.render();

        chart.dataURI().then(({ imgURI }) => {
            setDataURI(imgURI)
        });

        return () => chart.destroy();
    },[series, options]);

    return (
        <div id="tcdChart"></div>
    );
};

export default React.memo(
    withDefaultPrintChart(TcdChart),
    (prev, next) => stringify(prev) === stringify(next),
);