import React from 'react';
import { Col } from 'react-bootstrap';
import { SectionTitle, InputField } from '../../../../components';
import styles from './index.module.css';

const ProjectTitle = ({ onChange, state={} }) => {

    return ( 
        <Col xs={12} className={styles.columnSpace}>
            <SectionTitle title="Tytuł projektu"/>
            <InputField name="projectTitle" placeholder="Tytuł projektu" value={state.projectTitle} onChange={onChange}/>            
        </Col>
    )
}

export default ProjectTitle