import { useContext, useEffect } from "react";
import { verticalCapacityContext } from "../../../context/VerticalCapacity";
import config from "../../../config/config";
import PrintVerticalCapacityResults from "./PrintResults";
import DefaultNavigation from "../DefaultNavigation";

const VerticalCapacityNavigation = ({ report }) => {
    const VerticalCapacityContext = useContext(verticalCapacityContext);
    const { routes } = config;

    useEffect(() => {
        document.title = `Nośność pionowa - ${VerticalCapacityContext.fileNameToSave.fileName.value}`;
 
        return () => {
            document.title = '';
        }
    },[VerticalCapacityContext.fileNameToSave.fileName.value])
 
    return (
        <DefaultNavigation
            title='Nośność pionowa pala'
            subtitle='Nośność pionowa fundamentu palowego wg PN-83/B-2482 z uwzględnieniem Eurokodu 7'
            modelRoute={routes.dashboard.verticalCapacityModel}
            resultsRoute={routes.dashboard.verticalCapacityResults}
            context={VerticalCapacityContext}
        >
            {
                VerticalCapacityContext.results.state.isResultsActual && VerticalCapacityContext.results.isResultsActive &&
                <PrintVerticalCapacityResults
                    report={report}
                    isResultsActual={VerticalCapacityContext.results.state.isResultsActual}
                    printResults={VerticalCapacityContext.printResults}
                />                    
            }
        </DefaultNavigation>
    )
}

export default VerticalCapacityNavigation