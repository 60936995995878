import { useState } from 'react';
import { roundNumber } from '../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const designSoilParametersProp = [
    'cohesion',
    'density',
    'plasticity',
    'e0',
    'fi',
    'h',
    'hz',
    'kph',
    'kx_1',
    'kx_2',
    'ltf',
    'name',
    'pileFi',
    'qgr_1',
    'qgr_2',
    'sn',
    'weight',
    'z',
    'zc',
];
const parametersDetailsProp = ['h', 'kx', 'name', 'rgr', 'z'];
const internalForcesProp = ['bendingMoments', 'shearForces', 'displacements', 'plasticDisplacements'];

const ResultsHelpers = () => {
    const [resultsState, setResultsState] = useState({});
    const [resultsActive, setResultsActive] = useState(false);

    useDeepCompareEffect(() => {
        const isSoilParametersValid =
            Array.isArray(resultsState.designSoilParameters) &&
            resultsState.designSoilParameters
                .map((ele) =>
                    designSoilParametersProp.every((val) =>
                        ele.hasOwnProperty(val),
                    ),
                )
                .every((val) => !!val);
        const isParametersDetailsValid =
            Array.isArray(resultsState.parametersDetails) &&
            resultsState.parametersDetails
                .map((ele) =>
                    parametersDetailsProp.every((val) =>
                        ele.hasOwnProperty(val),
                    ),
                )
                .every((val) => !!val);
        const isInternalForcesValid =
            resultsState.internalForces &&
            Object.entries(resultsState.internalForces)
                .map(
                    ([key, val]) =>
                        internalForcesProp.every((_, idx, arr) =>
                            arr.includes(key),
                        ) &&
                        Array.isArray(val) &&
                        val.length > 0,
                )
                .every((val) => !!val);
        const isRestParametersValid =
            resultsState.restParameters &&
            resultsState.restParameters.hasOwnProperty('waterLevel');

        if (isInternalForcesValid) {
            setResultsState((state) => ({
                ...state,
                internalForcesDetails: Array(
                    resultsState.internalForces.bendingMoments.length,
                )
                    .fill('')
                    .reduce((acc, ele, idx) => {
                        return [
                            ...acc,
                            {
                                bendingMoment: roundNumber(
                                    resultsState.internalForces
                                        .bendingMoments[idx],
                                    2,
                                ),
                                shearForce: roundNumber(
                                    resultsState.internalForces
                                        .shearForces[idx],
                                    2,
                                ),
                                displacement: roundNumber(
                                    resultsState.internalForces
                                        .displacements[idx],
                                    2,
                                ),
                                z: roundNumber(idx * 0.1, 2),
                            },
                        ];
                    }, []),
            }));
        }

        setResultsActive(
            isSoilParametersValid &&
                isParametersDetailsValid &&
                isRestParametersValid &&
                isInternalForcesValid,
        );
    }, [resultsState]);

    const results = {
        state: {
            ...resultsState,
        },
        isResultsActive: resultsActive,
        initState: () => {
            setResultsState({});
            setResultsActive(false);
        },
        updateState: setResultsState,
    };

    return {
        results,
    };
};

export default ResultsHelpers;
