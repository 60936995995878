import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useState } from 'react';
import styles from './index.module.css';

const InputField = ({ 
    placeholder, 
    name, 
    type='text', 
    textarea=false,
    value='', 
    title, 
    isValid, 
    isWarning, 
    warning, 
    invalidText='', 
    onChange, 
    required, 
    css='',
    infoCss='',
    disable=false, 
    children,
    ...rest 
}) => {

    const [lockInput, setLockInput] = useState(true);

    const onLockInput = () => setLockInput(state => !state);

    return (
        <Fragment>
            <div className={styles.wrapper}>
                <div className={styles.titleWrapper}>
                    {title && <label className={styles.title}>{title}</label>}

                    {
                        children &&
                        <Fragment>
                            <FontAwesomeIcon className={styles.infoIcon} icon={faQuestionCircle}/>
                            <div className={`${styles.info} ${infoCss} defaultBox`}>
                                {children}
                            </div> 
                        </Fragment>
                    }
                </div>
                <div className={`${styles.group} ${ isWarning ? styles.groupIsWarning : '' } ${ isValid === false ? styles.groupInvalid : ''}`}>
                    {
                        disable
                        ?
                            <Fragment>
                                <div className={`lock-input`}> 
                                    <div className={styles.fullWidth}>
                                        <input
                                            className={styles.inputField}
                                            type={type} 
                                            name={name}
                                            placeholder={placeholder} 
                                            value={value} 
                                            onChange={onChange} 
                                            required={required}
                                            disabled={lockInput}
                                            onFocus={(e) => e.target.addEventListener("wheel", (e) => { e.preventDefault() }, { passive: false })}
                                            {...rest}
                                        />                                    
                                    </div>
                                    <div className={`${styles.groupIcon}`} onClick={onLockInput}>
                                        <FontAwesomeIcon icon={lockInput ? faLock : faLockOpen} />
                                    </div>     
                                </div>                           
                            </Fragment>
                        :
                            <Fragment>
                                {
                                    textarea
                                    ?
                                    <textarea
                                        className={`${styles.textarea} ${css}`}
                                        type={type}
                                        name={name} 
                                        placeholder={placeholder} 
                                        value={value} 
                                        onChange={onChange} 
                                        onFocus={(e) => e.target.addEventListener("wheel", (e) => { e.preventDefault() }, { passive: false })}
                                        required={required}
                                        {...rest}
                                    />
                                    :
                                    <input
                                        className={`${styles.textarea} ${css}`}
                                        type={type}
                                        name={name} 
                                        placeholder={placeholder} 
                                        value={value} 
                                        onChange={onChange}
                                        onFocus={(e) => e.target.addEventListener("wheel", (e) => { e.preventDefault() }, { passive: false })} 
                                        required={required}
                                        {...rest}
                                    />                                    
                                }
                            </Fragment>
                    }
                </div>
                {
                    invalidText && required && (isValid === false && <ul className={`${styles.ulList} ${styles.ulListInvalid}`}><li>{invalidText}</li></ul>)
                }
                {
                    isWarning && <ul className={`${styles.ulList} ${styles.ulListIsWarning}`}><li>{warning}</li></ul>
                }
            </div>
        </Fragment>
    )
}

export default React.memo(InputField)