import stringify from 'fast-json-stable-stringify';
import React, { Fragment } from 'react';
import { SelectField, Table, TableInput, Modal, Button, SectionTitle } from '../../../../../components';
import styles from './index.module.css';

const DefaultSoil = ({ open, onHide, onSubmit, state, soilTypes, ashFiTypes, cohesiveSoilType, rowIndex, onChange, onBlur, onKeyDown }) => {

    return (
        <Modal title={'Domyślne parametry warstwy gruntu'} hideButton={false} cssDialog={styles.dialogModal} open={open} onHide={onHide}>
            <SectionTitle
                title="Dobór parametrów sondowania CPT na podstawie Id/Il"
                infoCss={styles.infoCss}
            >
                Parametry sondowania CPT przyjęto na podstawie normy PN-B-04452:2002.<br></br><br></br>

                Uwaga:<br></br>
                Poprawna interpretacja wyników sondowań CPT charakteryzuje się dużą lokalnością w zależności od regionu. Nie istnieją uniwersalne wzory odpowiednie dla całego kraju, dlatego powyższy dobór parametrów należy traktować jako orientacyjny i każdorazowo wykonać porównawcze badania in-situ.
            </SectionTitle>
            <Table>
                <thead>
                    <tr>
                        <th title="Rodzaj gruntu">Rodzaj gruntu<br></br>[-]</th>
                        {
                            state.type.value === 1 &&
                            <Fragment>
                                <th width={200} title="Typ gruntu spoistego">Typ gruntu spoistego<br></br>[-]</th>
                                <th title="Zawartość frakcji ilastej">Frakcja ilasta<br></br>[-]</th>
                            </Fragment>
                            
                        }
                        <th title={state.type.value === 0 ? 'Stopień zagęszczenia' : "Stopień plastyczności"}>
                            {
                                state.type.value === 0
                                ?
                                    <Fragment>
                                        I<sub>d</sub><br></br>[-]
                                    </Fragment>
                                :
                                    <Fragment>
                                        I<sub>L</sub><br></br>[-]
                                    </Fragment>
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className={styles.tr}>
                        <td>
                            <SelectField
                                name="type" 
                                type='number'
                                value={soilTypes[state.type.value]}
                                isValid={state.type.isValid}
                                defaultOption="Wybierz rodzaj gruntu" 
                                options={Object.values(soilTypes)} 
                                onChange={onChange} 
                                onBlur={onBlur}

                                required
                            /> 
                        </td>
                        {
                            state.type.value === 1 &&
                            <Fragment>
                                <td>
                                    <SelectField
                                        name="cohesiveType" 
                                        type='number'
                                        value={cohesiveSoilType[state.cohesiveType.value]}
                                        isValid={state.cohesiveType.isValid}
                                        defaultOption="Wybierz typ gruntu" 
                                        options={Object.values(cohesiveSoilType)} 
                                        onChange={onChange} 
                                        onBlur={onBlur}

                                        required
                                    /> 
                                </td>
                                <td>
                                    <SelectField
                                        name="ashFi"
                                        value={state.ashFi.value}
                                        isValid={state.ashFi.isValid}
                                        defaultValue={ashFiTypes[3]}
                                        options={Object.values(ashFiTypes)}
                                        onChange={onChange}
                                        onBlur={onBlur}

                                        required
                                    />
                                </td>
                            </Fragment>
                        }
                        <td>
                            <TableInput
                                name="densityOrPlasticity" 
                                type="number" 
                                placeholder={`${state.type.value === 0 ? 'Stopień zagęszczenia' : 'Stopień plastczności'}`}
                                align={"center"} 
                                onChange={onChange} 
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                value={state.densityOrPlasticity.value}
                                isValid={state.densityOrPlasticity.isValid}
                                
                                required
                            />
                        </td>
                    </tr>
                </tbody>
            </Table>         
                <div className={styles.floatWrapper}>
                    <Button css={`${styles.button} default-button-style`} onClick={() => onSubmit(state, rowIndex)} altTitle="Dodaj" title="Dodaj warstwę"/>
                </div>
        </Modal>

    )
}

export default React.memo(DefaultSoil, (prev, next) => stringify(prev) === stringify(next))