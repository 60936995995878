import { useState } from 'react';
import { AuthFetch } from '../../../routes';

const initState = {
    currentPassword: {
        value: '',
        isValid: null,
    },
    newPassword: {
        value: '',
        isValid: null,
    },
    confirmPassword: {
        value: '',
        isValid: null,
    },
};
const ChangePasswordService = ({ fetch, setAlert, setLoading }) => {
    const [changePassword, setChangePassword] = useState(initState);

    const onChange = (e, isValid) => {
        const target = e.currentTarget.closest('input');
        const { name, value } = target;
        const valid = target.checkValidity();

        setChangePassword((state) => ({
            ...state,
            [name]: {
                value: value,
                isValid: isValid !== undefined ? isValid : valid,
            },
            ...(name === 'newPassword' && {
                confirmPassword: {
                    ...changePassword['confirmPassword'],
                    isValid:
                        state.confirmPassword.value.length > 0
                            ? value === state.confirmPassword.value
                            : state.confirmPassword.isValid,
                },
                newPassword: {
                    value: value,
                    isValid: valid && isValid,
                },
            }),
        }));
    };

    const onConfirmPassword = (e) => {
        const target = e.currentTarget.closest('input');
        let isPasswordCorrect = !!(
            target.checkValidity() &&
            target.value === changePassword.newPassword.value
        );
        onChange(e, isPasswordCorrect);
    };

    const onSubmit = () => {
        const isValidForm = Object.values(changePassword).every(
            (ele) => ele.isValid,
        );

        if (isValidForm) {
            setLoading({ name: 'saveNewPassword', loading: true });
            AuthFetch({
                url: fetch.changePassword.url,
                method: fetch.changePassword.method,
                body: {
                    ...Object.entries(changePassword).reduce(
                        (acc, [key, value]) => ({ ...acc, [key]: value.value }),
                        {},
                    ),
                },
            })
                .then((response) => {
                    setLoading({ name: 'saveNewPassword', loading: false });
                    if (
                        response.responseData.success &&
                        response.status === 200
                    ) {
                        setAlert({
                            error: false,
                            message: 'Hasło zostało zmienione!',
                            hideTime: 2000,
                        });
                        setChangePassword(initState);
                    } else {
                        if (response.status === 401) {
                            setAlert({
                                error: true,
                                message: 'Nie znaleziono użytkownika',
                            });
                        } else if (response.status === 403) {
                            setAlert({
                                error: true,
                                message: 'Błędne aktualne hasło',
                            });
                        } else {
                            setAlert({
                                error: true,
                                message: 'Błąd podczas zmiany hasła',
                            });
                        }
                    }
                })
                .catch((error) => {
                    setAlert({
                        error: true,
                        message: 'Błąd podczas zmiany hasła',
                    });
                    setLoading({ name: 'saveNewPassword', loading: false });
                });
        } else {
            setAlert({
                error: true,
                message: 'Niepoprawne dane do zmiany hasła',
            });
            setChangePassword((state) => {
                return {
                    ...Object.entries(state).reduce(
                        (acc, [key, value]) => ({
                            ...acc,
                            [key]: {
                                value: value.value,
                                isValid: !!value.isValid,
                            },
                        }),
                        {},
                    ),
                };
            });
        }
    };
    return {
        changePassword: {
            onChange,
            onConfirmPassword,
            onSubmit,

            state: {
                changePassword,
            },
        },
    };
};

export default ChangePasswordService;
