import React, { Fragment, useState } from 'react';
import { withPagination } from '../../../../../hoc';
import {
    ItemsPerPage,
    Pagination,
    SectionTitle,
    Table,
} from '../../../../../components';
import styles from './index.module.css';
import useDeepCompareEffect from 'use-deep-compare-effect';

const DesignSoilValues = ({
    dataToPaginate,
    allCollection = [],
    pagination,
    paginationHelpers,
}) => {
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [csvData, setCsvData] = useState([]);

    useDeepCompareEffect(() => {
        setCsvData(
            allCollection.map((ele) =>
                Object.fromEntries(
                    Object.entries(ele).map(([key, value]) => [
                        [key],
                        value.toString().replace('.', ','),
                    ]),
                ),
            ),
        );

        setCsvHeaders([
            {
                label: 'Nazwa',
                key: 'name',
            },
            {
                label: 'Z [m.p.p.t.]',
                key: 'z',
            },
            {
                label: 'H [m]',
                key: 'h',
            },
            {
                label: 'Hz [m]',
                key: 'hz',
            },
            {
                label: 'Zc [m]',
                key: 'zc',
            },
            {
                label: '\u03b3 [kN/m3]',
                key: 'weight',
            },
            {
                label: 'c [kPa]',
                key: 'cohesion',
            },
            {
                label: 'Id [-]',
                key: 'density',
            },
            {
                label: 'Il [-]',
                key: 'plasticity',
            },
            {
                label: '\u03c6 [\u2070]',
                key: 'fi',
            },
            {
                label: '\u03b4 [\u2070]',
                key: 'pileFi',
            },
            {
                label: 'E0 [MPa]',
                key: 'e0',
            },
            {
                label: 'Sn [-]',
                key: 'sn',
            },
            {
                label: '\u03a6 [-]',
                key: 'ltf',
            },
            {
                label: 'Kph [-]',
                key: 'kph',
            },
            {
                label: 'Kx1 [kPa]',
                key: 'kx_1',
            },
            {
                label: 'Kx2 [kPa]',
                key: 'kx_2',
            },
            {
                label: 'Qgr1 [kN/m]',
                key: 'qgr_1',
            },
            {
                label: 'Qgr2 [kN/m]',
                key: 'qgr_2',
            },
        ]);
    }, [allCollection]);

    return (
        <Fragment>
            <SectionTitle
                title={
                    'Parametry gruntowe oraz współczynniki technologiczne - wartości obliczeniowe'
                }>
                <p>
                    Warstwa gruntowa może zostać podzielona, ze względu na
                    "przecinające" ją ZWG lub/i "znajdujący" się w niej poziom
                    interpolacji H<sub>z</sub>. Ze względu na różne ciężary
                    gruntu z uwzględnieniem wyporu wody oraz bez dla warstwy
                    "przeciętej" obliczane są różne poziomy interpolacji oraz
                    zakresy modułów reakcji poziomej gruntu K<sub>x1</sub> oraz
                    K<sub>x2</sub>.
                </p>
            </SectionTitle>
            <ItemsPerPage
                onDisplayItemsNumber={paginationHelpers.displayItemsNumber}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                display={[15, 30, paginationHelpers.allItemsLength]}
            />
            <Table
                csvData={csvData}
                csvFilename={'SoilsDetails'}
                csvHeaders={csvHeaders}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th width={200}>
                            Nazwa gruntu<br></br>[-]
                        </th>
                        <th title="Rzędna">
                            Z<br></br>[m.p.p.t]
                        </th>
                        <th title="Grubość warstwy">
                            H<br></br>[m]
                        </th>
                        <th title="Głębokość krytyczna">
                            Z<sub>c</sub>
                            <br></br>[m]
                        </th>
                        <th title="Ciężar objętościowy gruntu z uwzgl. wyporu wody">
                            &#947; <br></br>[kN/m<sup>3</sup>]
                        </th>
                        <th title="Spójność gruntu">
                            c<br></br>[kPa]
                        </th>
                        <th title="Kąt tarcia wewnętrznego">
                            &#966; <br></br>[&#176;]
                        </th>
                        <th title="Kąt tarcia o pobocznicę pala">
                            &#948; <br></br>[&#176;]
                        </th>
                        <th title="Moduł odkształcenia pierwotnego gruntu">
                            E<sub>0</sub>
                            <br></br>[MPa]
                        </th>
                        <th title="Współczynnik zależny od technologii pogrążania pali">
                            S<sub>n</sub>
                            <br></br>[-]
                        </th>
                        <th title="Współczynnik obciążeń długotrwałych i powtarzalnych">
                            &#934; <br></br>[-]
                        </th>
                        <th title="Współczynnik odporu gruntu">
                            K<sub>ph</sub>
                            <br></br>[-]
                        </th>
                        <th title="Moduł poziomej reakcji podłoża w stropie warstwy">
                            K<sub>x1</sub>
                            <br></br>[kPa]
                        </th>
                        <th title="Moduł poziomej reakcji podłoża w spągu warstwy">
                            K<sub>x2</sub>
                            <br></br>[kPa]
                        </th>
                        <th title="Reakcja graniczna w stropie warstwy">
                            Q<sub>gr1</sub>
                            <br></br>[kN/m]
                        </th>
                        <th title="Reakcja graniczna w spągu warstwy">
                            Q<sub>gr2</sub>
                            <br></br>[kN/m]
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {dataToPaginate.map((element, index) => {
                        return (
                            <tr className={styles.tr} key={index}>
                                <td>
                                    {paginationHelpers.countingItemsPerPage +
                                        index +
                                        1}
                                </td>
                                <td className={styles.tdLeft}>
                                    {element.name}
                                </td>
                                <td>{element.z}</td>
                                <td>{element.h}</td>
                                <td>{element.zc}</td>
                                <td>{element.weight}</td>
                                <td>{element.cohesion}</td>
                                <td>{element.fi}</td>
                                <td>{element.pileFi}</td>
                                <td>{element.e0}</td>
                                <td>{element.sn}</td>
                                <td>{element.ltf}</td>
                                <td>{element.kph}</td>
                                <td>{element.kx_1}</td>
                                <td>{element.kx_2}</td>
                                <td>{element.qgr_1}</td>
                                <td>{element.qgr_2}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <Pagination
                currentPage={pagination.currentPage}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                nextPage={paginationHelpers.nextPage}
            />
        </Fragment>
    );
};

export default withPagination({
    component: DesignSoilValues,
    returnAllCollection: true,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 25,
    },
});
