import { useCallback, useMemo, useState } from "react";
import { defaultPNBSoils, noncohesiveMoistures } from "../../../config/ProjectConfig";
import { cohesionByGenesis, cohesiveSoilWeight, edometricModulesByGenesis, edometricModulusNoncohesiveSoils, frictionAngleByGenesis, frictionAngleNoncohesiveSoils, noncohesiveSoilWeight } from '../../../utils';


const defaultPNBSoilObj = {
    name: {
        value: '',
        isValid: null
    },
    densityOrPlasticity: {
        value: '',
        isValid: null
    },
    genesis: {
        value: '',
        isValid: null
    },
    moisture: {
        value: '',
        isValid: null
    },
    noncohesive: true,
    organic: false,
    cohesive: false
}

const DefaultPNBSoilHelpers = (setSoilTableState) => {
 
    const [defaultSoilParametersState, setDefaultSoilParametersState] = useState(defaultPNBSoilObj);

    const onBlurDefaultSoilState = useCallback((e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if(input){
            const { name } = input;
            const valid = input.checkValidity(); 
                  
            setDefaultSoilParametersState(state => ({ ...state, [name]: { ...state[name], isValid: valid }}))
        }
    },[])
    const onChangeDefaultSoilState = useCallback((e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if(input){
            const { name, value, type } = input;
            
            if(name ==='name'){
                const soil = defaultPNBSoils.find(ele => ele.name === value) || {};
                if(soil.state === 'noncohesive'){
                    setDefaultSoilParametersState(state => ({ ...state, cohesive: false, organic: false, noncohesive: true }))
                } else if(soil.state === 'cohesive'){
                    setDefaultSoilParametersState(state => ({ ...state, cohesive: true, organic: false, noncohesive: false }))
                } else {
                    setDefaultSoilParametersState(state => ({ ...state, noncohesive: false, cohesive: false, organic: true }))
                }
            }
            setDefaultSoilParametersState(state => {
                return {
                    ...state,
                    [name]: {
                        value: (type === 'number' && value.length > 0)  ? Number(value) : value,
                        isValid: true
                    }
                }
            }) 
        }
    },[])

    const onSubmitDefaultSoil = (obj, i) => {
        const { cohesive, noncohesive, name, densityOrPlasticity, genesis, moisture } = defaultSoilParametersState;
    
        const dataToSend = Object.entries({ name, densityOrPlasticity, ...cohesive && {genesis}, ...noncohesive && {moisture} });
        const isValidState = dataToSend.every(([key,value]) => value.isValid);

        if(isValidState){
            const soil = defaultPNBSoils.find(ele => ele.name === obj.name.value);
            if(soil){  
                setSoilTableState(state => state.map((element,index) => {
                    if(i !== index){
                        return element
                    } else {
                        if(soil.state === 'noncohesive'){
                            return {
                                ...element,
                                name: { value: soil.name, isValid: true },
                                weight: { value: noncohesiveSoilWeight(soil.signature, obj.moisture.value, obj.densityOrPlasticity.value), isValid: true },
                                underWaterWeight: { value: noncohesiveSoilWeight(soil.signature, obj.moisture.value, obj.densityOrPlasticity.value, true), isValid: true},
                                cohesion: { value: '', isValid: null },
                                friction: { value: frictionAngleNoncohesiveSoils[soil.signature](obj.densityOrPlasticity.value, [0,1]), isValid: true},
                                density: { value: obj.densityOrPlasticity.value, isValid: true },
                                plasticity: { value: '', isValid: null },
                                edometricModule: { value: edometricModulusNoncohesiveSoils[soil.signature](obj.densityOrPlasticity.value, [0,1]), isValid: true },
                                genesis: { value: '', isValid: null },
                                soilName: { value: soil.name, isValid: true },
                                soilType: { value: soil.type, isValid: true }
                            } 
                        } else if(soil.state === 'cohesive'){
                            return {
                                ...element,
                                name: { value: soil.name, isValid: true },
                                weight: { value: cohesiveSoilWeight(soil.weights, Number(obj.densityOrPlasticity.value)), isValid: true },
                                underWaterWeight: { value: cohesiveSoilWeight(soil.weights, Number(obj.densityOrPlasticity.value)) - 10, isValid: true },
                                cohesion: { value: cohesionByGenesis[obj.genesis.value](obj.densityOrPlasticity.value, [0,1]), isValid: true},
                                friction: { value: frictionAngleByGenesis[obj.genesis.value](obj.densityOrPlasticity.value, [0,1]), isValid: true },
                                density: { value: '', isValid: null },
                                plasticity: { value: obj.densityOrPlasticity.value, isValid: true },
                                edometricModule: { value: edometricModulesByGenesis[obj.genesis.value](obj.densityOrPlasticity.value,[0,1]), isValid: true },
                                genesis: { value: obj.genesis.value, isValid: true },
                                soilName: { value: soil.name, isValid: true },
                                soilType: { value: soil.type, isValid: true }
                            }
                        } else if(soil.state === 'organic'){
                            return {
                                ...element,
                                name: { value: soil.name, isValid: true },
                                weight: { value: soil.weight, isValid: true },
                                underWaterWeight: { value: soil.weight - 10, isValid: true },
                                cohesion: { value: soil.cohesion, isValid: true },
                                friction: { value: soil.friction, isValid: true },
                                density: { value: '', isValid: null },
                                plasticity: { value: obj.densityOrPlasticity.value, isValid: true },
                                edometricModule: { value: soil.edometricModules, isValid: true },
                                genesis: { value: '', isValid: null },
                                soilName: { value: soil.name, isValid: true },
                                soilType: { value: soil.type, isValid: true }
                            }
                        } else {
                            return { ...element }
                        }
                    }
                }))
            }            
        } else {
            setDefaultSoilParametersState(state => ({
                ...state,
                ...dataToSend.reduce((acc, [key, {isValid,...rest}]) => ({...acc, [key]:{ isValid: !!isValid, ...rest }}),{})
            }))
        }
    }

    const defaultSoilLayer = {
        defaultSoils: useMemo(() => defaultPNBSoils.map(ele => ele.name),[]),
        initState: () => setDefaultSoilParametersState(defaultPNBSoilObj),
        moistures: useMemo(() => noncohesiveMoistures,[]),
        state: defaultSoilParametersState,
        onChange: onChangeDefaultSoilState,
        onBlur: onBlurDefaultSoilState,
        onSubmit: onSubmitDefaultSoil
    }

    return {
        defaultSoilLayer
    }
}

export default DefaultPNBSoilHelpers