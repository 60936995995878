import { Outlet, useOutletContext } from "react-router-dom";
import MicropilesProvider from "../../../context/Micropiles";
import { MicropilesNavigation } from "../../../pages";

const MicropilesLayout = () => {
    const context = useOutletContext();

    return (
        <MicropilesProvider>
            <MicropilesNavigation report={context.report}/>
            <Outlet context={context}/>
        </MicropilesProvider>
    )
}

export default MicropilesLayout;