import React, { Fragment, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    Button,
    Checkbox,
    SectionTitle,
    Modal,
    InputField,
} from '../../../../components';
import ReportWrapper from './ReportWrapper';
import ReactToPrint from 'react-to-print';
import styles from './index.module.css';

const PrintResults = ({ state, onChange, open, onHide }) => {
    const pdfComponentRef = useRef();

    return (
        <Fragment>
            <Modal
                title="Raport z obliczeń"
                open={open}
                onHide={onHide}
                hideButton={false}>
                <Row>
                    <Col xs={6}>
                        <Checkbox
                            name="allItems"
                            label="Zaznacz wszystko"
                            value={state.reportItem.allItems}
                            onChange={onChange}
                        />
                    </Col>
                    <Col xs={6}>
                        <Checkbox
                            name="firstPage"
                            label="Strona tytułowa"
                            value={state.reportItem.firstPage}
                            onChange={onChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <SectionTitle title="Dane wejściowe" />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox
                            name="soilsTable" 
                            label="Tabela parametrów gruntu"
                            value={state.reportItem.soilsTable}
                            onChange={onChange}
                        />
                        <Checkbox
                            name="projectInfo"
                            label="Parametry ogólne obliczeń"
                            value={state.reportItem.projectInfo}
                            onChange={onChange}
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox
                            name="micropileProperties"
                            label="Parametry mikropala"
                            value={state.reportItem.micropileProperties}
                            onChange={onChange}
                        />
                        <Checkbox
                            name="saftyFactors"
                            label="Współczynniki bezpieczeństwa"
                            value={state.reportItem.saftyFactors}
                            onChange={onChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <SectionTitle title="Wyniki obliczeń" />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox
                            name="micropileCompressionDetailsTable"
                            label="Tabela nośności mikropala na wciskanie"
                            value={
                                state.reportItem
                                    .micropileCompressionDetailsTable
                            }
                            onChange={onChange}
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox
                            name="micropilePullDetailsTable"
                            label="Tabela nośności mikropala na wyciąganie"
                            value={state.reportItem.micropilePullDetailsTable}
                            onChange={onChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6}>
                        <Checkbox
                            name="summary"
                            label="Podsumowanie wyników"
                            value={state.reportItem.summary}
                            onChange={onChange}
                        />
                    </Col>
                    {state.results.calculationParameters.isSteelRod && (
                        <Col xs={12} sm={6}>
                            <Checkbox
                                name="steelRodTable"
                                label="Parametry żerdzi"
                                value={state.reportItem.steelRodTable}
                                onChange={onChange}
                            />
                        </Col>
                    )}
                </Row>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <SectionTitle title="Załączniki graficzne" />
                    </Col>

                    <Col xs={12} sm={6}>
                        <Checkbox
                            name="micropileCompressionChart"
                            label="Wykres nośności mikropala wciskanego"
                            value={state.reportItem.micropileCompressionChart}
                            onChange={onChange}
                        />
                    </Col>
                    {state.reportItem.micropileCompressionChart && (
                        <Col xs={12} sm={6}>
                            <InputField
                                title={'Długość mikropala wciskanego'}
                                onChange={onChange}
                                name="micropileCompressionLength"
                                type="number"
                                value={
                                    state.reportItem.micropileCompressionLength
                                }
                                placeholder="Dł. mikropala wciskanego"
                            />
                        </Col>
                    )}
                </Row>
                <Row className={styles.row}>
                    <Col xs={12} sm={6}>
                        <Checkbox
                            name="micropilePullChart"
                            label="Wykres nośności mikropala wyciąganego"
                            value={state.reportItem.micropilePullChart}
                            onChange={onChange}
                        />
                    </Col>
                    {state.reportItem.micropilePullChart && (
                        <Col xs={12} sm={6}>
                            <InputField
                                title={'Długość mikropala wyciąganego'}
                                onChange={onChange}
                                name="micropilePullLength"
                                type="number"
                                value={state.reportItem.micropilePullLength}
                                placeholder="Dł. pala wyciąganego"
                            />
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col xs={12}>
                        <ReactToPrint
                            trigger={() => (
                                <Button
                                    css={`
                                        ${styles.button} default-button-style
                                    `}
                                    altTitle="Drukuj"
                                    title="Drukuj raport"
                                    type="button"
                                />
                            )}
                            content={() => pdfComponentRef.current}
                        />
                    </Col>
                </Row>
                <ReportWrapper pdfState={state} ref={pdfComponentRef} />
            </Modal>
        </Fragment>
    );
};

export default PrintResults;
