import { Table } from 'react-bootstrap';
import styles from './index.module.css';

const DesignSoilParametersSection = ({ parameters = [] }) => {
    return (
        <div className={styles.wrapper}>
            <h2>Obliczeniowe parametry gruntu</h2>
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th>
                            Nazwa gruntu<br></br>[-]
                        </th>
                        <th>
                            Z<br></br>[m.p.p.t]
                        </th>
                        <th>
                            Z<sub>c</sub>
                            <br></br>[m]
                        </th>
                        <th>
                            &#947; <br></br>[kN/m<sup>3</sup>]
                        </th>
                        <th>
                            c<br></br>[kPa]
                        </th>
                        <th>
                            &#966; <br></br>[&#176;]
                        </th>
                        <th>
                            &#948; <br></br>[&#176;]
                        </th>
                        <th>
                            E<sub>0</sub>
                            <br></br>[MPa]
                        </th>
                        <th>
                            S<sub>n</sub>
                            <br></br>[-]
                        </th>
                        <th>
                            &#934; <br></br>[-]
                        </th>
                        <th>
                            K<sub>ph</sub>
                            <br></br>[-]
                        </th>
                        <th>
                            K<sub>x1</sub>
                            <br></br>[kPa]
                        </th>
                        <th>
                            K<sub>x2</sub>
                            <br></br>[kPa]
                        </th>
                        <th>
                            Q<sub>gr1</sub>
                            <br></br>[kN/m]
                        </th>
                        <th>
                            Q<sub>gr2</sub>
                            <br></br>[kN/m]
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {parameters.map((element, index) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{element.name}</td>
                                <td>{element.z}</td>
                                <td>{element.zc}</td>
                                <td>{element.weight}</td>
                                <td>{element.cohesion}</td>
                                <td>{element.fi}</td>
                                <td>{element.pileFi}</td>
                                <td>{element.e0}</td>
                                <td>{element.sn}</td>
                                <td>{element.ltf}</td>
                                <td>{element.kph}</td>
                                <td>{element.kx_1}</td>
                                <td>{element.kx_2}</td>
                                <td>{element.qgr_1}</td>
                                <td>{element.qgr_2}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <p className={styles.underline}>Oznaczenia:</p>
            <p>
                Z -{' '}
                <span className={styles.value}>
                    rzędna poniżej poziomu terenu
                </span>
            </p>
            <p>
                H - <span className={styles.value}>grubość warstwy</span>
            </p>
            <p>
                Z<sub>c</sub> -{' '}
                <span className={styles.value}>
                    głębokość krytyczna zmienności modułu reakcji podłoża
                </span>
            </p>
            <p>
                &#947; -{' '}
                <span className={styles.value}>
                    ciężar objętościowy gruntu uwzględniony w obliczeniach
                </span>
            </p>
            <p>
                c - <span className={styles.value}>spójność gruntu</span>
            </p>
            <p>
                &#966; -{' '}
                <span className={styles.value}>kąt tarcia wewnętrznego</span>
            </p>
            <p>
                &#948; -{' '}
                <span className={styles.value}>
                    kąt tarcia o pobocznicę pala
                </span>
            </p>
            <p>
                E<sub>0</sub> -{' '}
                <span className={styles.value}>
                    moduł odkształcenia pierwotnego gruntu
                </span>
            </p>

            <p>
                S<sub>n</sub> -{' '}
                <span className={styles.value}>
                    współczynnik zależny od technologii pogrążania pali
                </span>
            </p>
            <p>
                &#934; -{' '}
                <span className={styles.value}>
                    współczynnik obciążeń długotrwałych i powtarzalnych
                </span>
            </p>
            <p>
                K<sub>ph</sub> -{' '}
                <span className={styles.value}>współczynnik odporu gruntu</span>
            </p>
            <p>
                K<sub>x1</sub> -{' '}
                <span className={styles.value}>
                    moduł poziomej reakcji podłoża w stropie warstwy
                </span>
            </p>
            <p>
                K<sub>x2</sub> -{' '}
                <span className={styles.value}>
                    moduł poziomej reakcji podłoża w spągu warstwy
                </span>
            </p>
            <p>
                Q<sub>gr1</sub> -{' '}
                <span className={styles.value}>
                    reakcja graniczna podłoża w stropie warstwy
                </span>
            </p>
            <p>
                Q<sub>gr2</sub> -{' '}
                <span className={styles.value}>
                    reakcja graniczna podłoża w spągu warstwy
                </span>
            </p>
        </div>
    );
};

export default DesignSoilParametersSection;
