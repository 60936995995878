import { useCallback, useState } from "react"
import { roundNumber } from "../../../utils";

const initStateObj = {
    bendingMoment: {
        value: '',
        isValid: null,
    },
    horizontalForce: {
        value: '',
        isValid: null,
    },
}

const PileLoadsHelpers = ({
    updateResultsState
}) => {

    const [pileLoadsState, setPileLoadsState] = useState(initStateObj);

    const onBlurPileLoadsState = useCallback((e) => {
        const { name } = e.currentTarget;
        const valid = e.currentTarget.checkValidity();


        setPileLoadsState(state => ({
            ...state, 
            [name]: {
                ...state[name],
                isValid: valid
            }
        }))
    },[]);
    
    const onChangePileLoadsState = useCallback((e) => {
        const input = e.currentTarget.closest('input');
        if(input){
            const { name, value, type } = input;

            updateResultsState((state) => {
                return {
                    ...state,
                    isResultsActual: false,
                };
            });

            setPileLoadsState(state => {
                return {
                    ...state,
                    [name]: {
                        value: (type === 'number' && value.length > 0) ? roundNumber(value,2) : value,
                        isValid: true
                    }
                }
            })
        }
    },[])

    const pileLoads = {
        onChange: onChangePileLoadsState,
        onBlur: onBlurPileLoadsState,
        state: pileLoadsState,
        updateState: setPileLoadsState,
        initState: () => setPileLoadsState(initStateObj)
    }

    return {
        pileLoads
    }
}

export default PileLoadsHelpers