import { Fragment } from "react";
import { isNumber, roundNumber } from "../../../../../utils";
import styles from './index.module.css';

const PileParametersSection = ({ 
    currentPileLength,
    diameterPile,
    pileHeadSpot,
    pileType,
    pileTypeNumber,
    hPile,
    hType,
    baseWidth
 }) => {
    return (
        <div className={styles.wrapper}>
            <h2>Parametry pala</h2>
            <p>Rodzaj pala: <span className={styles.value}>{pileType}</span></p>
            {
            pileTypeNumber === 6
            ?
            <Fragment>
                <p>Typ elementu: <span className={styles.value}>{hType}</span></p>
                <p>Wysokość środnika: <span className={styles.value}>{roundNumber(hPile.h1, 1).toFixed(1)} mm</span></p>
                <p>Szerokość półki: <span className={styles.value}>{roundNumber(hPile.b, 1).toFixed(1)} mm</span></p>
            </Fragment>
            :
            pileTypeNumber === 7
            ?
            <Fragment>
                <p>Typ elementu: <span className={styles.value}>2x{hType}</span></p>
                <p>Wysokość środnika: <span className={styles.value}>{roundNumber(hPile.h1, 1).toFixed(1)} mm</span></p>
                <p>Szerokośc półki: <span className={styles.value}>{roundNumber(2*hPile.b, 1).toFixed(1)} mm</span></p>
            </Fragment>
            :
            <Fragment>
                <p>
                    {pileTypeNumber === 1 ? 'Szerokość boku' : 'Średnica pala'} :{' '}
                    <span className={styles.value}>{roundNumber(diameterPile, 2).toFixed(2)} m</span>
                </p>
            </Fragment>
        }
            <p>Długość pala: <span className={styles.value}>{isNumber(currentPileLength) && currentPileLength > 0 ? `${roundNumber(currentPileLength,2).toFixed(2)} m`: 'Brak' }</span></p>
            <p>Rzędna głowicy pala: <span className={styles.value}>{roundNumber(pileHeadSpot,2).toFixed(2)} m.p.p.t.</span></p>
            {
                (pileTypeNumber === 4 || pileTypeNumber === 5) &&
                <p>Poszerzona podstawa: <span className={styles.value}>{isNumber(baseWidth) ? `${roundNumber(baseWidth,2).toFixed(2)}m` : 'Nie'}</span></p>                
            }

        </div>
    )
}

export default PileParametersSection