import { useContext, useEffect } from 'react';
import config from '../../../config/config';
import { cptpileContext } from '../../../context/CptPile';
import PrintCptPileResults from './PrintResults';
import DefaultNavigation from '../DefaultNavigation';

const CptPileNavigation = ({ report }) => {
    const CptPileContext = useContext(cptpileContext);
    const { routes } = config;

    useEffect(() => {
        document.title = `Nośność pala CPT - ${CptPileContext.fileNameToSave.fileName.value}`;

        return () => {
            document.title = '';
        };
    }, [CptPileContext.fileNameToSave.fileName.value]); 

    return (
        <DefaultNavigation
            title="Nośność pala CPT"
            subtitle="Nośność pionowa pali na podstawie badań sondą statyczną CPT/CPTu na podstawie zaleceń EAP"
            modelRoute={routes.dashboard.pileCptModel}
            resultsRoute={routes.dashboard.pileCptResults}
            context={CptPileContext}
        >
            {CptPileContext.results.state.isResultsActual && CptPileContext.results.isResultsActive && (
                <PrintCptPileResults
                    report={report}
                    isResultsActual={
                        CptPileContext.results.state.isResultsActual
                    }
                    printResults={CptPileContext.printResults}
                />
                )
            } 
        </DefaultNavigation>
    );
};

export default CptPileNavigation;
