import React, { Fragment, useCallback, useState } from 'react';
import {
    ResultsFooter,
    ResultsHeader,
    ReportFirstPage,
} from '../../../../../components';
import SoilParametersSection from '../SoilParametersSection';
import ProjectInfo from '../ProjectInfo';
import styles from './index.module.css';
import MicropileParametersSection from '../MicropileParametersSection';
import SaftyFactorsSection from '../SaftyFactorsSection';
import ResultsOverviewSection from '../ResultsOverview';
import MicropileCapacityTable from '../MicropileCapacityTable';
import SteelRodTable from '../SteelRodTable';
import MicropileChart from '../MicropileChart';

const PdfReport = React.forwardRef(({ pdfState = {} }, ref) => {
    const [footerHeight, setFooterHeight] = useState(0);
    
    return (
        <div className={styles.wrapper} ref={ref}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <td>
                            <div className={styles.headerSpace}></div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <main className={styles.content}>
                                <ol>
                                    <div>
                                        { 
                                            pdfState.reportItem.firstPage &&
                                            <Fragment>
                                                <ReportFirstPage
                                                    title="Nośność wewnętrzna i zewnętrzna mikropali kotwiących"
                                                    subtitle={
                                                        pdfState.projectTitle
                                                            .projectTitle
                                                    }
                                                    author={pdfState.report.author}
                                                    contact={pdfState.report.contact}
                                                    logo={pdfState.report.logo}
                                                />
                                                                                               
                                            </Fragment>
                                        }
                                        {(pdfState.reportItem.soilsTable ||
                                            pdfState.reportItem.projectInfo ||
                                            pdfState.reportItem
                                                .micropileProperties ||
                                            pdfState.reportItem
                                                .saftyFactors) && (

                                            <Fragment>
                                                <div className={styles.break}></div> 
                                                <li className={styles.h1}>
                                                    Dane do projektu
                                                </li>
                                                {pdfState.reportItem
                                                    .soilsTable && (
                                                    <SoilParametersSection
                                                        parameters={
                                                            pdfState.results
                                                                .soilTable
                                                        }
                                                    />
                                                )}
                                                {pdfState.reportItem
                                                    .projectInfo && (
                                                    <ProjectInfo
                                                        parameters={{
                                                            groundLevel:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .groundLevel,
                                                            rightZwg:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .rightZwg,
                                                            leftZwg:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .leftZwg,
                                                            excavationBase:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .excavationBase,
                                                            sheetPileSpot:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .sheetPileSpot,
                                                            isDeepPlate:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .isDeepPlate,
                                                        }}
                                                    />
                                                )}
                                                {pdfState.reportItem
                                                    .micropileProperties && (
                                                    <MicropileParametersSection
                                                        parameters={{
                                                            groundLevel:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .groundLevel,
                                                            freeLength:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .freeLength,
                                                            micropileHeadSpot:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .micropileHeadSpot,
                                                            micropileFreeSpace:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .micropileFreeSpace,
                                                            micropileAngle:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .micropileAngle,
                                                            axisLoad:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .axisLoad,
                                                            diameter:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .diameter,
                                                            screwType:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .screwType,
                                                            steelRodProd:
                                                                pdfState.results
                                                                    .steelRod
                                                                    ? pdfState
                                                                          .results
                                                                          .steelRod
                                                                          .prod
                                                                    : '',
                                                            steelRodName:
                                                                pdfState.results
                                                                    .steelRod
                                                                    ? pdfState
                                                                          .results
                                                                          .steelRod
                                                                          .name
                                                                    : '',
                                                            steelRodSubname:
                                                                pdfState.results
                                                                    .steelRod
                                                                    ? pdfState
                                                                          .results
                                                                          .steelRod
                                                                          .subname
                                                                    : '',
                                                            micropileType:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .micropileType,
                                                            steelYoungModulus:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .steelYoungModulus,
                                                            concreteYoungModulus:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .concreteYoungModulus,
                                                        }}
                                                    />
                                                )}
                                                {pdfState.reportItem
                                                    .saftyFactors && (
                                                    <SaftyFactorsSection
                                                        parameters={{
                                                            softSoilCount:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .softSoilCount,
                                                            bondCapacityType:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .bondCapacityType,
                                                            capacityType:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .capacityType,
                                                            cohesiveSoils:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .cohesiveSoils,
                                                            cohesiveSoilsCpt:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .cohesiveSoilsCpt,
                                                            gravel: pdfState
                                                                .results
                                                                .calculationParameters
                                                                .gravel,
                                                            gravelCpt:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .gravelCpt,
                                                            isSteelRod:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .isSteelRod,
                                                            ksi: pdfState
                                                                .results
                                                                .calculationParameters
                                                                .ksi,
                                                            profilesNumber:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .profilesNumber,
                                                            qccuInterpolation:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .qccuInterpolation,
                                                            rocks: pdfState
                                                                .results
                                                                .calculationParameters
                                                                .rocks,
                                                            sands: pdfState
                                                                .results
                                                                .calculationParameters
                                                                .sands,
                                                            rocksCpt:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .rocksCpt,
                                                            sandsCpt:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .sandsCpt,
                                                            steelRodCapacity:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .steelRodCapacity,
                                                            steelRodYt:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .steelRodYt,
                                                            yt: pdfState.results
                                                                .calculationParameters
                                                                .yt,
                                                            designToCharLoads:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .designToCharLoads,
                                                        }}
                                                    />
                                                )}
                                                
                                            </Fragment>
                                        )}
                                        {(pdfState.reportItem.results ||
                                            pdfState.reportItem
                                                .micropileDetailsTable ||
                                            pdfState.reportItem
                                                .steelRodTable) && (
                                            <Fragment>
                                                <div className={styles.break}></div>
                                                <li className={styles.h1}>
                                                    Wyniki obliczeń
                                                </li>
                                                {pdfState.reportItem
                                                    .results && (
                                                    <ResultsOverviewSection
                                                        parameters={{
                                                            slipPlate:
                                                                pdfState.results
                                                                    .slipPlate,
                                                            deepPlate:
                                                                pdfState.results
                                                                    .deepPlate,
                                                            steelRodWeight:
                                                                pdfState.results
                                                                    .steelRod
                                                                    ? pdfState
                                                                          .results
                                                                          .steelRod
                                                                          .m
                                                                    : '',
                                                            rodModule:
                                                                pdfState.results
                                                                    .rodModule
                                                                    .value,
                                                            isSteelRod:
                                                                pdfState.results
                                                                    .calculationParameters
                                                                    .isSteelRod,
                                                        }}
                                                    />
                                                )}
                                                {pdfState.reportItem
                                                    .micropileDetailsTable && (
                                                    <MicropileCapacityTable
                                                        parameters={{
                                                            slipPlate:
                                                                pdfState.results
                                                                    .slipPlate,
                                                            deepPlate:
                                                                pdfState.results
                                                                    .deepPlate,
                                                        }}
                                                    />
                                                )}
                                                {pdfState.reportItem
                                                    .steelRodTable && (
                                                    <SteelRodTable
                                                        screwType={pdfState.results.calculationParameters.screwType}
                                                        steelRod={
                                                            pdfState.results
                                                                .steelRod
                                                        }
                                                    />
                                                )}
                                                <div
                                                    className={
                                                        styles.break
                                                    }></div>
                                            </Fragment>
                                        )}
                                        {pdfState.reportItem.micropileChart && (
                                            <Fragment>
                                                <li className={styles.h1}>
                                                    Załączniki
                                                </li>
                                                {pdfState.reportItem
                                                    .micropileChart && (
                                                    <Fragment>
                                                        <div
                                                            className={
                                                                styles.avoidBreak
                                                            }>
                                                            <MicropileChart
                                                                title="Nośność mikropala z uwzględnieniem klina odłamu - projektowana siła osiowa"
                                                                results={
                                                                    pdfState
                                                                        .results
                                                                        .slipPlate
                                                                        .axisLoadCapacity
                                                                }
                                                                soilTable={
                                                                    pdfState
                                                                        .results
                                                                        .soilTable
                                                                }
                                                                slideLineArr={
                                                                    pdfState
                                                                        .results
                                                                        .slideLineArr
                                                                }
                                                                intersectionPoints={
                                                                    pdfState
                                                                        .results
                                                                        .intersectionPoints
                                                                }
                                                                calculationParameters={{
                                                                    groundLevel:
                                                                        pdfState
                                                                            .results
                                                                            .calculationParameters
                                                                            .groundLevel,
                                                                    isSteelRod:
                                                                        pdfState
                                                                            .results
                                                                            .calculationParameters
                                                                            .isSteelRod,
                                                                    excavationBase:
                                                                        pdfState
                                                                            .results
                                                                            .calculationParameters
                                                                            .excavationBase,
                                                                    leftZwg:
                                                                        pdfState
                                                                            .results
                                                                            .calculationParameters
                                                                            .leftZwg,
                                                                    micropileAngle:
                                                                        pdfState
                                                                            .results
                                                                            .calculationParameters
                                                                            .micropileAngle,
                                                                    micropileHeadSpot:
                                                                        pdfState
                                                                            .results
                                                                            .calculationParameters
                                                                            .micropileHeadSpot,
                                                                    rightZwg:
                                                                        pdfState
                                                                            .results
                                                                            .calculationParameters
                                                                            .rightZwg,
                                                                    sheetPileSpot:
                                                                        pdfState
                                                                            .results
                                                                            .calculationParameters
                                                                            .sheetPileSpot,
                                                                }}
                                                            />
                                                        </div>
                                                        {pdfState.results
                                                            .calculationParameters
                                                            .isDeepPlate && (
                                                            <div
                                                                className={
                                                                    styles.avoidBreak
                                                                }>
                                                                <MicropileChart
                                                                    title="Nośność mikropala z uwzględnieniem głębokiej powierzchni poślizgu - proj. siła osiowa"
                                                                    results={
                                                                        pdfState
                                                                            .results
                                                                            .deepPlate
                                                                            .axisLoadCapacity
                                                                    }
                                                                    isDeepPlate={
                                                                        pdfState
                                                                            .results
                                                                            .calculationParameters
                                                                            .isDeepPlate
                                                                    }
                                                                    soilTable={
                                                                        pdfState
                                                                            .results
                                                                            .soilTable
                                                                    }
                                                                    slideLineArr={
                                                                        pdfState
                                                                            .results
                                                                            .slideLineArr
                                                                    }
                                                                    intersectionPoints={
                                                                        pdfState
                                                                            .results
                                                                            .intersectionPoints
                                                                    }
                                                                    calculationParameters={{
                                                                        groundLevel:
                                                                            pdfState
                                                                                .results
                                                                                .calculationParameters
                                                                                .groundLevel,
                                                                        isSteelRod:
                                                                            pdfState
                                                                                .results
                                                                                .calculationParameters
                                                                                .isSteelRod,
                                                                        excavationBase:
                                                                            pdfState
                                                                                .results
                                                                                .calculationParameters
                                                                                .excavationBase,
                                                                        leftZwg:
                                                                            pdfState
                                                                                .results
                                                                                .calculationParameters
                                                                                .leftZwg,
                                                                        micropileAngle:
                                                                            pdfState
                                                                                .results
                                                                                .calculationParameters
                                                                                .micropileAngle,
                                                                        micropileHeadSpot:
                                                                            pdfState
                                                                                .results
                                                                                .calculationParameters
                                                                                .micropileHeadSpot,
                                                                        rightZwg:
                                                                            pdfState
                                                                                .results
                                                                                .calculationParameters
                                                                                .rightZwg,
                                                                        sheetPileSpot:
                                                                            pdfState
                                                                                .results
                                                                                .calculationParameters
                                                                                .sheetPileSpot,
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                        {pdfState.results
                                                            .slipPlate
                                                            .steelRodCapacity && (
                                                            <div
                                                                className={
                                                                    styles.avoidBreak
                                                                }>
                                                                <MicropileChart
                                                                    title="Nośność mikropala z uwzględnieniem klina odłamu - nośność obliczeniowa żerdzi"
                                                                    results={
                                                                        pdfState
                                                                            .results
                                                                            .slipPlate
                                                                            .steelRodCapacity
                                                                    }
                                                                    soilTable={
                                                                        pdfState
                                                                            .results
                                                                            .soilTable
                                                                    }
                                                                    slideLineArr={
                                                                        pdfState
                                                                            .results
                                                                            .slideLineArr
                                                                    }
                                                                    intersectionPoints={
                                                                        pdfState
                                                                            .results
                                                                            .intersectionPoints
                                                                    }
                                                                    calculationParameters={{
                                                                        groundLevel:
                                                                            pdfState
                                                                                .results
                                                                                .calculationParameters
                                                                                .groundLevel,
                                                                        isSteelRod:
                                                                            pdfState
                                                                                .results
                                                                                .calculationParameters
                                                                                .isSteelRod,
                                                                        excavationBase:
                                                                            pdfState
                                                                                .results
                                                                                .calculationParameters
                                                                                .excavationBase,
                                                                        leftZwg:
                                                                            pdfState
                                                                                .results
                                                                                .calculationParameters
                                                                                .leftZwg,
                                                                        micropileAngle:
                                                                            pdfState
                                                                                .results
                                                                                .calculationParameters
                                                                                .micropileAngle,
                                                                        micropileHeadSpot:
                                                                            pdfState
                                                                                .results
                                                                                .calculationParameters
                                                                                .micropileHeadSpot,
                                                                        rightZwg:
                                                                            pdfState
                                                                                .results
                                                                                .calculationParameters
                                                                                .rightZwg,
                                                                        sheetPileSpot:
                                                                            pdfState
                                                                                .results
                                                                                .calculationParameters
                                                                                .sheetPileSpot,
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                        {pdfState.results
                                                            .calculationParameters
                                                            .isDeepPlate &&
                                                            pdfState.results
                                                                .deepPlate
                                                                .steelRodCapacity && (
                                                                <div
                                                                    className={
                                                                        styles.avoidBreak
                                                                    }>
                                                                    <MicropileChart
                                                                        title="Nośność mikropala z uwzgl. gł. powierzchni poślizgu - nośność obliczeniowa żerdzi"
                                                                        results={
                                                                            pdfState
                                                                                .results
                                                                                .deepPlate
                                                                                .steelRodCapacity
                                                                        }
                                                                        isDeepPlate={
                                                                            pdfState
                                                                                .results
                                                                                .calculationParameters
                                                                                .isDeepPlate
                                                                        }
                                                                        soilTable={
                                                                            pdfState
                                                                                .results
                                                                                .soilTable
                                                                        }
                                                                        slideLineArr={
                                                                            pdfState
                                                                                .results
                                                                                .slideLineArr
                                                                        }
                                                                        intersectionPoints={
                                                                            pdfState
                                                                                .results
                                                                                .intersectionPoints
                                                                        }
                                                                        calculationParameters={{
                                                                            groundLevel:
                                                                                pdfState
                                                                                    .results
                                                                                    .calculationParameters
                                                                                    .groundLevel,
                                                                            isSteelRod:
                                                                                pdfState
                                                                                    .results
                                                                                    .calculationParameters
                                                                                    .isSteelRod,
                                                                            excavationBase:
                                                                                pdfState
                                                                                    .results
                                                                                    .calculationParameters
                                                                                    .excavationBase,
                                                                            leftZwg:
                                                                                pdfState
                                                                                    .results
                                                                                    .calculationParameters
                                                                                    .leftZwg,
                                                                            micropileAngle:
                                                                                pdfState
                                                                                    .results
                                                                                    .calculationParameters
                                                                                    .micropileAngle,
                                                                            micropileHeadSpot:
                                                                                pdfState
                                                                                    .results
                                                                                    .calculationParameters
                                                                                    .micropileHeadSpot,
                                                                            rightZwg:
                                                                                pdfState
                                                                                    .results
                                                                                    .calculationParameters
                                                                                    .rightZwg,
                                                                            sheetPileSpot:
                                                                                pdfState
                                                                                    .results
                                                                                    .calculationParameters
                                                                                    .sheetPileSpot,
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                    </Fragment>
                                                )}
                                            </Fragment>
                                        )}
                                    </div>
                                </ol>
                            </main>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <div
                                style={{ height: footerHeight }}
                                className={styles.footerSpace}></div>
                        </td>
                    </tr>
                </tfoot>
            </table>
            <ResultsHeader
                author={pdfState.report.author}
                contact={pdfState.report.contact}
            />
            <ResultsFooter
                setFooterHeight={useCallback((val) => setFooterHeight(val),[])}
                pdfState={pdfState.reportItem}
                globalTitle="Nośność wewnętrzna i zewnętrzna mikropali kotwiących"
                title={pdfState.projectTitle.projectTitle}
            />
        </div>
    );
});

export default PdfReport;
