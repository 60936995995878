import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isNumber, roundNumber, setSoilColor } from '../../utils';
import { useWindowSize } from '../../hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';

const Wrapper = styled.div`
    .apexcharts-svg {
        border-radius: 5px;
    }
    .apexcharts-menu-item {
        color: #343a40;
        font-size: 14px;
    }
    .apexcharts-tooltip {
        padding: 5px;
        color: #343a40;
        font-size: 14px;
    }
    .apexcharts-menu-icon {
        &:hover {
            & svg {
                fill: #6e8192 !important;
            }
        }
    }
    .apexcharts-point-annotation-label {
        z-index: 10000
    }
`;

const withDefaultApexChartOptions = (Component) => {
    return ({ chartTitle, yLabel, xLabel, dataToChart = {}, ...rest }) => {
        const windowSize = useWindowSize();
        const [loaded, setLoaded] = useState(false);

        const [options, setOptions] = useState({
            chart: {
                toolbar: {
                    offsetY: 5,
                },
                fontFamily: 'Abhaya Libre, Sans-serif',
                animations: {
                    enabled: false,
                },
                zoom: {
                    enabled: false,
                },
            },
            dataLabels: {
                enabled: true,
                offsetY: -5,
                offsetX: 8,
                background: {
                    enabled: false,
                },
                formatter: function (x, opt) {
                    const label =
                        opt.w.globals.initialSeries[0].data[
                            opt.dataPointIndex
                        ][0];

                    return label === 0
                        ? ''
                        : opt.w.globals.initialSeries[0].data[
                              opt.dataPointIndex
                          ][0];
                },
                style: {
                    fontSize: '14px',
                    colors: ['#000'],
                },
            },
            stroke: {
                width: 1,
                curve: 'straight',
            },
            fill: {
                opacity: 0.3,
                type: 'solid',
            },
            markers: {
                size: 3,
                hover: {
                    size: 5,
                },
            },
            tooltip: {
                enabled: true,
                shared: false,
                intersect: true,

                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    return `<div>
                                Z: ${roundNumber(
                                    series[seriesIndex][dataPointIndex],
                                    2,
                                )} [m.p.p.t]
                            </div>
                            <div>
                                Kx: ${
                                    w.globals.initialSeries[0].data[
                                        dataPointIndex
                                    ][0]
                                } [kPa]
                            </div>
                        `;
                },
            },
            subtitle: {
                margin: 10,
                align: 'center',
                floating: true,
                style: {
                    fontSize: '15px',
                },
            },
            title: {
                text: chartTitle,
                offsetX: 3,
                offsetY: 3,
                margin: 15,
                align: 'center',
                style: {
                    fontSize: '16px',
                },
            },
            annotations: {
                position: 'back',
            },
            grid: {
                show: false,
            },
            yaxis: {
                reversed: true,
                type: 'numeric',
                title: {
                    text: yLabel,
                    offsetX: 3,
                    style: {
                        fontSize: '15px',
                    },
                },
                labels: {
                    style: {
                        fontSize: '14px',
                    },
                },
            },
            xaxis: {
                type: 'numeric',
                tickAmount: 8,
                axisBorder: {
                    show: false,
                },
                tickPlacement: 'between',
                title: {
                    text: xLabel,
                    offsetY: -5,
                    style: {
                        fontSize: '15px',
                    },
                },
                labels: {
                    style: {
                        fontSize: '14px',
                    },
                },
            },
        });

        useDeepCompareEffect(() => {
            setOptions((state) => {
                return {
                    ...state,
                    xaxis: {
                        ...state['xaxis'],
                        labels: {
                            ...state['xaxis']['labels'],
                            formatter: (val) => val.toFixed(0),
                        },
                    },
                    yaxis: {
                        ...state['yaxis'],
                        labels: {
                            ...state['yaxis']['labels'],
                            formatter: function (val) {
                                return parseInt(val) === val ? val : '';
                            },
                        },
                    },
                    annotations: {
                        ...state['annotations'],

                        yaxis: [
                            ...(Array.isArray(dataToChart.soilParameters) ? 
                            [
                                ...dataToChart.soilParameters.reduce(
                                    (acc, ele) => {
                                        return [
                                            ...acc,
                                            {
                                                y: ele.z - ele.h,
                                                y2: ele.z,
                                                borderColor: setSoilColor({
                                                    id: ele.density,
                                                    il: ele.plasticity,
                                                }),
                                                strokeDashArray: 0,
                                                opacity: 1,
                                                fillColor: setSoilColor({
                                                    id: ele.density,
                                                    il: ele.plasticity,
                                                }),
                                            },
                                        ];
                                    },
                                    [],
                                ),
                            ]
                            :
                            []),

                            {
                                ...(!!dataToChart.modelParameters &&
                                    isNumber(
                                        dataToChart.modelParameters.waterLevel,
                                    ) && {
                                        y: dataToChart.modelParameters
                                            .waterLevel,
                                        opacity: 1,
                                        strokeDashArray: 5,
                                        borderColor: 'blue',
                                    }),
                            },
                        ],
                    },
                };
            });
        }, [dataToChart]);

        useEffect(() => {
            const isWindowXs = windowSize.width < 576;
            setOptions((state) => {
                return {
                    ...state,
                    xaxis: {
                        ...state['xaxis'],
                        labels: {
                            ...state['xaxis']['labels'],
                            show: isWindowXs ? false : true,
                        },
                    },
                    yaxis: {
                        ...state['yaxis'],
                        show: isWindowXs ? false : true,
                    },
                    title: {
                        ...state['title'],
                        text: isWindowXs ? undefined : chartTitle,
                    },
                    subtitle: {
                        ...state['subtitle'],
                        offsetY: isWindowXs ? 3 : 35,
                    },
                };
            });
        }, [windowSize.width, chartTitle]);

        useDeepCompareEffect(() => {
            setLoaded(false);
            const timeout = setTimeout(() => {
                setLoaded(true);
            }, 0);

            return () => clearTimeout(timeout)
        }, [options]);

        return (
            <Wrapper>
                {loaded && (
                    <Component
                        chartOptions={options}
                        dataToChart={dataToChart}
                        {...rest}
                    />
                )}
            </Wrapper>
        );
    };
};

export default withDefaultApexChartOptions;
