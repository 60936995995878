import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useState } from "react"
import Button from "../Button"
import styles from './index.module.css';

const CustomSelectInput = ({ children, title, altTitle, name }) => {
    const [open, setOpen] = useState(false);

    const onOpen = () => setOpen(state => !state);
    return (
        <Fragment>
            <div className={styles.wrapper}>
                <Button
                    icon={faSortDown}
                    css={`${styles.button} default-button-style`}
                    title={title}
                    altTitle={altTitle}
                    name={name}
                    onClick={onOpen}
                    type="button"
                />
                {
                    open && 
                    <div className={`${styles.dropdown} customDropdown`}>
                        {children}
                    </div>                    
                }
            </div>
        </Fragment>
    )
}

export default CustomSelectInput