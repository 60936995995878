import React from 'react';
import { isNumber, roundNumber } from '../../../../../../../utils';
import styles from './index.module.css';

const SoilLayer = ({
    height, 
    backgroundColor, 
    layerHeight='', 
    groundLevel,
    name='', 
}) => {


    return (
        <div className={`${styles.layer} ${height === 0 ? styles.hiddenLayer : ''}`} style={{height: `${height}px`, backgroundColor, }}>
            <div className={styles.wrapper}>
                <div className={styles.description}>  
                    <div className={styles.listWrapperVisible}>
                        {name && <span className={styles.item}>{name}</span>}
                        
                    </div>
                    {isNumber(layerHeight) && 
                    <span className={styles.heightSpot}> 
                        {layerHeight.toFixed(2)}{' '}
                         {isNumber(groundLevel)
                            ? `(${roundNumber(groundLevel - layerHeight, 2).toFixed(2)})`
                        : ''}
                    </span>}
                </div>
            </div>
        </div>
    )
}

export default SoilLayer