import {
    faDollar,
} from '@fortawesome/free-solid-svg-icons';
import { Form, Col, Row } from 'react-bootstrap';
import {
    Modal,
    Button,
    InputField,
    RadioInput1,
    Checkbox1
} from '../';
import styles from './index.module.css';
import { Fragment } from 'react';

const InvoiceForm = ({
    invoiceType,
    invoiceState,
    saveFormData,
    onChangeInvoiceType,
    onChangeInvoice,
    onBlurInvoice,
    onChangeDataCheckbox,
    openInvoice,
    onHideInvoice,
    onSubmit,

    isSaveData=true,

    cssDialog,
    children
}) => {
    
    return (
        <Modal
            css={styles.modalOverflow}
            cssDialog={`${styles.modal1} ${cssDialog}`}
            onHide={onHideInvoice}
            title="Dane do faktury"
            open={openInvoice}
            hideButton={false}
        >
            <Row className={styles.row2}>
                <Col xs={12} sm={{ span: 10, offset: 1 }}>
                    <Form className={styles.radioForm}>
                        <RadioInput1
                            name="company"
                            label={'Faktura na firmę'}
                            onChange={() => onChangeInvoiceType(1)}
                            value={invoiceType === 1}
                        />
                        <RadioInput1
                            name="person"
                            label={'Faktura na osobę prywatną'}
                            onChange={() => onChangeInvoiceType(2)}
                            value={invoiceType === 2}
                        />
                    </Form>
                </Col>
            </Row>
            <Row className={styles.row2}>
                {invoiceType === 2 && (
                    <Fragment>
                        <Col xs={12} sm={6}>
                            <InputField
                                title="Imię"
                                placeholder={'Imię'}
                                name="firstName"
                                onChange={onChangeInvoice}
                                onBlur={onBlurInvoice}
                                value={invoiceState.firstName.value}
                                isValid={invoiceState.firstName.isValid}
                                required
                            />
                        </Col>
                        <Col xs={12} sm={6}>
                            <InputField
                                title="Nazwisko"
                                placeholder={'Nazwisko'}
                                name="lastName"
                                onChange={onChangeInvoice}
                                onBlur={onBlurInvoice}
                                value={invoiceState.lastName.value}
                                isValid={invoiceState.lastName.isValid}
                                required
                            />
                        </Col>
                    </Fragment>
                )}
                {invoiceType === 1 && (
                    <Fragment>
                        <Col xs={12}>
                            <InputField
                                title="Nazwa firmy"
                                placeholder={'Nazwa firmy'}
                                name="companyName"
                                onChange={onChangeInvoice}
                                onBlur={onBlurInvoice}
                                value={invoiceState.companyName.value}
                                isValid={invoiceState.companyName.isValid}
                                required
                            />
                        </Col>
                    </Fragment>
                )}
            </Row>
            {invoiceType === 1 && (
                <Row className={styles.row2}>
                    <Col xs={12}>
                        <InputField
                            title="NIP"
                            placeholder={'NIP'}
                            name="nip"
                            onChange={onChangeInvoice}
                            onBlur={onBlurInvoice}
                            value={invoiceState.nip.value}
                            isValid={invoiceState.nip.isValid}
                            required
                        />
                    </Col>
                </Row>
            )}
            <Row className={styles.row2}>
                <Col xs={12} sm={8}>
                    <InputField
                        title="Nazwa ulicy"
                        placeholder={'Nazwa ulicy'}
                        name={'street'}
                        onChange={onChangeInvoice}
                        onBlur={onBlurInvoice}
                        value={invoiceState.street.value}
                        isValid={invoiceState.street.isValid}
                        required
                    />
                </Col>
                <Col xs={12} sm={4}>
                    <InputField
                        title="Nr domu/lokalu"
                        placeholder={'Nr domu/lokalu'}
                        name="placeNumber"
                        onChange={onChangeInvoice}
                        onBlur={onBlurInvoice}
                        value={invoiceState.placeNumber.value}
                        isValid={invoiceState.placeNumber.isValid}
                        required
                    />
                </Col>
            </Row>
            <Row className={styles.row2}>
                <Col xs={12} sm={8}>
                    <InputField
                        title="Miejscowość"
                        placeholder={'Miejscowość'}
                        name="city"
                        onChange={onChangeInvoice}
                        onBlur={onBlurInvoice}
                        value={invoiceState.city.value}
                        isValid={invoiceState.city.isValid}
                        required
                    />
                </Col>
                <Col xs={12} sm={4}>
                    <InputField
                        title="Kod pocztowy"
                        placeholder={'Kod pocztowy'}
                        name="postcode"
                        onChange={onChangeInvoice}
                        onBlur={onBlurInvoice}
                        value={invoiceState.postcode.value}
                        isValid={invoiceState.postcode.isValid}
                        required
                    />
                </Col>
            </Row>
            <Row className={styles.row2}>
                <Col xs={12}>
                    <InputField
                        title="Email"
                        placeholder={'Email'}
                        name="email"
                        onChange={onChangeInvoice}
                        onBlur={onBlurInvoice}
                        value={invoiceState.email.value}
                        isValid={invoiceState.email.isValid}
                        required
                    />
                </Col>
            </Row>
            {
                isSaveData &&
                <Row className={styles.row2}>
                    <Col xs={12}>
                        <div
                            name="saveFormData"
                            className={styles.flexCheckbox}>
                            <Checkbox1
                                value={saveFormData}
                                name="saveFormData"
                                label="Zapamiętaj dane do faktury"
                                css={styles.checkboxWidth}
                                onChange={onChangeDataCheckbox}
                            />
                            <p
                                name="saveFormData"
                                onClick={onChangeDataCheckbox}>
                                Zapamiętaj dane do faktury
                            </p>
                        </div>
                    </Col>
                </Row>                
            }
            <Row className={styles.row2}>
                <Col xs={12} className={styles.toRight}>
                    <Button
                        icon={faDollar}
                        variant="info"
                        name="sendInvoice"
                        onClick={onSubmit}
                        title="Zapłać"
                        altTitle="Zapłać"
                    />
                </Col>
            </Row>

            <Fragment>
                {children}
            </Fragment>
        </Modal>    
    )
}

export default InvoiceForm;