import { useCallback, useState } from "react"
import { isNumber, stringToNumber } from "../../../utils";
import { sheetPilesWaling, ceo } from "../../../config/ProjectConfig";
import sheetPiles from "../../../config/ProjectConfig/Waling/sheetPileType";
import steelClasses from "../../../config/ProjectConfig/Waling/steelClasses";
import defaultSteelClass from "../../../config/ProjectConfig/DefaultSteelClass";
import plateSteelGrades from "../../../config/ProjectConfig/Waling/plateSteelGrades";
import defaultPlateSteelGrade from "../../../config/ProjectConfig/DefaultPlateSteelGrade";

const defaultState = {
    walingType_1: {
        value: true,
        isValid: true
    },
    walingType_2: {
        value: false,
        isValid: null
    },
    walingType_3: {
        value: false,
        isValid: null
    },
    walingType_4: {
        value: false,
        isValid: null
    },
    calcElements: {
        value: true,
        isValid: true
    },
    checkElements: {
        value: false,
        isValid: null
    },
    anchorForce: {
        value: '',
        isValid: null
    },
    sheetPileType: {
        value: '',
        isValid: null
    },
    space: {
        value: '',
        isValid: null
    },
    ceo: {
        value: '',
        isValid: null
    },
    steelClass: {
        value: defaultSteelClass.name,
        isValid: true
    },
    plateSteelClass: {
        value: defaultPlateSteelGrade.name,
        isValid: true
    },
    ceoFy: {
        value: defaultSteelClass.fyk,
        isValid: true
    },
    width: {
        value: '',
        isValid: null
    },
    height: {
        value: '',
        isValid: null
    },
    thickness: {
        value: '',
        isValid: null
    },
    d: {
        value: '',
        isValid: null
    },
    dsg: {
        value: '',
        isValid: null
    },
    type: {
        value: 'u',
        isValid: true
    },
    s: {
        value: '',
        isValid: null
    },
    med_sheet: {
        value: '',
        isValid: null
    },
    bb: {
        value: 1,
        isValid: true
    }
};

const Calculations = ({
    updateResultsState
}) => {

    const [state, setState] = useState(defaultState);
    const [viewType, setViewType] = useState(0);

    const onChangeView = (type) => setViewType(type);
    const onBlur = useCallback((e) => {
        const { name, value } = e.currentTarget;
        const valid = e.currentTarget.checkValidity();
        const greaterThanZeroProps = [];
        const greaterThanEqualZeroProps = [];

        setState((state) => {
            return {
                ...state,
                [name]: {
                    ...state[name],
                    isValid: greaterThanZeroProps.includes(name)
                        ? value > 0 && valid
                        : greaterThanEqualZeroProps.includes(name)
                        ? value >= 0 && valid
                        :
                        valid,
                },
            };
        });
    },[]);
    const onChangeType = useCallback((e) => {
        const img = e.currentTarget.closest('img');
        
        if(img){
            const { name } = img;
            updateResultsState((state) => {
                return { 
                    ...state,
                    isResultsActual: false,
                };
            });
            setState((state) => ({
                ...state,
                walingType_1: {
                    value: false,
                    isValid: null
                },
                walingType_2: {
                    value: false,
                    isValid: null
                },
                walingType_3: {
                    value: false,
                    isValid: null
                },
                walingType_4: {
                    value: false,
                    isValid: null
                },
                [name]: {
                    value: true,
                    isValid: true
                },
            }));
        }
    },[]);
    const onChange = useCallback((e, index) => {

        const input =
            e.currentTarget.closest('input') ||
            e.currentTarget.closest('select') ||
            e.currentTarget.closest('radio');

        if (input) {
            const { name, value, type, checked } = input;

            updateResultsState((state) => {
                return { 
                    ...state,
                    isResultsActual: false,
                };
            });
            if(type === 'checkbox') {
                    setState((state) => ({
                        ...state,
                        [name]: {
                            ...state[name],
                            value: !!checked,
                        },
                    }));
            } else {
                if(name === 'calcElements' || name === 'checkElements'){
                    setState((state) => ({
                        ...state,
                        calcElements: {
                            value: false,
                            isValid: null
                        },
                        checkElements: {
                            value: false,
                            isValid: null
                        },
                        [name]: {
                            ...state[name],
                            value: !!checked,
                            isValid: true
                        },
                    }));
                } else if(name === 'steelClass'){
                    const fyk = steelClasses.find(ele => ele.name === value) || {};
                    setState((state) => ({
                        ...state,
                        ceoFy: {
                            value: fyk.fyk,
                            isValid: isNumber(fyk.fyk) ? true : false
                        },
                        [name]: {
                            value: value,
                            isValid: true
                        },
                    }));
                } else {
                    if(name === 'sheetPileType'){
                        const type = sheetPiles[value] || {};
                        setState(stateObj => {
                            return {
                                ...stateObj,
                                type: {
                                    value: type.type,
                                    isValid: ['z','u'].includes(type.type) ? true : false
                                },
                                [name]: {
                                    value: value,
                                    isValid: true,
                                },
                            };
                        });
                    } else {
                        setState(stateObj => {
                            return {
                                ...stateObj,

                                [name]: {
                                    value: type === 'number' && value.length > 0 ? stringToNumber(value) : value,
                                    isValid: true,
                                },
                            };
                        });
                    }
                  
                }

            }
        }
    }, []);
    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    }, []);
    const loadProject = (loadedState = {}) => {
        setState(() => {
            return {
                ...defaultState,
                ...loadedState,
            };
        });
    };

    const calculationState = {
        onChange,
        onChangeType,
        onChangeView,
        onBlur,
        onKeyDown,
        updateState: setState,
        loadProject: loadProject,
        initState: () => setState(defaultState),

        state,
        sheetPilesWaling,
        viewType,
        ceo,
        steelClasses,
        plateSteelGrades
    };

    return {
        calculationState
    }

};

export default Calculations