import stringify from "fast-json-stable-stringify";
import { useEffect, useState } from "react";
import { isObjectId } from "../../../config/ProjectConfig";
import { AuthFetch } from "../../../routes";

const EditProjectHelpers = ({ 
    setAlert, 
    setLoading, 
    updatePileLoads, 
    updateSoilTable, 
    updateFoundationParameters, 
    updatePileProperties, 
    updateColculationSettings, 
    updateSaftyFactors, 
    updateResults,
    updateTitle,
    onLoadFileName,
    onLoadProfileName,

    onSetMainProjectName,
    onSetSoilProfileMainProjectName,

    projectId
}) => {
    const [currentProjectId, setCurrentProjectId] = useState('');
    const [currentProfileId, setCurrentProfileId] = useState('');
    const [loadProjectState, setLoadProjectState] = useState(false);
    const [currentProjectLoaded, setCurrentProjectLoaded] = useState(false);
    const kxCurrentProject = JSON.parse(localStorage.getItem('kx')) || {};

    const updateStateOnLoad = (response) => {
        setLoadProjectState(true);
        onSetMainProjectName(() => ({
            isValid: true,
            value: response.responseData.projectId.name
        }));
        onSetSoilProfileMainProjectName(() => ({
            value: response.responseData.projectId.name
        }));
        setCurrentProjectId(response.responseData.projectId.id);
        
        setCurrentProfileId(response.responseData.profileId);
        onLoadFileName({
            state: response.responseData.projectName ? 'loaded' : 'init',
            value: response.responseData.projectName
        });
        onLoadProfileName({
            state: 'init',
            value: ''
        });
        updatePileLoads(() => {
            return {
                ...response.responseData.model.pileLoads
            }
        });
        updateSoilTable(() => {
            return [
                ...response.responseData.model.soilsCollection
            ]
        });
        updateFoundationParameters(() => {
            return {
                ...response.responseData.model.foundationParameters
            }
        });
        updatePileProperties(() => {
            return {
                ...response.responseData.model.pileParameters
            }
        });
        updateColculationSettings(() => {
            return {
                ...response.responseData.model.calculationsSettings
            }
        });
        updateSaftyFactors(() => {
            return {
                ...response.responseData.model.saftyFactors
            }
        });
        updateTitle(() => {
            return {
                projectTitle: response.responseData.model.projectTitle
            }
        });
        setTimeout(() => {
            updateResults(() => {
                return { 
                    ...response.responseData.results,
                    ...!!response.responseData.results.currentCapacity && {
                        summaryResults: response.responseData.results.currentCapacity
                    }
                }
            })
        },0);        
    }

    useEffect(() => {
        if(!currentProjectLoaded && kxCurrentProject.id && kxCurrentProject.projectId){
            setLoadProjectState(false);
            AuthFetch({
                url: `/api/kosecki-method/project/${kxCurrentProject.id}/${kxCurrentProject.projectId}`,
                method: 'GET',
            }).then(response => {
                if(response.success){
                    setCurrentProjectLoaded(true);
                    updateStateOnLoad(response);                    
                }
            })
        }
    },[currentProjectLoaded, kxCurrentProject.id, kxCurrentProject.projectId])

    const onLoadProject = (id) => {
        if(isObjectId(id) && isObjectId(projectId)){
            setLoading({ name: 'fetchProject', loading: true });
            setLoadProjectState(false);
            AuthFetch({
                url: `/api/kosecki-method/project/${id}/${projectId}`,
                method: 'GET',
            }).then(response => {
                setLoading({ name: 'fetchProject', loading: false });
                if(response.success) {
                    localStorage.setItem('kx', stringify({ id: response.responseData.id, projectId: response.responseData.projectId.id }));
                    setCurrentProjectLoaded(true);
                    updateStateOnLoad(response);
                } else {
                    setAlert({ error: true, message: 'Błąd podczas wczytywania projektu' });
                }
            })
            .catch(() => {
                setAlert({ error: true, message: 'Błąd podczas wczytywania projektu' });
            })
        }
    }

    const editProject = {
        onLoadProject: onLoadProject,
        currentProjectId: currentProjectId,
        currentProfileId: currentProfileId,
        loadProjectState: loadProjectState,
    }

    return {
        editProject
    }
}

export default EditProjectHelpers