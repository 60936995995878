/*Minova samowiercacy*/

const drillType = {
    0: 'X',
    1: 'EX',
    2: 'EXX',
    3: 'EC',
    4: 'ECC',
    5: 'Clay',
    6: 'ES-F',
    7: 'ESS-F',
	8: 'XX',
	9: 'EY',
	10: 'EYY',
	11: 'ESS-D',
	12: 'ES-D',
	13: 'EX-4',
	14: 'EX-5',
}
const drill_T111 = [
	[drillType[8],175],
	[drillType[13],220],
	[drillType[14],220],
	[drillType[5],220],
	[drillType[7],170],
]
const drill_T76 = [
	[drillType[8],130],
	[drillType[8],145],
	[drillType[8],175],
	[drillType[8],200],
	[drillType[8],280],
	[drillType[8],300],
	
	[drillType[1],130],
	[drillType[5],130],
	[drillType[1],150],
	[drillType[2],150],
	[drillType[5],150],
	[drillType[5],175],
	[drillType[2],200],
	[drillType[5],200],
	[drillType[7],120],
]
const drill_T63N = [
    [drillType[1],115],
	[drillType[7],115],
	[drillType[5],130],
]
const drill_T51S = [
    [drillType[1],175],
	[drillType[5],175],
	[drillType[1],115],
	[drillType[7],115],
	[drillType[5],130],

]
const drill_R51 = [
    [drillType[8],100],
	[drillType[8],110],
	[drillType[8],120],
	[drillType[8],130],
	[drillType[8],76],
	[drillType[8],90],
	[drillType[8],170],
	[drillType[8],175],
	[drillType[8],183],
	[drillType[8],200],
	
	[drillType[1],100],
	[drillType[1],115],
	[drillType[2],115],
	[drillType[1],130],
	[drillType[2],130],
	[drillType[5],200],
	[drillType[5],150],
	[drillType[5],175],
	[drillType[5],76],
	[drillType[2],90],
	[drillType[9],90],
	[drillType[5],90],
	[drillType[6],100],
	[drillType[7],100],
	[drillType[12],115],
	[drillType[11],115],
	[drillType[7],76],

]
const drill_R38 = [
    [drillType[8],110],
	[drillType[8],115],
	[drillType[8],130],
	[drillType[8],150],
	[drillType[8],200],
	[drillType[5],110],
	
	[drillType[2],115],
	[drillType[5],130],
	[drillType[1],76],
	[drillType[9],76],
	[drillType[10],76],
	[drillType[1],90],
	[drillType[2],90],
	[drillType[10],90],
	
	[drillType[5],90],
	[drillType[7],76],
	[drillType[6],90],
	[drillType[7],90],
	[drillType[11],115],
]
const drill_R32 = [
    [drillType[0],51],
	[drillType[1],51],
	[drillType[1],76],
	[drillType[1],90],
	[drillType[2],51],
	[drillType[5],76],
	
	[drillType[5],90],
	[drillType[5],110],
	[drillType[3],51],
	[drillType[4],51],
	[drillType[6],51],
	[drillType[7],51],
	[drillType[6],76],
	[drillType[7],76],
]
const drill_R25 = [
    [drillType[0],51],
	[drillType[1],42],
	[drillType[1],51],
	[drillType[2],42],
	[drillType[3],42],
	[drillType[4],42],
]

const minova = [
    {
        prod: 'MINOVA',
        name: 'R25',
        subname: '',
        fu: 200,
        rmk: 150,
        drill: drill_R25
    },
    {
        prod: 'MINOVA',
        name: 'R32L',
        subname: '',
        fu: 210,
        rmk: 160,
        drill: drill_R32
    },
    {
        prod: 'MINOVA',
        name: 'R32N',
        subname: '',
        fu: 280,
        rmk: 230,
        drill: drill_R32
    },
    {
        prod: 'MINOVA',
        name: 'R32S',
        subname: '',
        fu: 360,
        rmk: 280,
        drill: drill_R32
    },
    {
        prod: 'MINOVA',
        name: 'R38N',
        subname: '',
        fu: 500,
        rmk: 400,
        drill: drill_R38
    },
    {
        prod: 'MINOVA',
        name: 'R51L',
        subname: '',
        fu: 550,
        rmk: 450,
        drill: drill_R51
    },
    {
        prod: 'MINOVA',
        name: 'R51N',
        subname: '',
        fu: 800,
        rmk: 630,
        drill: drill_R51
    },
    {
        prod: 'MINOVA',
        name: 'T51S',
        subname: '',
        fu: 1050,
        rmk: 750,
        drill: drill_T51S
    },
    {
        prod: 'MINOVA',
        name: 'T63N',
        subname: '',
        fu: 1400,
        rmk: 900,
        drill: drill_T63N
    },
    {
        prod: 'MINOVA',
        name: 'T76N',
        subname: '',
        fu: 1600,
        rmk: 1200,
        drill: drill_T76
    },
    {
        prod: 'MINOVA',
        name: 'T76S',
        subname: '',
        fu: 1900,
        rmk: 1500,
        drill: drill_T76
    },
    {
        prod: 'MINOVA',
        name: 'T111L',
        subname: '',
        fu: 2640,
        rmk: 2000,
        drill: drill_T111
    },
    {
        prod: 'MINOVA',
        name: 'T111N',
        subname: '',
        fu: 3650,
        rmk: 2750,
        drill: drill_T111
    },
];

export default minova;
