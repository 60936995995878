import React, { Fragment, useState } from 'react';
import { withPagination } from '../../../../../hoc';
import { ItemsPerPage, Pagination, SectionTitle, Table } from '../../../../../components';
import styles from './index.module.css';
import round from '../../../../../utils/roundNumber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import CorrectDepth from '../CorrectDepth';
import { isNumber } from '../../../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const MicropileCapacityPullTable = ({ dataToPaginate, allCollection=[], pagination, paginationHelpers }) => {
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [csvData, setCsvData] = useState([]);

    useDeepCompareEffect(() => {
        setCsvData(allCollection.map(({ z,d,qsk,diameter,yt,modelFactor,ksi,ntw }) => ({ z,d,qsk,diameter,yt,modelFactor,ksi,ntw })).map(ele => Object.fromEntries(Object.entries(ele).map(([key, value='']) => ([ [key], value.toString().replace('.',',') ])) )));

        setCsvHeaders([
            {
                label: 'Z [m.p.p.t.]',
                key: 'z'
            },
            {
                label: 'd [m]',
                key: 'd'
            },
            {
                label: 'qsk [kPa]',
                key: 'qsk'
            },
            {
                label: 'D [mm]',
                key: 'diameter'
            },
            {
                label: 'yt [-]',
                key: 'yt'
            },
            {
                label: 'ksi [-]',
                key: 'ksi'
            },
            {
                label: 'M',
                key: 'modelFactor'
            },
            {
                label: 'Rt,d [kN]',
                key: 'ntw'
            }
        ]);
        
    },[allCollection]);

    return (
        <Fragment>
            <SectionTitle title={'Nośność mikropala na wyciąganie'}/>
            <ItemsPerPage
                onDisplayItemsNumber={paginationHelpers.displayItemsNumber} 
                itemsPerPage={pagination.itemsPerPage} 
                totalItems={paginationHelpers.allItemsLength}
                display={[15,30, paginationHelpers.allItemsLength]}
            />
            <Table csvData={csvData} csvFilename={"micropileCapacityPull"} csvHeaders={csvHeaders}> 
                <thead>
                    <tr>
                        <th>Nr<br></br>[-]</th>
                        <th title="Rzędna">z<br></br>[m.p.p.t.]</th>
                        <th title="Opór gruntu na pobocznicy">q<sub>s,k</sub><br></br>[kPa]</th>
                        <th title="Średnica koronki wiertniczej">d<br></br>[mm]</th>
                        <th title="Poszerzenie średnicy">a<br></br>[-]</th>
                        <th title="Poszerzenie średnicy">a<sub>cpt</sub><br></br>[mm]</th>
                        <th title="Wsp. bezpieczeństwa">&#947;<sub>s,t</sub><br></br>[-]</th>
                        <th title="Wsp. modelu">&#958;<br></br>[-]</th>
                        <th title="Wsp. modelu">&#951;<sub>M</sub><br></br>[-]</th>
                        <th title="Przyrost nośności na wyciąganie">R<sub>t,i</sub><br></br>[kN]</th>
                        <th title="Nośność obliczeniowa na wyciąganie">R<sub>t,d</sub><br></br>[kN]</th>
                        <th title="Siła do próbnego obciążenia na wyciąganie">R<sub>t,lt</sub><br></br>[kN]</th>

                        <th title="Poprawność zagłębienia mikropala w gruncie"></th>
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    {
                        dataToPaginate.map((element, index) => {
                            const veryfiedNt = element.veryfiedNtw;
                            const axisLoadCorrect = isNumber(veryfiedNt.axisLoad) ? veryfiedNt.axisLoad >= 1 ? true : false : false;
                            const steelRodCorrect = isNumber(veryfiedNt.steelRodLoad) ? veryfiedNt.steelRodLoad > 1 ? false : true : true;

                            const correctLength = axisLoadCorrect;

                            return (
                                <tr className={`${styles.tr} ${ correctLength ? steelRodCorrect ? styles.validDepth : styles.warningDepth : styles.wrongDepth }`} key={index}>
                                    <td>{paginationHelpers.countingItemsPerPage + index+1}</td>
                                    <td>{round(element.z,2)}</td>
                                    <td>{round(element.qsk,2)}</td>
                                    <td>{round(element.diameter,2)}</td>
                                    <td>{element.isCpt ? '-' : round(element.enlargeDiameter,2)}</td>
                                    <td>{element.isCpt ? round(element.enlargeDiameter,2) : '-'}</td>
                                    <td>{round(element.yst,2)}</td>
                                    <td>{round(element.ksi,2)}</td>
                                    <td>{round(element.modelFactor,2)}</td>
                                    <td>{round(element.ntwi,2)}</td>
                                    <td>{round(element.ntw,2)}</td>
                                    <td>{round(element.ntw*1.5,2)}</td>
                                    <td>
                                        <FontAwesomeIcon icon={correctLength ? faCheckCircle : faTimesCircle}/>
                                        <div className={styles.info}>
                                            <CorrectDepth
                                                className={styles.depthColor}
                                                correctDepth={{
                                                    micropileCapacity: element.veryfiedNtw,
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>

            <Pagination
                currentPage={pagination.currentPage} 
                itemsPerPage={pagination.itemsPerPage} 
                totalItems={paginationHelpers.allItemsLength} 
                nextPage={paginationHelpers.nextPage}
            />
        </Fragment>

    )
}

export default withPagination({
    component: MicropileCapacityPullTable,
    returnAllCollection: true,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 25,
    }
})