import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import styles from './index.module.css';

const CheckboxInput = ({
    onChange,
    label,
    title,
    name,
    value,
    children,
    ...rest
}) => {
    return (
        <div className={styles.wrapper}>
            {
                <div className={styles.titleWrapper}>
                    {title && <label className={styles.title}>{title}</label>}

                    {children && (
                        <Fragment>
                            <FontAwesomeIcon
                                className={styles.infoIcon}
                                icon={faQuestionCircle}
                            />
                            <div className={`${styles.info} defaultBox`}>
                                {children}
                            </div>
                        </Fragment>
                    )}
                </div>
            }

            <div className={`${styles.switch} checkbox-switch`}>
                <input
                    className={styles.checkbox}
                    type="checkbox"
                    name={name}
                    checked={value}
                    title={label}
                    onChange={onChange}
                    {...rest}
                />
                <label className={styles.label}>
                    <div
                        className={styles.ball}
                        checked={value ? 'checked' : ''}></div>
                </label>
                <span>{label}</span>
            </div>
        </div>
    );
};

export default CheckboxInput;
