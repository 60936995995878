import { Col, Row } from "react-bootstrap";
import { SectionTitle, SelectField } from "../../../../../components";
import { withResults } from "../../../../../hoc";
import CrossSection from "../CrossSection";
import DesignTable from "../DesignTable";
import Summary from "../Summary";
import styles from './index.module.css';
import { Fragment } from "react";
import CrossSectionCapacity from "../CrossSectionCapacity";
import CrossSectionPoints from "../CrossSectionPoints";
import CapacityTable from "../CapacityTable";
import ShearSummary from "../ShearSummary";
import DesignShearTable from "../DesignShearTable";
import SecondOrderEffect from "../SecondOrder";
import SummarySGU from "../SummarySGU";

const ResultsWrapper = ({ data={} }) => {
    return (
        <div className={'vertical-capacity-results'}>
            <Fragment>
                <Row className={styles.row}>
                    <Col xs={12} sm={3}>
                        <SelectField
                            name="shortResults"
                            title="Widok raportu obliczeń"
                            onChange={data.onShortResultsChange}
                            value={data.shortResults.short ? 'Widok skrócony' : 'Widok szczegółowy'}
                            defaultValue={data.shortResults.short ? 'Widok skrócony' : 'Widok szczegółowy'}
                            
                            options={['Widok skrócony', 'Widok szczegółowy']}
                            required
                        />                                    
                    </Col>
                </Row> 
                {
                    Array.isArray(data.calculation)
                    ?
                    data.calculation.map((ele,idx) => {
                        if(ele.incorrectDimensions){
                            return (
                                <Row key={idx} styles={styles.row}>
                                    <SectionTitle classNameWrapper={styles.center} title={"Błąd wymiarowania przekroju"} />

                                    <Col xs={12}>
                                        <p className={styles.incorrectSection}>Niepoprawne wymiary przekroju. <br></br>Niemożliwy dobór odpowiedniego zbrojenia.</p>
                                    </Col>
                                </Row>                                
                            )
                        } else {
                            const checkCapacity = data.checkCapacity.state.find(element => element.index === ele.defaultResults.index) || {};
                            const crossSectionCapacity = data.sectionCapacity.state.find(element => element.index === ele.defaultResults.index);
                            const newPoint = data.sectionCapacity.newPoint.find(element => element.index === ele.defaultResults.index);
                            const dataToPaginate = Array.isArray(ele.crossSectionCapacity) && Array.isArray(ele.crossSectionCapacityNegative) 
                                ? 
                                ele.crossSectionCapacity.map((element,idx) => {
                                    return {
                                        ...element,
                                        mrdNeg: -ele.crossSectionCapacityNegative[idx].mrd
                                    }
                                })
                                .filter((_,idx, arr) => (idx > 0 && idx < arr.length-1) ? idx%5 === 0 : true)
                                :
                                [];

                            return (
                                <Fragment key={idx}>
                                    <Row  className={styles.row}>
                                        <SectionTitle classNameWrapper={styles.center} 
                                            title={
                                                <div className={styles.initLetter}>
                                                    <b>
                                                        M<sub>x,SGN</sub>={`${ele.defaultResults.initMed} kNm`},&nbsp; 
                                                        {
                                                            data.initModelValues.memberType !== 'Słup' && ele.defaultResults.isCrackCalc &&
                                                            <Fragment>M<sub>x,SGU</sub>={`${ele.defaultResults.medp} kNm`},&nbsp; </Fragment>
                                                        }                                                        
                                                        {
                                                            data.initModelValues.memberType === 'Słup' && <Fragment>N<sub>z,SGN</sub>={`${ele.defaultResults.ned} kN`}, &nbsp;</Fragment>
                                                        }
                                                        {
                                                            data.initModelValues.memberType !== 'Płyta' &&
                                                            <Fragment>
                                                                V<sub>y,SGN</sub>={`${ele.defaultResults.shearForce} kN`}
                                                            </Fragment>
                                                        }
                                                    </b>
                                                </div>
                                            } 
                                        />
                                        
                                        <Col 
                                            xs={12} 
                                            sm={!ele.defaultResults.isCrackCalc ? data.initModelValues.b > 3*data.initModelValues.h ? 5 : 4 : 12}>
                                            <CrossSection
                                                dimension={data.initModelValues.h}
                                                negativeMoment={ele.defaultResults.negativeMoment}
                                                width={data.initModelValues.b}
                                                pileShape={data.initModelValues.circular ? 1 : 2}
                                                memberType={data.initModelValues.memberType}
                                                coverUp={data.initModelValues.coverUp}
                                                coverBottom={data.initModelValues.coverBottom}
                                                checkCapacity={checkCapacity}
                                                rebarNumber={checkCapacity.rebarNumber}
                                                rebarDiameter={checkCapacity.rebarDiameter}
                                                rebarSpiral={checkCapacity.rebarSpiral}

                                                rebarNumberAs1={checkCapacity.rebarNumberAs1}
                                                rebarNumberAs2={checkCapacity.rebarNumberAs2}
                                                rebarDiameterAs1={checkCapacity.rebarDiameterAs1}
                                                rebarDiameterAs2={checkCapacity.rebarDiameterAs2}

                                                x={ele.result.x}
                                                d={ele.defaultResults.d}
                                            />
                                        </Col>
                                        <Col xs={12} sm={!ele.defaultResults.isCrackCalc ? data.initModelValues.b > 3*data.initModelValues.h ? 7 : 8 : 12}>
                                            <Row key={idx} className={styles.row}>
                                                <Col xs={12} sm={
                                                    data.initModelValues.memberType !== 'Płyta'
                                                        ? ele.defaultResults.isCrackCalc && data.initModelValues.memberType !== 'Słup' ? 4 : 6
                                                        : ele.defaultResults.isCrackCalc ? 6 : 12
                                                    }>
                                                    <Summary
                                                        results={ele}
                                                        xlim={ele.defaultResults.xlim}
                                                        a2={ele.defaultResults.a2}
                                                        d={ele.defaultResults.d}
                                                        pileShape={data.initModelValues.circular ? 1 : 2}
                                                        memberType={data.initModelValues.memberType}
                                                    />
                                                </Col>
                                                {
                                                    ele.defaultResults.isCrackCalc && data.initModelValues.memberType !== 'Słup' &&
                                                    <Col xs={12} sm={
                                                            data.initModelValues.memberType !== 'Płyta'
                                                                ? 4
                                                                : 6
                                                    }>
                                                        <SummarySGU
                                                            results={ele}
                                                            xlim={ele.defaultResults.xlim}
                                                            a2={ele.defaultResults.a2}
                                                            d={ele.defaultResults.d}
                                                        />
                                                    </Col>
                                                }
                                                {
                                                    data.initModelValues.memberType !== 'Płyta' &&
                                                    <Col xs={12} sm={ele.defaultResults.isCrackCalc && data.initModelValues.memberType !== 'Słup' ? 4 : 6}>
                                                        <ShearSummary 
                                                            results={ele.shearResult}
                                                            shearForce={ele.defaultResults.shearForce}
                                                            stirrupType={data.initModelValues.stirrupType}
                                                            pileShape={data.initModelValues.circular ? 1 : 2}
                                                        />
                                                    </Col>                                                    
                                                }

                                            </Row>
                                        </Col>
                                    </Row>
                                    {
                                        (!ele.result.reinfAsNotRequired || ele.defaultResults.minimumReinforcement === 1) &&
                                        <Row className={styles.row}>
                                            <Col className={styles.designTable} xs={12}>
                                                <DesignTable
                                                    lbd={ele.lbd}
                                                    isLbdCalc={ele.defaultResults.lbdCalculation}
                                                    pileShape={data.initModelValues.circular ? 1 : 2}
                                                    xlim={ele.defaultResults.xlim}
                                                    x={ele.result.x}
                                                    diameter={data.initModelValues.h}
                                                    b={data.initModelValues.b}
                                                    a2={ele.defaultResults.a2}
                                                    data={checkCapacity}
                                                    d={ele.defaultResults.d}
                                                    negativeMoment={ele.defaultResults.negativeMoment}
                                                />
                                            </Col>
                                        </Row>
                                    }
                                    {
                                        (!ele.shearResult.incorrectDiameter && !ele.shearResult.reinfNotRequired && ele.defaultResults.shearForce !== 0 && data.initModelValues.memberType !== 'Płyta') &&
                                        <Row className={styles.row}>
                                            <DesignShearTable
                                                results={ele.shearResult}
                                                stirrupType={data.initModelValues.stirrupType}
                                                memberType={data.initModelValues.memberType}
                                                nCut={data.initModelValues.nCut}
                                                shearCondition={checkCapacity.conditionShear}
                                                spiral={data.initModelValues.spiral}
                                            />
                                        </Row>                                    
                                    }
                                    {
                                        !data.shortResults.short && (ele.defaultResults.ea || ele.secondOrderEffect) && ele.defaultResults.ned > 0 &&
                                        <Row className={styles.row}>
                                            <SecondOrderEffect
                                                result={ele.defaultResults}
                                                imperfection={ele.defaultResults.ea}
                                                ned={ele.defaultResults.ned}
                                                secondOrder={ele.secondOrderEffect}
                                            />
                                        </Row>
                                    }
                                    {
                                        !data.shortResults.short &&
                                        <Row className={styles.row}>
                                            <SectionTitle title="Wykres interakcji przekroju żelbetowego (M-N)" />
                                            <Col xs={12} md={9}>
                                                <CrossSectionCapacity
                                                    chartTitle="Nośność przekroju - wykres interakcji"
                                                    yLabel="N,rd [kN]"
                                                    xLabel="M,rd [kNm]"

                                                    circular={data.initModelValues.circular}
                                                    shape={data.shape}
                                                    calcResult={ele.result}
                                                    x={ele.result.x}
                                                    a2={ele.defaultResults.a2}
                                                    result={crossSectionCapacity}
                                                />
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <CrossSectionPoints
                                                    onChange={data.sectionCapacity.onChange}
                                                    onAddPoint={data.sectionCapacity.onAddPoint}
                                                    onRemovePoint={data.sectionCapacity.onRemovePoint}
                                                    result={crossSectionCapacity}
                                                    newPoint={newPoint}
                                                    index={ele.defaultResults.index}
                                                />
                                            </Col>
                                        </Row>
                                    }
                                    {
                                        !data.shortResults.short &&
                                        <Row className={styles.row}>
                                            <SectionTitle title="Szczegółowe wyniki nośności przekroju" />
                                            <Col xs={12}>
                                                <CapacityTable
                                                    circular={data.initModelValues.circular}
                                                    dataToPaginate={dataToPaginate}
                                                />
                                            </Col>
                                        </Row>                                
                                    }
                                </Fragment>
                            )
                        }

                    })
                    :
                    <Fragment></Fragment>
                }
            </Fragment>
        </div>
    )
}

export default withResults(ResultsWrapper)