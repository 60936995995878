import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment, useMemo, useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, InputField, SectionTitle, Modal, RadioInput, Subtitle, Checkbox } from '../../../../components';
import styles from './index.module.css';
import stringify from 'fast-json-stable-stringify';

const SaftyFactors = ({ state, isFreeLength, isSteelRod, onChange, onBlur, onKeyDown }) => {

    const [open, setOpen] = useState({}); 
    const [isRodCapacityVisible, setIsRodCapacityVisible] = useState(false);

    const onOpen = (e) => {
        const target = e.currentTarget;  
        if(target){
            setOpen(state => ({ ...state, [target.name]: true }))
        } 
    }; 
    const onHide = () => setOpen({});

    useEffect(() => {
        setIsRodCapacityVisible( isSteelRod || open.steelRodFactors );
    },[
        isSteelRod,
        open
    ]);

    return(
        <Fragment>    
            <div className={styles.wrapper}>
                <SectionTitle title="Ustawienia i współczynniki technologiczne" />
                <Row className={styles.row}>
                    <Col xs={isRodCapacityVisible ? 6 : 12}>
                        <Button 
                            onClick={onOpen}
                            icon={faFolderOpen}
                            css={`${styles.button} default-button-style`}
                            name="technologyFactors"
                            altTitle="Parametry obliczeń"
                            title="Parametry obliczeń"
                        />
                    </Col>
                    {
                        isRodCapacityVisible && 
                        <Col xs={6}>
                            <Button 
                                onClick={onOpen}
                                icon={faFolderOpen}
                                css={`${styles.button} default-button-style`}
                                name="steelRodFactors"
                                altTitle="Parametry żerdzi"
                                title="Parametry żerdzi"
                            />
                        </Col>                    
                    }

                </Row>
            </div>
            <Modal open={open.technologyFactors} css={styles.modalOverflow} cssDialog={styles.modal} onHide={onHide} title="Parametry obliczeń">
                <Row className={styles.row}>
                    <Col xs={12}>
                        <Subtitle title="Poszerzenie średnicy na podstawie stanu i rodzaju gruntu [d x a]">
                            Współczynnik poszerzenia średnicy jest wartością empiryczną uzyskaną głównie z badań Ischebeck Titan. Taki sposób poszerzenia średnicy oraz odpowiedni opór graniczny gruntu na pobocznicy mikropala w danej warstwie stosowany jest, gdy nie podano wartości q<sub>c</sub> lub c<sub>u</sub> w tabeli parametrów gruntu. Poszerzenie trzonu mikropala oraz wartość oporu granicznego na pobocznicy q<sub>s,k</sub> dobierane jest na podstawie stanu gruntu (I<sub>d</sub> lub I<sub>L</sub>) oraz rodzaju gruntu.
                        </Subtitle>                    
                    </Col>
                    <Col xs={12} sm={6}>
                        <InputField 
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.gravel.value}
                            isValid={state.gravel.isValid}
                            type="number"
                            title="Pospółki i żwiry [-]"
                            placeholder="Poszerzenie w pospółkach i żwirach"
                            name="gravel"
                            disable={true}
                            step={0.001}
                            min={1}

                            required
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <InputField 
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.sands.value}
                            isValid={state.sands.isValid}
                            type="number"
                            title="Piaski [-]"
                            placeholder="Poszerzenie średnicy w piaskach"
                            name="sands"
                            disable={true}
                            step={0.001}
                            min={1}

                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6}>
                        <InputField 
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.cohesiveSoils.value}
                            isValid={state.cohesiveSoils.isValid}
                            type="number"
                            title="Grunty spoiste [-]"
                            placeholder="Poszerzenie średnicy w gruntach spoistych"
                            name="cohesiveSoils"
                            disable={true}
                            step={0.001}
                            min={1}

                            required
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <InputField 
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.rocks.value}
                            isValid={state.rocks.isValid}
                            type="number"
                            title="Grunty skaliste [-]"
                            placeholder="Poszerzenie średnicy w skałach"
                            name="rocks"
                            disable={true}
                            step={0.001}
                            min={1}

                            required
                        />
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <Subtitle title={<Fragment>Poszerzenie średnicy na podstawie paramterów q<sub>c</sub> lub c<sub>u</sub> [d + a]</Fragment>}>
                            Wartość poszerzenia średnicy jest wartością empiryczną uzyskaną głównie z badań Ischebeck Titan oraz Niemieckiego Komitetu Geotechniki. Taki sposób poszerzenia średnicy w danej warstwie stosowany jest, gdy podano wartości q<sub>c</sub> lub c<sub>u</sub> w tabeli parametrów gruntu. Poszerzenie trzonu mikropala dobierane jest na podstawie rodzaju gruntu, natomiast opór graniczny na pobocznicy mikropala dobierany jest na podstawie tabel EA-Pf&#228;le w zależności q<sub>c</sub> &#8594; q<sub>s,k</sub> oraz c<sub>u</sub> &#8594; q<sub>s,k</sub>.
                        </Subtitle>                    
                    </Col>
                    <Col xs={12} sm={6}>
                        <InputField 
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.gravelCpt.value}
                            isValid={state.gravelCpt.isValid}
                            type="number"
                            title="Pospółki i żwiry [mm]"
                            placeholder="Poszerzenie w pospółkach i żwirach"
                            name="gravelCpt"
                            disable={true}
                            step={0.001}
                            min={1}

                            required
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <InputField 
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.sandsCpt.value}
                            isValid={state.sandsCpt.isValid}
                            type="number"
                            title="Piaski [mm]"
                            placeholder="Poszerzenie średnicy w piaskach"
                            name="sandsCpt"
                            disable={true}
                            step={0.001}
                            min={1}

                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6}>
                        <InputField 
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.cohesiveSoilsCpt.value}
                            isValid={state.cohesiveSoilsCpt.isValid}
                            type="number"
                            title="Grunty spoiste [mm]"
                            placeholder="Poszerzenie średnicy w gruntach spoistych"
                            name="cohesiveSoilsCpt"
                            disable={true}
                            step={0.001}
                            min={1}

                            required
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <InputField 
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.rocksCpt.value}
                            isValid={state.rocksCpt.isValid}
                            type="number"
                            title="Grunty skaliste [mm]"
                            placeholder="Poszerzenie średnicy w skałach"
                            name="rocksCpt"
                            disable={true}
                            step={0.001}
                            min={1}

                            required
                        />
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12} sm={6} className={`${styles.flexEnd} ${styles.flexColumn}`}>
                        <Checkbox
                            onChange={onChange} 
                            title="Uwzględnij grunty słabe" 
                            name="softSoilCount"
                            label={state.softSoilCount.value ? 'Tak' : 'Nie'}
                            value={state.softSoilCount.value}
                        >
                            Przy obliczaniu nośności mikropali wciskanych i wyciąganych uwzględnione zostają grunty niespoiste o wartości q<sub>c</sub> &#60; 7,5MPa oraz grunty spoiste o wartości c<sub>u</sub> &#60; 60kPa. Z wyjątkiem gruntu organicznego, którego wartość q<sub>s,k</sub> zawszę będzie 0.
                        </Checkbox>
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12} sm={6} className={`${styles.flexEnd} ${styles.flexColumn}`}>
                        <RadioInput
                            onChange={onChange} 
                            title="Rodzaj nośności zgodnie z Eurokodem 7" 
                            radioTitle="(Rc,calc)mean"
                            name="capacityType"
                            label={useMemo(() => <Fragment>(R<sub>c;calc</sub>)<sub>mean</sub></Fragment> ,[])}
                            checked={state.capacityType.value === 1}
                            value={1}
                        />
                    </Col>
                    <Col xs={12} sm={6} className={`${styles.flexEnd}`}>
                        <RadioInput
                            onChange={onChange} 
                            name="capacityType"
                            radioTitle="(Rc,calc)min"
                            label={useMemo(() => <Fragment>(R<sub>c;calc</sub>)<sub>min</sub></Fragment> ,[])}
                            checked={state.capacityType.value === 2}
                            value={2}
                        />
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12} sm={6}>
                        <InputField 
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.profilesNumber.value}
                            isValid={state.profilesNumber.isValid}
                            type="number"
                            title={"Liczba zbadanych profili gruntowych"}
                            placeholder="Liczba profilów"
                            name="profilesNumber"
                            disable={true}
                            step={0.001}
                            min={1}

                            required
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <InputField 
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.ksi.value}
                            isValid={state.ksi.isValid}
                            type="number"
                            title={useMemo(() => <Fragment>Współczynnik zależny od liczby badań podłoża - &#11420;<sub>{state.capacityType.value === 1 ? 3 : 4}</sub></Fragment> ,[state.capacityType.value])}
                            placeholder="Wsp. korelacyjny"
                            name="ksi"
                            disable={true}
                            step={0.001}
                            min={1}

                            required
                        />
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12} sm={6}>
                        <InputField 
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.yt.value}
                            isValid={state.yt.isValid}
                            type="number"
                            title={useMemo(() => <Fragment>Częściowy współczynnik bezpieczeństwa - &#947;<sub>s,t</sub> (zestaw R2)</Fragment> ,[])}
                            placeholder="Wsp. redukcyjny"
                            name="yt"
                            disable={true}
                            step={0.001}
                            min={1}

                            required
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <InputField 
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.modelFactor.value}
                            isValid={state.modelFactor.isValid}
                            type="number"
                            title={useMemo(() => <Fragment>Współczynnik modelu - &#951;<sub>M</sub></Fragment>,[])}
                            placeholder="Współczynnik modelu"
                            name="modelFactor"
                            disable={true}
                            step={0.001}
                            min={1}

                            required
                        >
                            Współczynnik modelu zgodnie z DIN EN 14199 stosowany do nośności obliczeniowej mikropali wyciąganych obliczanej na podstawie wyników sondą CPT (q<sub>c</sub> lub c<sub>u</sub>)     
                        </InputField>
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12} sm={6}>
                        <InputField 
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.qccuInterpolation.value}
                            isValid={state.qccuInterpolation.isValid}
                            type="number"
                            title={useMemo(() => <Fragment>Zakres interpolacji granicznego oporu gruntu - q<sub>s,k</sub></Fragment>,[])}
                            placeholder="Zakres interpolacji"
                            name="qccuInterpolation"
                            disable={true}
                            step={0.001}
                            min={0}
                            max={1}

                            required
                        />
                    </Col>                    
                    {         
                    !isFreeLength &&
                    <Col xs={12} sm={6}>
                        <InputField 
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            value={state.micropileFreeSpace.value}
                            isValid={state.micropileFreeSpace.isValid}
                            type="number"
                            title="Odległość części nośnej mikropala od klina odłamu [m]"
                            placeholder="Odległość od klina odłamu"
                            name="micropileFreeSpace"
                            disable={true}
                            step={0.001}
                            min={0}

                            required
                        />
                    </Col>
                    }
                </Row>
            </Modal>
            <Modal open={open.steelRodFactors} css={styles.modalOverflow} cssDialog={styles.modal} onHide={onHide} title="Parametry żerdzi">
                {
                    isRodCapacityVisible && 
                    <Fragment>
                        <Row className={styles.row}>
                            <Col xs={12} sm={6} className={`${styles.flexEnd} ${styles.flexColumn}`}>
                                <RadioInput
                                    onChange={onChange} 
                                    title="Sposób doboru żerdzi mikropala" 
                                    radioTitle="Nośność plastyczna"
                                    name="steelRodCapacity"
                                    label="Nośność obliczeniowa/plastyczna"
                                    checked={state.steelRodCapacity.value === 1}
                                    value={1}
                                /> 
                            </Col>
                            <Col xs={12} sm={6} className={`${styles.flexEnd}`}>
                                <RadioInput
                                    onChange={onChange} 
                                    name="steelRodCapacity" 
                                    radioTitle="Nośność na zerwanie"
                                    label="Nośność na zerwanie"
                                    checked={state.steelRodCapacity.value === 2}
                                    value={2}
                                />
                            </Col>
                        </Row>
                        <Row className={styles.row}>
                            <Col xs={12} sm={6} className={`${styles.flexEnd} ${styles.flexColumn}`}>
                                <RadioInput
                                    onChange={onChange} 
                                    title="Sposób wymiarowania długości odcinka nośnego/buławy" 
                                    radioTitle="Podana siła obliczeniowa"
                                    name="bondCapacityType"
                                    label="Podana siła obliczeniowa"
                                    checked={state.bondCapacityType.value === 1}
                                    value={1}
                                />
                            </Col>
                            <Col xs={12} sm={6} className={`${styles.flexEnd}`}>
                                <RadioInput
                                    onChange={onChange} 
                                    name="bondCapacityType"
                                    radioTitle="Nośność żerdzi"
                                    label="Nośność żerdzi"
                                    checked={state.bondCapacityType.value === 2}
                                    value={2}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6}>
                                <InputField 
                                    onChange={onChange}
                                    onKeyDown={onKeyDown}
                                    onBlur={onBlur}
                                    value={state.designToCharLoads.value}
                                    isValid={state.designToCharLoads.isValid}
                                    type="number"
                                    title="Współczynnik obciążeń"
                                    placeholder="Wsp. obciążeń"
                                    name="designToCharLoads"
                                    disable={true}
                                    step={0.001}
                                    min={1}

                                    required
                                >
                                    Współczynnik stosowany do przeliczenia osiowej siły obliczeniowej na siłę charakterystyczną w celu wyznaczenia sprężystego wydłużenia mikropala
                                </InputField>
                            </Col>                            
                            <Col xs={12} sm={6}>
                                <InputField 
                                    onChange={onChange}
                                    onKeyDown={onKeyDown}
                                    onBlur={onBlur}
                                    value={state.steelRodYt.value}
                                    isValid={state.steelRodYt.isValid}
                                    type="number"
                                    title="Współczynnik bezpieczeństwa dla stali żerdzi"
                                    placeholder="Wsp. bezpieczeństwa"
                                    name="steelRodYt"
                                    disable={true}
                                    step={0.001}
                                    min={1}

                                    required
                                />
                            </Col>
                        </Row>
                        <Row className={styles.row}>
                            <Col xs={12} sm={6}>
                                <InputField 
                                    onChange={onChange}
                                    onKeyDown={onKeyDown}
                                    onBlur={onBlur}
                                    value={state.steelYoungModulus.value}
                                    isValid={state.steelYoungModulus.isValid}
                                    type="number"
                                    title="Moduł Younga dla stali [GPa]"
                                    placeholder="Moduł Younga stali"
                                    name="steelYoungModulus"
                                    disable={true}
                                    step={0.001}
                                    min={0}

                                    required
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <InputField 
                                    onChange={onChange}
                                    onKeyDown={onKeyDown}
                                    onBlur={onBlur}
                                    value={state.concreteYoungModulus.value}
                                    isValid={state.concreteYoungModulus.isValid}
                                    type="number"
                                    title="Moduł Younga dla trzonu iniekcyjnego [GPa]"
                                    placeholder="Moduł Younga trzonu iniekcyjnego"
                                    name="concreteYoungModulus"
                                    disable={true}
                                    step={0.001}
                                    min={0}

                                    required
                                />
                            </Col>
                        </Row> 
                    </Fragment>
                    }
                </Modal>
        </Fragment>
    )
}

export default React.memo(SaftyFactors, (prev, next) => stringify(prev) === stringify(next))