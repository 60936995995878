import { useCallback, useState } from 'react';
import {
    defaultPNBSoils,
    isDensityCorrect,
    isPlasticityCorrect,
} from '../../../config/ProjectConfig';
import {
    isNumber,
    makeNumberPositive,
    roundNumber,
    stringToNumber,
} from '../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const defaultRow = {
    layerNo: {
        value: 1,
    },
    absHeight: {
        value: '',
    },
    qc: {
        value: '',
    },
    t: {
        value: '',
    },
    name: {
        value: '',
        isValid: null,
    },
    height: {
        value: '',
        isValid: null,
    },
    weight: {
        value: '',
        isValid: null,
    },
    underWaterWeight: {
        value: '',
        isValid: null,
    },
    density: {
        value: '',
        isValid: null,
    },
    plasticity: {
        value: '',
        isValid: null,
    },
    soilName: {
        value: '',
        isValid: null,
    },
};

const SoilTableHelpers = ({
    updatePileProperties,
    updateCalculationSettings,
    newEmbankment,
    embankmentsNumber,

    updateResultsState
}) => {
    const [soilTableState, setSoilTableState] = useState([{ ...defaultRow }]);
    const [stateChange, setStateChange] = useState(false);

    const heightDiff = soilTableState.map(({ height }) => ({ height }));
    const nameDiff =  soilTableState.map(({ name, layerNo }) => ({ name, layerNo }));
    const densPlastSoilDiff = soilTableState.map(({ density, plasticity, soilName }) => ({
            density,
            plasticity,
            soilName,
        }));
    const soilParamsDiff = soilTableState.map(({ t, qc, density, plasticity, soilName }) => ({
            t,
            qc,
            density,
            plasticity,
            soilName,
        }));

    useDeepCompareEffect(() => {
        setSoilTableState((state) =>
            state.map((element, index, arr) => {
                if (index === 0) {
                    return {
                        ...element,
                        absHeight: {
                            value: isNumber(element.height.value)
                                ? Number(element.height.value)
                                : '',
                        },
                    };
                } else {
                    return {
                        ...element,
                        absHeight: {
                            value:
                                isNumber(element.height.value) &&
                                isNumber(arr[index - 1].height.value)
                                    ? element.height.value -
                                          arr[index - 1].height.value >
                                      0
                                        ? Number(
                                              (
                                                  element.height.value -
                                                  arr[index - 1].height.value
                                              ).toFixed(2),
                                          )
                                        : ''
                                    : '',
                        },
                    };
                }
            }),
        );
    }, [
        stateChange,
        heightDiff
    ]);

    useDeepCompareEffect(() => {
        updateCalculationSettings((state) => {
            return {
                ...state,
                layersName: nameDiff.reduce(
                    (acc, element, index, arr) => {
                        const layerExists = Object.keys(state.layersName)
                            .map((ele) => stringToNumber(ele))
                            .includes(element.layerNo.value);

                        if (index === arr.length - 1) {
                            return {
                                ...acc,
                            };
                        } else {
                            if (
                                layerExists &&
                                element.name.value ===
                                    state.layersName[element.layerNo.value].name
                            ) {
                                return {
                                    ...acc,
                                    [element.layerNo.value]: {
                                        ...state['layersName'][
                                            element.layerNo.value
                                        ],
                                        name: element.name.value,
                                        isValid: element.name.isValid,
                                    },
                                };
                            } else {
                                return {
                                    ...acc,
                                    [element.layerNo.value]: {
                                        value: false,
                                        name: element.name.value,
                                        isValid: element.name.isValid,
                                    },
                                };
                            }
                        }
                    },
                    {},
                ),
            };
        });
    }, [nameDiff, updateCalculationSettings]);

    useDeepCompareEffect(() => {
        setStateChange(false);
        setSoilTableState((state) =>
            state.map((element, index, arr) => {
                const soil = defaultPNBSoils.find(
                    (e) => e.name === element.soilName.value,
                );

                return {
                    ...element,
                    qc: {
                        value: soil
                            ? roundNumber(
                                  soil.qc({
                                      id: element.density.value,
                                      il: element.plasticity.value,
                                      embankment:
                                          newEmbankment.value &&
                                          embankmentsNumber.value >=
                                              index + 1 &&
                                          index <= arr.length - 2,
                                  }),
                                  2,
                              )
                            : '',
                    },
                    t: {
                        value: soil
                            ? roundNumber(
                                  soil.t({
                                      id: element.density.value,
                                      il: element.plasticity.value,
                                      embankment:
                                          newEmbankment.value &&
                                          embankmentsNumber.value >=
                                              index + 1 &&
                                          index <= arr.length - 2,
                                  }),
                                  2,
                              )
                            : '',
                    },
                };
            }),
        );
    }, [
        stateChange,
        newEmbankment.value,
        embankmentsNumber.value,
        densPlastSoilDiff,
    ]);

    useDeepCompareEffect(() => {
        setSoilTableState((state) =>
            state.map((element, _, arr) => {
                const isSoftSoil =
                    element.t.value === 0 || element.qc.value === 0;
                const isDensityValid =
                    isNumber(element.density.value) &&
                    isDensityCorrect(element.density.value);
                const isPlasticityValid =
                    isNumber(element.plasticity.value) &&
                    isPlasticityCorrect(element.plasticity.value);

                if (element.layerNo.value === arr.length) {
                    return {
                        ...element,
                        ...(isDensityValid && {
                            density: {
                                ...element['density'],
                                isValid: isSoftSoil ? false : true,
                            },
                        }),
                        ...(isPlasticityValid && {
                            plasticity: {
                                ...element['plasticity'],
                                isValid: isSoftSoil ? false : true,
                            },
                        }),
                    };
                } else {
                    return {
                        ...element,
                        ...(isDensityValid && {
                            density: {
                                ...element['density'],
                                isValid: true,
                            },
                        }),
                        ...(isPlasticityValid && {
                            plasticity: {
                                ...element['plasticity'],
                                isValid: true,
                            },
                        }),
                    };
                }
            }),
        );
    }, [soilParamsDiff]);

    useDeepCompareEffect(() => {
        setSoilTableState((state) =>
            state.map((element, _, arr) => {
                const soil = defaultPNBSoils.find(
                    (ele) => ele.name === element.soilName.value,
                );
                const soilState = soil ? soil.state : '';
                const isDensityValid =
                    isNumber(element.density.value) &&
                    isDensityCorrect(element.density.value);
                const isPlasticityValid =
                    isNumber(element.plasticity.value) &&
                    isPlasticityCorrect(element.plasticity.value);

                return {
                    ...element,

                    ...(isDensityValid &&
                        (soilState === 'cohesive' ||
                            soilState === 'organic') && {
                            soilName: {
                                value: '',
                                isValid: null,
                            },
                        }),
                    ...(isPlasticityValid &&
                        soilState === 'noncohesive' && {
                            soilName: {
                                value: '',
                                isValid: null,
                            },
                        }),
                };
            }),
        );
    }, [densPlastSoilDiff]);

    const onBlurSoilTableState = (e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if (input) {
            const { name } = input;
            const rowNumber = Number(input.getAttribute('data-row'));
            const valid = input.checkValidity();

            if (name === 'height') {
                updatePileProperties((state) => {
                    const maxLayerHeight = Math.max(
                        ...soilTableState.map((ele) => ele.height.value),
                    );
                    return {
                        ...state,
                        pileLength: {
                            ...state['pileLength'],
                            ...(isNumber(state.pileLength.value) &&
                                isNumber(state.pileHeadSpot.value) && {
                                    isValid:
                                        state.pileLength.value +
                                            state.pileHeadSpot.value <=
                                        maxLayerHeight,
                                }),
                        },
                        pileHeadSpot: {
                            ...state['pileHeadSpot'],
                            ...(isNumber(state.pileHeadSpot.value) && {
                                isValid:
                                    state.pileHeadSpot.value <= maxLayerHeight,
                            }),
                        },
                    };
                });
                setSoilTableState((state) =>
                    state.map((element, index, arr) => {
                        return {
                            ...element,
                            height: {
                                ...element['height'],
                                isValid: valid && arr.slice(0,index).every(ele => ele.height.value < element.height.value) && element.height.value > 0
                            }
                        }
                    }),
                );
            } else {
                setSoilTableState((state) =>
                    state.map((element, index, arr) => {
                        const isSoftSoil =
                            (element.t.value === 0 || element.qc.value === 0) &&
                            element.layerNo.value === arr.length;

                        if (rowNumber !== index) {
                            return element;
                        } else {
                            return {
                                ...element,
                                [name]: {
                                    ...element[name],
                                    isValid: valid,
                                },
                                ...(name === 'density' && {
                                    density: {
                                        ...element['density'],
                                        isValid: isSoftSoil
                                            ? false
                                            : element.plasticity.isValid
                                            ? null
                                            : valid,
                                    },
                                }),
                                ...(name === 'plasticity' && {
                                    plasticity: {
                                        ...element['plasticity'],
                                        isValid: isSoftSoil
                                            ? false
                                            : element.density.isValid
                                            ? null
                                            : valid,
                                    },
                                }),
                            };
                        }
                    }),
                );
            }
        }
    };
    
    const onChangeSoilState = useCallback((e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if (input) {
            const rowNumber = stringToNumber(input.getAttribute('data-row'));
            const { name, value, type } = input;
            
            updateResultsState((state) => {
                return {
                    ...state,
                    isResultsActual: false, 
                };
            });

            setSoilTableState((state) =>
                state.map((element, index) => {
                    const soil = defaultPNBSoils.find(
                        (ele) => ele.name === element.soilName.value,
                    );
                    const soilState = soil ? soil.state : '';

                    const currentSoil = defaultPNBSoils.find(
                        (ele) => ele.name === value,
                    );
                    const currentSoilState = currentSoil
                        ? currentSoil.state
                        : '';

                    if (rowNumber !== index) {
                        return element;
                    } else {
                        return {
                            ...element,

                            ...(name === 'density' && {
                                plasticity: {
                                    value: '',
                                    isValid: null,
                                },
                            }),
                            ...(name === 'plasticity' && {
                                density: {
                                    value: '',
                                    isValid: null,
                                },
                            }),
                            ...(name === 'density' &&
                                (soilState === 'cohesive' ||
                                    soilState === 'organic') && {
                                    soilName: {
                                        value: '',
                                        isValid: null,
                                    },
                                }),
                            ...(name === 'plasticity' &&
                                soilState === 'noncohesive' && {
                                    soilName: {
                                        value: '',
                                        isValid: null,
                                    },
                                }),
                            ...(name === 'soilName' &&
                                currentSoilState === 'noncohesive' && {
                                    plasticity: {
                                        value: '',
                                        isValid: null,
                                    },
                                    density: {
                                        value: isNumber(element.plasticity.value)
                                            ? element.plasticity.value
                                            : element.density.value,
                                        isValid: isNumber(element.plasticity.value)
                                            ? isDensityCorrect(
                                                  element.plasticity.value,
                                              )
                                            : element.density.value,
                                    },
                                }),
                            ...(name === 'soilName' &&
                                (currentSoilState === 'cohesive' ||
                                    currentSoilState === 'organic') && {
                                    density: {
                                        value: '',
                                        isValid: null,
                                    },
                                    plasticity: {
                                        value: isNumber(element.density.value)
                                            ? element.density.value
                                            : element.plasticity.value,
                                        isValid: isNumber(element.density.value)
                                            ? isPlasticityCorrect(
                                                  element.density.value,
                                              )
                                            : element.plasticity.isValid,
                                    },
                                }),
                            [name]: {
                                value:
                                    type === 'number' && value.length > 0
                                        ? name === 'plasticity'
                                            ? Number(value)
                                            : makeNumberPositive(Number(value))
                                        : value,
                                isValid: true,
                            },
                        };
                    }
                }),
            );
        }
    }, []);

    const addRowToSoilTable = () => {
        updateResultsState((state) => {
            return { 
                ...state,
                isResultsActual: false,
            };
        });
        setSoilTableState((state) => {
            return [
                ...state,
                {
                    ...defaultRow,
                    layerNo: {
                        value: state.length + 1,
                    },
                },
            ];
        });        
    }

    const removeRowFromSoilTable = (i) => {
        updateResultsState((state) => {
            return { 
                ...state,
                isResultsActual: false,
            };
        });
        setSoilTableState((state) => {
            return [
                ...state
                    .filter((_, index) => index !== i)
                    .map((ele, idx) => ({
                        ...ele,
                        layerNo: {
                            value: idx + 1,
                        },
                    })),
            ];
        });        
    }


    const onLoadSoilProfile = (soilProfile = []) => {
        const loadedProfile = soilProfile.reduce((acc, ele, idx) => {
            const entries = Object.fromEntries(
                Object.entries(ele).filter((ele) =>
                    Object.keys(defaultRow).includes(ele[0]),
                ),
            );

            return [
                ...acc,
                {
                    ...defaultRow,
                    ...entries,
                    layerNo: {
                        value: idx + 1,
                    },
                },
            ];
        }, []);
        setSoilTableState(loadedProfile);
        updateResultsState((state) => {
            return { 
                ...state,
                isResultsActual: false,
            };
        });
    };
    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    }, []);

    const loadProject = (soilsArr) => {
        setSoilTableState(() => {
            return soilsArr.map((state) => ({ ...defaultRow, ...state }));
        });
        setStateChange(true);
    };

    const soilTable = {
        onChangeState: setStateChange,
        onChange: onChangeSoilState,
        onBlur: onBlurSoilTableState,
        onKeyDown: onKeyDown,
        addRow: addRowToSoilTable,
        removeRow: removeRowFromSoilTable,
        state: soilTableState,
        updateState: setSoilTableState,
        loadProject: loadProject,
        onLoad: onLoadSoilProfile,
        initState: () => setSoilTableState([{ ...defaultRow }]),
    };

    return {
        soilTable,
    };
};

export default SoilTableHelpers;
