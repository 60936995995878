import { Fragment, useState } from 'react';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import {
    Modal,
    Table,
    Button,
    SelectField,
    SectionTitle,
    Checkbox1,
} from '../../../../../components';
import { subscriptionTypes } from '../../../../../config/ProjectConfig';
import styles from './index.module.css';
import InvoiceForm from '../../../../../components/InvoiceForm';

const RenewSubscription = ({
    users = [],
    open, 
    onHide,
    onChange,
    onSubmit, 

    onOpen,
    usersCost,
    invoiceType,
    invoiceState,
    onChangeInvoiceType,
    onChangeInvoice,
    onBlurInvoice,
}) => {
    const [openInvoice, setOpenInvoice] = useState(false);

    const onOpenInvoice = () => setOpenInvoice(true);
    const onHideInvoice = () => setOpenInvoice(false)
    return (
        <Fragment>
            <Modal
                open={open}
                css={styles.modalOverflow}
                cssDialog={styles.modal}
                onHide={onHide}
                hideButton={false}
                title="Przedłużenie subskrypcji">
                <div className={styles.spaceBetween}>
                    <SectionTitle title="Wybierz typ subskrypcji dla użytkowników" />
                </div>
                {users.length > 0 ? (
                    users.map((user, userIndex) => {
                        return (
                            <Fragment key={userIndex}>
                                <Table className={styles.table}>
                                    <thead>
                                        <tr>
                                            <th>Nr</th>
                                            <th>Imię</th>
                                            <th>Nazwisko</th>
                                            <th>Email</th>
                                            <th>Typ subskrypcji</th>
                                            <th>Darmowe konto</th>
                                            <th width={130}>Koniec</th>
                                            <th width={100}>Cena</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <Fragment>
                                            {user.map((element, index, arr) => {
                                                return (
                                                    <Fragment key={index}>
                                                        <tr>
                                                            <td width={30}>
                                                                {index + 1}
                                                            </td>
                                                            <td>
                                                                {element.firstName}
                                                            </td>
                                                            <td>
                                                                {element.lastName}
                                                            </td>
                                                            <td>{element.email}</td>
                                                            <td width={220}>
                                                                <SelectField
                                                                    name="type"
                                                                    value={subscriptionTypes[element.subscription.plan]}
                                                                    defaultOption="Wybierz plan subskrypcyjny"
                                                                    options={[
                                                                        'Subskrypcja miesięczna',
                                                                        'Subskrypcja roczna',
                                                                    ]}
                                                                    onChange={(e) => onChange(e, element.id)}
                                                                    required
                                                                />
                                                            </td>
                                                            <td>
                                                                <Checkbox1
                                                                    name="freeAccount"
                                                                    value={element.subscription.freeAccount}
                                                                    onChange={(e) => onChange(e, element.id)}
                                                                /> 
                                                            </td>
                                                            <td>
                                                                {moment(
                                                                    moment(
                                                                        element
                                                                            .subscription
                                                                            .endDate,
                                                                    ).diff(
                                                                        moment(),
                                                                        'seconds',
                                                                    ) > 0
                                                                        ? element
                                                                            .subscription
                                                                            .endDate
                                                                        : {},
                                                                )
                                                                    .add(
                                                                        1,
                                                                        element
                                                                            .subscription
                                                                            .plan ===
                                                                            2
                                                                            ? 'years'
                                                                            : 'months',
                                                                    )
                                                                    .local('pl')
                                                                    .format(
                                                                        'DD.MM.YYYY, HH:mm',
                                                                    )}
                                                            </td>
                                                            <td width={150}>{element.subscription.price}</td>
                                                        </tr>
                                                        <Fragment>
                                                            {Array.isArray(element.subusers) &&element.subusers.length > 0 &&
                                                                element.subusers.map(
                                                                    (ele, idx) => {
                                                                        return (
                                                                            <tr key={idx}>
                                                                                <td width={30}>{`${index + 1}.${idx + 1}`}</td>
                                                                                <td>{ele.firstName}</td>
                                                                                <td>{ele.lastName}</td>
                                                                                <td>{ele.email}</td>
                                                                                <td width={220}>
                                                                                    <SelectField
                                                                                        name="type"
                                                                                        value={subscriptionTypes[ele.subscription.plan]}
                                                                                        defaultOption="Wybierz plan subskrypcyjny"
                                                                                        options={[
                                                                                            'Subskrypcja miesięczna',
                                                                                            'Subskrypcja roczna',
                                                                                        ]}
                                                                                        onChange={(e) => onChange(e, ele.id)
                                                                                        }
                                                                                        required
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Checkbox1
                                                                                        name="freeAccount"
                                                                                        value={ele.subscription.freeAccount}
                                                                                        onChange={(e) => onChange(e, ele.id)}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    {moment(
                                                                                        moment(
                                                                                            ele.subscription.endDate,
                                                                                        ).diff(moment(),'seconds',) > 0
                                                                                            ? ele.subscription.endDate
                                                                                            : {},
                                                                                    )
                                                                                        .add(
                                                                                            1, ele.subscription.plan === 2
                                                                                                ? 'years'
                                                                                                : 'months',
                                                                                        )
                                                                                        .local('pl')
                                                                                        .format('DD.MM.YYYY, HH:mm')}
                                                                                </td>
                                                                                <td width={150}>{ele.subscription.price}</td>
                                                                            </tr>
                                                                        );
                                                                    },
                                                                )}
                                                        </Fragment>
                                                    </Fragment>
                                                );
                                            })}
                                        </Fragment>
                                    </tbody>
                                </Table>
                                <Row>
                                    <Col xs={12}>
                                        <h2 className={styles.cost}>
                                            Łączny koszt:{' '}
                                            {user.reduce(
                                                (acc, ele) => acc + ele.totalPrice,
                                                0,
                                            )}
                                            zł
                                        </h2>
                                    </Col>
                                </Row>
                            </Fragment>
                        );
                    })
                ) : (
                    <p className={styles.noUsersCenter}>
                        Nie wybrano żadnych użytkowników
                    </p>
                )}
                {users.length > 0 && (
                    <Row>
                        <Col xs={12}>
                            <div className={styles.centerButton}>
                                <Button
                                    icon={faShoppingCart}
                                    variant={'info'}
                                    onClick={usersCost > 0 ? 
                                        () => {
                                            onOpenInvoice(); 
                                            onHide();
                                        }
                                        :
                                        onSubmit
                                    }
                                    title={
                                        users.length === 1
                                            ? 'Przedłuż subskrypcję'
                                            : 'Przedłuż subskrypcje'
                                    }
                                />
                            </div>
                        </Col>
                    </Row>
                )}
            </Modal>
            <InvoiceForm
                isSaveData={false}
                openInvoice={openInvoice}
                invoiceType={invoiceType}
                invoiceState={invoiceState}
                onChangeInvoiceType={onChangeInvoiceType}
                onChangeInvoice={onChangeInvoice}
                onBlurInvoice={onBlurInvoice}
                onHideInvoice={() => {
                    onOpen(state => {
                        return {
                            ...state,
                            renewSubscription: true                            
                        }
                    });
                    onHideInvoice();
                }}
                onSubmit={usersCost > 0 ? onSubmit : onHideInvoice}
            />
        </Fragment>

    );
};

export default RenewSubscription;
