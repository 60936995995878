const kxInterpolationLevel = (state) => {
    switch (state) {
        case 'Niespoisty':
            return 5
        case 'Mało spoisty':
            return 4
        case 'Średnio spoisty':
            return 3
        case 'Zwięzło spoisty':
            return 2
        case 'Organiczny':
        case 'Bardzo spoisty':
            return 1
        default:
            return ''
    }
}

export default kxInterpolationLevel;