import { faPlus, faShoppingCart, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Modal, Table, TableInput, Button, SelectField, SectionTitle, Checkbox1 } from '../../../../../components';
import styles from './index.module.css';
import InvoiceForm from '../../../../../components/InvoiceForm';

const NewUserModal = ({
    open,
    onHide,
    state=[],
    usersCost,
    onChange,
    onChangeSubuser, 
    onBlur,
    onBlurSubuser,
    onSubmit,
    onKeyDown,
    addRow,
    addSubuserRow,
    removeRow,
    removeSubuserRow,

    onOpen,
    invoiceType,
    invoiceState,
    onChangeInvoiceType,
    onChangeInvoice,
    onBlurInvoice,
}) => {
    const [openInvoice, setOpenInvoice] = useState(false);

    const onOpenInvoice = () => setOpenInvoice(true);
    const onHideInvoice = () => setOpenInvoice(false)

    const isUserActive = state.some(ele => ele.isActiveSubs);

    return (
        <Fragment>
            <Modal open={open} css={styles.modalOverflow} cssDialog={styles.modal} onHide={onHide} hideButton={false} title="Dodaj nowego użytkownika">
                <SectionTitle title="Podstawowe dane nowych użytkowników" />
                {
                    state.length > 0 
                    &&
                    <Fragment>
                    {
                        state.map((element,index) => {
                            return (
                                <Fragment key={index}>
                                    <Table className={styles.table}>
                                        <thead>
                                            <tr>
                                                <th>Nr</th>
                                                <th>Imię</th>
                                                <th>Nazwisko</th>
                                                <th>Email</th>
                                                <th>Typ subskrypcji</th>
                                                <th>Darmowe konto</th>
                                                <th>Cena</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <Fragment>
                                                <tr>
                                                    <td width={30}>{index + 1}</td>
                                                    {
                                                        element.isActiveSubs
                                                        ?
                                                        <Fragment>
                                                            <td width={200}>{element.firstName.value}</td>
                                                            <td width={200}>{element.lastName.value} </td>
                                                            <td width={250}>{element.email.value}</td>
                                                            <td width={250}>{element.type.value || '[-]'} </td>
                                                            <td width={150}>{element.freeAccount.value ? 'Tak' : 'Nie'}</td>
                                                            <td width={150}>{'[-]'}</td>
                                                            <td width={100}>
                                                                <Button css={styles.deleteButton} variant="info" title="" icon={faPlus} altTitle={"Dodaj subkonto"} onClick={() => addSubuserRow(index)}/>
                                                            </td>
                                                            <td width={100}>{'[-]'}</td>
                                                        </Fragment>
                                                        :
                                                        <Fragment>
                                                            <td width={200}>
                                                                <TableInput
                                                                    name="firstName"
                                                                    placeholder="Imię"
                                                                    data-row={index}
                                                                    onBlur={onBlur}
                                                                    onChange={onChange}
                                                                    onKeyDown={onKeyDown}
                                                                    value={element.firstName.value}
                                                                    isValid={element.firstName.isValid}

                                                                    required
                                                                />
                                                            </td>
                                                            <td width={200}>
                                                                <TableInput
                                                                    name="lastName"
                                                                    placeholder="Nazwisko"
                                                                    data-row={index}
                                                                    onBlur={onBlur}
                                                                    onChange={onChange}
                                                                    onKeyDown={onKeyDown}
                                                                    value={element.lastName.value}
                                                                    isValid={element.lastName.isValid}

                                                                    required
                                                                />
                                                            </td>
                                                            <td width={250}>
                                                                <TableInput
                                                                    name="email"
                                                                    placeholder="Adres email"
                                                                    data-row={index}
                                                                    onBlur={onBlur}
                                                                    onChange={onChange}
                                                                    onKeyDown={onKeyDown}
                                                                    value={element.email.value}
                                                                    isValid={element.email.isValid}

                                                                    required
                                                                />
                                                            </td>
                                                            <td width={250}>
                                                                <SelectField
                                                                    name="type" 
                                                                    value={element.type.value}
                                                                    isValid={element.type.isValid}
                                                                    defaultOption="[-]" 
                                                                    options={['Subskrypcja miesięczna', 'Subskrypcja roczna']} 
                                                                    data-row={index}
                                                                    onChange={onChange} 
                                                                    onBlur={onBlur}

                                                                    required
                                                                />
                                                            </td>
                                                            <td width={180}>
                                                                <Checkbox1
                                                                    data-row={index}
                                                                    name="freeAccount"
                                                                    value={element.freeAccount.value}
                                                                    onChange={onChange}
                                                                    css={styles.checkbox}
                                                                />
                                                            </td>
                                                            <td width={100}>{element.price.value}</td>
                                                            <td width={100}>
                                                                <Button css={styles.deleteButton} variant="info" title="" icon={faPlus} altTitle={"Dodaj subkonto"} onClick={() => addSubuserRow(index)}/>
                                                            </td>
                                                            <td width={100}>
                                                                <Button css={styles.deleteButton} variant="danger" title="" icon={faTrashAlt} altTitle={"Usuń użytkownika"} onClick={() => removeRow(index)}/>
                                                            </td>                                                    
                                                        </Fragment>
                                                    }
                                                </tr>
                                                {
                                                    Array.isArray(element.subUsers) && element.subUsers.length > 0 && 
                                                    element.subUsers.map((ele, idx) => {
                                                        return(
                                                            <tr key={idx}>
                                                                <td width={30}>{`${index + 1}.${idx + 1}`}</td>
                                                                {
                                                                    ele.isActiveSubs
                                                                    ?
                                                                    <Fragment>
                                                                        <td width={200}>{ele.firstName.value}</td>
                                                                        <td width={200}>{ele.lastName.value}</td>
                                                                        <td width={250}>{ele.email.value}</td>
                                                                        <td width={250}>{ele.type.value}</td>
                                                                        <td width={150}>{ele.freeAccount.value ? 'Tak' : 'Nie'}</td>
                                                                        <td width={150}>{'[-]'}</td>
                                                                        <td width={100}>{'[-]'}</td>
                                                                        <td width={100}>{'[-]'}</td>
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <td width={200}>
                                                                            <TableInput
                                                                                name="firstName"
                                                                                placeholder="Imię"
                                                                                data-parent={index}
                                                                                data-row={idx}
                                                                                onBlur={onBlurSubuser}
                                                                                onChange={onChangeSubuser}
                                                                                onKeyDown={onKeyDown}
                                                                                value={ele.firstName.value}
                                                                                isValid={ele.firstName.isValid}
                            
                                                                                required
                                                                            />
                                                                        </td>
                                                                        <td width={200}>
                                                                            <TableInput
                                                                                name="lastName"
                                                                                placeholder="Nazwisko"
                                                                                data-parent={index}
                                                                                data-row={idx}
                                                                                onBlur={onBlurSubuser}
                                                                                onChange={onChangeSubuser}
                                                                                onKeyDown={onKeyDown}
                                                                                value={ele.lastName.value}
                                                                                isValid={ele.lastName.isValid}
                            
                                                                                required
                                                                            />
                                                                        </td>
                                                                        <td width={250}>
                                                                            <TableInput
                                                                                name="email"
                                                                                placeholder="Adres email"
                                                                                data-parent={index}
                                                                                data-row={idx}
                                                                                onBlur={onBlurSubuser}
                                                                                onChange={onChangeSubuser}
                                                                                onKeyDown={onKeyDown}
                                                                                value={ele.email.value}
                                                                                isValid={ele.email.isValid}
                            
                                                                                required
                                                                            />
                                                                        </td>
                                                                        <td width={250}>
                                                                            <SelectField
                                                                                name="type" 
                                                                                value={ele.type.value}
                                                                                isValid={ele.type.isValid}
                                                                                defaultOption="[-]" 
                                                                                options={['Subskrypcja miesięczna', 'Subskrypcja roczna']} 
                                                                                data-row={idx}
                                                                                data-parent={index}
                                                                                onChange={onChangeSubuser}
                                                                                onBlur={onBlurSubuser}
                            
                                                                                required
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Checkbox1
                                                                                data-parent={index}
                                                                                data-row={idx}
                                                                                name="freeAccount"
                                                                                value={ele.freeAccount.value}
                                                                                css={styles.checkbox}
                                                                                onChange={onChangeSubuser}
                                                                            />
                                                                        </td>
                                                                        <td width={150}>{ele.price.value}</td>
                                                                        <td width={100}>{'[-]'}</td>
                                                                        <td width={100}>
                                                                            <Button css={styles.deleteButton} variant="danger" title="" icon={faTrashAlt} altTitle={"Usuń użytkownika"} onClick={() => removeSubuserRow(index, idx)}/>
                                                                        </td>
                                                                    </Fragment>
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </Fragment>
                                        </tbody>
                                    </Table> 
                                    <Row>
                                        <Col xs={12}>
                                            <h2 className={styles.cost}>Łączny koszt: {element.totalPrice.value} zł</h2>
                                        </Col>
                                    </Row>
                                </Fragment>
                            )
                        })
                    }
                    </Fragment>  
                }
                {
                    !isUserActive &&
                    <div className={styles.actionsWrapper}>
                        <div className={styles.actions}>
                            <Button css="default-button-style" onClick={addRow} title={"Dodaj użytkownika"} icon={faPlus}/> 
                        </div>
                    </div>                
                }

                <Row>
                    <Col xs={12}>
                        <div className={styles.centerButton}>
                            <Button 
                                icon={faShoppingCart} 
                                variant={'info'} 
                                onClick={usersCost > 0 ? 
                                    () => {
                                        onOpenInvoice(); 
                                        onHide();
                                    }
                                    :
                                    onSubmit
                                }
                                title={state.length === 1 ? 'Zakup licencję' : 'Zakup licencje'}
                            /> 
                        </div>
                    </Col>
                </Row>
            </Modal>
            <InvoiceForm
                isSaveData={false}
                openInvoice={openInvoice}
                invoiceType={invoiceType}
                invoiceState={invoiceState}
                onChangeInvoiceType={onChangeInvoiceType}
                onChangeInvoice={onChangeInvoice}
                onBlurInvoice={onBlurInvoice}
                onHideInvoice={() => {
                    onOpen(state => {
                        return {
                            ...state,
                            addNewUser: true                            
                        }
                    });
                    onHideInvoice();
                }}
                onSubmit={usersCost > 0 ? onSubmit : onHideInvoice}
            />
        </Fragment>

    )
}

export default NewUserModal