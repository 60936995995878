import isNumber from "../isNumber"

const splitSoilsByWaterLevel = (soils, waterLevel) => { 
    if(Array.isArray(soils)){
        return soils.reduce((acc, ele) => {
            if(isNumber(waterLevel.value) && isNumber(ele.height.value) && isNumber(ele.absHeight.value)){
                if((ele.height.value - waterLevel.value > 0) && (ele.height.value - waterLevel.value < ele.absHeight.value)) {
                    return [...acc, 
                        { 
                            ...ele,
                            height: { value: waterLevel.value, isValid: true },
                            totalLayerHeight: { value: ele.absHeight.value },
                            absHeight: { value: Number((ele.absHeight.value - (ele.height.value - waterLevel.value)).toFixed(2)) }
                        }, 
                        { 
                            ...ele,
                            totalLayerHeight: { value: ele.absHeight.value },
                            absHeight: { value: Number((ele.height.value - waterLevel.value).toFixed(2)) }
                        }
                    ]
                } else {
                    return [
                        ...acc, 
                        {
                            ...ele,
                            totalLayerHeight: { value: ele.absHeight.value },
                        }
                    ]
                }
            } else {
                return [
                    ...acc, 
                    {
                        ...ele,
                        totalLayerHeight: { value: ele.absHeight.value },
                    }
                ]
            }
        },[])
    } else {
        return soils
    }
}

export default splitSoilsByWaterLevel