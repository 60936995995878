import { useCallback, useState } from 'react';
import { makeNumberPositive } from '../../../utils';

const defaultState = {
    yt: {
        value: 1.4,
        isValid: true,
    },
    ygq: {
        value: 1.42,
        isValid: true,
    },
    yst: {
        value: 1.5,
        isValid: true,
    },
    qccuInterpolation: {
        value: 0.5,
        isValid: true,
    },
    softSoilCount: {
        value: true,
        isValid: true,
    },
    hardSoilCount: {
        value: false,
        isValid: true,
    },
};

const SaftyFactorsHelpers = ({
    updateResultsState
}) => {
    const [saftyFactorsState, setSaftyFactorsState] = useState({
        ...defaultState,
    });

    const onBlurSaftyFactorsState = useCallback((e) => {
        const { name, value } = e.currentTarget;
        const valid = e.currentTarget.checkValidity();
        const greaterThanEqualOneProps = ['yt', 'yst', 'ygq'];

        setSaftyFactorsState((state) => ({
            ...state,
            [name]: {
                ...state[name],
                isValid: greaterThanEqualOneProps.includes(name)
                ? 
                value >= 1 && valid
                :
                valid,
            },
        }));
    }, []);

    const onChangeSaftyFactorsState = useCallback((e) => {
        const input = e.currentTarget.closest('input') || e.currentTarget.closest('select');
        const { value, name, type, checked } = input;

        updateResultsState((state) => {
            return { 
                ...state,
                isResultsActual: false,
            };
        });

        if (type === 'checkbox') {
            setSaftyFactorsState((state) => ({
                ...state,
                [name]: {
                    isValid: true,
                    value: !!checked,
                },
            }));
        } else {
            setSaftyFactorsState((state) => ({
                ...state,
                [name]: {
                    value: type === 'number' && value.length > 0 ? makeNumberPositive(Number(value)) : value,
                    isValid: true,
                },
            }));
        }
    }, []);
    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    }, []);

    const loadProject = (saftyFactorsObj = {}) => {
        setSaftyFactorsState((state) => {
            return {
                ...defaultState,
                ...saftyFactorsObj,
            };
        });
    };

    const saftyFactors = {
        onChange: onChangeSaftyFactorsState,
        onBlur: onBlurSaftyFactorsState,
        onKeyDown: onKeyDown,
        updateState: setSaftyFactorsState,
        loadProject: loadProject,
        initState: () =>
            setSaftyFactorsState({
                ...defaultState,
            }),
        state: saftyFactorsState,
    };

    return {
        saftyFactors,
    };
};

export default SaftyFactorsHelpers;