import React, { Fragment } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
    InputField,
    SelectField,
    SectionTitle,
    Checkbox,
    Table,
    TableInput,
    Button,
    RadioInput1,
} from '../../../../components';
import styles from './index.module.css';
import BucklingImg from "../../../../assets/wyboczenie.jpg";
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const CalculationInput = ({
    removeRow,
    addRow,
    onChange,
    onBlur,
    onChangeInetrnalForces,
    onKeyDown,
    concreteClasses,
    steelClasses,
    currentSteelClass,
    currentExposureClass,
    state = {},
    pileShape,
    exposureClasses,
    rebarDiameters,
    stirrupTypes,
    bucklingRatios,
    c0Values,
    cValues,
    memberTypes
}) => {

    return (
        <Fragment>
            <SectionTitle title="Parametry geometryczne przekroju" />
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <SelectField
                        name="memberType"
                        title="Rodzaj elementu"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={state.memberType.value}
                        isValid={state.memberType.isValid}
                        defaultValue={state.memberType.value}
                        infoCss={styles.infoClass}
                        options={memberTypes}
                        info={
                            <p>
                                Dla belki i płyty (elementy zginane) prawidłowe zbrojenie powinno być dobierane w 1 lub 2 fazie przekroju żelbetowego czyli x &le; x<sub>lim</sub>, zbrojenie rozciągane osiąga granicę plastyczności.<br></br> Dla słupów (elementy z dominującym ściskaniem) zbrojenie może być dobierane również w 3 i 4 fazie pracy przekroju, czyli x &gt; x<sub>lim</sub>.<br></br><br></br>
                                x - wysokość strefy ściskanej<br></br>
                                x<sub>lim</sub> - graniczna wysokość strefy ściskanej
                            </p>
                        }
                        required
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <SelectField
                        name="pileShape"
                        title="Kształt przekroju pala"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={state.pileShape.value}
                        isValid={state.pileShape.isValid}
                        defaultValue={state.pileShape.value}
                        
                        options={pileShape}
                        required
                    />
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <SelectField
                        name="exposureClass"
                        title="Klasa ekspozycji"
                        onChange={onChange}
                        onBlur={onBlur}
                        infoCss={styles.info}
                        info={
                            currentExposureClass
                            ?
                            <Fragment>
                                <div className={styles.info}>
                                    <p className={styles.underline}>{currentExposureClass.title}</p>
                                    <p>Klasa ekspozycji: <b>{currentExposureClass.name}</b></p>
                                    <p>Środowisko: <b>{currentExposureClass.env}</b></p>
                                    <p>Opis zastosowania: <b>{currentExposureClass.description}</b></p>
                                    <p>Klasa betonu: <b>{currentExposureClass.class}</b></p>
                                    {
                                        currentExposureClass.cem && 
                                        <p>Min. zawartość cementu: <b>{currentExposureClass.cem} kg/m<sup>3</sup></b></p>
                                    }
                                    {
                                        currentExposureClass.wc && 
                                        <p>Max. wskaźnik w/c: <b>{currentExposureClass.wc}</b></p>
                                    }
                                    {
                                        currentExposureClass.c && 
                                        <Fragment>
                                            <p>Min. otulina betonu: <b>{currentExposureClass.c}mm</b></p>
                                            <p>* Podana wartość otuliny betonu jest wartością zalecaną dla betonu zwykłego oraz okresu eskploatacji konstrukcji 50lat.</p>
                                        </Fragment>
                                        
                                    }
                                </div>
                            </Fragment>
                            :
                            ''
                        }
                        value={state.exposureClass.value}
                        isValid={state.exposureClass.isValid}
                        defaultOption={'Wybierz klasę ekspozycji'}
                        defaultValue=""
                        disabledDefault={false}
                        options={exposureClasses}
                    />
                </Col>
            </Row>
                {
                state.pileShape.value !== 'Kołowy'
                ?
                <Fragment>
                    <Row>
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField
                                name="diameterPile"
                                type="number"
                                value={state.diameterPile.value}
                                isValid={state.diameterPile.isValid}
                                title='Wysokość przekroju - h [mm]'
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                placeholder="Wysokość przekroju"
                                required
                            />
                        </Col>
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField
                                name="width"
                                type="number"
                                value={state.width.value}
                                isValid={state.width.isValid}
                                title='Szerokość przekroju - b [mm]'
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                placeholder="Szerokość przekroju"
                                disable={state.memberType.value === 'Płyta' ? true : false}
                                required
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField
                                name="coverBottom"
                                type="number"
                                value={state.coverBottom.value}
                                isValid={state.coverBottom.isValid}
                                title={<Fragment>Otulina zbrojenia A<sub>s1</sub> - c<sub>1</sub> [mm]</Fragment>}
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                placeholder="Otulenie dolne"
                                required
                            />
                        </Col>                        
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField
                                name="coverUp"
                                type="number"
                                value={state.coverUp.value}
                                isValid={state.coverUp.isValid}
                                title={<Fragment>Otulina zbrojenia A<sub>s2</sub> - c<sub>2</sub> [mm]</Fragment>}
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                placeholder="Otulenie górne"
                                required
                            />
                        </Col>
                    </Row>
                </Fragment>
                :
                <Fragment>
                    <Row>
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField
                                name="diameterPile"
                                type="number"
                                value={state.diameterPile.value}
                                isValid={state.diameterPile.isValid}
                                title='Średnica przekroju - d [mm]'
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                placeholder="Średnica przekroju"
                                required
                            />
                        </Col>
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField
                                name="cover"
                                type="number"
                                value={state.cover.value}
                                isValid={state.cover.isValid}
                                title="Otulina zbrojenia - c [mm]"
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                placeholder="Gr. otuliny zbrojenia"
                                required
                            >
                            </InputField>
                        </Col>
                    </Row>
                </Fragment>
                }
            <SectionTitle title="Parametry geometryczne zbrojenia" />
            {
                state.pileShape.value === 'Kołowy' &&
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <SelectField
                        title={<Fragment>Typ zbrojenia poprzecznego</Fragment>}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={state.stirrupType.value}
                        isValid={state.stirrupType.isValid}
                        defaultOption={''}
                        options={stirrupTypes}
                        name="stirrupType"
                        required
                    />
                </Col>
            }
            <Row>
                {
                state.pileShape.value === 'Kołowy'
                ?
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <SelectField
                        title={<Fragment>Średnica zbrojenia głównego A<sub>s</sub> [mm]</Fragment>}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={state.rebar.value}
                        isValid={state.rebar.isValid}
                        defaultOption="Wybierz średnicę As"
                        options={rebarDiameters}
                        name="rebar"
                        type="number"
                        required
                    />
                </Col>
                :
                <Fragment>
                    <Col xs={12} sm={state.memberType.value === 'Płyta' ? 6 : 4} className={styles.columnSpace}>
                        <SelectField
                            title={<Fragment>Średnica zbrojenia dolnego A<sub>s1</sub> [mm]</Fragment>}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={state.rebarAs1.value}
                            isValid={state.rebarAs1.isValid}
                            defaultOption="Wybierz średnicę As1"
                            options={rebarDiameters}
                            name="rebarAs1"
                            type="number"
                            required
                        />
                    </Col>
                    <Col xs={12} sm={state.memberType.value === 'Płyta' ? 6 : 4} className={styles.columnSpace}>
                        <SelectField
                            title={<Fragment>Średnica zbrojenia górnego A<sub>s2</sub> [mm]</Fragment>}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={state.rebarAs2.value}
                            isValid={state.rebarAs2.isValid}
                            defaultOption="Wybierz średnicę As2"
                            options={rebarDiameters}
                            name="rebarAs2"
                            type="number"
                            required
                        />
                    </Col>
                </Fragment>
                }
                {
                    state.memberType.value !== 'Płyta' &&
                    <Col xs={12} sm={state.pileShape.value === 'Kołowy' ? 6 : 4} className={styles.columnSpace}>
                        <SelectField
                            title={`Średnica  ${state.pileShape.value === 'Kołowy' ? state.stirrupType.value === 'Spirala' ? "spirali" : "strzemion" :  "strzemion"} [mm]`}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={state.spiral.value}
                            isValid={state.spiral.isValid}
                            defaultOption={`Wybierz średnicę ${state.pileShape.value === 'Kołowy' ? 'spirali' : 'strzemion'}`}
                            options={rebarDiameters}
                            name="spiral"
                            required
                        />
                    </Col>                    
                }
            </Row>
            <SectionTitle title="Parametry materiałowe przekroju i zbrojenia" />
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <SelectField
                        title="Klasa betonu"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={state.concreteClass.value}
                        defaultOption="Wybierz klasę betonu"
                        isValid={state.concreteClass.isValid}
                        options={concreteClasses}
                        name="concreteClass"
                        required
                    />
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        title={<Fragment>f<sub>ck</sub> [MPa]</Fragment>}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        onBlur={onBlur}
                        name="fck"
                        type="number"
                        value={state.fck.value}
                        isValid={state.fck.isValid}
                        disable={true}
                        required
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <SelectField
                        title="Klasa stali zbrojenia głównego"
                        onBlur={onBlur}
                        info={
                            currentSteelClass
                            ?
                            <Fragment>
                                <div className={styles.info}>
                                    <p className={styles.underline}>Właściwości wybranej stali</p>
                                    <p>Nazwa gatunku stali: <b>{currentSteelClass.name}</b></p>
                                    <p>Granica plastyczności f<sub>yk</sub>: <b>{currentSteelClass.fyk} MPa</b></p>
                                    <p>Granica na rozciąganie f<sub>t</sub>: <b>{currentSteelClass.ft} MPa</b></p>
                                    <p>Klasa ciągliwości: <b>{currentSteelClass.class}</b></p>
                                    <p>Nominalne średnice: <b>{`${currentSteelClass.minD}-${currentSteelClass.maxD}mm`}</b></p>
                                    <p>* w specyfikacji technicznej nie powinno się podawać nazwy gatunku stali a jedynie jej parametry potrzebne do prawidłowego zaprojektowania/wykonania konstrukcji.</p>
                                </div>
                            </Fragment>
                            :
                            ''
                        }
                        onChange={onChange}
                        value={state.steelClass.value}
                        defaultOption={'Brak nazwy'}
                        defaultValue=""
                        disabledDefault={false}
                        isValid={state.steelClass.isValid}
                        options={[...steelClasses]}
                        name="steelClass"
                    />
                </Col>
                <Col xs={12} sm={3} className={styles.columnSpace}>
                    <InputField
                        title={<Fragment>f<sub>yk</sub> [MPa]</Fragment>}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        onBlur={onBlur}
                        name="fyk"
                        type="number"
                        value={state.fyk.value}
                        isValid={state.fyk.isValid}
                        disable={true}
                        required
                    >
                        Granica plastyczności stali
                    </InputField>
                </Col>
                <Col xs={12} sm={3} className={styles.columnSpace}>
                    <InputField
                        title={<Fragment>f<sub>t</sub> [MPa]</Fragment>}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        onBlur={onBlur}
                        name="ft"
                        type="number"
                        value={state.ft.value}
                        isValid={state.ft.isValid}
                        disable={true}
                        required
                    >
                        Wytrzymałość na rozciąganie stali
                    </InputField>
                </Col>
            </Row>
            {
                state.memberType.value !== 'Płyta' &&
                <Row>
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <SelectField
                            title={state.pileShape.value === 'Kołowy' ? state.stirrupType.value === 'Spirala' ? "Klasa stali spirali" : "Klasa stali strzemion" :  "Klasa stali strzemion"} 
                            onBlur={onBlur}
                            onChange={onChange}
                            value={state.steelClassSpiral.value}
                            defaultOption={'Brak nazwy'}
                            defaultValue=""
                            disabledDefault={false}
                            isValid={state.steelClassSpiral.isValid}
                            options={[...steelClasses]}
                            name="steelClassSpiral"
                        />
                    </Col>
                    <Col xs={12} sm={state.pileShape.value === 'Kołowy' ? 6 : 3} className={styles.columnSpace}>
                        <InputField
                            title={<Fragment>f<sub>ywk</sub> [MPa]</Fragment>}
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            name="fywk"
                            type="number"
                            value={state.fywk.value}
                            isValid={state.fywk.isValid}
                            disable={true}
                            required
                        >
                            Granica plastyczności stali zbrojenia na ścinanie
                        </InputField>
                    </Col>
                    {
                    state.pileShape.value === 'Kołowy'
                    ?
                        <Fragment></Fragment>
                    :
                        <Col xs={12} sm={3} className={styles.columnSpace}>
                            <SelectField
                                title="Strzemiona n-cięte"
                                onBlur={onBlur}
                                onChange={onChange}
                                value={state.nCut.value}
                                defaultOption={'Brak nazwy'}
                                defaultValue=""
                                disabledDefault={false}
                                isValid={state.nCut.isValid}
                                options={[2,4,6,8]}
                                name="nCut"
                            />
                        </Col>
                    }
                </Row>                
            }

            {
                state.pileShape.value !== 'Kołowy' && state.memberType.value !== 'Słup' &&
                <Fragment>
                    <SectionTitle title="Szerokość rozwarcia rys dla elementów zginanych" >
                        Zbrojenie ze względu na dopuszczalną rozwartość rys wyznaczane jest dla elementów zginanch <b>bez udziału siły podłużnej (N<sub>z</sub> = 0)</b>
                    </SectionTitle>
                    <Row>
                        <Col xs={12} sm={4} className={`${styles.flexEnd} ${styles.flexColumn}`}>
                            <Checkbox
                                onChange={onChange}
                                name="isCrackCalc"
                                title={"Zbrojenie ze względu na szerokość rysy"}
                                label={state.isCrackCalc.value ? 'Tak' : 'Nie'}
                                value={state.isCrackCalc.value}
                            />
                        </Col>
                        {
                            state.isCrackCalc.value && 
                            <Fragment>
                                <Col xs={12} sm={4} className={styles.columnSpace}>
                                    <InputField
                                        title={<Fragment>Maksymalna szerokość rysy - w<sub>max</sub> [mm]</Fragment>}
                                        onChange={onChange}
                                        onKeyDown={onKeyDown}
                                        onBlur={onBlur}
                                        name="maxCrackCalc"
                                        type="number"
                                        value={state.maxCrackCalc.value}
                                        isValid={state.maxCrackCalc.isValid}
                                        disable={true}
                                        required
                                    />
                                </Col>
                                <Col xs={6} sm={4} className={styles.columnSpace}>
                                    <InputField
                                        title={<Fragment>&phi; (&#8734;, t<sub>0</sub>)</Fragment>} 
                                        placeholder={'Wsp. pełzania'} 
                                        onChange={onChange}
                                        onKeyDown={onKeyDown}
                                        onBlur={onBlur}
                                        name="finalPhiCrack"
                                        type="number"
                                        value={state.finalPhiCrack.value}
                                        isValid={state.finalPhiCrack.isValid}
                                        required
                                    />
                                </Col>
                            </Fragment>
                        }
                    </Row>
                </Fragment>
            }            
            <SectionTitle title="Siły wewnętrzne w przekroju">
                {
                    state.isCrackCalc && state.memberType.value !== 'Słup' 
                    ?
                    'Momenty charakterystyczny oraz obliczeniowy (odpowiednio SGU oraz SGN) muszą być takiego samego znaku. W przeciwnym razie do obliczeń znak momentu zginającego SGU będzie taki jak SGN.'
                    :
                    ''
                }
            </SectionTitle>
            <Table>
                <thead className={styles.thead}>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th title="Obliczeniowy moment zginający">
                            M<sub>x, SGN</sub><br></br>[kNm]
                        </th>
                        {
                            state.isCrackCalc.value && state.memberType.value !== 'Słup' &&
                            <th title="Charakterystyczny moment zginający">
                                M<sub>x, SGU</sub><br></br>[kNm]
                            </th>
                        }
                        {
                            state.memberType.value === 'Słup' &&
                            <th title="Obliczeniowa siła osiowa">
                                N<sub>z, SGN</sub><br></br>[kN]
                            </th>                            
                        }
                        {
                            state.memberType.value !== 'Płyta' &&
                            <th title="Obliczeniowa siła tnąca">
                                V<sub>y, SGN</sub><br></br>[kN]
                            </th>
                        }
                        <th>Usuń</th>
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    {state.internalForces.map((element, index) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    <TableInput
                                        name="bendingMoment"
                                        type="number"
                                        align="center"
                                        placeholder='Moment zginający SGN'
                                        data-row={index}
                                        onChange={onChangeInetrnalForces}
                                        onKeyDown={onKeyDown}
                                        value={element.bendingMoment.value}
                                        isValid={element.bendingMoment.isValid}
                                    />
                                </td>
                                {
                                    state.isCrackCalc.value && state.memberType.value !== 'Słup' &&
                                    <td>
                                        <TableInput
                                            name="medp"
                                            type="number"
                                            align="center"
                                            placeholder='Moment zginający SGU'
                                            data-row={index}
                                            onChange={onChangeInetrnalForces}
                                            onKeyDown={onKeyDown}
                                            value={element.medp.value}
                                            isValid={element.medp.isValid}
                                        />
                                    </td>
                                }
                                {
                                    state.memberType.value === 'Słup' &&
                                    <td>
                                        <TableInput
                                            name="axialForce"
                                            type="number"
                                            align="center"
                                            placeholder='Siła osiowa SGN'
                                            data-row={index}
                                            onChange={onChangeInetrnalForces}
                                            onKeyDown={onKeyDown}
                                            value={element.axialForce.value}
                                            isValid={element.axialForce.isValid}
                                        />
                                    </td>                                    
                                }
                                {
                                    state.memberType.value !== 'Płyta' &&
                                    <td>
                                        <TableInput
                                            name="shearForce"
                                            type="number"
                                            align="center"
                                            placeholder='Siła tnąca SGN'
                                            data-row={index}
                                            onChange={onChangeInetrnalForces}
                                            value={element.shearForce.value}
                                            isValid={element.shearForce.isValid}
                                            required
                                        />
                                    </td>                                    
                                }

                                <td>
                                    <Button
                                        variant="danger"
                                        icon={faTrashAlt}
                                        altTitle={'Usuń'}
                                        onClick={() => removeRow(index)}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <div className={styles.actionsWrapper}>
                <div className={styles.actions}>
                    <Button
                        css="default-button-style"
                        onClick={addRow}
                        title={'Dodaj wiersz'}
                        icon={faPlus}
                    />
                </div>
            </div>

            {
                state.memberType.value === 'Słup' &&
                <Fragment>
                    <SectionTitle title="Imperfekcje geometryczne i efekty drugiego rzędu" />
                    <Row>
                        <Col xs={12} sm={6}>
                            <Checkbox
                                onChange={onChange}
                                title="Imperfekcje geometryczne"
                                name="ea"
                                label={`${state.ea.value ? 'Tak' : 'Nie'}`}
                                value={state.ea.value}
                            >
                                Rozpatruje się wpływ imperfekcji na elementy wydzielone. Oznacza to, że wymiarowanie poszczególnych prętów oraz ustrojów usztywniających odbywa się poprzez obliczanie ich jako elementów wydzielonych. Imperfekcja geometryczna uwzględniona jest poprzez zastosowanie zastępczego mimośrodu e<sub>i</sub>. <br></br> Zalecanym podejściem do uwzględnienia imperfekcji przechyłowej jest uwzględnienie jej w obliczeniach statycznych poprzez zastosowanie zastępczej siły poprzecznej H<sub>i</sub> przyłożonej w miejscu wywołującym największy moment zginający.<br></br>
                                Do wymiarowania zbrojenia przyjęto maksymalną wartość z:<br></br>
                                <ul>
                                    <li><b>0.5*&#1060;<sub>i</sub>*l<sub>0</sub></b>; l<sub>0</sub> - długość efektywna, &#1060;<sub>i</sub> - kąt pochylenia</li>
                                    <li><b>h/30</b>; h - wysokość przekroju</li>
                                    <li><b>20mm</b></li>
                                </ul>

                            </Checkbox>
                        </Col>
                        <Col xs={12} sm={6}>
                            <Checkbox
                                onChange={onChange}
                                title="Efekty drugiego rzędu"
                                name="secondOrder"
                                label={`${state.secondOrder.value ? 'Tak' : 'Nie'}`}
                                value={state.secondOrder.value}
                            >

                            </Checkbox>
                        </Col>
                    </Row>
                    {
                        (state.ea.value || state.secondOrder.value) &&
                        <Row>
                            <Col xs={12} sm={4} className={styles.columnSpace}>
                                <InputField
                                    name="l"
                                    type="number"
                                    value={state.l.value}
                                    isValid={state.l.isValid}
                                    title="Długość elementu [m]"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    onKeyDown={onKeyDown}
                                    placeholder="Długość elementu"
                                    required
                                />
                            </Col>
                            <Col xs={12} sm={4} className={styles.columnSpace}>
                                <SelectField
                                    title="Współcznnik wyboczeniowy"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    value={state.bucklingRatio.value}
                                    defaultOption=""
                                    isValid={state.bucklingRatio.isValid}
                                    options={bucklingRatios}
                                    name="bucklingRatio"
                                    infoCss={styles.infoClass}
                                    info={
                                        <Fragment>
                                            <img alt="Wyboczenie elementów" className={styles.img} src={BucklingImg} />
                                        </Fragment>                                                
                                    }
                                    required
                                />
                            </Col>
                            <Col xs={12} sm={4} className={styles.columnSpace}>
                                <InputField
                                    name="leff"
                                    type="number"
                                    value={state.leff.value}
                                    isValid={state.leff.isValid}
                                    title="Dł. wyboczeniowa elementu [m]"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    onKeyDown={onKeyDown}
                                    placeholder="Długość wyboczeniowa"
                                    disable={true}
                                    required
                                >
                                    <p>Długość wyboczeniowa elementu z uwzględnieniem warunków podparcia. W palach znajdujących się w ośrodku gruntowym zjawisko wyboczenia może zachodzić w słabonośnych gruntach spoistych tj. w gruntach o wytrzymałości na ścinanie bez odpływu c<sub>u</sub> {`${'<='}`} 30 kPa.</p>
                                </InputField>
                            </Col>
                        </Row>
                    }
                    {
                        state.secondOrder.value &&
                        <Fragment>
                            <Row>
                                <Col xs={12} sm={4} className={styles.columnSpace}>
                                    <InputField
                                        name="m01"
                                        type="number"
                                        value={state.m01.value}
                                        isValid={state.m01.isValid}
                                        title={<Fragment>M<sub>0,1</sub> [kNm]</Fragment>}
                                        placeholder={"Moment podporowy górny"}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        onKeyDown={onKeyDown}
                                        required
                                    >
                                        <p>Moment zginający pierwszego rzędu na górnym końcu elementu</p>
                                    </InputField>
                                </Col>
                                <Col xs={12} sm={4} className={styles.columnSpace}>
                                    <InputField
                                        name="m02"
                                        type="number"
                                        value={state.m02.value}
                                        isValid={state.m02.isValid}
                                        title={<Fragment>M<sub>0,2</sub> [kNm]</Fragment>}
                                        placeholder={"Moment podporowy dolny"}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        onKeyDown={onKeyDown}
                                        required
                                    >
                                        <p>Moment zginający pierwszego rzędu na dolnym końcu elementu</p>
                                    </InputField>
                                </Col>
                            </Row>
                            <Row>
                                <Form className={styles.radioForm}>
                                    <Row>
                                        <Col xs={12} sm={6} className={styles.columnSpace}>
                                            <RadioInput1
                                                name="nominalStiffness"
                                                cssLabel={styles.radio}
                                                css={styles.radioWrapper}
                                                label={'Metoda nominalnej sztywności'}
                                                onChange={onChange}
                                                value={state.nominalStiffness.value}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} className={styles.columnSpace}>
                                            <RadioInput1
                                                name="nominalCurvature"
                                                cssLabel={styles.radio}
                                                css={styles.radioWrapper}
                                                label={'Metoda nominalnej krzywizny'}
                                                onChange={onChange}
                                                value={state.nominalCurvature.value}
                                            />
                                        </Col>
                                    </Row>
                                </Form>
                            </Row>
                            {
                                (state.nominalStiffness.value || state.nominalCurvature.value) &&
                                <Row>
                                    {state.nominalStiffness.value &&
                                        <Col xs={12} sm={6} className={styles.columnSpace}>
                                            <SelectField
                                                title={<Fragment>Współczynnik c<sub>0</sub></Fragment>} 
                                                onBlur={onBlur}
                                                onChange={onChange}
                                                value={state.c0.value}
                                                defaultOption="Wybierz wartość"
                                                defaultValue=""
                                                disabledDefault={true}
                                                isValid={state.c0.isValid}
                                                options={[...c0Values]}
                                                infoCss={styles.infoClass}
                                                info={
                                                    <Fragment>
                                                        Współczynnik zależny od rozkładu momentów zginających wzdłuż elementu:
                                                        <ul>
                                                            <li>8 - wykres prostokątny</li>
                                                            <li>9.6 - wykres paraboliczny wypukły</li>
                                                            <li>&pi;<sup>2</sup> (9.87) - wykres sinusoidalny lub inny niż wymienione w tym zestawieniu</li>
                                                            <li>12 - symetryczny wykres trójkątny</li>
                                                            <li>16 - wykres paraboliczny wklęsły</li>
                                                        </ul>
                                                    </Fragment>
                                                }
                                                name="c0"
                                                type="number"
                                            />
                                        </Col>
                                    }
                                    {
                                        state.nominalCurvature.value &&
                                        <Col xs={12} sm={6} className={styles.columnSpace}>
                                            <SelectField
                                                title={<Fragment>Współczynnik c</Fragment>} 
                                                onBlur={onBlur}
                                                onChange={onChange}
                                                value={state.c.value}
                                                defaultOption="Wybierz wartość"
                                                defaultValue=""
                                                disabledDefault={true}
                                                isValid={state.c.isValid}
                                                options={[...cValues]}
                                                infoCss={styles.infoClass}
                                                info={
                                                    <Fragment>
                                                        Współczynnik zależny od rozkładu krzywizny:
                                                        <ul>
                                                            <li>9.87 (&pi;<sup>2</sup>) - sinusoidalny rozkład krzywizny</li>
                                                            <li>8 - stała krzywizna</li>
                                                        </ul>
                                                        *Jeżeli przekrój poprzeczny jest stały, zwykle przyjmuje się c = 10 (&pi;<sup>2</sup>)
                                                    </Fragment>
                                                }
                                                name="c"
                                                type="number"
                                            />
                                        </Col>
                                    }
                                    <Col xs={12} sm={6} className={styles.columnSpace}>
                                        <InputField
                                            title={<Fragment>&phi; (&#8734;, t<sub>0</sub>)</Fragment>} 
                                            onBlur={onBlur}
                                            placeholder={"Podaj wartość współczynnika"}
                                            onChange={onChange}
                                            value={state.finalPhi.value}
                                            isValid={state.finalPhi.isValid}
                                            infoCss={styles.infoClass}
                                            name="finalPhi"
                                            type="number"
                                        >
                                            <Fragment>
                                                Współczynnik należy wyznaczyć zgodnie z punktem 3.1.4 normy PN-EN 1992-1-1:2008 (Eurokod 2). <br></br>W celu wyznaczenia efektywnego wsp. pełzania &phi;<sub>eff</sub> zastosowano wsp. udziału obciążeń prawie stałych w całkowitych obciążeniach równy <b>k<sub>0,qp</sub> = M<sub>0,qp</sub>/M<sub>0,Ed</sub> = 0,62</b>.<br></br><br></br>
                                                <b>M<sub>0,qp</sub></b> - jest momentem zginającym pierwszego rzędu wywołanym prawie stałą kombinacją obciążeń (SLS)<br></br>
                                                <b>M<sub>0,Ed</sub></b> - jest momentem zginającym pierwszego rzędu wywołanym obliczeniową kombinacją obciążeń (ULS)
                                            </Fragment>
                                        </InputField>
                                    </Col>
                                </Row>                                        
                            }                            
                        </Fragment>
                    }
                </Fragment>
            }
        </Fragment>
    )
}

export default CalculationInput;