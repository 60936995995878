import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import AuthFetch from '../../routes/AuthFetch';
import { isString } from '../../utils';

const ContactFormService = ({ fetch, setAlert, setLoading, user }) => {
    const initState = {
        firstName: {
            value: user.firstName || '',
            isValid: isString(user.firstName) ? true : null,
        },
        lastName: {
            value: user.lastName || '',
            isValid: isString(user.lastName) ? true : null,
        },
        email: {
            value: user.email || '',
            isValid: isString(user.email) ? true : null,
        },
        content: {
            value: '',
            isValid: null,
        },
    };
    const [contactFormDetails, setContactFormDetails] = useState(initState);

    useDeepCompareEffect(() => {
        setContactFormDetails(state => ({
            ...state,
            firstName: {
                value: user.firstName || '',
                isValid: isString(user.firstName) ? true : null,
            },
            lastName: {
                value: user.lastName || '',
                isValid: isString(user.lastName) ? true : null,
            },
            email: {
                value: user.email || '',
                isValid: isString(user.email) ? true : null,
            },
        }))
    },[user])

    const onChange = (e, isValid) => {
        const target =
            e.currentTarget.closest('input') ||
            e.currentTarget.closest('textarea');
        const { name, value } = target;
        const valid = target.checkValidity();

        setContactFormDetails((state) => ({
            ...state,
            [name]: {
                value: value,
                isValid: isValid !== undefined ? isValid : valid,
            },
        }));
    };

    const onSubmit = () => {
        const isValidForm = Object.values(contactFormDetails).every(
            (ele) => ele.isValid,
        );

        if (isValidForm) {
            setLoading({ name: 'sendingMessage', loading: true });
            AuthFetch({
                url: fetch.sendMessage.url,
                method: fetch.sendMessage.method,
                body: {
                    ...Object.entries(contactFormDetails).reduce(
                        (acc, [key, value]) => ({ ...acc, [key]: value.value }),
                        {},
                    ),
                },
            })
                .then((response) => {
                    setLoading({ name: 'sendingMessage', loading: false });
                    if (response.success && response.status === 200) {
                        setAlert({
                            error: false,
                            message: 'Wiadomość została wysłana!',
                            hideTime: 2000,
                        });
                        setTimeout(() => {
                            setContactFormDetails(initState);
                        }, 2000);
                    } else {
                        if (response.status === 401) {
                            setAlert({
                                error: true,
                                message: 'Nie znaleziono użytkownika',
                            });
                        } else {
                            setAlert({
                                error: true,
                                message: 'Błąd podczas wysyłania wiadomości',
                            });
                        }
                    }
                })
                .catch((error) => {
                    setAlert({
                        error: true,
                        message: 'Błąd podczas wysyłania wiadomości',
                    });
                    setLoading({ name: 'sendingMessage', loading: false });
                });
        } else {
            setAlert({ error: true, message: 'Wypełnij formularz poprawnie' });
            setContactFormDetails((state) => {
                return {
                    ...Object.entries(state).reduce(
                        (acc, [key, value]) => ({
                            ...acc,
                            [key]: {
                                value: value.value,
                                isValid: !!value.isValid,
                            },
                        }),
                        {},
                    ),
                };
            });
        }
    };
    return {
        contactForm: {
            onChange,
            onSubmit,

            state: {
                contactFormDetails,
            },
        },
    };
};

export default ContactFormService;
