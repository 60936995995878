import React from 'react';
import { Form } from 'react-bootstrap';

const Email = ({ userEmail }) => {
    return (
        <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
                name="email"
                type="email"
                placeholder="Email"
                value={userEmail}
                readOnly
                required></Form.Control>
        </Form.Group>
    );
};

export default Email;
