const types = ["undefined", "object", "boolean", "number", "bigint", "string", "symbol", "function"].map(ele => ele.toLowerCase());

function groupBy(collection, property, type) {
    var i = 0, val, index,
        values = [], result = [];
    for (; i < collection.length; i++) {
        val = collection[i][property];
        index = values.indexOf(val);

        if(types.includes(type)){
            if (index > -1 && typeof val === type)
                result[index].push(collection[i]);
            else {
                values.push(val);
                result.push([collection[i]]);
            }
        } else {
            if (index > -1)
                result[index].push(collection[i]);
            else {
                values.push(val);
                result.push([collection[i]]);
            }
        }
    }
    return result;
}

export default groupBy;