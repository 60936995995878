import React, { Fragment, useEffect, useState } from 'react';
import { SectionTitle, Table } from '../../../../../components';
import styles from './index.module.css';
import round from '../../../../../utils/roundNumber';
import { isNumber } from '../../../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const SteelRodTable = ({ data = {}, screwType }) => {
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [isValid, setIsValid] = useState({});

    useDeepCompareEffect(() => {
        setCsvData([
            Object.fromEntries(
                Object.entries(data)
                    .filter((ele) => ele[0] !== 'drill')
                    .filter((ele) =>
                        ele[0] === 'din' ? isNumber(ele[1]) : true,
                    )
                    .filter((ele) =>
                        ele[0] === 'wx' ? isNumber(ele[1]) : true,
                    )
                    .filter((ele) =>
                        ele[0] === 'g' ? ele[1].length > 0 : true,
                    )
                    .filter((ele) =>
                        ele[0] === 'subname' ? ele[1].length > 0 : true,
                    )
                    .map(([key, value = '']) => [
                        key,
                        value.toString().replace('.', ','),
                    ]),
            ),
        ]);
        setCsvHeaders([
            {
                label: 'Producent [-]',
                key: 'prod',
            },
            {
                label: 'Typ [-]',
                key: 'name',
            },
            {
                label: 'dzew [mm]',
                key: 'dout',
            },
            {
                label: 'a [mm2]',
                key: 'a',
            },
            {
                label: 'fu [kN]',
                key: 'fu',
            },
            {
                label: 'Rmk [kN]',
                key: 'rmk',
            },
            {
                label: 'm [kg/m]',
                key: 'm',
            },
        ]);
        setIsValid({
            rmk: isNumber(data.rmk),
            din: isNumber(data.din),
            wx: isNumber(data.wx),
            g: data.g ? data.g.length > 0 : false,
            subname: data.subname.length > 0,
        });
    }, [data]);

    useEffect(() => {
        if (isValid.subname) {
            setCsvHeaders((state) => [
                ...state,
                {
                    label: 'Rodzaj [-]',
                    key: 'subname',
                },
            ]);
        } else {
            setCsvHeaders((state) => [...state]);
        }
    }, [isValid.subname]);

    useEffect(() => {
        if (isValid.din) {
            setCsvHeaders((state) => [
                ...state,
                {
                    label: 'dwew [mm]',
                    key: 'din',
                },
            ]);
        } else {
            setCsvHeaders((state) => [...state]);
        }
    }, [isValid.din]);

    useEffect(() => {
        if (isValid.g) {
            setCsvHeaders((state) => [
                ...state,
                {
                    label: 'Gwint [-]',
                    key: 'g',
                },
            ]);
        } else {
            setCsvHeaders((state) => [...state]);
        }
    }, [isValid.g]);

    useEffect(() => {
        if (isValid.wx) {
            setCsvHeaders((state) => [
                ...state,
                {
                    label: 'Wx [cm3]',
                    key: 'wx',
                },
            ]);
        } else {
            setCsvHeaders((state) => [...state]);
        }
    }, [isValid.wx]);

    return (
        <Fragment>
            <SectionTitle title={'Parametry żerdzi stalowej'} />

            <Table
                csvData={csvData}
                csvFilename={'SteelRodCapacity'}
                csvHeaders={csvHeaders}>
                <thead>
                    <tr>
                        <th title="Nazwa">
                            Nazwa<br></br>[-]
                        </th>
                        <th title="Rodzaj">
                            Rodzaj<br></br>[-]
                        </th>
                        {
                            screwType && (
                                <th title="Koronka">
                                    Koronka<br></br>[-]
                                </th>  
                            )
                        }
                        {isValid.subname && (
                            <th title="Typ">
                                Typ<br></br>[-]
                            </th>
                        )}
                        <th title="Średnica zewnętrzna">
                            d<sub>zew</sub>
                            <br></br>[mm]
                        </th>
                        {isValid.din && (
                            <th title="Średnica wewnętrzna">
                                d<sub>wew</sub>
                                <br></br>[mm]
                            </th>
                        )}
                        <th title="Pole powierzchni">
                            a<br></br>[mm<sup>2</sup>]
                        </th>
                        <th title="Nośność na zerwanie">
                            f<sub>u</sub>
                            <br></br>[kN]
                        </th>
                        {isValid.rmk && (
                            <th title="Nośność charakterystyczna">
                                R<sub>m,k</sub>
                                <br></br>[kN]
                            </th>
                        )}
                        {isValid.g && (
                            <th title="Rodzaj gwintu">
                                Gwint<br></br>[-]
                            </th>
                        )}
                        <th title="Masa żerdzi">
                            m<br></br>[kg/m]
                        </th>
                        {isValid.wx && (
                            <th title="Wskaźnik wytrzymałości">
                                W<sub>x</sub>
                                <br></br>[cm<sup>3</sup>]
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    <tr className={`${styles.tr}`}>
                        <td>{data.prod}</td>
                        <td>{data.name}</td>
                        { screwType && <td>{screwType}</td>}
                        {data.subname.length > 0 && <td>{data.subname}</td>}
                        <td>{round(data.dout, 2)}</td>
                        {isNumber(data.din) && <td>{round(data.din, 2)}</td>}
                        <td>{round(data.a, 2)}</td>
                        <td>{round(data.fu, 2)}</td>
                        {isNumber(data.rmk) && <td>{round(data.rmk, 2)}</td>}
                        {data.g && data.g.length > 0 && <td>{data.g}</td>}
                        <td>{round(data.m, 2)}</td>
                        {isNumber(data.wx) && <td>{round(data.wx, 2)}</td>}
                    </tr>
                </tbody>
            </Table>
        </Fragment>
    );
};

export default SteelRodTable;
