import { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import styles from './index.module.css';
import { isNumber, roundNumber } from "../../../../../utils";

const CrossSection = ({ 
    dimension,
    width,
    circular,
    coverUp,
    coverBottom,
    memberType,
    result={},
    capacity={}
}) => {
    const negativeMoment = result.defaultResults.negativeMoment;
    const x = result.result.x;
    const d = result.defaultResults.d;

    const ratio = width > 3*dimension ? 300 : 200;
    const maxDim = Math.max(dimension, width);
    const heightPx = (dimension/maxDim)*ratio;
    const compressionZone =  (x/dimension)*100;
    const mmPerPx = ratio/maxDim;

    return (
        <Fragment>
            <Row>
                <Col xs={12}>
                    <div className={styles.avoidBreakInside}>
                        <div className={styles.pWrapper}>
                            <p><b>Przypadek nr: {result.defaultResults.index + 1}</b></p>
                            <p>Siły wewnętrzne przyjęte do obliczeń</p>
                            {result.defaultResults.memberType === 'Słup' && <p>Siła osiowa: <span className={styles.value}>{roundNumber(result.defaultResults.ned,2)} kN</span></p>}
                            {
                                memberType !== 'Płyta' &&
                                <p>Siła ścinająca: <span className={styles.value}>{roundNumber(result.defaultResults.shearForce,2)} kN</span></p>
                            }
                            <p>Moment zginający: <span className={styles.value}>{roundNumber(result.defaultResults.med,2)} kNm</span></p>
                        </div>                        

                        <div className={styles.sectionContainer}>
                            <div className={styles.wrapper}>

                                {
                                    !circular &&
                                    <div>
                                        {capacity.rebarNumberAs2.value > 0 && <p className={styles.rebarDescription}>{capacity.rebarNumberAs2.value}&#981;{capacity.rebarDiameterAs2.value}mm (A<sub>s2</sub>)</p>}                                    
                                    </div>                                
                                }
                                <div className={styles.flexRow}>
                                    {
                                        dimension && coverUp && coverBottom &&
                                        <div className={styles.verticalDimension}>
                                            <div style={{ height: `${(coverUp/dimension)*heightPx}px` }} className={styles.lineWrapper}>
                                                <div className={styles.line}></div>
                                                <p>{`${coverUp}`}</p>
                                                <div className={styles.line}></div>
                                            </div>
                                            <div style={{ height:`${heightPx - ((coverUp + coverBottom)/dimension)*heightPx}px` }} className={styles.lineWrapper}>
                                                <p>{`${dimension - (coverUp + coverBottom)}`}</p>
                                            </div>
                                            <div style={{ height: `${(coverBottom/dimension)*heightPx}px` }} className={styles.lineWrapper}>
                                                <div className={styles.line}></div>
                                                <p>{`${coverBottom}`}</p>
                                                <div className={styles.line}></div>
                                            </div>
                                        </div>
                                    }
                                    <div className={styles.block}>
                                        {
                                            circular
                                            ?
                                            <div 
                                                className={styles.crossSectionCircular}
                                                style={{
                                                    padding: `${(coverUp/dimension)*ratio}px`,
                                                    background: `${(compressionZone > 100 || !isNumber(compressionZone))
                                                        ? '#ccc'
                                                        : `linear-gradient(${negativeMoment ? '0deg' : '180deg'}, #b3b3b3 ${compressionZone}%, #ccc ${compressionZone}% 100%)`}`
                                                }}
                                            >
                                                <div 
                                                    className={styles.spiralCircular}
                                                    style={{
                                                        border: `${(capacity.rebarSpiral.value/dimension)*ratio}px solid #000`,
                                                    }}
                                                >
                                                    {
                                                        new Array(capacity.rebarNumber.value).fill('').map((_, idx) => {
                                                            return (
                                                                <div 
                                                                    key={idx} 
                                                                    className={styles.rebarCircle}
                                                                    style={{
                                                                        transform: `
                                                                        rotate(${capacity.rebarNumber.value%2 === 0 ? (360/capacity.rebarNumber.value)*idx : 90 + (360/capacity.rebarNumber.value)*idx}deg)  
                                                                        translateY(${(((coverUp + capacity.rebarSpiral.value  + 0.5*capacity.rebarDiameter.value)/dimension)*ratio) - 0.5*ratio}px)`,
                                                                        width: `${(capacity.rebarDiameter.value/dimension)*ratio}px`,
                                                                        height: `${(capacity.rebarDiameter.value/dimension)*ratio}px`,
                                                                        top: `calc(50% - ${0.5 * (capacity.rebarDiameter.value/dimension)*ratio}px)`,
                                                                        left: `calc(50% - ${0.5 * (capacity.rebarDiameter.value/dimension)*ratio}px)`
                                                                    }}
                                                                >
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {capacity.rebarNumber.value > 0 && <p className={styles.rebarDescription}>{capacity.rebarNumber.value}&#981;{capacity.rebarDiameter.value}mm (A<sub>s</sub>)</p>}
                                                </div>
                                            </div>
                                            :
                                            <Fragment>
                                                <div 
                                                    className={memberType === 'Płyta' ? styles.crossSection1 : styles.crossSection}
                                                    style={{
                                                        width: `${width*mmPerPx}px`,
                                                        height: `${dimension*mmPerPx}px`,
                                                        paddingTop: `${coverUp*mmPerPx}px`,
                                                        paddingBottom: `${coverBottom*mmPerPx}px`,
                                                        paddingLeft: `${coverBottom*mmPerPx}px`,
                                                        paddingRight: `${coverBottom*mmPerPx}px`,
                                                        background: `${compressionZone > 100 
                                                            ? '#ccc'
                                                            : `linear-gradient(${negativeMoment ? '0deg' : '180deg'}, #b3b3b3 ${compressionZone}%, #ccc ${compressionZone}% 100%)`}`
                                                    }}
                                                >
                                                    {
                                                        memberType === 'Płyta' &&
                                                        <Fragment>
                                                            <div className={styles.lineDashed}></div>
                                                            <div className={styles.lineDashed1}></div>
                                                        </Fragment>
                                                    }
                                                    <div 
                                                        className={styles.spiral}
                                                        style={{
                                                            border: `${memberType !== 'Płyta' ? `${capacity.rebarSpiral.value*mmPerPx}px solid #333` : 'none'} `,
                                                            borderRadius: `${(capacity.rebarSpiral.value <= 16 ? 2 : 3.5) * (capacity.rebarSpiral.value*mmPerPx)}px`,
                                                        }}
                                                    >
                                                        <div 
                                                            className={styles.rebarRow}
                                                            style={{
                                                                width: `${memberType !== 'Płyta' ? `calc(100% - ${(capacity.rebarSpiral.value <= 16 ? 2 : 3.5) * (capacity.rebarSpiral.value*mmPerPx)}px)` : '100%'}`,
                                                                position: 'relative',
                                                                left: `${memberType !== 'Płyta' ? `${(capacity.rebarSpiral.value <= 16 ? 2 : 3.5) * (capacity.rebarSpiral.value*mmPerPx)*0.5}px` : '0'}`
                                                            }}
                                                        >
                                                            <div className={styles.column}>
                                                                <div className={styles.flex}>
                                                                    {
                                                                        capacity.rebarNumberAs2.value > 0 &&
                                                                        new Array(capacity.rebarNumberAs2.value).fill('').map((_, idx) => {
                                                                            return (
                                                                                <div 
                                                                                    key={idx} 
                                                                                    className={styles.rebar}
                                                                                    style={{
                                                                                        width: `${capacity.rebarDiameterAs2.value*mmPerPx}px`,
                                                                                        height: `${capacity.rebarDiameterAs2.value*mmPerPx}px`
                                                                                    }}
                                                                                >
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div 
                                                            className={styles.rebarRow}
                                                            style={{
                                                                width: `${memberType !== 'Płyta' ? `calc(100% - ${(capacity.rebarSpiral.value <= 16 ? 2 : 3.5) * (capacity.rebarSpiral.value*mmPerPx)}px)` : '100%'}`,
                                                                position: 'relative',
                                                                left: `${memberType !== 'Płyta' ? `${(capacity.rebarSpiral.value <= 16 ? 2 : 3.5) * (capacity.rebarSpiral.value*mmPerPx)*0.5}px` : '0'}`
                                                            }}
                                                        >
                                                            <div className={styles.column}>
                                                                <div className={styles.flex}>
                                                                {
                                                                        capacity.rebarNumberAs1.value > 0 &&
                                                                        new Array(capacity.rebarNumberAs1.value).fill('').map((_, idx) => {
                                                                            return (
                                                                                <div 
                                                                                    key={idx} 
                                                                                    className={styles.rebar}
                                                                                    style={{
                                                                                        width: `${capacity.rebarDiameterAs1.value*mmPerPx}px`,
                                                                                        height: `${capacity.rebarDiameterAs1.value*mmPerPx}px`
                                                                                    }}
                                                                                >
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {capacity.rebarNumberAs1.value > 0 && <p className={styles.rebarDescription}>{capacity.rebarNumberAs1.value}&#981;{capacity.rebarDiameterAs1.value}mm (A<sub>s1</sub>)</p>}
                                            </Fragment>
                                        }

                                        {
                                            circular
                                            ?
                                                dimension &&
                                                <div className={styles.dimension}>
                                                    <p>{`${dimension}`}</p>
                                                    <div className={styles.lineWrapper}>
                                                        <div className={styles.line}></div>
                                                        <div className={styles.line}></div>
                                                    </div>
                                                </div>
                                            :
                                            width &&
                                            <Fragment>
                                                <div 
                                                    className={styles.dimension}
                                                    style={{
                                                        width: `${(width/maxDim)*ratio}px`
                                                    }}
                                                >
                                                    <p>{`${width}`}</p>
                                                    <div className={styles.lineWrapper}>
                                                        <div className={styles.line}></div>
                                                        <div className={styles.line}></div>
                                                    </div>
                                                    
                                                </div>
                                            </Fragment>
                                        }
                                    </div>
                                    <Fragment>
                                        {
                                            dimension && coverUp && coverBottom && x > 0 &&
                                            <div className={styles.verticalDimensionRight}>
                                                <div style={{ 
                                                    height:`${x > dimension ? heightPx : (x/dimension)*heightPx}px`, 
                                                    top: `${negativeMoment ? x > dimension ? 0 : heightPx - (x/dimension)*heightPx : 0}px`}} 
                                                    className={styles.lineWrapper}
                                                >
                                                    <div className={styles.line}></div>
                                                    <p>x={x}</p>
                                                    <div className={styles.line}></div>
                                                </div>
                                            </div>
                                        }
                                    </Fragment>
                                    <Fragment>
                                        {
                                            dimension && coverUp && coverBottom &&
                                            <div className={styles.verticalDimensionRight}>
                                                <div style={{ 
                                                    height: circular
                                                        ? 
                                                        `${((dimension - coverBottom - capacity.rebarSpiral.value - 0.5*capacity.rebarDiameter.value)/dimension)*heightPx}px`
                                                        :
                                                        `${(d/dimension)*heightPx}px`,
                                                    top: circular
                                                        ?
                                                        `${negativeMoment ? heightPx - ((dimension - coverUp - capacity.rebarSpiral.value - 0.5*capacity.rebarDiameter.value)/dimension)*heightPx : 0}px`
                                                        :
                                                        `${negativeMoment ? heightPx - (d/dimension)*heightPx : 0}px`
                                                    }} 
                                                    
                                                    className={styles.lineWrapper}
                                                >
                                                    <div className={styles.line}></div>
                                                    <p>{
                                                        circular
                                                        ? 
                                                        dimension - coverBottom - capacity.rebarSpiral.value - 0.5*capacity.rebarDiameter.value
                                                        :
                                                        d
                                                    }
                                                    </p>
                                                    <div className={styles.line}></div>
                                                </div>
                                            </div>
                                        }
                                    </Fragment>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}

export default CrossSection