import { Fragment } from 'react';
import { Table } from 'react-bootstrap';
import round from '../../../../../utils/roundNumber';
import styles from './index.module.css';
import { isNumber, isString } from '../../../../../utils';

const MicropileCompressionSummary = ({ data = [] }) => {
    return (
        <Fragment>
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th>
                            Tytuł obliczeń<br></br>[-]
                        </th>
                        <th>
                            Nazwa profilu<br></br>[-]
                        </th>
                        <th>
                            Producent<br></br>[-]
                        </th>
                        <th>
                            Typ żerdzi<br></br>[-]
                        </th>
                        <th>
                            d<br></br>[mm]
                        </th>
                        <th>
                            &#945;<br></br>[&#176;]
                        </th>
                        <th>
                            F<br></br>[kN]
                        </th>
                        <th>
                            R<sub>b</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            R<sub>s</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            R<sub>c,d</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            L<sub>b,c</sub>
                            <br></br>[m]
                        </th>
                        <th>
                            L<sub>tot,c</sub>
                            <br></br>[m]
                        </th>
                        <th>
                            R<sub>t,d</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            L<sub>b,t</sub>
                            <br></br>[m]
                        </th>
                        <th>
                            L<sub>tot,t</sub>
                            <br></br>[m]
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((element, index) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    {isString(element.model.projectTitle) &&
                                    element.model.projectTitle.length > 0
                                        ? element.model.projectTitle
                                        : '-'}
                                </td>
                                <td>
                                    {isString(element.profileName)
                                        ? element.profileName
                                        : '-'}
                                </td>
                                <td>
                                    {isString(
                                        element.results.currentCapacity.rodProd,
                                    )
                                        ? element.results.currentCapacity
                                              .rodProd
                                        : '-'}
                                </td>
                                <td>
                                    {isString(
                                        element.results.currentCapacity.rodName,
                                    )
                                        ? element.results.currentCapacity
                                              .rodName
                                        : '-'}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity
                                            .diameter,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity
                                            .micropileAngle,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity
                                            .axisLoad,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {isNumber(
                                        element.results.currentCapacity.np,
                                    )
                                        ? round(
                                              element.results.currentCapacity
                                                  .np,
                                              2,
                                          )
                                        : '-'}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity.ns,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity.nt,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity
                                            .bondLength,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity
                                            .totalRodLength,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity.ntw,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity
                                            .bondLengthPull,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity
                                            .totalRodLengthPull,
                                        2,
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <p className={styles.underline}>Oznaczenia:</p>
            <p>
                d -{' '}
                <span className={styles.value}>
                    średnica koronki wiertniczej mikropala
                </span>
            </p>
            <p>
                &#945; -{' '}
                <span className={styles.value}>
                    kąt nachylenia mikropala do pionu
                </span>
            </p>
            <p>
                F -{' '}
                <span className={styles.value}>
                    projektowana siła osiowa w mikropalu
                </span>
            </p>
            <p>
                R<sub>b</sub> -{' '}
                <span className={styles.value}>
                    obliczeniowa nośność podstawy mikropala
                </span>
            </p>
            <p>
                R<sub>s</sub> -{' '}
                <span className={styles.value}>
                    obliczeniowa nośność pobocznicy mikropala
                </span>
            </p>
            <p>
                R<sub>c,d</sub> -{' '}
                <span className={styles.value}>
                    obliczeniowa nośność mikropala na wciskanie
                </span>
            </p>
            <p>
                L<sub>b,c</sub> -{' '}
                <span className={styles.value}>
                    długość części nośnej mikropala potrzebnej do przeniesienia
                    wciskającej siły osiowej F
                </span>
            </p>
            <p>
                L<sub>tot,c</sub> -{' '}
                <span className={styles.value}>
                    całkowita długość mikropala potrzebna do przeniesienia
                    wciskającej siły osiowej F
                </span>
            </p>

            <p>
                R<sub>t,d</sub> -{' '}
                <span className={styles.value}>
                    obliczeniowa nośność mikropala na wyciąganie
                </span>
            </p>
            <p>
                L<sub>b,t</sub> -{' '}
                <span className={styles.value}>
                    długość części nośnej mikropala potrzebnej do przeniesienia
                    wyciągającej siły osiowej F
                </span>
            </p>
            <p>
                L<sub>tot,t</sub> -{' '}
                <span className={styles.value}>
                    całkowita długość mikropala potrzebna do przeniesienia
                    wyciągającej siły osiowej F
                </span>
            </p>
        </Fragment>
    );
};

export default MicropileCompressionSummary;
