import { Col, Row } from "react-bootstrap";
import { SectionTitle } from "../../../../../components";
import { withResults } from "../../../../../hoc";
import Ec7SingleCapacity from "../Ec7SingleCapacity";
import GroupPileCapacity from "../GroupPileCapacity";
import QcdChart from "../QcdChart";
import SinglePileCapacityPN from "../SinglePileCapacityPN";
import TcdChart from "../TcdChart";
import TechnologyFactorsDesign from "../TechnologyFactorsDesign";
import VerticalCapacityChart from "../VerticalCapacityChart";
import styles from './index.module.css';

const ResultsWrapper = ({ data={} }) => {

    return (
        <div className={'vertical-capacity-results'}>
            <Row className={styles.row}>
                <Col xs={12}>
                    <VerticalCapacityChart
                        dataToChart={{ 
                            soilProfile: data.soilProfile,    
                            groundLevel: data.groundLevel,
                            results: data.results,
                            pileHeadSpot: data.pileHeadSpot,
                            pileLength: data.pileLength,
                            pileShape: data.pileShape,
                            pileType: data.pileType, 
                            pileTechnology: data.pileTechnology,
                            diameterPile: data.diameterPile,
                            singlePile: data.singlePile,
                            currentPileLengthByUser: data.currentPileLength,
                            onChangeCurrentPileLength: data.onChangeCurrentPileLength,
                            onChangeCurrentCapacity: data.onChangeCurrentCapacity,
                            openSidebar: data.openSidebar
                        }}
                    />
                </Col>
            </Row>
            
            <Row className={styles.rowMarginTop}> 
                <Col xs={12}>
                    <SectionTitle title={"Rozkład oporów gruntu wzdłuż pala"}/>
                </Col>
            </Row>
                
            <Row className={styles.rowMarginBottom}>
                <Col xs={12} sm={{span: 10, offset: 1}} lg={{span: 6, offset: 0}}>
                    <QcdChart
                        chartTitle="Obliczeniowy opór pod podstawą pala q,r"
                        yLabel="Głębokość Z [m.p.p.t]"
                        xLabel="q,r [kPa]"
                        dataToChart={{
                            soilProfile: data.soilProfile,    
                            results: data.results
                        }}
                    />
                </Col>
                <Col xs={12} sm={{span: 10, offset: 1}} lg={{span: 6, offset: 0}}>
                    <TcdChart
                        chartTitle="Obliczeniowy opór na pobocznicy pala t,r"
                        yLabel="Głębokość Z [m.p.p.t]"
                        xLabel="t,r [kPa]"
                        dataToChart={{
                            soilProfile: data.soilProfile,    
                            results: data.results
                        }}
                    />
                </Col>                
            </Row>
            <Row className={styles.row}>
                <Col xs={12}>
                    <SinglePileCapacityPN 
                        dataToPaginate={data.results}
                    />
                </Col>
            </Row>
            <Row className={styles.row}>
                <Col xs={12}>
                    <Ec7SingleCapacity
                        dataToPaginate={data.results}
                    />
                </Col>                
            </Row>
            { 
                !data.singlePile &&
                <Row className={styles.row}>
                    <Col xs={12}>
                        <GroupPileCapacity
                            dataToPaginate={data.results}
                        />
                    </Col>                
                </Row>                
            }
            <Row className={styles.row}>
                <Col xs={12}>
                    <TechnologyFactorsDesign
                        dataToPaginate={data.results}
                    />
                </Col>                
            </Row>
        </div>
    )
}

export default withResults(ResultsWrapper)