import isNumber from "../isNumber";

const subHeight = (arr, waterLevel) => {
    const arrLength = arr.length;
    const lastElement = arr.slice(-1);
    const arrToLastElement = arr.slice(0,arrLength-1);
    const isValid = arr.every(ele => Object.values(ele).every(ele => isNumber(ele.value)));


    if(isValid){
        if(arrToLastElement.length <= 0){
            return 0
        } else {
            const sumAboveLayers = arrToLastElement.map(ele => {
                if(waterLevel >=0){
                    if(ele.height.value <= waterLevel){
                        return ele.absHeight.value*ele.weight.value
                    } else {
                        return ele.absHeight.value*ele.underWaterWeight.value
                    }
                } else {
                    return ele.absHeight.value*ele.weight.value
                }                
            }).reduce((acc, val) => acc+val, 0);

            if(lastElement[0].height.value > waterLevel){
                return Number((sumAboveLayers/(lastElement[0].underWaterWeight.value)).toFixed(2))
            } else {
                return Number((sumAboveLayers/(lastElement[0].weight.value)).toFixed(2))
            }
        }        
    } else {
        return ''
    }
}

export default subHeight