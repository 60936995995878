import React, { Fragment, useContext } from 'react';
import { MainAlertAndLoading } from '../../../../components';
import { reinforcementContext } from '../../../../context/Reinforcement';
import ResultsWrapper from './ResultsWrapper';

const Results = ({ openSidebar }) => {
    const {
        results,
        checkCapacity,
        sectionCapacity,
        globalAlerts={},
        globalLoading={},
    } = useContext(reinforcementContext);

    const { isResultsActive, ...rest } = results;
    
    return (
        <Fragment>
            <ResultsWrapper
                isResultsActive={results.isResultsActive}
                data={{ 
                    ...rest.state,
                    shortResults: rest.shortResults,
                    onShortResultsChange: rest.onShortResultsChange,
                    checkCapacity,
                    sectionCapacity,
                    openSidebar
                }}

            />
            <MainAlertAndLoading
                alerts={globalAlerts.state.alerts}
                updateAlerts={globalAlerts.updateState}
                loadings={globalLoading.state}
            />
        </Fragment>
    );
};

export default Results;
