import { useState } from 'react';
import { isNumber, roundNumber } from '../../../../../utils';
import styles from './index.module.css';
import useDeepCompareEffect from 'use-deep-compare-effect';

const CorrectDepth = ({ correctDepth = {} }) => {

    const [isBuckling, setIsBuckling] = useState(false);
    const { micropileCapacity = {}, buckling = {} } = correctDepth;

    useDeepCompareEffect(() => {
        if (Array.isArray(buckling)) {
            setIsBuckling(() => {
                const bucklingLayers = buckling.filter((prop) => prop.buckling);
                const isDefRatio =
                    bucklingLayers.length > 0
                        ? bucklingLayers.every((ele) => {
                              if (ele.details.freeSpaceRod) {
                                  return true;
                              } else {
                                  return ele.details.isDefRatioCorrect;
                              }
                          })
                        : true;
                return bucklingLayers.length > 0
                    ? bucklingLayers.every((ele) => {
                          return (
                              ele.details.isAxialForceCorrect &&
                              ele.details.isBuckCorrect &&
                              isDefRatio
                          );
                      })
                    : true;
            });
        } else {
            setIsBuckling(false);
        }
    }, [buckling]);

    return (
        <ol>
            {micropileCapacity.axisLoad >= 1 ? (
                <li>
                    <p>
                        Nośność mikropala spełniona do przeniesienia siły
                        osiowej. Udział nośności do siły osiowej{' '}
                        {roundNumber(micropileCapacity.axisLoad * 100, 1)}%
                    </p>
                </li>
            ) : (
                <li className={`${styles.wrongDepth}`}>
                    <p>
                        Zbyt mała nośność mikropala do przeniesienia siły
                        osiowej. Udział nośności do siły osiowej{' '}
                        {roundNumber(micropileCapacity.axisLoad * 100, 1)}%
                    </p>
                </li>
            )}
            {isNumber(micropileCapacity.steelRodLoad) && (
                <li
                    className={
                        micropileCapacity.steelRodLoad > 1
                            ? `${styles.warningDepth}`
                            : ''
                    }>
                    <p>
                        Wytężenie stalowej żerdzi w{' '}
                        {roundNumber(micropileCapacity.steelRodLoad * 100, 1)}%
                        przy wykorzystaniu pełnej nośności zewnętrznej mikropala
                    </p>
                </li>
            )}
            {Array.isArray(buckling) ? (
                !isBuckling ? (
                    <li className={`${styles.wrongDepth}`}>
                        <p>
                            Możliwe wyboczenie mikropala w gruntach spoistych o
                            c<sub>u</sub> {`<=`} 30 kPa lub w części wolnej nie
                            znajdującej się w gruncie, przy wykorzystaniu pełnej
                            nośności zewnętrznej mikropala
                        </p>
                    </li>
                ) : (
                    <li>
                        <p>Brak ryzyka wyboczenia mikropala</p>
                    </li>
                )
            ) : (
                ''
            )}
        </ol>
    );
};

export default CorrectDepth;
