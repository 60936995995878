import axios from "axios";

const AdminFetch = ({ url, method, body }) => {
    const token = localStorage.getItem('token1');
    return (
        axios({
            url,
            method,
            data: body,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        .then(response => {
            return {
                responseData: response.data,
                status: response.status,
                statusText: response.statusText,
                isLoaded: true,
                success: true
            }
        })
        .catch(error => {
            return {
                responseData: error.response.data, 
                status: error.response.status, 
                statusText: error.response.statusText, 
                isLoaded: true,
                success: false
            }
        })
    )
}

export default AdminFetch;