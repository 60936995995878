import React, { Fragment, useContext } from 'react';
import { MainAlertAndLoading } from '../../../../components';
import { micropilesCompressionContext } from '../../../../context/MicropilesCompression';
import ResultsWrapper from './ResultsWrapper';

const Results = ({ openSidebar }) => {
    const { results, globalAlerts, globalLoading } = useContext(
        micropilesCompressionContext,
    );

    return (
        <Fragment>
            <ResultsWrapper
                isResultsActive={results.isResultsActive}
                isResultsActual={results.state.isResultsActual}
                openSidebar={openSidebar}
                data={{
                    results: results.state,
                    currentMicropileLength: results.currentMicropileLength,
                    onChangeRodModule: results.onChangeRodModule,
                    onChangeCurrentMicropileLength:
                        results.onChangeCurrentMicropileLength,
                    onChangeProdMicropileLength:
                        results.onChangeProdMicropileLength,
                    onChangeCurrentCapacity: results.onChangeCurrentCapacity,
                }}
            />
            <MainAlertAndLoading
                alerts={globalAlerts.state.alerts}
                updateAlerts={globalAlerts.updateState}
                loadings={globalLoading.state}
            />
        </Fragment>
    );
};

export default Results;
