import { useEffect, useRef } from 'react';

const useInterval = (callback, delay, runImmeditely = false) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (runImmeditely) {
            tick();
        }
        let id = setInterval(tick, delay);

        return () => {
            clearTimeout(tick);
            clearInterval(tick);
            clearInterval(id);
        };
    }, [delay, runImmeditely]);
};

export default useInterval;
