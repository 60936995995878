import { Outlet, useOutletContext } from "react-router-dom";
import SheetPileCptProvider from "../../../context/SheetPileCpt";
import { SheetPileCptNavigation } from "../../../pages";

const SheetPileCptLayout = () => {
    const context = useOutletContext();

    return (
        <SheetPileCptProvider>
            <SheetPileCptNavigation report={context.report}/>
            <Outlet context={context}/>
        </SheetPileCptProvider>
    )
}

export default SheetPileCptLayout;