import { useState } from "react"

const LoadingState = () => {
    const [loading, setLoading] = useState({});

    const addLoading = ({ name, loading, text }) => {
        setLoading(state => ({
            ...state,
            text,
            [name]: loading
        }))
    }

    const globalLoading = {
        state: loading,
        updateState: setLoading,
        initState: () => setLoading({}),
        addLoading: addLoading
    }

    return {
        globalLoading
    }
}

export default LoadingState