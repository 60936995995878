import React, { Fragment, useState } from 'react';
import { withPagination } from '../../../../../hoc';
import {
    ItemsPerPage,
    Pagination,
    SectionTitle,
    Table,
} from '../../../../../components';
import styles from './index.module.css';
import round from '../../../../../utils/roundNumber';
import { isNumber } from '../../../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const MicropileCapacityTable = ({
    dataToPaginate,
    allCollection = [],
    pagination,
    paginationHelpers,
}) => {
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [csvData, setCsvData] = useState([]);

    useDeepCompareEffect(() => {
        setCsvData(
            allCollection
                .map(
                    ({
                        bondLength,
                        enlargeDiameter,
                        height,
                        absHeight,
                        diameter,
                        ksi,
                        yt,
                        modelFactor,
                        layerCapacity,
                        name,
                        qsk,
                    }) => ({
                        bondLength,
                        diameter,
                        absHeight,
                        ksi,
                        yt,
                        modelFactor,
                        enlargeDiameter,
                        height,
                        layerCapacity,
                        name,
                        qsk,
                    }),
                )
                .map((ele) =>
                    Object.fromEntries(
                        Object.entries(ele).map(([key, value = '']) => [
                            [key],
                            typeof value === 'string' || isNumber(value)
                                ? value.toString().replace('.', ',')
                                : value,
                        ]),
                    ),
                ),
        );

        setCsvHeaders([
            {
                label: 'Nazwa [-]',
                key: 'name',
            },
            {
                label: 'Z [m.p.p.t.]',
                key: 'height',
            },
            {
                label: 'h [m]',
                key: 'absHeight',
            },
            {
                label: 'd [mm]',
                key: 'diameter',
            },
            {
                label: 'qsk [kPa]',
                key: 'qsk',
            },
            {
                label: 'a [-]',
                key: 'enlargeDiameter',
            },
            {
                label: 'ys,t [-]',
                key: 'yt',
            },
            {
                label: 'ksi [-]',
                key: 'ksi',
            },
            {
                label: 'nM [-]',
                key: 'modelFactor',
            },
            {
                label: 'Lb [m]',
                key: 'bondLength',
            },

            {
                label: 'Rt,d [kN]',
                key: 'layerCapacity',
            },
        ]);
    }, [allCollection]);

    return (
        <Fragment>
            <SectionTitle
                title={'Rozkład nośności mikropala wzdłuż profilu gruntowego'}
            />
            <ItemsPerPage
                onDisplayItemsNumber={paginationHelpers.displayItemsNumber}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                display={[15, 30, paginationHelpers.allItemsLength]}
            />
            <Table
                csvData={csvData}
                csvFilename={'MicropileCapacityTable'}
                csvHeaders={csvHeaders}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th title="Nazwa warstwy">
                            Nazwa<br></br>[-]
                        </th>
                        <th title="Rzędna spodu warstwy">
                            Z<br></br>[m.p.p.t]
                        </th>
                        <th title="Wysokość warstwy">
                            H<br></br>[m]
                        </th>
                        <th title="Opór pobocznicy">
                            q<sub>s,k</sub>
                            <br></br>[kPa]
                        </th>
                        <th title="Średnica otworu">
                            d<br></br>[mm]
                        </th>
                        <th title="Poszerzenie podstawy">
                            a<br></br>[-]
                        </th>
                        <th title="Poszerzenie podstawy">
                            a<sub>cpt</sub>
                            <br></br>[mm]
                        </th>
                        <th title="Wsp. modelu">
                            &#947;<sub>s,t</sub>
                            <br></br>[-]
                        </th>
                        <th title="Wsp. bezpieczeństwa">
                            &#958;<br></br>[-]
                        </th>
                        <th title="Wsp. modelu">
                            &#951;<sub>M</sub>
                            <br></br>[-]
                        </th>
                        <th title="Długość buławy">
                            L<sub>b</sub>
                            <br></br>[m]
                        </th>
                        <th title="Nośność obliczeniowa mikropala">
                            R<sub>t,d</sub>
                            <br></br>[kN]
                        </th>
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    {dataToPaginate.map((element, index) => {
                        return (
                            <tr className={`${styles.tr}`} key={index}>
                                <td>
                                    {paginationHelpers.countingItemsPerPage +
                                        index +
                                        1}
                                </td>
                                <td>{round(element.name, 2)}</td>
                                <td>{round(element.height, 2)}</td>
                                <td>{round(element.absHeight, 2)}</td>
                                <td>{round(element.qsk, 2)}</td>
                                <td>{round(element.diameter, 2)}</td>
                                <td>
                                    {element.cpt
                                        ? '-'
                                        : round(element.enlargeDiameter, 2)}
                                </td>
                                <td>
                                    {element.cpt
                                        ? round(element.enlargeDiameter, 2)
                                        : '-'}
                                </td>
                                <td>{round(element.yt, 2)}</td>
                                <td>{round(element.ksi, 2)}</td>
                                <td>
                                    {isNumber(element.modelFactor)
                                        ? round(element.modelFactor, 2)
                                        : '-'}
                                </td>
                                <td>{round(element.bondLength, 2)}</td>
                                <td>{round(element.layerCapacity, 2)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            {
                paginationHelpers.totalPages > 1 &&
                <Pagination
                    currentPage={pagination.currentPage}
                    itemsPerPage={pagination.itemsPerPage}
                    totalItems={paginationHelpers.allItemsLength}
                    nextPage={paginationHelpers.nextPage}
                />                
            }
        </Fragment>
    );
};

export default withPagination({
    component: MicropileCapacityTable,
    returnAllCollection: true,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 25,
    },
});
