import SoilProfile from "./SoilProfile"


const CptCapacityChart = ({ 
    dataToChart
 }) => { 

    return (
        <SoilProfile 
            {...dataToChart}
        />
    )
}

export default CptCapacityChart