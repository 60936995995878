import React, { useState } from 'react';
import { pdf, Document, Font, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { saveAs } from 'file-saver'
import { Button } from '../../../components';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../../assets/Logo.png';
import TablePDF from '../../../components/TablePDF';
import { roundNumber } from '../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

Font.register({
    family: "Roboto",
    fonts: [
        {
            src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"
        },
        {
            src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
            fontWeight: 400
        },
        {
            src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
            fontWeight: 500
        },
        {
            src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
            fontWeight: 700
        }
    ]
    
  });

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        fontSize: 10,
        padding: 50,
    },
    title: {
        fontSize: 16,
        fontWeight: 700,
        marginBottom: 8,
    },
    subtitle: {
        fontSize: 10,
        marginBottom: 5,
    },
    side: {
        fontSize: 12,
        fontWeight: 500,
        marginBottom: 5,
        textDecoration: 'underline',
    },
    content: {
        marginBottom: 10,
    },
    logoContainer: {
        width: '25%',
    },
    row: {   
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    rowBody: {   
        marginTop: 55,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    row3: {
        marginTop: 15,
        textAlign: 'right',
        alignItems : 'flex-end',
    },
    rowRight: {   
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '50%',
        marginBottom: 5,
    },
    row4: {
        marginTop: 25,
    },
    bold: {
        fontWeight: 700,
    }
});

const Invoice = ({
        invoice
}) => {
    const { invoiceDataSeller, invoiceData, invoiceType, invoiceNumber, products=[], transactionDate } = invoice;

    const [reduceTheSameproductsAddQuantity, setReduceTheSameproductsAddQuantity] = useState([]);

    useDeepCompareEffect(() => {
        setReduceTheSameproductsAddQuantity(products.reduce((acc, product) => {
            const existingProduct = acc.length > 0 ? acc.find(p => p.netPrice === product.netPrice && p.plan === product.plan) : false;
            if (existingProduct) {
                existingProduct.quantity += 1;
            } else {
                acc.push({ ...product, quantity: 1 });
            }
            return acc;
        },[]))
    }, [
        products
    ])

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View>
                    <View style={styles.row}>
                        <View style={styles.logoContainer}>
                            <Image src={Logo} style={styles.logo} />
                        </View>
                        <View>
                            <Text style={styles.title}>Faktura nr {invoiceNumber}</Text>
                            <Text style={styles.subtitle}>Data wystawienia: {new Date(invoiceData.createdAt).toLocaleDateString()}</Text>
                            <Text style={styles.subtitle}>Data sprzedaży: {new Date(transactionDate).toLocaleDateString()}</Text>
                            <Text style={styles.subtitle}>Status: Zapłacona</Text>
                        </View>
                    </View>
                    <View>
                        <View style={styles.rowBody}>
                            <View style={styles.content}>
                                <Text style={styles.side}>Sprzedawca</Text>
                                <Text style={styles.subtitle}>Imię: {invoiceDataSeller.firstName}</Text>
                                <Text style={styles.subtitle}>Nazwisko: {invoiceDataSeller.lastName}</Text>
                                <Text style={styles.subtitle}>Adres: {`ul. ${invoiceDataSeller.street} ${invoiceDataSeller.placeNumber}, ${invoiceDataSeller.postcode} ${invoiceDataSeller.city}`}</Text>
                                <Text style={styles.subtitle}>Email: {invoiceDataSeller.email}</Text>
                            </View>
                            {
                                invoiceType === 2 
                                ?
                                <View style={styles.content}>
                                    <Text style={styles.side}>Nabywca</Text>
                                    <Text style={styles.subtitle}>Imię: {invoiceData.firstName}</Text>
                                    <Text style={styles.subtitle}>Nazwisko: {invoiceData.lastName}</Text>
                                    <Text style={styles.subtitle}>Adres: {`ul. ${invoiceData.street} ${invoiceData.placeNumber}, ${invoiceData.postcode} ${invoiceData.city}`}</Text>
                                    <Text style={styles.subtitle}>Email: {invoiceData.email}</Text>
                                </View>
                                :
                                <View style={styles.content}>
                                    <Text style={styles.side}>Nabywca</Text>
                                    <Text style={styles.subtitle}>Nazwa firmy: {invoiceData.companyName}</Text>
                                    <Text style={styles.subtitle}>NIP: {invoiceData.nip}</Text>
                                    <Text style={styles.subtitle}>Adres: {`ul. ${invoiceData.street} ${invoiceData.placeNumber}, ${invoiceData.postcode} ${invoiceData.city}`}</Text>
                                    <Text style={styles.subtitle}>Email: {invoiceData.email}</Text>
                                </View>
                            }
                        </View>
                        <TablePDF>
                            <TablePDF.Header>
                                <TablePDF.HeaderCell width={30}>Nazwa usługi</TablePDF.HeaderCell>
                                <TablePDF.HeaderCell width={10}>Ilość</TablePDF.HeaderCell>
                                <TablePDF.HeaderCell width={15}>Cena netto</TablePDF.HeaderCell>
                                <TablePDF.HeaderCell width={15}>Wartość netto</TablePDF.HeaderCell>
                                <TablePDF.HeaderCell width={10}>VAT</TablePDF.HeaderCell>
                                <TablePDF.HeaderCell width={20}>Wartość brutto</TablePDF.HeaderCell>
                                
                            </TablePDF.Header>
                            <TablePDF.Body>
                                {
                                    reduceTheSameproductsAddQuantity.filter(ele => ele.netPrice > 0).map((product, index) => (
                                        <TablePDF.Row key={index}>
                                            <TablePDF.Cell width={30}>
                                                {product.plan === 1 ? 'Subskrypcja miesięczna' : 'Subskrypcja roczna'}
                                            </TablePDF.Cell>
                                            <TablePDF.Cell width={10}>{product.quantity}</TablePDF.Cell>
                                            <TablePDF.Cell width={15}>{product.netPrice}</TablePDF.Cell>
                                            <TablePDF.Cell width={15}>{roundNumber(product.netPrice * product.quantity,2)}</TablePDF.Cell>
                                            <TablePDF.Cell width={10}>{product.vatRate > 0 ? `${product.vatRate}%` : '-'}</TablePDF.Cell>
                                            <TablePDF.Cell width={20}>{roundNumber(product.grossPrice * product.quantity,2)}</TablePDF.Cell>
                                        </TablePDF.Row>
                                    ))
                                }
                            </TablePDF.Body>
                        </TablePDF>
                        <View style={styles.row3}>
                            <View style={styles.rowRight}>
                                <Text>Wartość netto</Text>
                                <Text>{roundNumber(products.reduce((acc, product) => acc + product.netPrice, 0),2).toFixed(2)} zł</Text>
                            </View>
                            <View style={styles.rowRight}>
                                <Text>VAT</Text>
                                <Text>{roundNumber(products.reduce((acc, product) => acc + product.vat, 0),2).toFixed(2)} zł</Text>
                            </View>
                            <View style={styles.rowRight}>
                                <Text style={styles.bold}>Razem do zapłaty</Text>
                                <Text style={styles.bold}>{roundNumber(products.reduce((acc, product) => acc + product.grossPrice, 0),2).toFixed(2)} zł</Text>
                            </View>
                        </View>
                        <View style={styles.row4}>
                            <Text>Podatnik zwolniony z VAT na podstawie art. 113 ust. 1 ustawy o podatku od towarów i usług.</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};



const DownloadButton = ({ invoice }) => {
  const handleDownload = async () => {
    const blob = await pdf(<Invoice invoice={invoice}/>).toBlob()
    saveAs(blob, `${invoice.invoiceNumber}.pdf`)
  }

  return <Button
            name="downloadInvoice"
            css={`default-button-danger-style`}
            icon={faDownload}
            title="Pobierz"
            altTitle="Pobierz"
            onClick={handleDownload}
        />
}

export default DownloadButton;