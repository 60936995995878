import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './index.module.css';
import Loading from '../Loading';

const Button = ({
    title = '',
    altTitle = '',
    type = 'button',

    variant = '',
    css = '',
    loadingCss = '',
    onClick,
    children,
    icon,
    name,
    active = false,
    loading = false,

    ...rest
}) => {

    return (
        <button
            disabled={loading}
            title={altTitle}
            className={`${styles.default} ${
                variant === 'info'
                    ? styles.info
                    : variant === 'danger'
                    ? styles.danger
                    : ''
            } ${active ? 'active' : ''} ${css} `}
            onClick={onClick}
            name={name}
            type={type}
            {...rest}
            >
            {
                <div className={styles.flex}>
                    {loading && (
                        <Loading
                            css={`
                                ${styles.loading} ${loadingCss}
                            `}
                            wrapperCss={styles.wrapperLoading}
                        />
                    )}
                    {icon && (
                        <FontAwesomeIcon
                            className={title ? styles.icon : styles.centerIcon}
                            icon={icon}
                        />
                    )}
                    <span>{title}</span>
                    {children}
                </div>
            }
        </button>
    );
};

export default Button;
