import { isNumber } from "../../../../utils";

const ksi3 = [1.40, 1.35, 1.33, 1.31, 1.29, 1.28, 1.27, 1.26, 1.26, 1.25];
const ksi4 = [1.40, 1.27, 1.23, 1.20, 1.15, 1.14, 1.12, 1.11, 1.09, 1.08];

const ec7ksi = (type, profilesNumber) => {

    const typeCapacity = [1,2].includes(type);
    if(isNumber(profilesNumber) && typeCapacity){
        if(profilesNumber <= 10){
            return type === 1 ? ksi3[profilesNumber - 1] : ksi4[profilesNumber - 1]
        } else if(profilesNumber > 10){
            return type === 1 ? ksi3[9] : ksi4[9]
        } else {
            return ''
        }
    } else {
        return ''
    }
}

export default ec7ksi