import { AuthFetch } from "../../../routes";

const CalculationListHelpers = ({
    projectId,
    setAlert,
    fetch,
    updateAllCalculationsWitoutProfile,
    updateCalculationsInProfile,
}) => {
    const onRemoveCalculation = (id, type) => {
        AuthFetch({
            url: `${fetch.delete.url}/${id}`,
            method: fetch.delete.method, 
            body: {
                calculationType: type,
                projectId: projectId
            } 
        }).then(response => {
            if(response.success){
                updateAllCalculationsWitoutProfile(state => ([
                    ...state.filter(ele => ele.id !== response.responseData.id)
                ]));
                updateCalculationsInProfile(state => ([
                    ...state.map(ele => ({ ...ele, calculations: ele.calculations.filter(ele => ele.id !== response.responseData.id ) }))
                ]));
                setAlert({ error: false, message: 'Obliczenia zostały usunięte', hideTime: 2000 });                  
            } else {
                setAlert({ error: true, message: 'Błąd podczas usuwania obliczeń' });
            }
        })
    };

    const calculations = {
        state: {},
        onRemoveCalculation: onRemoveCalculation
    }
    return {
        calculations,
    }
}

export default CalculationListHelpers