import React, { Fragment, useCallback, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Checkbox, SectionTitle, Modal } from '../../../../components';
import ReportWrapper from './ReportWrapper';
import ReactToPrint from 'react-to-print';
import styles from './index.module.css'; 

const PrintResults = ({ state, onChange, open, onHide }) => {
    const [loading, setLoading] = useState(false);
    const pdfComponentRef = useRef();

    return ( 
        <Fragment>            
            <Modal title="Raport z obliczeń" open={open} onHide={onHide} hideButton={false}>
                <Row className={styles.row}>
                    <Col xs={12} sm={6}>
                        <Checkbox name="allItems" label="Zaznacz wszystko" value={state.reportItem.allItems} onChange={onChange}/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="firstPage" label="Strona tytułowa" value={state.reportItem.firstPage} onChange={onChange}/>
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <SectionTitle title="Dane wejściowe"/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="calculationInput" label="Parametry geometryczne przekroju i zbrojenia" value={state.reportItem.calculationInput} onChange={onChange}/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="projectInfo" label="Parametry materiałowe i siły wewnętrzne" value={state.reportItem.projectInfo} onChange={onChange}/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="modelFactors" label="Parametry obliczeniowe modelu" value={state.reportItem.modelFactors} onChange={onChange}/>
                    </Col>
                    {
                        (state.calculationInput.secondOrder.value || state.calculationInput.ea.value) &&
                        <Col xs={12} sm={6}>
                        <Checkbox name="secondOrderModel" label="Imperfecje geometryczne i efekty II-go rzędu" value={state.reportItem.secondOrderModel} onChange={onChange}/>
                    </Col>
                    }
                </Row>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <SectionTitle title="Wyniki obliczeń"/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="summary" label="Wyniki zginania z siłą osiową" value={state.reportItem.summary} onChange={onChange}/>
                    </Col>
                    {
                        state.results.initModelValues.memberType !== 'Płyta' &&
                        <Col xs={12} sm={6}>
                            <Checkbox name="shearSummary" label="Wyniki ścinania" value={state.reportItem.shearSummary} onChange={onChange}/>
                        </Col>                        
                    }

                    <Col xs={12} sm={6}>
                        <Checkbox name="capacityChart" label="Wykres nośności przekroju" value={state.reportItem.capacityChart} onChange={(e) => {
                            if(!state.reportItem.capacityChart){
                                setLoading(true)
                            }
                            onChange(e);
                        }}/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="capacityDetails" label="Szczegółowe wyniki nośności przekroju" value={state.reportItem.capacityDetails} onChange={onChange}/>
                    </Col>
                    {
                        (state.calculationInput.secondOrder.value || state.calculationInput.ea.value) &&
                        <Col xs={12} sm={6}>
                            <Checkbox name="secondOrder" label="Imperfecje geometryczne i efekty II-go rzędu" value={state.reportItem.secondOrder} onChange={onChange}/>
                        </Col>
                    }
                </Row>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <SectionTitle title="Załączniki graficzne"/>
                    </Col>

                    <Col xs={12} sm={6}>
                        <Checkbox name="sectionChart" label="Przekrój elementu" value={state.reportItem.sectionChart} onChange={onChange}/>
                    </Col>
                </Row>   

                <Row className={styles.row}>
                    <Col xs={12}>
                        <ReactToPrint 
                            trigger = {() => <Button css={`${styles.button} default-button-style`} altTitle="Drukuj" title="Drukuj raport" type="button" loading={loading}/>}
                            content={() => pdfComponentRef.current}
                        /> 
                    </Col>
                </Row> 
                
                <ReportWrapper
                    setLoading={useCallback((e) => setLoading(e),[])}
                    pdfState={state}
                    ref={pdfComponentRef} 
                />
            </Modal>
        </Fragment>
    )
}

export default PrintResults