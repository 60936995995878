import React, { createContext } from 'react';
import { 
    ProjectTitleService,
    GlobalAlertService, 
    GlobalLoadingService,
    SoilProfileService,
    SoilTableService,
    MicropilePropertiesService,
    ZwgLevelsService,
    SaftyFactorsService,
    ResultsService,
    SaveSoilService,
    EditSoilService,
    SaveProjectService,
    EditProjectService,
    SubmitCalculationsService,
    PrintResultsService,
    FileNameToSave,
    NewProjectService,
    LoadAndRemoveProjectService
} from '../../services/Micropiles';  
  
const micropilesContext = createContext({});

const MicropilesProvider = ({ children }) => {
    
    const { projectTitle }              = ProjectTitleService(); 
 
    const { globalAlerts }              = GlobalAlertService();
    const { globalLoading }             = GlobalLoadingService(); 
    const { fileNameToSave }            = FileNameToSave();
    const soilProfileFileNameToSave     = FileNameToSave();

    const { results } = ResultsService();

    const { zwgLevels }                 = ZwgLevelsService({
        updateResultsState: results.updateState
    }); 
 
    const { soilTable }                 = SoilTableService({
        updateProfileProperties: zwgLevels.updateState,
        updateResultsState: results.updateState
    }); 

    const { editSoilProfile } = EditSoilService({
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad, 
        updateSoilTable: soilTable.onLoad, 
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,

        fetch: {
            getProfiles: {
                url: '/api/soils/parameters/profiles',
                method: 'GET'
            },
            getProjectsName: {
                url: '/api/projects/list/names',
                method: 'GET'
            },
            removeProfile: {
                url: '/api/soils/parameters/edit',
                method: 'DELETE'
            },
            loadProfile: {
                url: '/api/soils/parameters/edit',
                method: 'GET'
            }
        }
    });

    const { micropileProperties }   = MicropilePropertiesService({
        profileParameters: zwgLevels.state,
        maxHeight: Math.max( ...soilTable.state.map(ele => ele.height.value )),

        updateResultsState: results.updateState
    });
    const { soilProfile }           = SoilProfileService({
        soilTableState: soilTable.state,
    });

    const { saftyFactors } = SaftyFactorsService({
        micropileType: micropileProperties.state.micropileType,
        axisLoad: micropileProperties.state.axisLoad,
        updateRods: micropileProperties.updateRods,
        updateMicropileProperties: micropileProperties.updateState,
        updateResultsState: results.updateState
    });



    const { submitCalculation } = SubmitCalculationsService({
        soilTable: { state: soilTable.state, updateState: soilTable.updateState },
        micropileProperties: { state: micropileProperties.state, updateState: micropileProperties.updateState },
        zwgLevels: { state: zwgLevels.state, updateState: zwgLevels.updateState },
        saftyFactors: { state: saftyFactors.state, updateState: saftyFactors.updateState },
        results: { updateState: results.updateState },
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading
    });

    const { loadAndRemoveProject } = LoadAndRemoveProjectService({
        profileStateChanged: editSoilProfile.state.stateChanged,
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        urlOnLoad: '/api/projects/list/names',
        urlOnLoadCalculations: '/api/micropilePull/projects',
        urlOnRemove: '/api/micropilePull/calculations/'
    });

    const { editProject } = EditProjectService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,

        loadMicropileProperties: micropileProperties.loadProject,
        loadSoilTable: soilTable.onLoad,
        loadZwgLevels: zwgLevels.loadProject, 
        loadSaftyFactors: saftyFactors.loadProject,
        loadTitle: projectTitle.loadProject,
        loadResults: results.loadProject,

        onLoadFileName: fileNameToSave.onLoad,
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad,
        
        onSetMainProjectName: loadAndRemoveProject.onSetMainProjectName,
        onSetSoilProfileMainProjectName: editSoilProfile.onSetMainProjectName,

        projectId: loadAndRemoveProject.state.currentProjectId
    });

    const { saveSoilProfile } = SaveSoilService({
        fileNameToSave: soilProfileFileNameToSave.fileNameToSave.fileName,
        initMainProjectName: loadAndRemoveProject.state.mainProjectName,
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad, 
        onChangeState: editSoilProfile.onStateChange,
        model: {
            soilsCollection: soilTable.state
        },
        fetch: {
            projectList: {
                url: '/api/projects/list/names',
                method: 'GET'
            },
            saveSoil: {
                url: '/api/soils/parameters/edit',
                method: 'POST'
            },
            editSoil: {
                url: '/api/soils/parameters/edit',
                method: 'PUT'
            },
        },
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading
    });

    const { saveProject } = SaveProjectService({
        fileNameToSave: fileNameToSave.fileName,
        onLoadProjectName: fileNameToSave.onLoad, 
        projectTitle: projectTitle.state,

        currentProjectId: editProject.currentProjectId,
        currentProfileId: editProject.currentProfileId,
        currentUserEmail: loadAndRemoveProject.state.currentUser,
        loadProjectState: editProject.loadProjectState,
        
        model: {
            soilsCollection: soilTable.stateToSave, 
            micropileParameters: micropileProperties.stateToSave, 
            zwgLevels: zwgLevels.state,
            saftyFactors: saftyFactors.state,
        },
        results: {
            isResultsActive: results.isResultsActive,
            isResultsActual: results.state.isResultsActual,
            results: results.stateToSave,
        }, 
        fetch: {
            save: {
                url: '/api/micropilePull/calculations/saveProject',
                method: 'POST' 
            },
            overwrite: {
                url: '/api/micropilePull/calculations/saveProject',
                method: 'PUT'
            },
            create: {
                url: '/api/projects/create',
                method: 'POST'
            },
            projectList: {
                url: '/api/projects/list',
                method: 'GET'
            }
        },
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        onChangeState: loadAndRemoveProject.onStateChange,
        onProjectStateChange: saveSoilProfile.onProjectStateChange,
        onProfileStateChange: saveSoilProfile.onProfileStateChange,
        profileStateChange: saveSoilProfile.profileStateChange,
        removeProfileStateChange: editSoilProfile.state.stateChanged,

        saveProjectToLocalStorage: (str) => localStorage.setItem('micropiles', str)
    });

    const { printResults } = PrintResultsService({
        projectTitle: projectTitle.state,
        results: {
            ...results.state,
        }
    });
    const { newProject } = NewProjectService({
        onLoadProjectName: fileNameToSave.onLoad, 
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad, 
        initProject: [
            () => localStorage.removeItem('micropiles'),
            globalAlerts.initState,
            globalLoading.initState,  
            projectTitle.initState,                      
            micropileProperties.initState,
            zwgLevels.initState,
            saftyFactors.initState,
            soilTable.initState,
            results.initState,
            printResults.initState
        ]
    });
    return (
        <micropilesContext.Provider  
            value={{ 
                projectTitle,
                globalAlerts,
                globalLoading,
                soilTable,
                soilProfile,
                micropileProperties,
                zwgLevels,
                saftyFactors,
                results,
                saveSoilProfile,
                editSoilProfile,
                saveProject,
                editProject,
                loadAndRemoveProject,
                printResults,
                fileNameToSave,
                submitCalculation,
                newProject
            }}
        >
            {children}
        </micropilesContext.Provider>
    )
}

export { micropilesContext }
export default MicropilesProvider