import { useContext, useEffect } from 'react';
import config from '../../../config/config';
import { sheetPileCptContext } from '../../../context/SheetPileCpt';
import PrintSheetPileCptResults from './PrintResults';
import DefaultNavigation from '../DefaultNavigation';

const SheetPileCptNavigation = ({ report }) => {
    const SheetPileCptContext = useContext(sheetPileCptContext);
    const { routes } = config;

    useEffect(() => {
        document.title = `Ścianka szczelna CPT - ${SheetPileCptContext.fileNameToSave.fileName.value}`;

        return () => {
            document.title = '';
        };
    }, [SheetPileCptContext.fileNameToSave.fileName.value]); 
    return (
        <DefaultNavigation
            title="Nośność ścianki szczelnej CPT"
            subtitle="Nośność pionowa grodzic na podstawie metody francuskiego Centralnego Laboratorium Dróg i Mostów w oparciu o wyniki sondowań CPT"
            modelRoute={routes.dashboard.sheetPileCptModel}
            resultsRoute={routes.dashboard.sheetPileCptResults}
            context={SheetPileCptContext}
        >
            {SheetPileCptContext.results.state.isResultsActual && SheetPileCptContext.results.isResultsActive && (
                <PrintSheetPileCptResults
                    report={report}
                    isResultsActual={
                        SheetPileCptContext.results.state.isResultsActual
                    }
                    printResults={SheetPileCptContext.printResults}
                />
                )
            } 
        </DefaultNavigation>
    );
};

export default SheetPileCptNavigation;
