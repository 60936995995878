import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import styles from './index.module.css';

const InputFile = ({
    name,
    title,
    onChange,
    required,
    wrapperCss='',
    style = {},
    children,
    multiple = false,
    label = 'Dodaj plik',
    innerRef=null,
    ...rest
}) => {
    return (
        <Fragment>
            <div className={`${styles.wrapper} ${wrapperCss}`}>
                {title && (
                    <div className={styles.titleWrapper}>
                        <label className={styles.title}>{title}</label>
                        {children && (
                            <Fragment>
                                <FontAwesomeIcon
                                    className={styles.infoIcon}
                                    icon={faQuestionCircle}
                                />
                                <div className={`${styles.info} defaultBox`}>
                                    {children}
                                </div>
                            </Fragment>
                        )}
                    </div>
                )}

                <div className={`${styles.group}`}>
                    <input
                        className={styles.inputField}
                        id={name}
                        type="file"
                        name={name}
                        onChange={onChange}
                        required={required}
                        multiple={multiple}
                        ref={innerRef}
                        {...rest}
                    />
                    <label
                        className={`${styles.labelInput} default-button-style`}
                        htmlFor={name}>
                        <FontAwesomeIcon icon={faUpload} />
                        <span>{label}</span>
                    </label>
                </div>
            </div>
        </Fragment>
    );
};

export default React.memo(InputFile);
