import React, { Fragment, useContext, useEffect } from 'react';
import CalculationAuthor from './CalculationAuthor';
import Statistics from './Statistics';
import Subscription from './Subscription';
import { Col, Row } from 'react-bootstrap';
import styles from './index.module.css';
import { homeContext } from '../../context/Home';
import ChangePassword from './ChangePassword';
import { Loading, MainAlertAndLoading } from '../../components';
import ReportLogo from './ReportLogo';
import NewUsers from './NewUsers';
import { isAdminRole, isUserRole } from '../../utils';
import PaymentsHistory from './PaymentsHistory';
import { useOutletContext } from 'react-router-dom';

const Home = () => {
    const {
        renewSubscription = {},
        subscription = {},
        stats = {},
        changePassword = {},
        globalAlerts = {},
        globalLoading = {},
        calculationAuthor = {},
        reportLogo = {},
        newUsers = {},
        payments = {},
    } = useContext(homeContext);

    const authObject = useOutletContext();

    useEffect(() => {
        document.title = `Moje konto`;

        return () => {
            document.title = '';
        };
    }, []);

    return (
        <Fragment>
            <Row className={styles.row}>
                <Statistics stats={stats.state.stats} />
            </Row>
            <Fragment>
                <Row className={styles.row}>
                    <Col xs={12} sm={6} className={styles.colLeft}>
                        <CalculationAuthor {...calculationAuthor} />
                    </Col>
                    <Col xs={12} sm={6} className={styles.colRight}>
                        <ReportLogo {...reportLogo} />
                    </Col>
                </Row>
                {globalLoading.state.updateLogo && (
                    <div className={styles.loadingWrapper}>
                        <Loading text="Zmiana loga do raportów pdf..." />
                    </div>
                )}
                {globalLoading.state.updateReportDetails && (
                    <div className={styles.loadingWrapper}>
                        <Loading text="Zmiana danych do raportów pdf..." />
                    </div>
                )}
            </Fragment>
            <Fragment>
                <Row className={styles.row}>
                    {isUserRole(authObject.permission) && (
                        <Fragment>
                            <Col xs={12} sm={6} className={styles.colLeft}>
                                <Subscription
                                    invoiceType={payments.state.invoiceType}
                                    invoiceState={payments.state.invoiceState}
                                    saveFormData={payments.state.saveFormData}
                                    onChangeInvoiceType={payments.onChangeInvoiceType}
                                    onChangeInvoice={payments.onChangeInvoiceState}
                                    onBlurInvoice={payments.onBlurInvoiceState}
                                    onChangeDataCheckbox={payments.onChangeDataCheckbox}

                                    subsObj={authObject.subscription}
                                    {...subscription}
                                />
                            </Col>

                            <Col xs={12} sm={6} className={styles.colRight}>
                                <ChangePassword {...changePassword} />
                            </Col>
                            {globalLoading.state.saveNewPassword && (
                                <div className={styles.loadingWrapper}>
                                    <Loading text="Zmiana hasła..." />
                                </div>
                            )}
                            {globalLoading.state.startPayment && (
                                <div className={styles.loadingWrapper}>
                                    <Loading text="Przekierowuję do płatności..." />
                                </div>
                            )}
                        </Fragment>
                    )}
                </Row>
            </Fragment>
            {(isUserRole(authObject.permission) || isAdminRole(authObject.permission)) && (
                <Fragment>
                    <Row className={styles.row}>
                        <Col xs={12} className={styles.col}>
                            <NewUsers
                                invoiceType={payments.state.invoiceType}
                                invoiceState={payments.state.invoiceState}
                                saveFormData={payments.state.saveFormData}
                                onChangeInvoiceType={payments.onChangeInvoiceType}
                                onChangeInvoice={payments.onChangeInvoiceState}
                                onBlurInvoice={payments.onBlurInvoiceState}
                                onChangeDataCheckbox={payments.onChangeDataCheckbox}

                                renewSubscription={renewSubscription}
                                {...newUsers}
                                dataToPaginate={newUsers.state.currentUsers}
                            />
                        </Col>
                    </Row>
                    {globalLoading.state.renewUsersSubscriptions && (
                        <div className={styles.loadingWrapper}>
                            <Loading text="Przedłużanie subskrypcji..." />
                        </div>
                    )}
                    {globalLoading.state.deletingUser && (
                        <div className={styles.loadingWrapper}>
                            <Loading text="Usuwanie użytkowników..." />
                        </div>
                    )}
                    {globalLoading.state.resettingPassword && (
                        <div className={styles.loadingWrapper}>
                            <Loading text="Resetowanie haseł..." />
                        </div>
                    )}
                    {globalLoading.state.editUserSave && (
                        <div className={styles.loadingWrapper}>
                            <Loading text="Zapisuję dane..." />
                        </div>
                    )}
                </Fragment>
            )}
            {(isUserRole(authObject.permission) || isAdminRole(authObject.permission)) && (
                <Fragment>
                    <Row className={styles.row}>
                        <Col xs={12} className={styles.col}>
                            <PaymentsHistory
                                dataToPaginate={payments.state.paymentsList}
                                endDate={payments.state.endDate}
                                startDate={payments.state.startDate}
                                onChangeDateRange={payments.onChangeDateRange}
                                onClearDateInput={payments.onClearDateInput}
                            />
                        </Col>
                    </Row>
                </Fragment>
            )}
            <MainAlertAndLoading
                alerts={globalAlerts.state.alerts}
                updateAlerts={globalAlerts.updateState}
            />
            {globalLoading.state.userSubscription && (
                <div className={styles.loadingWrapper}>
                    <Loading text="Oczekiwanie na płatność..." />
                </div>
            )}
        </Fragment>
    );
};

export default Home;
