import { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { SectionTitle } from "../../../../../components";
import { isNumber, roundNumber } from "../../../../../utils";
import styles from './index.module.css';

const Summary = ({ results, pileShape, xlim, a2, d, memberType }) => {
    const result = results.sgnResult ? results.sgnResult : results.result;
    const lbd = results.sgnLbd ? results.sgnLbd : results.lbd;

    return (
        <Fragment>
            <SectionTitle
                title="Zbrojenie główne (SGN)"
            />
            <Row className={styles.row}>
                <Col xs={12} className={styles.col}>
                    {
                        pileShape === 1
                        ?
                            <Fragment>
                                {result.reinfAsNotRequired && <b><u>Zbrojenie obliczeniowe nie jest wymagane</u></b>}
                                <b><p>Powierzchnia zbrojenia [A<sub>s</sub>] - {roundNumber(result.as,2)} cm<sup>2</sup></p></b>
                            </Fragment>
                        :
                            <Fragment>
                                {result.reinfAsNotRequired && <b><u>Zbrojenie obliczeniowe nie jest wymagane</u></b>}
                                {
                                    roundNumber(result.x,2) > roundNumber(xlim,2) && (memberType === 'Belka' || memberType === 'Płyta') &&
                                    <Fragment>
                                        <div className={styles.phase3}>
                                            <p>UWAGA:</p>
                                            <p>Wysokość strefy ściskanej jest większa od granicznej wysokości strefy ściskanej. Grozi kruchym zniszczeniem betonu w strefie ściskanej (niesygnalizowane zniszczenie). Zbrojenie rozciągane nie osiąga granicy plastyczności.</p>                                            
                                        </div>

                                    </Fragment>
                                }
                                <b><p>Zbrojenie górne ({ result.x > a2 
                                    ? 
                                    results.defaultResults.negativeMoment 
                                        ? result.x > d ? 'ściskane' : 'rozciągane' 
                                        : 'ściskane' 
                                    : 
                                    'rozciągane'}) [A<sub>s2</sub>] - {roundNumber(result.as2,2)} cm<sup>2</sup></p></b>
                                <b><p>Zbrojenie dolne ({ result.x > a2 
                                ? 
                                results.defaultResults.negativeMoment 
                                    ? 'ściskane' 
                                    : result.x > d ? 'ściskane' : 'rozciągane' 
                                : 
                                'rozciągane'}) [A<sub>s1</sub>] - {roundNumber(result.as1,2)} cm<sup>2</sup></p></b>
                            </Fragment>       
                    }
                </Col>
                <Col xs={12} className={styles.col}>
                    <p>Zbrojenie minimalne [A<sub>s,min</sub>] - {roundNumber(result.minAs,2)} cm<sup>2</sup></p>
                </Col>
                {
                    !result.reinfAsNotRequired &&
                    <Fragment>
                        <Col xs={12} className={styles.col}>
                            <p>Wysokość strefy ściskanej [x] - {roundNumber(result.x,2)} mm</p>
                        </Col>
                        <Col xs={12} className={styles.col}>
                            <p>Graniczna wysokość strefy ściskanej [x<sub>lim</sub>] - {roundNumber(xlim,2)} mm</p>
                        </Col>
                        <Col xs={12} className={styles.col}>
                            <p>Wypadkowa naprężeń w betonie [F<sub>c</sub>] - {roundNumber(result.fc,2)} kN</p>
                        </Col>                        
                    </Fragment>
                }
                <Col xs={12} className={styles.col}>
                    <p>Obliczeniowy moment zginający [M<sub>Ed</sub>] - {roundNumber(results.defaultResults.med,2)} kNm</p>
                </Col>
                {
                    memberType === 'Słup' &&
                    <Col xs={12} className={styles.col}>
                        <p>Obliczeniowa siła osiowa [N<sub>Ed</sub>] - {roundNumber(results.defaultResults.ned,2)} kN</p>
                    </Col>
                }
                {
                    results.defaultResults.lbdCalculation &&
                    <Fragment>
                        {
                            results.lbd && isNumber(lbd.tension) &&
                            <Col xs={12} className={styles.col}>
                                <p>Długość zakotwienia zbrojenia rozciąganego [l<sub>bd+</sub>] - {roundNumber(results.lbd.tension,0)} mm</p>
                            </Col>
                        }
                        {
                            results.lbd && isNumber(lbd.compression) &&
                            <Col xs={12} className={styles.col}>
                                <p>Długość zakotwienia zbrojenia ściskanego [l<sub>bd-</sub>] - {roundNumber(results.lbd.compression,0)} mm</p>
                            </Col>
                        }
                    </Fragment>
                }
            </Row>
        </Fragment>
    )
}

export default Summary;