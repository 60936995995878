import { useEffect, useState } from 'react';
import { AdminFetch } from '../../../routes';
import { isAdminRole } from '../../../utils';

const Statistics = ({ setAlert, fetch, role }) => {
    const [stats, setStats] = useState({});

    useEffect(() => {
        if (isAdminRole(role)) {
            AdminFetch({
                url: fetch.statistics.url,
                method: fetch.statistics.method,
            })
            .then((response) => {
                if (response.success) {
                    setStats(response.responseData);
                } else {
                    setAlert({
                        error: true,
                        message: 'Błąd podczas wczytywania statystyk',
                    });
                }                            
            })
            .catch((e) => {
                setAlert({
                    error: true,
                    message: 'Błąd podczas wczytywania statystyk',
                });
            });
        }
    }, [fetch.statistics.url, fetch.statistics.method, role, setAlert]);

    const statistics = {
        state: {
            stats,
        },
    };

    return {
        statistics,
    };
};

export default Statistics;
