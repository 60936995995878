import styles from './index.module.css';

const ReportFirstPage = ({
    title = '',
    subtitle = '',
    logo = '',
    author = '',
    contact = '',
    titleClass = '',
}) => { 
    return (
        <div className={styles.firstPage}>
            {logo && <img alt="Logo raportów pdf" className={styles.logo} src={logo} />}

            <div className={`${titleClass} ${styles.titleWrapper}`}>
                <h1 className={styles.h1}>{title}</h1>
                {subtitle && <h2 className={styles.h2}>{subtitle}</h2>}
            </div>
            <div className={styles.authors}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th className={styles.mm9}>
                                Autor obliczeń: 
                            </th>
                            <th className={styles.mm9}>
                                Kontakt: 
                            </th>
                            <th className={styles.mm3}>
                                Podpis:
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {author}
                            </td>
                            <td>
                                {contact}
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>                
            </div>
        </div>
    );
};

export default ReportFirstPage;
