import React, { Fragment, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import SoilTable from './model/SoilTable';
import ProjectTitle from './model/ProjectTitle';
import SoilProfile from './model/SoilProfile';
import CalculationSettings from './model/CalculationSettings';
import PileParameters from './model/PileParameters';
import SaftyFactors from './model/SaftyFactors';
import FoundationParameters from './model/FoundationParameters';
import FoundationSchema from './model/FoundationSchema';
import SubmitCalculations from '../SubmitCalculations';
import PileLoads from './model/PileLoads';
import { MainAlertAndLoading } from '../../components';
import { dataContext } from '../../context/HorizontalCapacity';
import styles from './index.module.css';

const HorizontalCapacity = () => {

    const { 
        soilTable={}, 
        projectTitle={},  
        soilProfile={}, 
        calculationSettings={}, 
        saftyFactors={}, 
        pileProperties={}, 
        defaultSoilLayer={}, 
        foundationParameters={}, 
        submitCalculation={}, 
        saveSoilProfile={}, 
        editSoilProfile={},
        globalAlerts={},
        globalLoading={},
        pileLoads={}
    } = useContext(dataContext);

    return (
        <Fragment>         
            <section className={styles.section}>
                <Row>
                    <ProjectTitle
                        { ...projectTitle }
                    />                      
                </Row>
            </section>

            <section className={styles.section}>
                <Row>
                    <SoilTable 
                        {...soilTable}
                        defaultSoilLayer={defaultSoilLayer}

                        saveSoilProfile={saveSoilProfile}
                        editSoilProfile={editSoilProfile}
                        globalLoading={globalLoading.state}
                    />                      
                </Row>
            </section>
            <section className={styles.section}>
                <Row>
                    <Col xs={12} sm={5} md={6}>
                        <SoilProfile
                            { ...soilProfile }
                            waterLevel={calculationSettings.state.waterLevel.value}
                            groundLevel={calculationSettings.state.groundLevel.value}
                            pileLength={pileProperties.state.pileLength.value}
                            pileShape={pileProperties.state.pileShape.value}
                            pileHeadSpot={pileProperties.state.pileHeadSpot.value}
                        />                        
                    </Col>
                    <Col xs={12} sm={7} md={6}>
                        <CalculationSettings 
                            profileProperties = {{ ...calculationSettings }}
                        />
                        <PileParameters
                            {...pileProperties}
                            layersHeight={soilTable.state.map(element => element.height.value)}
                        />
                    </Col>
                </Row>
            </section>
            <section className={styles.section}>
                <Row>
                    <Col xs={12} sm={6}>
                        <FoundationParameters
                            { ...foundationParameters }
                            diameterPile={pileProperties.state.diameterPile}
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <PileLoads
                            { ...pileLoads }
                        />
                    </Col>
                </Row>
            </section>
            <section className={styles.section}>
                <Row>
                    <Col xs={12}>
                        <FoundationSchema
                            foundationParameters={foundationParameters.state}
                            pileShape={pileProperties.state.pileShape}
                        />                        
                    </Col>
                </Row>
            </section>
            <section className={styles.section}>
                <Row>
                    <SaftyFactors
                        saftyFactors= {{ ...saftyFactors }}
                    />
                </Row>
            </section>
            <section className={styles.section}>
                <Row>
                    <Col xs={12}>
                        <SubmitCalculations
                            { ...submitCalculation }
                        />                        
                    </Col>
                </Row>
            </section>
            <MainAlertAndLoading
                alerts={globalAlerts.state.alerts}
                updateAlerts={globalAlerts.updateState}
                loadings={globalLoading.state}
            />
        </Fragment>
    )
}

export default HorizontalCapacity