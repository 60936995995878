import { useEffect, useState } from 'react';
import { roles } from '../../../config/ProjectConfig';
import { AdminFetch } from '../../../routes';
import { isAdminRole, stringToNumber } from '../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useIsMountedState } from '../../../hooks';

const UsersListService = ({ fetch, setAlert, setLoading, role }) => {
    const { current } = useIsMountedState();
    const [usersListState, setUsersListState] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [arrayOfIds, setArrayOfIds] = useState([]);
    const [deleteCheckbox, setDeleteCheckbox] = useState(false);

    const [stateChanged, setStateChanged] = useState(false);

    useDeepCompareEffect(() => {
        setSelectedUsers(() =>
            usersListState.filter((ele) =>
                arrayOfIds.includes(ele.id),
            ),
        );
    }, [arrayOfIds, usersListState]);

    useEffect(() => {
        if (isAdminRole(role)) {
            if(current){
                AdminFetch({
                    url: fetch.getUsersList.url,
                    method: fetch.getUsersList.method,
                })
                .then((response) => {
                    if(current){
                        if (response.responseData) {
                            setStateChanged(false);
                            setArrayOfIds([]);
                            setUsersListState(
                                [
                                    ...response.responseData.admins,
                                    ...response.responseData.users,
                                ].reduce((acc, ele, idx) => {
                                    const { subUsers, ...restProps } = ele;

                                    if (
                                        Array.isArray(subUsers) &&
                                        subUsers.length > 0
                                    ) {
                                        return [
                                            ...acc,
                                            {
                                                lp: idx + 1,
                                                subUsers,
                                                subUsersNumber: subUsers.length,
                                                ...restProps,
                                            },
                                            ...subUsers
                                                .sort(
                                                    (a, b) =>
                                                        new Date(
                                                            a.subscription.endDate,
                                                        ) -
                                                        new Date(
                                                            b.subscription.endDate,
                                                        ),
                                                )
                                                .map((element, index) => {
                                                    const { parentUser, ...rest } =
                                                        element;
                                                    return {
                                                        lp: stringToNumber(
                                                            `${idx + 1}.${
                                                                index + 1
                                                            }`,
                                                        ),
                                                        parentId: parentUser,
                                                        ...rest,
                                                    };
                                                }),
                                        ];
                                    } else {
                                        return [
                                            ...acc,
                                            {
                                                lp: idx + 1,
                                                subUsers,
                                                subUsersNumber: subUsers.length,
                                                ...restProps,
                                            },
                                        ];
                                    }
                                }, []),
                            );
                        } else {
                            setUsersListState([]);
                        }                            
                    }

                })
                .catch((e) => {
                    if(current){
                        setUsersListState([]);
                        setAlert({
                            error: true,
                            message: 'Błąd podczas wczytywania listy użytkowników',
                        });                            
                    }
                });                
            }
        }
    }, [
        stateChanged,
        fetch.getUsersList.url,
        fetch.getUsersList.method,
        role,
        setAlert,
        current
    ]);

    const onChangeIdsCheckbox = (id) => {
        if (arrayOfIds.includes(id)) {
            setArrayOfIds((state) => state.filter((ele) => ele !== id));
        } else {
            setArrayOfIds((state) => [...state, id]);
        }
    };

    const onChangeBlockAccount = (id) => {
        setSelectedUsers((state) =>
            state.map((ele) => {
                if (ele.id === id) {
                    return { ...ele, blacklisted: !ele.blacklisted };
                } else {
                    return ele;
                }
            }),
        );
    };

    const onSubmitBlockUserAccount = () => {
        if (selectedUsers.length > 0) {
            setLoading({ name: 'blockAccount', loading: true });
            AdminFetch({
                url: fetch.blockUserAccount.url,
                method: fetch.blockUserAccount.method,
                body: {
                    users: selectedUsers
                        .filter((ele) => ele.role !== roles[2])
                        .map((ele) => {
                            return {
                                id: ele.id,
                                blacklisted: ele.blacklisted,
                            };
                        }),
                },
            })
                .then((response) => {
                    setLoading({ name: 'blockAccount', loading: false });
                    if (response.success) {
                        setStateChanged(true);
                        setAlert({
                            error: false,
                            message: 'Użytkownicy zostali zaktualizowani',
                            hideTime: 2000,
                        });
                    } else {
                        setAlert({
                            error: true,
                            message: 'Błąd podczas aktualizacji użytkowników',
                        });
                    }
                })
                .catch(() => {
                    setLoading({ name: 'blockAccount', loading: false });
                    setAlert({
                        error: true,
                        message: 'Błąd podczas aktualizacji użytkowników',
                    });
                });
        } else {
            setAlert({
                error: true,
                message: 'Błąd podczas aktualizacji użytkowników',
            });
        }
    };

    const onChangeDeleteCheckbox = () => setDeleteCheckbox((state) => !state);

    const usersList = {
        onChangeIdsCheckbox: onChangeIdsCheckbox,
        onChangeBlockAccount: onChangeBlockAccount,
        onChangeDeleteCheckbox: onChangeDeleteCheckbox,
        onSubmitBlockUserAccount: onSubmitBlockUserAccount,
        setStateChanged: setStateChanged,
        state: {
            usersListState,
            selectedUsers,
            deleteCheckbox,
            arrayOfIds,
        },
    };
    return {
        usersList,
    };
};

export default UsersListService;
