import { useState, useContext, Fragment } from "react";
import { themeContext } from "../../../context/Theme";
import { adminAuthContext } from '../../../context/Admin'; 
import { GlobalStyles } from '../../../config/globalStyles'
import { Error } from "../../../pages";
import { RenewSession, Sidebar } from "../../../pages/Admin";
import { Logout } from "../../../pages/Admin";
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";
import styles from './index.module.css';
import { AdminNavbar, Loading } from "../../../components";

const loadingStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    opacity: 0.75,
    zIndex: 9999,
};


const AdminGlobalLayout = ({ openSidebar, setOpenSidebar }) => {
    const { modeTheme, mode } = useContext(themeContext); 
    const [logOutModal, setLogOutModal] = useState(false);
    const toggleMenu = () => setOpenSidebar(openSidebar => !openSidebar);

    const {
        firstName,
        lastName,
        email,
        permission,
        setStateChanged,
        id,
        isLoading,
        isUserLoggedIn
    } = useContext(adminAuthContext);

    return (
        <Fragment>
            <GlobalStyles theme={modeTheme} open={openSidebar} />
            {
            isLoading && isUserLoggedIn
            ?
                <div style={loadingStyle}>
                    <Loading oppositeColors={true}/>
                </div>
            :
                !isUserLoggedIn
                ?
                    <Error />
                :
                    <Fragment>
                        <Sidebar firstName={firstName} lastName={lastName} email={email} onToggle={toggleMenu}/>
                        <AdminNavbar onLogOut={setLogOutModal} mode={mode}/>
                        <div id={'project-wrapper'} className={styles.wrapper}>
                            <Container>
                                <Outlet context={{
                                    firstName,
                                    lastName,
                                    email,
                                    permission,
                                    setStateChanged,
                                    id,
                                }}/>
                            </Container>
                            <RenewSession />
                            <Logout open={logOutModal} onChange={setLogOutModal}/>
                        </div>
                    </Fragment>
            }
        </Fragment>
    )
}

export default AdminGlobalLayout;