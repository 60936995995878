import { roundNumber } from "../../../../../utils";
import styles from './index.module.css';

const TechnologyFactors = ({ 
    ygq,
    yst,
    yt,
    softSoilCount,
    hardSoilCount,
    qccuInterpolation,
 }) => {
    
    return (
        <div className={styles.wrapper}>
            <h2>Współczynniki technologiczne</h2>
            <p>Współczynnik obciążeń: <span className={styles.value}>{roundNumber(ygq,2)}</span></p>
            <p>Współczynnik nośności przy wciskaniu: <span className={styles.value}>{roundNumber(yt,2)}</span></p>
            <p>Współczynnik nośności przy wyciąganiu: <span className={styles.value}>{roundNumber(yst,2)}</span></p>
            <p>Współczynnik interpolacji oporów gruntu (min,max): <span className={styles.value}>{roundNumber(qccuInterpolation,2)}</span></p>
            <p>Uwzględnij grunty słabe: <span className={styles.value}>{softSoilCount ? 'Tak' : 'Nie'}</span></p>
            <p>Uwzględnij grunty mocne: <span className={styles.value}>{hardSoilCount ? 'Tak' : 'Nie'}</span></p>
        </div>
    )
}

export default TechnologyFactors