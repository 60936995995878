import { useCallback, useEffect, useState } from 'react';
import {
    alfaDegree,
    ec7ksi,
    pilesTypeVertical,
} from '../../../config/ProjectConfig';
import {
    isNumber,
    makeNumberPositive,
    pileInterpolationLevel,
    roundNumber,
    stringToNumber,
} from '../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const defaultTechnologyFactors = {
    name: '',
    soilName: '',
    layerNo: '',
    height: '',
    absHeight: '',
    qc: '',
    t: '',
    hz: '',
    hci: '',
    tci: '',
    hcifz: '',
    tcifz: '',
    density: '',
    plasticity: '',
    alfa: '',
    weight: '',
    underWaterWeight: '',
    ym: {
        value: '',
        isValid: false,
    },
    sp: {
        value: '',
        isValid: false,
    },
    ss: {
        value: '',
        isValid: false,
    },
    sw: {
        value: '',
        isValid: false,
    },

    ymfz: {
        value: '',
        isValid: false,
    },
    spfz: {
        value: '',
        isValid: false,
    },
    ssfz: {
        value: '',
        isValid: false,
    },
    swfz: {
        value: '',
        isValid: false,
    },
};
const defaultState = {
    m: {
        value: '',
        isValid: null, 
    },
    tn: {
        value: 10,
        isValid: true,
    },
    qtInterpolationMethod: {
        value: 1,
        isValid: true,
    },
    customInterpolationLevel: {
        value: '',
        isValid: null,
    },
    rigidPileHead: {
        value: true,
        isValid: true,
    },
    profilesNumber: {
        value: 1,
        isValid: true,
    },
    ksi: {
        value: '',
        isValid: null,
    },
    yrd: {
        value: '',
        isValid: null,
    },
    yt: {
        value: 1.1,
        isValid: true,
    },
    pnenFactor: {
        value: 1.4,
        isValid: true,
    },
    capacityType: {
        value: 1,
        isValid: true,
    },
    a2reduction: {
        value: true,
        isValid: true,
    },
};

const SaftyFactorsHelpers = ({
    soilsCollection,
    pileParameters,
    waterLevel = {},
    negativeFriction = {},
    negativeFrictionLayers = {},

    updateResultsState,
}) => {
    const [stateChanged, setStateChanged] = useState(false);
    const [saftyFactorsState, setSaftyFactorsState] = useState({
        ...defaultState,
        technologyFactors: soilsCollection.map((ele) => {
            return {
                ...defaultTechnologyFactors,
            };
        }),
    });
    const [soilsParameters, setSoilsParameters] = useState(soilsCollection);

    const soilParamsKeys = soilsParameters.map((element) =>
            Object.fromEntries(
                Object.entries(element).filter(([key]) =>
                    [
                        'weight',
                        'qc',
                        't',
                        'underWaterWeight',
                        'height',
                        'absHeight',
                        'name',
                        'density',
                        'plasticity',
                        'soilName',
                        'layerNo',
                    ].includes(key),
                ),
            ),
        );

    const layerNo = soilsParameters.map((element) =>
            Object.fromEntries(
                Object.entries(element).filter(([key]) =>
                    ['layerNo'].includes(key),
                ),
            ),
        );
    const hz = saftyFactorsState.technologyFactors.map((ele) => ele.hz);

    useDeepCompareEffect(() => {
        const customInterpolationLevel = saftyFactorsState.customInterpolationLevel.value;

        setSoilsParameters(() => {
            return soilsCollection.reduce((acc, ele) => {
                if (
                    saftyFactorsState.qtInterpolationMethod.value === 3 &&
                    isNumber(customInterpolationLevel)
                ) {
                    if (
                        customInterpolationLevel >
                            ele.height.value - ele.absHeight.value &&
                        customInterpolationLevel < ele.height.value
                    ) {
                        return [
                            ...acc,
                            {
                                ...ele,
                                height: {
                                    value: customInterpolationLevel,
                                    isValid: ele.height.isValid,
                                },
                                absHeight: {
                                    value:
                                        customInterpolationLevel -
                                        (ele.height.value -
                                            ele.absHeight.value),
                                },
                            },
                            {
                                ...ele,
                                height: {
                                    value: ele.height.value,
                                    isValid: ele.height.isValid,
                                },
                                absHeight: {
                                    value:
                                        ele.height.value -
                                        customInterpolationLevel,
                                },
                            },
                        ];
                    } else {
                        return [
                            ...acc,
                            {
                                ...ele,
                            },
                        ];
                    }
                } else {
                    return [
                        ...acc,
                        {
                            ...ele,
                        },
                    ];
                }
            }, []);
        });
    }, [
        saftyFactorsState.customInterpolationLevel.value,
        saftyFactorsState.qtInterpolationMethod.value,
        soilsCollection,
        waterLevel.value,
        stateChanged
    ]);

    useEffect(() => {
        const ksiValue = ec7ksi(
            saftyFactorsState.capacityType.value,
            saftyFactorsState.profilesNumber.value,
        );
        setSaftyFactorsState((state) => ({
            ...state,
            ksi: {
                value: ksiValue,
                isValid: isNumber(ksiValue) ? true : false,
            },
        }));
    }, [
        saftyFactorsState.capacityType.value,
        saftyFactorsState.profilesNumber.value,
        saftyFactorsState.ksi.value,
        stateChanged
    ]);

    useDeepCompareEffect(() => {
        if(stateChanged){
            setStateChanged(false);
        }
        setSaftyFactorsState((state) => {
            return {
                ...state,

                technologyFactors: soilParamsKeys.reduce(
                    (acc, element, index, arr) => {
                        const customInterpolationLevel = state.customInterpolationLevel.value;
                        const defaultFactorsObj = {
                            ...defaultTechnologyFactors,
                            ...state['technologyFactors'][index],
                            name: element.name.value,
                            soilName: element.soilName.value,
                            weight: element.weight.value,
                            underWaterWeight: element.underWaterWeight.value,
                            density: element.density.value,
                            plasticity: element.plasticity.value,
                            layerNo: element.layerNo.value,
                            alfa: alfaDegree({
                                id: element.density.value,
                                il: element.plasticity.value,
                            }),
                            qc: element.qc.value,
                            t: element.t.value,
                            height: element.height.value,
                            absHeight: element.absHeight.value,
                            hz: customInterpolationLevel,
                        };

                        if (state.qtInterpolationMethod.value === 3 && isNumber(customInterpolationLevel)) {
                            return [
                                ...acc,
                                {
                                    ...defaultFactorsObj,
                                    hz: customInterpolationLevel,
                                },
                            ];
                        } else {
                            const hz =
                                saftyFactorsState.qtInterpolationMethod.value === 1
                                    ? pileInterpolationLevel({
                                        arr: arr.slice(0, index + 1),
                                        waterLevel: waterLevel,
                                    })
                                    : pileInterpolationLevel({
                                        arr: arr.slice(0, index + 1),
                                        waterLevel: waterLevel,
                                        isNegativeFriction:
                                            negativeFriction.value,
                                        negativeFrictionLayers:
                                            negativeFrictionLayers,
                                        type: 2,
                                    });

                            return [
                                ...acc,
                                {
                                    ...defaultFactorsObj,
                                    hz: hz,
                                },
                            ];
                        }
                    },
                    [],
                ),                
            }
        });
    }, [
        waterLevel,
        saftyFactorsState.qtInterpolationMethod.value,
        saftyFactorsState.customInterpolationLevel.value,
        negativeFriction.value,
        negativeFrictionLayers,
        soilParamsKeys,
        stateChanged
    ]);

    useDeepCompareEffect(() => {
        const pileType = pilesTypeVertical[pileParameters.pileType.value];
        const pileTech = pileType
            ? pileType[pileParameters.pileTechnology.value]
            : '';
        const pileTechPref =
            pilesTypeVertical['Żelbetowe prefabrykowane']['Wbijany'];
        const fzDiameter =
            isNumber(pileParameters.groupWidth.value) &&
            isNumber(pileParameters.groupLength.value)
                ? Math.sqrt(
                      (pileParameters.groupWidth.value *
                          pileParameters.groupLength.value *
                          4) /
                          Math.PI,
                  )
                : '';

            const densPlastSoil = soilsParameters.map((element) =>
                Object.fromEntries(
                    Object.entries(element).filter(([key]) =>
                        ['density', 'plasticity', 'soilName'].includes(key),
                    ),
                ),
            );

        setSaftyFactorsState((state) => ({
            ...state,

            technologyFactors: densPlastSoil.map((element, index) => {
                const sp = pileTech
                    ? pileTech.sp(
                          {
                              id: element.density.value,
                              il: element.plasticity.value,
                          },
                          element.soilName.value,
                      )
                    : '';
                const ss = pileTech
                    ? pileTech.ss(
                          {
                              id: element.density.value,
                              il: element.plasticity.value,
                          },
                          element.soilName.value,
                      )
                    : '';
                const sw = pileTech
                    ? pileTech.sw(
                          {
                              id: element.density.value,
                              il: element.plasticity.value,
                          },
                          element.soilName.value,
                      )
                    : '';

                const spfz = pileTechPref
                    ? pileTechPref.sp(
                          {
                              id: element.density.value,
                              il: element.plasticity.value,
                          },
                          element.soilName.value,
                      )
                    : '';
                const ssfz = pileTechPref
                    ? pileTechPref.ss(
                          {
                              id: element.density.value,
                              il: element.plasticity.value,
                          },
                          element.soilName.value,
                      )
                    : '';
                const swfz = pileTechPref
                    ? pileTechPref.sw(
                          {
                              id: element.density.value,
                              il: element.plasticity.value,
                          },
                          element.soilName.value,
                      )
                    : '';

                return {
                    ...defaultTechnologyFactors,
                    ...state['technologyFactors'][index],

                    hci: pileTech
                        ? pileTech.hci(pileParameters.diameterPile.value, {
                              id: element.density.value,
                              il: element.plasticity.value,
                          })
                        : '',
                    tci: pileTech ? pileTech.tci() : '',

                    hcifz: pileTechPref
                        ? pileTechPref.hci(fzDiameter, {
                              id: element.density.value,
                              il: element.plasticity.value,
                          })
                        : '',
                    tcifz: pileTechPref ? pileTechPref.tci() : '',

                    sp: {
                        value: roundNumber(sp,2),
                        isValid: isNumber(sp) ? true : false,
                    },
                    ss: {
                        value: roundNumber(ss,2),
                        isValid: isNumber(ss) ? true : false,
                    },
                    sw: {
                        value: roundNumber(sw,2),
                        isValid: isNumber(sw) ? true : false,
                    },

                    spfz: {
                        value: roundNumber(spfz,2),
                        isValid: isNumber(spfz) ? true : false,
                    },
                    ssfz: {
                        value: ssfz,
                        isValid: isNumber(ssfz) ? true : false,
                    },
                    swfz: {
                        value: roundNumber(swfz,2),
                        isValid: isNumber(swfz) ? true : false,
                    },
                };
            }),
        }));
    }, [
        pileParameters.groupLength.value,
        pileParameters.groupWidth.value,
        pileParameters.diameterPile.value,
        pileParameters.pileType.value,
        pileParameters.pileTechnology.value,
        negativeFriction.value,
        negativeFrictionLayers,
        soilsParameters,
        stateChanged
    ]);

    useDeepCompareEffect(() => {
        setSaftyFactorsState((state) => ({
            ...state,

            technologyFactors: layerNo.map((element, index) => {
                const isNegativeFriction = negativeFriction.value
                    ? negativeFrictionLayers[element.layerNo.value]
                        ? negativeFrictionLayers[element.layerNo.value]
                              .value
                        : false
                    : false;
                const ym = isNegativeFriction ? 1.1 : 0.9;

                return {
                    ...defaultTechnologyFactors,
                    ...state['technologyFactors'][index],

                    ym: {
                        value: ym,
                        isValid: isNumber(ym) ? true : false,
                    },
                    ymfz: {
                        value: ym,
                        isValid: isNumber(ym) ? true : false,
                    },
                };
            }),
        }));
    }, [
        negativeFriction.value,
        negativeFrictionLayers,
        layerNo,
        stateChanged
    ]);

    useEffect(() => {
        const singlePile = pileParameters.singlePile.value;
        const pileGroup = pileParameters.pileGroup.value;

        const mFactor = singlePile
            ? pileParameters.pilesInFoundation.value
            : pileGroup
            ? pileParameters.groupPilesNumber.value
            : '';
        const mFactorValid = isNumber(mFactor) && mFactor > 0;

        setSaftyFactorsState((state) => ({
            ...state,
            m: {
                value: mFactorValid
                    ? mFactor === 1
                        ? 0.7
                        : mFactor === 2
                        ? 0.8
                        : 0.9
                    : '',
                isValid: mFactorValid,
            },
            yrd: {
                value: mFactorValid
                    ? mFactor === 1
                        ? 1.286
                        : mFactor === 2
                        ? 1.125
                        : 1
                    : '',
                isValid: mFactorValid,
            },
        }));
    }, [
        pileParameters.pileGroup.value,
        pileParameters.groupPilesNumber.value,
        pileParameters.singlePile.value,
        pileParameters.pilesInFoundation.value,
        stateChanged
    ]);

    useDeepCompareEffect(() => {
        let cacheHz = '';
        setSaftyFactorsState((state) => ({
            ...state,
            technologyFactors: state.technologyFactors.map((ele, idx, arr) => {
                if (isNumber(ele.hz)) {
                    cacheHz = ele.hz;
                    return {
                        ...ele,
                    };
                } else if (idx > 0 && !isNumber(ele.hz)) {
                    return {
                        ...ele,
                        hz: cacheHz,
                    };
                } else {
                    const firstHz = arr
                        .slice(idx)
                        .find((ele) => isNumber(ele.hz));
                    return {
                        ...ele,
                        hz: firstHz ? firstHz.hz : '',
                    };
                }
            }),
        }));
    }, [
        hz,
        stateChanged
    ]);

    const onBlurSaftyFactorsState = useCallback((e) => {
        const { name } = e.currentTarget;
        const valid = e.currentTarget.checkValidity();
        const rowNumber = stringToNumber(
            e.currentTarget.getAttribute('data-row'),
        );
        const namesWithValidation = [
            'sp',
            'spfz',
            'ss',
            'ssfz',
            'sw',
            'swfz',
            'ym',
            'ymfz',
        ];

        if (namesWithValidation.includes(name)) {
            setSaftyFactorsState((state) => ({
                ...state,
                technologyFactors: state.technologyFactors.map(
                    (element, index) => {
                        if (rowNumber !== index) {
                            return element;
                        } else {
                            return {
                                ...element,
                                [name]: {
                                    ...element[name],
                                    isValid: valid,
                                },
                            };
                        }
                    },
                ),
            }));
        } else {
            setSaftyFactorsState((state) => ({
                ...state,
                [name]: {
                    ...state[name],
                    isValid: valid,
                },
            }));
        }
    }, []);
    const onChangeSaftyFactorsState = useCallback((e) => {
        const { value, name, type, checked } = e.currentTarget;

        const rowNumber = stringToNumber(
            e.currentTarget.getAttribute('data-row'),
        );
        const namesWithValidation = [
            'sp',
            'spfz',
            'ss',
            'ssfz',
            'sw',
            'swfz',
            'ym',
            'ymfz',
        ];
        
        updateResultsState((state) => {
            return {
                ...state,
                isResultsActual: false, 
            };
        });

        if (type === 'radio') {
            setSaftyFactorsState((state) => {
                return {
                    ...state,
                    [name]: {
                        value: Number(value),
                        isValid: true,
                    },
                };
            });
        } else if (type === 'checkbox') {
            setSaftyFactorsState((state) => {
                return {
                    ...state,
                    [name]: {
                        ...state[name],
                        value: !!checked,
                    },
                };
            });
        } else {
            if (namesWithValidation.includes(name)) {
                setSaftyFactorsState((state) => ({
                    ...state,
                    technologyFactors: state.technologyFactors.map(
                        (element, index) => {
                            if (rowNumber !== index) {
                                return element;
                            } else {
                                return {
                                    ...element,
                                    [name]: {
                                        value:
                                            value.length > 0
                                                ? makeNumberPositive(
                                                      Number(value),
                                                  )
                                                : value,
                                        isValid: true,
                                    },
                                };
                            }
                        },
                    ),
                }));
            } else {
                setSaftyFactorsState((state) => ({
                    ...state,
                    [name]: {
                        value:
                            type === 'number' && value.length > 0
                                ? makeNumberPositive(Number(value))
                                : value,
                        isValid: true,
                    },
                    ...(name === 'customInterpolationLevel' && {
                        customInterpolationLevel: {
                            value: stringToNumber(value),
                            isValid: true,
                        },
                    }),
                }));
            }
        }
    }, []);
    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    }, []);
    const loadProject = (saftyFactorsObj = {}) => {
        const { technologyFactors = [], ...rest } = saftyFactorsObj;

        setSaftyFactorsState((state) => {
            return {
                ...defaultState,
                ...rest,
                technologyFactors: technologyFactors.map((factors, idx) => ({
                    ...state.technologyFactors[idx],
                    ...factors,
                })),
            };
        });
    };

    const saftyFactors = {
        onChange: onChangeSaftyFactorsState,
        onChangeState: setStateChanged,
        onBlur: onBlurSaftyFactorsState,
        onKeyDown: onKeyDown,
        updateState: setSaftyFactorsState,
        loadProject: loadProject,
        initState: () =>
            setSaftyFactorsState({
                ...defaultState,
                technologyFactors: soilsCollection.map((ele) => {
                    return {
                        ...defaultTechnologyFactors,
                    };
                }),
            }),
        state: saftyFactorsState,
    };

    return {
        saftyFactors,
    };
};

export default SaftyFactorsHelpers;
