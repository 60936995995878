import { useCallback, useState } from "react";
import { makeNumberPositive } from "../../../utils";

const initStateObj = {
    waterLevel: {
        value: '',
        isValid: true
    },
    groundLevel: {
        value: '',
        isValid: null
    }    
}

const CalculationSettingsHelpers = ({
    updateResultsState
}) => {
    const [calculationPropertiesState, setCalculationPropertiesState] = useState(initStateObj);

    const onBlurCalculationPropertiesState = useCallback((e) => {
        const { name } = e.currentTarget;
        const valid = e.currentTarget.checkValidity(); 

        setCalculationPropertiesState(state => ({
            ...state, 
            [name]: { 
                ...state[name],
                isValid: valid
            }
        }))
    },[])
    const onChangeCalculationPropertiesState = useCallback((e) => {
        const { value, name } = e.currentTarget;

        updateResultsState((state) => {
            return {
                ...state,
                isResultsActual: false,
            };
        });
        setCalculationPropertiesState(state => ({
            ...state, 
            [name]: { 
                value: value.length > 0 ? name === 'waterLevel' ? makeNumberPositive(Number(value)) : Number(value) : value,
                isValid: true
            }
        }))
    },[])

    const calculationSettings = {
        onChange: onChangeCalculationPropertiesState,
        onBlur: onBlurCalculationPropertiesState,
        updateState: setCalculationPropertiesState,
        initState: () => setCalculationPropertiesState(initStateObj),
        state: calculationPropertiesState
    }

    return {
        calculationSettings
    }

}

export default CalculationSettingsHelpers