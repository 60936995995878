
const drillType = {
    0: 'CB-Gwint-D-HD',
    1: 'CB-Gwint-D-HM',
    2: 'BB-Gwint-D-HD',
    3: 'BB-Gwint-D-HM',
    4: 'AR-Gwint-D-HD',
    5: 'AR-Gwint-D-HM',
    6: 'AB-Gwint-D-HD',
    7: 'AB-Gwint-D-HM',
    8: 'TSB-Gwint-D-R',
    9: 'TSB-Gwint-D-RS',
}

const drill_T76 = [
    [drillType[0], 115],
    [drillType[0], 130],
    [drillType[0], 150],
    [drillType[0], 200],
    [drillType[1], 115],
    [drillType[1], 130],
    [drillType[1], 200],
    [drillType[2], 120],
    [drillType[2], 130],
    [drillType[3], 120],
    [drillType[3], 130],
    [drillType[6], 115],
    [drillType[6], 130],
    [drillType[7], 115],
    [drillType[7], 130],
    [drillType[8], 120],
    [drillType[8], 130],
    [drillType[8], 150],
    [drillType[8], 175],
    [drillType[8], 200],
    [drillType[8], 300],
]

const drill_R51 = [
    [drillType[0], 76],
    [drillType[0], 90],
    [drillType[0], 100],
    [drillType[0], 115],
    [drillType[0], 130],
    [drillType[0], 150],
    [drillType[1], 90],
    [drillType[1], 100],
    [drillType[1], 115],
    [drillType[2], 76],
    [drillType[2], 100],
    [drillType[2], 115],
    [drillType[3], 76],
    [drillType[3], 100],
    [drillType[3], 115],
    [drillType[4], 90],           
    [drillType[4], 115],
    [drillType[6], 76],
    [drillType[6], 90],
    [drillType[6], 115],
    [drillType[7], 76],
    [drillType[7], 90],
    [drillType[7], 115],
    [drillType[9], 110],
    [drillType[9], 130],
    [drillType[9], 150],
]

const drill_R38 = [
    [drillType[0], 76],
    [drillType[0], 90],
    [drillType[0], 115],
    [drillType[1], 76],
    [drillType[1], 90],
    [drillType[1], 115],
    [drillType[2], 76],
    [drillType[2], 90],
    [drillType[2], 100],
    [drillType[2], 115],
    [drillType[3], 76],
    [drillType[3], 90],
    [drillType[3], 100],
    [drillType[3], 115],
    [drillType[4], 76],
    [drillType[4], 90],
    [drillType[4], 115],
    [drillType[6], 76],
    [drillType[6], 90],
    [drillType[6], 115],
    [drillType[7], 76],
    [drillType[7], 90],
    [drillType[7], 115],
    [drillType[9], 76],
    [drillType[9], 100],
    [drillType[9], 110],
    [drillType[9], 130],
    [drillType[9], 150],
]

const drill_R32 = [
    [drillType[0], 51],
    [drillType[0], 76],
    [drillType[1], 51],
    [drillType[1], 76],
    [drillType[2], 51],
    [drillType[2], 76],
    [drillType[3], 51],
    [drillType[3], 76],
    [drillType[4], 51],
    [drillType[4], 76],
    [drillType[4], 90],
    [drillType[4], 115],
    [drillType[5], 51],
    [drillType[5], 76],
    [drillType[6], 76],
    [drillType[6], 90],
    [drillType[7], 76],
    [drillType[7], 90],
    [drillType[9], 76],
    [drillType[9], 100],
    [drillType[9], 110],
    [drillType[9], 130],
]

const dywidag = [
{
    prod: 'DYWIDAG',
    name: 'R32-210',
    subname:'',
    fu: 210,
    rmk: 160,
    drill: drill_R32
},
{
    prod: 'DYWIDAG',
    name: 'R32-250',
    subname:'',
    fu: 250,
    rmk: 190,
    drill: drill_R32
},
{
    prod: 'DYWIDAG',
    name: 'R32-280',
    subname:'',
    fu: 280,
    rmk: 210,
    drill: drill_R32
},
{
    prod: 'DYWIDAG',
    name: 'R32-320',
    subname:'',
    fu: 320,
    rmk: 250,
    drill: drill_R32
},
{
    prod: 'DYWIDAG',
    name: 'R32-360',
    subname:'',
    fu: 360,
    rmk: 280,
    drill: drill_R32
},
{
    prod: 'DYWIDAG',
    name: 'R32-400',
    subname:'',
    fu: 400,
    rmk: 330,
    drill: drill_R32
},
{
    prod: 'DYWIDAG',
    name: 'R38-420',
    subname:'',
    fu: 420,
    rmk: 350,
    drill: drill_R38
},
{
    prod: 'DYWIDAG',
    name: 'R38-500',
    subname:'',
    fu: 500,
    rmk: 400,
    drill: drill_R38
},
{
    prod: 'DYWIDAG',
    name: 'R38-550',
    subname:'',
    fu: 550,
    rmk: 430,
    drill: drill_R38
},
{
    prod: 'DYWIDAG',
    name: 'R51-550',
    subname:'',
    fu: 550,
    rmk: 450,
    drill: drill_R51
},
{
    prod: 'DYWIDAG',
    name: 'R51-660',
    subname:'',
    fu: 660,
    rmk: 530,
    drill: drill_R51
},
{
    prod: 'DYWIDAG',
    name: 'R51-800',
    subname:'',
    fu: 800,
    rmk: 630,
    drill: drill_R51
},
{
    prod: 'DYWIDAG',
    name: 'T76-1300',
    subname:'',
    fu: 1300,
    rmk: 1000,
    drill: drill_T76
},
{
    prod: 'DYWIDAG',
    name: 'T76-1650',
    subname:'',
    fu: 1650,
    rmk: 1200,
    drill: drill_T76
},
{
    prod: 'DYWIDAG',
    name: 'T76-1900',
    subname:'',
    fu: 1900,
    rmk: 1500,
    drill: drill_T76
},
]

export default dywidag