import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Checkbox, InputField, SelectField, SectionTitle, RadioInput } from '../../../../components';
import styles from './index.module.css'

const MicropileParameters = ({onChange, onBlur, onKeyDown, state={} }) => {
    
    return (
        <Fragment>
            <SectionTitle title="Parametry mikropala" />
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField 
                        title="Rzędna głowicy mikropala [m.p.p.t]"
                        name="micropileHeadSpot"
                        type="number"
                        value={state.micropileHeadSpot.value}
                        isValid={state.micropileHeadSpot.isValid}
                        
                        onChange={onChange}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        placeholder="Rzędna głowicy mikropala" 
                        min={0}
                        step={0.001}
                        required
                    />
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField 
                        title="Kąt nachylenia do poziomu [&#176;]"
                        name="micropileAngle"
                        type="number"
                        value={state.micropileAngle.value}
                        isValid={state.micropileAngle.isValid}
                        
                        onChange={onChange}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        placeholder="Kąt nachylenia do poziomu" 
                        min={0}
                        step={0.001}

                        required
                    />
                </Col>
            </Row>
            <Row className={styles.row}>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <RadioInput
                        onChange={onChange} 
                        title="Rodzaj mikropala" 
                        radioTitle="Samowiercący/iniekcja ciśnieniowa"
                        name="micropileType"
                        label="System samowiercący"
                        checked={state.micropileType.value === 1}
                        value={1}
                    />
                </Col>
                <Col xs={12} sm={6} className={`${styles.columnSpace} ${styles.flexEnd}`}>
                    <RadioInput
                        onChange={onChange} 
                        name="micropileType"
                        radioTitle="Iniekcja tradycyjna"
                        label="Iniekcja zwykła"
                        checked={state.micropileType.value === 2}
                        value={2}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <Checkbox
                        onChange={onChange} 
                        title="Wymiaruj żerdź mikropala"
                        name="isSteelRod"
                        label={''}
                        value={state.isSteelRod.value}
                    >
                        Domyślnie żerdź dobierana jest na nośność obliczeniową lub granicę plastyczności, w ustawieniach można zmienić sposób dobierania żerdzi na nośność na zerwanie.
                    </Checkbox>
                </Col>  
            </Row>
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        title="Obliczeniowa siła osiowa [kN]"
                        name="axisLoad"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={state.axisLoad.value}
                        isValid={state.axisLoad.isValid}
                        placeholder="Siła osiowa"
                        type="number"

                        required
                    />
                </Col>   
                {
                    !state.isSteelRod.value ?
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <InputField 
                            title="Średnica koronki [mm]"
                            name="micropileScrewDiameter"
                            type="number"
                            value={state.micropileScrewDiameter.value}
                            isValid={state.micropileScrewDiameter.isValid}
                            
                            onChange={onChange}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            placeholder="Średnica koronki" 
                            min={0}
                            step={0.001}

                            required
                        />
                    </Col>
                    :
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <SelectField 
                            title="Typ żerdzi"
                            onBlur={onBlur}
                            onChange={onChange} 
                            value={state.micropileType.value === 1 ? state.rodType.value : state.rodThreadbarType.value}
                            defaultOption="Wybierz typ żerdzi"
                            isValid={state.micropileType.value === 1 ? state.rodType.isValid : state.rodThreadbarType.isValid}
                            options={state.allRods} 
                            name={state.micropileType.value === 1 ? "rodType" : "rodThreadbarType"}
                        /> 
                    </Col>
                }    
                </Row>
                {
                    state.isSteelRod.value && 
                    <Row>
                        {
                            state.micropileType.value === 1 &&
                            <Col xs={12} sm={6} className={styles.columnSpace}>
                                <SelectField 
                                    title="Typ koronki"
                                    onBlur={onBlur}
                                    onChange={onChange} 
                                    value={state.screwType.value}
                                    defaultOption="Wybierz typ koronki"
                                    isValid={state.screwType.isValid}
                                    options={state.allScrews} 
                                    name="screwType"
                                /> 
                            </Col>                             
                        }
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <InputField 
                                title={state.micropileType.value === 1 ? "Średnica koronki [mm]" : "Średnica otworu [mm]"}
                                name="screwTypeDiameter"
                                type="number"
                                value={state.screwTypeDiameter.value}
                                isValid={state.screwTypeDiameter.isValid}
                                
                                onChange={onChange}
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                placeholder="Średnica koronki" 
                                min={0}
                                step={0.001}
                                disable={state.micropileType.value === 1 ? true : false}

                                required
                            />
                        </Col>
                    </Row>
                }
            <SectionTitle title="Parametry obliczeń"/>
            <Row>
                <Col xs={12} className={styles.columnSpace}>
                    <Checkbox
                        onChange={onChange} 
                        title="Głęboka powierzchnia poślizgu"
                        name="isDeepPlate"
                        label={''}
                        value={state.isDeepPlate.value}
                    >  
                        Uwzględnij głęboką powierzchnię poślizgu jako 2/3 &#966;
                    </Checkbox>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <Checkbox
                        onChange={onChange} 
                        title="Rzędna klina odłamu gruntu"
                        name="isSlipPlate"
                        label={''}
                        value={state.isSlipPlate.value}
                    >  
                        Zadaj rzędną [p.p.p.t] od której obliczony zostanie klin odłamu gruntu. Domyślnie przyjęto rzędną wbicia ścianki szczelnej. 
                    </Checkbox>
                </Col>  
                {
                    state.isSlipPlate.value &&
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <InputField
                            title="Rzędna klina odłamu [m.p.p.t]"
                            name="slipPlate"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={state.slipPlate.value}
                            isValid={state.slipPlate.isValid}
                            placeholder="Rzędna poślizgu"
                            type="number"

                            required
                        />
                    </Col>
                }
            </Row>
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}> 
                    <Checkbox
                        onChange={onChange} 
                        title="Długość wolna mikropala"
                        name="isFreeLength"
                        label={''}
                        value={state.isFreeLength.value}
                    >
                        Długość wolna mikropala, po której obliczona zostanie długość buławy kotwiącej. W obliczeniach nie będzie uwzględniony warunek minimalnej długości wolnej ze względu na klin odłamu gruntu. Przy założeniu obliczeń z uwzględnieniem głębokiej powierzchni poślizgu minimalna długość wolna wynika z analizy głębokiej powierzchni poślizgu, natomiast, jeżeli podana długość wolna jest większa od minimalnej to uwzględniona zostanie długość wolna podana przez użytkownika.
                    </Checkbox>                  
                </Col>
                {
                    state.isFreeLength.value &&
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <InputField
                            title="Długość wolna [m]"
                            name="freeLength"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={state.freeLength.value}
                            isValid={state.freeLength.isValid}
                            placeholder="Długość wolna"
                            type="number"

                            required
                        />
                    </Col>
                }
            </Row>
        </Fragment>
    )
}

export default MicropileParameters