import { useCallback, useState } from "react"
import { isNumber, roundNumber, stringToNumber } from "../../../utils";
import { cptPileTypes } from "../../../config/ProjectConfig";

const defaultState = {
    zwg: {
        value: '',
        isValid: null
    },
    cptLevel: {
        value: '',
        isValid: null 
    },
    groundLevel: {
        value: '',
        isValid: null 
    },
    pileType: {
        value: '',
        isValid: null
    },
    diameterPile: { 
        value: '',
        isValid: null
    },
    thickness: { 
        value: '',
        isValid: null
    },
    pileLength: {
        value: '',
        isValid: null
    },
    pileHeadSpot: {
        value: '',
        isValid: null
    },
    newEmbankment: {
        value: false,
        isValid: true
    },
    from: {
        value: '',
        isValid: null
    },
    to: {
        value: '',
        isValid: null
    },
    negativeFriction: {
        value: false,
        isValid: true
    },
    isBaseWidth: {
        value: false,
        isValid: true
    },
    baseWidth: {
        value: '',
        isValid: null
    },
    hType: {
        value: '',
        isValid: null 
    },
    layersName: [],
};

const Calculations = ({
    updateResultsState
}) => {
    const [state, setState] = useState(defaultState);

    const onBlur = useCallback((e) => {
        const { name, value } = e.currentTarget;
        const valid = e.currentTarget.checkValidity();
        const greaterThanZeroProps = [
            'diameterPile',
            'pileLength',
            'baseWidth'
        ];
        const greaterThanEqualZeroProps = [
            'zwg',
            'pileHeadSpot'
        ];
        setState((state) => {
            return {
                ...state,
                [name]: {
                    ...state[name],
                    isValid: greaterThanZeroProps.includes(name)
                        ? value > 0 && valid
                        : greaterThanEqualZeroProps.includes(name)
                        ? value >= 0 && valid
                        :
                        valid,
                },
            };
        });
    },[]);
    const onChange = (e, index, updateSoilTable) => {
        const input =
            e.currentTarget.closest('input') ||
            e.currentTarget.closest('select');

        if (input) {
            const { name, value, type, checked } = input;

            updateResultsState((state) => {
                return { 
                    ...state,
                    isResultsActual: false,
                };
            });

            if(type === 'radio') {
                setState((state) => {
                    return {
                        ...state,
                        [name]: {
                            value: Number(value),
                            isValid: true,
                        },
                    };
                });
            } else if(type === 'checkbox') {
                if(name === 'layersName'){
                    setState(state => ({
                        ...state,
                        layersName: state.layersName.map((ele,idx) => {
                            if(index !== idx) {
                                return ele
                            } else {
                                return {
                                    ...ele,
                                    value: !!checked
                                }
                            }
                        })
                    }));
                } else {
                    setState((state) => ({
                        ...state,
                        [name]: {
                            ...state[name],
                            value: !!checked,
                        },
                    }));
                }
            } else {
                if (name === 'cptLevel') { 
                    const cptLevel = type === 'number' && value.length > 0 
                        ? 
                            isNumber(stringToNumber(value)) ? stringToNumber(value) : 0
                        : 
                            isNumber(value) ? value : 0;
                    const currentCptLevel = isNumber(state.cptLevel.value) ? state.cptLevel.value : 0;

                    setState((state) => ({
                        ...state,
                        [name]: {
                            value: type === 'number' && value.length > 0 ? stringToNumber(value) : value,
                            isValid: true,
                        },
                    }));

                    updateSoilTable((state) => {
                        return state.map(ele => {
                            const z = roundNumber(ele.z.value - currentCptLevel + cptLevel,2);

                            if(!ele.cd || !ele.cd.value){
                                return {
                                    ...ele,
            
                                    z: {
                                        value: z,
                                        isValid: isNumber(z) ? true : ele.z.isValid
                                    },
                                }                    
                            } else {
                                return ele
                            }
                        })
                    })
                } else {
                    setState(stateObj => {
                        return {
                            ...stateObj,

                            [name]: {
                                value: type === 'number' && value.length > 0 ? stringToNumber(value) : value,
                                isValid: true,
                            },
                        };
                    });
                }
            }
        }
    };
    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    }, []);

    const loadProject = (loadedState = {}) => {
        setState(() => {
            return {
                ...defaultState,
                ...loadedState,
            };
        });
    };

    const calculationState = {
        onChange,
        onBlur,
        onKeyDown,
        updateState: setState,
        loadProject: loadProject,
        initState: () => {
            setState(defaultState)
        },

        state,
        pileTypes: cptPileTypes
    };

    return {
        calculationState
    }
};

export default Calculations