import React from 'react';
import styles from './index.module.css';

const useHeaderType = ({type, title}) => {

    switch(type){
        case 1: {
            return (
                <h1>{title}</h1>
            )
        }
        case 2: {
            return (
                <h2>{title}</h2>
            )
        }
        case 3: {
            return (
                <h3>{title}</h3>
            )
        }
        case 4: {
            return (
                <h4>{title}</h4>
            )
        }
        case 5: {
            return (
                <h5>{title}</h5>
            )
        }
        case 6: {
            return (
                <h6>{title}</h6>
            )
        }
        default: {
            return (
                <h2>{title}</h2>
            )
        }
    }
}
const MainTitle = ({ title, subtitle, headerType=2}) => {

    return (
        <div className={styles.wrapper}>
            {useHeaderType({
                type:headerType,
                title: title
            })}
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        </div>
    )
}
 
export default MainTitle