import { useState, useContext } from 'react';
import { AuthFetch } from '../../../routes';
import { useLocation } from 'react-router-dom';
import { isObjectId } from '../../../config/ProjectConfig';
import { authContext } from '../../../context/Authentication';

import { useIsMountedState } from '../../../hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';

const calculationType = [
    {
        type: 0,
        name: 'Kx - metoda uogólniona',
    },
    {
        type: 1,
        name: 'Nośność pali - PN-83/B-02482 + EC7',
    },
    {
        type: 2,
        name: 'Mikropal kotwiący',
    },
    {
        type: 3,
        name: 'Mikropal wciskany/wyciągany',
    },
    {
        type: 4,
        name: 'Zbrojenie przekroju',
    },
    {
        type: 5,
        name: 'Nośność pali - CPT',
    },
    {
        type: 6,
        name: 'Nośność ścianki szczelnej - CPT',
    },
];

const ProjectsListService = ({ setAlert }) => {
    const location = useLocation();
    const { email: userEmail } = useContext(authContext);
    const [allProjects, setAllProjects] = useState([]);

    const [currentProject, setCurrentProject] = useState({
        name: '',
        id: '',
        userId: '',
    });
    const [currentUser, setCurrentUser] = useState('');
    const [currentUserProjects, setCurrentUserProjects] = useState([]);

    const [currentCalcType, setCurrentCalcType] = useState({
        name: '',
        type: '',
    });

    const [verticalCapactyCollection, setVerticalCapacityCollection] = useState(
        [],
    );
    const [micropilePullCollection, setMicropilePullCollection] = useState([]);
    const [micropileCompressionCollection, setMicropileCompressionCollection] =
        useState([]);
    const [kxCollection, setKxCollection] = useState([]);
    const [reinforcementCollection, setReinforcementCollection] = useState([]);
    const [pileCptCollection, setPileCptCollection] = useState([]);
    const [sheetpileCptCollection, setSheetpileCptCollection] = useState([]);
    const isMountedState = useIsMountedState();

    useDeepCompareEffect(() => {
        const userEmailMatch = currentUser.match(/\(([^)]+)\)/);
        const currentUserEmail = userEmailMatch ? userEmailMatch[1] : userEmail;
        const currentUserObj =
            allProjects.length > 0
                ? allProjects.find(
                      (ele) => ele.email === currentUserEmail,
                  )
                : undefined;
        const currentUserObjProjects = currentUserObj
            ? currentUserObj.projects
            : undefined;

        if (currentUserObjProjects) {
            const isCurrentProjects =
                Array.isArray(currentUserObjProjects) &&
                currentUserObjProjects.length > 0;
            setCurrentUserProjects(currentUserObjProjects);
            setCurrentProject({
                name: isCurrentProjects ? currentUserObjProjects[0].name : '',
                id: isCurrentProjects ? currentUserObjProjects[0].id : '',
                userId: isCurrentProjects
                    ? currentUserObjProjects[0].userId
                    : '',
            });
        } else {
            setCurrentUserProjects([]);
            setCurrentUser('');
        }
    }, [userEmail, currentUser, allProjects]);

    useDeepCompareEffect(() => {
        if (
            isObjectId(currentProject.id) &&
            isObjectId(currentProject.userId) &&
            [0, 1, 2, 3, 4, 5, 6].includes(currentCalcType.type)
        ) {
            AuthFetch({
                url: '/api/projects/charts/calculations',
                method: 'POST',
                body: {
                    projectId: currentProject.id,
                    userId: currentProject.userId,
                    calculationType: currentCalcType.type,
                },
            })
            .then((response) => {
                if(isMountedState.current){
                    if (response.responseData.calculationType === 0) {
                        if (
                            Array.isArray(response.responseData.results) &&
                            response.responseData.results.length > 0
                        ) {
                            setKxCollection(
                                response.responseData.results
                                    .filter((ele) => ele.results)
                                    .map((ele) => ({
                                        profile: ele.profileId ? ele.profileId.profileName : '',
                                        capacity: ele.results.currentCapacity,
                                    })),
                            );
                        } else {
                            setKxCollection([]);
                        }
                    } else if (response.responseData.calculationType === 1) {
                        if (
                            Array.isArray(response.responseData.results) &&
                            response.responseData.results.length > 0
                        ) {
                            setVerticalCapacityCollection(
                                response.responseData.results
                                    .filter((ele) => ele.results)
                                    .map((ele) => ({
                                        profile: ele.profileId ? ele.profileId.profileName : '',
                                        capacity: ele.results.currentCapacity,
                                    })),
                            );
                        } else {
                            setVerticalCapacityCollection([]);
                        }
                    } else if (response.responseData.calculationType === 2) {
                        if (
                            Array.isArray(response.responseData.results) &&
                            response.responseData.results.length > 0
                        ) {
                            setMicropilePullCollection(
                                response.responseData.results
                                    .filter((ele) => ele.results)
                                    .map((ele) => ({
                                        profile: ele.profileId ? ele.profileId.profileName : '',
                                        capacity: ele.results.currentCapacity,
                                    })),
                            );
                        } else {
                            setMicropilePullCollection([]);
                        }
                    } else if (response.responseData.calculationType === 3) {
                        if (
                            Array.isArray(response.responseData.results) &&
                            response.responseData.results.length > 0
                        ) {
                            setMicropileCompressionCollection(
                                response.responseData.results
                                    .filter((ele) => ele.results)
                                    .map((ele) => ({
                                        profile: ele.profileId ? ele.profileId.profileName : '',
                                        capacity: ele.results.currentCapacity,
                                    })),
                            );
                        } else {
                            setMicropileCompressionCollection([]);
                        }
                    } else if (response.responseData.calculationType === 4) {
                        if (
                            Array.isArray(response.responseData.results) &&
                            response.responseData.results.length > 0
                        ) {
                            setReinforcementCollection(
                                response.responseData.results
                                    .filter((ele) => ele.results)
                                    .map((ele) => ({
                                        profile: ele.profileId ? ele.profileId.profileName : '',
                                        capacity: ele.results,
                                    })),
                            );
                        } else {
                            setVerticalCapacityCollection([]);
                        }
                    } else if (response.responseData.calculationType === 5) {
                        if (
                            Array.isArray(response.responseData.results) &&
                            response.responseData.results.length > 0
                        ) {
                            setPileCptCollection(
                                response.responseData.results
                                    .filter((ele) => ele.results)
                                    .map((ele) => ({
                                        profile: ele.profileId ? ele.profileId.profileName : '',
                                        capacity: ele.results,
                                    })),
                            );
                        } else {
                            setPileCptCollection([]);
                        }
                    } else if (response.responseData.calculationType === 6) {
                        if (
                            Array.isArray(response.responseData.results) &&
                            response.responseData.results.length > 0
                        ) {
                            setSheetpileCptCollection(
                                response.responseData.results
                                    .filter((ele) => ele.results)
                                    .map((ele) => ({
                                        profile: ele.profileId ? ele.profileId.profileName : '',
                                        capacity: ele.results,
                                    })),
                            );
                        } else {
                            setSheetpileCptCollection([]);
                        }
                    } else {
                        setMicropilePullCollection([]);
                        setVerticalCapacityCollection([]);
                        setMicropileCompressionCollection([]);
                        setKxCollection([]);
                        setReinforcementCollection([]);
                        setPileCptCollection([]);
                        setSheetpileCptCollection([]);
                    }
                }
            })
            .catch(() => {
                if(isMountedState.current){
                setAlert({
                        error: true,
                        message: 'Błąd podczas wczytywania wyników obliczeń',
                    });                    
                }
            });
        }
    }, [
        currentProject.id,
        currentProject.userId,
        currentCalcType.type,
        location.pathname,
        setAlert,
        isMountedState
    ]);
    useDeepCompareEffect(() => {
        AuthFetch({
            url: '/api/projects/list/profiles/charts',
            method: 'GET',
        })
            .then((response) => {
                if(isMountedState.current){
                    if (
                        Array.isArray(response.responseData.projects) &&
                        response.responseData.projects.length > 0
                    ) {
                        setAllProjects(() => {
                            return response.responseData.projects.reduce(
                                (acc, ele) => {
                                    const { userId, ...rest } = ele;
                                    const userExistsIndex =
                                        acc.length > 0
                                            ? acc.findIndex(
                                                (ele) => ele.id === userId.id,
                                            )
                                            : -1;

                                    if (userExistsIndex > -1) {
                                        acc[userExistsIndex] = {
                                            ...acc[userExistsIndex],
                                            projects: [
                                                ...acc[userExistsIndex].projects,
                                                {
                                                    ...rest,
                                                    userId: userId.id,
                                                    email: userId.email,
                                                },
                                            ],
                                        };
                                        return [...acc];
                                    }
                                    return [
                                        ...acc,
                                        {
                                            ...userId,
                                            projects: [
                                                {
                                                    ...rest,
                                                    userId: userId.id,
                                                    email: userId.email,
                                                },
                                            ],
                                        },
                                    ];
                                },
                                [],
                            );
                        });
                    } else {
                        setAllProjects([]);
                    }                    
                }
            })
            .catch(() => {
                if(isMountedState.current){
                    setAllProjects(() => []);
                    setAlert({
                        error: true,
                        message: 'Błąd podczas wczytywania projektów',
                    });                    
                }
            });
    }, [location.pathname, setAlert, isMountedState]);

    const onChangeCurrentProject = (e) => {
        const currentSelectProject = currentUserProjects.find(
            (ele) => ele.name === e.target.value,
        );

        if (currentSelectProject) {
            setCurrentProject({
                name: currentSelectProject.name,
                id: currentSelectProject.id,
                userId: currentSelectProject.userId,
            });
        }
    };
    const onChangeCalculationType = (e) =>
        setCurrentCalcType({
            name: e.target.value,
            type: calculationType.find((ele) => ele.name === e.target.value)
                .type,
        });
    const onChangeUser = (e) => {
        const target = e.currentTarget;
        if (target.name === 'currentUser') {
            setCurrentUser(target.value);
        }
    };
    const projects = {
        state: {
            allProjects,
            currentUserProjects,
            currentUser,
            userEmail,

            calculationType,
            currentProject,
            currentCalcType,

            verticalCapactyCollection,
            micropilePullCollection,
            micropileCompressionCollection,
            reinforcementCollection,
            kxCollection,
            pileCptCollection,
            sheetpileCptCollection
        },
        onChangeCurrentProject,
        onChangeCalculationType,
        onChangeUser,
    };

    return {
        projects,
    };
};

export default ProjectsListService;
