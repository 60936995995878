import { useContext, useEffect } from 'react';
import config from '../../../config/config';
import { dataContext } from '../../../context/HorizontalCapacity';
import PrintHorizontalCapacityResults from './PrintResults';
import DefaultNavigation from '../DefaultNavigation';

const HorizontalCapacityNavigation = ({
    report
}) => {

    const HorizontalCapacityContext = useContext(dataContext); 
    const { routes } = config;

    useEffect(() => {
        document.title = `Nośność pozioma - ${HorizontalCapacityContext.fileNameToSave.fileName.value}`;

        return () => {
            document.title = '';
        };
    }, [HorizontalCapacityContext.fileNameToSave.fileName.value]); 

    return (
        <DefaultNavigation 
            title="Nośność pozioma pala"
            subtitle="Nośność pozioma fundamentu palowego wg metody uogólnionej Mieczysława Koseckiego"
            modelRoute={routes.dashboard.koseckiMethodModel}
            resultsRoute={routes.dashboard.koseckiMethodResults}
            context={HorizontalCapacityContext}>
            {HorizontalCapacityContext.results.state.isResultsActual && HorizontalCapacityContext.results.isResultsActive && (
                <PrintHorizontalCapacityResults
                    report={report}
                    isResultsActual={
                        HorizontalCapacityContext.results.state.isResultsActual
                    }
                    printResults={HorizontalCapacityContext.printResults}
                />
            )}
        </DefaultNavigation>
    );
};

export default HorizontalCapacityNavigation;
