import React, { Fragment, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Checkbox, SectionTitle, Modal } from '../../../../components';
import ReportWrapper from './ReportWrapper';
import ReactToPrint from 'react-to-print';
import styles from './index.module.css';
import useDeepCompareEffect from 'use-deep-compare-effect';

const PrintResults = ({ state, onChange, onLoad, open, onHide }) => {
    const pdfComponentRef = useRef();
    const [isLoaded, setIsLoaded] = useState(true);

    useDeepCompareEffect(() => {
        setIsLoaded(() => {
            let localState = true;
            if(state.reportItem.bendingChart){
                localState = localState && state.allLoaded.bendingChart;
            } else {
                localState = localState && true;
            }
            if(state.reportItem.shearChart){
                localState = localState && state.allLoaded.shearChart;
            } else {
                localState = localState && true;
            }
            if(state.reportItem.displacementChart){
                localState = localState && state.allLoaded.displacementChart;
            } else {
                localState = localState && true;
            }
            if(state.reportItem.plasticDisplacementChart){
                localState = localState && state.allLoaded.plasticDisplacementChart;
            } else {
                localState = localState && true;
            }
            if(state.reportItem.kxChart){
                localState = localState && state.allLoaded.kxChart;
            } else {
                localState = localState && true;
            }
            if(state.reportItem.rgrChart){
                localState = localState && state.allLoaded.rgrChart;
            } else {
                localState = localState && true;
            }

            return localState
        });
    },[state.allLoaded, state.reportItem]);

    return (
        <Fragment>
            <Modal
                title="Raport z obliczeń"
                open={open}
                onHide={onHide}
                hideButton={false}>
                <Row>
                    <Col xs={6}>
                        <Checkbox
                            name="allItems"
                            label="Zaznacz wszystko"
                            value={state.reportItem.allItems} 
                            onChange={onChange}
                        />
                    </Col>
                    <Col xs={6}>
                        <Checkbox
                            name="firstPage"
                            label="Strona tytułowa"
                            value={state.reportItem.firstPage} 
                            onChange={onChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <SectionTitle title="Dane wejściowe" />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox
                            name="soilsTable"
                            label="Tabela parametrów gruntu"
                            value={state.reportItem.soilsTable}
                            onChange={onChange}
                        />
                        <Checkbox
                            name="projectInfo"
                            label="Parametry ogólne obliczeń"
                            value={state.reportItem.projectInfo}
                            onChange={onChange}
                        />
                        <Checkbox
                            name="pileLoads"
                            label="Obciążenie głowicy pala"
                            value={state.reportItem.pileLoads}
                            onChange={onChange}
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox
                            name="pileParameters"
                            label="Parametry pala"
                            value={state.reportItem.pileParameters}
                            onChange={onChange}
                        />
                        <Checkbox
                            name="foundationParameters"
                            label="Parametry fundamentu"
                            value={state.reportItem.foundationParameters}
                            onChange={onChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <SectionTitle title="Wyniki obliczeń" />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox
                            name="designSoilsParams"
                            label="Obliczeniowe parametry gruntu"
                            value={state.reportItem.designSoilsParams}
                            onChange={onChange}
                        />
                        <Checkbox
                            name="kxRgr"
                            label="Szczegółowe wartości Kx i Rgr"
                            value={state.reportItem.kxRgr}
                            onChange={onChange}
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox
                            name="internalForces"
                            label="Tabela sił wewnętrznych"
                            value={state.reportItem.internalForces}
                            onChange={onChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <SectionTitle title="Załączniki graficzne" />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox
                            name="bendingChart"
                            label="Wykres momentów w palu"
                            value={state.reportItem.bendingChart}
                            onChange={onChange}
                        />
                        <Checkbox
                            name="shearChart"
                            label="Wykres sił tnących w palu"
                            value={state.reportItem.shearChart}
                            onChange={onChange}
                        />
                        <Checkbox
                            name="displacementChart"
                            label="Wykres przemiszczeń sprężystych pala"
                            value={state.reportItem.displacementChart}
                            onChange={onChange}
                        />
                        {
                            state.results && Array.isArray(state.results.internalForces.plasticDisplacements) && state.results.internalForces.plasticDisplacements.length > 0 && 
                            <Checkbox
                                name="plasticDisplacementChart"
                                label="Wykres przemieszczeń sprężysto-plastycznych pala"
                                value={state.reportItem.plasticDisplacementChart}
                                onChange={onChange}
                            />                        
                        }
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox
                            name="kxChart"
                            label="Rozkład reakcji poziomej gruntu"
                            value={state.reportItem.kxChart}
                            onChange={onChange}
                        />
                        <Checkbox
                            name="rgrChart"
                            label="Rozkład reakcji granicznej gruntu"
                            value={state.reportItem.rgrChart}
                            onChange={onChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <ReactToPrint
                            trigger={() => (
                                <Button
                                    loading={!isLoaded}
                                    loadingCss={styles.loading}
                                    css={`
                                        ${styles.button} default-button-style
                                    `}
                                    altTitle="Drukuj"
                                    title="Drukuj raport"
                                    type="button"
                                />
                            )}
                            content={() => pdfComponentRef.current}
                        />
                    </Col>
                </Row>

                <ReportWrapper
                    pdfState={state}
                    onLoad={onLoad}
                    ref={pdfComponentRef}
                />
            </Modal>
        </Fragment>
    );
};

export default PrintResults;
