import { Fragment } from 'react';
import { Button, InputField, SectionTitle } from '../../../components';
import styles from './index.module.css';

const CalculationAuthor = ({ state, onChange, onSubmit }) => {
    return (
        <Fragment>
            <div className={styles.wrapper}>
                <SectionTitle title="Dane w nagłówku raportów pdf" />
                <div className={`backgroundAround`}>
                    <div className={styles.inputSpace}>
                        <InputField
                            value={state.reportDetails.author.value}
                            isValid={state.reportDetails.author.isValid}
                            onChange={onChange}
                            name="author"
                            type="text"
                            title="Autor obliczeń"
                            placeholder="Autor obliczeń"
                            disable={true}
                            required
                        />
                    </div>
                    <div className={styles.inputSpace}>
                        <InputField
                            value={state.reportDetails.contact.value}
                            isValid={state.reportDetails.contact.isValid}
                            onChange={onChange}
                            name="contact"
                            type="text"
                            title="Dane kontaktowe"
                            placeholder="Dane kontaktowe"
                            disable={true}
                            required
                        />
                    </div>
                    <div className={styles.buttonToRight}>
                        <Button
                            onClick={onSubmit}
                            css={'default-button-style'}
                            name="saveChanges"
                            altTitle="Zapisz zmiany"
                            title="Zapisz zmiany"
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default CalculationAuthor;
