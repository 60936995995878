import { Fragment } from 'react';
import { Table } from 'react-bootstrap';
import round from '../../../../../utils/roundNumber';
import styles from './index.module.css';

const SheetPileCptSummary = ({ data = [] }) => {

    return (
        <Fragment>
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th>
                            Profil gruntowy<br></br>[-]
                        </th>
                        <th>
                            Typ grodzicy<br></br>[-]
                        </th>
                        <th>
                            z<br></br>[m.p.p.t.]
                        </th>
                        <th>
                            R<sub>b,k</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            R<sub>s,k</sub>=&Sigma;R<sub>s,k,i</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            &gamma;<sub>b,s,t</sub>
                            <br></br>[-]
                        </th> 
                        <th>
                            R<sub>c,d</sub>
                            <br></br>[kN]
                        </th> 
                        {
                            data.map(ele =>  ele.results.results.calculations.sheetPileNumbers).some(ele => ele > 1) &&
                            <th>
                                R<sub>c,d,single</sub>
                                <br></br>[kN]
                            </th>                                        
                        }
                        {
                            data.map(ele =>  ele.results.results.calculations.sheetPileList).some(ele => ele === true) &&
                            <th>
                                R<sub>c,d,meter</sub>
                                <br></br>[kN/mb]
                            </th>
                        }
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    {data.map((element, index) => {
                        return (
                            <tr className={styles.tr} key={index}>
                                <td>{index + 1}</td>
                                <td>{element.profileName}</td>
                                <td>{element.results.results.calculations.sheetPileType}</td>
                                <td>{round(element.results.results.currentCapacity.z, 2)}</td>
                                <td>{round(element.results.results.currentCapacity.Rbk, 2)}</td>
                                <td>{round(element.results.results.currentCapacity.Rsk, 2)}</td>
                                <td>{round(element.results.results.calculations.yt, 2)}</td>
                                <td>{round(element.results.results.currentCapacity.Rcd, 2)}</td>
                                {
                                    element.results.results.calculations.sheetPileNumbers > 1 &&
                                    <td>{round(element.results.results.currentCapacity.Rcd_single, 2)}</td>
                                }
                                {
                                    element.results.results.calculations.sheetPileList 
                                    ?
                                    <td>{round(element.results.results.currentCapacity.Rcd_meter, 2)}</td>
                                    :
                                    <td>-</td>
                                }
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <p className={styles.underline}>Oznaczenia:</p>
            <p>Z - <span className={styles.value}>rzędna zagłębienia pala poniżej poziomu terenu</span></p>
            <p>R<sub>b,k</sub> - <span className={styles.value}>nośność podstawy na rzędnej Z</span></p>
            <p>R<sub>s,k</sub>=&Sigma;R<sub>s,k,i</sub> - <span className={styles.value}>nośność pobocznicy na rzędnej Z</span></p>
            <p>&gamma;<sub>b,s,t</sub> - <span className={styles.value}>współczynnik bezpieczeństwa dla nośności pobocznicy, podstawy lub całkowitej</span></p>
            <p>R<sub>c,d</sub> - <span className={styles.value}>nośność obliczeniowa na wciskanie na rzędnej Z</span></p>
            {
                data.map(ele =>  ele.results.results.calculations.sheetPileNumbers).some(ele => ele > 1) &&
                <p>R<sub>c,d,single</sub> - <span className={styles.value}>nośność obliczeniowa pojedynczego zestawu na wciskanie na rzędnej Z</span></p>                
            }
            {
                data.map(ele =>  ele.results.results.calculations.sheetPileList).some(ele => ele === true) && 
                <p>R<sub>c,d,meter</sub> - <span className={styles.value}>nośność obliczeniowa na wciskanie ścianki na metr bieżący na rzędnej Z</span></p>
            }
        </Fragment>
    );
};

export default SheetPileCptSummary;
