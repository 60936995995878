import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../../../components';
import styles from './index.module.css';

const NavItem = ({ to, css = '', title, active, children }) => {
    const isActiveRoute = active === to;

    return (
        <div className={styles.space}>
            <Button
                css={`
                    ${styles.button} ${isActiveRoute
                        ? `${styles.active} active`
                        : ''} ${css}
                `}>
                <Link to={to} title={title}>
                    {children}
                </Link>
            </Button>
        </div>
    );
};

export default NavItem;
