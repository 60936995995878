import styles from './index.module.css';

const PileLoadsSection = ({ loads = {} }) => {
    return (
        <div className={styles.wrapper}>
            <h2>Obciążenie głowicy pala</h2>
            <p>
                Moment zginający:{' '}
                <span className={styles.value}>
                    {loads.bendingMoment.value} kNm
                </span>
            </p>
            <p>
                Siła pozioma:{' '}
                <span className={styles.value}>
                    {loads.horizontalForce.value} kN
                </span>
            </p>
        </div>
    );
};

export default PileLoadsSection;
