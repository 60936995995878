import styles from './index.module.css';
import { roundNumber } from "../../../../../utils";
import { Table } from "react-bootstrap";

const ResultsCapacityDetails = ({ 
    result={},
}) => {
    const collection = Array.isArray(result.crossSectionCapacity) && Array.isArray(result.crossSectionCapacityNegative) 
        ? 
            result.crossSectionCapacity.map((element,idx) => {
                return {
                    ...element,
                    mrdNeg: -result.crossSectionCapacityNegative[idx].mrd
                }
            })
            .filter((_,idx, arr) => (idx > 0 && idx < arr.length-1) ? idx%5 === 0 : true)
        :
            [];

    return ( 
        <div className={styles.wrapper}>
            <div className={styles.margins}>
                <h2>Szczegółowe wyniki nośności przekroju</h2>
            </div>
            
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th>
                            x<br></br>[mm]
                        </th>
                        <th>
                            M<sup>+</sup><br></br>[kNm]
                        </th>
                        <th>
                            M<sup>-</sup><br></br>[kNm]
                        </th>
                        <th>
                            N<br></br>[kN]
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        collection
                        .map((ele, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{idx + 1}.</td>
                                    <td>{roundNumber(ele.x,2)}</td>
                                    <td>{roundNumber(ele.mrd,2)}</td>
                                    <td>{roundNumber(ele.mrdNeg,2)}</td>
                                    <td>{roundNumber(ele.nrd,2)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </div>
    )
}

export default ResultsCapacityDetails;