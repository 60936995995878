import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import { isNumber, roundNumber, setSoilColor } from '../../../../../utils';
import styles from './index.module.css';
import CorrectDepth from '../../Results/CorrectDepth';
import SoilLayer from './SoilLayer';
import useDeepCompareEffect from 'use-deep-compare-effect';

const MicropileCapacityCompressionChart = ({
    results = [],
    soilProfile = [],
    steelRod = {},
    title = '',
    pullCapacity,
    micropileLength = '',
    micropileHeadElevation = '',
    micropileAngle,
    groundLevel = '',
}) => {
    const soilProfileRef = useRef(null);
    const resultsRef = useRef(null);
    const pileRef = useRef(null);

    const [isMicropileVisible, setIsMicropileVisible] = useState(false);
    const [soilLayer, setSoilLayer] = useState([]);
    const [profileMicropileLength, setProfileMicropileLength] = useState('');
    const [profileMicropileHeadSpot, setProfileMicropileHeadSpot] =
        useState('');
    const [resultsWidth, setResultsWidth] = useState(0);
    const [resultsHeight, setResultsHeight] = useState(0);
    const [maxHeight, setMaxHeight] = useState();
    const [maxNt, setMaxNt] = useState('');
    const [axisNtScale, setAxisNtScale] = useState([]);
    const [currentNt, setCurrentNt] = useState('');
    const [currentCapacity, setCurrentCapacity] = useState({});
    const [micropileChartAngle, setMicropileChartAngle] = useState(0);
    const [pileRefWidth, setPileRefWidth] = useState(0);
    const [correctMicropileLength, setCorrectMicropileLength] = useState({
        compression: '',
        pull: '',
        steelCompression: '',
        steelPull: '',
    });
    const [micropileTotalLength, setMicropileTotalLength] = useState(0);
    const [currentBaseElevation, setCurrentBaseElevation] = useState(0);
    const [correctMicropileHeadElevation, setCorrectMicropileHeadElevation] =
        useState(0);

    useEffect(() => {
        setCorrectMicropileHeadElevation(
            micropileHeadElevation < 0 ? 0 : micropileHeadElevation,
        );
    }, [micropileHeadElevation]);

    useEffect(() => {
        if (pileRef.current) {
            setPileRefWidth(pileRef.current.offsetWidth - 20);
        }
        if (resultsRef.current) {
            setResultsWidth(resultsRef.current.offsetWidth * 0.9);
        }
    }, [isMicropileVisible, pullCapacity]);

    useDeepCompareEffect(() => {
        setMaxHeight(Math.max(...soilProfile.map((ele) => ele.height)));
    }, [soilProfile]);

    useDeepCompareEffect(() => {
        if (soilProfileRef.current) {
            const offsetHeight = soilProfileRef.current.offsetHeight;
            const layers = soilProfile.map((ele, i, arr) => {
                const absHeight =
                    i === 0 ? ele.height : ele.height - arr[i - 1].height;
                return (
                    <SoilLayer
                        key={i}
                        backgroundColor={setSoilColor({
                            id: ele.density,
                            il: ele.plasticity,
                        })}
                        name={ele.name}
                        groundLevel={groundLevel}
                        height={
                            maxHeight === 0
                                ? 0
                                : (offsetHeight *
                                      (i === arr.length - 1
                                          ? absHeight
                                          : absHeight)) /
                                  maxHeight
                        }
                        layerHeight={ele.height}
                    />
                );
            });
            setSoilLayer(layers);
            setResultsHeight(offsetHeight);
        }
    }, [maxHeight, soilProfile, groundLevel]);

    useDeepCompareEffect(() => {
        const nt = pullCapacity
            ? Math.max(...results.map((ele) => ele.ntw))
            : Math.max(...results.map((ele) => ele.nt));
        const maxAxisNt = roundNumber(nt - (nt % 5), 0);
        const maxLength =
            Math.max(...results.map((ele) => ele.z)) -
            correctMicropileHeadElevation;
        const validMicropileLength =
            micropileLength < 0
                ? 0
                : micropileLength > maxLength
                ? maxLength
                : micropileLength;
        const initNt =
            results.find(
                (ele) =>
                    ele.z >=
                    validMicropileLength + correctMicropileHeadElevation,
            ) || {};
        const totalLength = isNumber(initNt.z)
            ? initNt.z - correctMicropileHeadElevation
            : results[results.length - 1].z -
              correctMicropileHeadElevation;

        setMicropileTotalLength(totalLength);
        setCurrentBaseElevation(
            isNumber(initNt.z)
                ? initNt.z
                : results[results.length - 1].z,
        );

        setIsMicropileVisible(
            isNumber(correctMicropileHeadElevation) &&
                maxHeight - correctMicropileHeadElevation > 0,
        );
        setCurrentNt(pullCapacity ? initNt.ntw : initNt.nt);
        setCurrentCapacity(initNt);
        setAxisNtScale(
            Array(5)
                .fill(maxAxisNt / 5)
                .reduce((acc, ele, idx) => [...acc, ele * (idx + 1)], []),
        );
        setMaxNt(nt);

        if (soilProfileRef.current) {
            const maxProfileWidth = pileRefWidth / 2;
            const angleSign = micropileAngle / Math.abs(micropileAngle);
            const maxAngle =
                (Math.atan(
                    maxProfileWidth /
                        ((resultsHeight *
                            (maxHeight - correctMicropileHeadElevation)) /
                            maxHeight),
                ) *
                    180) /
                Math.PI;
            const radianAngle =
                Math.abs(micropileAngle) > maxAngle
                    ? (maxAngle * Math.PI) / 180
                    : (micropileAngle * Math.PI) / 180;
            const maxMicropileLength = totalLength / Math.cos(radianAngle);

            setProfileMicropileHeadSpot(
                maxHeight === 0
                    ? 0
                    : (resultsHeight * correctMicropileHeadElevation) /
                          maxHeight,
            );
            setMicropileChartAngle((angleSign * radianAngle * 180) / Math.PI);
            setProfileMicropileLength(
                maxHeight === 0
                    ? 0
                    : (resultsHeight * maxMicropileLength) / maxHeight,
            );
        }
    }, [
        maxHeight,
        resultsHeight,
        pileRefWidth,
        micropileLength,
        correctMicropileHeadElevation,
        micropileAngle,
        pullCapacity,
        results,
    ]);

    useDeepCompareEffect(() => {
        const buckling = currentCapacity.buckling || {};
        const veryfiedNt = currentCapacity.veryfied || {};
        const veryfiedNtw = currentCapacity.veryfiedNtw || {};
        const axisLoadCorrect = isNumber(veryfiedNt.axisLoad)
            ? veryfiedNt.axisLoad >= 1
                ? true
                : false
            : false;
        const steelRodCorrect = isNumber(veryfiedNt.steelRodLoad)
            ? veryfiedNt.steelRodLoad > 1
                ? false
                : true
            : true;

        const axisLoadCorrectNtw = isNumber(veryfiedNtw.axisLoad)
            ? veryfiedNtw.axisLoad >= 1
                ? true
                : false
            : false;
        const steelRodCorrectNtw = isNumber(veryfiedNtw.steelRodLoad)
            ? veryfiedNtw.steelRodLoad > 1
                ? false
                : true
            : true;

        const isDefRatio = buckling.details
            ? buckling.details.freeSpaceRod
                ? true
                : buckling.details.isDefRatioCorrect
            : '';
        const bucklingCorrect = buckling.details
            ? buckling.details.isAxialForceCorrect &&
              buckling.details.isBuckCorrect &&
              isDefRatio
            : true;

        setCorrectMicropileLength({
            compression: axisLoadCorrect && bucklingCorrect,
            pull: axisLoadCorrectNtw,
            steelCompression: steelRodCorrect,
            steelPull: steelRodCorrectNtw,
        });
    }, [currentCapacity]);

    return (
        <Fragment>
            <div className={styles.chartWrapper}>
                <div className={styles.title}>
                    <h2 className={styles.h2}>{title}</h2>
                </div>
                <div className={styles.wrapper} ref={soilProfileRef}>
                    {isMicropileVisible && (
                        <Fragment>
                            <div
                                className={styles.pileWrapper}
                                style={{
                                    height: `${profileMicropileLength}px`,
                                    top: `${profileMicropileHeadSpot}px`,
                                }}>
                                <div className={styles.resultsWrapper}>
                                    <div
                                        className={styles.pileSchema}
                                        ref={pileRef}>
                                        <div
                                            className={styles.pile}
                                            style={{
                                                transform: `rotate(${
                                                    -micropileChartAngle || 0
                                                }deg)`,
                                            }}>
                                            <div className={styles.steelRod}>
                                                <div
                                                    className={
                                                        styles.rod
                                                    }></div>
                                            </div>
                                            <div className={styles.pileLength}>
                                                <p>
                                                    L ={' '}
                                                    {roundNumber(
                                                        micropileTotalLength,
                                                        2,
                                                    )}{' '}
                                                    m
                                                </p>
                                            </div>
                                        </div>
                                        <div className={styles.spotWrapper}>
                                            <span
                                                className={styles.pileSpotTop}>
                                                {isNumber(
                                                    correctMicropileHeadElevation,
                                                ) ? (
                                                    <Fragment>
                                                        <p
                                                            className={
                                                                styles.elevationMicropile
                                                            }>
                                                            {(-correctMicropileHeadElevation).toFixed(
                                                                2,
                                                            )}
                                                        </p>
                                                        <p
                                                            className={
                                                                styles.elevationMicropile
                                                            }>
                                                            {isNumber(
                                                                groundLevel,
                                                            )
                                                                ? `(${roundNumber(
                                                                      groundLevel -
                                                                          correctMicropileHeadElevation,
                                                                      2,
                                                                  ).toFixed(
                                                                      2,
                                                                  )})`
                                                                : ''}
                                                        </p>
                                                    </Fragment>
                                                ) : (
                                                    ''
                                                )}
                                            </span>
                                            <span
                                                className={
                                                    styles.pileSpotBottom
                                                }
                                                style={{
                                                    textAlign: `${
                                                        micropileChartAngle > 0
                                                            ? 'left'
                                                            : 'right'
                                                    }`,
                                                    left: `${
                                                        micropileChartAngle > 0
                                                            ? '15px'
                                                            : 'auto'
                                                    }`,
                                                    right: `${
                                                        micropileChartAngle <= 0
                                                            ? '15px'
                                                            : 'auto'
                                                    }`,
                                                }}>
                                                {isNumber(
                                                    currentBaseElevation,
                                                ) ? (
                                                    <Fragment>
                                                        <p
                                                            className={
                                                                styles.elevationMicropile
                                                            }>
                                                            {(-currentBaseElevation).toFixed(
                                                                2,
                                                            )}
                                                        </p>
                                                        <p
                                                            className={
                                                                styles.elevationMicropile
                                                            }>
                                                            {isNumber(
                                                                currentBaseElevation,
                                                            )
                                                                ? `(${roundNumber(
                                                                      groundLevel -
                                                                          currentBaseElevation,
                                                                      2,
                                                                  ).toFixed(
                                                                      2,
                                                                  )})`
                                                                : ''}
                                                        </p>
                                                    </Fragment>
                                                ) : (
                                                    ''
                                                )}
                                            </span>
                                        </div>
                                    </div>

                                    <div
                                        className={styles.results}
                                        ref={resultsRef}>
                                        <div
                                            className={styles.horizontalAxis}
                                            style={{
                                                width: `${resultsWidth + 50}px`,
                                            }}>
                                            {axisNtScale.map((ele, idx) => {
                                                return (
                                                    <span
                                                        key={idx}
                                                        className={
                                                            styles.axisCoord
                                                        }
                                                        style={{
                                                            left: `${
                                                                (resultsWidth /
                                                                    maxNt) *
                                                                ele
                                                            }px`,
                                                        }}>
                                                        <p
                                                            className={
                                                                styles.value
                                                            }>
                                                            {ele}
                                                        </p>
                                                    </span>
                                                );
                                            })}

                                            <span className={styles.caret}>
                                                {pullCapacity ? (
                                                    <p
                                                        className={
                                                            styles.axisDescription
                                                        }>
                                                        R<sub>t,d</sub>[kN]
                                                    </p>
                                                ) : (
                                                    <p
                                                        className={
                                                            styles.axisDescription
                                                        }>
                                                        R<sub>c,d</sub>[kN]
                                                    </p>
                                                )}
                                            </span>
                                        </div>
                                        <div className={styles.verticalAxis}>
                                            <span
                                                className={
                                                    styles.verticalCaret
                                                }>
                                                <p
                                                    className={
                                                        styles.axisVerticalDescription
                                                    }>
                                                    Z[m]
                                                </p>
                                            </span>
                                        </div>
                                        <div
                                            className={
                                                styles.currentHorizontalValue
                                            }
                                            style={{
                                                top: `${profileMicropileLength}px`,
                                                width: `${
                                                    (resultsWidth * currentNt) /
                                                    maxNt
                                                }px`,
                                            }}></div>
                                        <div
                                            className={
                                                styles.currentVerticalValue
                                            }
                                            style={{
                                                height: `${profileMicropileLength}px`,
                                                left: `${
                                                    (resultsWidth * currentNt) /
                                                    maxNt
                                                }px`,
                                            }}></div>

                                        {pullCapacity ? (
                                            <div
                                                className={`${
                                                    styles.capacityPoint
                                                } ${
                                                    correctMicropileLength.pull
                                                        ? correctMicropileLength.steelPull
                                                            ? styles.validDepth
                                                            : styles.warningDepth
                                                        : styles.wrongDepth
                                                }`}
                                                style={{
                                                    top: `${
                                                        (resultsHeight *
                                                            (currentCapacity.z -
                                                                correctMicropileHeadElevation)) /
                                                        maxHeight
                                                    }px`,
                                                    left: `${
                                                        (resultsWidth *
                                                            currentCapacity.ntw) /
                                                        maxNt
                                                    }px`,
                                                }}>
                                                <FontAwesomeIcon
                                                    icon={faCheckCircle}
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                className={`${
                                                    styles.capacityPoint
                                                } ${
                                                    correctMicropileLength.compression
                                                        ? correctMicropileLength.steelCompression
                                                            ? styles.validDepth
                                                            : styles.warningDepth
                                                        : styles.wrongDepth
                                                }`}
                                                style={{
                                                    top: `${
                                                        (resultsHeight *
                                                            (currentCapacity.z -
                                                                correctMicropileHeadElevation)) /
                                                        maxHeight
                                                    }px`,
                                                    left: `${
                                                        (resultsWidth *
                                                            currentCapacity.nt) /
                                                        maxNt
                                                    }px`,
                                                }}>
                                                <FontAwesomeIcon
                                                    icon={
                                                        correctMicropileLength.compression
                                                            ? faCheckCircle
                                                            : faTimesCircle
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}
                    {[...soilLayer]}
                </div>
            </div>
            <div className={styles.capacityDescription}>
                <p>
                    Długość mikropala [L] -{' '}
                    <span className={styles.spanValue}>
                        <b>
                            {isNumber(micropileTotalLength) &&
                            isNumber(micropileAngle)
                                ? roundNumber(
                                    micropileTotalLength /
                                        Math.cos(
                                            (Math.abs(micropileAngle) * Math.PI) /
                                                180,
                                        ),
                                    2,
                                )
                                : ''}{' '}
                            m
                        </b>
                    </span>
                </p>
                {isNumber(steelRod.m) &&
                    isNumber(micropileTotalLength) &&
                    isNumber(micropileAngle) && (
                        <p>
                            Ciężar żerdzi mikropala [m] -{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {steelRod.m *
                                        roundNumber(
                                            micropileTotalLength /
                                                Math.cos(
                                                    (Math.abs(micropileAngle) *
                                                        Math.PI) /
                                                        180,
                                                ),
                                            2,
                                        )}
                                </b>
                            </span>
                        </p>
                    )}
                {pullCapacity ? (
                    <Fragment>
                        <p>
                            Nośność na wyciąganie mikropala [R<sub>t,d</sub>] -{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(currentCapacity.ntw)
                                        ? roundNumber(currentCapacity.ntw, 0)
                                        : ''}{' '}
                                    kN
                                </b>
                            </span>
                        </p>
                        <p>
                            Siła do badania próbnego mikropala [R<sub>t,lt</sub>] -{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(currentCapacity.ntw)
                                        ? roundNumber(currentCapacity.ntw * 1.5, 0)
                                        : ''}{' '}
                                    kN
                                </b>
                            </span>
                        </p>

                        <p className={styles.underline}>UWAGI:</p>
                        <CorrectDepth
                            correctDepth={{
                                micropileCapacity: currentCapacity.veryfiedNtw,
                            }}
                        />
                    </Fragment>
                ) : (
                    <Fragment>
                        <p>
                            Nośność podstawy [R<sub>b</sub>] -{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(currentCapacity.np)
                                        ? roundNumber(currentCapacity.np, 0)
                                        : ''}{' '}
                                    kN
                                </b>
                            </span>
                        </p>
                        <p>
                            Nośność pobocznicy [R<sub>s</sub>] -{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(currentCapacity.ns)
                                        ? roundNumber(currentCapacity.ns, 0)
                                        : ''}{' '}
                                    kN
                                </b>
                            </span>
                        </p>
                        <p>
                            Nośność na wciskanie mikropala [R<sub>c,d</sub>] -{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(currentCapacity.nt)
                                        ? roundNumber(currentCapacity.nt, 0)
                                        : ''}{' '}
                                    kN
                                </b>
                            </span>
                        </p>
                        <p>
                            Siła do badania próbnego mikropala [R<sub>c,lt</sub>] -{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(currentCapacity.nt)
                                        ? roundNumber(currentCapacity.nt * 1.5, 0)
                                        : ''}{' '}
                                    kN
                                </b>
                            </span>
                        </p>

                        <p className={styles.underline}>UWAGI:</p>
                        <CorrectDepth
                            correctDepth={{
                                micropileCapacity: currentCapacity.veryfied,
                                buckling: currentCapacity.buckling,
                            }}
                        />
                    </Fragment>
                )}                
            </div>

        </Fragment>
    );
};

export default MicropileCapacityCompressionChart;
