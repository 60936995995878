import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';
import styles from './index.module.css';

const SingleElement = ({
    title,
    wrapperTitle,
    description,
    icon,
    css = '',
}) => {
    return (
        <div
            className={`${styles.wrapper} backgroundAround statsWrapper`}
            title={wrapperTitle}>
            <Row className={styles.row}>
                <Col className={styles.col} xs={8}>
                    <div>
                        <h4 className={styles.h4}>{title}</h4>
                        <h2 className={styles.h2}>{description}</h2>
                    </div>
                </Col>
                {icon && (
                    <Col className={`${styles.col} ${styles.flexRight}`} xs={4}>
                        <div className={`${styles.icon} stat ${css}`}>
                            <FontAwesomeIcon icon={icon} />
                        </div>
                    </Col>
                )}
            </Row>
        </div>
    );
};

export default SingleElement;
