import React from 'react';
import styles from './index.module.css';

const Loading = ({ text, oppositeColors=false, css='', wrapperCss }) => {

    return (
        <div className={`${styles.wrapper} ${wrapperCss}`}>
            <div className={`${styles.center} ${oppositeColors ? 'oppositeLoadingColors' : 'loadingColor'}`}>
                <div className={`${styles.icon} ${css}`}></div>
                { text && <h5 className={styles.h5}>{text}</h5> }                 
            </div>
        </div>
    )
}

export default Loading