import { Fragment } from 'react';
import styles from './index.module.css';

const SaftyFactorsSection = ({ parameters = {} }) => {
    return (
        <div className={styles.wrapper}>
            <h2>Parametry analizy oraz współczynniki bezpieczeństwa</h2>

            {parameters.isSteelRod && (
                <Fragment>
                    <p>
                        Siła do wymiarowania odcinka nośnego:{' '}
                        <span className={styles.value}>{`${
                            parameters.bondCapacityType === 1
                                ? 'Podana siła osiowa'
                                : 'Nośność obliczeniowa żerdzi'
                        }`}</span>
                    </p>
                    <p>
                        Sposób doboru żerdzi:{' '}
                        <span className={styles.value}>{`${
                            parameters.steelRodCapacity === 1
                                ? 'Nośność plastyczna żerdzi'
                                : 'Nośność na zerwanie żerdzi'
                        }`}</span>
                    </p>
                    <p>
                        Współczynnik bezpieczeństwa dla stali - &#947;
                        <sub>M</sub>:{' '}
                        <span className={styles.value}>
                            {parameters.steelRodYt}
                        </span>
                    </p>
                </Fragment>
            )}
            {parameters.designToCharLoads && (
                <p>
                    Współczynnik obciążeniowy (obliczeniowe na
                    charakterystyczne) - &#947;<sub>G</sub>:{' '}
                    <span className={styles.value}>
                        {parameters.designToCharLoads}
                    </span>
                </p>
            )}
            <p>
                Częściowy współczynnik do nośności pobocznicy dla wyciągania -
                &#947;<sub>s,t</sub>:{' '}
                <span className={styles.value}>{parameters.yt}</span>
            </p>
            <p>
                Liczba zbadanych profili gruntowych:{' '}
                <span className={styles.value}>
                    {parameters.profilesNumber}
                </span>
            </p>
            <p>
                Współczynnik korelacyjny do nośności charakterystycznej -
                &#11420;<sub>{parameters.capacityType === 1 ? 3 : 4}</sub>:{' '}
                <span className={styles.value}>{parameters.ksi}</span>
            </p>
            <p>
                Współczynnik interpolacji granicznego oporu pobocznicy - q
                <sub>s,k</sub>:{' '}
                <span className={styles.value}>
                    {parameters.qccuInterpolation}
                </span>
            </p>
            <p>
                Uwzględniono grunty słabe:{' '}
                <span className={styles.value}>
                    {parameters.softSoilCount ? 'Tak' : 'Nie'}
                </span>
            </p>
            <p>
                Współczynnik poszerzenia średnicy w żwirach i pospółkach:{' '}
                <span className={styles.value}>{parameters.gravel}</span>
            </p>
            <p>
                Współczynnik poszerzenia średnicy w piaskach:{' '}
                <span className={styles.value}>{parameters.sands}</span>
            </p>
            <p>
                Współczynnik poszerzenia średnicy w gruntach skalistych:{' '}
                <span className={styles.value}>{parameters.rocks}</span>
            </p>
            <p>
                Współczynnik poszerzenia średnicy w gruntach spoistych:{' '}
                <span className={styles.value}>{parameters.cohesiveSoils}</span>
            </p>

            <p>
                Wartość poszerzenia średnicy w żwirach i pospółkach na podstawie
                badań CPT:{' '}
                <span className={styles.value1}>{parameters.gravelCpt}mm</span>
            </p>
            <p>
                Wartość poszerzenia średnicy w piaskach na podstawie badań CPT:{' '}
                <span className={styles.value1}>{parameters.sandsCpt}mm</span>
            </p>
            <p>
                Wartość poszerzenia średnicy w gruntach skalistych na podstawie
                badań CPT:{' '}
                <span className={styles.value1}>{parameters.rocksCpt}mm</span>
            </p>
            <p>
                Wartość poszerzenia średnicy w gruntach spoistych na podstawie
                badań CPT:{' '}
                <span className={styles.value1}>
                    {parameters.cohesiveSoilsCpt}mm
                </span>
            </p>
        </div>
    );
};

export default SaftyFactorsSection;
