import styles from './index.module.css';
import { SectionTitle, Table } from '../../../../../components';
import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';
import round from '../../../../../utils/roundNumber';

const DesignShearTable = ({ 
    results,
    stirrupType='Strzemiona',
    shearCondition={},
    memberType,
    nCut,
    spiral
}) => {

    return (
        <Fragment>
            <SectionTitle
                title={<Fragment>Zbrojenie na ścinanie</Fragment>}
            />
            <Table>
                <thead className={styles.thead}>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th>
                            Rodzaj zbrojenia<br></br>[-]
                        </th>
                        <th>
                            Średnica<br></br>[mm]
                        </th>
                        <th>
                            n-cięte<br></br>[-]
                        </th>
                        <th title='Wymiar w osiach prętów'>
                            {stirrupType === 'Strzemiona' ? 'Rozstaw strzemion' : 'Skok spirali' }<br></br>[mm]
                        </th>
                        <th title='Maksymalny wymiar w osiach prętów'>
                        {stirrupType === 'Strzemiona' ? 'Max. rozstaw strzemion' : 'Max. skok spirali' }<br></br>[mm]
                        </th>
                        <th>
                            Warunek<br></br>[-]
                        </th>
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    <tr>
                        <td>1</td>
                        <td>{stirrupType}</td>
                        <td>{spiral}</td>
                        <td>{nCut || 2}</td>
                        <td>{round(results.s,2)}</td>
                        <td>{round(results.smin,2)}</td>
                        <td className={shearCondition.value ? styles.valid : styles.inValid}>
                            <FontAwesomeIcon
                                icon={shearCondition.value ? faCheck : faBan}
                            />
                            <div className={styles.info}>
                                Minimalny rozstaw zbrojenia na ścinanie nie został znormalizowany. Ze względów technologicznych zaleca się:
                                <ul>
                                    <li>b/2; b - szerokość przekroju</li>
                                    <li>100mm</li>
                                </ul>
                                <Fragment>
                                    Maksymalne odstępy między prętami:
                                    {
                                        memberType === 'Belka' 
                                        ?
                                        <Fragment>
                                            <ul>
                                                <li>0.75&#183;(1 + cot&#945;) - strzemiona</li>
                                                <li>0.60&#183;(1 + cot&#945;) - pręty odgięte</li>
                                                <li>600mm</li>
                                            </ul>
                                            <p>&#945; - kąt nachylenia strzemion, prętów odgiętych.</p>
                                        </Fragment>
                                        :
                                        <Fragment>
                                            <ul>
                                                <li>20 średnic zbrojenia podłużnego</li>
                                                <li>długość krótszego boku słupa</li>
                                                <li>400mm</li>
                                            </ul>
                                            Podstawowy rozstaw strzemion s<sub>1</sub> należy zmniejszyć do s<sub>2</sub>=0.6&#183;s<sub>1</sub> w przypadkach:
                                            <ul>
                                                <li>powyżej i poniżej belki lub płyty na odcinkach o długości l<sub>2</sub>=h (większego boku słupa)</li>
                                                <li>na odcinkach połączenia na zakład prętów o średnicy większej niż 14mm. Wówczas wymaga się przynajmniej trzech strzemion rozmieszczonych równomiernie na długości zakładu</li>
                                            </ul>
                                        </Fragment>
                                    }
                                </Fragment>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </Table>            
        </Fragment>

    )
}

export default DesignShearTable;