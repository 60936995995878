import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './index.module.css';

const NavItem = ({
    to,
    toResults,
    title,
    fontIcon,
    textIcon,
    children,
    onClick,
    role,
    active,

    icon
}) => {
    const isActiveLink = active && (active === to || active === toResults);

    return (
        <li className={styles.li}>
            {role === 'button' ? (
                <button
                    className={styles.button}
                    title={title}
                    onClick={onClick}>
                    {textIcon && <i>{textIcon}</i>}
                    {fontIcon && (
                        <i>
                            <FontAwesomeIcon icon={fontIcon} />
                        </i>
                    )}

                    <span>{children}</span>
                </button>
            ) : (
                <Link
                    to={to}
                    title={title}
                    className={isActiveLink ? `${styles.active} active` : ''}>
                    {textIcon && <i className={styles.textIcon}>{textIcon}</i>}
                    {
                        <img alt='' className={styles.icon} src={icon} />
                    }
                    {fontIcon && (
                        <i>
                            <FontAwesomeIcon icon={fontIcon} />
                        </i>
                    )}
                    <span>{children}</span>
                </Link>
            )}
        </li>
    );
};

export default NavItem;
