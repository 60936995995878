import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styles from './index.module.css';

const SearchInput = ({ 
    value='', 
    placeholder,
    title, 
    onChange, 
    ...rest 
}) => {

    return (
        <div className={styles.wrapper}>
            <div className={`lock-input`}> 
                <div className={styles.fullWidth}>
                    <input
                        className={styles.inputField}
                        type="text" 
                        name="search"
                        placeholder={placeholder}
                       
                        onChange={onChange} 
                        {...rest}
                    />
                </div>
                <div className={`${styles.groupIcon}`}>
                    <FontAwesomeIcon icon={faSearch} />
                </div>
            </div>
        </div>
    )
}

export default React.memo(SearchInput)