import { Outlet, useOutletContext } from "react-router-dom"
import AdminHomeProvider from "../../../context/AdminHome"

const AdminHomeLayout = () => {
    return (
        <AdminHomeProvider>
            <Outlet context={useOutletContext()}/>
        </AdminHomeProvider>
    )
}

export default AdminHomeLayout;