import { useContext } from 'react';
import { useState } from 'react';
import { isObjectId } from '../../config/ProjectConfig';
import { authContext } from '../../context/Authentication';
import { AuthFetch } from '../../routes';

import { useIsMountedState } from '../../hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';

const LoadAndRemoveProject = ({
    profileStateChanged,
    setLoading,
    setAlert,
    urlOnLoad,
    urlOnRemove,
    urlOnLoadCalculations,
}) => { 
    const { email: userEmail } = useContext(authContext);

    const [projects, setProjects] = useState([]);
    const [noProjects, setNoProjects] = useState(false);
    const [noCalculations, setNoCalculations] = useState(false);
    const [stateChanged, setStateChanged] = useState(true);
    const [mainProjectName, setMainProjectName] = useState({
        value: '',
    });
    const [calculations, setCalculations] = useState([]);
    const [currentUser, setCurrentUser] = useState('');
    const [currentProjectId, setCurrentProjectId] = useState('');
    const [currentUserProjects, setCurrentUserProjects] = useState([]);
    const isMountedState = useIsMountedState();

    useDeepCompareEffect(() => {
        const userEmailMatch = currentUser.match(/\(([^)]+)\)/);
        const currentUserEmail = userEmailMatch ? userEmailMatch[1] : userEmail;
        const currentUserObj =
            projects.length > 0
                ? projects.find((ele) => ele.email === currentUserEmail)
                : undefined;
        const currentUserObjProjects = currentUserObj
            ? currentUserObj.projects
            : undefined;

        if (currentUserObjProjects) {
            setCurrentUserProjects(currentUserObjProjects);
            setMainProjectName((state) => ({
                value:  state.value ? state.value : currentUserObjProjects[0].name,
                isValid: true,
            }));
        } else {
            setCurrentUserProjects([]);
            setCurrentUser('');
            setMainProjectName(() => ({
                value: '',
                isValid: null,
            }));
        }
    }, [currentUser, userEmail, projects]);

    useDeepCompareEffect(() => {
        const projectByName = currentUserProjects.find(
            (ele) => ele.name === mainProjectName.value,
        );

        if (projectByName) {
            setCurrentProjectId(projectByName.id);
            AuthFetch({
                url: `${urlOnLoadCalculations}/${projectByName.id}`,
                method: 'GET',
            })
            .then((response) => {
                if(isMountedState.current){
                    setNoCalculations(false);
                    if (response.success) {
                        setStateChanged(false);
                        setCalculations(() => [...response.responseData]);
                        if (response.responseData.length === 0) {
                            setNoCalculations(true);
                        }
                    } else {
                        setAlert({
                            error: true,
                            message:
                                'Błąd podczas wczytywania obliczeń',
                        });
                    }                        
                }
            })
            .catch(() => {
                if(isMountedState.current){
                    setAlert({
                        error: true,
                        message:
                            'Błąd podczas wczytywania obliczeń',
                    });
                }
            });
        }
    }, [
        mainProjectName.value,
        stateChanged,
        profileStateChanged,
        currentUserProjects,
        setAlert,
        urlOnLoadCalculations,
        isMountedState
    ]);

    const onLoadProjects = () => {
        setLoading({
            name: 'fetchAllProjects',
            loading: !stateChanged ? false : true,
        });
        AuthFetch({
            url: urlOnLoad,
            method: 'GET',
        })
        .then((response) => {
            setLoading({ name: 'fetchAllProjects', loading: false });
            setNoProjects(false);
            if (response.success) {
                setStateChanged(false);
                if (response.responseData.projects.length === 0) {
                    setNoProjects(true);
                } else {
                    setProjects(() => {
                        return response.responseData.projects.reduce(
                            (acc, ele) => {
                                const { userId, ...rest } = ele;
                                const userExistsIndex =
                                    acc.length > 0
                                        ? acc.findIndex(
                                                (ele) => ele.id === userId.id,
                                            )
                                        : -1;

                                if (userExistsIndex > -1) {
                                    acc[userExistsIndex] = {
                                        ...acc[userExistsIndex],
                                        projects: [
                                            ...acc[userExistsIndex]
                                                .projects,
                                            {
                                                ...rest,
                                                userId: userId.id,
                                            },
                                        ],
                                    };
                                    return [...acc];
                                }
                                return [
                                    ...acc,
                                    {
                                        ...userId,
                                        projects: [
                                            {
                                                ...rest,
                                                userId: userId.id,
                                            },
                                        ],
                                    },
                                ];
                            },
                            [],
                        );
                    });
                }
            } else {
                setAlert({
                    error: true,
                    message: 'Błąd podczas wczytywania projektów',
                });
            }
        })
        .catch(() => {
            setAlert({
                error: true,
                message: 'Błąd podczas wczytywania projektów',
            });
        });
    };

    const onRemoveProject = (id) => {
        const projectByName =
            currentUserProjects.find(
                (ele) => ele.name === mainProjectName.value,
            ) || {};
        if (isObjectId(id) && isObjectId(projectByName.id)) {
            setLoading({            
                name: 'removeCalculation',
                loading: true
            });

            AuthFetch({
                url: `${urlOnRemove}/${id}/${projectByName.id}`,
                method: 'DELETE',
            })
            .then((response) => {
                setLoading({            
                    name: 'removeCalculation',
                    loading: false
                });
                if (response.success) {
                    setStateChanged(true);
                    setAlert({
                        error: false,
                        message: 'Projekt został usunięty',
                        hideTime: 2000,
                    });
                    setCalculations((state) => [
                        ...state.filter(
                            (ele) => ele.id !== response.responseData.id,
                        ),
                    ]);
                } else {
                    setAlert({
                        error: true,
                        message: 'Błąd podczas usuwania projektu',
                    });
                }
            })
            .catch(() => {
                setLoading({            
                    name: 'removeCalculation',
                    loading: true
                });
                setAlert({
                    error: true,
                    message: 'Błąd podczas usuwania projektu',
                });
            });
        } else {
            setAlert({
                error: true,
                message: 'Błąd podczas usuwania projektu',
            });
        }
    };

    const onChange = (e) => {
        const target = e.currentTarget;
        if (target.name === 'currentUser') {
            setCurrentUser(target.value);
        } else {
            setMainProjectName((state) => ({
                ...state,
                value: target.value,
            }));
        }
    };

    const loadAndRemoveProject = {
        onLoadProjects: onLoadProjects,
        onRemoveProject: onRemoveProject,
        onStateChange: setStateChanged,
        onSetMainProjectName: setMainProjectName,
        onChange: onChange,
        stateChanged,
        state: {
            projects,
            mainProjectName,
            calculations,
            noCalculations,

            userEmail,
            noProjects,
            currentUser,
            currentProjectId,
            currentUserProjects,
        },
    };

    return {
        loadAndRemoveProject,
    };
};

export default LoadAndRemoveProject;
