import { useState } from 'react';

const initState = {
    allItems: false,
    projectInfo: false,
    pileParameters: false,

    capacityDetails: false,
    helperFactorsDetails: false,

    pileChart: false,
    cptChart: false,
    resistanceSettlement: false,
    capacityTable: false,

    firstPage: false
};
const PrintResultsHelpers = (data) => { 
    const [reportItem, setRaportItem] = useState(initState);

    const onChangeRaportItems = (e) => {
        const input = e.currentTarget.closest('input');

        if (input) {
            const { name, checked } = input;

            if (name === 'allItems') {
                setRaportItem((state) => ({
                    ...state,
                    ...Object.fromEntries(
                        Object.entries(state).map(([key, val]) => {
                            return [key, !!checked ? true : false];
                        }),
                    ),
                }));
            } else {
                setRaportItem((state) => ({
                    ...state,
                    [name]: !!checked,
                }));
            }
        }
    };

    const printResults = {
        onChange: onChangeRaportItems,
        initState: () => setRaportItem(initState),
        state: {
            reportItem,
            ...data,
        },
    };

    return {
        printResults,
    };
};

export default PrintResultsHelpers;
