import React, { createContext } from 'react';
import { 
    LoadingService, 
    GlobalAlertService,
    FileNameToSave,
    SaveProjectService,
    NewProjectService,
    LoadAndRemoveProjectService,
    ProjectTitleService,
    SoilTableService,
    DefaultSoilService,
    CalculationsService,
    SubmitCalculationsService,

    ResultsService,
    PrintResultsService,
    EditProjectService,
    EditSoilService,
    SaveSoilService
} from '../../services/SheetPileCpt'; 
 
const sheetPileCptContext = createContext({});

const SheetPileCptProvider = ({ children }) => {
    const { globalAlerts }          = GlobalAlertService();
    const { globalLoading }         = LoadingService();
    const { fileNameToSave }        = FileNameToSave(); 
    const soilProfileFileNameToSave = FileNameToSave();
    const { projectTitle }          = ProjectTitleService(); 
    const { results }               = ResultsService(); 
    const { calculationState }      = CalculationsService({
        updateResultsState: results.updateState,
    });
    const { soilTable }             = SoilTableService({
        setAlert: globalAlerts.addAlert,
        zwg: calculationState.state.zwg,
        cptLevel: calculationState.state.cptLevel,
        updateCalculation: calculationState.updateState,
        updateResultsState: results.updateState,
    });
    const { editSoilProfile } = EditSoilService({
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad, 
        updateSoilTable: soilTable.onLoad, 
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        fetch: {
            getProfiles: {
                url: '/api/soils/cpt/parameters/profiles',
                method: 'GET'
            },
            getProjectsName: {
                url: '/api/projects/list/names',
                method: 'GET'
            },
            removeProfile: {
                url: '/api/soils/cpt/parameters/edit',
                method: 'DELETE'
            },
            loadProfile: {
                url: '/api/soils/cpt/parameters/edit',
                method: 'GET'
            }
        }
    });
    const { defaultSoilLayer } = DefaultSoilService(soilTable.updateState);
    const { submitCalculation } = SubmitCalculationsService({
        soilTable: { state: soilTable.state, updateState: soilTable.updateState },
        calculations: { state: calculationState.state, updateState: calculationState.updateState },
        results: { 
            updateState: results.updateState,
            updateCurrentSheetPileLength: results.onChangeCurrentSheetPileLength,
            updateCurrentCapacity: results.onChangeCurrentCapacity
        },
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading 
    });
    const { loadAndRemoveProject } = LoadAndRemoveProjectService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        urlOnLoad: '/api/projects/list/names',
        urlOnRemove: '/api/sheetpile/cpt/project/',
        urlOnLoadCalculations: '/api/sheetpile/cpt/projects'
    });
    const { printResults } = PrintResultsService({
        projectTitle: projectTitle.state, 
        soilsCollection: soilTable.state, 
        calculations: calculationState.state,
        currentPileLength: results.currentPileLength,
        results: {
            ...results.state,
        }
    });
    const { editProject } = EditProjectService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,

        loadSoilTable: soilTable.loadProject,
        loadCalculationState: calculationState.loadProject,
        loadTitle: projectTitle.loadProject,
        loadResults: results.loadProject,
        loadCurrentPileLength: results.onChangeCurrentSheetPileLength,
        loadCurrentCapacity: results.onChangeCurrentCapacity,

        onLoadFileName: fileNameToSave.onLoad,
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad, 

        onSetMainProjectName: loadAndRemoveProject.onSetMainProjectName,
        onSetSoilProfileMainProjectName: editSoilProfile.onSetMainProjectName,

        initProject: () => {
            soilTable.initState();
            calculationState.initState();
            results.initState();
            printResults.initState();
        },

        projectId: loadAndRemoveProject.state.currentProjectId
    });
    const { saveSoilProfile } = SaveSoilService({
        fileNameToSave: soilProfileFileNameToSave.fileNameToSave.fileName,
        initMainProjectName: loadAndRemoveProject.state.mainProjectName,
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad, 
        onChangeState: editSoilProfile.onStateChange,
        model: {
            soilsCollection: soilTable.state
        },
        fetch: {
            projectList: {
                url: '/api/projects/list/names',
                method: 'GET'
            },
            saveSoil: {
                url: '/api/soils/cpt/parameters/edit',
                method: 'POST'
            },
            editSoil: {
                url: '/api/soils/cpt/parameters/edit',
                method: 'PUT'
            },
        },
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading
    }); 

    const { saveProject } = SaveProjectService({
        fileNameToSave: fileNameToSave.fileName,
        onLoadProjectName: fileNameToSave.onLoad, 
        projectTitle: projectTitle.state,

        currentProjectId: editProject.currentProjectId,
        currentProfileId: editProject.currentProfileId,
        currentUserEmail: loadAndRemoveProject.state.currentUser,
        loadProjectState: editProject.loadProjectState,
         
        model: { 
            soilsCollection: soilTable.state, 
            calculationsSettings: calculationState.state,
        },
        results: { 
            isResultsActive: results.isResultsActive,
            isResultsActual: results.state.isResultsActual,
            results: results.state
        },

        fetch: {
            save: {
                url: '/api/sheetpile/cpt/saveProject',
                method: 'POST'
            }, 
            overwrite: { 
                url: '/api/sheetpile/cpt/saveProject', 
                method: 'PUT'
            },
            create: {
                url: '/api/projects/create',
                method: 'POST'
            },
            projectList: {
                url: '/api/projects/cpt/list',
                method: 'GET'
            }
        },
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        onChangeState: loadAndRemoveProject.onStateChange,
        onProjectStateChange: saveSoilProfile.onProjectStateChange,
        onProfileStateChange: saveSoilProfile.onProfileStateChange,
        profileStateChange: saveSoilProfile.profileStateChange,
        removeProfileStateChange: editSoilProfile.state.stateChanged,

        saveProjectToLocalStorage: (str) => localStorage.setItem('sheetPileCpt', str)
    });

    const { newProject } = NewProjectService({
        onLoadProjectName: fileNameToSave.onLoad, 
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad,
        initProject: [
            () => localStorage.removeItem('sheetPileCpt'),
            globalAlerts.initState,
            globalLoading.initState,
            projectTitle.initState,
            soilTable.initState,
            calculationState.initState,
            results.initState,
            printResults.initState
        ]
    });

    return (
        <sheetPileCptContext.Provider 
            value={{ 
                globalAlerts,
                globalLoading,
                fileNameToSave,
                newProject,
                editSoilProfile,
                saveSoilProfile,
                editProject,
                loadAndRemoveProject,
                printResults,
                projectTitle,
                saveProject,
                soilTable,
                defaultSoilLayer,
                calculationState,
                submitCalculation,
                results
            }}
        >
            {children}
        </sheetPileCptContext.Provider>
    )
}

export { sheetPileCptContext }
export default SheetPileCptProvider;