import { useState, useEffect } from "react"; 
import { isObjectId } from "../../../config/ProjectConfig";
import { AuthFetch } from "../../../routes";
import stringify from 'fast-json-stable-stringify';

const EditProjectHelpers = ({ 
    setAlert,
    setLoading, 
    loadSoilTable, 
    loadCalculationState, 
    loadResults, 
    loadTitle,
    loadCurrentPileLength,
    loadCurrentCapacity,

    onLoadFileName,
    onLoadProfileName,

    onSetMainProjectName,
    onSetSoilProfileMainProjectName,

    initProject,
 
    projectId
}) => { 
    const [currentProjectId, setCurrentProjectId] = useState('');
    const [currentProfileId, setCurrentProfileId] = useState('');
    const [loadProjectState, setLoadProjectState] = useState(false);
    const [currentProjectLoaded, setCurrentProjectLoaded] = useState(false);
    const cptPileCurrentProject = JSON.parse(localStorage.getItem('sheetPileCpt')) || {};

    const updateStateOnLoad = (response) => {
        setLoadProjectState(true);
        onSetMainProjectName(() => ({ 
            isValid: true,
            value: response.responseData.projectId.name
        }));
        onSetSoilProfileMainProjectName(() => ({
            value: response.responseData.projectId.name
        }));

        setCurrentProjectId(response.responseData.projectId.id);
        setCurrentProfileId(response.responseData.profileId);
        const { model={}, results={}, projectName='' } = response.responseData;
        onLoadFileName({
            state: projectName ? 'loaded' : 'init',
            value: projectName
        });
        onLoadProfileName({
            state: 'init',
            value: ''
        });

        loadSoilTable(model.soilsCollection);
        loadCalculationState(model.calculationsSettings);

        loadTitle({
            projectTitle: model.projectTitle
        });
        setTimeout(() => {
            const { currentPileLength, currentCapacity, ...restResults } = results.results;
            loadResults({
                isResultsActive: results.isResultsActive,
                isResultsActual: results.isResultsActual,
                ...restResults
            });
            loadCurrentPileLength(currentPileLength);
            loadCurrentCapacity(currentCapacity);
        },0);
    }

    useEffect(() => {
        if(!currentProjectLoaded && cptPileCurrentProject.id && cptPileCurrentProject.projectId){
            setLoadProjectState(false);
            AuthFetch({
                url: `/api/sheetpile/cpt/project/${cptPileCurrentProject.id}/${cptPileCurrentProject.projectId}`,
                method: 'GET',
            }).then(response => {
                if(response.success){
                    setCurrentProjectLoaded(true);
                    updateStateOnLoad(response);                    
                }
            })
        }
    },[currentProjectLoaded, cptPileCurrentProject.id, cptPileCurrentProject.projectId])
    
    const onLoadProject = (id)=> {
        if(isObjectId(id) && isObjectId(projectId)){
            setLoading({ name: 'fetchProject', loading: true });
            setLoadProjectState(false);
            AuthFetch({
                url: `/api/sheetpile/cpt/project/${id}/${projectId}`,
                method: 'GET',
            })
            .then(response => {
                setLoading({ name: 'fetchProject', loading: false });
                setLoadProjectState(true)
                if(response.success) {
                    initProject();
                    localStorage.setItem('sheetPileCpt', stringify({ id: response.responseData.id, projectId: response.responseData.projectId.id }));
                    setCurrentProjectLoaded(true);
                    updateStateOnLoad(response);
                } else {
                    setAlert({ error: true, message: 'Błąd podczas wczytywania projektu' });
                }
            })
            .catch(() => {
                setAlert({ error: true, message: 'Błąd podczas wczytywania projektu' });
            })
        }
    }
 
    const editProject = {
        onLoadProject: onLoadProject,
        currentProjectId: currentProjectId,
        currentProfileId: currentProfileId,
        loadProjectState: loadProjectState
    }

    return {
        editProject
    }
}

export default EditProjectHelpers