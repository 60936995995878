import { Fragment } from 'react';
import DescriptionDetails from './DescriptionDetails';

const ResultsOverviewSection = ({
    parameters: {
        slipPlate = {},
        deepPlate = {},
        steelRodWeight = '',
        isSteelRod,
        rodModule,
    },
}) => {
    return (
        <Fragment>
            <DescriptionDetails
                results={slipPlate.axisLoadCapacity}
                steelRodWeight={steelRodWeight}
                rodModule={rodModule}
                isSteelRod={isSteelRod}
                title="Nośność mikropala z uwzględnieniem klina odłamu - projektowana siła osiowa"
            />
            <DescriptionDetails
                results={deepPlate.axisLoadCapacity}
                steelRodWeight={steelRodWeight}
                rodModule={rodModule}
                isSteelRod={isSteelRod}
                title="Nośność mikropala z uwzględnieniem głębokiej powierzchni poślizgu - projektowana siła osiowa"
            />
            {isSteelRod && (
                <Fragment>
                    <DescriptionDetails
                        results={slipPlate.steelRodCapacity}
                        steelRodWeight={steelRodWeight}
                        rodModule={rodModule}
                        title="Nośność mikropala z uwzględnieniem klina odłamu - nośność obliczeniowa żerdzi"
                    />
                    <DescriptionDetails
                        results={deepPlate.steelRodCapacity}
                        steelRodWeight={steelRodWeight}
                        rodModule={rodModule}
                        title="Nośność mikropala z uwzględnieniem gł. powierzchni poślizgu - nośność obliczeniowa żerdzi"
                    />
                </Fragment>
            )}
        </Fragment>
    );
};

export default ResultsOverviewSection;
