import isNumber from "../../isNumber"

const longTermLoadFactor = ({id, il}, temporaryConstruction) => {
    if(temporaryConstruction){
        return 1
    } else {
        if(isNumber(id) && !isNumber(il)){
            if(id > 0.67){
                return 0.65
            } else if ((id > 0.33) && (id <= 0.67)){
                return 0.45
            }
            else if((id >= 0.2) && (id <= 0.33)){
                return 0.35
            }
            else if(id < 0.2){
                return 0.3
            } else {
                return 0.3
            }
        } else if(isNumber(il) && !isNumber(id)){
            if(il <= 0){
                return 0.65
            } else if ((il > 0) && (il <= 0.25)){
                return 0.45
            }
            else if((il > 0.25) && (il <= 0.5)){
                return 0.35
            }
            else if(il > 0.5){
                return 0.3
            } else {
                return 0.3
            }
        } else {
            return ''
        }        
    }
}

export default longTermLoadFactor