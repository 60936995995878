import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { chartsMaxYTick, roundNumber } from '../../../../../utils';
import { withDefaultApexChart } from '../../../../../hoc';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';

const defaultSeries = [
    {
        name: '',
        data: [],
    },
];

const DisplacementChart = ({
    dataToChart: { displacements = [] },
    chartOptions = {},
}) => {
    const [options, setOptions] = useState(chartOptions);
    const [series, setSeries] = useState(defaultSeries);

    useDeepCompareEffect(() => {
        const maxDisplacement = Math.max(...displacements);
        const minDisplacement = Math.min(...displacements);

        setSeries(() => {
            return [
                {
                    name: 'Z',
                    data: [
                        ...displacements.reduce(
                            (acc, ele, index, arr) => {
                                if (index === arr.length - 1) {
                                    return [
                                        ...acc,
                                        [ele, index * 0.1],
                                        [0, index * 0.1],
                                    ];
                                } else {
                                    return [...acc, [ele, index * 0.1]];
                                }
                            },
                            [],
                        ),
                    ],
                },
            ];
        });
        setOptions(() => {
            const maxZ = Math.ceil(
                Math.max(
                    ...series[0].data.map((ele) =>
                        roundNumber(ele[1], 0),
                    ),
                ),
            );
            const maxVal = roundNumber(
                Math.max(Math.abs(maxDisplacement), Math.abs(minDisplacement)),
                1,
            );

            const { maxNum, maxTick } = chartsMaxYTick(maxZ);

            return {
                ...chartOptions,
                subtitle: {
                    ...chartOptions['subtitle'],
                    text: `Dmax - ${roundNumber(
                        Math.max(
                            Math.abs(maxDisplacement),
                            Math.abs(minDisplacement),
                        ),
                        2,
                    )} mm`,
                },
                yaxis: {
                    ...chartOptions['yaxis'],
                    tickAmount: maxTick,
                    max: maxNum,
                },
                xaxis: {
                    ...chartOptions['xaxis'],
                    max: 1.2 * maxVal,
                    min: -1.2 * maxVal,
                    tickAmount: 10,
                    labels: {
                        showDuplicates: false,
                        formatter: function (value) {
                            return roundNumber(value, 2);
                        },
                    },
                },
                fill: {
                    ...chartOptions['fill'],
                    colors: ['#00CED1'],
                    opacity: 0.65,
                },
                stroke: {
                    ...chartOptions['stroke'],
                    width: 1,
                    colors: ['#008B8B'],
                },
                dataLabels: {
                    ...chartOptions['dataLabels'],
                    offsetY: 0,
                    offsetX: 0,
                    formatter: function (x, opt) {
                        const series = opt.w.globals.initialSeries[0].data;
                        return roundNumber(series[opt.dataPointIndex][0], 2);
                    },
                },
                markers: {
                    size: 0,
                },
                tooltip: {
                    enabled: false,
                },
                annotations: {
                    ...chartOptions['annotations'],
                    xaxis: [
                        {
                            x: 0,
                            opacity: 1,
                            strokeDashArray: 0,
                            borderColor: '#000',
                        },
                    ],
                },
            };
        });
    }, [chartOptions, series, displacements]);

    return (
        <Chart
            options={options}
            series={series}
            type="area"
            height={'350px'}
            width={'100%'}
        />
    );
};

export default React.memo(
    withDefaultApexChart(DisplacementChart),
    (prev, next) => stringify(prev) === stringify(next),
);
