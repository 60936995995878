import React, { Fragment, useState } from 'react';
import { withPagination } from '../../../../../hoc';
import {
    ItemsPerPage,
    Pagination,
    SectionTitle,
    Table,
} from '../../../../../components';
import styles from './index.module.css';
import round from '../../../../../utils/roundNumber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import CorrectDepth from '../CorrectDepth';
import { isNumber } from '../../../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const MicropileCapacityTable = ({
    dataToPaginate,
    allCollection = [],
    pagination,
    paginationHelpers,
}) => {
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [csvData, setCsvData] = useState([]);

    useDeepCompareEffect(() => {
        setCsvData(
            allCollection
                .map(({ z, d, qsk, diameter, yt, ksi, np, nsi, ns, nt }) => ({
                    z,
                    d,
                    qsk,
                    diameter,
                    yt,
                    ksi,
                    np,
                    nsi,
                    ns,
                    nt,
                }))
                .map((ele) =>
                    Object.fromEntries(
                        Object.entries(ele).map(([key, value = '']) => [
                            [key],
                            value.toString().replace('.', ','),
                        ]),
                    ),
                ),
        );

        setCsvHeaders([
            {
                label: 'Z [m.p.p.t.]',
                key: 'z',
            },
            {
                label: 'd [m]',
                key: 'd',
            },
            {
                label: 'qsk [kPa]',
                key: 'qsk',
            },
            {
                label: 'D [mm]',
                key: 'diameter',
            },
            {
                label: 'yt [-]',
                key: 'yt',
            },
            {
                label: 'ksi [-]',
                key: 'ksi',
            },
            {
                label: 'Rb [kN]',
                key: 'np',
            },
            {
                label: 'Rs,i [kN]',
                key: 'nsi',
            },
            {
                label: 'Rs [kN]',
                key: 'ns',
            },
            {
                label: 'Rc,d [kN]',
                key: 'nt',
            },
        ]);
    }, [allCollection]);

    return (
        <Fragment>
            <SectionTitle title={'Nośność mikropala na wciskanie'} />
            <ItemsPerPage
                onDisplayItemsNumber={paginationHelpers.displayItemsNumber}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                display={[15, 30, paginationHelpers.allItemsLength]}
            />
            <Table
                csvData={csvData}
                csvFilename={'micropileCapacity'}
                csvHeaders={csvHeaders}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th title="Rzędna">
                            z<br></br>[m.p.p.t.]
                        </th>
                        <th title="Opór gruntu na pobocznicy">
                            q<sub>s,k</sub>
                            <br></br>[kPa]
                        </th>
                        <th title="Średnica koronki wiertniczej">
                            d<br></br>[mm]
                        </th>
                        <th title="Poszerzenie średnicy">
                            a<br></br>[-]
                        </th>
                        <th title="Poszerzenie średnicy">
                            a<sub>cpt</sub>
                            <br></br>[mm]
                        </th>
                        <th title="Wsp. bezpieczeństwa">
                            &#947;<sub>t</sub>
                            <br></br>[-]
                        </th>
                        <th title="Wsp. modelu">
                            &#958;<br></br>[-]
                        </th>

                        <th title="Nośność podstawy">
                            R<sub>b</sub>
                            <br></br>[kN]
                        </th>
                        <th title="Przyrost nośności pobocznicy">
                            R<sub>s,i</sub>
                            <br></br>[kN]
                        </th>
                        <th title="Całkowita nośność pobocznicy">
                            R<sub>s</sub>=&Sigma;R<sub>s,i</sub>
                            <br></br>[kN]
                        </th>

                        <th title="Nośność obliczeniowa na wciskanie">
                            R<sub>c,d</sub>
                            <br></br>[kN]
                        </th>
                        <th title="Siła do próbnego obciążenia na wciskanie">
                            R<sub>c,lt</sub>
                            <br></br>[kN]
                        </th>

                        <th title="Poprawność zagłębienia mikropala w gruncie"></th>
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    {dataToPaginate.map((element, index) => {
                        const buckling = element.buckling || {};
                        const veryfiedNt = element.veryfied;
                        const axisLoadCorrect = isNumber(veryfiedNt.axisLoad)
                            ? veryfiedNt.axisLoad >= 1
                                ? true
                                : false
                            : false;
                        const steelRodCorrect = isNumber(
                            veryfiedNt.steelRodLoad,
                        )
                            ? veryfiedNt.steelRodLoad > 1
                                ? false
                                : true
                            : true;

                        const isDefRatio = buckling.details
                            ? buckling.details.freeSpaceRod
                                ? true
                                : buckling.details.isDefRatioCorrect
                            : true;
                        const bucklingCorrect = buckling.details
                            ? buckling.details.isAxialForceCorrect &&
                              buckling.details.isBuckCorrect &&
                              isDefRatio
                            : true;

                        const correctLength =
                            axisLoadCorrect && bucklingCorrect;

                        return (
                            <tr
                                className={`${styles.tr} ${
                                    correctLength
                                        ? steelRodCorrect
                                            ? styles.validDepth
                                            : styles.warningDepth
                                        : styles.wrongDepth
                                }`}
                                key={index}>
                                <td>
                                    {paginationHelpers.countingItemsPerPage +
                                        index +
                                        1}
                                </td>
                                <td>{round(element.z, 2)}</td>
                                <td>{round(element.qsk, 2)}</td>
                                <td>{round(element.diameter, 2)}</td>
                                <td>
                                    {element.isCpt
                                        ? '-'
                                        : round(element.enlargeDiameter, 2)}
                                </td>
                                <td>
                                    {element.isCpt
                                        ? round(element.enlargeDiameter, 2)
                                        : '-'}
                                </td>
                                <td>{round(element.yt, 2)}</td>
                                <td>{round(element.ksi, 2)}</td>
                                <td>{round(element.np, 2)}</td>
                                <td>{round(element.nsi, 2)}</td>
                                <td>{round(element.ns, 2)}</td>
                                <td>{round(element.nt, 2)}</td>
                                <td>{round(element.nt * 1.5, 2)}</td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={
                                            correctLength
                                                ? faCheckCircle
                                                : faTimesCircle
                                        }
                                    />
                                    <div className={styles.info}>
                                        <CorrectDepth
                                            className={styles.depthColor}
                                            correctDepth={{
                                                micropileCapacity:
                                                    element.veryfied,
                                                buckling: element.buckling,
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <Pagination
                currentPage={pagination.currentPage}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                nextPage={paginationHelpers.nextPage}
            />
        </Fragment>
    );
};

export default withPagination({
    component: MicropileCapacityTable,
    returnAllCollection: true,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 25,
    },
});
