import { Fragment } from "react";
import styles from './index.module.css';
import { roundNumber } from "../../../../../utils";
import { Table } from "react-bootstrap";

const SummaryShearResults = ({ 
    result={}, 
    circular,
    stirrupType='Strzemiona',
    spiral,
    nCut
}) => {
    return ( 
        <div className={styles.wrapper}>
            <h2>Wyniki obliczeń zbrojenia na ścinanie</h2>
            <Fragment>
                {result.shearResult.incorrectDiameter 
                ? 
                <p><b>Niepoprawne wymiary przekroju ze względu na ścinanie.</b></p>
                :
                <Fragment>
                    {
                        result.shearResult.reinfNotRequired
                        ?
                        <p>Zbrojenie na ścinanie nie jest wymagane</p>
                        :
                        <Fragment>
                            {
                                circular && stirrupType === 'Spirala'
                                ?
                                <Fragment>
                                    <p>Obliczeniowy skok spirali [p]: <span className={styles.value}>{roundNumber(result.shearResult.s,2)} mm</span></p>
                                    <p>Maksymalny skok spirali [p<sub>min</sub>]: <span className={styles.value}>{roundNumber(result.shearResult.smin,2)} mm</span></p>
                                </Fragment>
                                :
                                <Fragment>
                                    <p>Obliczeniowy rozstaw strzemion [s]: <span className={styles.value}>{roundNumber(result.shearResult.s,2)} mm</span></p>
                                    <p>Maksymalny rozstaw strzemion [s<sub>min</sub>]: <span className={styles.value}>{roundNumber(result.shearResult.smin,2)} mm</span></p>
                                </Fragment>
                            }
                            <p>Obliczeniowa siła poprzeczna [V<sub>Ed</sub>]: <span className={styles.value}>{roundNumber(result.defaultResults.shearForce,2)} kN</span></p>
                            {
                            result.shearResult.vrdc &&
                            <p>Nośność na ścinanie bez zbrojenia [V<sub>Rd,c</sub>]: <span className={styles.value}>{roundNumber(result.shearResult.vrdc,2)} kN</span></p>
                            }
                            {
                            result.shearResult.vrdMax &&
                            <p>Nośność krzyżulca ściskanego [V<sub>Rd,max</sub>]: <span className={styles.value}>{roundNumber(result.shearResult.vrdMax,2)} kN</span></p>
                            }
                            {
                            result.shearResult.cotTeta &&
                            <p>Kąt nachylenia krzyżulców ściskanych [&#920;]: <span className={styles.value}>{roundNumber(
                                Math.atan(1/result.shearResult.cotTeta)*180/Math.PI
                            ,2)} &deg;</span></p>
                            }
                        </Fragment>
                    }
                </Fragment>
                }
            </Fragment>
            {
                !result.shearResult.reinfNotRequired &&
                <Fragment>
                    <div className={styles.margins}>
                        <h2>Zbrojenie na ścinanie</h2>
                    </div>
                    <Table className={styles.table}>
                        <thead>
                            <tr>
                                <th>
                                    Nr<br></br>[-]
                                </th>
                                <th>
                                    Rodzaj zbrojenia<br></br>[-]
                                </th>
                                <th>
                                    Średnica<br></br>[mm]
                                </th>
                                <th>
                                    n-cięte<br></br>[-]
                                </th>
                                <th>
                                    {stirrupType === 'Strzemiona' ? 'Rozstaw strzemion' : 'Skok spirali' }<br></br>[mm]
                                </th>
                                <th>
                                    {stirrupType === 'Strzemiona' ? 'Max. rozstaw strzemion' : 'Max. skok spirali' }<br></br>[mm]
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>{stirrupType}</td>
                                <td>{spiral}</td>
                                <td>{nCut || 2}</td>
                                <td>{roundNumber(result.shearResult.s,2)}</td>
                                <td>{roundNumber(result.shearResult.smin,2)}</td>
                            </tr>
                        </tbody>
                    </Table>                    
                </Fragment>
            }

        </div>
    )
}

export default SummaryShearResults