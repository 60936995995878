import { useCallback, useEffect, useState, useContext } from 'react';
import { authContext } from '../../context/Authentication';
import { useLocation } from 'react-router-dom';
import { AuthFetch } from '../../routes';
import { useIsMountedState } from '../../hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';

const SaveSoilsHelpers = ({
    fileNameToSave = {},
    initMainProjectName,
    onLoadProfileName,
    onChangeState,
    model,
    setAlert,
    setLoading,
    fetch={}
}) => {
    const { email: userEmail } = useContext(authContext);
    const [profileName, setProfileName] = useState({
        value: '',
        isValid: null,
    });
    const [mainProjectName, setMainProjectName] = useState({
        value: '',
        isValid: null,
    });
    const [projects, setProjects] = useState([]);
    const [profileExists, setProfileExists] = useState({
        value: false,
    });
    const [currentUser, setCurrentUser] = useState('');
    const [currentUserProjects, setCurrentUserProjects] = useState([]);
    const location = useLocation();
    const [projectStateChange, setProjectStateChange] = useState(false);
    const [profileStateChange, setProfileStateChange] = useState(false);

    const isMountedState = useIsMountedState();

    useEffect(() => {
        setMainProjectName(() => ({
            value: initMainProjectName.value,
            isValid: initMainProjectName.isValid,
        }))
    },[initMainProjectName.value, initMainProjectName.isValid])

    useDeepCompareEffect(() => {
        const userEmailMatch = currentUser.match(/\(([^)]+)\)/);
        const currentUserObj = projects.length > 0
            ? projects.find((ele) => ele.email === userEmail)
            : undefined;
        const currentUserObjProjects = currentUserObj
            ? currentUserObj.projects
            : undefined;

        if (userEmailMatch) {
            const subUserProjects = projects.find(
                (ele) => ele.email === userEmailMatch[1],
            );

            if (subUserProjects) {
                setCurrentUserProjects(subUserProjects.projects);
                setMainProjectName((state) => ({
                    value: state.value || subUserProjects.projects[0].name,
                    isValid: true,
                }));
            } else {
                setCurrentUserProjects(currentUserObjProjects || []);
                setCurrentUser('');
                setMainProjectName((state) => ({
                    value: state.value || currentUserObjProjects ? currentUserObjProjects[0].name : '',
                    isValid: currentUserObjProjects ? true : null,
                }));
            }
        } else {
            setCurrentUserProjects(currentUserObjProjects || []);
            setCurrentUser('');
            setMainProjectName((state) => ({
                value: state.value || currentUserObjProjects ? currentUserObjProjects[0].name : '',
                isValid: currentUserObjProjects ? true : null,
            }));
        }
    }, [currentUser, projects, userEmail]);

    useDeepCompareEffect(() => {
        AuthFetch({
            url: fetch.projectList.url,
            method: fetch.projectList.method,
        })
        .then((response) => {
            if(isMountedState.current){
                setProjectStateChange(false);
                if (
                    Array.isArray(response.responseData.projects) &&
                    response.responseData.projects.length > 0
                ) {
                    setProjects(() => {
                        return response.responseData.projects.reduce(
                            (acc, ele) => {
                                const { userId, ...rest } = ele;
                                const userExistsIndex =
                                    acc.length > 0
                                        ? acc.findIndex(
                                            (ele) => ele.id === userId.id,
                                        )
                                        : -1;

                                if (userExistsIndex > -1) {
                                    acc[userExistsIndex] = {
                                        ...acc[userExistsIndex],
                                        projects: [
                                            ...acc[userExistsIndex].projects,
                                            rest,
                                        ],
                                    };
                                    return [...acc];
                                }
                                return [
                                    ...acc,
                                    {
                                        ...userId,
                                        projects: [rest],
                                    },
                                ];
                            },
                            [],
                        );
                    });
                } else {
                    setProjects([]);
                }                    
            }
        })
        .catch(() => {
            if(isMountedState.current){
                setProjectStateChange(false);
                setAlert({
                    error: true,
                    message: 'Błąd podczas wczytywania projektów',
                });
            }
        });
    }, [projectStateChange, location.pathname, setAlert, isMountedState, fetch.projectList]);

    useEffect(() => {
        setProfileName({
            value: fileNameToSave.state === 'init' ? '' : fileNameToSave.value,
            isValid: fileNameToSave.state === 'init' ? null : true,
        });
    }, [fileNameToSave.state, fileNameToSave.value]);

    const onChange = useCallback((e) => {
        const target = e.currentTarget;
        if (target.name === 'profileName') {
            setProfileName((state) => ({
                ...state,
                value: target.value,
            }));
        } else if (target.name === 'currentUser') {
            setCurrentUser(target.value);
        } else if (target.name === 'mainProjectName') {
            setMainProjectName((state) => ({
                ...state,
                value: target.value,
            }));
        }
    }, []);

    const onBlur = useCallback((e) => {
        const target = e.currentTarget;

        if (target.name === 'profileName') {
            setProfileName((state) => ({
                ...state,
                isValid: target ? target.checkValidity() : false,
            }));
        } else if (target.name === 'mainProjectName') {
            setMainProjectName((state) => ({
                ...state,
                isValid: target ? target.checkValidity() : false,
            }));
        }
    }, []);

    const onSubmit = () => {
        setProfileStateChange(false);
        const projectId = currentUserProjects.find((ele) => ele.name === mainProjectName.value) || {};
        
        if (profileName.isValid && mainProjectName.isValid && projectId.id) {
            setLoading({ name: 'saveSoilProfile', loading: true });
            AuthFetch({
                url: fetch.saveSoil.url,
                method: fetch.saveSoil.method,
                body: {
                    profileName: profileName.value,
                    projectId: projectId.id,
                    ...model,
                },
            })
            .then((response) => {
                setLoading({ name: 'saveSoilProfile', loading: false });
                if (response.success) {
                    if (response.responseData.exists) {
                        setProfileExists((state) => ({
                            ...state,
                            value: true,
                        }));
                    } else {
                        setProfileStateChange(true);
                        onChangeState(true);
                        onLoadProfileName({
                            state: 'saved',
                            value: profileName.value,
                        });
                        setAlert({
                            error: false,
                            message: 'Profil geologiczny został zapisany',
                            hideTime: 2000,
                        });
                    }
                } else {
                    setAlert({
                        error: true,
                        message: 'Błąd podczas zapisu profilu geologicznego',
                    });
                }
            });
        } else {
            setAlert({
                error: true,
                message: 'Nazwa profilu geologicznego oraz nazwa projektu są wymagane',
            });
            setProfileName((state) => ({ ...state, isValid: !!state.isValid }));
            setMainProjectName((state) => ({
                ...state,
                isValid: !!state.isValid,
            }));
        }
    };

    const onOverwriteProfile = () => {
        setProfileStateChange(false);
        const currentProject = Array.isArray(projects)
            ? projects.find((ele) =>
                  Array.isArray(ele.projects)
                      ? ele.projects.find(
                            (element) => element.name === mainProjectName.value,
                        )
                      : false,
              )
            : {};

        const projectId = currentProject.projects
            ? currentProject.projects.find(
                  (element) => element.name === mainProjectName.value,
              )
            : {};

        if (profileName.isValid && mainProjectName.isValid && projectId.id) {
            setLoading({ name: 'overwriteSoilProfile', loading: true });
            AuthFetch({
                url: fetch.editSoil.url,
                method: fetch.editSoil.method,
                body: {
                    profileName: profileName.value,
                    projectId: projectId.id,
                    ...model,
                },
            })
            .then((response) => {
                setLoading({ name: 'overwriteSoilProfile', loading: false });
                if (response.success) {
                    setTimeout(() => {
                        onLoadProfileName({
                            state: 'saved',
                            value: profileName.value,
                        });
                        setProfileExists((state) => ({
                            ...state,
                            value: false,
                        }));
                    }, 2000);
                    setProfileStateChange(true);
                    onChangeState(true);
                    setAlert({
                        error: false,
                        message: 'Profil geologiczny został zapisany',
                        hideTime: 2000,
                    });
                } else {
                    setAlert({
                        error: true,
                        message: 'Błąd podczas zapisu profilu geologicznego',
                    });
                }
            });
        } else {
            setAlert({
                error: true,
                message: 'Nazwa profilu geologicznego jest wymagana',
            });
        }
    };

    const saveSoilProfile = {
        state: {
            profileExists,
            profileName,
            mainProjectName,
            projects,
            userEmail,
            currentUser,
            currentUserProjects,
        },
        onProfileStateChange: setProfileStateChange,
        profileStateChange: profileStateChange,
        onChange: onChange,
        onBlur: onBlur,
        onSubmit: onSubmit,
        onOverwrite: onOverwriteProfile,
        onHideExistProfileModal: setProfileExists,
        onProjectStateChange: setProjectStateChange,
    };
    return {
        saveSoilProfile,
    };
};

export default SaveSoilsHelpers;
