import { Navigate, Outlet } from "react-router-dom"
import config from '../../../config/config';

const LoginLayout = () => {
    const { routes } = config;

    const token = localStorage.getItem('token');
    const isLoggedIn = localStorage.getItem('isLoggedIn')
    const isUserLoggedIn = token && isLoggedIn

    return (
        isUserLoggedIn
        ?
        <Navigate to={routes.dashboard.home} />
        :
        <Outlet />
    )
}

export default LoginLayout