import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Form, Button } from 'react-bootstrap';
import Password from './Password';
import { AlertMessage2, Email } from '../../components';
import { withForm } from '../../hoc';
import { adminAuthContext } from '../../context/Admin';
import config from '../../config/config';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 576px) {
        max-width: 540px;
    }
    @media (min-width: 768px) {
        max-width: 720px;
    }
    @media (min-width: 992px) {
        max-width: 960px;
    }
`;
const Wrapper = styled.div`
    height: 100vh;
    background-color: #f6f6f6;
    font-weight: 700;
    & form {
        background-color: #fff;
        padding: 20px;
        margin-top: 15px;
        box-shadow: 0 0.4rem 0.8rem -0.1rem rgba(0, 32, 128, 0.1),
            0 0 0 1px #f0f2f7;
            
        & .form-group {
            margin: 10px 0;
        }
    }
`;
const FormWrapper = styled.div`
    padding-top: 100px;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;

    & button[type='submit'] {
        display: block;
        width: 100%;
        background-color: #1b3e6e;
        border-color: #1b3e6e;
        border-radius: 20px;
        transition: all 0.5s ease;

        &:active {
            background-color: #1b3e6e !important;
            border-color: #1b3e6e !important;
            box-shadow: none !important;
        }
        &:hover {
            background-color: #1b3e90;
            border-color: #1b3e90;
        }
        &:focus {
            box-shadow: none !important;
        }
    }
`;
const H1 = styled.h1`
    font-size: 28px;
    color: #1b3e6e;
    font-weight: inherit;
    text-align: center;
`;
const ForgetPasswordWrapper = styled.div`
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    & .form-group {
        margin: 10px 0;
    }
    & label {
        color: #666;
        padding: 0;
        border: none;
        margin-bottom: 5px;
    }
    & label:hover {
        color: #000;
        cursor: pointer;
    }
`;

const AdminLogin = ({ 
    formState,
    messageState = {},
    onToggleCheckbox,
    onChange,
    actions = {},
    onSubmit,
}) => {
    const navigate = useNavigate();
    const { logIn } = useContext(adminAuthContext);
    const { routes } = config;

    useEffect(() => {
        document.title = `GeoInCloud | Zaloguj się`;

        return () => {
            document.title = '';
        };
    }, []);

    const onSubmitForm = (e) => {
        e.preventDefault();

        onSubmit((formData) => {
            actions
                .onLoginUser({
                    body: { ...formData, checked: formState.checked },
                })
                .then((response) => {
                    if (response.status === 200) {
                        logIn(response.data.tokens, response.data.user);
                        navigate(routes.admin.home);
                    }
                });
        });
    };

    return (
        <Wrapper>
            <Container>
                <FormWrapper>
                    <H1>Zaloguj się</H1>
                    <Form onSubmit={onSubmitForm} noValidate>
                        <Email
                            onChange={onChange}
                            email={formState.email}
                            label="Email"
                            placeholder="Email"
                            autofocus
                            required
                        />
                        <Password
                            onChange={onChange}
                            password={formState.password}
                            label="Hasło"
                        />

                        <ForgetPasswordWrapper>
                            <Form.Group controlId="rememberMe">
                                <Form.Check
                                    onChange={onToggleCheckbox}
                                    type="checkbox"
                                    name="rememberMe"
                                    label="Zapamiętaj mnie"
                                    checked={formState.checked}></Form.Check>
                            </Form.Group>
                        </ForgetPasswordWrapper>
                        <Button variant="success" type="submit">
                            Zaloguj
                        </Button>

                        <AlertMessage2
                            {...messageState.onLoginUser}
                            message={messageState.onLoginUser.message}
                            isLoaded={formState.onLoginUser.isLoaded}
                            loadingName={
                                formState.onLoginUser.loadingName
                            }></AlertMessage2>
                    </Form>
                </FormWrapper>
            </Container>
        </Wrapper>
    );
};

export default withForm({
    component: AdminLogin,
    clearFormToDefaultState: true,
    actions: [
        {
            name: 'onLoginUser',
            url: '/api/admin/geoincloud/auth/login',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            errorMessage: 'Błędny login lub hasło',
            showErrorMessage: true,
            loadingName: '',
        },
    ],
    localState: {
        email: {
            value: '',
            isValid: null,
        },
        password: {
            value: '',
            isValid: null,
        },
        checked: false,
    },
});
