const setSoilColor = ({ id, il }) => {
  if(typeof id  === 'number'){
    if(id >= 0 && id < 0.15){
      return '#ffdf00'
    } else if(id >= 0.15 && id < 0.35){
      return '#ffd300'
    } else if(id >= 0.35 && id < 0.65){
      return '#ffcc00'
    } else if(id >= 0.65 && id < 0.85){
      return '#daa520'
    } else if(id >= 0.85 && id <= 1){
      return '#b8860b'
    } else {
      return '#b8860b' 
    }     
  } else if(typeof il === 'number'){
    if(il <= 0){
      return '#882d17'
    } else if(il > 0 && il <= 0.25){
      return '#665d1e'
    } else if(il > 0.25 && il <= 0.5){
      return '#6b8e23'
    } else if(il > 0.5 && il <= 1){
      return '#059033'
    } else if(il > 1){
      return '#007f66'
    } else {
      return '#007f66'
    }  
  } else {
    return '#007f66'
  }
}

  export default setSoilColor