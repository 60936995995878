import React from 'react';
import NavCategory from './SidebarCategory';
import NavItem from './CategoryItem';
import { useLocation } from 'react-router-dom';
import config from '../../../config/config';
import { Switcher } from '../../../components';

import styles from './index.module.css';
import {
    faChartLine,
    faDollarSign,
    faUsers,
} from '@fortawesome/free-solid-svg-icons';

const Sidebar = ({ firstName, lastName, email, onToggle }) => {
    const { routes } = config;
    const location = useLocation();
    const { pathname } = location;

    return (
        <div id={'sidebar-wrapper'} className={`${styles.wrapper}`}>
            <div className={styles.overflowSidebar}>
                <nav className={styles.nav}>
                    <div className={styles.navContainer}>
                        <div className={styles.brand}>
                            <div className={styles.info}>
                                <span
                                    className={
                                        styles.uppercase
                                    }>{`${firstName} ${lastName}`}</span>
                                <span>{email}</span>
                            </div>
                            <div className={styles.switchMode}>
                                <Switcher />
                            </div>
                        </div>
                        <div
                            className={`${styles.toggleOpen} sidebar-open-icon`}
                            onClick={onToggle}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <NavCategory label="Konto">
                            <NavItem
                                active={pathname}
                                to={routes.admin.home}
                                fontIcon={faChartLine}
                                title="Kokpit">
                                Kokpit
                            </NavItem>
                        </NavCategory>
                        <NavCategory label="Użytkownicy">
                            <NavItem
                                active={pathname}
                                to={routes.admin.usersList}
                                fontIcon={faUsers}
                                title="Lista użytkowników">
                                Lista użytkowników
                            </NavItem>
                        </NavCategory>
                        <NavCategory label="Płatności">
                            <NavItem
                                active={pathname}
                                to={routes.admin.payments}
                                fontIcon={faDollarSign}
                                title="Lista płatności">
                                Lista płatności
                            </NavItem>
                        </NavCategory>
                    </div>
                </nav>
            </div>
        </div>
    );
};
export default Sidebar;
