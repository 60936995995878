import { useContext, useEffect } from 'react';
import config from '../../../config/config';
import { micropilesCompressionContext } from '../../../context/MicropilesCompression';
import PrintMicropileCompressionResults from './PrintResults';
import DefaultNavigation from '../DefaultNavigation';

const MicropilesCompressionNavigation = ({
    report,
}) => { 
    const MicropileCompressionContext = useContext(
        micropilesCompressionContext,
    );
    const { routes } = config;

    useEffect(() => {
        document.title = `Mikropal wciskany - ${MicropileCompressionContext.fileNameToSave.fileName.value}`;

        return () => {
            document.title = '';
        };
    }, [MicropileCompressionContext.fileNameToSave.fileName.value]);

    return (
        <DefaultNavigation
            title="Mikropal wciskany/wyciągany"
            subtitle="Nośność na wciskanie i wyciąganie mikropali iniekcyjnych"
            modelRoute={routes.dashboard.micropilesCompressionModel}
            resultsRoute={routes.dashboard.micropilesCompressionResults}
            context={MicropileCompressionContext}>
            {MicropileCompressionContext.results.state.isResultsActual && MicropileCompressionContext.results.isResultsActive && (
                <PrintMicropileCompressionResults
                    report={report}
                    isResultsActual={
                        MicropileCompressionContext.results.state
                            .isResultsActual
                    }
                    printResults={MicropileCompressionContext.printResults}
                />
            )}
        </DefaultNavigation>
    );
};

export default MicropilesCompressionNavigation;
