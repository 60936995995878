import { useState, useCallback } from 'react';

const InvoiceFormService = () => {

    const [invoice, setInvoice] = useState({
        firstName: {
            value: '',
            isValid: null,
        },
        lastName: {
            value: '',
            isValid: null,
        },
        companyName: {
            value: '',
            isValid: null,
        },
        nip: {
            value: '',
            isValid: null,
        },
        street: {
            value: '',
            isValid: null,
        },
        placeNumber: {
            value: '',
            isValid: null,
        },
        postcode: {
            value: '',
            isValid: null,
        },
        city: {
            value: '',
            isValid: null,
        },

        email: {
            value: '',
            isValid: null,
        },
    });
    const [invoiceType, setInvoiceType] = useState(1);

    const onChangeInvoiceType = (val) => setInvoiceType(val);

    const onBlurInvoiceState = useCallback((e) => {
        const { name } = e.currentTarget;
        const valid = e.currentTarget.checkValidity();

        setInvoice((state) => {
            return {
                ...state,
                [name]: {
                    ...state[name],
                    isValid: valid,
                },
            };
        });
    }, []);

    const onChangeInvoiceState = useCallback((e) => {
        const input = e.currentTarget.closest('input');

        if (input) {
            const { name, value } = input;

            setInvoice((state) => {
                return {
                    ...state,

                    [name]: {
                        value: value,
                        isValid: true,
                    },
                };
            });
        }
    }, []);

    const invoiceForm = {
        onChangeInvoiceType,
        onChangeInvoiceState,
        onBlurInvoiceState,

        updateInvoiceState: setInvoice,

        state: {
            invoiceType,
            invoiceState: invoice,
        },
    };

    return {
        invoiceForm,
    };
};

export default InvoiceFormService;
