import { Fragment } from 'react';
import { Table } from 'react-bootstrap';
import round from '../../../../../utils/roundNumber';
import styles from './index.module.css';

const PileCptSummary = ({ data = [] }) => {
    return (
        <Fragment>
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th>
                            Profil gruntowy<br></br>[-]
                        </th>
                        <th>
                            Typ pala<br></br>[-]
                        </th>
                        <th>
                            z<br></br>[m.p.p.t.]
                        </th>
                        <th>
                            R<sub>b,k</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            R<sub>s,k</sub>=&Sigma;R<sub>s,k,i</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            T<sub>n,k</sub>=&Sigma;T<sub>n,i</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            &gamma;<sub>b,s,t</sub>
                            <br></br>[-]
                        </th> 
                        <th>
                            R<sub>c,d</sub>
                            <br></br>[kN]
                        </th> 
                        <th>
                            R<sub>c,d,min</sub>
                            <br></br>[kN]
                        </th> 
                        <th>
                            R<sub>c,d,max</sub>
                            <br></br>[kN]
                        </th> 
                        <th>
                            &gamma;<sub>s,t</sub>
                            <br></br>[-]
                        </th> 
                        <th>
                            R<sub>t,d</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            R<sub>t,d,min</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            R<sub>t,d,max</sub>
                            <br></br>[kN]
                        </th>
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    {data.map((element, index) => {
                        const bored_screwPile = [4,5].includes(element.results.results.pileTypeNumber);
                        return (
                            <tr className={styles.tr} key={index}>
                                <td>{index + 1}</td>
                                <td>{element.profileName}</td>
                                <td>{element.results.results.pileType}</td>
                                <td>{round(element.results.results.currentCapacity.z, 2)}</td>
                                <td>{round(bored_screwPile ? element.results.results.currentCapacity.Rbk_current_01 : element.results.results.currentCapacity.Rbk_current_01D, 2)}</td>
                                <td>{round(bored_screwPile ? element.results.results.currentCapacity.Rsk_current_ssg : element.results.results.currentCapacity.Rsk_current_01D, 2)}</td>
                                <td>{round(bored_screwPile ? element.results.results.currentCapacity.Tsk_current_ssg : element.results.results.currentCapacity.Tsk_current_01D, 2)}</td>
                                <td>{round(element.results.results.yt, 2)}</td>
                                <td>{round(element.results.results.currentCapacity.Rcd_current, 2)}</td>
                                <td>{round(element.results.results.currentCapacity.Rcd_lower, 2)}</td>
                                <td>{round(element.results.results.currentCapacity.Rcd_upper, 2)}</td>
                                <td>{round(element.results.results.yst, 2)}</td>
                                <td>{round(element.results.results.currentCapacity.Rtd_current, 2)}</td>
                                <td>{round(element.results.results.currentCapacity.Rtd_lower, 2)}</td>
                                <td>{round(element.results.results.currentCapacity.Rtd_upper, 2)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <p className={styles.underline}>Oznaczenia:</p>
            <p>Z - <span className={styles.value}>rzędna zagłębienia pala poniżej poziomu terenu</span></p>
            <p>R<sub>b,k</sub> - <span className={styles.value}>nośność podstawy na rzędnej Z</span></p>
            <p>R<sub>s,k</sub>=&Sigma;R<sub>s,k,i</sub> - <span className={styles.value}>nośność pobocznicy na rzędnej Z</span></p>
            <p>T<sub>n,k</sub>=&Sigma;T<sub>n,i</sub> - <span className={styles.value}>tarcie negatywne na rzędnej Z</span></p>
            <p>&gamma;<sub>b,s,t</sub> - <span className={styles.value}>współczynnik bezpieczeństwa dla nośności pobocznicy, podstawy lub całkowitej</span></p>
            <p>R<sub>c,d</sub> - <span className={styles.value}>nośność obliczeniowa na wciskanie na rzędnej Z</span></p>
            <p>R<sub>c,d,min</sub> - <span className={styles.value}>minimalna nośność obliczeniowa na wciskanie na rzędnej Z</span></p>
            <p>R<sub>c,d,max</sub> - <span className={styles.value}>maksymalna nośność obliczeniowa na wciskanie na rzędnej Z</span></p>
            <p>&gamma;<sub>s,t</sub> - <span className={styles.value}>współczynnik bezpieczeństwa dla nośności całkowitej na wyciąganie</span></p>
            <p>R<sub>t,d</sub> - <span className={styles.value}>nośność obliczeniowa na wyciąganie na rzędnej Z</span></p>
            <p>R<sub>t,d,min</sub> - <span className={styles.value}>minimalna nośność obliczeniowa na wyciąganie na rzędnej Z</span></p>
            <p>R<sub>t,d,max</sub> - <span className={styles.value}>maksymalna nośność obliczeniowa na wyciąganie na rzędnej Z</span></p>
        </Fragment>
    );
};

export default PileCptSummary;
