import React, { Fragment } from 'react';
import { Table } from 'react-bootstrap';
import styles from './index.module.css';
import round from '../../../../../../utils/roundNumber';
import { isNumber } from '../../../../../../utils';

const DetailTable = ({ results = [], title = '' }) => {
    return (
        <div className={styles.wrapper}>
            {results[0] && (
                <Fragment>
                    <div className={styles.margins}>
                        <h2>{title}</h2>                        
                    </div>
                    <Table className={styles.table}>
                        <thead>
                            <tr>
                                <th>
                                    Nr<br></br>[-]
                                </th>
                                <th>
                                    Nazwa<br></br>[-]
                                </th>
                                <th>
                                    Z<br></br>[m.p.p.t]
                                </th>
                                <th>
                                    H<br></br>[m]
                                </th>
                                <th>
                                    q<sub>s,k</sub>
                                    <br></br>[kPa]
                                </th>
                                <th>
                                    d<br></br>[mm]
                                </th>
                                <th>
                                    a<br></br>[-]
                                </th>
                                <th>
                                    a<sub>cpt</sub>
                                    <br></br>[mm]
                                </th>
                                <th>
                                    &#947;<sub>s,t</sub>
                                    <br></br>[-]
                                </th>
                                <th>
                                    &#958;<br></br>[-]
                                </th>
                                <th>
                                    &#951;<sub>M</sub>
                                    <br></br>[-]
                                </th>
                                <th>
                                    L<sub>b</sub>
                                    <br></br>[m]
                                </th>
                                <th>
                                    R<sub>t,d</sub>
                                    <br></br>[kN]
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {results[0].map((element, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{round(element.name, 2)}</td>
                                        <td>{round(element.height, 2)}</td>
                                        <td>{round(element.absHeight, 2)}</td>
                                        <td>{round(element.qsk, 2)}</td>
                                        <td>{round(element.diameter, 2)}</td>
                                        <td>
                                            {element.cpt
                                                ? '-'
                                                : round(
                                                      element.enlargeDiameter,
                                                      2,
                                                  )}
                                        </td>
                                        <td>
                                            {element.cpt
                                                ? round(
                                                      element.enlargeDiameter,
                                                      2,
                                                  )
                                                : '-'}
                                        </td>
                                        <td>{round(element.yt, 2)}</td>
                                        <td>{round(element.ksi, 2)}</td>
                                        <td>
                                            {isNumber(element.modelFactor)
                                                ? round(element.modelFactor, 2)
                                                : '-'}
                                        </td>
                                        <td>{round(element.bondLength, 2)}</td>
                                        <td>
                                            {round(element.layerCapacity, 2)}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Fragment>
            )}
        </div>
    );
};

export default DetailTable;
