import { Outlet, useOutletContext } from "react-router-dom";
import HorizontalCapacityProvider from "../../../context/HorizontalCapacity";
import { HorizontalCapacityNavigation } from "../../../pages";

const HorizontalCapacityLayout = () => {
    const context = useOutletContext();

    return (
        <HorizontalCapacityProvider>
            <HorizontalCapacityNavigation report={context.report}/>
            <Outlet context={context}/>
        </HorizontalCapacityProvider>
    )
}

export default HorizontalCapacityLayout;