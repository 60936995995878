import { Fragment } from 'react';
import styles from './index.module.css';

const SaftyFactorsSection = ({ parameters = {} }) => {
    return (
        <div className={styles.wrapper}>
            <h2>Parametry analizy oraz współczynniki bezpieczeństwa</h2>

            {parameters.isSteelRod && (
                <Fragment>
                    <p>
                        Współczynnik materiałowy dla stali - &#947;<sub>s</sub>:{' '}
                        <span className={styles.value}>
                            {parameters.steelRodYt}
                        </span>
                    </p>
                </Fragment>
            )}
            <p>
                Współczynnik materiałowy dla betonu - &#947;<sub>c</sub>:{' '}
                <span className={styles.value}>{parameters.yc}</span>
            </p>
            <p>
                Wsp. materiałowy dla wytrzymałości gruntu na ścinanie
                bez odpływu - &#947;<sub>cu</sub>:{' '}
                <span className={styles.value}>{parameters.ycu}</span>
            </p>
            <p>
                Częściowy współczynnik bezpieczeństwa dla wyciągania - &#947;
                <sub>s,t</sub>:{' '}
                <span className={styles.value}>{parameters.yst}</span>
            </p>
            <p>
                Współczynnik modelu dla nośności na wyciąganie - &#951;
                <sub>M</sub>:{' '}
                <span className={styles.value}>{parameters.modelFactor}</span>
            </p>

            <p>
                Częściowy współczynnik bezpieczeństwa dla wciskania - &#947;
                <sub>t</sub>:{' '}
                <span className={styles.value}>{parameters.yt}</span>
            </p>
            <p>
                Liczba zbadanych profili gruntowych:{' '}
                <span className={styles.value}>
                    {parameters.profilesNumber}
                </span>
            </p>
            <p>
                Współczynnik korelacyjny do nośności charakterystycznej -
                &#11420;<sub>{parameters.capacityType === 1 ? 3 : 4}</sub>:{' '}
                <span className={styles.value}>{parameters.ksi}</span>
            </p>
            <p>
                Współczynnik interpolacji granicznego oporu gruntu q
                <sub>s,k</sub>:{' '}
                <span className={styles.value}>
                    {parameters.qccuInterpolation}
                </span>
            </p>
            <p>
                Poziom interpolacji oporów gruntu q<sub>s,k</sub>:{' '}
                <span className={styles.value}>
                    {parameters.tci.toFixed(2)} m.p.p.t.
                </span>
            </p>
            <p>
                Uwzględniono grunty słabe:{' '}
                <span className={styles.value}>
                    {parameters.softSoilCount ? 'Tak' : 'Nie'}
                </span>
            </p>
            <p>
                Współczynnik poszerzenia średnicy w żwirach i pospółkach:{' '}
                <span className={styles.value}>{parameters.gravel}</span>
            </p>
            <p>
                Współczynnik poszerzenia średnicy w piaskach:{' '}
                <span className={styles.value}>{parameters.sands}</span>
            </p>
            <p>
                Współczynnik poszerzenia średnicy w gruntach skalistych:{' '}
                <span className={styles.value}>{parameters.rocks}</span>
            </p>
            <p>
                Współczynnik poszerzenia średnicy w gruntach spoistych:{' '}
                <span className={styles.value}>{parameters.cohesiveSoils}</span>
            </p>

            <p>
                Wartość poszerzenia średnicy w żwirach i pospółkach na podstawie
                badań CPT:{' '}
                <span className={styles.value}>{parameters.gravelCpt}mm</span>
            </p>
            <p>
                Wartość poszerzenia średnicy w piaskach na podstawie badań CPT:{' '}
                <span className={styles.value}>{parameters.sandsCpt}mm</span>
            </p>
            <p>
                Wartość poszerzenia średnicy w gruntach skalistych na podstawie
                badań CPT:{' '}
                <span className={styles.value}>{parameters.rocksCpt}mm</span>
            </p>
            <p>
                Wartość poszerzenia średnicy w gruntach spoistych na podstawie
                badań CPT:{' '}
                <span className={styles.value}>
                    {parameters.cohesiveSoilsCpt}mm
                </span>
            </p>
        </div>
    );
};

export default SaftyFactorsSection;
