import React, { Fragment, useState } from 'react';
import { withPagination } from '../../../../../hoc';
import {
    ItemsPerPage,
    Pagination,
    SectionTitle,
    Table,
} from '../../../../../components';
import styles from './index.module.css';
import round from '../../../../../utils/roundNumber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import CorrectDepth from '../CorrectDepth';
import useDeepCompareEffect from 'use-deep-compare-effect';

const GroupPileCapacity = ({
    dataToPaginate,
    allCollection = [],
    pagination,
    paginationHelpers,
}) => {
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [csvData, setCsvData] = useState([]);

    useDeepCompareEffect(() => {
        setCsvData(
            allCollection
                .map(
                    ({
                        z,
                        d,
                        m1,
                        m1w,
                        m2,
                        mn,
                        ntgr,
                        nwgr,
                        npfz,
                        nsfz,
                        ntfz,
                        nwfz,
                        ntwfz,
                    }) => ({
                        z,
                        d,
                        m1,
                        m1w,
                        m2,
                        mn,
                        ntgr,
                        nwgr,
                        npfz,
                        nsfz,
                        ntfz,
                        nwfz,
                        ntwfz,
                    }),
                )
                .map((ele) =>
                    Object.fromEntries(
                        Object.entries(ele).map(([key, value]) => [
                            [key],
                            value.toString().replace('.', ','),
                        ]),
                    ),
                ),
        );

        setCsvHeaders([
            {
                label: 'Z [m.p.p.t.]',
                key: 'z',
            },
            {
                label: 'd [m]',
                key: 'd',
            },
            {
                label: 'm1 [-]',
                key: 'm1',
            },
            {
                label: 'm1w [-]',
                key: 'm1w',
            },
            {
                label: 'm2 [-]',
                key: 'm2',
            },
            {
                label: 'mn [-]',
                key: 'mn',
            },
            {
                label: 'Ntgr [kN]',
                key: 'ntgr',
            },
            {
                label: 'Ntwgr [kN]',
                key: 'nwgr',
            },
            {
                label: 'Npfz [kN]',
                key: 'npfz',
            },
            {
                label: 'Nsfz [kN]',
                key: 'nsfz',
            },
            {
                label: 'Ntfz [kN/pal]',
                key: 'ntfz',
            },
            {
                label: 'Nwfz [kN]',
                key: 'nwfz',
            },
            {
                label: 'Ntwfz [kN/pal]',
                key: 'ntwfz',
            },
        ]);
    }, [allCollection]);

    return (
        <Fragment>
            <SectionTitle
                title={
                    'Nośność pionowa pala pracującego w grupie - wg normy PN-83/B-2482'
                }
            />
            <ItemsPerPage
                onDisplayItemsNumber={paginationHelpers.displayItemsNumber}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                display={[15, 30, paginationHelpers.allItemsLength]}
            />
            <Table
                csvData={csvData}
                csvFilename={'GroupPileCapacity'}
                csvHeaders={csvHeaders}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th title="Rzędna">
                            z<br></br>[m.p.p.t.]
                        </th>
                        <th title="Współczynnik rozstawu pali">
                            m<sub>1</sub>
                            <br></br>[-]
                        </th>
                        <th title="Współczynnik rozstawu pali - wyciąganie">
                            m<sub>1w</sub>
                            <br></br>[-]
                        </th>
                        <th title="Współczynnik wbijania w piaski luźne">
                            m<sub>2</sub>
                            <br></br>[-]
                        </th>
                        <th title="Współczynnik tarcia negatywnego">
                            m<sub>n</sub>
                            <br></br>[-]
                        </th>
                        <th title="Nośność na wciskanie fundamentu zastępczego">
                            N<sub>tfz</sub>
                            <br></br>[kN/pal]
                        </th>
                        <th title="Nośność na wciskanie pala w grupie">
                            N<sub>tgr</sub>
                            <br></br>[kN]
                        </th>
                        <th title="Warunek nośności na wciskanie">
                            N<sub>tfz</sub> &gt; N<sub>tgr</sub>
                            <br></br>[-]
                        </th>
                        <th title="Nośność na wyciąganie fundamentu zastępczego">
                            N<sub>twfz</sub>
                            <br></br>[kN/pal]
                        </th>
                        <th title="Nośność na wyciąganie pala w grupie">
                            N<sub>twgr</sub>
                            <br></br>[kN]
                        </th>
                        <th title="Warunek nośności na wciskanie">
                            N<sub>twfz</sub> &gt; N<sub>twgr</sub>
                            <br></br>[-]
                        </th>

                        <th title="Poprawność zagłębienia pala w gruncie"></th>
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    {dataToPaginate.map((element, index) => {
                        const {
                            correctBaseDepth,
                            correctMultiLayerDepth,
                            isCohesive,
                            isSoftLayer,
                        } = element.correctDepth;
                        const correctLength =
                            correctBaseDepth >= 1 &&
                            correctMultiLayerDepth &&
                            !isCohesive &&
                            !isSoftLayer;
                        const warningDepth =
                            correctBaseDepth > 0 &&
                            correctBaseDepth < 1 &&
                            correctMultiLayerDepth &&
                            !isCohesive &&
                            !isSoftLayer;

                        return (
                            <tr
                                className={`${styles.tr} ${
                                    correctLength
                                        ? styles.validDepth
                                        : warningDepth
                                        ? styles.warningDepth
                                        : styles.wrongDepth
                                }`}
                                key={index}>
                                <td>
                                    {paginationHelpers.countingItemsPerPage +
                                        index +
                                        1}
                                </td>
                                <td>{round(element.z, 2)}</td>
                                <td>{round(element.m1, 2)}</td>
                                <td>{round(element.m1w, 2)}</td>
                                <td>{round(element.m2, 2)}</td>
                                <td>{round(element.mn, 2)}</td>
                                <td>{round(element.ntfz, 2)}</td>
                                <td>{round(element.ntgr, 2)}</td>
                                <td
                                    className={
                                        element.ntfz > element.ntgr
                                            ? styles.validCapacity
                                            : styles.wrongDepth
                                    }>
                                    <FontAwesomeIcon
                                        icon={
                                            element.ntfz > element.ntgr
                                                ? faCheckCircle
                                                : faTimesCircle
                                        }
                                    />
                                </td>
                                <td>{round(element.ntwfz, 2)}</td>
                                <td>{round(element.nwgr, 2)}</td>
                                <td
                                    className={
                                        element.ntwfz > element.nwgr
                                            ? styles.validCapacity
                                            : styles.wrongDepth
                                    }>
                                    <FontAwesomeIcon
                                        icon={
                                            element.ntwfz > element.nwgr
                                                ? faCheckCircle
                                                : faTimesCircle
                                        }
                                    />
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={
                                            correctLength
                                                ? faCheckCircle
                                                : warningDepth
                                                ? faCheckCircle
                                                : faTimesCircle
                                        }
                                    />
                                    <div className={styles.info}>
                                        <CorrectDepth
                                            className={styles.depthColor}
                                            correctDepth={element.correctDepth}
                                        />
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <Pagination
                currentPage={pagination.currentPage}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                nextPage={paginationHelpers.nextPage}
            />
        </Fragment>
    );
};

export default withPagination({
    component: GroupPileCapacity,
    returnAllCollection: true,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 25,
    },
});