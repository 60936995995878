import React, { Fragment, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import ProjectTitle from './model/ProjectTitle';
import styles from './index.module.css';
import SoilTable from './model/SoilTable';
import SoilProfile from './model/SoilProfile';
import CalculationSettings from './model/CalculationSettings';
import PileParameters from './model/PileParameters';
import { verticalCapacityContext } from '../../context/VerticalCapacity';

import { AlertMessage, Loading } from '../../components';
import SaftyFactors from './model/SaftyFactors';
import SubmitCalculations from '../SubmitCalculations';

const VerticalCapacity = () => {
    const {
        soilTable = {},
        defaultSoilLayer = {},
        projectTitle = {},
        calculationSettings = {},
        pileProperties = {},
        soilProfile = {},
        saftyFactors = {},
        submitCalculation = {},
        globalLoading = {},
        globalAlerts = {},
        saveSoilProfile = {},
        editSoilProfile = {},
    } = useContext(verticalCapacityContext);

    return (
        <Fragment>
            <section className={styles.section}>
                <Row>
                    <ProjectTitle {...projectTitle} />
                </Row>
            </section>
            <section className={styles.section}>
                <Row>
                    <SoilTable
                        {...soilTable}
                        saveSoilProfile={saveSoilProfile}
                        editSoilProfile={editSoilProfile}
                        defaultSoilLayer={defaultSoilLayer}
                        globalLoading={globalLoading.state}
                    />
                </Row>
            </section>
            <section className={styles.section}>
                <Row>
                    <Col xs={12} sm={5}>
                        <SoilProfile
                            {...soilProfile}
                            waterLevel={
                                calculationSettings.state.waterLevel.value
                            }
                            groundLevel={
                                calculationSettings.state.groundLevel.value
                            }
                            pileLength={pileProperties.state.pileLength.value}
                            pileHeadSpot={
                                pileProperties.state.pileHeadSpot.value
                            }
                            pileShape={pileProperties.state.pileShape.value}
                        />
                    </Col>
                    <Col xs={12} sm={7}>
                        <CalculationSettings {...calculationSettings} />
                        <PileParameters
                            {...pileProperties}
                            layersHeight={soilTable.state.map(
                                (element) => element.height.value,
                            )}
                        />
                        <SaftyFactors
                            isPileGroup={pileProperties.state.pileGroup}
                            isSinglePile={pileProperties.state.singlePile}
                            isOpenSteelPile={
                                pileProperties.state.pileType.value ===
                                'Z rury stalowej z dnem otwartym'
                            }
                            {...saftyFactors}
                        />
                    </Col>
                </Row>
            </section>
            <section>
                <Row>
                    <Col xs={12}>
                        <SubmitCalculations {...submitCalculation} />
                    </Col>
                </Row>
            </section>
            <div className={styles.alerts}>
                {Array.isArray(globalAlerts.state.alerts) &&
                    globalAlerts.state.alerts.length > 0 &&
                    globalAlerts.state.alerts.map((ele, index) => {
                        return (
                            <AlertMessage
                                index={index}
                                updateState={globalAlerts.updateState}
                                key={index}
                                message={ele.message}
                                error={ele.error}
                            />
                        );
                    })}
            </div>
            {globalLoading.state.calculations && (
                <div className={styles.loadingWrapper}>
                    <Loading text="Wykonuję obliczenia..." />
                </div>
            )}
            {globalLoading.state.fetchProject && (
                <div className={styles.loadingWrapper}>
                    <Loading text="Wczytuję projekt..." />
                </div>
            )}
            {
                globalLoading.state.removeCalculation && 
                <div className={styles.loadingWrapper}>
                    <Loading text="Usuwam obliczenia..."/>
                </div>  
            }
            {globalLoading.state.fetchProfile && (
                <div className={styles.loadingWrapper}>
                    <Loading text="Wczytuję profil geologiczny..." />
                </div>
            )}
        </Fragment>
    );
};

export default VerticalCapacity;