import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useIsMountedState } from '../../../hooks';
import { AuthFetch } from '../../../routes';
import useDeepCompareEffect from 'use-deep-compare-effect';

const initStats = {
    projects: 0,
    profiles: 0,
    calculations: 0,
};
const Statistics = ({ fetch, setAlert }) => {
    const [stats, setStats] = useState(initStats);
    const location = useLocation();
    const isMountedState = useIsMountedState();

    useDeepCompareEffect(() => {
        if (isMountedState.current) {
            AuthFetch({
                url: fetch.stats.url,
                method: fetch.stats.method,
            })
                .then((response) => {
                    if (isMountedState.current) {
                        if (response.success) {
                            setStats(response.responseData);
                        } else {
                            setAlert({
                                error: true,
                                message:
                                    'Błąd podczas wczytywania statystyki konta',
                            });
                            setStats(initStats);
                        }
                    }
                })
                .catch((e) => {
                    if (isMountedState.current) {
                        setStats(initStats);
                        setAlert({
                            error: true,
                            message: 'Błąd podczas wczytywania statystyki konta',
                        });
                    }

                });
        }
    }, [
        location.pathname,
        fetch.stats.url,
        fetch.stats.method,
        setAlert,
        isMountedState,
    ]);

    return {
        stats: {
            state: {
                stats,
            },
        },
    };
};

export default Statistics;
