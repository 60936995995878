import { Col, Row } from "react-bootstrap"
import { Button, InputField, Loading, Modal, SectionTitle, SelectField, Table, TableInput } from "../../../../components"
import styles from './index.module.css';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { genesisType, soilType } from '../../../../config/ProjectConfig';
import { Fragment } from "react";

const SoilTableModal = ({
    open,
    title,

    onHide,
    onChange,
    onBlur,
    profileName={},
    soilTable,
    onChangeSoilState,
    onBlurSoilState,
    onKeyDown,
    removeRow,
    addRow,
    openDefaultSoilModal,
    saveButtonTitle,
    loading,
    defaultSoils,
    onSubmit,

    hideProfileName
}) => {

    return (
        <Fragment>
            <Modal open={open} onHide={onHide} css={styles.modal} cssDialog={styles.modalDialog} title={title} hideButton={false}>
                {
                    !hideProfileName && 
                    <Row className={styles.row}>
                        <Col xs={12}>
                            <SectionTitle title="Nazwa profilu gruntowego" />
                            <InputField
                                onChange={onChange}
                                onBlur={onBlur}
                                value={profileName.value}
                                isValid={profileName.isValid}
                                type="text" 
                                title=""
                                placeholder="Nazwa profilu" 
                                name="profileName"

                                required
                            />
                        </Col>    
                    </Row>                
                }

                <Row className={styles.row}>
                    <Col xs={12}>
                        <SectionTitle title="Parametry podłoża gruntowego" />
                        <Table>
                            <thead className={styles.thead}>
                                <tr>
                                    <th>Nr<br></br>[-]</th>
                                    <th className={styles.min150}>Nazwa warstwy<br></br>[-]</th>
                                    <th className={styles.min50}>Z<br></br>[m.p.p.t]</th>

                                    <th className={styles.min50}>&#947; <br></br>[kN/m<sup>3</sup>]</th>
                                    <th className={styles.min50}>&#947;' <br></br>[kN/m<sup>3</sup>]</th>
                                    <th className={styles.min50}>c<br></br>[kPa]</th> 
                                    <th className={styles.min50}>&#966; <br></br>[&#176;]</th>
                                    <th className={styles.min50}>E<sub>0</sub> <br></br>[MPa]</th>

                                    <th className={styles.min50}>q<sub>c</sub><br></br>[MPa]</th>
                                    <th className={styles.min50}>c<sub>u</sub><br></br>[kPa]</th>
                                    
                                    <th className={styles.min50}>I<sub>d</sub> <br></br>[-]</th>
                                    <th className={styles.min50}>I<sub>L</sub> <br></br>[-]</th> 
                                    <th className={styles.min100}>Nazwa gruntu <br></br>[-]</th>

                                    <th className={styles.min100}>Geneza <br></br>[-]</th>
                                    <th className={styles.min100}>Rodzaj <br></br>[-]</th>

                                    <th>Usuń</th>
                                    <th>Dodaj</th>
                                </tr>
                            </thead>
                            <tbody className={styles.tbody}>
                                {soilTable.state.map((element, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td className={styles.tdLeft}>
                                                <TableInput
                                                    name="name" 
                                                    placeholder="Nazwa gruntu" 
                                                    maxLength="35" 
                                                    data-row={index}
                                                    onBlur={onBlurSoilState}
                                                    onChange={onChangeSoilState}
                                                    value={element.name.value}
                                                    isValid={element.name.isValid}
                                                />
                                            </td>
                                            <td>
                                                <TableInput 
                                                    name="height" 
                                                    type="number"
                                                    min="0"
                                                    step="0.001" 
                                                    align="center"
                                                    data-row={index}
                                                    onBlur={onBlurSoilState}
                                                    onChange={onChangeSoilState}
                                                    onKeyDown={onKeyDown}
                                                    value={element.height.value}
                                                    isValid={element.height.isValid}
                                                />
                                            </td>
                                            <td>
                                                <TableInput
                                                    name="weight" 
                                                    type="number" 
                                                    min="10" 
                                                    max="30" 
                                                    step="0.001" 
                                                    align="center"
                                                    data-row={index}
                                                    onChange={onChangeSoilState}
                                                    onKeyDown={onKeyDown}
                                                    onBlur={onBlurSoilState}
                                                    value={element.weight.value}
                                                    isValid={element.weight.isValid}
                                                />
                                            </td>
                                            <td>
                                                <TableInput
                                                    name="underWaterWeight" 
                                                    type="number" 
                                                    min="0" 
                                                    max="20" 
                                                    step="0.001" 
                                                    align="center"
                                                    data-row={index}
                                                    onChange={onChangeSoilState} 
                                                    onBlur={onBlurSoilState}
                                                    onKeyDown={onKeyDown}
                                                    value={element.underWaterWeight.value}
                                                    isValid={element.underWaterWeight.isValid}
                                                />
                                            </td>
                                            <td>
                                                <TableInput 
                                                    name="cohesion" 
                                                    type="number" 
                                                    min="0" 
                                                    max="100" 
                                                    step="0.001" 
                                                    align="center"
                                                    data-row={index}
                                                    onChange={onChangeSoilState}
                                                    onBlur={onBlurSoilState}
                                                    onKeyDown={onKeyDown}
                                                    value={element.cohesion.value}
                                                    isValid={element.cohesion.isValid}
                                                />
                                            </td>
                                            <td>
                                                <TableInput
                                                    name="friction" 
                                                    type="number" 
                                                    min="0" 
                                                    max="80" 
                                                    step="0.001" 
                                                    align="center"
                                                    data-row={index}
                                                    onChange={onChangeSoilState}
                                                    onBlur={onBlurSoilState}
                                                    onKeyDown={onKeyDown}
                                                    value={element.friction.value}
                                                    isValid={element.friction.isValid}
                                                />
                                            </td>
                                            <td>
                                                <TableInput
                                                    name="edometricModule" 
                                                    type="number" 
                                                    min="0" 
                                                    max="400" 
                                                    step="0.001" 
                                                    align="center"
                                                    data-row={index}
                                                    onChange={onChangeSoilState} 
                                                    onBlur={onBlurSoilState}
                                                    onKeyDown={onKeyDown}
                                                    value={element.edometricModule.value}
                                                    isValid={element.edometricModule.isValid}
                                                />
                                            </td>
                                            <td>
                                                <TableInput
                                                    name="qccpt" 
                                                    type="number" 
                                                    min="0"
                                                    step="0.001" 
                                                    align="center"
                                                    data-row={index}
                                                    onChange={onChangeSoilState}
                                                    onBlur={onBlurSoilState}
                                                    onKeyDown={onKeyDown}
                                                    value={element.qccpt.value}
                                                    isValid={element.qccpt.isValid}
                                                />
                                            </td>
                                            <td>
                                                <TableInput
                                                    name="cucpt" 
                                                    type="number" 
                                                    min="0"
                                                    step="0.001" 
                                                    align="center"
                                                    data-row={index}
                                                    onChange={onChangeSoilState} 
                                                    onBlur={onBlurSoilState}
                                                    onKeyDown={onKeyDown}
                                                    value={element.cucpt.value}
                                                    isValid={element.cucpt.isValid}
                                                />
                                            </td>
                                            <td>
                                                <TableInput
                                                    name="density" 
                                                    type="number" 
                                                    min="0" 
                                                    max="1" 
                                                    step="0.001" 
                                                    align="center"
                                                    data-row={index}
                                                    onChange={onChangeSoilState} 
                                                    onBlur={onBlurSoilState}
                                                    onKeyDown={onKeyDown}
                                                    value={element.density.value}
                                                    isValid={element.density.isValid}
                                                />
                                            </td>
                                            <td>
                                                <TableInput
                                                    name="plasticity" 
                                                    type="number" 
                                                    max="1" 
                                                    step="0.001" 
                                                    align="center"
                                                    data-row={index} 
                                                    onChange={onChangeSoilState} 
                                                    onBlur={onBlurSoilState}
                                                    onKeyDown={onKeyDown}
                                                    value={element.plasticity.value}
                                                    isValid={element.plasticity.isValid}
                                                />
                                            </td>
                                            <td>
                                                <SelectField
                                                    name="soilName" 
                                                    value={element.soilName.value}
                                                    isValid={element.soilName.isValid}
                                                    defaultOption="[-]" 
                                                    options={defaultSoils} 
                                                    data-row={index}
                                                    onChange={onChangeSoilState} 
                                                    onBlur={onBlurSoilState}
                                                /> 
                                            </td>
                                            <td>
                                                <SelectField 
                                                    name="genesis" 
                                                    value={element.genesis.value}
                                                    isValid={element.genesis.isValid}
                                                    defaultOption="[-]" 
                                                    options={genesisType}
                                                    data-row={index}
                                                    onChange={onChangeSoilState}
                                                    onBlur={onBlurSoilState}
                                                /> 
                                            </td>
                                            <td>
                                                <SelectField
                                                    name="soilType" 
                                                    value={element.soilType.value}
                                                    isValid={element.soilType.isValid}
                                                    defaultOption="[-]" 
                                                    options={Object.values(soilType).map(ele => ele.pl)} 
                                                    data-row={index}
                                                    onChange={onChangeSoilState} 
                                                    onBlur={onBlurSoilState}
                                                />
                                            </td>
                                            <td>
                                                <Button variant="danger" icon={faTrashAlt} altTitle={"Usuń"} onClick={() => removeRow(index)}/>
                                            </td>
                                            <td>
                                                <Button variant="info" icon={faPlus} altTitle={"Dodaj"} onClick={() => openDefaultSoilModal(index)}/>
                                            </td>
                                        </tr>                            
                                    )
                                })}
                            </tbody> 
                        </Table>
                        <div className={styles.actionsWrapper}>
                            <div className={styles.actions}>
                                <Button css="default-button-style" onClick={addRow} title={"Dodaj warstwę"} icon={faPlus}/> 
                            </div>
                            <div className={styles.actions}>
                                <Button
                                    css={`${styles.button} default-button-style`}
                                    title={saveButtonTitle}
                                    altTitle={"saveButtonTitle"}
                                    name="createProfile"
                                    onClick={onSubmit}
                                    type="submit"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal> 

            {
                loading && 
                <div className={styles.loadingWrapper}>
                    <Loading text="Zapisuję nowy profil..."/>
                </div>
            }
        </Fragment>

    )
}
 
export default SoilTableModal