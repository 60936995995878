import stringify from 'fast-json-stable-stringify';
import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { InputField, SectionTitle } from '../../../../components';
import styles from './index.module.css';

const FoundationParameters = ({ state, onBlur, onChange, diameterPile }) => {
    return (
        <Fragment>
            <SectionTitle title="Parametry fundamentu" />
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        name="columns"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={state.columns.value}
                        isValid={state.columns.isValid}
                        placeholder="Liczba kolumn"
                        type="number"
                        title="Liczba kolumn pali"
                        required
                    />
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        name="rows"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={state.rows.value}
                        isValid={state.rows.isValid}
                        placeholder="Liczba rzędów"
                        type="number"
                        title="Liczba rzędów pali"
                        required
                    />
                </Col>
            </Row>
            <Row>
                {(state.columns.value > 1 || state.columns.value === '') && (
                    <Col xs={12} className={styles.columnSpace}>
                        <InputField
                            name="perpendicularSpace"
                            onChange={onChange}
                            onBlur={onBlur}
                            data-diameter={diameterPile.value}
                            value={state.perpendicularSpace.value}
                            isValid={state.perpendicularSpace.isValid}
                            isWarning={state.perpendicularSpace.isWarning}
                            warning="Przy rozstawie mniejszym niż 2D ustrój zachowuje się jak ściana"
                            placeholder="Rozstaw pali R1"
                            type="number"
                            title={
                                <Fragment>
                                    Rozstaw pali w pł. prostopadłej do kierunku
                                    siły poziomej - R<sub>1</sub> [m]
                                </Fragment>
                            }
                            required
                        />
                    </Col>
                )}
                {(state.rows.value > 1 || state.rows.value === '') && (
                    <Col xs={12} className={styles.columnSpace}>
                        <InputField
                            name="parallelSpace"
                            onChange={onChange}
                            onBlur={onBlur}
                            data-diameter={diameterPile.value}
                            value={state.parallelSpace.value}
                            isValid={state.parallelSpace.isValid}
                            placeholder="Rozstaw pali R2"
                            type="number"
                            title={
                                <Fragment>
                                    Rozstaw pali w pł. równoległej do kierunku
                                    siły poziomej - R<sub>2</sub> [m]
                                </Fragment>
                            }
                            required
                        />
                    </Col>
                )}
            </Row>
        </Fragment>
    );
};

export default React.memo(
    FoundationParameters,
    (prev, next) => stringify(prev) === stringify(next),
);
