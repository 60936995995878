const steelPiles = {
    1: "Z rury stalowej z dnem otwartym",
    2: "Z rury stalowej z dnem zamkniętym",    
}
const concretePiles = {
    3: "Żelbetowe prefabrykowane",
    4: "Wiercone z iniekcją pod podstawą",
    5: "Wiercone bez iniekcji pod postawą",
    6: "Omega",
    7: "Atlas",
    8: "Vibrex",
    9: "Vibro-Fundex",
    10: "Fundex",
    11: "Tubex",
    12: "FDP",
    13: "SDP",
    14: "PCS Lambda",
    15: "Starsol",
    16: 'CFA'    
}
const allPileTypes = {
    ...steelPiles,
    ...concretePiles
}

export { allPileTypes, steelPiles, concretePiles }