import { useState } from "react";
import { isObjectId } from "../../../config/ProjectConfig";
import { AuthFetch } from "../../../routes";
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';

const EditProjectHelpers = ({ 
    setAlert,
    setLoading, 

    loadTitle,

    loadCalculationInput,
    loadCheckCapacity,

    onLoadFileName,
    onLoadProfileName,
    onSetMainProjectName,

    projectId,
    onSubmitState,
    onSubmitCalc,

    initResults,

    onCalculationInputToSend,
    onIsValidState,
}) => { 

    const [currentProjectId, setCurrentProjectId] = useState('');
    const [currentProfileId, setCurrentProfileId] = useState('');
    const [loadProjectState, setLoadProjectState] = useState(false);
    const [currentProjectLoaded, setCurrentProjectLoaded] = useState(false);
    const reinforcementCurrentProject = JSON.parse(localStorage.getItem('reinforcement')) || {};

    const [isLoaded, setIsLoaded] = useState(false);

    const updateStateOnLoad = (response) => {
        setLoadProjectState(true);
        onSetMainProjectName(() => ({ 
            isValid: true,
            value: response.responseData.projectId.name
        }));

        setCurrentProjectId(response.responseData.projectId.id);
        setCurrentProfileId(response.responseData.profileId);

        const { model={}, projectName='' } = response.responseData;

        onLoadFileName({
            state: projectName ? 'loaded' : 'init',
            value: projectName
        });
        onLoadProfileName({ 
            state: 'init',
            value: ''
        });

        
        loadCheckCapacity(model.checkCapacity);
        loadTitle({
            projectTitle: model.projectTitle
        });
        loadCalculationInput(model.calculationInput);

        setTimeout(() => {
            const calculationToSend = onCalculationInputToSend(model.calculationInput);
            const isValid = onIsValidState(calculationToSend);
            
            if(isValid){
                setIsLoaded(true);
            }
        },0)
    }

    useDeepCompareEffect(() => {
        if(onSubmitState.isValidCalculationInputToSend && onSubmitState.calculationInputIsLoaded && isLoaded){
            onSubmitCalc(false);
            setIsLoaded(false)
        }
    },[onSubmitState, isLoaded]);

    useDeepCompareEffect(() => {
        if(!currentProjectLoaded && reinforcementCurrentProject.id && reinforcementCurrentProject.projectId){
            setLoadProjectState(false);
            AuthFetch({
                url: `/api/reinforcement/project/${reinforcementCurrentProject.id}/${reinforcementCurrentProject.projectId}`,
                method: 'GET',
            }).then(response => {
                if(response.success){
                    setCurrentProjectLoaded(true);
                    updateStateOnLoad(response);                    
                }
            })
        }
    },[currentProjectLoaded, reinforcementCurrentProject])
    
    const onLoadProject = (id)=> {
        if(isObjectId(id) && isObjectId(projectId)){
            setLoading({ name: 'fetchProject', loading: true });
            setLoadProjectState(false);

            AuthFetch({
                url: `/api/reinforcement/project/${id}/${projectId}`,
                method: 'GET',
            }).then(response => {
                setLoading({ name: 'fetchProject', loading: false });
                setLoadProjectState(true)
                if(response.success) {
                    localStorage.setItem('reinforcement', stringify({ id: response.responseData.id, projectId: response.responseData.projectId.id }));
                    setCurrentProjectLoaded(true);
                    initResults();
                    updateStateOnLoad(response);
                } else {
                    setAlert({ error: true, message: 'Błąd podczas wczytywania projektu' });
                }
            })
            .catch(() => {
                setAlert({ error: true, message: 'Błąd podczas wczytywania projektu' });
            })
        }
    };
 
    const editProject = {
        onLoadProject: onLoadProject,
        currentProjectId: currentProjectId,
        currentProfileId: currentProfileId,
        loadProjectState: loadProjectState
    }

    return {
        editProject
    }
}

export default EditProjectHelpers