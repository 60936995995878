import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { isNumber, roundNumber } from '../../../../../utils';
import styles from './index.module.css';

const SoilLayer = ({
    height,
    backgroundColor,
    layerHeight,
    name,
    groundLevel,
    qccpt,
    cucpt,
    density,
    plasticity,
    friction,
}) => {
    const [descriptionHeight, setDescrptionHeight] = useState(0);

    const descriptionRef = (e) => {
        if (e) {
            setDescrptionHeight(e.offsetHeight);
        }
    };

    return (
        <div
            className={`${styles.layer} ${
                height === 0 ? styles.hiddenLayer : ''
            }`}
            style={{ height: `${height}px`, backgroundColor }}>
            <span className={styles.spot}>
                {layerHeight.toFixed(2)}{' '}
                {isNumber(groundLevel)
                    ? `(${roundNumber(groundLevel - layerHeight, 2).toFixed(
                          2,
                      )})`
                    : ''}
            </span>
            <div className={styles.wrapper}>
                <div className={styles.description}>
                    <div
                        className={
                            descriptionHeight > height
                                ? styles.listWrapperHidden
                                : styles.listWrapperVisible
                        }
                        ref={descriptionRef}>
                        {name && (
                            <span className={styles.item}>
                                <b>{name}</b>
                            </span>
                        )}
                        {typeof friction === 'number' && (
                            <span className={styles.item}>
                                &#966; = {friction}&#176;
                            </span>
                        )}
                        {typeof density === 'number' && (
                            <span className={styles.item}>Id = {density}</span>
                        )}
                        {typeof plasticity === 'number' && (
                            <span className={styles.item}>
                                Il = {plasticity}
                            </span>
                        )}
                        {typeof qccpt === 'number' && (
                            <span className={styles.item}>
                                q<sub>c</sub> = {qccpt} MPa
                            </span>
                        )}
                        {typeof cucpt === 'number' && (
                            <span className={styles.item}>
                                c<sub>u</sub> = {cucpt} kPa
                            </span>
                        )}
                    </div>
                    <div
                        className={`${styles.icon} ${
                            descriptionHeight <= height ? styles.iconHidden : ''
                        }`}>
                        <FontAwesomeIcon icon={faInfo} />
                        <div className={styles.details}>
                            {name && (
                                <span className={styles.item}>
                                    <b>{name}</b>
                                </span>
                            )}
                            {typeof friction === 'number' && (
                                <span className={styles.item}>
                                    &#966; = {friction}&#176;
                                </span>
                            )}
                            {typeof density === 'number' && (
                                <span className={styles.item}>
                                    Id = {density}
                                </span>
                            )}
                            {typeof plasticity === 'number' && (
                                <span className={styles.item}>
                                    Il = {plasticity}
                                </span>
                            )}
                            {typeof qccpt === 'number' && (
                                <span className={styles.item}>
                                    q<sub>c</sub> = {qccpt} MPa
                                </span>
                            )}
                            {typeof cucpt === 'number' && (
                                <span className={styles.item}>
                                    c<sub>u</sub> = {cucpt} kPa
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SoilLayer;
