import { Fragment, useState, useMemo } from 'react';
import moment from 'moment';
import {
    Button,
    SectionTitle,
    AddNewProject,
    ConfirmModal,
    SelectField,
    Modal,
    Table,
    Checkbox1,
    Subtitle,
    InputField,
    Pagination,
    ItemsPerPage,
    SearchInput,
} from '../../../components';
import styles from './index.module.css';
import {
    faPlus,
    faUsers,
    faListUl,
    faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faQuestionCircle,
    faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';
import { Col, Row } from 'react-bootstrap';
import { withPagination } from '../../../hoc';
import stringify from 'fast-json-stable-stringify';

const Projects = ({
    data = {},
    dataToPaginate = [],
    pagination,
    paginationHelpers,
    loading = {},
    currentProject = {},
    isUserRole,
    id,
}) => {
    const [open, setOpen] = useState({});
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [openEditProjectName, setOpenEditProjectName] = useState(false);
    const [infoObj, setInfoObj] = useState({});
    const [openEditUser, setOpenEditUser] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [projectId, setProjectId] = useState('');

    const allProjectsStringify = stringify(data.state.allProjects);

    const userProjects = useMemo(() => {
        const allProjectsParsed = JSON.parse(allProjectsStringify);

        return allProjectsParsed.filter(
            (ele) => ele.email !== data.state.userEmail,
        );
    }, [allProjectsStringify, data.state.userEmail]);

    const onOpen = (e) =>
        setOpen((state) => ({ ...state, [e.currentTarget.name]: true }));
    const onHide = () => setOpen({});

    const onOpenInfoModal = (obj) => {
        setInfoObj(obj);
        setOpenInfoModal(true);
    };

    const onOpenProjectModal = (id, name) => {
        setProjectName(name);
        setProjectId(id);
        setOpenConfirmModal(true);
    };
    const onOpenEditUserModal = (id, name) => {
        data.onLoadProjectUsers(id);
        setProjectId(id);
        setOpenEditUser(true);
        setProjectName(name);
    };

    const onDeleteProject = () => {
        setOpenConfirmModal(false);
        data.onRemoveProject(projectId);
    };

    const onOpenEditProjectNameModal = (name, id) => {
        setProjectName(name);
        setProjectId(id);
        setOpenEditProjectName(true);
        data.setProjectId(id);
        data.loadEditProjectName({
            value:
                id !== data.state.projectId
                    ? name
                    : data.state.editProjectName.value,
            isValid: true,
        });
    };
    const onHideEditProjectNameModal = () => {
        setOpenEditProjectName(false);
        setProjectId('');
        setProjectName('');
    };
    const onHideInfoModal = () => {
        setInfoObj({});
        setOpenInfoModal(false);
    };

    const onHideConfirmModal = () => {
        setProjectId('');
        setProjectName('');
        setOpenConfirmModal(false);
    };
    const onHideEditAccessUserModal = () => {
        setProjectId('');
        setProjectName('');
        setOpenEditUser(false);
    };

    return (
        <Fragment>
            <div className={styles.inlineNavbar}>
                <SectionTitle title="Projekty" />
                <div className={styles.flexTitle}>
                    {Array.isArray(userProjects) && userProjects.length > 0 && (
                        <SelectField
                            title=""
                            css={styles.select}
                            onChange={data.onChange}
                            value={data.state.currentUser}
                            defaultOption="Moje projekty"
                            disabledDefault={false}
                            options={userProjects.map(
                                (ele) =>
                                    `${ele.firstName} ${ele.lastName} (${ele.email})`,
                            )}
                            name="currentUser"
                        />
                    )}

                    <Button
                        icon={faPlus}
                        css={`
                            ${styles.loadButton} default-button-style
                        `}
                        variant="info"
                        name="createProject"
                        onClick={onOpen}
                        altTitle="Nowy projekt"
                    />
                </div>
            </div>
            <div className={styles.itemsPerPage}>
                <Row>
                    <Col xs={5}>
                        <ItemsPerPage
                            align="left"
                            onDisplayItemsNumber={
                                paginationHelpers.displayItemsNumber
                            }
                            itemsPerPage={pagination.itemsPerPage}
                            totalItems={paginationHelpers.allItemsLength}
                            display={[
                                5,
                                15,
                                30,
                                paginationHelpers.allItemsLength,
                            ]}
                        />
                    </Col>
                    <Col xs={7} className={styles.centerCol}>
                        <SearchInput
                            onChange={data.onSearch}
                            placeholder="Wyszukaj projekt"
                        />
                    </Col>
                </Row>
            </div>
            {dataToPaginate.length > 0 ? (
                <Fragment>
                    <div className={`backgroundAround`}>
                        <ul className={styles.ul}>
                            {dataToPaginate.map((ele, idx) => {
                                return (
                                    <li key={idx}>
                                        <div
                                            className={`${
                                                currentProject.name === ele.name
                                                    ? styles.currentProject
                                                    : ''
                                            } ${styles.projectWrapper}`}>
                                            <p
                                                onClick={() =>
                                                    data.onChangeCurrentProject(
                                                        {
                                                            id: ele.id,
                                                            name: ele.name,
                                                            userId: ele.userId,
                                                            profiles:
                                                                ele.soilProfiles,
                                                        },
                                                    )
                                                }
                                                className={styles.p}>{`${
                                                paginationHelpers.countingItemsPerPage +
                                                idx +
                                                1
                                            }. ${ele.name}`}</p>
                                            <div className={styles.flex}>
                                                {(isUserRole ||
                                                    id === ele.userId) && (
                                                    <Fragment>
                                                        <div
                                                            className={
                                                                styles.action
                                                            }
                                                            title="Edytuj nazwę projektu"
                                                            onClick={() =>
                                                                onOpenEditProjectNameModal(
                                                                    ele.name,
                                                                    ele.id,
                                                                )
                                                            }>
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faPencilAlt
                                                                }
                                                            />
                                                        </div>
                                                        {isUserRole &&
                                                            data.state.subUsers
                                                                .length > 0 && (
                                                                <div
                                                                    className={
                                                                        styles.action
                                                                    }
                                                                    title="Edytuj dostęp do projektu"
                                                                    onClick={() =>
                                                                        onOpenEditUserModal(
                                                                            ele.id,
                                                                            ele.name,
                                                                        )
                                                                    }>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faUsers
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        <div
                                                            className={
                                                                styles.action
                                                            }
                                                            title="Usuń projekt"
                                                            onClick={() =>
                                                                onOpenProjectModal(
                                                                    ele.id,
                                                                    ele.name,
                                                                )
                                                            }>
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faTrashAlt
                                                                }
                                                            />
                                                        </div>
                                                    </Fragment>
                                                )}
                                                <div
                                                    className={styles.action}
                                                    title="Informacje o projekcie"
                                                    onClick={() =>
                                                        onOpenInfoModal({
                                                            name: ele.name,
                                                            firstName:
                                                                ele.firstName,
                                                            lastName:
                                                                ele.lastName,
                                                            email: ele.email,
                                                            created:
                                                                ele.createdAt,
                                                            updated:
                                                                ele.updatedAt,
                                                        })
                                                    }>
                                                    <FontAwesomeIcon
                                                        icon={faQuestionCircle}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    {paginationHelpers.totalPages > 1 && (
                        <Pagination
                            currentPage={pagination.currentPage}
                            itemsPerPage={pagination.itemsPerPage}
                            totalItems={paginationHelpers.allItemsLength}
                            nextPage={paginationHelpers.nextPage}
                        />
                    )}
                </Fragment>
            ) : (
                <p className={styles.center}>Brak zapisanych projektów.</p>
            )}

            <ConfirmModal
                open={openConfirmModal}
                cssDialog={styles.confirmModal}
                onHide={onHideConfirmModal}
                onConfirm={onDeleteProject}
                title="Usuń projekt">
                <p>
                    Jesteś pewny, że chcesz usunąć projekt -{' '}
                    <b>{projectName}</b>? <br></br>Usuwając projekt usuniesz
                    również przypisane do niego profile gruntowe oraz
                    obliczenia.
                </p>
            </ConfirmModal>
            <Modal
                open={openEditProjectName}
                cssDialog={styles.modalDialogEditProjectName}
                onHide={onHideEditProjectNameModal}
                hideButton={false}
                title={`Edycja nazwy projektu - ${projectName}`}>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <Subtitle title="Nazwa projektu" />
                        <InputField
                            onChange={data.onChangeEditProjectName}
                            value={data.state.editProjectName.value}
                            isValid={data.state.editProjectName.isValid}
                            type="text"
                            placeholder="Nazwa projektu"
                            name="editProjectName"
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Button
                            css={`
                                ${styles.button} default-button-style
                            `}
                            title={'Zapisz zmiany'}
                            altTitle="Zapisz zmiany"
                            name="saveChanges"
                            onClick={() =>
                                data.onSaveEditedProjectName(projectId)
                            }
                            type="submit"
                        />
                    </Col>
                </Row>
            </Modal>
            {isUserRole && (
                <Modal
                    open={openEditUser}
                    cssDialog={styles.modalDialog}
                    onHide={onHideEditAccessUserModal}
                    hideButton={false}
                    title={`Edycja projektu - ${projectName}`}>
                    <Row className={styles.row}>
                        <Col xs={12}>
                            <Subtitle
                                className={styles.sectionTitle}
                                title="Użytkownicy, którzy będą mieli dostęp do projektu"
                            />
                            {data.state.subUsers.length > 0 ? (
                                <Table className={styles.table}>
                                    <thead>
                                        <tr>
                                            <th title="Numer">Nr</th>
                                            <th
                                                className={styles.pointer}
                                                title="Zaznacz/odznacz wszystkich"
                                                onClick={() =>
                                                    data.onAddAllEditUsers(
                                                        data.state.subUsers.map(
                                                            (ele) => ele.id,
                                                        ),
                                                    )
                                                }>
                                                <FontAwesomeIcon
                                                    icon={faListUl}
                                                />
                                            </th>
                                            <th title="Imię">Imię</th>
                                            <th title="Nazwisko">Nazwisko</th>
                                            <th title="Adres email">Email</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.state.subUsers.map(
                                            (element, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <Checkbox1
                                                                css={
                                                                    styles.checkbox
                                                                }
                                                                onChange={() =>
                                                                    data.onChangeCheckboxEditAccess(
                                                                        element.id,
                                                                    )
                                                                }
                                                                value={data.state.editedUserAccess.includes(
                                                                    element.id,
                                                                )}
                                                                name="selectRow"
                                                            />
                                                        </td>
                                                        <td>
                                                            {element.firstName}
                                                        </td>
                                                        <td>
                                                            {element.lastName}
                                                        </td>
                                                        <td>{element.email}</td>
                                                    </tr>
                                                );
                                            },
                                        )}
                                    </tbody>
                                </Table>
                            ) : (
                                <p>
                                    Nie masz żadnych użytkowników przypisanych
                                    do konta
                                </p>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Button
                                css={`
                                    ${styles.button} default-button-style
                                `}
                                title={'Zapisz zmiany'}
                                altTitle="Zapisz zmiany"
                                name="saveChanges"
                                onClick={() =>
                                    data.onSaveEditedUserAccess(projectId)
                                }
                                type="submit"
                            />
                        </Col>
                    </Row>
                </Modal>
            )}

            {
                <Modal
                    open={openInfoModal}
                    cssDialog={styles.modalDialogInfo}
                    onHide={onHideInfoModal}
                    hideButton={false}
                    title={`Informacje o projekcie - ${infoObj.name}`}>
                    <div className={styles.infoWrapper}>
                        <Row>
                            <Col xs={12}>
                                <SectionTitle
                                    title="Nazwa projektu"
                                    className={styles.h2Title}
                                />
                            </Col>
                            <Col xs={12}>
                                <p>{infoObj.name}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <SectionTitle
                                    title="Autor projektu"
                                    className={styles.h2Title}
                                />
                            </Col>
                            <Col xs={12}>
                                <p>{`${infoObj.firstName} ${infoObj.lastName}, ${infoObj.email}`}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <SectionTitle
                                    title="Data utworzenia projektu"
                                    className={styles.h2Title}
                                />
                            </Col>
                            <Col xs={12}>
                                <p>
                                    {moment(infoObj.created)
                                        .local('pl')
                                        .format('DD.MM.YYYY, HH:mm')}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <SectionTitle
                                    title="Data ostatniej aktualizacji projektu"
                                    className={styles.h2Title}
                                />
                            </Col>
                            <Col xs={12}>
                                <p>
                                    {moment(infoObj.updated)
                                        .local('pl')
                                        .format('DD.MM.YYYY, HH:mm')}
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            }
            <AddNewProject
                open={open.createProject}
                onHide={onHide}
                onAddAllUsers={data.onAddAllUsers}
                onChangeCheckbox={data.onChangeCheckbox}
                onChange={data.onChange}
                onBlur={data.onBlur}
                onSubmit={data.onSubmit}
                state={{
                    newMainProjectName: data.state.projectName,
                    subUsers: data.state.subUsers,
                    userAccessToProject: data.state.userAccessToProject,
                }}
                loading={loading.state.creatingProject}
            />
        </Fragment>
    );
};

export default withPagination({
    component: Projects,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 10,
    },
});