import { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { InputField, SectionTitle } from '../../../../../../components';
import { isNumber, roundNumber } from '../../../../../../utils';
import styles from './index.module.css';

const DescriptionResults = ({
    result,
    rodModule,
    isSteelRod,
    prodLengthValues,
    steelRodWeight,
    onChangeRodModule,
    diameter,
    rodName
}) => {
    const globalResults = result[1] || {};

    return (
        <div className={styles.wrapper}>
            <SectionTitle title="Teoretyczna nośność mikropala" />
            <Row>
                <Col xs={12}>
                    {
                        rodName &&
                        <p className={styles.p}>
                            Nazwa żerdzi:{' '}
                            <span className={styles.result}>
                                <b>{rodName}</b>
                            </span>
                        </p>
                    }
                    <p className={styles.p}>
                        Projektowana siła obliczeniowa:{' '}
                        <span className={styles.result}>
                            <b>{globalResults.axisLoad}kN</b>
                        </span>
                    </p>
                    <p className={styles.p}>
                        Osiągnięta nośność obliczeniowa:{' '}
                        <span className={styles.result}>
                            <b>{globalResults.totalCapacity}kN</b>
                        </span>
                    </p>
                    <p className={styles.p}>
                        Średnica wiercenia:{' '}
                        <span className={styles.result}>
                            <b>{diameter}mm</b>
                        </span>
                    </p>
                    <p className={styles.p}>
                        Długość odcinka nośnego/buławy:{' '}
                        <span className={styles.result}>
                            <b>{globalResults.totalBondLength}m</b>
                        </span>
                    </p>
                    <p className={`${styles.p} ${styles.underline}`}>
                        Długość mikropala w gruncie:{' '}
                        <span className={styles.result}>
                            <b>
                                {roundNumber(
                                    globalResults.totalBondLength +
                                        globalResults.freeLength,
                                    2,
                                )}
                                m
                            </b>
                        </span>
                    </p>
                    {isSteelRod && (
                        <Fragment>
                            <p className={styles.p}>
                                Długość zakotwienia głowicy:{' '}
                                <span className={styles.result}>
                                    <b>{globalResults.headLength}m</b>
                                </span>
                            </p>
                            <p className={styles.p}>
                                Długość całkowita żerdzi:{' '}
                                <span className={styles.result}>
                                    <b>
                                        {roundNumber(
                                            globalResults.totalBondLength +
                                                globalResults.freeLength +
                                                globalResults.headLength,
                                            2,
                                        )}
                                        m
                                    </b>
                                </span>
                            </p>
                        </Fragment>
                    )}

                    {globalResults.totalElongation && (
                        <Fragment>
                            {isSteelRod && (
                                <Fragment>
                                    {isNumber(
                                        globalResults.totalElongation[0].ea,
                                    ) && (
                                        <p className={styles.p}>
                                            Sztywność osiowa mikropala EA:{' '}
                                            <span className={styles.result}>
                                                <b>
                                                    {roundNumber(
                                                        globalResults
                                                            .totalElongation[0]
                                                            .ea / 1000000,
                                                        3,
                                                    )}{' '}
                                                    x 10<sup>6</sup>kN
                                                </b>
                                            </span>
                                        </p>
                                    )}
                                    {isNumber(
                                        globalResults.totalElongation[0]
                                            .elonSum,
                                    ) && (
                                        <p className={styles.p}>
                                            Wydłużenie sprężyste mikropala:{' '}
                                            <span className={styles.result}>
                                                <b>
                                                    {roundNumber(
                                                        globalResults
                                                            .totalElongation[0]
                                                            .elonSum * 1000,
                                                        1,
                                                    )}
                                                    mm
                                                </b>
                                            </span>
                                        </p>
                                    )}
                                </Fragment>
                            )}

                            {isNumber(
                                globalResults.totalElongation[0].concreteVolume,
                            ) && (
                                <p className={styles.p}>
                                    Objętość zaczynu cementowego:{' '}
                                    <span className={styles.result}>
                                        <b>
                                            {roundNumber(
                                                globalResults.totalElongation[0]
                                                    .concreteVolume,
                                                2,
                                            )}
                                            m<sup>3</sup>
                                        </b>
                                    </span>
                                </p>
                            )}
                        </Fragment>
                    )}

                    {isNumber(steelRodWeight) && (
                        <p className={styles.p}>
                            Masa całkowita żerdzi:{' '}
                            <span className={styles.result}>
                                <b>
                                    {roundNumber(
                                        (globalResults.totalBondLength +
                                            globalResults.freeLength +
                                            globalResults.headLength) *
                                            steelRodWeight,
                                        2,
                                    )}
                                    kg
                                </b>
                            </span>
                        </p>
                    )}

                    {!globalResults.veryfied && (
                        <Fragment>
                            <h3 className={styles.h3}>UWAGA: </h3>
                            <p className={styles.error}>
                                Nie osiągnięto wymaganej nośności mikropala,
                                zmień/wydłuż profil gruntowy
                            </p>
                        </Fragment>
                    )}
                </Col>
            </Row>

            {isSteelRod && (
                <div className={styles.typicalLength}>
                    <SectionTitle title="Rzeczywista długość mikropala" />
                    <Row>
                        <Col xs={12} sm={8}>
                            <InputField
                                title="Moduł długości żerdzi"
                                name="rodModule"
                                type="number"
                                value={rodModule.value}
                                onChange={onChangeRodModule}
                                placeholder="Moduł żerdzi"
                                required
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className={styles.typicalDescription}>
                                {rodModule.value > 0 ? (
                                    <Fragment>
                                        {isNumber(
                                            prodLengthValues.bondLength,
                                        ) && (
                                            <p className={styles.p}>
                                                Długość odcinka nośnego/buławy:{' '}
                                                <span className={styles.result}>
                                                    <b>
                                                        {
                                                            prodLengthValues.bondLength
                                                        }
                                                        m
                                                    </b>
                                                </span>
                                            </p>
                                        )}
                                        {isNumber(
                                            prodLengthValues.totalLength,
                                        ) && (
                                            <p className={styles.p}>
                                                Długość całkowita mikropala:{' '}
                                                <span className={styles.result}>
                                                    <b>
                                                        {rodModule.value}m x{' '}
                                                        {roundNumber(
                                                            prodLengthValues.totalLength /
                                                                rodModule.value,
                                                            0,
                                                        )}{' '}
                                                        ={' '}
                                                        {
                                                            prodLengthValues.totalLength
                                                        }
                                                        m
                                                    </b>
                                                </span>
                                            </p>
                                        )}
                                        {isNumber(steelRodWeight) && (
                                            <p className={styles.p}>
                                                Masa całkowita żerdzi:{' '}
                                                <span className={styles.result}>
                                                    <b>
                                                        {roundNumber(
                                                            prodLengthValues.totalLength *
                                                                steelRodWeight,
                                                            2,
                                                        )}
                                                        kg
                                                    </b>
                                                </span>
                                            </p>
                                        )}
                                    </Fragment>
                                ) : (
                                    <Fragment></Fragment>
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
};

export default DescriptionResults;
