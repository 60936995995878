import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Loading, Modal } from '../../../../components';
import styles from './index.module.css';

const OverwriteProfile = ({
    open,
    profileName,
    onSubmit,
    overwriteLoading,
    onHideExistProfileModal,
}) => {
    const [openOverwriteProfileState, setOverwriteProfileState] = useState(
        open.value,
    );

    useEffect(() => {
        setOverwriteProfileState(open.value);
    }, [open.value]);

    const onHideOverwriteProfile = () => {
        onHideExistProfileModal({
            value: false,
        });
        setOverwriteProfileState(false);
    };
    return (
        <Modal
            open={openOverwriteProfileState}
            css={styles.modal}
            cssDialog={styles.modalDialog}
            onHide={onHideOverwriteProfile}
            title="Nadpisz profil"
            hideButton={false}>
            <Row>
                <Col xs={12}>
                    <h4 className={styles.h4}>
                        Profil geologiczny o nazwie <span>{profileName}</span>{' '}
                        już istnieje, chcesz nadpisać istniejący profil?
                    </h4>
                </Col>
                <Col xs={12}>
                    <Button
                        css={`
                            ${styles.button} default-button-style
                        `}
                        altTitle="Zapisz"
                        title="Tak, zapisz"
                        name="overwriteProfile"
                        onClick={onSubmit}
                        type="submit"
                    />
                </Col>
            </Row>

            {overwriteLoading && (
                <Loading oppositeColors={true} text="Zapisuję profil..." />
            )}
        </Modal>
    );
};

export default OverwriteProfile;
