import { Col, Row } from 'react-bootstrap';
import { Modal, Button, InputField } from '../../../../components';
import { isEmail } from '../../../../config/ProjectConfig';
import styles from './index.module.css';

const EditUserModal = ({
    open,
    onHide,
    state = {},
    onChange,
    onSubmit,
    onBlur,
}) => {
    return (
        <Modal
            open={open}
            css={styles.modalOverflow}
            cssDialog={styles.modal}
            onHide={onHide}
            hideButton={false}
            title="Edytuj użytkownika">
            <Row className={styles.row}>
                <Col xs={12} sm={6}>
                    <InputField
                        name="firstName"
                        title="Imię"
                        placeholder="Imię"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={state.firstName.value}
                        isValid={state.firstName.isValid}
                        required
                    />
                </Col>
                <Col xs={12} sm={6}>
                    <InputField
                        name="lastName"
                        title="Nazwisko"
                        placeholder="Nazwisko"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={state.lastName.value}
                        isValid={state.lastName.isValid}
                        required
                    />
                </Col>
            </Row>
            <Row className={styles.row}>
                <Col xs={12}>
                    <InputField
                        infoCss={styles.infoField}
                        name="email"
                        title="Adres email"
                        placeholder="Adres email"
                        onBlur={onBlur}
                        onChange={(e) =>
                            onChange(
                                e,
                                isEmail.test(e.target.closest('input').value),
                            )
                        }
                        value={state.email.value}
                        isValid={state.email.isValid}
                        required>
                        Zmiana adresu email wiąże się z koniecznością
                        potwierdzenia autentyczności konta poprzez jego ponowną
                        aktywację. Link do aktywacji zostanie wysłany na podany
                        adres email.
                    </InputField>
                </Col>
            </Row>
            <Row className={`${styles.row} ${styles.right}`}>
                <Col xs={12}>
                    <Button
                        css={`
                            ${styles.button} default-button-style
                        `}
                        onClick={onSubmit}
                        altTitle="Zapisz zmiany"
                        title="Zapisz zmiany"
                    />
                </Col>
            </Row>
        </Modal>
    );
};

export default EditUserModal;
