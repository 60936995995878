import React, { Fragment, useEffect, useRef, useState } from 'react';
import { isNumber, roundNumber, setSoilColorQc } from '../../../../../utils';
import SoilLayer from '../../Results/CptCapacityChart/SoilProfile/SoilLayer';
import styles from './index.module.css';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';


const SettlementChart = ({
    soilProfile,
    results = [],
    pileTypeNumber,
    title,
    currentPileLength,
    pileHeadSpot,
    currentCapacity={},
    diameterPile,
    hType
}) => {

    const soilProfileRef = useRef(null);
    const resultsRef = useRef(null);
    const [resultsWidth, setResultsWidth] = useState(0);
    const [resultsHeight, setResultsHeight] = useState(0);
    const [axisNtSettleScale, setAxisNtSettleScale] = useState([]);
    const [axisZScale, setAxisZScale] = useState([]);
    const [maxNtSettle, setMaxNtSettle] = useState('');
    const [maxZ, setMaxZ] = useState('');
    const [pileBaseLayer, setPileBaseLayer] = useState('')
    const [pileBaseSpot, setPileBaseSpot] = useState('');


    const soilProfileHeights = results.map((ele) => ele.z);

    useEffect(() => {
        if (resultsRef.current) {
            setResultsWidth(resultsRef.current.offsetWidth*0.9);
            setResultsHeight(resultsRef.current.offsetHeight*0.95);
        }
    }, []);

    useDeepCompareEffect(() => {
        if (soilProfileRef.current) {
            const offsetHeight = soilProfileRef.current.offsetHeight;

            const layerBaseSpot = soilProfile.find(ele => ele.z >= pileBaseSpot);

            if(layerBaseSpot){
                setPileBaseLayer(
                    <SoilLayer
                        backgroundColor={setSoilColorQc({
                            type: layerBaseSpot.soilType,
                            qc: layerBaseSpot.qc,
                        })}
                        height={offsetHeight}
                    />
                )
            }
        }
    }, [soilProfile, soilProfileHeights, soilProfileRef, pileBaseSpot]);

    useDeepCompareEffect(() => {
        const nt_settle = currentCapacity.Rck_current_01*1.15;
        const maxAxisNtSettle = roundNumber(nt_settle - (nt_settle % 5), 0);
        const z = Math.max(...results.map((ele) => ele.deq_01));
        const maxAxisZ = Math.ceil(z);

        setMaxNtSettle(nt_settle);
        setAxisNtSettleScale(
            Array(5)
            .fill(maxAxisNtSettle / 5)
            .reduce((acc, ele, idx) => [...acc, ele * (idx + 1)], []),
        );
        setAxisZScale(
            Array(maxAxisZ*2 + 1)
            .fill(0)
            .reduce((acc, _, idx) => {
                if(idx === 0) {
                    return acc
                } else {
                    return [...acc, (idx*0.5)]
                }    
            }, [])
        )
        setMaxZ(maxAxisZ);
        setPileBaseSpot((state) =>
            isNumber(state) && state > pileHeadSpot
                ? state
                : roundNumber(currentPileLength + pileHeadSpot,2),
        );

    }, [
        currentCapacity,
        pileHeadSpot,
        currentPileLength,
        results
    ]);

    return (
        <Fragment>
            {
                <Fragment>
                    <div className={styles.chartWrapper}>
                        <div className={styles.title}>
                            <h2>{title}</h2>
                        </div>
                        <div className={styles.wrapper} ref={soilProfileRef}>
                            <div className={styles.pileWrapper}>
                                <div className={styles.results} ref={resultsRef}>
                                    <div className={styles.horizontalAxis}>
                                        {axisNtSettleScale.map((ele, idx) => {
                                            return (
                                                <span key={idx} className={styles.axisCoord}
                                                    style={{left: `${(resultsWidth / maxNtSettle)*ele}px`}}>
                                                    <p className={ styles.value }>
                                                        {ele}
                                                    </p>
                                                </span>
                                            );
                                        })}

                                        <span className={styles.caret}>
                                            <p className={styles.axisDescription}>
                                                R<sub>c,k</sub>[kN]
                                            </p>
                                        </span>
                                    </div>
                                    <div className={styles.verticalAxis}>
                                        {axisZScale.map((ele, idx) => {
                                        return (
                                            <span key={idx} className={styles.axisCoordVert}
                                                style={{top: `${(resultsHeight / maxZ)*ele}px`}}>
                                                <p className={ styles.valueVert }>{roundNumber(ele*10,0)}</p>
                                            </span>
                                        );
                                        })}
                                        <span className={styles.verticalCaret}>
                                            <p className={styles.axisVerticalDescription}>
                                                s[mm]
                                            </p>
                                        </span>
                                    </div>
                                    {
                                        isNumber(maxNtSettle) && isNumber(maxZ) &&
                                        <div className={styles.chartWrapperResult}>
                                            {
                                                (pileTypeNumber === 4 || pileTypeNumber === 5)
                                                ?
                                                <svg>
                                                    <polyline 
                                                        className={styles.shaftChart} 
                                                        points={`0,0 ${(resultsWidth / maxNtSettle)*currentCapacity.Rsk_current_ssg},${(resultsHeight / maxZ)*currentCapacity.ssg_current} ${(resultsWidth / maxNtSettle)*currentCapacity.Rsk_current_ssg},${(resultsHeight / maxZ)*currentCapacity.deq_01}`}
                                                    ></polyline>

                                                    <polyline 
                                                        className={styles.baseChart} 
                                                        points={`0,0 
                                                        ${(resultsWidth / maxNtSettle)*currentCapacity.Rbk_current_002},${(resultsHeight / maxZ)*currentCapacity.deq_002} 
                                                        ${(resultsWidth / maxNtSettle)*currentCapacity.Rbk_current_003},${(resultsHeight / maxZ)*currentCapacity.deq_003} 
                                                        ${(resultsWidth / maxNtSettle)*currentCapacity.Rbk_current_01},${(resultsHeight / maxZ)*currentCapacity.deq_01}`}
                                                    ></polyline>
                                                    {
                                                        currentCapacity.ssg_current > currentCapacity.deq_002
                                                        ?
                                                        <Fragment>
                                                            {
                                                                currentCapacity.ssg_current > currentCapacity.deq_003
                                                                ?
                                                                <polyline 
                                                                    className={styles.totalChart} 
                                                                    points={`0,0 
                                                                    ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_002},${(resultsHeight / maxZ)*currentCapacity.deq_002} 
                                                                    ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_003},${(resultsHeight / maxZ)*currentCapacity.deq_003} 
                                                                    ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_ssg_current},${(resultsHeight / maxZ)*currentCapacity.ssg_current} 
                                                                    ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01},${(resultsHeight / maxZ)*currentCapacity.deq_01}`}
                                                                ></polyline>
                                                                :
                                                                <polyline 
                                                                    className={styles.totalChart} 
                                                                    points={`0,0 
                                                                    ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_002},${(resultsHeight / maxZ)*currentCapacity.deq_002} 
                                                                    ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_ssg_current},${(resultsHeight / maxZ)*currentCapacity.ssg_current} 
                                                                    ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_003},${(resultsHeight / maxZ)*currentCapacity.deq_003} 
                                                                    ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01},${(resultsHeight / maxZ)*currentCapacity.deq_01}`}
                                                                ></polyline>                                                            
                                                            }
                                                        </Fragment>
                                                        :
                                                        <polyline 
                                                            className={styles.totalChart} 
                                                            points={`0,0 
                                                            ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_ssg_current},${(resultsHeight / maxZ)*currentCapacity.ssg_current} 
                                                            ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_002},${(resultsHeight / maxZ)*currentCapacity.deq_002} ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_003},${(resultsHeight / maxZ)*currentCapacity.deq_003} 
                                                            ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01},${(resultsHeight / maxZ)*currentCapacity.deq_01}`}
                                                        ></polyline>                                                
                                                    }

                                                    <text 
                                                        className={styles.text}
                                                        x={-102 + (resultsWidth / maxNtSettle)*currentCapacity.Rck_ssg_current} 
                                                        y={5+ (resultsHeight / maxZ)*currentCapacity.ssg_current}
                                                    >
                                                        {`${roundNumber(currentCapacity.Rck_ssg_current,1)}kN - ${roundNumber(currentCapacity.ssg_current*10,1)}mm`}
                                                    </text>
                                                    <circle
                                                        cx={(resultsWidth / maxNtSettle)*currentCapacity.Rck_ssg_current}
                                                        cy={(resultsHeight / maxZ)*currentCapacity.ssg_current}
                                                        r="3"
                                                        fill="#fff"
                                                        stroke="#000"
                                                        strokeWidth="2"
                                                    >
                                                    </circle>

                                                    <text 
                                                        className={styles.text}
                                                        x={10 + (resultsWidth / maxNtSettle)*currentCapacity.Rck_current_002} 
                                                        y={2+(resultsHeight / maxZ)*currentCapacity.deq_002}
                                                    >
                                                        {`${roundNumber(currentCapacity.Rck_current_002,1)}kN - ${roundNumber(currentCapacity.deq_002*10,1)}mm`}
                                                    </text>
                                                    <circle
                                                        cx={(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_002}
                                                        cy={(resultsHeight / maxZ)*currentCapacity.deq_002}
                                                        r="3"
                                                        fill="#fff"
                                                        stroke="#000"
                                                        strokeWidth="2"
                                                    >
                                                    </circle>

                                                    <text 
                                                        className={styles.text}
                                                        x={10 + (resultsWidth / maxNtSettle)*currentCapacity.Rck_current_003} 
                                                        y={5 + (resultsHeight / maxZ)*currentCapacity.deq_003}
                                                    >
                                                        {`${roundNumber(currentCapacity.Rck_current_003,1)}kN - ${roundNumber(currentCapacity.deq_003*10,1)}mm`}
                                                    </text>
                                                    <circle
                                                        cx={(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_003}
                                                        cy={(resultsHeight / maxZ)*currentCapacity.deq_003}
                                                        r="3"
                                                        fill="#fff"
                                                        stroke="#000"
                                                        strokeWidth="2"
                                                    >
                                                    </circle>

                                                    <text 
                                                        className={styles.text}
                                                        x={10 + (resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01} 
                                                        y={5 + (resultsHeight / maxZ)*currentCapacity.deq_01}
                                                    >
                                                        {`${roundNumber(currentCapacity.Rck_current_01,1)}kN - ${roundNumber(currentCapacity.deq_01*10,1)}mm`}
                                                    </text>
                                                    <circle
                                                        cx={(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01}
                                                        cy={(resultsHeight / maxZ)*currentCapacity.deq_01}
                                                        r="3"
                                                        fill="#fff"
                                                        stroke="#000"
                                                        strokeWidth="2"
                                                    >
                                                    </circle>

                                                    <text 
                                                        className={styles.textLine}
                                                        x={(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01} 
                                                        y={15 + (resultsHeight / maxZ)*currentCapacity.deq_01}
                                                    >
                                                        R<tspan dy ="4">c,k</tspan>
                                                    </text>

                                                    <text 
                                                        className={styles.textLine}
                                                        x={(resultsWidth / maxNtSettle)*currentCapacity.Rbk_current_01 - 10} 
                                                        y={15 + (resultsHeight / maxZ)*currentCapacity.deq_01}
                                                    >
                                                        R<tspan dy ="4">b,k</tspan>
                                                    </text>

                                                    <text 
                                                        className={styles.textLine}
                                                        x={(resultsWidth / maxNtSettle)*currentCapacity.Rsk_current_ssg - 10} 
                                                        y={15 + (resultsHeight / maxZ)*currentCapacity.deq_01}
                                                    >
                                                        R<tspan dy ="4">s,k</tspan>
                                                    </text>
                                                </svg>
                                                :
                                                <svg>
                                                    <polyline 
                                                        className={styles.shaftChart} 
                                                        points={`0,0 ${(resultsWidth / maxNtSettle)*currentCapacity.Rsk_current_ssg},${(resultsHeight / maxZ)*currentCapacity.ssg_current} ${(resultsWidth / maxNtSettle)*currentCapacity.Rsk_current_01D},${(resultsHeight / maxZ)*currentCapacity.deq_01}`}
                                                    ></polyline>

                                                    <polyline 
                                                        className={styles.baseChart} 
                                                        points={`0,0 ${(resultsWidth / maxNtSettle)*currentCapacity.Rbk_current_0035},${(resultsHeight / maxZ)*currentCapacity.deq_0035} ${(resultsWidth / maxNtSettle)*currentCapacity.Rbk_current_01D},${(resultsHeight / maxZ)*currentCapacity.deq_01}`}
                                                    ></polyline>
                                                    {
                                                        currentCapacity.ssg_current > currentCapacity.deq_0035
                                                        ?
                                                        <polyline 
                                                            className={styles.totalChart} 
                                                            points={`0,0 
                                                                ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_0035},${(resultsHeight / maxZ)*currentCapacity.deq_0035} 
                                                                ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_ssg_current},${(resultsHeight / maxZ)*currentCapacity.ssg_current} 
                                                                ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01},${(resultsHeight / maxZ)*currentCapacity.deq_01}`}
                                                        ></polyline>
                                                        :
                                                        <polyline 
                                                            className={styles.totalChart} 
                                                            points={`0,0 ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_ssg_current},${(resultsHeight / maxZ)*currentCapacity.ssg_current} ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_0035},${(resultsHeight / maxZ)*currentCapacity.deq_0035} ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01},${(resultsHeight / maxZ)*currentCapacity.deq_01}`}
                                                        ></polyline>                                                    
                                                    }

                                                    <text 
                                                        className={styles.text}
                                                        x={10 + (resultsWidth / maxNtSettle)*currentCapacity.Rck_ssg_current} 
                                                        y={5 + (resultsHeight / maxZ)*currentCapacity.ssg_current}
                                                    >
                                                        {`${roundNumber(currentCapacity.Rck_ssg_current,1)}kN - ${roundNumber(currentCapacity.ssg_current*10,1)}mm`}
                                                    </text>
                                                    <circle
                                                        cx={(resultsWidth / maxNtSettle)*currentCapacity.Rck_ssg_current}
                                                        cy={(resultsHeight / maxZ)*currentCapacity.ssg_current}
                                                        r="3"
                                                        fill="#fff"
                                                        stroke="#000"
                                                        strokeWidth="2"
                                                    >
                                                    </circle>

                                                    <text 
                                                        className={styles.text}
                                                        x={10 + (resultsWidth / maxNtSettle)*currentCapacity.Rck_current_0035} 
                                                        y={5 + (resultsHeight / maxZ)*currentCapacity.deq_0035}
                                                    >
                                                        {`${roundNumber(currentCapacity.Rck_current_0035,1)}kN - ${roundNumber(currentCapacity.deq_0035*10,1)}mm`}
                                                    </text>
                                                    <circle
                                                        cx={(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_0035}
                                                        cy={(resultsHeight / maxZ)*currentCapacity.deq_0035}
                                                        r="3"
                                                        fill="#fff"
                                                        stroke="#000"
                                                        strokeWidth="2"
                                                    >
                                                    </circle>

                                                    <text 
                                                        className={styles.text}
                                                        x={10 + (resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01} 
                                                        y={5 + (resultsHeight / maxZ)*currentCapacity.deq_01}
                                                    >
                                                        {`${roundNumber(currentCapacity.Rck_current_01,1)}kN - ${roundNumber(currentCapacity.deq_01*10,1)}mm`}
                                                    </text>
                                                    <circle
                                                        cx={(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01}
                                                        cy={(resultsHeight / maxZ)*currentCapacity.deq_01}
                                                        r="3"
                                                        fill="#fff"
                                                        stroke="#000"
                                                        strokeWidth="2"
                                                    >
                                                    </circle>

                                                    <text 
                                                        className={styles.textLine}
                                                        x={(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01} 
                                                        y={15 + (resultsHeight / maxZ)*currentCapacity.deq_01}
                                                    >
                                                        R<tspan dy ="4">c,k</tspan>
                                                    </text>

                                                    <text 
                                                        className={styles.textLine}
                                                        x={(resultsWidth / maxNtSettle)*currentCapacity.Rbk_current_01D - 20} 
                                                        y={15 + (resultsHeight / maxZ)*currentCapacity.deq_01}
                                                    >
                                                        R<tspan dy ="4">b,k</tspan>
                                                    </text>

                                                    <text 
                                                        className={styles.textLine}
                                                        x={(resultsWidth / maxNtSettle)*currentCapacity.Rsk_current_01D} 
                                                        y={15 + (resultsHeight / maxZ)*currentCapacity.deq_01}
                                                    >
                                                        R<tspan dy ="4">s,k</tspan>
                                                    </text>
                                                </svg>
                                            }
                                        </div>                                        
                                    }

                                    <div className={styles.currentHorizontalValue} 
                                        style={{ 
                                            top: `${(resultsHeight / maxZ)*currentCapacity.s}px`,
                                            width: `${(resultsWidth / maxNtSettle)*currentCapacity.f}px`,
                                        }}
                                    >
                                        <div className={styles.line}></div>
                                        {
                                            isNumber(currentCapacity.s) &&
                                            <div className={styles.spaceBetween}>
                                                <p className={styles.currentSettleValue}>{roundNumber(currentCapacity.s*10,2)}mm</p>
                                                <p className={styles.currentSettleValue}>{roundNumber(currentCapacity.f,2)}kN</p>
                                            </div>

                                        }
                                    </div>
                                    <div className={styles.currentVerticalValue}
                                        style={{
                                            height: `${(resultsHeight / maxZ)*currentCapacity.s}px`,
                                            left: `${(resultsWidth / maxNtSettle)*currentCapacity.f}px`,
                                        }}    
                                    >
                                        <div className={styles.lineVertical}></div>
                                    </div>
                                    <div 
                                        className={`${styles.pile} ${[6,7].includes(pileTypeNumber) ? styles.pileH : ''}`}
                                    >
                                        {
                                        (pileTypeNumber === 4 || pileTypeNumber === 5 || pileTypeNumber === 2)
                                        ?
                                        <Fragment>
                                            <div className={`${styles.pileShape} ${styles.circle}`}></div>
                                            <p><b>D={diameterPile}m</b></p>
                                            <p><b>L={roundNumber(currentPileLength, 2).toFixed(1)}m</b></p>
                                        </Fragment>

                                        :
                                            (pileTypeNumber === 3)
                                            ?
                                            <Fragment>
                                                <div className={`${styles.pileShape} ${styles.openEnded}`}>
                                                    <div className={`${styles.openEndedInner}`}></div>
                                                </div>
                                                <p><b>D={diameterPile}m</b></p>
                                                <p><b>L={roundNumber(currentPileLength, 2).toFixed(1)}m</b></p>
                                            </Fragment>

                                            :
                                            (pileTypeNumber === 6)
                                            ?
                                            <Fragment>
                                                <div className={`${styles.hWrapperView}`}>
                                                    <div className={`${styles.hTypeFlange}`}></div>
                                                    <div className={`${styles.hTypeHeight}`}></div>
                                                    <div className={`${styles.hTypeFlange}`}></div>
                                                </div>
                                                <p><b>{hType}</b></p>
                                                <p><b>L={roundNumber(currentPileLength, 2).toFixed(1)}m</b></p>
                                            </Fragment>

                                            :
                                            (pileTypeNumber === 7)
                                            ?
                                            <Fragment>
                                                <div className={`${styles.hWrapperView2H}`}>
                                                    <div className={`${styles.hWrapperView}`}>
                                                        <div className={`${styles.hTypeFlange}`}></div>
                                                        <div className={`${styles.hTypeHeight}`}></div>
                                                        <div className={`${styles.hTypeFlange}`}></div>
                                                    </div>
                                                    <div className={`${styles.hWrapperView}`}>
                                                        <div className={`${styles.hTypeFlange}`}></div>
                                                        <div className={`${styles.hTypeHeight}`}></div>
                                                        <div className={`${styles.hTypeFlange}`}></div>
                                                    </div>
                                                </div>
                                                <p><b>{hType}</b></p>
                                                <p><b>L={roundNumber(currentPileLength, 2).toFixed(1)}m</b></p>
                                            </Fragment>

                                            :
                                            <Fragment>
                                                <div className={`${styles.pileShapePref}`}></div>
                                                <p><b>B={diameterPile}m</b></p>
                                                <p><b>L={roundNumber(currentPileLength, 2).toFixed(1)}m</b></p>
                                            </Fragment>
                                        }
                                    </div>
                                </div>                            
                            </div>
                            {
                                pileBaseLayer &&
                                <div>
                                    {pileBaseLayer}
                                </div>                                
                            }

                        </div>
                    </div>
                </Fragment>
            }
        </Fragment>

    );
};

export default React.memo(
    SettlementChart,
    (prev, next) => stringify(prev) === stringify(next),
);
