import React from 'react';
import styles from './index.module.css';

const LabelName = ({title}) => {

    return (
        <label className={styles.title}>{title}</label>
    )
} 

export default LabelName