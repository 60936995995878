import React from 'react';
import Button from '../Button';
import { Modal } from 'react-bootstrap';
import styles from './index.module.css';

const ConfirmModal = ({ open, onHide, onConfirm, cssDialog, title='', children }) => {

    return ( 
        <Modal contentClassName={styles.modalContent} dialogClassName={`${styles.modalDialogWrapper} ${cssDialog ? cssDialog : ''}`} show={open} onHide={onHide}>
            <Modal.Dialog className={styles.modalDialog}> 
                <Modal.Header className={styles.modalHeader}>
                    <Modal.Title className={styles.modalTitle}>{title}</Modal.Title> 
                </Modal.Header> 
                <Modal.Body className={styles.modalBody}>   
                    <span className={styles.span}>{children}</span>
                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                    <div className={styles.buttonsWrapper}>
                        <Button css={`${styles.defaultButton}`} onClick={onHide}>Nie</Button>
                        <Button css={`${styles.defaultButton} default-button-style`} onClick={onConfirm}>Tak</Button>
                    </div>
                </Modal.Footer>                    
            </Modal.Dialog>
        </Modal>  
    ) 
}

export default ConfirmModal;