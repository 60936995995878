import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import config from '../../config/config';
import {
    Register,
    Login,
    Activation,
    ResetPassword,
    Error,
    SetPassword,
    Home,
    HorizontalCapacity,
    Micropiles,
    Projects,
    CapacityCharts,
    VerticalCapacity,
    MicropilesCompression,
    Reinforcement,
    CptPile,
    SheetPileCpt,
    Waling,
    AdminLogin,
} from '../../pages';
import HorizontalCapacityResults from '../../pages/HorizontalCapacity/results/Results';
import VerticalResults from '../../pages/VerticalCapacity/results/Results';
import MicropileResults from '../../pages/Micropiles/results/Results';
import MicropilesCompressionResults from '../../pages/MicropilesCompression/results/Results';
import ReinforcementResults from '../../pages/Reinforcement/results/Results';
import PileCptaResults from '../../pages/CptPile/results/Results';
import SheetPileCptResults from '../../pages/SheetPileCpt/results/Results';

import ThemeProvider from '../../context/Theme';
import CheckoutSuccess from '../../pages/Login/CheckoutSuccess';
import CheckoutCancel from '../../pages/Login/CheckoutCancel';

import GlobalLayout from '../Layouts/GlobalLayout/index.js';
import HomeLayout from '../Layouts/HomeLayout';
import AdminAuthLayout from '../Layouts/AdminAuthLayout';
import AuthLayout from '../Layouts/AuthLayout';
import HorizontalCapacityLayout from '../Layouts/HorizontalCapacityLayout';
import CapacityChartsLayout from '../Layouts/CapacityChartsLayout';
import ProjectsLayout from '../Layouts/ProjectsLayout';
import VerticalCapacityLayout from '../Layouts/VerticalCapacityLayout';
import MicropilesLayout from '../Layouts/MicropilesLayout';
import MicropileCompressionLayout from '../Layouts/MicropileCompressionLayout';
import LoginLayout from '../Layouts/LoginLayout';
import AdminLoginLayout from '../Layouts/AdminLoginLayout';
import AdminGlobalLayout from '../Layouts/AdminGlobalLayout';

import { Home as AdminHome, UsersList } from '../../pages/Admin';
import PaymentsList from '../../pages/Admin/Payments'
import AdminHomeLayout from '../Layouts/AdminHomeLayout';
import AdminUserListLayout from '../Layouts/AdminUserListLayout';
import AdminPaymentsLayout from '../Layouts/AdminPaymentsLayout';
import ReinforcementLayout from '../Layouts/ReinforcementLayout';
import CptPileLayout from '../Layouts/CptPileLayout';
import SheetPileCptLayout from '../Layouts/SheetPileCptLayout';
import ResetPasswordLayout from '../Layouts/ResetPasswordLayout/index.js';
import WalingLayout from '../Layouts/WalingLayout/index.js';
import { useNavigate } from 'react-router-dom';


const ApplicationRoutes = () => {
    const navigate = useNavigate();    
    const { routes } = config;
    const location = useLocation();
    const [openSidebar, setOpenSidebar] = useState(true);

    useEffect(() => {
        window.innerWidth > 1100 ? setOpenSidebar(false) : setOpenSidebar(true);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = (event) =>
        event.target.innerWidth > 1100
            ? setOpenSidebar(false)
            : setOpenSidebar(true);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        if(location.pathname === '/'){
            navigate(routes.dashboard.logIn)
        }
    }, [location.pathname]);

    return (
        <ThemeProvider> 
            <Routes>
                <Route element={<AdminAuthLayout />}>
                    <Route element={<AdminLoginLayout />}>
                        <Route 
                            path={routes.admin.logIn}
                            element={<AdminLogin />}>
                        </Route>
                    </Route>
                    <Route
                        element={
                            <AdminGlobalLayout
                                openSidebar={openSidebar}
                                setOpenSidebar={setOpenSidebar}
                            />
                        }
                    >
                        <Route element={<AdminHomeLayout />}>
                            <Route path={routes.admin.home} element={<AdminHome />}></Route>                                
                        </Route>
                        <Route element={<AdminUserListLayout />}>
                            <Route path={routes.admin.usersList} element={<UsersList />}></Route>
                        </Route>
                        <Route element={<AdminPaymentsLayout />}>
                            <Route path={routes.admin.payments} element={<PaymentsList />}></Route>
                        </Route>
                    </Route>
                </Route>
                <Route element={<AuthLayout />}>
                    <Route element={<LoginLayout />}>
                        <Route 
                            path={routes.dashboard.logIn}
                            element={<Login />}></Route>                        
                    </Route>
                    <Route element={<ResetPasswordLayout />}>
                        <Route
                            path={routes.app.resetPassword}
                            element={<ResetPassword />}>
                        </Route>
                    </Route>
                    <Route
                        element={
                            <GlobalLayout
                                openSidebar={openSidebar}
                                setOpenSidebar={setOpenSidebar}
                            />
                        }>
                        <Route
                            path={routes.dashboard.checkoutCancel}
                            element={<CheckoutCancel />}></Route>
                        <Route
                            path={routes.dashboard.checkoutSuccess}
                            element={<CheckoutSuccess link={false} />}></Route>
                        <Route element={<HomeLayout />}>
                            <Route
                                path={routes.dashboard.home}
                                element={<Home />}></Route>
                        </Route>
                        <Route element={<VerticalCapacityLayout />}>
                            <Route
                                path={routes.dashboard.verticalCapacityModel}
                                element={<VerticalCapacity />}></Route>
                            <Route
                                path={routes.dashboard.verticalCapacityResults}
                                element={
                                    <VerticalResults
                                        openSidebar={openSidebar}
                                    />
                                }></Route>
                        </Route>
                        <Route element={<HorizontalCapacityLayout />}>
                            <Route
                                index
                                path={routes.dashboard.koseckiMethodModel}
                                element={<HorizontalCapacity />}></Route>
                            <Route
                                path={routes.dashboard.koseckiMethodResults}
                                element={<HorizontalCapacityResults />}></Route>
                        </Route>
                        <Route element={<CapacityChartsLayout />}>
                            <Route
                                path={routes.dashboard.charts}
                                element={<CapacityCharts />}></Route>
                        </Route>
                        <Route element={<MicropilesLayout />}>
                            <Route
                                path={routes.dashboard.micropilesModel}
                                element={<Micropiles />}></Route>
                            <Route
                                path={routes.dashboard.micropilesResults}
                                element={
                                    <MicropileResults
                                        openSidebar={openSidebar}
                                    />
                                }></Route>
                        </Route>
                        <Route element={<MicropileCompressionLayout />}>
                            <Route
                                path={
                                    routes.dashboard.micropilesCompressionModel
                                }
                                element={<MicropilesCompression />}></Route>
                            <Route
                                path={
                                    routes.dashboard
                                        .micropilesCompressionResults
                                }
                                element={
                                    <MicropilesCompressionResults
                                        openSidebar={openSidebar}
                                    />
                                }></Route>
                        </Route>
                        <Route element={<ReinforcementLayout />}>
                            <Route
                                    path={
                                        routes.dashboard.reinforcementModel
                                    }
                                    element={<Reinforcement />}>
                            </Route>
                            <Route
                                    path={
                                        routes.dashboard.reinforcementResults
                                    }
                                    element={
                                        <ReinforcementResults 
                                            openSidebar={openSidebar}    
                                        />
                                    }>
                            </Route>
                        </Route>
                        <Route element={<CptPileLayout />}>
                            <Route path={routes.dashboard.pileCptModel} element={<CptPile/>}></Route>
                            <Route path={routes.dashboard.pileCptResults} element={<PileCptaResults openSidebar={openSidebar}/>}></Route>
                        </Route>

                        <Route element={<SheetPileCptLayout />}>
                            <Route path={routes.dashboard.sheetPileCptModel} element={<SheetPileCpt/>}></Route>
                            <Route path={routes.dashboard.sheetPileCptResults} element={<SheetPileCptResults openSidebar={openSidebar}/>}></Route>
                        </Route>

                        <Route element={<WalingLayout />}>
                            <Route path={routes.dashboard.walingModel} element={<Waling/>}></Route>
                        </Route>

                        <Route element={<ProjectsLayout />}>
                            <Route
                                path={routes.dashboard.projects}
                                element={<Projects />}></Route>
                        </Route>
                    </Route>
                </Route>                    
                <Route
                    path={routes.app.signUp}
                    element={<Register />}></Route>
                <Route
                    path={routes.app.checkoutSuccess}
                    element={<CheckoutSuccess />}></Route>
                <Route
                    path={routes.app.checkoutCancel}
                    element={<CheckoutCancel />}></Route>
                <Route
                    path={routes.app.activation}
                    element={<Activation />}></Route>
                        <Route
                            path={routes.app.setPassword}
                            element={<SetPassword />}>
                        </Route>
                <Route path="*" element={<Error />}></Route>
            </Routes>
        </ThemeProvider>
    );
};

export default ApplicationRoutes;