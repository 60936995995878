import { isNumber } from "../../../utils";
import { defaultRocks } from '../../../config/ProjectConfig';
import { AuthFetch } from "../../../routes";

const SubmitCalculationHelpers = ({ 
    soilTable, 
    micropileProperties, 
    zwgLevels, 
    saftyFactors, 
    results, 
    setAlert,  
    setLoading 
}) => {

    const onSubmitCalculation = () => {
        const micropileParametersToSend = Object.fromEntries(
            Object.entries(micropileProperties.state)
            .filter((ele,_,arr) => ele[0] !== 'allRods' && ele[0] !== 'allScrews')
            .filter((ele,_,arr) => !arr.find(ele => ele[0] === 'isSlipPlate')[1].value ? ele[0] !== 'slipPlate' : ele)
            .filter((ele,_,arr) => !arr.find(ele => ele[0] === 'isFreeLength')[1].value ? ele[0] !== 'freeLength' : ele)
            .filter((ele,_,arr) => !arr.find(ele => ele[0] === 'isSteelRod')[1].value ? !['rodType','screwType','screwTypeDiameter','rodThreadbarType'].includes(ele[0]) : ele[0] !== 'micropileScrewDiameter')
            .filter((ele,_,arr) => arr.find(ele => ele[0] === 'micropileType')[1].value === 1 ? !['rodThreadbarType'].includes(ele[0]) : ele)
            .filter((ele,_,arr) => arr.find(ele => ele[0] === 'micropileType')[1].value === 2 ? !['rodType','screwType'].includes(ele[0]) : ele)
        );

        const soilTableToSend = soilTable.state.map(element => {
            return Object.fromEntries(Object.entries(element).filter(ele => !['absHeight','layerNo'].includes(ele[0])));
        })
        .map(element => {
            return Object.fromEntries(Object.entries(element)
            .filter((ele,_,arr) => {
                const qccpt = arr.find(ele => ele[0] === 'qccpt'); 

                if(!isNumber(qccpt[1].value)){
                    return ele[0] !== 'qccpt'
                } else {
                    return ele
                }
            })
            .filter((ele,_,arr) => {
                const cucpt = arr.find(ele => ele[0] === 'cucpt');

                if(!isNumber(cucpt[1].value)){
                    return ele[0] !== 'cucpt'
                } else {
                    return ele
                }
            })
            .filter((ele,_,arr) => {
                const plasticity = arr.find(ele => ele[0] === 'plasticity');
                const density = arr.find(ele => ele[0] === 'density');

                if(plasticity[1] && density[1]){
                    return isNumber(plasticity[1].value) && !isNumber(density[1].value) ? ele[0] !== 'density'
                        : !isNumber(plasticity[1].value) && isNumber(density[1].value) ? ele[0] !== 'plasticity'
                        : ele
                } else {
                    return ele
                }
            })
            .filter((ele,_,arr) => {
                const rocks = defaultRocks.map(ele => ele.name);
                const soilName = arr.find(ele => ele[0] === 'soilName');
                const isRock = soilName[0] === 'soilName' && rocks.includes(soilName[1].value);

                if(isRock){
                    return ele[0] !== 'density' && ele[0] !== 'plasticity'
                } else {
                    return ele
                }
            })
            )
        });

        const zwgLevelsToSend = Object.fromEntries(Object.entries(zwgLevels.state)
            .filter((ele,_,arr) => {
                const leftZwg = arr.find(ele => ele[0] === 'leftZwg');
                const rightZwg = arr.find(ele => ele[0] === 'rightZwg');

                if(!isNumber(leftZwg[1].value) && isNumber(rightZwg[1].value)){
                    return ele[0] !== 'leftZwg';
                } else if(isNumber(leftZwg[1].value) && !isNumber(rightZwg[1].value)){
                    return ele[0] !== 'rightZwg'
                } else if(!isNumber(leftZwg[1].value) && !isNumber(rightZwg[1].value)){
                    return ele[0] !== 'leftZwg' && ele[0] !== 'rightZwg'
                } else {
                    return ele
                }
            })
            .filter((ele,_,arr) => {
                const groundLevel = arr.find(ele => ele[0] === 'groundLevel');

                if(!isNumber(groundLevel[1].value)){
                    return ele[0] !== 'groundLevel';
                } else {
                    return ele
                }
            }) 
        );

        const saftyFactorsToSend = Object.fromEntries(Object.entries(saftyFactors.state)
            .filter((ele) => micropileProperties.state.isFreeLength.value ? ele[0] !== 'micropileFreeSpace' : ele)
            .filter((ele) => micropileProperties.state.isSteelRod.value && (micropileProperties.state.rodType.value || micropileProperties.state.rodThreadbarType.value) ? ele : !['steelYoungModulus','concreteYoungModulus','steelRodYt','designToCharLoads','steelRodCapacity','bondCapacityType'].includes(ele[0]))
        );

        const isValidMicropileProperties = Object.values(micropileParametersToSend).every(ele => ele.isValid);
        const isValidSoilState = soilTableToSend.every(ele => Object.values(ele).every(ele => ele.isValid));
        const isValidZwgLevelsState = Object.values(zwgLevelsToSend).every(ele => ele.isValid);
        const isValidSaftyFactorsState = Object.values(saftyFactorsToSend).every(ele => ele.isValid);

        if(isValidSoilState && isValidMicropileProperties && isValidZwgLevelsState && isValidSaftyFactorsState){
            setLoading({ name: 'calculations', loading: true });
            AuthFetch({
                url: '/api/micropilePull/calculations/submit', 
                method: 'POST',
                body: {
                    soilTable: soilTableToSend.map(ele => Object.entries(ele).reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }),{})),
                    zwgLevels: Object.entries(zwgLevelsToSend).reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }),{}),
                    micropileParameters:Object.entries(micropileParametersToSend).reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }),{}),
                    saftyFactors: Object.entries(saftyFactorsToSend).reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }),{}),
                }
            }).then(response => {
                setLoading({ name: 'calculations', loading: false });
                if(response.success){
                    results.updateState({
                        isResultsActual: true,
                        ...response.responseData
                    })
                } else {
                    setAlert({ error: true, message: 'Błąd podczas wykonywania obliczeń' });
                }
            })


        } else {
            const invalidSoilState = soilTableToSend.map(ele => Object.entries(ele).reduce( (acc, [key, value]) => ({ ...acc, [key]: { value: value.value, isValid: !!value.isValid } }),{}));

            !isValidSoilState && setAlert({ error: true, message: 'Nieprawidłowe parametry podłoża gruntowego' });
            !isValidMicropileProperties && setAlert({ error: true, message: 'Nieprawidłowe parametry mikropala' });
            !isValidZwgLevelsState && setAlert({ error: true, message: 'Nieprawidłowe parametry profilu gruntowego' });
            !isValidSaftyFactorsState && setAlert({ error: true, message: 'Nieprawidłowe współczynniki bezpieczeństwa' });

            soilTable.updateState(state => state.map((element, index) => {
                return {
                    ...element,
                    ...invalidSoilState[index]
                }
            }));
            micropileProperties.updateState(state => {
                return {
                    ...state,
                    ...Object.entries(micropileParametersToSend).reduce((acc, [key, value]) => ({ ...acc, [key]: { value: value.value, isValid: !!value.isValid } }) ,{})
                }
            });
            zwgLevels.updateState(state => {
                return {
                    ...state,
                    ...Object.entries(zwgLevelsToSend)
                            .reduce((acc, [key, value]) => (
                                { ...acc, 
                                    [key]:{ value: value.value, isValid: !!value.isValid } 
                                })
                            ,{})
                }
            });
            saftyFactors.updateState(state => {
                return {
                    ...state,
                    ...Object.entries(saftyFactorsToSend).reduce( (acc, [key, value]) => ({ ...acc, [key]: { value: value.value, isValid: !!value.isValid } }) ,{}),
                }
            })
        }
    }

    const submitCalculation = {
        onSubmit: onSubmitCalculation
    }
    return {
        submitCalculation
    }
}

export default SubmitCalculationHelpers