import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Loading, Modal } from '../../../../components';
import styles from './index.module.css';

const OverwriteProfile = ({ open, profileName, onSubmit, overwriteLoading, onHideExistProfileModal }) => {

    return (
        <Modal open={open} css={styles.modal} cssDialog={styles.modalDialog} onHide={onHideExistProfileModal} title="Nadpisz profil" hideButton={false}>
            <Row>
                <Col xs={12}>
                    <h4 className={styles.h4}>Profil geologiczny o nazwie <span>{profileName}</span> już istnieje, chcesz nadpisać istniejący profil?</h4>
                </Col>
                <Col xs={12}>
                    <Button
                        css={`${styles.button} default-button-style`}
                        altTitle="Zapisz"
                        title="Tak, zapisz"
                        name="overwriteProfile"
                        onClick={onSubmit}
                        type="submit"
                    />
                </Col>                
            </Row>

            {overwriteLoading && <Loading text="Zapisuję profil..."/>}

        </Modal>
    )
}

export default OverwriteProfile