import { useCallback, useState } from 'react';
import { isNumber } from '../../utils';
import stringify from 'fast-json-stable-stringify';

const defaultState = {
    alerts: [],
};

const AlertState = () => {
    const [alerts, setAlerts] = useState(defaultState);

    const addAlert = (alert) => {
        setAlerts((state) => ({
            ...state,
            alerts: [...state['alerts'], { ...alert }].filter(
                (element, index, arr) =>
                    arr.findIndex(
                        (ele) =>
                            stringify(ele) === stringify(element),
                    ) === index,
            ),
        }));

        if (isNumber(alert.hideTime)) {
            setTimeout(() => {
                setAlerts((state) => ({
                    ...state,
                    alerts: state.alerts.filter(
                        (ele) => ele.message !== alert.message,
                    ),
                }));
            }, alert.hideTime);
        }
    };

    const globalAlerts = {
        state: alerts,
        updateState: useCallback((obj) => setAlerts(obj), []),
        initState: useCallback(() => setAlerts(defaultState), []),
        addAlert: useCallback((alert) => addAlert(alert), []),
    };

    return {
        globalAlerts,
    };
};

export default AlertState;
