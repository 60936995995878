import React, { useState } from 'react';
import { chartsMaxYTick } from '../../../../../utils';
import { withDefaultPrintChart } from '../../../../../hoc';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';
import ApexCharts from "apexcharts";

const KxChart = ({
    series = [],
    chartTitle = '',
    yLabel,
    xLabel, 
    chartOptions = {},
    onLoad,
}) => {
    const [seriesState, setSeriesState] = useState([]);
    const [options, setOptions] = useState(chartOptions);

    useDeepCompareEffect(() => {
        setOptions((state) => {
            const maxZ = Math.max(...series.map((ele) => ele.z));
            const { maxNum, maxTick } = chartsMaxYTick(maxZ);

            return {
                ...state,
                chart: {
                    ...state['chart'],
                },
                title: {
                    ...state['title'],
                    text: chartTitle,
                },
                yaxis: {
                    ...state['yaxis'],
                    type: 'numeric',
                    tickAmount: maxTick,
                    max: maxNum,
                },
                xaxis: {
                    ...state['xaxis'],
                    max:
                        Math.max(
                            ...series.reduce(
                                (acc, ele) => [...acc, ele.kx_1, ele.kx_2],
                                [],
                            ),
                        ),
                    title: {
                        ...state['xaxis']['title'],
                        offsetY: 30,
                        text: xLabel,
                    },
                    labels: {
                        ...state['xaxis']['labels'],
                        offsetY: 10
                    }
                },
            };
        });
        setSeriesState(() => {
            return [
                {
                    name: 'Z',
                    data: [
                        ...series.reduce((acc, ele, index, arr) => {
                            if (index === arr.length - 1) {
                                return [
                                    ...acc,
                                    [ele.kx_1, ele.z - ele.h],
                                    [ele.kx_2, ele.z],
                                    [0, ele.z],
                                ];
                            } else {
                                return [
                                    ...acc,
                                    [ele.kx_1, ele.z - ele.h],
                                    [ele.kx_2, ele.z],
                                ];
                            }
                        }, []),
                    ],
                },
            ];
        });
    }, [series, chartTitle, xLabel, yLabel]);

    useDeepCompareEffect(() => {
        let chart = new ApexCharts(document.querySelector("#kxChart"), {
            series: seriesState,
            ...options,
            chart: {
                ...options['chart'],
                type: 'area'
            }
        });
        chart.render();

        chart.dataURI().then(({ imgURI }) => {
            onLoad({ kxChart: imgURI })
        });

        return () => chart.destroy();
    },[seriesState, options]);

    return (
        <div id="kxChart"></div>
    );
};

export default React.memo(
    withDefaultPrintChart(KxChart),
    (prev, next) => stringify(prev) === stringify(next),
);
