import styles from './index.module.css'

const PdfHeader = ({ author, contact, authorTitle='Autor obliczeń' }) => {

    return (
        <div className={styles.header}>
            <div className={styles.wrapper}>
                <div>
                    <p>{authorTitle}: <br></br><b>{`${author}`}</b></p>
                </div>
                <div>
                    <p>Kontakt: <br></br><b>{`${contact}`}</b></p>
                </div>
            </div>
        </div>
    )
}

export default PdfHeader