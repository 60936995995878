import React, { Fragment, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import ProjectTitle from './model/ProjectTitle';
import { reinforcementContext } from '../../context/Reinforcement';
import { AlertMessage, Loading } from '../../components';
import SubmitCalculations from '../SubmitCalculations';
import styles from './index.module.css';
import CalculationInput from './model/CalculationInput';
import CrossSectionModel from './model/CrossSectionModel';
import SaftyFactors from './model/SaftyFactors';

const Reinforcement = () => {
    const {
        projectTitle = {},
        globalLoading = {},
        globalAlerts = {},
        calculationInput = {}, 
        submitCalculation = {}
    } = useContext(reinforcementContext);
    
    return (
        <Fragment>
            <section className={styles.section}>
                <Row>
                    <ProjectTitle {...projectTitle} />
                </Row>
            </section>
            <section className={styles.section}>
                <Row>
                    <Col className={styles.colMargin} xs={12} sm={4}>
                        <CrossSectionModel 
                            dimension={calculationInput.state.diameterPile.value}
                            width={calculationInput.state.width.value}
                            pileShape={calculationInput.state.pileShape.value}
                            memberType={calculationInput.state.memberType.value}
                            cover={calculationInput.state.cover.value}
                            coverUp={calculationInput.state.coverUp.value}
                            coverBottom={calculationInput.state.coverBottom.value}
                        />
                    </Col>
                    <Col xs={12} sm={8}>
                        <CalculationInput {...calculationInput} />
                        <SaftyFactors 
                            onChange={calculationInput.onChange}
                            onBlur={calculationInput.onBlur}
                            onKeyDown={calculationInput.onKeyDown}
                            state={{
                                yt: calculationInput.state.yt,
                                yc: calculationInput.state.yc,
                                euk: calculationInput.state.euk,
                                alfa: calculationInput.state.alfa,
                                youngModulus: calculationInput.state.youngModulus,
                                compressionZone: calculationInput.state.compressionZone,
                                compressionZoneValue: calculationInput.state.compressionZoneValue,
                                defaultDelta: calculationInput.state.defaultDelta,
                                hardeningModel: calculationInput.state.hardeningModel,
                                minimumReinforcement: calculationInput.state.minimumReinforcement,
                                minimumReinforcementCrack: calculationInput.state.minimumReinforcementCrack,
                                maxCrack: calculationInput.state.maxCrack,
                                lbdCalculation: calculationInput.state.lbdCalculation,
                                concreteCondition: calculationInput.state.concreteCondition,
                                rebarShape: calculationInput.state.rebarShape
                            }} 
                        />
                    </Col>
                </Row>
            </section>
            <section>
                <Row>
                    <Col xs={12}>
                        <SubmitCalculations {...submitCalculation} />
                    </Col>
                </Row>
            </section>
            <div className={styles.alerts}>
                {Array.isArray(globalAlerts.state.alerts) &&
                    globalAlerts.state.alerts.length > 0 &&
                    globalAlerts.state.alerts.map((ele, index) => {
                        return (
                            <AlertMessage
                                index={index}
                                updateState={globalAlerts.updateState}
                                key={index}
                                message={ele.message}
                                error={ele.error}
                            />
                        );
                    })}
            </div>
            {globalLoading.state.loadCalc && (
                <div className={styles.loadingWrapper}>
                    <Loading text="" />
                </div>
            )}
            {globalLoading.state.calculations && (
                <div className={styles.loadingWrapper}>
                    <Loading text="Wykonuję obliczenia..." />
                </div>
            )}
            {globalLoading.state.fetchProject && (
                <div className={styles.loadingWrapper}>
                    <Loading text="Wczytuję projekt..." />
                </div>
            )}
            {
                globalLoading.state.removeCalculation && 
                <div className={styles.loadingWrapper}>
                    <Loading text="Usuwam obliczenia..."/>
                </div>  
            }
        </Fragment>
    )
}

export default Reinforcement;