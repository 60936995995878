import { Fragment } from "react";
import styles from './index.module.css';
import { roundNumber } from "../../../../../utils";

const SecondOrderResults = ({ result={} }) => {

    const initMed = result.defaultResults.negativeMoment ? -Math.abs(result.defaultResults.initMed) : Math.abs(result.defaultResults.initMed);
    const imperfectionMed = result.defaultResults.negativeMoment ? -Math.abs(result.defaultResults.imperfectionMed) : Math.abs(result.defaultResults.imperfectionMed);
    const secondMed = result.defaultResults.negativeMoment ? -Math.abs(result.secondOrderEffect.med) : Math.abs(result.secondOrderEffect.med);
    const m2 = result.defaultResults.negativeMoment ? -Math.abs(result.secondOrderEffect.m2) : Math.abs(result.secondOrderEffect.m2);

    return ( 
        <div className={styles.wrapper}>
            <h2>Imperfekcje geometryczne i efekty II-go rzędu</h2>

            {
                result.defaultResults.ea &&
                <Fragment>
                    <p>Imperfekcje geometryczne:</p>
                    <p>Zastępczy mimośród [e<sub>i</sub>]: <span className={styles.value}>{roundNumber((imperfectionMed/result.secondOrderEffect.ned)*1000,2)} mm</span></p>
                    <p>Moment zginający wywołany imperfekcją geom. [M<sub>i</sub>]: <span className={styles.value}>{roundNumber(imperfectionMed,2)} kNm</span></p>
                    <p>Całkowity moment zginający z uwzgl. imperfekcji [M<sub>iEd</sub>]: <span className={styles.value}>{roundNumber(imperfectionMed + initMed,2)} kNm</span></p>                    
                </Fragment>
            }
            {
                result.secondOrderEffect.secondOrder &&
                <Fragment>
                    <p>Smukłość słupa:</p>
                    <p>Efektywny wsp. pełzania [&phi;<sub>eff</sub>]: <span className={styles.value}>{roundNumber(result.secondOrderEffect.qeff,2)}</span></p>
                    <p>A: <span className={styles.value}>{roundNumber(result.secondOrderEffect.A,2)}</span></p>
                    <p>Intensywność zbrojenia [&omega;]: <span className={styles.value}>{roundNumber(result.secondOrderEffect.w,2)}</span></p>
                    <p>B: <span className={styles.value}>{roundNumber(result.secondOrderEffect.B,2)}</span></p>
                    <p>C: <span className={styles.value}>{roundNumber(result.secondOrderEffect.C,2)}</span></p>
                    <p>Względna siła normalna [n]: <span className={styles.value}>{roundNumber(result.secondOrderEffect.n,2)}</span></p>

                    <p>Smukłość [&lambda;]: <span className={styles.value}>{roundNumber(result.secondOrderEffect.slenderness,2)}</span></p>
                    <p>Smukłość graniczna [&lambda;<sub>lim</sub>]: 
                        <span className={styles.value}>
                            {roundNumber(result.secondOrderEffect.slendernessLim,2)} <b>{result.secondOrderEffect.slenderness > result.secondOrderEffect.slendernessLim ? ' - Należy uwzględnić efekty II-go rzędu' : ' - Efekty II-go rzędu można pominąć'}</b>
                        </span>
                    </p>
                    {
                        result.secondOrderEffect.slenderness > result.secondOrderEffect.slendernessLim &&
                        <p>Metoda: 
                            <span className={styles.value}>
                            {
                            result.secondOrderEffect.nominalCurvature ? 
                            'Metoda nominalnej krzywizny' : 
                            result.secondOrderEffect.nominalStiffness ? 
                            'Metoda nominalnej sztywności' : 
                            ''
                            }
                            </span>
                        </p>
                    }
                    {
                        result.secondOrderEffect.nominalStiffness &&
                        <Fragment>
                            <p>Krytyczna siła wyboczeniowa [N<sub>b</sub>]: <span className={styles.value}>{roundNumber(result.secondOrderEffect.nb,2)} kN</span></p>
                            <p>Sztywność nominalna [EI]: <span className={styles.value}>{roundNumber(result.secondOrderEffect.EI*0.0001,2)} kNm<sup>2</sup></span></p>
                            <p>Obliczeniowy moment pierwszego rzędu [M<sub>0Ed</sub>]: <span className={styles.value}>{roundNumber(imperfectionMed + initMed,2)} kNm</span></p>
                            <p>Obliczeniowy moment drugiego rzędu [M<sub>Ed</sub>]: <span className={styles.value}>{roundNumber(secondMed,2)} kNm</span></p>
                        </Fragment>
                    }
                    {
                        result.secondOrderEffect.nominalCurvature &&
                        <Fragment>
                            <p>Ugięcie [e<sub>2</sub>]: <span className={styles.value}>{roundNumber(result.secondOrderEffect.e2*100,2)} cm</span></p>
                            <p>Obliczeniowy moment pierwszego rzędu [M<sub>0Ed</sub>]: <span className={styles.value}>{roundNumber(imperfectionMed + initMed,2)} kNm</span></p>
                            <p>Nominalny moment drugiego rzędu [M<sub>2</sub>]: <span className={styles.value}>{roundNumber(m2,2)} kNm</span></p>
                            <p>Obliczeniowy moment drugiego rzędu [M<sub>Ed</sub>]: <span className={styles.value}>{roundNumber(secondMed,2)} kNm</span></p>
                        </Fragment>
                    }
                </Fragment>
            }
        </div>
    )
}

export default SecondOrderResults