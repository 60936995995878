//qsk kN/m2
const defaultRocks = [
    {
        name: 'Łupki',
        state: 'rock',
        qsk: 350
    },
    {
        name: 'Zwietrzlina',
        state: 'rock',
        qsk: 350
    },
    {
        name: 'Skała słabo zwietrzała',
        state: 'rock',
        qsk: 750
    },
    {
        name: 'Skała miękka',
        state: 'rock',
        qsk: 1000
    },
    {
        name: 'Piaskowiec',
        state: 'rock',
        qsk: 1000
    },
    {
        name: 'Wapień',
        state: 'rock',
        qsk: 1000
    },
    {
        name: 'Skała twarda',
        state: 'rock',
        qsk: 1400
    },
    {
        name: 'Granit',
        state: 'rock',
        qsk: 1400
    },
    {
        name: 'Gnejs',
        state: 'rock',
        qsk: 1400
    },
]

export default defaultRocks