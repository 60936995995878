import { useCallback, useState } from 'react';
import { roundNumber } from '../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const resultsProps = [
    'Rbk',
    'Rbk_single',
    'Rcd',
    'Rcd_single',
    'Rsk',
    'Rsk_single',
    'heightLayer',
    'maxVal',
    'minVal',
    'name',
    'qc',
    'qc1',
    'qce',
    'qcm',
    'soilType',
    'yt',
    'z',
];
const calculationsProps = [
    'groundLevel',
    'sheetPileHeadSpot',
    'sheetPileLength',
    'sheetPileList',
    'sheetPileNumbers',
    'technology',
    'yt',
    'zwg',
];

const ResultsHelpers = () => {
    const [resultsState, setResultsState] = useState({});
    const [resultsActive, setResultsActive] = useState(false);

    const [currentPileLength, setCurrentPileLength] = useState(0);
    const [currentCapacity, setCurrentCapacity] = useState({});

    useDeepCompareEffect(() => {
        if(resultsState.results){
            const { results, isResultsActual, calculations={} } = resultsState;
            const isResultsActive = results.map((ele) => {
                return resultsProps.every((val) => ele.hasOwnProperty(val));
            })
            .every((val) => val === true);
            
            const isResultsRestActive = Object.keys(calculations).every(ele => {
                if(calculations.sheetPileList){
                    return ['sheetPileType', ...calculationsProps].includes(ele)
                } else {
                    return ['baseArea', 'perimeter', ...calculationsProps].includes(ele)
                }                
            });
            setResultsActive(isResultsActive && isResultsRestActive && isResultsActual);            
        }
    }, [resultsState]);

    const loadProject = ({
        isResultsActive,
        ...results
    }) => {
        setResultsActive(isResultsActive);
        setResultsState(results);
    };

    const results = {
        state: {
            ...resultsState,
            currentPileLength,
            currentCapacity,
        },
        isResultsActive: resultsActive,
        updateState: setResultsState,
        loadProject: loadProject,
        onChangeCurrentSheetPileLength: useCallback((obj={}) => setCurrentPileLength(roundNumber(obj,2)),[]),
        onChangeCurrentCapacity: useCallback((obj={}) => setCurrentCapacity(obj),[]),
        initState: () => {
            setCurrentCapacity({});
            setCurrentPileLength(0);
            setResultsState({});
            setResultsActive(false);
        },
    };

    return {
        results,
    };
};

export default ResultsHelpers;
