import { useCallback, useMemo, useState } from 'react';
import {
    concreteClasses,
    defaultConcreteClass,
    defaultShape,
    pileShape,
    pilesType,
    steelClasses,
} from '../../../config/ProjectConfig';
import {
    isMinPileSpace,
    isMinPerpendicularPileSpace,
    isNumber,
    makeNumberPositive,
} from '../../../utils';

const initStateObj = {
    pileType: {
        value: '',
        isValid: null,
    },
    pileTechnology: {
        value: '',
        isValid: null,
    },
    pileShape: {
        value: defaultShape.shape,
        isValid: true,
    },
    diameterPile: {
        value: '',
        isValid: null,
    },
    pileLength: {
        value: '',
        isValid: null,
    },
    pileHeadSpot: {
        value: '',
        isValid: null,
    },
    concreteClass: {
        value: defaultConcreteClass.name,
        isValid: true,
    },
    concreteFck: {
        value: defaultConcreteClass.fck,
        isValid: true,
    },
    temporaryContruction: {
        value: false,
        isValid: true,
    },
    thickness: {
        value: '',
        isValid: null,
    },
    trestles: {
        value: false,
        isValid: true,
    },
};

const PilePropertiesHelpers = ({
    perpendicularSpace,
    parallelSpace,
    updateFoundationState,

    updateResultsState,
}) => {
    const [pilePropertiesState, setPilePropertiesState] =
        useState(initStateObj);

    const onBlurPilePropertiesState = (e) => {
        const { name, value } = e.currentTarget;
        const soilHeight = JSON.parse(
            e.currentTarget.getAttribute('data-height'),
        );
        const valid = e.currentTarget.checkValidity();

        if (name === 'diameterPile') {
            const isDiameterValid = isMinPileSpace(
                perpendicularSpace.value,
                value,
            );

            updateFoundationState((state) => ({
                ...state,
                ...(isNumber(perpendicularSpace.value) && {
                    perpendicularSpace: {
                        ...state['perpendicularSpace'],
                        isValid: isDiameterValid,
                        isWarning: isDiameterValid
                            ? !isMinPerpendicularPileSpace(
                                  perpendicularSpace.value,
                                  value,
                              )
                            : false,
                    },
                }),
                ...(isNumber(parallelSpace.value) && {
                    parallelSpace: {
                        ...state['parallelSpace'],
                        isValid: isMinPileSpace(parallelSpace.value, value),
                    },
                }),
            }));
        }
        setPilePropertiesState((state) => {
            return {
                ...state,
                [name]: {
                    ...state[name],
                    isValid: valid,
                },
                ...((name === 'pileLength' || name === 'pileHeadSpot') && {
                    [name]: {
                        ...state[name],
                        isValid:
                            Math.max(...soilHeight) >=
                                state.pileLength.value +
                                    state.pileHeadSpot.value && valid,
                    },
                }),
                ...(name === 'thickness' && {
                    [name]: {
                        ...state[name],
                        isValid:
                            valid &&
                            value * 0.001 <= state.diameterPile.value / 2,
                    },
                }),
            };
        });
    };

    const onChangePilePropertiesState = useCallback((e) => {
        const input =
            e.currentTarget.closest('input') ||
            e.currentTarget.closest('select');

        if (input) {
            const { name, value, type, checked } = input;

            updateResultsState((state) => {
                return {
                    ...state,
                    isResultsActual: false,
                };
            });

            if (type === 'checkbox') {
                setPilePropertiesState((state) => ({
                    ...state,
                    [name]: {
                        ...state[name],
                        value: !!checked,
                    },
                }));
            } else {
                setPilePropertiesState((state) => {
                    return {
                        ...state,
                        ...(name === 'concreteClass' && {
                            concreteFck: {
                                value: concreteClasses.filter(
                                    (ele) => ele.name === value,
                                )[0].fck,
                                isValid: true,
                            },
                        }),
                        ...(name === 'pileType' && {
                            pileTechnology: {
                                value: '',
                                isValid: null,
                            },
                        }),
                        [name]: {
                            value:
                                type === 'number' && value.length > 0
                                    ? makeNumberPositive(Number(value))
                                    : value,
                            isValid: true,
                        },
                    };
                });
            }
        }
    }, []);

    const pileProperties = {
        onChange: onChangePilePropertiesState,
        onBlur: onBlurPilePropertiesState,
        state: pilePropertiesState,
        updateState: setPilePropertiesState,
        initState: () => setPilePropertiesState(initStateObj),
        concreteClasses: useMemo(
            () => concreteClasses.map((ele) => ele.name),
            [],
        ),
        steelClasses: useMemo(() => steelClasses.map((ele) => ele.name), []),
        pileTypes: useMemo(() => Object.keys(pilesType), []),
        pileShape: useMemo(() => Object.keys(pileShape), []),
        pileTechnologies: useMemo(
            () =>
                !!pilePropertiesState.pileType.value
                    ? [
                          ...new Set(
                              Object.keys(
                                  pilesType[pilePropertiesState.pileType.value],
                              ),
                          ),
                      ]
                    : [
                          ...new Set(
                              Object.values(pilesType)
                                  .map((ele) => Object.keys(ele))
                                  .flat(1),
                          ),
                      ],
            [pilePropertiesState.pileType.value],
        ),
    };

    return {
        pileProperties,
    };
};

export default PilePropertiesHelpers;
