import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
 
const reinforcementProps = [
    'calculation',
    'shape',
    'rebar',
    'rebarAs1',
    'rebarAs2',
    'initModelValues'
];

const ResultsHelpers = ({ model, updateResultsState }) => {
    const [resultsState, setResultsState] = useState({});
    const [resultsActive, setResultsActive] = useState(false);

    const [shortResults, setShortResults] = useState({
        short: false
    });

    useDeepCompareEffect(() => {
        const isResultsArr = Object.keys(resultsState).filter(
            (ele) => ele !== 'isResultsActual',
        );

        const isResultsActive =
            isResultsArr.length > 0 &&
            isResultsArr.every((ele) => reinforcementProps.includes(ele));

        setResultsActive(isResultsActive);

        if(resultsState && resultsState.initModelValues){            
            updateResultsState({
                circular: resultsState.initModelValues.circular,
                h: resultsState.initModelValues.h,
                b: resultsState.initModelValues.b,
                coverUp: resultsState.initModelValues.coverUp,
                coverBottom: resultsState.initModelValues.coverBottom,
                spiral: resultsState.initModelValues.spiral,
            }) 
        }
        
    }, [resultsState]);

    const { isResultsActual, ...restState } = resultsState;

    const loadProject = (results = {}) => {

        const { isResultsActive, ...restResults} = results;

        setResultsActive(isResultsActive);
        setResultsState(restResults);
    };

    const onShortResultsChange = (e) => {
        const target = e.currentTarget;

        if(target){
            setShortResults({
                short: target.value === 'Widok skrócony' ? true : false
            })
        }
    }

    const results = {
        state: {
            ...resultsState,
        },
        stateToSave: {
            ...restState,
        },
        shortResults,
        onShortResultsChange,
        isResultsActive: resultsActive,
        updateState: setResultsState,
        loadProject: loadProject,
        initState: () => {
            setResultsState({});
            setResultsActive(false);
        },
    };

    return {
        results,
    };
};

export default ResultsHelpers;
