import { isNumber, roundNumber } from '../../../../../utils';
import styles from './index.module.css';

const MicropileParametersSection = ({ parameters = {} }) => {
    return (
        <div className={styles.wrapper}>
            <h2>Parametry mikropala</h2>
            <p>
                Technologia wykonania:{' '}
                <span className={styles.value}>{`${
                    parameters.micropileType === 1
                        ? 'System samowiercący'
                        : 'Iniekcja zwykła'
                }`}</span>
            </p>
            <p>
                Rzędna głowicy:{' '}
                <span className={styles.value}>
                    {parameters.micropileHeadSpot.toFixed(2)} m.p.p.t.{' '}
                    {isNumber(parameters.groundLevel)
                        ? `(${roundNumber(
                              parameters.groundLevel -
                                  parameters.micropileHeadSpot,
                              2,
                          ).toFixed(2)} m.n.p.m.)`
                        : ''}
                </span>
            </p>
            <p>
                Kąt nachylenia:{' '}
                <span className={styles.value}>
                    {parameters.micropileAngle}&#176;
                </span>
            </p>
            <p>
                Obciążenie osiowe:{' '}
                <span className={styles.value}>{parameters.axisLoad} kN</span>
            </p>
            <p>
                Średnica koronki:{' '}
                <span className={styles.value}>{parameters.diameter} mm</span>
            </p>
            {isNumber(parameters.micropileFreeSpace) && (
                <p>
                    Odległość buławy za klinem odłamu:{' '}
                    <span className={styles.value}>
                        {parameters.micropileFreeSpace.toFixed(2)} m
                    </span>
                </p>
            )}
            {isNumber(parameters.freeLength) && (
                <p>
                    Zadana długość wolna mikropala:{' '}
                    <span className={styles.value}>
                        {parameters.freeLength.toFixed(2)} m
                    </span>
                </p>
            )}

            {parameters.steelYoungModulus && (
                <p>
                    Moduł Younga stali żerdzi:{' '}
                    <span className={styles.value}>
                        {parameters.steelYoungModulus} GPa
                    </span>
                </p>
            )}
            {parameters.concreteYoungModulus && (
                <p>
                    Moduł Younga zaczynu cementowego:{' '}
                    <span className={styles.value}>
                        {parameters.concreteYoungModulus} GPa
                    </span>
                </p>
            )}
            {parameters.steelRodProd && (
                <p>
                    Nazwa żerdzi:{' '}
                    <span className={styles.value}>
                        {parameters.steelRodProd}
                    </span>
                </p>
            )}
            {parameters.steelRodName && (
                <p>
                    Rodzaj żerdzi:{' '}
                    <span className={styles.value}>
                        {parameters.steelRodName}
                    </span>
                </p>
            )}
            {parameters.steelRodSubname && (
                <p>
                    Typ żerdzi:{' '}
                    <span className={styles.value}>
                        {parameters.steelRodSubname}
                    </span>
                </p>
            )}
            {parameters.screwType && (
                <p>
                    Typ koronki:{' '}
                    <span className={styles.value}>
                        {parameters.screwType}
                    </span>
                </p>
            )}
        </div>
    );
};

export default MicropileParametersSection;
