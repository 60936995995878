import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Button } from '../../components';
import styles from './index.module.css';

const SubmitCalculations = ({ onSubmit }) => {
    return (
        <div className={styles.wrapper}>
            <Button
                icon={faCalculator}
                variant="danger"
                type="submit"
                onClick={onSubmit}
                title="Rozpocznij obliczenia"
                altTitle="Rozpocznij obliczenia"
            />
        </div>
    );
};

export default SubmitCalculations;
