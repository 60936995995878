import { Table } from "react-bootstrap"
import styles from './index.module.css';
import round from "../../../../../utils/roundNumber";

const CapacityTable = ({ currentLength, pileHeadSpot, results=[], pileTypeNumber, yt, yst }) => {

    return (
        <div className={styles.wrapper}>
            <div className={styles.margins}>
                <h2>Nośność pionowa pala pojedynczego wg zaleceń EAP</h2>                 
            </div> 
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th>Nr<br></br>[-]</th>
                        <th>z<br></br>[m.p.p.t.]</th>
                        <th>R<sub>b,k</sub><br></br>[kN]</th>
                        <th>R<sub>s,k</sub>=&Sigma;R<sub>s,k,i</sub><br></br>[kN]</th>
                        <th>T<sub>n,k</sub>=&Sigma;T<sub>n,i</sub><br></br>[kN]</th>
                        <th>&gamma;<sub>b,s,t</sub><br></br>[-]</th> 
                        <th>R<sub>c,d</sub><br></br>[kN]</th>
                        <th>R<sub>c,d,min</sub><br></br>[kN]</th> 
                        <th>R<sub>c,d,max</sub><br></br>[kN]</th> 
                        <th>&gamma;<sub>s,t</sub><br></br>[-]</th> 
                        <th>R<sub>t,d</sub><br></br>[kN]</th>
                        <th>R<sub>t,d,min</sub><br></br>[kN]</th>
                        <th>R<sub>t,d,max</sub><br></br>[kN]</th>
                    </tr>
                </thead>
                <tbody>
                    {results.map((element, index) => {
                        const bored_screwPile = [4,5].includes(pileTypeNumber);
                        return (
                            <tr key={index} style={{ backgroundColor: round(pileHeadSpot + currentLength,2) === round(element.z,2) ? '#ddd' : 'transparent' }}>
                                <td>{index + 1}</td>
                                <td>{round(element.z, 2)}</td>
                                <td>{round(bored_screwPile ? element.Rbk_current_01 : element.Rbk_current_01D, 2)}</td>
                                <td>{round(bored_screwPile ? element.Rsk_current_ssg : element.Rsk_current_01D, 2)}</td>
                                <td>{round(bored_screwPile ? element.Tsk_current_ssg : element.Tsk_current_01D, 2)}</td>
                                <td>{round(yt, 2)}</td>
                                <td>{round(element.Rcd_current, 2)}</td>
                                <td>{round(element.Rcd_lower, 2)}</td>
                                <td>{round(element.Rcd_upper, 2)}</td>
                                <td>{round(yst, 2)}</td>
                                <td>{round(element.Rtd_current, 2)}</td>
                                <td>{round(element.Rtd_lower, 2)}</td>
                                <td>{round(element.Rtd_upper, 2)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <p className={styles.underline}>Oznaczenia:</p>
            <p>Z - <span className={styles.value}>rzędna zagłębienia pala poniżej poziomu terenu</span></p>
            <p>R<sub>b,k</sub> - <span className={styles.value}>nośność podstawy na rzędnej Z</span></p>
            <p>R<sub>s,k</sub>=&Sigma;R<sub>s,k,i</sub> - <span className={styles.value}>nośność pobocznicy na rzędnej Z</span></p>
            <p>T<sub>n,k</sub>=&Sigma;T<sub>n,i</sub> - <span className={styles.value}>tarcie negatywne na rzędnej Z</span></p>
            <p>&gamma;<sub>b,s,t</sub> - <span className={styles.value}>współczynnik bezpieczeństwa dla nośności pobocznicy, podstawy lub całkowitej</span></p>
            <p>R<sub>c,d</sub> - <span className={styles.value}>nośność obliczeniowa na wciskanie na rzędnej Z</span></p>
            <p>R<sub>c,d,min</sub> - <span className={styles.value}>minimalna nośność obliczeniowa na wciskanie na rzędnej Z</span></p>
            <p>R<sub>c,d,max</sub> - <span className={styles.value}>maksymalna nośność obliczeniowa na wciskanie na rzędnej Z</span></p>
            <p>&gamma;<sub>s,t</sub> - <span className={styles.value}>współczynnik bezpieczeństwa dla nośności całkowitej na wyciąganie</span></p>
            <p>R<sub>t,d</sub> - <span className={styles.value}>nośność obliczeniowa na wyciąganie na rzędnej Z</span></p>
            <p>R<sub>t,d,min</sub> - <span className={styles.value}>minimalna nośność obliczeniowa na wyciąganie na rzędnej Z</span></p>
            <p>R<sub>t,d,max</sub> - <span className={styles.value}>maksymalna nośność obliczeniowa na wyciąganie na rzędnej Z</span></p>
        </div>
    )
}

export default CapacityTable