const exposureClasses = [
    {
        name: 'X0',
        env: 'Dla betonów niezbrojonych wszystkie klasy oprócz XF, XA',
        description:'Elementy betonowe wewnątrz budynków o małej wilgotności powietrza',
        title: 'Brak zagrożenia korozją lub agresją środowiska',
        cem: '',
        wc:'',
        class: 'C12/15',
        c: '10'
    },

    {
        name: 'XA1',
        env: 'Słaba agresja chemiczna',
        description:'Beton narażony na kontakt z gruntem i wodą gruntową',
        title: 'Agresja chemiczna',
        cem: '300',
        wc:'0.55',
        class: 'C30/37',
        c: ''
    },
    {
        name: 'XA2',
        env: 'Umiarkowana agresja chemiczna',
        description:'Beton narażony na kontakt z gruntem i wodą gruntową',
        title: 'Agresja chemiczna',
        cem: '320',
        wc:'0.50',
        class: 'C30/37',
        c: ''
    },
    {
        name: 'XA3',
        env: 'Silna agresja chemiczna',
        description:'Beton narażony na kontakt z gruntem i wodą gruntową',
        title: 'Agresja chemiczna',
        cem: '360',
        wc:'0.45',
        class: 'C35/45',
        c: ''
    },

    {
        name: 'XC1',
        env: 'Suche lub stale mokre',
        description:'Elementy betonowe wewnątrz budynków o normalnej wilgotności powietrza',
        title: 'Korozja wywołana karbonatyzacją',
        cem: '260',
        wc:'0.65',
        class: 'C20/25',
        c: '15'
    },
    {
        name: 'XC2',
        env: 'Mokre, sporadycznie suche',
        description:'Części konstrukcji hydrotechnicznych, większość fundamentów',
        title: 'Korozja wywołana karbonatyzacją',
        cem: '280',
        wc: '0.60',
        class: 'C25/30',
        c: '20'
    },
    {
        name: 'XC3',
        env: 'Umiarkowanie wilgotne',
        description:'Elementy betonowe wewnątrz budynków o podwyższonej wilgotności powietrza, Zewnętrzne elementy betonowe osłonięte przed deszczem',
        title: 'Korozja wywołana karbonatyzacją',
        cem: '280',
        wc:'0.55',
        class: 'C30/37',
        c: '20'
    },
    {
        name: 'XC4',
        env: 'Cyklicznie: suche-mokre',
        description:'Elementy narażone na kontakt z wodą, spoza klasy ekspozycji XC2',
        title: 'Korozja wywołana karbonatyzacją',
        cem: '300',
        wc: '0.50',
        class: 'C30/37',
        c: '25'
    },

    {
        name: 'XD1',
        env: 'Umiarkowanie wilgotne',
        description:'Elementy betonowe narażone na działanie chlorków z powietrza',
        title: 'Korozja wywołana chlorkami niepochodzącymi z wody morskiej',
        cem: '300',
        wc: '0.55',
        class: 'C30/37',
        c: '40'
    },
    {
        name: 'XD2',
        env: 'Mokre sporadycznie suche',
        description:'Betonowe elementy basenów kąpielowych, betonowe elementy zbiorników przemysłowych gromadzących roztwory chlorków',
        title: 'Korozja wywołana chlorkami niepochodzącymi z wody morskiej',
        cem: '300',
        wc: '0.55',
        class: 'C30/37',
        c: '40'
    },
    {
        name: 'XD3',
        env: 'Cyklicznie: suche-mokre',
        description:'Części mostów, nawierzchnie betonowe dróg i parkingów',
        title: 'Korozja wywołana chlorkami niepochodzącymi z wody morskiej',
        cem: '320',
        wc: '0.45',
        class: 'C35/45',
        c: '40'
    },

    {
        name: 'XS1',
        env: 'Działanie soli zawartych w powietrzu',
        description:'Elementy zlokalizowane w pobliżu wybrzeża',
        title: 'Korozja wywołana chlorkami pochodzącymi z wody morskiej',
        cem: '300',
        wc: '0.50',
        class: 'C30/37',
        c: '40'
    },
    {
        name: 'XS2',
        env: 'Stałe zanurzenie',
        description:'Zatopione części konstrukcji morskich',
        title: 'Korozja wywołana chlorkami pochodzącymi z wody morskiej',
        cem: '320',
        wc: '0.45',
        class: 'C35/45',
        c: '40'
    },
    {
        name: 'XS3',
        env: 'Strefy pływów, rozbryzgów i aerozoli',
        description:'Strefy rozbryzgu i obmywania konstrukcji morskich (w efekcie falowania morza)',
        title: 'Korozja wywołana chlorkami pochodzącymi z wody morskiej',
        cem: '340',
        wc: '0.45',
        class: 'C35/45',
        c: '40'
    },

    {
        name: 'XF1',
        env: 'Umiarkowane nasycenie wodą',
        description:'Pionowe powierzchnie narażone na deszcz i zamarzanie',
        title: 'Agresja spowodowana zamrażaniem/rozmrażaniem',
        cem: '300',
        wc: '0.55',
        class: 'C30/37',
        c: '40'
    },
    {
        name: 'XF2',
        env: 'Umiarkowane nasycenie wodą ze środkami odladzającymi',
        description:'Pionowe powierzchnie konstrukcji drogowych narażone na zamarzanie i działanie środków odladzających z powietrza',
        title: 'Agresja spowodowana zamrażaniem/rozmrażaniem',
        cem: '300',
        wc: '0.55',
        class: 'C25/30',
        c: '40'
    },
    {
        name: 'XF3',
        env: 'Silne nasycenie wodą bez środków odladzających',
        description:'Poziome powierzchnie narażone na deszcz i zamarzanie',
        title: 'Agresja spowodowana zamrażaniem/rozmrażaniem',
        cem: '320',
        wc: '0.50',
        class: 'C30/37',
        c: '40'
    },
    {
        name: 'XF4',
        env: 'Silne nasycenie wodą ze środkami odladzającymi lub wodą morską',
        description:'Jezdnie dróg i mostów narażone na działanie środków odladzających, powierzchnie betonowe i strefy rozbryzgów narażone na działanie wody i zamarzanie',
        title: 'Agresja spowodowana zamrażaniem/rozmrażaniem',
        cem: '340',
        wc: '0.45',
        class: 'C30/37',
        c: '40'
    },
];

export default exposureClasses