import { useCallback, useState } from "react";
import { isNumber, roundNumber, stringToNumber } from "../../../utils";
import useDeepCompareEffect from "use-deep-compare-effect";

const getMrd = (data, target) => 
    data.reduce((acc, obj) =>
        ((Math.abs(target - obj.x) < Math.abs(target - acc.x)) && obj.y > 0) ? obj : acc
    );
const getMrdNegative = (data, target) => 
    data.reduce((acc, obj) =>
        ((Math.abs(target - obj.x) < Math.abs(target - acc.x)) && obj.y <= 0) ? obj : acc
    );
const getNrd = (data, target) => 
    data.reduce((acc, obj) =>
        Math.abs(target - obj.y) < Math.abs(target - acc.y) ? obj : acc
    );

const CrossSectionCapacity = ({
    calculation=[],
    initValues
}) => {

    const [state, setState] = useState([]);
    const [newPoint, setNewPoint] = useState([{
        x: '',
        y: '',
        index: 0
    }]) 

    useDeepCompareEffect(() => {
        if(Array.isArray(calculation) && calculation.length > 0){
            const calculationResults = calculation.map((element) => {
                if(!element.incorrectDimensions){
                    const removedDuplicates = element.crossSectionCapacity
                        .filter((value, index, self) => index === self.findIndex((t) => (
                            t.nrd === value.nrd && t.mrd === value.mrd
                        )))
                        .reduce((acc, ele, idx, arr) => {
                            if(idx === arr.length -1){
                                return [
                                    ...acc,
                                    {
                                        x: element.defaultResults.negativeMoment ? -ele.mrd : ele.mrd,
                                        y: ele.nrd
                                    },
                                    {
                                        x: element.defaultResults.negativeMoment ? -arr[0].mrd : arr[0].mrd,
                                        y: arr[0].nrd
                                    }
                                ]
                            } else {
                                return [
                                    ...acc,
                                    {
                                        x: element.defaultResults.negativeMoment ? -ele.mrd : ele.mrd,
                                        y: ele.nrd
                                    }
                                ]                
                            }

                        },[])

                    const removedDuplicatesNegative = element.crossSectionCapacityNegative
                        .filter((value, index, self) => index === self.findIndex((t) => (
                            t.nrd === value.nrd && t.mrd === value.mrd
                        )))
                        .reduce((acc, ele, idx, arr) => {
                            if(idx === arr.length -1){
                                return [
                                    ...acc,
                                    {
                                        x: element.defaultResults.negativeMoment ? ele.mrd : -ele.mrd,
                                        y: ele.nrd
                                    },
                                    {
                                        x: element.defaultResults.negativeMoment ? arr[0].mrd : -arr[0].mrd,
                                        y: arr[0].nrd
                                    }
                                ]
                            } else {
                                return [
                                    ...acc,
                                    {
                                        x: element.defaultResults.negativeMoment ? ele.mrd : -ele.mrd,
                                        y: ele.nrd
                                    }
                                ]                
                            }

                        },[]);
                        
                    const dataToChart = [...removedDuplicates, ...removedDuplicatesNegative];
                    const maxMrd = dataToChart.reduce((prev, current) => (prev.x > current.x) ? prev : current);
                    const minMrd = dataToChart.reduce((prev, current) => (prev.x < current.x) ? prev : current);
                    const maxNrd = dataToChart.reduce((prev, current) => (prev.y > current.y) ? prev : current);
                    const minNrd = dataToChart.reduce((prev, current) => (prev.y < current.y) ? prev : current);

                    const pureBending = getNrd(removedDuplicates, 0);
                    const pureBendingNegative = getNrd(removedDuplicatesNegative,0);
                    const pureAxial = getMrd(removedDuplicates, 0);
                    const pureAxialNegative = getMrdNegative(removedDuplicatesNegative, 0);

                    const points = [
                        ...[
                            {
                                x: maxMrd.x,
                                y: maxMrd.y,
                                label: {
                                    borderWidth: 0,
                                    offsetX: -5,
                                    offsetY: 5,
                                    text: `Mmax=${roundNumber(maxMrd.x,1)}, N=${roundNumber(maxMrd.y,1)}`,
                                    style: {
                                        cssClass: 'apex-chart-color',
                                        fontSize: '11px'
                                    }
                                }
                            },
                            {
                                x: maxNrd.x,
                                y: maxNrd.y,
                                label: {
                                    borderWidth: 0,
                                    offsetY: 4,
                                    text: `M=${roundNumber(maxNrd.x,1)}, Nmax=${roundNumber(maxNrd.y,1)}`,
                                    style: {
                                        cssClass: 'apex-chart-color',
                                        fontSize: '11px'
                                    }
                                }
                            },
                            {
                                x: minNrd.x,
                                y: minNrd.y,
                                label: {
                                    borderWidth: 0,
                                    offsetY: 35,
                                    text: `M=${roundNumber(minNrd.x,1)}, Nmin=${roundNumber(minNrd.y,1)}`,
                                    style: {
                                        cssClass: 'apex-chart-color',
                                        fontSize: '11px'
                                    }
                                }
                            },
                            {
                                x: pureBending.x,
                                y: pureBending.y,
                                label: {
                                    borderWidth: 0,
                                    offsetX: 0,
                                    offsetY: 0,
                                    text: `M=${roundNumber(pureBending.x,1)}, N=${roundNumber(pureBending.y,1)}`,
                                    style: {
                                        cssClass: 'apex-chart-color',
                                        fontSize: '11px'
                                    }
                                }
                            },
                            {
                                x: pureBendingNegative.x,
                                y: pureBendingNegative.y,
                                label: {
                                    borderWidth: 0,
                                    offsetX: 0,
                                    offsetY: 35,
                                    text: `M=${roundNumber(pureBendingNegative.x,1)}, N=${roundNumber(pureBendingNegative.y,1)}`,
                                    style: {
                                        cssClass: 'apex-chart-color',
                                        fontSize: '11px'
                                    }
                                }
                            },
                            {
                                x: minMrd.x,
                                y: minMrd.y,
                                label: {
                                    borderWidth: 0,
                                    offsetY: 5,
                                    text: `Mmin=${roundNumber(minMrd.x,1)}, N=${roundNumber(minMrd.y,1)}`,
                                    style: {
                                        cssClass: 'apex-chart-color',
                                        fontSize: '11px'
                                    }
                                }
                            },
                            {
                                x: pureAxial.x,
                                y: pureAxial.y,
                                label: {
                                    borderWidth: 0,
                                    offsetY: 35,
                                    text: `M=${roundNumber(pureAxial.x,1)}, N=${roundNumber(pureAxial.y,1)}`,
                                    style: {
                                        cssClass: 'apex-chart-color',
                                        fontSize: '11px'
                                    }
                                }
                            },
                            {
                                x: pureAxialNegative.x,
                                y: pureAxialNegative.y,
                                label: {
                                    borderWidth: 0,
                                    text: `M=${roundNumber(pureAxialNegative.x,1)}, N=${roundNumber(pureAxialNegative.y,1)}`,
                                    style: {
                                        cssClass: 'apex-chart-color',
                                        fontSize: '11px'
                                    }
                                }
                            },  
                        ]
                        .filter((value, index, self) => {
                            return index === self.findIndex((t) => (roundNumber(t.x,2) === roundNumber(value.x,2) && roundNumber(t.y,2) === roundNumber(value.y,2)))
                        })
                        .filter(ele => {
                            return (roundNumber(ele.x,2) !== roundNumber(element.defaultResults.med,2) || roundNumber(ele.y,2) !== roundNumber(element.defaultResults.ned,2))
                        }),
                        {
                            x: element.defaultResults.med,
                            y: element.defaultResults.ned,
                            marker: {
                                size: 5,
                                strokeColor: 'red'
                            },
                            label: {
                                borderColor: 'red',
                                borderWidth: 1,
                                offsetY: 37,
                                offsetX: 0,
                                text: `M=${roundNumber(element.defaultResults.med,1)}, N=${roundNumber(element.defaultResults.ned,1)}`,
                                style: {
                                    cssClass: 'apex-chart-color',
                                    fontSize: '11px',
                                    padding: {
                                        left: 5,
                                        right: 5,
                                        top: 2,
                                        bottom: 2,
                                    }
                                }
                            }
                        }
                    ];

                    return {
                        maxMrd,
                        minMrd,
                        points,
                        collectionPositive: removedDuplicates,
                        collectionNegative: removedDuplicatesNegative,
                        pileShape: initValues.circular ? 1 : 2,
                        index: element.defaultResults.index,
                        negativeMoment: element.defaultResults.negativeMoment,
                        as: element.result.as,
                        as1: element.result.as1,
                        as2: element.result.as2,
                    }
                }
            });
            setNewPoint(calculation.map((ele) => {
                if(!ele.incorrectDimensions){
                    return {
                        x: '',
                        y: '',
                        index: ele.defaultResults.index,
                    }
                } else {
                    return ele
                }
            }));

            setState(calculationResults);
        }
    }, [calculation]);

    const onChange = useCallback((e, index) => {
        const input = e.currentTarget.closest('input');
        const { name, value } = input;

        if(input && (isNumber(stringToNumber(value)) || value === '')){
            setNewPoint(state => state.map(ele => {
                if(ele.index === index){
                    return {
                        ...ele,
                        [name]: stringToNumber(value)
                    }
                } else {
                    return ele
                }
            }))
        }
    },[]);
    const removePoint = (i, collIdx) => setState(state => {
        return state.map((ele,idx) => {
            if(collIdx === idx){
                return {
                    ...ele,
                    points: ele.points.filter((_, index) => index !== i)
                }
            } else {
                return ele
            }
        })
    });
    const addPoint = (index) => {
        const newPointVal = newPoint.find(ele => ele.index === index) || {};
        if(isNumber(newPointVal.x) && isNumber(newPointVal.y)){
            setState(state => {
                return state.map(ele => {
                    if(ele.index === index){
                        return {
                            ...ele,
                            points: [
                                ...ele.points,
                                {
                                    x: newPointVal.x,
                                    y: newPointVal.y,
                                    marker: {
                                        size: 5,
                                        strokeColor: 'red'
                                    },
                                    label: {
                                        borderColor: 'red',
                                        borderWidth: 1,
                                        offsetY: 0,
                                        offsetX: 0,
                                        text: `M=${newPointVal.x}, N=${newPointVal.y}`,
                                        style: {
                                            fontSize: '12px',
                                            padding: {
                                                left: 7,
                                                right: 7,
                                                top: 3,
                                                bottom: 3,
                                            }
                                        }
                                    }
                                } 
                            ],
                        }
                    } else {
                        return {
                            ...ele
                        }
                    }
                })
            });
            setNewPoint(state => state.map(ele => {
                if(ele.index === index){
                    return {
                        ...ele,
                        x: '',
                        y: ''
                    }
                } else {
                    return ele
                }
            }))
        }
    }

    const loadProject = (sectionCapacity={}) => {

        setState(() => {
            return sectionCapacity
        });
    }

    return {
        sectionCapacity: {
            state,
            newPoint,
            loadProject: loadProject,
            onChange,
            onRemovePoint: removePoint,
            onAddPoint: addPoint            
        }
    }
}

export default CrossSectionCapacity