import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { faBan, faDesktop, faEdit, faPlus, faSave, faCheck, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import DefaultSoil from './DefaultSoil';
import {
    SectionTitle,
    Table,
    Button,
    SelectField,
    TableInput,
    InputFile,
    Modal,
    InputField,
    RadioInput1,
    ItemsPerPage,
    Pagination,
} from '../../../../components';
import { SaveSoils, EditSoils } from '../../../ProjectActions';
import styles from './index.module.css';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWindowSize } from '../../../../hooks';
import { isNumber } from '../../../../utils';
import { withPagination } from '../../../../hoc';
import useDeepCompareEffect from 'use-deep-compare-effect';

const SoilTable = ({
    cohesiveSoilType,
    dataToPaginate,
    pagination,
    paginationHelpers,

    defaultSoilLayer = {}, 
    saveSoilProfile = {},
    editSoilProfile = {},
    globalLoading = {},

    addRow,
    addRowStart,
    removeRow,
    onChange,
    onBlur,
    onKeyDown,
    onChangeCptChart,
    onChangeScale,
    onClearFile,
    onAxisCoordinate,
    onChangeChartDataValue,
    
    onClosePoints,
    onContinuePoints,

    setChartData,
    setImgDimensions,

    cptChart='',
    scale,
    chartData,
    imgDimensions,
    closePoints
}) => {
    const [openLayerModal, setOpenLayerModal] = useState(false);
    const windowSize = useWindowSize();
    const fileRef = useRef({});
    const imgRef = useRef({});
    const [open, setOpen] = useState({});
    const [isMaxSize, setIsMaxSize] = useState(false);
    const [openImg, setOpenImg] = useState(false);
    const [index, setIndex] = useState(null);
    const openDefaultSoilModal = (i) => {
        setIndex(i);
        setOpenLayerModal(true);
    };

    useEffect(() => {
        if(fileRef.current){
            fileRef.current.value = '';
        }
    },[cptChart]);

    useDeepCompareEffect(() => {
        if(imgRef.current){
            const originWidth = imgRef.current.clientWidth/imgDimensions.width;
            const originHeight = imgRef.current.clientHeight/imgDimensions.height;

            if(isNumber(originHeight) && isNumber(originWidth) && originHeight > 0 && originWidth > 0){
                setChartData(state => {
                    return {
                        ...state,
                        ...(isNumber(state.origin[0]) && isNumber(state.origin[1])) && {
                            origin: [
                                state.origin[0]*originWidth,
                                state.origin[1]*originHeight
                            ]
                        },
                        ...isNumber(state.scaleX[0]) && {
                            scaleX: [
                                state.scaleX[0]*originWidth,
                                state.scaleX[1]
                            ]
                        },
                        ...isNumber(state.scaleY[0]) && {
                            scaleY: [
                                state.scaleY[0]*originHeight,
                                state.scaleY[1]
                            ]
                        },
                        points: state.points.map(ele => {
                            if(isNumber(ele[0]) && isNumber(ele[1])){
                                return [ele[0]*originWidth, ele[1]*originHeight]
                            } else {
                                return ele
                            }
                        }),
                        layers: state.layers.map(ele => {
                            if(isNumber(ele[0])){
                                return [ele[0]*originHeight, ele[1], ele[2]]
                            } else {
                                return ele
                            }
                        })
                    }
                });
                setImgDimensions({
                    width: imgRef.current.clientWidth,
                    height: imgRef.current.clientHeight
                });
            }
        }
    },[windowSize.width, windowSize.height, isMaxSize, chartData, imgDimensions, imgRef]);

    const onRemoveModel = () => {
        setChartData(() => {
            return {
                origin: [],
                scaleX: [],
                scaleY: [],
                points: [],
                layers: []
            }
        });
        setImgDimensions({
            width: 0,
            height: 0
        })
    };
    const hideDefaultSoilModal = () => setOpenLayerModal(false);
    const onOpen = (e) => {
        const target = e.currentTarget;
        if (target) {
            if (target.name === 'editProfile') {
                editSoilProfile.onLoadProjects();
            }
            setOpen((state) => ({ ...state, [target.name]: true }));
        }
    };
    const onHide = () => setOpen({});
    const onHideImg = () => {
        setIsMaxSize(false);
        setOpenImg(false);
    };
    const changeSoilType = (type, index) => {
        setChartData(state => {
            return {
                ...state,
                layers: state.layers.map((ele,idx) => {
                    if(idx === index){
                        if(type === 1){
                            return [
                                ele[0],
                                type,
                                ele[2] || Object.values(cohesiveSoilType)[0]
                            ]
                        } else {
                            return [
                                ele[0],
                                type
                            ]                            
                        }
                    } else {
                        return ele
                    }
                })
            }
        })
    };
    const changeCohesiveSoilType = (e, type ,index) => {
        if(e.currentTarget){
            const { value } = e.currentTarget;
            setChartData(state => {
                return {
                    ...state,
                    layers: state.layers.map((ele,idx) => {
                        if(idx === index){
                            return [
                                ele[0],
                                type,
                                value || ele[2]
                            ]
                        } else {
                            return ele
                        }
                    })
                }
            })            
        }

    }
    const onRemoveLayer = (index) => {
        setChartData(state => {
            return {
                ...state,
                layers: state.layers.filter((_,idx) => {
                    return idx !== index
                })
            }
        })
    };
    const onRemovePoint = (e, index) => {
        e.preventDefault();
        if(e.type === 'contextmenu'){
            setChartData(state => {
                return {
                    ...state,
                    points: state.points.filter((_,idx) => {
                        return idx !== index
                    })
                }
            })            
        }
    };

    return (
        <Fragment>
            <Col xs={12}>
                <SectionTitle classNameWrapper={styles.titleWrapperSoilTable} title="Parametry podłoża gruntowego"/>
                <ItemsPerPage
                    onDisplayItemsNumber={paginationHelpers.displayItemsNumber}
                    itemsPerPage={pagination.itemsPerPage}
                    totalItems={paginationHelpers.allItemsLength}
                    display={[15, 30, paginationHelpers.allItemsLength]}
                />
                <Table>
                    <thead className={styles.thead}> 
                        <tr>
                            <th>
                                L.p.<br></br>[-]
                            </th>
                            <th title="Numer/nazwa warstwy">
                                Nazwa warstwy<br></br>[-]
                            </th>
                            <th title="Zagłębienie warstwy">
                                Z<br></br>[m.p.p.t]
                            </th>
                            <th title="Ciężar objętościowy gruntu">
                                &#947; <br></br>[kN/m<sup>3</sup>]
                            </th>
                            <th title="Opór na stożku">
                                q<sub>c</sub> <br></br>[MPa]
                            </th>
                            <th title="Wytrzymałość gruntu na ścinanie bez odpływu">
                                c<sub>u</sub> <br></br>[kPa]
                            </th>
                            <th width={130}>
                                Rodzaj gruntu <br></br>[-]
                            </th>
                            <th>Usuń</th>
                            <th>Dodaj</th>
                        </tr>
                    </thead>
                    <tbody className={styles.tbody}>
                        {dataToPaginate.map((element, index) => {
                            return ( 
                                <tr key={index} className={element.layerIndex.value % 2 === 0 ? 'shadowTr' : '' }>
                                    <td>{paginationHelpers.countingItemsPerPage + index + 1}</td>
                                    <td>
                                        <TableInput
                                            name="name"
                                            placeholder="Nazwa warstwy"
                                            type="string"
                                            align="center"
                                            data-row={paginationHelpers.countingItemsPerPage + index}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            onKeyDown={onKeyDown}
                                            value={element.name.value}
                                            isValid={element.name.isValid}
                                            required
                                        />
                                    </td>
                                    <td>
                                        <TableInput
                                            name="z"
                                            type="number"
                                            min="0"
                                            align="center"
                                            data-row={paginationHelpers.countingItemsPerPage + index}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            onKeyDown={onKeyDown}
                                            value={element.z.value}
                                            isValid={element.z.isValid}
                                            required
                                        />
                                    </td>
                                    <td>
                                        <TableInput
                                            name="y"
                                            type="number"
                                            min="0"
                                            align="center"
                                            data-row={paginationHelpers.countingItemsPerPage + index}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            onKeyDown={onKeyDown}
                                            value={element.y.value}
                                            isValid={element.y.isValid}
                                            required
                                        />
                                    </td>
                                    <td>
                                        <TableInput
                                            name="qc"
                                            type="number"
                                            min="0"
                                            align="center"
                                            data-row={paginationHelpers.countingItemsPerPage + index}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            onKeyDown={onKeyDown}
                                            value={element.qc.value}
                                            isValid={element.qc.isValid}
                                            required
                                        />
                                    </td>
                                    <td>
                                        <TableInput
                                            name="cu"
                                            type="number"
                                            min="0"
                                            align="center"
                                            data-row={paginationHelpers.countingItemsPerPage + index}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            onKeyDown={onKeyDown}
                                            value={element.cu.value}
                                            isValid={element.cu.isValid}
                                            required
                                        />
                                    </td>
                                    <td>
                                        <SelectField 
                                            name="soilType"
                                            title=""
                                            data-row={paginationHelpers.countingItemsPerPage + index}
                                            onChange={onChange}
                                            value={element.soilType.value === 0 ? 'Niespoisty' : element.soilType.value === 1 ? 'Spoisty' : element.soilType.value}
                                            defaultOption='Typ gruntu'

                                            options={['Niespoisty','Spoisty']}
                                            required
                                        />
                                    </td>
                                    <td>
                                        <Button
                                            variant="danger"
                                            icon={faTrashAlt}
                                            altTitle={'Usuń'}
                                            onClick={() => removeRow(paginationHelpers.countingItemsPerPage + index)}
                                        />
                                    </td>
                                    <td>
                                        <Button
                                            variant="info"
                                            icon={faPlus}
                                            altTitle={'Dodaj'}
                                            onClick={() =>
                                                openDefaultSoilModal(index)
                                            }
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                {
                    paginationHelpers.allItemsLength > pagination.itemsPerPage &&
                    <Pagination
                        currentPage={pagination.currentPage}
                        itemsPerPage={pagination.itemsPerPage}
                        totalItems={paginationHelpers.allItemsLength}
                        nextPage={paginationHelpers.nextPage}
                    />                    
                }
                <div className={styles.actionsWrapper}>
                    <div className={styles.actionsChart}>
                        <div>
                            <Button
                                css="default-button-style"
                                onClick={addRowStart}
                                title={'Dodaj warstwę na początku'}
                                icon={faPlus}
                            />    
                            <Button
                                css="default-button-style"
                                onClick={addRow}
                                title={'Dodaj warstwę na końcu'}
                                icon={faPlus}
                            />                            
                        </div>

                        <Button
                            css="default-button-style"
                            onClick={() => setOpenImg(true)}
                            title={'Odczytaj dane z wykresu'}
                            icon={faDesktop}
                        />
                    </div>
                    <div className={styles.actions}>
                        <Button
                            icon={faEdit}
                            variant="info"
                            name="editProfile"
                            title="Wczytaj profil"
                            onClick={onOpen}
                        />
                        <Button
                            icon={faSave}
                            variant="danger"
                            name="saveProfile"
                            title="Zapisz profil"
                            onClick={onOpen}
                        />
                    </div>
                </div>
                <EditSoils
                    open={open.editProfile}
                    onHide={onHide}
                    onRemoveProfile={editSoilProfile.onRemoveProfile}
                    onLoadProfile={editSoilProfile.onLoadProfile}
                    onChange={editSoilProfile.onChange}
                    state={editSoilProfile.state}
                    loading={globalLoading.fetchAllProfiles}
                />

                <SaveSoils
                    open={open.saveProfile}
                    openOverwriteProfile={saveSoilProfile.state.profileExists}
                    onChange={saveSoilProfile.onChange}
                    onBlur={saveSoilProfile.onBlur}
                    onSubmit={saveSoilProfile.onSubmit}
                    onHideExistProfileModal={saveSoilProfile.onHideExistProfileModal}
                    onOverwrite={saveSoilProfile.onOverwrite}
                    state={saveSoilProfile.state}
                    saveLoading={globalLoading.saveSoilProfile}
                    overwriteLoading={globalLoading.overwriteSoilProfile}
                    onHide={onHide}
                />

                <DefaultSoil
                    {...defaultSoilLayer}
                    open={openLayerModal}
                    onHide={hideDefaultSoilModal}
                    rowIndex={index}
                />
            </Col>

            <Modal 
                open={openImg} 
                onHide={onHideImg} 
                hideButton={true}
                fullScreen={true} 
                fullScreenFunc={setIsMaxSize}
                isFull={isMaxSize}
                cssDialog={styles.dialog} 
                title="Odczyt danych z wykresu sondowania CPT"
            >
                <Row className={styles.row}>
                    <Col xs={6}>
                        <InputField 
                            name="scale"
                            type="number"
                            title={"Skala wykresu [%]"}
                            placeholder="Skala wykresu"
                            value={scale}
                            onChange={onChangeScale}
                        />
                    </Col>
                    <Col xs={6} className={styles.flexAlign}>
                        <InputFile
                            innerRef={fileRef}
                            onChange={onChangeCptChart}
                            wrapperCss={styles.inputFileWrapper}
                            name="chartCpt"
                            type="file"
                            label={'Dodaj plik'}
                        />
                        <Button
                            icon={faTrashAlt}
                            variant="danger"
                            name="clearInput"
                            title="Usuń"
                            onClick={onClearFile}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={7}>
                        <div className={styles.overflowAuto}>
                            {
                                cptChart 
                                ?
                                <div className={`${styles.relative} chartView`} onClick={onAxisCoordinate} ref={imgRef}>
                                    <img 
                                        alt="Wykres sondowania CPT" 
                                        className={styles.cptChart} 
                                        src={cptChart}
                                        style={{
                                            cursor: 'crosshair',
                                            transform: `scale(${scale*0.01})`
                                        }}
                                    />
                                    {
                                        Array.isArray(chartData.origin) && chartData.origin.length === 2 &&
                                        <Fragment>
                                            <div 
                                                className={styles.horizontal}
                                                style={{
                                                    width: `calc(${scale}% - ${(chartData.origin[0] + 15)}px)`,
                                                    left: chartData.origin[0],
                                                    top: chartData.origin[1]
                                                }}    
                                            >
                                                <span className={styles.caret}></span>
                                            </div>
                                            {
                                                Array.isArray(chartData.scaleX) && isNumber(chartData.scaleX[0]) &&
                                                <div 
                                                    className={styles.horizontalScale}
                                                    style={{
                                                        width: `${chartData.scaleX[0] - chartData.origin[0]}px`,
                                                        left: chartData.origin[0],
                                                        top: chartData.origin[1]
                                                    }}
                                                >
                                                    <span 
                                                        className={styles.line}
                                                        style={{
                                                            left: chartData.scaleX[0] - chartData.origin[0] - 1,
                                                        }}
                                                    ></span>
                                                </div>                                                    
                                            }
                                            <div 
                                                className={styles.vertical}
                                                style={{
                                                    height: `calc(${scale}% - ${(chartData.origin[1] + 15)}px)`,
                                                    left: chartData.origin[0],
                                                    top: chartData.origin[1]
                                                }}    
                                            > 
                                                <span className={styles.verticalCaret}></span>
                                            </div>
                                            {
                                                Array.isArray(chartData.scaleY) && isNumber(chartData.scaleY[0]) &&
                                                <div 
                                                    className={styles.verticalScale}
                                                    style={{
                                                        height: `${chartData.scaleY[0] - chartData.origin[1]}px`,
                                                        left: chartData.origin[0],
                                                        top: chartData.origin[1]
                                                    }}
                                                >
                                                    <span 
                                                        className={styles.lineVertical}
                                                        style={{
                                                            top: chartData.scaleY[0] - chartData.origin[1] - 1,
                                                        }}
                                                    ></span>
                                                </div>                                                    
                                            }
                                            {
                                                Array.isArray(chartData.points) && chartData.points.length > 0 &&
                                                <Fragment>
                                                    {
                                                        chartData.points.map((ele,idx) => {
                                                            return (
                                                                <div 
                                                                    title="Usuń punkt prawym przyciskiem myszy"
                                                                    onClick={(e) => onRemovePoint(e,idx)}
                                                                    onContextMenu={(e) => onRemovePoint(e,idx)}
                                                                    key={idx}
                                                                    className={styles.point}
                                                                    style={{
                                                                        left: ele[0] - 4,
                                                                        top: ele[1] - 4
                                                                    }}
                                                                >        
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Fragment>
                                            }
                                            {
                                                Array.isArray(chartData.layers) && chartData.layers.length > 0 &&
                                                <Fragment>
                                                    {
                                                        chartData.layers.map((ele,idx) => {
                                                            return (
                                                                <div
                                                                    key={idx}
                                                                    className={styles.layer}
                                                                    style={{
                                                                        width: `calc(${scale}% `,
                                                                        left: 0,
                                                                        top: ele[0]
                                                                    }}
                                                                >
                                                                    <p 
                                                                        className={styles.soilType}
                                                                        style={{
                                                                            background: ele[1] === 0 ? 'bisque' : 'coral'
                                                                        }}
                                                                    >
                                                                        {idx+1}. {ele[1] === 0 ? 'Niespoisty' : ele[1] === 1 ? 'Spoisty' : ''}
                                                                    </p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Fragment>
                                            }
                                        </Fragment>
                                    }
                                </div>
                                :
                                <div className={styles.appendix}>
                                    <FontAwesomeIcon icon={faBan} />
                                    <p>Nie załączono pliku</p>
                                </div>
                            }

                        </div>
                    </Col>
                    <Col xs={12} sm={5}>
                        {
                            cptChart &&
                            <div className={styles.stepByStep}>
                                <div className={styles.titleWrapper}>
                                    <h1>Krok po kroku: </h1>
                                    <div className={styles.new} onClick={onRemoveModel} title="Rozpocznij od nowa">
                                        <FontAwesomeIcon icon={faTrashAlt}/>
                                    </div>
                                </div>
                                <ol>
                                    <li>
                                        <p>Kliknij w początek układu współrzędnych (0,0) 
                                            {   
                                                Array.isArray(chartData.origin) && chartData.origin.length === 2 &&
                                                <FontAwesomeIcon icon={faCheck} />
                                            }
                                        </p>
                                    </li>
                                    <li>
                                        <p>Kliknij dowolną wartość na osi X (qc) i wpisz ją poniżej:
                                            {   
                                                Array.isArray(chartData.scaleX) && chartData.scaleX.length === 2 && chartData.scaleX.every(ele => isNumber(ele)) &&
                                                <FontAwesomeIcon icon={faCheck} />
                                            }
                                        </p>
                                        <InputField 
                                            placeholder="Wpisz wartość qc [MPa]" 
                                            name='scaleX'
                                            type='number'
                                            value={chartData.scaleX[1]}
                                            onChange={onChangeChartDataValue}
                                        />
                                    </li>
                                    <li>
                                        <p>Kliknij dowolną wartość na osi Y (Głębokość) i wpisz ją poniżej:
                                            {
                                                Array.isArray(chartData.scaleY) && chartData.scaleY.length === 2 && chartData.scaleY.every(ele => isNumber(ele)) &&
                                                <FontAwesomeIcon icon={faCheck} />
                                            }
                                        </p>
                                        <InputField 
                                            placeholder="Wpisz głębokość Z [m]" 
                                            name='scaleY'
                                            type='number'
                                            value={chartData.scaleY[1]}    
                                            onChange={onChangeChartDataValue}
                                        />
                                    </li>
                                    <li>
                                        <p>Oznacz punkty na wykresie sondowania:
                                        {
                                            closePoints && Array.isArray(chartData.points) && chartData.points.length > 0 &&
                                            <FontAwesomeIcon icon={faCheck} />
                                        }
                                        </p>
                                        <br></br>
                                        <Fragment>
                                            {
                                                Array.isArray(chartData.scaleY) && chartData.scaleY.length === 2 && chartData.scaleY.every(ele => isNumber(ele)) &&
                                                Array.isArray(chartData.scaleX) && chartData.scaleX.length === 2 && chartData.scaleX.every(ele => isNumber(ele)) &&
                                                Array.isArray(chartData.origin) && chartData.origin.length === 2 &&
                                                
                                                <div>
                                                {
                                                    !closePoints &&
                                                    <Button
                                                        icon={faLock}
                                                        variant="danger"
                                                        name="closePoints"
                                                        title="Zakończ odczyt"
                                                        onClick={onClosePoints}
                                                    />
                                                }
                                                {
                                                    closePoints &&
                                                    <Button
                                                        icon={faLockOpen}
                                                        variant="info"
                                                        name="continuePoints"
                                                        title="Kontynuuj odczyt"
                                                        onClick={onContinuePoints}
                                                    />
                                                }
                                                </div>
                                            }
                                        </Fragment>
                                    </li>
                                    <li>
                                        <p>Oznacz warstwy na profilu gruntowym (kliknij w spąg danej warstwy)</p>
                                    </li>
                                </ol> 
                                {
                                    closePoints && Array.isArray(chartData.points) && chartData.points.length > 0 &&
                                    <Fragment>
                                        <p className={styles.lpTitle}>Rodzaj gruntu danej warstwy:</p>
                                        {
                                            Array.isArray(chartData.layers) && chartData.layers.length > 0 &&
                                            <Fragment>
                                                { 
                                                    chartData.layers.map((ele,idx) => {
                                                        return (
                                                            <div className={styles.editLayerType} key={idx}>
                                                                <p className={styles.lp}>{idx + 1}.</p>
                                                                <div className={styles.new1} onClick={() => onRemoveLayer(idx)} title="Usuń warstwę">
                                                                    <FontAwesomeIcon icon={faTrashAlt}/>
                                                                </div>
                                                                <RadioInput1
                                                                    name={`${idx}_noncohesive`}
                                                                    label={'Niespoisty'}
                                                                    onChange={() => changeSoilType(0,idx)}
                                                                    value={ele[1] === 0}
                                                                    cssWrapper={styles.radioWrapper}
                                                                    cssText={styles.radioText}
                                                                />
                                                                <RadioInput1
                                                                    name={`${idx}_cohesive`}
                                                                    label={'Spoisty'}
                                                                    onChange={() => changeSoilType(1,idx)}
                                                                    value={ele[1] === 1}
                                                                    cssWrapper={styles.radioWrapper1}
                                                                    cssText={styles.radioText}
                                                                />
                                                                {
                                                                    ele[1] === 1 &&
                                                                    <SelectField
                                                                        name={`${idx}_cohesiveSoilType`}
                                                                        title=""
                                                                        onChange={(e) => changeCohesiveSoilType(e, ele[1], idx)}
                                                                        value={ele[2]}
                                                                        defaultValue={Object.values(cohesiveSoilType)[0]}
                                                                        
                                                                        options={Object.values(cohesiveSoilType)}
                                                                    />
                                                                }

                                                            </div>
                                                        )
                                                    })
                                                }                                                
                                            </Fragment>
                                        }
                                    </Fragment>
                                }
                            </div>                            
                        }
                    </Col>
                </Row>
            </Modal>
        </Fragment>
    );
};

export default withPagination({
    component: SoilTable,
    returnAllCollection: true,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 15,
    },
})
