import { isNumber } from '../../../utils';
import { soilType } from '../SoilType';

const interpolationNoncohesive = (id,[d1,d2,d3,d4], embankment=false) => {
    if(embankment){
        return 0
    } else {
        if(id >= 0.67 && id <= 1){
            return d2+((d1-d2)/0.33)*(id-0.67)
        } else if (id < 0.67 && id >= 0.33){
            return d3+((d2-d3)/0.34)*(id-0.33)
        } else if(id < 0.33 && id >= 0.2) {
            return d4+((d3-d4)/0.13)*(id-0.2)
        } else {
            return 0 
        }
    }
}
const interpolationCohesive = (il,[l1,l2,l3,l4], embankment=false) => {
    if(embankment){
        return 0
    } else {
        if(il < 0){
            return l1;
        } else if (il < 0.5 && il >= 0){
            return l3+((l2-l3)/0.5)*(0.5-il);
        } else if(il >= 0.5 && il <= 0.75) {
            return l4+((l3-l4)/0.25)*(0.75-il)
        } else {
            return 0
        }        
    }
}

const defaultPNBSoils = [
    {
        name: 'Żwir',
        state: 'noncohesive',
        signature: 0,
        type: soilType[0].pl,
        qc: ({id, embankment}) => isNumber(id) ? interpolationNoncohesive(id, [7750,5100,3000,1950], embankment) : '',
        t: ({id, embankment}) => isNumber(id) ? interpolationNoncohesive(id, [165,110,74,59], embankment) : '',
    },
    {
        name: 'Żwir gliniasty',
        state: 'cohesive',
        weights: [22.5, 22, 21, 20.5],
        type: soilType[1].pl,
        qc: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [4150,2750,1650,850], embankment) : '',
        t: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [134,95,67,44], embankment) : ''
    },
    {
        name: 'Pospółka',
        state: 'noncohesive',
        signature: 0,
        type: soilType[0].pl,
        qc: ({id, embankment}) => isNumber(id) ? interpolationNoncohesive(id, [7750,5100,3000,1950], embankment) : '',
        t: ({id, embankment}) => isNumber(id) ? interpolationNoncohesive(id, [165,110,74,59], embankment) : ''
    },
    {
        name: 'Pospółka gliniasta',
        state: 'cohesive',
        weights: [22.5, 22, 21, 20.5],
        type: soilType[1].pl,
        qc: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [4150,2750,1650,850], embankment) : '',
        t: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [134,95,67,44], embankment) : ''
    },
    {
        name: 'Piasek gruby',
        state: 'noncohesive',
        signature: 1,
        type: soilType[0].pl,
        qc: ({id, embankment}) => isNumber(id) ? interpolationNoncohesive(id, [5850,3600,2150,1450], embankment) : '',
        t: ({id, embankment}) => isNumber(id) ? interpolationNoncohesive(id, [132,74,47,34], embankment) : ''
    },
    {
        name: 'Piasek średni',
        state: 'noncohesive',
        signature: 1,
        type: soilType[0].pl,
        qc: ({id, embankment}) => isNumber(id) ? interpolationNoncohesive(id, [5850,3600,2150,1450], embankment) : '',
        t: ({id, embankment}) => isNumber(id) ? interpolationNoncohesive(id, [132,74,47,34], embankment) : ''
    },
    {
        name: 'Piasek drobny',
        state: 'noncohesive',
        signature: 2,
        type: soilType[0].pl,
        qc: ({id, embankment}) => isNumber(id) ? interpolationNoncohesive(id, [4100,2700,1650,1050], embankment) : '',
        t: ({id, embankment}) => isNumber(id) ? interpolationNoncohesive(id, [100,62,31,22], embankment) : ''
    },
    {
        name: 'Piasek pylasty',
        state: 'noncohesive',
        signature: 2,
        type: soilType[0].pl,
        qc: ({id, embankment}) => isNumber(id) ? interpolationNoncohesive(id, [3350,2100,1150,700], embankment) : '',
        t: ({id, embankment}) => isNumber(id) ? interpolationNoncohesive(id, [75,45,25,16], embankment) : ''
    },
    {
        name: 'Piasek gliniasty',
        state: 'cohesive',
        weights: [22, 21.5, 21, 20.5],
        type: soilType[1].pl,
        qc: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [2750,1950,850,450], embankment) : '',
        t: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [95,50,31,14], embankment) : ''
    },
    {
        name: 'Pył piaszczysty',
        state: 'cohesive',
        weights: [21.5, 21, 20.5, 20],
        type: soilType[1].pl,
        qc: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [1850,1250,500,250], embankment) : '',
        t: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [65,30,16,7], embankment) : ''
    },
    {
        name: 'Pył',
        state: 'cohesive',
        weights: [21, 20.5, 20, 19.5],
        type: soilType[1].pl,
        qc: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [1850,1250,500,250], embankment) : '',
        t: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [65,30,16,7], embankment) : ''
    },
    {
        name: 'Glina piaszczysta',
        state: 'cohesive',
        weights: [22.5, 22, 21, 20],
        type: soilType[2].pl,
        qc: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [2800,1950,800,400], embankment) : '',
        t: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [95,50,31,14], embankment) : ''
    },
    {
        name: 'Glina',
        state: 'cohesive',
        weights: [22, 21.5, 20.5, 19.5],
        type: soilType[2].pl,
        qc: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [2750,1950,850,450], embankment) : '',
        t: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [95,50,31,14], embankment) : ''
    },
    {
        name: 'Glina pylasta',
        state: 'cohesive',
        weights: [21.5, 21, 20, 19],
        type: soilType[2].pl,
        qc: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [2750,1950,850,450], embankment) : '',
        t: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [95,50,31,14], embankment) : ''
    },
    {
        name: 'Glina zwięzła',
        state: 'cohesive',
        weights: [22, 21, 20, 19],
        type: soilType[3].pl,
        qc: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [2800,1950,800,400], embankment) : '',
        t: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [95,50,25,11], embankment) : ''
    },
    {
        name: 'Ił piaszczysty',
        state: 'cohesive',
        weights: [22, 21, 19.5, 18],
        type: soilType[4].pl,
        qc: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [2800,1950,800,400], embankment) : '',
        t: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [95,50,25,11], embankment) : ''
    },
    {
        name: 'Ił',
        state: 'cohesive',
        weights: [21.5, 20, 18.5, 17.5],
        type: soilType[4].pl,
        qc: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [2800,1950,800,400], embankment) : '',
        t: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [95,50,25,11], embankment) : ''
    },
    {
        name: 'Ił pylasty',
        state: 'cohesive',
        weights: [20.5, 19, 18, 17],
        type: soilType[4].pl,
        qc: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [2800,1950,800,400], embankment) : '',
        t: ({il, embankment}) => isNumber(il) ? interpolationCohesive(il, [95,50,25,11], embankment) : ''
    },
    {
        name: 'Grunt organiczny',
        state: 'organic',
        type: soilType[5].pl,
        qc: () => 0,
        t: ({ il, embankment }) => isNumber(il) ? interpolationCohesive(il, [48,18,0,0], embankment) : '',
        weight: 15,
        friction: 3,
        cohesion: 10,
        edometricModules: 1.5
    },
];


export default defaultPNBSoils;