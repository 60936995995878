import { useCallback, useState } from 'react';

const defaultState = {
    projectTitle: '',
};

const ProjectTitleHelpers = () => {
    const [projectTitleState, setProjectTitleState] = useState(defaultState);

    const onChangeProjectTitle = useCallback((e) => {
        const { value, name } = e.currentTarget;

        setProjectTitleState((state) => ({ ...state, [name]: value }));
    }, []);
    const loadProject = (projectTitleObj = {}) => {
        setProjectTitleState(() => {
            return {
                ...defaultState,
                ...projectTitleObj,
            };
        });
    };

    const projectTitle = {
        onChange: onChangeProjectTitle,
        loadProject: loadProject,
        state: projectTitleState,
        updateState: setProjectTitleState,
        initState: () => setProjectTitleState(defaultState),
    };

    return {
        projectTitle,
    };
};

export default ProjectTitleHelpers;
