const atm_sas = [
    {
        prod: 'ATM',
        name: 'SAS 550/620',
        subname: '',
        dout: 12,
        fu: 70,
        rmk: 62,
    },
    { 
        prod: 'ATM',
        name: 'SAS 550/620',
        subname: '',
        dout: 14,
        fu: 95,
        rmk: 85,
    },
    {
        prod: 'ATM',
        name: 'SAS 550/620',
        subname: '',
        dout: 16,
        fu: 125,
        rmk: 110,
    },
    {
        prod: 'ATM',
        name: 'SAS 550/620',
        subname: '',
        dout: 20,
        fu: 195,
        rmk: 175,
    },
    {
        prod: 'ATM',
        name: 'SAS 550/620',
        subname: '',
        dout: 25,
        fu: 305,
        rmk: 270,
    },
    {
        prod: 'ATM',
        name: 'SAS 550/620',
        subname: '',
        dout: 28,
        fu: 380,
        rmk: 340,
    },
    {
        prod: 'ATM',
        name: 'SAS 550/620',
        subname: '',
        dout: 32,
        fu: 500,
        rmk: 440,
    },
    {
        prod: 'ATM',
        name: 'SAS 550/620',
        subname: '',
        dout: 40,
        fu: 780,
        rmk: 690,
    },
    {
        prod: 'ATM',
        name: 'SAS 550/620',
        subname: '',
        dout: 50,
        fu: 1215,
        rmk: 1080,
    },
    {
        prod: 'ATM',
        name: 'SAS 555/700',
        subname: '',
        dout: 57.5,
        fu: 1818,
        rmk: 1441,
    },
    {
        prod: 'ATM',
        name: 'SAS 555/700',
        subname: '',
        dout: 63.5,
        fu: 2215,
        rmk: 1760,
    },
    {
        prod: 'ATM',
        name: 'SAS 500/550',
        subname: '',
        dout: 75,
        fu: 2430,
        rmk: 2209,
    },
    {
        prod: 'ATM',
        name: 'SAS 450/700',
        subname: '',
        dout: 16,
        fu: 145,
        rmk: 93,
    },
    {
        prod: 'ATM',
        name: 'SAS 450/700',
        subname: '',
        dout: 25,
        fu: 345,
        rmk: 220,
    },
    {
        prod: 'ATM',
        name: 'SAS 650/800',
        subname: '',
        dout: 22,
        fu: 304,
        rmk: 247,
    },
    {
        prod: 'ATM',
        name: 'SAS 650/800',
        subname: '',
        dout: 25,
        fu: 393,
        rmk: 319,
    },
    {
        prod: 'ATM',
        name: 'SAS 650/800',
        subname: '',
        dout: 28,
        fu: 493,
        rmk: 400,
    },
    {
        prod: 'ATM',
        name: 'SAS 650/800',
        subname: '',
        dout: 30,
        fu: 565,
        rmk: 460,
    },
    {
        prod: 'ATM',
        name: 'SAS 670/800',
        subname: '',
        dout: 18,
        fu: 204,
        rmk: 170,
    },
    {
        prod: 'ATM',
        name: 'SAS 670/800',
        subname: '',
        dout: 22,
        fu: 304,
        rmk: 255,
    },
    {
        prod: 'ATM',
        name: 'SAS 670/800',
        subname: '',
        dout: 25,
        fu: 393,
        rmk: 329,
    },
    {
        prod: 'ATM',
        name: 'SAS 670/800',
        subname: '',
        dout: 28,
        fu: 493,
        rmk: 413,
    },
    {
        prod: 'ATM',
        name: 'SAS 670/800',
        dout: 30,
        fu: 565,
        rmk: 474,
    },
    {
        prod: 'ATM',
        name: 'SAS 670/800',
        subname: '',
        dout: 35,
        fu: 770,
        rmk: 645,
    },
    {
        prod: 'ATM',
        name: 'SAS 670/800',
        subname: '',
        dout: 43,
        fu: 1162,
        rmk: 973,
    },
    {
        prod: 'ATM',
        name: 'SAS 670/800',
        subname: '',
        dout: 50,
        fu: 1570,
        rmk: 1315,
    },
    {
        prod: 'ATM',
        name: 'SAS 670/800',
        subname: '',
        dout: 57.5,
        fu: 2077,
        rmk: 1740,
    },
    {
        prod: 'ATM',
        name: 'SAS 670/800',
        subname: '',
        dout: 63.5,
        fu: 2534,
        rmk: 2122,
    },
    {
        prod: 'ATM',
        name: 'SAS 670/800',
        subname: '',
        dout: 75,
        fu: 3535,
        rmk: 2960,
    },
    {
        prod: 'ATM',
        name: 'SAS 950/1050',
        subname: '',
        dout: 18,
        fu: 255,
        rmk: 230,
    },
    {
        prod: 'ATM',
        name: 'SAS 950/1050',
        subname: '',
        dout: 26.5,
        fu: 580,
        rmk: 525,
    },
    {
        prod: 'ATM',
        name: 'SAS 950/1050',
        subname: '',
        dout: 32,
        fu: 845,
        rmk: 760,
    },
    {
        prod: 'ATM',
        name: 'SAS 950/1050',
        subname: '',
        dout: 36,
        fu: 1070,
        rmk: 960,
    },
    {
        prod: 'ATM',
        name: 'SAS 950/1050',
        subname: '',
        dout: 40,
        fu: 1320,
        rmk: 1190,
    },
    {
        prod: 'ATM',
        name: 'SAS 950/1050',
        subname: '',
        dout: 47,
        fu: 1820,
        rmk: 1650,
    },
    {
        prod: 'ATM',
        name: 'SAS 835/1035',
        subname: '',
        dout: 57,
        fu: 2671,
        rmk: 2155,
    },
    {
        prod: 'ATM',
        name: 'SAS 835/1035',
        subname: '',
        dout: 65,
        fu: 3447,
        rmk: 2780,
    },
    {
        prod: 'ATM',
        name: 'SAS 835/1035',
        subname: '',        
        dout: 75,
        a: 4418,
        fu: 4572,
        fud: 4572/1.15,
        fupl: 3690,
        rmk: 3690,
        rmd: 3690/1.15,
        m: 35.9
    },
].map(ele =>({ ...ele, din: 0 }))

export default atm_sas