import { useCallback, useState, useMemo } from 'react';
import { isPlasticityCorrect, isDensityCorrect, isSheetPileBaseSpotCorrect, micropileSoils } from '../../../config/ProjectConfig';
import { isNumber, makeNumberPositive, stringToNumber } from '../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const defaultRow = {
    absHeight: {
        value: '',
    },
    name: {
        value: '',
        isValid: null 
    },
    height: { 
        value: '',
        isValid: null
    },
    qccpt: {
        value: '',
        isValid: null
    },
    cucpt: {
        value: '',
        isValid: null
    },
    density: {
        value: '',
        isValid: null
    },
    plasticity: {
        value: '',
        isValid: null
    },
    friction: {
        value: '',
        isValid: null
    },    
    soilName: {
        value: '',
        isValid: null
    },
}


const SoilTableHelpers = ({ updateProfileProperties, updateResultsState }) => {
    const [soilTableState, setSoilTableState] = useState([{...defaultRow}]);

    const soilTableHeights = soilTableState.map(({ height }) => ({ height }));
    const soilTableNames = soilTableState.map(({ soilName }) => ({ soilName }));

    useDeepCompareEffect(() => {
        setSoilTableState(state => state.map((element, index, arr) => {
            if(index === 0){
                return {
                    ...element,
                    absHeight: {
                        value: isNumber(element.height.value) ? Number(element.height.value) : ''
                    }
                }
            } else {
                return {
                    ...element,
                    absHeight: {
                        value: (isNumber(element.height.value) && isNumber(arr[index-1].height.value)) ? element.height.value - arr[index-1].height.value > 0 ? Number((element.height.value - arr[index-1].height.value).toFixed(2)) : '' : '',
                    }
                }
            }
        }));
    },[soilTableHeights]);

    useDeepCompareEffect(() => {
        setSoilTableState(state => state.map((element, _, arr) => {
            const soil = micropileSoils.find(ele => ele.name === element.soilName.value);
            const soilState = soil ? soil.state : '';
            const isDensityValid = isNumber(element.density.value) && isDensityCorrect(element.density.value);
            const isPlasticityValid = isNumber(element.plasticity.value) && isPlasticityCorrect(element.plasticity.value);

            return {
                ...element,

                ...(isDensityValid && (soilState ==='cohesive' || soilState === 'organic')) && {
                    soilName: {
                        value: '',
                        isValid: null
                    },
                },
                ...(isPlasticityValid && soilState ==='noncohesive') && {
                    soilName: {
                        value: '',
                        isValid: null
                    },
                },
            }

        }))
    },[
        soilTableNames
    ])

    const onBlurSoilTableState = (e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if(input){
            const { name, value } = input;
            const rowNumber = Number(input.getAttribute('data-row'));
            const valid = input.checkValidity();

            if(name === 'height'){
                updateProfileProperties(state => {
                    const maxLayerHeight = Math.max(...soilTableState.map(ele => ele.height.value));
                    return {
                        ...state,
                        sheetPileSpot: {
                            ...state['sheetPileSpot'],
                            ...isNumber(state.sheetPileSpot.value) && {
                                isValid: isSheetPileBaseSpotCorrect(state.sheetPileSpot.value, maxLayerHeight)
                            }
                        },
                    }
                });
                setSoilTableState(state => state.map((element, index, arr) => {
                    return {
                        ...element,
                        height: {
                            ...element['height'],
                            isValid: valid && arr.slice(0,index).every(ele => ele.height.value < element.height.value) && element.height.value > 0
                        }
                    }
                }));
            } else {
                setSoilTableState(state => state.map((element,index, arr) => {
                    const soil = micropileSoils.find(ele => ele.name === element.soilName.value);
                    const soilState = soil ? soil.state : '';

                    if(rowNumber !== index){ 
                        return element
                    } else {
                        return {
                            ...element,
                            [name]: {
                                ...element[name],
                                isValid: valid
                            },
                            ...(name === 'density') && {
                                density: {
                                    ...element['density'],
                                    isValid: element.plasticity.isValid || soilState === 'rock' ? null : valid
                                },
                                soilName: {
                                    value: isDensityCorrect(stringToNumber(value)) ? ['cohesive','organic','rock'].includes(soilState) ? '' : element.soilName.value : element.soilName.value,
                                    isValid: isDensityCorrect(stringToNumber(value)) ? ['cohesive','organic','rock'].includes(soilState) ? null : element.soilName.isValid : element.soilName.isValid,
                                },
                            },
                            ...(name === 'plasticity') && {
                                plasticity: {
                                    ...element['plasticity'],
                                    isValid: element.density.isValid || soilState === 'rock' ? null : valid
                                },
                                soilName: {
                                    value: isPlasticityCorrect(stringToNumber(value)) ? soilState === 'noncohesive' || soilState === 'rock' ? '' : element.soilName.value : element.soilName.value,
                                    isValid: isPlasticityCorrect(stringToNumber(value)) ?  soilState === 'noncohesive' || soilState === 'rock' ? null : element.soilName.isValid : element.soilName.isValid,
                                },
                            },
                        }
                    }
                }));
            }
        }
    };

    const onChangeSoilState = useCallback((e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if(input){
            updateResultsState((state) => {
                return {
                    ...state,
                    isResultsActual: false,
                };
            });

            const rowNumber = stringToNumber(input.getAttribute('data-row'));

            const { name, value, type } = input;
      
            setSoilTableState(state => state.map((element,index) => {
                const soil = micropileSoils.find(ele => ele.name === value);
                const soilState = soil ? soil.state : '';

                if(rowNumber !== index){
                    return element
                } else {
                    
                    return {
                        ...element,
                        ...(name === 'density') && {
                            plasticity: {
                                value: '',
                                isValid: null
                            },
                        },
                        ...(name === 'plasticity') && {
                            density: {
                                value: '',
                                isValid: null
                            }
                        },
                        ...(name === 'soilName' && soilState === 'rock') && {
                            density: {
                                value: '',
                                isValid: null
                            }, 
                            plasticity: {
                                value: '',
                                isValid: null
                            },
                        },
                        
                        ...(name === 'soilName' && (soilState === 'cohesive' || soilState === 'organic')) && {
                            density: {
                                value: '',
                                isValid: null
                            },
                            plasticity: {
                                value: isNumber(element.density.value) ? element.density.value : element.plasticity.value,
                                isValid: isNumber(element.density.value) ? isPlasticityCorrect(element.density.value) : element.plasticity.isValid
                            } 
                        },
                        ...(name === 'soilName' && soilState === 'noncohesive') && {
                            plasticity: {
                                value: '',
                                isValid: null
                            },
                            density: {
                                value: isNumber(element.plasticity.value) ? element.plasticity.value : element.density.value,
                                isValid: isNumber(element.plasticity.value) ? isDensityCorrect(element.plasticity.value) : element.density.isValid
                            }
                        },
                        [name]: {
                            value: (type === 'number' && value.length > 0) ? name === 'plasticity' ? Number(value) : makeNumberPositive(Number(value))  : value,
                            isValid: true
                        }
                    }
                }
            }))
        }
    },[])
    
    const addRowToSoilTable = () => {
        updateResultsState((state) => {
            return {
                ...state,
                isResultsActual: false,
            };
        });
        setSoilTableState(state => {
            return [
                ...state, 
                { 
                    ...defaultRow,
                }
            ]
        })        
    }
    
    const removeRowFromSoilTable = (i) => {
        updateResultsState((state) => {
            return {
                ...state,
                isResultsActual: false,
            };
        });
        setSoilTableState(state => {
            return [
                ...state.filter((_, index) => index !== i).map((ele,idx) => ({
                    ...ele,
                }))
            ]
        });        
    }
    
    const onLoadSoilProfile = (soilProfile=[]) => {
        const loadedProfile = soilProfile.reduce((acc, ele, idx) => {
            const entries = Object.fromEntries(Object.entries(ele).filter(ele => Object.keys(defaultRow).includes(ele[0])));

            return [
                ...acc,
                {
                    ...defaultRow,
                    ...entries,
                }
            ]
        },[]);
        updateResultsState((state) => {
            return {
                ...state,
                isResultsActual: false,
            };
        });
        setSoilTableState(loadedProfile) 
    }
    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    },[]);

    const stateToSave = soilTableState.map(ele => {
        const { absHeight, ...rest } = ele;

        return rest
    });
    const soilTable = {
        onChange: onChangeSoilState,
        onBlur: onBlurSoilTableState,
        onKeyDown: onKeyDown,
        addRow: addRowToSoilTable,
        removeRow: removeRowFromSoilTable,
        state: soilTableState,
        stateToSave: stateToSave,
        defaultSoils: useMemo(() => [...micropileSoils.map(ele => ele.name)],[]),
        updateState: setSoilTableState,
        onLoad: onLoadSoilProfile,
        initState: () => setSoilTableState([{...defaultRow}])
    }

    return {
        soilTable
    }
}

export default SoilTableHelpers;