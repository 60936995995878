import { useState } from 'react';

const capacityChartTypes = [
    {
        type: 0,
        name: 'Długość pala',
    },
    {
        type: 1,
        name: 'Obliczeniowa nośność pala wg PN',
    },
    {
        type: 2,
        name: 'Obliczeniowa nośność pala wg EC7',
    },
    {
        type: 3,
        name: 'Nośność graniczna pala wg EC7',
    },
    {
        type: 4,
        name: 'Obliczeniowa nośność pala na wyciąganie wg PN',
    },
];
const VerticalCapacityService = () => {
    const [settings, setSettings] = useState({
        showVertical: true,
        showHorizontal: true,
        showLegend: true,
        showValues: false,
        showDescription: true,
        showValue: true,
        rotateDescriptionX: false,
    });
    const [capacityType, setCapacityType] = useState(0);
    const [capacityName, setCapacityName] = useState('Długość pala');
    const [chartNames, setChartNames] = useState({
        0: {
            title: 'Rozkład długości pala wzdłuż profili geologicznych',
            axisY: 'Długość pala [m]',
            axisX: 'Profile geologiczne',
            legend: '- długość pala',
        },
        1: {
            title: 'Rozkład nośności pala na wciskanie wg PN-83/B-02482',
            axisY: 'Nośność pala [kN]',
            axisX: 'Profile geologiczne',
            legend: '- nośność pala',
        },
        2: {
            title: 'Rozkład nośności pala na wciskanie wg Eurokodu 7',
            axisY: 'Nośność pala [kN]',
            axisX: 'Profile geologiczne',
            legend: '- nośność pala',
        },
        3: {
            title: 'Rozkład nośności granicznej pala wg Eurokodu 7',
            axisY: 'Nośność pala [kN]',
            axisX: 'Profile geologiczne',
            legend: '- nośność pala',
        },
        4: {
            title: 'Rozkład nośności pala na wyciąganie wg PN-83/B-02482',
            axisY: 'Nośność pala [kN]',
            axisX: 'Profile geologiczne',
            legend: '- nośność pala',
        },
    });

    const onChangeChartTitles = (e = {}, type) => {
        if (e.target) {
            const { name, value } = e.target;

            setChartNames((state) => ({
                ...state,
                [type]: {
                    ...state[type],
                    [name]: value,
                },
            }));
        }
    };

    const onToggleChartType = (e) => {
        const currentSelectProject = capacityChartTypes.find(
            (ele) => ele.name === e.target.value,
        );

        if (currentSelectProject) {
            setCapacityType(currentSelectProject.type);
            setCapacityName(currentSelectProject.name);
        }
    };

    const onChangeSettings = (e) => {
        e.preventDefault();
        e.persist();

        const name = e.currentTarget.getAttribute('name');

        setSettings((state) => ({ ...state, [name]: !state[name] }));
    };

    const verticalCapacity = {
        state: {
            chartNames,
            capacityType,
            capacityName,
            capacityChartTypes,

            settings,
        },
        onChangeChartTitles,
        onToggleChartType,
        onChangeSettings,
    };

    return {
        verticalCapacity,
    };
};

export default VerticalCapacityService;
