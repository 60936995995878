import { useCallback, useEffect, useState } from 'react';
import { isNumber, roundNumber } from '../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const resultsProps_bored = [
    'Rbk_current_01',
    'Rbk_current_002',
    'Rbk_current_003',
    'Rbk_lower_01',
    'Rbk_lower_002',
    'Rbk_lower_003',
    'Rbk_ssg_current',
    'Rbk_upper_01',
    'Rbk_upper_002',
    'Rbk_upper_003',
    'Rcd_current',
    'Rcd_lower',
    'Rcd_upper',
    'Rck_current_01',
    'Rck_current_002',
    'Rck_current_003',
    'Rck_lower_01',
    'Rck_lower_002',
    'Rck_lower_003',
    'Rck_ssg_current',
    'Rck_upper_01',
    'Rck_upper_002',
    'Rck_upper_003',
    'Rsk_current_ssg',
    'Rsk_lower_ssg',
    'Rsk_upper_ssg',
    'Rtd_current',
    'Rtd_lower',
    'Rtd_upper',
    'Tsk_current_ssg',
    'Tsk_lower_ssg',
    'Tsk_upper_ssg',
    'correctConditions',
    'cu',
    'cuAverage',
    'deq_01',
    'deq_002',
    'deq_003',
    'name',
    'qc',
    'qcAverage',
    'ssg_current',
    'ssg_lower',
    'ssg_upper',
    'y',
    'z',
];
const resultsProps_driven = [
    'Rbk_current_01D',
    'Rbk_current_0035',
    'Rbk_lower_01D',
    'Rbk_ssg_current',
    'Rbk_upper_01D',
    'Rcd_current',
    'Rcd_lower',
    'Rcd_upper',
    'Rck_current_01',
    'Rck_current_0035',
    'Rck_lower_01',
    'Rck_ssg_current',
    'Rck_upper_01',
    'Rsk_current_01D',
    'Rsk_current_0035',
    'Rsk_current_ssg',
    'Rsk_lower_01D',
    'Rsk_upper_01D',
    'Rtd_current',
    'Rtd_lower',
    'Rtd_upper',
    'Tsk_current_01D',
    'Tsk_current_ssg',
    'Tsk_lower_01D',
    'Tsk_upper_01D',
    'correctConditions',
    'cu',
    'cuAverage',
    'deq_01',
    'deq_0035',
    'name',
    'qc',
    'qcAverage',
    'soilType',
    'ssg_current',
    'y',
    'z',
];
const restStateProps = [
    'currentResult',
    'diameterPile',
    'groundLevel',
    'isBaseWidth',
    'negativeFriction',
    'newEmbankment',
    'pileHeadSpot',
    'pileLength',
    'pileType',
    'pileTypeNumber',
    'results',
    'soilProfile',
    'ygq',
    'yst',
    'yt',
    'zwg',
    'softSoilCount',
    'hardSoilCount',
    'qccuInterpolation'
]
const onCalculateSettlement = ({ rcd, type, settlements={} }) => {
    if(type === 4 || type === 5){
        if(settlements.ssg_current > settlements.deq_002){
            if(settlements.Rck_current_002 >= rcd){
                return {
                    s: settlements.deq_002*rcd/settlements.Rck_current_002,
                    f: rcd
                }
            } else if(settlements.Rck_current_002 < rcd && settlements.Rck_ssg_current >= rcd){
                return {
                    s: settlements.deq_002 + (settlements.ssg_current - settlements.deq_002)*(rcd - settlements.Rck_current_002)/(settlements.Rck_ssg_current - settlements.Rck_current_002),
                    f: rcd
                }
            } else if(settlements.Rck_ssg_current < rcd && rcd <= settlements.Rck_current_003){
                return {
                    s: settlements.ssg_current + (settlements.deq_003 - settlements.ssg_current)*(rcd - settlements.Rck_ssg_current)/(settlements.Rck_current_003 - settlements.Rck_ssg_current),
                    f: rcd
                }
            } else if(settlements.Rck_current_003 < rcd && rcd <= settlements.Rck_current_01){
                return {
                    s: settlements.deq_003 + (settlements.deq_01 - settlements.deq_003)*(rcd - settlements.Rck_current_003)/(settlements.Rck_current_01 - settlements.Rck_current_003),
                    f: rcd
                }
            } else {
                return {}
            }
        } else {
            if(settlements.Rck_ssg_current >= rcd){
                return {
                    s: settlements.ssg_current*rcd/settlements.Rck_ssg_current,
                    f: rcd
                }
            } else if(settlements.Rck_ssg_current < rcd && settlements.Rck_current_002 >= rcd){
                return {
                    s: settlements.ssg_current + (settlements.deq_002 - settlements.ssg_current)*(rcd - settlements.Rck_ssg_current)/(settlements.Rck_current_002 - settlements.Rck_ssg_current),
                    f: rcd
                }
            } else if(settlements.Rck_current_002 < rcd && rcd <= settlements.Rck_current_003){
                return {
                    s: settlements.deq_002 + (settlements.deq_003 - settlements.deq_002)*(rcd - settlements.Rck_current_002)/(settlements.Rck_current_003 - settlements.Rck_current_002),
                    f: rcd
                }
            } else if(settlements.Rck_current_003 < rcd && rcd <= settlements.Rck_current_01){
                return {
                    s: settlements.deq_003 + (settlements.deq_01 - settlements.deq_003)*(rcd - settlements.Rck_current_003)/(settlements.Rck_current_01 - settlements.Rck_current_003),
                    f: rcd
                }
            } else {
                return {}
            }
        }
    } else {
        if(settlements.Rck_ssg_current >= rcd){
            return {
                s: settlements.ssg_current*rcd/settlements.Rck_ssg_current,
                f: rcd
            }
        } else if(settlements.Rck_ssg_current < rcd && settlements.Rck_current_0035 >= rcd){
            return {
                s: settlements.ssg_current + (settlements.deq_0035 - settlements.ssg_current)*(rcd - settlements.Rck_ssg_current)/(settlements.Rck_current_0035 - settlements.Rck_ssg_current),
                f: rcd
            }
        } else if(settlements.Rck_current_0035 < rcd && rcd <= settlements.Rck_current_01){
            return {
                s: settlements.deq_0035 + (settlements.deq_01 - settlements.deq_0035)*(rcd - settlements.Rck_current_0035)/(settlements.Rck_current_01 - settlements.Rck_current_0035),
                f: rcd
            }
        } else {
            return {}
        }
    }
};

const ResultsHelpers = () => {
    const [resultsState, setResultsState] = useState({});
    const [resultsActive, setResultsActive] = useState(false);

    const [currentPileLength, setCurrentPileLength] = useState(0);
    const [currentCapacity, setCurrentCapacity] = useState({});
    const [charLoad, setCharLoad] = useState(0);

    useDeepCompareEffect(() => {
        if(resultsState.results){
            const { results, isResultsActual, ...rest } = resultsState;
            const isResultsActive = results.map((ele) => {
                if(rest.pileTypeNumber === 4 || rest.pileTypeNumber === 5){
                    return resultsProps_bored.every((val) => ele.hasOwnProperty(val));
                } else {
                    return resultsProps_driven.every((val) => ele.hasOwnProperty(val));
                }
            })
            .every((val) => val === true);
            
            const isResultsRestActive = Object.keys(rest).every(ele => {
                if(rest.pileTypeNumber === 6 || rest.pileTypeNumber === 7){
                    return [...restStateProps, 'hPile', 'hType'].includes(ele)
                } else if(rest.pileTypeNumber === 4 || rest.pileTypeNumber === 5) {
                    return [...restStateProps, 'baseWidth'].includes(ele)
                } else {
                    return restStateProps.includes(ele)
                }
                
            });
            setResultsActive(isResultsActive && isResultsRestActive && isResultsActual);            
        }
    }, [resultsState]);

    useEffect(() => {
        if(isNumber(currentCapacity.Rcd_current/resultsState.ygq)){
            setCharLoad(roundNumber(currentCapacity.Rcd_current/resultsState.ygq,2));
        }
    },[currentCapacity.Rcd_current, resultsState.ygq])

    const loadProject = ({
        isResultsActive,
        ...results
    }) => {
        setResultsActive(isResultsActive);
        setResultsState(results);
    };

    const results = {
        state: {
            ...resultsState,
            currentPileLength,
            currentCapacity,
            charLoad
        },
        isResultsActive: resultsActive,
        updateState: setResultsState,
        loadProject: loadProject,
        onChangeCurrentPileLength: useCallback((obj) => setCurrentPileLength(roundNumber(obj,2)),[]),
        onChangeCurrentCapacity: useCallback((obj={}) => setCurrentCapacity(obj),[]),
        onCalculateSettlement: onCalculateSettlement,
        onSetCharLoad: (e) => setCharLoad(e),
        initState: () => {
            setCurrentCapacity({});
            setCharLoad(0);
            setCurrentPileLength(0);
            setResultsState({});
            setResultsActive(false);
        },
    };

    return {
        results,
    };
};

export default ResultsHelpers;
