import { useState } from 'react';
import { useIsMountedState } from '../../../hooks';
import { AuthFetch } from '../../../routes';
import useDeepCompareEffect from 'use-deep-compare-effect';
import stringify from 'fast-json-stable-stringify';

const initState = {
    author: {
        value: '',
        isValid: null,
    },
    contact: {
        value: '',
        isValid: null,
    },
};
const CalculationAuthorService = ({
    fetch,
    setAlert,
    setLoading,

    updateReportDetails,
}) => {
    const [reportDetails, setReportDetails] = useState(initState);
    const isMountedState = useIsMountedState();

    useDeepCompareEffect(() => {
        if(isMountedState.current){
            AuthFetch({
                url: fetch.getReportData.url,
                method: fetch.getReportData.method,
            })
                .then((response) => {
                    if(isMountedState.current){
                        if (response.success) {
                            setReportDetails({
                                author: {
                                    value: response.responseData.author,
                                    isValid: true,
                                },
                                contact: {
                                    value: response.responseData.contact,
                                    isValid: true,
                                },
                            });
                        } else {
                            setAlert({
                                error: true,
                                message:
                                    'Błąd podczas wczytywania danych użytkownika do raportów pdf',
                            });
                        }
                    }
                })
                .catch((e) => {
                    if(isMountedState.current){
                        setAlert({
                            error: true,
                            message:
                                'Błąd podczas wczytywania danych użytkownika do raportów pdf',
                        });
                    }
                });            
        }

    }, [fetch.getReportData.url, fetch.getReportData.method, setAlert, isMountedState]);

    const onChange = (e) => {
        const target = e.currentTarget.closest('input');
        const { name, value } = target;
        const valid = target.checkValidity();

        setReportDetails((state) => ({
            ...state,
            [name]: {
                value: value,
                isValid: valid,
            },
        }));
    };

    const onSubmit = () => {
        const isValidForm = Object.values(reportDetails).every(
            (ele) => ele.isValid,
        );

        if (isValidForm) {
            setLoading({ name: 'updateReportDetails', loading: true });
            AuthFetch({
                url: fetch.updateReportData.url,
                method: fetch.updateReportData.method,
                body: {
                    ...Object.entries(reportDetails).reduce(
                        (acc, [key, value]) => ({ ...acc, [key]: value.value }),
                        {},
                    ),
                },
            })
                .then((response) => {
                    setLoading({ name: 'updateReportDetails', loading: false });
                    if (response.success && response.status === 200) {
                        updateReportDetails((state) => ({
                            ...state,
                            ...response.responseData,
                        }));
                        localStorage.setItem(
                            'updateReport',
                            stringify(new Date()),
                        );
                        setAlert({
                            error: false,
                            message: 'Dane do raportów pdf zostały zmienione',
                            hideTime: 2000,
                        });
                    } else {
                        if (response.status === 401) {
                            setAlert({
                                error: true,
                                message: 'Nie znaleziono użytkownika',
                            });
                        } else {
                            setAlert({
                                error: true,
                                message:
                                    'Błąd podczas edycji danych do raportów pdf',
                            });
                        }
                    }
                })
                .catch((error) => {
                    setAlert({
                        error: true,
                        message: 'Błąd podczas edycji danych do raportów pdf',
                    });
                    setLoading({ name: 'updateReportDetails', loading: false });
                });
        } else {
            setAlert({ error: true, message: 'Uzupełnij dane poprawnie' });
            setReportDetails((state) => {
                return {
                    ...Object.entries(state).reduce(
                        (acc, [key, value]) => ({
                            ...acc,
                            [key]: {
                                value: value.value,
                                isValid: !!value.isValid,
                            },
                        }),
                        {},
                    ),
                };
            });
        }
    };
    return {
        calculationAuthor: {
            onChange,
            onSubmit,

            state: {
                reportDetails,
            },
        },
    };
};

export default CalculationAuthorService;
