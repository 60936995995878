import React from 'react';
import { ApplicationRoutes } from './routes';
import './App.css';

const App = () => {
    return (
        <div id="interview-app">
            <ApplicationRoutes />
        </div>
    );
};

export default App;
