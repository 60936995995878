import { useCallback, useState } from 'react';
import { roundNumber } from '../../../utils';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';

const singlePileResultsProps = [
    'd',
    'np',
    'ns',
    'ns1',
    'nt',
    'ntec7',
    'ntec7d',
    'ntec7lt',
    'ntw',
    'nw',
    'nw1',
    'qcd',
    'tcd',
    'tn',
    'tn1',
    'z',
];
const groupPileResultsProps = [
    'm1',
    'm1w',
    'm2',
    'mn',
    'npfz',
    'nsfz',
    'ntfz',
    'ntgr',
    'ntwfz',
    'nwfz',
    'nwgr',
    'tnfz',
];
const ResultsHelpers = () => {
    const [resultsState, setResultsState] = useState({});
    const [resultsActive, setResultsActive] = useState(false);
    const [currentPileLength, setCurrentPileLength] = useState(0);
    const [currentCapacity, setCurrentCapacity] = useState({});

    useDeepCompareEffect(() => {
        const isSinglePileValid =
            Array.isArray(resultsState.results) && 
            resultsState.results
                .map((ele) =>
                    singlePileResultsProps.every((val) =>
                        ele.hasOwnProperty(val),
                    ),
                )
                .every((val) => val === true);
        const isGroupPileValid =
            Array.isArray(resultsState.results) &&
            resultsState.results
                .map((ele) =>
                    [...singlePileResultsProps, ...groupPileResultsProps].every(
                        (val) => ele.hasOwnProperty(val),
                    ),
                )
                .every((val) => val === true);
        const isResultsActive =
            Array.isArray(resultsState.results) &&
            resultsState.results.length > 0 &&
            (resultsState.singlePile
                ? isSinglePileValid
                : isSinglePileValid && isGroupPileValid);

        setResultsActive(isResultsActive);

        const initNt = Array.isArray(resultsState.results)
            ? resultsState.results.find(
                  (ele) =>
                      ele.z ===
                      currentPileLength +
                          resultsState.pileParameters.pileHeadSpot,
              ) || {}
            : {};

        const capacityObject = resultsState.pileParameters
            ? {
                  currentPileLength: currentPileLength,
                  diameterPile: resultsState.pileParameters.diameterPile,
                  pileShape: resultsState.pileParameters.pileShape,
                  pileType: resultsState.pileParameters.pileType,
                  pileTechnology: resultsState.pileParameters.pileTechnology,
                  singlePile: resultsState.pileParameters.singlePile,

                  np: initNt.np,
                  ns: initNt.ns,
                  nt: initNt.nt,
                  ntec7: initNt.ntec7,
                  ntec7d: initNt.ntec7d,
                  ntec7lt: initNt.ntec7lt,
                  ntw: initNt.ntw,

                  ntgr: initNt.ntgr,
                  nwgr: initNt.nwgr,
              }
            : {};

        setCurrentCapacity((state) => {
            const emptyState =
                (Object.keys(state).length === 0 &&
                    state.constructor === Object) ||
                stringify(capacityObject) !== stringify(state);

            const validObj = emptyState ? capacityObject : state;

            return validObj;
        });
    }, [resultsState, currentPileLength]);

    const loadProject = ({
        isResultsActive,
        currentPileLength,
        currentCapacity,
        results = {}
    }) => {
        setResultsActive(isResultsActive);
        setResultsState(results);
        setCurrentPileLength(roundNumber(currentPileLength,2));
        setCurrentCapacity(currentCapacity || {});
    };

    const results = {
        state: {
            ...resultsState,
            currentPileLength,
            currentCapacity,
        },
        isResultsActive: resultsActive,
        updateState: setResultsState,
        loadProject: loadProject,
        onChangeCurrentPileLength: useCallback((obj) => setCurrentPileLength(roundNumber(obj,2)),[]),
        onChangeCurrentCapacity: useCallback((obj) => setCurrentCapacity(obj),[]),
        currentPileLength: currentPileLength,
        initState: () => {
            setResultsState({});
            setResultsActive(false);
        },
    };

    return {
        results,
    };
};

export default ResultsHelpers;
