import { Fragment, useState, forwardRef } from 'react';
import {
    SectionTitle,
    Table,
    Pagination,
    ItemsPerPage,
    Modal,
} from '../../../../components';
import styles from './index.module.css';
import moment from 'moment';
import { withPagination } from '../../../../hoc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faQuestionCircle,
    faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';
import { Col, Row } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useDeepCompareEffect from 'use-deep-compare-effect';
import pl from 'date-fns/locale/pl';
import DownloadButton from '../../../Home/Invoice';
registerLocale('pl', pl);

const PaymentsTable = ({
    dataToPaginate,
    pagination,
    paginationHelpers,
    allCollection = [],
    startDate,
    endDate,
    onChangeDateRange,
    onClearDateInput,
}) => {
    const [transDetails, setTransDetails] = useState([]);
    const [open, setOpen] = useState(false);
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [csvData, setCsvData] = useState([]);

    const onClickTransDetails = ({ open, details = [] }) => {
        setTransDetails(details);
        setOpen(open);
    };
    const onHide = () => setOpen(false);

    useDeepCompareEffect(() => {
        setCsvData(
            allCollection
                .map(
                    (
                        {
                            buyer: { firstName, lastName, email },
                            price,
                            byAdmin,
                            transactionDate,
                            transId, 
                            invoiceId,
                            invoiceNumber
                        },
                        index,
                        arr,
                    ) => ({
                        firstName,
                        lastName,
                        email,
                        byAdmin: byAdmin ? 'Administrator' : 'Użytkownik',
                        amount: price,
                        transactionDate: moment(transactionDate)
                            .local('pl')
                            .format('DD.MM.YYYY, HH:mm'),
                        summary: arr
                            .filter((_, idx) => idx <= index)
                            .reduce((acc, ele) => acc + Number(ele.price), 0),
                        transId: transId ? transId : '',
                        invoiceId: invoiceId ? invoiceId : '',
                        invoiceNumber: invoiceNumber ? invoiceNumber : '',
                    }),
                )
                .map((ele) =>
                    Object.fromEntries(
                        Object.entries(ele).map(([key, value = '']) => [
                            [key],
                            value.toString().replace('.', ','),
                        ]),
                    ),
                ),
        );

        setCsvHeaders([
            {
                label: 'Imię',
                key: 'firstName',
            },
            {
                label: 'Nazwisko',
                key: 'lastName',
            },
            {
                label: 'Adres email',
                key: 'email',
            },
            {
                label: 'Stripe ID transakcji',
                key: 'transId',
            },
            {
                label: 'ID transakcji',
                key: 'invoiceId',
            },
            {
                label: 'Numer faktury',
                key: 'invoiceNumber',
            },
            {
                label: 'Data transakcji',
                key: 'transactionDate',
            },
            {
                label: 'Kwota transakcji',
                key: 'amount',
            },
            {
                label: 'Suma',
                key: 'summary',
            },

            {
                label: 'Wykonawca transakcji',
                key: 'byAdmin',
            },
        ]);
    }, [allCollection]);

    const DatePickerCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div
            className={`${styles.dateButton} ${styles.flex} default-button-info-style`}>
            <div onClick={onClick} ref={ref}>
                {value}
            </div>
            <div
                className={styles.clearInput}
                title="Usuń daty"
                onClick={onClearDateInput}>
                <FontAwesomeIcon icon={faTrashAlt} />
            </div>
        </div>
    ));

    const DatePickerComponent = () => {
        return (
            <DatePicker
                locale={'pl'}
                tabIndex={1}
                selected={endDate}
                onChange={onChangeDateRange}
                customInput={<DatePickerCustomInput />}
                minDate={moment().subtract(10, 'years').toDate()}
                maxDate={moment().toDate()}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                dateFormat="dd.MM.yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
            />
        );
    };

    return (
        <Fragment>
            <div className={styles.flexSpace}>
                <SectionTitle title="Lista płatności" />
                <ItemsPerPage
                    onDisplayItemsNumber={paginationHelpers.displayItemsNumber}
                    itemsPerPage={pagination.itemsPerPage}
                    totalItems={paginationHelpers.allItemsLength}
                    display={[10, 50, 100, paginationHelpers.allItemsLength]}
                />
            </div>
            <div className={styles.datepicker}></div>
            <Table
                csvData={csvData}
                csvFilename={'PaymentsReport'}
                csvHeaders={csvHeaders}
                className={styles.table}
                filterByDate={DatePickerComponent()}>
                <thead>
                    <tr>
                        <th title="Numer wiersza">Nr</th>
                        <th title="Imię">Imię</th>
                        <th title="Nazwisko">Nazwisko</th>
                        <th title="Adres email">Email</th>
                        <th title="Kwota transakcji netto">Kwota netto</th>
                        <th title="VAT">VAT</th>
                        <th title="Kwota transakcji brutto">Kwota brutto</th>
                        <th title="Data transakcji">Data</th>
                        <th title="Operacja wykonana przez">Wykonawca operacji</th>
                        <th title="Numer faktury">Numer faktury</th>
                        <th title="Szczegóły">Szczegóły</th>
                        <th title="Faktura została wystawiona">Faktura</th>
                    </tr>
                </thead>
                {dataToPaginate.length > 0 ? (
                    <tbody>
                        {dataToPaginate.map((element, index, arr) => {
                            return (
                                <Fragment key={index}>
                                    <tr
                                        className={`${
                                            index % 2 !== 0 ? 'shadow-rows' : ''
                                        }`}
                                        key={element.id}>
                                        <td>
                                            {paginationHelpers.countingItemsPerPage + index + 1}.
                                        </td>
                                        <td>{element.buyer.firstName}</td>
                                        <td>{element.buyer.lastName}</td>
                                        <td>{element.buyer.email}</td>
                                        <td>{element.netPrice}</td>
                                        <td>{element.vat}</td>
                                        <td>{element.grossPrice}</td>
                                        <td>
                                            {moment(element.transactionDate)
                                                .local('pl')
                                                .format('DD.MM.YYYY, HH:mm')}
                                        </td>
                                        <td>
                                            {element.byAdmin
                                                ? 'Administrator'
                                                : 'Użytkownik'}
                                        </td>
                                        <td>
                                            {element.invoiceNumber || '-'}
                                        </td>
                                        <td className={styles.pointer}>
                                            <FontAwesomeIcon
                                                onClick={() =>
                                                    onClickTransDetails({
                                                        open: true,
                                                        details: {
                                                            netAmount: element.netPrice,
                                                            grossAmount: element.grossPrice,
                                                            transId: element.transId,
                                                            invoiceId: element.invoiceId,
                                                            invoiceNumber: element.invoiceNumber,
                                                            buyer: element.buyer,
                                                            products: element.products,
                                                        },
                                                    })
                                                }
                                                icon={faQuestionCircle}
                                            />
                                        </td>
                                        <td width={150}>
                                            {element.invoiceNumber ? (
                                                <DownloadButton 
                                                    invoice={element}
                                                />
                                            ) : <p>Brak faktury</p>}
                                        </td>
                                    </tr>
                                </Fragment>
                            );
                        })}
                    </tbody>
                ) : (
                    <tbody>
                        <tr>
                            <td className={styles.tdNoBorder}>
                                <div className={styles.absoluteTr}>
                                    <p className={styles.noUsers}>
                                        Brak użytkowników przypisanych do konta
                                    </p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                )}
            </Table>

            {paginationHelpers.totalPages > 1 && (
                <Pagination
                    css={styles.pagination}
                    currentPage={pagination.currentPage}
                    itemsPerPage={pagination.itemsPerPage}
                    totalItems={paginationHelpers.allItemsLength}
                    nextPage={paginationHelpers.nextPage}
                />
            )}
            <Modal
                css={styles.modalOverflow}
                cssDialog={styles.modal}
                onHide={onHide}
                title="Szczegóły dotyczące transakcji"
                open={open}>
                <Row>
                    <Col xs={12}>
                        <div className={styles.detail}>
                            <p className={styles.upper}>
                                Numer faktury
                            </p>
                            <p>{transDetails.invoiceNumber || 'brak'}</p>
                            <p className={styles.upper}>
                                Identyfikator transakcji Stripe
                            </p>
                            <p>{transDetails.transId || 'brak'}</p>
                            <p className={styles.upper}>
                                Identyfikator transakcji
                            </p>
                            <p>{transDetails.invoiceId || 'brak'}</p>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className={styles.detail}>
                            <p className={styles.upper}>
                                Autor transakcji
                            </p>
                            <p>
                                {transDetails.buyer
                                    ? `${transDetails.buyer.firstName} ${transDetails.buyer.lastName}, ${transDetails.buyer.email}`
                                    : ''}
                            </p>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className={styles.detail}>
                            <p className={styles.upper}>Kwota transakcji netto</p>
                            <p>{transDetails.netAmount}</p>
                        </div>
                    </Col>
                    {
                        transDetails.vat > 0 &&
                        <Col xs={12}>
                            <div className={styles.detail}>
                                <p className={styles.upper}>Kwota podatku VAT</p>
                                <p>{transDetails.vat}</p>
                            </div>
                        </Col>                        
                    }
                    <Col xs={12}>
                        <div className={styles.detail}>
                            <p className={styles.upper}>Kwota transakcji brutto</p>
                            <p>{transDetails.grossAmount}</p>
                        </div>
                    </Col>
                    {Array.isArray(transDetails.products) &&
                    transDetails.products.length > 0 ? (

                        <Table className={styles.table1}>
                            <thead>
                                <tr>
                                    <th>Nr</th>
                                    <th title="Imię użytkownika">Imię</th>
                                    <th title="Nazwisko użytkownika">Nazwisko</th>
                                    <th title="Adres email">Email</th>
                                    <th title="Typ subskrypcji">Subskrypcja</th>
                                    <th title="Kwota transakcji netto">Cena netto</th>
                                    <th title="Kwota vat">VAT</th>
                                    <th title="Kwota transakcji brutto">Cena brutto</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transDetails.products.map((ele, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>{idx + 1}</td>
                                            <td>{ele.user.firstName}</td>
                                            <td>{ele.user.lastName}</td>
                                            <td>{ele.user.email}</td>
                                            <td>
                                                {ele.plan === 1
                                                    ? 'Subskrypcja miesięczna'
                                                    : ele.plan === 2
                                                    ? 'Subskrypcja roczna'
                                                    : ele.plan === 3
                                                    ? 'Subskrypcja testowa'
                                                    : ''}
                                            </td>
                                            <td>{ele.netPrice} zł</td>
                                            <td>{ele.vat} zł</td>
                                            <td>{ele.grossPrice} zł</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        
                    ) : (
                        <Col xs={12}>
                            <div className={styles.detail}>
                                <p className={styles.upper}>
                                    Rodzaj subskrypcji
                                </p>
                                <p>
                                    {transDetails.plan === 1
                                        ? 'Subskrypcja miesięczna'
                                        : transDetails.plan === 2
                                        ? 'Subskrypcja roczna'
                                        : transDetails.plan === 3
                                        ? 'Subskrypcja testowa'
                                        : ''}
                                </p>
                            </div>
                        </Col>
                    )}
                </Row>
            </Modal>
        </Fragment>
    );
};

export default withPagination({
    component: PaymentsTable,
    returnAllCollection: true,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 20,
    },
});
