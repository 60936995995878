import { Outlet, useOutletContext } from "react-router-dom";
import ReinforcementProvider from "../../../context/Reinforcement";
import { ReinforcementNavigation } from "../../../pages";

const ReinforcementLayout = () => {
    const context = useOutletContext();

    return (
        <ReinforcementProvider>
            <ReinforcementNavigation report={context.report}/>
            <Outlet context={context}/>
        </ReinforcementProvider>
    )
}

export default ReinforcementLayout;