import { isNumber } from '../../../../../utils';
import styles from './index.module.css';

const ZwgLevel = ({ parameters = {} }) => {
    return (
        <div className={styles.wrapper}>
            <h2>Parametry ogólne obliczeń</h2>
            <p>
                Rzędna terenu:{' '}
                <span className={styles.value}>
                    {isNumber(parameters.groundLevel)
                        ? parameters.groundLevel.toFixed(2)
                        : 0.0}{' '}
                    m.n.p.m.
                </span>
            </p>
            <p>
                Rzędna zwierciadła wody:{' '}
                <span className={styles.value}>
                    {isNumber(parameters.zwg)
                        ? `${parameters.zwg.toFixed(2)} m.p.p.t.`
                        : 'Brak'}
                </span>
            </p>
        </div>
    );
};

export default ZwgLevel;
