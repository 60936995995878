import { useRef, useEffect } from 'react';

const useIsMountedState = () => {
    const isMountedRef = useRef(null);
    useEffect(() => {
        isMountedRef.current = true;
        return () => (isMountedRef.current = false);
    }, []);

    return isMountedRef;
};

export default useIsMountedState;
