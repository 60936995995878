import React from 'react';
import { isNumber, roundNumber } from '../../../../../../utils';
import styles from './index.module.css';

const SoilLayer = ({
    height,
    groundLevel,
    backgroundColor,
    layerHeight,
    name,
}) => {
    return (
        <div
            className={`${styles.layer} ${
                height === 0 ? styles.hiddenLayer : ''
            }`}
            style={{ height: `${height}px`, backgroundColor }}>
            <span className={styles.spot}>
                {layerHeight.toFixed(2)}{' '}
                {isNumber(groundLevel)
                    ? `(${roundNumber(groundLevel - layerHeight, 2).toFixed(
                          2,
                      )})`
                    : ''}
            </span>
            <div className={styles.wrapper}>
                <div className={styles.description}>
                    <div className={styles.listWrapperVisible}>
                        {name && <span className={styles.item}>{name}</span>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SoilLayer;
