import { Fragment } from 'react';
import { Table } from 'react-bootstrap';
import round from '../../../../../utils/roundNumber';
import styles from './index.module.css';
import { isString } from '../../../../../utils';

const KoseckiSummary = ({ data = [] }) => {
    return (
        <Fragment>
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th>
                            Tytuł obliczeń<br></br>[-]
                        </th>
                        <th>
                            Nazwa profilu<br></br>[-]
                        </th>
                        <th>
                            Rodzaj pala<br></br>[-]
                        </th>
                        <th>
                            Technologia<br></br>[-]
                        </th>
                        <th>
                            L<br></br>[m]
                        </th>
                        <th>
                            D<br></br>[m]
                        </th>

                        <th>
                            M<sub>max</sub>
                            <br></br>[kNm]
                        </th>
                        <th>
                            M<sub>min</sub>
                            <br></br>[kNm]
                        </th>
                        <th>
                            T<sub>max</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            T<sub>min</sub>
                            <br></br>[kN]
                        </th>

                        <th>
                            d<sub>max</sub>
                            <br></br>[mm]
                        </th>
                        <th>
                            d<sub>min</sub>
                            <br></br>[mm]
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((element, index) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    {isString(element.model.projectTitle) &&
                                    element.model.projectTitle.length > 0
                                        ? element.model.projectTitle
                                        : '-'}
                                </td>
                                <td>
                                    {isString(element.profileName)
                                        ? element.profileName
                                        : '-'}
                                </td>
                                <td>
                                    {element.results.currentCapacity.pileType}
                                </td>
                                <td>
                                    {
                                        element.results.currentCapacity
                                            .pileTechnology
                                    }
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity
                                            .pileLength,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity
                                            .diameterPile,
                                        2,
                                    )}
                                </td>

                                <td>
                                    {round(
                                        element.results.currentCapacity
                                            .maxMoment,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity
                                            .minMoment,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity
                                            .maxShearForce,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity
                                            .minShearForce,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity
                                            .maxDisplacement,
                                        2,
                                    )}
                                </td>
                                <td>
                                    {round(
                                        element.results.currentCapacity
                                            .minDisplacement,
                                        2,
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <p className={styles.underline}>Oznaczenia:</p>
            <p>
                L - <span className={styles.value}>długość pala</span>
            </p>
            <p>
                D -{' '}
                <span className={styles.value}>średnica/długość boku pala</span>
            </p>

            <p>
                M<sub>max</sub> -{' '}
                <span className={styles.value}>
                    maksymalny moment zginający w palu
                </span>
            </p>
            <p>
                M<sub>min</sub> -{' '}
                <span className={styles.value}>
                    minimalny moment zginający w palu
                </span>
            </p>

            <p>
                T<sub>max</sub> -{' '}
                <span className={styles.value}>
                    maksymalna siła tnąca w palu
                </span>
            </p>
            <p>
                T<sub>min</sub> -{' '}
                <span className={styles.value}>
                    minimalna siła tnąca w palu
                </span>
            </p>

            <p>
                d<sub>max</sub> -{' '}
                <span className={styles.value}>
                    maksymalne odkształcenie trzonu pala
                </span>
            </p>
            <p>
                d<sub>min</sub> -{' '}
                <span className={styles.value}>
                    minimalne odkształcenie trzonu pala
                </span>
            </p>
        </Fragment>
    );
};

export default KoseckiSummary;
