import React, { Fragment, useState } from 'react';
import { withPagination } from '../../../../../hoc';
import {
    ItemsPerPage,
    Pagination,
    SectionTitle,
    Table,
} from '../../../../../components';
import styles from './index.module.css';
import round from '../../../../../utils/roundNumber';
import useDeepCompareEffect from 'use-deep-compare-effect';

const CapacityTable = ({
    dataToPaginate,
    allCollection = [],
    pagination,
    paginationHelpers,
    yt,
    currentZ
}) => {
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [csvData, setCsvData] = useState([]);

    useDeepCompareEffect(() => {
        setCsvData(
            allCollection
                .map(
                    ({
                        z,
                        Rbk,
                        Rsk,
                        Rcd,
                        yt
                    }) => ({ z, Rbk, Rsk, Rcd, yt }),
                )
                .map((ele) =>
                    Object.fromEntries(
                        Object.entries(ele).map(([key, value = '']) => [
                            [key],
                            value.toString().replace('.', ','),
                        ]),
                    ),
                ),
        );

        setCsvHeaders([
            {
                label: 'Z [m.p.p.t.]',
                key: 'z',
            },
            {
                label: 'Rbk [kN]',
                key: 'Rbk',
            },
            {
                label: 'Rsk [kN]',
                key: 'Rsk',
            },
            {
                label: 'yt [-]',
                key: 'yt',
            },
            {
                label: 'Rcd [kN]',
                key: 'Rcd',
            },
        ]);
    }, [allCollection]);
    return (
        <Fragment>
            <SectionTitle
                title={
                    'Nośność pionowa ścianki szczelnej wg metody francuskiego Centralnego Laboratorium Dróg i Mostów'
                }
            />
            <ItemsPerPage
                onDisplayItemsNumber={paginationHelpers.displayItemsNumber}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                display={[15, 30, paginationHelpers.allItemsLength]}
            />
            <Table
                csvData={csvData}
                csvFilename={'SheetPileCapacity'}
                csvHeaders={csvHeaders}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th title="Rzędna">
                            z<br></br>[m.p.p.t.]
                        </th>
                        <th title="Charakterystyczna nośność podstawy">
                            R<sub>b,k</sub>
                            <br></br>[kN]
                        </th>
                        <th title="Charakterystyczna nośność pobocznicy">
                            R<sub>s,k</sub>=&Sigma;R<sub>s,k,i</sub>
                            <br></br>[kN]
                        </th>
                        <th title="Współczynnik bezp. przy ściskaniu">
                            &gamma;<sub>b,s,t</sub>
                            <br></br>[-]
                        </th> 
                        <th title="Nośność obliczeniowa na wciskanie">
                            R<sub>c,d</sub>
                            <br></br>[kN]
                        </th> 
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    {dataToPaginate.map((element, index) => {
                        return (
                            <tr
                                className={`${styles.tr} ${element.z === currentZ ? 'shadowTr' : ''}`}
                                key={index}
                            >
                                <td>{paginationHelpers.countingItemsPerPage + index + 1}</td>
                                <td>{round(element.z, 2).toFixed(2)}</td>
                                <td>{round(element.Rbk, 2).toFixed(2)}</td>
                                <td>{round(element.Rsk, 2).toFixed(2)}</td>
                                <td>{round(yt, 2).toFixed(2)}</td>
                                <td>{round(element.Rcd, 2).toFixed(2)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <Pagination
                currentPage={pagination.currentPage}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                nextPage={paginationHelpers.nextPage}
            />
        </Fragment>
    );
};

export default withPagination({
    component: CapacityTable,
    returnAllCollection: true,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 25,
    },
});