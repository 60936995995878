import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { withDefaultVerticalCapacityChart } from '../../../../hoc';
import { isNumber, setSoilColorQc } from '../../../../utils';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';

const CuChart = ({
    dataToChart,
    chartOptions,
}) => {
    const [options, setOptions] = useState(chartOptions);
    const [series, setSeries] = useState([
        {
            name: '',
            data: [],
        },
    ]);

    useDeepCompareEffect(() => {
        setSeries(() => {
            return [
                {
                    name: 'Z',
                    data: [
                        ...dataToChart.sort((a,b) => a.z - b.z)
                        .filter(ele => (isNumber(ele.cu) && isNumber(ele.z)))
                        .reduce((acc, ele, idx, arr) => {
                            if (idx === (arr.length - 1)){
                                return [...acc, [ele.cu, ele.z], [0, ele.z]];
                            } else if(idx === 0) {
                                return [
                                    ...acc, 
                                    [ele.cu, 0], 
                                    [ele.cu, ele.z]
                                ];
                            } else {
                                return [...acc, [ele.cu, ele.z]];
                            }
                        }, []),
                    ],
                },
            ];
        });

        setOptions(() => {
            const maxZ = Math.max(...dataToChart.map((ele) => ele.z));
            const maxCu = Math.max(...dataToChart.map((ele) => ele.cu));
            const minCu = Math.min(...dataToChart.map((ele) => ele.cu));

            return {
                ...chartOptions,

                dataLabels: {
                    ...chartOptions['dataLabels'],
                    offsetX: 8,
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 'normal',
                        colors: ['#000']
                    },
                },
                yaxis: {
                    ...chartOptions['yaxis'],
                    type: 'numeric',
                    tickAmount: Math.ceil(maxZ),
                    max: Math.ceil(maxZ),
                    title: {
                        ...chartOptions['yaxis']['title'],
                        offsetX: 0
                    },
                },
                xaxis: {
                    ...chartOptions['xaxis'],
                    type: 'numeric',
                    labels: {
                        ...chartOptions['xaxis']['labels'],
                        offsetY: 9
                    },
                    title: {
                        ...chartOptions['xaxis']['title'],
                        offsetY: -80
                    },
                    position: 'top',
                    tickAmount: 10,
                    max: Math.floor((maxCu + 10 - 1) / 10) * 10 + 10,
                    min: Math.floor(minCu),
                    axisBorder: {
                        show: true,
                    },
                },
                stroke: {
                    ...chartOptions['stroke'],
                    width: 1,
                    colors: ['#000'],
                },
                tooltip: {
                    enabled: false
                },
                markers: {
                    size: 0
                },
                fill: {
                    ...chartOptions['fill'],
                    colors: ['#000'],
                    opacity: 0.15,
                },
                annotations: {
                    ...chartOptions['annotations'],

                    yaxis: [
                        ...dataToChart.sort((a,b) => a.z-b.z).reduce((acc, ele, idx, arr) => {
                            return [
                                ...acc,
                                {
                                    y: idx === 0 ? 0 : arr[idx - 1].z,
                                    y2: idx === (arr.length - 1) ? Math.ceil(ele.z) : ele.z,
                                    borderColor: setSoilColorQc({
                                        type: ele.soil,
                                        qc: ele.qc,
                                    }),
                                    opacity: 0.9,
                                    fillColor: setSoilColorQc({
                                        type: ele.soil,
                                        qc: ele.qc,
                                    }),
                                },
                            ];
                        }, []),
                    ],
                },
            };
        });
    }, [chartOptions, dataToChart]);

    return (
        <Chart
            options={options}
            series={series}
            height={650}
            type="area"
        />
    );
};

export default React.memo(
    withDefaultVerticalCapacityChart(CuChart),
    (prev, next) => stringify(prev) === stringify(next),
);
