import { roundNumber } from "../../../utils";

const netPrice = {
    year:{
        first: 1419,
        second: 1309,
    },
    month:{
        first: 129,
        second: 119,
    }
};
const vat = 0*0.01;
const vatTax = {
    year:{
        first: roundNumber(netPrice.year.first * vat,2),
        second: roundNumber(netPrice.year.second * vat,2),
    },
    month:{
        first: roundNumber(netPrice.month.first * vat,2),
        second: roundNumber(netPrice.month.second * vat,2)
    }
};
const plans = {
    year:{
        first: roundNumber(netPrice.year.first + vatTax.year.first,2),
        second: roundNumber(netPrice.year.second + vatTax.year.second,2)
    },
    month:{
        first: roundNumber(netPrice.month.first + vatTax.month.first,2),
        second: roundNumber(netPrice.month.second + vatTax.month.second,2)
    }
}

export default plans