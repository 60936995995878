import stringify from 'fast-json-stable-stringify';
import React from 'react';
import {
    SelectField,
    Table,
    TableInput,
    Modal,
    Button,
} from '../../../../../components';
import styles from './index.module.css';

const DefaultSoil = ({
    open,
    onHide,
    onSubmit,
    state,
    defaultSoils,
    moistures,
    rowIndex,
    genesisType,
    onChange,
    onBlur,
}) => {
    return (
        <Modal
            title={'Dobierz domyślne parametry warstwy gruntu'}
            hideButton={false}
            css={styles.modal}
            open={open}
            onHide={onHide}>
            <h4 className={styles.alert}>
                UWAGA:<br></br>Parametry gruntów dobierane są na podstawie normy
                PN-81 B-03020. Nie jest to metoda zalecana, w świetle
                dzisiejszych przepisów parametry gruntowe powinny być wyznaczane
                na podstawie badań laboratoryjnych i/lub sondowań!
            </h4>
            <Table>
                <thead>
                    <tr>
                        <th>
                            Rodzaj gruntu<br></br>[-]
                        </th>
                        <th>
                            I<sub>L</sub>/I<sub>d</sub> <br></br>[-]
                        </th>
                        {state.cohesive && (
                            <th>
                                Geneza <br></br>[-]
                            </th>
                        )}
                        {state.noncohesive && (
                            <th>
                                Stan wilgotności <br></br>[-]
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    <tr className={styles.tr}>
                        <td>
                            <SelectField
                                name="name"
                                value={state.name.value}
                                isValid={state.name.isValid}
                                defaultOption="Wybierz rodzaj gruntu"
                                options={defaultSoils}
                                onChange={onChange}
                                onBlur={onBlur}
                                required
                            />
                        </td>
                        <td>
                            <TableInput
                                name="densityOrPlasticity"
                                type="number"
                                min={state.cohesive ? '-1' : '0'}
                                max="1"
                                step="0.001"
                                align={'center'}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={state.densityOrPlasticity.value}
                                isValid={state.densityOrPlasticity.isValid}
                                required
                            />
                        </td>
                        {state.cohesive && (
                            <td>
                                <SelectField
                                    name="genesis"
                                    value={state.genesis.value}
                                    isValid={state.genesis.isValid}
                                    defaultOption="[-]"
                                    options={genesisType}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    required
                                />
                            </td>
                        )}
                        {state.noncohesive && (
                            <td>
                                <SelectField
                                    name="moisture"
                                    value={state.moisture.value}
                                    isValid={state.moisture.isValid}
                                    defaultOption="[-]"
                                    options={moistures}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    required
                                />
                            </td>
                        )}
                    </tr>
                </tbody>
            </Table>
            <div className={styles.floatWrapper}>
                <Button
                    css={`
                        ${styles.button} default-button-style
                    `}
                    onClick={() => onSubmit(state, rowIndex)}
                    altTitle="Dodaj"
                    title="Dodaj warstwę"
                />
            </div>
        </Modal>
    );
};

export default React.memo(
    DefaultSoil,
    (prev, next) => stringify(prev) === stringify(next),
);
