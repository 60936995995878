import { useCallback, useState } from "react"
import { isNumber, roundNumber, stringToNumber } from "../../../utils";
import { sheetPileTypes } from "../../../config/ProjectConfig";


const technologyTypes = [
    'Wbijane lub wciskane',
    'Wwibrowywane'
];
const defaultState = {
    zwg: {
        value: '',
        isValid: null
    },
    cptLevel: {
        value: '',
        isValid: null 
    },
    groundLevel: {
        value: '',
        isValid: null 
    },
    sheetPileType: {
        value: '',
        isValid: null
    },
    sheetPileLength: {
        value: '',
        isValid: null
    },
    sheetPileHeadSpot: {
        value: '',
        isValid: null
    },
    baseArea: {
        value: '',
        isValid: null
    },
    perimeter: {
        value: '',
        isValid: null
    },
    sheetPileList: {
        value: true,
        isValid: true
    },
    sheetPileNumbers: {
        value: 1,
        isValid: true
    },
    yt: {
        value: 1.4,
        isValid: true
    },
    technology: {
        value: technologyTypes[0],
        isValid: true
    }
};

const Calculations = ({
    updateResultsState,
}) => {
    const [state, setState] = useState(defaultState);
    
    const onBlur = useCallback((e) => {
        const { name, value } = e.currentTarget;
        const valid = e.currentTarget.checkValidity();
        const greaterThanZeroProps = [
            'sheetPileLength',
            'perimeter',
            'baseArea',
            'sheetPileNumbers',
            'yt'
        ];
        const greaterThanEqualZeroProps = [
            'zwg',
            'sheetPileHeadSpot'
        ];
        setState((state) => {
            return {
                ...state,
                [name]: {
                    ...state[name],
                    isValid: greaterThanZeroProps.includes(name)
                        ? value > 0 && valid
                        : greaterThanEqualZeroProps.includes(name)
                        ? value >= 0 && valid
                        :
                        valid,
                },
            };
        });
    },[]);

    const onChange = (e, updateSoilTable) => {
        const input =
            e.currentTarget.closest('input') ||
            e.currentTarget.closest('select');

        if (input) {
            const { name, value, type, checked } = input;

            updateResultsState((state) => {
                return { 
                    ...state,
                    isResultsActual: false,
                };
            });

            if(type === 'radio') {
                setState((state) => {
                    return {
                        ...state,
                        [name]: {
                            value: Number(value),
                            isValid: true,
                        },
                    };
                });
            } else if(type === 'checkbox') {
                    setState((state) => ({
                        ...state,
                        [name]: {
                            ...state[name],
                            value: !!checked,
                        },
                    }));
            } else {
                if (name === 'cptLevel') {
                    const cptLevel = type === 'number' && value.length > 0 
                        ? 
                            isNumber(stringToNumber(value)) ? stringToNumber(value) : 0
                        : 
                            isNumber(value) ? value : 0;
                    const currentCptLevel = isNumber(state.cptLevel.value) ? state.cptLevel.value : 0;

                    setState((state) => ({
                        ...state,
                        [name]: {
                            value: type === 'number' && value.length > 0 ? stringToNumber(value) : value,
                            isValid: true,
                        },
                    }));

                    updateSoilTable((state) => {
                        return state.map(ele => {
                            const z = roundNumber(ele.z.value - currentCptLevel + cptLevel,2);

                            if(!ele.cd || !ele.cd.value){
                                return {
                                    ...ele,
            
                                    z: {
                                        value: z,
                                        isValid: isNumber(z) ? true : ele.z.isValid
                                    },
                                }                    
                            } else {
                                return ele
                            }
                        })
                    })
                } else {
                    setState(stateObj => {
                        return {
                            ...stateObj,

                            [name]: {
                                value: type === 'number' && value.length > 0 ? stringToNumber(value) : value,
                                isValid: true,
                            },
                        };
                    });
                }
            }
        }
    };
    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    }, []);

    const loadProject = (loadedState = {}) => {
        setState(() => {
            return {
                ...defaultState,
                ...loadedState,
            };
        });
    };

    const calculationState = {
        onChange,
        onBlur,
        onKeyDown,
        updateState: setState,
        loadProject: loadProject,
        initState: () => setState(defaultState),

        state,
        sheetPileTypes,
        technologyTypes
    };

    return {
        calculationState
    }

};

export default Calculations