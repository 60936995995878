import React, { Fragment, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Checkbox, SectionTitle, Modal } from '../../../../components';
import ReportWrapper from './ReportWrapper';
import ReactToPrint from 'react-to-print';
import styles from './index.module.css';

const PrintResults = ({ state, onChange, open, onHide }) => {

    const pdfComponentRef = useRef();

    return ( 
        <Fragment>            
            <Modal title="Raport z obliczeń" open={open} onHide={onHide} hideButton={false}>
                <Row>
                    <Col xs={6}>
                        <Checkbox name="allItems" label="Zaznacz wszystko" value={state.reportItem.allItems} onChange={onChange}/>
                    </Col>
                    <Col xs={6}>
                        <Checkbox name="firstPage" label="Strona tytułowa" value={state.reportItem.firstPage} onChange={onChange}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <SectionTitle title="Dane wejściowe"/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="soilsTable" label="Tabela parametrów gruntu" value={state.reportItem.soilsTable} onChange={onChange}/>
                        <Checkbox name="projectInfo" label="Parametry ogólne obliczeń" value={state.reportItem.projectInfo} onChange={onChange}/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="micropileProperties" label="Parametry mikropala" value={state.reportItem.micropileProperties} onChange={onChange}/>
                        <Checkbox name="saftyFactors" label="Współczynniki bezpieczeństwa" value={state.reportItem.saftyFactors} onChange={onChange}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <SectionTitle title="Wyniki obliczeń"/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="results" label="Wyniki nośności mikropala" value={state.reportItem.results} onChange={onChange}/>
                        {state.results.calculationParameters.isSteelRod && <Checkbox name="steelRodTable" label="Parametry żerdzi" value={state.reportItem.steelRodTable} onChange={onChange}/>}
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="micropileDetailsTable" label="Tabela nośności wzdłuż profilu gruntowego" value={state.reportItem.micropileDetailsTable} onChange={onChange}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <SectionTitle title="Załączniki graficzne"/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Checkbox name="micropileChart" label="Schemat nośności mikropala" value={state.reportItem.micropileChart} onChange={onChange}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <ReactToPrint 
                            trigger = {() => <Button css={`${styles.button} default-button-style`} altTitle="Drukuj" title="Drukuj raport" type="button" />}
                            content={() => pdfComponentRef.current}
                        /> 
                    </Col>
                </Row>
                <div className={styles.pdfWrapper}>
                    <ReportWrapper
                        pdfState={state}
                        ref={pdfComponentRef} 
                    />                    
                </div>
            </Modal>
        </Fragment>
    )
}

export default PrintResults