import { useCallback, useState } from "react";
import { isExcavationBaseCorrect } from "../../../config/ProjectConfig";
import { makeNumberPositive, stringToNumber, isNumber } from "../../../utils";

const defaultState = {
    leftZwg: {
        value: '',
        isValid: null
    },
    rightZwg: {
        value: '',
        isValid: null
    },
    sheetPileSpot: {
        value: '',
        isValid: null
    },
    excavationBase: {
        value: '',
        isValid: null
    },
    groundLevel: {
        value: '',
        isValid: null 
    }
}

const ZwgLevelsHelpers = ({
    updateResultsState
}) => {
    const [zwgLevelsState, setZwgLevelsState] = useState(defaultState);

    const onBlurZwgState = (e) => {
        const maxHeight = JSON.parse(e.currentTarget.getAttribute('data-height'));
        const { name, value } = e.currentTarget;
        const valid = e.currentTarget.checkValidity(); 

        setZwgLevelsState(state => ({
            ...state, 
            [name]: { 
                ...state[name],
                isValid: valid
            },
            ...(name === 'excavationBase') && {
                [name]: {
                    ...state[name],
                    isValid: (state.sheetPileSpot.value > value) && valid
                }
            },
            ...(name === 'sheetPileSpot') && {
                excavationBase: {
                    ...state['excavationBase'],
                    ...isNumber(state.excavationBase.value) && {
                        isValid: isExcavationBaseCorrect(state.excavationBase.value, stringToNumber(value))
                    }
                },
                [name]: {
                    ...state[name],
                    isValid: (maxHeight >= value) && valid
                }
            }  
        })) 
    };

    const onChangeZwgState = useCallback((e) => {
        const input = e.currentTarget.closest('input');
        if(input){
            const { value, name } = input;

            updateResultsState((state) => {
                return {
                    ...state,
                    isResultsActual: false,
                };
            });

            setZwgLevelsState(state => ({
                ...state, 
                [name]: { 
                    value: name === 'groundLevel' ? stringToNumber(value) : makeNumberPositive(stringToNumber(value)),
                    isValid: true
                }
            }))
        }
    },[]);

    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    },[]);

    const loadProject = (calculationSettingsObj={}) => {
        setZwgLevelsState(() => {
            return {
                ...defaultState,
                ...calculationSettingsObj
            }
        })
    }

    const zwgLevels = {
        onChange: onChangeZwgState,
        onBlur: onBlurZwgState,
        onKeyDown: onKeyDown,
        initState: () => setZwgLevelsState(defaultState),
        updateState: setZwgLevelsState,
        loadProject: loadProject,
        state: zwgLevelsState
    }

    return {
        zwgLevels
    }

}

export default ZwgLevelsHelpers