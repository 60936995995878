import isNumber from "../isNumber";

function numberRange(absUpper, absLower, step) {
    const arr =[];
    if(isNumber(absUpper) && isNumber(absLower) && isNumber(step)){
        if(absUpper >= absLower){
            for (let i = absLower, l = Math.ceil(absUpper + step); i < l; i = i + step) {    
                if(i >= absUpper) {
                    arr.push(absUpper);
                    break
                } else {
                    arr.push(i)
                }
            }        
        } else {
            for (let i = absUpper, l = Math.ceil(absLower + step); i < l; i = i + step) {     
                if(i >= absLower) {
                    arr.push(absLower);
                    break
                } else {
                    arr.push(i)
                }
            }
        }
        return arr
    } else {
        return []
    }
}
export default numberRange