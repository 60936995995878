import React, { Fragment, useCallback, useState, useEffect, useRef } from 'react';
import CalculationSection from '../CalculationSection';
import DesignSoilParametersSection from '../DesignSoilParametersSection';
import DisplacementChart from '../DisplacementChart';
import FoundationParametersSection from '../FoundationParametersSection';
import InternalForcesSection from '../InternalForcesSection';
import KxChart from '../KxChart';
import MomentChart from '../MomentChart';
import { ReportFirstPage, ResultsFooter, ResultsHeader } from '../../../../../components';
import PileLoadsSection from '../PileLoadsSection';
import PileParametersSection from '../PileParametersSection';
import QgrChart from '../QgrChart';
import KxRgrSection from '../KxRgrSection';
import ShearForceChart from '../ShearForceChart';
import SoilParametersSection from '../SoilParametersSection';

import styles from './index.module.css';
import PlasticDisplacementChart from '../PlasticDisplacementChart';

const PdfReport = React.forwardRef(({ pdfState, onLoad }, ref) => {
    const contentRef = useRef(null);
    const [footerHeight, setFooterHeight] = useState(0);
    const [maxWidth, setMaxWidth] = useState(0);

    useEffect(() =>{
        if(contentRef.current){
            setMaxWidth(contentRef.current.offsetWidth)
        }
    },[]);

    return (   
        <Fragment>
            <div className={styles.wrapper} ref={ref}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <td>
                                <div className={styles.headerSpace}></div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <main className={styles.content} ref={contentRef}>
                                    <ol>
                                        <div>
                                            { pdfState.reportItem.firstPage && 
                                                <Fragment>
                                                    <ReportFirstPage
                                                        title="Nośność pozioma pali wg metody uogólnionej M. Koseckiego"
                                                        subtitle={pdfState.projectTitle.state.projectTitle}
                                                        author={pdfState.report.author}
                                                        contact={pdfState.report.contact}
                                                        logo={pdfState.report.logo}
                                                    />
                                                    
                                                </Fragment>
                                            }
                                            {
                                            (pdfState.reportItem.soilsTable || pdfState.reportItem.projectInfo || pdfState.reportItem.pileParameters || pdfState.reportItem.foundationParameters || pdfState.reportItem.pileLoads) && 
                                            <Fragment>
                                                <div className={styles.break}></div>
                                                <li className={styles.h1}>Dane do projektu</li>
                                                { pdfState.reportItem.soilsTable && 
                                                    <SoilParametersSection
                                                        parameters={pdfState.soilsCollection}
                                                    /> 
                                                }
                                                { pdfState.reportItem.projectInfo && 
                                                    <CalculationSection
                                                        parameters={pdfState.calculationsSettings}
                                                        analysisType={pdfState.saftyFactors.analysisType.value}
                                                    />
                                                }
                                                { pdfState.reportItem.pileParameters && 
                                                    <PileParametersSection
                                                        parameters={pdfState.pileParameters}
                                                    />
                                                }
                                                { pdfState.reportItem.foundationParameters && 
                                                    <FoundationParametersSection
                                                        parameters={pdfState.foundationParameters}
                                                    />
                                                }
                                                { pdfState.reportItem.pileLoads && 
                                                    <PileLoadsSection
                                                        loads={pdfState.pileLoads}
                                                    />
                                                }
                                                
                                            </Fragment>
                                            }
                                            { 
                                            (pdfState.reportItem.designSoilsParams || pdfState.reportItem.kxRgr || pdfState.reportItem.internalForces) && 
                                                <Fragment>
                                                    <div className={styles.break}></div>
                                                    <li className={styles.h1}>Wyniki obliczeń</li>
                                                    { pdfState.reportItem.designSoilsParams && 
                                                        <DesignSoilParametersSection
                                                            parameters={pdfState.results.designSoilParameters}
                                                        />
                                                    }
                                                    { pdfState.reportItem.kxRgr && 
                                                        <KxRgrSection
                                                            parameters={pdfState.results.parametersDetails}
                                                        />
                                                    }
                                                    { pdfState.reportItem.internalForces && 
                                                        <InternalForcesSection
                                                            forces={pdfState.results.internalForcesDetails}
                                                        />
                                                    }
                                                    <div className={styles.break}></div>
                                                </Fragment>
                                            }
                                            {
                                            (pdfState.reportItem.kxChart || pdfState.reportItem.rgrChart || pdfState.reportItem.bendingChart || pdfState.reportItem.shearChart || pdfState.reportItem.displacementChart) &&
                                            <Fragment>
                                                <li className={styles.h1}>Załączniki</li>
                                                { pdfState.reportItem.kxChart && 
                                                    <div className={styles.breakInside}>
                                                        <h2>Rozkład reakcji poziomej gruntu</h2>
                                                        <div className={styles.imgBorder} style={{ width: `${maxWidth}px` }}>
                                                            <img alt='' src={pdfState.allLoaded.kxChart || ''} />
                                                        </div>
                                                    </div>
                                                }
                                                {pdfState.reportItem.rgrChart && 
                                                    <div className={styles.breakInside}>
                                                        <h2>Rozkład oporu granicznego gruntu</h2>
                                                        <div className={styles.imgBorder} style={{ width: `${maxWidth}px` }}>
                                                            <img alt='' src={pdfState.allLoaded.rgrChart || ''} />
                                                        </div>
                                                    </div>
                                                }
                                                { pdfState.reportItem.bendingChart && 
                                                    <div className={styles.breakInside}>
                                                        <h2>Wykres momentów zginających w palu</h2>
                                                        <div className={styles.imgBorder} style={{ width: `${maxWidth}px` }}>
                                                            <img alt='' src={pdfState.allLoaded.bendingChart || ''} />
                                                        </div>
                                                    </div>
                                                }
                                                { pdfState.reportItem.shearChart && 
                                                    <div className={styles.breakInside}>
                                                        <h2>Wykres sił tnących w palu</h2>
                                                        <div className={styles.imgBorder} style={{ width: `${maxWidth}px` }}>
                                                            <img alt='' src={pdfState.allLoaded.shearChart || ''} />
                                                        </div>
                                                    </div>
                                                }
                                                { pdfState.reportItem.displacementChart && 
                                                    <div className={styles.breakInside}>
                                                        <h2>Wykres przemieszczeń poziomych pala w stanie sprężystym</h2>
                                                        <div className={styles.imgBorder} style={{ width: `${maxWidth}px` }}>
                                                            <img alt='' src={pdfState.allLoaded.displacementChart || ''}/>
                                                        </div>
                                                    </div>
                                                }
                                                { pdfState.reportItem.plasticDisplacementChart && 
                                                    <div className={styles.breakInside}>
                                                        <h2>Wykres przemieszczeń poziomych pala z stanie sprężysto-plastycznym</h2>
                                                        <div className={styles.imgBorder} style={{ width: `${maxWidth}px` }}>
                                                            <img alt='' src={pdfState.allLoaded.plasticDisplacementChart || ''}/>
                                                        </div>
                                                    </div>
                                                }
                                            </Fragment>
                                            }
                                        </div>
                                    </ol>
                                </main>                     
                            </td>
                        </tr> 
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <div style={{ height: footerHeight }} className={styles.footerSpace}></div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <ResultsHeader
                    author={pdfState.report.author}
                    contact={pdfState.report.contact}
                />
                <ResultsFooter
                    setFooterHeight={useCallback((val) => setFooterHeight(val),[])}
                    pdfState={pdfState.reportItem}
                    globalTitle='Nośność pozioma pali wg metody uogólnionej M. Koseckiego'
                    title={pdfState.projectTitle.state.projectTitle}
                />

            </div>
            { pdfState.reportItem.kxChart && 
                <div className={styles.chartsWrapper}>
                    <KxChart
                        maxWidth={maxWidth}
                        chartTitle="Moduł reakcji poziomej gruntu Kx"
                        xLabel="Kx [kPa]"
                        onLoad={onLoad}
                        series={pdfState.results.designSoilParameters}
                    />                                            
                </div>
            }
            {pdfState.reportItem.rgrChart && 
                <div className={styles.chartsWrapper}>
                    <QgrChart
                        maxWidth={maxWidth}
                        chartTitle="Opór graniczny gruntu Qgr"
                        xLabel="Qgr [kN/m]"
                        onLoad={onLoad}
                        series={pdfState.results.designSoilParameters}
                    />                                            
                </div>
            }

            { pdfState.reportItem.bendingChart && 
                <div className={styles.chartsWrapper}>
                    <MomentChart
                        maxWidth={maxWidth}
                        chartTitle="Moment zginający w palu"
                        xLabel="M [kNm]"
                        onLoad={onLoad}
                        series={pdfState.results.internalForces || []}
                    />
                </div>
            }
            { pdfState.reportItem.shearChart && 
                <div className={styles.chartsWrapper}>
                    <ShearForceChart
                        maxWidth={maxWidth}
                        chartTitle="Siła tnąca w palu"
                        xLabel="T [kN]"
                        onLoad={onLoad}
                        series={pdfState.results.internalForces || []}
                    />
                </div>
            }
            { pdfState.reportItem.displacementChart && 
                <div className={styles.chartsWrapper}>
                    <DisplacementChart
                        maxWidth={maxWidth}
                        chartTitle="Przemieszczenia poziome pala w stanie sprężystym"
                        xLabel="D [mm]"
                        onLoad={onLoad}
                        series={pdfState.results.internalForces || []}
                    />
                </div>
            }
            { Array.isArray(pdfState.results.internalForces.plasticDisplacements) && pdfState.reportItem.plasticDisplacementChart && 
                <div className={styles.chartsWrapper}>
                    <PlasticDisplacementChart
                        maxWidth={maxWidth}
                        chartTitle="Przemieszczenia poziome pala w stanie sprężysto-plastycznym"
                        xLabel="D [mm]"
                        onLoad={onLoad}
                        series={pdfState.results.internalForces || []}
                    />
                </div>
            }
        </Fragment>
    )
})

export default PdfReport