const dev = {
    routes: {
        app: {
            home: '/',
            checkoutSuccess: '/konto/platnosc/sukces', 
            checkoutCancel: '/konto/platnosc/anuluj',
            signUp: '/rejestruj',
            activation: '/konto/aktywuj/:id',
            resetPassword: '/konto/uzytkownik/haslo/reset/:id',
            setPassword: '/konto/uzytkownik/haslo/ustaw/:id',
            notFound: '/strona-nie-istnieje/404',
        },
        dashboard: {
            adminRoute: '/auth',
            logIn: '/auth/zaloguj',

            koseckiMethodModel: '/auth/kosecki',
            koseckiMethodResults: '/auth/kosecki/wyniki',

            verticalCapacityModel: '/auth/pale',
            verticalCapacityResults: '/auth/pale/wyniki',

            reinforcementModel: '/auth/zbrojenie',
            reinforcementResults: '/auth/zbrojenie/wyniki',

            micropilesModel: '/auth/mikropale/kotwiace',
            micropilesResults: '/auth/mikropale/kotwiace/wyniki',

            micropilesCompressionModel: '/auth/mikropale/wciskane',
            micropilesCompressionResults:'/auth/mikropale/wciskane/wyniki',

            pileCptModel: '/auth/pale/cpt',
            pileCptResults: '/auth/pale/cpt/wyniki',

            sheetPileCptModel: '/auth/scianka',
            sheetPileCptResults: '/auth/scianka/wyniki',

            walingModel: '/auth/kotwienie',

            projects: '/auth/projekty',
            charts: '/auth/wykresy',
            home: '/auth/home',

            checkoutSuccess: '/auth/platnosc/sukces',
            checkoutCancel: '/auth/platnosc/anuluj',
        },
        admin: {
            adminRoute: '/admin/auth',
            home: '/admin/auth/home',
            logIn: '/admin/auth/zaloguj',
            usersList: '/admin/auth/uzytkownicy',
            payments: '/admin/auth/platnosci',
        },
    },
};
const prod = {
    routes: {
        app: {
            home: '/',
            checkoutSuccess: '/konto/platnosc/sukces', 
            checkoutCancel: '/konto/platnosc/anuluj',
            signUp: '/rejestruj',
            activation: '/konto/aktywuj/:id',
            resetPassword: '/konto/uzytkownik/haslo/reset/:id',
            setPassword: '/konto/uzytkownik/haslo/ustaw/:id',
            notFound: '/strona-nie-istnieje/404',
        },
        dashboard: {
            adminRoute: '/auth',
            logIn: '/auth/zaloguj',

            koseckiMethodModel: '/auth/kosecki',
            koseckiMethodResults: '/auth/kosecki/wyniki',

            verticalCapacityModel: '/auth/pale',
            verticalCapacityResults: '/auth/pale/wyniki',

            reinforcementModel: '/auth/zbrojenie',
            reinforcementResults: '/auth/zbrojenie/wyniki',

            micropilesModel: '/auth/mikropale/kotwiace',
            micropilesResults: '/auth/mikropale/kotwiace/wyniki',

            micropilesCompressionModel: '/auth/mikropale/wciskane',
            micropilesCompressionResults:'/auth/mikropale/wciskane/wyniki',

            pileCptModel: '/auth/pale/cpt',
            pileCptResults: '/auth/pale/cpt/wyniki',

            sheetPileCptModel: '/auth/scianka',
            sheetPileCptResults: '/auth/scianka/wyniki',

            walingModel: '/auth/kotwienie',

            projects: '/auth/projekty',
            charts: '/auth/wykresy',
            home: '/auth/home',

            checkoutSuccess: '/auth/platnosc/sukces',
            checkoutCancel: '/auth/platnosc/anuluj',
        },
        admin: {
            adminRoute: '/admin/auth',
            home: '/admin/auth/home',
            logIn: '/admin/auth/zaloguj',
            usersList: '/admin/auth/uzytkownicy',
            payments: '/admin/auth/platnosci',
        },
    },
};

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;

const obj = {
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config,
};

export default obj;
