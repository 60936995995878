import { useCallback, useState, useEffect } from 'react';
import {
    stringToNumber,
    isNumber,
    roundNumber,
    makeNumberPositive
} from '../../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { nkInterpolation, soilType } from '../../../config/ProjectConfig';

const mimeTypes = [
    'image/apng',
    'image/avif',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/webp',
];
const defaultRow = {
    layerNo: {
        value: 1,
    },
    name: {
        value: '',
        isValid: null
    },
    layerIndex: {
        value: 1,
    },
    sigm: {
        value: 0
    },
    sigmSum: {
        value: 0
    },
    qc: {
        value: '',
        isValid: null,
    },
    cu: {
        value: '',
        isValid: null,
    },
    y: {
        value: '',
        isValid: null,
    },
    z: {
        value: '',
        isValid: null,
    },
    zSoil: { 
        value: '',
        isValid: null,
    },
    soilType: {
        value: '',
        isValid: null,
    },
    cohesiveType: {
        value: '',
    },
    nk: {
        value: '',
    },
    cd: {
        value: true
    }
};
const defaultChartDefault = {
    origin: [],
    scaleX: [],
    scaleY: [],
    points: [],
    layers: []    
};
const cohesiveSoilType = {
    1: 'Gliny pokrywowe i zwałowe zlodowacenia Wisły nieskonsolidowane lodowcem',
    2: 'Gliny zwałowe starsze skonsolidowane',
    3: 'Utwory zastoiskowe czwartorzędowe iły pylaste, gliny pylaste',
    4: 'Iły plioceńskie i mioceńskie',
    5: 'Gytie'
};

const SoilTableHelpers = ({
    setAlert,
    zwg,
    cptLevel,
    updateResultsState,
    updateCalculation
}) => {
    const [soilTableState, setSoilTableState] = useState([{ ...defaultRow }]);
    const [logoImg, setLogoImg] = useState('');
    const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });
    const [scale, setScale] = useState(100);
    const [closePoints, setClosePoints] = useState(false);
    const [chartData, setChartData] = useState(defaultChartDefault);

    useDeepCompareEffect(() => {
        if(soilTableState.map(ele => ele.name.value.toString()).length > 0){
            updateCalculation(state => {
                return {
                    ...state,
                    layersName: [...new Set(soilTableState.map(ele => ele.name.value.toString()))].map((ele,idx) => {
                        return {
                            index: idx,
                            name: ele,
                            value: false
                        }
                    })
                }
            })            
        }

    },[
        soilTableState.map(state => state.name),
        updateCalculation
    ]);
    useDeepCompareEffect(() => {
        if(closePoints && chartData.layers.length > 0){
            setSoilTableState(() => {
                const qcScale = chartData.scaleX[1]/(chartData.scaleX[0] - chartData.origin[0]);
                const zScale = chartData.scaleY[1]/(chartData.scaleY[0] - chartData.origin[1]);
                const maxLayerValue = Math.max(...chartData.layers.map(ele => ele[0]));
                let sigmSum = 0;
                let lastZ = 0;

                return chartData.points.filter(ele => ele[1] <= maxLayerValue).sort((a,b) => a[1]-b[1]).map((ele,idx) => {
                    const soilIndex = chartData.layers.findIndex((element) => {
                        return ele[1] < element[0]
                    });
                    const soil = chartData.layers[soilIndex];
                    const currentCptLevel = isNumber(cptLevel.value) ? cptLevel.value : 0;
                    const z = roundNumber(((ele[1] - chartData.origin[1])*zScale) + currentCptLevel,2);
                    const qc = roundNumber((ele[0] - chartData.origin[0])*qcScale,2);
                    const cohesiveType = (Array.isArray(soil) && soil[2]) ? stringToNumber(Object.keys(cohesiveSoilType).find(key => cohesiveSoilType[key] === soil[2])) : '';

                    const nk = nkInterpolation({ qc, type: cohesiveType });

                    const soilWeight = soil ?
                            soil[1] === 0 
                            ?
                            19
                            :
                            soil[1] === 1
                                ?
                                    cohesiveType === 1
                                    ?
                                    21
                                    :
                                    cohesiveType === 2
                                    ?
                                    20.5
                                    :
                                    cohesiveType === 3
                                    ?
                                    20
                                    :
                                    cohesiveType === 4
                                    ?
                                    19
                                    :
                                    cohesiveType === 5
                                    ?
                                    17
                                    :
                                    20
                                :
                                    ''
                            :
                            '';
                    
                    const soilWeightZwg = isNumber(zwg.value) && zwg.value > 0 
                        ?
                            zwg.value >= z
                            ?
                            soilWeight
                            :
                                (lastZ <= zwg.value && z >= zwg.value)
                                ?
                                (soilWeight*(zwg.value - lastZ) + (soilWeight - 10)*(z-zwg.value))/(z-lastZ)
                                :
                                soilWeight - 10
                        :
                            soilWeight;
                    
                    const sigm = idx === 0 ? soilWeightZwg*z : (z - lastZ)*soilWeightZwg;
                    lastZ = z;
                    sigmSum = sigmSum + sigm

                    const cu = (soilType === 0 || !isNumber(nk)) ? 0 : (qc*1000 - sigmSum)/nk;

                    return {
                        layerNo: {
                            value: idx + 1
                        },
                        nk: {
                            value: nk,
                        },
                        cohesiveType: {
                            value: cohesiveType
                        },
                        name: {
                            value: soilIndex + 1,
                            isValid: true
                        },
                        layerIndex: {
                            value: soilIndex + 1
                        },
                        sigm: {
                            value: sigm
                        },
                        sigmSum: {
                            value: sigmSum
                        },
                        qc: {
                            value: qc,
                            isValid: true
                        },
                        cu: {
                            value: roundNumber(cu,2),
                            isValid: true
                        },
                        z: {
                            value: z,
                            isValid: isNumber(z) ? true : null
                        },
                        y: {
                            value: soilWeight,
                            isValid: isNumber(soilWeight) ? true : null
                        },
                        soilType: {
                            value: chartData.layers.length > 0 ? soil ? roundNumber(soil[1],2) : roundNumber(chartData.layers.slice(-1)[0][1],2) : '',
                            isValid: true
                        },
                        zSoil: {
                            value: chartData.layers.length > 0 
                                ? soil 
                                    ? roundNumber(soil[0],2) 
                                    : roundNumber(chartData.layers.slice(-1)[0][0],2) 
                                : '',
                            isValid: true
                        },
                        cd: {
                            value: false
                        }
                    }
                })
            });
        } else if(chartData.points.length === 0){
            setSoilTableState([{ ...defaultRow }]);
        }
    },[chartData, closePoints]);

    useDeepCompareEffect(() => {
        setSoilTableState((state) => {
            let sigmSum = 0;
            let lastZ = 0;

            return state.map((ele,idx) => {
                const z = ele.z.value;
                const qc = ele.qc.value;
                const cohesiveType = ele.cohesiveType.value;
                const nk = nkInterpolation({ qc, type: cohesiveType });
                const soilWeight = ele.y.value;
                const soilType = ele.soilType.value;

                const soilWeightZwg = isNumber(zwg.value) && zwg.value > 0 
                    ?
                        zwg.value >= z
                        ?
                        soilWeight
                        :
                            (lastZ <= zwg.value && z >= zwg.value)
                            ?
                            (soilWeight*(zwg.value - lastZ) + (soilWeight - 10)*(z-zwg.value))/(z-lastZ)
                            :
                            soilWeight - 10
                    :
                        soilWeight;
                
                const sigm = idx === 0 ? soilWeightZwg*z : (z - lastZ)*soilWeightZwg;
                lastZ = z;
                sigmSum = sigmSum + sigm

                const cu = soilType === 0 ? 0 : (qc*1000 - sigmSum)/nk;
                return {
                    ...ele,
                    layerNo: {
                        value: idx + 1
                    },
                    sigm: {
                        value: sigm
                    },
                    sigmSum: {
                        value: sigmSum
                    },
                    cu: {
                        value: isNumber(cu) ? roundNumber(cu,2) : ele.cu.value,
                        isValid: isNumber(cu) ? true : ele.cu.isValid
                    },
                    z: {
                        value: z,
                        isValid: isNumber(z) ? true : ele.z.isValid
                    },
                    y: {
                        value: soilWeight,
                        isValid: isNumber(soilWeight) ? true : ele.y.isValid
                    },
                }
            })
        })
    },[
        zwg.value, 
        soilTableState.map(ele => ele.z.value),
        soilTableState.map(ele => ele.y.value),
        soilTableState.map(ele => ele.qc.value),
    ]);
    const onBlurSoilTableState = (e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if (input) {
            const { name, type, value } = input;
            const rowNumber = Number(input.getAttribute('data-row'));
            const valid = input.checkValidity();

            setSoilTableState((state) =>
                state.map((element, index) => {
                    if (rowNumber !== index) {
                        return element;
                    } else {
                        return {
                            ...element,
                            [name]: {
                                ...element[name],
                                isValid: type === 'number' ? isNumber(stringToNumber(value)) : valid,
                            }
                        };
                    }
                }),
            );
        }
    };
    const onChangeChartDataValue = (e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if (input) {
            updateResultsState((state) => {
                return { 
                    ...state,
                    isResultsActual: false,
                };
            });
            const { name, value } = input;

            setChartData(state => {
                if(name === 'scaleX'){
                    return {
                        ...state,
                        scaleX: [state.scaleX[0], stringToNumber(value)]
                    }
                } else if(name === 'scaleY'){
                    return {
                        ...state,
                        scaleY: [state.scaleY[0], stringToNumber(value)]
                    }
                } else {
                    return state
                }
            })
        }
    };
    const onChangeSoilState = useCallback((e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if (input) {
            const rowNumber = stringToNumber(input.getAttribute('data-row'));
            const { name, value, type } = input;

            updateResultsState((state) => {
                return { 
                    ...state,
                    isResultsActual: false,
                };
            });

            setSoilTableState((state) =>
                state.map((element, index) => {
                    if (rowNumber !== index) {
                        return element;
                    } else {
                        if(name === 'soilType'){
                            const val = value === 'Niespoisty' ? 0 : value === 'Spoisty' ? 1 : '';

                            if(element.soilType.value === 1 || element.soilType.value === 0){
                                return {
                                    ...element,
                                    qc: {
                                        value: '',
                                        isValid: null,
                                    },
                                    cu: {
                                        value: '',
                                        isValid: null,
                                    },
                                    cohesiveType: {
                                        value: '',
                                    },
                                    nk: {
                                        value: '',
                                    },
                                    [name]: {
                                        value: val,
                                        isValid: isNumber(val) ? true : false,
                                    },
                                };                                
                            } else {
                                return {
                                    ...element,
                                    [name]: {
                                        value: val,
                                        isValid: isNumber(val) ? true : false,
                                    },
                                };
                            }
                        } else {
                            return {
                                ...element,

                                [name]: {
                                    value: type === 'number' ? makeNumberPositive(stringToNumber(value)) : value,
                                    isValid: true,
                                },
                            };
                        }
                    }
                }),
            );
        }
    }, []);
    const onChangeCptChart = (e) => { 
        const file = e.target.files ? e.target.files[0] : null;

        if(file) {
            if (!mimeTypes.includes(file.type)) {
                setAlert({
                    error: true,
                    message: `Nieprawidłowy format pliku. Wybierz plik o formacie graficznym`,
                });
            } else {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    setLogoImg(reader.result);
                };
            }
        } else {
            setLogoImg('');
            setAlert({ error: true, message: `Błąd podczas dodawania pliku` });
        }
    };
    const addRowToSoilTable = () => {
        updateResultsState((state) => {
            return { 
                ...state,
                isResultsActual: false,
            };
        });
        setSoilTableState((state) => {
            return [
                ...state,
                {
                    ...defaultRow,
                    cd: {
                        value: true
                    },
                    layerNo: {
                        value: state.length + 1,
                    },
                },
            ];
        });
    }
    const addRowToStartSoilTable = () => {
        updateResultsState((state) => {
            return { 
                ...state,
                isResultsActual: false,
            };
        });
        setSoilTableState((state) => {
            return [
                {
                    ...defaultRow,
                    cd: {
                        value: true
                    },
                    layerNo: {
                        value: 1,
                    },
                },                
                ...state,
            ]
            .map((ele, idx) => ({
                ...ele,
                layerNo: {
                    value: idx + 1,
                },
            }))
        });        
    }
    const removeRowFromSoilTable = (i) => {
        updateResultsState((state) => {
            return { 
                ...state,
                isResultsActual: false,
            };
        });
        setSoilTableState((state) => {
            return [
                ...state
                    .filter((_, index) => index !== i)
                    .map((ele, idx) => ({
                        ...ele,
                        layerNo: {
                            value: idx + 1,
                        },
                    })),
            ];
        });
    }
    const onLoadSoilProfile = (soilProfile = []) => {
        const loadedProfile = soilProfile.reduce((acc, ele, idx) => {
            const entries = Object.fromEntries(
                Object.entries(ele).filter((ele) =>
                    Object.keys(defaultRow).includes(ele[0]),
                ),
            );

            return [
                ...acc,
                {
                    ...defaultRow,
                    ...entries,
                    layerNo: {
                        value: idx + 1,
                    },
                },
            ];
        }, []);
        updateResultsState((state) => {
            return { 
                ...state,
                isResultsActual: false,
            };
        });
        setSoilTableState(loadedProfile);
    };
    const onClearFile = () => {
        
        setLogoImg('')
    };
    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    }, []);
    const onChangeScale = (e) => {
        setScale((state) => isNumber(stringToNumber(e.target.value)) ? Math.abs(e.target.value) : state);

        setChartData(() => {
            return {
                origin: [],
                scaleX: [],
                scaleY: [],
                points: [],
                layers: []
            }
        });
        setImgDimensions({
            width: 0,
            height: 0
        })
    }
    const loadProject = (soilsArr) => {
        setSoilTableState(() => {
            return soilsArr.map((state) => {
                if(!state.cd){
                    return {
                        ...defaultRow, 
                        ...state,
                        cd: {
                            value: false
                        }
                    }
                } else {
                    return {
                        ...defaultRow, 
                        ...state,
                    }
                }
            });
        });
    };
    const onAxisCoordinate = (e) => {
        if([...e.target.classList].includes('chartView')){
            const rect = e.currentTarget.getBoundingClientRect();

            setImgDimensions({
                width: e.currentTarget.clientWidth,
                height: e.currentTarget.clientHeight
            });
            setChartData(state => {
                if(state.origin.length === 0){
                    return {
                        ...state,
                        origin: [e.clientX + e.target.scrollLeft - rect.left, e.clientY + e.target.scrollTop - rect.top],
                    }
                } else if(
                    state.origin.length === 2 && 
                    (state.scaleX.length === 0 || !state.scaleX.every(ele => isNumber(ele)))
                ){
                    return {
                        ...state,
                        scaleX: [e.clientX + e.target.scrollLeft - rect.left, state.scaleX[1]]
                    }
                } else if(
                    state.origin.length === 2 && state.scaleX.length === 2 && state.scaleX.every(ele => isNumber(ele)) &&
                    
                    (state.scaleY.length === 0 || !state.scaleY.every(ele => isNumber(ele)))
                ){
                    return {
                        ...state,
                        scaleY: [e.clientY + e.target.scrollTop - rect.top, state.scaleY[1]]
                    }                
                } else if(
                    state.origin.length === 2 && state.scaleX.length === 2 && state.scaleX.every(ele => isNumber(ele)) && state.scaleY.length === 2 && state.scaleY.every(ele => isNumber(ele)) && !closePoints
                ){
                    return {
                        ...state,
                        points: [
                            ...state.points,
                            [
                                e.clientX + e.target.scrollLeft - rect.left,
                                e.clientY + e.target.scrollTop - rect.top,
                            ]
                        ]
                    }  
                } else if(
                    state.origin.length === 2 && state.scaleX.length === 2 && state.scaleX.every(ele => isNumber(ele)) && state.scaleY.length === 2 && state.scaleY.every(ele => isNumber(ele)) && closePoints
                ) {
                    return {
                        ...state,
                        layers: [
                            ...state.layers,
                            [
                                e.clientY + e.target.scrollTop - rect.top,
                                0
                            ]
                        ] 
                    }  
                } else {
                    return state
                }
            })            
        }

    }
    const soilTable = {
        onChange: onChangeSoilState,
        onChangeCptChart: onChangeCptChart,
        onChangeScale: onChangeScale,
        onChangeChartDataValue: onChangeChartDataValue,
        onAxisCoordinate: onAxisCoordinate,
        onClearFile: onClearFile,
        onBlur: onBlurSoilTableState,
        onKeyDown: onKeyDown,
        addRow: addRowToSoilTable,
        addRowStart: addRowToStartSoilTable,
        removeRow: removeRowFromSoilTable,
        updateState: setSoilTableState,
        loadProject: loadProject,
        onLoad: onLoadSoilProfile,
        setChartData: setChartData, 
        setImgDimensions: setImgDimensions,
        onClosePoints: () => setClosePoints(true),
        onContinuePoints: () => setClosePoints(false),
        initState: () => {
            setSoilTableState([{ ...defaultRow }]);
            setChartData(defaultChartDefault);
            setLogoImg('');
        },

        state: soilTableState,
        stateToSave: soilTableState.map(ele => {
            const { nk, cohesiveType, sigm, sigmSum, ...rest } = ele;
            return {
                ...rest
            }
        }),
        cohesiveSoilType: cohesiveSoilType,
        cptChart: logoImg,
        scale: scale,
        chartData: chartData,
        imgDimensions: imgDimensions,
        closePoints: closePoints
    };

    return {
        soilTable,
    };
};

export default SoilTableHelpers;
