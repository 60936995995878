import { Outlet, useOutletContext } from "react-router-dom";
import CptPileProvider from "../../../context/CptPile";
import { CptPileNavigation } from "../../../pages";

const CptPileLayout = () => {
    const context = useOutletContext();

    return (
        <CptPileProvider>
            <CptPileNavigation report={context.report}/>
            <Outlet context={context}/>
        </CptPileProvider>
    )
}

export default CptPileLayout;