import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    InputField,
    SectionTitle,
    Checkbox,
    LabelName,
} from '../../../../components';
import { stringToNumber } from '../../../../utils';
import styles from './index.module.css';

const CalculationSettings = ({ state, onChange, onBlur, onKeyDown }) => {
    return (
        <Fragment>
            <SectionTitle title="Parametry profilu gruntowego" />
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        onChange={onChange}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        value={state.groundLevel.value}
                        isValid={state.groundLevel.isValid}
                        type="number"
                        title="Rzędna terenu [m.n.p.m]"
                        placeholder={'Wpisz rzędną terenu'}
                        name="groundLevel"
                        min={-10911}
                        max={8850}
                        step={0.001}
                        required
                    />
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <InputField
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        onBlur={onBlur}
                        value={state.waterLevel.value}
                        isValid={state.waterLevel.isValid}
                        type="number"
                        title={'Poziom ZWG [m.p.p.t]'}
                        placeholder={'Wpisz poziom ZWG'}
                        name="waterLevel"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <Checkbox
                        onChange={onChange}
                        title="Świeży nasyp gruntowy"
                        name="newEmbankment"
                        label={`${state.newEmbankment.value ? '' : 'Brak'}`}
                        value={state.newEmbankment.value}
                    />
                </Col>
                <Col xs={12} sm={6} className={styles.columnSpace}>
                    <Checkbox
                        onChange={onChange}
                        title="Tarcie negatywne"
                        name="negativeFriction"
                        label={`${state.negativeFriction.value ? '' : 'Brak'}`}
                        value={state.negativeFriction.value}
                    />
                </Col>
            </Row>
            <Row>
                {state.newEmbankment.value && (
                    <Col xs={12} sm={6} className={styles.columnSpace}>
                        <InputField
                            name="embankmentsNumber"
                            type="number"
                            value={state.embankmentsNumber.value}
                            isValid={state.embankmentsNumber.isValid}
                            title="Liczba warstw stanowiących nasyp"
                            onChange={onChange}
                            onBlur={onBlur}
                            placeholder="Liczba warstw"
                            min={0}
                            required
                        />
                    </Col>
                )} 
                {state.negativeFriction.value ? (
                    Object.values(state.layersName).filter((ele) => ele.isValid)
                        .length > 0 ? (
                        <Col xs={12} sm={6} className={styles.columnSpace}>
                            <Fragment>
                                <LabelName title="Warstwy z tarciem negatywnym" />
                                {Object.entries(state.layersName)
                                    .filter(([key, val]) => val.isValid)
                                    .map(([key, val]) => {
                                        return (
                                            <div
                                                className={styles.fullWidth}
                                                key={key}>
                                                <Checkbox
                                                    onChange={onChange}
                                                    data-layer={key}
                                                    name="layersName"
                                                    label={`${
                                                        val.name
                                                    } (${stringToNumber(key)})`}
                                                    value={val.value}
                                                />
                                            </div>
                                        );
                                    })}
                            </Fragment>
                        </Col>
                    ) : (
                        <Col
                            xs={12}
                            sm={6}
                            className={`${styles.wrongSoilLayers} ${styles.columnSpace}`}>
                            <FontAwesomeIcon icon={faBan} />
                            <p className={styles.p}>
                                Uzupełnij parametry gruntu
                            </p>
                        </Col>
                    )
                ) : (
                    <Fragment></Fragment>
                )}
            </Row>
        </Fragment>
    );
};

export default CalculationSettings;
