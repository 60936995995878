import { isNumber } from "../../../utils";
import { AuthFetch } from "../../../routes";
import { concretePiles, steelPiles } from "../../../config/ProjectConfig";

const SubmitCalculationHelpers = ({ soilTable, pileProperties, calculationSettings, foundationParameters, saftyFactors, pileLoads, results, setAlert, setLoading }) => {

    const onSubmitCalculation = () => {
        const { technologyFactors, ...restFactors } = saftyFactors.state;

        const soilTableToSend = soilTable.state.map(element => {
            const { genesis, soilName, density, plasticity, absHeight, cohesion, ...rest } = element;
            const cohesiveSoil = isNumber(plasticity.value) && !isNumber(density.value);
            const noncohesiveSoil = isNumber(density.value) && !isNumber(plasticity.value);

            const organicSoil = element.soilType.value.toLowerCase() === 'organiczny'

            return { 
                ...rest, 
                ...cohesiveSoil && { 
                    cohesion, 
                    plasticity,
                    ...!organicSoil && {
                        genesis, 
                    }
                }, 
                ...noncohesiveSoil && { 
                    density 
                }, 
                ...(!cohesiveSoil && !noncohesiveSoil) && { 
                    density, 
                    plasticity, 
                    cohesion, 
                    genesis 
                } 
            }
        });
        const isValidPileProperties = Object.entries(pileProperties.state).filter((ele,_,arr) => {
            const pileTypeName = arr.find(ele => ele[0] === 'pileType');

            if(Object.values(steelPiles).includes(pileTypeName[1].value)){
                return ele[0] !== 'concreteClass' && ele[0] !== 'concreteFck'
            } else if(Object.values(concretePiles).includes(pileTypeName[1].value)){
                return ele[0] !== 'thickness'
            } else {
                return ele
            }
        }).every(ele => ele[1].isValid);

        const technologyFactorsToSend = technologyFactors.map(({density, plasticity, cohesion, ...rest}) => {
            const cohesiveSoil = isNumber(plasticity) && !isNumber(density);
            const noncohesiveSoil = isNumber(density) && !isNumber(plasticity);

            return { ...rest, ...cohesiveSoil && { plasticity, cohesion }, ...noncohesiveSoil && { density } }
        });

        const foundationParametersToSend = Object.fromEntries(Object.entries(foundationParameters.state)
            .filter((ele,_,arr) => {
                const columns = arr.find(ele => ele[0] === 'columns');
                
                return columns[1].value !== 1 ? ele : ele[0] !== 'perpendicularSpace'
            })
            .filter((ele,_,arr) => {
                const rows = arr.find(ele => ele[0] === 'rows');

                return rows[1].value !== 1 ? ele : ele[0] !== 'parallelSpace'
            })
        );

        const isValidPileLoads = Object.values(pileLoads.state).every(ele => ele.isValid);
        const isValidSoilState = soilTableToSend.every(ele => Object.values(ele).every(ele => ele.isValid));
        const isValidCalculationSettingsState = Object.values(calculationSettings.state).every(ele => ele.isValid);
        const isValidFoundationParameters = Object.values(foundationParametersToSend).every(ele => ele.isValid);
        const isValidRestFactorsState = Object.values(restFactors).every(ele => ele.isValid);
        const isValidTechnologyFactorsState = technologyFactorsToSend.every(element => Object.values(element).every(ele => ele.hasOwnProperty('isValid') ? ele.isValid : (isNumber(ele) || ele.length > 0)));


        if(isValidPileLoads && isValidSoilState && isValidPileProperties && isValidCalculationSettingsState && isValidFoundationParameters && isValidRestFactorsState && isValidTechnologyFactorsState){
            setLoading({ name: 'calculations', loading: true });
            AuthFetch({
                url: '/api/kosecki-method/submit',
                method: 'POST',
                body: {
                    foundationParameters: Object.entries(foundationParametersToSend).reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }),{}),
                    pileLoads: Object.entries(pileLoads.state).reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }),{}),
                    calculationSettings: Object.entries(calculationSettings.state).reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }),{}),
                    pileParameters: Object.entries(pileProperties.state).filter((ele,_,arr) => {
                        const pileTypeName = arr.find(ele => ele[0] === 'pileType');
            
                        if(Object.values(steelPiles).includes(pileTypeName[1].value)){
                            return ele[0] !== 'concreteClass' && ele[0] !== 'concreteFck'
                        } else if(Object.values(concretePiles).includes(pileTypeName[1].value)){
                            return ele[0] !== 'thickness'
                        } else {
                            return ele
                        }
                    }).reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }),{}),
                    saftyFactors: Object.entries(restFactors).reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }),{}),
                    technologyFactors: technologyFactorsToSend.map(ele => Object.entries(ele).reduce((acc, [key, value]) => {
                        return {
                            ...acc,
                            [key]: value.hasOwnProperty('value') ? value.value : value
                        }
                    },{}))
                }
            }).then(response => {
                setLoading({ name: 'calculations', loading: false }); 
                if(response.success){
                    results.updateState({
                        isResultsActual: true,
                        ...response.responseData
                    })
                } else {
                    if(response.status === 400){
                        setAlert({ error: true, message: 'Niepoprawne wymiary pala!' });
                        results.updateState({
                            isResultsActual: false,
                        })
                    } else {
                        setAlert({ error: true, message: 'Błąd podczas wykonywania obliczeń' });
                    }
                    
                }
            })


        } else {
            const invalidSoilState = soilTableToSend.map(ele => Object.entries(ele).reduce( (acc, [key, value]) => ({ ...acc, [key]: { value: value.value, isValid: !!value.isValid } }),{}));

            !isValidSoilState && setAlert({ error: true, message: 'Nieprawidłowe parametry podłoża gruntowego' });
            !isValidPileProperties && setAlert({ error: true, message: 'Nieprawidłowe parametry pala' });
            !isValidCalculationSettingsState && setAlert({ error: true, message: 'Nieprawidłowe parametry profilu gruntowego' });
            !isValidFoundationParameters && setAlert({ error: true, message: 'Nieprawidłowe parametry fundamentu' });
            !isValidPileLoads && setAlert({ error: true, message: 'Nieprawidłowe obciążenie fundamentu' });
            (!isValidRestFactorsState || !isValidTechnologyFactorsState) && setAlert({ error: true, message: 'Nieprawidłowe współczynniki bezpieczeństwa' });

            soilTable.updateState(state => state.map((element, index) => {
                return {
                    ...element,
                    ...invalidSoilState[index]
                }
            }));
            pileLoads.updateState(state => {
                return {
                    ...state,
                    ...Object.entries(pileLoads.state).reduce((acc, [key, value]) => ({ ...acc, [key]: { value: value.value, isValid: !!value.isValid } }) ,{})
                }
            });
            pileProperties.updateState(state => {
                return {
                    ...state,
                    ...Object.entries(pileProperties.state).reduce((acc, [key, value]) => ({ ...acc, [key]: { value: value.value, isValid: !!value.isValid } }) ,{})
                }
            });
            calculationSettings.updateState(state => {
                return {
                    ...state,
                    ...Object.entries(calculationSettings.state).reduce((acc, [key, value]) => ({ ...acc, [key]: { value: value.value, isValid: !!value.isValid } }) ,{})
                }
            });
            foundationParameters.updateState(state => {
                return {
                    ...state,
                    ...Object.entries(foundationParametersToSend).reduce((acc, [key, value]) => ({ ...acc, [key]: { value: value.value, isValid: !!value.isValid } }) ,{})
                }
            });
            saftyFactors.updateState(state => {
                return {
                    ...state,
                    ...Object.entries(restFactors).reduce( (acc, [key, value]) => ({ ...acc, [key]: { value: value.value, isValid: !!value.isValid } }) ,{}),
                    technologyFactors: technologyFactorsToSend.map((element, index) => {
                        return Object.entries(element).reduce((acc, [key, value]) => ({ ...acc, [key]: value.hasOwnProperty('isValid') ? { value: value.value, isValid: !!value.isValid } : value }),{})
                    }) 
                }
            })
        }
    }

    const submitCalculation = {
        onSubmit: onSubmitCalculation
    }
    return {
        submitCalculation
    }
}

export default SubmitCalculationHelpers