import { useState } from 'react';

const capacityChartTypes = [
    {
        type: 0,
        name: 'Długość buławy',
    },
    {
        type: 1,
        name: 'Długość całkowita mikropala',
    },
    {
        type: 2,
        name: 'Nośność mikropala',
    },
    {
        type: 3,
        name: 'Nośność żerdzi',
    },
    {
        type: 4,
        name: 'Długość buławy - wym. na nośność żerdzi',
    },
    {
        type: 5,
        name: 'Długość całk. mikropala - wym. na nośność żerdzi',
    },
];
const MicropilePullCapacityService = () => {
    const [settings, setSettings] = useState({
        showVertical: true,
        showHorizontal: true,
        showLegend: true,
        showValues: false,
        showDescription: true,

        showValue1: true,
        showValue: true,
        rotateDescriptionX: false,
    });
    const [capacityType, setCapacityType] = useState(0);
    const [capacityName, setCapacityName] = useState('Długość buławy');
    const [chartNames, setChartNames] = useState({
        0: {
            title: 'Długość części nośnej mikropla (buławy)',
            axisY: 'Długość buławy [m]',
            axisX: 'Profile geologiczne',
            legend: '- długość części nośnej (buławy) - klin odłamu',
            legend1:
                '- długość części nośnej (buławy) - gł. powierzchnia poślizgu',
        },
        1: {
            title: 'Długość całkowita mikropala',
            axisY: 'Długość mikropla [m]',
            axisX: 'Profile geologiczne',
            legend: '- długość całkowita mikropala - klin odłamu',
            legend1:
                '- długość całkowita mikropala - gł. powierzchnia poślizgu',
        },
        2: {
            title: 'Nośność obliczeniowa mikropala',
            axisY: 'Nośność mikropala [kN]',
            axisX: 'Profile geologiczne',
            legend: '- nośność mikropala',
        },
        3: {
            title: 'Nośność obliczeniowa żerdzi',
            axisY: 'Nośność żerdzi [kN]',
            axisX: 'Profile geologiczne',
            legend: '- nośność żerdzi',
        },
        4: {
            title: 'Długość buławy wymiarowanej na nośność żerdzi',
            axisY: 'Długość buławy [m]',
            axisX: 'Profile geologiczne',
            legend: '- długość części nośnej (buławy) - klin odłamu',
            legend1:
                '- długość części nośnej (buławy) - gł. powierzchnia poślizgu',
        },
        5: {
            title: 'Długość całkowita mikropala wymiarowanego na nośność żerdzi',
            axisY: 'Długość mikropala [m]',
            axisX: 'Profile geologiczne',
            legend: '- długość całkowita mikropala - klin odłamu',
            legend1:
                '- długość całkowita mikropala - gł. powierzchnia poślizgu',
        },
    });

    const onChangeChartTitles = (e = {}, type) => {
        if (e.target) {
            const { name, value } = e.target;

            setChartNames((state) => ({
                ...state,
                [type]: {
                    ...state[type],
                    [name]: value,
                },
            }));
        }
    };

    const onToggleChartType = (e) => {
        const currentSelectProject = capacityChartTypes.find(
            (ele) => ele.name === e.target.value,
        );

        if (currentSelectProject) {
            if ([2, 3].includes(currentSelectProject.type)) {
                setSettings((state) => ({ ...state, showValue: true }));
            }
            setCapacityType(currentSelectProject.type);
            setCapacityName(currentSelectProject.name);
        }
    };

    const onChangeSettings = (e) => {
        e.preventDefault();
        e.persist();

        const name = e.currentTarget.getAttribute('name');

        setSettings((state) => ({ ...state, [name]: !state[name] }));
    };

    const micropilePullCapacity = {
        state: {
            chartNames,
            capacityType,
            capacityName,
            capacityChartTypes,

            settings,
        },
        onChangeChartTitles,
        onToggleChartType,
        onChangeSettings,
    };

    return {
        micropilePullCapacity,
    };
};

export default MicropilePullCapacityService;
