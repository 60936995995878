const pileTypes = {
    1: 'Wbijany żelbetowy prefabrykowany',
    2: 'Wbijany z rury stalowej z dnem zamkniętym',
    3: 'Wbijany z rury stalowej z dnem otwartym',
    4: 'Wiercony',
    5: 'Wkręcany',
    6: 'Wbijany z elementów typu H',
    7: 'Wbijany z elementów typu 2xH',  
};

export default pileTypes