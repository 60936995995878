import React from 'react';
import styles from './index.module.css';
import DetailTable from './DetailTable';

const MicropileCapacityTable = ({
    parameters: { slipPlate = {}, deepPlate = {} },
}) => {
    return (
        <div className={styles.wrapper}>
            <DetailTable
                title="Rozkład nośności mikropala z uwzględnieniem klina odłamu - projektowana siła osiowa"
                results={slipPlate.axisLoadCapacity}
            />

            <DetailTable
                title="Rozkład nośności mikropala z uwzgl. głębokiej powierzchni poślizgu - projektowana siła osiowa"
                results={deepPlate.axisLoadCapacity}
            />
            <DetailTable
                title="Rozkład nośności mikropala z uwzględnieniem klina odłamu - nośność obliczeniowa żerdzi"
                results={slipPlate.steelRodCapacity}
            />

            <DetailTable
                title="Rozkład nośności mikropala z uwzgl. głębokiej powierzchni poślizgu - nośność obl. żerdzi"
                results={deepPlate.steelRodCapacity}
            />

            <p className={styles.underline}>Oznaczenia:</p>
            <p>
                Z -{' '}
                <span className={styles.value}>
                    rzędna spągu warstwy gruntu
                </span>
            </p>
            <p>
                H -{' '}
                <span className={styles.value}>wysokość warstwy gruntu</span>
            </p>
            <p>
                q<sub>s,k</sub> -{' '}
                <span className={styles.value}>
                    graniczny opór gruntu na pobocznicy mikropala
                </span>
            </p>
            <p>
                d -{' '}
                <span className={styles.value}>
                    średnica koronki wiertniczej
                </span>
            </p>
            <p>
                a -{' '}
                <span className={styles.value}>
                    współczynnik poszerzenia średnicy na podstawie stanu i
                    rodzaju gruntu
                </span>
            </p>
            <p>
                a<sub>cpt</sub> -{' '}
                <span className={styles.value}>
                    wartość poszerzenia średnicy na podstawie parametrów q
                    <sub>c</sub> lub c<sub>u</sub>
                </span>
            </p>
            <p>
                &#947;<sub>s,t</sub> -{' '}
                <span className={styles.value}>
                    współczynnik częściowy do oporu gruntu przy wyciąganiu
                </span>
            </p>
            <p>
                &#958; -{' '}
                <span className={styles.value}>
                    współczynnik korelacyjny do nośności charakterystycznej na
                    podstawie badań podłoża
                </span>
            </p>
            <p>
                &#951;<sub>M</sub> -
                <span className={styles.value}>współczynnik modelu</span>{' '}
            </p>
            <p>
                L<sub>b</sub> -{' '}
                <span className={styles.value}>
                    długość buławy w rozpatrywanej warstwie
                </span>
            </p>
            <p>
                R<sub>t,d</sub> -{' '}
                <span className={styles.value}>
                    nośność mikropala w rozpatrywanej warstwie
                </span>
            </p>
        </div>
    );
};

export default MicropileCapacityTable;
