import styles from './index.module.css';

const PileParametersSection = ({ parameters = {} }) => {
    return (
        <div className={styles.wrapper}>
            <h2>Parametry pala</h2>
            <p>
                Rodzaj pala:{' '}
                <span className={styles.value}>
                    {parameters.pileType.value}
                </span>
            </p>
            <p>
                Technologia wykonania:{' '}
                <span className={styles.value}>
                    {parameters.pileTechnology.value}
                </span>
            </p>
            <p>
                Przekrój pala:{' '}
                <span className={styles.value}>
                    {parameters.pileShape.value}
                </span>
            </p>
            <p>
                Średnica/bok pala:{' '}
                <span className={styles.value}>
                    {parameters.diameterPile.value.toFixed(2)} m
                </span>
            </p>
            <p>
                Długość pala:{' '}
                <span className={styles.value}>
                    {parameters.pileLength.value.toFixed(2)}m
                </span>
            </p>
            <p>
                Rzędna głowicy pala:{' '}
                <span className={styles.value}>
                    {parameters.pileHeadSpot.value.toFixed(2)} m.p.p.t.
                </span>
            </p>
            <p>
                Klasa betonu:{' '}
                <span className={styles.value}>
                    {parameters.concreteClass.value}
                </span>
            </p>
            <p>
                Konstrukcja tymczasowa:{' '}
                <span className={styles.value}>
                    {parameters.temporaryContruction.value ? 'Tak' : 'Ne'}
                </span>
            </p>
            <p>
                Pale w układzie kozłowym:{' '}
                <span className={styles.value}>
                    {parameters.trestles.value ? 'Tak' : 'Nie'}
                </span>
            </p>
        </div>
    );
};

export default PileParametersSection;
