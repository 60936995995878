import { useCallback, useState } from 'react';
import { isEmail, plans, roles } from '../../../config/ProjectConfig';
import { AuthFetch } from '../../../routes';
import {
    stringToNumber,
    isNumber,
    isUserRole,
    planCost,
    isAdminRole,
} from '../../../utils';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useIsMountedState } from '../../../hooks';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_ID);

const defaultRow = {
    rowNumber: {
        value: 1,
    },
    firstName: {
        value: '',
        isValid: null,
    },
    lastName: {
        value: '',
        isValid: null,
    },
    email: {
        value: '',
        isValid: null,
    },
    price: {
        value: '',
        isValid: null,
    },
    type: {
        value: '',
        isValid: null,
    },
};
const subscription = {
    1: 'Subskrypcja miesięczna',
    2: 'Subskrypcja roczna',
};
const getKeyByValue = (object, value) =>
    Object.keys(object).find((key) => object[key] === value) || null;

const NewUsersHelpers = ({
    setAlert,
    setLoading,
    updateInvoiceState,
    stateChanged,
    fetch,
    role,

    invoiceState,
    isSaveFormData,
    invoiceType,
}) => {
    const [newUsersState, setNewUsersState] = useState([{ ...defaultRow }]);
    const [currentUsersState, setCurrentUsersState] = useState([]);
    const [summaryCost, setSummaryCost] = useState(0);
    const [currentUsersNumber, setCurrentUsersNumber] = useState(0);
    const [deleteCheckbox, setDeleteCheckbox] = useState(false);
    const [editUser, setEditUser] = useState({
        id: {
            value: '',
            isValid: null,
        },
        firstName: {
            value: '',
            isValid: null,
        },
        lastName: {
            value: '',
            isValid: null,
        },
        email: {
            value: '',
            isValid: null,
        },
    });
    const [arrayOfIds, setArrayOfIds] = useState([]);
    const [loadUsers, setLoadUsers] = useState(false);
    const [usersToRenewSubscription, setUsersToRenewSubscription] = useState(
        [],
    );
    const isMountedState = useIsMountedState();

    const newUsersStatePrice = newUsersState.map((ele) => ele.price)

    useDeepCompareEffect(() => {
        setUsersToRenewSubscription(
            currentUsersState
                .filter((ele) => arrayOfIds.includes(ele.id))
                .sort(
                    (a, b) =>
                        new Date(a.subscription.endDate) -
                        new Date(b.subscription.endDate),
                )
                .map((ele) => {
                    return {
                        ...ele,
                        subscription: {
                            ...ele.subscription,
                            plan:
                                ele.subscription.plan === 2
                                    ? subscription[ele.subscription.plan]
                                    : subscription[1],
                            price: planCost({
                                plan: ele.subscription.plan,
                                role: roles[0],
                                plans: plans,
                            }),
                        },
                    };
                }),
        );
    }, [arrayOfIds, currentUsersState]);

    useDeepCompareEffect(() => {
        if(isMountedState.current){
            if (isUserRole(role) || isAdminRole(role)) {
                AuthFetch({
                    url: fetch.getSubscribers.url,
                    method: fetch.getSubscribers.method,
                })
                    .then((response) => {
                        if(isMountedState.current){
                            setLoadUsers(false);
                            if (response.success) {
                                setCurrentUsersNumber(
                                    response.responseData.subUsers.filter(
                                        (ele) =>
                                            moment(ele.subscription.endDate).diff(
                                                moment(),
                                                'seconds',
                                            ) > 0,
                                    ).length,
                                );
                                setCurrentUsersState(
                                    response.responseData.subUsers.sort(
                                        (a, b) =>
                                            new Date(a.subscription.endDate) -
                                            new Date(b.subscription.endDate),
                                    ),
                                );
                            } else {
                                setAlert({
                                    error: true,
                                    message:
                                        'Błąd podczas wczytywania aktualnych subskrypcji',
                                });
                            }
                        }

                    })
                    .catch(() => {
                        if(isMountedState.current){
                            setLoadUsers(false);
                            setAlert({
                                error: true,
                                message:
                                    'Błąd podczas wczytywania aktualnych subskrypcji',
                            });
                        }
                    });
            }            
        }

    }, [
        loadUsers,
        stateChanged,
        fetch.getSubscribers.url,
        fetch.getSubscribers.method,
        role,
        setAlert,
        isMountedState
    ]);

    useDeepCompareEffect(() => {
        setNewUsersState((state) =>
            state.map((ele) => {
                const price = planCost({
                    plan: ele.type.value,
                    role: roles[0],
                    plans: plans,
                });

                return {
                    ...ele,
                    price: {
                        value: price,
                        isValid: isNumber(price) ? true : false,
                    },
                };
            }),
        );
    }, [newUsersState]);

    useDeepCompareEffect(() => {
        setSummaryCost(() => {
            const usersLength = newUsersState.length + currentUsersNumber;
            if (usersLength > 0) {
                return newUsersState.reduce((acc, ele) => {
                    if (ele.type.value === 'Subskrypcja roczna') {
                        return acc + plans.year.second;
                    } else if (ele.type.value === 'Subskrypcja miesięczna') {
                        return acc + plans.month.second;
                    } else {
                        return acc + 0;
                    }
                }, 0);
            } else {
                return 0;
            }
        });
    }, [
        newUsersState,
        newUsersStatePrice,
        currentUsersNumber,
    ]);

    const onChangeIdsCheckbox = (id) => {
        if (arrayOfIds.includes(id)) {
            setArrayOfIds((state) => state.filter((ele) => ele !== id));
        } else {
            setDeleteCheckbox(false);
            setArrayOfIds((state) => [...state, id]);
        }
    };

    const onFindEditUser = (id) => {
        const userById = currentUsersState.filter((ele) => ele.id === id)[0];

        if (userById) {
            setEditUser({
                id: {
                    value: userById.id,
                    isValid: true,
                },
                firstName: {
                    value: userById.firstName,
                    isValid: true,
                },
                lastName: {
                    value: userById.lastName,
                    isValid: true,
                },
                email: {
                    value: userById.email,
                    isValid: true,
                },
            });
        }
    };
    const onChangeEditUser = useCallback((e, isValid) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if (input) {
            const { name, value } = input;
            const valid = input.checkValidity();

            setEditUser((state) => {
                return {
                    ...state,
                    [name]: {
                        value: value,
                        isValid:
                            isValid === undefined ? valid : valid && isValid,
                    },
                };
            });
        }
    }, []);

    const onBlurNewUsersState = useCallback((e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if (input) {
            const { name, value } = input;
            const dataRow = Number(input.getAttribute('data-row'));
            const valid = input.checkValidity();

            setNewUsersState((state) =>
                state.map((element, index) => {
                    if (dataRow !== index) {
                        return element;
                    } else {
                        return {
                            ...element,
                            [name]: {
                                ...element[name],
                                isValid: valid,
                            },
                            ...(name === 'email' && {
                                email: {
                                    ...element[name],
                                    isValid: isEmail.test(value) && valid,
                                },
                            }),
                        };
                    }
                }),
            );
        }
    }, []);

    const onChangeNewUsersState = useCallback((e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if (input) {
            const dataRow = stringToNumber(input.getAttribute('data-row'));
            const { name, value } = input;

            setNewUsersState((state) =>
                state.map((element, index, arr) => {
                    if (dataRow !== index) {
                        return element;
                    } else {
                        return {
                            ...element,
                            [name]: {
                                value: value,
                                isValid: true,
                            },
                        };
                    }
                }),
            );
        }
    }, []);

    const addRowToNewUsersTable = () =>
        setNewUsersState((state) => {
            return [
                ...state,
                {
                    ...defaultRow,
                    rowNumber: {
                        value: state.length + 1,
                    },
                },
            ];
        });
    const removeRowFromNewUsersTable = (i) =>
        setNewUsersState((state) => {
            return [
                ...state
                    .filter((_, index) => index !== i)
                    .map((ele, idx) => ({
                        ...ele,
                        rowNumber: {
                            value: idx + 1,
                        },
                    })),
            ];
        });

    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    }, []);

    const loadProject = (arr) => { 
        setNewUsersState(() => {
            return arr.map((state) => ({ ...defaultRow, ...state }));
        });
    };

    const onSubmit = () => {
        const { companyName, nip, firstName, lastName, ...rest } = invoiceState;
        const stateToValidation = newUsersState.map(
            ({ rowNumber, ...rest }) => ({ ...rest }),
        );
        const isValidForm =
            stateToValidation.every((ele) =>
                Object.values(ele).every((ele) => ele.isValid),
            ) &&
            isNumber(summaryCost) &&
            summaryCost > 0;

        const validFormObject =
            invoiceType === 1
                ? { companyName, nip, ...rest }
                : invoiceType === 2
                ? { firstName, lastName, ...rest }
                : {};

        const isFormValidInvoice = Object.values(validFormObject).every(
            (ele) => ele.isValid,
        );
        

        if (isValidForm && isFormValidInvoice) {
            setLoading({ name: 'userSubscription', loading: true });
            AuthFetch({
                url: fetch.subscribeUsers.url,
                method: fetch.subscribeUsers.method,
                body: {
                    subusers: newUsersState
                        .map((ele) =>
                            Object.fromEntries(
                                Object.entries(ele).map((ele) => [
                                    ele[0],
                                    ele[1].value,
                                ]),
                            ),
                        )
                        .map((ele) => ({
                            ...ele,
                            type: getKeyByValue(subscription, ele.type),
                        })),
                    totalPrice: summaryCost,
                    invoiceType,
                    saveFormData: isSaveFormData,
                    ...Object.entries(validFormObject).reduce(
                        (acc, [key, value]) => ({ ...acc, [key]: value.value }),
                        {},
                    ),
                },
            })
            .then(async (response) => {
                setLoading({ name: 'userSubscription', loading: false });
                if (response.success) {
                    const { id: sessionId } = response.responseData;
                    const stripe = await stripePromise;
                    const { error } = await stripe.redirectToCheckout({
                        sessionId,
                    });

                    if (error) {
                        setAlert({
                            error: true,
                            message: 'Błąd podczas wykonywania płatności',
                        });
                    }
                } else {
                    setAlert({
                        error: true,
                        message: 'Błąd podczas wykonywania płatności',
                    });
                }
            })
            .catch(() => {
                setLoading({ name: 'userSubscription', loading: false });
                setAlert({
                    error: true,
                    message: 'Błąd podczas wykonywania płatności',
                });
            })
            .finally(() => {
                setLoading({ name: 'userSubscription', loading: false });
            });
        } else {
            setAlert({
                error: true,
                message: 'Niepoprawne dane do zakupu licencji',
            });
            updateInvoiceState((state) => { 
                return {
                    ...state,
                    ...Object.entries(validFormObject).reduce(
                        (acc, [key, value]) => ({
                            ...acc,
                            [key]: {
                                value: value.value,
                                isValid: !!value.isValid,
                            },
                        }),
                        {},
                    ),
                };
            })
            setNewUsersState((state) => {
                return [
                    ...state.map((state) =>
                        Object.entries(state).reduce(
                            (acc, [key, value]) => ({
                                ...acc,
                                [key]: { 
                                    value: value.value,
                                    isValid: !!value.isValid,
                                },
                            }),
                            {},
                        ),
                    ),
                ];
            });
        }
    };

    const onSaveEditUser = () => {
        const valid = Object.values(editUser).every((ele) => !!ele.isValid);

        if (valid) {
            setLoading({ name: 'editUserSave', loading: true });
            AuthFetch({
                url: fetch.editUser.url,
                method: fetch.editUser.method,
                body: {
                    ...Object.entries(editUser).reduce(
                        (acc, [key, value]) => ({ ...acc, [key]: value.value }),
                        {},
                    ),
                },
            })
                .then((response) => {
                    setLoading({ name: 'editUserSave', loading: false });
                    if (response.success) {
                        setLoadUsers(true);
                        setAlert({
                            error: false,
                            message: 'Zmiany zostały zapisane poprawnie',
                            hideTime: 2000,
                        });
                    } else {
                        setAlert({
                            error: true,
                            message: 'Błąd podczas edycji danych użytkownika',
                        });
                    }
                })
                .catch(() => {
                    setLoading({ name: 'editUserSave', loading: false });
                    setAlert({
                        error: true,
                        message: 'Błąd podczas edycji danych użytkownika',
                    });
                });
        } else {
            setEditUser((state) => ({
                ...state,
                ...Object.entries(state).reduce(
                    (acc, [key, value]) => ({
                        ...acc,
                        [key]: { value: value.value, isValid: !!value.isValid },
                    }),
                    {},
                ),
            }));
            setAlert({
                error: true,
                message: 'Błąd podczas edycji danych użytkownika',
            });
        }
    };

    const onResetUserPassword = () => {
        if (arrayOfIds.length > 0) {
            setLoading({ name: 'resettingPassword', loading: true });
            AuthFetch({
                url: fetch.resetPassword.url,
                method: fetch.resetPassword.method,
                body: {
                    ids: arrayOfIds,
                },
            })
                .then((response) => {
                    setLoading({ name: 'resettingPassword', loading: false });
                    if (response.success) {
                        setLoadUsers(true);
                        setAlert({
                            error: false,
                            message:
                                'Hasła zostały zresetowane. Należy postępować zgodnie z informacjami zawartymi w wiadomości e-mail.',
                            hideTime: 10000,
                        });
                    } else {
                        setAlert({
                            error: true,
                            message:
                                'Błąd podczas resetowania haseł użytkowników',
                        });
                    }
                })
                .catch(() => {
                    setLoading({ name: 'resettingPassword', loading: false });
                    setAlert({
                        error: true,
                        message: 'Błąd podczas resetowania haseł użytkowników',
                    });
                });
        } else {
            setAlert({
                error: true,
                message: 'Nie wybrano żadnego użytkownika',
            });
        }
    };
    const onDeleteUser = () => {
        if (arrayOfIds.length > 0) {
            setLoading({ name: 'deletingUser', loading: true });
            AuthFetch({
                url: fetch.deleteUser.url,
                method: fetch.deleteUser.method,
                body: {
                    deleteCheckbox: deleteCheckbox,
                    ids: arrayOfIds,
                },
            })
                .then((response) => {
                    setLoading({ name: 'deletingUser', loading: false });
                    setArrayOfIds([]);
                    if (response.success) {
                        setLoadUsers(true);
                        setAlert({
                            error: false,
                            message: 'Użytkownicy zostali usunięci',
                            hideTime: 2000,
                        });
                    } else {
                        setAlert({
                            error: true,
                            message: 'Błąd podczas usuwania użytkowników',
                        });
                    }
                })
                .catch(() => {
                    setLoading({ name: 'deletingUser', loading: false });
                    setAlert({
                        error: true,
                        message: 'Błąd podczas usuwania użytkowników',
                    });
                });
        } else {
            setAlert({
                error: true,
                message: 'Nie wybrano żadnego użytkownika',
            });
        }
    };

    const onDeleteCalculations = (e) => {
        const target = e.currentTarget;
        if (target) {
            setDeleteCheckbox((state) => !state);
        }
    };

    const newUsers = {
        onDeleteCalculations: onDeleteCalculations,
        onChange: onChangeNewUsersState,
        onChangeEditUser: onChangeEditUser,
        onFindEditUser: onFindEditUser,
        onChangeIdsCheckbox: onChangeIdsCheckbox,
        onSubmit: onSubmit,
        onSaveEditUser: onSaveEditUser,
        onBlur: onBlurNewUsersState,
        onKeyDown: onKeyDown,
        onResetUserPassword: onResetUserPassword,
        onDeleteUser: onDeleteUser,
        addRow: addRowToNewUsersTable,
        removeRow: removeRowFromNewUsersTable,
        state: {
            usersToRenewSubscription,
            currentUsersNumber: currentUsersNumber,
            newUsers: newUsersState,
            currentUsers: currentUsersState,
            summaryCost: summaryCost,
            editUser: editUser,
            arrayOfIds: arrayOfIds,
            deleteCheckbox: deleteCheckbox,
        },
        updateState: setNewUsersState,
        loadProject: loadProject,
        initState: () => setNewUsersState([{ ...defaultRow }]),
    };

    return {
        newUsers,
    };
};

export default NewUsersHelpers;
