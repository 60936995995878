import { Table } from 'react-bootstrap';
import round from '../../../../../utils/roundNumber';
import styles from './index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import { isNumber } from '../../../../../utils';

const MicropileCapacityPullDetails = ({ parameters = [] }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.margins}>
                <h2>Nośność mikropala na wyciąganie</h2>
            </div>

            <Table className={styles.table}> 
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th>
                            z<br></br>[m.p.p.t.]
                        </th>
                        <th>
                            q<sub>s,k</sub>
                            <br></br>[kPa]
                        </th>
                        <th>
                            d<br></br>[mm]
                        </th>
                        <th>
                            a<br></br>[-]
                        </th>
                        <th>
                            a<sub>cpt</sub>
                            <br></br>[mm]
                        </th>
                        <th>
                            &#947;<sub>s,t</sub>
                            <br></br>[-]
                        </th>
                        <th>
                            &#958;<br></br>[-]
                        </th>
                        <th>
                            &#951;<sub>M</sub>
                            <br></br>[-]
                        </th>
                        <th>
                            R<sub>t,i</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            R<sub>t,d</sub>
                            <br></br>[kN]
                        </th>
                        <th>
                            R<sub>t,lt</sub>
                            <br></br>[kN]
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {parameters.map((element, index) => {
                        const veryfiedNt = element.veryfiedNtw;
                        const axisLoadCorrect = isNumber(veryfiedNt.axisLoad)
                            ? veryfiedNt.axisLoad >= 1
                                ? true
                                : false
                            : false;
                        const correctLength = axisLoadCorrect;

                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{round(element.z, 2)}</td>
                                <td>{round(element.qsk, 2)}</td>
                                <td>{round(element.diameter, 2)}</td>
                                <td>
                                    {element.isCpt
                                        ? '-'
                                        : round(element.enlargeDiameter, 2)}
                                </td>
                                <td>
                                    {element.isCpt
                                        ? round(element.enlargeDiameter, 2)
                                        : '-'}
                                </td>
                                <td>{round(element.yst, 2)}</td>
                                <td>{round(element.ksi, 2)}</td>
                                <td>{round(element.modelFactor, 2)}</td>
                                <td>{round(element.ntwi, 2)}</td>
                                <td>{round(element.ntw, 2)}</td>
                                <td>{round(element.ntw * 1.5, 2)}</td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={
                                            correctLength
                                                ? faCheckCircle
                                                : faTimesCircle
                                        }
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <p className={styles.underline}>Oznaczenia:</p>
            <p>
                Z -{' '}
                <span className={styles.value}>
                    rzędna zagłębienia pala poniżej poziomu terenu
                </span>
            </p>
            <p>
                q<sub>s,k</sub> -{' '}
                <span className={styles.value}>
                    graniczny opór gruntu na pobocznicy mikropala
                </span>
            </p>
            <p>
                d -{' '}
                <span className={styles.value}>
                    średnica koronki wiertniczej mikropala
                </span>
            </p>
            <p>
                a -{' '}
                <span className={styles.value}>
                    współczynnik poszerzenia średnicy mikropala
                </span>
            </p>
            <p>
                a<sub>cpt</sub> -{' '}
                <span className={styles.value}>
                    poszerzenie średnicy mikropala na podstawie badań sondą CPT
                </span>
            </p>
            <p>
                &#947;<sub>s,t</sub> -{' '}
                <span className={styles.value}>
                    częściowy współczynnik bezpieczeństwa dla mikropali
                    wyciąganych
                </span>
            </p>
            <p>
                &#958; -{' '}
                <span className={styles.value}>
                    współczynnik modelu wg. Eurokodu 7 (załącznik krajowy)
                </span>
            </p>
            <p>
                &#951;<sub>M</sub> -{' '}
                <span className={styles.value}>
                    współczynnik modelu dla mikropali wyciąganych
                </span>
            </p>
            <p>
                R<sub>t,i</sub> -{' '}
                <span className={styles.value}>
                    przyrost nośność na pobocznicy mikropala wyciąganego
                </span>
            </p>
            <p>
                R<sub>t,d</sub> -{' '}
                <span className={styles.value}>
                    obliczeniowa nośność mikropala wyciąganego
                </span>
            </p>
            <p>
                R<sub>t,lt</sub> -{' '}
                <span className={styles.value}>
                    siła do wykonania próbnego obciążenia mikropala wyciąganego
                </span>
            </p>
        </div>
    );
};

export default MicropileCapacityPullDetails;
