import { Table } from 'react-bootstrap';
import styles from './index.module.css';

const SoilParametersSection = ({ parameters = [] }) => {
    return (
        <div className={styles.wrapper}>
            <h2>Tabela parametrów gruntu</h2>
            <Table className={styles.table}> 
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th>
                            Nazwa gruntu<br></br>[-]
                        </th>
                        <th>
                            Z<br></br>[m.p.p.t]
                        </th>
                        <th>
                            &#947; <br></br>[kN/m<sup>3</sup>]
                        </th>
                        <th>
                            &#947;' <br></br>[kN/m<sup>3</sup>]
                        </th>
                        <th>
                            c<br></br>[kPa]
                        </th>
                        <th>
                            &#966; <br></br>[&#176;]
                        </th>
                        <th>
                            E<sub>0</sub> <br></br>[MPa]
                        </th>
                        <th>
                            I<sub>d</sub> <br></br>[-]
                        </th>
                        <th>
                            I<sub>L</sub> <br></br>[-]
                        </th>
                        <th>
                            Geneza <br></br>[-]
                        </th>
                        <th>
                            Rodzaj <br></br>[-]
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {parameters.map((element, index) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{element.name.value}</td>
                                <td>{element.height.value}</td>
                                <td>{element.weight.value}</td>
                                <td>{element.underWaterWeight.value}</td>
                                <td>{element.cohesion.value}</td>
                                <td>{element.friction.value}</td>
                                <td>{element.edometricModule.value}</td>
                                <td>{element.density.value || '-'}</td>
                                <td>{element.plasticity.value || '-'}</td>
                                <td>{element.genesis.value || '-'}</td>
                                <td>{element.soilType.value}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <p className={styles.underline}>Oznaczenia:</p>
            <p>
                Z - <span className={styles.value}>rzędna terenu</span>
            </p>
            <p>
                &#947; -{' '}
                <span className={styles.value}>ciężar objętościowy gruntu</span>
            </p>
            <p>
                &#947;' -{' '}
                <span className={styles.value}>
                    ciężar objętościowy gruntu z uwzględnieniem wyporu wody
                </span>
            </p>
            <p>
                c - <span className={styles.value}>spójność gruntu</span>
            </p>
            <p>
                &#966; -{' '}
                <span className={styles.value}>kąt tarcia wewnętrznego</span>
            </p>
            <p>
                E<sub>0</sub> -{' '}
                <span className={styles.value}>
                    moduł odkształcenia pierwotnego gruntu
                </span>
            </p>

            <p>
                I<sub>d</sub> -{' '}
                <span className={styles.value}>
                    stopień zagęszczenia gruntu
                </span>
            </p>
            <p>
                I<sub>L</sub> -{' '}
                <span className={styles.value}>
                    wskaźnik plastyczności gruntu
                </span>
            </p>
        </div>
    );
};

export default SoilParametersSection;
