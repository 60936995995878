import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    Button,
    InputField,
    SectionTitle,
    Table,
    Modal,
    RadioInput,
} from '../../../../components';
import styles from './index.module.css';
import stringify from 'fast-json-stable-stringify';

const SaftyFactors = ({ saftyFactors }) => {
    const [open, setOpen] = useState({});

    const onOpen = (e) => {
        const target = e.currentTarget;
        if (target) {
            setOpen((state) => ({ ...state, [target.name]: true }));
        }
    };
    const onHide = () => setOpen({});

    return (
        <Col xs={12}>
            <SectionTitle title="Współczynniki technologiczne i bezpieczeństwa" />
            <Row className={styles.mainRow}>
                <Col xs={12} md={4} className={styles.center}>
                    <Button
                        onClick={onOpen}
                        icon={faFolderOpen}
                        css={`
                            ${styles.button} default-button-style
                        `}
                        name="materialsCo"
                        altTitle="Współczynniki materiałowe"
                        title="Współczynniki materiałowe"
                    />
                </Col>
                <Col xs={12} md={4} className={styles.center}>
                    <Button
                        onClick={onOpen}
                        icon={faFolderOpen}
                        css={`
                            ${styles.button} default-button-style
                        `}
                        name="koseckiCo"
                        altTitle="Współczynniki metody uogólnionej"
                        title="Współczynniki metody uogólnionej"
                    />
                </Col>
                <Col xs={12} md={4} className={styles.center}>
                    <Button
                        onClick={onOpen}
                        icon={faFolderOpen}
                        css={`
                            ${styles.button} default-button-style
                        `}
                        name="loadsCo"
                        altTitle="Współczynniki obciążeń"
                        title="Współczynniki obciążeń"
                    />
                </Col>
            </Row>

            <Modal
                open={open.materialsCo}
                onHide={onHide}
                title="Współczynniki materiałowe">
                <Row className={styles.row}>
                    <Col xs={12}>
                        <SectionTitle title="Dla parametrów gruntu" />
                    </Col>
                    <Col xs={12} sm={4}>
                        <InputField
                            onChange={saftyFactors.onChange}
                            onBlur={saftyFactors.onBlur}
                            value={saftyFactors.state.frictionAngleSafe.value}
                            isValid={
                                saftyFactors.state.frictionAngleSafe.isValid
                            }
                            max={1}
                            type="number"
                            title="Kąt tarcia gruntu"
                            placeholder="Dla kąta tarcia"
                            name="frictionAngleSafe"
                            disable={true}
                            required
                        />
                    </Col>
                    <Col xs={12} sm={4}>
                        <InputField
                            onChange={saftyFactors.onChange}
                            onBlur={saftyFactors.onBlur}
                            value={saftyFactors.state.cohesionSafe.value}
                            isValid={saftyFactors.state.cohesionSafe.isValid}
                            max={1}
                            type="number"
                            title="Spójność gruntu"
                            placeholder="Dla spójności"
                            name="cohesionSafe"
                            disable={true}
                            required
                        />
                    </Col>
                    <Col xs={12} sm={4}>
                        <InputField
                            onChange={saftyFactors.onChange}
                            onBlur={saftyFactors.onBlur}
                            value={saftyFactors.state.weightSafe.value}
                            isValid={saftyFactors.state.weightSafe.isValid}
                            max={1}
                            type="number"
                            title="Ciężar gruntu"
                            placeholder="Dla ciężaru gruntu"
                            name="weightSafe"
                            disable={true}
                            required
                        />
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <SectionTitle title="Dla materiału pala" />
                    </Col>
                    <Col xs={12} sm={4}>
                        <InputField
                            onChange={saftyFactors.onChange}
                            onBlur={saftyFactors.onBlur}
                            value={
                                saftyFactors.state.steelReductionFactor.value
                            }
                            isValid={
                                saftyFactors.state.steelReductionFactor.isValid
                            }
                            type="number"
                            title={useMemo(
                                () => (
                                    <Fragment>
                                        Dla stali - &#947;<sub>s</sub>
                                    </Fragment>
                                ),
                                [],
                            )}
                            placeholder="Wsp. materiałowy dla stali"
                            name="steelReductionFactor"
                            disable={true}
                            required
                        />
                    </Col>
                    <Col xs={12} sm={4}>
                        <InputField
                            onChange={saftyFactors.onChange}
                            onBlur={saftyFactors.onBlur}
                            value={
                                saftyFactors.state.concreteReductionFactor.value
                            }
                            isValid={
                                saftyFactors.state.concreteReductionFactor
                                    .isValid
                            }
                            type="number"
                            title={useMemo(
                                () => (
                                    <Fragment>
                                        Dla betonu - &#947;<sub>c</sub>
                                    </Fragment>
                                ),
                                [],
                            )}
                            placeholder="Wsp. materiałowy dla konstrukcji"
                            name="concreteReductionFactor"
                            disable={true}
                            required
                        />
                    </Col>
                    <Col xs={12} sm={4}>
                        <InputField
                            onChange={saftyFactors.onChange}
                            onBlur={saftyFactors.onBlur}
                            value={
                                saftyFactors.state.constructionReductionFactor
                                    .value
                            }
                            isValid={
                                saftyFactors.state.constructionReductionFactor
                                    .isValid
                            }
                            type="number"
                            title={useMemo(
                                () => (
                                    <Fragment>
                                        Wsp. redukcyjny - &#945;<sub>cc</sub>
                                    </Fragment>
                                ),
                                [],
                            )}
                            placeholder="Wsp. redukcyjny"
                            name="constructionReductionFactor"
                            disable={true}
                            required
                        />
                    </Col>
                </Row>
            </Modal>
            <Modal
                open={open.koseckiCo}
                cssDialog={styles.modal}
                onHide={onHide}
                title="Współczynniki częściowe">
                <Row className={styles.row}>
                    <Col xs={12}>
                        <SectionTitle title="Dla metody uogólnionej Koseckiego" />
                    </Col>
                    <Col xs={12} sm={4}>
                        <InputField
                            onChange={saftyFactors.onChange}
                            onBlur={saftyFactors.onBlur}
                            value={saftyFactors.state.kxSafe.value}
                            isValid={saftyFactors.state.kxSafe.isValid}
                            type="number"
                            title="Sztywność pozioma Kx"
                            placeholder="Dla sztywności Kx"
                            name="kxSafe"
                            disable={true}
                            required
                        />
                    </Col>
                    <Col xs={12} sm={4}>
                        <InputField
                            onChange={saftyFactors.onChange}
                            onBlur={saftyFactors.onBlur}
                            value={saftyFactors.state.rgrSafe.value}
                            isValid={saftyFactors.state.rgrSafe.isValid}
                            type="number"
                            title="Reakcja graniczna Rgr"
                            placeholder="Dla reakcji granicznej"
                            name="rgrSafe"
                            disable={true}
                            required
                        />
                    </Col>
                    <Col xs={12} sm={4}>
                        <InputField
                            onChange={saftyFactors.onChange}
                            onBlur={saftyFactors.onBlur}
                            value={saftyFactors.state.kpSafe.value}
                            isValid={saftyFactors.state.kpSafe.isValid}
                            type="number"
                            title="Odpór gruntu Kp"
                            placeholder="Dla odporu gruntu"
                            name="kpSafe"
                            disable={true}
                            required
                        />
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12} md={4}>
                        <InputField
                            onChange={saftyFactors.onChange}
                            onBlur={saftyFactors.onBlur}
                            value={saftyFactors.state.pileShapeFactor.value}
                            isValid={saftyFactors.state.pileShapeFactor.isValid}
                            type="number"
                            title={useMemo(
                                () => (
                                    <Fragment>&#954;</Fragment>
                                ),
                                [],
                            )}
                            placeholder="Wsp. kształtu pala"
                            name="pileShapeFactor"
                            disable={true}
                            required
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <InputField
                            onChange={saftyFactors.onChange}
                            onBlur={saftyFactors.onBlur}
                            value={saftyFactors.state.n0.value}
                            isValid={saftyFactors.state.n0.isValid}
                            type="number"
                            title={useMemo(
                                () => (
                                    <Fragment>
                                        n<sub>0</sub>
                                    </Fragment>
                                ),
                                [],
                            )}
                            name="n0"
                            disable={true}
                            required
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <InputField
                            onChange={saftyFactors.onChange}
                            onBlur={saftyFactors.onBlur}
                            value={saftyFactors.state.n1.value}
                            isValid={saftyFactors.state.n1.isValid}
                            type="number"
                            title={useMemo(
                                () => (
                                    <Fragment>
                                        n<sub>1</sub>
                                    </Fragment>
                                ),
                                [],
                            )}
                            name="n1"
                            disable={true}
                            required
                        />
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12} sm={6}>
                        <InputField
                            onChange={saftyFactors.onChange}
                            onBlur={saftyFactors.onBlur}
                            value={saftyFactors.state.n2.value}
                            isValid={saftyFactors.state.n2.isValid}
                            type="number"
                            title={useMemo(
                                () => (
                                    <Fragment>
                                        n<sub>2</sub>
                                    </Fragment>
                                ),
                                [],
                            )}
                            name="n2"
                            disable={true}
                            required
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <InputField
                            onChange={saftyFactors.onChange}
                            onBlur={saftyFactors.onBlur}
                            value={saftyFactors.state.n3.value}
                            isValid={saftyFactors.state.n3.isValid}
                            type="number"
                            title={useMemo(
                                () => (
                                    <Fragment>
                                        n<sub>3</sub>
                                    </Fragment>
                                ),
                                [],
                            )}
                            name="n3"
                            disable={true}
                            required
                        />
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12} sm={6}>
                        <InputField
                            onChange={saftyFactors.onChange}
                            onBlur={saftyFactors.onBlur}
                            value={
                                saftyFactors.state.cohesiveShaftFriction.value
                            }
                            isValid={
                                saftyFactors.state.cohesiveShaftFriction.isValid
                            }
                            min={-1}
                            max={0}
                            type="number"
                            title="Kąt tarcia gruntu o pobocznicę pala - grunt spoisty"
                            placeholder="Grunt spoisty"
                            name="cohesiveShaftFriction"
                            disable={true}
                            required
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <InputField
                            onChange={saftyFactors.onChange}
                            onBlur={saftyFactors.onBlur}
                            value={
                                saftyFactors.state.nonCohesiveShaftFriction
                                    .value
                            }
                            isValid={
                                saftyFactors.state.nonCohesiveShaftFriction
                                    .isValid
                            }
                            min={-1}
                            max={0}
                            type="number"
                            title="Kąt tarcia gruntu o pobocznicę pala - grunt niespoisty"
                            placeholder="Grunt niespoisty"
                            name="nonCohesiveShaftFriction"
                            disable={true}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6}>
                        <RadioInput
                            onChange={saftyFactors.onChange}
                            title="Typ analizy"
                            name="analysisType"
                            label="Analiza sprężysto-plastyczna"
                            checked={
                                saftyFactors.state.analysisType.value === 1
                            }
                            value={1}>
                                Wartości sił wewnętrznych (momenty zginające oraz
                                siły tnące) mogą być obliczane z uwzględnieniem
                                uplastycznienia gruntu wokół pala lub zakładając
                                sprężyste zachowanie gruntu wokół pala.
                                Przemieszczenia pala bez względu na wybór metody
                                wyznaczane są przy założeniu sprężystej pracy
                                gruntu.
                        </RadioInput>
                    </Col>
                    <Col xs={12} sm={6} className={styles.flex}>
                        <RadioInput
                            onChange={saftyFactors.onChange}
                            name="analysisType"
                            label="Analiza sprężysta"
                            checked={
                                saftyFactors.state.analysisType.value === 2
                            }
                            value={2}
                            css={styles.alignEnd}
                        />
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <label className={styles.label}>
                            Współczynniki technologiczne pala i gruntu
                        </label>
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        Nr<br></br>[-]
                                    </th>
                                    <th width={150}>
                                        Nazwa gruntu<br></br>[-]
                                    </th>
                                    <th title="Rzędna">
                                        Z<br></br>[m.p.p.t]
                                    </th>
                                    <th title="Głębokość krytyczna">
                                        Z<sub>c</sub>
                                        <br></br>[m]
                                    </th>
                                    <th title="Zastępczy poziom terenu">
                                        H<sub>z</sub>
                                        <br></br>[m]
                                    </th>
                                    <th title="Kąt tarcia wewnętrznego">
                                        &#966; <br></br>[&#176;]
                                    </th>
                                    <th title="Kąt tarcia o pobocznicę pala">
                                        &#948; <br></br>[&#176;]
                                    </th>
                                    <th title="Współczynnik odporu gruntu">
                                        K<sub>ph</sub>
                                        <br></br>[m]
                                    </th>
                                    <th
                                        title="Współczynnik korekcyjny zależny od rodzaju gruntu"
                                        width={110}>
                                        m<sub>1</sub>
                                        <br></br>[-]
                                    </th>
                                    <th
                                        title="Współczynnik zależny od technologii pogrążania pali"
                                        width={110}>
                                        S<sub>n</sub>
                                        <br></br>[-]
                                    </th>
                                    <th
                                        title="Współczynnik obciążeń długotrwałych i powtarzalnych"
                                        width={110}>
                                        &#934; <br></br>[-]
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {saftyFactors.state.technologyFactors.map(
                                    (element, index) => {
                                        return (
                                            <tr
                                                className={styles.tr}
                                                key={index}>
                                                <td>{index + 1}</td>
                                                <td className={styles.tdLeft}>
                                                    {element.name}
                                                </td>
                                                <td>{element.height}</td>
                                                <td>{element.zc}</td>
                                                <td>{element.hz}</td>
                                                <td>{element.friction}</td>
                                                <td>{element.pileFriction}</td>
                                                <td>{element.kph}</td>
                                                <td>
                                                    <InputField
                                                        onChange={
                                                            saftyFactors.onChange
                                                        }
                                                        onBlur={
                                                            saftyFactors.onBlur
                                                        }
                                                        data-row={index}
                                                        value={element.m1.value}
                                                        isValid={
                                                            element.m1.isValid
                                                        }
                                                        type="number"
                                                        name="m1"
                                                        disable={true}
                                                        required
                                                    />
                                                </td>
                                                <td>
                                                    <InputField
                                                        onChange={
                                                            saftyFactors.onChange
                                                        }
                                                        onBlur={
                                                            saftyFactors.onBlur
                                                        }
                                                        data-row={index}
                                                        value={element.sn.value}
                                                        isValid={
                                                            element.sn.isValid
                                                        }
                                                        type="number"
                                                        name="sn"
                                                        disable={true}
                                                        required
                                                    />
                                                </td>
                                                <td>
                                                    <InputField
                                                        onChange={
                                                            saftyFactors.onChange
                                                        }
                                                        onBlur={
                                                            saftyFactors.onBlur
                                                        }
                                                        data-row={index}
                                                        value={
                                                            element
                                                                .longTermFactor
                                                                .value
                                                        }
                                                        isValid={
                                                            element
                                                                .longTermFactor
                                                                .isValid
                                                        }
                                                        type="number"
                                                        name="longTermFactor"
                                                        disable={true}
                                                        required
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Modal>
            <Modal
                open={open.loadsCo}
                cssDialog={styles.loadsModal}
                onHide={onHide}
                title="Współczynniki obciążeń">
                <Row className={styles.row}>
                    <Col xs={12} sm={6}>
                        <InputField
                            onChange={saftyFactors.onChange}
                            onBlur={saftyFactors.onBlur}
                            value={saftyFactors.state.deadLoadReduction.value}
                            isValid={
                                saftyFactors.state.deadLoadReduction.isValid
                            }
                            type="number"
                            title={useMemo(
                                () => (
                                    <Fragment>
                                        Wsp. dla obciążeń stałych - &#947;
                                        <sub>G</sub>
                                    </Fragment>
                                ),
                                [],
                            )}
                            placeholder="Wsp. dla obciążeń stałych"
                            name="deadLoadReduction"
                            disable={true}
                            required
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <InputField
                            onChange={saftyFactors.onChange}
                            onBlur={saftyFactors.onBlur}
                            value={saftyFactors.state.liveLoadReduction.value}
                            isValid={
                                saftyFactors.state.liveLoadReduction.isValid
                            }
                            type="number"
                            title={useMemo(
                                () => (
                                    <Fragment>
                                        Wsp. dla obciążeń zmiennych - &#947;
                                        <sub>Q</sub>
                                    </Fragment>
                                ),
                                [],
                            )}
                            placeholder="Wsp. dla obciążeń zmiennych"
                            name="liveLoadReduction"
                            disable={true}
                            required
                        />
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12} sm={6}>
                        <InputField
                            onChange={saftyFactors.onChange}
                            onBlur={saftyFactors.onBlur}
                            value={
                                saftyFactors.state.globalLoadsReduction.value
                            }
                            isValid={
                                saftyFactors.state.globalLoadsReduction.isValid
                            }
                            min={1}
                            type="number"
                            title={useMemo(
                                () => (
                                    <Fragment>
                                        Całk. wsp. obciążeń - &#947;
                                        <sub>(G,Q)</sub>
                                    </Fragment>
                                ),
                                [],
                            )}
                            placeholder="Całk. wsp. obciążeń"
                            name="globalLoadsReduction"
                            disable={true}
                            required
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <InputField
                            onChange={saftyFactors.onChange}
                            onBlur={saftyFactors.onBlur}
                            value={saftyFactors.state.liveDeadLoadRatio.value}
                            isValid={
                                saftyFactors.state.liveDeadLoadRatio.isValid
                            }
                            type="number"
                            title={useMemo(
                                () => (
                                    <Fragment>
                                        Stosunek obc. zmiennych do całk.
                                    </Fragment>
                                ),
                                [],
                            )}
                            placeholder="Stosunek obc. zmiennych do całk."
                            name="liveDeadLoadRatio"
                            disable={true}
                            required
                        />
                    </Col>
                </Row>
            </Modal>
        </Col>
    );
};

export default React.memo(
    SaftyFactors,
    (prev, next) => stringify(prev) === stringify(next),
);
