import { useState } from 'react';

const capacityChartTypes = [
    {
        type: 0,
        name: 'Długość pala',
    },
    {
        type: 1,
        name: 'Maksymalny moment zginający',
    },
    {
        type: 2,
        name: 'Minimalny moment zginający',
    },
    {
        type: 3,
        name: 'Maksymalna siła tnąca',
    },
    {
        type: 4,
        name: 'Minimalna siła tnąca',
    },
    {
        type: 5,
        name: 'Maksymalne przemieszczenie',
    },
    {
        type: 6,
        name: 'Minimalne przemieszczenie',
    },
];
const KxCapacityService = () => {
    const [settings, setSettings] = useState({
        showVertical: true,
        showHorizontal: true,
        showLegend: true,
        showValues: false,
        showDescription: true,
        showValue: true,
        rotateDescriptionX: false,
    });
    const [capacityType, setCapacityType] = useState(0);
    const [capacityName, setCapacityName] = useState('Długość pala');
    const [chartNames, setChartNames] = useState({
        0: {
            title: 'Rozkład długości pala wzdłuż profili geologicznych',
            axisY: 'Długość pala [m]',
            axisX: 'Profile geologiczne',
            legend: '- długość pala',
        },
        1: {
            title: 'Maksymalny moment zginający w palu',
            axisY: 'Moment zginający [kNm]',
            axisX: 'Profile geologiczne',
            legend: '- maksymalny moment zginający w palu',
        },
        2: {
            title: 'Minimalny moment zginający w palu',
            axisY: 'Moment zginający [kNm]',
            axisX: 'Profile geologiczne',
            legend: '- minimalny moment zginający w palu',
        },
        3: {
            title: 'Maksymalna siła tnąca w palu',
            axisY: 'Siła tnąca [kN]',
            axisX: 'Profile geologiczne',
            legend: '- maksymalna siła tnąca w palu',
        },
        4: {
            title: 'Minimalna siła tnąca w palu',
            axisY: 'Siła tnąca [kN]',
            axisX: 'Profile geologiczne',
            legend: '- minimalna siła tnąca w palu',
        },
        5: {
            title: 'Maksymalne przemieszczenie pala',
            axisY: 'Przemieszczenie [mm]',
            axisX: 'Profile geologiczne',
            legend: '- maksymalne przemieszczenie pala',
        },
        6: {
            title: 'Minimalne przemieszczenie pala',
            axisY: 'Przemieszczenie [mm]',
            axisX: 'Profile geologiczne',
            legend: '- minimalne przemieszczenie pala',
        },
    });

    const onChangeChartTitles = (e = {}, type) => {
        if (e.target) {
            const { name, value } = e.target;

            setChartNames((state) => ({
                ...state,
                [type]: {
                    ...state[type],
                    [name]: value,
                },
            }));
        }
    };

    const onToggleChartType = (e) => {
        const currentSelectProject = capacityChartTypes.find(
            (ele) => ele.name === e.target.value,
        );

        if (currentSelectProject) {
            setCapacityType(currentSelectProject.type);
            setCapacityName(currentSelectProject.name);
        }
    };

    const onChangeSettings = (e) => {
        e.preventDefault();
        e.persist();

        const name = e.currentTarget.getAttribute('name');

        setSettings((state) => ({ ...state, [name]: !state[name] }));
    };

    const kxCapacity = {
        state: {
            chartNames,
            capacityType,
            capacityName,
            capacityChartTypes,

            settings,
        },
        onChangeChartTitles,
        onToggleChartType,
        onChangeSettings,
    };

    return {
        kxCapacity,
    };
};

export default KxCapacityService;
