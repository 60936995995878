import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { withResults } from '../../../../../hoc';
import { SectionTitle } from '../../../../../components';
import DesignSoilParameters from '../DesignSoilParameters';
import KxChart from '../KxChart';
import KxRgrDetails from '../KxRgrDetails';
import MomentChart from '../MomentChart';
import QgrChart from '../QgrChart';
import ShearForceChart from '../ShearForceChart';
import DisplacementChart from '../DisplacementChart';
import InternalForcesDetails from '../InternalForcesDetails';
import styles from './index.module.css';

const ResultsWrapper = ({ data }) => {
    return (
        <div className={'horizontal-capacity-results'}>
            <Row className={styles.row}>
                <Col xs={12}>
                    <DesignSoilParameters
                        dataToPaginate={data.designSoilParameters}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <SectionTitle
                        title={'Siły wewnętrzne i przemieszczenia w palu'}
                    />
                </Col>
                <Col xs={12} lg={6}>
                    <MomentChart
                        chartTitle="Moment zginający w palu"
                        yLabel="Długość pala [m]"
                        xLabel="M [kNm]"
                        dataToChart={{
                            bendingMoments: data.internalForces.bendingMoments,
                            soilParameters: data.designSoilParameters,
                            modelParameters: data.restParameters,
                        }}
                    />
                </Col>
                <Col xs={12} lg={6}>
                    <ShearForceChart
                        chartTitle="Siła tnąca w palu"
                        yLabel="Długość pala [m]"
                        xLabel="T [kN]"
                        dataToChart={{
                            shearForces: data.internalForces.shearForces,
                            soilParameters: data.designSoilParameters,
                            modelParameters: data.restParameters,
                        }}
                    />
                </Col>
                <Col xs={12} lg={6}>
                    <DisplacementChart
                        chartTitle="Przemieszczenia poziome pala w stanie sprężystym"
                        yLabel="Długość pala [m]"
                        xLabel="D [mm]"
                        dataToChart={{
                            displacements: data.internalForces.displacements,
                            soilParameters: data.designSoilParameters,
                            modelParameters: data.restParameters,
                        }}
                    />
                </Col>
                {
                    Array.isArray(data.internalForces.plasticDisplacements) && data.internalForces.plasticDisplacements.length > 0 && 
                    <Col xs={12} lg={6}>
                        <DisplacementChart
                            chartTitle="Przemieszczenia poziome pala w stanie sprężysto-plastycznym"
                            yLabel="Długość pala [m]"
                            xLabel="D [mm]"
                            dataToChart={{
                                displacements: data.internalForces.plasticDisplacements,
                                soilParameters: data.designSoilParameters,
                                modelParameters: data.restParameters,
                            }}
                        />
                    </Col>
                }
            </Row>
            <Row>
                <Col xs={12}>
                    <InternalForcesDetails
                        dataToPaginate={data.internalForcesDetails}
                    />
                </Col>
            </Row>
            <Row className={styles.row}>
                <Col xs={12}>
                    <SectionTitle
                        title={
                            'Rozkład parametrów gruntu wzdłuż profilu geotechnicznego'
                        }
                    />
                </Col>
                <Col xs={12} lg={6}>
                    <KxChart
                        chartTitle="Moduł reakcji poziomej gruntu Kx"
                        yLabel="Głębokość Z [m.p.p.t]"
                        xLabel="Kx [kPa]"
                        dataToChart={{
                            soilParameters: data.designSoilParameters,
                            modelParameters: data.restParameters,
                        }}
                    />
                </Col>
                <Col xs={12} lg={6}>
                    <QgrChart
                        chartTitle="Opór graniczny gruntu Qgr"
                        yLabel="Głębokość Z [m.p.p.t]"
                        xLabel="Qgr [kN/m]"
                        dataToChart={{
                            soilParameters: data.designSoilParameters,
                            modelParameters: data.restParameters,
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <KxRgrDetails dataToPaginate={data.parametersDetails} />
                </Col>
            </Row>
        </div>
    );
};

export default withResults(ResultsWrapper);
