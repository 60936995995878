import { forwardRef } from "react";
import styles from './index.module.css';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import pl from 'date-fns/locale/pl';
registerLocale('pl', pl)


const DatePickerComponent = ({ 
    onClearDateInput,
    onChangeDateRange,
    endDate,
    startDate
}) => {

    const DatePickerCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className={`${styles.dateButton} ${styles.flex} default-button-info-style`}>
            <div onClick={onClick} ref={ref}>
                {value}
            </div>
            <div className={styles.clearInput} title="Usuń daty" onClick={onClearDateInput}>
                <FontAwesomeIcon icon={faTrashAlt}/>
            </div>
        </div>
    ));   

    return (      
        <DatePicker
            locale={'pl'}
            tabIndex={1}
            selected={endDate}
            onChange={onChangeDateRange}
            customInput={<DatePickerCustomInput />}
            minDate={moment().subtract(10, 'years').toDate()}
            maxDate={moment().toDate()}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            
            dateFormat="dd.MM.yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
        /> 
    )
}

export default DatePickerComponent