import { Table } from "react-bootstrap"
import styles from './index.module.css';
import round from "../../../../../utils/roundNumber"; 

const CapacityTable = ({                                                                
    currentSheetPileLength,
    sheetPileHeadSpot,
    sheetPileType,
    results=[],
    yt
 }) => {

    return (
        <div className={styles.wrapper}>
            <div className={styles.margins}>
                <h2>Nośność pionowa ścianki szczelnej {`${sheetPileType ? sheetPileType : ''}`}</h2>                 
            </div> 
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th>Nr<br></br>[-]</th>
                        <th>z<br></br>[m.p.p.t.]</th>
                        <th>R<sub>b,k</sub><br></br>[kN]</th>
                        <th>R<sub>s,k</sub>=&Sigma;R<sub>s,k,i</sub><br></br>[kN]</th>
                        <th>&gamma;<sub>b,s,t</sub><br></br>[-]</th> 
                        <th>R<sub>c,d</sub><br></br>[kN]</th>
                    </tr>
                </thead>
                <tbody>
                    {results.map((element, index) => {
                        return (
                            <tr key={index} style={{ backgroundColor: round(sheetPileHeadSpot + currentSheetPileLength,2) === round(element.z,2) ? '#ddd' : 'transparent' }}>
                                <td>{index + 1}</td>
                                <td>{round(element.z, 2)}</td>
                                <td>{round(element.Rbk, 2)}</td>
                                <td>{round(element.Rsk, 2)}</td>
                                <td>{round(yt, 2)}</td>
                                <td>{round(element.Rcd, 2)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <p className={styles.underline}>Oznaczenia:</p>
            <p>Z - <span className={styles.value}>rzędna zagłębienia pala poniżej poziomu terenu</span></p>
            <p>R<sub>b,k</sub> - <span className={styles.value}>nośność podstawy na rzędnej Z</span></p>
            <p>R<sub>s,k</sub>=&Sigma;R<sub>s,k,i</sub> - <span className={styles.value}>nośność pobocznicy na rzędnej Z</span></p>
            <p>&gamma;<sub>b,s,t</sub> - <span className={styles.value}>współczynnik bezpieczeństwa dla nośności pobocznicy, podstawy lub całkowitej</span></p>
            <p>R<sub>c,d</sub> - <span className={styles.value}>nośność obliczeniowa na wciskanie na rzędnej Z</span></p>
        </div>
    )
}

export default CapacityTable