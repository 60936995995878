import React, { useState } from 'react';
import { isNumber } from '../../utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const defaultPaginationState = {
    currentPage: 1,
    itemsPerPage: 15,
}
const withPagination = ({ component, returnAllCollection = false, paginationState={} }) => { 
    const Component = component;

    return ({dataToPaginate=[], ...rest}) => {
        
        const [state, setState] = useState({...defaultPaginationState, ...paginationState});

        useDeepCompareEffect(() => {
            if(Array.isArray(dataToPaginate) && dataToPaginate.length > 0) {
                if(state.itemsPerPage * state.currentPage > dataToPaginate.length){
                    setState(state => ({ ...state, currentPage: Math.ceil(dataToPaginate.length/state.itemsPerPage) }))
                }                
            }

        },[dataToPaginate, state.itemsPerPage, state.currentPage])

        const nextPage = (pageNumber) => setState(state => ({ ...state, currentPage: pageNumber }));
        const displayItemsNumber = ({target}) => {
            
            setState(state => ({ ...state, currentPage: 1, itemsPerPage: Number.parseInt(target.closest('a').getAttribute('value')) }));
        }

        const indexOfLastItem = state.currentPage * state.itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - state.itemsPerPage;
        const countingItemsPerPage = (state.currentPage - 1)*state.itemsPerPage;

        const totalPages = Math.ceil(dataToPaginate.length/state.itemsPerPage);

        const paginationHelpers = {
            nextPage,
            displayItemsNumber,
            indexOfFirstItem,
            indexOfLastItem,
            countingItemsPerPage,
            allItemsLength: dataToPaginate.length,
            totalPages: isNumber(totalPages) ? totalPages : 0
        }

        return(
            <Component 
                dataToPaginate={dataToPaginate.slice((state.currentPage * state.itemsPerPage - state.itemsPerPage), (state.currentPage * state.itemsPerPage))}
                allCollection={returnAllCollection ? dataToPaginate : []}
                pagination={state} 
                paginationHelpers={paginationHelpers}
                {...rest} 
            />
        )
    }
}

export default withPagination