import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import styled from "styled-components";


const NoResults = styled.div`
    text-align: center;
    margin: 25px 0;

    & svg {
        margin: 30px 0; 
        color: #cc1525;
        font-size: 64px;
    }
`

const H2 = styled.p`
    font-size: 18px;
`
const withResults = (Component) => {
    
    return ({ isResultsActive, isResultsActual = true, ...rest }) => {

        return (
            <Fragment>
                {
                    isResultsActive && isResultsActual
                    ?
                    <Component {...rest} />
                    :
                    <NoResults>
                        <FontAwesomeIcon icon={faBan} />
                        <H2>Brak wyników</H2>
                    </NoResults>
                }                
            </Fragment>
        )
    }
}

export default withResults