import SoilProfile from "./SoilProfile"


const VerticalCapacityChart = ({ 
    dataToChart
 }) => { 

    return (
        <SoilProfile 
            groundLevel={dataToChart.groundLevel}
            soilProfile={dataToChart.soilProfile}
            results={dataToChart.results}
            pileLength={dataToChart.pileLength}
            pileHeadSpot={dataToChart.pileHeadSpot}
            pileType={dataToChart.pileType}
            pileTechnology={dataToChart.pileTechnology}
            pileShape={dataToChart.pileShape}
            diameterPile={dataToChart.diameterPile}
            singlePile={dataToChart.singlePile}
            currentPileLengthByUser={dataToChart.currentPileLengthByUser}
            onChangeCurrentPileLength = {dataToChart.onChangeCurrentPileLength}
            onChangeCurrentCapacity={dataToChart.onChangeCurrentCapacity}

            openSidebar={dataToChart.openSidebar}
        />
    )
}

export default VerticalCapacityChart