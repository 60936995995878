import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import config from "../../../config/config";
import styles from './index.module.css';

const CheckoutCancel = () => {

    useEffect(() => {
        document.title = `Płatność anulowana`;

        return () => {
            document.title = ''; 
        } 
    },[]);

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <div className={styles.wrapper}>
                        <div className={styles.icon}>
                            <h1>
                                <FontAwesomeIcon icon={faBan}/>
                            </h1>
                        </div>
                        <h2>Płatność anulowana!</h2>
                        <p>Twoja transakcja nie została rozliczona poprawnie. <br></br>Twój rachunek nie został obciążony.</p>

                        <button className={styles.button}>
                            <Link to={config.routes.dashboard.logIn} title="Zaloguj się">Powrót do logowania</Link>
                        </button>
                    </div>
                </Col>
            </Row>            
        </Container>

    )
}

export default CheckoutCancel