import React, { Fragment, useState } from 'react';
import { withPagination } from '../../../../../hoc';
import {
    ItemsPerPage,
    Pagination,
    SectionTitle,
    Table,
} from '../../../../../components';
import styles from './index.module.css';
import useDeepCompareEffect from 'use-deep-compare-effect';

const InternalForcesDetails = ({
    dataToPaginate,
    allCollection = [],
    pagination,
    paginationHelpers,
}) => {
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [csvData, setCsvData] = useState([]);

    useDeepCompareEffect(() => {
        setCsvData(
            allCollection.map((ele) =>
                Object.fromEntries(
                    Object.entries(ele).map(([key, value]) => [
                        [key],
                        value.toString().replace('.', ','),
                    ]),
                ),
            ),
        );

        setCsvHeaders([
            {
                label: 'Z [m.p.p.t.]',
                key: 'z',
            },
            {
                label: 'M [kNm]',
                key: 'bendingMoment',
            },
            {
                label: 'T [kN]',
                key: 'shearForce',
            },
            {
                label: 'D [mm]',
                key: 'displacement',
            },
        ]);
    }, [allCollection]);

    return (
        <Fragment>
            <SectionTitle
                title={
                    'Szczegółowe wartości sił wewnętrznych - wartości obliczeniowe'
                }
            />
            <ItemsPerPage
                onDisplayItemsNumber={paginationHelpers.displayItemsNumber}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                display={[15, 30, paginationHelpers.allItemsLength]}
            />
            <Table
                csvData={csvData}
                csvFilename={'ForcesDetails'}
                csvHeaders={csvHeaders}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th>
                            Długość pala<br></br>[m]
                        </th>
                        <th title="Moment zginający">
                            M<br></br>[kNm]
                        </th>
                        <th title="Siła tnąca">
                            T<br></br>[kN]
                        </th>
                        <th title="Przemieszczenie sprężyste pala">
                            D<br></br>[mm]
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {dataToPaginate.map((element, index) => {
                        return (
                            <tr className={styles.tr} key={index}>
                                <td>
                                    {paginationHelpers.countingItemsPerPage +
                                        index +
                                        1}
                                </td>
                                <td>{element.z}</td>
                                <td>{element.bendingMoment}</td>
                                <td>{element.shearForce}</td>
                                <td>{element.displacement}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <Pagination
                currentPage={pagination.currentPage}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={paginationHelpers.allItemsLength}
                nextPage={paginationHelpers.nextPage}
            />
        </Fragment>
    );
};

export default withPagination({
    component: InternalForcesDetails,
    returnAllCollection: true,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 25,
    },
});
