import { useState } from 'react';

const ResultsHelpers = () => {
    const [resultsState, setResultsState] = useState({});

    const loadProject = ({
        ...results
    }) => {
        setResultsState(results);
    };

    const results = {
        state: {
            ...resultsState,
        },
        updateState: setResultsState,
        loadProject: loadProject,
        initState: () => {
            setResultsState({});
        },
    };

    return {
        results,
    };
};

export default ResultsHelpers;
