import { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Table, Pagination, SectionTitle } from '../../../components';
import { withPagination } from '../../../hoc';
import { isNumber } from '../../../utils';
import round from '../../../utils/roundNumber';
import styles from './index.module.css';

const ReinforcementSummary = ({
    dataToPaginate = [],
    pagination,
    paginationHelpers = {},
}) => {
    return (
        <Fragment>
            {dataToPaginate.length > 0 && (
                <Row className={styles.row}>
                    <Col xs={12}> 
                        <SectionTitle
                            title={`Wyniki zbrojenia przekroju zginanego z siłą podłużną wg Eurokodu 2`}
                        />
                        <Fragment>
                            {dataToPaginate.map((element, index) => {
                                const diameter = element.results.initModelValues.h;
                                const b = element.results.initModelValues.b;
                                const memberType = element.results.initModelValues.memberType;
                                return (
                                    <Fragment key={index}>
                                        {
                                            element.results.initModelValues.circular 
                                            ?
                                            <Table className={styles.table}>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Nr<br></br>[-]
                                                        </th>
                                                        <th title="Nazwa pliku">
                                                            Nazwa pliku<br></br>
                                                        </th>
                                                        <th title="Moment zginający">
                                                            M<sub>x</sub><br></br>[kNm]
                                                        </th>
                                                        <th title="Siła osiowa">
                                                            N<sub>z</sub><br></br>[kN]
                                                        </th>
                                                        <th title="Siła tnąca">
                                                            V<sub>y</sub><br></br>[kN]
                                                        </th>
                                                        <th title="Typ elementu">
                                                            Typ<br></br>[-]
                                                        </th>
                                                        <th title="Klasa betonu">
                                                            Beton<br></br>[-]
                                                        </th>
                                                        <th title="Klasa stali">
                                                            Stal<br></br>[-]
                                                        </th>
                                                        <th title="Zbrojenie obliczeniowe">
                                                            A<sub>s</sub><br></br>[cm<sup>2</sup>]
                                                        </th>
                                                        <th title="Liczba prętów">
                                                            n<br></br>[-]
                                                        </th>
                                                        <th title="Średnica prętów">
                                                            d<br></br>[mm]
                                                        </th>
                                                        <th title="Powierzchnia przyjętego zbrojenia">
                                                            A<sub>s,pov</sub><br></br>[cm<sup>2</sup>]
                                                        </th>
                                                        <th title="Stopień zbrojenia">
                                                            &#961;<br></br>[%]
                                                        </th>
                                                        <th title='Wymiar w osiach prętów'>
                                                            s<br></br>[mm]
                                                        </th>
                                                        <th title='Długość zakotwienia pręta rozciąganego'>
                                                            l<sub>bd+</sub><br></br>[mm]
                                                        </th>
                                                        <th title='Długość zakotwienia pręta ściskanego'>
                                                            l<sub>bd-</sub><br></br>[mm]
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <Fragment>
                                                        {
                                                            element.results.calculation.map((ele, idx, arr) => {
                                                                const sectionCapacity = element.model.checkCapacity.find(element => element.index === ele.defaultResults.index);
                                                                const a2 = ele.defaultResults.a2;
                                                                return (
                                                                    <tr key={idx}>
                                                                        <td>{
                                                                            arr.length > 1
                                                                            ?
                                                                            `${paginationHelpers.countingItemsPerPage + index + 1}.${idx+1}`
                                                                            :
                                                                            paginationHelpers.countingItemsPerPage + index + 1
                                                                        }</td>
                                                                        <td>{element.projectName}</td>

                                                                        <td>{ele.defaultResults.med}</td>
                                                                        <td>{ele.defaultResults.ned}</td>
                                                                        <td>{ele.defaultResults.shearForce}</td>

                                                                        <td>{element.results.initModelValues.memberType}</td>
                                                                        <td>{element.results.initModelValues.concreteClass}</td>
                                                                        <td>{element.results.initModelValues.steelClass}</td>
                                                                        <td>{ele.result.as}</td>
                                                                        <td>{sectionCapacity.rebarNumber.value}</td>
                                                                        <td>{sectionCapacity.rebarDiameter.value}</td>
                                                                        <td>{sectionCapacity.rebarArea.value}</td>
                                                                        <td>{round(100*sectionCapacity.rebarArea.value/(diameter*diameter*0.25*0.01*Math.PI),2)}</td>
                                                                        <td>{round(2*Math.PI*(diameter*0.5 - a2)/sectionCapacity.rebarNumber.value,2)}</td>
                                                                        {ele.lbd && isNumber(ele.lbd.tension) ? <td>{round(ele.lbd.tension,0)}</td> : <td>-</td>}
                                                                        {ele.lbd && isNumber(ele.lbd.compression) ? <td>{round(ele.lbd.compression,0)}</td> : <td>-</td>}
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </Fragment>
                                                </tbody>
                                            </Table>
                                            :
                                            <Table className={styles.table}>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Nr<br></br>[-]
                                                        </th>
                                                        <th title="Nazwa pliku">
                                                            Nazwa pliku<br></br>
                                                        </th>
                                                        <th title="Moment zginający">
                                                            M<sub>x</sub><br></br>[kNm]
                                                        </th>
                                                        {
                                                            element.results.initModelValues.memberType === 'Słup' &&
                                                            <th title="Siła osiowa">
                                                                N<sub>z</sub><br></br>[kN]
                                                            </th>                                                            
                                                        }
                                                        <th title="Siła tnąca">
                                                            V<sub>y</sub><br></br>[kN]
                                                        </th>
                                                        <th title="Typ elementu">
                                                            Typ<br></br>[-]
                                                        </th>
                                                        <th title="Klasa betonu">
                                                            Beton<br></br>[-]
                                                        </th>
                                                        <th title="Klasa stali">
                                                            Stal<br></br>[-]
                                                        </th>
                                                        <th title="Zbrojenie obliczeniowe dolne">
                                                            A<sub>s1</sub><br></br>[cm<sup>2</sup>]
                                                        </th>
                                                        <th title="Liczba prętów dolnych">
                                                            n<sub>1</sub><br></br>[-]
                                                        </th>
                                                        <th title="Średnica prętów dolnych">
                                                            d<sub>1</sub><br></br>[mm]
                                                        </th>
                                                        <th title="Powierzchnia przyjętego zbrojenia dolnego">
                                                            A<sub>1,pov</sub><br></br>[cm<sup>2</sup>]
                                                        </th>
                                                        <th title="Stopień zbrojenia dolnego">
                                                            &#961;<sub>1</sub><br></br>[%]
                                                        </th>
                                                        <th title='Wymiar w osiach prętów dolnych'>
                                                            s<sub>1</sub><br></br>[mm]
                                                        </th>
                                                        <th title='Długość zakotwienia pręta dolnego'>
                                                            l<sub>bd,1</sub><br></br>[mm]
                                                        </th>

                                                        <th title="Zbrojenie obliczeniowe górne">
                                                            A<sub>s2</sub><br></br>[cm<sup>2</sup>]
                                                        </th>
                                                        <th title="Liczba prętów górnych">
                                                            n<sub>2</sub><br></br>[-]
                                                        </th>
                                                        <th title="Średnica prętów górnych">
                                                            d<sub>2</sub><br></br>[mm]
                                                        </th>
                                                        <th title="Powierzchnia przyjętego zbrojenia górnego">
                                                            A<sub>2,pov</sub><br></br>[cm<sup>2</sup>]
                                                        </th>
                                                        <th title="Stopień zbrojenia górnego">
                                                            &#961;<sub>2</sub><br></br>[%]
                                                        </th>
                                                        <th title='Wymiar w osiach prętów górnych'>
                                                            s<sub>2</sub><br></br>[mm]
                                                        </th>
                                                        <th title='Długość zakotwienia pręta górnego'>
                                                            l<sub>bd,2</sub><br></br>[mm]
                                                        </th>
                                                        {
                                                            memberType !== 'Słup' &&
                                                            <Fragment>
                                                                <th title='Szerokość rysy'>
                                                                    w<sub>k</sub><br></br>[mm]
                                                                </th>
                                                            </Fragment>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <Fragment>
                                                        {
                                                            element.results.calculation.map((ele, idx, arr) => {
                                                                const sectionCapacity = element.model.checkCapacity.find(element => element.index === ele.defaultResults.index);
                                                                const a2 = ele.defaultResults.a2;
                                                                const a1 = ele.defaultResults.a1;
                                                                const d = ele.defaultResults.d;
                                                                const x = ele.result.x;
                                                                const negativeMoment = ele.defaultResults.negativeMoment;

                                                                return (
                                                                    <tr key={idx}>
                                                                        <td>{
                                                                            arr.length > 1
                                                                            ?
                                                                            `${paginationHelpers.countingItemsPerPage + index + 1}.${idx+1}`
                                                                            :
                                                                            paginationHelpers.countingItemsPerPage + index + 1
                                                                        }</td>
                                                                        <td>{element.projectName}</td>

                                                                        <td>{ele.defaultResults.med}</td>
                                                                        {
                                                                            element.results.initModelValues.memberType === 'Słup' && 
                                                                            <td>{ele.defaultResults.ned}</td>
                                                                        }
                                                                        <td>{ele.defaultResults.shearForce}</td>
                                                                        <td>{element.results.initModelValues.memberType}</td>
                                                                        <td>{element.results.initModelValues.concreteClass}</td>
                                                                        <td>{element.results.initModelValues.steelClass}</td>
                                                                        <td>{ele.result.as1}</td>
                                                                        <td>{sectionCapacity.rebarNumberAs1.value}</td>
                                                                        <td>{sectionCapacity.rebarDiameterAs1.value}</td>
                                                                        <td>{sectionCapacity.rebarAreaAs1.value}</td>
                                                                        <td>{round(100*sectionCapacity.rebarAreaAs1.value/(diameter*b*0.01),2)}</td>
                                                                        <td>{round((b - 2*a1)/(sectionCapacity.rebarNumberAs1.value - 1),2)}</td>

                                                                        {ele.lbd
                                                                            ? 
                                                                            <td>{x > a2 ? negativeMoment ? round(ele.lbd.compression,0) || '-' : x > d ? round(ele.lbd.compression,0) || '-' : round(ele.lbd.tension,0) || '-' : round(ele.lbd.tension,0) || '-' }</td>
                                                                            : 
                                                                            <td>-</td>
                                                                        }
                                                                        <td>{ele.result.as2}</td>
                                                                        <td>{sectionCapacity.rebarNumberAs2.value}</td>
                                                                        <td>{sectionCapacity.rebarDiameterAs2.value}</td>
                                                                        <td>{sectionCapacity.rebarAreaAs2.value}</td>
                                                                        <td>{round(100*sectionCapacity.rebarAreaAs2.value/(diameter*b*0.01),2)}</td>
                                                                        <td>{round((b - 2*a1)/(sectionCapacity.rebarNumberAs2.value - 1),2)}</td>

                                                                        {ele.lbd
                                                                            ? 
                                                                            <td>{
                                                                                x > a2 
                                                                                    ? 
                                                                                    negativeMoment 
                                                                                        ? 
                                                                                            x > d 
                                                                                                ? round(ele.lbd.compression,0) || '-' 
                                                                                                : round(ele.lbd.tension,0) || '-' 
                                                                                        : round(ele.lbd.compression,0) || '-' 
                                                                                    : round(ele.lbd.tension,0) || '-' 
                                                                            }</td>
                                                                            : 
                                                                            <td>-</td>
                                                                        }
                                                                        {
                                                                            memberType !== 'Słup' &&
                                                                            <Fragment>
                                                                                <td>
                                                                                    {
                                                                                        ele.crack && isNumber(ele.crack.wk)
                                                                                        ?
                                                                                        round(ele.crack.wk,2)
                                                                                        :
                                                                                        '-'
                                                                                    }
                                                                                </td>
                                                                            </Fragment>
                                                                        }
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </Fragment>
                                                </tbody>
                                            </Table>                                      
                                        }
                                    </Fragment>
                                )
                            })}
                        </Fragment>

                        {paginationHelpers.totalPages > 1 && (
                            <Pagination
                                currentPage={pagination.currentPage}
                                itemsPerPage={pagination.itemsPerPage}
                                totalItems={paginationHelpers.allItemsLength}
                                nextPage={paginationHelpers.nextPage}
                            />
                        )}
                    </Col>
                </Row>
            )}
        </Fragment>
    );
};

export default withPagination({
    component: ReinforcementSummary,
    returnAllCollection: false,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 15,
    },
});
