import { Button, Modal, InputField, Loading, Table, Subtitle, Checkbox1 } from "../";
import { Row, Col } from 'react-bootstrap';
import styles from './index.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListUl } from "@fortawesome/free-solid-svg-icons";
import { Fragment } from "react";

const AddNewProject = ({
    open,
    onHide,
    onChange,
    onChangeCheckbox,
    onAddAllUsers,
    onBlur,
    onSubmit,
    state,
    loading,

    inputName='projectName'
}) => {

    return (
        <Fragment>
            <Modal open={open} onHide={onHide} css={styles.modal} cssDialog={styles.modalDialog} title="Nowy projekt" hideButton={false}>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <InputField
                            onChange={onChange}
                            onBlur={onBlur}
                            value={state.newMainProjectName.value}
                            isValid={state.newMainProjectName.isValid}
                            type="text" 
                            title="Nazwa projektu"
                            placeholder="Nazwa projektu" 
                            name={inputName}

                            required
                        />
                    </Col>             
                </Row>
                {
                    Array.isArray(state.subUsers) && state.subUsers.length > 0 &&
                    <Row className={styles.row}>
                        <Col xs={12}>
                            <Subtitle
                                className={styles.sectionTitle}
                                title="Użytkownicy, którzy będą mieli dostęp do projektu"
                            />
                            <Table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th title="Numer">Nr</th>
                                        <th className={styles.pointer} title="Zaznacz wszystkich" onClick={() => onAddAllUsers(state.subUsers.map(ele => ele.id))}><FontAwesomeIcon icon={faListUl}/></th>
                                        <th title="Imię">Imię</th>
                                        <th title="Nazwisko">Nazwisko</th>
                                        <th title="Adres email">Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        state.subUsers.map((element,index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <Checkbox1
                                                            css={styles.checkbox}
                                                            onChange={() => onChangeCheckbox(element.id)}
                                                            value={state.userAccessToProject.includes(element.id)}
                                                            name="selectRow"
                                                        />
                                                    </td>
                                                    <td>{element.firstName}</td>
                                                    <td>{element.lastName}</td>
                                                    <td>{element.email}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                }
                <Row className={styles.row}>
                    <Col xs={12}>
                        <Button
                            css={`${styles.button} default-button-style`}
                            title={'Utwórz projekt'}
                            altTitle="Utwórz projekt"
                            name="createProject"
                            onClick={onSubmit}
                            type="submit"
                        />
                    </Col>   
                </Row>
            
            </Modal> 
            {
                loading &&
                <div className={styles.loadingWrapper}>
                    <Loading text="Tworzę nowy projekt..."/>
                </div>                
            }
        </Fragment>
    )
}

export default AddNewProject