import { Fragment } from "react";
import styles from './index.module.css';
import { isNumber, roundNumber } from "../../../../../utils";
import { Table } from "react-bootstrap";

const SummaryResults = ({ 
    results={}, 
    circular,
    memberType,
    checkCapacity,
    b,
    d,
    diameter,
}) => {
    const result = memberType !== 'Słup' && results.sgnResult ? results.sgnResult : results.result;
    const lbd = memberType !== 'Słup' && results.sgnLbd ? results.sgnLbd : results.lbd;

    return ( 
        <div className={styles.wrapper}>
            <h2>Wyniki obliczeń zbrojenia głównego (SGN)</h2>
            <Fragment>
                {
                    circular 
                    ?
                    <Fragment>
                        {result.reinfAsNotRequired && <p><b>Zbrojenie obliczeniowe nie jest wymagane</b></p>}
                        <p>Powierzchnia zbrojenia [A<sub>s</sub>]: <span className={styles.value}>{roundNumber(result.as,2)} cm<sup>2</sup></span></p>
                    </Fragment>
                    :
                    <Fragment>
                        {result.reinfAsNotRequired && <p><b>Zbrojenie obliczeniowe nie jest wymagane</b></p>}
                        {
                            roundNumber(result.x,2) > roundNumber(results.defaultResults.xlim,2) && (memberType === 'Belka' || memberType === 'Płyta') &&
                            <Fragment>
                                <p>UWAGA:</p>
                                <p>Wysokość strefy ściskanej jest większa od granicznej wysokości strefy ściskanej. Grozi kruchym zniszczeniem betonu w strefie ściskanej (niesygnalizowane zniszczenie). Zbrojenie rozciągane nie osiąga granicy plastyczności.</p>
                            </Fragment>
                        }
                        <p>Zbrojenie górne ({result.x > results.defaultResults.a2 
                            ? 
                            results.defaultResults.negativeMoment 
                                ? result.x > d ? 'ściskane' : 'rozciągane' 
                                : 'ściskane' 
                            : 
                            'rozciągane'}) [A<sub>s2</sub>]: <span className={styles.value}>{roundNumber(result.as2,2)} cm<sup>2</sup></span>
                        </p>
                        <p>Zbrojenie dolne ({result.x > results.defaultResults.a2 
                            ? 
                            results.defaultResults.negativeMoment 
                                ? 'ściskane' 
                                : result.x > d ? 'ściskane' : 'rozciągane'  
                            : 
                            'rozciągane'}) [A<sub>s1</sub>]: <span className={styles.value}>{roundNumber(result.as1,2)} cm<sup>2</sup></span>
                        </p>
                    </Fragment>                                
                }
                <p>Zbrojenie minimalne [A<sub>s,min</sub>]: <span className={styles.value}>{roundNumber(results.defaultResults.minAs,2)} cm<sup>2</sup></span></p>
                {
                    !result.reinfAsNotRequired &&
                    <Fragment>
                        <p>Wysokość strefy ściskanej [x]: <span className={styles.value}>{roundNumber(result.x,2)} mm</span></p>
                        <p>Graniczna wysokość strefy ściskanej [x<sub>lim</sub>]: <span className={styles.value}>{roundNumber(results.defaultResults.xlim,2)} mm</span></p>
                        <p>Wypadkowa naprężeń w betonie [F<sub>c</sub>]: <span className={styles.value}>{roundNumber(result.fc,2)} kN</span></p>
                    </Fragment>
                }
                {
                    results.defaultResults.lbdCalculation &&
                    <Fragment>
                        {
                            lbd && isNumber(lbd.tension) &&
                            <p>Długość zakotwienia zbrojenia rozciąganego [l<sub>bd</sub>]: <span className={styles.value}>{roundNumber(lbd.tension,0)} mm</span></p>
                        }
                        {
                            lbd && isNumber(lbd.compression) &&
                            <p>Długość zakotwienia zbrojenia ściskanego [l<sub>bd</sub>]: <span className={styles.value}>{roundNumber(lbd.compression,0)} mm</span></p>
                        }
                    </Fragment>
                }
            </Fragment>
            {
                memberType !== 'Słup' && results.defaultResults.isCrackCalc &&
                <Fragment>
                    <h2>Wyniki obliczeń zbrojenia głównego (SGU)</h2>
                    <Fragment>
                        {results.result.reinfAsNotRequired && <p><b>Zbrojenie obliczeniowe nie jest wymagane</b></p>}
                        {
                            roundNumber(results.result.x,2) > roundNumber(results.defaultResults.xlim,2) && (memberType === 'Belka' || memberType === 'Płyta') &&
                            <Fragment>
                                <p>UWAGA:</p>
                                <p>Wysokość strefy ściskanej jest większa od granicznej wysokości strefy ściskanej. Grozi kruchym zniszczeniem betonu w strefie ściskanej (niesygnalizowane zniszczenie). Zbrojenie rozciągane nie osiąga granicy plastyczności.</p>
                            </Fragment>
                        }
                        <p>Zbrojenie górne ({results.result.x > results.defaultResults.a2 
                            ? 
                            results.defaultResults.negativeMoment 
                                ? result.x > d ? 'ściskane' : 'rozciągane' 
                                : 'ściskane' 
                            : 
                            'rozciągane'}) [A<sub>s2</sub>]: <span className={styles.value}>{roundNumber(results.result.as2,2)} cm<sup>2</sup></span>
                        </p>
                        <p>Zbrojenie dolne ({results.result.x > results.defaultResults.a2 
                            ? 
                            results.defaultResults.negativeMoment 
                                ? 'ściskane' 
                                : result.x > d ? 'ściskane' : 'rozciągane'  
                            : 
                            'rozciągane'}) [A<sub>s1</sub>]: <span className={styles.value}>{roundNumber(results.result.as1,2)} cm<sup>2</sup></span>
                        </p>
                        {
                            !results.result.reinfAsNotRequired &&
                            <Fragment>
                                <p>Wysokość strefy ściskanej [x]: <span className={styles.value}>{roundNumber(results.result.x,2)} mm</span></p>
                                <p>Szerokość rysy [w<sub>k</sub>]: <span className={styles.value}>{roundNumber(results.crack.wk,2)} mm</span></p>
                                <p>Dopuszczalna szerokość rysy [w<sub>max</sub>]: <span className={styles.value}>{roundNumber(results.crack.maxCrackCalc,2)} mm</span></p>
                            </Fragment>
                        }
                        {
                            results.defaultResults.lbdCalculation &&
                            <Fragment>
                                {
                                    results.lbd && isNumber(results.lbd.tension) &&
                                    <p>Długość zakotwienia zbrojenia rozciąganego [l<sub>bd</sub>]: <span className={styles.value}>{roundNumber(results.lbd.tension,0)} mm</span></p>
                                }
                                {
                                    results.lbd && isNumber(results.lbd.compression) &&
                                    <p>Długość zakotwienia zbrojenia ściskanego [l<sub>bd</sub>]: <span className={styles.value}>{roundNumber(results.lbd.compression,0)} mm</span></p>
                                }
                            </Fragment>
                        }
                    </Fragment>                                
                </Fragment>
            }

            {
                circular 
                ?
                <Fragment>
                    <Table className={styles.table}>
                        <thead>
                            <tr>
                                <th>
                                    Nr<br></br>[-]
                                </th>
                                <th>
                                    Liczba prętów<br></br>[-]
                                </th>
                                <th>
                                    Średnica<br></br>[mm]
                                </th>
                                <th>
                                    Powierzchnia
                                    <br></br>[cm<sup>2</sup>]
                                </th>
                                <th>
                                    Stopień zbrojenia
                                    <br></br>[%]
                                </th>
                                <th>
                                    Rozstaw
                                    <br></br>[mm]
                                </th>
                                <th>
                                    Odstęp
                                    <br></br>[mm]
                                </th>
                                {
                                    results.defaultResults.lbdCalculation &&
                                    <Fragment>
                                        {
                                            results.lbd && isNumber(results.lbd.tension) &&
                                            <th title='Długość zakotwienia pręta rozciąganego'>
                                                l<sub>bd+</sub><br></br>[mm]
                                            </th>
                                        }
                                        {
                                            results.lbd && isNumber(results.lbd.compression) &&
                                            <th title='Długość zakotwienia pręta ściskanego'>
                                                l<sub>bd-</sub><br></br>[mm]
                                            </th>
                                        }                                        
                                    </Fragment>
                                }                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>{checkCapacity.rebarNumber.value}</td>
                                <td>{checkCapacity.rebarDiameter.value}</td>
                                <td>{checkCapacity.rebarArea.value}</td>
                                <td>{roundNumber(100*checkCapacity.rebarArea.value/(diameter*diameter*0.25*0.01*Math.PI),2)}</td>
                                <td>{roundNumber(2*Math.PI*(diameter*0.5 - results.defaultResults.a2)/checkCapacity.rebarNumber.value,2)}</td>
                                <td>{roundNumber(checkCapacity.space.value,2)}</td>
                                {
                                    results.defaultResults.lbdCalculation &&
                                    <Fragment>
                                        {results.lbd && isNumber(results.lbd.tension) && <td>{roundNumber(results.lbd.tension,0)}</td>}
                                        {results.lbd && isNumber(results.lbd.compression) && <td>{roundNumber(results.lbd.compression,0)}</td>}
                                    </Fragment>
                                }
                            </tr>
                        </tbody>
                    </Table>  
                </Fragment>
                :
                <Fragment>
                    <Fragment>
                        <div className={styles.margins}>
                            <h2>Zbrojenie górne ({ results.result.x > results.defaultResults.a2 ? results.defaultResults.negativeMoment ? result.x > d ? 'ściskane' : 'rozciągane' : 'ściskane' : 'rozciągane' }) A<sub>s2</sub></h2>
                        </div>
                        {
                            results.result.as2 > 0 
                            ?       
                            <Table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th>
                                            Nr<br></br>[-]
                                        </th>
                                        <th>
                                            Liczba prętów<br></br>[-]
                                        </th>
                                        <th>
                                            Średnica<br></br>[mm]
                                        </th>
                                        <th>
                                            Powierzchnia
                                            <br></br>[cm<sup>2</sup>]
                                        </th>
                                        <th>
                                            Stopień zbrojenia
                                            <br></br>[%]
                                        </th>
                                        <th>
                                            Rozstaw
                                            <br></br>[mm]
                                        </th>
                                        <th>
                                            Odstęp
                                            <br></br>[mm]
                                        </th>
                                        {
                                            results.defaultResults.lbdCalculation &&
                                            <Fragment>
                                                {
                                                    results.defaultResults.negativeMoment
                                                    ?
                                                    <Fragment>
                                                    {
                                                        results.lbd && isNumber(results.lbd.tension) &&
                                                        <th title='Długość zakotwienia pręta rozciąganego'>
                                                            l<sub>bd+</sub><br></br>[mm]
                                                        </th>                                               
                                                    }
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                    {
                                                        results.lbd && isNumber(results.lbd.compression) &&
                                                        <th title='Długość zakotwienia pręta ściskanego'>
                                                            l<sub>bd-</sub><br></br>[mm]
                                                        </th>  
                                                    }
                                                    </Fragment>                                            
                                                }
                                            </Fragment>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>{checkCapacity.rebarNumberAs2.value}</td>
                                        <td>{checkCapacity.rebarDiameterAs2.value}</td>
                                        <td>{checkCapacity.rebarAreaAs2.value}</td>
                                        <td>{roundNumber(100*checkCapacity.rebarAreaAs2.value/(diameter*b*0.01),2)}</td>
                                        <td>{roundNumber(checkCapacity.spaceAs2.value + checkCapacity.rebarDiameterAs2.value,2)}</td>
                                        <td>{roundNumber(checkCapacity.spaceAs2.value,2)}</td>
                                        {
                                            results.defaultResults.lbdCalculation &&
                                            <Fragment>
                                                {
                                                    results.defaultResults.negativeMoment
                                                    ?
                                                    <Fragment>
                                                        {results.lbd && isNumber(results.lbd.tension) && <td>{roundNumber(results.lbd.tension,0)}</td>}
                                                    </Fragment>                                            
                                                    :
                                                    <Fragment>
                                                        {results.lbd && isNumber(results.lbd.compression) && <td>{roundNumber(results.lbd.compression,0)}</td>}
                                                    </Fragment>
                                                }
                                            </Fragment>
                                        }
                                    </tr>
                                </tbody>
                            </Table>
                            :
                            <p>Zbrojenie górne nie jest wymagane</p>
                        }
                    </Fragment>
                    <Fragment>
                        <div className={styles.margins}>
                            <h2>Zbrojenie dolne ({ results.result.x > results.defaultResults.a2 ? results.defaultResults.negativeMoment ? 'ściskane' : result.x > d ? 'ściskane' : 'rozciągane' : 'rozciągane' }) A<sub>s1</sub></h2>
                        </div>
                        {
                            results.result.as1 > 0 
                            ?
                            <Table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th>
                                            Nr<br></br>[-]
                                        </th>
                                        <th>
                                            Liczba prętów<br></br>[-]
                                        </th>
                                        <th>
                                            Średnica<br></br>[mm]
                                        </th>
                                        <th>
                                            Powierzchnia
                                            <br></br>[cm<sup>2</sup>]
                                        </th>
                                        <th>
                                            Stopień zbrojenia
                                            <br></br>[%]
                                        </th>
                                        <th>
                                            Rozstaw
                                            <br></br>[mm]
                                        </th>
                                        <th>
                                            Odstęp
                                            <br></br>[mm]
                                        </th>
                                        {
                                            results.defaultResults.lbdCalculation && 
                                            <Fragment>
                                                {
                                                    results.defaultResults.negativeMoment
                                                    ?
                                                    <Fragment>
                                                        {
                                                        results.lbd && isNumber(results.lbd.compression) &&
                                                        <th title='Długość zakotwienia pręta ściskanego'>
                                                            l<sub>bd-</sub><br></br>[mm]
                                                        </th>  
                                                        }
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        {
                                                            results.lbd && isNumber(results.lbd.tension) &&
                                                            <th title='Długość zakotwienia pręta rozciąganego'>
                                                                l<sub>bd+</sub><br></br>[mm]
                                                            </th>                                               
                                                        }
                                                    </Fragment>
                                                }
                                            </Fragment>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>{checkCapacity.rebarNumberAs1.value}</td>
                                        <td>{checkCapacity.rebarDiameterAs1.value}</td>
                                        <td>{checkCapacity.rebarAreaAs1.value}</td>
                                        <td>{roundNumber(100*checkCapacity.rebarAreaAs1.value/(diameter*b*0.01),2)}</td>
                                        <td>{roundNumber(checkCapacity.spaceAs1.value + checkCapacity.rebarDiameterAs1.value,2)}</td>
                                        <td>{roundNumber(checkCapacity.spaceAs1.value,2)}</td>
                                        {
                                            results.defaultResults.lbdCalculation && 
                                            <Fragment>
                                                {
                                                    results.defaultResults.negativeMoment
                                                    ?
                                                    <Fragment>
                                                        {results.lbd && isNumber(results.lbd.compression) && <td>{roundNumber(results.lbd.compression,0)}</td>}
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        {results.lbd && isNumber(results.lbd.tension) && <td>{roundNumber(results.lbd.tension,0)}</td>}
                                                    </Fragment>
                                                }
                                            </Fragment>
                                        }   
                                    </tr>
                                </tbody>
                            </Table>  
                            :
                            <p>Zbrojenie dolne nie jest wymagane</p>
                        }
                    </Fragment>
                </Fragment>
            }
        </div>
    ) 
}

export default SummaryResults