import { useContext, useEffect, Fragment } from 'react';
import { paymentsContext } from '../../../context/AdminPayments';
import PaymentsTable from './PaymentsTable';
import styles from './index.module.css';
import { Row } from 'react-bootstrap';
import { Loading, MainAlertAndLoading } from '../../../components';

const PaymentsList = () => {
    const { globalAlerts, globalLoading, payments } =  useContext(paymentsContext);

    useEffect(() => {
        document.title = `GeoInCloud | Lista płatności`;

        return () => {
            document.title = '';
        };
    }, []);

    return (
        <Fragment>
            <Row className={styles.row}>
                <PaymentsTable
                    onChangeDateRange={payments.onChangeDateRange}
                    endDate={payments.state.endDate}
                    startDate={payments.state.startDate}
                    onClearDateInput={payments.onClearDateInput}
                    dataToPaginate={payments.state.paymentsList}
                />
            </Row>
            <MainAlertAndLoading
                alerts={globalAlerts.state.alerts}
                updateAlerts={globalAlerts.updateState}
            />
            {globalLoading.state.changeInvoiceStatus && (
                <div className={styles.loadingWrapper}>
                    <Loading text="" />
                </div>
            )}
        </Fragment>
    );
};

export default PaymentsList;
