import React from 'react';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { isPassword } from '../../../config/ProjectConfig';

const UL = styled.ul`
    padding-left: 0;
    padding-top: 5px;
    margin-bottom: 0;
    color: ${({ valid }) => valid === false && 'red'};
    color: ${({ valid }) => valid && '#28a745'};
`;
const LI = styled.li`
    list-style: none;
`;
const InputPassword = styled.input`
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    border-color: ${({ valid }) => valid === false && 'red'};
    border-color: ${({ valid }) => valid && '#28a745'};

    &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
`;

const Password = ({ onChange, password: { value, isValid } }) => {
    return (
        <Form.Group controlId="password">
            <Form.Label>Nowe hasło</Form.Label>
            <InputPassword
                valid={isValid}
                type="password"
                name="password"
                value={value}
                placeholder="Hasło"
                onChange={(e) =>
                    onChange(
                        e,
                        e.currentTarget.closest('input').checkValidity() &&
                            isPassword.test(
                                e.currentTarget.closest('input').value,
                            ),
                    )
                }
                required></InputPassword>
            {isValid === false && (
                <div>
                    <UL valid={isValid}>
                        <LI>
                            Hasło musi mieć co najmniej jedną cyfrę oraz znak
                            specjalny ! @ # $ % ^ &#38; *
                        </LI>
                        <LI>Hasło musi mieć co najmniej 10 znaków</LI>
                        <LI>Hasło jest obowiązkowe</LI>
                    </UL>
                </div>
            )}
        </Form.Group>
    );
};

export default Password;
