import React from 'react';
import NavCategory from './SidebarCategory';
import NavItem from './CategoryItem';
import { useLocation } from 'react-router-dom';
import config from '../../../config/config';
import { Switcher } from '../../../components';

import styles from './index.module.css';

import Reinforced_light from '../../../assets/icons/Reinforced_light.svg';
import Piles_light from '../../../assets/icons/Piles_light.svg';
import Cpt_light from '../../../assets/icons/CPT_light.svg';
import AzCPT_light from '../../../assets/icons/Az_CPT_light.svg';
import Kx_light from '../../../assets/icons/Spring_light.svg';
import Micropiles_light from '../../../assets/icons/Micropiles_light.svg';
import Compression_light from '../../../assets/icons/Compression_light.svg';
import Graph_light from '../../../assets/icons/Graph_light.svg';
import Schedule_light from '../../../assets/icons/Schedule_light.svg';
import Waling_light from '../../../assets/icons/Waling_light.svg'

import Reinforced from '../../../assets/icons/Reinforced.svg';
import Piles from '../../../assets/icons/Piles.svg';
import Cpt from '../../../assets/icons/CPT.svg';
import AzCPT from '../../../assets/icons/Az_CPT.svg';
import Kx from '../../../assets/icons/Spring.svg';
import Micropiles from '../../../assets/icons/Micropiles.svg';
import Compression from '../../../assets/icons/Compression.svg';
import Graph from '../../../assets/icons/Graph.svg';
import Schedule from '../../../assets/icons/Schedule.svg';
import Waling from '../../../assets/icons/Waling.svg'

const Sidebar = ({ firstName, lastName, email, report = {}, onToggle, mode }) => {
    const { routes } = config;
    const location = useLocation();
    const { pathname } = location;

    return (
        <div id={'sidebar-wrapper'} className={`${styles.wrapper}`}>
            <div className={styles.overflowSidebar}>
                <nav className={styles.nav}>
                    <div className={styles.navContainer}>
                        <div className={styles.brand}>
                            {report.logo && (
                                <div className={`${styles.avatar} avatar`}>
                                    <img
                                        alt="Logo raportów pdf"
                                        title="Logo raportów pdf"
                                        src={report.logo}
                                    />
                                </div>
                            )}

                            <div className={styles.info}>
                                <span
                                    className={
                                        styles.uppercase
                                    }>{`${firstName} ${lastName}`}</span>
                                <span>{email}</span>
                            </div>
                            <div className={styles.switchMode}>
                                <Switcher />
                            </div>
                        </div>
                        <div
                            className={`${styles.toggleOpen} sidebar-open-icon`}
                            onClick={onToggle}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <NavCategory label="Projekty">
                            <NavItem
                                icon={mode === 'dark' ? Schedule : Schedule_light}
                                active={pathname}
                                to={routes.dashboard.projects}
                                title="Zestawienie projektów">
                                Zestawienie projektów
                            </NavItem>
                            <NavItem
                                icon={mode === 'dark' ? Graph : Graph_light}
                                active={pathname}
                                to={routes.dashboard.charts}
                                title="Wykresy nośności">
                                Wykresy nośności
                            </NavItem>
                        </NavCategory>
                        <NavCategory label="Pale">
                            <NavItem
                                icon={mode === 'dark' ? Kx : Kx_light}
                                active={pathname}
                                to={routes.dashboard.koseckiMethodModel}
                                toResults={routes.dashboard.koseckiMethodResults}
                                title="Metoda M. Koseckiego">
                                Kx - metoda uogólniona
                            </NavItem>
                            <NavItem
                                icon={mode === 'dark' ? Piles : Piles_light}
                                active={pathname}
                                to={routes.dashboard.verticalCapacityModel}
                                toResults={routes.dashboard.verticalCapacityResults}
                                title="Nośność pionowa">
                                Nośność - PN-83/B-02482 + EC7
                            </NavItem>
                            <NavItem
                                icon={mode === 'dark' ? Cpt : Cpt_light}
                                active={pathname}
                                to={routes.dashboard.pileCptModel}
                                toResults={routes.dashboard.pileCptResults}
                                title="Nośność pionowa CPT">
                                Nośność pala CPT
                            </NavItem>
                        </NavCategory>
                        <NavCategory label="Mikropale">
                            <NavItem
                                icon={mode === 'dark' ? Micropiles : Micropiles_light}
                                active={pathname}
                                to={routes.dashboard.micropilesModel}
                                toResults={routes.dashboard.micropilesResults}
                                title="Mikropal kotwiący">
                                Mikropal kotwiący
                            </NavItem>
                            <NavItem
                                icon={mode === 'dark' ? Compression : Compression_light}
                                active={pathname}
                                to={routes.dashboard.micropilesCompressionModel}
                                toResults={routes.dashboard.micropilesCompressionResults}
                                title="Mikropal wciskany/wyciągany">
                                Mikropal wciskany/wyciągany
                            </NavItem>
                        </NavCategory>
                        <NavCategory label="Ścianka szczelna">
                            <NavItem
                                icon={mode === 'dark' ? AzCPT : AzCPT_light}
                                active={pathname}
                                to={routes.dashboard.sheetPileCptModel}
                                toResults={routes.dashboard.sheetPileCptResults}
                                title="Nośność pionowa ścianki szczelnej">
                                Ścianka szczelna CPT
                            </NavItem>
                            <NavItem
                                icon={mode === 'dark' ? Waling : Waling_light}
                                active={pathname}
                                to={routes.dashboard.walingModel}
                                title="Nośność kleszcza stalowego od siły kotwienia">
                                Kleszcz stalowy
                            </NavItem>
                        </NavCategory>
                        <NavCategory label="Inne">
                            <NavItem
                                icon={mode === 'dark' ? Reinforced : Reinforced_light}
                                active={pathname}
                                to={routes.dashboard.reinforcementModel}
                                toResults={routes.dashboard.reinforcementResults}
                                title="Zbrojenie przekroju EC2">
                                Zbrojenie przekroju
                            </NavItem>
                        </NavCategory>
                    </div>
                </nav>
            </div>
        </div>
    );
};
export default Sidebar;
