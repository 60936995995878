const rebarSteelClasses = [
    {
        name: 'BSt 500 KR',
        minD: 6,
        maxD: 12,
        fyk: 500,
        ft: 550,
        class: 'A',
        euk: 2.5
    },
    {
        name: 'B 500 A',
        minD: 4,
        maxD: 16,
        fyk: 500,
        ft: 550,
        class: 'A',
        euk: 2.5
    },
    {
        name: 'BSt 500 S(A)',
        minD: 6,
        maxD: 14,
        fyk: 500,
        ft: 550,
        class: 'A',
        euk: 2.5
    },
    {
        name: 'RB 500',
        minD: 4,
        maxD: 16,
        fyk: 500,
        ft: 550,
        class: 'A',
        euk: 2.5
    },
    {
        name: 'RB 500 W',
        minD: 6,
        maxD: 40,
        fyk: 500,
        ft: 550,
        class: 'A',
        euk: 2.5
    },
    {
        name: 'BSt 500 S(B)',
        minD: 6,
        maxD: 40,
        fyk: 500,
        ft: 550,
        class: 'B',
        euk: 5
    },
    {
        name: 'RB 500 (B)',
        minD: 6,
        maxD: 40,
        fyk: 500,
        ft: 550,
        class: 'B',
        euk: 5
    },
    {
        name: 'RB 400',
        minD: 6,
        maxD: 40,
        fyk: 400,
        ft: 550,
        class: 'B',
        euk: 5
    },
    {
        name: 'RB 400 W',
        minD: 6,
        maxD: 40,
        fyk: 400,
        ft: 440,
        class: 'B',
        euk: 5
    },
    {
        name: 'RB 500 WZ',
        minD: 8,
        maxD: 32,
        fyk: 500,
        ft: 550,
        class: 'B',
        euk: 5
    },
    {
        name: 'BSt 500 WR',
        minD: 8,
        maxD: 32,
        fyk: 500,
        ft: 550,
        class: 'B',
        euk: 5
    },
    {
        name: '34GS',
        minD: 10,
        maxD: 32,
        fyk: 410,
        ft: 550,
        class:'B',
        euk: 5
    },
    {
        name: 'B 500 SP',
        minD: 8,
        maxD: 40,
        fyk: 500,
        ft: 575,
        class: 'C',
        euk: 7.5
    }, 
    {
        name: '35G2Y',
        minD: 6,
        maxD: 20,
        fyk: 410,
        ft: 550,
        class: 'C',
        euk: 7.5
    },
    {
        name: '20G2VY-b',
        minD: 6,
        maxD: 28,
        fyk: 490,
        ft: 590,
        class: 'C',
        euk: 7.5
    }
]
//Wytrzymałość charakterystyczna stali na rozciąganie ft [MPa]
export default rebarSteelClasses