import { Fragment } from "react";
import styles from './index.module.css';
import { roundNumber } from "../../../../../utils";

const SecondOrder = ({ parameters={} }) => {
    return ( 
        <div className={styles.wrapper}>
            <h2>Imperfekcje geometryczne i efekty drugiego rzędu</h2>
            <p>Imperfekcje geometryczne: <span className={styles.value}>{parameters.ea.value ? 'Tak' : 'Nie'}</span></p>
            <p>Efekty II-go rzędu: <span className={styles.value}>{parameters.secondOrder.value ? 'Tak' : 'Nie'}</span></p>
            <p>Długość elementu [l]: <span className={styles.value}>{roundNumber(parameters.l.value,2)} m</span></p>
            <p>Współczynnik wyboczeniowy: <span className={styles.value}>{roundNumber(parameters.bucklingRatio.value,2)}</span></p>
            <p>Długość wyboczeniowa elementu [l<sub>eff</sub>]: <span className={styles.value}>{roundNumber(parameters.leff.value,2)} m</span></p>

            {
                parameters.secondOrder.value &&
                <Fragment>
                    
                    <p>Metoda: <span className={styles.value}>{parameters.nominalCurvature.value 
                        ? 'Metoda nominalnej krzywizny' 
                        : parameters.nominalStiffness.value 
                            ?
                            'Metoda nominalnej sztywności' 
                            :
                            'Brak'
                    }</span></p>
                    <p>Moment zginający I-go rzędu [M<sub>0,1</sub>]: <span className={styles.value}>{roundNumber(parameters.m01.value,2)} kNm</span></p>
                    <p>Moment zginający I-go rzędu [M<sub>0,2</sub>]: <span className={styles.value}>{roundNumber(parameters.m02.value,2)} kNm</span></p>
                    <p>Końcowy współczynnik pełzania [&phi; (&#8734;, t<sub>0</sub>)]: <span className={styles.value}>{roundNumber(parameters.finalPhi.value,2)}</span></p>
                    {
                        parameters.nominalCurvature.value &&
                        <p>Współczynnik rozkładu krzywizny [c]: <span className={styles.value}>{parameters.c.value}</span></p>
                    }
                    {
                        parameters.nominalStiffness.value &&
                        <p>Współczynnik rozkładu momentu zginającego [c<sub>0</sub>]: <span className={styles.value}>{parameters.c0.value}</span></p>
                    }
                    <p>* |M<sub>0,2</sub>| &gt; |M<sub>0,1</sub>|</p>
                </Fragment>
            }
        </div>
    )
}

export default SecondOrder