import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import { useIsMountedState } from '../../hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';

const urlReplacer = (url = '', paramsObject = {}) => {
    return Object.keys(paramsObject).length > 0
        ? url
              .replace(/(?<=(:))\w+/g, ($1) => paramsObject[$1])
              .replace(/:/g, '')
        : url;
};

const withLoader = ({ component, fetch = [], localState = {} }) => {
    const Component = component;

    const defaultState = {
        loadingName: 'Wczytuję...',
        isLoaded: false,
        loadersLoaded: [],
    };
    const defaultLocalState = (Array.isArray(fetch) ? fetch : [fetch]).reduce(
        (acc, ele) => {
            acc[ele.name] = {
                responseData: {},
                isLoaded: false,
                loadingName: ele.loadingName || '',
            };
            return acc;
        },
        {},
    );
    const defaultMessageState = (Array.isArray(fetch) ? fetch : [fetch]).reduce(
        (acc, ele) => {
            acc[ele.name] = {
                error: false,
                open: false,
                message: false,
                success: false,
                emptyCollection:
                    ele.emptyCollection || 'Brak elementów do wyświetlenia',
            };
            return acc;
        },
        {},
    );

    return (props) => {
        const params = useParams();

        const [formState, setFormState] = useState({
            ...defaultState,
            ...defaultLocalState,
            ...localState,
        });
        const [messageState, setMessageState] = useState({
            ...defaultMessageState,
        });
        const { pathname = '' } = useLocation();
        const isMountedState = useIsMountedState();

        useDeepCompareEffect(() => {
            const allLoadersLoaded = formState.loadersLoaded.every(
                (ele) => ele === true,
            );
            if (
                allLoadersLoaded &&
                formState.loadersLoaded.length === fetch.length
            ) {
                setFormState((state) => ({
                    ...state,
                    isLoaded: true,
                    loadersLoaded: [],
                }));
            }
        }, [formState]);

        useDeepCompareEffect(() => {

            fetch.forEach((element) => {
                setFormState((state) => ({
                    ...state,
                    [element.name]: { ...state[element.name], isLoaded: false },
                }));

                axios({
                    url: urlReplacer(
                        element.url,
                        params,
                    ),
                    method: 'GET',
                })
                    .then((response) => {
                        if (isMountedState.current) {
                            setFormState((state) => ({
                                ...state,
                                loadersLoaded: [...state.loadersLoaded, true],
                                [element.name]: {
                                    ...state[element.name],
                                    responseData: response.data,
                                    status: response.status,
                                    statusText: response.statusText,
                                    isLoaded: true,
                                },
                            }));

                            setMessageState((state) => ({
                                ...state,
                                [element.name]: {
                                    ...state[element.name],
                                    success: true,
                                    open: element.showSuccessMessage,
                                    message:
                                        element.successMessage ||
                                        response.data.message,
                                },
                            }));
                        }
                    })
                    .catch((error) => {
                        if (isMountedState.current) {
                            setFormState((state) => ({
                                ...state,
                                loadersLoaded: [...state.loadersLoaded, true],
                                [element.name]: {
                                    ...state[element.name],
                                    responseData: error.response.data,
                                    status: error.response.status,
                                    statusText: error.response.statusText,
                                    isLoaded: true,
                                },
                            }));

                            setMessageState((state) => ({
                                ...state,
                                [element.name]: {
                                    success: false,
                                    open: element.showErrorMessage,
                                    message:
                                        element.errorMessage ||
                                        error.response.data.message,
                                },
                            }));
                        }
                    });
            });
        }, [pathname, isMountedState, props.match]);

        return (
            <Component
                fetchData={formState}
                messageState={messageState}
                {...props}
            />
        );
    };
};

export default withLoader;
