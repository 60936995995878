import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
} from '@fortawesome/free-regular-svg-icons';
import { isNumber, roundNumber, setSoilColorQc } from '../../../../../utils';
import SoilLayer from '../../Results/CptCapacityChart/SoilProfile/SoilLayer';
import styles from './index.module.css';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';


const CapacityChart = ({
    soilProfile,
    results = [],
    pileTypeNumber,
    title,
    type="nt",
    groundLevel,
    currentPileLength,
    currentCapacity={},
    pileHeadSpot,
    diameterPile,
    yt,
    charLoad,
    hPile
}) => {
    const soilProfileRef = useRef(null);
    const resultsRef = useRef(null);
    const [soilLayer, setSoilLayer] = useState([]);
    const [resultsWidth, setResultsWidth] = useState(0);
    const [soilProfileHeight, setSoilProfileHeight] = useState(0);
    const [profilePileLength, setProfilePileLength] = useState('');
    const [profilePileHeadSpot, setProfilePileHeadSpot] = useState('');
    const [axisNtScale, setAxisNtScale] = useState([]);
    const [maxNt, setMaxNt] = useState('');
    const [maxHeight, setMaxHeight] = useState(0);
    const [currentNt, setCurrentNt] = useState('');
    const [pileBaseSpot, setPileBaseSpot] = useState('');

    useEffect(() => {
        if (resultsRef.current) {
            setResultsWidth(resultsRef.current.offsetWidth*0.9);
        }
    }, []);

    useDeepCompareEffect(() => {
        setMaxHeight(Math.max(...soilProfile.map(ele => ele.z)));
    }, [soilProfile]);

    useDeepCompareEffect(() => {
        if (soilProfileRef.current) {
            const offsetHeight = soilProfileRef.current.offsetHeight;
            const layers = soilProfile.map((ele, i) => {
                return (
                    <SoilLayer
                        key={i}
                        backgroundColor={setSoilColorQc({
                            type: ele.soilType,
                            qc: ele.qc,
                        })}
                        height={
                            maxHeight === 0
                                ? 0
                                : offsetHeight * ele.absHeight / maxHeight
                        }
                    />
                );
            });
            setSoilLayer(layers);
            setSoilProfileHeight(offsetHeight);
        }
    }, [soilProfile, soilProfileRef, maxHeight]);

    useDeepCompareEffect(() => {
        const nt = Math.max(...results.map((ele) => type === 'nw' ? ele.Rtd_upper : ele.Rcd_upper ));
        const maxAxisNt = roundNumber(nt - (nt % 5), 0);

        const initPileLength = isNumber(currentPileLength) && currentPileLength > 0
            ? currentPileLength
            : roundNumber(maxHeight - pileHeadSpot,2);

        setAxisNtScale(Array(5).fill(maxAxisNt / 5).reduce((acc, ele, idx) => [...acc, ele * (idx + 1)], []));        
        setMaxNt(nt);

        setPileBaseSpot((state) =>
            isNumber(state) && state > pileHeadSpot
                ? state
                : roundNumber(initPileLength + pileHeadSpot,2),
        );

        setCurrentNt(() => type === 'nw' ? currentCapacity.Rtd_current : currentCapacity.Rcd_current);

        if (soilProfileRef.current) {
            setProfilePileHeadSpot(
                maxHeight === 0
                    ? 0
                    : (soilProfileHeight * pileHeadSpot) / maxHeight,
            );
            setProfilePileLength(maxHeight === 0 ? 0 : (soilProfileHeight * initPileLength) / maxHeight);
        }
    }, [
        maxHeight,
        soilProfileHeight,
        currentPileLength,
        currentCapacity,
        pileHeadSpot,
        results,
        type,
        soilProfileRef
    ]);

    return (
        <Fragment>
            <div className={styles.chartWrapper}>
                <div className={styles.title}>
                    <h2>{title}</h2>
                </div>
                <div className={styles.wrapper} ref={soilProfileRef}>
                    <div className={styles.pileWrapperCapacityView}
                        style={{
                            height: `${profilePileLength}px`,
                            top: `${profilePileHeadSpot}px`,
                        }}
                    >
                        <div className={styles.pileCapacityView}>
                            {
                            (pileTypeNumber === 4 || pileTypeNumber === 5 || pileTypeNumber === 2)
                            ?
                            <div className={`${styles.pileShapeCapacityView} ${styles.circleCapacityView}`}></div>
                            :
                                (pileTypeNumber === 3)
                                ?
                                <div className={`${styles.pileShapeCapacityView} ${styles.openEndedCapacityView}`}>
                                    <div className={`${styles.openEndedInnerCapacityView}`}></div>
                                </div>
                                :
                                (pileTypeNumber === 6)
                                ?
                                <div className={`${styles.hWrapper}`}>
                                    <div className={`${styles.hTypeFlange}`}></div>
                                    <div className={`${styles.hTypeHeight}`}></div>
                                    <div className={`${styles.hTypeFlange}`}></div>
                                </div>
                                :
                                (pileTypeNumber === 7)
                                ?
                                <div className={styles.wrapper2H}>
                                    <div className={`${styles.hWrapper}`}>
                                        <div>
                                            <div className={`${styles.hTypeFlange}`}></div>
                                            <div className={`${styles.hTypeHeight}`}></div>
                                            <div className={`${styles.hTypeFlange}`}></div>
                                        </div>
                                        <div>
                                            <div className={`${styles.hTypeFlange}`}></div>
                                            <div className={`${styles.hTypeHeight}`}></div>
                                            <div className={`${styles.hTypeFlange}`}></div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className={`${styles.pileShapeCapacityView}`}></div>
                            }
                        
                            <div className={styles.pileLength}>
                                <p>
                                    L ={' '}
                                    {roundNumber(currentPileLength, 2)} m
                                </p>
                            </div>
                        </div>
                        <div className={styles.spotWrapper}>
                            <span className={styles.pileSpot}>
                                {isNumber(pileHeadSpot) ? pileHeadSpot.toFixed(2) : ''}
                                <span className={styles.spanBlock}>
                                    {(isNumber(pileHeadSpot) && isNumber(groundLevel)) ? `(${(groundLevel - pileHeadSpot).toFixed(2)})`: ''}
                                </span>
                            </span>
                            <span className={styles.pileSpot}>
                                {isNumber(pileBaseSpot) ? pileBaseSpot.toFixed(2) : ''}
                                <span className={styles.spanBlock}>
                                    {(isNumber(pileBaseSpot) && isNumber(groundLevel)) ? `(${(groundLevel - pileBaseSpot).toFixed(2)})`: ''}
                                </span>
                            </span>
                        </div>
                        <div className={styles.results} ref={resultsRef}>
                            <div className={styles.horizontalAxis}>
                                {axisNtScale.map((ele, idx) => {
                                    return (
                                        <span key={idx} className={styles.axisCoord}
                                            style={{left: `${(resultsWidth / maxNt)*ele}px`}}>
                                            <p className={ styles.value }>
                                                {ele}
                                            </p>
                                        </span>
                                    );
                                })}
                                <span className={styles.caret}>
                                    <p className={styles.axisDescription}>
                                        {
                                            type === 'nw'
                                            ?
                                            <Fragment>R<sub>t,d</sub>[kN]</Fragment>
                                            :
                                            <Fragment>R<sub>c,d</sub>[kN]</Fragment>
                                        }
                                        
                                    </p>
                                </span>
                            </div>
                            <div className={styles.verticalAxis}>
                                <span className={styles.verticalCaret}>
                                    <p className={styles.axisVerticalDescription}>Z[m]</p>
                                </span>
                            </div>
                            <div className={styles.currentHorizontalValue} 
                                style={{ 
                                    top: `${profilePileLength}px`,
                                    width: `${(resultsWidth * currentNt) / maxNt}px`,
                                }}
                            >
                                <div className={styles.line}></div>
                            </div>
                            <div className={styles.currentVerticalValue}
                                style={{
                                    height: `${profilePileLength}px`,
                                    left: `${
                                        (resultsWidth * currentNt) /
                                        maxNt
                                    }px`,
                                }}>
                                    <div className={styles.lineVertical}></div>
                            </div>
                            <svg className={styles.svgWrapper}>
                                {results.map((ele, i, a) => {
                                    return (
                                        <Fragment key={i}>
                                        {
                                            i < (a.length - 1)
                                            &&
                                            <Fragment key={i}>
                                                {
                                                    type === 'nt'
                                                    ?
                                                    <polyline 
                                                        className={styles.svgLine} 
                                                        points={`
                                                            ${(resultsWidth*ele.Rcd_current) / maxNt}, ${(soilProfileHeight *(ele.z - pileHeadSpot)) /maxHeight} 
                                                            ${(resultsWidth*a[i+1].Rcd_current) / maxNt}, ${(soilProfileHeight *(a[i+1].z - pileHeadSpot)) /maxHeight}`}
                                                    ></polyline>
                                                    :
                                                    <polyline 
                                                        className={styles.svgLine} 
                                                        points={`
                                                            ${(resultsWidth*ele.Rtd_current) / maxNt}, ${(soilProfileHeight *(ele.z - pileHeadSpot)) /maxHeight} 
                                                            ${(resultsWidth*a[i+1].Rtd_current) / maxNt}, ${(soilProfileHeight *(a[i+1].z - pileHeadSpot)) /maxHeight}`}
                                                    ></polyline>
                                                }
                                            </Fragment>
                                            }
                                        </Fragment>
                                    )
                                })}
                            </svg>
                            {
                                currentCapacity.correctConditions &&
                                <Fragment>
                                    {
                                        type === 'nt'
                                        ?
                                        <div className={`${styles.capacityPoint} ${!currentCapacity.correctConditions.depth || !currentCapacity.correctConditions.depthInLayer ? styles.wrongDepth : styles.validDepth}`}
                                            style={{
                                                top: `${(soilProfileHeight *(currentCapacity.z - pileHeadSpot)) /maxHeight}px`,
                                                left: `${(currentCapacity.Rcd_current > 0) ? (resultsWidth*currentCapacity.Rcd_current) / maxNt : 0}px`
                                            }}>
                                                <FontAwesomeIcon
                                                    icon={faCheckCircle}
                                                />
                                        </div>
                                        :
                                        <div className={`${styles.capacityPoint}`}
                                            style={{
                                                top: `${(soilProfileHeight *(currentCapacity.z - pileHeadSpot)) /maxHeight}px`,
                                                left: `${(currentCapacity.Rtd_current > 0) ? (resultsWidth*currentCapacity.Rtd_current) / maxNt : 0}px`}}>
                                                <FontAwesomeIcon
                                                    icon={faCheckCircle}
                                                />
                                        </div>
                                    }
                                </Fragment>
                            }

                        </div>                            
                    </div>

                    <div>
                        {[...soilLayer]}
                    </div>
                </div>
            </div>
            <div className={styles.capacityDescription}>
                {
                    pileTypeNumber === 6
                    ?
                    <Fragment>
                        <p>
                            Wysokość środnika [h]-{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(hPile.h1)
                                        ? roundNumber(hPile.h1, 2)
                                        : ''}{' '}
                                    mm
                                </b>
                            </span>
                        </p>
                        <p>
                            Szerokość półki [b]-{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(hPile.b)
                                        ? roundNumber(hPile.b, 2)
                                        : ''}{' '}
                                    mm
                                </b>
                            </span>
                        </p>
                    </Fragment>
                    :
                    pileTypeNumber === 7
                    ?
                    <Fragment>
                        <p>
                            Wysokość środnika [h]-{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(hPile.h1) ? roundNumber(hPile.h1, 2) : ''}{' '}mm
                                </b>                                
                            </span>
                        </p>
                        <p>
                            Szerokośc półki [b]-{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(hPile.b) ? roundNumber(2*hPile.b, 0).toFixed(0) : ''}{' '}mm
                                </b>                                
                            </span>
                        </p>
                    </Fragment>
                    :
                    <Fragment>
                        <p>
                            {pileTypeNumber === 1 ? 'Szerokość boku [B]' : 'Średnica pala [D]'} -{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(diameterPile)
                                        ? roundNumber(diameterPile, 2).toFixed(2)
                                        : ''}{' '}
                                    m
                                </b>                                
                            </span>
                        </p>
                    </Fragment>
                }
                <p>
                    Długość pala [L] -{' '}
                    <span className={styles.spanValue}>
                        <b>
                            {isNumber(currentPileLength)
                                ? roundNumber(currentPileLength, 2).toFixed(2)
                                : ''}{' '}
                            m
                        </b>
                    </span>
                </p>
                {type === 'nt' && (
                    <Fragment>
                        <p>
                            Nośność podstawy [R<sub>b,d</sub>]-{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    <Fragment>
                                    {
                                        (pileTypeNumber === 4 || pileTypeNumber === 5)
                                        ?
                                        <Fragment>
                                            {isNumber(currentCapacity.Rbk_current_01)
                                                ? roundNumber(currentCapacity.Rbk_current_01/yt, 2).toFixed(2)
                                                : ' '} kN                                                
                                        </Fragment>
                                        :
                                        <Fragment>
                                            {isNumber(currentCapacity.Rbk_current_01D)
                                                ? roundNumber(currentCapacity.Rbk_current_01D/yt, 2).toFixed(2)
                                                : ' '} kN
                                        </Fragment>
                                    }
                                    </Fragment>
                                </b>
                            </span>
                        </p>
                        <p>
                            Nośność pobocznicy [R<sub>s,d</sub>]-{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {
                                        (pileTypeNumber === 4 || pileTypeNumber === 5)
                                        ?
                                        <Fragment>
                                            {isNumber(currentCapacity.Rsk_current_ssg)
                                                ? roundNumber(currentCapacity.Rsk_current_ssg/yt, 2).toFixed(2)
                                                : ''}
                                            kN
                                        </Fragment>
                                        :
                                        <Fragment>
                                            {isNumber(currentCapacity.Rsk_current_01D)
                                                ? roundNumber(currentCapacity.Rsk_current_01D/yt, 2).toFixed(2)
                                                : ''}
                                            kN
                                        </Fragment>
                                    }
                                </b>
                            </span>
                        </p>
                        <p>
                            Nośność obliczeniowa pala na wciskanie [R<sub>c,d</sub>]-{' '}
                            <span className={styles.spanValue}>
                                <b>
                                {isNumber(currentCapacity.Rcd_current)
                                    ? roundNumber(currentCapacity.Rcd_current, 2).toFixed(2)
                                    : ''}{' '}
                                kN
                                </b>
                            </span>
                        </p>
                        <p>
                            Minimalna nośność obliczeniowa na wciskanie [R<sub>c,d,min</sub>]-{' '}
                            <span className={styles.spanValue}>
                                <b>
                                {isNumber(currentCapacity.Rck_lower_01)
                                    ? roundNumber(currentCapacity.Rck_lower_01/yt, 2).toFixed(2)
                                    : ''}{' '}
                                kN
                                </b>
                            </span>
                        </p>
                        <p>
                            Maksymalna nośność obliczeniowa na wciskanie [R<sub>c,d,max</sub>]-{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(currentCapacity.Rck_upper_01)
                                        ? roundNumber(currentCapacity.Rck_upper_01/yt, 2).toFixed(2)
                                        : ''}{' '}
                                    kN
                                </b>
                            </span>
                        </p>
                        <p>
                            Siła do badania próbnego pala [R<sub>c,lt</sub>] -{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(currentCapacity.Rcd_current)
                                        ? roundNumber(currentCapacity.Rcd_current*1.5,2).toFixed(2)
                                        : ''}{' '}
                                    kN
                                </b>
                            </span>
                        </p>
                        <p>
                            Charakterystyczne obciążenie głowicy pala [P] -&nbsp;
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(charLoad)
                                        ? roundNumber(charLoad).toFixed(2)
                                        : ''}{' '}
                                    kN
                                </b>
                            </span>
                        </p>                        
                        <p>
                            Osiadanie głowicy pala [s]-{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(currentCapacity.s)
                                        ? roundNumber(currentCapacity.s*10,2).toFixed(2)
                                        : ''}{' '}
                                    mm
                                </b>
                            </span>
                        </p>
                    </Fragment>
                )}
                {type === 'nw' && (
                    <Fragment>
                        <p>
                            Nośność obliczeniowa na wyciąganie [R<sub>t,d</sub>]-{' '}
                            <span className={styles.spanValue}>
                                <b>
                                {isNumber(currentCapacity.Rtd_current)
                                    ? roundNumber(currentCapacity.Rtd_current, 2).toFixed(2)
                                    : ''}{' '}
                                kN
                                </b>
                            </span>
                        </p>
                        <p>
                            Minimalna nośność obliczeniowa na wyciąganie [R<sub>t,d,min</sub>]-{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(currentCapacity.Rtd_lower)
                                        ? roundNumber(currentCapacity.Rtd_lower, 2).toFixed(2)
                                        : ''}{' '}
                                    kN
                                </b>
                            </span>
                        </p>
                        <p>
                            Maksymalna nośność obliczeniowa na wyciąganie [R<sub>t,d,max</sub>]-{' '}
                            <span className={styles.spanValue}>
                                <b>
                                    {isNumber(currentCapacity.Rtd_upper)
                                        ? roundNumber(currentCapacity.Rtd_upper, 2).toFixed(2)
                                        : ''}{' '}
                                    kN
                                </b>
                            </span>
                        </p>
                    </Fragment>
                )}                
            </div>
        </Fragment>
    );
};

export default React.memo(
    CapacityChart,
    (prev, next) => stringify(prev) === stringify(next),
);
