import SoilProfile from "./SoilProfile"


const MicropileCapacityChart = ({ 
    dataToChart,
    openSidebar
 }) => {

    return (
        <SoilProfile 
            openSidebar={openSidebar}
            soilProfile={dataToChart.soilTable}
            results={dataToChart.results}
            steelRod={dataToChart.steelRod}
            calculationParameters = {dataToChart.calculationParameters}
            initCurrentMicropileLength={dataToChart.currentMicropileLength}
            onChangeProdMicropileLength = {dataToChart.onChangeProdMicropileLength}
            onChangeCurrentMicropileLength = {dataToChart.onChangeCurrentMicropileLength}
            onChangeRodModule={dataToChart.onChangeRodModule}
            rodModule={dataToChart.rodModule}
        />
    )
}

export default MicropileCapacityChart