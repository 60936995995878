import stringify from 'fast-json-stable-stringify';
import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { InputField, SectionTitle } from '../../../../components';
import styles from './index.module.css';

const PileLoads = ({ state, onBlur, onChange }) => {
    return (
        <Fragment>
            <SectionTitle title="Obliczeniowe obciążenie głowicy pala" />
            <Row>
                <Col xs={7}>
                    <Row>
                        <Col xs={12} className={styles.columnSpace}>
                            <InputField
                                name="horizontalForce"
                                onChange={onChange}
                                onBlur={onBlur}
                                value={state.horizontalForce.value}
                                isValid={state.horizontalForce.isValid}
                                placeholder="Siła pozioma"
                                type="number"
                                title={'Siła pozioma [kN]'}
                                required
                            />
                        </Col>
                        <Col xs={12} className={styles.columnSpace}>
                            <InputField
                                name="bendingMoment"
                                onChange={onChange}
                                onBlur={onBlur}
                                value={state.bendingMoment.value}
                                isValid={state.bendingMoment.isValid}
                                placeholder="Moment zginający"
                                type="number"
                                title={'Moment zginający [kNm]'}
                                required
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={5}>
                    <div className={styles.bendingMoment}>
                        <p
                            className={`${styles.loadsDescription} ${styles.momentDescription}`}>
                            M(+)
                        </p>
                    </div>
                    <div className={styles.horizontalForce}>
                        <p
                            className={`${styles.loadsDescription} ${styles.horizontalDescription}`}>
                            P(+)
                        </p>
                    </div>
                    <div className={styles.foundation}></div>
                </Col>
            </Row>
        </Fragment>
    );
};

export default React.memo(
    PileLoads,
    (prev, next) => stringify(prev) === stringify(next),
);
