import { faFolderOpen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Pagination } from '../../../../components';
import { withPagination } from '../../../../hoc';

import styles from './index.module.css';

const ProjectsList = ({ 
    onOpenProjectModal, 
    isUserRole,
    id,
    currentProjectId,
    dataToPaginate=[], 
    pagination, 
    paginationHelpers
 }) => {

    return (
        <Fragment> 
            <Row className={styles.row}>
                <Col xs={12}>
                    {
                        dataToPaginate.length > 0
                        ?
                        <Fragment>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th className={styles.lp}>Nr</th>
                                        <th className={styles.projectName} title="Nazwa obliczeń">Nazwa obliczeń</th>
                                        <th className={styles.profileName} title="Nazwa profilu">Profil grutnowy</th>
                                        <th className={styles.data} title="Data zapisu">Data zapisu</th>
                                        <th></th>
                                        {
                                            (isUserRole || (currentProjectId === id)) &&
                                            <th></th>
                                        }
                                    </tr>
                                </thead>
                                <tbody className={styles.tbody}>
                                    {
                                        dataToPaginate.map((ele, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className={styles.lp}>{paginationHelpers.countingItemsPerPage + index+1}.</td>
                                                    <td className={styles.projectName}>{ele.projectName}</td>
                                                    <td className={styles.profileName}>{ele.profileName ? ele.profileName : '-'}</td>
                                                    <td className={styles.data}>{new Date(ele.updatedAt).toLocaleString('pl-PL', { dateStyle: 'medium',timeStyle: 'short' })}</td>
                                                    <td>
                                                        <button className={styles.load} title="Otwórz projekt" name="openProject" data-id={ele.id} data-project={ele.projectName} onClick={onOpenProjectModal}>
                                                            <FontAwesomeIcon icon={faFolderOpen}/>
                                                        </button>
                                                    </td>
                                                    {
                                                        (isUserRole || (currentProjectId === id)) &&
                                                        <td>
                                                            <button className={styles.delete} title="Usuń projekt" name="deleteProject" data-id={ele.id} data-project={ele.projectName} onClick={onOpenProjectModal}>
                                                                <FontAwesomeIcon icon={faTrashAlt}/>
                                                            </button>
                                                        </td>                                                        
                                                    }

                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                paginationHelpers.totalPages > 1 &&
                                <Pagination
                                    currentPage={pagination.currentPage} 
                                    itemsPerPage={pagination.itemsPerPage} 
                                    totalItems={paginationHelpers.allItemsLength} 
                                    nextPage={paginationHelpers.nextPage}

                                    css={styles.paginationWrapper}
                                />
                            }                            
                        </Fragment>
                        :
                        <p className={styles.center}>Brak zapisanych obliczeń</p>
                    }

                </Col>
            </Row>
        </Fragment>
    )
}

export default withPagination({
    component: ProjectsList,
    returnAllCollection: false,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 15,
    }
}) 