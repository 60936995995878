import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle` 
    body {
        background: ${({ theme }) => theme.background};
        color: ${({ theme }) => theme.color};

        & .loadingColor {
            color: ${({ theme }) => theme.loadingColor};
            border-color: ${({ theme }) => theme.loadingColor};
        }

        & .shadow-rows {
            background: ${({ theme }) => theme.shadowRowBackground};
        }
        & .oppositeLoadingColors {
            color: ${({ theme }) => theme.oppositeLoadingColor};
            border-color: ${({ theme }) => theme.oppositeLoadingColor};
        }
        & .apex-chart-color {
            fill: ${({ theme }) => theme.chart.color} !important;
        }
        & .apexcharts-canvas {
            background: ${({ theme }) => theme.chart.background} !important;

            & text {
                fill: ${({ theme }) => theme.color};
            }

            & .apexcharts-datalabels {
                & text {
                    fill: #000;
                }
            }

        }
        & .statsWrapper {
            position: relative; 

            & .stat {
                position: relative; 
                width: 60px;
                height: 60px;
                border-radius: 100%;
                color: ${({ theme }) => theme.stats.color};
            }

            & .stat1 {
                background: ${({ theme }) => theme.stats.iconBackround1};
            }
            & .stat2 {
                background: ${({ theme }) => theme.stats.iconBackround2};
            }
            & .stat3 {
                background: ${({ theme }) => theme.stats.iconBackround3};
            }

            & svg {
                position: absolute;
                left: 50%;
                top: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
        }

        & .titleColorClass {
            color: ${({ theme }) => theme.titleColor};
        }

        & .defaultBox {
            color: inherit;
            padding: 5px;
            background: ${({ theme }) => theme.elementsBackground};
            border: ${({ theme }) => theme.border};
        }
        & .customDropdown {
            color: inherit;
            background: ${({ theme }) => theme.elementsBackground};
            border: ${({ theme }) => theme.border};   

            & li:hover {
                background: ${({ theme }) => theme.background};
            }
        }

        & .horizontal-capacity-results, .vertical-capacity-results, .micropile-results, .pile-cpt-capacity-results {
            & svg.apexcharts-svg {
                background: ${({ theme }) => theme.elementsBackground} !important;

                & text.apexcharts-title-text {
                    fill: ${({ theme }) => theme.titleColor};
                }
                & text {
                    fill: ${({ theme }) => theme.color};
                }
                & g.apexcharts-datalabels {
                    & text {
                        fill: #222;
                    }
                }
            }            
        }

        & .pagination-wrapper {
            & li a {
                background: ${({ theme }) => theme.elementsBackground};
                color: ${({ theme }) => theme.color};
                border-color: ${({ theme }) => theme.elementsBackground};

                &:hover {
                    background: ${({ theme }) => theme.background};
                    border: ${({ theme }) => theme.border};
                    color: ${({ theme }) => theme.titleColor};
                }

            } 
            & li.active a {
                background: ${({ theme }) => theme.background};
                border: ${({ theme }) => theme.border};
                color: ${({ theme }) => theme.titleColor};
            }
        }

        & .export-table-csv {
            background: ${({ theme }) => theme.elementsBackground};
            border: ${({ theme }) => theme.border};
            color: ${({ theme }) => theme.color};

            & a:hover {
                background: ${({ theme }) => theme.background};
                color: ${({ theme }) => theme.titleColor};
            }
        }

        & .dropdown-toggle {
            background: ${({ theme }) => theme.elementsBackground} !important;
            border: ${({ theme }) => theme.border} !important;
            color: ${({ theme }) => theme.color} !important;

            &:hover {
                border-color: ${({ theme }) => theme.activeInputBorder} !important;
                color: inherit;
            }

            & + .dropdown-menu {
                background: ${({ theme }) => theme.elementsBackground};
                border-radius: 0;
                color: inherit;
                border: ${({ theme }) => theme.border};

                & .dropdown-item {
                    background: ${({ theme }) => theme.elementsBackground};

                    &:hover {
                        background: ${({ theme }) => theme.background};
                        color: ${({ theme }) => theme.titleColor};
                    }
                }

                & .active {
                    background: ${({ theme }) => theme.background} !important;
                    color: ${({ theme }) => theme.titleColor} !important;
                }
            }
        }

        & .modal-content {
            background: ${({ theme }) => theme.background};
            color: ${({ theme }) => theme.color};

            & button {
                outline: none !important;
            }

            & button.close {
                color: ${({ theme }) => theme.color};
            }

            & .modal-footer {
                & button {
                    border: ${({ theme }) => theme.border};

                    &:hover {
                        border-color: ${({ theme }) => theme.activeInputBorder};
                    }
                }                
            }
        }

        & .project-navigation {
            border: ${({ theme }) => theme.border};
            color: ${({ theme }) => theme.color} !important;
            background: ${({ theme }) => theme.elementsBackground};
            transition: none;

            & a {
                color: ${({ theme }) => theme.color} !important;
            }
            
            &.active {
                background: ${({ theme }) => theme.background};
                cursor: default;
            }
        }

        & .default-button-style {
            border: ${({ theme }) => theme.defaultButton.border} !important;
            color: ${({ theme }) => theme.defaultButton.color};
            background: ${({ theme }) => theme.defaultButton.background};
            transition: none;
            
            &:hover {
                background: ${({ theme }) => theme.defaultButton.hoverBackground};
            }
        }

        & .default-button-info-style {
            border: ${({ theme }) => theme.defaultButton.info.border} !important;
            color: ${({ theme }) => theme.defaultButton.info.color};
            background: ${({ theme }) => theme.defaultButton.info.background};
            transition: none;
            
            &:hover {
                background: ${({ theme }) => theme.defaultButton.info.hoverBackground};
            }
        }

        
        & .default-button-danger-style {
            border: ${({ theme }) => theme.defaultButton.danger.border} !important;
            color: ${({ theme }) => theme.defaultButton.danger.color};
            background: ${({ theme }) => theme.defaultButton.danger.background};
            transition: none;
            
            &:hover {
                background: ${({ theme }) => theme.defaultButton.danger.hoverBackground};
            }
        }

        & #project-wrapper {
            padding-left: ${({open}) => !open ? '250px' : '0'};

            @media (max-width: 576px) 
            { 
                padding-left: ${({open}) => open ? '50px' : '0px'};
            }
        }

        & #sidebar-wrapper {
            left: ${({ open }) => !open ? '0' : '-270px'};
            background: ${({ theme }) => theme.sidebar.background};
            color: ${({ theme }) => theme.sidebar.color};

            & .avatar {
                background: ${({ theme }) => theme.background};
            }

            & a, button {
                & svg {
                    color: ${({ theme }) => theme.sidebar.iconColor};
                }                            
            }
            & li a:hover::after {
                background: ${({ theme }) => theme.elementsBackground};
            }
            & li a.active {
                &::after{
                    background: ${({ theme }) => theme.elementsBackground};
                }
            }

            & label {
                color: ${({ theme }) => theme.sidebar.labelColor};
            }
            & .sidebar-open-icon {
                left: ${({ open }) => open ? '285px' : '15px'};
                & span {
                    background: ${({ open, theme }) => !open ? theme.sidebar.toggleButtonColorOpen : theme.sidebar.toggleButtonColorHide};  
                    
                    @media (max-width: 576px){
                        background: ${({ open, theme }) => open ? theme.sidebar.toggleButtonColorOpen : theme.sidebar.toggleButtonColorHide};
                    }
                }
                @media (max-width: 576px){
                    left: ${({ open }) => !open ? '75px' : '15px'};
                }
            }
            @media (max-width: 576px){
                left: ${({ open }) => !open ? '-60px' : '0'};
            }
        }

        & #navbar-wrapper { 
            padding-left: ${({ open }) => !open ? '250px' : '50px'};

            @media (max-width: 576px){
                padding-left: 50px;
            }
        }

        & nav#site-navigation {
            border-bottom: ${({ theme }) => theme.border};

            & .menu-container {
                background: ${({ theme }) => theme.background};
            }

            & #main-menu-panel span {
                background: ${({ theme }) => theme.titleColor};
            }
        }

        & .checkbox-switch {

            & input:checked + label {
                background: ${({ theme }) => theme.checkbox.closeBackground};

                & div:first-child {
                    background: ${({ theme }) => theme.checkbox.closeIcon};
                }
            }

            & label {
                background: ${({ theme }) => theme.checkbox.openBackground};
                border: ${({ theme }) => theme.checkbox.switchBorder};

                & div:first-child {
                    background: ${({ theme }) => theme.checkbox.openIcon};
                }
            }
        }

        & .custom-checkbox {

            & input:checked + label {
                background: ${({ theme }) => theme.elementsBackground};

                & div:first-child {
                    background: ${({ theme }) => theme.checkbox.closeIcon};
                }
            }

            & label {
                background: ${({ theme }) => theme.checkbox.openBackground};

                & div:first-child {
                    background: ${({ theme }) => theme.checkbox.openIcon};
                }

                &:before{
                    border: 1px solid;
                    border-color: ${({ theme }) => theme.checkbox.borderColor};
                }
                &:after{
                    border-color: ${({ theme }) => theme.color};
                }
            }
        }
        & .custom-radio {

            & input:checked + label {
                background: ${({ theme }) => theme.elementsBackground};

                & div:first-child {
                    background: ${({ theme }) => theme.checkbox.closeIcon};
                }
            }
 
            & label {
                background: ${({ theme }) => theme.checkbox.openBackground};

                & div:first-child {
                    background: ${({ theme }) => theme.checkbox.openIcon};
                }

                &:before{
                    border: ${({ theme }) => theme.checkbox.switchBorder};
                }
                &:after{
                    border-color: ${({ theme }) => theme.checkbox.openIcon};
                    background: ${({ theme }) => theme.checkbox.openIcon};
                }
            }
        }
        
        & input, select, textarea {
            color: inherit;
            background: ${({ theme }) => theme.elementsBackground};
            border: ${({ theme }) => theme.border};
            border-radius: 7px;

            &:disabled {
                border-radius: 7px 0 0 7px;
                background: ${({ theme }) => theme.disabledInputBackground};
                color: ${({ theme }) => theme.disabledInputColor};
            }
            &:focus {
                border-color: ${({ theme }) => theme.activeInputBorder};
            }
            &::-webkit-input-placeholder{
                font-weight: 100;
                color: ${({ theme }) => theme.placeholder};
            }
            &::-moz-placeholder{
                font-weight: 100;
                color: ${({ theme }) => theme.placeholder};
            }
        }

        & .lock-input {
            color: inherit;
            border: ${({ theme }) => theme.border};
            border-radius: 7px;
            display: flex;

            &:focus-within {
                border-color: ${({ theme }) => theme.activeInputBorder};
            }
        }
        & h1,h2 {
            color: ${({ theme }) => theme.titleColor};
        }

        & .table-wrapper {
            background: ${({ theme }) => theme.elementsBackground};
            color: inherit;

            & .table {
                border-color: ${({ theme }) => theme.table.borderColor};
                color: inherit;
                
                & thead th, .header div {
                    color: inherit;
                    background: ${({ theme }) => theme.background};
                }
                & .shadowTr {
                    background: ${({ theme }) => theme.table.elementsBackground};
                }
                & select {
                    background: ${({ theme }) => theme.elementsBackground};
                }
            }
        }
        & .backgroundAround {
            background: ${({ theme }) => theme.elementsBackground};
            padding: 15px;
            border-radius: 15px;
            color: inherit;
        }
    }
`