import { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Table, Pagination, SectionTitle } from '../../../components';
import { withPagination } from '../../../hoc';
import isString from '../../../utils/isString';
import round from '../../../utils/roundNumber';
import styles from './index.module.css';

const KoseckiSummary = ({
    dataToPaginate = [],
    pagination,
    paginationHelpers = {},
}) => {
    return (
        <Fragment>
            {dataToPaginate.length > 0 && (
                <Row className={styles.row}>
                    <Col xs={12}>
                        <SectionTitle
                            title={`Wyniki sił wewnętrznych w palach wg metody uogólnionej`}
                        />
                        <Table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>
                                        Nr<br></br>[-]
                                    </th>
                                    <th className={styles.min120} title="Nazwa pliku">
                                        Nazwa pliku<br></br>
                                    </th>
                                    <th className={styles.min120} title="Profil gruntowy">
                                        Nazwa profilu<br></br>
                                    </th>
                                    <th title="Rodzaj pala">
                                        Rodzaj pala<br></br>
                                    </th>
                                    <th title="Technologia wykonania">
                                        Technologia<br></br>
                                    </th>
                                    <th title="Długość pala">
                                        L<br></br>[m]
                                    </th>
                                    <th title="Średnica pala">
                                        D<br></br>[m]
                                    </th>

                                    <th title="Moment maksymalny">
                                        M<sub>max</sub>
                                        <br></br>[kNm]
                                    </th>
                                    <th title="Moment minimalny">
                                        M<sub>min</sub>
                                        <br></br>[kNm]
                                    </th>
                                    <th title="Siła ścinająca maksymalna">
                                        T<sub>max</sub>
                                        <br></br>[kN]
                                    </th>
                                    <th title="Siła ścinająca minimalna">
                                        T<sub>min</sub>
                                        <br></br>[kN]
                                    </th>

                                    <th title="Przemieszczenie maksymalne">
                                        d<sub>max</sub>
                                        <br></br>[mm]
                                    </th>
                                    <th title="Przemieszczenie minimalne">
                                        d<sub>min</sub>
                                        <br></br>[mm]
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataToPaginate.map((element, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {paginationHelpers.countingItemsPerPage +
                                                    index +
                                                    1}
                                            </td>
                                            <td>{element.projectName}</td>
                                            <td>
                                                {isString(element.profileName)
                                                    ? element.profileName
                                                    : '-'}
                                            </td>
                                            <td>
                                                {
                                                    element.results
                                                        .currentCapacity
                                                        .pileType
                                                }
                                            </td>
                                            <td>
                                                {
                                                    element.results
                                                        .currentCapacity
                                                        .pileTechnology
                                                }
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity
                                                        .pileLength,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity
                                                        .diameterPile,
                                                    2,
                                                )}
                                            </td>

                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity
                                                        .maxMoment,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity
                                                        .minMoment,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity
                                                        .maxShearForce,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity
                                                        .minShearForce,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity
                                                        .maxDisplacement,
                                                    2,
                                                )}
                                            </td>
                                            <td>
                                                {round(
                                                    element.results
                                                        .currentCapacity
                                                        .minDisplacement,
                                                    2,
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        {paginationHelpers.totalPages > 1 && (
                            <Pagination
                                currentPage={pagination.currentPage}
                                itemsPerPage={pagination.itemsPerPage}
                                totalItems={paginationHelpers.allItemsLength}
                                nextPage={paginationHelpers.nextPage}
                            />
                        )}
                    </Col>
                </Row>
            )}
        </Fragment>
    );
};

export default withPagination({
    component: KoseckiSummary,
    returnAllCollection: false,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 15,
    },
});
