import { Outlet, useOutletContext } from "react-router-dom";
import MicropilesCompressionProvider from "../../../context/MicropilesCompression";
import { MicropilesCompressionNavigation } from "../../../pages";

const MicropileCompressionLayout = () => {
    const context = useOutletContext();

    return (
        <MicropilesCompressionProvider>
            <MicropilesCompressionNavigation report={context.report}/>
            <Outlet context={context}/>
        </MicropilesCompressionProvider>
    )
}

export default MicropileCompressionLayout;