import { useContext, useEffect, Fragment } from 'react';
import { homeContext } from '../../../context/AdminHome';
import styles from './index.module.css';
import Statistics from './Statistics';
import ChangePassword from './ChangePassword';
import { Loading, MainAlertAndLoading } from '../../../components';

const Home = () => {
    const { globalAlerts, globalLoading, statistics, changePassword } = useContext(homeContext);

    useEffect(() => {
        document.title = `GeoInCloud | Strona główna`;

        return () => {
            document.title = '';
        };
    }, []);

    return (
        <Fragment>
            <Statistics stats={statistics.state.stats} />

            <ChangePassword {...changePassword} />

            {globalLoading.state.saveNewPassword && (
                <div className={styles.loadingWrapper}>
                    <Loading text="Zmiana hasła..." />
                </div>
            )}

            <MainAlertAndLoading
                alerts={globalAlerts.state.alerts}
                updateAlerts={globalAlerts.updateState}
            />
        </Fragment>
    );
};

export default Home;
