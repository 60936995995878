import { useState, Fragment } from 'react';
import { Button } from '../../../../components';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.css';
import PrintResults from '../../../VerticalCapacity/results/PrintResults';

const PrintVerticalCapacityResults = ({
    report={},
    printResults={},
    isResultsActual,
}) => {
 
    const [open, setOpen] = useState(false);

    const onOpen = () => setOpen(true)
    const onHide = () => setOpen(false);

    return (
        <Fragment>
            {
                isResultsActual &&
                <Fragment>
                    <div className={styles.wrapper}>
                        <Button icon={faPrint} css={styles.printButton} variant="danger" name="report" onClick={onOpen} title="Raport z obliczeń" altTitle="Raport"/>   
                    </div>
                    
                    <PrintResults
                        open={open}
                        onChange={printResults.onChange}
                        state={{
                            report,
                            ...printResults.state
                        }}
                        onHide={onHide}
                    /> 
                </Fragment>
            }
        </Fragment>
    )
}

export default PrintVerticalCapacityResults