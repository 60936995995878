import isNumber from "../../isNumber";
import round from "../../roundNumber";

const kphSoilFactor = (angle, pileFriction) => {
    if(isNumber(angle) && isNumber(pileFriction)){
        const angleRad = (angle*Math.PI)/180;
        const pileFrictionRad = (pileFriction*Math.PI)/180;

        const denom = round(Math.cos(pileFrictionRad)*Math.pow(1 - Math.sqrt(Math.sin(angleRad-pileFrictionRad)*Math.sin(angleRad)/Math.cos(pileFrictionRad)),2),5);

        return denom !== 0 ? Number((Math.pow(Math.cos(angleRad),2)/denom).toFixed(2)) : '';
    } else {
        return ''
    }

}

export default kphSoilFactor