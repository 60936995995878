import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { faEdit, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import DefaultSoil from './DefaultSoil';
import {
    SectionTitle,
    Table,
    Button,
    SelectField,
    TableInput,
} from '../../../../components';
import { genesisType } from '../../../../config/ProjectConfig';
import { SaveSoils, EditSoils } from '../../../ProjectActions';
import styles from './index.module.css';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

const SoilTable = ({
    state = [],
    addRow,
    removeRow,
    onChange,
    onBlur,
    onKeyDown,
    defaultSoilLayer = {},
    saveSoilProfile = {},
    editSoilProfile = {},
    globalLoading = {},
}) => {
    const [openLayerModal, setOpenLayerModal] = useState(false);
    const [open, setOpen] = useState({});
    const [index, setIndex] = useState(null);
    const openDefaultSoilModal = (i) => {
        setIndex(i);
        setOpenLayerModal(true);
    };

    const hideDefaultSoilModal = () => setOpenLayerModal(false);

    const onOpen = (e) => {
        const target = e.currentTarget;
        if (target) {
            if (target.name === 'editProfile') {
                editSoilProfile.onLoadProjects();
            }
            setOpen((state) => ({ ...state, [target.name]: true }));
        }
    };
    const onHide = () => setOpen({});

    return (
        <Col xs={12}>
            <SectionTitle title="Parametry podłoża gruntowego"/>
            <Table>
                <thead className={styles.thead}> 
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th width={200}>
                            Nazwa warstwy<br></br>[-]
                        </th>
                        <th title="Zagłębienie warstwy">
                            Z<br></br>[m.p.p.t]
                        </th>
                        <th title="Ciężar objętościowy gruntu">
                            &#947; <br></br>[kN/m<sup>3</sup>]
                        </th>
                        <th title="Ciężar objętościowy gruntu z uwzględnieniem wyporu wody">
                            &#947;' <br></br>[kN/m<sup>3</sup>]
                        </th>
                        <th title="Stopień zagęszczenia">
                            I<sub>d</sub> <br></br>[-]
                        </th>
                        <th title="Stopień plastyczności">
                            I<sub>L</sub> <br></br>[-]
                        </th>
                        <th width={130}>
                            Nazwa gruntu <br></br>[-]
                        </th>
                        <th>Usuń</th>
                        <th>Dodaj</th>
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    {state.map((element, index) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td className={styles.tdLeft}>
                                    <TableInput
                                        name="name"
                                        placeholder="Nazwa gruntu"
                                        maxLength="35"
                                        data-row={index}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={element.name.value}
                                        isValid={element.name.isValid}
                                        required
                                    />
                                </td>
                                <td>
                                    <TableInput
                                        name="height"
                                        type="number"
                                        align="center"
                                        data-row={index}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        onKeyDown={onKeyDown}
                                        value={element.height.value}
                                        isValid={element.height.isValid}
                                        required
                                    />
                                </td>
                                <td>
                                    <TableInput
                                        name="weight"
                                        type="number"
                                        align="center"
                                        data-row={index}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        onKeyDown={onKeyDown}
                                        value={element.weight.value}
                                        isValid={element.weight.isValid}
                                        required
                                    />
                                </td>
                                <td>
                                    <TableInput
                                        name="underWaterWeight"
                                        type="number"
                                        align="center"
                                        data-row={index}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        onKeyDown={onKeyDown}
                                        value={element.underWaterWeight.value}
                                        isValid={
                                            element.underWaterWeight.isValid
                                        }
                                        required
                                    />
                                </td>
                                <td>
                                    <TableInput
                                        name="density"
                                        type="number"
                                        align="center"
                                        data-row={index}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        onKeyDown={onKeyDown}
                                        value={element.density.value}
                                        isValid={element.density.isValid}
                                        required
                                    />
                                </td>
                                <td>
                                    <TableInput
                                        name="plasticity"
                                        type="number"
                                        align="center"
                                        data-row={index}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        onKeyDown={onKeyDown}
                                        value={element.plasticity.value}
                                        isValid={element.plasticity.isValid}
                                        required
                                    />
                                </td>
                                <td>
                                    <SelectField
                                        name="soilName"
                                        value={element.soilName.value}
                                        isValid={element.soilName.isValid}
                                        defaultOption="[-]"
                                        options={defaultSoilLayer.defaultSoils}
                                        data-row={index}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        required
                                    />
                                </td>
                                <td>
                                    <Button
                                        variant="danger"
                                        icon={faTrashAlt}
                                        altTitle={'Usuń'}
                                        onClick={() => removeRow(index)}
                                    />
                                </td>
                                <td>
                                    <Button
                                        variant="info"
                                        icon={faPlus}
                                        altTitle={'Dodaj'}
                                        onClick={() =>
                                            openDefaultSoilModal(index)
                                        }
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <div className={styles.actionsWrapper}>
                <div className={styles.actions}>
                    <Button
                        css="default-button-style"
                        onClick={addRow}
                        title={'Dodaj warstwę'}
                        icon={faPlus}
                    />
                </div>
                <div className={styles.actions}>
                    <Button
                        icon={faEdit}
                        variant="info"
                        name="editProfile"
                        title="Wczytaj profil"
                        onClick={onOpen}
                    />
                    <Button
                        icon={faSave}
                        variant="danger"
                        name="saveProfile"
                        title="Zapisz profil"
                        onClick={onOpen}
                    />
                </div>
            </div>

            <EditSoils
                open={open.editProfile}
                onHide={onHide}
                onRemoveProfile={editSoilProfile.onRemoveProfile}
                onLoadProfile={editSoilProfile.onLoadProfile}
                onChange={editSoilProfile.onChange}
                state={editSoilProfile.state}
                loading={globalLoading.fetchAllProfiles}
            />

            <SaveSoils
                open={open.saveProfile}
                openOverwriteProfile={saveSoilProfile.state.profileExists}
                onChange={saveSoilProfile.onChange}
                onBlur={saveSoilProfile.onBlur}
                onSubmit={saveSoilProfile.onSubmit}
                onHideExistProfileModal={
                    saveSoilProfile.onHideExistProfileModal
                }
                onOverwrite={saveSoilProfile.onOverwrite}
                state={saveSoilProfile.state}
                saveLoading={globalLoading.saveSoilProfile}
                overwriteLoading={globalLoading.overwriteSoilProfile}
                onHide={onHide}
            />

            <DefaultSoil
                {...defaultSoilLayer}
                open={openLayerModal}
                onHide={hideDefaultSoilModal}
                genesisType={genesisType}
                rowIndex={index}
            />
        </Col>
    );
};

export default SoilTable;
