import { useEffect, useContext, Fragment } from 'react';
import { MainTitle, SectionTitle } from '../../../components';
import { projectsContext } from '../../../context/Projects';
import styles from './index.module.css';

const GeotechnicalProfilesNavigation = () => {
    const projectsContextObj = useContext(projectsContext);

    useEffect(() => {
        document.title = `Projekty`;

        return () => {
            document.title = '';
        };
    }, []);

    return (
        <Fragment>
            <MainTitle
                title={'Projekty'}
                subtitle={
                    'Zestawienie zapisanych projektów wraz z wykonanymi obliczeniami'
                }
            />
            <div className={styles.fileNameWrapper}>
                <SectionTitle
                    className={styles.h2}
                    title={projectsContextObj.fileNameToSave.fileName.value}
                />
            </div>
        </Fragment>
    );
};

export default GeotechnicalProfilesNavigation;
