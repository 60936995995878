import { isNumber, roundNumber } from '../../../../../utils';
import styles from './index.module.css';

const CalculationSection = ({ parameters = {} }) => {
    return (
        <div className={styles.wrapper}>
            <h2>Parametry profilu</h2>
            {isNumber(parameters.groundLevel) && (
                <p>
                    Rzędna terenu:{' '}
                    <span className={styles.value}>
                        {parameters.groundLevel.toFixed(2)} m.n.p.m.
                    </span>
                </p>
            )}
            <p>
                Rzędna zwierciadła wody w wykopie:{' '}
                <span className={styles.value}>
                    {isNumber(parameters.leftZwg)
                        ? isNumber(parameters.groundLevel)
                            ? `${parameters.leftZwg.toFixed(
                                  2,
                              )} m.p.p.t. (${roundNumber(
                                  parameters.groundLevel - parameters.leftZwg,
                                  2,
                              ).toFixed(2)} m.n.p.m.)`
                            : `${parameters.leftZwg.toFixed(2)} m.p.p.t.`
                        : 'Brak'}
                </span>
            </p>
            <p>
                Rzędna zwierciadła wody w naziomie:{' '}
                <span className={styles.value}>
                    {isNumber(parameters.rightZwg)
                        ? isNumber(parameters.groundLevel)
                            ? `${parameters.rightZwg.toFixed(
                                  2,
                              )} m.p.p.t. (${roundNumber(
                                  parameters.groundLevel - parameters.rightZwg,
                                  2,
                              ).toFixed(2)} m.n.p.m.)`
                            : `${parameters.rightZwg.toFixed(2)} m.p.p.t.`
                        : 'Brak'}
                </span>
            </p>
            <p>
                Rzędna dna wykopu:{' '}
                <span className={styles.value}>
                    {parameters.excavationBase.toFixed(2)} m.p.p.t.{' '}
                    {isNumber(parameters.groundLevel)
                        ? `(${roundNumber(
                              parameters.groundLevel -
                                  parameters.excavationBase,
                              2,
                          ).toFixed(2)} m.n.p.m.)`
                        : ''}
                </span>
            </p>
            <p>
                Rzędna wbicia ścianki szczelnej:{' '}
                <span className={styles.value}>
                    {parameters.sheetPileSpot.toFixed(2)} m.p.p.t{' '}
                    {isNumber(parameters.groundLevel)
                        ? `(${roundNumber(
                              parameters.groundLevel - parameters.sheetPileSpot,
                              2,
                          ).toFixed(2)} m.n.p.m.)`
                        : ''}
                </span>
            </p>
            <p>
                Analiza głębokiej powierzchni poślizgu:{' '}
                <span className={styles.value}>
                    {parameters.isDeepPlate ? 'Tak' : 'Nie'}
                </span>
            </p>
        </div>
    );
};

export default CalculationSection;
