import { Fragment } from "react";
import { isNumber, roundNumber } from "../../../../../utils";
import styles from './index.module.css';

const PileParametersSection = ({ 
    currentSheetPileLength,
    baseArea,
    perimeter,
    sheetPileHeadSpot,
    sheetPileList,
    sheetPileType,
    technology,
 }) => {
    
    return (
        <div className={styles.wrapper}>
            <h2>Parametry ścianki szczelnej</h2>
            {
                sheetPileList &&
                <p>Rodzaj ścianki szczelnej: <span className={styles.value}>{sheetPileType}</span></p>
            }
            <p>Technologia pogrążania grodzic: <span className={styles.value}>{technology}</span></p>
            <p>Długość ścianki szczelnej: <span className={styles.value}>{isNumber(currentSheetPileLength) && currentSheetPileLength > 0 ? `${roundNumber(currentSheetPileLength,2).toFixed(2)} m`: 'Brak' }</span></p>
            {
                !sheetPileList &&
                <Fragment>
                    <p>Powierzchnia podstawy: <span className={styles.value}>{roundNumber(baseArea,2).toFixed(2)} cm<sup>2</sup></span></p>
                    <p>Długość pobocznicy: <span className={styles.value}>{roundNumber(perimeter,2).toFixed(2)} cm</span></p>
                </Fragment>
            }
            <p>Rzędna głowicy ścianki szczelnej: <span className={styles.value}>{roundNumber(sheetPileHeadSpot,2).toFixed(2)} m.p.p.t.</span></p>
        </div>
    )
}

export default PileParametersSection