import { Fragment, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    Button,
    Checkbox1,
    Collapse,
    InputField,
    SelectField,
    TableInput,
} from '../../../components';
import { useWindowSize } from '../../../hooks';
import {
    isNumber,
    numberRange,
    roundNumber,
    stringToNumber,
} from '../../../utils';
import { SketchPicker } from 'react-color';
import styles from './index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faArrowsAlt, faUndo } from '@fortawesome/free-solid-svg-icons';
import stringify from 'fast-json-stable-stringify';

import useDeepCompareEffect from 'use-deep-compare-effect';
import DefaultReinforcementChart from '../DefaultReinforcementChart';

const chartHeight = 300;

const onDataCollection = (collection, state={}) => {
    return collection.map((ele, idx) => {
        const { h, coverBottom, coverUp, spiral, b, circular } = ele.capacity.initModelValues;

        if(ele.capacity.initModelValues.circular){    
            return (
                {
                    id: idx,
                    profile: ele.profile,
                    value: ele.capacity.calculation.map((element,index) => {
                        if(state.capacityType === 0){
                            return element.result.as
                        } else if(state.capacityType === 1) {
                            const rebarNumber = Math.ceil(element.result.as/(Math.PI*0.25*ele.capacity.rebar**2*0.01));
                            const innerD = h - coverBottom - coverUp - 2*spiral - ele.capacity.rebar;
                            const circleAround = innerD * Math.PI;

                            const space = rebarNumber < 2 
                                ? 
                                0 
                                : 
                                roundNumber(circleAround/rebarNumber,2);
                            return space*0.1;
                        } else if(state.capacityType === 2) {
                            return Math.ceil(element.result.as/(Math.PI*0.25*ele.capacity.rebar**2*0.01));
                        } else { 
                            return element.lbd.tension;
                        }
                    }),
                    value1: ele.capacity.calculation.map((element,index) => {
                        if(state.capacityType === 3){
                            return element.lbd.compression;
                        } else {
                            return "";
                        }
                    }),
                    circular: circular,
                    rebar: ele.capacity.rebar,
                }
            )
        } else {
            return (
                {
                    id: idx,
                    profile: ele.profile,
                    value: ele.capacity.calculation.map((element,index) => {
                        if(state.capacityType === 0){
                            return element.result.as1
                        } else if(state.capacityType === 1) {
                            const rebarNumberAs1 = Math.ceil(element.result.as1/(Math.PI*0.25*ele.capacity.rebarAs1**2*0.01));
                            const spaceAs1 = rebarNumberAs1 < 2 
                            ? 
                            0 
                            : 
                            roundNumber(((b - 2*coverBottom - 2*spiral - ele.capacity.rebarAs1) / (rebarNumberAs1 - 1)),2);
                            return spaceAs1*0.1
                        } else if(state.capacityType === 2) {
                            return Math.ceil(element.result.as1/(Math.PI*0.25*ele.capacity.rebarAs1**2*0.01));
                        } else { 
                            return element.lbd.tension;
                        }
                    }),
                    value1: ele.capacity.calculation.map((element,index) => {
                        if(state.capacityType === 0){
                            return element.result.as2
                        } else if(state.capacityType === 1) {
                            const rebarNumberAs2 = Math.ceil(element.result.as2/(Math.PI*0.25*ele.capacity.rebarAs2**2*0.01));
                            const spaceAs2 = rebarNumberAs2 < 2 
                                ? 
                                0 
                                : 
                                roundNumber(((b - 2*coverUp - 2*spiral - ele.capacity.rebarAs2) / (rebarNumberAs2 - 1)),2);
                            return spaceAs2*0.1;
                        } else if(state.capacityType === 2) {
                            return Math.ceil(element.result.as2/(Math.PI*0.25*ele.capacity.rebarAs2**2*0.01));
                        } else { 
                            return element.lbd.compression
                        }
                    }),
                    circular: circular,
                    rebarAs1: ele.capacity.rebarAs1,
                    rebarAs2: ele.capacity.rebarAs2,
                }
            )
        }
    })
}
const ReinforcementChart = ({
    collection = [],
    onChangeChartTitles,
    onChangeSettings,
    onToggleChartType,
    state = {},
}) => {
    const horAxisRef = useRef(null);
    const [profileAndValue, setProfileAndValue] = useState([]);
    const [capacityCollection, setCapacityCollection] = useState([]);
    const [maxValue, setMaxValue] = useState(0);
    const chartColorStorage = JSON.parse(localStorage.getItem('chartColor'));
    const chartColorStorage1 = JSON.parse(localStorage.getItem('chartColor1'));
    const [chartColor, setChartColor] = useState(
        chartColorStorage || { r: 255, g: 0, b: 0, a: 1 },
    );
    const [chartColor1, setChartColor1] = useState(
        chartColorStorage1 || { r: 0, g: 255, b: 0, a: 1 },
    );
    const [dragId, setDragId] = useState();
    const [barWidth, setBarWidth] = useState(0);
    const [chartScale, setChartScale] = useState(100);
    const windowSize = useWindowSize();

    useDeepCompareEffect(() => {
        setProfileAndValue(onDataCollection(collection, state));
    }, [collection, state.capacityType]);

    useDeepCompareEffect(() => {
        const max1 = Math.max(...profileAndValue.map((ele) => ele.value).flat(1).filter(ele => isNumber(ele))) || 0;
        const max2 = Math.max(...profileAndValue.map((ele) => ele.value1).flat(1).filter(ele => isNumber(ele))) || 0;

        const max = Math.max(max1, max2) || 0;

        const collectionStep = isNumber(max)
            ? roundNumber(Math.ceil(max / 10), 0)
            : 2;
        setCapacityCollection(
            [
                ...new Set(
                    numberRange(
                        Math.ceil(max / collectionStep) * collectionStep,
                        0,
                        collectionStep,
                    ),
                ),
            ]
                .map((ele) => roundNumber(ele, 0))
                .sort((a, b) => b - a),
        );

        if (horAxisRef.current) {
            setBarWidth(
                roundNumber(
                    Math.floor(horAxisRef.current.offsetWidth - 55) /
                        profileAndValue.length,
                    0,
                ),
            );
        }
    }, [profileAndValue, windowSize.width]);

    useDeepCompareEffect(() => {
        if (
            Array.isArray(capacityCollection) &&
            capacityCollection.length > 0
        ) {
            setMaxValue(Math.max(...capacityCollection));
        }
    }, [capacityCollection]);

    const onChangeColor = (color) => {
        localStorage.setItem('chartColor', stringify(color.rgb));
        setChartColor(color.rgb);
    };
    const onChangeColor1 = (color) => {
        localStorage.setItem('chartColor1', stringify(color.rgb));
        setChartColor1(color.rgb);
    };

    const handleDrag = (e) =>
        setDragId(stringToNumber(e.currentTarget.getAttribute('data-id')));
    const handleDrop = (e) => {
        const dropId = stringToNumber(e.currentTarget.getAttribute('data-id'));

        setProfileAndValue((state) => {
            const dragItemIndex = profileAndValue.findIndex(
                (ele) => ele.id === dragId,
            );
            const dropItemIndex = profileAndValue.findIndex(
                (ele) => ele.id === dropId,
            );

            if (isNumber(dragItemIndex) && isNumber(dropItemIndex)) {
                const copyCollection = state.map((ele) => ele);
                let b = copyCollection[dragItemIndex];
                copyCollection[dragItemIndex] = copyCollection[dropItemIndex];
                copyCollection[dropItemIndex] = b;

                return copyCollection;
            } else {
                return state;
            }
        });
    };
    const onChangeYValues = (e, id) => {
        const { value } = e.target;
        setProfileAndValue((state) =>
            state.map((ele) => {
                if (ele.id === id) {
                    return {
                        ...ele,
                        profile: value,
                    };
                }
                return ele;
            }),
        );
    };

    const onRemoveItem = (id) => setProfileAndValue((state) => state.filter((ele) => ele.id !== id));
    const onReturnData = () => setProfileAndValue(onDataCollection(collection, state));
    const onChangeChartScale = (e) => setChartScale(e.target.value);

    return (
        <Fragment>
            {Array.isArray(profileAndValue) && profileAndValue.length > 0 && Array.isArray(capacityCollection) 
            ? (
                <Fragment>
                    <Col xs={12} sm={9}>
                        <DefaultReinforcementChart
                            chartNames={state.chartNames}
                            capacityType={state.capacityType}
                            capacityCollection={capacityCollection}
                            maxValue={maxValue}
                            chartHeight={chartHeight}
                            chartScale={chartScale}
                            settings={state.settings}
                            horAxisRef={horAxisRef}
                            profileAndValue={profileAndValue}
                            barWidth={barWidth}
                            chartColor={chartColor}
                            chartColor1={chartColor1}

                            report={state.report}

                            globalTitle="Graficzne zestawienie wyników obliczeń nośności pali wg PN-83/B-02482 + EC7"
                        />
                    </Col>
                    <Col xs={12} sm={3} className={styles.leftSide}>
                        <Row>
                            <Col xs={12} className={styles.margin}>
                                <div className={styles.buttonWrapper}>
                                    <SelectField
                                        title="Rodzaj wykresu"
                                        onChange={onToggleChartType}
                                        value={state.capacityName}
                                        defaultOption=""
                                        options={state.capacityChartTypes.map(
                                            (ele) => ele.name,
                                        )}
                                        name="capacityType"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className={styles.rowMargin}>
                            <Col xs={12}>
                                <Collapse title={'Tytuł wykresu'}>
                                    <InputField
                                        onChange={(e) =>
                                            onChangeChartTitles(
                                                e,
                                                state.capacityType,
                                            )
                                        }
                                        value={
                                            state.chartNames[state.capacityType]
                                                .title
                                        }
                                        name="title"
                                        disable={true}
                                    />
                                </Collapse>
                            </Col>
                        </Row>
                        <Row className={styles.rowMargin}>
                            <Col xs={12}>
                                <Collapse title={'Nazwa osi pionowej'}>
                                    <InputField
                                        onChange={(e) =>
                                            onChangeChartTitles(
                                                e,
                                                state.capacityType,
                                            )
                                        }
                                        value={
                                            state.chartNames[state.capacityType]
                                                .axisY
                                        }
                                        name="axisY"
                                        disable={true}
                                    />
                                </Collapse>
                            </Col>
                        </Row>
                        <Row className={styles.rowMargin}>
                            <Col xs={12}>
                                <Collapse title={'Nazwa osi poziomej'}>
                                    <InputField
                                        onChange={(e) =>
                                            onChangeChartTitles(
                                                e,
                                                state.capacityType,
                                            )
                                        }
                                        value={
                                            state.chartNames[state.capacityType]
                                                .axisX
                                        }
                                        name="axisX"
                                        disable={true}
                                    />
                                </Collapse>
                            </Col>
                        </Row>
                        <Row className={styles.rowMargin}>
                            <Col xs={12}>
                                <Collapse title={'Skala wysokości wykresu'}>
                                    <div className={styles.flex}>
                                        <InputField
                                            onChange={onChangeChartScale}
                                            value={chartScale}
                                            name="chartScale"
                                            disable={true}
                                        />
                                        <p>%</p>
                                    </div>
                                </Collapse>
                            </Col>
                        </Row>
                        <Row className={styles.rowMargin}>
                            <Col xs={12}>
                                <Collapse title={'Kolor serii'}>
                                    <p>Zbrojenie As/As1</p>
                                    <SketchPicker
                                        color={chartColor}
                                        onChange={onChangeColor}
                                    />

                                    {Array.isArray(profileAndValue) &&
                                    profileAndValue.some((ele) =>
                                        Array.isArray(ele.value1) && ele.value1.some(element => isNumber(element)),
                                    ) && (
                                        <Fragment>
                                            <p>Zbrojenie As2</p>
                                            <SketchPicker
                                                color={chartColor1}
                                                onChange={onChangeColor1}
                                            />
                                        </Fragment>
                                    )}
                                </Collapse>
                            </Col>
                        </Row>
                        <Row className={styles.rowMargin}>
                            <Col xs={12}>
                                <Collapse title={'Dane w serii'}>
                                    <Fragment>
                                        <div
                                            className={`table-wrapper ${styles.seriesWrapper}`}>
                                            <div
                                                className={`table ${styles.table}`}>
                                                <div
                                                    className={`${styles.header} header`}>
                                                    <div
                                                        className={
                                                            styles.headerItem
                                                        }>
                                                        <p>Oś X</p>
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.headerItem
                                                        }>
                                                        <p>Usuń</p>
                                                    </div>
                                                    <div
                                                        className={`${styles.headerItem}`}>
                                                        <p>Przesuń</p>
                                                    </div>
                                                </div>
                                                <div className={styles.body}>
                                                    {profileAndValue.map(
                                                        (ele, idx) => {
                                                            return (
                                                                <div
                                                                    key={idx}
                                                                    data-id={
                                                                        ele.id
                                                                    }
                                                                    className={
                                                                        styles.bodyRow
                                                                    }
                                                                    draggable={
                                                                        true
                                                                    }
                                                                    onDragOver={(
                                                                        e,
                                                                    ) =>
                                                                        e.preventDefault()
                                                                    }
                                                                    onDragStart={
                                                                        handleDrag
                                                                    }
                                                                    onDrop={
                                                                        handleDrop
                                                                    }>
                                                                    <div
                                                                        className={`${styles.bodyRowItem} ${styles.inputText}`}>
                                                                        <TableInput
                                                                            name={`barName${ele.id}`}
                                                                            value={
                                                                                ele.profile
                                                                            }
                                                                            onChange={(
                                                                                e,
                                                                            ) =>
                                                                                onChangeYValues(
                                                                                    e,
                                                                                    ele.id,
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        title={
                                                                            'Usuń wiersz'
                                                                        }
                                                                        onClick={() =>
                                                                            onRemoveItem(
                                                                                ele.id,
                                                                            )
                                                                        }
                                                                        className={`${styles.bodyRowItem} ${styles.removeItem}`}>
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faTrashAlt
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        title={
                                                                            'Zmień kolejność'
                                                                        }
                                                                        className={`${styles.headerItem} ${styles.bodyRowItem}`}>
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faArrowsAlt
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                        },
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {collection.length !==
                                            profileAndValue.length && (
                                            <div className={styles.returnData}>
                                                <Button
                                                    name="returnData"
                                                    css={`default-button-info-style`}
                                                    icon={faUndo}
                                                    title="Przywróć dane"
                                                    altTitle={'Przywróć dane'}
                                                    onClick={onReturnData}
                                                />
                                            </div>
                                        )}
                                    </Fragment>
                                </Collapse>
                            </Col>
                        </Row>
                        <Row className={styles.rowMargin}>
                            <Col xs={12}>
                                <Collapse title="Ustawienia">
                                    <div
                                        name="showValues"
                                        onClick={onChangeSettings}
                                        className={styles.flexCheckbox}>
                                        <Checkbox1
                                            css={styles.checkbox}
                                            value={state.settings.showValues}
                                            label="Wartości na wykresie"
                                            onChange={() => false}
                                        />
                                        <p>Wartości na wykresie</p>
                                    </div>
                                    <div
                                        name="showDescription"
                                        onClick={onChangeSettings}
                                        className={styles.flexCheckbox}>
                                        <Checkbox1
                                            css={styles.checkbox}
                                            value={
                                                state.settings.showDescription
                                            }
                                            label="Opis przekroju"
                                            onChange={() => false}
                                        />
                                        <p>Pokaż opis przekroju</p>
                                    </div>
                                    <div
                                        name="rotateDescriptionX"
                                        onClick={onChangeSettings}
                                        className={styles.flexCheckbox}>
                                        <Checkbox1
                                            css={styles.checkbox}
                                            value={
                                                state.settings
                                                    .rotateDescriptionX
                                            }
                                            label="Obrót opisu osi X"
                                            onChange={() => false}
                                        />
                                        <p>Obróć opis osi X</p>
                                    </div>
                                    <div
                                        name="showHorizontal"
                                        onClick={onChangeSettings}
                                        className={styles.flexCheckbox}>
                                        <Checkbox1
                                            css={styles.checkbox}
                                            value={
                                                state.settings.showHorizontal
                                            }
                                            label="Pokaż oś poziomą"
                                            onChange={() => false}
                                        />
                                        <p>Pokaż oś poziomą</p>
                                    </div>
                                    <div
                                        name="showVertical"
                                        onClick={onChangeSettings}
                                        className={styles.flexCheckbox}>
                                        <Checkbox1
                                            css={styles.checkbox}
                                            value={state.settings.showVertical}
                                            label="Pokaż oś pionową"
                                            onChange={() => false}
                                        />
                                        <p>Pokaż oś pionową</p>
                                    </div>
                                    <div
                                        name="showLegend"
                                        onClick={onChangeSettings}
                                        className={styles.flexCheckbox}>
                                        <Checkbox1
                                            css={styles.checkbox}
                                            value={state.settings.showLegend}
                                            label="Pokaż legendę"
                                            onChange={() => false}
                                        />
                                        <p>Pokaż legendę</p>
                                    </div>
                                </Collapse>
                            </Col>
                        </Row>
                    </Col>
                </Fragment>
            ) : (
                <Col xs={12}>
                    <p className={styles.noResults}>Brak wyników obliczeń</p>
                </Col>
            )}
        </Fragment>
    );
};

export default ReinforcementChart;
