import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useState } from 'react';
import styles from './index.module.css';

const TableInput = ({ name='', placeholder='', onChange, isValid, value, disabled=false, dataRow, ...rest }) => {

    const [lockInput, setLockInput] = useState(true);
    const onLockInput = () => setLockInput(state => !state);

    return (
        <Fragment>
            {
                disabled
                ?
                <div className={styles.group}>
                    <div className={`lock-input`}> 
                        <div className={styles.fullWidth}>
                            <input
                                className={styles.inputField}
                                name={name}
                                placeholder={placeholder} 
                                value={value} 
                                onChange={onChange}
                                onFocus={(e) => e.target.addEventListener("wheel", (e) => { e.preventDefault() }, { passive: false })}
                                data-row={dataRow}
                                disabled={lockInput}
                                {...rest}
                            />
                        </div>
                        <div className={styles.groupIcon} onClick={onLockInput}>
                            <FontAwesomeIcon icon={lockInput ? faLock : faLockOpen} />
                        </div>
                    </div>                    
                </div>
                :
                <div className={`${styles.wrapper} ${isValid === false ? styles.wrapperInvalid : ''}`}>
                    <input 
                        className={`${styles.input} ${isValid === false ? styles.inputInvalid : ''}`} 
                        name={name} 
                        placeholder={placeholder} 
                        onChange={onChange} 
                        onFocus={(e) => e.target.addEventListener("wheel", (e) => { e.preventDefault() }, { passive: false })}
                        value={value} 
                        data-row={dataRow} 
                        {...rest}
                    />
                </div>            
            }
        </Fragment>
    )
}

export default React.memo(TableInput)