import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
} from '@fortawesome/free-regular-svg-icons';
import { findClosest, isNumber, isString, roundNumber, setSoilColorQc, stringToNumber, lowerFirstLetter } from '../../../../../../utils';
import { Button, InputField, SectionTitle } from '../../../../../../components';
import SoilLayer from './SoilLayer';
import styles from './index.module.css';
import round from '../../../../../../utils/roundNumber';
import { Col, Row } from 'react-bootstrap';
import { useWindowSize } from '../../../../../../hooks';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';

const SoilProfile = ({
    soilProfile=[],
    results = [],
    pileTypeNumber,
    groundLevel,
    openSidebar,
    pileLength,
    pileHeadSpot,
    charLoad,
    yt,
    ygq,
    hPile={},
    hType,
    diameterPile,
    currentPileLengthByUser,
    onChangeCurrentPileLength,
    onChangeCurrentCapacity,
    onCalculateSettlement,
    onSetCharLoad,
    pileType='',
    isBaseWidth,
    baseWidth
}) => {
    const soilProfileRef = useRef(null);
    const resultsRef = useRef(null);
    const [soilLayer, setSoilLayer] = useState([]);
    const [resultsWidth, setResultsWidth] = useState(0);
    const [resultsHeight, setResultsHeight] = useState(0);
    const [soilProfileHeight, setSoilProfileHeight] = useState(0);
    const [profilePileLength, setProfilePileLength] = useState('');
    const [profilePileHeadSpot, setProfilePileHeadSpot] = useState('');
    const [axisNtScale, setAxisNtScale] = useState([]);
    const [axisNtSettleScale, setAxisNtSettleScale] = useState([]);
    const [axisZScale, setAxisZScale] = useState([]);
    const [maxNt, setMaxNt] = useState('');
    const [maxNtSettle, setMaxNtSettle] = useState('');
    const [maxZ, setMaxZ] = useState('');
    const [maxHeight, setMaxHeight] = useState(0);
    const [activeCapacity, setActiveCapacity] = useState({
        pileCapacity: true,
        pullCapacity: false,
        settlement: false,
    });
    const [currentNt, setCurrentNt] = useState('');
    const [pileBaseSpot, setPileBaseSpot] = useState('');
    const [currentPileLength, setCurrentPileLength] = useState(
        currentPileLengthByUser
    );
    const [currentCapacity, setCurrentCapacity] = useState({});
    const windowSize = useWindowSize();
    const [settlements, setSettlements] = useState({});

    const [pileBaseLayer, setPileBaseLayer] = useState({})

    const soilProfileHeights = results.map((ele) => ele.z);

    useEffect(() => {
        setCurrentPileLength(currentPileLengthByUser);
    },[currentPileLengthByUser])

    useEffect(() => {
        if (resultsRef.current) {
            setResultsWidth(resultsRef.current.offsetWidth*0.9);
            setResultsHeight(resultsRef.current.offsetHeight*0.95);
        }
    }, [
        activeCapacity.pileCapacity,
        activeCapacity.pullCapacity,
        activeCapacity.settlement,
        openSidebar,
        windowSize.width,
    ]);

    useEffect(() => {
        onChangeCurrentPileLength(currentPileLength);
    }, [
        onChangeCurrentPileLength,
        diameterPile,
        currentPileLength,
    ]);

    useDeepCompareEffect(() => {
        setMaxHeight(Math.max(...soilProfile.map(ele => ele.z)));
    }, [soilProfile]);

    useDeepCompareEffect(() => {
        if(Object.keys(currentCapacity).length > 0){
            onChangeCurrentCapacity({
                ...currentCapacity,
                s: settlements.s,
                f: settlements.f
            });
        }
    },[
        onChangeCurrentCapacity,
        currentCapacity,
        settlements
    ]);

    useDeepCompareEffect(() => {
        
        setSettlements(
            onCalculateSettlement({
                rcd: charLoad,
                settlements: currentCapacity,
                type: pileTypeNumber
            })
        );
    },[
        currentCapacity,
        yt,
        ygq,
        charLoad
    ]);
    useDeepCompareEffect(() => {
        if (soilProfileRef.current) {
            const offsetHeight = soilProfileRef.current.offsetHeight;
            const maxHeight = Math.max(...soilProfile.map(ele => ele.z));
            const layers = soilProfile.map((ele, i, arr) => {
                return (
                    <SoilLayer
                        key={i}
                        backgroundColor={setSoilColorQc({
                            type: ele.soilType,
                            qc: ele.qc,
                        })}
                        height={
                            maxHeight === 0
                                ? 0
                                : (offsetHeight *
                                      (i === arr.length - 1
                                          ? ele.absHeight
                                          : ele.absHeight)) /
                                  maxHeight
                        }
                    />
                );
            });
            setSoilLayer(layers);
            setSoilProfileHeight(offsetHeight);

            const layerBaseSpot = soilProfile.find(ele => ele.z >= pileBaseSpot);

            if(layerBaseSpot){
                setPileBaseLayer(
                    <SoilLayer
                        backgroundColor={setSoilColorQc({
                            type: layerBaseSpot.soilType,
                            qc: layerBaseSpot.qc,
                        })}
                        height={offsetHeight}
                    />
                )
            }

        }
    }, [soilProfile, soilProfileHeights, pileBaseSpot]);

    useDeepCompareEffect(() => {
        const nt = Math.max(...results.map((ele) => activeCapacity.pullCapacity ? ele.Rtd_upper : activeCapacity.pileCapacity ? ele.Rcd_upper : ele.Rck_upper_01 ));

        const nt_settle = currentCapacity.Rck_current_01*1.15;

        setMaxNtSettle(nt_settle);

        const z = Math.max(...results.map((ele) => ele.deq_01));

        const maxAxisNt = round(nt - (nt % 5), 0);
        const maxAxisNtSettle = round(nt_settle - (nt_settle % 5), 0);
        const maxAxisZ = isNumber(z) ? Math.ceil(z) : 0;

        const initPileLength =
            isNumber(currentPileLength) && currentPileLength > 0
                ? currentPileLength
                : isNumber(pileLength) && pileLength > 0
                ? pileLength
                : roundNumber(maxHeight - pileHeadSpot - 1,2);

        const initNt =
            results.find(
                (ele) => roundNumber(ele.z,2) === roundNumber(initPileLength + pileHeadSpot,2),
            ) || {};

        setCurrentCapacity(initNt);

        setAxisNtScale(
            Array(5)
            .fill(maxAxisNt / 5)
            .reduce((acc, ele, idx) => [...acc, ele * (idx + 1)], []),
        );
        
        setAxisNtSettleScale(
            Array(5)
            .fill(maxAxisNtSettle / 5)
            .reduce((acc, ele, idx) => [...acc, ele * (idx + 1)], []),
        );
        setAxisZScale(
            Array(maxAxisZ*2 + 1)
            .fill(0)
            .reduce((acc, _, idx) => {
                if(idx === 0) {
                    return acc
                } else {
                    return [...acc, (idx*0.5)]
                }    
            }, [])
        )
        setMaxNt(nt);
        setMaxZ(maxAxisZ);

        setPileBaseSpot((state) =>
            isNumber(state) && state > pileHeadSpot
                ? state
                : roundNumber(initPileLength + pileHeadSpot,2),
        );

        setCurrentNt(() => activeCapacity.pullCapacity ? initNt.Rtd_current : initNt.Rcd_current);

        if (soilProfileRef.current) {
            setProfilePileHeadSpot(
                maxHeight === 0
                    ? 0
                    : (soilProfileHeight * pileHeadSpot) / maxHeight,
            );
            setProfilePileLength(maxHeight === 0 ? 0 : (soilProfileHeight * initPileLength) / maxHeight);
        }
    }, [
        maxHeight,
        soilProfileHeight,
        activeCapacity.pileCapacity,
        activeCapacity.pullCapacity,
        activeCapacity.settlement,
        currentPileLength,
        currentCapacity,
        pileHeadSpot,
        pileLength,
        results,
    ]);

    const onChangePileLength = (e) => {
        e.stopPropagation();

        const profileSpots = results.map((ele) => ele.z);
        const clientRect = e.currentTarget.getClientRects()[0];
        const baseSpot = ((clientRect.height - clientRect.bottom + e.clientY) / clientRect.height) * maxHeight;
        const closestSpot = findClosest(profileSpots, baseSpot);
        const closestResult = results.find((ele) => ele.z === roundNumber(closestSpot,2));

        setCurrentNt((state) => closestResult ? closestResult.Rcd_current : state);
        setCurrentCapacity(closestResult);
        setPileBaseSpot((state) =>
            closestSpot > pileHeadSpot ? closestSpot : state,
        );
        setCurrentPileLength((state) =>
            closestSpot > pileHeadSpot ? closestSpot - pileHeadSpot : state,
        );
        setProfilePileLength((state) =>
            closestSpot > pileHeadSpot
                ? (clientRect.height * (closestSpot - pileHeadSpot)) / maxHeight
                : state,
        );
    };

    const onChangeCapacity = (e) => {
        const { name } = e.currentTarget;

        setActiveCapacity((state) => {
            return {
                ...state,
                pileCapacity: false,
                pullCapacity: false,
                settlement: false,
                [name]: true,
            };
        });
    };

    const onChangeCharLoad = (e) => onSetCharLoad(stringToNumber(e.target.value));

    return (
        <Fragment>
            <Row>
                <Col xs={12} sm={6}>
                    <SectionTitle
                        className={styles.title}
                        title={`
                            ${activeCapacity.pileCapacity ? 'Nośność pala na wciskanie na podstawie wyników badań sondą CPT' : 
                            activeCapacity.pullCapacity ? 'Nośność pala na wyciąganie na podstawie wyników badań sondą CPT' : 'Wykres obciążenie - osiadanie'
                            }`}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Button
                        onClick={onChangeCapacity}
                        css={`project-navigation ${styles.buttonMarginLeft}`}
                        name="pileCapacity"
                        altTitle="Nośność na wciskanie"
                        title="Nośność na wciskanie"
                        active={activeCapacity.pileCapacity}
                    />
                    <Button
                        onClick={onChangeCapacity}
                        css={`project-navigation`}
                        name="pullCapacity"
                        altTitle="Nośność na wyciąganie"
                        title="Nośność na wyciąganie"
                        active={activeCapacity.pullCapacity}
                    />
                    <Button
                        onClick={onChangeCapacity}
                        css={`project-navigation ${styles.buttonMarginRight}`}
                        name="settlement"
                        altTitle="Obciążenie - osiadanie"
                        title="Obciążenie - osiadanie"
                        active={activeCapacity.settlement}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}>
                    {
                        activeCapacity.settlement
                        ?
                        <div className={styles.wrapper} ref={soilProfileRef}>
                            <div className={styles.pileWrapper}>
                                <div className={styles.results} ref={resultsRef}>
                                    <div className={styles.horizontalAxis}>
                                        {axisNtSettleScale.map((ele, idx) => {
                                            return (
                                                <span key={idx} className={styles.axisCoord}
                                                    style={{left: `${(resultsWidth / maxNtSettle)*ele}px`}}>
                                                    <p className={ styles.value }>
                                                        {ele}
                                                    </p>
                                                </span>
                                            );
                                        })}

                                        <span className={styles.caret}>
                                            <p className={styles.axisDescription}>
                                                R<sub>c,k</sub>[kN]
                                            </p>
                                        </span>
                                    </div>
                                    <div className={styles.verticalAxis}>
                                        {axisZScale.map((ele, idx) => {
                                        return (
                                            <span key={idx} className={styles.axisCoordVert}
                                                style={{top: `${(resultsHeight / maxZ)*ele}px`}}>
                                                <p className={ styles.valueVert }>{round(ele*10,0)}</p>
                                            </span>
                                        );
                                        })}
                                        <span className={styles.verticalCaret}>
                                            <p className={styles.axisVerticalDescription}>
                                                s[mm]
                                            </p>
                                        </span>
                                    </div>
                                    <div className={styles.chartWrapperResult}>
                                        {
                                            (pileTypeNumber === 4 || pileTypeNumber === 5)
                                            ?
                                            <svg>
                                                <polyline 
                                                    className={styles.shaftChart} 
                                                    points={`0,0 ${(resultsWidth / maxNtSettle)*currentCapacity.Rsk_current_ssg},${(resultsHeight / maxZ)*currentCapacity.ssg_current} ${(resultsWidth / maxNtSettle)*currentCapacity.Rsk_current_ssg},${(resultsHeight / maxZ)*currentCapacity.deq_01}`}
                                                ></polyline>

                                                <polyline 
                                                    className={styles.baseChart} 
                                                    points={`0,0 
                                                    ${(resultsWidth / maxNtSettle)*currentCapacity.Rbk_current_002},${(resultsHeight / maxZ)*currentCapacity.deq_002} 
                                                    ${(resultsWidth / maxNtSettle)*currentCapacity.Rbk_current_003},${(resultsHeight / maxZ)*currentCapacity.deq_003} 
                                                    ${(resultsWidth / maxNtSettle)*currentCapacity.Rbk_current_01},${(resultsHeight / maxZ)*currentCapacity.deq_01}`}
                                                ></polyline>
                                                {
                                                    currentCapacity.ssg_current > currentCapacity.deq_002
                                                    ?
                                                    <Fragment>
                                                        {
                                                            currentCapacity.ssg_current > currentCapacity.deq_003
                                                            ?
                                                            <polyline 
                                                                className={styles.totalChart} 
                                                                points={`0,0 
                                                                ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_002},${(resultsHeight / maxZ)*currentCapacity.deq_002} 
                                                                ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_003},${(resultsHeight / maxZ)*currentCapacity.deq_003} 
                                                                ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_ssg_current},${(resultsHeight / maxZ)*currentCapacity.ssg_current} 
                                                                ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01},${(resultsHeight / maxZ)*currentCapacity.deq_01}`}
                                                            ></polyline>
                                                            :
                                                            <polyline 
                                                                className={styles.totalChart} 
                                                                points={`0,0 
                                                                ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_002},${(resultsHeight / maxZ)*currentCapacity.deq_002} 
                                                                ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_ssg_current},${(resultsHeight / maxZ)*currentCapacity.ssg_current} 
                                                                ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_003},${(resultsHeight / maxZ)*currentCapacity.deq_003} 
                                                                ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01},${(resultsHeight / maxZ)*currentCapacity.deq_01}`}
                                                            ></polyline>                                                            
                                                        }
                                                    </Fragment>
                                                    :
                                                    <polyline 
                                                        className={styles.totalChart} 
                                                        points={`0,0 
                                                        ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_ssg_current},${(resultsHeight / maxZ)*currentCapacity.ssg_current} 
                                                        ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_002},${(resultsHeight / maxZ)*currentCapacity.deq_002} ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_003},${(resultsHeight / maxZ)*currentCapacity.deq_003} 
                                                        ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01},${(resultsHeight / maxZ)*currentCapacity.deq_01}`}
                                                    ></polyline>                                                
                                                }

                                                <text 
                                                    className={styles.text}
                                                    x={-102 + (resultsWidth / maxNtSettle)*currentCapacity.Rck_ssg_current} 
                                                    y={5+ (resultsHeight / maxZ)*currentCapacity.ssg_current}
                                                >
                                                    {`${round(currentCapacity.Rck_ssg_current,1)}kN - ${round(currentCapacity.ssg_current*10,1)}mm`}
                                                </text>
                                                <circle
                                                    cx={(resultsWidth / maxNtSettle)*currentCapacity.Rck_ssg_current}
                                                    cy={(resultsHeight / maxZ)*currentCapacity.ssg_current}
                                                    r="3"
                                                    fill="#fff"
                                                    stroke="#000"
                                                    strokeWidth="2"
                                                >
                                                </circle>

                                                <text 
                                                    className={styles.text}
                                                    x={10 + (resultsWidth / maxNtSettle)*currentCapacity.Rck_current_002} 
                                                    y={2+(resultsHeight / maxZ)*currentCapacity.deq_002}
                                                >
                                                    {`${round(currentCapacity.Rck_current_002,1)}kN - ${round(currentCapacity.deq_002*10,1)}mm`}
                                                </text>
                                                <circle
                                                    cx={(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_002}
                                                    cy={(resultsHeight / maxZ)*currentCapacity.deq_002}
                                                    r="3"
                                                    fill="#fff"
                                                    stroke="#000"
                                                    strokeWidth="2"
                                                >
                                                </circle>

                                                <text 
                                                    className={styles.text}
                                                    x={10 + (resultsWidth / maxNtSettle)*currentCapacity.Rck_current_003} 
                                                    y={5 + (resultsHeight / maxZ)*currentCapacity.deq_003}
                                                >
                                                    {`${round(currentCapacity.Rck_current_003,1)}kN - ${round(currentCapacity.deq_003*10,1)}mm`}
                                                </text>
                                                <circle
                                                    cx={(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_003}
                                                    cy={(resultsHeight / maxZ)*currentCapacity.deq_003}
                                                    r="3"
                                                    fill="#fff"
                                                    stroke="#000"
                                                    strokeWidth="2"
                                                >
                                                </circle>

                                                <text 
                                                    className={styles.text}
                                                    x={10 + (resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01} 
                                                    y={5 + (resultsHeight / maxZ)*currentCapacity.deq_01}
                                                >
                                                    {`${round(currentCapacity.Rck_current_01,1)}kN - ${round(currentCapacity.deq_01*10,1)}mm`}
                                                </text>
                                                <circle
                                                    cx={(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01}
                                                    cy={(resultsHeight / maxZ)*currentCapacity.deq_01}
                                                    r="3"
                                                    fill="#fff"
                                                    stroke="#000"
                                                    strokeWidth="2"
                                                >
                                                </circle>

                                                <text 
                                                    className={styles.textLine}
                                                    x={(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01} 
                                                    y={25 + (resultsHeight / maxZ)*currentCapacity.deq_01}
                                                >
                                                    R<tspan dy ="4">c,k</tspan>
                                                </text>

                                                <text 
                                                    className={styles.textLine}
                                                    x={(resultsWidth / maxNtSettle)*currentCapacity.Rbk_current_01 - 10} 
                                                    y={25 + (resultsHeight / maxZ)*currentCapacity.deq_01}
                                                >
                                                    R<tspan dy ="4">b,k</tspan>
                                                </text>

                                                <text 
                                                    className={styles.textLine}
                                                    x={(resultsWidth / maxNtSettle)*currentCapacity.Rsk_current_ssg - 10} 
                                                    y={25 + (resultsHeight / maxZ)*currentCapacity.deq_01}
                                                >
                                                    R<tspan dy ="4">s,k</tspan>
                                                </text>
                                            </svg>
                                            :
                                            <svg>
                                                <polyline 
                                                    className={styles.shaftChart} 
                                                    points={`0,0 ${(resultsWidth / maxNtSettle)*currentCapacity.Rsk_current_ssg},${(resultsHeight / maxZ)*currentCapacity.ssg_current} ${(resultsWidth / maxNtSettle)*currentCapacity.Rsk_current_01D},${(resultsHeight / maxZ)*currentCapacity.deq_01}`}
                                                ></polyline>

                                                <polyline 
                                                    className={styles.baseChart} 
                                                    points={`0,0 ${(resultsWidth / maxNtSettle)*currentCapacity.Rbk_current_0035},${(resultsHeight / maxZ)*currentCapacity.deq_0035} ${(resultsWidth / maxNtSettle)*currentCapacity.Rbk_current_01D},${(resultsHeight / maxZ)*currentCapacity.deq_01}`}
                                                ></polyline>
                                                {
                                                    currentCapacity.ssg_current > currentCapacity.deq_0035
                                                    ?
                                                    <polyline 
                                                        className={styles.totalChart} 
                                                        points={`0,0 
                                                            ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_0035},${(resultsHeight / maxZ)*currentCapacity.deq_0035} 
                                                            ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_ssg_current},${(resultsHeight / maxZ)*currentCapacity.ssg_current} 
                                                            ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01},${(resultsHeight / maxZ)*currentCapacity.deq_01}`}
                                                    ></polyline>
                                                    :
                                                    <polyline 
                                                        className={styles.totalChart} 
                                                        points={`0,0 ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_ssg_current},${(resultsHeight / maxZ)*currentCapacity.ssg_current} ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_0035},${(resultsHeight / maxZ)*currentCapacity.deq_0035} ${(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01},${(resultsHeight / maxZ)*currentCapacity.deq_01}`}
                                                    ></polyline>                                                    
                                                }

                                                <text 
                                                    className={styles.text}
                                                    x={10 + (resultsWidth / maxNtSettle)*currentCapacity.Rck_ssg_current} 
                                                    y={5 + (resultsHeight / maxZ)*currentCapacity.ssg_current}
                                                >
                                                    {`${round(currentCapacity.Rck_ssg_current,1)}kN - ${round(currentCapacity.ssg_current*10,1)}mm`}
                                                </text>
                                                <circle
                                                    cx={(resultsWidth / maxNtSettle)*currentCapacity.Rck_ssg_current}
                                                    cy={(resultsHeight / maxZ)*currentCapacity.ssg_current}
                                                    r="3"
                                                    fill="#fff"
                                                    stroke="#000"
                                                    strokeWidth="2"
                                                >
                                                </circle>

                                                <text 
                                                    className={styles.text}
                                                    x={10 + (resultsWidth / maxNtSettle)*currentCapacity.Rck_current_0035} 
                                                    y={5 + (resultsHeight / maxZ)*currentCapacity.deq_0035}
                                                >
                                                    {`${round(currentCapacity.Rck_current_0035,1)}kN - ${round(currentCapacity.deq_0035*10,1)}mm`}
                                                </text>
                                                <circle
                                                    cx={(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_0035}
                                                    cy={(resultsHeight / maxZ)*currentCapacity.deq_0035}
                                                    r="3"
                                                    fill="#fff"
                                                    stroke="#000"
                                                    strokeWidth="2"
                                                >
                                                </circle>

                                                <text 
                                                    className={styles.text}
                                                    x={10 + (resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01} 
                                                    y={5 + (resultsHeight / maxZ)*currentCapacity.deq_01}
                                                >
                                                    {`${round(currentCapacity.Rck_current_01,1)}kN - ${round(currentCapacity.deq_01*10,1)}mm`}
                                                </text>
                                                <circle
                                                    cx={(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01}
                                                    cy={(resultsHeight / maxZ)*currentCapacity.deq_01}
                                                    r="3"
                                                    fill="#fff"
                                                    stroke="#000"
                                                    strokeWidth="2"
                                                >
                                                </circle>

                                                <text 
                                                    className={styles.textLine}
                                                    x={(resultsWidth / maxNtSettle)*currentCapacity.Rck_current_01} 
                                                    y={25 + (resultsHeight / maxZ)*currentCapacity.deq_01}
                                                >
                                                    R<tspan dy ="4">c,k</tspan>
                                                </text>

                                                <text 
                                                    className={styles.textLine}
                                                    x={(resultsWidth / maxNtSettle)*currentCapacity.Rbk_current_01D - 20} 
                                                    y={25 + (resultsHeight / maxZ)*currentCapacity.deq_01}
                                                >
                                                    R<tspan dy ="4">b,k</tspan>
                                                </text>

                                                <text 
                                                    className={styles.textLine}
                                                    x={(resultsWidth / maxNtSettle)*currentCapacity.Rsk_current_01D} 
                                                    y={25 + (resultsHeight / maxZ)*currentCapacity.deq_01}
                                                >
                                                    R<tspan dy ="4">s,k</tspan>
                                                </text>
                                            </svg>
                                        }
                                    </div>
                                    <div className={styles.currentHorizontalValue} 
                                        style={{ 
                                            top: `${(resultsHeight / maxZ)*settlements.s}px`,
                                            width: `${(resultsWidth / maxNtSettle)*settlements.f}px`,
                                        }}
                                    >
                                        <div className={styles.line}></div>
                                        {
                                            isNumber(settlements.s) &&
                                            <div className={styles.spaceBetween}>
                                                <p className={styles.currentSettleValue}>{roundNumber(settlements.s*10,2)}mm</p>
                                                <p className={styles.currentSettleValue}>{roundNumber(settlements.f,2)}kN</p>
                                            </div>

                                        }
                                    </div>
                                    <div className={styles.currentVerticalValue}
                                        style={{
                                            height: `${(resultsHeight / maxZ)*settlements.s}px`,
                                            left: `${(resultsWidth / maxNtSettle)*settlements.f}px`,
                                        }}    
                                    >
                                        <div className={styles.lineVertical}></div>
                                    </div>
                                    <div 
                                        className={`${styles.pile} ${[6,7].includes(pileTypeNumber) ? styles.pileH : ''}`}
                                    >
                                        {
                                        (pileTypeNumber === 4 || pileTypeNumber === 5 || pileTypeNumber === 2)
                                        ?
                                        <Fragment>
                                            <div className={`${styles.pileShape} ${styles.circle}`}></div>
                                            <p><b>D={diameterPile}m</b></p>
                                            <p><b>L={round(currentPileLength, 2).toFixed(1)}m</b></p>
                                        </Fragment>

                                        :
                                            (pileTypeNumber === 3)
                                            ?
                                            <Fragment>
                                                <div className={`${styles.pileShape} ${styles.openEnded}`}>
                                                    <div className={`${styles.openEndedInner}`}></div>
                                                </div>
                                                <p><b>D={diameterPile}m</b></p>
                                                <p><b>L={round(currentPileLength, 2).toFixed(1)}m</b></p>
                                            </Fragment>

                                            :
                                            (pileTypeNumber === 6)
                                            ?
                                            <Fragment>
                                                <div className={`${styles.hWrapperView}`}>
                                                    <div className={`${styles.hTypeFlange}`}></div>
                                                    <div className={`${styles.hTypeHeight}`}></div>
                                                    <div className={`${styles.hTypeFlange}`}></div>
                                                </div>
                                                <p><b>{hType}</b></p>
                                                <p><b>L={round(currentPileLength, 2).toFixed(1)}m</b></p>
                                            </Fragment>

                                            :
                                            (pileTypeNumber === 7)
                                            ?
                                            <Fragment>
                                                <div className={`${styles.hWrapperView2H}`}>
                                                    <div className={`${styles.hWrapperView}`}>
                                                        <div className={`${styles.hTypeFlange}`}></div>
                                                        <div className={`${styles.hTypeHeight}`}></div>
                                                        <div className={`${styles.hTypeFlange}`}></div>
                                                    </div>
                                                    <div className={`${styles.hWrapperView}`}>
                                                        <div className={`${styles.hTypeFlange}`}></div>
                                                        <div className={`${styles.hTypeHeight}`}></div>
                                                        <div className={`${styles.hTypeFlange}`}></div>
                                                    </div>
                                                </div>
                                                <p><b>{hType}</b></p>
                                                <p><b>L={round(currentPileLength, 2).toFixed(1)}m</b></p>
                                            </Fragment>

                                            :
                                            <Fragment>
                                                <div className={`${styles.pileShapePref}`}></div>
                                                <p><b>B={diameterPile}m</b></p>
                                                <p><b>L={round(currentPileLength, 2).toFixed(1)}m</b></p>
                                            </Fragment>
                                        }
                                    </div>
                                </div>                            
                            </div>

                            <div>
                                {pileBaseLayer}
                            </div>
                        </div>
                        :
                        <div className={styles.wrapper} ref={soilProfileRef} onClick={onChangePileLength}>
                            <div className={styles.pileWrapperCapacityView}
                                style={{
                                    height: `${profilePileLength}px`,
                                    top: `${profilePileHeadSpot}px`,
                                }}
                            >       
                                <div className={styles.pileCapacityView}>
                                    {
                                    (pileTypeNumber === 4 || pileTypeNumber === 5 || pileTypeNumber === 2)
                                    ?
                                    <div className={`${styles.pileShapeCapacityView} ${styles.circleCapacityView}`}></div>
                                    :
                                        (pileTypeNumber === 3)
                                        ?
                                        <div className={`${styles.pileShapeCapacityView} ${styles.openEndedCapacityView}`}>
                                            <div className={`${styles.openEndedInnerCapacityView}`}></div>
                                        </div>
                                        :
                                        (pileTypeNumber === 6)
                                        ?
                                        <div className={`${styles.hWrapper}`}>
                                            <div className={`${styles.hTypeFlange}`}></div>
                                            <div className={`${styles.hTypeHeight}`}></div>
                                            <div className={`${styles.hTypeFlange}`}></div>
                                        </div>
                                        :
                                        (pileTypeNumber === 7)
                                        ?
                                        <div className={styles.wrapper2H}>
                                            <div className={`${styles.hWrapper}`}>
                                                <div>
                                                    <div className={`${styles.hTypeFlange}`}></div>
                                                    <div className={`${styles.hTypeHeight}`}></div>
                                                    <div className={`${styles.hTypeFlange}`}></div>
                                                </div>
                                                <div>
                                                    <div className={`${styles.hTypeFlange}`}></div>
                                                    <div className={`${styles.hTypeHeight}`}></div>
                                                    <div className={`${styles.hTypeFlange}`}></div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className={`${styles.pileShapeCapacityView}`}></div>
                                    }
                               
                                    <div className={styles.pileLength}>
                                        <p>
                                            L ={' '}
                                            {round(currentPileLength, 2)} m
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.spotWrapper}>
                                    <span className={styles.pileSpot}>
                                        {isNumber(pileHeadSpot) ? pileHeadSpot.toFixed(2) : ''}
                                        <span className={styles.spanBlock}>
                                            {(isNumber(pileHeadSpot) && isNumber(groundLevel)) ? `(${(groundLevel - pileHeadSpot).toFixed(2)})`: ''}
                                        </span>
                                    </span>
                                    <span className={styles.pileSpot}> 
                                        {isNumber(pileBaseSpot) ? pileBaseSpot.toFixed(2) : ''} 
                                        <span className={styles.spanBlock}>
                                            {(isNumber(pileBaseSpot) && isNumber(groundLevel)) ? `(${(groundLevel - pileBaseSpot).toFixed(2)})`: ''}
                                        </span>
                                    </span>
                                </div>
                                <div className={styles.results} ref={resultsRef}>
                                    <div className={styles.horizontalAxis}>
                                        {axisNtScale.map((ele, idx) => {
                                            return (
                                                <span key={idx} className={styles.axisCoord} style={{left: `${(resultsWidth / maxNt)*ele}px`}}>
                                                    <p className={ styles.value }>
                                                        {ele}
                                                    </p>
                                                </span>
                                            );
                                        })}

                                        <span className={styles.caret}>
                                            <p className={styles.axisDescription}>
                                                {
                                                    activeCapacity.pileCapacity
                                                    ?
                                                    <Fragment>R<sub>c,d</sub>[kN]</Fragment>
                                                    :
                                                    <Fragment>R<sub>t,d</sub>[kN]</Fragment>
                                                }
                                               
                                            </p>
                                        </span>
                                    </div>
                                    <div className={styles.verticalAxis}>
                                        <span className={styles.verticalCaret}>
                                            <p className={styles.axisVerticalDescription}>Z[m]</p>
                                        </span>
                                    </div>
                                    <div className={styles.currentHorizontalValue} 
                                        style={{ 
                                            top: `${profilePileLength}px`,
                                            width: `${(resultsWidth * currentNt) / maxNt}px`,
                                        }}
                                    >
                                        <div className={styles.line}></div>
                                    </div>
                                    <div className={styles.currentVerticalValue}
                                        style={{
                                            height: `${profilePileLength}px`,
                                            left: `${
                                                (resultsWidth * currentNt) /
                                                maxNt
                                            }px`,
                                        }}>
                                            <div className={styles.lineVertical}></div>
                                    </div>
                                    <svg className={styles.svgWrapper}>
                                        {results.map((ele, i, a) => {
                                            return (
                                                <Fragment key={i}>
                                                {
                                                    i < (a.length - 1)
                                                    &&
                                                    <Fragment key={i}>
                                                        {
                                                            activeCapacity.pileCapacity
                                                            ?
                                                            <polyline 
                                                                className={styles.svgLine} 
                                                                points={`
                                                                    ${(resultsWidth*ele.Rcd_current) / maxNt}, ${(soilProfileHeight *(ele.z - pileHeadSpot)) /maxHeight} 
                                                                    ${(resultsWidth*a[i+1].Rcd_current) / maxNt}, ${(soilProfileHeight *(a[i+1].z - pileHeadSpot)) /maxHeight}`}
                                                            ></polyline>
                                                            :
                                                            <polyline 
                                                                className={styles.svgLine} 
                                                                points={`
                                                                    ${(resultsWidth*ele.Rtd_current) / maxNt}, ${(soilProfileHeight *(ele.z - pileHeadSpot)) /maxHeight} 
                                                                    ${(resultsWidth*a[i+1].Rtd_current) / maxNt}, ${(soilProfileHeight *(a[i+1].z - pileHeadSpot)) /maxHeight}`}
                                                            ></polyline>
                                                        }
                                                    </Fragment>
                                                    }
                                                </Fragment>
                                            )
                                        })}
                                    </svg>
                                    {results.map((ele, i) => {
                                        return (
                                            <Fragment key={i}>
                                                {
                                                    activeCapacity.pileCapacity
                                                    ?
                                                    <div className={`${styles.capacityPoint} ${!ele.correctConditions.depth || !ele.correctConditions.depthInLayer ? styles.wrongDepth : styles.validDepth}`}
                                                        style={{
                                                            top: `${(soilProfileHeight *(ele.z - pileHeadSpot)) /maxHeight}px`,
                                                            left: `${(ele.Rcd_current > 0) ? (resultsWidth*ele.Rcd_current) / maxNt : 0}px`
                                                        }}>
                                                            <FontAwesomeIcon
                                                                icon={faCheckCircle}
                                                            />
                                                    </div>
                                                    :
                                                    <div className={`${styles.capacityPoint}`}
                                                        style={{
                                                            top: `${(soilProfileHeight *(ele.z - pileHeadSpot)) /maxHeight}px`,
                                                            left: `${(ele.Rtd_current > 0) ? (resultsWidth*ele.Rtd_current) / maxNt : 0}px`}}>
                                                            <FontAwesomeIcon
                                                                icon={faCheckCircle}
                                                            />
                                                    </div>
                                                }
                                            </Fragment>
                                        );
                                    })}
                                </div>                            
                            </div>

                            <div>
                                {[...soilLayer]}
                            </div>
                        </div>
                    }
                </Col>
                <Col xs={12} sm={6}>
                    <div className={styles.resultsOverview}>
                        <SectionTitle
                            className={styles.title}
                            title="Wyniki"
                        />
                        <div className={styles.sticky}>
                            <p><b>Pal {lowerFirstLetter(pileType)}</b></p>
                            {
                                pileTypeNumber === 6
                                ?
                                <Fragment>
                                    <p>
                                        Nazwa przekroju [-]-{' '}
                                        <b>
                                            {isString(hType)
                                                ? hType
                                                : ''}{' '}
                                        </b>
                                    </p>
                                    <p>
                                        Wysokość środnika [h]-{' '}
                                        <b>
                                            {isNumber(hPile.h1)
                                                ? round(hPile.h1, 2)
                                                : ''}{' '}
                                            mm
                                        </b>
                                    </p>
                                    <p>
                                        Szerokość półki [b]-{' '}
                                        <b>
                                            {isNumber(hPile.b)
                                                ? round(hPile.b, 2)
                                                : ''}{' '}
                                            mm
                                        </b>
                                    </p>
                                </Fragment>
                                :
                                pileTypeNumber === 7
                                ?
                                <Fragment>
                                    <p>
                                        Nazwa przekroju [-]-{' '}
                                        <b>
                                            {isString(hType) ? `2x${hType}` : ''}{' '}
                                        </b>
                                    </p>
                                    <p>
                                        Wysokość środnika [h]-{' '}
                                        <b>
                                            {isNumber(hPile.h1) ? round(hPile.h1, 2) : ''}{' '}mm
                                        </b>
                                    </p>
                                    <p>
                                        Szerokość półki [b]-{' '}
                                        <b>
                                            {isNumber(hPile.b) ? round(2*hPile.b, 2) : ''}{' '}mm
                                        </b>
                                    </p>
                                </Fragment>
                                :
                                <Fragment>
                                    <p>
                                        {pileTypeNumber === 1 ? 'Szerokość boku [B]' : 'Średnica pala [D]'} -{' '}
                                        <b>
                                            {isNumber(diameterPile)
                                                ? round(diameterPile, 2)
                                                : ''}{' '}
                                            m
                                        </b>
                                    </p>
                                    {
                                        isBaseWidth && isNumber(baseWidth) &&
                                        <p>
                                        Poszerzona podstawa pala -{' '}
                                        <b>{round(baseWidth,2).toFixed(2)} m</b>
                                    </p>
                                    }
                                </Fragment>
                            }

                            <p>
                                Długość pala w gruncie [L]-{' '}
                                <b>
                                    {isNumber(currentPileLength)
                                        ? round(currentPileLength, 2).toFixed(2)
                                        : ''}{' '}
                                    m
                                </b>
                            </p>
                            {
                                activeCapacity.pullCapacity
                                ?
                                <Fragment>
                                    <p>
                                        <u>
                                            Nośność obliczeniowa pala na wyciąganie [R<sub>t,d</sub>]-{' '}
                                            <b>
                                                {isNumber(currentCapacity.Rtd_current)
                                                    ? round(currentCapacity.Rtd_current, 2)
                                                    : ''}{' '}
                                                kN
                                            </b>
                                        </u>
                                    </p>         
                                    <p>
                                        Minimalna nośność obliczeniowa pala na wyciąganie [R<sub>t,d,min</sub>]-{' '}
                                        <b>
                                            {isNumber(currentCapacity.Rtd_lower)
                                                ? round(currentCapacity.Rtd_lower, 2)
                                                : ''}{' '}
                                            kN
                                        </b>
                                    </p>
                                    <p>
                                        Maksymalna nośność obliczeniowa pala na wyciąganie [R<sub>t,d,max</sub>]-{' '}
                                        <b>
                                            {isNumber(currentCapacity.Rtd_upper)
                                                ? round(currentCapacity.Rtd_upper, 2)
                                                : ''}{' '}
                                            kN
                                        </b>
                                    </p>                           
                                </Fragment>
                                :
                                <Fragment>
                                    <p>
                                        {
                                            (pileTypeNumber === 4 || pileTypeNumber === 5)
                                            ?
                                            <Fragment>
                                                Nośność podstawy [R<sub>b,d</sub>]-{' '}
                                                <b>
                                                    {isNumber(currentCapacity.Rbk_current_01)
                                                        ? roundNumber(currentCapacity.Rbk_current_01/yt, 2)
                                                        : ' '} kN
                                                </b>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                Nośność podstawy [R<sub>b,d</sub>]-{' '}
                                                <b>
                                                    {isNumber(currentCapacity.Rbk_current_01D)
                                                        ? roundNumber(currentCapacity.Rbk_current_01D/yt, 2)
                                                        : ' '} kN
                                                </b>
                                            </Fragment>
                                        }

                                    </p>
                                    <p>
                                        Nośność pobocznicy [R<sub>s,d</sub>]-{' '}
                                        <b>
                                            {
                                                (pileTypeNumber === 4 || pileTypeNumber === 5)
                                                ?
                                                <Fragment>
                                                    {isNumber(currentCapacity.Rsk_current_ssg)
                                                        ? roundNumber(currentCapacity.Rsk_current_ssg/yt, 2)
                                                        : ''}
                                                    kN                                                
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    {isNumber(currentCapacity.Rsk_current_01D)
                                                        ? roundNumber(currentCapacity.Rsk_current_01D/yt, 2)
                                                        : ''}
                                                    kN                                                
                                                </Fragment>
                                            }
                                        </b>
                                    </p>
                                    <p>
                                        <u>
                                            Nośność obliczeniowa pala na wciskanie [R<sub>c,d</sub>]-{' '}
                                            <b>
                                                {isNumber(currentCapacity.Rcd_current)
                                                    ? round(currentCapacity.Rcd_current, 2)
                                                    : ''}{' '}
                                                kN
                                            </b>
                                        </u>
                                    </p>
                                    <p title="Nośność pala dla minimalnych oporów pod podstawą i na pobocznicy pala">
                                        Minimalna nośność obliczeniowa na wciskanie [R<sub>c,d,min</sub>]-{' '}
                                        <b>
                                            {isNumber(currentCapacity.Rck_lower_01)
                                                ? round(currentCapacity.Rck_lower_01/yt, 2)
                                                : ''}{' '}
                                            kN
                                        </b>
                                    </p>
                                    <p title="Nośność pala dla maksymalnych oporów pod podstawą i na pobocznicy pala">
                                        Maksymalna nośność obliczeniowa na wciskanie [R<sub>c,d,max</sub>]-{' '}
                                        <b>
                                            {isNumber(currentCapacity.Rck_upper_01)
                                                ? round(currentCapacity.Rck_upper_01/yt, 2)
                                                : ''}{' '}
                                            kN
                                        </b>
                                    </p>
                                    <p>
                                        Siła do badania próbnego pala [R<sub>c,lt</sub>]-{' '}
                                        <b>
                                            {isNumber(currentCapacity.Rcd_current)
                                                ? round(currentCapacity.Rcd_current*1.5,2)
                                                : ''}{' '}
                                            kN
                                        </b>
                                    </p>
                                    <p>
                                        Osiadanie głowicy pala [s]-{' '}
                                        <b>
                                            {isNumber(settlements.s)
                                                ? round(settlements.s*10,2)
                                                : ''}{' '}
                                            mm
                                        </b>
                                    </p>
                                    <p className={styles.charLoad}>
                                        Charakterystyczne obciążenie głowicy pala [P] -&nbsp;
                                        
                                        {isNumber(currentCapacity.Rcd_current)
                                            ?
                                            <Fragment>
                                                <div className={styles.charLoadWrapper}>
                                                    <InputField
                                                        onChange={onChangeCharLoad}
                                                        value={charLoad}
                                                        type="number"
                                                        placeholder="Charakterystyczne obc. pala"
                                                        name="charLoadPileHead"
                                                        disable={true}
                                                    />
                                                </div>
                                                <p>&nbsp;kN</p>
                                            </Fragment>
                                            : 
                                            ''
                                        }
                                    </p>
                                    <div className={styles.summary}>
                                        <Row className={styles.row}>
                                            <Col xs={12} className={styles.col}>
                                                <h3>PODSUMOWANIE WYNIKÓW:</h3>
                                            </Col>
                                        </Row>
                                        <Row className={styles.row}>
                                            <Col xs={4} className={styles.col}>
                                                <p>
                                                    R<sub>c,d</sub>-{' '}
                                                    <b>
                                                        {isNumber(currentCapacity.Rcd_current)
                                                            ? round(currentCapacity.Rcd_current, 2)
                                                            : ''}{' '}
                                                        kN
                                                    </b>
                                                </p>
                                            </Col>
                                            <Col xs={4} className={styles.col}>
                                                <p>
                                                    R<sub>b,d</sub>-{' '}
                                                    <b>
                                                        {
                                                            (pileTypeNumber === 4 || pileTypeNumber === 5)
                                                            ?
                                                            <Fragment>
                                                                {isNumber(currentCapacity.Rbk_current_01)
                                                                    ? roundNumber(currentCapacity.Rbk_current_01/yt, 2)
                                                                    : ' '}
                                                                    kN
                                                            </Fragment>
                                                            :
                                                            <Fragment>
                                                                {isNumber(currentCapacity.Rbk_current_01D)
                                                                    ? roundNumber(currentCapacity.Rbk_current_01D/yt, 2)
                                                                    : ' '}
                                                                kN
                                                            </Fragment>
                                                        }

                                                    </b>
                                                </p>
                                            </Col>                                        
                                            <Col xs={4} className={styles.col}>
                                                <p>
                                                    R<sub>s,d</sub>-{' '}
                                                    <b>
                                                        {
                                                            (pileTypeNumber === 4 || pileTypeNumber === 5)
                                                            ?
                                                            <Fragment>
                                                                {isNumber(currentCapacity.Rsk_current_ssg)
                                                                    ? roundNumber(currentCapacity.Rsk_current_ssg/yt, 2)
                                                                    : ' '}
                                                                kN
                                                            </Fragment>
                                                            :
                                                            <Fragment>
                                                                {isNumber(currentCapacity.Rsk_current_01D)
                                                                    ? roundNumber(currentCapacity.Rsk_current_01D/yt, 2)
                                                                    : ' '}
                                                                kN
                                                            </Fragment>
                                                        }
                                                    </b>
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className={styles.row}>
                                            <Col xs={4} className={styles.col}>
                                                <p>
                                                    P-{' '}
                                                    <b>
                                                        {isNumber(charLoad)
                                                            ? round(charLoad,2)
                                                            : ''}{' '}
                                                        kN
                                                    </b>
                                                </p>
                                            </Col>
                                            <Col xs={4} className={styles.col}>
                                                <p>
                                                    s-{' '}
                                                    <b>
                                                        {isNumber(settlements.s)
                                                            ? round(settlements.s*10,2)
                                                            : ''}{' '}
                                                        mm
                                                    </b>
                                                </p>
                                            </Col>
                                            <Col xs={4} className={styles.col}>
                                                <p>
                                                    L<sub>pala</sub>-{' '}
                                                    <b>
                                                        {isNumber(currentPileLength)
                                                            ? round(
                                                                    currentPileLength,
                                                                    2,
                                                                ).toFixed(2)
                                                            : ''}{' '}
                                                        m                                                    
                                                    </b>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className={styles.warnings}>
                                        <h3>UWAGI:</h3>
                                        <Fragment>
                                            {
                                                currentCapacity.correctConditions &&
                                                <Fragment>
                                                {
                                                    currentCapacity.correctConditions.depth 
                                                    ? <p>Odległość spodu pala od warstw nienośnych lub ostatniej warstwy profilu jest poprawna.</p>
                                                    : <p className={styles.wrongDepth}>
                                                        Odległość spodu pala od warstw nienośnych lub ostatniej warstwy profilu jest zbyt mała, minimum 1,5m lub 5*D<sub>Eq</sub>.
                                                    </p>
                                                }
                                                {
                                                    currentCapacity.correctConditions.depthInLayer
                                                    ? <p>Zagłębienie pala w gruntach nośnych jest prawidłowe.</p>
                                                    : <p className={styles.wrongDepth}>
                                                        Zagłębienie pala w gruntach nośnych jest zbyt małe, minimum 2,5m.
                                                    </p>
                                                }
                                                </Fragment>
                                            }
                                        </Fragment>
                                    </div>
                                </Fragment>
                            }
                        </div>
                    </div>
                </Col>                
            </Row>
        </Fragment>
    );
};

export default React.memo(
    SoilProfile,
    (prev, next) => stringify(prev) === stringify(next),
);
