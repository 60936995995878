import { Outlet, useOutletContext } from "react-router-dom";
import VerticalCapacityProvider from "../../../context/VerticalCapacity";
import { VerticalCapacityNavigation } from "../../../pages";

const VerticalCapacityLayout = () => {
    const context = useOutletContext();

    return (
        <VerticalCapacityProvider>
            <VerticalCapacityNavigation report={context.report}/>
            <Outlet context={context}/>
        </VerticalCapacityProvider>
    )
}

export default VerticalCapacityLayout;