import { Outlet, useOutletContext } from "react-router-dom";
import AdminPaymentsProvider from "../../../context/AdminPayments";

const AdminPaymentsLayout = () => {
    return (
        <AdminPaymentsProvider>
            <Outlet context={useOutletContext()}/>
        </AdminPaymentsProvider>
    )
}

export default AdminPaymentsLayout;