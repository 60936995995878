import { isNumber, roundNumber } from "../../../../utils";

const techFactor = ({id,il,noncohesive=[],cohesive=[]}) => {
    if(isNumber(id)){
        if(id >= 0.2 && id <= 0.67){
            return noncohesive[0];
        } else if(id > 0.67){
            return noncohesive[1];
        } else {
            return 0
        }
    } else if(isNumber(il)){
        if(il < 0){
            return cohesive[0];
        } else if(il >= 0 && il <= 0.75){
            return cohesive[1];
        } else {
            return 0;
        }
    } else {
        return '';
    }
}
//1 - wbijane i wwibrowywane
//2- wiercone i wkręcane
const hciDeph = (type, d, { id, il }) => {
    const d0 = 0.4;
    const hc = 10;

    if(isNumber(type) && isNumber(d) && (isNumber(id) || isNumber(il))){
        if(type === 1){
            if((d < 0.4 || d > 0.4) && id > 0.33){
                return roundNumber(hc*Math.sqrt(d/d0),2);
            } else {
                return hc
            }
        } else if(type === 2){
            if(d > 0.4 && id > 0.33){
                return roundNumber(1.3*hc*Math.sqrt(d/d0),2);
            } else {
                return hc
            }
        } else {
            return ''
        }
    } else {
        return ''
    }
}

const pilesType = {
    'CFA': { 
        'Wiercony': { 
            hci: (d,id) => hciDeph(2,d,id),
            tci: () => 5,
            sp: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.9, 0.8]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [1,1],
                        cohesive: [1,1]
                    })
                }
            },
            ss: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.8, 0.7]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [1.1,1],
                        cohesive: [1,1]
                    })
                }
            },
            sw: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.5, 0.5]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [0.7,0.7],
                        cohesive: [0.7,0.6]
                    })
                }
            }
        }
    },
    'Z profili stalowych': { 
        'Wbijany': {
            hci: (d,id) => hciDeph(1,d,id),
            tci: () => 5,
            sp: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1,1],
                    cohesive: [1,1]
                })
            },
            ss: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.9,0.8],
                    cohesive: [1,0.9]
                })
            },
            sw: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.5,0.5],
                    cohesive: [0.5,0.5]
                })
            },
        },
        'Wwibrowywany': {
            hci: (d,id) => hciDeph(1,d,id),
            tci: () => 5,
            sp: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1,1],
                    cohesive: [0,0]
                })
            },
            ss: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.7,0.7],
                    cohesive: [0,0]
                })
            },
            sw: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.4,0.4],
                    cohesive: [0,0]
                })
            },
        },
        'Wpłukiwany - ostatni 1m wbijany': {
            hci: (d,id) => hciDeph(1,d,id),
            tci: () => 5,
            sp: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1,1],
                    cohesive: [0,0]
                })
            },
            ss: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.6,0.5],
                    cohesive: [0,0]
                })
            },
            sw: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.3,0.3],
                    cohesive: [0,0]
                })
            },
        }
    },  
    'Z rury stalowej z dnem otwartym': { 
        'Wbijany': {
            hci: (d,id) => hciDeph(1,d,id),
            tci: () => 5,
            sp: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1.1,1.1],
                    cohesive: [1,1]
                })
            },
            ss: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1,1],
                    cohesive: [1.1,0.9]
                })
            },
            sw: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.5,0.5],
                    cohesive: [0.5,0.5]
                })
            },
        },
        'Wwibrowywany': {
            hci: (d,id) => hciDeph(1,d,id),
            tci: () => 5,
            sp: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1,1],
                    cohesive: [0,0]
                })
            },
            ss: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.8,0.8],
                    cohesive: [0,0]
                })
            },
            sw: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.5,0.5],
                    cohesive: [0,0]
                })
            },
        },
        'Wpłukiwany - ostatni 1m wbijany': {
            hci: (d,id) => hciDeph(1,d,id),
            tci: () => 5,
            sp: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1,1],
                    cohesive: [0,0]
                })
            },
            ss: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.6,0.7],
                    cohesive: [0,0]
                })
            },
            sw: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.4,0.4],
                    cohesive: [0,0]
                })
            },
        }
    }, 
    'Z rury stalowej z dnem zamkniętym': {
        'Wbijany': {
            hci: (d,id) => hciDeph(1,d,id),
            tci: () => 5,
            sp: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1.1,1.1],
                    cohesive: [1,1]
                })
            },
            ss: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1,1],
                    cohesive: [1.1,0.9]
                })
            },
            sw: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.5,0.5],
                    cohesive: [0.5,0.5]
                })
            },
            
        },
        'Wwibrowywany': {
            hci: (d,id) => hciDeph(1,d,id),
            tci: () => 5,
            sp: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1,1],
                    cohesive: [0,0]
                })
            },
            ss: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.8,0.8],
                    cohesive: [0,0]
                })
            },
            sw: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.5,0.5],
                    cohesive: [0,0]
                })
            },
        },
        'Wpłukiwany - ostatni 1m wbijany': {
            hci: (d,id) => hciDeph(1,d,id),
            tci: () => 5,
            sp: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1,1],
                    cohesive: [0,0]
                })
            },
            ss: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.6,0.7],
                    cohesive: [0,0]
                })
            },
            sw: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.4,0.4],
                    cohesive: [0,0]
                })
            },
        }
    },
    'Żelbetowe prefabrykowane': {
        'Wbijany': {
            hci: (d,id) => hciDeph(1,d,id),
            tci: () => 5,
            sp: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1.1,1],
                    cohesive: [1,1]
                })
            },
            ss: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1.1,1],
                    cohesive: [1,0.9]
                })
            },
            sw: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.6,0.6],
                    cohesive: [0.7,0.6]
                })
            },
        },
        'Wwibrowywany': {
            hci: (d,id) => hciDeph(1,d,id),
            tci: () => 5,
            sp: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1,1],
                    cohesive: [0,0]
                })
            },
            ss: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.8,0.8],
                    cohesive: [0,0]
                })
            },
            sw: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.5,0.5],
                    cohesive: [0,0]
                })
            },
        },
        'Wpłukiwany - ostatni 1m wbijany': {
            hci: (d,id) => hciDeph(1,d,id),
            tci: () => 5,
            sp: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1,1],
                    cohesive: [0,0]
                })
            },
            ss: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.8,0.8],
                    cohesive: [0,0]
                })
            },
            sw: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.4,0.4],
                    cohesive: [0,0]
                })
            },
        }
    }, 
    'Wiercone bez iniekcji pod postawą': {
        'Wiercony w rurze obsadowej wyciąganej': {
            hci: (d,id) => hciDeph(2,d,id),
            tci: () => 5,
            sp: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.9, 0.8]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [1,1],
                        cohesive: [1,1]
                    })
                }
            },
            ss: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.7, 0.6]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [0.9,0.8],
                        cohesive: [0.9,0.9]
                    })
                }
            },
            sw: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.5, 0.4]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [0.7,0.7],
                        cohesive: [0.6,0.6]
                    })
                }
            }
        },
        'Wiercony w rurze obsadowej pozostawianej': {
            hci: (d,id) => hciDeph(2,d,id),
            tci: () => 5,
            sp: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.9, 0.8]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [1,1],
                        cohesive: [1,1]
                    })
                }
            },
            ss: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.7, 0.6]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [0.8,0.8],
                        cohesive: [0.8,0.8]
                    })
                }
            },
            sw: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.5, 0.4]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [0.6,0.6],
                        cohesive: [0.6,0.5]
                    })
                }
            }
        },
        'Wiercony w rurze obsadowej zagłębianej i wyciąganej głowicą pokrętną': {
            hci: (d,id) => hciDeph(2,d,id),
            tci: () => 5,
            sp: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.9, 0.8]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [1.1,1],
                        cohesive: [1,1]
                    })
                }
            },
            ss: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.8, 0.7]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [1.1,1],
                        cohesive: [1,1]
                    })
                }
            },
            sw: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.5, 0.5]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [0.7,0.7],
                        cohesive: [0.7,0.6]
                    })
                }
            }
        },
        'Wiercony w zawiesinie iłowej': {
            hci: (d,id) => hciDeph(2,d,id),
            tci: () => 5,
            sp: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [1, 1]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [1,1],
                        cohesive: [1,1]
                    })
                }
            },
            ss: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.9, 0.9]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [1,1],
                        cohesive: [0.9,0.9]
                    })
                }
            },
            sw: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.6, 0.6]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [0.7,0.7],
                        cohesive: [0.5,0.5]
                    })
                }
            }
        },
        'Wiercony metodą obrotowo-ssącą z płuczką wodną': {
            hci: (d,id) => hciDeph(2,d,id),
            tci: () => 5,
            sp: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [1,1]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [1,1],
                        cohesive: [0,0]
                    })
                }
            },
            ss: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [1,1]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [1,1],
                        cohesive: [0,0]
                    })
                }
            },
            sw: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.7, 0.7]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [0.7,0.7],
                        cohesive: [0,0]
                    })
                }
            }
        },
    }, 
    'Omega': {
        'Wkręcany': {
            hci: (d,id) => hciDeph(2,d,id),
            tci: () => 5,
            sp: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.9,0.8]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [1,1],
                        cohesive: [1,1]
                    })
                }
            },
            ss: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1.1,1],
                    cohesive: [1,0.9]
                })
            },
            sw: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.6,0.6],
                    cohesive: [0.6,0.6]
                })
            },
        }
    }, 
    'Atlas': {
        'Wkręcany': {
            hci: (d,id) => hciDeph(2,d,id),
            tci: () => 5,
            sp: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.9,0.8]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [1,1],
                        cohesive: [1,1]
                    })
                }
            },
            ss: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1.1,1],
                    cohesive: [1,0.9]
                })
            },
            sw: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.6,0.6],
                    cohesive: [0.6,0.6]
                })
            },
        }
    },
    'Vibro': {
        'Wwibrowywany': {
            hci: (d,id) => hciDeph(1,d,id),
            tci: () => 5,
            sp: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1.4,1.1],
                    cohesive: [1,1]
                })
            },
            ss: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1.1, 1],
                    cohesive: [1,0.9]
                })
            },
            sw: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.6,0.6],
                    cohesive: [0.6,0.6]
                })
            },
        }
    },
    /*'Vibro-Fundex': {
        'Wbijany': {
            hci: (d,id) => hciDeph(1,d,id),
            tci: () => 5,
            sp: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1.4,1.1],
                    cohesive: [1,1]
                })
            },
            ss: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1.1, 1],
                    cohesive: [1,0.9]
                })
            },
            sw: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.6,0.6],
                    cohesive: [0.6,0.6]
                })
            },
        }
    },*/
    'FDP': {
        'Wkręcany': {
            hci: (d,id) => hciDeph(2,d,id),
            tci: () => 5,
            sp: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.9,0.8]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [1,1],
                        cohesive: [1,1]
                    })
                }
            },
            ss: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1.1,1],
                    cohesive: [1,0.9]
                })
            },
            sw: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.6,0.6],
                    cohesive: [0.6,0.6]
                })
            },           
        }
    },
    'SDP': {
        'Wkręcany': {
            hci: (d,id) => hciDeph(2,d,id),
            tci: () => 5,
            sp: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.9,0.8]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [1,1],
                        cohesive: [1,1]
                    })
                }
            },
            ss: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1.1,1],
                    cohesive: [1,0.9]
                })
            },
            sw: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.6,0.6],
                    cohesive: [0.6,0.6]
                })
            },          
        }
    },
    'PCS Lambda': {
        'Wiercony': {
            hci: (d,id) => hciDeph(2,d,id),
            tci: () => 5,
            sp: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.9,0.8]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [1,1],
                        cohesive: [1,1]
                    })
                }
            },
            ss: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1.1,1],
                    cohesive: [1,0.9]
                })
            },
            sw: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [0.6,0.6],
                    cohesive: [0.6,0.6]
                })
            },
        }   
    },
    'Starsol': {
        'Wiercony': {
            hci: (d,id) => hciDeph(2,d,id),
            tci: () => 5,
            sp: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.9, 0.8]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [1,1],
                        cohesive: [1,1]
                    })
                }
            },
            ss: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.8, 0.7]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [1.1,1],
                        cohesive: [1,1]
                    })
                }
            },
            sw: ({id, il}, soil) => {
                if(soil === 'Piasek drobny' || soil === 'Piasek pylasty'){
                    return techFactor({
                        id,
                        noncohesive: [0.5, 0.5]
                    })
                } else {
                    return techFactor({
                        id,
                        il,
                        noncohesive: [0.7,0.7],
                        cohesive: [0.7,0.6]
                    })
                }
            }
        }
    },
    'Franki': {
        'Wbijany':{
            hci: (d,id) => hciDeph(1,d,id),
            tci: () => 5,
            sp: ({id, il}) => {
                if(isNumber(id)){
                    if(id >= 0.2 && id < 0.5){
                        return 1.8;
                    } else if(id >= 0.5 && id <= 0.67){
                        return 1.3+((0.5/0.17)*(0.67-id))
                    } else if(id > 0.67){
                        return 1.3;
                    } else {
                        return 0
                    }
                } else if(isNumber(il)){
                    if(il < 0){
                        return 1.2;
                    } else if(il >= 0 && il <= 0.75){
                        return 1.1;
                    } else {
                        return 0;
                    }
                } else {
                    return 0;
                }
            },
            ss: ({id, il}) => {
                if(isNumber(id)){
                    if(id >= 0.2 && id < 0.5){
                        return 1.6;
                    } else if(id >= 0.5 && id <= 0.67){
                        return 1.1+((0.5/0.17)*(0.67-id))
                    } else if(id > 0.67){
                        return 1.1;
                    } else {
                        return 0
                    }
                } else if(isNumber(il)){
                    if(il < 0){
                        return 1.1;
                    } else if(il >= 0 && il <= 0.75){
                        return 1;
                    } else {
                        return 0;
                    }
                } else {
                    return 0;
                }
            },
            sw: ({id, il}) => {
                return techFactor({
                    id,
                    il,
                    noncohesive: [1,1],
                    cohesive: [0.8,0.7]
                })
            },
        }
    }
};

export default pilesType