import { useState } from "react";
import { isNumber, roundNumber } from "../../../utils";
import useDeepCompareEffect from "use-deep-compare-effect";

const defaultState = {
    index: 0,
    rebarNumber: {
        value: '',
        isValid: null
    },
    rebarNumberAs1: {
        value: '',
        isValid: null
    },
    rebarNumberAs2: {
        value: '',
        isValid: null
    },
    rebarSpiral: {
        value: '',
        isValid: null
    },
    rebarDiameter: {
        value: '',
        isValid: null
    },
    rebarDiameterAs1: {
        value: '',
        isValid: null
    },
    rebarDiameterAs2: {
        value: '',
        isValid: null
    },
    rebarAreaAs1: {
        value: '',
        isValid: true
    },
    rebarAreaAs2: {
        value: '',
        isValid: true
    },
    rebarArea: {
        value: '',
        isValid: true
    },
    space: {
        value: '',
    },
    spaceAs2: {
        value: '',
    },
    spaceAs1: {
        value: '',
    },
    condition: {
        value: '',
        isValid: true
    },
    conditionAs2: {
        value: '',
        isValid: true
    },
    conditionAs1: {
        value: '',
        isValid: true
    },
    conditionShear: {
        value: '',
        isValid: true
    },
    calcAs: {
        value: ''
    },
    calcAs1: {
        value: ''
    },
    calcAs2: {
        value: ''
    },
    incorrectSection: {
        value: false
    }
};
const checkShearSpace = ({ b, h, space, d, memberType, rebar }) => { 
    const areNumbers = [b, h, space, d, rebar].every(ele => isNumber(ele));
    if(areNumbers){
        const minSpace = Math.min(b*0.5, 100);
        if(memberType === 'Belka'){
            if(space > Math.min(0.75*d, 600)){
                return false;
            } 
            if(space < minSpace){
                return false;
            }
            return true;
        } else {
            if(space > Math.min(400, 20*rebar, b, h)){
                return false;
            } 
            if(space < minSpace){
                return false;
            }
            return true;
        }
    } else {
        return ''
    }    
}
const checkSpace = ({ rebarNumber, space, rebarDiameter, h }) => {
    const ac = h*h*0.25*Math.PI;
    const rebarsArea = rebarNumber*rebarDiameter*rebarDiameter*0.25*Math.PI;
    const areNumbers = [rebarNumber, space, rebarDiameter].every(ele => isNumber(ele));

    if(areNumbers){
        const minSpace = Math.max(rebarDiameter, 20);
        if((rebarsArea/ac) > 0.04){
            return false;
        }
        if(rebarNumber < 4){
            return false;
        } 
        if(space < minSpace){
            return false;
        }

        return true;
    } else {
        return ''
    }
}
const checkSpaceAs2 = ({ rebarNumber, space, rebarDiameter, h, b, as1 }) => {
    const areNumbers = [rebarNumber, space, rebarDiameter, h].every(ele => isNumber(ele));
    const ac = h*b;
    const rebarsArea = rebarNumber*rebarDiameter*rebarDiameter*0.25*Math.PI + as1;
   
    if(areNumbers){
        const minSpace = Math.max(rebarDiameter, 20);
        if((rebarsArea/ac) > 0.04){
            return false;
        }
        if(rebarNumber < 2){
            return false;
        } 

        if(space > 400 || space > 3*h){
            return false;
        }
        if(space < minSpace){
            return false;
        }

        return true;
    } else {
        return ''
    }
}
const checkSpaceAs1 = ({ rebarNumber, space, rebarDiameter, h, b, as2 }) => {
    const areNumbers = [rebarNumber, space, rebarDiameter, h].every(ele => isNumber(ele));
    const ac = h*b;
    const rebarsArea = rebarNumber*rebarDiameter*rebarDiameter*0.25*Math.PI + as2;
    
    if(areNumbers){
        const minSpace = Math.max(rebarDiameter, 20);
        if((rebarsArea/ac) > 0.04){
            return false;
        }
        if(rebarNumber < 2){
            return false;
        } 
        if(space > 250 || space > 2*h){
            return false
        }
        if(space < minSpace){
            return false;
        }

        return true;
    } else {
        return ''
    }
}
const CheckCapacity = () => {

    const [state, setState] = useState([{...defaultState}]);
    const [resultsState, setResultsState] = useState(null);

    useDeepCompareEffect(() => {
        if(!state.incorrectSection && !!resultsState){
            setState(state => {
                return state.map(ele => {
                    if(resultsState.circular){
                        const innerD = ele.rebarDiameter ? resultsState.h - resultsState.coverBottom - resultsState.coverUp - 2*resultsState.spiral - ele.rebarDiameter.value : '';
                        const circleAround = innerD * Math.PI;
                        const angle = ele.rebarDiameter ? Math.asin(ele.rebarDiameter.value/innerD) : '';
                        const angleDegree = angle*360/Math.PI;
                        const space = ele.rebarNumber ? ele.rebarNumber.value < 2 ? 0 : roundNumber((circleAround - ele.rebarNumber.value*(Math.PI*innerD*angleDegree/360))/ele.rebarNumber.value,2) : '';
                        const rebarArea = (ele.rebarNumber && ele.rebarDiameter) ? roundNumber(ele.rebarNumber.value * Math.PI * ele.rebarDiameter.value * ele.rebarDiameter.value * 0.25 * 0.01,2) : '';

                        const areValidNumbers = (ele.rebarNumber && ele.space && ele.rebarDiameter && ele.ned)
                            ?
                            [ele.rebarNumber.value, ele.space.value, ele.rebarDiameter.value, ele.ned.value, resultsState.h].every(ele => isNumber(ele))
                            :
                            false;
        
                        return {
                            ...ele,
                            ...(isNumber(rebarArea)) && {
                                rebarArea: {
                                    value: rebarArea,
                                    isValid: isNumber(rebarArea) ? true : false
                                }                        
                            },
                            ...isNumber(space) && {
                                space: {
                                    value: space < 0 ? 0 : space,
                                }
                            },
                            ...areValidNumbers && {
                                condition: {
                                    value: checkSpace({
                                        rebarNumber: ele.rebarNumber.value,
                                        space: ele.space.value,
                                        rebarDiameter: ele.rebarDiameter.value,
                                        h: resultsState.h
                                    })
                                }
                            }
                        }
                    } else {
                        const spaceAs1 = ele.rebarNumberAs1 && ele.rebarDiameterAs1
                            ? isNumber(ele.spaceAs1.value) ? roundNumber(ele.spaceAs1.value,2) : 0

                            : 0;
                        const spaceAs2 = ele.rebarNumberAs2 && ele.rebarDiameterAs2
                        ? isNumber(ele.spaceAs2.value) ? roundNumber(ele.spaceAs2.value,2) : 0
                        : 0;

                        const rebarAreaAs1 = ele.rebarNumberAs1 && ele.rebarDiameterAs1
                        ? roundNumber(ele.rebarNumberAs1.value * Math.PI * ele.rebarDiameterAs1.value * ele.rebarDiameterAs1.value * 0.25 * 0.01,2)
                        : 0;
                        const rebarAreaAs2 = ele.rebarNumberAs2 && ele.rebarDiameterAs2
                        ? roundNumber(ele.rebarNumberAs2.value * Math.PI * ele.rebarDiameterAs2.value * ele.rebarDiameterAs2.value * 0.25 * 0.01,2)
                        : 0;

                        return {
                            ...ele,
                            ...isNumber(spaceAs1) && {
                                spaceAs1: {
                                    value: spaceAs1 < 0 ? 0 : spaceAs1,
                                }
                            },
                            ...isNumber(spaceAs2) && {
                                spaceAs2: {
                                    value: spaceAs2 < 0 ? 0 : spaceAs2,
                                }
                            },
                            ...(isNumber(rebarAreaAs1)) && {
                                rebarAreaAs1: {
                                    value: rebarAreaAs1,
                                    isValid: true
                                }
                            },
                            ...(isNumber(rebarAreaAs2)) && {
                                rebarAreaAs2: {
                                    value: rebarAreaAs2,
                                    isValid: true
                                }
                            },
                        }
                    }
                })
            })            
        }

    }, [
        state,
        resultsState
    ]);

    const loadProject = (checkCapacity=[]) => {
        setState(() => {
            return checkCapacity
        });
    };

    const checkCapacity = {
        state: state,
        updateState: setState,
        updateResultsState: setResultsState,
        onCheckSpaceAs1: checkSpaceAs1,
        onCheckSpaceAs2: checkSpaceAs2,
        onCheckShearAs: checkShearSpace,

        loadProject: loadProject,
    }
    return {
        checkCapacity
    }
}

export default CheckCapacity;