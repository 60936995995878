import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isObjectId } from '../../../config/ProjectConfig';
import { authContext } from '../../../context/Authentication';
import { AuthFetch } from '../../../routes';
import { isString } from '../../../utils';
import { useIsMountedState } from '../../../hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';

const ProjectsListHelpers = ({
    setLoading,
    setAlert,
    fetch,
    onLoadProjectName,
}) => {
    const { email: userEmail } = useContext(authContext);
    const [projectName, setProjectName] = useState({
        value: '',
        isValid: null,
    });
    const [editProjectName, setEditProjectName] = useState({
        value: '',
        isValid: null,
    });
    const [allProjects, setAllProjects] = useState([]);
    const [subUsers, setSubUsers] = useState([]);
    const [userAccessToProject, setUserAccessToProject] = useState([]);
    const [allCalculationsWitoutProfile, setAllCalculationsWitoutProfile] =
        useState([]);
    const [currentProject, setCurrentProject] = useState({
        name: '',
        id: '',
        userId: '',
        profiles: [],
    });
    const [currentProjectId, setCurrentProjectId] = useState('');
    const [currentUser, setCurrentUser] = useState('');
    const [currentUserProjects, setCurrentUserProjects] = useState([]);
    const location = useLocation();
    const [newProject, setNewProject] = useState(false);
    const [projectsCopy, setProjectsCopy] = useState([]);
    const [editedUserAccess, setEditedUserAccess] = useState([]);
    const isMountedState = useIsMountedState();

    useDeepCompareEffect(() => {
        const userEmailMatch = currentUser.match(/\(([^)]+)\)/);
        const currentUserObj =
            allProjects.length > 0
                ? allProjects.find((ele) => ele.email === userEmail)
                : undefined;

        if (userEmailMatch) {
            const subUserProjects = allProjects.find(
                (ele) => ele.email === userEmailMatch[1],
            );

            if (subUserProjects) {
                setCurrentUserProjects(subUserProjects.projects);
                setProjectsCopy(subUserProjects.projects);
            } else {
                setCurrentUserProjects(
                    currentUserObj ? currentUserObj.projects : [],
                );
                setProjectsCopy(currentUserObj ? currentUserObj.projects : []);
                setCurrentUser('');
            }
        } else {
            setCurrentUserProjects(
                currentUserObj ? currentUserObj.projects : [],
            );
            setProjectsCopy(currentUserObj ? currentUserObj.projects : []);
            setCurrentUser('');
        }
    }, [currentUser, userEmail, allProjects]);

    useDeepCompareEffect(() => {
        AuthFetch({
            url: '/api/projects/list/names/users/profiles',
            method: 'GET',
        })
        .then((response) => {
            if(isMountedState.current){
                setNewProject(false);
                if (
                    Array.isArray(response.responseData.projects) &&
                    response.responseData.projects.length > 0
                ) {
                    setSubUsers(response.responseData.subUsers);
                    setAllProjects(() => {
                        return response.responseData.projects.reduce(
                            (acc, ele) => {
                                const { userId, ...rest } = ele;
                                const userExistsIndex =
                                    acc.length > 0
                                        ? acc.findIndex(
                                            (ele) => ele.id === userId.id,
                                        )
                                        : -1;

                                if (userExistsIndex > -1) {
                                    acc[userExistsIndex] = {
                                        ...acc[userExistsIndex],
                                        projects: [
                                            ...acc[userExistsIndex].projects,
                                            {
                                                ...rest,
                                                userId: userId.id,
                                                firstName: userId.firstName,
                                                lastName: userId.lastName,
                                                email: userId.email,
                                            },
                                        ],
                                    };
                                    return [...acc];
                                }
                                return [
                                    ...acc,
                                    {
                                        ...userId,
                                        projects: [
                                            {
                                                ...rest,
                                                userId: userId.id,
                                                firstName: userId.firstName,
                                                lastName: userId.lastName,
                                                email: userId.email,
                                            },
                                        ],
                                    },
                                ];
                            },
                            [],
                        );
                    });
                } else {
                    setAllProjects([]);
                }                    
            }
        })
        .catch(() => {
            if(isMountedState.current){
                setAllProjects(() => []);
                setAlert({
                    error: true,
                    message: 'Błąd podczas wczytywania projektów',
                });
            }
        });
    }, [newProject, location.pathname, currentProject, setAlert, isMountedState]);

    useEffect(() => {
        setCurrentUser('');
        setAllCalculationsWitoutProfile([]);
        setCurrentProject({
            name: '',
            id: '',
            userId: '',
            profiles: [],
        });
        onLoadProjectName({
            state: 'init',
            value: '',
        });
    }, [location.pathname, onLoadProjectName]);

    const onChange = (e) => {
        const target = e.currentTarget;

        if (target.name === 'currentUser') {
            setCurrentUser(target.value);
            setAllCalculationsWitoutProfile([]);
            setCurrentProject({
                name: '',
                id: '',
                userId: '',
                profiles: [],
            });
            onLoadProjectName({
                state: 'init',
                value: '',
            });
        } else {
            setProjectName((state) => ({
                ...state,
                value: target.value,
            }));
        }
    };

    const onBlur = useCallback((e) => {
        const target = e.currentTarget;

        setProjectName((state) => ({
            ...state,
            isValid: target ? target.checkValidity() : false,
        }));
    }, []);

    const onSubmit = () => {
        if (projectName.isValid) {
            setLoading({ name: 'creatingProject', loading: true });
            AuthFetch({
                url: fetch.create.url,
                method: fetch.create.method,
                body: {
                    name: projectName.value,
                    userAccessToProject: userAccessToProject,
                },
            }).then((response) => {
                setLoading({ name: 'creatingProject', loading: false });
                if (response.success) {
                    if (response.responseData.exists) {
                        setAlert({
                            error: true,
                            message: 'Projekt o podanej nazwie już istnieje',
                        });
                    } else {
                        setNewProject(true);
                        setAlert({
                            error: false,
                            message: 'Projekt został utworzony',
                            hideTime: 2000,
                        });
                    }
                } else {
                    setAlert({
                        error: true,
                        message: 'Błąd podczas tworzenia projektu',
                    });
                }
            });
        } else {
            setAlert({ error: true, message: 'Nazwa projektu jest wymagana' });
            setProjectName((state) => ({ ...state, isValid: !!state.isValid }));
        }
    };

    const onChangeCurrentProject = (obj) => {
        setCurrentProject(obj);
        onLoadProjectName({
            state: 'init',
            value: obj.name,
        });
        AuthFetch({
            url: `${fetch.loadCalculations.url}/${obj.id}`,
            method: fetch.loadCalculations.method,
        }).then((response) => {
            if (response.success) {
                setAllCalculationsWitoutProfile(
                    [].concat.apply(
                        [],
                        Object.values(response.responseData).filter((ele) =>
                            Array.isArray(ele),
                        ),
                    ),
                );
            } else {
                setAlert({
                    error: true,
                    message: 'Błąd podczas wczytywania obliczeń',
                });
            }
        });
    };

    const onRemoveProject = (id) => {
        AuthFetch({
            url: `${fetch.delete.url}/${id}`,
            method: fetch.delete.method,
        }).then((response) => {
            if (response.success) {
                onLoadProjectName((state) => ({
                    ...state,
                    value:
                        response.responseData.id !== currentProject.id
                            ? state.value
                            : '',
                }));
                setAllCalculationsWitoutProfile((state) =>
                    response.responseData.id !== currentProject.id ? state : [],
                );
                setCurrentProject((state) => ({
                    name:
                        response.responseData.id !== state.id ? state.name : '',
                    userId:
                        response.responseData.id !== state.id
                            ? state.userId
                            : '',
                    id: response.responseData.id !== state.id ? state.id : '',
                    profiles:
                        response.responseData.id !== state.id
                            ? state.profiles
                            : [],
                }));
                setNewProject(true);
                setAlert({
                    error: false,
                    message: 'Projekt został usunięty',
                    hideTime: 2000,
                });
            } else {
                setAlert({
                    error: true,
                    message: 'Błąd podczas usuwania projektu',
                });
            }
        });
    };
    const onChangeAccessUser = (id) => {
        if (userAccessToProject.includes(id)) {
            setUserAccessToProject((state) =>
                state.filter((ele) => ele !== id),
            );
        } else {
            setUserAccessToProject((state) => [...state, id]);
        }
    };
    const onAddAllUsers = (ids = []) => {
        if (Array.isArray(ids)) {
            if (userAccessToProject.length > 0) {
                setUserAccessToProject([]);
            } else {
                ids.forEach((ele) => {
                    setUserAccessToProject((state) => [...state, ele]);
                });
            }
        }
    };

    const onChangeEditAccessUser = (id) => {
        if (editedUserAccess.includes(id)) {
            setEditedUserAccess((state) => state.filter((ele) => ele !== id));
        } else {
            setEditedUserAccess((state) => [...state, id]);
        }
    };
    const onAddAllEditUsers = (ids = []) => {
        if (Array.isArray(ids)) {
            if (editedUserAccess.length > 0) {
                setEditedUserAccess([]);
            } else {
                ids.forEach((ele) => {
                    setEditedUserAccess((state) => [...state, ele]);
                });
            }
        }
    };

    const onLoadProjectUsers = (id) => {
        if (currentProjectId !== id) {
            setCurrentProjectId(id);
            AuthFetch({
                url: '/api/projects/users/' + id,
                method: 'GET',
            })
                .then((response) => {
                    if (
                        response.success &&
                        Array.isArray(response.responseData.accessIds) &&
                        response.responseData.accessIds.length > 0
                    ) {
                        setEditedUserAccess(
                            response.responseData.accessIds
                                .filter((ele) => isObjectId(ele))
                                .filter(
                                    (ele) =>
                                        ele !== response.responseData.userId,
                                ),
                        );
                    } else {
                        setEditedUserAccess([]);
                    }
                })
                .catch(() => {
                    setEditedUserAccess([]);
                });
        }
    };

    const onSaveEditedUserAccess = (id) => {
        AuthFetch({
            url: '/api/projects/users/' + id,
            method: 'PUT',
            body: {
                editedUserAccess,
            },
        })
            .then((response) => {
                if (response.success && response.status === 200) {
                    setAlert({
                        error: false,
                        message: 'Projekt został zaktualizowany',
                        hideTime: 2000,
                    });
                } else {
                    setAlert({
                        error: true,
                        message: 'Błąd podczas aktualizacji projektu',
                    });
                }
            })
            .catch(() => {
                setAlert({
                    error: true,
                    message: 'Błąd podczas aktualizacji projektu',
                });
            });
    };

    const onSaveEditedProjectName = (id) => {
        if (editProjectName.isValid) {
            AuthFetch({
                url: '/api/project/edit/name/' + id,
                method: 'PUT',
                body: {
                    editProjectName: editProjectName.value,
                },
            })
                .then((response) => {
                    if (response.success && response.status === 200) {
                        if (response.responseData.exists) {
                            setAlert({
                                error: true,
                                message:
                                    'Projekt o podanej nazwie już istnieje',
                            });
                        } else {
                            setNewProject(true);
                            setAlert({
                                error: false,
                                message: 'Nazwa projektu został zaktualizowana',
                                hideTime: 2000,
                            });
                        }
                    } else {
                        setAlert({
                            error: true,
                            message: 'Błąd podczas aktualizacji nazwy projektu',
                        });
                    }
                })
                .catch(() => {
                    setAlert({
                        error: true,
                        message: 'Błąd podczas aktualizacji nazwy projektu',
                    });
                });
        } else {
            setAlert({
                error: true,
                message: 'Nazwa projektu nie jest poprawna',
            });
        }
    };

    const onChangeEditProjectName = (e) => {
        const target = e.currentTarget;
        if (target) {
            setEditProjectName({
                value: target.value,
                isValid: target ? target.checkValidity() : false,
            });
        }
    };

    const onSearchProject = (e) => {
        const { value } = e.target;
        if (isString(value)) {
            setCurrentUserProjects(
                projectsCopy.filter(
                    (ele) =>
                        ele.name.toLowerCase().substr(0, value.length) ===
                        value.toLowerCase(),
                ),
            );
        }
    };

    const createProject = {
        state: {
            projectName,
            allProjects,
            allCalculationsWitoutProfile,
            currentProject,
            userEmail,
            currentUserProjects,
            subUsers,
            editProjectName,
            userAccessToProject,

            editedUserAccess,
            projectId: currentProjectId,
        },
        onSaveEditedUserAccess: onSaveEditedUserAccess,
        onLoadProjectUsers: onLoadProjectUsers,
        onChangeCheckbox: onChangeAccessUser,
        onAddAllUsers: onAddAllUsers,

        onChangeCheckboxEditAccess: onChangeEditAccessUser,
        onAddAllEditUsers: onAddAllEditUsers,

        onChangeCurrentProject: onChangeCurrentProject,
        onChangeEditProjectName: onChangeEditProjectName,
        onRemoveProject: onRemoveProject,
        onChange: onChange,
        onSearch: onSearchProject,
        onBlur: onBlur,
        onSubmit: onSubmit,
        updateCurrentProject: setCurrentProject,
        loadEditProjectName: setEditProjectName,
        onSaveEditedProjectName: onSaveEditedProjectName,

        setProjectId: setCurrentProjectId,

        updateAllCalculationsWitoutProfile: setAllCalculationsWitoutProfile,
    };
    return {
        createProject,
        allProjects,
    };
};

export default ProjectsListHelpers;
