import { Table } from 'react-bootstrap';
import styles from './index.module.css';

const InternalForcesSection = ({ forces = [] }) => {
    return (
        <div className={styles.wrapper}>
            <h2>Wartości sił wewnętrznych w węzłach</h2>
            <p>
                Maksymalny moment zginający:{' '}
                <span className={styles.value}>
                    {Math.max(
                        ...forces.map((ele) => ele.bendingMoment),
                    ).toFixed(2)}{' '}
                    kNm
                </span>
            </p>
            <p>
                Minimalny moment zginający:{' '}
                <span className={styles.value}>
                    {Math.min(
                        ...forces.map((ele) => ele.bendingMoment),
                    ).toFixed(2)}{' '}
                    kNm
                </span>
            </p>
            <p>
                Maksymalna siła tnąca:{' '}
                <span className={styles.value}>
                    {Math.max(...forces.map((ele) => ele.shearForce)).toFixed(
                        2,
                    )}{' '}
                    kN
                </span>
            </p>
            <p>
                Minimalna siła tnąca:{' '}
                <span className={styles.value}>
                    {Math.min(...forces.map((ele) => ele.shearForce)).toFixed(
                        2,
                    )}{' '}
                    kN
                </span>
            </p>
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th>
                            Nr<br></br>[-]
                        </th>
                        <th>
                            Długość pala<br></br>[m]
                        </th>
                        <th>
                            M<br></br>[kNm]
                        </th>
                        <th>
                            T<br></br>[kN]
                        </th>
                        <th>
                            D<br></br>[mm]
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {forces.map((element, index) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{element.z}</td>
                                <td>{element.bendingMoment}</td>
                                <td>{element.shearForce}</td>
                                <td>{element.displacement}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <p className={styles.underline}>Oznaczenia:</p>
            <p>
                M -{' '}
                <span className={styles.valueLegend}>
                    momenty zginające w palu
                </span>
            </p>
            <p>
                T -{' '}
                <span className={styles.valueLegend}>siły tnące w palu</span>
            </p>
            <p>
                D -{' '}
                <span className={styles.valueLegend}>przemieszczenie pala</span>
            </p>
        </div>
    );
};

export default InternalForcesSection;
