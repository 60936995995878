import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-regular-svg-icons';
import { themeContext } from '../../context/Theme';
import styles from './index.module.css';

const Switcher = () => {
    const { toggleMode, mode } = useContext(themeContext);
    const onToggle = () => toggleMode();

    return (
        <div className={`${styles.switch} ${mode === 'dark' ? styles.open : ''}`} onClick={onToggle}>
            <input className={styles.checkbox} />
            <FontAwesomeIcon  icon={mode === 'dark' ? faSun : faMoon}/>
            <div className={styles.icon}></div>
        </div>
    )
}

export default Switcher