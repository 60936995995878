import { useCallback, useEffect, useMemo, useState } from "react";
import { defaultShape, concreteClasses, defaultConcreteClass, defaultRebarClass, rebarSteelClasses, exposureClasses, rebarDiameters, defaultDiameter, defaultDiameterSpiral } from "../../../config/ProjectConfig";
import { isNumber, roundNumber, stringToNumber } from "../../../utils";

const defaultSecondOrderState = {
    nominalCurvature: {
        value: false,
        isValid: true 
    },
    nominalStiffness: {
        value: true,
        isValid: true
    }
};
const defaultState = {
    pileShape: {
        value: defaultShape.shape,
        isValid: true
    }, 
    concreteClass: {
        value: defaultConcreteClass.name,
        isValid: true
    }, 
    fck: {
        value: defaultConcreteClass.fck,
        isValid: true
    },
    fctm: {
        value: defaultConcreteClass.fctm,
        isValid: true
    },
    fctk: {
        value: defaultConcreteClass.fctk,
        isValid: true
    },
    ecm: {
        value: defaultConcreteClass.ecm,
        isValid: true
    },
    steelClass: {
        value: defaultRebarClass.name,
        isValid: true
    },
    steelClassSpiral: {
        value: defaultRebarClass.name,
        isValid: true
    },
    fyk: {
        value: defaultRebarClass.fyk,
        isValid: true
    },
    fywk: {
        value: defaultRebarClass.fyk,
        isValid: true
    },
    ft: {
        value: defaultRebarClass.ft,
        isValid: true
    },
    diameterPile: { 
        value: '',
        isValid: null
    },
    width: { 
        value: '',
        isValid: null
    },
    cover: {
        value: '',
        isValid: null
    },
    coverUp: {
        value: '',
        isValid: null
    },
    coverBottom: {
        value: '',
        isValid: null
    },
    stirrupType: {
        value: 'Spirala',
        isValid: true,
    },
    rebar: {
        value: defaultDiameter.d,
        isValid: true
    },
    rebarAs1: {
        value: defaultDiameter.d,
        isValid: true
    },
    rebarAs2: {
        value: defaultDiameter.d,
        isValid: true
    },
    spiral: {
        value: defaultDiameterSpiral.d,
        isValid: true
    },
    yt: {
        value: 1.15,
        isValid: true
    },
    yc: {
        value: 1.4,
        isValid: true
    },
    alfa: {
        value: 1,
        isValid: true
    },
    youngModulus: {
        value: 200,
        isValid: true
    },
    compressionZone: {
        value: 1,
        isValid: true
    },
    minimumReinforcement: {
        value: 1,
        isValid: true
    },
    minimumReinforcementCrack: {
        value: false,
        isValid: true
    },
    lbdCalculation: {
        value: true,
        isValid: true
    },
    concreteCondition: {
        value: true,
        isValid: true
    },
    rebarShape: {
        value: true,
        isValid: true
    },
    isCrackCalc : {
        value: false,
        isValid: true
    },
    maxCrackCalc: {
        value: 0.3,
        isValid: true
    },
    maxCrack: {
        value: 0.3,
        isValid: true
    },
    defaultDelta: {
        value: 0.9,
        isValid: true
    },
    compressionZoneValue: {
        value: (0.9-0.44)/1.25,
        isValid: true
    },
    exposureClass: {
        value: '',
        isValid: true
    },
    hardeningModel: {
        value: false,
        isValid: true
    },
    ea: {
        value: false,
        isValid: true
    },
    secondOrder: {
        value: false,
        isValid: true
    },
    l: {
        value: '',
        isValid: null
    },
    m01: {
        value: '',
        isValid: null
    },
    m02: {
        value: '',
        isValid: null
    },
    leff: {
        value: '',
        isValid: null
    },
    euk: {
        value: 0.9*defaultRebarClass.euk,
        isValid: true
    },
    nCut: {
        value: 2,
        isValid: true,
    },
    memberType: {
        value: 'Słup',
        isValid: true
    },
    bucklingRatio: {
        value: 1,
        isValid: true
    },
    c0: {
        value: '',
        isValid: null
    },
    c: {
        value: '',
        isValid: null
    },
    finalPhi: {
        value: '',
        isValid: null
    },
    finalPhiCrack: {
        value: '',
        isValid: null
    },
    ...defaultSecondOrderState
};
const defaultRow = {
    bendingMoment: {
        value: '',
        isValid: null 
    },
    medp: {
        value: '',
        isValid: null 
    },
    shearForce: {
        value: '',
        isValid: null 
    },
    axialForce: {
        value: '',
        isValid: null 
    }
};

const CalculationInputHelpers = ({
    updateResultsState
}) => {
    const [calculationInputState, setCalculationInputState] = useState(defaultState);
    const [internalForces, setInternalForces] = useState([{ ...defaultRow }]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setCalculationInputState(state => {
            const compressionZoneValue = roundNumber(state.youngModulus.value * 1000 * 0.0035/(state.youngModulus.value*1000*0.0035 + state.fyk.value/1.15),3)
            return {
                ...state,
                compressionZoneValue: {
                    value: isNumber(compressionZoneValue) && state.compressionZone.value === 2 ? compressionZoneValue : state.compressionZoneValue.value,
                    isValid: isNumber(compressionZoneValue) && state.compressionZone.value === 2 ? true : state.compressionZoneValue.isValid
                }
            }
        });
    },[calculationInputState.fyk.value, calculationInputState.youngModulus.value]);

    useEffect(() => {
        setCalculationInputState(state => {
            return {
                ...state,
                leff: {
                    value: isNumber(state.l.value) && isNumber(state.bucklingRatio.value) 
                        ? 
                            roundNumber(state.l.value * state.bucklingRatio.value,2)
                        :
                            state.leff.value, 
                    isValid: isNumber(state.l.value * state.bucklingRatio.value) 
                        ?
                        true
                        :
                        false
                }
            }
        });
    },[calculationInputState.l.value, calculationInputState.bucklingRatio.value]);

    useEffect(() => {
        setInternalForces(ele => ele.map((element) => {
            if(calculationInputState.memberType.value !== 'Słup'){
                return {
                    ...element,
                    axialForce: {
                        value: '',
                        isValid: null
                    }
                }
            } else {
                return element
            }
        }));
    },[calculationInputState.memberType.value]);

    const onBlurCalculationInputState = useCallback((e) => {
        const { name, value } = e.currentTarget;
        const valid = e.currentTarget.checkValidity();

        const greaterThanZeroProps = ['fyk','fywk','fck','ft', 'fctm', 'fctk', 'cover', 'coverUp', 'coverBottom', 'width', 'diameterPile','spiral', 'yt', 'yc', 'alfa', 'youngModulus', 'compressionZoneValue', 'euk', 'nCut', 'c0', 'c', 'finalPhi', 'l', 'leff', 'rebar', 'rebarAs1', 'rebarAs2', 'spiral', 'maxCrack', 'defaultDelta', 'finalPhiCrack', 'maxCrackCalc'];

        setCalculationInputState(state => {
            if(name === 'alfa'){
                return {
                    ...state, 
                    alfa: { 
                        ...state['alfa'],
                        isValid:  value > 0 && valid && value <= 1
                    }
                }
            } else {
                return {
                    ...state, 
                    [name]: { 
                        ...state[name],
                        isValid: greaterThanZeroProps.includes(name) ? value > 0 && valid : valid
                    }
                }                
            }
        });
    },[]);
    
    const onChangeCalculationInputState = useCallback((e) => {
        const input = e.currentTarget.closest('input') || e.currentTarget.closest('select') || e.currentTarget.closest('radio');;

        if(input){
            const { name, value, type } = input;
            updateResultsState((state) => {
                return {
                    ...state,
                    isResultsActual: false, 
                };
            });
            setCalculationInputState(state => {
                if(name === 'steelClass'){
                    const steelClass = rebarSteelClasses.find(ele => ele.name === value) || {};
                    return {
                        ...state,
                        fyk: {
                            value: steelClass.fyk ? steelClass.fyk : state.fyk.value, 
                            isValid: steelClass.fyk ? true : state.fyk.isValid
                        },
                        ft: {
                            value: steelClass.ft ? steelClass.ft : state.ft.value,
                            isValid: steelClass.ft ? true : state.ft.isValid
                        },
                        euk: {
                            value: steelClass.euk ? 0.9*steelClass.euk : 1,
                            isValid: true
                        },

                        [name]: {
                            value: value,
                            isValid: true
                        },
                    }
                } else if(name === 'memberType'){
                    return {
                        ...state,
                        pileShape: {
                            value: value === 'Słup' ? defaultShape.shape : 'Prostokątny',
                            isValid: true
                        },
                        width: {
                            value: value === 'Płyta' ? 1000 : '',
                            isValid: value === 'Płyta' ? true : null,
                        },
                        [name]: {
                            value: value,
                            isValid: true
                        }
                    }
                } else if(name === 'steelClassSpiral'){
                    const steelClass = rebarSteelClasses.find(ele => ele.name === value) || {};
                    return {
                        ...state,
                        fywk: {
                            value: steelClass.fyk ? steelClass.fyk : state.fyk.value, 
                            isValid: steelClass.fyk ? true : state.fyk.isValid
                        },
                        [name]: {
                            value: value,
                            isValid: true
                        },
                    }
                } else if(name === 'concreteClass'){
                    const concreteClass = concreteClasses.find(ele => ele.name === value) || {};
                    return {
                        ...state,
                        fck: {
                            value: concreteClass.fck ? concreteClass.fck : state.fck.value,
                            isValid: concreteClass.fck ? true : state.fck.isValid
                        },
                        fctm: {
                            value: concreteClass.fctm ? concreteClass.fctm : state.fctm.value,
                            isValid: concreteClass.fctm ? true : state.fctm.isValid
                        },
                        fctk: {
                            value: concreteClass.fctk ? concreteClass.fctk : state.fctk.value,
                            isValid: concreteClass.fctk ? true : state.fctk.isValid
                        },
                        ecm: {
                            value: concreteClass.ecm ? concreteClass.ecm : state.ecm.value,
                            isValid: concreteClass.ecm ? true : state.ecm.isValid
                        },
                        [name]: {
                            value: value,
                            isValid: true
                        },
                    }
                } else if(name === 'minimumReinforcement'){
                    return {
                        ...state,
                        [name]: {
                            value: stringToNumber(value),
                            isValid: true
                        },
                    }  
                } else if(name === 'compressionZone') {
                    const compressionZoneValue = Number(value) === 1 
                    ?
                    roundNumber((state.defaultDelta.value - 0.44)/1.25,3)
                    : Number(value) === 2 
                        ? roundNumber(state.youngModulus.value * 1000 * 0.0035/(state.youngModulus.value*1000*0.0035 + state.fyk.value/1.15),3)
                        : ''
                    return {
                        ...state,
                        compressionZoneValue: {
                            value: compressionZoneValue,
                            isValid: isNumber(compressionZoneValue) ? true : false
                        },
                        [name]: {
                            value: Number(value),
                            isValid: true,
                        },
                    };
                } else if(name === 'defaultDelta'){
                    const compressionZoneValue = roundNumber((Number(value) - 0.44)/1.25,3);
                    return {
                        ...state,
                        compressionZoneValue: {
                            value: isNumber(compressionZoneValue) && state.compressionZone.value === 1 ? compressionZoneValue : state.compressionZone.value,
                            isValid: isNumber(compressionZoneValue) && state.compressionZone.value === 1 ? true : state.compressionZone.isValid
                        },
                        [name]: {
                            value: Number(value),
                            isValid: true,
                        },
                    };
                } else if(name === 'hardeningModel'){
                    return {
                        ...state,
                        [name]: {
                            value: !state.hardeningModel.value,
                            isValid: true,
                        },
                    };
                } else if(name === 'ea'){
                    return {
                        ...state,
                        [name]: {
                            value: !state.ea.value,
                            isValid: true,
                        },
                    };
                } else if(name === 'isCrackCalc'){
                    return {
                        ...state,
                        [name]: {
                            value: !state.isCrackCalc.value,
                            isValid: true,
                        },
                    };
                } else if(name === 'secondOrder'){
                    return {
                        ...state,
                        [name]: {
                            value: !state.secondOrder.value,
                            isValid: true,
                        },
                    };
                } else if(name === 'minimumReinforcementCrack'){
                    return {
                        ...state,
                        [name]: {
                            value: !state.minimumReinforcementCrack.value,
                            isValid: true,
                        },
                    };
                } else if(name === 'lbdCalculation'){
                    return {
                        ...state,
                        [name]: {
                            value: !state.lbdCalculation.value,
                            isValid: true,
                        },
                    }; 
                } else if(name === 'concreteCondition'){
                    return {
                        ...state,
                        [name]: {
                            value: !state.concreteCondition.value,
                            isValid: true,
                        },
                    }; 
                } else if(name === 'rebarShape'){
                    return {
                        ...state,
                        [name]: {
                            value: !state.rebarShape.value,
                            isValid: true,
                        },
                    }; 
                } else if(name === 'nCut') {
                    return {
                        ...state,
                        [name]: {
                            value: stringToNumber(value),
                            isValid: true
                        },                        
                    }
                } else if(name === 'bucklingRatio'){
                    return {
                        ...state,
                        [name]: {
                            value: stringToNumber(value),
                            isValid: true
                        },
                    }      
                } else if(name === 'nominalStiffness' || name === 'nominalCurvature'){
                    return {
                        ...state,
                        nominalCurvature: {
                            value: false,
                            isValid: true
                        },
                        nominalStiffness: {
                            value: false,
                            isValid: true
                        },
                        [name]: {
                            value: true,
                            isValid: true
                        },
                    }      
                } else {
                    return {
                        ...state,
                        [name]: {
                            value: ((type === 'number' || name === 'c' || name === 'c0') && value.length > 0) ? stringToNumber(value) : value,
                            isValid: true
                        },
                    }
                }
            })
        }
    },[]);

    const onChangeInetrnalForces = useCallback((e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if (input) {
            const rowNumber = stringToNumber(input.getAttribute('data-row'));
            const { name, value } = input;

            updateResultsState((state) => {
                return {
                    ...state,
                    isResultsActual: false, 
                };
            });
            setInternalForces((state) =>
                state.map((element, index) => {

                    if (rowNumber !== index) {
                        return element;
                    } else {
                        return {
                            ...element,

                            [name]: {
                                value: stringToNumber(value),
                                isValid: isNumber(stringToNumber(value)) ? true : value.length === 0 ? null : false,
                            },
                        };
                    }
                }),
            );
        }
    }, []);

    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    },[]);

    const loadProject = (calculationInputObj={}) => {
        const { internalForces, ...rest } = calculationInputObj;

        setCalculationInputState(() => {
            return {
                ...defaultState,
                ...rest
            }
        });
        setInternalForces(() => {
            return internalForces
        });
        setLoaded(true);
    }
    const addRow = () => {
        updateResultsState((state) => {
            return {
                ...state,
                isResultsActual: false, 
            };
        });
        setInternalForces((state) => {
            return [
                ...state,
                {
                    ...defaultRow,
                },
            ];
        });
    }
    const removeRow = (i) => {
        updateResultsState((state) => {
            return {
                ...state,
                isResultsActual: false, 
            };
        });
        setInternalForces((state) => {
            return [
                ...state
                    .filter((_, index) => index !== i)
                    .map(ele => ({
                        ...ele,
                    })),
            ];
        });
    }

    const calculationInput = {
        onChangeInetrnalForces,
        onChange: onChangeCalculationInputState,
        onBlur: onBlurCalculationInputState,
        onKeyDown: onKeyDown,
        addRow,
        removeRow,

        state: {
            ...calculationInputState,
            internalForces
        },
        isLoaded: loaded,
        updateState: setCalculationInputState,
        loadProject: loadProject,
        initState: () => {
            setCalculationInputState(defaultState);
            setInternalForces([{ ...defaultRow }])
        },
        pileShape: useMemo(() => ['Kołowy', 'Prostokątny'].filter(ele => calculationInputState.memberType.value === 'Słup' ? ele : ele === 'Prostokątny'),[calculationInputState.memberType.value]),
        memberTypes: useMemo(() => ['Belka', 'Słup', 'Płyta'],[]),
        exposureClasses: useMemo(() => exposureClasses.map(ele => ele.name), []),
        concreteClasses: useMemo(() => concreteClasses.filter(ele => ele.fck >= 12 && ele.fck <= 50).map(ele => ele.name),[]),
        steelClasses: useMemo(() => rebarSteelClasses.map(ele => ele.name),[]),
        rebarDiameters: useMemo(() => rebarDiameters.map(ele => ele.d),[]),
        currentSteelClass: useMemo(() => rebarSteelClasses.find(ele => ele.name === calculationInputState.steelClass.value),[calculationInputState.steelClass.value]),
        currentExposureClass: exposureClasses.find(ele => ele.name === calculationInputState.exposureClass.value),
        stirrupTypes: ['Spirala','Strzemiona'],
        bucklingRatios: [0.5, 0.7, 1, 2],
        c0Values: [8, 9.6, 9.87, 12, 16],
        cValues: [9.87, 8]
    }
 
    return { 
        calculationInput
    }
}

export default CalculationInputHelpers; 