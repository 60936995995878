import { useCallback, useMemo, useState } from "react";
import { defaultShape, pileShape, pilesTypeVertical as pilesType, pilesShape } from "../../../config/ProjectConfig";
import { isNumber, stringToNumber } from "../../../utils";

const defaultState = {
    pileType: {
        value: '',
        isValid: null
    },
    pileTechnology: {
        value: '',
        isValid: null
    },
    pileShape: {
        value: defaultShape.shape,
        isValid: true
    },  
    isBaseWidth: {
        value: false,
        isValid: true
    },
    baseWidth: {
        value: '',
        isValid: null
    },
    diameterPile: { 
        value: '',
        isValid: null
    },
    pileLength: {
        value: '',
        isValid: true
    },
    pileHeadSpot: {
        value: '',
        isValid: null
    },
    singlePile: {
        value: true,
        isValid: true
    },
    pilesInFoundation: {
        value: 1,
        isValid: true
    },
    pileGroup: { 
        value: false,
        isValid: true
    },
    pileSpace: {
        value: '',
        isValid: null
    },
    groupWidth: {
        value: '',
        isValid: null
    },
    groupLength: {
        value: '',
        isValid: null
    },
    groupPilesNumber: {
        value: '',
        isValid: null
    },
}

const PilePropertiesHelpers = ({ updateResultsState }) => {
    const [pilePropertiesState, setPilePropertiesState] = useState(defaultState);

    const onBlurPilePropertiesState = useCallback((e) => {

        const { name, value } = e.currentTarget;
        const soilHeight = JSON.parse(e.currentTarget.getAttribute('data-height'));
        const valid = e.currentTarget.checkValidity();
        const greaterThanZeroProps = ['baseWidth', 'diameterPile', 'pileLength', 'pilesInFoundation', 'pileSpace', 'groupWidth', 'groupLength', 'groupPilesNumber'];

        setPilePropertiesState(state => {
            return {
                ...state, 
                [name]: { 
                    ...state[name],
                    isValid: greaterThanZeroProps.includes(name) ? value > 0 && valid : valid
                },
                ...(name === 'pileHeadSpot') && {
                    pileLength: {
                        ...state['pileLength'],
                        isValid: Math.max(...soilHeight) >= (stringToNumber(value) + state.pileLength.value) && (stringToNumber(value) + state.pileLength.value) > 0
                    },
                    [name]: {
                        ...state[name],
                        isValid: isNumber(stringToNumber(value)) 
                        ? 
                        (Math.max(...soilHeight) > stringToNumber(value)) && valid 
                        : 
                        valid                     
                    }
                },
                ...(name === 'pileLength') && {
                    [name]: {
                        ...state[name],
                        isValid: isNumber(stringToNumber(value)) 
                        ? 
                        value > 0 && Math.max(...soilHeight) >= (stringToNumber(value) + state.pileHeadSpot.value) && (stringToNumber(value) + state.pileHeadSpot.value) > 0 && valid 
                        : 
                        valid                     
                    }
                }
            }
        });

    },[])
    
    const onChangePilePropertiesState = useCallback((e) => {
        const input = e.currentTarget.closest('input') || e.currentTarget.closest('select');

        if(input){
            const { name, value, type, checked } = input;

            updateResultsState((state) => {
                return {
                    ...state,
                    isResultsActual: false, 
                };
            });
            
            if(type === 'checkbox'){
                setPilePropertiesState(state => ({
                    ...state,
                    ...(name === 'singlePile') && {
                        pileGroup: {
                            ...state[name],
                            value: !checked
                        }
                    },
                    ...(name === 'pileGroup') && {
                        singlePile: {
                            ...state[name],
                            value: !checked
                        }
                    },
                    [name]: {
                        ...state[name],
                        value: !!checked
                    }
                }));
            } else if(type === 'radio'){
                setPilePropertiesState(state => {
                    return {
                        ...state, 
                        [name]: {
                            value: stringToNumber(value),
                            isValid: true
                        }
                    }
                })
            } else {
                setPilePropertiesState(state => {
                    return {
                        ...state,

                        ...(name === 'pileType') && {
                            pileTechnology: {
                                value: Object.keys(pilesType[value]).length === 1 ? Object.keys(pilesType[value])[0] : '',
                                isValid: Object.keys(pilesType[value]).length === 1 ? true : null
                            },
                            pileShape: {
                                value: Array.isArray(pilesShape[value]) ? pilesShape[value][0] : pilesShape[value],
                                isValid: true,
                            }
                        },
                        [name]: {
                            value: (type === 'number' && value.length > 0) ? stringToNumber(value) : value,
                            isValid: true
                        },


                    }
                })
            }
        }
    },[]);

    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    },[]);

    const loadProject = (pilePropertiesObj={}) => {

        setPilePropertiesState(() => {
            return {
                ...defaultState,
                ...pilePropertiesObj
            }
        })
    }

    const pileProperties = {
        onChange: onChangePilePropertiesState,
        onBlur: onBlurPilePropertiesState,
        onKeyDown: onKeyDown,
        state: pilePropertiesState,
        updateState: setPilePropertiesState,
        loadProject: loadProject,
        initState: () => setPilePropertiesState(defaultState),
        pileTypes: Object.keys(pilesType),
        pileShape: !!pilePropertiesState.pileType.value 
            ? 
            Object.keys(pileShape).filter(ele => ele === pilePropertiesState.pileShape) 
            : 
            Object.keys(pileShape),
        pileTypeShape: !!pilePropertiesState.pileType.value 
            ? 
            Array.isArray(pilesShape[pilePropertiesState.pileType.value]) ? pilesShape[pilePropertiesState.pileType.value] : [pilesShape[pilePropertiesState.pileType.value]]
            : 
            Object.keys(pileShape), 

        pileTechnologies: useMemo(() => !!pilePropertiesState.pileType.value ? [...new Set(Object.keys(pilesType[pilePropertiesState.pileType.value]))] : [...new Set(Object.values(pilesType).map(ele => Object.keys(ele)).flat(1))],[pilePropertiesState.pileType.value])
    }
 
    return { 
        pileProperties
    }
}

export default PilePropertiesHelpers