import React, { Fragment, useEffect, useContext, useState, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Password from './Password';
import ForgotPassword from './ForgotPassword';
import {
    AlertMessage2,
    Email,
    Modal,
    RadioInput1,
    Button as DefaultButton, 
} from '../../components';
import { withForm } from '../../hoc';
import { authContext } from '../../context/Authentication';
import config from '../../config/config';
import { plans } from '../../config/ProjectConfig';
import styles from './index.module.css';
import { isNumber } from '../../utils';
import { loadStripe } from '@stripe/stripe-js';

import logo from '../../assets/Logo.png'
import InvoiceForm from '../../components/InvoiceForm';
import loginImg from '../../assets/login_img.jpg';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_ID);

const Wrapper = styled.div`
    height: 100vh;
    background-color: #fff;
    font-weight: 700;
    
    & form {
        background-color: #fff;
        border-radius: 10px;
        padding: 20px;
    }
    & p, label, button, a {
        font-size: 16px !important;
    }
    & label {
        font-weight: 500 !important;
    }

    & input {
        &:focus {
            box-shadow: 0 0 0 0.1rem rgba(0,123,255,.25);
        }
    }
`;
const FormWrapper = styled.div`
    padding-top: 80px;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;


    & .form-group {
        margin: 10px 0;
    }

    & button[type='submit'] {
        display: block;
        padding: 5px 25px;
        background-color: #1b3e6e;
        border-color: #1b3e6e;
        border-radius: 2px;
        transition: all 0.5s ease;

        &:active {
            background-color: #1b3e6e !important;
            border-color: #1b3e6e !important;
            box-shadow: none !important;
        }
        &:hover {
            background-color: #1b3e90;
            border-color: #1b3e90;
        }
        &:focus {
            box-shadow: none !important;
        }
    }
`;
const SignUpLink = styled.div`
    text-align: center;
    margin-top: 40px;
    & a {
        color: #666;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
        text-align: center;
        text-decoration: underline !important;
        &:hover {
            color: #000;
        }
    }
`;
const H1 = styled.h1`
    font-size: 22px;
    color: #000;
    font-weight: inherit;
    text-align: center;
    margin: 5px 0 35px 0;
`;
const ForgetPasswordWrapper = styled.div`
    overflow: hidden;
    display: flex;
    justify-content: space-between;
`;
const RememberMe = styled.div`
    overflow: hidden;
    display: flex;
    margin: 20px 0;

    & .form-check {
        & input {
            box-shadow: none;
            border: 1px solid rgba(0, 0, 0, .25) !important;

            &:hover {
                cursor: pointer;
            }
        } 
        
    }
    & label {
        color: #666;
        padding: 0;
        border: none;
        margin-bottom: 0px;
    }
    & label:hover {
        color: #000;
        cursor: pointer;
    }
`;
const ForgetButton = styled.button`
    background-color: transparent;
    border: none;
    color: #666;
    outline: none;
    padding: 0;
    font-weight: inherit;

    &:hover,
    &:focus,
    &:active {
        color: #000;
        text-decoration: underline;
        outline: none;
        box-shadow: none;
    }
`;
const ActiveButton = styled.button`
    margin-left: 15px;
    padding: 7px;
    font-size: 14px;

    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
const ImageWrapper = styled.div`
    text-align: center;
    margin: 0 0 0px 0;
    padding: 0 20px;
    & img {
        width: 190px
    }
`;
const ImageWrapperLogin = styled.div`
    text-align: center;
    margin: 0 0 0px 0;
    padding: 0;
    height: 100vh;
    
    & img {
        width: 100%;
        height: 100vh;
    }
`
const TextWithLine = styled.p`
    width: 100%; 
    text-align: center; 
    border-bottom: 2px solid #ddd; 
    line-height: 0;
    margin-bottom: 25px;

    & span {
        background:#fff; 
        padding:0 10px; 
    }
`

const initSubscriptionType = {
    yearSubscription: false,
    monthSubscription: false,
};

const Login = ({
    formState,
    messageState = {},
    onToggleCheckbox,
    onChange,
    actions = {},
    onSubmit,
}) => {
    const navigate = useNavigate();
    const { logIn } = useContext(authContext);
    const { routes } = config;
    const [state, setState] = useState({ modal: false });
    const [isSubsExpired, setIsSubsExpired] = useState(false);
    const [subscriptionType, setSubscriptionType] = useState({
        yearSubscription: true,
        monthSubscription: false,
    });
    const [summaryCost, setSummaryCost] = useState();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [renewSubsMessageState, setRenewSubsMessageState] = useState({
        error: false,
        open: false,
        message: false,
        success: false,
    });
    const [dataForm, setDataForm] = useState({});
    const [openInvoice, setOpenInvoice] = useState(false);
    const [saveFormData, setSaveFormData] = useState(false);
    const [invoice, setInvoice] = useState({
        firstName: {
            value: '',
            isValid: null,
        },
        lastName: {
            value: '',
            isValid: null,
        },
        companyName: {
            value: '',
            isValid: null,
        },
        nip: {
            value: '',
            isValid: null,
        },
        street: {
            value: '',
            isValid: null,
        },
        placeNumber: {
            value: '',
            isValid: null,
        },
        postcode: {
            value: '',
            isValid: null,
        },
        city: {
            value: '',
            isValid: null,
        },

        email: {
            value: '',
            isValid: null,
        },
    });
    const [invoiceType, setInvoiceType] = useState(1);

    const onOpenInvoice = () => setOpenInvoice(true);
    const onHideInvoice = () => setOpenInvoice(false);
    const openModal = () => setState((state) => ({ ...state, modal: true }));
    const hideModal = () => setState((state) => ({ ...state, modal: false }));

    useEffect(() => {
        document.title = `GeoInCloud | Zaloguj się`;

        return () => {
            document.title = '';
        };
    }, []);

    useEffect(() => {
        setSummaryCost(() => {
            if (subscriptionType.yearSubscription) {
                return plans.year.first;
            } else {
                return plans.month.first;
            }
        });
    }, [subscriptionType.yearSubscription, subscriptionType.monthSubscription]);

    const onSubmitForm = (e) => {
        e.preventDefault();

        onSubmit((formData) => {
            setDataForm(formData);
            actions
            .onLoginUser({
                body: { ...formData, checked: formState.checked },
            })
            .then((response) => {
                if(response){
                    if (response.status === 200) {
                        if (response.data.subscriptionExpired) {
                            setIsSubsExpired(true);
                        } else {
                            logIn(response.data.tokens, response.data.user);
                            navigate(routes.dashboard.home);
                        }
                    }
                }
            });
        });
    };
    const onResendActivationLink = (e) => {
        e.preventDefault();
        actions.resendLink({
            body: {
                email: formState.email.value,
            },
        });
    };
    const onHide = () => {
        setRenewSubsMessageState((state) => ({
            ...state,
            open: false,
        }));
        setIsSubsExpired(false);
    };
    const onChangeRenewSubs = (e) => {
        const { name } = e.currentTarget;

        setSubscriptionType((state) => {
            if (Object.keys(state).includes(name)) {
                return {
                    ...initSubscriptionType,
                    [name]: true,
                };
            } else {
                return state;
            }
        });
    };
    const onOrderSubscription = () => {
        const { companyName, nip, firstName, lastName, ...rest } = invoice;

        const validFormObject =
            invoiceType === 1
                ? { companyName, nip, ...rest }
                : invoiceType === 2
                ? { firstName, lastName, ...rest }
                : {};

        const isFormValidInvoice = Object.values(validFormObject).every(
            (ele) => ele.isValid,
        );

        setIsButtonDisabled(true);
        setRenewSubsMessageState({
            error: false,
            open: false,
            message: false,
            success: false,
        });

        if(isFormValidInvoice){
            actions
            .renewSubscription({
                body: {
                    loginData: {
                        ...dataForm,
                    },
                    plan: subscriptionType.yearSubscription
                        ? 2
                        : subscriptionType.monthSubscription
                        ? 1
                        : '',
                    amount: summaryCost,

                    invoiceType,
                    saveFormData,
                    ...Object.entries(validFormObject).reduce(
                        (acc, [key, value]) => ({ ...acc, [key]: value.value }),
                        {},
                    ),
                },
            })
            .then(async (response) => {
                if (response.status === 200) {
                    const { id: sessionId } = response.data;

                    const stripe = await stripePromise;
                    const { error } = await stripe.redirectToCheckout({
                        sessionId,
                    });
                    setIsButtonDisabled(false);
                    if (error) {
                        setRenewSubsMessageState({
                            error: true,
                            open: true,
                            message: 'Błąd podczas przedłużania subskrypcji',
                            success: false,
                        });
                    }
                } else {
                    setRenewSubsMessageState({
                        error: true,
                        open: true,
                        message: 'Błąd podczas przedłużania subskrypcji',
                        success: false,
                    });
                    setIsButtonDisabled(false);
                }
            })
            .catch(() => {
                setIsButtonDisabled(false);
                setRenewSubsMessageState({
                    error: true,
                    open: true,
                    message: 'Błąd podczas przedłużania subskrypcji',
                    success: false,
                });
            });
        } else {
            setInvoice((state) => { 
                return {
                    ...state,
                    ...Object.entries(validFormObject).reduce(
                        (acc, [key, value]) => ({
                            ...acc,
                            [key]: {
                                value: value.value,
                                isValid: !!value.isValid,
                            },
                        }),
                        {},
                    ),
                };
            })
            setRenewSubsMessageState({
                error: true,
                open: true,
                message: 'Niepoprawnie wypełniony formularz',
                success: false,
            });
            setIsButtonDisabled(false);
        }
    };
    const onChangeInvoiceType = (val) => setInvoiceType(val);
    const onBlurInvoice = useCallback((e) => {
        const { name } = e.currentTarget;
        const valid = e.currentTarget.checkValidity();

        setInvoice((state) => {
            return {
                ...state,
                [name]: {
                    ...state[name],
                    isValid: valid,
                },
            };
        });
    }, []);
    const onChangeInvoice = useCallback((e) => {
        const input = e.currentTarget.closest('input');

        if (input) {
            const { name, value } = input;

            setInvoice((state) => {
                return {
                    ...state,

                    [name]: {
                        value: value,
                        isValid: true,
                    },
                };
            });
        }
    }, []);
    const onChangeDataCheckbox = (e) => {
        const target = e.currentTarget;
        if (target) {
            setSaveFormData((state) => !state);
        }
    };

    return (
        <Wrapper>
            <Row className={styles.rowWrapper}>
                <Col md={6} className={`${styles.colWrapper} ${styles.hideMd}`} >
                    <ImageWrapperLogin>
                        <img src={loginImg} alt='Login'/>
                    </ImageWrapperLogin>
                </Col>
                <Col xs={12} lg={6}>
                    <FormWrapper>
                        <ImageWrapper>
                            <img src={logo} alt='Company logo'/>
                        </ImageWrapper>
                        <Form onSubmit={onSubmitForm} noValidate>
                            <H1>Panel logowania</H1>
                            <Email
                                onChange={onChange}
                                email={formState.email}
                                label="Email"
                                placeholder="Email"
                                autofocus
                                required
                            />
                            <Password
                                onChange={onChange}
                                password={formState.password}
                                label="Hasło"
                            />
                            <RememberMe>
                                <Form.Group controlId="rememberMe">
                                    <Form.Check
                                        onChange={onToggleCheckbox}
                                        type="checkbox"
                                        name="rememberMe"
                                        label="Zapamiętaj mnie"
                                        checked={formState.checked}></Form.Check>
                                </Form.Group>
                            </RememberMe>
                            <ForgetPasswordWrapper>
                                <ForgetButton onClick={openModal} type="button">
                                    Nie pamiętasz hasła?
                                </ForgetButton>
                                <Button variant="success" type="submit">
                                    Zaloguj
                                </Button>
                            </ForgetPasswordWrapper>
                            
                            <SignUpLink>
                                <TextWithLine><span>lub</span></TextWithLine>
                                <p><Link to={routes.app.signUp}>Załóż konto</Link> w naszej aplikacji!</p>
                            </SignUpLink>
                            <AlertMessage2
                                {...messageState.onLoginUser}
                                message={
                                    formState.onLoginUser.responseData.expired
                                        ? 'Konto nie jest aktywne'
                                        : messageState.onLoginUser.message
                                }
                                isLoaded={formState.onLoginUser.isLoaded}
                                loadingName={formState.onLoginUser.loadingName}>
                                {formState.onLoginUser.responseData.expired && (
                                    <ActiveButton
                                        onClick={onResendActivationLink}
                                        type="button"
                                        className="activate-button"
                                        variant="primary">
                                        Aktywuj konto!
                                    </ActiveButton>
                                )}
                            </AlertMessage2>
                            <AlertMessage2
                                {...messageState.resendLink}
                                isLoaded={formState.resendLink.isLoaded}
                                loadingName={formState.resendLink.loadingName}
                            />
                        </Form>
                        <ForgotPassword open={state.modal} onHide={hideModal} />
                    </FormWrapper>
                </Col>
            </Row>
            <Modal
                css={styles.modalOverflow}
                cssDialog={styles.modal}
                open={isSubsExpired}
                onHide={onHide}
                hideButton={false}
                title="Odnowienie subskrypcji">
                <h2 className={styles.modalH2}>
                    Twoja subskrypcja straciła ważność!
                </h2>
                {formState.onLoginUser.responseData.userRole === 'user' ? (
                    <Fragment>
                        <h4 className={styles.h4Center}>
                            Wybierz dogodny dla Ciebie plan i kontynuuj
                            obliczenia
                        </h4>

                        <Row className={styles.row}>
                            <Col xs={12}>
                                <Form className={styles.radioForm}>
                                    <RadioInput1
                                        name="yearSubscription"
                                        css={styles.radioWrapper}
                                        cssLabel={styles.radio}
                                        cssText={styles.labelText}
                                        label={'Roczna subskrypcja'}
                                        onChange={onChangeRenewSubs}
                                        value={
                                            subscriptionType.yearSubscription
                                        }
                                    />
                                    <RadioInput1
                                        name="monthSubscription"
                                        css={styles.radioWrapper}
                                        cssLabel={styles.radio}
                                        cssText={styles.labelText}
                                        label={'Miesięczna subskrypcja'}
                                        onChange={onChangeRenewSubs}
                                        value={
                                            subscriptionType.monthSubscription
                                        }
                                    />
                                </Form>
                            </Col>
                        </Row>
                        <Row className={styles.row}>
                            <Col xs={12}>
                                {isNumber(summaryCost) ? (
                                    <Fragment>
                                        <h2 className={styles.summary}>
                                            {`${summaryCost}`}
                                            <span>{` zł / ${
                                                subscriptionType.yearSubscription
                                                    ? 'rok'
                                                    : 'miesiąc'
                                            }`}</span>
                                        </h2>
                                        <p className={styles.newLicence}>
                                            Licencja zostanie przedłużona do:{' '}
                                            {moment()
                                                .add(
                                                    1,
                                                    subscriptionType.yearSubscription
                                                        ? 'years'
                                                        : 'months',
                                                )
                                                .local('pl')
                                                .format('DD.MM.YYYY, HH:mm')}
                                        </p>
                                    </Fragment>
                                ) : (
                                    <h2>-</h2>
                                )}
                            </Col>
                        </Row>
                        <Row className={styles.row}>
                            <Col xs={12} className={styles.center}>
                                <DefaultButton
                                    loading={isButtonDisabled}
                                    css={styles.buyNowButton}
                                    variant="info"
                                    name="subscribe"
                                    onClick={() => {
                                        onOpenInvoice(true)
                                        setIsSubsExpired(false)
                                    }}
                                    title="Kup teraz"
                                    altTitle="Kup teraz"
                                />
                            </Col>
                        </Row>
                    </Fragment>
                ) : (
                    <h4 className={styles.h4Center}>
                        Skontaktuj się z administratorem Twojego konta w celu
                        przedłużenia subskrypcji.
                    </h4>
                )}
            </Modal>
            <InvoiceForm
                openInvoice={openInvoice}
                invoiceType={invoiceType}
                invoiceState={invoice}
                saveFormData={saveFormData}
                onChangeInvoiceType={onChangeInvoiceType}
                onChangeInvoice={onChangeInvoice}
                onBlurInvoice={onBlurInvoice}
                onChangeDataCheckbox={onChangeDataCheckbox}
                onHideInvoice={() => {
                    setIsSubsExpired(true)
                    onHideInvoice();
                }}
                onSubmit={onOrderSubscription}
                cssDialog={styles.invoiceDialog}
            >
                <AlertMessage2
                    {...renewSubsMessageState}
                    isLoaded={true}
                />
            </InvoiceForm>
        </Wrapper>
    );
};

export default withForm({
    component: Login,
    clearFormToDefaultState: true,
    actions: [
        {
            name: 'onLoginUser',
            url: '/api/auth/login',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            errorMessage: 'Błędny login lub hasło',
            showErrorMessage: true,
            loadingName: '',
        },
        {
            name: 'resendLink',
            url: '/api/auth/account/activate/resend',
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            errorMessage: 'Wystąpił błąd podczas aktywacji konta',
            successMessage:
                'Link aktywacyjny został wysłany na podany adres email',
            loadingName: '',
            showErrorMessage: true,
            showSuccessMessage: true,
        },
        {
            name: 'renewSubscription',
            url: '/api/subscription/login/order',
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            returnData: true,
            errorMessage: 'Wystąpił błąd podczas przedłużania subskrypcji',
            loadingName: '',
            showErrorMessage: true,
            showSuccessMessage: false,
        },
    ],
    localState: {
        email: {
            value: '',
            isValid: null,
        },
        password: {
            value: '',
            isValid: null,
        },
        checked: false,
    },
});
