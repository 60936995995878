import { useCallback, useState } from "react";

const defaultState = {
    waterLevel: {
        value: '',
        isValid: true
    },
    groundLevel: {
        value: '',
        isValid: null  
    },
    newEmbankment: {
        value: false,
        isValid: true
    },
    embankmentsNumber: {
        value: '',
        isValid: null
    },
    negativeFriction: {
        value: false,
        isValid: true
    },
    layersName: {},
}
 
const CalculationSettingsHelpers = ({ updateResultsState }) => {
    const [calculationPropertiesState, setCalculationPropertiesState] = useState(defaultState);

    const onBlurCalculationPropertiesState = useCallback((e) => {
        const { name } = e.currentTarget;
        const valid = e.currentTarget.checkValidity(); 

        setCalculationPropertiesState(state => ({
            ...state, 
            [name]: { 
                ...state[name],
                isValid: valid
            }
        })) 
    },[]);

    const onChangeCalculationPropertiesState = useCallback((e) => {
        const input = e.currentTarget.closest('input');
        if(input){
            const { value, name, checked, type } = input;

            updateResultsState((state) => {
                return {
                    ...state,
                    isResultsActual: false, 
                };
            });

            if(type === 'checkbox'){
                if(name === 'layersName'){
                    const layerIndex = input.getAttribute('data-layer');

                    setCalculationPropertiesState(state => ({
                        ...state,
                        layersName: { 
                            ...state[name],
                            [layerIndex]: {
                                ...state[name][layerIndex],
                                value: !!checked
                            }
                        }
                    }))
                } else {
                    setCalculationPropertiesState(state => ({
                        ...state,
                        [name]: {
                            ...state[name],
                            value: !!checked
                        }
                    }))
                }
            } else {
                setCalculationPropertiesState(state => ({
                    ...state, 
                    [name]: { 
                        value: value.length > 0 ? Number(value) : value,
                        isValid: true
                    }
                }))            
            }            
        }
    },[]);

    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    },[]);

    const loadProject = (calculationSettingsObj={}) => {
        setCalculationPropertiesState(() => {
            return {
                ...defaultState,
                ...calculationSettingsObj
            }
        })
    }

    const calculationSettings = {
        onChange: onChangeCalculationPropertiesState,
        onBlur: onBlurCalculationPropertiesState,
        onKeyDown: onKeyDown,
        initState: () => setCalculationPropertiesState(defaultState),
        updateState: setCalculationPropertiesState,
        loadProject: loadProject,
        state: calculationPropertiesState
    }

    return {
        calculationSettings
    }

}

export default CalculationSettingsHelpers