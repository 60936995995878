const ceo = {
    'C50':	{
        h: 50,
        b: 38,
        tw: 5,
        tf: 7,
        r: 7,
        r1: 3.5,
        a: 7.12,
        m: 5.59,	
        u: 0.229,
        ix: 26.4,
        iy: 9.12,
        wx: 10.6,
        wy: 3.75
    },
    'C65':	{
        h: 65,
        b: 42,
        tw: 5.5,
        tf: 7.5,
        r: 7.5,
        r1: 4,
        a: 9.03,
        m: 7.09,	
        u: 0.273,
        ix: 57.5,
        iy: 14.1,
        wx: 17.7,
        wy: 5.07
    },
    'C80':	{
        h: 80,
        b: 45,
        tw: 6,
        tf: 8,
        r: 8,
        r1: 4,
        a: 11,
        m: 8.64,	
        u: 0.312,
        ix: 106,
        iy: 19.4,
        wx: 26.5,
        wy: 6.36
    },
    'C100':	{
        h: 100,
        b: 50,
        tw: 6,
        tf: 8.5,
        r: 8.5,
        r1: 4.5,
        a: 13.5,
        m: 10.6,
        u: 0.372,
        ix: 206,
        iy: 29.3,
        wx: 41.2,
        wy: 8.49
    },
    'C120':	{
        h: 120,
        b: 55,
        tw: 7,
        tf: 9,
        r: 9,
        r1: 4.5,
        a: 17,
        m: 13.4,	
        u: 0.434,
        ix: 364,
        iy: 43.2,
        wx: 60.7,
        wy: 11.1
    },
    'C140':	{
        h: 140,
        b: 60,
        tw: 7,
        tf: 10,
        r: 10,
        r1: 5,
        a: 20.4,
        m: 16,
        u: 0.489,
        ix: 605,
        iy: 62.7,
        wx: 86.4,
        wy: 14.8
    },
    'C160':	{
        h: 160,
        b: 65,
        tw: 7.5,
        tf: 10.5,
        r: 10.5,
        r1: 5.5,
        a: 24,
        m: 18.8,	
        u: 0.546,
        ix: 925,
        iy: 85.3,
        wx: 116,
        wy: 18.3
    },
    'C180':	{
        h: 180,
        b: 70,
        tw: 8,
        tf: 11,
        r: 11,
        r1: 5.5,
        a: 28,
        m: 22,	
        u: 0.611,
        ix: 1350,
        iy: 114,
        wx: 150,
        wy: 22.4
    },
    'C200':	{
        h: 200,
        b: 75,
        tw: 8.5,
        tf: 11.5,
        r: 11.5,
        r1: 6,
        a: 32.2,
        m: 25.3,
        u: 0.661,
        ix: 1910,
        iy: 148,
        wx: 191,
        wy: 27
    },
    'C220':	{
        h: 220,
        b: 80,
        tw: 9,
        tf: 12.5,
        r: 12.5,
        r1: 6.5,
        a: 37.4,
        m: 29.4,
        u: 0.718,
        ix: 2690,
        iy: 197,
        wx: 245,
        wy: 33.6
    },
    'C240':	{
        h: 240,
        b: 85,
        tw: 9.5,
        tf: 13,
        r: 13,
        r1: 6.5,
        a: 42.3,
        m: 33.2,
        u: 0.775,
        ix: 3600,
        iy: 248,
        wx: 300,
        wy: 39.6
    },
    'C260':	{
        h: 260,
        b: 90,
        tw: 10,
        tf: 14,
        r: 14,
        r1: 7,
        a: 48.3,
        m: 37.9,
        u: 0.834,
        ix: 4820,
        iy: 317,
        wx: 371,
        wy: 47.7
    },
    'C280':	{
        h: 280,
        b: 95,
        tw: 10,
        tf: 15,
        r: 15,
        r1: 7.5,
        a: 53.3,
        m: 41.8,
        u: 0.890,
        ix: 6280,
        iy: 399,
        wx: 448,
        wy: 57.2
    },
    'C300':	{
        h: 300,
        b: 100,
        tw: 10,
        tf: 16,
        r: 16,
        r1: 8,
        a: 58.8,
        m: 46.2,
        u: 0.950,
        ix: 8030,
        iy: 495,
        wx: 535,
        wy: 67.8
    },
    'C320':	{
        h: 320,
        b: 100,
        tw: 14,
        tf: 17.5,
        r: 17.5,
        r1: 8.75,
        a: 75.8,
        m: 59.5,
        u: 0.982,
        ix: 10870,
        iy: 597,
        wx: 679,
        wy: 80.6
    },
    'C350':	{
        h: 350,
        b: 100,
        tw: 14,
        tf: 16,
        r: 16,
        r1: 8,
        a: 77.3,
        m: 60.6,
        u: 1.047,
        ix: 12840,
        iy: 570,
        wx: 734,
        wy: 75
    },
    'C380':	{
        h: 380,
        b: 102,
        tw: 13.5,
        tf: 16,
        r: 16,
        r1: 8,
        a: 80.4,
        m: 63.1,
        u: 1.11,
        ix: 15760,
        iy: 615,
        wx: 829,
        wy: 78.7
    },
    'C400':	{
        h: 400,
        b: 110,
        tw: 14,
        tf: 18,
        r: 18,
        r1: 9,
        a: 91.5,
        m: 71.8,
        u: 1.182,
        ix: 20350,
        iy: 846,
        wx: 1020,
        wy: 102
    }
}

export default ceo;