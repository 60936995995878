import { Fragment } from "react";
import { isNumber } from "../../../../../utils";
import styles from './index.module.css';

const PileParametersSection = ({ parameters={}, pileLength }) => {
    
    return (
        <div className={styles.wrapper}>
            <h2>Parametry pala</h2>
            <p>Rodzaj pala: <span className={styles.value}>{parameters.pileType}</span></p>
            <p>Technologia wykonania: <span className={styles.value}>{parameters.pileTechnology}</span></p>
            <p>Przekrój pala: <span className={styles.value}>{parameters.pileShape}</span></p>
            <p>Średnica/bok pala: <span className={styles.value}>{parameters.diameterPile} m</span></p>
            <p>Długość pala: <span className={styles.value}>{isNumber(pileLength) && pileLength > 0 ? `${pileLength} m`: 'Brak' }</span></p>
            <p>Rzędna głowicy pala: <span className={styles.value}>{parameters.pileHeadSpot} m.p.p.t.</span></p>
            <p>Poszerzona podstawa: <span className={styles.value}>{isNumber(parameters.baseWidth) ? `${parameters.baseWidth}m` : 'Nie'}</span></p>

            {
                parameters.singlePile && !parameters.pileGroup &&
                <Fragment>
                    <p>Pal pojedynczy: <span className={styles.value}>Tak</span></p>
                    <p>Liczba pali podpierających fundament: <span className={styles.value}>
                        {
                            parameters.pilesInFoundation >= 3
                            ?
                            <Fragment>&#8805; {parameters.pilesInFoundation}</Fragment>
                            :
                            parameters.pilesInFoundation
                        }
                        </span></p>
                </Fragment>
                
            }
            {
                !parameters.singlePile && parameters.pileGroup &&
                <Fragment>
                    <p>Pal w grupie: <span className={styles.value}>Tak</span></p>
                    <p>Długość grupy pali: <span className={styles.value}>{parameters.groupLength}</span></p>
                    <p>Szerokość grupy pali: <span className={styles.value}>{parameters.groupWidth}</span></p>
                    <p>Rozstaw pali w grupie: <span className={styles.value}>{parameters.pileSpace}</span></p>
                    <p>Liczba pali w grupie: <span className={styles.value}>{parameters.groupPilesNumber}</span></p>
                </Fragment>
            }
        </div>
    )
}

export default PileParametersSection