import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { micropilesContext } from '../../context/Micropiles';
import styles from './index.module.css';
import ProjectTitle from './model/ProjectTitle';
import SoilTable from './model/SoilTable';
import SoilProfile from './model/SoilProfile';
import MicropileParameters from './model/MicropileParameters';
import ZwgLevels from './model/ZwgLevels';
import SaftyFactors from './model/SaftyFactors';

import { MainAlertAndLoading } from '../../components';
import SubmitCalculations from '../SubmitCalculations';
import { useWindowSize } from '../../hooks';

const Micropiles = () => {
    const {
        projectTitle = {},
        soilTable = {},
        soilProfile = {},
        saveSoilProfile = {},
        editSoilProfile = {},
        globalAlerts = {},
        globalLoading = {},
        micropileProperties = {},
        zwgLevels = {},
        saftyFactors = {},
        submitCalculation = {},
    } = useContext(micropilesContext);

    const modelRef = useRef(null);
    const [modelWidthPx, setModelWidthPx] = useState(0);
    const [isSteelRod, setIsSteelRod] = useState(false);

    const windowSize = useWindowSize();

    useEffect(() => {
        const rodType =
            micropileProperties.state.micropileType.value === 1
                ? micropileProperties.state.rodType.value
                : micropileProperties.state.rodThreadbarType.value;
        setIsSteelRod(micropileProperties.state.isSteelRod.value && rodType);
    }, [
        micropileProperties.state.isSteelRod.value,
        micropileProperties.state.rodType.value,
        micropileProperties.state.rodThreadbarType.value,
        micropileProperties.state.micropileType.value,
    ]);

    useEffect(() => {
        if (modelRef.current) {
            setModelWidthPx(modelRef.current.offsetWidth);
        }
    }, [windowSize.width]);

    return (
        <Fragment>
            <section className={styles.section}>
                <Row>
                    <ProjectTitle {...projectTitle} />
                </Row>
            </section>
            <section className={styles.section}>
                <Row>
                    <SoilTable
                        {...soilTable}
                        saveSoilProfile={saveSoilProfile}
                        editSoilProfile={editSoilProfile}
                        globalLoading={globalLoading.state}
                    />
                </Row>
            </section>
            <section className={styles.section}>
                <Row>
                    <Col xs={12} sm={6} ref={modelRef}>
                        <SoilProfile
                            {...soilProfile}
                            soilTable={soilTable.state}
                            pileHeadSpot={
                                micropileProperties.state.micropileHeadSpot
                                    .value
                            }
                            pileAngle={
                                micropileProperties.state.micropileAngle.value
                            }
                            slipPlate={
                                micropileProperties.state.slipPlate.value
                            }
                            isSlipPlate={
                                micropileProperties.state.isSlipPlate.value
                            }
                            isDeepPlate={
                                micropileProperties.state.isDeepPlate.value
                            }
                            profileParameters={zwgLevels.state}
                            modelWidthPx={modelWidthPx}
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <ZwgLevels
                            maxHeight={Math.max(
                                ...soilTable.state.map(
                                    (ele) => ele.height.value,
                                ),
                            )}
                            {...zwgLevels}
                        />
                        <MicropileParameters {...micropileProperties} />
                        <SaftyFactors
                            isFreeLength={
                                micropileProperties.state.isFreeLength.value
                            }
                            isSteelRod={isSteelRod}
                            {...saftyFactors}
                        />
                    </Col>
                </Row>
            </section>
            <section>
                <Row>
                    <Col xs={12}>
                        <SubmitCalculations {...submitCalculation} />
                    </Col>
                </Row>
            </section>
            <MainAlertAndLoading
                alerts={globalAlerts.state.alerts}
                updateAlerts={globalAlerts.updateState}
                loadings={globalLoading.state}
            />
        </Fragment>
    );
};

export default Micropiles;
