import React from 'react';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';

const UL = styled.ul`
    padding-left: 0;
    padding-top: 5px;
    margin-bottom: 0;
    color: ${({ valid }) => (valid ? '#28a745' : 'red')};
`;
const LI = styled.li`
    list-style: none;
`;
const Input = styled.input`
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    border-color: ${({ valid }) => valid === false && 'red'};
    border-color: ${({ valid }) => valid === true && '#28a745'};

    &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
`;

const ConfirmPassword = ({ onChange, confirmPassword: { value, isValid } }) => {
    return (
        <Form.Group controlId="confirmPassword">
            <Form.Label>Powtórz hasło</Form.Label>
            <Input
                valid={isValid}
                type="password"
                name="confirmPassword"
                value={value}
                placeholder="Powtórz hasło"
                onChange={onChange}
                required></Input>
            {isValid === false && (
                <div>
                    <UL>
                        <LI>Błędne hasła</LI>
                    </UL>
                </div>
            )}
        </Form.Group>
    );
};

export default ConfirmPassword;
