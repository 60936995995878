import { useState, useContext, Fragment } from "react";
import ContactFormProvider from "../../../context/ContactForm";
import { themeContext } from "../../../context/Theme";
import { authContext } from '../../../context/Authentication';
import { GlobalStyles } from '../../../config/globalStyles'
import { Error, Logout, RenewSession, Sidebar } from "../../../pages";
import { Navigate, Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";
import styles from './index.module.css';
import { Loading, Navbar } from "../../../components";
import config from '../../../config/config';

const loadingStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    opacity: 0.75,
    zIndex: 9999,
};


const GlobalLayout = ({ openSidebar, setOpenSidebar }) => {
    const { modeTheme, mode } = useContext(themeContext); 
    const [logOutModal, setLogOutModal] = useState(false);
    const toggleMenu = () => setOpenSidebar(openSidebar => !openSidebar);

    const {
        firstName,
        lastName,
        email,
        permission,
        report,
        subscription,
        setStateChanged,
        id,
        isLoading,
        isLoggedOut
    } = useContext(authContext);

    const token = localStorage.getItem('token');
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    const isUserLoggedIn = token && isLoggedIn;
 
    return (
        <ContactFormProvider>
            <GlobalStyles theme={modeTheme} open={openSidebar} />
            {
                isLoading && isUserLoggedIn
                ?
                <div style={loadingStyle}>
                    <Loading oppositeColors={true}/>
                </div>
                :
                !isUserLoggedIn
                ?
                    !isLoggedOut
                    ?
                    <Navigate to={config.routes.dashboard.logIn} />
                    :
                    <Error />
                :
                <Fragment>
                    <Sidebar mode={mode} firstName={firstName} lastName={lastName} email={email} report={report} onToggle={toggleMenu}/>
                    <Navbar onLogOut={setLogOutModal} role={permission} subsDays={subscription.days} mode={mode} />
                    <div id={'project-wrapper'} className={styles.wrapper}>
                        <Container>
                            <Outlet context={{
                                firstName,
                                lastName,
                                email,
                                permission,
                                report,
                                subscription,
                                setStateChanged,
                                id,
                            }}/>
                        </Container>
                        <RenewSession />
                        <Logout open={logOutModal} onChange={setLogOutModal}/>
                    </div>                    
                </Fragment>
            }
        </ContactFormProvider>
    )
}

export default GlobalLayout;