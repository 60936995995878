import { Col, Row } from "react-bootstrap";
import { withResults } from "../../../../../hoc";
import CptCapacityChart from "../CptCapacityChart";
import styles from './index.module.css';
import CapacityTable from "../CapacityTable";

const ResultsWrapper = ({ data={} }) => {
    return (
        <div className={'pile-cpt-capacity-results'}>
            <Row className={styles.row}>
                <Col xs={12}>
                    <CptCapacityChart
                        dataToChart={{
                            ...data
                        }}
                    />
                </Col>
                <Col xs={12}>
                    <CapacityTable
                        yst={data.yst}
                        yt={data.yt}
                        currentZ={data.currentCapacity.z}
                        dataToPaginate={data.results}
                        pileTypeNumber={data.pileTypeNumber}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default withResults(ResultsWrapper)