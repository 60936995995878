import { Outlet, useOutletContext } from "react-router-dom";
import CapacityChartsProvider from "../../../context/CapacityCharts";
import { CapacityChartsNavigation } from "../../../pages";

const CapacityChartsLayout = () => {
    const context = useOutletContext();
    return (
        <CapacityChartsProvider>
            <CapacityChartsNavigation />
            <Outlet context={context}/>
        </CapacityChartsProvider>
    )
}

export default CapacityChartsLayout;