import React, { useMemo } from 'react';
import { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    Button,
    InputField,
    Modal,
    Loading,
    SelectField,
} from '../../../components';
import OverwriteProfile from './OverwriteProfile';
import styles from './index.module.css';
import stringify from 'fast-json-stable-stringify';

const SaveSoils = ({
    open,
    openOverwriteProfile,
    onHide,
    onChange,
    onBlur,
    onSubmit,
    onOverwrite,
    state,
    saveLoading,
    overwriteLoading,

    onHideExistProfileModal,
}) => {
    const projectsStringify = stringify(state.projects);
    const currentUserProjectsStringify = stringify(state.currentUserProjects);

    const userProjects = useMemo(() => {
        const projectsParsed = JSON.parse(projectsStringify);

        return projectsParsed.filter((ele) => ele.email !== state.userEmail);
    }, [projectsStringify, state.userEmail]);

    const currentUserProjects = useMemo(() => {
        const currentUserProjectsParsed = JSON.parse(
            currentUserProjectsStringify,
        );

        return currentUserProjectsParsed;
    }, [currentUserProjectsStringify]);
    return (
        <Fragment>
            <Modal
                css={styles.modal}
                cssDialog={styles.modalDialog}
                open={open}
                onHide={onHide}
                title="Zapisz profil geologiczny"
                hideButton={false}>
                <Row className={styles.row}>
                    {Array.isArray(userProjects) && userProjects.length > 0 && (
                        <Col xs={6}>
                            <SelectField
                                title="Autor projektu"
                                onChange={onChange} 
                                value={state.currentUser}
                                defaultOption="Moje projekty"
                                disabledDefault={false}
                                options={userProjects.map(
                                    (ele) =>
                                        `${ele.firstName} ${ele.lastName} (${ele.email})`,
                                )}
                                name="currentUser"
                            />
                        </Col>
                    )}
                    <Col xs={6}>
                        <SelectField
                            title="Projekt"
                            onBlur={onBlur}
                            onChange={onChange}
                            value={state.mainProjectName.value}
                            defaultOption="Wybierz projekt"
                            isValid={state.mainProjectName.isValid}
                            options={currentUserProjects.map((ele) => ele.name)}
                            name="mainProjectName"
                            required
                        />
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={12}>
                        <InputField
                            onChange={onChange}
                            onBlur={onBlur}
                            value={state.profileName.value}
                            isValid={state.profileName.isValid}
                            type="text"
                            title="Nazwa profilu geologicznego"
                            placeholder="Nazwa profilu"
                            name="profileName"
                            required
                        />
                    </Col>
                    <Col xs={12}>
                        <Button
                            css={`
                                ${styles.button} default-button-style
                            `}
                            title="Zapisz profil"
                            altTitle="Zapisz profil"
                            name="saveProfile"
                            onClick={onSubmit}
                            type="submit"
                        />
                    </Col>
                </Row>
                {saveLoading && (
                    <Loading oppositeColors={true} text="Zapisuję profil..." />
                )}
            </Modal>
            <OverwriteProfile
                onHideExistProfileModal={onHideExistProfileModal}
                overwriteLoading={overwriteLoading}
                open={openOverwriteProfile}
                profileName={state.value}
                onSubmit={onOverwrite}
            />
        </Fragment>
    );
};

export default SaveSoils;
