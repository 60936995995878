import { isNumber } from "../../../../../utils";
import styles from './index.module.css';


const CalculationSection = ({ parameters={} }) => {

    return ( 
        <div className={styles.wrapper}>
            <h2>Parametry obliczeń</h2>
            <p>Rzędna terenu: <span className={styles.value}>{parameters.groundLevel.value} m.n.p.m.</span></p>
            <p>Rzędna zwierciadła wody: <span className={styles.value}>{isNumber(parameters.waterLevel.value) ? `${parameters.waterLevel.value} m.p.p.t.` : 'Brak'}</span></p>
            <p>Liczba warstw świeżego nasypu: <span className={styles.value}>{parameters.newEmbankment.value ? parameters.embankmentsNumber.value : 'Brak' }</span></p>
            <p>Warstwy z tarciem negatywnym: <span className={styles.value}>{!parameters.negativeFriction.value && 'Brak' }</span></p>
            {
                parameters.negativeFriction.value &&
                <ul>
                    {
                        Object.values(parameters.layersName).map((ele, idx) => {
                            return (
                                ele.value 
                                ?
                                    <li key={idx}><p>{ele.name}</p></li>
                                :
                                ''
                            )
                        })                        
                    }
                </ul>                
            }
        </div>
    )
}

export default CalculationSection